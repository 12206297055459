import styled, { createGlobalStyle } from 'styled-components'
import { themeCommonStyles } from '../../../themes/infludataThemes'

export const DayPickerGlobalStyles = createGlobalStyle`
  .DayPicker {
    font-family: ${themeCommonStyles.fontFamilyInter};
    background-color: ${themeCommonStyles.colors.white};
    border: 0.5px solid ${themeCommonStyles.colors.mediumBrown};
    border-radius: 17px;
    width: 100%;
    min-width: 270px;

    &-Caption {
      font-size: 16px;
      color: ${themeCommonStyles.colors.brown};
    }

    &-Weekday {
      abbr {
        font-size: 12px;
        font-weight: bold;
        cursor: default !important;
        color: ${themeCommonStyles.colors.mediumBrown};
        text-transform: uppercase;
      }
    }

    &-Day {
      color: ${themeCommonStyles.colors.darkBrown};
      line-height: 1.0;
      height: 36px;
      width: 36px;
      max-width: 36px;
      max-height: 36px;

      &--today {
        color: ${themeCommonStyles.colors.darkBrown} !important;
        font-weight: bold;
      }

      &--highlighted {
        color: ${themeCommonStyles.colors.white} !important;
      }

      &--highlighted {
        background: ${themeCommonStyles.gradients.main};
        color: ${themeCommonStyles.colors.white};
      }

      &:hover {
        background-color: ${themeCommonStyles.colors.lightBrown} !important;
      }

      &--outside {
        color: ${themeCommonStyles.colors.mediumBrown} !important;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
`

export const DayPickerWithNavbarGlobalStyles = createGlobalStyle`
  .DayPicker {
    &-Caption {
      display: none !important;
    }

    &-Month {
      margin-top: 0 !important;
    }
  }
`

export const CalendarNavbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 250px;
  margin: 15px auto;
`

export const CalendarNavbarPickMonthButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 23px;
`

export const CalendarNavbarPickMonthButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;

  & + & {
    margin-left: 10px;
  }
`

const InputWrapper = styled.div`
  .form-element {
    margin-bottom: 0;
  }

  .label {
    padding-left: 12px;
    margin-bottom: 2px;
  }

  .input {
    padding: 8px 15px;
    border-radius: 7px;
  }

  .error-message {
    padding-left: 5px;
  }
`

export const CalendarNavbarInputMonthWrapper = styled(InputWrapper)`
  max-width: 115px;
  min-width: 115px;
`

export const CalendarNavbarInputYearWrapper = styled(InputWrapper)`
  max-width: 75px;
  min-width: 75px;
`
