import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../localization/useTranslation'
import { useSelector } from 'react-redux'
import {
  openedCollectionUsersCountSelector,
  openedCollectionSortingSelector,
} from '../../redux/selectors'
import { withReduxActions } from '../../hocs/withRedux'
import { changeOpenedCollectionSorting } from '../../redux/ducks/collectionsDucks'

import { COLLECTIONS_SORTING_TYPES, SORTING_DIRECTIONS } from '../../constants/sortings'

import SortingCommonTool from './SortingCommonTool'

const CollectionUsersSortingTool = ({ changeOpenedCollectionSorting }) => {
  const { labelStrings } = useTranslation()

  const currentCollectionUsersCount = useSelector(openedCollectionUsersCountSelector)
  const { sortKey: currentSortKey, sortDirection: currentSortDirection } = useSelector(
    openedCollectionSortingSelector
  )

  const sortingOptionsList = [
    { value: COLLECTIONS_SORTING_TYPES.position.sortKey, label: labelStrings.dateAdded },
    { value: COLLECTIONS_SORTING_TYPES.username.sortKey, label: labelStrings.username },
    {
      value: COLLECTIONS_SORTING_TYPES.profileScore.sortKey,
      label: labelStrings.scoreOverall,
    },
    { value: COLLECTIONS_SORTING_TYPES.stars.sortKey, label: labelStrings.rating },
    { value: COLLECTIONS_SORTING_TYPES.followers.sortKey, label: labelStrings.followers },
    { value: COLLECTIONS_SORTING_TYPES.impressions.sortKey, label: labelStrings.viewsPerPost },
  ]

  const getCurrentSortingOption = useCallback(() => {
    return sortingOptionsList.find(obj => obj.value === currentSortKey)
  }, [currentSortKey])

  const changeSortingHandler = ({ value: newSortKey }) => {
    if (newSortKey === currentSortKey) return
    changeOpenedCollectionSorting({ newSortKey, newSortDirection: SORTING_DIRECTIONS.descend })
  }

  const changeSortingDirectionHandler = newSortDirection => () => {
    if (newSortDirection === currentSortDirection) return
    changeOpenedCollectionSorting({ newSortKey: currentSortKey, newSortDirection })
  }

  const showSorting = currentCollectionUsersCount > 1 // show sorting only when there are at least 2 users

  return (
    showSorting && (
      <SortingCommonTool
        sortToolId="collection_users_sort"
        sortingOptions={sortingOptionsList}
        changeSortingHandler={changeSortingHandler}
        currentSortingValue={getCurrentSortingOption()}
        changeSortingDirectionHandler={changeSortingDirectionHandler}
        currentSortingDirection={currentSortDirection}
      />
    )
  )
}

CollectionUsersSortingTool.propTypes = {
  changeOpenedCollectionSorting: PropTypes.func,
}

export default withReduxActions({ changeOpenedCollectionSorting })(CollectionUsersSortingTool)
