import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Chart } from 'react-google-charts'
import LoadingSpinner from '../common/spinners/LoadingSpinner'
import NotEnoughDataForChartMessage from './NotEnoughDataForChartMessage'

const PieChartWithLegendContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.isForPdf ? 'column-reverse' : 'row')};
  justify-content: center;
  align-items: start;
  min-height: 320px;
`

const ChartContainer = styled.div`
  max-height: 250px;
  max-width: 250px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const CustomLegend = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  ${props => props.isForPdf && 'padding-left: 20px'};
`

const CustomLegendItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & + & {
    margin-top: 10px;
  }
`

const CustomLegendColormark = styled.div`
  width: 18px;
  height: 18px;
  background-color: ${props => props.color};
  border-radius: 5px;
  ${props => props.isForPdf && 'margin-top: 18px'};
`

const CustomLegendText = styled.div`
  font-size: 13px;
  line-height: 16px;
  margin-left: 5px;
  ${props =>
    props.isDisabled
      ? `
    color: ${props.theme.colors.lightBrown};
    background-color: ${props.theme.colors.lightBrown};
    user-select: none;
  `
      : `
    color: ${props.theme.colors.darkBrown};
  `}
`

const CustomLegendPercentage = styled.div`
  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
  margin-left: 5px;
  ${props =>
    props.isDisabled
      ? `
    color: ${props.theme.colors.lightBrown};
    background-color: ${props.theme.colors.lightBrown};
    user-select: none;
  `
      : `
    color: ${props.theme.colors.darkBrown};
  `}
`

const PieChart = ({
  chartData = [],
  withCustomLegend = false,
  title = '',
  userindex = '',
  isDisabledChart = true,
  isAudienceTypeChart = false,
  notEnoughData = false,
  isForPdf = false,
}) => {
  const isChartDisabled = isDisabledChart || notEnoughData
  const createCustomLegend = () => {
    return chartData.values.reduce((arr, item, idx) => {
      if (idx) {
        // skip first el with titles
        arr.push(
          <CustomLegendItem isForPdf={isForPdf} key={item[0] + item[1]}>
            <CustomLegendColormark isForPdf={isForPdf} color={chartData.colors[idx - 1]} />
            <CustomLegendText isDisabled={isChartDisabled}>{item[0]}</CustomLegendText>
            <CustomLegendPercentage isDisabled={isChartDisabled}>{item[1]}%</CustomLegendPercentage>
          </CustomLegendItem>
        )
      }

      return arr
    }, [])
  }

  return (
    <PieChartWithLegendContainer
      withCustomLegend={withCustomLegend}
      isDisabled={isChartDisabled}
      isForPdf={isForPdf}
    >
      {withCustomLegend && (
        <CustomLegend isForPdf={isForPdf} lengthItems={chartData?.values?.length}>
          {createCustomLegend()}
        </CustomLegend>
      )}
      <ChartContainer isAudienceTypeChart={isAudienceTypeChart} isForPdf={isForPdf}>
        <Chart
          className="pie-chart"
          chartType="PieChart"
          loader={<LoadingSpinner />}
          data={chartData.values}
          options={{
            backgroundColor: 'transparent',
            enableInteractivity: false, // disabled all interactions
            pieHole: !isForPdf ? 0.5 : 0.25,
            colors: chartData.colors,
            legend: {
              height: 200,
              position: withCustomLegend || isChartDisabled ? 'none' : 'left',
              alignment: 'center',
              textStyle: {
                color: '#272727',
                fontSize: 12,
                fontName: 'Inter',
              },
            },
            width: !isForPdf ? 400 : 250,
            height: !isForPdf ? 400 : 250,
            pieSliceText: isChartDisabled ? 'none' : 'pecentage',
            pieSliceTextStyle: {
              color: '#ffffff',
              fontSize: 13,
              fontName: 'Inter',
            },
            tooltip: {
              trigger: 'selection',
              showColorCode: true,
              textStyle: {
                color: '#272727',
                fontSize: 12,
                fontName: 'Inter',
              },
            },
            sliceVisibilityThreshold: 0.005,
          }}
          rootProps={{ 'data-testid': '3' }}
        />
      </ChartContainer>
      {notEnoughData && <NotEnoughDataForChartMessage platform={userindex} isForPdf={isForPdf} />}
    </PieChartWithLegendContainer>
  )
}

PieChart.propTypes = {
  chartData: PropTypes.object.isRequired,
  userindex: PropTypes.string,
  withCustomLegend: PropTypes.bool,
  isDisabledChart: PropTypes.bool,
  isAudienceTypeChart: PropTypes.bool,
  notEnoughData: PropTypes.bool,
  isForPdf: PropTypes.bool,
}

export default PieChart
