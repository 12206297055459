import React from 'react'
import Popup from 'reactjs-popup'
import { WithPopupWrapper } from '../../../common/styledWrappers'
import PropTypes from 'prop-types'
import BuyAudienceTokensDialog from '../../../common/dialogs/BuyAudienceTokensDialog'

const BuyAudienceTokensModal = ({ open, onClose, onSubmit }) => {
  return (
    <WithPopupWrapper isTransparent fullScreen withBlurBG>
      <Popup open={open} modal closeOnDocumentClick={false} closeOnEscape={false}>
        <BuyAudienceTokensDialog onSubmit={onSubmit} onClose={onClose} />
      </Popup>
    </WithPopupWrapper>
  )
}

BuyAudienceTokensModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default BuyAudienceTokensModal
