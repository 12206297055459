import React from 'react'
import ReactDOMServer from 'react-dom/server'
import PropTypes from 'prop-types'
import { Box, Paper, useMediaQuery } from '@mui/material'
import { BigCardWidget } from '../../../components/cardWidgets/BigCardWidget'
import { useSelector } from 'react-redux'
import { currentOpenedCampaignSelector } from '../../../../redux/selectors'
import useTranslation from '../../../../localization/useTranslation'
import ChartCardWidget from '../../../components/cardWidgets/ChartCardWidget'
import { pretifyBigNum } from '../../../../utils'

export const Overview = ({ countsWidget = [], customTracking = [], isPdf = false }) => {
  const currentCampaign = useSelector(currentOpenedCampaignSelector)
  const { settings } = currentCampaign
  const { labelStrings } = useTranslation()

  const isSmallDisplay = useMediaQuery('(max-width:1620px)')
  const isExtraSmallDisplay = useMediaQuery('(max-width:1380px)')

  if (!countsWidget?.length && !customTracking?.length) return null

  const rowLengthItem = [...countsWidget, ...customTracking]?.length

  const getItemTitle = graphId => {
    const baseTitles = {
      creators_in_campaigns_count: labelStrings.filedLabels.creators_in_campaigns_count,
      published_content_count: labelStrings.filedLabels.published_content_count,
      reached_users_sum: labelStrings.filedLabels.reached_users_sum,
      received_likes_count: labelStrings.filedLabels.received_likes_count,
      received_comments_count: labelStrings.filedLabels.received_comments_count,
      widget_earned_media_value: labelStrings.filedLabels.widget_earned_media_value,
    }

    const lastPart = graphId.split('_').pop()

    if (graphId?.startsWith('widget_brand_account_growth_')) {
      return labelStrings.filedLabels.widget_brand_account_growth
    }

    // Check if the last part is a number, implying it's a dynamic key
    if (!isNaN(parseInt(lastPart))) {
      return 'Follower Change'
    }

    return baseTitles[graphId] || 'Unknown Title' // Default to 'Unknown Title' if no match is found
  }
  const getItemIcon = graphId => {
    const baseTitles = {
      creators_in_campaigns_count: 'tabler:number',
      published_content_count: 'solar:posts-carousel-vertical-outline',
      reached_users_sum: 'fluent:megaphone-loud-32-filled',
      received_likes_count: 'iconamoon:like-bold',
      received_comments_count: 'uil:comments',
      widget_earned_media_value: 'tdesign:money',
    }
    const lastPart = graphId.split('_').pop()

    // Check if the last part is a number, implying it's a dynamic key
    if (!isNaN(parseInt(lastPart))) {
      return 'fluent:arrow-growth-20-filled'
    }

    return baseTitles[graphId] || 'uil:comments' // Default to 'Unknown Title' if no match is found
  }

  return (
    <Paper
      direction="row"
      spacing={1}
      sx={{
        px: 1,
        display: 'grid',
        counterReset: `grid-items`,
        gridTemplateColumns: isPdf
          ? `repeat(5,1fr)`
          : isSmallDisplay
          ? `repeat(${isExtraSmallDisplay ? 5 : 7},1fr)`
          : `repeat(${rowLengthItem > 9 ? 6 : 9}, 1fr)`,

        paddingTop: isPdf ? 1 : 1,
        paddingBottom: isPdf ? 0 : 1,
        backgroundColor: '#f0f1f3',
        my: 4,

        borderRadius: 2,

        boxShadow: '0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)',
      }}
      justifyContent="center"
      alignItems="center"
    >
      {countsWidget?.map(countWidget => {
        if (countWidget.graphId?.startsWith('widget_brand_account_growth_')) {
          const chartSeriesData = countWidget.graphData?.map(el => el.followers)
          const chartCategories = countWidget.graphData?.map(el => el.date)
          return (
            <Box key={countWidget?.graphId} sx={{ padding: 1, counterIncrement: `grid-item` }}>
              <ChartCardWidget
                title={getItemTitle(countWidget.graphId)}
                subtitle={
                  countWidget.labelBrandAccount
                    ? `@${countWidget.labelBrandAccount}`
                    : countWidget.uniqueProfileIdCount
                    ? labelStrings.ofTotalSelectedCreators
                    : null
                }
                percent={countWidget?.valuePercentage}
                total={countWidget?.valueAbsolute}
                helperText={labelStrings.widgetBrandAccountGrowthHelperText}
                sx={{
                  width: '100%',
                  minWidth: '200px',
                  height: '235px',
                }}
                chart={{
                  series: chartSeriesData,
                  options: {
                    xaxis: {
                      categories: chartCategories,
                    },
                    tooltip: {
                      custom: function({ seriesIndex, dataPointIndex, w }) {
                        const tooltipView = (
                          <div
                            style={{
                              padding: '5px',
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: '12px',
                            }}
                          >
                            {labelStrings?.date}: {chartCategories[dataPointIndex]}
                            <br />
                            {labelStrings?.followers}:{' '}
                            {pretifyBigNum(w.globals.series[seriesIndex][dataPointIndex])}
                          </div>
                        )
                        return ReactDOMServer.renderToStaticMarkup(tooltipView)
                      },
                    },
                  },
                }}
              />
            </Box>
          )
        }

        return (
          <Box key={countWidget?.graphId} sx={{ padding: 1, counterIncrement: `grid-item` }}>
            <BigCardWidget
              title={getItemTitle(countWidget.graphId)}
              subtitle={
                countWidget.labelBrandAccount
                  ? `@${countWidget.labelBrandAccount}`
                  : countWidget.uniqueProfileIdCount
                  ? labelStrings.ofTotalSelectedCreators
                  : null
              }
              sx={{ width: '100%', minWidth: '115px', height: '235px' }}
              total={
                countWidget.uniqueProfileIdCount
                  ? countWidget.uniqueProfileIdCount
                  : countWidget.value
              }
              totalPercentage={countWidget.valuePercentage}
              subTotal={
                countWidget.uniqueProfileIdCount
                  ? `${labelStrings.of} ${countWidget.value}`
                  : countWidget.valueAbsolute
                  ? parseInt(countWidget.valueAbsolute)
                  : null
              }
              metricName={countWidget.hasCurrency ? `${settings?.campaignCurrency || 'EUR'}` : null}
              icon={getItemIcon(countWidget.graphId)}
              compareRate={countWidget.compareRate}
              compareDifference={countWidget.compareDifference}
            />
          </Box>
        )
      })}
      {customTracking?.map(customTrack => {
        return (
          <Box key={customTrack?.graphId} sx={{ padding: 1, counterIncrement: `grid-item` }}>
            <BigCardWidget
              title={customTrack.goalName}
              total={customTrack.valueSum}
              metricName={customTrack.metricName}
              icon="uil:comment-info"
              color="info"
              sx={{ width: '100%', minWidth: '115px', height: '100%' }}
            />
          </Box>
        )
      })}
    </Paper>
  )
}

Overview.propTypes = {
  countsWidget: PropTypes.array,
  customTracking: PropTypes.array,
  isPdf: PropTypes.bool,
}