import React, { useState } from 'react'
import useTranslation from '../../../../localization/useTranslation'
import { Form } from '../../../../components/common/styledWrappers/signupFormsStyles/signupStepOneFormStyles'
import { Button, Stack, TextField } from '@mui/material'
import MailOutlinedIcon from '@mui/icons-material/MailOutlined'
import { EMAIL_VALID_REGEX } from '../../../../constants/login'
import { validateEmail } from '../../../../utils'

const StyledLoginBtn = {
  marginTop: '40px',
  fontFamily: `Lato, sans-serif`,
  fontWeight: 'bold',
  fontSize: '17px',
  lineHeight: '16px',
  color: '#fff',
  width: '100%',
  textTransform: 'uppercase',
  border: 'none',
  background:
    'transparent linear-gradient(89deg, #FF726F 0%, #FFCA55 120%) 0% 0% no-repeat padding-box',
}

export const Email = props => {
  const { setSteps, changeSignupState } = props || {}

  const { labelStrings } = useTranslation()

  const [formState, changeFormState] = useState({
    email: '',
    isValidEmail: true,
    isGoogleAuthError: false,
    errorText: '',
  })

  const isEmailInvalid = !formState.isValidEmail
  const hasErrorText =
    !!formState.errorText?.length &&
    formState.errorText !== labelStrings.signupLocalization.workEmailAddress

  const changeEmail = ({ target }) => changeFormState({ ...formState, email: target.value })
  const onFocusInput = () =>
    !formState.isValidEmail && changeFormState({ ...formState, isValidEmail: true })

  const submitForm = async e => {
    e.preventDefault()
    const isValidEmail = !!formState.email && validateEmail(formState.email.trim())
    if (isValidEmail) {
      const { email } = formState
      setSteps(prev => prev + 1)
      changeSignupState(prevState => ({
        ...prevState,
        stepOneData: { email },
        stepTwoData: {
          ...prevState.stepTwoData,
        },
      }))
    }
    changeFormState({ ...formState, isValidEmail: false })
  }

  const onBlurEmailInput = () => {
    if (formState.email.trim() === '') {
      changeFormState({
        ...formState,
        errorText: labelStrings.signupLocalization.workEmailAddress,
      })
    } else if (!formState.email.match(EMAIL_VALID_REGEX)) {
      changeFormState({
        ...formState,
        errorText: labelStrings.signupLocalization.emailError,
      })
    } else {
      changeFormState({
        ...formState,
        errorText: '',
      })
    }
  }

  return (
    <Form onSubmit={submitForm}>
      <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
        <MailOutlinedIcon
          sx={{
            color: isEmailInvalid || hasErrorText ? '#FF5630' : 'action.active',
            mr: 1,
            my: 0.5,
          }}
        />
        <TextField
          variant="standard"
          sx={{
            width: '100%',
            '& .MuiFormHelperText-root': {
              fontSize: '15px',
            },
            '& .MuiInput-input': {
              fontSize: '18px',
              fontFamily: 'Inter, sans-serif',
            },
          }}
          label={labelStrings.email}
          id="signup-email"
          type="text"
          size="medium"
          onChange={changeEmail}
          onFocus={onFocusInput}
          onBlur={onBlurEmailInput}
          value={formState.email}
          error={isEmailInvalid || hasErrorText}
          helperText={
            isEmailInvalid ? labelStrings.reenterNotValidEmail : formState.errorText || null
          }
          autoFocus
        />
      </Stack>

      <Button sx={StyledLoginBtn} variant="contained" type="submit" size="large">
        {labelStrings.next}
      </Button>
    </Form>
  )
}
