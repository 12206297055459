import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import { FormHelperText, Stack, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers-pro'

RHFDateField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
}

export default function RHFDateField({
  name,
  label,
  minDate,
  maxDate,
  defaultValue,
  size,
  helperText = '',
  ...other
}) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <Stack direction="column">
            <DatePicker
              label={label}
              defaultValue={defaultValue}
              value={!!field.value ? new Date(field.value) : null}
              maxDate={maxDate}
              minDate={minDate}
              format="dd/MM/yyyy"
              onChange={newValue => {
                field.onChange(newValue)
              }}
              slotProps={{ textField: { size: size } }}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  error={!!error}
                  helperText={error ? error?.message : helperText}
                  {...other}
                  sx={{
                    '	.MuiFormHelperText-root': {
                      margin: 0,
                      marginTop: 1,
                      padding: 0,
                    },
                  }}
                />
              )}
            />

            {error?.message && (
              <FormHelperText error={!!error} sx={{ mx: 0, mt: 1 }}>
                {error?.message}
              </FormHelperText>
            )}
          </Stack>
        )
      }}
    />
  )
}
