export const TABLE_HEAD = [
  { id: 'value', label: 'Search Term', align: 'left' },
  { id: 'type', label: 'Type', align: 'left' },
  { id: 'count', label: 'Found #', align: 'left' },
  { id: 'latestActivity', label: 'Last Activity', align: 'left' },
  { id: 'startingDate', label: 'Added', align: 'left' },
  { id: '' },
]

export const inputSymbol = {
  ['mention']: '@',
  ['hashtag']: '#',
  // ['link']: '/',
}

export const applyFilter = ({ inputData, comparator, filterSearchTerm }) => {
  const stabilizedThis = inputData.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })

  inputData = stabilizedThis.map(el => el[0])

  if (filterSearchTerm) {
    inputData = inputData.filter(
      field =>
        field.value?.toLowerCase().indexOf(filterSearchTerm.toLowerCase()) !== -1 ||
        field.type?.toLowerCase().indexOf(filterSearchTerm.toLowerCase()) !== -1
    )
  }

  return inputData
}
