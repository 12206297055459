import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'
import * as Styled from './styles'

const ComparisonToSimilarInfluencers = ({ label = '', benchmarkArray = [], xAxisValues = [], isTitleHidden }) => {
  const { labelStrings } = useTranslation()

  return (
    <Styled.ChartContainer>
      {!isTitleHidden && (
        <Styled.ChartName>{labelStrings.comparisonToSimilarInfluencers}</Styled.ChartName>
      )}

      <Styled.ChartLabel>{label}</Styled.ChartLabel>

      <Styled.BarsAndLabelsWrapper>
        <Styled.Bars>
          {benchmarkArray.map((item, index) => (
            <Styled.BarWrapper key={index} isActive={item.isActive}>
              <Styled.BarFilled score={item.score} height={item.height} index={index} p={item.p} />
              {item.isActive && <Styled.BarIndicator />}
            </Styled.BarWrapper>
          ))}
        </Styled.Bars>

        <Styled.BarLabels>
          {xAxisValues.map((value, index) => (
            <Styled.BarLabel key={index}>{value}</Styled.BarLabel>
          ))}
        </Styled.BarLabels>
      </Styled.BarsAndLabelsWrapper>
    </Styled.ChartContainer>
  )
}

ComparisonToSimilarInfluencers.propTypes = {
  label: PropTypes.string,
  benchmarkArray: PropTypes.arrayOf(
    PropTypes.shape({
      height: PropTypes.number,
      p: PropTypes.number,
      isActive: PropTypes.bool,
      parsedValue: PropTypes.string,
      score: PropTypes.number,
      value: PropTypes.number,
    })
  ),
  xAxisValues: PropTypes.arrayOf(PropTypes.string),
  isTitleHidden: PropTypes.bool,
}

export default ComparisonToSimilarInfluencers
