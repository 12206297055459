import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import ReactSpeedometer from 'react-d3-speedometer'
import NoDataMessage from '../../../influencerProfile/detailedInfludataRating/NoDataMessage'
import useTranslation from '../../../../localization/useTranslation'
import { TextTips } from '../../../common/textTips'
import * as Styled from './styles'

export const DonutChart = ({ totalOverlap = 0, tabSelect = '' }) => {
  const { labelStrings, currentLang } = useTranslation()

  useEffect(() => {
    const labels = document.querySelectorAll('.segment-value')
    labels.forEach(label => {
      // @ts-ignore
      label.innerHTML = `${label?.__data__}%`
    })
  }, [])

  const renderChart = useMemo(() => {
    return totalOverlap === 0 ? (
      <Styled.NoDataWrapper>
        <h3>{labelStrings?.overallComparison}</h3>
        <NoDataMessage tabSelect={tabSelect}/>
      </Styled.NoDataWrapper>
    ) : (
      <Styled.DonutChartWrapper>
        <div className="donutLeftSide">
          <h3>{labelStrings?.overallComparison}</h3>
          <div className="chart-description">{labelStrings?.graphDescrOveral}</div>
          <TextTips
            title={labelStrings?.overalChartCalculatedTitle}
            main={labelStrings?.overallCompareModalTitle}
            text={labelStrings?.overallCompareModalText}
          />
        </div>
        <div className="donutRightSide">
          <h3>{labelStrings?.collectionAudienceOverlapTitle}</h3>
          <div className="reactSpeedWrapper">
            {totalOverlap && (
              <ReactSpeedometer
                forceRender
                fluidWidth={true}
                customSegmentStops={[0.0, 12, 24, 36, 48, 60]}
                minValue={0}
                maxValue={60}
                segmentColors={['#6CB75B', '#CBD923', '#FFDB00', '#FDAF11', '#FF5D5A']}
                value={totalOverlap || 0}
                textColor={'#784E4E'}
                labelFontSize={'10'}
                valueTextFontSize={'34px'}
                needleHeightRatio={0.8}
                paddingVertical={15}
                valueTextFontWeight={'bold'}
                currentValueText={`${totalOverlap || 0}%`}
                needleColor="#784E4E"
                needleTransitionDuration={4000}
                // @ts-ignore
                needleTransition="easeElastic"
              />
            )}
          </div>
        </div>
      </Styled.DonutChartWrapper>
    )
  }, [totalOverlap, currentLang])

  return renderChart
}

DonutChart.propTypes = {
  totalOverlap: PropTypes.number,
  tabSelect: PropTypes.string
}
