import React from 'react'
import Popup from 'reactjs-popup'
import styled from 'styled-components'
import useTranslation from '../../localization/useTranslation'
import { WithPopupWrapper } from '../common/styledWrappers'

const Tip = styled.div`
  font-size: 20px;
  color: ${props => props.theme.color.mineShaft};
  font-weight: 600;
  background: ${props => props.theme.primaryColorLighter};
  padding: 20px;
  border-radius: 10px;
  height: 84%;
`

const SubUserSettingsTip = () => {
  const { labelStrings } = useTranslation()

  return (
    <WithPopupWrapper enableHeader withBlurBG fullScreen isTransparent popupHeightOffset={110}>
      <Popup open={true} modal closeOnDocumentClick={false} closeOnEscape={false}>
        {close => <Tip>{labelStrings.subUserSettingsTip}</Tip>}
      </Popup>
    </WithPopupWrapper>
  )
}

export default SubUserSettingsTip
