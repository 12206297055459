import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import useTranslation from '../../../localization/useTranslation'
import { currentOpenedCampaignSelector } from '../../../redux/selectors'
import { ModalComponent } from '../../components/modal/ModalComponent'
import {
  Stack,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Button,
  Popover,
} from '@mui/material'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined'

const CONTENT_MODAL_WIDTH = '740px'
const WEBHOOK_URL = 'https://app.infludata.com/api/catchHookCampaign'

export const WebhookToCustomField = props => {
  const { labelStrings } = useTranslation()
  const { campaignId } = useSelector(currentOpenedCampaignSelector)

  const { setOpenWebHook, openWebhook: isOpenModal, setCurrRow, currRow } = props
  const { applyLevel, customFieldId } = currRow || {}

  const [anchorPopover, setAnchorPopover] = useState(null)
  const [currPopover, setCurrPopover] = useState(null)

  const handlePopoverOpen = event => {
    setAnchorPopover(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorPopover(null)
  }

  const handleClose = () => {
    setOpenWebHook(false)
    setCurrRow(null)
  }

  const onCopy = () => {
    navigator.clipboard.writeText(WEBHOOK_URL)
  }

  const popoverText = {
    ['creatorId']: labelStrings.customFieldWebHook.popoverText.creatorId,
    ['contentId']: labelStrings.customFieldWebHook.popoverText.contentId,
  }

  const applyLevelLabel = {
    ['creator']: labelStrings.customFieldWebHook.applyLevelTitles.creator,
    ['content']: labelStrings.customFieldWebHook.applyLevelTitles.content,
    ['campaign']: labelStrings.customFieldWebHook.applyLevelTitles.campaign,
  }

  return (
    <>
      <ModalComponent
        isOpenModal={isOpenModal}
        closeModal={handleClose}
        title={labelStrings.customFieldWebHook.modalTitle}
        contentWidth={CONTENT_MODAL_WIDTH}
        contentHeight='auto'
        isCenterTitle
      >
        <Stack>
          <Typography textAlign="center" sx={{ pb: 3, color: '#637381' }}>{applyLevelLabel[applyLevel]}</Typography>

          <Stack spacing={1}>
            <Typography sx={{ fontWeight: 'bold' }}>
              1. {labelStrings.customFieldWebHook.sendAPostReq}
            </Typography>
            <TextField
              sx={{
                '& .MuiInputBase-input': {
                  color: '#637381',
                },
              }}
              value={WEBHOOK_URL}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={onCopy}>
                      <ContentCopyIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack spacing={1} sx={{ mt: 3 }}>
            <Typography sx={{ fontWeight: 'bold' }}>
              2. {labelStrings.customFieldWebHook.addTheFollowingVariables}
            </Typography>
            <Stack
              spacing={1}
              sx={{
                p: 2,
                borderRadius: '14px',
                border: '1px solid lightgrey',
                height: '100%',
                '&:hover': {
                  borderColor: '#212B36',
                },
                '& .MuiTypography-root': {
                  color: '#637381',
                },
              }}
            >
              <Typography>
                campaignId: {campaignId} ({labelStrings.customFieldWebHook.required})
              </Typography>
              <Typography>
                customField: {customFieldId} ({labelStrings.customFieldWebHook.required})
              </Typography>
              <Typography>value: Number (optional, default is 1)</Typography>
              <Typography>date: format "2024-12-01" (optional, default is current date)</Typography>
              {applyLevel === 'creator' && (
                <Stack direction="row" alignItems="center" sx={{ maxHeight: '24px' }}>
                  <Typography>
                    creatorId: String ({labelStrings.customFieldWebHook.required})
                  </Typography>
                  <IconButton
                    onMouseEnter={e => {
                      handlePopoverOpen(e)
                      setCurrPopover('creatorId')
                    }}
                    onMouseLeave={() => {
                      setCurrPopover('')
                      handlePopoverClose()
                    }}
                    disableFocusRipple
                    disableRipple
                  >
                    <HelpCenterOutlinedIcon />
                  </IconButton>
                </Stack>
              )}
              {applyLevel === 'content' && (
                <Stack direction="row" alignItems="center" sx={{ maxHeight: '24px' }}>
                  <Typography>
                    contentId: String ({labelStrings.customFieldWebHook.required})
                  </Typography>
                  <IconButton
                    onMouseEnter={e => {
                      handlePopoverOpen(e)
                      setCurrPopover('contentId')
                    }}
                    onMouseLeave={() => {
                      setCurrPopover('')
                      handlePopoverClose()
                    }}
                    disableFocusRipple
                    disableRipple
                  >
                    <HelpCenterOutlinedIcon />
                  </IconButton>
                </Stack>
              )}
            </Stack>
          </Stack>
          <Typography textAlign="center" sx={{ pt: 2, color: '#637381' }}>
            {labelStrings.customFieldWebHook.noteText}
            {<br />}
            {labelStrings.customFieldWebHook.noteSubText}
          </Typography>

          <Stack direction="row" alignItems="center" justifyContent="center">
            <Button onClick={handleClose} sx={{ my: 3, width: 'fit-content' }} variant="contained">
              {labelStrings.close}
            </Button>
          </Stack>
        </Stack>
      </ModalComponent>
      <Popover
        open={!!anchorPopover}
        anchorEl={anchorPopover}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          pointerEvents: 'none',
        }}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2, maxWidth: '450px' }}>{popoverText[currPopover]}</Typography>
      </Popover>
    </>
  )
}
