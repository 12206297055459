import styled from 'styled-components'

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => {
    if (!props.platform) return props.theme.gradients.mainWithOpacity(0.8)
    return props.theme.getGradientsForPlatform(props.platform).mainWithOpacity(0.8)
  }};
  backdrop-filter: blur(10px);
  z-index: 2147483647;
  overflow-y: auto;
  padding: 75px;
  ${props => props.theme.scrollbarStyles}
`

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  min-height: 100%;
  height: fit-content;
`

export const ModalWindow = styled.div`
  min-width: 350px;
  max-width: 500px;
  width: ${props => props.width || 'auto'};
  border-radius: 16px;
  overflow: hidden;
`

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  text-align: center;
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.darkBrown};
  position: relative;
`

export const ModalHeaderContent = styled.div`
  padding: 0 20px;
  flex: 1;
`

export const ButtonClose = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  margin: 0;
  padding: 0;
  line-height: 0;
  background-color: transparent;
  border: none;

  .icon_close {
    width: 20px;
    height: 20px;
  }
`

export const ModalContent = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.white};
`

export const ConfirmationModalMessage = styled.div`
  padding: 20px;
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.brown};
`

export const ConfirmationModalFooter = styled.div`
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.lightBrown};
  position: relative;

  button + button {
    margin-left: 15px;
  }
`
