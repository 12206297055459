import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectedPlatformSelector } from '../../redux/selectors'
import SuggestedLocationsContainer from './SuggestedLocationsContainer'
import { LocationsSearchWrapper, SuggestLocationsWrapper } from './styles'
import useTranslation from '../../localization/useTranslation'

let isAnalyzeResultsVisible = false // global var for native eventListener, it "doesn't see" inner state vars

const GoogleLocationSearchContainer = ({ changeLocation }) => {
  const { labelStrings } = useTranslation()
  const currentPlatform = useSelector(selectedPlatformSelector)
  // const isUserLoggedIn = useSelector(isUserLoggedInSelector)

  const [isDocumentOutsideClicked, setIsDocumentOutsideClicked] = useState(false)
  const [isAnalyzeResultsOpened, setIsAnalyzeResultsOpened] = useState(false)

  const changeIsAnalyzedResultsOpened = isOpened => setIsAnalyzeResultsOpened(isOpened)

  useEffect(() => {
    isAnalyzeResultsVisible = isAnalyzeResultsOpened // copy value to outside var, to make it "visible" for native listener
  }, [isAnalyzeResultsOpened])

  const docClickHandler = function() {
    isAnalyzeResultsVisible && setIsDocumentOutsideClicked(true)
  }

  useEffect(() => {
    document && document.addEventListener('click', docClickHandler)
    return () => {
      document && document.removeEventListener('click', docClickHandler)
    }
  }, [])

  useEffect(() => {
    if (isDocumentOutsideClicked) {
      setIsDocumentOutsideClicked(false) // triggers var back. the var is passed to child component before triggering back and inits list close
    }
  }, [isDocumentOutsideClicked])

  const onLocationSelect = locationId => {
    changeLocation(locationId)
  }

  const closeAnalyzeOnDocumentClick = event => {
    event.nativeEvent.stopImmediatePropagation() // prevents further event bubbling and firing of upper handlers
  }

  return (
    <LocationsSearchWrapper onClick={closeAnalyzeOnDocumentClick}>
      <SuggestLocationsWrapper>
        <SuggestedLocationsContainer
          selectLocationNextAction={onLocationSelect}
          userindex={currentPlatform}
          closeOnLocationSelect
          resetOnLocationSelect
          isAnalyzeSuggest
          id="googleLocationSearch"
          withPlaceholder
          placeholderText={labelStrings.selectLocation}
          watchChangeUserindex
          withParentHeight
          isDocumentOutsideClicked={isDocumentOutsideClicked}
          openedSuggestListInformer={changeIsAnalyzedResultsOpened}
        />
      </SuggestLocationsWrapper>
    </LocationsSearchWrapper>
  )
}

export default GoogleLocationSearchContainer
