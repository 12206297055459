import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { salesManagerSelector } from '../../redux/selectors'
import useResponsive from '../../new-ui/hooks/useResponsive'

const SignUpSuccess = props => {
  const { isStepSignup } = props
  const { calendlyLink } = useSelector(salesManagerSelector)

  const isMobile = useResponsive('down', 'sm')

  const [showCalendar, setShowCalendar] = useState(true)
  const [scriptLoaded, setScriptLoaded] = useState(false)

  useEffect(() => {
    if (!scriptLoaded) {
      const script = document.createElement('script')
      script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
      script.async = true
      script.onload = () => {
        if (window.HubspotMeetings) {
          window.HubspotMeetings.refresh()
          setScriptLoaded(true)
        }
      }
      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [scriptLoaded])

  const rendered = isStepSignup ? (
    <div
      className="meetings-iframe-container"
      data-src={calendlyLink}
      style={{ width: isMobile ? '100%' : '80%', height: isMobile ? '75%' : '80%' }}
    ></div>
  ) : (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10000,
      }}
    >
      <div
        className="meetings-iframe-container"
        data-src={calendlyLink}
        style={{ width: isMobile ? '100%' : '80%', height: isMobile ? '75%' : '80%' }}
      ></div>
      <button
        onClick={() => setShowCalendar(false)}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          background: 'white',
          border: 'none',
          borderRadius: '5px',
          padding: '5px 10px',
          cursor: 'pointer',
        }}
      >
        Close
      </button>
    </div>
  )

  return (
    <>
      {showCalendar && rendered}
      <Helmet />
    </>
  )
}

export default SignUpSuccess
