import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { RHFDateField, RHFRadioGroup } from '../../../components/hook-form'
import useTranslation from '../../../../localization/useTranslation'
import { userGrantSelector } from '../../../../redux/selectors'
import { shallowEqual, useSelector } from 'react-redux'

export const StepTwo = props => {
  const { watch } = props
  const { labelStrings } = useTranslation()

  const { allow_past_campaigns } = useSelector(userGrantSelector, shallowEqual) || {}

  const watchedValueStartAsInactive = watch('startAsInactive')

  return (
    <Stack>
      <Typography
        component="label"
        sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '24px', mb: 1 }}
      >
        {labelStrings.createCampaign.steps.two.label}
      </Typography>
      <Box sx={{ width: '370px' }}>
        <RHFRadioGroup
          name="startAsInactive"
          label=""
          sx={{ marginLeft: 1 }}
          options={[
            { value: 1, label: labelStrings.createCampaign.steps.two.optionLabels.now },
            { value: 2, label: labelStrings.createCampaign.steps.two.optionLabels.later },
            // {
            //   value: 3,
            //   label: labelStrings.createCampaign.steps.two.optionLabels.laterKeepInactive,
            //   subLabel:
            //     labelStrings.createCampaign.steps.two.optionLabels.subLabelLaterKeepInactive,
            // },
            {
              value: 4,
              label: labelStrings.createCampaign.steps.two.optionLabels.inThePast,
              subLabel: labelStrings.createCampaign.steps.two.optionLabels.subLabelInThePast,
              disabled: !allow_past_campaigns,
            },
          ]}
        />
      </Box>

      {(watchedValueStartAsInactive === '3' || watchedValueStartAsInactive === '4') && (
        <Box sx={{ py: 2 }}>
          <RHFDateField
            size="small"
            name="overwriteStartingDate"
            label={labelStrings.createCampaign.steps.two.optionLabels.selectedDate}
            minDate={watchedValueStartAsInactive === '3' ? new Date() : null}
            maxDate={watchedValueStartAsInactive === '4' ? new Date() : null}
          />
        </Box>
      )}
    </Stack>
  )
}
