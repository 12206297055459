import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import useTranslation from '../../../localization/useTranslation'
import { collectionsSelector } from '../../../redux/selectors'
import {
  addSelectedProfileToCollection,
  getAllCollections,
  removeSelectedProfileFromCollection,
} from '../../../redux/ducks/collectionsDucks'
import { FAV_COLLECTION_NAME, UNLOCKED_COLLECTION_NAME } from '../../../constants/collections'
import { USER_INDEXES } from '../../../constants/appSettings'

import { themeCommonStyles } from '../../../themes/infludataThemes'
import Modal from '../../common/modals/Modal/Modal'
import { GradientButton } from '../../common/buttons/styledButtons'
import GradientTransparentButton from '../../common/buttons/GradientTransparentButton'
import Spinner from '../../common/spinners/Spinner'
import {
  CreateCollectionButtonWrapper,
  CollectionList,
  CollectionItem,
  CollectionName,
  CollectionCount,
  ConfirmBlock,
} from './commonStyles'
import { fetchProfileById } from '../../../redux/ducks/profileSelectedDucks'
import { convertHexToRgba } from '../../../utils'

const AddToCollectionModal = ({
  profile = {},
  platform = '',
  collectionArray = [],
  closeModal,
  openCreateCollectionModal,
  setCollectionCount,
  isContent = false,
  withoutPortal = false,
  withoutFetchProfile,
}) => {
  const { labelStrings } = useTranslation()
  const dispatch = useDispatch()

  const collectionsList = useSelector(collectionsSelector)

  const [isLoading, setIsLoading] = useState(false)

  const checkIsUserInCollection = collectionId => collectionArray?.includes(collectionId)

  const hasUserCollections = collectionsList?.length > 2 // fav, unlocked are default collections

  const onCollectionClick = async (collectionId, isUserInCollection) => {
    setIsLoading(true)

    const contentId = profile.contentId

    const handler = isUserInCollection
      ? removeSelectedProfileFromCollection
      : addSelectedProfileToCollection

    try {
      const handlerResponse = await dispatch(handler(profile._id, collectionId, contentId))

      if (handlerResponse) {
        dispatch(getAllCollections())

        if (withoutFetchProfile) {
          setIsLoading(false)
        } else {
          await dispatch(
            fetchProfileById({
              profileId: profile._id,
              fetchSimilars: false,
              withoutReset: true,
              withReplaceCollectionArray: true,
            })
          )
        }
      }

      if (isContent) {
        await setCollectionCount(
          isUserInCollection
            ? collectionArray?.filter(el => el !== collectionId)
            : [...collectionArray, collectionId]
        )
      }
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      headerContent={labelStrings.addToCollection}
      platform={platform}
      closeModal={closeModal}
      withoutPortal={withoutPortal}
    >
      <CreateCollectionButtonWrapper>
        <GradientButton platform={platform} onClick={openCreateCollectionModal}>
          {labelStrings.createNewCollection}
        </GradientButton>
      </CreateCollectionButtonWrapper>

      {hasUserCollections && (
        <CollectionList>
          {isLoading && <Spinner isOverlay overlayBgColor={convertHexToRgba('#FFF9F9', 0.9)} />}
          {collectionsList?.map(collection => {
            const { collectionId, name, count } = collection
            const isUserInCollection = checkIsUserInCollection(collectionId)

            if (collectionId === FAV_COLLECTION_NAME || collectionId === UNLOCKED_COLLECTION_NAME) {
              return null
            }

            return (
              <CollectionItem
                key={collectionId}
                isUserInCollection={isUserInCollection}
                platform={platform}
                onClick={() => onCollectionClick(collectionId, isUserInCollection)}
              >
                <CollectionName platform={platform} isUserInCollection={isUserInCollection}>
                  {name}
                </CollectionName>
                <CollectionCount>{count}</CollectionCount>
              </CollectionItem>
            )
          })}
        </CollectionList>
      )}

      <ConfirmBlock>
        <GradientTransparentButton
          onClick={closeModal}
          backgroundColor={themeCommonStyles.colors.offWhite}
          {...(platform
            ? { gradient: themeCommonStyles.getGradientsForPlatform(platform).main }
            : {})}
        >
          {labelStrings.confirm}
        </GradientTransparentButton>
      </ConfirmBlock>
    </Modal>
  )
}

AddToCollectionModal.propTypes = {
  profile: PropTypes.object.isRequired,
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube]),
  collectionArray: PropTypes.array,
  closeModal: PropTypes.func,
  openCreateCollectionModal: PropTypes.func,
  setCollectionCount: PropTypes.func,
  isContent: PropTypes.bool,
  withoutPortal: PropTypes.bool,
  withoutFetchProfile: PropTypes.func,
}

export default AddToCollectionModal
