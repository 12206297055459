import React from 'react'
import PropTypes from 'prop-types'
import { ImageWrapper, Placeholder } from './styles'
import Image from './Image'

const LazyImage = ({ src, alt }) => {
  const refPlaceholder = React.useRef()

  const removePlaceholder = () => {
    refPlaceholder.current.remove()
  }

  return (
    <ImageWrapper>
      <Placeholder ref={refPlaceholder} />
      <Image
        onLoad={removePlaceholder}
        onError={removePlaceholder}
        src={src}
        alt={alt}
        loading="lazy"
        errorImg=""
        placeholderImg=""
      />
    </ImageWrapper>
  )
}

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}

export default LazyImage
