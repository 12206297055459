import React from 'react'
import NewPageLayout from '../../../../../components/influencerProfile/profilePdfDocument/PageLayout/NewPageLayout'
import { CpmCpe } from '../../cards/CpmCpe'

export const SecondPdfPage = ({ cpmOrganicData, cpeOrganicData, emvData }) => {
  return (
    <NewPageLayout>
      <CpmCpe
        cpmOrganicData={cpmOrganicData}
        cpeOrganicData={cpeOrganicData}
        emvData={emvData}
        isPdf
      />
    </NewPageLayout>
  )
}
