import React from 'react'
import ConfirmDialog from '../../../components/confirm-dialog/ConfirmDialog'
import { Button, Stack, Typography } from '@mui/material'
import useTranslation from '../../../../localization/useTranslation'

export const ArchiveModal = props => {
  const { data, handleCloseConfirm, handleArchiveRow } = props
  const { labelStrings } = useTranslation()
  return (
    <>
      <ConfirmDialog
        open={!!data?.id}
        onClose={handleCloseConfirm}
        title={`${labelStrings.campaignCreatorOverview.popoverMenu.archiveCreator} ${data?.name}`}
        content={
          <Stack spacing={2}>
            <Typography textAlign="center">{labelStrings.onceArchived}</Typography>
            <Typography textAlign="center">{labelStrings.allDataWillStillAppear}</Typography>
          </Stack>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleArchiveRow(data)
              handleCloseConfirm()
            }}
          >
            {labelStrings.campaignCreatorOverview.popoverMenu.archiveCreator}
          </Button>
        }
      />
    </>
  )
}
