import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { isUserLoggedInSelector, userGrantSelector } from '../../redux/selectors'
import { AnalyzeBarWrapper } from '../common/styledWrappers/navbarStyles'
import SuggestedCreatorsInputNavContainer from '../../new-ui/components/nav-section/CreatorsSuggestions'

const AnalyzeInlinedNavbarContainer = () => {
  const {
    enable_username_search,
    enable_ig_search,
    enable_tt_search,
    enable_yt_search,
  } = useSelector(userGrantSelector, shallowEqual)

  const isUserLoggedIn = useSelector(isUserLoggedInSelector)

  const closeAnalyzeOnDocumentClick = event => {
    event.nativeEvent.stopImmediatePropagation() // prevents further event bubbling and firing of upper handlers
  }

  return (
    <AnalyzeBarWrapper onClick={closeAnalyzeOnDocumentClick}>
      <SuggestedCreatorsInputNavContainer
        disabled={
          !enable_username_search ||
          !isUserLoggedIn ||
          (!enable_ig_search && !enable_tt_search && !enable_yt_search)
        }
      />
    </AnalyzeBarWrapper>
  )
}


export default AnalyzeInlinedNavbarContainer
