import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TableRow, TableCell, IconButton } from '@mui/material'
import Iconify from '../../components/iconify'
import { PopoverItemMenu } from './PopoverItemMenu'
import { StatusesWrapper, StyledStatus } from './styles'
import { pretifyBigNum } from '../../../utils'
import { fDate } from '../../utils/formatTime'

export const GoalTableRow = ({ row, onEditRow, onDeleteRow, handlePauseUnpause }) => {
  const {
    goalName,
    period,
    isPaused,
    goalReachedRate,
    goalLevel,
    metricLabel,
    goalAmount,
    goalId,
    goalInterval,
    goalPeriod
  } = row

  const [openPopover, setOpenPopover] = useState(null)

  const handleOpenPopover = event => {
    setOpenPopover(event.currentTarget)
  }

  const handleClosePopover = () => {
    setOpenPopover(null)
  }

  const onPauseUnpause = isPausedParam => {
    handlePauseUnpause({ goalId, isPaused: isPausedParam })
  }

  return (
    <>
      <TableRow hover>
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {goalName}
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {goalInterval} ({fDate(goalPeriod.from, 'dd.MM.yyyy')})
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {goalLevel}
        </TableCell>

        <TableCell align="center">
          <StatusesWrapper sx={{ margin: '0 auto'}}>
            <StyledStatus
              sx={{
                color: isPaused === true ? '#FF5D5A' : '#637381',
                backgroundColor: isPaused === true ? 'rgba(255, 93, 90, 0.08)' : 'transparent',
              }}
            >
              Paused
            </StyledStatus>
            <StyledStatus
              sx={{
                color: isPaused === false ? '#FF5D5A' : '#637381',
                backgroundColor: isPaused === false ? 'rgba(255, 93, 90, 0.08)' : 'transparent',
              }}
            >
              Active
            </StyledStatus>
          </StatusesWrapper>
        </TableCell>

        <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
          {metricLabel}
        </TableCell>

        <TableCell align="center">{pretifyBigNum(goalAmount)}</TableCell>

        <TableCell align="center">{Math.ceil(goalReachedRate * 100)}</TableCell>

        <TableCell align="right">
          <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <PopoverItemMenu
        openPopover={openPopover}
        handleClosePopover={handleClosePopover}
        onEditRow={onEditRow}
        onDeleteRow={onDeleteRow}
        handlePauseUnpause={onPauseUnpause}
        isPaused={isPaused}
      />
    </>
  )
}

GoalTableRow.propTypes = {
  row: PropTypes.object,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  handlePauseUnpause: PropTypes.func,
}
