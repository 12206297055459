import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  isSignUpOpenedSelector,
  isSignInOpenedSelector,
  userCredsLoadProgressSelector,
  limitsExistSelector,
  isSignUpSuccessModalOpenedSelector,
  campaignDashboard,
  userTrackSelector,
} from '../redux/selectors'
import withPageMeta from '../hocs/withPageMeta'

import NavbarContainer from '../components/navbar/NavBarContainer'
import SignUpContainer from '../components/signup/SignUpContainer'
import SignUpSuccess from '../components/signup/SignUpSuccess'

import Footer from '../components/Footer'
import { PageContentWrapper } from '../components/common/styledWrappers'
import LoadingSpinner from '../components/common/spinners/LoadingSpinner'

import LoginContainer from '../components/signin/LoginContainer'
import LimitsContainer from '../components/limits/LimitsContainer'
import { ScrollWrapper } from './styles'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { ModalContentNews } from '../new-ui/components/modal-content-news'

const PageContainer = ({ isNavbar = true, isFooter = true, children }) => {
  const { pathname } = useLocation()
  const isPageLoading = useSelector(userCredsLoadProgressSelector)
  const isSignupOpened = useSelector(isSignUpOpenedSelector)
  const isSignInOpened = useSelector(isSignInOpenedSelector)
  const isSignUpSuccessModalOpened = useSelector(isSignUpSuccessModalOpenedSelector)
  const isSomeLimitActivated = useSelector(limitsExistSelector)
  const isUserTrack = useSelector(userTrackSelector)

  const isPreviewDashboardPage = pathname === '/dashboardPreview'

  const { data: dashboardStartupData, isLoading: isLoadingDashboard } = useSelector(
    campaignDashboard
  )

  return (
    <>
      {isPageLoading && <LoadingSpinner isTransparent showOnFullPage isOuterPageLoad />}
      {isPreviewDashboardPage ? (
        <PageContentWrapper isLoading={isPageLoading}>{children}</PageContentWrapper>
      ) : (
        <PageContentWrapper isLoading={isPageLoading}>
          {isNavbar && <NavbarContainer />}
          {isSignupOpened && <SignUpContainer />}
          {isSignInOpened && <LoginContainer />}
          {isSomeLimitActivated && <LimitsContainer />}
          {isUserTrack
            ? isSignUpSuccessModalOpened &&
              dashboardStartupData?.length &&
              !isLoadingDashboard && <SignUpSuccess />
            : isSignUpSuccessModalOpened && <SignUpSuccess />}
          {pathname === '/settings' ? (
            <>{children}</>
          ) : pathname === '/new-campaigns/' ? (
            <>
              {children}
              {isFooter && <Footer />}
            </>
          ) : (
            <ScrollWrapper id="scroll-wrapper">
              {children}
              {isFooter && <Footer />}
            </ScrollWrapper>
          )}
        </PageContentWrapper>
      )}
      <ModalContentNews />
    </>
  )
}

PageContainer.propTypes = {
  isNavbar: PropTypes.bool,
  isFooter: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default withPageMeta(PageContainer)
