import styled from 'styled-components'
import { FOOTER_HEIGHT } from '../../../constants/appSettings'

export const FooterElement = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${'' /* ${props =>
    props.isFixed &&
    `
    position: fixed;
    bottom: 0px;
    left: 0px;
  `} */}
  width: 100%;
  height: ${FOOTER_HEIGHT}px;
  background: ${props => props.theme.colors.lightRed};
  position: relative;
  z-index: 1200;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

export const LinkToLanding = styled.a`
  &:hover {
    color: #888888;
    text-decoration: underline;
  }
`

export const Text = styled.span`
  display: flex;
  font-family: 'Inter';
  font-style: normal;
  padding: 12px;
  letter-spacing: 1.1px;
  color: ${props => props.theme.colors.darkBrown};
  ${props => props.left && `
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
  `}
  ${props => props.right && `
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
  `}

`

export const RowFlex = styled.div`
  ${props => props.theme.flex.centerAll}
  margin-right: 100px
`
export const LangWrapper = styled.div`
  margin: 0 20px;
`
