import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { EyeIcon, EyeSlashedIcon } from './index'

const PasswordIconWrapper = styled.div`
  position: absolute;
  top: 27px;
  right: 10px;
`

const PasswordIcon = ({ showPassword = false, toggleShowPassword, isBigIcon }) => {
  const props = {
    isActiveIcon: false,
    height: isBigIcon ? '30px' : '24px',
    width: isBigIcon ? '30px' : '24px',
    tabIcon: true,
  }
  return (
    <PasswordIconWrapper onClick={toggleShowPassword}>
      {showPassword ? <EyeIcon {...props} /> : <EyeSlashedIcon {...props} />}
    </PasswordIconWrapper>
  )
}

PasswordIcon.propTypes = {
  showPassword: PropTypes.bool,
  toggleShowPassword: PropTypes.func,
}

export default PasswordIcon
