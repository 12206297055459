import React from 'react'
import PropTypes from 'prop-types'
import { INFLUDATA_HOSTS } from '../../../constants/appSettings'
import useTranslation from '../../../localization/useTranslation'
import { StyledLinkToFaq } from './styles'

const LinkToFaq = ({ faqQuestionId = '', children = null }) => {
  const { currentLang } = useTranslation()

  let href = `${INFLUDATA_HOSTS.faq}/${currentLang}/support/solutions`
  if (faqQuestionId) href += `/articles/${faqQuestionId}`

  return (
    <StyledLinkToFaq className="link-to-faq" href={href} target="_blank">
      {children}
    </StyledLinkToFaq>
  )
}

LinkToFaq.propTypes = {
  faqQuestionId: PropTypes.string,
  children: PropTypes.node,
}

export default LinkToFaq
