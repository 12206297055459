import { GTAG_ID, GTAG_CONVERSION_LABEL } from '../constants/gaTracking'

export const trackEventWithGA = ({ eventCategory, eventAction }) => {
  if (window && window.ga) {
    try {
      window.ga('send', 'event', eventCategory, eventAction)
    } catch (err) {
      console.log(err)
    }
  }
}

export const gtagConversionAction = () => {
  if (window && window.gtag) {
    try {
      window.gtag('event', 'conversion', {
        send_to: `${GTAG_ID}/${GTAG_CONVERSION_LABEL}`,
      })
    } catch (err) {
      console.log(err)
    }
  }
}
