import styled from 'styled-components'
import { USER_INDEXES } from '../../../../constants/appSettings'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 50px 90px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
  position: relative;
`

export const DateBlock = styled.div`
  display: flex;
  flex-direction: column;
`

export const DateLabel = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: ${props => props.theme.getColorPaletteForPlatform(props.platform).main};
`

export const Date = styled.div`
  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
  color: ${props => props.theme.getColorPaletteForPlatform(props.platform).main};
`

export const PlatformIconWrapper = styled.div`
  ${props =>
    props.isBranded &&
    `position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);`}

  .icon_instagram,
  .icon_tiktok {
    width: 30px;
    height: 30px;
  }
`

export const LogoIconWrapper = styled.div`
  svg {
    g,
    path,
    rect {
      fill: ${props => props.theme.colors.darkBrown};
    }
  }
`

export const Main = styled.div`
  background-color: ${props =>
    (() => {
      switch (props.platform) {
        case USER_INDEXES.instagram:
          return props.theme.colors.lightBrown
        case USER_INDEXES.tiktok:
          return props.theme.colors.aquaUltraLight
        case USER_INDEXES.youtube:
          return props.theme.colors.lightBrown
      }
    })()};
  border-radius: 12px;
  min-width: 410px;
  min-height: 200px;
  position: relative;
  margin-top: 100px;
`

export const AvatarWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 30px;

  .rating_meter {
    circle {
      stroke-width: 7px;
    }
  }
`

export const Username = styled.div`
  font-size: 24px;
  line-height: 26px;
  font-weight: bold;
  color: ${props => props.theme.getColorPaletteForPlatform(props.platform).main};
  margin-top: 20px;
`

export const GaleryWrapper = styled.div`
  margin-top: 100px;
  padding: 10px;
`
