import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useTranslation from '../../../../localization/useTranslation'
import { selectedProfileSelector } from '../../../../redux/selectors'
import { prepareProfileChartData } from '../../../../utils/profileCharts'
import FollowersHistory from '../../followersHistory/FollowersHistory'
import TiktokStatistics from '../../tiktokStatistics/TiktokStatistics'
import PageLayout from '../PageLayout/PageLayout'
import { Section, SectionTitle } from '../commonStyles'

const TtProfileInfo = ({
  setIsFollowersChartReady,
  isBranded = true,
  isShowFollowerTrendGraph = true,
  isShowQualityScores = true,
}) => {
  const { profile } = useSelector(selectedProfileSelector)
  const { _id: profileId, _index: platform, username, dataLog, monthlyGrowthFollowers } = profile || {}

  const { labelStrings } = useTranslation()

  return (
    <PageLayout profileId={profileId} username={username} platform={platform} isBranded={isBranded}>
      {isShowFollowerTrendGraph && (
        <Section noPadding>
          <SectionTitle platform={platform}>{labelStrings.followersHistory}</SectionTitle>
          <FollowersHistory
            chartData={prepareProfileChartData(dataLog, platform).followers}
            platform={platform}
            monthlyGrowthFollowers={monthlyGrowthFollowers}
            onChartReadyCallback={() => setIsFollowersChartReady(true)}
            isForPdf
          />
        </Section>
      )}

      {isShowQualityScores && (
        <Section noPadding>
          <SectionTitle platform={platform}>{labelStrings.statistics}</SectionTitle>
          <TiktokStatistics profile={profile} isForPdf />
        </Section>
      )}
    </PageLayout>
  )
}

TtProfileInfo.propTypes = {
  setIsFollowersChartReady: PropTypes.func,
  isBranded: PropTypes.bool,
  isShowFollowerTrendGraph: PropTypes.bool,
  isShowQualityScores: PropTypes.bool,
}

export default TtProfileInfo
