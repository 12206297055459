export const USERS_PER_PAGE = 20
export const USERS_PER_PAGE_CONTENT = 50
export const NEXT_PAGE_DIRECTION = 'forward'

export const SEARCH_SLIDERS_RANGE = {
  min: 2000,
  max: Infinity,
}

export const COMMA_SPLITTER = ','
export const UNDERSCORE_SPLITTER = '_'

export const GROWTH_RATES = {
  '1%': 1,
  '2%': 2,
  '5%': 5,
  '10%': 10,
  '15%': 15,
}

export const ENGAGEMENTS_RATES = {
  '1%': 1,
  '3%': 3,
  '5%': 5,
  '8%': 8,
  '10%': 10,
  '15%': 15,
  '20%': 20,
}

export const SIGN_UP_AMOUNT_OF_INFLUENCERS = {
  'No': '0 Jahre',
  '0-1': '0-1 Jahre',
  '1-3': '1-3 Jahre',
  '3-5': '3-5 Jahre',
  '5+': '5+ Jahre',
}

export const AUDIENCE_COUNTRIES_SUBSEARCH_RATES = {
  // '0%': 0,
  '10%': 10,
  '20%': 20,
  '30%': 30,
  '40%': 40,
  '50%': 50,
  '60%': 60,
  '70%': 70,
  '80%': 80,
  '90%': 90,
}

export const AUDIENCE_REAL_PEOPLE_SUBSEARCH_RATES = {
  '45%': 45,
  '50%': 50,
  '55%': 55,
  '60%': 60,
  '65%': 65,
  '70%': 70,
  '75%': 75,
  '80%': 80,
}

export const AUDIENCE_GENDER_SUBSEARCH_RATES = {
  beforeZero: {
    '95%': `95${UNDERSCORE_SPLITTER}male`,
    '75%': `75${UNDERSCORE_SPLITTER}male`,
    '50%': `50${UNDERSCORE_SPLITTER}male`,
    '25%': `25${UNDERSCORE_SPLITTER}male`,
  },
  afterZero: {
    ' 25% ': `25${UNDERSCORE_SPLITTER}female`,
    ' 50% ': `50${UNDERSCORE_SPLITTER}female`,
    ' 75% ': `75${UNDERSCORE_SPLITTER}female`,
    ' 95% ': `95${UNDERSCORE_SPLITTER}female`,
  },
}

export const MULTI_RANGE_AUDIENCE_AGE_RANGES = {
  mapping: [0,13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35, 100],
  presentationMapping: [
    'Off',
    '13',
    '15',
    '17',
    '19',
    '21',
    '23',
    '25',
    '27',
    '29',
    '31',
    '33',
    '35',
    '>37',
  ],
}

export const searchContentTypes = {
  CREATOR: 'CREATOR',
  CONTENT: 'CONTENT'
}