import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'
import CloseButton from '../buttons/CloseButton'
import PrimaryButton from '../buttons/PrimaryButton'
import CancelPrimaryButton from '../buttons/CancelPrimaryButton'
import { DialogBluredWrapper, DialogBluredTitle } from './dialogsCommonStyles'

const Wrapper = styled(DialogBluredWrapper)`
  max-width: 489px;
  padding-left: ${props => (props.isSuccessDelete ? 8 : 10)}%;
  padding-right: ${props => (props.isSuccessDelete ? 8 : 10)}%;
  padding-bottom: ${props => (props.isSuccessDelete ? 20 : 10)}px;
  padding-top: 40px;
`

const SubTitleWrapper = styled.div`
  font-weight: bold;
  font-size: 15px;
  width: 100%;
  ${props => props.withMargin && `margin: 20px 0;`}
`

const List = styled.ul`
  padding-left: 0%;
  list-style-type: none;
  width: 100%;
`

const ListItem = styled.li`
  font-size: 15px;
`

const ButtonsWrapper = styled.div`
  ${props => props.theme.flex.centerAll}
  flex-direction: column;
`

const DeleteTipWrapper = styled.div`
  font-size: 15px;
  margin-bottom: 15px;
`

const SuccessTextWrapper = styled.div`
  font-size: 15px;
  line-height: 1;
  margin: 20px 0;
`

const DeleteMyAccountDialog = ({
  close,
  confirm,
  successDeleteAccountHandler,
  isSuccessDelete = false,
}) => {
  const { labelStrings } = useTranslation()

  return (
    <Wrapper isSuccessDelete={isSuccessDelete}>
      <DialogBluredTitle noUpperCase>{labelStrings.deleteMyAccount}</DialogBluredTitle>
      {!isSuccessDelete && <CloseButton close={close} top="14px" right="14px" />}
      {isSuccessDelete ? (
        <>
          <SuccessTextWrapper>
            <b>{labelStrings.yourAccountWasdeactivated}</b> {labelStrings.and}{' '}
            {labelStrings.willBeDeletedIn60Days}. {labelStrings.contactUsToReactivateDeletedAccount}
          </SuccessTextWrapper>
        </>
      ) : (
        <>
          <SubTitleWrapper withMargin>{labelStrings.confirmDeleteAccount}</SubTitleWrapper>
          <SubTitleWrapper>{labelStrings.weWillClearFollowingDatain60Days}</SubTitleWrapper>
          <List>
            {Object.keys(labelStrings.dataToDelete).map(key => (
              <ListItem key={key}>-&nbsp;{labelStrings.dataToDelete[key]}</ListItem>
            ))}
          </List>

          <SubTitleWrapper>{labelStrings.forLegalSecurityReasonsWeKeep}</SubTitleWrapper>
          <List>
            {Object.keys(labelStrings.dataToKeepWhenDelete).map(key => (
              <ListItem key={key}>-&nbsp;{labelStrings.dataToKeepWhenDelete[key]}</ListItem>
            ))}
          </List>

          <DeleteTipWrapper>{labelStrings.deleteAccountReactivateTip}</DeleteTipWrapper>
        </>
      )}

      <ButtonsWrapper>
        {isSuccessDelete ? (
          <>
            <PrimaryButton
              onClick={successDeleteAccountHandler}
              label={labelStrings.goToInfludataHome}
              isBig
            />
          </>
        ) : (
          <>
            <PrimaryButton onClick={confirm} label={labelStrings.deleteMyAccount} isBig />
            <CancelPrimaryButton onClick={close} />
          </>
        )}
      </ButtonsWrapper>
    </Wrapper>
  )
}

DeleteMyAccountDialog.propTypes = {
  close: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  successDeleteAccountHandler: PropTypes.func,
  isSuccessDelete: PropTypes.bool,
}

export default DeleteMyAccountDialog
