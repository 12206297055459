import React from 'react'
import PropTypes from 'prop-types'
import { SOCIAL_LINKS_PATHS } from '../../../constants/appSettings'
import { convertValueToPercentage, cutStringToMaxLength, pretifyBigNum } from '../../../utils'
import { LikeIcon, CommentIcon, EyeIcon } from '../../common/icons/icons'
import {
  YtGalleryImg,
  YtGalleryItem,
  YtGalleryItemTitle,
  YtGalleryStatistics,
  YtIconGalleryWrapper,
  YtIconSingle,
} from './styles'
import useTranslation from '../../../localization/useTranslation'

const LatestPostsYtGalleryItem = ({
  imgSrc = '',
  postLink = '',
  title = '',
  likeCount = 0,
  commentCount = 0,
  viewCount = 0,
  duration = 0,
  engagement = '',
  positiveReactions = '',
}) => {
  const { labelStrings } = useTranslation()
  const isAllItems = [viewCount, likeCount, commentCount]?.every(Boolean)
  return (
    <a href={SOCIAL_LINKS_PATHS.ytVideoLink(postLink)} target="_blank">
      <YtGalleryItem>
        <YtGalleryImg src={imgSrc} />
        <YtGalleryItemTitle>
          {cutStringToMaxLength({ str: title, maxLength: 50 })}
        </YtGalleryItemTitle>
        <YtIconGalleryWrapper isSpaceBetween={isAllItems}>
          {viewCount && (
            <YtIconSingle isMargin={isAllItems}>
              <EyeIcon color={'#784E4E'} width={20} height={18} />
              {pretifyBigNum(viewCount)}
            </YtIconSingle>
          )}
          {likeCount && (
            <YtIconSingle isMargin={isAllItems}>
              <LikeIcon color={'#784E4E'} width={20} height={18} />
              {pretifyBigNum(likeCount)}
            </YtIconSingle>
          )}
          {commentCount && (
            <YtIconSingle>
              <CommentIcon color={'#784E4E'} width={20} height={18} />
              {pretifyBigNum(commentCount)}
            </YtIconSingle>
          )}
        </YtIconGalleryWrapper>
        <YtGalleryStatistics>
          <div>
            {labelStrings.duration}: <span>{`${duration || 0} sec`}</span>
          </div>
          <div>
            {labelStrings.engagement}: <span>{`${convertValueToPercentage(engagement) || 0}`}</span>
          </div>
          {positiveReactions && (
            <div>
              {labelStrings.reactions}:{' '}
              <span>{`${convertValueToPercentage(
                positiveReactions
              )}% ${labelStrings.positive?.toUpperCase()}`}</span>
            </div>
          )}
        </YtGalleryStatistics>
      </YtGalleryItem>
    </a>
  )
}

LatestPostsYtGalleryItem.propTypes = {
  imgSrc: PropTypes.string,
  postLink: PropTypes.string,
  title: PropTypes.string,
  likeCount: PropTypes.number,
  commentCount: PropTypes.number,
  viewCount: PropTypes.number,
  duration: PropTypes.number,
  engagement: PropTypes.number,
  positiveReactions: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default LatestPostsYtGalleryItem
