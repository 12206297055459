import React from 'react'
import { Droppable, Draggable } from '@hello-pangea/dnd'
import { Paper, Stack, Button } from '@mui/material'
import { PipelineTaskCard } from '../PipelineTaskCard'
import Iconify from '../../../components/iconify/Iconify'
import PipelineColumnToolBar from './PipelineColumnToolBar'

const _COLUMN_WIDTH = 220

export const PipelineColumn = ({
  column,
  index,
  cards,
  onHandleOpenAddTaskModal,

  handleUpdateColumn,
  handleDeleteColumn,
  handleDeleteTask,
  handleUpdateTask,
  setCurrentColumn,
  handleActiveInCampaign
}) => {
  const cardBelongToColumn = Object.values(cards)?.filter(el => el.inColumnId === column.columnId)

  const handleToggleAddTask = () => {
    onHandleOpenAddTaskModal()
    setCurrentColumn(column)
  }

  return (
    <Draggable draggableId={column.columnId} index={index}>
      {provided => (
        <Paper
          {...provided.draggableProps}
          ref={provided.innerRef}
          variant="outlined"
          sx={{
            px: 1,
            borderRadius: 1,
            borderStyle: 'dashed',
            bgcolor: theme => (theme.palette.mode === 'light' ? 'grey.100' : 'background.default'),
            mb: '24px !important'
          }}
        >
          <Stack spacing={0.001} {...provided.dragHandleProps}>
            <PipelineColumnToolBar
              columnName={column?.columnName}
              columns={cardBelongToColumn}
              onDelete={handleDeleteColumn}
              onUpdate={newName => handleUpdateColumn(newName, column)}
            />
            <Droppable droppableId={column.columnId} type="TASK">
              {columnProvided => (
                <Stack
                  ref={columnProvided.innerRef}
                  {...columnProvided.droppableProps}
                  spacing={1}
                  sx={{ width: _COLUMN_WIDTH }}
                >
                  {cardBelongToColumn?.map((card, cardIndex) => (
                    <PipelineTaskCard
                      key={card.cardId}
                      index={cardIndex}
                      card={card}
                      onDeleteTask={handleDeleteTask}
                      handleUpdateTask={fields => handleUpdateTask(fields, column)}
                      handleActiveInCampaign={handleActiveInCampaign}
                    />
                  ))}
                  {columnProvided.placeholder}             
                </Stack>
              )}
            </Droppable>

            <Stack spacing={2} sx={{ py: 1 }}>
              <Button
                fullWidth
                size="large"
                color="inherit"
                // @ts-ignore
                startIcon={<Iconify icon={'eva:plus-fill'} />}
                onClick={handleToggleAddTask}
                sx={{ fontSize: 14 }}
              >
                {'Add Creator'}
              </Button>
            </Stack>
          </Stack>
        </Paper>
      )}
    </Draggable>
  )
}
