import React from 'react'
import useTranslation from '../../../../../localization/useTranslation'
import { YT_SCORE_NAMES } from '../../../../../constants/profile'
import { USER_INDEXES } from '../../../../../constants/appSettings'
import InfludataRating from '../../../../qualityScores/InfludataRating'
import ComparisonToSimilarInfluencers from '../../../../qualityScores/ComparisonToSimilarInfluencers'
import RatingDescriptionTip from '../../ratingDescriptionTip/RatingDescriptionTip'
import NoDataMessage from '../../NoDataMessage'
import { pretifyBigNum } from '../../../../../utils'
import {
  SectionTitleWrapper,
  SectionTitleName,
  SectionTitleBorder,
} from '../../../commonProfilePageStyles'
import {
  RatingSectionContainer,
  RatingSectionGrid,
  RatingSectionGridItem,
  RatingTitleWithScoreWrapper,
  RatingTitleWrapper,
  RatingTitle,
} from '../../commonStyles'

const AudienceEngagementShorts = ({ platform, profileScores }) => {
  const { labelStrings, scoreStrings } = useTranslation()
  const ytScoreStrings = scoreStrings[USER_INDEXES.youtube]

  const {
    likeEngagementShortsObject,
    commentEngagementShortsObject,
    engagementShortsObject,
    averageDurationShortsObject,
    viewsPerShortObject,
    shortsViewChangeObject
  } = profileScores

  const formatLabel = label => (!!label && !Number.isNaN(+label) ? pretifyBigNum(+label) : label)

  const scoresArr = [
    {
      name: YT_SCORE_NAMES.shortsLikes,
      data: likeEngagementShortsObject,
      label: labelStrings.nOfViewers(likeEngagementShortsObject?.label),
    },
    {
      name: YT_SCORE_NAMES.shortsComments,
      data: commentEngagementShortsObject,
      label: labelStrings.nComments100000Followers(formatLabel(commentEngagementShortsObject?.label)),
    },
    {
      name: YT_SCORE_NAMES.shortsEngagement,
      data: engagementShortsObject,
      label: labelStrings.nOfViewers(engagementShortsObject?.label),
    },
    {
      name: YT_SCORE_NAMES.shortsViewsTotal,
      data: viewsPerShortObject,
      label: labelStrings.nViewsOnAverage(viewsPerShortObject?.label),
    },
    {
      name: YT_SCORE_NAMES.shortsViewsGrowth,
      data: shortsViewChangeObject,
      label: labelStrings.nVideoViewsGrowth(shortsViewChangeObject?.label),
    },
    {
      name: YT_SCORE_NAMES.shortsAverageDuration,
      data: averageDurationShortsObject,
      label: labelStrings.nSecondsOnAverage(averageDurationShortsObject?.label),
    }
  ]

  const scoreItemsCount = scoresArr.reduce(
    (currentCount, scoreItem) => (!!scoreItem.data ? currentCount + 1 : currentCount),
    0
  )

  return (
    <RatingSectionContainer>
      <SectionTitleWrapper>
        <SectionTitleName platform={platform}>{labelStrings.audienceEngagementShorts}</SectionTitleName>
        <SectionTitleBorder />
      </SectionTitleWrapper>

      {!scoreItemsCount && <NoDataMessage />}

      <RatingSectionGrid columnsCount={3}>
        {scoresArr.map((scoreItem, index) => {
          const { name: scoreName, label, data: scoreData } = scoreItem
          const { benchmarkArray, score, xAxisHigh, xAxisMid, xAxisLow } = scoreData || {
            benchmarkArray: [],
            score: 0,
            xAxisHigh: null,
            xAxisLow: null,
            xAxisMid: null,
          }
          const xAxisValues = [xAxisHigh, xAxisMid, xAxisLow]

          if (!scoreData) return null

          return (
            <RatingSectionGridItem key={index}>
              <RatingTitleWithScoreWrapper>
                <RatingTitleWrapper>
                  <RatingTitle>{ytScoreStrings[scoreName].title}</RatingTitle>
                  <RatingDescriptionTip scoreDesc={ytScoreStrings[scoreName].description} />
                </RatingTitleWrapper>

                <InfludataRating
                  scoreValue={score}
                  platform={platform}
                  size={50}
                  isTitleHidden
                  isOutOfHidden
                />
              </RatingTitleWithScoreWrapper>

              <ComparisonToSimilarInfluencers
                label={label}
                benchmarkArray={benchmarkArray}
                xAxisValues={xAxisValues}
              />
            </RatingSectionGridItem>
          )
        })}
      </RatingSectionGrid>
    </RatingSectionContainer>
  )
}

export default AudienceEngagementShorts
