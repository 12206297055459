import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Stack, Avatar, Typography, MenuItem, IconButton } from '@mui/material'
import { fToNow } from '../../../utils/formatTime'
import Image from '../../../components/image/Image'
import Lightbox from '../../../components/lightbox/Lightbox'
import MenuPopover from '../../../components/menu-popover/MenuPopover'
import Iconify from '../../../components/iconify/Iconify'

export const PipelineDetailsCommentList = ({ comments, onRemoveComment }) => {
  const [selectedImage, setSelectedImage] = useState(-1)
  const [openPopover, setOpenPopover] = useState(null)
  const [currComment, setCurrComment] = useState(null)

  const handleOpenPopover = event => {
    setOpenPopover(event.currentTarget)
  }

  const handleClosePopover = () => {
    setOpenPopover(null)
    setCurrComment(null)
  }

  const imagesLightbox = comments
    .filter(comment => comment.messageType === 'image')
    .map(item => ({ src: item.message }))

  const handleOpenLightbox = imageUrl => {
    const imageIndex = imagesLightbox.findIndex(image => image.src === imageUrl)
    setSelectedImage(imageIndex)
  }

  const handleCloseLightbox = () => {
    setSelectedImage(-1)
  }

  return (
    <>
      <Stack
        spacing={3}
        sx={{
          py: 3,
          px: 2.5,
          bgcolor: 'background.neutral',
        }}
      >
        {comments?.map(comment => (
          <Stack
            key={comment?.commentId}
            justifyContent="space-between"
            alignItems="center"
            direction="row"
            spacing={2}
          >
            <Stack direction="row" spacing={2}>
              <Avatar src={comment.avatar} />

              <Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="subtitle2">{comment.formattedName || 'Unknown'}</Typography>

                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    {fToNow(comment?.uploaded)}
                  </Typography>
                </Stack>

                {comment.messageType === 'image' ? (
                  <Image
                    alt={comment?.commentText}
                    src={comment?.commentText}
                    onClick={() => handleOpenLightbox(comment?.commentText)}
                    sx={{
                      mt: 1,
                      borderRadius: 1,
                    }}
                  />
                ) : (
                  <Typography variant="body2" sx={{ mt: 0.5 }}>
                    {comment?.commentText}
                  </Typography>
                )}
              </Stack>
            </Stack>

            <IconButton
              color={openPopover ? 'inherit' : 'default'}
              onClick={event => {
                handleOpenPopover(event)
                setCurrComment(comment)
              }}
            >
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </Stack>
        ))}
      </Stack>

      <MenuPopover open={openPopover} onClose={handleClosePopover} arrow="right-top">
        <MenuItem
          onClick={() => {
            onRemoveComment({
              commentId: currComment?.commentId,
            })
            handleClosePopover()
          }}
        >
          Delete Comment
        </MenuItem>
      </MenuPopover>

      <Lightbox
        index={selectedImage}
        slides={imagesLightbox}
        open={selectedImage >= 0}
        close={handleCloseLightbox}
      />
    </>
  )
}

PipelineDetailsCommentList.propTypes = {
  comments: PropTypes.array,
}
