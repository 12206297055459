import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import { Box, List, ListItemText, Tooltip } from '@mui/material'
import {
  StyledNavCategory,
  StyledNavItem,
  StyledNavItemIcon,
  styledConfigMinimalNavItem,
  styledConfigMinimalNavText,
} from './styles'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import useTranslation from '../../../localization/useTranslation'
import { userGrantSelector } from '../../../redux/selectors'
import { shallowEqual, useSelector } from 'react-redux'
import {
  AirtableViewIcon,
  CampaignSettingsIcon,
  ContentOverviewIcon,
  CalendarIcon,
  CreatorOverviewIcon,
  CustomFieldsIcon,
  DashboardIcon,
  DataEnrichmentIcon,
  GoalsIcon,
  KanbanIcon,
  KeywordsMentionsIcon,
  NotificationsIcon,
} from '../../assets/icons/navbar'

NavSection.propTypes = {
  data: PropTypes.array,
  disabledLinks: PropTypes.bool,
  navType: PropTypes.string,
}

export default function NavSection({ dashboardId, disabledLinks, navType, ...other }) {
  const { labelStrings } = useTranslation()
  const { campaign_pipelines, campaign_calendar, campaign_custom_fields } =
    useSelector(userGrantSelector, shallowEqual) || {}

  const isNavDefault = navType === 'default'

  const navConfigCampaign = useMemo(
    () => [
      {
        title: 'dashboard',
        path: 'dashboard',
        icon: <DashboardIcon />,
        disabled: false,
      },
      {
        title: 'content overview',
        smallTitle: 'content',
        path: 'content-overview',
        icon: <ContentOverviewIcon />,
        disabled: false,
      },
      {
        title: 'calendar',
        path: 'calendar',
        icon: <CalendarIcon />,
        disabled: false/*!campaign_calendar*/,
      },
      {
        title: 'creator pipelines',
        smallTitle: 'pipelines',
        path: 'pipeline',
        icon: <KanbanIcon />,
        disabled: false/*!campaign_pipelines*/,
      },
    ],
    [campaign_calendar, campaign_pipelines]
  )
  const navConfigManagement = useMemo(
    () => [
      {
        title: 'creator manager & overview',
        smallTitle: 'creator',
        path: 'creator-overview',
        icon: <CreatorOverviewIcon />,
        disabled: false,
      },
      {
        title: 'keywords & mentions manager',
        smallTitle: 'keywords',
        path: 'keywords-mentions-manager',
        icon: <KeywordsMentionsIcon />,
        disabled: false,
      },
      {
        title: 'goals',
        path: 'goals',
        icon: <GoalsIcon />,
        disabled: false,
      },
      {
        title: 'custom fields',
        path: 'custom-fields',
        icon: <CustomFieldsIcon />,
        disabled: false/*!campaign_custom_fields*/,
      },
      {
        title: 'data enrichment',
        path: 'data-enrichment',
        icon: <DataEnrichmentIcon />,
        disabled: true,
      },
    ],
    [campaign_custom_fields]
  )
  const navConfigReportingAndExport = useMemo(
    () => [
      {
        title: 'Airtable View',
        smallTitle: 'airtable',
        path: 'airtable-view',
        icon: <AirtableViewIcon />,
        disabled: false,
      },
    ],
    []
  )
  const navConfigSettings = useMemo(
    () => [
      {
        title: 'notifications',
        path: 'notifications',
        icon: <NotificationsIcon />,
        disabled: false,
      },
      {
        title: 'campaign settings',
        smallTitle: 'settings',
        path: 'campaign-settings',
        icon: <CampaignSettingsIcon />,
        disabled: false,
      },
    ],
    []
  )

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        <Box>
          {isNavDefault && <StyledNavCategory>{labelStrings.campaignOverview}</StyledNavCategory>}
          {navConfigCampaign.map(item => {
            return item.path === 'dashboard' ? (
              <NavItem
                key={item.path}
                item={{ ...item, path: `${item.path}?dashboardId=${dashboardId}` }}
                disabled={disabledLinks ? disabledLinks : item.disabled}
                isNavDefault={isNavDefault}
              />
            ) : (
              <Tooltip
                key={item.path}
                title={item.disabled ? labelStrings.notIncludedInYourCurrentPlan : ''}
                arrow
              >
                <Box>
                  <NavItem
                    // key={item.path}
                    item={item}
                    disabled={disabledLinks ? disabledLinks : item.disabled}
                    isNavDefault={isNavDefault}
                  />
                </Box>
              </Tooltip>
            )
          })}
        </Box>
        <Box>
          {isNavDefault && <StyledNavCategory>Management</StyledNavCategory>}
          {navConfigManagement.map(item => (
            <Tooltip
              key={item.path}
              title={item.disabled ? labelStrings.notIncludedInYourCurrentPlan : ''}
              arrow
            >
              <Box>
                <NavItem
                  // key={item.path}
                  item={item}
                  disabled={disabledLinks ? disabledLinks : item.disabled}
                  isNavDefault={isNavDefault}
                />
              </Box>
            </Tooltip>
          ))}
        </Box>
        <Box>
          {isNavDefault && <StyledNavCategory>Reporting and Export</StyledNavCategory>}
          {navConfigReportingAndExport.map(item => (
            <NavItem
              key={item.path}
              item={item}
              disabled={disabledLinks ? disabledLinks : item.disabled}
              isNavDefault={isNavDefault}
            />
          ))}
        </Box>
        <Box>
          {isNavDefault && <StyledNavCategory>Settings</StyledNavCategory>}
          {navConfigSettings.map(item => (
            <NavItem
              key={item.path}
              item={item}
              disabled={disabledLinks ? disabledLinks : item.disabled}
              isNavDefault={isNavDefault}
            />
          ))}
        </Box>
      </List>
    </Box>
  )
}

NavItem.propTypes = {
  item: PropTypes.object,
  disabled: PropTypes.bool,
  isNavDefault: PropTypes.bool,
}

function NavItem({ item, disabled, isNavDefault }) {
  const { title, path, icon, smallTitle } = item
  const match = useRouteMatch()

  const navURL = match.url?.endsWith('/') ? `${match.url}${path}` : `${match.url}/${path}`

  const titleText = isNavDefault ? title : smallTitle || title

  return (
    <StyledNavItem
      component={RouterLink}
      to={navURL}
      disabled={disabled}
      sx={!isNavDefault ? styledConfigMinimalNavItem : null}
      is_minimal_nav={!isNavDefault ? 'true' : 'false'}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText
        disableTypography
        primary={titleText}
        sx={!isNavDefault ? styledConfigMinimalNavText : null}
      />
    </StyledNavItem>
  )
}
