import styled from 'styled-components'

export const SortContainer = styled.div`
  display: flex;
  width: ${props => props.isFullWidth ? '100%' : '295px'};
  ${props => props.isFullWidth && `justify-content: space-between`};
  height: ${props => !props.isContent && '50px'};
  align-items: center;
  padding: ${props => !props.isContent && '15px 15px 10px 25px'};
`
export const SortInnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
export const SortLabelSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
export const SortByLabel = styled.span`
  font-family: ${props => props.theme.fontFamilyInter};
  font-size: 13px;
  color: ${props => props.theme.colors.mauve};
  margin-right: 5px;
  ${props => props.isDisabled && 'opacity: 0.5;'}
`
export const SelectWrapper = styled.div`
  width: 170px;
  & .creatable-multi-select__control {
    border-radius: 10px;
    min-height: unset;
    height: 35px;
    flex-wrap: unset;
    & * {
      font-family: ${props => props.theme.fontFamilyInter};
      font-size: 13px;
    }
  }
  & .creatable-multi-select__control--is-focused {
    // border-color: transparent !important;
    // box-shadow: none !important;
  }
  ${props => props.isDisabled && props.theme.disabled}
`
export const SortDirectionsWrapper = styled.div`
  ${props => props.theme.flex.centerAll}
  ${props => props.isDisabled && props.theme.disabled}
`
export const SortDirectionIconWrapper = styled.div`
  ${props =>
    props.isAscDirection &&
    `
    transform: rotateZ(180deg);
    position: relative;
  `}
  ${props => 
    props.isActive && 
      `background-color: ${props.theme.getColorPaletteForPlatform(props.userindex).main};
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      line-height: normal;
    `}
`
