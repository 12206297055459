import { useEffect } from 'react'
import { isSignUpOpenedSelector } from '../redux/selectors'
import { useSelector, useDispatch } from 'react-redux'
import { toggleSignUp } from '../redux/ducks/appSettingsDucks'

const CampaignsTrial = () => {
  const isSignupOpened = useSelector(isSignUpOpenedSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isSignupOpened) {
      dispatch(toggleSignUp()) // dispatch action to open sign up modal
    }
  }, [dispatch, isSignupOpened])

  return null
}

export default CampaignsTrial
