import React from 'react'
import PropTypes from 'prop-types'
import ProfilePic from './ProfilePic'
import RatingMeter from '../../RatingMeter'
import styled from 'styled-components'
import { convertHexToRgba } from '../../../utils'

const Container = styled.div`
  position: relative;
  ${props => props.theme.flex.centerAll};
`

const Overlay = styled.div`
  position: absolute;
  width: ${props => props.dimensions.sizeOuter}px;
  height: ${props => props.dimensions.sizeOuter}px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const OverlayInner = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.dimensions.sizeInner}px;
  height: ${props => props.dimensions.sizeInner}px;
  font-family: ${props => props.theme.fontFamilyInter};
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: ${props => props.theme.colors.white};
  background: ${props =>
    convertHexToRgba(props.theme.getColorPaletteForPlatform(props.userindex).main, 0.7)};
  border-radius: 100%;
  opacity: 0;
`

const ProfileAvatar = ({
  score = 0,
  profilePicUrl = '',
  userindex = '',
  withRating = false,
  withOverlay = false,
  size = 70,
  withGradient = false,
  withShadow = true,
  unfilledAreaBgColor = '',
  animated = true,
  isForPdf
}) => {
  const overlayOffset = size * 0.2
  const overlayDimensions = {
    sizeInner: size,
    sizeOuter: size + overlayOffset,
    posOffset: overlayOffset / 2,
  }

  let displayedScore
  if (!score) {
    displayedScore = 0
  } else {
    displayedScore = score === 10 || score === 0 ? score : score.toFixed(1)
  }

  return (
    <Container className="profile-avatar_container">
      <ProfilePic
        profilePicUrl={profilePicUrl}
        size={size - 3}
        userindex={userindex}
        withShadow={withShadow}
        isForPdf={isForPdf}
      />
      <>
        {withRating && (
          <RatingMeter
            value={score}
            size={size}
            userindex={userindex}
            withGradient={withGradient}
            unfilledAreaBgColor={unfilledAreaBgColor}
            animated={animated}
          />
        )}
        {withOverlay && (
          <Overlay dimensions={overlayDimensions} className="overlay">
            <OverlayInner
              userindex={userindex}
              dimensions={overlayDimensions}
              className="overlay-inner"
            >
              {displayedScore}
            </OverlayInner>
          </Overlay>
        )}
      </>
    </Container>
  )
}

ProfileAvatar.propTypes = {
  score: PropTypes.number,
  profilePicUrl: PropTypes.string,
  userindex: PropTypes.string,
  withOverlay: PropTypes.bool,
  withRating: PropTypes.bool,
  size: PropTypes.number,
  withGradient: PropTypes.bool,
  withShadow: PropTypes.bool,
  unfilledAreaBgColor: PropTypes.string,
  animated: PropTypes.bool,
  isForPdf: PropTypes.bool
}

export default ProfileAvatar
