import React, { useEffect, useState } from 'react'
import { ModalComponent } from '../../components/modal/ModalComponent'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { goalSchema } from './Form/FormSchema.js'
import { Fields } from './Form/Fields'
import useTranslation from '../../../localization/useTranslation'

export const EditGoal = props => {
  const { openEditGoalModal, setOpenEditGoalModal, editGoal, setEditGoal, handleGoal } = props

  const { labelStrings } = useTranslation()

  const [selectedGoal, setSelectedGoal] = useState(null)

  const methods = useForm({
    resolver: yupResolver(goalSchema),
    defaultValues: {
      goalName: '',
      goalLevel: '',
      isPaused: '',
      goalInterval: '',
      isCreatorIndividual: '',
      goalAmount: '',
      connectedCustomField: '',
    },
  })

  const { handleSubmit, reset, watch } = methods

  const handleClose = () => {
    reset({
      goalName: '',
      goalLevel: '',
      isPaused: '',
      goalInterval: '',
      isCreatorIndividual: '',
      goalAmount: '',
      connectedCustomField: '',
    })
    setOpenEditGoalModal(false)
    setEditGoal(null)
    setSelectedGoal(null)
  }

  const onSubmit = data => {
    handleGoal({
      ...data,
      method: editGoal?.goalId ? 'change' : 'add',
      goalId: editGoal?.goalId,
      goalAmount: data.goalAmount ? Number(data.goalAmount) : 0,
      connectedToFieldId: data.connectedCustomField,
    })
    handleClose()
  }

  useEffect(() => {
    reset(editGoal)
  }, [editGoal])

  return (
    <ModalComponent
      isOpenModal={openEditGoalModal}
      closeModal={handleClose}
      title={
        editGoal?.goalId
          ? labelStrings.campaignsGoal.editGoal
          : labelStrings.campaignsGoal.createGoal
      }
      contentWidth="789px"
    >
      <Fields
        onSubmit={onSubmit}
        editGoal={editGoal}
        handleSubmit={handleSubmit}
        methods={methods}
        handleClose={handleClose}
        setSelectedGoal={setSelectedGoal}
        selectedGoal={selectedGoal}
        watchValue={watch}
      />
    </ModalComponent>
  )
}
