import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

const WithScrollToTop = ({ children }) => {
  const location = useLocation()
  const { pathname } = location
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return children
}

WithScrollToTop.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default WithScrollToTop
