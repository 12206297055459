import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import useOutsideClick from '../../../../hooks/useOutsideClick'
import useTranslation from '../../../../localization/useTranslation'
import { USER_INDEXES } from '../../../../constants/appSettings'
import { themeCommonStyles } from '../../../../themes/infludataThemes'
import { PinIcon, EditIcon, DeleteIcon } from '../../../common/icons/icons'
import {
  PrivateNoteActionsOverlay,
  PrivateNoteAction,
  PrivateNoteActionText,
  IconWrapper,
} from './styles'

const PrivateNoteActions = ({
  noteId = '',
  isPinned = false,
  platform = '',
  closeOverlay,
  onPinClick,
  onEditClick,
  onDeleteClick,
}) => {
  const { labelStrings } = useTranslation()

  const overlayRef = useRef(null)
  useOutsideClick({
    ref: overlayRef,
    callback: event => {
      if (!event.target.closest(`#note-${noteId}__open-actions`)) {
        closeOverlay()
      }
    },
  })

  const [color1, color2] = themeCommonStyles.getMainGradientColorsForPlatform(platform)

  return (
    <PrivateNoteActionsOverlay ref={overlayRef}>
      <PrivateNoteAction onClick={onPinClick}>
        <IconWrapper>
          <PinIcon gradientColor1={color1} gradientColor2={color2} />
        </IconWrapper>
        <PrivateNoteActionText>
          {isPinned ? labelStrings.unpinNote : labelStrings.pinNote}
        </PrivateNoteActionText>
      </PrivateNoteAction>
      <PrivateNoteAction onClick={onEditClick}>
        <IconWrapper>
          <EditIcon gradientColor1={color1} gradientColor2={color2} />
        </IconWrapper>
        <PrivateNoteActionText>{labelStrings.editNote}</PrivateNoteActionText>
      </PrivateNoteAction>
      <PrivateNoteAction onClick={onDeleteClick}>
        <IconWrapper>
          <DeleteIcon gradientColor1={color1} gradientColor2={color2} />
        </IconWrapper>
        <PrivateNoteActionText>{labelStrings.deleteNote}</PrivateNoteActionText>
      </PrivateNoteAction>
    </PrivateNoteActionsOverlay>
  )
}

PrivateNoteActions.propTypes = {
  noteId: PropTypes.string,
  isPinned: PropTypes.bool,
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  closeOverlay: PropTypes.func,
  onPinClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
}

export default PrivateNoteActions
