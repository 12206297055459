import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { GoogleMap, useLoadScript, useGoogleMap, OverlayView } from '@react-google-maps/api'
import { mapStyles } from '../../../../components/profileCommon/profileListTable/profileContentUser/mapStyles'
import { format } from 'date-fns'
import {
  Card,
  CardHeader,
  Typography,
  Box,
  Button,
  Grid,
  Tooltip,
  Avatar,
  CardActions,
  lighten,
  Stack,
} from '@mui/material'
import useTranslation from '../../../../localization/useTranslation'

const HEIGHT = 360
const DEFAULT_ZOOM = 4

export const MapChart = ({ title, subheader = '', chart, height = '464px', ...other }) => {
  const { labelStrings, currentLang } = useTranslation()
  const { data: contentForMap, colors } = chart
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })

  //add custom buttons for the zoom-in/zoom-out on the map
  const CustomZoomControl = () => {
    const map = useGoogleMap()
    const [value, setValue] = useState(DEFAULT_ZOOM)

    const handleZoomUpdate = (zoomChange = 1) => {
      const nextZoom = value + zoomChange
      if (nextZoom < 0) {
        return
      }
      setValue(nextZoom)
      map.setZoom(nextZoom)
    }

    return (
      <>
        <Grid
          container
          direction="column"
          alignItems="flex-end"
          justifyContent="flex-end"
          sx={{ p: 2, height: '100%' }}
        >
          <Button
            className="py-0"
            variant="contained"
            sx={{
              color: colors[2],
              fontSize: '25px',
              fontWeight: 500,
              minWidth: 40,
              height: 26,
              boxShadow: '0px 8px 16px 0px rgba(255, 93, 90, 0.25);',
            }}
            size="small"
            onClick={() => handleZoomUpdate(1)}
          >
            +
          </Button>
          <Button
            className="py-0"
            variant="contained"
            sx={{
              color: colors[2],
              fontSize: '25px',
              fontWeight: 500,
              minWidth: 40,
              height: 26,
              mt: 1,
              boxShadow: '0px 8px 16px 0px rgba(255, 93, 90, 0.25);',
            }}
            size="small"
            onClick={() => handleZoomUpdate(-1)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path fill="none" stroke="currentColor" strokeWidth="1.5" d="M6 12h12"></path>
            </svg>
          </Button>
        </Grid>
      </>
    )
  }

  const [activeMarker, setActiveMarker] = useState({})
  const [activeProfile, setActiveProfile] = useState(null)

  const onHandleActive = (e, el) => {
    setActiveProfile(el)
    const lat = e.latLng?.lat()
    const lng = e.latLng?.lng()
    setActiveMarker({ lat, lng })
  }

  const handleMouseOut = () => {
    setActiveMarker({})
  }

  const handleClickScroll = el => {
    setActiveMarker({ lat: el.locationArray[1], lng: el.locationArray[0] })
  }

  const getColorPoint = contentType => {
    let colorPoint = ''
    switch (contentType) {
      case 'story':
      case 'story_group':
        colorPoint = colors[1]
        break
      case 'reel':
        colorPoint = colors[5]
        break
      case 'post':
        colorPoint = colors[0]
        break
      case 'youtube':
        colorPoint = colors[7]
      default:
        colorPoint = colors[6]
    }
    return colorPoint
  }

  const [currPos, setCurrPos] = useState({
    lat: 0,
    lng: 0,
  })

  const mapContainerStyle = {
    height: HEIGHT + 'px',
    width: '100%',
    borderRadius: '8px',
  }

 

  useEffect(() => {
    let sumLat = 0;
    let sumLng = 0;
    contentForMap.forEach(item => {
        sumLat += item.locationArray[1];
        sumLng += item.locationArray[0];
    });
    const avgLat = sumLat / contentForMap.length;
    const avgLng = sumLng / contentForMap.length;

    setCurrPos({
        lat: avgLat,
        lng: avgLng,
    });
}, [contentForMap]);

  const Markers = () =>
    contentForMap?.map((el, idx) => {
      const id = el.contentId
      const lat = el.locationArray[1]
      const lng = el.locationArray[0]
      const typeLabel = el.platform.charAt(0).toUpperCase() + el.platform.slice(1) + ' ' + el.contentType.charAt(0).toUpperCase() + el.contentType.slice(1)

      const colorPoint = getColorPoint(el?.contentType)
      return (
        <OverlayView
          key={`${id}-${idx}`}
          position={{ lat, lng }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={(width, height) => ({
            x: -(width / 2),
            y: -(height / 2),
          })}
        >
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'white',
                },
              },
              arrow: {
                sx: {
                  color: 'white',
                },
              },
            }}
            arrow
            title={
              <Card sx={{ minWidth: 190, p: 0, boxShadow: 'none' }}>
                <Typography sx={{ fontSize: 14 }} variant={'subtitle2'} align={'center'}>
                  {typeLabel}
                </Typography>
                <Stack alignItems="center" direction="row" sx={{ p: 1 }}>
                  <Avatar alt={activeProfile?.platform} src={activeProfile?.profilePicUrl} />
                  <Stack alignItems="center" justifyContent="end" sx={{ ml: 1.5 }}>
                    <Typography
                      sx={{ fontSize: 14, width: '100%' }}
                      variant={'subtitle2'}
                      align="left"
                    >
                      {activeProfile?.username}
                    </Typography>
                    {activeProfile?.uploaded && (
                      <Typography
                        sx={{ fontSize: 12, color: colors[3], fontWeight: 400, width: '100%' }}
                        align="left"
                      >
                        Posted {format(new Date(activeProfile?.uploaded), 'do MMM yyyy')}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                <CardActions>
                 {el.contentType !== 'story' && (
                  <Button
                    onClick={() => {
                      window.open(
                        `https://www.${activeProfile?.platform}.com/${el.contentType === 'reel' ? 'reel' : 'p'}/${activeProfile?.contentId}`,
                        '_blank'
                      )
                    }}
                    sx={{
                      '&:hover': {
                        boxShadow: 'none',
                        color: 'white',
                      },
                      color: 'black',
                      backgroundColor: colors[4],
                      py: currentLang === 'de' ? 3 : 2,
                    }}
                    size="small"
                    variant="contained"
                  >
                    {labelStrings.geolocationMapClickToOpenInNewTab}
                  </Button>
                  )}
                </CardActions>
              </Card>
            }
          >
            <Box
              sx={{
                borderRadius: '50%',
                backgroundColor: colorPoint,
                height: 15,
                width: 15,
                '&:hover': { backgroundColor: lighten(colorPoint, 0.2) },
              }}
              onMouseOut={() => handleMouseOut()}
              onMouseOver={e => onHandleActive(e, el)}
              onClick={() => handleClickScroll(el)}
            />
          </Tooltip>
        </OverlayView>
      )
    })

  if (loadError) return <Grid>Error loading maps</Grid>
  if (!isLoaded) return <Grid>Loading maps</Grid>

  return (
    <Card {...other} sx={{ height }}>
      <CardHeader
        title={title}
        subheader={
          <Typography sx={{ fontWeight: 700, fontSize: '14px', lineHeight: '28px' }}>
            {subheader}
          </Typography>
        }
      />

      <Box sx={{ px: 2, pt: 1 }}>
        <GoogleMap
          center={currPos}
          zoom={DEFAULT_ZOOM}
          mapContainerStyle={mapContainerStyle}
          options={{
            styles: mapStyles,
            panControl: false,
            keyboardShortcuts: false,
            disableDefaultUI: true,
            fullscreenControl: false,
            zoomControl: false,
            mapTypeControl: false,
          }}
        >
          <>
            <CustomZoomControl />
            {Markers()}
          </>
        </GoogleMap>
      </Box>
    </Card>
  )
}

MapChart.propTypes = {
  chart: PropTypes.object,
  title: PropTypes.string,
}
