import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TableRow, TableCell, IconButton } from '@mui/material'
import Label from '../../components/label'
import Iconify from '../../components/iconify'
import { PopoverItemMenu } from './PopoverItemMenu'
import { format } from 'date-fns'
import { pretifyBigNum } from '../../../utils'

export const FieldTableRow = ({
  row,
  onEditRow,
  onDeleteRow,
  setOpenAddValueModal,
  onHandleCurrentRow,
  setOpenWebHook,
}) => {
  const {
    customFieldName,
    currentValue,
    applyLevel,
    trackingMethod,
    latestActivity,
    metricName,
  } = row

  const [openPopover, setOpenPopover] = useState(null)

  const handleOpenPopover = event => {
    setOpenPopover(event.currentTarget)
  }

  const handleClosePopover = () => {
    setOpenPopover(null)
  }

  return (
    <>
      <TableRow hover>
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {customFieldName}
        </TableCell>

        <TableCell align="left">{pretifyBigNum(currentValue)}</TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>{applyLevel}</TableCell>

        <TableCell align="left">
          <Label
            variant="soft"
            color={(trackingMethod === 'manual' && 'secondary') || 'success'}
            sx={{ textTransform: 'capitalize' }}
          >
            {trackingMethod}
          </Label>
        </TableCell>

        <TableCell align="left">{latestActivity ? format(new Date(latestActivity), 'MMM do, yyyy') : '-'}</TableCell>

        <TableCell align="left">{metricName}</TableCell>

        <TableCell align="right">
          <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <PopoverItemMenu
        openPopover={openPopover}
        handleClosePopover={handleClosePopover}
        onEditRow={onEditRow}
        onDeleteRow={onDeleteRow}
        setOpenAddValueModal={setOpenAddValueModal}
        applyLevel={applyLevel}
        trackingMethod={row?.trackingMethod}
        onHandleCurrentRow={onHandleCurrentRow}
        setOpenWebHook={setOpenWebHook}
        rowData={row}
      />
    </>
  )
}

FieldTableRow.propTypes = {
  row: PropTypes.object,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  setOpenAddValueModal: PropTypes.func,
  onHandleCurrentRow: PropTypes.func,
  setOpenWebHook: PropTypes.func,
}
