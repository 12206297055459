import styled from 'styled-components'

export const TextareaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 15px 0;
`

export const Label = styled.label`
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  padding-left: 15px;
  margin-bottom: 5px;
  color: ${props => (props.hasError ? props.theme.colors.salmon : props.theme.colors.darkBrown)};
`

export const StyledTextarea = styled.textarea`
  width: 100%;
  height: 100%;
  font-size: 15px;
  line-height: 19px;
  padding: 10px 15px;
  border: 1px solid
    ${props => (props.hasError ? props.theme.colors.salmon : props.theme.colors.darkBrown)};
  color: ${props => props.theme.colors.brown};
  border-radius: 13px;
  outline: none;
  resize: none;

  &::placeholder {
    font-size: 15px;
    line-height: 19px;
    color: ${props => props.theme.colors.mediumBrown};
  }
`

export const ErrorMessage = styled.div`
  font-size: 12px;
  line-height: 15px;
  padding-left: 10px;
  padding-top: 5px;
  color: ${props => props.theme.colors.salmon};
`
