export const byOneMonth = new Date()
byOneMonth.setMonth(byOneMonth.getMonth() - 1)

export const byThreeMonth = new Date()
byThreeMonth.setMonth(byThreeMonth.getMonth() - 3)

export const filteredTimestamps = ({ data, desiredMonth }) => {
  return data.filter(item => new Date(item.timestamp) >= desiredMonth)
}

export const hasImage = link => {
  if (!link) return false
  return link?.split('.')[0]?.includes('https://infludata-content')
}
export const scrollToAnchor = anchorId => {
  const target = document.getElementById(anchorId)
  if (target) {
    target.scrollIntoView({ behavior: 'smooth' })
  }
}

export const truncateString = (str, maxLength) => {
  if (str?.length > maxLength) {
    return str.substring(0, maxLength - 3) + '...'
  }
  return str
}

export const applyFilter = ({ inputData, comparator }) => {
  const stabilizedThis = inputData?.map((el, index) => [el, index])
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })

  inputData = stabilizedThis.map(el => el[0])

  return inputData
}

export const getCSVData = (data, mappingArray) => {
  if (!data?.length) return
  return data?.map(item => {
    const transformedObject = {}
    mappingArray?.forEach(mapping => {
      if (item[mapping.id] !== undefined || mapping.id !== '') {
        const modifiedData = {
          ['isActive']: item[mapping.id] ? 'Yes' : 'No',
          ['username']: `@${item[mapping.id]}`,
        }
        transformedObject[mapping.id] = modifiedData[mapping.id]
          ? modifiedData[mapping.id]
          : item[mapping.id]
      }
    })
    return transformedObject
  })
}
