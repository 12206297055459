import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useTranslation from '../../../localization/useTranslation'

const Section = styled.div`
  width: 40%;
`

const RulesListTitle = styled.div`
  font-weight: 600;
  font-size: 15px;
`
const RulesList = styled.ul`
  list-style-type: none;
  padding: 0 0 0 15px;
  margin: 0;
`

const RulesListItem = styled.li`
  font-size: 14px;
  position: relative;
  &::before {
    position: absolute;
    content: '';
    top: 10px;
    left: -10px;
    height: 1px;
    width: 5px;
    background: ${props => props.theme.color.mineShaft};
  }
`

const SubUsersRules = () => {
  const { labelStrings } = useTranslation()

  return (
    <Section>
      <RulesListTitle>{labelStrings.companyMembersAbleTo}:</RulesListTitle>
      <RulesList>
        {labelStrings.companyMembersRules.able.map(rule => (
          <RulesListItem key={rule}>{rule}</RulesListItem>
        ))}
      </RulesList>

      <RulesListTitle>{labelStrings.companyMembersNotAbleTo}:</RulesListTitle>
      <RulesList>
        {labelStrings.companyMembersRules.notAble.map(rule => (
          <RulesListItem key={rule}>{rule}</RulesListItem>
        ))}
      </RulesList>
    </Section>
  )
}

export default SubUsersRules
