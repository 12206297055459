import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StyledButtonContainer = styled.div`
  margin-right: 10px;
`
export const TipContainer = styled.div`
  max-width: 200px;
`
export const UpgradeLinkWrapper = styled(Link)`
  color: ${props => props.theme.color.black};
  text-decoration: underline;
  &:hover {
    color: ${props => props.theme.color.black};
    opacity: 0.9;
  }
`
