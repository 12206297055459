export const modifyArrayLength = (array, to) => {
  return array?.slice(0, to)
}

export const gradientTextSVG = text => {
  return (
    <svg width="990" height="54" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="textGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0.35%" stopColor="#FFBC00" />
          <stop offset="100%" stopColor="#FF5D5A" />
        </linearGradient>
      </defs>

      <text
        x="0"
        y="50"
        fontFamily="Inter, sans-serif"
        fontWeight="bold"
        fontSize="50"
        fill="url(#textGradient)"
      >
        {text}
      </text>
    </svg>
  )
}
