import { delayFunc, throttled } from '../utils'
import { DRAG_EVENT_TYPES, CLICK_EVENT_MAX_TIME_MS } from '../constants/appSettings'

/**
 * DOCS
 * when application inits, anyone can subscribe to drag different elements and will be notified than, when drag happens
 * draggings are defined by its types, like add to collection or smth else.
 *
 * if you want to use more events, add it in DRAG_EVENT_TYPES constants and here in constructor
 *
 * DRAGGABLE ELEMENT
 * use registerDraggingHandlers method below
 *
 * DROPPABLE ELEMENT
 * use registerDroppingHandlers method below
 *
 * PLEASE NOTE:
 * subscribe functions must be passed together with unique ids, Search in queues is performed by fn ids
 *
 * NOTE: this feature is now only for desktops, won't work on mobiles. For mobiles we need to use other events (touch events)
 */
class DragNDropService {
  constructor() {
    this.permission = true
    //
    this.isElementCaptured = false
    this.isElementDragging = false
    this.dragElementProps = null
    this.dragEventType = null

    this.subscribersQueues = {
      [DRAG_EVENT_TYPES.addToCollection]: [],
      // can be many queues in format [event type, one of DRAG_EVENT_TYPES]: [array of subscribers, each is obj {fnId: STRING, fn: FUNCTION}]
    }
    this.notifyDataObj = {
      isCaptured: false,
      isDragging: false,
      mouseCords: {},
      dragElementProps: {},
    }

    this._mouseMoveFn = null
    this._mouseUpFn = null
  }

  _createMouseMoveSubscribeFn() {
    return throttled(e => {
      if (this.isElementCaptured) {
        this.watchDragging(this.dragEventType, this.dragElementProps, e)
      }
    }, 100)
  }

  _createMouseUpSubscribeFn() {
    return e => {
      if (this.dragEventType) {
        this.releaseElement({ dragEventType: this.dragEventType })(e)
      }
    }
  }

  _subscribeToMouseMove() {
    this._mouseMoveFn = this._createMouseMoveSubscribeFn()
    window.addEventListener('mousemove', this._mouseMoveFn)
  }

  _unsubscribeFromMouseMove() {
    if (this._mouseMoveFn) {
      window.removeEventListener('mousemove', this._mouseMoveFn)
    }
  }

  _subscribeToMouseUp() {
    this._mouseUpFn = this._createMouseUpSubscribeFn()
    window.addEventListener('mouseup', this._mouseUpFn)
  }

  _unsubscribeFromMouseUp() {
    if (this._mouseUpFn) {
      window.removeEventListener('mouseup', this._mouseUpFn)
    }
  }

  _setDragEventType(dragEventType) {
    if (this.dragEventType !== dragEventType) this.dragEventType = dragEventType
  }

  _getMouseCords(e) {
    return {
      clientX: e.clientX,
      clientY: e.clientY,
    }
  }

  _createDefaultTargetQueue(targetQueueName) {
    this.subscribersQueues[targetQueueName] = []
  }

  _mapTargetQueueAndNotify(targetQueueName, notifyData) {
    if (this.subscribersQueues[targetQueueName]) {
      this.subscribersQueues[targetQueueName].forEach(fnObj => {
        fnObj.fn(notifyData)
      })
    } else {
      this._createDefaultTargetQueue(targetQueueName)
    }
  }

  _notifyDragging(dragEventType, mouseEvent) {
    this._mapTargetQueueAndNotify(dragEventType, {
      ...this.notifyDataObj,
      isCaptured: true,
      isDragging: true,
      mouseCords: this._getMouseCords(mouseEvent),
      dragElementProps: this.dragElementProps,
    })
  }

  // API methods
  //
  reactToClickEvent({ callback = null } = {}) {
    this.changeOperationStatus({ allowed: false })
    callback && callback()
    delayFunc(() => this.changeOperationStatus({ allowed: true }), CLICK_EVENT_MAX_TIME_MS)
  }

  changeOperationStatus({ allowed = false } = {}) {
    this.permission = allowed
  }

  // closured for passing params
  captureElement({ dragEventType, dragElementProps, mouseDownCB, captureDelay = 0 }) {
    return mouseEvent => {
      if (mouseEvent.button === 0) {
        mouseEvent.persist()
        delayFunc(() => {
          if (!this.permission) return

          // this.mouseDownEventTime = Date.now()
          if (mouseDownCB) mouseDownCB(mouseEvent)

          this._setDragEventType(dragEventType)
          this.isElementCaptured = true
          this.dragElementProps = dragElementProps

          // notify target queue that element was captured
          this._mapTargetQueueAndNotify(dragEventType, {
            ...this.notifyDataObj,
            isCaptured: true,
            mouseCords: this._getMouseCords(mouseEvent),
            dragElementProps,
          })

          this._subscribeToMouseMove()
          this._subscribeToMouseUp()
        }, captureDelay || CLICK_EVENT_MAX_TIME_MS)
      }
    }
  }

  releaseElement({ dragEventType, mouseUpCB = null }) {
    return event => {
      // notify target queue that element was released
      mouseUpCB && mouseUpCB(event)

      this._mapTargetQueueAndNotify(dragEventType, {
        ...this.notifyDataObj,
        dragElementProps: this.dragElementProps,
      })
      this.isElementCaptured = false
      this.isElementDragging = false
      this.dragElementProps = false
      this._setDragEventType(null)
      this._unsubscribeFromMouseMove()
      this._unsubscribeFromMouseUp()
    }
  }

  subscribeToDrag(dragEventType, fnObj) {
    if (!this.subscribersQueues[dragEventType]) this._createDefaultTargetQueue(dragEventType)
    const { fnId, fn } = fnObj
    // must use ids for functions here in fnObj arg. when subscribe, check for duplicates
    const isDuplicate = this.subscribersQueues[dragEventType].some(_f => _f.fnId === fnId)
    if (isDuplicate) return
    this.subscribersQueues[dragEventType].push({ fnId, fn })
  }

  watchDragging(dragEventType, dragElementProps, mouseEvent) {
    if (!dragEventType) return
    if (!this.dragEventType) this._setDragEventType(dragEventType)
    if (!this.isElementDragging) this.isElementDragging = true
    if (!this.dragElementProps) this.dragElementProps = dragElementProps
    this._notifyDragging(dragEventType, mouseEvent)
  }

  unsubscribeFromDrag(dragEventType, fnId) {
    // fnId is an id of function, passed when subscribe.
    this.subscribersQueues[dragEventType] = this.subscribersQueues[dragEventType].filter(
      _f => _f.fnId !== fnId
    )
  }

  // extra API for more complex apporach
  // register all needed handlers for draggable element
  registerDraggingHandlers({
    dragEventType,
    dragElementProps,
    mouseUpCB = null,
    mouseDownCB = null,
    captureDelay = 0,
    withMouseUp = false,
  }) {
    return {
      onMouseDown: this.captureElement({
        dragEventType,
        dragElementProps,
        mouseDownCB,
        captureDelay,
      }),

      ...(withMouseUp && { onMouseUp: this.releaseElement({ dragEventType, mouseUpCB }) }),
    }
  }

  registerDroppingHandlers(
    dragEventType,
    { onDragEnter = null, onDragLeave = null, onDrop = null } = {}
  ) {
    const eventCheckPassed = () => dragEventType === this.dragEventType

    const fireCallback = cb => async e => {
      cb && eventCheckPassed() && (await cb({ event: e, dragElementProps: this.dragElementProps }))
    }

    return {
      //  onDragEnter
      onMouseEnter: fireCallback(onDragEnter),
      // onDragLeave
      onMouseLeave: fireCallback(onDragLeave),
      // onDrop
      onMouseUp: fireCallback(onDrop),
    }
  }
}

/**
       * when adopt to mobile, add touch events to register dragging and dropping handlers:
       *  onTouchStart={e => console.log('TOUCH START')}
          onTouchMove={e => console.log('TOUCH MOVE')}
          onTouchEnd={() => console.log('TOUCH END')}
          onTouchCancel={() => console.log('TOUCH CANCEL')}
       */

export default new DragNDropService()
