import React, { useMemo } from 'react'
import { SidebarOuterWrapper } from '../../../../components/common/styledWrappers'
import { selectedPlatformSelector } from '../../../../redux/selectors'
import { shallowEqual, useSelector } from 'react-redux'
import { SOCIAL_PLATFORMS_NAMES, USER_INDEXES } from '../../../../constants/appSettings'
import SearchBarContainer from './SearchbarContainer'

export const Sidebar = ({
  exploreViewType,
  onChangeExploreViewType,
  selectedData,
  setSelectedData,
  onHandleRecentSearchResults,
  onHandleGetSuggestedUsersById,
  onOpenModalLookalikeAi,
  onOpenModalDeleteSuggestionsList
}) => {
  const currentSocialPlatform = useSelector(selectedPlatformSelector, shallowEqual)

  const currentPlatformIndex = useMemo(() => {
    // if (renderMap.search && !isEnableSearch) return ''
    if (currentSocialPlatform === SOCIAL_PLATFORMS_NAMES.tiktok.name) return USER_INDEXES.tiktok
    if (currentSocialPlatform === SOCIAL_PLATFORMS_NAMES.youtube.name) return USER_INDEXES.youtube
    return USER_INDEXES.instagram
  }, [currentSocialPlatform])

  return (
    <SidebarOuterWrapper userindex={currentPlatformIndex} isSearch={true} isCollections={false}>
      <SearchBarContainer
        isSearchEnabled={true}
        userindex={currentPlatformIndex}
        exploreViewType={exploreViewType}
        onChangeExploreViewType={onChangeExploreViewType}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        onHandleRecentSearchResults={onHandleRecentSearchResults}
        onHandleGetSuggestedUsersById={onHandleGetSuggestedUsersById}
        onOpenModalLookalikeAi={onOpenModalLookalikeAi}
        onOpenModalDeleteSuggestionsList={onOpenModalDeleteSuggestionsList}
      />
    </SidebarOuterWrapper>
  )
}
