import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { showCollectionAccessSelector } from '../../redux/selectors'
import { COLLECTIONS_ACCESS_TYPES } from '../../constants/collections'
import { MultiUsersIcon, UnlockIcon } from '../common/icons'
import WithTip from '../common/popups/WithTip'
import useTranslation from '../../localization/useTranslation'

const Wrapper = styled.div`
  ${props => props.showInCollection && 'margin: 0 15px; cursor: pointer;'}
`

const IconWrapper = styled.div`
  line-height: 1;
`

const CollectionAccessStatus = ({ access = '', showInCollection = false }) => {
  const { labelStrings } = useTranslation()
  const showCollectionAccess = useSelector(showCollectionAccessSelector)

  const isPublicCollection = !access || access === COLLECTIONS_ACCESS_TYPES.public

  const multiIconSize = showInCollection ? '35px' : '25px'
  const unlockIconSize = showInCollection ? '20px' : '13px'

  const Icon = isPublicCollection ? (
    <MultiUsersIcon height={multiIconSize} width={multiIconSize} />
  ) : (
    <UnlockIcon
      height={unlockIconSize}
      width={unlockIconSize}
      tabIcon
      isActiveIcon={false}
      isGrey
      inFocus={false}
    />
  )

  return (
    showCollectionAccess && (
      <Wrapper showInCollection={showInCollection}>
        {showInCollection ? (
          <WithTip
            tipText={
              isPublicCollection ? labelStrings.publicCollection : labelStrings.privateCollection
            }
            position={'right center'}
          >
            <IconWrapper>{Icon}</IconWrapper>
          </WithTip>
        ) : (
          Icon
        )}
      </Wrapper>
    )
  )
}

CollectionAccessStatus.propTypes = {
  access: PropTypes.string,
  showAsBlock: PropTypes.bool,
}

export default CollectionAccessStatus
