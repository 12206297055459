import React from 'react'
import useTranslation from '../../../../localization/useTranslation'
import { USER_INDEXES } from '../../../../constants/appSettings'
import {
  SuspiciousActivitiesResultMessage,
  SuspiciousActivitiesItemsGrid,
  SuspiciousActivitiesItem,
} from './styles'

const SuspiciousActivities = ({ suspiciousActivities }) => {
  const { labelStrings, scoreStrings } = useTranslation()
  const suspActivitiesStrings = scoreStrings[USER_INDEXES.instagram].suspiciousActivities
  const { fakeFollowers: hasFakeFollowers, followUnfollow: hasFollowUnfollow, likesViewsDisabled: hasLikesViewsDisabled, isInactive: hasInactive } =
    suspiciousActivities || {}

  let resultMessage = suspActivitiesStrings.notFoundSuspiciousActivity

  if (hasFakeFollowers || hasFollowUnfollow || hasLikesViewsDisabled || hasInactive) {
    resultMessage = suspActivitiesStrings.foundSuspiciousActivity
  }

  const suspiciousActivitiesArr = [
    { name: suspActivitiesStrings.followUnfollowActivity, isFound: hasFollowUnfollow },
    { name: suspActivitiesStrings.fakeFollowers, isFound: hasFakeFollowers },
    { name: suspActivitiesStrings.likesViewsDisabled, isFound: hasLikesViewsDisabled },
    { name: suspActivitiesStrings.isInactive, isFound: hasInactive }
  ]

  return (
    <>
      <SuspiciousActivitiesResultMessage>{resultMessage}</SuspiciousActivitiesResultMessage>

      <SuspiciousActivitiesItemsGrid>
        {suspiciousActivitiesArr.map((item, index) => (
          <SuspiciousActivitiesItem
            key={index}
            isFound={item.isFound}
            tipContent={item.isFound ? labelStrings.possible : labelStrings.notFound}
          >
            {item.name}
          </SuspiciousActivitiesItem>
        ))}
      </SuspiciousActivitiesItemsGrid>
    </>
  )
}

export default SuspiciousActivities
