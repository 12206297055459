import React, { useState, useEffect, forwardRef } from 'react'
import styled from 'styled-components'
import { USER_INDEXES } from '../../../constants/appSettings'
import TitlePage from './TitlePage/TitlePage'
import ProfileOverviewPage from './ProfileOverviewPage/ProfileOverviewPage'
import { AudienceAnalysisPage1, AudienceAnalysisPage2 } from './audienceAnalysisPages'
import { IgPageQuialityRating, IgPageEngagement, IgPageBrandValue } from './igPages'
import { TtProfileInfo } from './ttPages'
import YtPageQualityRating from './ytPages/PageQuialityRating'
import AverageViewsChartPage from './ytPages/AverageViewsChartPage'
import EngagementShortsPage from './ytPages/EngagementShortsPage'
import EngagementsVideosPage from './ytPages/EngagementsVideosPage'
import EngagementOverallPage from './ytPages/EngagementOverallPage'
import AudienceAnalysisPage from './ytPages/AudienceAnalysisPage'
import { shallowEqual, useSelector } from 'react-redux'
import { selectedProfileSelector } from '../../../redux/selectors'

const ProfilePdfDocumentHideWrapper = styled.div`
  /* Hide redered components */
  position: fixed;
  top: -9999px;
  left: -9999px;
  z-index: -9999;

  /* Show rendered components (for dev) */
  ${'' /* position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: scroll;
  z-index: 9999;
  background: white;
  border: 1px solid black; */}
`

const ProfilePdfDocument = forwardRef(
  ({ format, platform, dateOfReport, setMarkupReady, optionsModal }, ref) => {
    const [isFollowersChartReady, setIsFollowersChartReady] = useState(false)
    const [isViewsPerPostChartReady, setIsViewsPerPostChartReady] = useState(
      platform === USER_INDEXES.tiktok ? false : true
    )

    const modifiedOptionsModal = optionsModal?.reduce(
      (obj, item) => ((obj[item.id] = item.value), obj),
      {}
    )

    const { profile } = useSelector(selectedProfileSelector, shallowEqual)
    const {
      likeEngagementShortsObject,
      commentEngagementShortsObject,
      engagementShortsObject,
      averageDurationShortsObject,
      viewsPerShortObject,
      shortsViewChangeObject,
      likeEngagementPostsObject,
      commentEngagementPostsObject,
      engagementPostsObject,
      averageDurationPostsObject,
      viewsPerPostObject,
      postsViewChangeObject,
    } = profile.profileScore

    const isEngagementsVideo =
      likeEngagementPostsObject &&
      commentEngagementPostsObject &&
      engagementPostsObject &&
      averageDurationPostsObject &&
      viewsPerPostObject &&
      postsViewChangeObject

    const isEngagementsShorts =
      likeEngagementShortsObject &&
      commentEngagementShortsObject &&
      engagementShortsObject &&
      averageDurationShortsObject &&
      viewsPerShortObject &&
      shortsViewChangeObject

    useEffect(() => {
      if (isFollowersChartReady && isViewsPerPostChartReady) {
        setTimeout(() => setMarkupReady(), 3000)
      }
      if (!modifiedOptionsModal.showFollowerTrendGraph) {
        setTimeout(() => setMarkupReady(), 3000)
      }
    }, [isFollowersChartReady, isViewsPerPostChartReady])

    return (
      <ProfilePdfDocumentHideWrapper>
        <div ref={ref}>
          {modifiedOptionsModal.showFirstPage && (
            <TitlePage
              dateOfReport={dateOfReport}
              isBranded={modifiedOptionsModal.includeInfludataBranding}
            />
          )}

          <ProfileOverviewPage
            setIsFollowersChartReady={setIsFollowersChartReady}
            setIsViewsPerPostChartReady={setIsViewsPerPostChartReady}
            isBranded={modifiedOptionsModal.includeInfludataBranding}
            isShowFollowerTrendGraph={modifiedOptionsModal.showFollowerTrendGraph}
          />

          {(() => {
            switch (platform) {
              case USER_INDEXES.instagram:
                return (
                  <>
                    {modifiedOptionsModal.showQualityScores && (
                      <>
                        <IgPageQuialityRating
                          isBranded={modifiedOptionsModal.includeInfludataBranding}
                        />
                        <IgPageEngagement
                          isBranded={modifiedOptionsModal.includeInfludataBranding}
                        />{' '}
                      </>
                    )}
                    {modifiedOptionsModal.showBrandMentions &&
                      modifiedOptionsModal.showSmartMediaValue && (
                        <IgPageBrandValue
                          isBranded={modifiedOptionsModal.includeInfludataBranding}
                          isShowBrandMentions={modifiedOptionsModal.showBrandMentions}
                          isShowSmartMediaValue={modifiedOptionsModal.showSmartMediaValue}
                        />
                      )}
                  </>
                )
              case USER_INDEXES.tiktok:
                return (
                  <>
                    {modifiedOptionsModal.showFollowerTrendGraph &&
                      modifiedOptionsModal.showQualityScores && (
                        <TtProfileInfo
                          setIsFollowersChartReady={setIsFollowersChartReady}
                          isBranded={modifiedOptionsModal.includeInfludataBranding}
                          isShowFollowerTrendGraph={modifiedOptionsModal.showFollowerTrendGraph}
                          isShowQualityScores={modifiedOptionsModal.showQualityScores}
                        />
                      )}
                  </>
                )
              case USER_INDEXES.youtube:
                return (
                  <>
                    {modifiedOptionsModal.showFollowerTrendGraph && (
                      <AverageViewsChartPage
                        isBranded={modifiedOptionsModal.includeInfludataBranding}
                      />
                    )}
                    {modifiedOptionsModal.showQualityScores && (
                      <>
                        {' '}
                        <YtPageQualityRating
                          isBranded={modifiedOptionsModal.includeInfludataBranding}
                        />
                        {isEngagementsVideo && (
                          <EngagementsVideosPage
                            isBranded={modifiedOptionsModal.includeInfludataBranding}
                          />
                        )}
                        {isEngagementsShorts && (
                          <EngagementShortsPage
                            isBranded={modifiedOptionsModal.includeInfludataBranding}
                          />
                        )}
                      </>
                    )}
                    {modifiedOptionsModal.showQualityScores &&
                      modifiedOptionsModal.showSmartMediaValue && (
                        <EngagementOverallPage
                          isBranded={modifiedOptionsModal.includeInfludataBranding}
                          isShowQualityScores={modifiedOptionsModal.showQualityScores}
                          isShowSmartMediaValue={modifiedOptionsModal.showSmartMediaValue}
                        />
                      )}
                    {modifiedOptionsModal.showAudienceReport && (
                      <AudienceAnalysisPage
                        isBranded={modifiedOptionsModal.includeInfludataBranding}
                      />
                    )}
                  </>
                )
            }
          })()}

          {platform !== USER_INDEXES.youtube && modifiedOptionsModal.showAudienceReport && (
            <>
              <AudienceAnalysisPage1 isBranded={modifiedOptionsModal.includeInfludataBranding} />
              {platform !== USER_INDEXES.tiktok && (
                <AudienceAnalysisPage2 isBranded={modifiedOptionsModal.includeInfludataBranding} />
              )}
            </>
          )}
        </div>
      </ProfilePdfDocumentHideWrapper>
    )
  }
)

export default ProfilePdfDocument
