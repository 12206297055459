import { format, getTime, formatDistanceToNow, addHours } from 'date-fns'

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy'

  return date ? format(new Date(date), fm) : ''
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy, HH:mm'

  return date ? format(new Date(date), fm) : ''
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : ''
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : ''
}

export function getCurrentDatePlusOneHour(dateParam) {
  const date = new Date(dateParam)
  const datePlusOneHour = addHours(date, 1)
  return datePlusOneHour
}

export const isValidDate = d => {
  return d instanceof Date && !isNaN(d)
}
