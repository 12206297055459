import React from 'react'
import { Button, Typography, Stack, Box } from '@mui/material'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import useTranslation from '../../../localization/useTranslation'

export const TopControls = ({ onCreate, disabled }) => {
  const { labelStrings } = useTranslation()
  return (
    <Stack justifyContent="space-between" direction="row" alignItems="flex-start">
      <Box sx={{ width: '75%' }}>
        <Typography sx={{ fontWeight: 700, fontSize: '18px', lineHeight: '28px', fontFamily: 'Inter', }}>
          {labelStrings.keywordsMentionsManager}
        </Typography>
        <Typography
          sx={{ fontFamily: 'Inter', fontWeight: 400, fontSize: '14px', lineHeight: '22px', color: '#637381', whiteSpace: 'pre-line' }}
        >
          {labelStrings.keywordsMentionsManagerSubTitle}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={() => onCreate(true)}
          variant="outlined"
          startIcon={<AddRoundedIcon />}
          disabled={disabled}
          sx={{
            textTransform: 'capitalize',
            fontSize: '14px',
            lineHeight: '24px',
            fontWeight: 700,
            mt: '2px',
          }}
        >
          {labelStrings.addNewTerm}
        </Button>
      </Box>
    </Stack>
  )
}
