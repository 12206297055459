export const getCurrentDashboardFields = graphData => {
  return graphData?.reduce(
    (acc, data) => {
      if (data.graphId.startsWith('cpm_organic')) {
        acc.cpmCpe.push(data.graphId)
      } else if (data.graphId.startsWith('cpe_organic')) {
        acc.cpeOrganic.push(data.graphId)
      } else if (data.graphId.startsWith('custom_tracking_count')) {
        acc.customTracking.push(data.graphId)
      } else if (data.graphId.startsWith('goal_reached')) {
        acc.goals.push(data.graphId)
      } else if (data.graphId.startsWith('reached_audience')) {
        acc.reachedAudience.push(data.graphId)
      } else if (data.graphId.endsWith('_count')) {
        acc.overview.push(data.graphId)
      } else if (data.graphId.endsWith('_sum')) {
        acc.overview.push(data.graphId)
      } else if (data.graphId.startsWith('widget_')) {
        acc.overview.push(data.graphId)
      } else if (
        data.graphId.includes('comment') ||
        data.graphId.includes('comments') ||
        data.graphId.startsWith('semantic_')
      ) {
        acc.sentiment.push(data.graphId)
      } else if (data.graphId.startsWith('emv_')) {
        acc.emv.push(data.graphId)
      } else if (data.graphId === 'main_metrics_table') {
        acc.main_metrics_table.push(data)
      } else {
        acc.publishedContent.push(data.graphId)
      }
      return acc
    },
    {
      cpmCpe: [],
      cpeOrganic: [],
      emv: [],
      customTracking: [],
      goals: [],
      reachedAudience: [],
      overview: [],
      sentiment: [],
      publishedContent: [],
      main_metrics_table: [],
    }
  )
}

export const processDashboardData = graphData => {
  return graphData?.reduce(
    (acc, data) => {
      if (data.graphId.startsWith('cpm_organic')) {
        acc.cpm_organic.push(data)
      } else if (data.graphId.startsWith('cpe_organic')) {
        acc.cpe_organic.push(data)
      } else if (data.graphId.startsWith('custom_tracking_count')) {
        acc.custom_tracking.push(data)
      } else if (data.graphId.startsWith('goal_reached')) {
        acc.goal_reached.push(data)
      } else if (data.graphId.startsWith('reached_audience')) {
        acc.reached_audience.push(data)
      } else if (data.graphId.endsWith('_count')) {
        acc.count_widgets.push(data)
      } else if (data.graphId.endsWith('_sum')) {
        acc.count_widgets.push(data)
      } else if (data.graphId.startsWith('widget_')) {
        acc.count_widgets.push(data)
      } else if (data.graphId.startsWith('emv_')) {
        acc.emv.push(data)
      } else {
        acc[data.graphId] = data
      }
      return acc
    },
    {
      cpe_organic: [],
      cpm_organic: [],
      emv: [],
      custom_tracking: [],
      goal_reached: [],
      reached_audience: [],
      count_widgets: [],
    }
  )
}

export const getAlwaysVisibleDashboardFields = graphData => {
  return graphData?.reduce(
    (acc, data) => {
      if (data.startsWith('cpm_organic')) {
        acc.cpmCpe.push(data)
      } else if (data.startsWith('cpe_organic')) {
        acc.cpeOrganic.push(data)
      } else if (data.startsWith('custom_tracking_count')) {
        acc.customTracking.push(data)
      } else if (data.startsWith('goal_reached')) {
        acc.goals.push(data)
      } else if (data.startsWith('reached_audience')) {
        acc.reachedAudience.push(data)
      } else if (data.endsWith('_count')) {
        acc.overview.push(data)
      } else if (data.endsWith('_sum')) {
        acc.overview.push(data)
      } else if (data.startsWith('widget_')) {
        acc.overview.push(data)
      } else if (
        data.includes('comment') ||
        data.includes('comments') ||
        data.startsWith('semantic_')
      ) {
        acc.sentiment.push(data)
      } else if (data.startsWith('emv_')) {
        acc.emv.push(data)
      } else if (data === 'main_metrics_table') {
        acc.main_metrics_table.push(data)
      } else {
        acc.publishedContent.push(data)
      }
      return acc
    },
    {
      cpmCpe: [],
      cpeOrganic: [],
      emv: [],
      customTracking: [],
      goals: [],
      reachedAudience: [],
      overview: [],
      sentiment: [],
      publishedContent: [],
      main_metrics_table: [],
    }
  )
}

export const compareTwoArrays = (arr1, arr2) => {
  if (Array.isArray(arr1) && Array.isArray(arr2)) {
    const sortArr1 = arr1?.sort()
    const sortArr2 = arr2?.sort()

    return sortArr1?.every((v, i) => v === sortArr2[i])
  }

  return false
}

export const filteredData = (arr, val) => {
  const result = arr?.filter(dashboard => dashboard.dashboardId === val)
  return result?.length ? result[0] : {}
}

export const separateDataPublishedContent = (data = []) => {
  return data?.reduce(
    (acc, item) => {
      acc.date.push(item.date?.substr(0, 10))
      acc.followers.push(item.followers_1)
      acc.publishedContentCount.push(item.publishedContentCount)
      acc.reachedUsersSum.push(item.reachedUsersSum)
      acc.username.push(item.username)
      acc.averagePositivityRate.push(item.averagePositivityRate)
      acc.averageControversialRate.push(item.averageControversialRate)
      acc.averageCreatorFocusRate.push(item.averageCreatorFocusRate)
      acc.averageBrandFocusRate.push(item.averageBrandFocusRate)
      acc.averageLowQualityRate.push(item.averageLowQualityRate)
      acc.otherRate.push(item.otherRate)
      acc.countedContent.push(item.countedContent)
      acc.mainMetricsTable.push(item.main_metrics_table)
      return acc
    },
    {
      date: [],
      followers: [],
      publishedContentCount: [],
      reachedUsersSum: [],
      username: [],
      averagePositivityRate: [],
      averageControversialRate: [],
      averageBrandFocusRate: [],
      averageCreatorFocusRate: [],
      averageLowQualityRate: [],
      otherRate: [],
      countedContent: [],
      followersSum: [],
      mainMetricsTable: []
    }
  )
}

export const ucFirst = str => {
  if (!str) return str
  return str[0].toUpperCase() + str.slice(1)
}

export const getMinAndMaxFollowers = data => {
  const length = (data && Object.keys(data)?.length - 2) || 0
  let minFollowers = Infinity
  let maxFollowers = -Infinity

  for (const item of data) {
    for (let i = 1; i <= length; i++) {
      const followersKey = `followers_${i}`
      const followersCount = item[followersKey]

      if (followersCount < minFollowers) {
        minFollowers = followersCount
      }

      if (followersCount > maxFollowers) {
        maxFollowers = followersCount
      }
    }
  }
  return [minFollowers, maxFollowers]
}

export const dateFormatMaskYYYY_MM_dd = 'yyyy/MM/dd'

export const shortsProfileCountryNames = {
  ['united arab emirates']: 'UAE',
  ['united states of america']: 'USA',
  ['united states']: 'USA',
  ['united kingdom']: 'UK',
}