import React from 'react'
import PropTypes from 'prop-types'
import { Box, Checkbox, TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material'

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
}

const TableHeadCustom = ({
  order,
  orderBy,
  rowCount = 0,
  headLabel,
  numSelected = 0,
  onSort,
  onSelectAllRows,
  sx,
  isFirstRowBorder,
  isForPdf,
  isBrandPerformancePdf,
}) => {
  return (
    <TableHead
      sx={{
        ...sx,
        '& .MuiTableCell-root': {
          color: isBrandPerformancePdf ? '#784E4E' : '#637381',
          backgroundColor: isBrandPerformancePdf ? '#fff' : '#F4F6F8',
        },
      }}
    >
      <TableRow>
        {onSelectAllRows && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={event => onSelectAllRows(event.target.checked)}
            />
          </TableCell>
        )}
        {headLabel?.map((headCell, idx) => (
          <TableCell
            key={`${headCell.id}_${idx}`}
            align={headCell.align || 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              width: headCell.width,
              minWidth: headCell.minWidth,
              maxWidth: isForPdf ? '100px' : '100%',
              '&:first-of-type': {
                borderRight: isFirstRowBorder ? '2px solid rgba(145, 158, 171, 0.24)' : 'inherit',
              },
              verticalAlign: headCell.verticalAlign || 'top',
              fontSize: isForPdf ? '12px' : '14px',
            }}
          >
            {onSort ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => onSort(headCell.id)}
                sx={{ textTransform: 'capitalize', ...headCell.sx }}
              >
                {headCell.label}

                {orderBy === headCell.id ? (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

TableHeadCustom.propTypes = {
  sx: PropTypes.object,
  onSort: PropTypes.func,
  orderBy: PropTypes.string,
  headLabel: PropTypes.array,
  rowCount: PropTypes.number,
  numSelected: PropTypes.number,
  onSelectAllRows: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']),
  isFirstRowBorder: PropTypes.bool,
  isForPdf: PropTypes.bool,
  isBrandPerformancePdf: PropTypes.bool,
}

export default TableHeadCustom
