import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import useTranslation from '../../../../localization/useTranslation'
import SearchbarDropdownContainer from '../../../common/contentDropdowns/SearchbarDropdownContainer'
import { USER_INDEXES } from '../../../../constants/appSettings'
import {
  AUDIENCE_REAL_PEOPLE_SUBSEARCH_RATES,
  AUDIENCE_GENDER_SUBSEARCH_RATES,
  MULTI_RANGE_AUDIENCE_AGE_RANGES,
  COMMA_SPLITTER,
  UNDERSCORE_SPLITTER,
} from '../../../../constants/search'

import AudienceCountriesSubSearch from './AudienceCountriesSubSearch'
import AudienceSubSearchSingleRangeSlider from '../../../common/sliders/AudienceSubSearchSingleRangeSlider'
import AudienceSubSearchMultiRangeSlider from '../../../common/sliders/AudienceSubSearchMultiRangeSlider'
import {
  AudienceCountriesNavWrapper,
  AudienceSearchSectionsDivider,
  AudienceWrapperWithPadding,
} from '../../../common/styledWrappers/audienceSubSearchStyles'
import { SearchLabel } from '../../../common/styledWrappers'
import AudienceLanguagesSubSearch from './AudienceLanguagesSubSearch'

const AudienceSubSearchContainer = ({
  changeAudienceSearch,
  isEnabled = false,
  userindex = '',
  audience,
  formatOptionLabelCountryFlag = true,
}) => {
  const CLEAR_VALUE = 'clear'
  const DEFAULT_RANGE = 10
  const { labelStrings, countryOptions, languageOptions } = useTranslation()

  const updateAudienceSearch = updateObj => changeAudienceSearch({ ...audience, ...updateObj })

  const isTikTok = userindex === USER_INDEXES.tiktok
  const isYTPlatform = userindex === USER_INDEXES.youtube

  const countries = useMemo(() => {
    if (!audience.audienceCountries) return []
    const _cArr = audience.audienceCountries.split(COMMA_SPLITTER)
    return _cArr.reduce((_arr, c) => {
      if (c.includes(UNDERSCORE_SPLITTER)) {
        const [country, range] = c.split(UNDERSCORE_SPLITTER)
        _arr.push({ country, range: parseInt(range) })
      }
      return _arr
    }, [])
  }, [audience.audienceCountries])

  const languages = useMemo(() => {
    if (!audience.audienceLanguages) return []
    const _cArr = audience.audienceLanguages.split(COMMA_SPLITTER)
    return _cArr.reduce((_arr, c) => {
      if (c.includes(UNDERSCORE_SPLITTER)) {
        const [language, range] = c.split(UNDERSCORE_SPLITTER)
        _arr.push({ language, range: parseInt(range) })
      }
      return _arr
    }, [])
  }, [audience.audienceLanguages])

  const _update = countriesForUpdate => {
    if (!audience.audienceCountries && !countriesForUpdate.length) return
    const audienceCountries = countriesForUpdate.reduce(
      (str, item) => `${str ? `${str},` : ''}${item.country}${UNDERSCORE_SPLITTER}${item.range}`,
      ''
    )
    updateAudienceSearch({ audienceCountries })
  }

  const _updateLanguage = languagesForUpdate => {
    if (!audience.audienceLanguages && !languagesForUpdate.length) return
    const audienceLanguages = languagesForUpdate.reduce(
      (str, item) => `${str ? `${str},` : ''}${item.language}${UNDERSCORE_SPLITTER}${item.range}`,
      ''
    )
    updateAudienceSearch({ audienceLanguages })
  }

  // countries change
  const onChangeAudienceCountries = prevCountry => newCountryObj => {
    // delete
    if (!newCountryObj || newCountryObj.value === CLEAR_VALUE) {
      return _update(countries.filter(c => c.country !== prevCountry))
    }
    if (prevCountry === newCountryObj.value) return
    const exists = countries.some(c => c.country === prevCountry)
    if (exists) {
      // replace
      return _update(
        countries.map(c => (c.country === prevCountry ? { ...c, country: newCountryObj.value } : c))
      )
    }
    if (!prevCountry) {
      const alreadyHaveInList = countries.some(c => c.country === newCountryObj.value)
      if (alreadyHaveInList) return
      // add new
      return _update([...countries, { country: newCountryObj.value, range: DEFAULT_RANGE }])
    }
  }

  // languages change
  const onChangeAudienceLanguages = prevLanguage => newLanguageObj => {
    // delete
    if (!newLanguageObj || newLanguageObj.value === CLEAR_VALUE) {
      return _updateLanguage(languages.filter(lang => lang.language !== prevLanguage))
    }
    if (prevLanguage === newLanguageObj.value) return
    const exists = languages.some(lang => lang.language === prevLanguage)
    if (exists) {
      // replace
      return _updateLanguage(
        languages.map(lang =>
          lang.language === prevLanguage ? { ...lang, language: newLanguageObj.value } : lang
        )
      )
    }
    if (!prevLanguage) {
      const alreadyHaveInList = languages.some(lang => lang.language === newLanguageObj.value)
      if (alreadyHaveInList) return
      // add new
      return _updateLanguage([
        ...languages,
        { language: newLanguageObj.value, range: DEFAULT_RANGE },
      ])
    }
  }

  // countries ranges change
  const onChangeAudienceCountriesRange = country => range => {
    if (!country) return
    const updated = range
      ? countries.map(c => (c.country === country ? { ...c, range } : c))
      : countries.filter(c => c.country !== country)
    _update(updated)
  }

  // languages ranges change
  const onChangeAudienceLanguagesRange = language => range => {
    if (!language) return
    const updated = range
      ? languages.map(c => (c.language === language ? { ...c, range } : c))
      : languages.filter(c => c.language !== language)
    _updateLanguage(updated)
  }

  // real people change
  const updateAudienceRealPeopleSearch = value =>
    updateAudienceSearch({ audienceRealPeople: value })

  // ages ranges change
  const updateAudienceAgesSearch = ranges => {
    if (ranges.length < 2) return
    const [min, max] = ranges
    const payload = ranges[0] === 0 ? '' : `${min},${max}`
    updateAudienceSearch({ audienceAgeRanges: payload })
  }

  // gender change
  const updateAudienceGenderSearch = value => updateAudienceSearch({ audienceGender: value })

  const reactOnCloseDropdown = () => {
    // reset all audience search params when dropdown is closed
    if (Object.values(audience).some(v => !!v)) {
      updateAudienceSearch({
        audienceAgeRanges: '',
        audienceCountries: '',
        audienceLanguages: '',
        audienceGender: '',
        audienceRealPeople: null,
      })
    }
  }

  // helpers
  const formatRealPeopleValueTip = value => `> ${value}% ${labelStrings.real.toUpperCase()}`
  const formatGenderValueTip = value => {
    if (!value) return ''
    const [percent, gender] = value.split(UNDERSCORE_SPLITTER)
    return `${percent}% ${labelStrings[gender].toUpperCase()}`
  }

  // processed values
  const preparedCountryOptions = useMemo(() => {
    return countryOptions.map(c =>
      !!c.value ? c : { value: CLEAR_VALUE, label: labelStrings.clearInput }
    )
  }, [countries])

  const preparedLanguageOptions = useMemo(() => {
    return languageOptions?.map(language =>
      !!language.value
        ? language
        : {
            value: CLEAR_VALUE,
            label: labelStrings.clearInput,
          }
    )
  }, [languages])

  const preparedAudienceAgesValues = useMemo(() => {
    if (audience.audienceAgeRanges) {
      return audience.audienceAgeRanges.split(COMMA_SPLITTER).map(n => parseInt(n))
    }
  }, [audience.audienceAgeRanges])

  const preparedAudienceGender = useMemo(() => {
    return audience.audienceGender || ''
  }, [audience.audienceGender])

  return (
    <SearchbarDropdownContainer
      titleText={labelStrings.searchByAudience}
      isActive={isEnabled}
      upgradeTipText={labelStrings.useAudienceSearch}
      faqQuestionId="" // TODO: FAQLINK
      notImplemented={isTikTok}
      notImplementedLabel={labelStrings.commingSoon}
      notImplementedTipText={labelStrings.audienceSearchForTikTokCommingSoon}
      userindex={userindex}
      closeDropdownCB={reactOnCloseDropdown}
    >
      <>
        <AudienceSearchSectionsDivider />
        <AudienceWrapperWithPadding>
          <AudienceCountriesNavWrapper>
            <SearchLabel htmlFor={`${isYTPlatform ? 'languages' : 'country'}`}>
              {isYTPlatform ? labelStrings.languages : labelStrings.country}
            </SearchLabel>
          </AudienceCountriesNavWrapper>

          {isYTPlatform ? (
            <AudienceLanguagesSubSearch
              idPrefix="audienceLanguageSelect"
              options={preparedLanguageOptions}
              placeholder={labelStrings.selectLanguage}
              selectedList={languages}
              onChangeAudienceLanguages={onChangeAudienceLanguages}
              onChangeAudienceLanguagesRange={onChangeAudienceLanguagesRange}
              selectsLimit={3} // max 3 languages
            />
          ) : (
            <AudienceCountriesSubSearch
              idPrefix="audienceCountySelect"
              options={preparedCountryOptions}
              placeholder={labelStrings.selectCountry}
              selectedList={countries}
              onChangeAudienceCountries={onChangeAudienceCountries}
              onChangeAudienceCountriesRange={onChangeAudienceCountriesRange}
              selectsLimit={3} // max 3 countries
              formatOptionLabelCountryFlag={formatOptionLabelCountryFlag}
            />
          )}
        </AudienceWrapperWithPadding>

        <AudienceSearchSectionsDivider />
        <AudienceWrapperWithPadding>
          {/* GENDER */}
          <AudienceSubSearchSingleRangeSlider
            faqQuestionId="" // TODO: FAQLINK
            onChange={updateAudienceGenderSearch}
            value={preparedAudienceGender}
            title={labelStrings.audienceByGender}
            sliderRanges={AUDIENCE_GENDER_SUBSEARCH_RATES}
            formatCurrentValueTip={formatGenderValueTip}
            isMirrorSlider
            leftSubLabel={labelStrings.male}
            rightSubLabel={labelStrings.female}
            zeroValue={''}
          />
        </AudienceWrapperWithPadding>
        {!isYTPlatform && (
          <>
            <AudienceSearchSectionsDivider />
            <AudienceWrapperWithPadding>
              {/* REAL PEOPLE */}
              <AudienceSubSearchSingleRangeSlider
                faqQuestionId="" // TODO: FAQLINK
                onChange={updateAudienceRealPeopleSearch}
                value={audience.audienceRealPeople || null}
                title={labelStrings.audienceByRealPeople}
                sliderRanges={AUDIENCE_REAL_PEOPLE_SUBSEARCH_RATES}
                formatCurrentValueTip={formatRealPeopleValueTip}
              />
            </AudienceWrapperWithPadding>

            <AudienceSearchSectionsDivider />
            <AudienceWrapperWithPadding>
              {/* AGES */}
              <AudienceSubSearchMultiRangeSlider
                values={preparedAudienceAgesValues}
                title={labelStrings.audienceByAvgAge}
                updateHandler={updateAudienceAgesSearch}
                ranges={MULTI_RANGE_AUDIENCE_AGE_RANGES}
                faqQuestionId="" // TODO: FAQLINK
                usePresentionalLabels
              />
            </AudienceWrapperWithPadding>
          </>
        )}
      </>
    </SearchbarDropdownContainer>
  )
}

AudienceSubSearchContainer.propTypes = {
  changeAudienceSearch: PropTypes.func,
  isEnabled: PropTypes.bool,
  userindex: PropTypes.string,
  formatOptionLabelCountryFlag: PropTypes.bool,
}

export default AudienceSubSearchContainer
