import availableLangs from './availableLangs'

export default {
  [availableLangs.en]: {
    //all: '🌐 international',
    germany: '🇩🇪 Germany',
    austria: '🇦🇹 Austria',
    switzerland: '🇨🇭 Switzerland',
    usa: ' 🇺🇸 United States',
    albania: '🇦🇱 Albania',
    algeria: '🇩🇿 Algeria',
    argentina: '🇦🇷 Argentina',
    armenia: '🇦🇲 Armenia',
    australia: '🇦🇺 Australia',
    bahrain: '🇧🇭 Bahrain',
    belarus: '🇧🇾 Belarus',
    belgium: '🇧🇪 Belgium',
    bolivia: '🇧🇴 Bolivia',
    bosniaHerzegovina: '🇧🇦 Bosnia and Herzegovina',
    brazil: '🇧🇷 Brazil',
    bulgaria: '🇧🇬 Bulgaria',
    canada: '  🇨🇦 Canada',
    colombia: ' 🇨🇴 Columbia',
    chile: ' 🇨🇱 Chile',
    costaRica: '🇨🇷 Costa Rica',
    croatia: '🇭🇷 Croatia',
    cuba: '🇨🇺 Cuba',
    cyprus: ' 🇨🇾  Cyprus',
    czechRepublic: ' 🇨🇿 Czech Republic',
    denmark: ' 🇩🇰 Denmark',
    dominicanRepublic: '🇩🇴 Dominican Republic',
    ecuador: '🇪🇨 Ecuador',
    egypt: '🇪🇬 Egypt',
    estonia: '🇪🇪 Estonia',
    finland: ' 🇫🇮 Finland',
    france: ' 🇫🇷 France',
    greece: ' 🇫🇷 Greece',
    guatemala: '🇬🇹 Guatemala',
    honduras: '🇭🇳 Honduras',
    hongkong: '🇭🇰 Hong Kong',
    hungary: ' 🇭🇺 Hungary',
    iceland: ' 🇮🇸 Iceland',
    indonesia: '🇮🇩 Indonesia',
    iran: '🇮🇷 Iran',
    iraq: '🇮🇶 Iraq',
    ireland: ' 🇮🇪 Ireland',
    israel: ' 🇮🇱 Israel',
    italy: ' 🇮🇹 Italy',
    india: '🇮🇳 India',
    jamaica: '🇯🇲 Jamaica',
    japan: '🇯🇵 Japan',
    kuwait: '🇰🇼 Kuwait',
    latvia: '🇱🇻 Latvia',
    libya: '🇱🇾 Libya',
    lithuania: ' 🇱🇹 Lithuania',
    luxembourg: ' 🇱🇺 Luxembourg',
    macedonia: ' 🇲🇰 Macedonia',
    malaysia: ' 🇲🇲 Malaysia',
    mexico: ' 🇲🇽 Mexico',
    morocco: ' 🇲🇦 Morocco',
    netherlands: '🇳🇱 Netherlands',
    newZealand: ' 🇳🇿 New Zealand',
    nicaragua: '🇳🇮 Nicaragua',
    nigeria: '🇳🇬 Nigeria',
    norway: '🇳🇴 Norway',
    oman: '🇴🇲 Oman',
    panama: '🇵🇦 Panama',
    paraguay: '🇵🇾 Paraguay',
    peru: '🇵🇪 Peru',
    philippines: '🇵🇭 Philippines',
    poland: '🇵🇱 Poland',
    portugal: '🇵🇹 Portugal',
    romania: '🇷🇴 Romania',
    russia: '🇷🇺 Russia',
    qatar: '🇶🇦 Qatar',
    saudiArabia: '🇸🇦 Saudi Arabia',
    serbia: '🇷🇸 Serbia',
    singapore: '🇸🇬  Singapore',
    slovakia: '🇸🇰 Slovakia',
    slovenia: ' 🇸🇮 Slovenia',
    southAfrica: ' 🇿🇦 South Africa',
    southKorea: ' 🇰🇷 South Korea',
    spain: ' 🇪🇸 Spain',
    sweden: '🇸🇪 Sweden',
    taiwan: '🇹🇼 Taiwan',
    thailand: '🇹🇭 Thailand',
    turkey: ' 🇹🇷 Turkey',
    tunisia: '🇹🇳 Tunisia',
    ukraine: ' 🇺🇦 Ukraine',
    unitedKingdom: '🇬🇧 United Kingdom',
    unitedArabEmirates: '🇦🇪 United Arab Emirates',
    uruguay: '🇺🇾 Uruguay',
    vietnam: '🇻🇳 Vietnam',
    puertoRico: '🇵🇷 Puerto Rico',
    namibia: '🇳🇦 Namibia',
    ghana: '🇬🇭 Ghana',
    swaziland: '🇸🇿 Swaziland',
    lesotho: '🇱🇸 Lesotho',
    kenya: '🇰🇪 Kenya'
  },
  [availableLangs.de]: {
    //all: '🌐 international',
    germany: '🇩🇪 Deutschland',
    austria: '🇦🇹 Österreich',
    switzerland: '🇨🇭 Schweiz',
    usa: '🇺🇸 Vereinigte Staaten',
    albania: '🇦🇱 Albanien',
    algeria: '🇩🇿 Algerien',
    argentina: ' 🇦🇷 Argentinien',
    armenia: '🇦🇲 Armenien',
    australia: '🇦🇺 Australien',
    bahrain: '🇧🇭 Bahrain',
    belarus: '🇧🇾 Weißrussland',
    belgium: '🇧🇪 Belgien',
    bolivia: '🇧🇴 Bolivien',
    bosniaHerzegovina: '🇧🇦 Bosnien und Herzegowina',
    brazil: '🇧🇷 Brasilien',
    bulgaria: '🇧🇬 Bulgarien',
    canada: ' 🇨🇦 Kanada',
    colombia: '🇨🇴 Kolumbien',
    chile: '🇨🇱 Chile',
    costaRica: '🇨🇷 Costa Rica',
    cuba: '🇨🇺 Kuba',
    croatia: ' 🇭🇷 Kroatien',
    cyprus: ' 🇨🇾 Zypern',
    czechRepublic: ' 🇨🇿 Tschechien',
    denmark: ' 🇩🇰 Dänemark',
    dominicanRepublic: '🇩🇴 Dominikanische Republik',
    ecuador: '🇪🇨 Equador',
    egypt: ' 🇪🇬 Ägypten',
    estonia: '🇪🇪 Estland',
    finland: ' 🇫🇮 Finnland',
    france: '🇫🇷 Frankreich',
    guatemala: '🇬🇹 Guatemala',
    greece: ' 🇬🇷 Griechenland',
    honduras: '🇭🇳 Honduras',
    hongkong: '🇭🇰 Hong Kong',
    hungary: '🇭🇺 Ungarn',
    iceland: ' 🇮🇸 Island',
    indonesia: '🇮🇩 Indonesien',
    iran: '🇮🇷 Iran',
    iraq: '🇮🇶 Irak',
    ireland: ' 🇮🇪 Irland',
    israel: ' 🇮🇱 Israel',
    india: '🇮🇳 Indien',
    italy: '🇮🇹 Italien',
    jamaica: '🇯🇲 Jamaika',
    japan: ' 🇯🇵 Japan',
    kuwait: '🇰🇼 Kuwait',
    latvia: '🇱🇻 Lettland',
    libya: '🇱🇾 Libyen',
    lithuania: '🇱🇹 Litauen',
    luxembourg: ' 🇱🇺 Luxemburg',
    macedonia: ' 🇲🇰 Mazedonien',
    malaysia: ' 🇲🇲 Malaysia',
    mexico: ' 🇲🇽 Mexiko',
    morocco: ' 🇲🇦 Marokko',
    netherlands: '🇳🇱 Niederlande',
    newZealand: ' 🇳🇿 Neuseeland',
    nicaragua: '🇳🇮 Nicaragua',
    nigeria: '🇳🇬 Nigeria',
    norway: ' 🇳🇴  Norwegen',
    oman: '🇴🇲 Oman',
    panama: '🇵🇦 Panama',
    paraguay: '🇵🇾 Paraguay',
    peru: '🇵🇪 Peru',
    philippines: '🇵🇭 Philippinen',
    poland: ' 🇵🇱 Polen',
    portugal: ' 🇵🇹 Portugal',
    romania: '🇷🇴 Rumänien',
    russia: '🇷🇺 Russland',
    qatar: '🇶🇦 Katar',
    saudiArabia: '🇸🇦 Saudi-Arabien',
    serbia: ' 🇷🇸 Serbien',
    singapore: '🇸🇬 Singapur',
    slovakia: '🇸🇰 Slowakei',
    slovenia: '🇸🇮 Slowenien',
    southAfrica: ' 🇿🇦 Südafrika',
    southKorea: ' 🇰🇷 Südkorea',
    spain: ' 🇪🇸 Spanien',
    sweden: ' 🇸🇪 Schweden',
    taiwan: '🇹🇼 Taiwan',
    thailand: '🇹🇭 Thailand',
    tunisia: '🇹🇳 Tunesien',
    turkey: ' 🇹🇷 Türkei',
    ukraine: ' 🇺🇦 Ukraine',
    unitedKingdom: ' 🇬🇧 Großbritannien',
    unitedArabEmirates: ' 🇿🇦 Vereinigte Arabische Emirate',
    uruguay: '🇺🇾 Uruguay',
    vietnam: '🇻🇳 Vietnam',
    puertoRico: '🇵🇷 Puerto Rico'
  },
}
