import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { refreshGrantSelector } from '../../../redux/selectors'
import { withReduxActions } from '../../../hocs/withRedux'
import { deleteAccount } from '../../../redux/ducks/userDucks'

import DeleteAccount from './DeleteAccount'

const DeleteAccountContainer = ({ deleteAccount }) => {
  const history = useHistory()
  const refreshGrants = useSelector(refreshGrantSelector)

  const [isModalOpened, setIsModalOpened] = useState(false)
  const [isSuccessDelete, setIsSuccessDelete] = useState(false)

  const toggleModal = () => setIsModalOpened(!isModalOpened)

  const confirmDeleteAccount = async () => {
    const success = await deleteAccount()
    if (success) {
      setIsSuccessDelete(true)
    }
  }

  const successDeleteAccountHandler = async () => {
    await refreshGrants()
    history.push('/search')
  }

  return (
    <DeleteAccount
      isModalOpened={isModalOpened}
      toggleModal={toggleModal}
      confirmDeleteAccount={confirmDeleteAccount}
      successDeleteAccountHandler={successDeleteAccountHandler}
      isSuccessDelete={isSuccessDelete}
    />
  )
}

DeleteAccountContainer.propTypes = {
  deleteAccount: PropTypes.func,
}

export default withReduxActions({ deleteAccount })(DeleteAccountContainer)
