import React from 'react'
import PropTypes from 'prop-types'

import useTranslation from '../../../localization/useTranslation'
import { useSelector } from 'react-redux'
import { isUserAuthenticatedSelector, userGrantSelector } from '../../../redux/selectors'
import { USER_INDEXES } from '../../../constants/appSettings'

import PostsPerWeek from './PostsPerWeek'
import ViralPosts from './ViralPosts'
import OneHitWonder from './OneHitWonder'
import OverallScore from './OverallScore'
import ExpectedAudiencePerPost from './ExpectedAudiencePerPost'
import EngagementRate from './EngagementRate'

import UnavailableProfileSection from '../../common/unavailableProfileSection'
import InfoTip from '../../common/tips/InfoTip'
import {
  SectionTitleWrapper,
  SectionTitleName,
  SectionTitleBorder,
} from '../commonProfilePageStyles'
import {
  TiktokStatisticsContainer,
  TiktokStatisticsGrid,
  ContactSalesWrapper,
} from './commonStyles'

const TiktokStatistics = ({ profile, isForPdf = false }) => {
  const { labelStrings, profileSectionDescs } = useTranslation()

  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const { no_plan: isNoPlan } = useSelector(userGrantSelector)

  const isSectionDisabled = !isUserAuthenticated || isNoPlan

  return (
    <TiktokStatisticsContainer isForPdf={isForPdf}>
      {!isForPdf && (
        <SectionTitleWrapper>
          <SectionTitleName platform={profile._index}>{labelStrings.statistics}</SectionTitleName>
          <InfoTip>
            <div dangerouslySetInnerHTML={{ __html: profileSectionDescs.tiktokStatistics }} />
          </InfoTip>
          <SectionTitleBorder isForPdf={isForPdf} />
        </SectionTitleWrapper>
      )}

      {!isSectionDisabled ? (
        <TiktokStatisticsGrid>
          <PostsPerWeek postsPerWeek={profile.postsPerWeek} isForPdf={isForPdf} />
          <ViralPosts viralCountArray={profile.viralCountArray} isForPdf={isForPdf} />
          <OneHitWonder oneHitWonder={profile.oneHitWonder} isForPdf={isForPdf} />
          <OverallScore profileScore={profile.profileScore.overallScore} isForPdf={isForPdf} />
          <ExpectedAudiencePerPost audiencePerPostValue={profile.impressions} isForPdf={isForPdf} />
          <EngagementRate
            engagementMean={Number.parseFloat(profile.engagementMean)}
            isForPdf={isForPdf}
          />
        </TiktokStatisticsGrid>
      ) : (
        <ContactSalesWrapper>
          <UnavailableProfileSection
            unavailableSectionLabel={labelStrings.toSeeStatistic}
            faqQuestionId=""
            linkToFaqText={labelStrings.whatIsInfludataStatistic}
            platform={USER_INDEXES.tiktok}
          />
        </ContactSalesWrapper>
      )}
    </TiktokStatisticsContainer>
  )
}

TiktokStatistics.propTypes = {
  profile: PropTypes.object.isRequired,
  isForPdf: PropTypes.bool,
}

export default TiktokStatistics
