import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import useOutsideClick from '../../../hooks/useOutsideClick'
import Input from './Input'
import { InputAutocompleteWrapper, AutocompleteOptions, AutocompleteOption } from './styles'

const InputAutocomplete = ({
  id = '',
  name = '',
  type = '',
  label = '',
  placeholder = '',
  initialValue = '',
  icon = null,
  options = [],
  selectOption,
  ...props
}) => {
  const [value, setValue] = useState(initialValue)
  const [isOptionsDisplayed, setIsOptionsDisplayed] = useState(false)
  const [optionsToDisplay, setOptionsToDisplay] = useState([])

  const optionsDropdownRef = useRef(null)
  useOutsideClick({ ref: optionsDropdownRef, callback: () => setIsOptionsDisplayed(false) })

  useEffect(() => {
    setOptionsToDisplay(
      options.filter(option => {
        return option.toLowerCase().startsWith(value.toLowerCase())
      })
    )
  }, [value])

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const onChange = event => {
    setValue(event.target.value)

    if (!event.target.value) {
      setIsOptionsDisplayed(false)
    } else {
      setIsOptionsDisplayed(true)
    }
  }

  const onOptionClick = option => {
    setIsOptionsDisplayed(false)
    selectOption(option)
  }

  const isEmptyOptionsToDisplay = optionsToDisplay.length === 0

  return (
    <InputAutocompleteWrapper className="input-autocomplete__wrapper">
      <Input
        id={id}
        name={name}
        type="text"
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        icon={icon}
        {...props}
      />

      {isOptionsDisplayed && !isEmptyOptionsToDisplay && (
        <AutocompleteOptions ref={optionsDropdownRef}>
          {optionsToDisplay.map((option, index) => {
            return (
              <AutocompleteOption
                key={index}
                isSelected={option === value}
                onClick={() => onOptionClick(option)}
              >
                {option}
              </AutocompleteOption>
            )
          })}
        </AutocompleteOptions>
      )}
    </InputAutocompleteWrapper>
  )
}

InputAutocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  selectOption: PropTypes.func,
}

export default InputAutocomplete
