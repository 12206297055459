import styled, { keyframes } from 'styled-components'

export const ImageWrapper = styled.div`
  position: relative;
  width: 95px;
  height: 95px;
`
export const loadingAnimation = keyframes`
  0% {
    background-color: #EED9D9;
  }
  50% {
    background-color: #BC9797;
  }
  100% {
    background-color: #EED9D9;
  }
`
export const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: ${loadingAnimation} 1s infinite;
  border-radius: 10px;
`
export const StyledImage = styled.img`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`