import { LoadingButton } from '@mui/lab'
import { Grid, Box, Stack } from '@mui/material'
import React from 'react'
import { RHFMultiCheckbox } from '../../../components/hook-form'
import FormProvider from '../../../components/hook-form/FormProvider'
import { StyledHeading, StyledSubheader, StyledButton } from './styles.js'

export const Fields = props => {
  const {
    methods,
    isSubmitting,
    //
    onSubmit,
    handleSubmit,
    handleClose,
    //
    cpmFields,
    cpeOrganicFields,
    emvFields,
    overviewFields,
    customTrackingFields,
    goalsFields,
    reachedAudienceFields,
    sentimentFields,
    publishedContentFields,
    mainMetricsTableFields,
  } = props

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Box sx={{ marginTop: 0, minHeight: '540px' }}>
              {(!!overviewFields?.length || !!customTrackingFields?.length) && (
                <Box>
                  {!!overviewFields?.length && (
                    <>
                      <StyledHeading>Overview</StyledHeading>
                      <RHFMultiCheckbox
                        row
                        name="overview"
                        spacing={3}
                        defaultValue={false}
                        sx={{ marginLeft: 0.5 }}
                        options={overviewFields}
                      />
                    </>
                  )}

                  {!!customTrackingFields?.length && (
                    <Box>
                      <StyledSubheader sx={{ fontWeight: 600 }}>Custom Tracking</StyledSubheader>
                      <RHFMultiCheckbox
                        row
                        name="customTracking"
                        spacing={3}
                        defaultValue={false}
                        sx={{ marginLeft: 0.5 }}
                        options={customTrackingFields}
                      />
                    </Box>
                  )}
                </Box>
              )}

              {!!cpmFields?.length && (
                <Box sx={{ marginTop: 3 }}>
                  <StyledHeading sx={{ marginBottom: '3px' }}>CPM Organic</StyledHeading>
                  <RHFMultiCheckbox
                    row
                    name="cpmCpe"
                    spacing={3}
                    defaultValue={false}
                    sx={{ marginLeft: 0.5 }}
                    options={cpmFields}
                  />
                </Box>
              )}

              {!!cpeOrganicFields?.length && (
                <Box sx={{ marginTop: 3 }}>
                  <StyledHeading sx={{ marginBottom: '3px' }}>CPE Organic</StyledHeading>
                  <RHFMultiCheckbox
                    row
                    name="cpeOrganic"
                    spacing={3}
                    defaultValue={false}
                    sx={{ marginLeft: 0.5 }}
                    options={cpeOrganicFields}
                  />
                </Box>
              )}

              {!!emvFields?.length && (
                <Box sx={{ marginTop: 3 }}>
                  <StyledHeading sx={{ marginBottom: '3px' }}>EMV</StyledHeading>
                  <RHFMultiCheckbox
                    row
                    name="emv"
                    spacing={3}
                    defaultValue={false}
                    sx={{ marginLeft: 0.5 }}
                    options={emvFields}
                  />
                </Box>
              )}

              {!!goalsFields?.length && (
                <Box sx={{ marginTop: 3 }}>
                  <StyledHeading sx={{ marginBottom: '3px' }}>Goals</StyledHeading>
                  <RHFMultiCheckbox
                    row
                    name="goals"
                    spacing={3}
                    defaultValue={false}
                    sx={{ marginLeft: 0.5 }}
                    options={goalsFields}
                  />
                </Box>
              )}

              {!!sentimentFields?.length && (
                <Box sx={{ marginTop: 3 }}>
                  <StyledHeading sx={{ marginBottom: '3px' }}>Sentiment</StyledHeading>
                  <RHFMultiCheckbox
                    row
                    name="sentiment"
                    spacing={3}
                    defaultValue={false}
                    sx={{ marginLeft: 0.5 }}
                    options={sentimentFields}
                  />
                </Box>
              )}

              {(!!reachedAudienceFields?.length || !!reachedAudienceFields?.length) && (
                <Box sx={{ marginTop: 3 }}>
                  <StyledHeading sx={{ marginBottom: '3px' }}>Content</StyledHeading>
                  {!!publishedContentFields?.length && (
                    <Box>
                      <StyledSubheader sx={{ fontWeight: 600 }}>Published Content</StyledSubheader>
                      <RHFMultiCheckbox
                        row
                        name="publishedContent"
                        spacing={3}
                        sx={{ marginLeft: 0.5 }}
                        options={publishedContentFields}
                      />
                    </Box>
                  )}

                  {!!reachedAudienceFields?.length && (
                    <Box sx={{ marginTop: 1 }}>
                      <StyledSubheader sx={{ fontWeight: 600 }}>Reached Audience</StyledSubheader>
                      <RHFMultiCheckbox
                        row
                        name="reachedAudience"
                        spacing={3}
                        sx={{ marginLeft: 0.5 }}
                        options={reachedAudienceFields}
                      />
                    </Box>
                  )}

                  {!!mainMetricsTableFields?.length && (
                    <Box sx={{ marginTop: 3 }}>
                      <StyledHeading sx={{ marginBottom: '3px' }}>Campaign Metrics</StyledHeading>
                      <RHFMultiCheckbox
                        row
                        name="main_metrics_table"
                        spacing={3}
                        defaultValue={false}
                        sx={{ marginLeft: 0.5 }}
                        options={mainMetricsTableFields}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>

            <Stack
              direction="row"
              spacing={1.5}
              alignItems="flex-center"
              justifyContent="flex-end"
              sx={{ my: 2 }}
            >
              <StyledButton variant="outlined" sx={{ fontWeight: 'bold' }} onClick={handleClose}>
                Cancel
              </StyledButton>

              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                Confirm
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  )
}
