import React, { useCallback } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { typographyStyles } from '../../../commonStyle'
import Chart, { useChart } from '../../../../../../../new-ui/components/chart'
import { fNumber } from '../../../../../../../new-ui/utils/formatNumber'
import { pretifyBigNum } from '../../../../../../../utils'
import { fDate } from '../../../../../../../new-ui/utils/formatTime'
import useTranslation from '../../../../../../../localization/useTranslation'
import PdfPieChart from './PdfPieChart'

export const MiddleChartSection = props => {
  const { metadata } = props
  const { creatorTypes, reachEmvBarGraphData, creatorScores } = metadata || {}

  const { labelStrings } = useTranslation()

  const creatorTypesSum = Object.values(creatorTypes).reduce((acc, val) => acc + val, 0)
  const creatorScoresSum = Object.values(creatorScores?.values).reduce((acc, val) => acc + val, 0)

  const creatorTypesChartData = useCallback(() => {
    const namesMap = {
      macro: {
        text: `macro`,
        color: '#CBB3B3',
      },
      mega: {
        text: `mega`,
        color: '#784E4E',
      },
      micro: {
        text: `micro`,
        color: '#FFBC00',
      },
      nano: {
        text: `nano`,
        color: '#FF5D5A',
      },
    }

    return Object.keys(creatorTypes)?.reduce(
      (finalObj, item) => {
        if (creatorTypes[item] > 0) {
          finalObj.values.push([`${namesMap[item].text.toUpperCase()}`, creatorTypes[item]])
          finalObj.colors.push(namesMap[item].color)
        }

        return finalObj
      },
      { values: [['Type', 'Value']], colors: [] }
    )
  }, [creatorTypes])

  const creatorScoresChartData = useCallback(() => {
    const namesMap = {
      veryHigh: {
        text: `Very High`,
        color: '#057B2D',
      },
      high: {
        text: `High`,
        color: `#6CB75B`,
      },
      medium: {
        text: `Medium`,
        color: `#FFBC00`,
      },
      low: {
        text: `Low`,
        color: `#FF8D06`,
      },
      veryLow: {
        text: `Very Low`,
        color: '#EA3323',
      },
    }

    const CreatorsScoresType = creatorScores?.values

    return Object.keys(CreatorsScoresType)?.reduce(
      (finalObj, item) => {
        if (CreatorsScoresType[item] > 0) {
          finalObj.values.push([`${namesMap[item].text.toUpperCase()}`, CreatorsScoresType[item]])
          finalObj.colors.push(namesMap[item].color)
        }

        return finalObj
      },
      { values: [['Type', 'Value']], colors: [] }
    )
  }, [creatorScores?.values])

  const reachEmvGraphDates = reachEmvBarGraphData?.map(el => el.date)
  const reachEmvGraphValues = reachEmvBarGraphData?.map(el => el.value)
  const reachEmvGraphReach = reachEmvBarGraphData?.map(el => el.reach)

  const barChartOptions = type =>
    useChart({
      tooltip: {
        marker: { show: false },
        y: {
          formatter: value => `${fNumber(value)} ${type === 'emv' ? 'EUR' : 'Views'}`, // Append "Views" to each value
          title: {
            formatter: () => '',
          },
        },
      },
      chart: {
        type: 'bar',
      },
      xaxis: {
        categories: reachEmvGraphDates,
        labels: {
          formatter: function(value) {
            return `${fDate(value, 'MMM yy')}`
          },
          style: {
            colors: Array.from({ length: reachEmvGraphDates?.length }, () => '#784E4E'),
          },
        },
        axisBorder: {
          show: true,
          color: '#CBB3B3',
          offsetX: 0,
          offsetY: 0,
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
          colorStops: [
            {
              offset: 0.35,
              color: '#FFBC00',
              opacity: 1,
            },
            {
              offset: 100,
              color: '#FF5D5A',
              opacity: 1,
            },
          ],
        },
      },
      yaxis: {
        show: true,
        axisBorder: {
          show: true,
          color: '#CBB3B3',
          offsetX: 0,
          offsetY: 0,
        },
        labels: {
          formatter: function(value) {
            return `${pretifyBigNum(value)}`
          },
          style: {
            colors: Array.from({ length: reachEmvGraphDates?.length }, () => '#784E4E'),
          },
        },
      },
      grid: {
        show: true,
        borderColor: '#FFBC00',
        strokeDashArray: 0,
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      stroke: {
        show: false,
      },
    })

  return (
    <Stack direction="column" spacing={1}>
      <Stack sx={{ width: '100%', height: '100%' }} spacing={2}>
        <Stack direction="row" spacing={2}>
          <Stack sx={{ width: '50%' }}>
            <Stack spacing={2} sx={{ maxHeight: '272px' }}>
              <Typography sx={typographyStyles}>
                {labelStrings.brandsTimelineText.creatorInfludataScores}
              </Typography>

              <Box>
                <PdfPieChart
                  chartData={creatorScoresChartData()}
                  isDisabledChart={false}
                  notEnoughData={false}
                  sumValues={creatorScoresSum}
                  chartHeight={250}
                  chartWidth={250}
                  totalValue={creatorScores?.median}
                  withCustomLegend
                />
                <Typography
                  textAlign="center"
                  sx={{ px: 1, fontSize: '12px', color: '#442424', fontWeight: 'bold', mt: -2 }}
                >{`Very High Score > 8.5 | High Score > 7 | Medium Score > 5 | Low Score > 3 | Very Low Score < 3`}</Typography>
              </Box>
            </Stack>
            <Stack spacing={2} sx={{ maxHeight: '272px', mt: '32px' }}>
              <Typography sx={typographyStyles}>
                {labelStrings.brandsTimelineText.creatorsType}
              </Typography>

              <Box>
                <PdfPieChart
                  chartData={creatorTypesChartData()}
                  withCustomLegend
                  isDisabledChart={false}
                  isAudienceTypeChart
                  notEnoughData={false}
                  sumValues={creatorTypesSum}
                  chartHeight={250}
                  chartWidth={250}
                />
                <Typography
                  textAlign="center"
                  sx={{ px: 1, mt: -5, fontSize: '12px', color: '#442424', fontWeight: 'bold' }}
                >{`Nano <10k ${labelStrings?.followers} | Micro <100k ${labelStrings?.followers} | Macro <500k ${labelStrings?.followers} | Mega >500k ${labelStrings?.followers}`}</Typography>
              </Box>
            </Stack>
          </Stack>

          <Stack sx={{ width: '50%' }} spacing={2}>
            {!!reachEmvGraphReach?.length && (
              <Stack>
                <Typography sx={{ ...typographyStyles, mb: 1 }}>
                  {labelStrings.brandsTimelineText.reachFromCreatorsLabelChart}
                </Typography>
                <Box>
                  <Chart
                    type="bar"
                    series={[{ data: reachEmvGraphReach || [] }]}
                    options={barChartOptions('reach')}
                    height={230}
                  />
                </Box>
              </Stack>
            )}

            {!!reachEmvGraphValues?.length && (
              <Stack sx={{ position: 'relative' }}>
                <Typography sx={{ ...typographyStyles, mb: 1 }}>
                  {labelStrings.brandsTimelineText.emvLabelChart}
                </Typography>
                <Chart
                  type="bar"
                  series={[{ data: reachEmvGraphValues || [] }]}
                  options={barChartOptions('emv')}
                  height={230}
                />
                <Typography
                  sx={{
                    position: 'absolute',
                    bottom: '25px',
                    left: '35px',
                    fontSize: '11px',
                    color: '#784E4E',
                  }}
                >
                  €
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
