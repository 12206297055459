import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useTranslation from '../../../localization/useTranslation'
import { openedCollectionSelector, collectionsSelector } from '../../../redux/selectors'
import { unlockAudienceReports } from '../../../redux/ducks/collectionsDucks'
import { withReduxActions } from '../../../hocs/withRedux'
import { InfoCircleIcon } from '../../common/icons/icons'
import * as Styled from './styles'
import Tabs from '../../common/tabs/Tabs'
import { DonutChart } from './Charts/DonutChart'
import { HeatMapChart } from './Charts/HeatMapChart'
import CollectionUnlockBtn from './CollectionUnlockBtn'

const tabs = [
  { id: 'instagram', content: 'instagram' },
  { id: 'tiktok', content: 'tiktok' },
]

const CollectionOverlap = ({ unlockAudienceReports }) => {
  const { labelStrings } = useTranslation()

  const openedCollection = useSelector(openedCollectionSelector)
  const userCollections = useSelector(collectionsSelector)

  const currentCollection = userCollections?.find(
    collection => collection.collectionId === openedCollection.id
  )

  const { userdata, metadata } = openedCollection.users
  const { audienceReport } = metadata

  const instaOverlapData = metadata?.audienceReport?.followerOverlap?.instaOverlap
  const tiktokOverlapData = metadata?.audienceReport?.followerOverlap?.tiktokOverlap

  const { toUnlockArray, unlockedButUnavailableCount, followerOverlap } = audienceReport || {}
  const { instaOverlap, tiktokOverlap } = followerOverlap || {}

  const collectionLength = userdata?.length
  const audienceTotalInfluencers =
    currentCollection?.count - toUnlockArray?.length - unlockedButUnavailableCount
  const unlockedUsersLength = currentCollection?.count - toUnlockArray?.length
  const isAllInfluecnersUnlocked = collectionLength === unlockedUsersLength

  const [activeTabId, setActiveTabId] = useState(!!instaOverlap ? 'instagram' : 'tiktok')
  const switchTab = id => setActiveTabId(id)

  return (
    <Styled.Container>
      <Styled.TabsWrapper>
        <Tabs
          tabs={tabs}
          activeTabId={activeTabId}
          switchTab={switchTab}
          igDisabled={!!instaOverlap}
          ttDisabled={!!tiktokOverlap}
        />
      </Styled.TabsWrapper>
      <Styled.AudienceOverlapInfo>
        <Styled.Row>
          <Styled.TotalInfluencers>
            <Styled.Label>{labelStrings.totalInfluencers}:</Styled.Label>
            &ensp;
            <Styled.Value>{audienceTotalInfluencers}</Styled.Value>
          </Styled.TotalInfluencers>

          <Styled.UnlockedInfluencers>
            <Styled.IconWrapper>
              <InfoCircleIcon isGradient />
            </Styled.IconWrapper>
            <Styled.UnlockedInfluencersText>
              {isAllInfluecnersUnlocked
                ? labelStrings.allInfluencersAreUnlocked
                : labelStrings.onlyNFromNInfluencersAreUnlocked(
                    unlockedUsersLength,
                    currentCollection?.count
                  )}
            </Styled.UnlockedInfluencersText>
            {!isAllInfluecnersUnlocked && (
              <>
                &ensp;
                <CollectionUnlockBtn
                  confirmAction={unlockAudienceReports}
                  unlockedReportsCount={toUnlockArray?.length}
                />
                &ensp;
                <Styled.UnlockedInfluencersText>
                  {labelStrings.toShowExactMetrics}
                </Styled.UnlockedInfluencersText>
              </>
            )}
            &ensp;
            {unlockedButUnavailableCount !== 0 && (
              <Styled.UnlockedInfluencersText>
                {labelStrings.missingReports}
              </Styled.UnlockedInfluencersText>
            )}
          </Styled.UnlockedInfluencers>
        </Styled.Row>
      </Styled.AudienceOverlapInfo>
      <Styled.OverlapChartsWrapper>
        {(() => {
          switch (activeTabId) {
            case 'instagram':
              return (
                <Styled.ChartWrapper>
                  <DonutChart
                    totalOverlap={instaOverlapData?.totalOverlap}
                    tabSelect={activeTabId}
                  />
                  <HeatMapChart
                    metadata={instaOverlapData?.collectionOverlap}
                    userdata={userdata}
                    tabSelect={activeTabId}
                  />
                </Styled.ChartWrapper>
              )
            case 'tiktok':
              return (
                <Styled.ChartWrapper>
                  <DonutChart
                    totalOverlap={tiktokOverlapData?.totalOverlap}
                    tabSelect={activeTabId}
                  />
                  <HeatMapChart
                    metadata={tiktokOverlapData?.collectionOverlap}
                    userdata={userdata}
                    tabSelect={activeTabId}
                  />
                </Styled.ChartWrapper>
              )
          }
        })()}
      </Styled.OverlapChartsWrapper>
    </Styled.Container>
  )
}

CollectionOverlap.propTypes = {
  unlockAudienceReports: PropTypes.func,
}

export default withReduxActions({ unlockAudienceReports })(CollectionOverlap)
