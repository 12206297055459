import styled from 'styled-components'
import { RoundedInput, Label } from '../index'

export const CheckboxWrapper = styled.div`
  padding: 0 10px;
  & label {
    color: ${props => props.theme.color.lightBlack};
    font-size: 14px;
  }
`
export const Form = styled.form`
  height: 100%;
  maxHeight: 280px;
  position: relative;
`

export const EmailInput = styled(RoundedInput)`
  width: 396px;
  height: 45px;
  ${props =>
    props.notValid &&
    `
    border-color: ${props.theme.primaryColor};
    color: ${props.theme.primaryColor};
  `}
`
export const EmailLabel = styled(Label)`
  font-size: 13px;
`

export const EmailInputWrapper = styled.div`
  height: 100%;
  background: ${props => props.theme.color.lightPink};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 10px 0;
`

export const EmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SubmitWrapper = styled.div`
  margin-bottom: 6px;
`

export const NotValidEmailWrapper = styled.span`
  position: absolute;
  top: 60px;
  left: ${props => props.left || 24}%;
  color: ${props => props.theme.primaryColor};
  font-size: 14px;
`
