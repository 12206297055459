import React from 'react'
import useTranslation from '../../localization/useTranslation'
import { Button, Stack, Typography } from '@mui/material'

export const ToastAddCreatorToCampaign = () => {
  const { labelStrings } = useTranslation()
  return (
    <Stack spacing={2.5} alignItems="center">
      <Typography>{labelStrings.addCreateToCampaignPopup}</Typography>
      <Button sx={{ minWidth: '120px', color: 'black', borderColor: 'black', '&:hover': {
        borderColor: 'black',
        opacity: 0.5
      } }} variant="outlined">{labelStrings.close}</Button>
    </Stack>
  )
}
