import React, { useEffect, useRef, useState } from 'react'
import { Stack, TextField, Button, Box, Typography } from '@mui/material'
import useTranslation from '../../../../localization/useTranslation'

export const AddLabel = props => {
  const { onConfirmLastStep } = props || {}
  const { labelStrings } = useTranslation()

  const inputRef = useRef(null)

  const [labelValue, setLabelValue] = useState('')

  const onHandleChangeLabel = ({ target }) => {
    setLabelValue(target.value)
  }

  useEffect(() => {
    setLabelValue('')
    inputRef?.current?.focus()
  }, [])

  return (
    <Stack spacing={3} sx={{ width: '100%' }} alignItems="center">
      <TextField
        inputRef={inputRef}
        autoFocus
        sx={{ maxWidth: '250px' }}
        value={labelValue}
        onChange={onHandleChangeLabel}
      />
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', mb: '16px !important' }}>
          <Stack direction="column" alignItems="center">
            <Typography sx={{ whiteSpace: 'pre-line', fontSize: '15px', textAlign: 'center', pb: 2 }}>
              {labelStrings?.buildLookalike?.willTake5Minutes}
            </Typography>
              <Button
              disabled={!labelValue?.length}
              variant="contained"
              onClick={() => onConfirmLastStep({ label: labelValue })}
            >
              {labelStrings?.confirm}
            </Button>
        </Stack>
      </Box>
    </Stack>
  )
}
