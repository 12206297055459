import React from 'react'
import { Stack, Typography } from '@mui/material'

export const TopControls = () => {
  return (
    <Stack>
      <Stack>
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: '18px',
            fontWeight: 700,
            lineHeight: '28px',
            mb: 0.5,
          }}
        >
          Campaign Pipeline
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '22px',
            maxWidth: '943px',
            color: '#637381',
          }}
        >
          You can use this pipeline to onboard or evaluate creators that are not yet active in your
          campaign. Feel free to rename the stages and add creators from the search, your
          collections or directly from here. Newly added creators will always be added to your first
          stage.
        </Typography>
      </Stack>
    </Stack>
  )
}
