import React, { memo, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { prepareProfileChartData } from '../../utils/profileCharts'
import { USER_INDEXES } from '../../constants/appSettings'
import ProfileOverview from './profileOverview/ProfileOverview'
import NotesAndAdditionalInfo from './notesAndAdditionalInfo/NotesAndAdditionalInfo'
import InfludataQualityRating from './infludataQualityRating'
import LatestPostsGallery from './latestPostsGallery/LatestPostsGallery'
import SimilarAccounts from './similarAccounts/SimilarAccounts'
import FollowersHistory from './followersHistory/FollowersHistory'
import ViewsPerPost from './viewsPerPost/ViewsPerPost'
import DetailedInfludataRating from './detailedInfludataRating/DetailedInfludataRating'
import AudienceAnalysisMediator from './audienceAnalysis/AudienceAnalysisMediator'
import { MaxWidthContainer, Section, FlexRow } from './commonProfilePageStyles'
import DetailedInfludataRatingYT from './detailedInfludataRating/DetailedInfludataRatingYT'
import DetailedInfludataRatingTikTok from './detailedInfludataRating/DetailedInfludataRatingTikTok'
import { changeAppSocialPlatform } from '../../redux/ducks/appSettingsDucks'
import { selectedPlatformSelector } from '../../redux/selectors'

const ProfileContainer = ({ profile }) => {
  const { _index: platform, dataLog, isPrivate, creatorCollaborations } = profile

  const dispatch = useDispatch()

  const currentPlatform = useSelector(selectedPlatformSelector)

  const chartData = useMemo(() => prepareProfileChartData(dataLog, platform), [dataLog, platform])

  let profileScore
  if (!isPrivate) {
    switch (platform) {
      case USER_INDEXES.instagram:
        profileScore = profile.profileScore.overallScore
        break
      case USER_INDEXES.tiktok:
        profileScore = profile.profileScore.overallScore
        break
      case USER_INDEXES.youtube:
        profileScore = profile.profileScore.overallScore
    }
  } else {
    profileScore = 0
  }

  useEffect(() => {
    if (platform?.toLowerCase() !== currentPlatform?.toLowerCase()) {
      // @ts-ignore
      dispatch(changeAppSocialPlatform(platform?.toUpperCase()))
    }
  }, [])

  return (
    <div className="profile-container">
      <ProfileOverview profile={profile} />

      <MaxWidthContainer>
        <FlexRow>
          <Section isLeft>
            <NotesAndAdditionalInfo profile={profile} />

            {(() => {
              switch (platform) {
                case USER_INDEXES.instagram:
                  return (
                    <>
                      <FollowersHistory
                        chartData={chartData.followers}
                        platform={profile._index}
                        monthlyGrowthFollowers={profile.monthlyGrowthFollowers}
                      />
                      {!isPrivate && (
                        <InfludataQualityRating
                          overallScore={profileScore}
                          platform={profile._index}
                          creatorCollaborations={creatorCollaborations}
                        />
                      )}
                    </>
                  )
                case USER_INDEXES.tiktok:
                  if (isPrivate || !profile.monthlyGrowthObject) return null
                  return (
                    <>
                      {/* <TiktokStatistics profile={profile} /> */}
                      <FollowersHistory
                        chartData={chartData.followers}
                        platform={profile._index}
                        monthlyGrowthFollowers={[
                          profile.monthlyGrowthObject.monthlyDifference,
                          profile.monthlyGrowthObject.monthlyChange,
                        ]}
                      />
                      <ViewsPerPost
                        chartData={chartData.views}
                        platform={platform}
                        monthlyGrowthFollowers={[
                          profile.monthlyGrowthObject.viewsDifference,
                          profile.monthlyGrowthObject.viewsChange,
                        ]}
                      />
                      {!isPrivate && (
                        <InfludataQualityRating
                          overallScore={profileScore}
                          platform={profile._index}
                        />
                      )}
                    </>
                  )
                case USER_INDEXES.youtube:
                  return (
                    <>
                      <FollowersHistory
                        chartData={chartData.followers}
                        platform={profile._index}
                        monthlyGrowthFollowers={profile.monthlyGrowthFollowers}
                      />
                      <FollowersHistory
                        platform={profile._index}
                        monthlyGrowthFollowers={profile.monthlyGrowthObject}
                        allChartData={chartData}
                        isYt
                      />
                      {!isPrivate && (
                        <InfludataQualityRating
                          overallScore={profileScore}
                          platform={profile._index}
                        />
                      )}
                    </>
                  )
                default:
                  return null
              }
            })()}
          </Section>

          {!isPrivate && (
            <Section isRight>
              <LatestPostsGallery profile={profile} />
              <SimilarAccounts profileId={profile._id} platform={profile._index} />
            </Section>
          )}
        </FlexRow>

        {platform === USER_INDEXES.instagram && !isPrivate && (
          <Section>
            <DetailedInfludataRating profile={profile} />
          </Section>
        )}

        {platform === USER_INDEXES.tiktok && !isPrivate && (
          <Section>
            <DetailedInfludataRatingTikTok profile={profile} />
          </Section>
        )}

        {platform === USER_INDEXES.youtube && !isPrivate && (
          <Section>
            <DetailedInfludataRatingYT profile={profile} />
          </Section>
        )}

        {!isPrivate && (
          <Section>
            <AudienceAnalysisMediator profile={profile} />
          </Section>
        )}
      </MaxWidthContainer>
    </div>
  )
}

ProfileContainer.propTypes = {
  profile: PropTypes.object.isRequired,
}

export default memo(ProfileContainer)
