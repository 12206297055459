import React, { Fragment } from 'react'
import NewPageLayout from '../../../../../components/influencerProfile/profilePdfDocument/PageLayout/NewPageLayout'
import { SentimentPdf } from '../SentimentPdf'
import { Avatar, Box, Card, CardHeader, Paper, Stack, Typography } from '@mui/material'
import { LatestSummariesItem } from '../../../../components/carousel/comments/LatestSummariesItem'
import { ColorfulSmallCardWidget } from '../../../../components/cardWidgets/ColorfulSmallCardWidget'
import { getUrlWithProxy, pretifyBigNum } from '../../../../../utils'
import { fDateTime } from '../../../../utils/formatTime'
import { StyledCardTitle } from '../../../../components/carousel/published-content/styles'
import { fShortenNumber } from '../../../../utils/formatNumber'
import { getPercent } from '../../../../components/carousel/published-content/utils'
import Label from '../../../../components/label/Label'

export const FourthPdfPage = ({
  settings,
  latestSummariesData,
  goalsData,
  publishedContentElementsData,
  publishedContentElementsCount,
  publishedContentElementsTextLabel
}) => {
  return (
    <NewPageLayout>
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <Card sx={{ border: '1px solid rgba(145, 158, 171, 0.24)' }}>
          <CardHeader title={`Latest Comments with Brand Mentions`} />
          <LatestSummariesItem
            item={latestSummariesData[0]}
            isPdf
          />
        </Card>
        <Stack spacing={2}>
          {goalsData?.map(goal => {
            return (
              <Fragment key={goal.graphId}>
                <ColorfulSmallCardWidget
                  title={goal?.goalName}
                  total={goal?.goalAbsoluteValue}
                  metricLabel={goal?.metricLabel}
                  dateRange={goal?.dateRange}
                  subText={goal?.subtext}
                  goalLevel={goal.goalLevel}
                  icon="ic:round-trending-up"
                  reachedRate={goal?.goalReachedRate * 100}
                  chart={{
                    series: Math.ceil(goal?.goalReachedRate * 100),
                  }}
                  sx={{
                    width: '100%',
                  }}
                  isPdf
                />
              </Fragment>
            )
          })}
        </Stack>
      </Stack>
      <Card sx={{ mt: 2 }}>
        <CardHeader
          title={`Latest Published Content & Reach`}
          subheader={`${publishedContentElementsCount} content pieces in the ${publishedContentElementsTextLabel}`}
        />
        <Stack direction="row" spacing={1} sx={{ mt: 3 }} justifyContent="space-between">
          {publishedContentElementsData?.map(el => (
            <Paper
              sx={{ p: 1.5, border: '1px solid rgba(145, 158, 171, 0.24)' }}
              key={el.contentId}
            >
              <Stack direction="row" alignItems="center" spacing={2} sx={{ position: 'relative' }}>
                <Box>
                  <Avatar alt={el?.username} src={getUrlWithProxy(el?.profilePicUrl)} />
                </Box>
                <Box>
                  <Typography variant="subtitle2">{el?.username}</Typography>

                  <Typography
                    variant="caption"
                    sx={{ color: 'text.disabled', mt: 0.5, display: 'block' }}
                  >
                    {fDateTime(el?.uploaded)}
                  </Typography>
                </Box>
              </Stack>

              <Stack
                direction="column"
                alignItems="center"
                spacing={1}
                sx={{ mt: 2, mb: 1, maxWidth: '210px', mx: 'auto' }}
              >
                <Stack direction="row" alignItems="flex-start" sx={{ width: '100%' }} spacing={2}>
                  {typeof el?.likes !== 'undefined' && el?.likes !== null && (
                    <Typography variant="caption">
                      <StyledCardTitle>Likes:</StyledCardTitle> {fShortenNumber(el?.likes)}
                    </Typography>
                  )}
                  {typeof el?.comments !== 'undefined' && el?.comments !== null && (
                    <Typography variant="caption">
                      <StyledCardTitle>Comments: </StyledCardTitle>
                      {fShortenNumber(el?.comments)}
                    </Typography>
                  )}
                </Stack>
                <Stack direction="row" alignItems="flex-start" sx={{ width: '100%' }} spacing={2}>
                  <Typography variant="caption">
                    <StyledCardTitle>Reach:</StyledCardTitle>
                    {fShortenNumber(el?.reach)}
                  </Typography>
                  <Typography variant="caption">
                    <StyledCardTitle>Value:</StyledCardTitle>{' '}
                    {`${pretifyBigNum(el?.valueFrom)} - ${pretifyBigNum(el?.valueTo)} ${
                      settings?.campaignCurrency
                    }`}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="flex-start" sx={{ width: '100%' }}>
                  <Typography variant="caption">
                    <StyledCardTitle>Found:</StyledCardTitle>
                    {el?.found?.map((el, idx) =>
                      idx === el?.found?.length - 1 ? el : `${el},${' '}`
                    )}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="flex-start" sx={{ width: '100%' }}>
                  {typeof el?.engagementRate !== 'undefined' &&
                    el?.engagementRate !== null &&
                    el?.engagementRate !== 0 && (
                      <Typography variant="caption">
                        <StyledCardTitle>Engagement Rate:</StyledCardTitle>
                        {getPercent(el?.engagementRate || 0)}
                      </Typography>
                    )}
                </Stack>
              </Stack>

              <Box
                sx={{
                  height: '230px',
                  width: '210px',
                  margin: '0 auto',
                  '& img': {
                    width: '100%',
                    height: '100%',
                    borderRadius: '8px',
                  },
                  position: 'relative',
                }}
              >
                <img
                  src={getUrlWithProxy(el?.imageUrls?.length ? el?.imageUrls[0] : el?.imageUrl)}
                  alt="content-img"
                />
                <Stack
                  direction="row"
                  justifyContent="end"
                  alignItems="center"
                  sx={{
                    width: '100% !important',
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    padding: '10px',
                  }}
                >
                  <Label
                    variant="filled"
                    color={
                      (el.contentType === 'shorts' && 'error') ||
                      (el.contentType === 'reel' && 'info') ||
                      (el.contentType === 'video' && 'secondary') ||
                      'warning'
                    }
                  >
                    <Typography sx={{ fontSize: '12px', fontWeight: 'bold', py: 1, mb: 1.5 }}>
                      {el.contentType}
                    </Typography>
                  </Label>
                </Stack>
              </Box>
            </Paper>
          ))}
        </Stack>
      </Card>
    </NewPageLayout>
  )
}
