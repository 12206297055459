// @ts-nocheck
import React, { useEffect } from 'react'
import { Dashboard } from './Dashboard'
import { useDispatch, useSelector } from 'react-redux'
import { getPreviewCampaignDashboardData } from '../../../redux/ducks/campaignsDucks'
import { useParams, useLocation } from 'react-router-dom'
import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material'
import Scrollbar from '../../components/scrollbar/Scrollbar'
import LoadingSpinner from '../../../components/common/spinners/LoadingSpinner'
import { campaignDashboard } from '../../../redux/selectors'
import BlockIcon from '@mui/icons-material/Block'
import useTranslation from '../../../localization/useTranslation'

export const DashboardPreview = () => {
  const { labelStrings } = useTranslation()
  const { campaignId } = useParams()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const { error, isLoading } = useSelector(campaignDashboard)

  const params = new URLSearchParams(search)
  const dashboardIdFromQueryURL = params.get('dashboardId')
  const campaignIdFromQueryURL = params.get('campaignId')
  const dashboardTokenFromQueryURL = params.get('dashboardToken')

  useEffect(() => {
    if (dashboardIdFromQueryURL && campaignIdFromQueryURL && dashboardTokenFromQueryURL) {
      dispatch(
        getPreviewCampaignDashboardData({
          campaignId: campaignIdFromQueryURL || campaignId,
          dashboardId: dashboardIdFromQueryURL,
          dashboardToken: dashboardTokenFromQueryURL,
        })
      )
    }
  }, [dashboardIdFromQueryURL, campaignIdFromQueryURL, dashboardTokenFromQueryURL])

  if (!!error) {
    return (
      <Stack alignItems="center" justifyContent="center" sx={{ height: '100vh' }}>
        <Box>
          <SvgIcon sx={{ color: '#FF5D5A', fontSize: '48px' }}>
            <BlockIcon />
          </SvgIcon>
        </Box>
        <Stack spacing={0.5} justifyContent="center" alignItems="center">
          <Typography align="center" sx={{ fontWeight: 'bold', fontSize: '22px' }}>
            {labelStrings.pageNotAllowed}
          </Typography>
          <Button
            onClick={() => (window.location.href = '/')}
            sx={{ mt: '8px !important', width: '200px', py: 1 }}
            variant="contained"
          >
            {labelStrings.goHome}
          </Button>
        </Stack>
      </Stack>
    )
  }

  return (
    <Box>
      {isLoading && <LoadingSpinner showOnFullPage isTransparent isInsidePageLoad />}

      <Scrollbar
        sx={{
          maxHeight: `100vh`,
          width: '100%',
        }}
      >
        <Container disableGutters maxWidth={false} sx={{ maxWidth: '1366px' }}>
          <Dashboard isPreview />
        </Container>
      </Scrollbar>
    </Box>
  )
}
