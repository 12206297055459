import React from 'react'
import { ModalComponent } from '../../../components/modal/ModalComponent'
import { Button, Stack, Typography } from '@mui/material'
import useTranslation from '../../../../localization/useTranslation'

export const ModalDeleteCampaign = props => {
  const { isOpen, onClose, campaignName, onDeleteCampaign } = props
  const { labelStrings } = useTranslation()

  return (
    <ModalComponent
      contentWidth={`500px`}
      contentHeight={`290px`}
      isOpenModal={isOpen}
      closeModal={onClose}
      isCenterTitle
    >
      <Stack spacing={2} sx={{ p: 2 }}>
        <Stack alignItems="center">
          <Typography sx={{ fontWeight: 'bold' }} textAlign="start">
            {labelStrings.confirmDeletingCampaign}
          </Typography>
          <Typography
            sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}
          >{`"${campaignName}"`}</Typography>
        </Stack>
        <Typography textAlign="center" sx={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}>
          {labelStrings.deletingModalBodyText}
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: '32px !important' }}
        >
          <Button variant="outlined" onClick={onClose}>
            {labelStrings.cancel}
          </Button>
          <Button variant="contained" onClick={onDeleteCampaign}>
            {labelStrings.deleteCampaign}
          </Button>
        </Stack>
      </Stack>
    </ModalComponent>
  )
}
