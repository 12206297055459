import React from 'react'
import { ModalComponent } from '../../../components/modal/ModalComponent'
import useTranslation from '../../../../localization/useTranslation'
import { Stack, Button, Box } from '@mui/material'

export const DeleteSuggestionsListModal = props => {
  const { openDeleteSuggestionsListModal, onCloseDeleteSuggestionsModal, onDeleteSuggestionList } =
    props || {}
  const { labelStrings } = useTranslation()

  return (
    <ModalComponent
      isOpenModal={openDeleteSuggestionsListModal}
      closeModal={onCloseDeleteSuggestionsModal}
      title={labelStrings?.buildLookalike?.deleteSuggestionsList}
      smallPadding
      contentWidth="400px"
      contentHeight="auto"
      isCenterTitle
      withoutScrollWrapper={false}
    >
      <Box>
        <Stack
          sx={{ pb: 2 }}
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Button variant="outlined" onClick={onCloseDeleteSuggestionsModal}>
            {labelStrings.cancel}
          </Button>
          <Button variant="contained" onClick={onDeleteSuggestionList}>
            {labelStrings.delete}
          </Button>
        </Stack>
      </Box>
    </ModalComponent>
  )
}
