import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useTranslation from '../../../localization/useTranslation'
import { ChevronArrowIcon } from '../icons'

const Wrapper = styled.div`
  ${props => props.theme.flex.centerAll}
  margin: 10px 0;
  width: 100%;
`

const Label = styled.span`
  font-family: ${props => props.theme.fontFamilyInter.inter};
  font-size: 14px;
  color: ${props => props.theme.primaryColor};
  font-weight: bold;
`

const LabelWrapper = styled.div`
  ${props => props.theme.flex.centerAll}
  cursor: pointer;
`

const BackToTopOfPage = ({ pageId = '' }) => {
  const { labelStrings } = useTranslation()

  const onClick = () => {
    const elementToScroll = pageId ? document.getElementById(pageId) : null
    const element = elementToScroll || window
    element.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <Wrapper>
      <LabelWrapper onClick={onClick}>
        <ChevronArrowIcon />
        <Label>{labelStrings.backToTop}</Label>
      </LabelWrapper>
    </Wrapper>
  )
}

BackToTopOfPage.propTypes = {
  pageId: PropTypes.string,
}

export default BackToTopOfPage
