import React from 'react'
import PropTypes from 'prop-types'

import { TextareaWrapper, Label, StyledTextarea, ErrorMessage } from './styles'

const Textarea = ({ id = '', name = '', value = '', placeholder = '', label = '', onChange, errorMessage = '', ...props }) => {
  return (
    <TextareaWrapper className="textarea-wrapper">
      {label && (
        <Label className="textarea-label" htmlFor={name} hasError={!!errorMessage}>
          {label}
        </Label>
      )}
      <StyledTextarea
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        hasError={!!errorMessage}
        className="textarea"
        {...props}
      />
      {errorMessage && (
        <ErrorMessage className="textarea-error-message">{errorMessage}</ErrorMessage>
      )}
    </TextareaWrapper>
  )
}

Textarea.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
}

export default Textarea
