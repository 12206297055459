import React from 'react'
import { Stack, Typography, Box, Link } from '@mui/material'
import { PDF_INFLUDATA_LOGO } from '../../../../components/common/icons'
import AudienceReportsSearch from '../search'

export const Header = props => {
  const { reports, isForPdf } = props || {}
  const isNotSubInactiveStatus = reports && reports?.status !== 'sub_inactive'
  const usernameLabel = reports?.audienceData?.username
    ? `@${reports.audienceData.username}`
    : 'not available'

  return (
    <Stack direction="row" justifyContent="space-between">
      {isNotSubInactiveStatus && (
        <Box>
          <Typography sx={{ color: '#784E4E', fontWeight: 'bold', fontSize: '18px' }}>
            {`Audience Report - ${usernameLabel}`}
          </Typography>
          {reports?.isFreeTrial && (
            <Typography variant="caption" sx={{ color: '#784E4E' }}>
              You are now accessing influData audience reports through our limited-time free trial.
            </Typography>
          )}
        </Box>
      )}
      <Stack
        direction="row"
        sx={{ height: '50px' }}
        alignItems="start"
        spacing={reports?.status === 'ok' ? 2 : 0}
      >
        {reports?.status === 'ok' && !isForPdf && <AudienceReportsSearch isAllowedToAddCreators />}

        <Link href="/">
          <PDF_INFLUDATA_LOGO width={180} height={50} />
        </Link>
      </Stack>
    </Stack>
  )
}
