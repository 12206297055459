import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../../localization/useTranslation'
import { pretifyBigNum } from '../../../../utils'
import {
  ProfileCardImgWrapper,
  ProfileCardText,
  ProfileContentCard,
  ProfileFoundWrapper,
  ProfileStatistics,
  ProfileStatisticsItem,
  ProfileText,
  ProfileTextNumber,
  ShowMoreBtn,
  ToolIconWrapper,
  ToolItem,
} from './styles'
import { useHistory } from 'react-router-dom'
import {
  AddToCollectionSearchContentIcon,
  AddToFavoriteSearchContentIcon,
  LocationIconSvg,
} from '../../../common/icons'
import { USER_INDEXES } from '../../../../constants/appSettings'
import { useDispatch, useSelector } from 'react-redux'
import {
  addSavedContentToCollection,
  removeSavedContentFromCollection,
} from '../../../../redux/ducks/searchDucks'
import AddToCollectionModal from '../../../collections/collectionTools/AddToCollectionModal'
import { searchContentActiveMapMarker } from '../../../../redux/selectors'
import EditCollectionModal from '../../../collections/collectionTools/EditCollectionModal'

export const ProfileContentItem = ({ profile = {}, setActiveCard, activeCard = {}, setRenderedContent, isContentFav = false }) => {
  const { labelStrings } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const activeMapMarker = useSelector(searchContentActiveMapMarker)

  const [showMore, setShowMore] = useState(false)
  const [isAddToCollectionOpened, setIsAddToCollectionOpened] = useState(false)
  const [isCreateCollectionOpened, setIsCreateCollectionOpened] = useState(false)
  const [isLoadedCardImg, setIsLoadedCardImg] = useState(false)
  const [isFav, setIsFav] = useState(false)
  const [collectionCount, setCollectionCount] = useState(profile.collectionArray)

  useEffect(() => {
    const isFavContent = collectionCount?.find(el => el === 'contentFav')
    isFavContent && setIsFav(true)
  }, [collectionCount])

  const btnShowMoreAction = e => {
    e.preventDefault()
    e.stopPropagation()
    setShowMore(!showMore)
  }

  const goToProfile = (e, profileId) => {
    e.preventDefault()
    e.stopPropagation()
    history.push(`${process.env.REACT_APP_PREFIX}profile/${profileId}`)
  }

  const onHandleLinkContent = () => {
    if (isAddToCollectionOpened) return

    if (profile.contentType === 'story' && profile.user_id) {
      window.open(`${process.env.REACT_APP_PREFIX + 'profile/' + profile.user_id}`, '_blank')
    }
    if (profile.contentType !== 'story') {
      window.open(profile.platformLink, '_blank')
    }
    return
  }

  const onAddRemoveFavorites = async (e, ids, isFav) => {
    e.preventDefault()
    e.stopPropagation()

    if (isFav) {
      setIsFav(!isFav)
      const isSuccessful = await dispatch(
        removeSavedContentFromCollection({ contentIds: [ids], collectionId: 'contentFav' })
      )
      isContentFav && setRenderedContent(prev => prev?.filter(el => el.contentId !== profile.contentId))
      if (!isSuccessful) setIsFav(!isFav)
    } else {
      setIsFav(!isFav)
      const isSuccessful = await dispatch(
        addSavedContentToCollection({ contentIds: [ids], collectionId: 'contentFav' })
      )
      if (!isSuccessful) setIsFav(!isFav)
    }
  }

  const onAddToCollection = e => {
    e.preventDefault()
    e.stopPropagation()
    setIsAddToCollectionOpened(true)
  }

  const currCollectionCount = collectionCount?.filter(c => c !== 'contentFav')?.length

  return (
    <ProfileContentCard
      onClick={onHandleLinkContent}
      onMouseEnter={() => setActiveCard(profile)}
      onMouseLeave={() => setActiveCard({})}
      id={profile._id}
      isActive={profile._id === activeMapMarker._id}
    >
      <ProfileCardImgWrapper isHideContentBtnBottom={profile.contentType === 'story'}>
        {isLoadedCardImg && activeCard.contentId === profile.contentId && (
          <div className="topBtnWrapper">
            <ToolItem
              isActive={false}
              numberInBubble={currCollectionCount}
              platform={profile.platform}
              onClick={e => onAddToCollection(e)}
              isDisabled={false}
            >
              <ToolIconWrapper>
                <AddToCollectionSearchContentIcon />
              </ToolIconWrapper>
            </ToolItem>
            <ToolItem
              isActive={isFav}
              platform={profile.platform}
              onClick={e => onAddRemoveFavorites(e, profile.contentId, isFav)}
              isDisabled={false}
            >
              <ToolIconWrapper>
                <AddToFavoriteSearchContentIcon isFav={isFav} />
              </ToolIconWrapper>
            </ToolItem>
          </div>
        )}
        <img
          src={profile.imageUrl}
          alt={profile.username}
          onLoad={() => setIsLoadedCardImg(true)}
        />
        {isLoadedCardImg && activeCard.contentId === profile.contentId && (
          <div className="bottomBtnWrapper">
            <button onClick={e => goToProfile(e, profile.profileId)}>influData Profile</button>
            {profile.contentType !== 'story' && (
              <button onClick={onHandleLinkContent}>
                Go to <span>{profile.contentType}</span>
              </button>
            )}
          </div>
        )}
      </ProfileCardImgWrapper>

      <ProfileCardText>
        <div className="rightType">
          <h2>{profile.contentType}</h2>
        </div>
        {profile.locationString && (
          <div className="leftLocation">
            <div>
              <LocationIconSvg />
            </div>
            <span>{profile.locationString}</span>
          </div>
        )}
      </ProfileCardText>
      <ProfileStatistics>
        <ProfileStatisticsItem>
          <span>{labelStrings.uploaded}</span>
          <ProfileText>{profile.uploaded?.substring(0, 10)}</ProfileText>
        </ProfileStatisticsItem>
        <ProfileStatisticsItem onClick={e => goToProfile(e, profile.profileId)}>
          <span>{labelStrings.username}</span>
          <ProfileText>{profile.username}</ProfileText>
        </ProfileStatisticsItem>
        {profile.contentType !== 'story' && (
          <>
            <ProfileStatisticsItem>
              <span>{labelStrings.likes}</span>
              <ProfileTextNumber>{pretifyBigNum(profile.likes || 0)}</ProfileTextNumber>
            </ProfileStatisticsItem>
            <ProfileStatisticsItem>
              <span>{labelStrings.comments}</span>
              <ProfileTextNumber>{pretifyBigNum(profile.comments || 0)}</ProfileTextNumber>
            </ProfileStatisticsItem>
          </>
        )}
        <ProfileStatisticsItem>
          <span>
            {profile.contentType === 'reel' || profile.platform === USER_INDEXES.tiktok || profile.platform === USER_INDEXES.youtube
              ? `${labelStrings.plays}`
              : `${labelStrings.estimatedReach}`}
          </span>
          <ProfileTextNumber>{pretifyBigNum(profile.reach || 0)}</ProfileTextNumber>
        </ProfileStatisticsItem>
        {
          <ProfileStatisticsItem>
            {!!profile.valueFrom && !!profile.valueTo && (
              <>
                <span>{labelStrings.valueInEur}</span>
                <ProfileTextNumber>
                  {pretifyBigNum(profile.valueFrom)}-{pretifyBigNum(profile.valueTo)}
                </ProfileTextNumber>
              </>
            )}
          </ProfileStatisticsItem>
        }
      </ProfileStatistics>
      <ProfileFoundWrapper>
        {profile.title && (
          <>
            <h2>Title</h2>
            <div dangerouslySetInnerHTML={{ __html: profile.title }} />
          </>
        )}
        {profile.keywords?.length > 300 ? (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: showMore
                  ? profile.keywords
                  : `${profile.keywords?.substring(0, 300)?.concat('...')}`,
              }}
            />
            <ShowMoreBtn onClick={e => btnShowMoreAction(e)}>
              {showMore ? labelStrings.showLess : labelStrings.showMore}
            </ShowMoreBtn>
          </>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: profile.keywords }} />
        )}
      </ProfileFoundWrapper>

      {isAddToCollectionOpened && (
        <AddToCollectionModal
          profile={activeCard}
          platform={profile.platform}
          collectionArray={collectionCount}
          setCollectionCount={setCollectionCount}
          isContent
          closeModal={() => {
            setIsAddToCollectionOpened(false)
          }}
          openCreateCollectionModal={() => {
            setIsCreateCollectionOpened(true)
            setIsAddToCollectionOpened(false)
          }}
        />
      )}

      {isCreateCollectionOpened && (
        <EditCollectionModal
          platform={profile.platform}
          isNewCollection
          closeModal={() => setIsCreateCollectionOpened(false)}
        />
      )}
    </ProfileContentCard>
  )
}

ProfileContentItem.propTypes = {
  profile: PropTypes.object,
  setActiveCard: PropTypes.func,
  activeCard: PropTypes.object,
  setRenderedContent: PropTypes.func,
  isContentFav: PropTypes.bool
}
