import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { selectedPlatformSelector } from '../../../redux/selectors'
import useDebounce from '../../../hooks/useDebounce'
import httpService from '../../../services/httpService'
import { addCreatorToCampaign } from '../../../redux/ducks/campaignsDucks'
import { SuggestedCreatorsInput } from './SuggestedCreatorsInput'

const SuggestedCreatorsInputContainer = ({
  campaignId = '',
  creatorsTableList = [],
  isAllowedToAddCreators = false,
  isPipeline = false,
  onAssignCreators,
}) => {
  const dispatch = useDispatch()
  const currentPlatform = useSelector(selectedPlatformSelector)

  const [loadingCreators, setLoadingCreators] = useState(false)
  const [creatorsData, setCreatorsData] = useState([])
  const [notFoundData, setNotFoundData] = useState(false)
  const [error, setError] = useState(false)

  const [inputValue, setInputValue] = useState('')
  const debouncedInputValue = useDebounce(inputValue, 500)

  const onAddCreatorsToCampaign = creatorId => {
    dispatch(
      // @ts-ignore
      addCreatorToCampaign({
        creatorId,
        campaignId,
        defaultAxiosFetch: true
      })
    )
  }

  const fetchSuggestedCreators = async searchString => {
    try {
      setLoadingCreators(true)
      setNotFoundData(false)
      setError(false)
      setCreatorsData([])
      const creatorsUsers = await httpService.fetchSuggestedUsers(
        searchString,
        currentPlatform?.toLowerCase()
      )
      if (!creatorsUsers?.length) {
        setNotFoundData(true)
      }
      if (creatorsUsers?.length) {
        setCreatorsData(creatorsUsers)
      }
      setLoadingCreators(false)
    } catch (err) {
      // console.error(err)
      setError(true)
      setLoadingCreators(false)
      setCreatorsData([])
      setNotFoundData(false)
    }
  }

  useEffect(() => {
    if (debouncedInputValue === inputValue && debouncedInputValue.length >= 2) {
      fetchSuggestedCreators(debouncedInputValue)
    }
    if (!debouncedInputValue?.length) {
      setCreatorsData([])
      setNotFoundData(false)
      setLoadingCreators(false)
      setError(false)
    }
  }, [debouncedInputValue, currentPlatform])

  return (
    <SuggestedCreatorsInput
      creatorsTableList={creatorsTableList}
      creatorsData={creatorsData}
      notFoundData={notFoundData}
      error={error}
      currentPlatform={currentPlatform}
      debouncedInputValue={debouncedInputValue}
      inputValue={inputValue}
      loadingCreators={loadingCreators}
      setInputValue={setInputValue}
      setCreatorsData={setCreatorsData}
      setLoadingCreators={setLoadingCreators}
      setNotFoundData={setNotFoundData}
      onAddCreatorsToCampaign={onAddCreatorsToCampaign}
      isAllowedToAddCreators={isAllowedToAddCreators}
      isPipeline={isPipeline}
      onAssignCreators={onAssignCreators}
    />
  )
}

SuggestedCreatorsInputContainer.propTypes = {
  campaignId: PropTypes.string,
  creatorsTableList: PropTypes.array,
  isAllowedToAddCreators: PropTypes.bool,
  isPipeline: PropTypes.bool,
  onAssignCreators: PropTypes.func
}

export default SuggestedCreatorsInputContainer
