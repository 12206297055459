import React from 'react'
import PropTypes from 'prop-types'

import useTranslation from '../../../../localization/useTranslation'

import SmartMediaValueItem from './SmartMediaValueItem'
import { SmartMediaValueSection, SmartMediaValueItems } from './styles'
import {
  SectionTitleWrapper,
  SectionTitleName,
  SectionTitleBorder,
} from '../../commonProfilePageStyles'
import { USER_INDEXES } from '../../../../constants/appSettings'

const SmartMediaValue = ({ cpms = null, platform = '', isForPdf = false, currency = ''}) => {
  const { labelStrings } = useTranslation()

  if (!cpms) return null

  const isYtPlatform = platform === USER_INDEXES.youtube
  const isTTPlatform = platform === USER_INDEXES.tiktok
  const smartMediaArr = [
    {
      perMediaLabel: isYtPlatform || isTTPlatform ? labelStrings.perVideo : labelStrings.perPost,
      cpm: isTTPlatform ? cpms.smartMediaVideos : cpms?.smartMediaPosts,
    },
    { perMediaLabel: labelStrings.perStory, cpm: cpms?.smartMediaStories },
    { perMediaLabel: labelStrings.perReel, cpm: cpms?.smartMediaReels },
    { perMediaLabel: labelStrings.perShort, cpm: cpms?.smartMediaShorts },
  ]

  return (
    <SmartMediaValueSection isForPdf={isForPdf}>
      {isForPdf && (
        <SectionTitleWrapper customStyles="margin-bottom: 30px">
          <SectionTitleName platform={platform} isForPdf>
            {labelStrings.audienceSmartMediaValue}
          </SectionTitleName>
          <SectionTitleBorder isForPdf={isForPdf} />
        </SectionTitleWrapper>
      )}
      <SmartMediaValueItems>
        {smartMediaArr?.map((smartMediaItem, index) => {
          if (!smartMediaItem.cpm) return null
          return (
            <SmartMediaValueItem
              key={index}
              perMediaLabel={smartMediaItem.perMediaLabel}
              cpm={smartMediaItem.cpm}
              isForPdf={isForPdf}
              currency={currency}
              platform={platform}
            />
          )
        })}
      </SmartMediaValueItems>
    </SmartMediaValueSection>
  )
}

SmartMediaValue.propTypes = {
  cpms: PropTypes.object,
  platform: PropTypes.oneOf([
    USER_INDEXES.instagram,
    USER_INDEXES.tiktok,
    USER_INDEXES.youtube,
    USER_INDEXES.tiktok,
  ]).isRequired,
  isForPdf: PropTypes.bool,
  currency: PropTypes.string,
}

export default SmartMediaValue
