import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'
import Modal from '../../common/modals/Modal/Modal'
import { GradientButton, CancelButton } from '../../common/buttons/styledButtons'
import { ContentWrapper, Message, ActionButtonsWrapper } from './commonStyles'

const DeleteCollectionModal = ({ collectionName = '', closeModal, deleteCollection }) => {
  const { labelStrings } = useTranslation()

  const headerContent = `${labelStrings.remove} ${collectionName} ${labelStrings.collection}?`

  return (
    <Modal headerContent={headerContent} closeModal={closeModal}>
      <ContentWrapper>
        <Message>{labelStrings.removeAllUsersFromCollection}</Message>
      </ContentWrapper>
      <ActionButtonsWrapper>
        <CancelButton onClick={closeModal}>{labelStrings.cancel}</CancelButton>
        <GradientButton onClick={deleteCollection}>{labelStrings.remove}</GradientButton>
      </ActionButtonsWrapper>
    </Modal>
  )
}

DeleteCollectionModal.propTypes = {
  collectionName: PropTypes.string,
  closeModal: PropTypes.func,
  deleteCollection: PropTypes.func,
}

export default DeleteCollectionModal
