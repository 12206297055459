import React from 'react'
import PropTypes from 'prop-types'
import * as Styled from './styles'

export const TextTips = (props) => {
  const { title, main, text } = props;
  return (
    <Styled.DescriptionTipContainer>
      <Styled.DescriptionTipLabel>{title}</Styled.DescriptionTipLabel>
      <Styled.DescriptionOverlay>
        <Styled.DescriptionMain>{main}</Styled.DescriptionMain>
        <Styled.DescriptionText dangerouslySetInnerHTML={{ __html: text }} />
      </Styled.DescriptionOverlay>
    </Styled.DescriptionTipContainer>
  )
}

TextTips.propTypes = {
  title: PropTypes.string.isRequired,
  main: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}
