import styled from 'styled-components'

export const OuterWrapper = styled.div`
  & .popup-content {
    width: 52px !important;
    border-radius: 5px;
    background: ${props => props.theme.colors.lightRed} !important;
    box-shadow: 0px 0px 6px ${props => props.theme.colors.lightRed} !important;
    border: 1px solid ${props => props.theme.colors.darkBrown} !important;
    &:hover {
      filter: brightness(95%)
    }
  }
  & .popup-arrow {
    background: ${props => props.theme.colors.lightRed} !important;
    box-shadow: 0px 0px 6px ${props => props.theme.colors.lightRed} !important;
    border-right: 1px solid ${props => props.theme.colors.darkBrown} !important;
    border-bottom: 1px solid ${props => props.theme.colors.darkBrown} !important;
  }
`

export const LanguageSwitchWrapper = styled.div`
  cursor: pointer;
  border: 1px solid
    ${props => (props.inFooter ? props.theme.colors.darkBrown : props.theme.color.serenade)};
  border-radius: 5px;
  line-height: initial;
  ${props => props.theme.flex.centerAll};
  padding: 3px 0 3px 5px;
  &:hover {
    opacity: 0.8;
  }
  img {
    margin-right: 0 !important;
  }
`
export const IconWrapper = styled.div`
  transition: all 0.2s ease;
  & svg path {
    fill: ${props => (props.inFooter ? props.theme.colors.darkBrown : props.theme.color.serenade)};
  }
  & svg {
    &:hover {
      & path {
        fill: ${props =>
          props.inFooter ? props.theme.colors.darkBrown : props.theme.color.serenade};
      }
    }
  }
  ${props =>
    props.isOpened &&
    `
    transform: rotateZ(180deg) translate(0px, -3px);
  `}
`

export const ProposedLang = styled.div`
  width: 100%;
  line-height: 18px;
  cursor: pointer;
  ${props => props.theme.flex.centerAll};
  img {
    margin-right: 0 !important;
  }
`
