import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Paper, Button, ClickAwayListener, TextField } from '@mui/material'
import Iconify from '../../../components/iconify/Iconify'
import { addEditColumnPipeline } from '../../../../redux/ducks/campaignsDucks'
import { pipelineSelector } from '../../../../redux/selectors'

export default function PipelineColumnAdd({ campaignId }) {
  const dispatch = useDispatch()
  const { board } = useSelector(pipelineSelector)

  const [name, setName] = useState('')
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChangeName = event => {
    setName(event.target.value)
  }

  const handleCreateColumn = async () => {
    try {
      if (name) {
        // @ts-ignore
        dispatch(addEditColumnPipeline({
          campaignId: campaignId,
          columnName: name,
          position: board.columnOrder?.length + 1,
          method: 'add',
        }))
        setName('')
      }
      handleClose()
    } catch (error) {
      console.error(error)
    }
  }

  const handleKeyUp = event => {
    if (event.key === 'Enter') {
      handleCreateColumn()
    }
  }

  return (
    <Paper sx={{ minWidth: 280, width: 280 }}>
      {open ? (
        <ClickAwayListener onClickAway={handleCreateColumn}>
          <TextField
            autoFocus
            fullWidth
            placeholder="New section"
            value={name}
            onChange={handleChangeName}
            onKeyUp={handleKeyUp}
            InputProps={{
              sx: { typography: 'h6' },
            }}
          />
        </ClickAwayListener>
      ) : (
        <Button
          fullWidth
          size="large"
          color="inherit"
          variant="outlined"
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={handleOpen}
        >
          Add section
        </Button>
      )}
    </Paper>
  )
}
