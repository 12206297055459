import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Ratings from 'react-star-ratings'
import WithUpgradeTip from './common/popups/WithUpgradeTip'
import { themeCommonStyles } from '../themes/infludataThemes'
import { debounced } from '../utils'

const StarRatingWrapper = styled.div`
  .star-ratings {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`

const StarRating = ({
  nrOfStars = 0,
  onStarClick,
  userindex = '',
  allowRating = false,
  stopEventBubbling,
}) => {
  const [currentRating, setCurrentRating] = useState(nrOfStars)

  const changeCurrentRating = rating => setCurrentRating(rating)
  const debouncedOnStarClick = debounced(newRating => onStarClick(newRating))

  const changeStarRating = newRating => {
    changeCurrentRating(newRating)
    debouncedOnStarClick(newRating)
  }

  useEffect(() => {
    if (currentRating !== nrOfStars) changeCurrentRating(nrOfStars)
  }, [nrOfStars])

  const ratedColor = themeCommonStyles.getColorPaletteForPlatform(userindex).main

  const Component = (
    <StarRatingWrapper
      onClick={stopEventBubbling}
      onMouseDownCapture={stopEventBubbling}
    >
      <Ratings
        rating={currentRating}
        changeRating={changeStarRating}
        starDimension="20px"
        starSpacing="1px"
        starEmptyColor={themeCommonStyles.colors.lightBrown}
        starRatedColor={ratedColor}
        starHoverColor={ratedColor}
        svgIconViewBox="0 0 24 24"
        svgIconPath="M23.22,9a1.64,1.64,0,0,0-1.58-1.15l-6.15,0L13.58,2a1.66,1.66,0,0,0-3.16,0L8.54,7.81H2.36a1.67,1.67,0,0,0-1,3l5,3.59L4.46,20.29A1.67,1.67,0,0,0,7,22.15l5-3.63,5,3.63a1.71,1.71,0,0,0,1,.32,1.66,1.66,0,0,0,1-.32,1.63,1.63,0,0,0,.61-1.86l-1.92-5.84,5-3.63A1.64,1.64,0,0,0,23.22,9Z"
      />
    </StarRatingWrapper>
  )

  return allowRating ? (
    Component
  ) : (
    <WithUpgradeTip userindex={userindex} tipText="add your rating">
      {Component}
    </WithUpgradeTip>
  )
}

StarRating.propsTypes = {
  nrOfStars: PropTypes.number,
  onStarClick: PropTypes.func,
  userindex: PropTypes.string,
  allowRating: PropTypes.bool,
  stopEventBubbling: PropTypes.func,
}

export default StarRating
