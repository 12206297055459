import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { TopControls } from '../../sections/goals/TopContorls'
import { GoalsList } from '../../sections/goals/GoalsList'
import { EditGoal } from '../../sections/goals/EditGoal'
import { useDispatch, useSelector } from 'react-redux'
import {
  addChangeRemoveCampaignGoals,
  getCampaignsGoals,
} from '../../../redux/ducks/campaignsDucks'
import { currentOpenedCampaignSelector, goalsSelector } from '../../../redux/selectors'

export const Goals = () => {
  const dispatch = useDispatch()
  const ref = useRef(null)

  const { campaignId } = useSelector(currentOpenedCampaignSelector)
  const { isLoading, data } = useSelector(goalsSelector)

  const [openEditGoalModal, setOpenEditGoalModal] = useState(false)
  const [editGoal, setEditGoal] = useState(null)
  const [currPage, setCurrPage] = useState(0)

  const onCreateGoal = () => {
    setOpenEditGoalModal(true)
    setEditGoal(null)
  }

  useEffect(() => {
    if (campaignId && !data?.length) {
      // @ts-ignore
      dispatch(getCampaignsGoals({ campaignId }))
    }
  }, [campaignId, data?.length])

  const handleGoal = objParam => {
    // @ts-ignore
    dispatch(addChangeRemoveCampaignGoals({ ...objParam, campaignId }))
  }

  useEffect(() => {
    ref?.current?.firstElementChild?.scrollIntoView()
  }, [currPage, ref])

  return (
    <>
      <Box ref={ref} sx={{ px: 3, py: 3 }}>
        <TopControls onCreateCustomField={onCreateGoal} isLoading={isLoading} />
        <GoalsList
          data={data}
          openModal={setOpenEditGoalModal}
          setEditField={setEditGoal}
          handleGoal={handleGoal}
          isLoading={isLoading}
          setCurrPage={setCurrPage}
        />
      </Box>
      <EditGoal
        openEditGoalModal={openEditGoalModal}
        setOpenEditGoalModal={setOpenEditGoalModal}
        editGoal={editGoal}
        setEditGoal={setEditGoal}
        handleGoal={handleGoal}
      />
    </>
  )
}
