import React, { useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import ProfileToolsMediator from '../../profileTools/ProfileToolsMediator'
import StarRating from '../../../StarRating'
import useTranslation from '../../../../localization/useTranslation'
import ProfileAvatar from '../../profileAvatar'
import UserGrowthDynamics from '../../../common/dynamicsLabels/UserGrowthDynamics'
import {
  ProfileRow,
  RowCell,
  Username,
  DisplayName,
  SmallText,
  BoldText,
  PayAttentionText,
  PrivateProfileWarnCell,
  CheckboxWrapper,
  YouTubeCreatorLabelWrapper,
  YouTubeIconsWrapper,
  TextWithIconWrapper,
  YTShortsIconWrapper,
  YTStatisticsWrapper,
  ModalContainer,
  AdditionalFieldDetailsOverlay,
  ProfileImagesWrapper,
  ProfileImgWrapper,
  RowLink,
  TableVerifiedWrapper,
  IconVerifiedWrapper,
} from '../tableStyles'
import RowCellContent from './RowCellContent'
import {
  constructLinkToWECFileServer,
  convertValueToPercentage,
  findCountryIsoCode,
  formatFollowers,
  pretifyBigNum,
  getPngCountryFlags,
} from '../../../../utils'

import Checkbox from '../../../common/checkboxes/Checkbox'
import {
  VerifiedProfileIcon,
  YouTubeClassicIcon,
  YouTubeShortsCreateIcon,
} from '../../../common/icons/icons'
import ProfileUnlockedStatusContent from '../ProfileUnlockedStatusContent'

import LazyImage from '../../../lazyImage'
import RelativePortal from 'react-relative-portal'
import { Stack } from '@mui/material'

const ProfileListUserRowDetails = ({
  user,
  dragProps = {},
  selectSingle,
  isCollectionView = false,
  isUnlockedCollectionOpened = false,
  applyMocked = false,
  isFullTable = false,
  privateRating = '',
  userlanguage = '',
  onStarClick,
  onRowClick,
  stopEventBubbling,
  link = '#',
  isBlackListCollection = false,
  onBlockUnblockUser,
  isSelectedProfileView = false,
  setIsOpenPipelineModal,
  hasNotCheckboxes = false,
  isExplore = false,
  lookalikeTypeData = '',
}) => {
  const { countryOptions, labelStrings } = useTranslation()
  const isPrivateAccount = !!user.isPrivate && isFullTable

  const isProfileVerified = !!user.isInfluDataVerified

  const hasCheckbox = isCollectionView && !isUnlockedCollectionOpened
  const hasStarRating = isCollectionView && !isUnlockedCollectionOpened
  const hasUnlockedStatus = isCollectionView && isUnlockedCollectionOpened

  const hasEngagementRatePostsAndShorts = user.engagementRatePosts && user.engagementRateShorts

  const followersViewPostsEngagementRow = useMemo(() => {
    return (
      <YouTubeIconsWrapper>
        {user.engagementRatePosts && (
          <TextWithIconWrapper hasBothValues={hasEngagementRatePostsAndShorts}>
            <YouTubeClassicIcon />
            <YTStatisticsWrapper>
              <PayAttentionText userindex={user._index} applyMocked={applyMocked}>
                {pretifyBigNum(user.medianViewCountPosts) || 0}
              </PayAttentionText>
              <SmallText applyMocked={applyMocked}>
                {convertValueToPercentage(user.engagementRatePosts)}
              </SmallText>
            </YTStatisticsWrapper>
          </TextWithIconWrapper>
        )}

        {user.engagementRateShorts && (
          <TextWithIconWrapper hasBothValues={hasEngagementRatePostsAndShorts}>
            <YouTubeShortsCreateIcon />
            <YTStatisticsWrapper>
              <PayAttentionText userindex={user._index} applyMocked={applyMocked}>
                {pretifyBigNum(user.medianViewCountShorts) || 0}
              </PayAttentionText>
              <SmallText applyMocked={applyMocked}>
                {convertValueToPercentage(user.engagementRateShorts)}
              </SmallText>
            </YTStatisticsWrapper>
          </TextWithIconWrapper>
        )}
      </YouTubeIconsWrapper>
    )
  }, [user])
  const [openModal, setOpenModal] = useState(false)
  const modalRef = useRef(null)

  return (
    <>
      <ProfileRow userindex={user._index} {...dragProps} onClick={onRowClick} id={user._id}>
        {hasCheckbox && !isBlackListCollection && !hasNotCheckboxes && (
          <RowCell onClick={stopEventBubbling} onMouseDown={stopEventBubbling} width={30}>
            <RowCellContent link={link} />
            <CheckboxWrapper>
              <Checkbox isChecked={user.isSelected || false} onChange={selectSingle} />
            </CheckboxWrapper>
          </RowCell>
        )}

        <RowCell width={100}>
          <ModalContainer
            ref={modalRef}
            onMouseOver={() => setOpenModal(true)}
            onMouseLeave={() => setOpenModal(false)}
          >
            <RowCellContent link={link} over />
            <YouTubeCreatorLabelWrapper>
              <YTShortsIconWrapper hasCheckbox={hasCheckbox}>
                {user.isShortsCreator && <YouTubeShortsCreateIcon />}
              </YTShortsIconWrapper>
              <TableVerifiedWrapper>
                <ProfileAvatar
                  score={user.profileScore.overall}
                  profilePicUrl={user.profilePicURL}
                  userindex={user._index}
                  withRating={!applyMocked}
                  withOverlay={!applyMocked}
                  size={75}
                />
                {isProfileVerified && (
                  <IconVerifiedWrapper>
                    <VerifiedProfileIcon width={25} height={28} />
                  </IconVerifiedWrapper>
                )}
              </TableVerifiedWrapper>
            </YouTubeCreatorLabelWrapper>

            {openModal && !!user?.postLinksArray?.length && (
              <RelativePortal component="div">
                <AdditionalFieldDetailsOverlay>
                  <ProfileImagesWrapper>
                    {user?.postLinksArray?.map((el, index) => (
                      <ProfileImgWrapper key={index}>
                        <LazyImage key={index} src={constructLinkToWECFileServer(el)} alt="" />
                      </ProfileImgWrapper>
                    ))}
                  </ProfileImagesWrapper>
                </AdditionalFieldDetailsOverlay>
              </RelativePortal>
            )}
          </ModalContainer>
        </RowCell>

        {isFullTable && (
          <RowCell width={90}>
            <RowCellContent link={link} />
            {isPrivateAccount && (
              <PrivateProfileWarnCell userindex={user._index}>
                {labelStrings.profileSetToPrivate}
              </PrivateProfileWarnCell>
            )}
            <ProfileToolsMediator
              user={{ ...user }}
              isCollectionView={isCollectionView}
              isSearchView={!isCollectionView}
              stopEventBubbling={stopEventBubbling}
              onBlockUnblockUser={onBlockUnblockUser}
              isSelectedProfileView={isSelectedProfileView}
              isBlackListCollection={isBlackListCollection}
              setIsOpenPipelineModal={setIsOpenPipelineModal}
              isExplore={isExplore}
            />
          </RowCell>
        )}

        <RowCell width={170}>
          <RowCellContent link={link} />
          <Username userindex={user._index} applymocked={applyMocked ? 'true' : undefined}>
            <RowLink to={`/profile/${user._id}`} rel="noopener noreferrer">
              {user.username}
            </RowLink>
          </Username>
          <Stack direction={'row'} alignItems={'center'}>
            {user.city && user.country && (
              <SmallText
                applyMocked={applyMocked}
                style={{ display: 'inline-flex', alignItems: 'center' }}
              >
                {getPngCountryFlags(findCountryIsoCode(countryOptions, user.country))
                  ? getPngCountryFlags(findCountryIsoCode(countryOptions, user.country))
                  : user.country}{' '}
                {user.city}
              </SmallText>
            )}
            {!user.city && user.country && (
              <SmallText
                applyMocked={applyMocked}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {user.country}
              </SmallText>
            )}
            <SmallText applyMocked={applyMocked} style={{ marginRight: user.country ? '2px' : 0 }}>
              {userlanguage.label && user.country ? ', ' : ''}
            </SmallText>
            <SmallText applyMocked={applyMocked}>
              {userlanguage.label ? userlanguage.label : ''}
            </SmallText>
          </Stack>
          <div>
            <SmallText applyMocked={applyMocked}>
              {user.categories && user.categories[0] ? user.categories[0].category : ''}
            </SmallText>
          </div>
        </RowCell>

        <RowCell width={350}>
          <RowCellContent link={link} />
          <DisplayName userindex={user._index} applymocked={applyMocked ? 'true' : undefined}>
            <RowLink to={`/profile/${user._id}`} rel="noopener noreferrer">
              {user.displayName}
            </RowLink>
          </DisplayName>

          <div>
            <SmallText
              applyMocked={applyMocked}
              dangerouslySetInnerHTML={{ __html: user.description }}
            />
          </div>
        </RowCell>

        <RowCell width={125}>
          <RowCellContent link={link} />
          <BoldText applyMocked={applyMocked} asBlock>
            {formatFollowers(user.followers)}
            {user.monthlyGrowthFollowers && (
              <UserGrowthDynamics growthData={user.monthlyGrowthFollowers} />
            )}
            <br />
          </BoldText>
          {user._index === 'youtube' ? (
            followersViewPostsEngagementRow
          ) : (
            <>
              <PayAttentionText userindex={user._index} applyMocked={applyMocked}>
                {user.impressions ? pretifyBigNum(user.impressions) : 0}
              </PayAttentionText>
              <br />
              <SmallText applyMocked={applyMocked}>{user.engagementMean}</SmallText>
            </>
          )}
        </RowCell>

        {hasStarRating && !isBlackListCollection && lookalikeTypeData !== 'LOOKALIKE_TYPE' && (
          <RowCell width={125}>
            <RowCellContent link={link} />
            <StarRating
              nrOfStars={user.stars}
              onStarClick={onStarClick}
              userindex={user._index}
              allowRating={privateRating}
              stopEventBubbling={stopEventBubbling}
            />
          </RowCell>
        )}

        {lookalikeTypeData === 'LOOKALIKE_TYPE' && (
          <RowCell width={125}>
            <RowCellContent link={link} />
            <SmallText applyMocked={applyMocked}>
              {user.matchRate ? convertValueToPercentage(user.matchRate) : ''}
            </SmallText>
          </RowCell>
        )}

        {hasUnlockedStatus && (
          <RowCell width={125}>
            <ProfileUnlockedStatusContent
              unlockedOn={user.unlockedOn}
              isAudienceAvailable={user.audienceAvailable}
            />
          </RowCell>
        )}
      </ProfileRow>
    </>
  )
}

ProfileListUserRowDetails.propsTypes = {
  user: PropTypes.object.isRequired,
  isCollectionView: PropTypes.bool,
  isUnlockedCollectionOpened: PropTypes.bool,
  applyMocked: PropTypes.bool,
  privateNotes: PropTypes.string,
  privateRating: PropTypes.string,
  showUserGrowth: PropTypes.bool,
  dragProps: PropTypes.object,
  selectSingle: PropTypes.func,
  isFullTable: PropTypes.bool,
  userlanguage: PropTypes.string,
  onStarClick: PropTypes.func,
  onCommentAdd: PropTypes.func,
  onRowClick: PropTypes.func,
  stopEventBubbling: PropTypes.func,
  link: PropTypes.string,
  isBlackListCollection: PropTypes.bool,
  onBlockUnblockUser: PropTypes.func,
  isSelectedProfileView: PropTypes.bool,
  setIsOpenPipelineModal: PropTypes.func,
  hasNotCheckboxes: PropTypes.bool,
  isExplore: PropTypes.bool,
  lookalikeTypeData: PropTypes.string,
}

export default ProfileListUserRowDetails
