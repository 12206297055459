import React from 'react'
import { Stack, Typography } from '@mui/material'

export const UnableToAddPage = () => {
  return (
    <Stack alignItems="center" spacing={2}>
      <Stack>
        <Typography align="center" sx={{ color: '#784E4E', fontWeight: 500, fontSize: '18px' }}>
          We are unable to create a report for this user.
        </Typography>
        <Typography align="center" sx={{ color: '#784E4E', fontWeight: 500, fontSize: '18px' }}>
          Please check if the user exists, has more than 3000 followers and is not set to private.
        </Typography>
      </Stack>

      <Typography align="center" sx={{ color: '#784E4E', fontWeight: 500, fontSize: '14px' }}>
        We won’t charge you for this report. If you think this is an error, please contact us.
      </Typography>
    </Stack>
  )
}
