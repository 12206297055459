import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { GlobalDraggingStyles } from '../../../../themes/infludataThemes'
import useTranslation from '../../../../localization/useTranslation'
import { delayFunc } from '../../../../utils'
import dragNDropService from '../../../../services/dragNdropService'
import { DRAG_EVENT_TYPES } from '../../../../constants/appSettings'
import ProfileListUserRowDetails from './ProfileListUserRowDetails'
import DraggableUser from './DraggableUser'
import { withReduxActions } from '../../../../hocs/withRedux'
import { setLastPageBeforeProfileRedirect } from '../../../../redux/ducks/appSettingsDucks'
import { setSaveOpenedProfileId } from '../../../../redux/ducks/profileSelectedDucks'
import AddToCampaignPipelineModal from '../../../campaigns/campaignTools/OverviewTools/AddToCampaignPipelineModal'

const ProfileListUserRow = ({
  user,
  isCollectionView = false,
  isUnlockedCollectionOpened = false,
  actionHandlers,
  tableForSimilarAccounts,
  applyMocked = false,
  privateRating = '',
  showUserGrowth = false,
  allowAddToCollectionsViaDrag,
  beforeRedirectPage = '',
  setLastPageBeforeProfileRedirect,
  setSaveOpenedProfileId,
  isBlackListCollection = false,
  onBlockUnblockUser,
  isSelectedProfileView = false,
  hasNotCheckboxes = false,
  isExplore = false,
  lookalikeTypeData = ''
}) => {
  const { languageOptions } = useTranslation()
  const history = useHistory()

  const [isOpenedPipelineModal, setIsOpenPipelineModal] = useState(false)

  const [isRowDragging, setIsRowDragging] = useState(false)
  const [draggingCords, setDraggingCords] = useState({})

  useEffect(() => {
    let isMounted = true
    const sudcribeToDragFnId = `user_row_${user._id}`
    const subscribeToDragFn = ({ dragElementProps, isCaptured, mouseCords }) => {
      if (dragElementProps && dragElementProps._id === user._id) {
        setDraggingCords(mouseCords)
        delayFunc(() => isMounted && setIsRowDragging(isCaptured), 100)
      }
    }
    dragNDropService.subscribeToDrag(DRAG_EVENT_TYPES.addToCollection, {
      fn: subscribeToDragFn,
      fnId: sudcribeToDragFnId,
    })
    return () => {
      isMounted = false
      dragNDropService.unsubscribeFromDrag(DRAG_EVENT_TYPES.addToCollection, sudcribeToDragFnId)
    }
  }, [])

  /* since we have drag here, we need to identify when row is clicked and when is captured for drag
    time diff is used for that -> when clicked, time netween mousedown and mouse up should be within CLICK_EVENT_MAX_TIME_MS
   */

  //
  const link = `/profile/${user._id}`
  const goToProfile = e => history.push(link)
  const onStarClick = newRating => actionHandlers.modifyUser(user._id, 'stars', parseInt(newRating))
  const selectSingle = event => actionHandlers.selectOne(user._id)
  const userlanguage = languageOptions.find(el => el.value === user.language) || {}
  const isFullTable = !tableForSimilarAccounts // add more limits here for future short tables

  //
  const onRowClick = () => {
    setSaveOpenedProfileId(user?._id)
    setLastPageBeforeProfileRedirect(beforeRedirectPage)
    dragNDropService.reactToClickEvent({
      callback: goToProfile,
    })
  }

  const stopEventBubbling = e => e.stopPropagation()

  const dragProps = allowAddToCollectionsViaDrag
    ? dragNDropService.registerDraggingHandlers({
        dragEventType: DRAG_EVENT_TYPES.addToCollection,
        dragElementProps: user,
      })
    : {}

  return (
    <>
      <ProfileListUserRowDetails
        {...{
          user,
          dragProps,
          selectSingle,
          isCollectionView,
          isUnlockedCollectionOpened,
          applyMocked,
          isFullTable,
          privateRating,
          userlanguage,
          showUserGrowth,
          onStarClick,
          onRowClick,
          stopEventBubbling,
          link,
          isBlackListCollection,
          onBlockUnblockUser,
          isSelectedProfileView,
          setIsOpenPipelineModal,
          hasNotCheckboxes,
          isExplore,
          lookalikeTypeData
        }}
      />
      {isRowDragging && (
        <>
          <GlobalDraggingStyles />
        </>
      )}
      <DraggableUser draggableCords={draggingCords} username={user.username} />

      <AddToCampaignPipelineModal
        profile={user}
        platform={user._index}
        isOpened={isOpenedPipelineModal}
        closeModal={() => setIsOpenPipelineModal(false)}
        disableAutoFocus
        disableEnforceFocus
      />
    </>
  )
}

ProfileListUserRow.propsTypes = {
  actionHandlers: PropTypes.shape({
    modifyUser: PropTypes.func,
    selectAllUsers: PropTypes.func,
    selectOne: PropTypes.func,
  }),
  user: PropTypes.object.isRequired,
  isCollectionView: PropTypes.bool,
  isUnlockedCollectionOpened: PropTypes.bool,
  tableForSimilarAccounts: PropTypes.bool,
  applyMocked: PropTypes.bool,
  privateRating: PropTypes.string,
  beforeRedirectPage: PropTypes.string,
  setLastPageBeforeProfileRedirect: PropTypes.func,
  setSaveOpenedProfileId: PropTypes.func,
  showUserGrowth: PropTypes.bool,
  allowAddToCollectionsViaDrag: PropTypes.bool,
  isBlackListCollection: PropTypes.bool,
  onBlockUnblockUser: PropTypes.func,
  isSelectedProfileView: PropTypes.bool,
  hasNotCheckboxes: PropTypes.bool,
  isExplore: PropTypes.bool,
  lookalikeTypeData: PropTypes.string
}

export default withReduxActions({ setLastPageBeforeProfileRedirect, setSaveOpenedProfileId })(
  ProfileListUserRow
)
