import React from 'react'
import PropTypes from 'prop-types'
import { Paper, Typography } from '@mui/material'

export const SearchNotFound = ({ sx, ...other }) => {
  return (
    <Paper
      sx={{
        textAlign: 'center',
        ...sx,
      }}
      {...other}
    >
      <Typography variant="h6" paragraph>
        Not found
      </Typography>
    </Paper>
  )
}

SearchNotFound.propTypes = {
  sx: PropTypes.object,
}
