import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, ListItemAvatar } from '@mui/material'
import { StyledListItem, StyledListItemText } from './styles'
// import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded'

export const CreatorsListItem = props => {
  let { creator, isAllowedToAddCreators } = props || {}

  isAllowedToAddCreators = true

  const onOpenCreatorProfile = () => {
    //window.open(`${process.env.REACT_APP_PREFIX}/profile/${creator._id}`, '_blank')
    window.open(
      `${process.env.REACT_APP_PREFIX}audience-reports?instagramId=${creator.instagramId}`,
      '_blank'
    )
  }

  const handleAddCreator = e => {
    e.stopPropagation()
    window.open(
      `${process.env.REACT_APP_PREFIX}audience-reports?instagramId=${creator._id}`,
      '_blank'
    )
  }

  return (
    <StyledListItem
      onClick={onOpenCreatorProfile}
      secondaryAction={
        <div>
        </div>
      }
    >
      <ListItemAvatar>
        <Avatar
          alt={creator.username ? creator.username : creator.displayName}
          src={creator.profilePicURL}
        />
      </ListItemAvatar>
      <StyledListItemText
        primary={creator.username ? `@${creator.username}` : `@${creator.displayName}`}
      />
    </StyledListItem>
  )
}

CreatorsListItem.propTypes = {
  creator: PropTypes.object,
  isAllowedToAddCreators: PropTypes.bool,
}