import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Card,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  alpha,
  useTheme,
} from '@mui/material'
import { fPercent } from '../../utils/formatNumber'
import { pretifyBigNum } from '../../../utils'
import Chart, { useChart } from '../chart'
import Iconify from '../iconify'
import { TooltipComponent } from '../tooltip/TooltipComponent'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import DownloadIcon from '@mui/icons-material/Download'
import html2canvas from 'html2canvas'
import pptxgen from 'pptxgenjs'
import useTranslation from '../../../localization/useTranslation'

// ----------------------------------------------------------------------

export default function ChartCardWidget({
  title,
  subtitle,
  helperText,
  percent,
  total,
  chart,
  sx,
  ...other
}) {
  const theme = useTheme()

  const { labelStrings } = useTranslation()

  const cardRef = useRef(null)

  const [anchorEl, setAnchorEl] = useState(null)

  const handleDownloadClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleDownloadClose = () => {
    setAnchorEl(null)
  }

  const exportToPowerPoint = async () => {
    const cardElement = cardRef.current
    const cardImage = await html2canvas(cardElement)
    const cardDataUrl = cardImage.toDataURL()

    const pptx = new pptxgen()
    const slide = pptx.addSlide()

    const cardAspectRatio = cardElement.offsetWidth / cardElement.offsetHeight
    const slideWidth = 10
    const slideHeight = slideWidth / cardAspectRatio

    slide.addImage({
      data: cardDataUrl,
      x: 0,
      y: 0,
      w: slideWidth,
      h: slideHeight,
    })

    pptx.writeFile('BigCardWidget.pptx')
    handleDownloadClose()
  }

  const exportToImage = async () => {
    const cardElement = cardRef.current
    const cardImage = await html2canvas(cardElement)
    const cardDataUrl = cardImage.toDataURL('image/png')

    const link = document.createElement('a')
    link.href = cardDataUrl
    link.download = 'BigCardWidget.png'
    link.click()
    handleDownloadClose()
  }

  const {
    colors = [theme.palette.primary.light, theme.palette.primary.main],
    series,
    options,
  } = chart

  const chartOptions = useChart({
    // colors: [colors[1]],
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: [
          { offset: 0, color: colors[0], opacity: 1 },
          { offset: 100, color: colors[1], opacity: 1 },
        ],
      },
    },
    chart: {
      animations: {
        enabled: true,
      },
      sparkline: {
        enabled: true,
      },
    },
    tooltip: {
      ...options.tooltip,
      x: {
        show: true,
      },
      y: {
        labels: {
          formatter: value => {
            return value
          },
        },
        title: {
          formatter: () => '',
        },
      },
      marker: {
        show: false,
      },
    },
    ...options,
  })

  const renderTrending = (
    <Stack direction="row" alignItems="center" justifyContent='center'>
      <Iconify
        icon={percent < 0 ? 'eva:trending-down-fill' : 'eva:trending-up-fill'}
        sx={{
          mr: 1,
          p: 0.5,
          width: 24,
          height: 24,
          borderRadius: '50%',
          color: 'success.main',
          bgcolor: alpha(theme.palette.success.main, 0.16),
          ...(percent < 0 && {
            color: 'error.main',
            bgcolor: alpha(theme.palette.error.main, 0.16),
          }),
        }}
      />

      <Typography variant="subtitle2" component="div" sx={{ color: '#FFFFFF' }} noWrap>
        {percent > 0 && '+'}

        {fPercent(percent)}

        <Box
          component="span"
          sx={{ color: '#D6D6D6', typography: 'body2', whiteSpace: 'pre-line' }}
        >
          {` ${labelStrings?.thanDashboardStart}`}
        </Box>
      </Typography>
    </Stack>
  )

  return (
    <Card
      ref={cardRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        backgroundColor: '#505050',
        boxShadow: 0,
        position: 'relative',
        ...sx,
      }}
      {...other}
    >
      <Stack direction="row" sx={{ width: '100%', mb: 1 }}>
        <Box
          sx={{
            flexGrow: 1,
            width: '60%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="subtitle2" sx={{ color: '#FFFFFF' }}>
            {title}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: '#D6D6D6' }}>
            {subtitle}
          </Typography>
        </Box>
        <Stack direction="row" spacing={1}>
          {false && helperText && (
            <TooltipComponent text={helperText}>
              <InfoOutlinedIcon sx={{ fontSize: '19px', cursor: 'pointer', color: '#D6D6D6' }} />
            </TooltipComponent>
          )}
          <IconButton aria-label="Download" onClick={handleDownloadClick}>
            <DownloadIcon sx={{ fontSize: '19px', color: '#FFFFFF' }} />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleDownloadClose}>
            <MenuItem onClick={exportToPowerPoint}>Download as PPTX</MenuItem>
            <MenuItem onClick={exportToImage}>Download as Image</MenuItem>
          </Menu>
        </Stack>
      </Stack>

      <Stack
        spacing={1}
        sx={{
          width: '100%',
          minHeight: '110px',
        }}
        alignItems="center"
      >
        <Typography variant="h3" sx={{ color: '#FFFFFF' }}>
          {pretifyBigNum(total)}
        </Typography>
        <Chart
          dir="ltr"
          type="line"
          series={[{ data: series }]}
          options={chartOptions}
          width={96}
          height={64}
        />
      </Stack>
      <Box>{renderTrending}</Box>
    </Card>
  )
}

ChartCardWidget.propTypes = {
  chart: PropTypes.object,
  percent: PropTypes.number,
  sx: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  total: PropTypes.number,
  helperText: PropTypes.string,
}
