import { SOCIAL_PLATFORMS_NAMES } from '../../../constants/appSettings'

export const PLATFORM_OPTIONS = [
  'all',
  SOCIAL_PLATFORMS_NAMES.instagram.index,
  SOCIAL_PLATFORMS_NAMES.tiktok.index,
  SOCIAL_PLATFORMS_NAMES.youtube.index,
]

export const applyFilter = ({
  inputData,
  comparator,
  filterName,
  filterStatus,
  filterPlatform,
}) => {
  const stabilizedThis = inputData.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })

  inputData = stabilizedThis.map(el => el[0])

  if (filterName) {
    inputData = inputData.filter(
      user =>
        user.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        user.matchMethod.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    )
  }

  if (filterStatus !== 'all' && filterStatus !== 'cpms') {
    inputData = inputData.filter(user => user.status === filterStatus)
  }

  if (filterStatus === 'cpms') {
    inputData = inputData.filter(user => user.status === 'active')
  }

  if (filterPlatform !== 'all') {
    inputData = inputData.filter(user => user.platform === filterPlatform)
  }

  return inputData
}

export const filteredData = (arr, val) => {
  const result = arr?.filter(overview => overview._id === val)
  return result?.length ? result[0] : {}
}

const _findValueByType = (arr, objType) => {
  let value = ''
  const index = arr?.findIndex(el => el.type === objType)
  if (index > -1) {
    value = arr[index].value
  }
  return value
}

export const getCreatorOverviewFieldsData = graphData => {
  const creatorContactMethods = graphData.fromCampaignData?.creatorContactMethods
  const email = creatorContactMethods ? _findValueByType(creatorContactMethods, 'email') : null
  const phone = creatorContactMethods ? _findValueByType(creatorContactMethods, 'phone') : null

  return {
    id: graphData._id,
    overwriteAudience: graphData.fromCampaignData?.overwriteAudience,
    shouldContactCreator: graphData.fromCampaignData?.shouldContactCreator || false,
    enablePricing: graphData.fromCampaignData?.enablePricing || false,
    username: graphData.username,
    overwriteName: graphData.fromCampaignData?.overwriteName,
    bitlyLink: graphData.fromCampaignData?.bitlyLink,
    email,
    phone,
    pricing: graphData.fromCampaignData.pricing,
    countryArray: graphData.fromCampaignData?.manualAudienceReport?.countryArray || [],
    ageArray:
      graphData.fromCampaignData?.manualAudienceReport?.ageArray ||
      graphData.fromCampaignData?.manualAudienceReport?.averageBirthyearArray ||
      [],
    genderArray:
      (graphData?.fromCampaignData?.manualAudienceReport?.genderArray || [])?.length ||
      Object.keys(graphData?.fromCampaignData?.manualAudienceReport?.genderMtoF || {}).length
        ? graphData?.fromCampaignData?.manualAudienceReport?.genderArray ||
          graphData?.fromCampaignData?.manualAudienceReport?.genderMtoF
        : {},
    languageArray: graphData.fromCampaignData?.manualAudienceReport?.languageArray || [],
  }
}

function findNestedProp(obj, prop) {
  let parts = prop.split('.'),
    last = parts.pop(),
    l = parts.length,
    i = 1,
    current = parts[0]

  while ((obj = obj[current]) && i < l) {
    current = parts[i]
    i++
  }

  if (obj) {
    return obj[last]
  }
}

const _objHasKeys = (obj, keys) => {
  var next = keys.shift()
  return obj[next] && (!keys.length || _objHasKeys(obj[next], keys))
}

export const checkChangesInNestedObject = (defaultValues, data, fields) => {
  return fields.some(field => findNestedProp(data, field) != findNestedProp(defaultValues, field))
}

const checkChangesInObject = (defaultValues, data, fields) => {
  return fields.some(field => data[field] != defaultValues[field])
}

export const preparedPriceCreatorFields = (data, defaultValues) => {
  // {
  //   collaborationPrice: null,
  //   price_instagram_story: 100,
  //   price_instagram_reel: 50,
  //   ....
  //   }
  const changedEnablePricing = checkChangesInObject(defaultValues, data, ['enablePricing'])
  const changedCollaborationPrice = checkChangesInNestedObject(defaultValues, data, [
    'pricing.collaborationPrice',
  ])
  const changedPrice = checkChangesInNestedObject(defaultValues, data, [
    'pricing.priceForStory',
    'pricing.priceForPost',
    'pricing.priceForReel',
    'pricing.priceForTikTok',
    'pricing.priceForYTVideo',
    'pricing.priceForYTShort',
    'pricing.additionalCosts',
  ])

  let result = null
  if (
    (changedEnablePricing && data.enablePricing) ||
    (data.enablePricing && changedCollaborationPrice) ||
    (data.enablePricing && changedPrice)
  ) {
    if (changedCollaborationPrice && data.pricingTrack === 'trackEntireCollaboration') {
      result = {
        pricing: {
          collaborationPrice: Number(data.pricing.collaborationPrice),
          priceForStory: null,
          priceForPost: null,
          priceForReel: null,
          priceForTikTok: null,
          priceForYTVideo: null,
          priceForYTShort: null,
          additionalCosts: null,
        },
      }
    } else if (changedPrice && data.pricingTrack === 'trackEachContentPiece') {
      result = {
        pricing: {
          collaborationPrice: null,
          priceForStory: Number(data.pricing.priceForStory),
          priceForPost: Number(data.pricing.priceForPost),
          priceForReel: Number(data.pricing.priceForReel),
          priceForTikTok: Number(data.pricing.priceForTikTok),
          priceForYTVideo: Number(data.pricing.priceForYTVideo),
          priceForYTShort: Number(data.pricing.priceForYTShort),
          additionalCosts: Number(data.pricing.additionalCosts),
        },
      }
    }
  } else if (changedEnablePricing && !data.enablePricing) {
    result = null
  }

  return result
}

export const preparedContactCreatorFields = (data, defaultValues) => {
  let result = null
  const changedEnableContact = checkChangesInObject(defaultValues, data, ['shouldContactCreator'])
  const changedEmail = checkChangesInObject(defaultValues, data, ['email'])
  const changedPhone = checkChangesInObject(defaultValues, data, ['phone'])

  if (
    (changedEnableContact && data.shouldContactCreator) ||
    (changedEmail && data.shouldContactCreator) ||
    (changedPhone && data.shouldContactCreator)
  ) {
    result = [
      ...(changedEmail && data.email ? [{ type: 'email', value: data.email }] : ''),
      ...(changedPhone && data.phone
        ? [{ type: 'phone', value: data.phone.includes('+') ? data.phone : `+${data.phone}` }]
        : ''),
    ]
  } else if (changedEnableContact && !data.shouldContactCreator) {
    result = null
  }

  return result
}

export const isChangedEnableCreatorPricing = (data, defaultValues) => {
  const changedEnablePricing = checkChangesInObject(defaultValues, data, ['enablePricing'])

  const changedCollaborationPrice = checkChangesInNestedObject(defaultValues, data, [
    'pricing.collaborationPrice',
  ])
  const changedPrice = checkChangesInNestedObject(defaultValues, data, [
    'pricing.priceForStory',
    'pricing.priceForPost',
    'pricing.priceForReel',
    'pricing.priceForTikTok',
    'pricing.priceForYTVideo',
    'pricing.priceForYTShort',
    'pricing.additionalCosts',
  ])
  return changedEnablePricing || changedCollaborationPrice || changedPrice
}

export const isChangedContactCreatorFields = (data, defaultValues) => {
  const changedEnableContact = checkChangesInObject(defaultValues, data, ['shouldContactCreator'])
  const changedEmail = checkChangesInObject(defaultValues, data, ['email'])
  const changedPhone = checkChangesInObject(defaultValues, data, ['phone'])

  return changedEnableContact || changedEmail || changedPhone
}

const isEqualObjects = (o1, o2) => {
  return (
    Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(p => o1[p] === o2[p])
  )
}

const compareArrays = (a1, a2) => {
  return a1.length === a2.length && a1.every((o, idx) => isEqualObjects(o, a2[idx]))
}

export const getNotEmptyAttributes = obj => {
  return Object.keys(obj)
    .filter(k => obj[k] != null)
    .reduce((a, k) => ({ ...a, [k]: +obj[k] }), {})
}

export const isChangedManualAudienceReportFields = (data, defaultValues, countryOptions) => {
  const defaultCountryArray = preparedCountryFields(defaultValues.countryArray, countryOptions)
  const defaultLanguageArray = preparedLanguageFields(defaultValues.languageArray)
  const defaultAgeArray = preparedAgeFields(defaultValues.ageArray)
  const genderObject = getNotEmptyAttributes(data.genderArray)

  const isChangedGenderObj = !isEqualObjects(defaultValues.genderArray, genderObject)
  const isChangedCountryArray = !compareArrays(data.countryArray, defaultCountryArray)
  const isChangedLanguageArray = !compareArrays(data.languageArray, defaultLanguageArray)
  const isChangedAgeArray = !compareArrays(data.ageArray, defaultAgeArray)

  return isChangedLanguageArray || isChangedCountryArray || isChangedAgeArray || isChangedGenderObj
}

export const getNotEmptyValues = (arr = []) => {
  const newArr = []
  arr.forEach(v => {
    if (Object.keys(v).some(k => v[k] !== '')) newArr.push(v)
  })
  return newArr
}

export const preparedCountryArray = (data, countryOptions) => {
  const countryArray = data.countryArray.map(el => {
    const index = countryOptions.findIndex(c => c.isoCode === el.country)
    return index > -1 ? { ...el, country: countryOptions[index].value, value: +el.value } : el
  })

  return getNotEmptyValues(countryArray)
}

export const preparedManualAudienceReport = (data, countryOptions) => {
  const countryArray = preparedCountryArray(data, countryOptions)
  const languageArray = getNotEmptyValues(data.languageArray)
  const ageArray = data.ageArray.filter(el => el.value !== '')
  const genderMtoF = getNotEmptyAttributes(data.genderArray)

  return {
    averageBirthyearArray: ageArray,
    countryArray,
    languageArray,
    genderMtoF,
  }
}

export const preparedCountryFields = (data, countryOptions) => {
  const defaultArray = Array.from({ length: 5 }).fill({ country: '', value: '' })

  const newArr =
    data?.map(item => {
      const isoCode = countryOptions?.find(el => el.value === item.country)?.isoCode
      return { ...item, country: isoCode }
    }) || []

  const length = defaultArray.length - newArr.length
  return [...newArr, ...defaultArray.slice(-length)]
}

export const preparedLanguageFields = data => {
  const defaultArray = Array.from({ length: 5 }).fill({ language: '', value: '' })
  const length = defaultArray.length - data.length
  return [...data, ...defaultArray.slice(-length)]
}

export const preparedAgeFields = data => {
  const defaultArray = [
    { birthyear: '13-17', value: '' },
    { birthyear: '18-24', value: '' },
    { birthyear: '25-34', value: '' },
    { birthyear: '35-44', value: '' },
    { birthyear: '45-54', value: '' },
    { birthyear: '55+', value: '' },
  ]

  const newArr =
    data?.map(item => {
      const index = defaultArray?.findIndex(el => el.birthyear === item.birthyear)
      return defaultArray[index].birthyear === item.birthyear && { ...item }
    }) || []

  const restArr = defaultArray?.filter(item => {
    return newArr?.every(el => el.birthyear !== item.birthyear)
  })
  return [...newArr, ...restArr]?.sort((a, b) => (a?.birthyear > b?.birthyear ? 1 : -1))
}

export const updateObjectById = (data, idToUpdate, updatedObject) => {
  const index = data.findIndex(obj => obj._id === idToUpdate)

  if (index !== -1) {
    data[index] = { ...data[index], ...updatedObject }
  }

  return data
}
