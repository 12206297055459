import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { RHFTextField } from '../../../components/hook-form'
import useTranslation from '../../../../localization/useTranslation'

export const StepOne = () => {
  const { labelStrings } = useTranslation()

  return (
    <Stack alignItems="center">
      <Typography
        component="label"
        sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '24px', mb: 1 }}
      >
        {labelStrings.createCampaign.steps.one.label}
      </Typography>
      <Box sx={{ width: '370px' }}>
        <RHFTextField
          name="campaignName"
          label={labelStrings.createCampaign.steps.one.fieldLabel}
          helperText={labelStrings.createCampaign.steps.one.helperText}
          sx={{ minWidth: '370px' }}
        />
      </Box>
    </Stack>
  )
}
