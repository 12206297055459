import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { USER_INDEXES, SOCIAL_LINKS_PATHS } from '../../../../constants/appSettings'
import { GalleryItem } from './styles'

const LatestPostsGalleryItem = ({ platform = '', username = '', imgSrc = '', platformPostCode = '' }) => {
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const img = new Image()
    img.crossOrigin = '*'
    img.src = imgSrc
    img.onerror = () => setIsError(true)
  }, [])

  let linkToPost
  switch (platform) {
    case USER_INDEXES.instagram:
      linkToPost = SOCIAL_LINKS_PATHS.postsInstagram(platformPostCode)
      break
    case USER_INDEXES.tiktok:
      linkToPost = SOCIAL_LINKS_PATHS.postsTiktok(username, platformPostCode)
      break
  }

  if (isError) return null

  return <GalleryItem href={linkToPost} platform={platform} imgSrc={imgSrc} />
}

LatestPostsGalleryItem.propTypes = {
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  username: PropTypes.string.isRequired,
  imgSrc: PropTypes.string,
  platformPostCode: PropTypes.string,
}

export default LatestPostsGalleryItem
