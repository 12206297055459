import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  userGrantSelector,
  isUserAuthenticatedSelector,
  isUserDetailsPresentSelector,
  isUserDetailsLoadingSelector,
  isSubUserSelector,
} from '../../redux/selectors'
import { withReduxActions } from '../../hocs/withRedux'
import { getUserDetails } from '../../redux/ducks/userDucks'

import BackToTopOfPage from '../common/backToTopOfPage'
import DeleteAccountContainer from './deleteAccount/DeleteAccountContainer'
import AccountSettingsContainer from './accountSettings/AccountSettingsContainer'
import BillingDetailsContainer from './billingDetails/BillingDetailsContainer'
import PaymentsContainer from './payments/PaymentsContainer'
import AddOnsContainer from './addOns/AddOnsContainer'
import SubUsersContainer from './subUsers/SubUsersContainer'
import SubUserSettingsTip from './SubUserSettingsTip'
import { SectionsDivider, SettingsContainerWrapper } from '../common/styledWrappers'

const SettingsContainer = ({ pageId = '', getUserDetails }) => {
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const {
    package_free: isFreeUser,
    no_plan: isNoPlan,
  } = useSelector(userGrantSelector)
  const isUserDetailsPresent = useSelector(isUserDetailsPresentSelector)
  const isUserDetailsLoading = useSelector(isUserDetailsLoadingSelector)
  const isSubUser = useSelector(isSubUserSelector)

  const showDeleteAccount = isNoPlan

  useEffect(() => {
    if (isUserAuthenticated) {
      !isUserDetailsPresent && !isUserDetailsLoading && getUserDetails()
    }
  }, [isUserAuthenticated])

  return (
    <SettingsContainerWrapper>
      <>
        {isSubUser && <SubUserSettingsTip />}
        {isUserAuthenticated && (
          <>
            <AccountSettingsContainer />
            {!isSubUser && (
              <>
                <BillingDetailsContainer />
                <PaymentsContainer />
              </>
            )}
            {!isFreeUser && !isSubUser && <AddOnsContainer />}
            <SubUsersContainer />
          </>
        )}
      </>

      <SectionsDivider width="90%" />
      <BackToTopOfPage pageId={pageId} />
      {isUserAuthenticated && (
        <>
          {showDeleteAccount && (
            <>
              <SectionsDivider width="90%" />
              <DeleteAccountContainer />
            </>
          )}
        </>
      )}
    </SettingsContainerWrapper>
  )
}

SettingsContainer.propTypes = {
  pageId: PropTypes.string,
  getUserDetails: PropTypes.func,
}

export default withReduxActions({ getUserDetails })(SettingsContainer)
