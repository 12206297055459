import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { themeCommonStyles } from '../../../themes/infludataThemes'

export const ContentWrapper = styled.div`
  font-family: ${props => props.theme.fontFamilyInter};
  font-size: 15px;
  font-weight: bold;
  line-height: 19px;
  text-transform: uppercase;
  ${props => !props.isDisabled && props.theme.getTextGradientStyles({ gradient: props.gradient })}
`

export const ButtonWrapper = styled.button`
  border-radius: 9px;
  padding: 5px 10px;
  user-select: none;

  ${props =>
    props.isDisabled
      ? `
    border: 1px solid ${props.theme.colors.mauve};
    color: ${props.theme.colors.mauve};
    opacity: 0.3;
    background: none;
  `
      : `
    border: solid 1px transparent;
    background-image:
    linear-gradient(rgba(255, 255, 255, 0),rgba(255, 255, 255, 0)),
      ${props.gradient};
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px ${props.backgroundColor} inset;

    &:hover {
      box-shadow: none;

      ${ContentWrapper} {
        background: transparent;
        -webkit-background-clip: ${props.theme.colors.white};
        -webkit-text-fill-color: white;
      }
    }

    ${props.isActive &&
      `
      &:hover {
        filter: brightness(0.9)
      }

      box-shadow: none;
      ${ContentWrapper} {
        background: transparent;
        -webkit-background-clip: ${props.theme.colors.white};
        -webkit-text-fill-color: white;
      }
    `}
  `}
`

const GradientTransparentButton = ({
  children = null,
  gradient = themeCommonStyles.gradients.main,
  backgroundColor = themeCommonStyles.colors.white,
  isDisabled = false,
  isActive = false,
  ...props
}) => {
  return (
    <ButtonWrapper
      className="gradient-transparent-button"
      // @ts-ignore
      gradient={gradient}
      backgroundColor={backgroundColor}
      isDisabled={isDisabled}
      disabled={isDisabled}
      isActive={isActive}
      {...props}
    >
      <ContentWrapper
        className="gradient-transparent-button__content-wrapper"
        // @ts-ignore
        gradient={gradient}
        isDisabled={isDisabled}
      >
        {children}
      </ContentWrapper>
    </ButtonWrapper>
  )
}

GradientTransparentButton.propTypes = {
  children: PropTypes.node,
  gradient: PropTypes.string,
  backgroundColor: PropTypes.string,
  isDisabled: PropTypes.bool,
  isActive: PropTypes.bool,
}

export default GradientTransparentButton
