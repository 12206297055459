import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SuggestLocationsInput from './SuggestLocationsInput'
import LocationList from './LocationList'
import { isUserAuthenticatedSelector, searchDataSelector } from '../../redux/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { getGoogleLocationByLetters } from '../../redux/ducks/searchDucks'
import useDebounce from '../../hooks/useDebounce'
import { AnalyzeBarWrapper } from './styles'

export const SuggestedLocationsContainer = ({
  selectLocationNextAction,
  userindex = '',
  withLabel = false,
  withPlaceholder = false,
  placeholderText = '',
  withParentHeight = false,
  watchChangeUserindex = false,
  closeOnLocationSelect = false,
  resetOnLocationSelect = false,
  isAnalyzeSuggest = false,
  id = '',
  isDocumentOutsideClicked = false, // using this var we can close list when user click on other elements of page
  openedSuggestListInformer = null,
}) => {
  const dispatch = useDispatch()
  // const { enable_username_search } = useSelector(userGrantSelector, shallowEqual)
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const { searchLocation } = useSelector(searchDataSelector)
  const disabledSearch = !isUserAuthenticated //|| !enable_username_search

  const suggestedUserStaticIdPart = `suggested_`

  const defaultState = {
    suggestedLocations: [],
    inputValue: '',
    resultsVisible: true,
    selectedLocation: '',
  }

  const [inputValue, changeInputValue] = useState(defaultState.inputValue)
  const [suggestedLocations, changeSuggestedLocations] = useState(defaultState.suggestedLocations)
  const [resultsVisible, toggleVisibleResults] = useState(defaultState.resultsVisible)
  const [selectedLocation, setSelectedLocation] = useState(defaultState.selectedLocation)
  const debouncedInputValue = useDebounce(inputValue, 300)

  const onChange = async ({ target: { value } }) =>
    changeInputValue(!value && suggestedLocations?.length ? defaultState.inputValue : value)

  useEffect(() => {
    if (!debouncedInputValue && suggestedLocations?.length) {
      changeSuggestedLocations(defaultState.suggestedLocations)
    }
    if (debouncedInputValue?.length >= 4 && !disabledSearch) {
      dispatch(getGoogleLocationByLetters({ letters: debouncedInputValue }))
    }
  }, [debouncedInputValue, disabledSearch])

  useEffect(() => {
    if (watchChangeUserindex && inputValue?.length > 4 && !disabledSearch) {
      dispatch(getGoogleLocationByLetters({ letters: debouncedInputValue }))
    }
  }, [userindex, disabledSearch])

  useEffect(() => {
    // this effect watches outside list clicks and closes list,
    if (!!isDocumentOutsideClicked && !!resultsVisible) toggleVisibleResults(!resultsVisible)
  }, [isDocumentOutsideClicked])

  useEffect(() => {
    // is used if we need to inform parent that results are opened
    openedSuggestListInformer && openedSuggestListInformer(!!resultsVisible && !!inputValue)
  }, [resultsVisible, inputValue])

  const clearInputHandler = () => {
    changeInputValue(defaultState.inputValue)
    setSelectedLocation(defaultState.selectedLocation)
    selectLocationNextAction(defaultState.selectedLocation)
    watchChangeUserindex && changeSuggestedLocations(defaultState.suggestedLocations)
  }

  const locationSelectClickHandler = selectedId => {
    resetOnLocationSelect && changeSuggestedLocations(defaultState.suggestedLocations)
    closeOnLocationSelect && changeInputValue(defaultState.inputValue)
    if (selectedId) {
      selectLocationNextAction(selectedId)
    }
  }

  const onFocus = () => {
    if (!resultsVisible) toggleVisibleResults(!resultsVisible)
  }

  const onBlur = ({ relatedTarget = {} }) => {
    if (relatedTarget && relatedTarget.id && relatedTarget.id.includes(suggestedUserStaticIdPart)) {
      return
    }
    !watchChangeUserindex && toggleVisibleResults(!resultsVisible)
  }

  const inputProps = {
    id,
    withLabel,
    inputValue: selectedLocation ? selectedLocation : inputValue,
    onChange,
    onBlur,
    onFocus,
    withParentHeight,
    withPlaceholder,
    placeholderText,
    isAnalyzeSuggest,
    clearInputHandler,
    userindex,
  }

  return (
    <AnalyzeBarWrapper withParentHeight={withParentHeight}>
      <SuggestLocationsInput {...inputProps} />

      {isAnalyzeSuggest && (
        <>
          <LocationList
            isVisible={!!inputValue && !!resultsVisible}
            userindex={userindex}
            locationsList={searchLocation}
            suggestedLocationStaticIdPart={suggestedUserStaticIdPart}
            onLocationSelect={locationSelectClickHandler}
            setSelectedLocation={setSelectedLocation}
            isInputValue={!!inputValue}
            disableSearch={disabledSearch}
          />
        </>
      )}
    </AnalyzeBarWrapper>
  )
}

SuggestedLocationsContainer.propTypes = {
  selectLocationNextAction: PropTypes.func,
  userindex: PropTypes.string,
  withLabel: PropTypes.bool,
  withPlaceholder: PropTypes.bool,
  placeholderText: PropTypes.string,
  useParentHeight: PropTypes.bool,
  watchChangeUserindex: PropTypes.bool,
  closeOnLocationSelect: PropTypes.bool,
  resetOnLocationSelect: PropTypes.bool,
  isAnalyzeSuggest: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isDocumentOutsideClicked: PropTypes.bool,
  openedSuggestListInformer: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(null)]),
}

export default SuggestedLocationsContainer
