import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectedProfileSelector } from '../../../../redux/selectors'
import SponsoredMediaScores from '../../detailedInfludataRating/brandValue/sponsoredMediaScores/SponsoredMediaScores'
import SmartMediaValue from '../../audienceAnalysis/smartMediaValue/SmartMediaValue'
import { Section, SectionTitle } from '../commonStyles'
import PageLayout from '../PageLayout/PageLayout'
import MentionedProfiles from '../../detailedInfludataRating/brandValue/mentionedProfiles/MentionedProfiles'
import { useLocalStorage } from '../../../../hooks/useLocalStorage'
import useTranslation from '../../../../localization/useTranslation'

const IgPageBrandValue = ({ isBranded = true, isShowBrandMentions = true, isShowSmartMediaValue = true }) => {
  const { labelStrings } = useTranslation()
  const { profile } = useSelector(selectedProfileSelector)
  const {
    _id: profileId,
    username,
    _index: platform,
    profileScore,
    cpms,
    brandCollaborations,
    creatorCollaborations,
  } = profile || {}

  const { mentionAccountsAndTimeline } = creatorCollaborations || brandCollaborations || {}

  const [currencyFromStorage] = useLocalStorage('currency')

  return (
    <>
      <PageLayout
        profileId={profileId}
        username={username}
        platform={platform}
        isBranded={isBranded}
      >
        <SectionTitle
          style={{ marginBottom: 0 }}
          platform={platform}
        >{`${labelStrings.brandValue} & ${labelStrings.audienceSmartMediaValue}`}</SectionTitle>
        {isShowBrandMentions && (
          <Section style={{ paddingBottom: 0 }}>
            <SponsoredMediaScores profileScores={profileScore} isForPdf />
          </Section>
        )}
        {isShowSmartMediaValue && (
          <Section noPadding>
            <SmartMediaValue
              cpms={cpms}
              platform={platform}
              isForPdf
              currency={currencyFromStorage}
            />
          </Section>
        )}
      </PageLayout>
      {isShowBrandMentions && (
        <PageLayout
          profileId={profileId}
          username={username}
          platform={platform}
          isBranded={isBranded}
        >
          <Section>
            <SectionTitle platform={platform}>{labelStrings.sponsoredPostsAndStories}</SectionTitle>
            <MentionedProfiles
              platform={platform}
              profileUsername={username}
              mentionAccountsAndTimeline={mentionAccountsAndTimeline}
              isForPdf
            />
          </Section>
        </PageLayout>
      )}
    </>
  )
}

IgPageBrandValue.propTypes = {
  isBranded: PropTypes.bool,
  isShowBrandMentions: PropTypes.bool,
  isShowSmartMediaValue: PropTypes.bool,
}

export default IgPageBrandValue
