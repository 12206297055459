import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'
import { USER_INDEXES } from '../../../constants/appSettings'
import { MIN_CHART_COUNT } from '../../../constants/components'
import { YT_TAB_SHORTS, YT_TAB_VIDEOS } from '../../../constants/profile'
import { themeCommonStyles } from '../../../themes/infludataThemes'
import LineChart from '../../charts/LineChart'
import InfoTip from '../../common/tips/InfoTip'
import {
  SectionTitleWrapper,
  SectionTitleName,
  SectionTitleBorder,
} from '../commonProfilePageStyles'
import {
  FollowersHistoryContainer,
  FollowersChartWrapper,
  BtnYtChartWrapper,
  BtnYTChart,
} from './styles'

const checkData = dataArr => {
  if (!Array.isArray(dataArr)) return false
  return !!dataArr
    ?.map(el => el[1])
    ?.slice(1)
    ?.reduce((a, b) => a + b, 0)
}

const FollowersHistory = ({
  chartData = [],
  platform = '',
  monthlyGrowthFollowers = [],
  onChartReadyCallback,
  isForPdf = false,
  isYt = false,
  ytCurrentType = '',
  allChartData = {},
}) => {
  const { labelStrings, profileSectionDescs } = useTranslation()
  const lineChartId = `${USER_INDEXES[platform]}_profile_followers_chart`

  const { medianViewsVideos, medianViewsShorts } = allChartData || {}

  const isMedianViewsVideos = useMemo(() => checkData(medianViewsVideos), [medianViewsVideos])
  const isMedianViewsShorts = useMemo(() => checkData(medianViewsShorts), [medianViewsShorts])

  const isNotEnoughDataForChart = isYt
    ? medianViewsVideos?.length < MIN_CHART_COUNT && medianViewsShorts?.length < MIN_CHART_COUNT
    : chartData?.length < MIN_CHART_COUNT

  const initialType = ytCurrentType
    ? ytCurrentType
    : isMedianViewsShorts
    ? YT_TAB_SHORTS
    : YT_TAB_VIDEOS
  const [currYtType, setCurrYtType] = useState(initialType)

  let chartBgColor = themeCommonStyles.colors.offWhite
  if (platform === USER_INDEXES.tiktok && isForPdf) {
    chartBgColor = '#EEFCFB'
  }

  const data = useMemo(
    () => (currYtType === YT_TAB_VIDEOS ? medianViewsVideos : medianViewsShorts),
    [allChartData, currYtType]
  )

  const lastMonthGrowthData = useMemo(
    () =>
      currYtType === YT_TAB_VIDEOS
        ? monthlyGrowthFollowers?.postViews &&
          Object.values(monthlyGrowthFollowers?.postViews)?.reverse()
        : monthlyGrowthFollowers?.shortsViews &&
          Object.values(monthlyGrowthFollowers?.shortsViews)?.reverse(),
    [monthlyGrowthFollowers, currYtType]
  )

  const titleName = isYt ? labelStrings.averageViews : labelStrings.followersHistory

  const isDisabledChartYt = useMemo(
    () =>
      (isYt && currYtType === YT_TAB_VIDEOS && !isMedianViewsVideos) ||
      (isYt && currYtType === YT_TAB_SHORTS && !isMedianViewsShorts),
    [currYtType, isYt, isMedianViewsShorts, isMedianViewsVideos]
  )

  return (
    <FollowersHistoryContainer isForPdf={isForPdf}>
      {!isForPdf && (
        <SectionTitleWrapper>
          <SectionTitleName platform={platform}>{titleName}</SectionTitleName>
          <InfoTip>
            <div
              dangerouslySetInnerHTML={{
                __html: isYt
                  ? profileSectionDescs?.viewsHistory
                  : profileSectionDescs.followersHistory,
              }}
            />
          </InfoTip>
          <SectionTitleBorder isForPdf={isForPdf} />
        </SectionTitleWrapper>
      )}

      {isYt && !isForPdf && (
        <BtnYtChartWrapper>
          <BtnYTChart
            isActive={currYtType === YT_TAB_VIDEOS}
            onClick={() => setCurrYtType(YT_TAB_VIDEOS)}
            isDisabled={!isMedianViewsVideos}
          >
            Videos
          </BtnYTChart>
          <BtnYTChart
            isActive={currYtType === YT_TAB_SHORTS}
            onClick={() => setCurrYtType(YT_TAB_SHORTS)}
            isDisabled={!isMedianViewsShorts}
          >
            Shorts
          </BtnYTChart>
        </BtnYtChartWrapper>
      )}

      <FollowersChartWrapper isForPdf={isForPdf}>
        <LineChart
          chartData={isYt ? data : chartData}
          userindex={platform}
          faqQuestionId=""
          isDisabledChart={isNotEnoughDataForChart || isDisabledChartYt}
          lastMonthGrowthData={isYt ? lastMonthGrowthData : monthlyGrowthFollowers}
          id={lineChartId}
          onChartReadyCallback={onChartReadyCallback}
          backgroundColor={chartBgColor}
          isForPdf={isForPdf}
        />
      </FollowersChartWrapper>
    </FollowersHistoryContainer>
  )
}

FollowersHistory.propTypes = {
  chartData: PropTypes.array,
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  ytCurrentType: PropTypes.oneOf([YT_TAB_SHORTS, YT_TAB_VIDEOS, '']),
  monthlyGrowthFollowers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onChartReadyCallback: PropTypes.func,
  isForPdf: PropTypes.bool,
  isYt: PropTypes.bool,
  allChartData: PropTypes.object,
}

export default FollowersHistory
