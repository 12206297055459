import React, { useState, useEffect, useCallback, memo } from 'react'
import PropTypes from 'prop-types'

import { shallowEqual, useSelector } from 'react-redux'
import useTranslation from '../../../localization/useTranslation'
import { withReduxActions } from '../../../hocs/withRedux'
import CollectionsBarRenderer from './CollectionsBarRenderer'
import ForbiddenDropTip from './ForbiddenDropTip'
import {
  createNewCollection,
  addElementToCollection,
  moveElementFromOpenedCollection,
} from '../../../redux/ducks/collectionsDucks'
import {
  canCreateCollectionsSelector,
  collectionsSelector,
  canAddToCollectionDynamicSelector,
  noRerenderEqFn,
} from '../../../redux/selectors'
import dragNDropService from '../../../services/dragNdropService'
import { DRAG_EVENT_TYPES } from '../../../constants/appSettings'
import { UNLOCKED_COLLECTION_NAME } from '../../../constants/collections'
import { delayFunc } from '../../../utils'

import WithUpgradeTip from '../../common/popups/WithUpgradeTip'
import { Box } from '@mui/material'
import NavToggleButton from './NavToggleBtn'

const CollectionsBarContainer = ({
  createNewCollection,
  allowCollections,
  useAsPopupWithDragDrop,
  addElementToCollection,
  moveElementFromOpenedCollection,
  navType = 'default',
  onChangeNavType,
  onHandleOpenViewType
}) => {
  const { labelStrings } = useTranslation()
  const collectionsList = useSelector(collectionsSelector, shallowEqual)
  const canCreateCollection = useSelector(canCreateCollectionsSelector)
  const canAddToCollectionDynamic = useSelector(canAddToCollectionDynamicSelector, noRerenderEqFn)

  const initDragCapture = {
    isCaptured: false,
    dragUser: {},
  }

  const initDropOperations = {
    inProgress: false,
    success: false,
  }

  const initCanDragItemToCollection = {
    forbidden: false,
    reason: '',
  }

  const [dragCapture, setDragCapture] = useState(initDragCapture)
  const resetDragCapture = () => setDragCapture(initDragCapture)

  const [dropOperations, setDropOperations] = useState(initDropOperations)
  const toggleDropOperations = operation => setDropOperations({ ...dropOperations, ...operation })

  const [activeForDropCollectionId, setActiveForDropCollectionId] = useState(null)

  const [canDragItemToCollection, setCanDragItemToCollection] = useState(
    initCanDragItemToCollection
  )

  useEffect(() => {
    const fnId = 'open_close_bar_fn'
    dragNDropService.subscribeToDrag(DRAG_EVENT_TYPES.addToCollection, {
      fnId,
      fn: barViewSubscribeFn(),
    })
    return () => {
      dragNDropService.unsubscribeFromDrag(DRAG_EVENT_TYPES.addToCollection, fnId)
    }
  }, [])

  useEffect(() => {
    canDragItemToCollectionChecker(activeForDropCollectionId)
  }, [activeForDropCollectionId])

  const barViewSubscribeFn = () => {
    let _isCaptured = false // closured because react scoped state var is not reactive inside native handlers
    return args => {
      const { isCaptured, dragElementProps } = args
      if (_isCaptured !== isCaptured) {
        _isCaptured = isCaptured
        setDragCapture({ isCaptured, dragUser: dragElementProps })
      }
    }
  }

  const handleDropToCollectionItem = async (collectionId, dragUser) => {
    if (useAsPopupWithDragDrop) {
      // in case of popuped collection sidebar, we add user to collection
      return await addElementToCollection(dragUser._id, collectionId, dragUser)
    }
    // otherwise, it means that we have collections sidebar on collections page and in this case we move element from opened collection to selected
    return await moveElementFromOpenedCollection([dragUser._id], collectionId)
  }

  const resetAllStatesToDefaults = () => {
    resetDragCapture()
    setActiveForDropCollectionId(null)
    toggleDropOperations(initDropOperations)
  }

  const onDragEnterCollectionItem = collectionId => () => setActiveForDropCollectionId(collectionId)
  const onDragLeaveCollectionList = () => setActiveForDropCollectionId(null)

  const onDropOverCollectionItem = collectionId => async ({ event, dragElementProps }) => {
    const isAllowed = canAddToCollectionDynamic({
      collectionId,
      checkPresenceUser: dragElementProps,
    })
    if (!isAllowed) return resetAllStatesToDefaults()
    toggleDropOperations({ inProgress: true })
    const success = await handleDropToCollectionItem(collectionId, dragElementProps)
    if (!success) return resetAllStatesToDefaults()
    toggleDropOperations({ inProgress: true, success: true })
    delayFunc(resetAllStatesToDefaults, 500)
  }

  const registerDropableHandlers = useCallback(collectionId => {
    return dragNDropService.registerDroppingHandlers(DRAG_EVENT_TYPES.addToCollection, {
      onDragEnter: onDragEnterCollectionItem(collectionId),
      onDrop: onDropOverCollectionItem(collectionId),
      // there is also onDragLeave in api, but its not used here. onDragLeave watches list component with onDragLeaveCollectionList method
    })
  }, [])

  const canDragItemToCollectionChecker = collectionId => {
    if (dropOperations.inProgress || !collectionId) {
      return setCanDragItemToCollection(initCanDragItemToCollection)
    }
    if (collectionId === UNLOCKED_COLLECTION_NAME) {
      return setCanDragItemToCollection({
        forbidden: true,
        reason: '',
      })
    }
    const { allowed, isInCollection, countExceed } = canAddToCollectionDynamic({
      collectionId,
      checkPresenceUser: dragCapture.dragUser,
      withReasonsObj: true,
    })
    if (allowed) {
      return setCanDragItemToCollection(initCanDragItemToCollection)
    }
    let returnObj = { forbidden: true, reason: '' }
    if (countExceed) {
      returnObj.reason = `${labelStrings.notAllowedToDropToCollectionReasonLimit}`
    }
    if (isInCollection) {
      returnObj.reason = `${labelStrings.notAllowedToDropToCollectionReasonuserExists}`
    }
    setCanDragItemToCollection(returnObj)
  }

  const createCollectionHandler = collectionData => createNewCollection(collectionData)

  const CollectionsBar = (
    <CollectionsBarRenderer
      collectionsList={collectionsList}
      createCollection={createCollectionHandler}
      disabled={!allowCollections}
      useAsPopupWithDragDrop={useAsPopupWithDragDrop}
      canCreateCollection={canCreateCollection}
      isDragCaptured={dragCapture.isCaptured}
      dropOperations={dropOperations}
      activeForDropCollectionId={activeForDropCollectionId}
      registerDropableHandlers={registerDropableHandlers}
      canDragItemToCollection={canDragItemToCollection}
      onDropLeaveCollectionList={onDragLeaveCollectionList}
      isNavType={navType}
      onHandleOpenViewType={onHandleOpenViewType}
    />
  )

  return allowCollections ? (
    <Box sx={{ position: 'relative' }}>
      {!useAsPopupWithDragDrop && (
        <NavToggleButton onChangeNavType={onChangeNavType} navType={navType} />
      )}

      {CollectionsBar}

      <ForbiddenDropTip
        canDragItemToCollection={canDragItemToCollection}
        activeForDropCollectionId={activeForDropCollectionId}
      />
    </Box>
  ) : (
    <WithUpgradeTip tipText={labelStrings.useCollections}>
      <div>{CollectionsBar}</div>
    </WithUpgradeTip>
  )
}

CollectionsBarContainer.propTypes = {
  createNewCollection: PropTypes.func,
  allowCollections: PropTypes.bool,
  useAsPopupWithDragDrop: PropTypes.bool,
  addElementToCollection: PropTypes.func,
  moveElementFromOpenedCollection: PropTypes.func,
  navType: PropTypes.string,
  onChangeNavType: PropTypes.func,
  onHandleOpenViewType: PropTypes.func
}

export default withReduxActions({
  createNewCollection,
  addElementToCollection,
  moveElementFromOpenedCollection,
})(memo(CollectionsBarContainer))
