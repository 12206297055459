import React from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { USER_INDEXES } from '../../../../constants/appSettings'
import YouTubeIcon from '@mui/icons-material/YouTube'
import {
  InstagramSwitchIcon,
  TikTokSwitchIcon,
} from '../../../../new-ui/components/add-creator/icons'
import useTranslation from '../../../../localization/useTranslation'
import useResponsive from '../../../../new-ui/hooks/useResponsive'

const StyledFinishBtn = {
  fontFamily: `Lato, sans-serif`,
  marginTop: '28px',
  fontWeight: 'bold',
  fontSize: '17px',
  lineHeight: '16px',
  color: '#ffffff',
  minWidth: '64px',
  width: 'fit-content',
  height: '46px',
  textTransform: 'capitalize',
  border: 'none',
  borderRadius: '24px',
  background:
    'transparent linear-gradient(89deg, #FF726F 0%, #FFCA55 120%) 0% 0% no-repeat padding-box',
}

const btnPlatforms = [
  { name: USER_INDEXES.instagram, value: 'ig', icon: <InstagramSwitchIcon /> },
  { name: USER_INDEXES.tiktok, value: 'tt', icon: <TikTokSwitchIcon /> },
  { name: USER_INDEXES.youtube, value: 'yt', icon: <YouTubeIcon /> },
]

export const WhichPlatform = props => {
  const { username, afterSignupData, setAfterSignupData, handleContinue } = props || {}
  const { platforms } = afterSignupData || {}

  const { labelStrings } = useTranslation()
  const isMobile = useResponsive('down', 'sm')

  const isActiveEl = value => {
    return platforms?.some(el => el === value)
  }

  const onChangePlatform = value => {
    if (platforms.includes(value)) {
      setAfterSignupData(prev => ({
        ...prev,
        platforms: platforms?.filter(el => el !== value),
      }))
    } else {
      setAfterSignupData(prev => ({
        ...prev,
        platforms: [...prev.platforms, value],
      }))
    }
  }

  return (
    <Stack sx={{ width: '100%', height: '100%' }}>
      <Typography
        sx={{ fontFamily: `Lato, sans-serif`, fontSize: '26px', fontWeight: 600, color: '#1F2937' }}
      >
        {labelStrings.signupLocalization.welcome}, {username}!
      </Typography>
      <Stack spacing={2} sx={{ mt: 3, pb: 5 }}>
        <Typography
          sx={{
            fontFamily: `Lato, sans-serif`,
            fontSize: '16px',
            fontWeight: 600,
            color: '#1F2937',
          }}
        >
          {labelStrings.signupLocalization.whichPlatformAreRelevant}
        </Typography>

        <Stack spacing={1} sx={{ px: 4, maxWidth: isMobile ? '100%' : '450px' }}>
          {btnPlatforms?.map(el => {
            return (
              <Button
                key={el.value}
                variant="outlined"
                size="medium"
                sx={{
                  backgroundColor: isActiveEl(el.value) ? '#FF726F' : 'initial',
                  color: isActiveEl(el.value) ? '#FFF' : '#1F2937',
                  borderColor: !isActiveEl(el.value) ? 'lightgrey' : '#FF726F',
                  minHeight: '42px',
                  '&:hover': {
                    opacity: '0.8',
                    backgroundColor: isActiveEl(el.value) ? '#FF726F' : 'initial',
                    color: isActiveEl(el.value) ? '#FFF' : '#FF726F',
                  },
                }}
                onClick={() => onChangePlatform(el.value)}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="start"
                  spacing={1}
                  sx={{ width: '110px' }}
                >
                  <Box>{el.icon}</Box>
                  <Typography>{el.name}</Typography>
                </Stack>
              </Button>
            )
          })}
        </Stack>
      </Stack>

      <Button
        disabled={!platforms?.length}
        variant="contained"
        onClick={handleContinue}
        sx={
          !!platforms?.length
            ? { ...StyledFinishBtn, width: isMobile ? '100%' : StyledFinishBtn.width }
            : {
                fontFamily: `Lato, sans-serif`,
                fontSize: StyledFinishBtn.fontSize,
                fontWeight: StyledFinishBtn.fontWeight,
                height: StyledFinishBtn.height,
                borderRadius: StyledFinishBtn.borderRadius,
                width: isMobile ? '100%' : 'fit-content',
              }
        }
      >
        {labelStrings.signupLocalization.continue}
      </Button>
    </Stack>
  )
}
