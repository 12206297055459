import React from 'react'
import PropTypes from 'prop-types'
import { PrimaryDefaultButton } from './styledButtons'

const PrimaryButton = ({
  onClick,
  label = '',
  userindex = '',
  isActive = false,
  isDisabled = false,
  isBig = false,
  withMaxWidth = '',
  noBG = false,
}) => {
  return (
    <PrimaryDefaultButton
      // @ts-ignore
      noFocusEffect
      onClick={onClick}
      userindex={userindex}
      isActive={isActive}
      isDisabled={isDisabled}
      isBig={isBig}
      withMaxWidth={withMaxWidth}
      noBG={noBG}
    >
      {label}
    </PrimaryDefaultButton>
  )
}

PrimaryButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  userindex: PropTypes.string,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isBig: PropTypes.bool,
  withMaxWidth: PropTypes.string,
  noBG: PropTypes.bool,
}

export default PrimaryButton
