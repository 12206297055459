import React from 'react'
import { Paper, Stack, Typography } from '@mui/material'
import { typographyStyles, typographyGreenStyles, typographySmallStyles } from './commonStyle'
import { pretifyBigNum } from '../../../../utils'
import useTranslation from '../../../../localization/useTranslation'
import { getPercent } from '../../../../new-ui/components/carousel/published-content/utils'

export const CreatorMentionCards = props => {
  const { username, currency, mentionAccountsAndTimeline, metadata } = props
  const { labelStrings, languageOptions, gender } = useTranslation()

  const creatorsCount = mentionAccountsAndTimeline?.length

  const {
    totalContentPieces,
    totalMediaValue,
    totalReach,
    partnershipTypes,
    topCreatorLocations,
    topCreatorLanguages,
    creatorGenderRates,
    contentStrategy,
    topHashtags,
  } = metadata || {}

  const findLanguage = currentLang => languageOptions?.find(lang => lang.value === currentLang)

  const transformObjectToArray = (data, type) => {
    return Object.keys(data).reduce((acc, key) => {
      if (type === 'lang') {
        acc.push({ name: findLanguage(key)?.label, value: data[key] })
      } else if (type === 'gender') {
        const genderObj = {
          w: gender.female,
          m: gender.male,
        }
        acc.push({ name: genderObj[key], value: data[key] })
      } else if (type === 'contentStrategy') {
        acc.push({ name: data[key]?.contentType, value: data[key]?.rate })
      } else if (type === 'topHashtags') {
        acc.push({ name: '', value: data[key]?.hashtag })
      } else {
        acc.push({ name: key, value: data[key] })
      }
      return acc
    }, [])
  }

  const CustomPaper = ({ title, subtitle, data, formatter, isContentStrategy }) => (
    <Paper sx={{ p: 2, width: 'fit-content', borderRadius: '13px', border: '1px solid #F4E8E8' }}>
      <Typography sx={{ ...typographyStyles, mb: 1 }}>{title}</Typography>
      {data.map((item, idx) => (
        <Stack key={item.name || idx} direction="row" spacing={0.5}>
          <Typography
            sx={{
              ...typographyGreenStyles,
              textTransform: isContentStrategy ? 'lowercase' : 'uppercase',
            }}
          >
            {formatter(item.value)}
          </Typography>
          {!isContentStrategy && (
            <Typography sx={typographySmallStyles}>
              ({item.value} {labelStrings.brandsTimelineText.creators})
            </Typography>
          )}
          <Typography
            sx={{
              ...typographySmallStyles,
              fontWeight: 'bold',
              textTransform: isContentStrategy ? 'capitalize' : 'lowercase',
            }}
          >
            {subtitle} {item.name}
          </Typography>
        </Stack>
      ))}
    </Paper>
  )

  const topCreatorLocationsArray = transformObjectToArray(topCreatorLocations)
  const topCreatorLanguagesArray = transformObjectToArray(topCreatorLanguages, 'lang')
  const creatorGenderArray = transformObjectToArray(creatorGenderRates, 'gender')
  const creatorCountWithGender = creatorGenderRates ? creatorGenderRates.m + creatorGenderRates.w : 0

  const creatorContentStrategy = transformObjectToArray(contentStrategy, 'contentStrategy')
  const topCreatorHashtags = transformObjectToArray(topHashtags, 'topHashtags')

  const WithinTheLast365Days = () => (
    <Paper sx={{ p: 2, width: 'fit-content', borderRadius: '13px', border: '1px solid #F4E8E8' }}>
      <Typography sx={{ ...typographyStyles, mb: 1 }}>
        {labelStrings.brandsTimelineText.withinTheLast365DaysWasTaggedBy(username)}
      </Typography>

      {!!mentionAccountsAndTimeline?.length && (
        <Typography sx={typographyGreenStyles}>
          {pretifyBigNum(creatorsCount)} {labelStrings.brandsTimelineText.differentCreators}
        </Typography>
      )}
      {totalContentPieces && (
        <Stack direction="row" spacing={0.5}>
          <Typography sx={typographyGreenStyles}>
            {pretifyBigNum(totalContentPieces)}{' '}
            {labelStrings.brandsTimelineText.brandedContentPieces}
          </Typography>
          <Typography sx={typographySmallStyles}>
            {labelStrings.brandsTimelineText.werePublished}
          </Typography>
        </Stack>
      )}
      {totalMediaValue && (
        <Stack direction="row" spacing={0.5}>
          <Typography sx={typographyGreenStyles}>
            {pretifyBigNum(totalMediaValue)} {currency?.toUpperCase()}{' '}
            {labelStrings.brandsTimelineText.emv}
          </Typography>
          <Typography sx={typographySmallStyles}>
            {labelStrings.brandsTimelineText.wasGenerated}
          </Typography>
        </Stack>
      )}
      {totalReach && (
        <Stack direction="row" spacing={0.5}>
          <Typography sx={typographyGreenStyles}>
            {labelStrings.brandsTimelineText.reach(pretifyBigNum(totalReach))}
          </Typography>
          <Typography sx={typographySmallStyles}>
            {labelStrings.brandsTimelineText.wereGenerated}
          </Typography>
        </Stack>
      )}
    </Paper>
  )

  const PartnershipTypes = () => (
    <Paper sx={{ p: 2, width: 'fit-content', borderRadius: '13px', border: '1px solid #F4E8E8' }}>
      <Typography sx={{ ...typographyStyles, mb: 1 }}>
        {labelStrings.brandsTimelineText.partnershipTypes}
      </Typography>

      {partnershipTypes?.oneOff && (
        <Stack direction="row" spacing={0.5}>
          <Typography sx={typographyGreenStyles}>
            {Math.floor((partnershipTypes?.oneOff / creatorsCount) * 100)}%
          </Typography>
          <Typography sx={typographySmallStyles}>
            ({partnershipTypes?.oneOff} {labelStrings.brandsTimelineText.creators})
          </Typography>
          <Typography sx={{ ...typographySmallStyles, fontWeight: 'bold' }}>
            {labelStrings.brandsTimelineText.oneOff}
          </Typography>
          <Typography
            sx={typographySmallStyles}
          >{`(<1 ${labelStrings.brandsTimelineText.month})`}</Typography>
        </Stack>
      )}

      {partnershipTypes?.longTerm && (
        <Stack direction="row" spacing={0.5}>
          <Typography sx={typographyGreenStyles}>
            {Math.floor((partnershipTypes?.longTerm / creatorsCount) * 100)}%
          </Typography>
          <Typography sx={typographySmallStyles}>
            ({partnershipTypes?.longTerm} {labelStrings.brandsTimelineText.creators})
          </Typography>
          <Typography sx={{ ...typographySmallStyles, fontWeight: 'bold' }}>
            {labelStrings.brandsTimelineText.longTerm}
          </Typography>
          <Typography
            sx={typographySmallStyles}
          >{`(1-6 ${labelStrings.brandsTimelineText.month})`}</Typography>
        </Stack>
      )}

      {partnershipTypes?.ambassadors && (
        <Stack direction="row" spacing={0.5}>
          <Typography sx={typographyGreenStyles}>
            {Math.floor((partnershipTypes?.ambassadors / creatorsCount) * 100)}%
          </Typography>
          <Typography sx={typographySmallStyles}>
            ({partnershipTypes?.ambassadors} {labelStrings.brandsTimelineText.creators})
          </Typography>
          <Typography sx={{ ...typographySmallStyles, fontWeight: 'bold' }}>
            {labelStrings.brandsTimelineText.brandAmbassadors}
          </Typography>
          <Typography
            sx={typographySmallStyles}
          >{`(>6 ${labelStrings.brandsTimelineText.month})`}</Typography>
        </Stack>
      )}
    </Paper>
  )

  return (
    <Stack spacing={1}>
      <WithinTheLast365Days />
      <PartnershipTypes />

      {!!topCreatorLocationsArray?.length && (
        <CustomPaper
          title={labelStrings.brandsTimelineText.top5CreatorLocations}
          subtitle={labelStrings.brandsTimelineText.from}
          data={topCreatorLocationsArray}
          formatter={value => `${Math.floor((value / creatorsCount) * 100)}%`}
        />
      )}
      {!!topCreatorLanguagesArray?.length && (
        <CustomPaper
          title={labelStrings.brandsTimelineText.top3CreatorLanguages}
          subtitle={labelStrings.brandsTimelineText.speak}
          data={topCreatorLanguagesArray}
          formatter={value => `${Math.floor((value / creatorsCount) * 100)}%`}
        />
      )}
      {!!creatorGenderArray?.length && (
        <CustomPaper
          title={labelStrings.brandsTimelineText.creatorGender}
          subtitle={labelStrings.brandsTimelineText.are}
          data={creatorGenderArray}
          formatter={value => `${Math.floor((value / creatorCountWithGender) * 100)}%`}
        />
      )}

      {!!contentStrategy?.length && (
        <CustomPaper
          title={labelStrings.brandsTimelineText.contentStrategy}
          data={creatorContentStrategy}
          formatter={value => getPercent(value)}
          isContentStrategy
        />
      )}
      {!!topHashtags?.length && (
        <CustomPaper
          title={labelStrings.brandsTimelineText.campaignHashtags}
          data={topCreatorHashtags}
          formatter={value => value}
          isContentStrategy
        />
      )}
    </Stack>
  )
}
