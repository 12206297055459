import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { Typography, Box, IconButton } from '@mui/material'
import { bgBlur } from '../../utils/cssStyles'

import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';

const StyledRoot = styled(Box)(({ theme }) => ({
  ...bgBlur({
    opacity: 0.48,
    color: theme.palette.grey[900],
  }),
  // zIndex: 9,
  // display: 'inline-flex',
  // alignItems: 'center',
  // position: 'absolute',
  // bottom: '6px',
  // left: theme.spacing(2),
  padding: theme.spacing(0.25),
  color: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
}))

const StyledIconButton = styled(IconButton)({
  width: 22,
  height: 22,
  padding: 0,
  opacity: 0.48,
  '&:hover': { opacity: 1 },
})

CarouselArrowIndex.propTypes = {
  sx: PropTypes.object,
  onNext: PropTypes.func,
  index: PropTypes.number,
  total: PropTypes.number,
  onPrevious: PropTypes.func,
}

export default function CarouselArrowIndex({
  index,
  total,
  onNext,
  onPrevious,
  disabledNext,
  disabledPrev,
  sx,
  ...other
}) {
  return (
    <StyledRoot sx={sx} {...other}>
      <StyledIconButton color="inherit" onClick={onPrevious} disabled={disabledPrev}>
        <ArrowLeftRoundedIcon sx={{ width: '28px', height: '28px' }} />
      </StyledIconButton>

      <Typography variant="subtitle2" component="span" sx={{ mx: 0.25 }}>
        {index + 1}/{total}
      </Typography>

      <StyledIconButton color="inherit" onClick={onNext} disabled={disabledNext}>
        <ArrowRightRoundedIcon  sx={{ width: '28px', height: '28px' }} />
      </StyledIconButton>
    </StyledRoot>
  )
}
