import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { EmptyListMessagesWrapper, CenterWrapper } from '../common/styledWrappers'
import useTranslation from '../../localization/useTranslation'

const MainWrapper = styled(CenterWrapper)`
  position: absolute;
  height: inherit;
  width: 100%;
  top: 0;
  left: 0;
  ${props => props.theme.flex.centerAll}
  background: ${props => props.theme.color.white50};
  z-index: 100;
`

const ContentWrapper = styled(EmptyListMessagesWrapper)`
  align-self: baseline;
  margin-top: ${props => (props.notAllowed ? 30 : 25)}%;
`

const NoUsersWrapper = styled.div`
  font-size: 20px;
  font-weight: 800;
  font-family: ${props => props.theme.fontfamilyInter};
  text-align: center;
`

const TipWrapper = styled.div`
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  width: 465px;
`

const UpgradeLinkWrapper = styled(Link)`
  color: ${props => props.theme.primaryColor};
  text-decoration: underline;
  &:hover {
    color: ${props => props.theme.primaryColor};
    opacity: 0.9;
  }
`

const CompareProfilesPageTips = ({ notAllowed = false }) => {
  const { labelStrings } = useTranslation()
  return (
    <MainWrapper>
      <ContentWrapper notAllowed={notAllowed}>
        {notAllowed ? (
          <NoUsersWrapper>
            <UpgradeLinkWrapper to="/settings">{labelStrings.contactSales}</UpgradeLinkWrapper>{' '}
            {labelStrings.to} {labelStrings.useComapreProfiles}
          </NoUsersWrapper>
        ) : (
          <NoUsersWrapper>
            {labelStrings.noProfilesToCompare}.
            <TipWrapper>{labelStrings.noProfilesToCompareTip}.</TipWrapper>
          </NoUsersWrapper>
        )}
      </ContentWrapper>
    </MainWrapper>
  )
}

CompareProfilesPageTips.propTypes = {
  notAllowed: PropTypes.bool,
}

export default CompareProfilesPageTips
