import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { SearchSvgIcon } from '../../common/icons'
import { debounced } from '../../../utils'
import {
  ClearInputWrapper,
  CollectionSearchInput,
  CollectionSearchWrapper,
  SearchIconWrapper,
} from './styles'

export const CollectionSearch = props => {
  const { value, setValue, placeholder = '', collectionViewMode } = props

  const setDebounceValue = value => {
    debounced(setValue(value), 300)
  }

  const iconContainer = useMemo(() => {
    return (
      <>
        {value ? (
          <ClearInputWrapper onClick={() => setValue('')} />
        ) : (
          <SearchIconWrapper>
            <SearchSvgIcon height="20" width="20" />
          </SearchIconWrapper>
        )}
      </>
    )
  }, [value, setValue])

  return (
    <CollectionSearchWrapper collectionViewMode={collectionViewMode}>
      <CollectionSearchInput
        autoComplete="off"
        value={value}
        placeholder={placeholder}
        onChange={e => setDebounceValue(e.target.value)}
      />
      {iconContainer}
    </CollectionSearchWrapper>
  )
}

CollectionSearch.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  collectionViewMode: PropTypes.bool,
}
