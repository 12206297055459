import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { USER_INDEXES, SOCIAL_LINKS_PATHS } from '../../../constants/appSettings'
import { stringifyNumToPretty } from '../../../utils'
import { LikeIcon } from '../../common/icons/icons'
import { ShareIcon } from '../../common/icons'
import {
  GaleryItem,
  GaleryItemImage,
  GaleryItemOverlay,
  GaleryItemActivityWrapper,
  GaleryItemAnalysisWrapper,
} from './styles'
import { Stack } from '@mui/material'
import { LATEST_REELS, LATEST_STORIES, MOST_VIEWED_REELS } from '../../../constants/profile'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ChatIcon from '@mui/icons-material/Chat'
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded'

const LatestPostsGalleryItem = ({
  platform = '',
  username = '',
  imgSrc = '',
  platformPostCode = '',
  likeCount = 0,
  commentCount = 0,
  playCount = 0,
  shareCount = 0,
  handleOpenStoriesModal,
  tabType = '',
  reelsViews = 0,
}) => {
  const isIgStory = tabType === LATEST_STORIES && platform === USER_INDEXES.instagram
  const isIgReels =
    platform === USER_INDEXES.instagram &&
    (tabType === LATEST_REELS || tabType === MOST_VIEWED_REELS)

  const [isImageLoadedWithError, setIsImageLoadedWithError] = useState(false)

  let linkToPost
  switch (platform) {
    case USER_INDEXES.instagram:
      linkToPost = isIgReels
        ? SOCIAL_LINKS_PATHS.reelsInstagram(platformPostCode)
        : SOCIAL_LINKS_PATHS.postsInstagram(platformPostCode)
      break
    case USER_INDEXES.tiktok:
      linkToPost = SOCIAL_LINKS_PATHS.postsTiktok(username, platformPostCode)
      break
    default:
      linkToPost = ''
      break
  }

  const onErrorImageLoading = () => setIsImageLoadedWithError(true)

  if (isImageLoadedWithError) return null

  return isIgStory ? (
    <Stack
      onClick={handleOpenStoriesModal}
      sx={{
        width: '170px',
        height: '300px',
        cursor: 'pointer',
        '&:hover': {
          opacity: 0.8,
        },
      }}
    >
      <GaleryItemImage src={imgSrc} onError={onErrorImageLoading} />
    </Stack>
  ) : (
    <GaleryItem href={linkToPost} target="_blank" platform={platform} isIgReels={isIgReels}>
      <GaleryItemImage src={imgSrc} onError={onErrorImageLoading} />

      <GaleryItemOverlay platform={platform}>
        <GaleryItemActivityWrapper>
          {(() => {
            switch (platform) {
              case USER_INDEXES.instagram:
                return (
                  <>
                    {reelsViews !== 0 && isIgReels && (
                      <GaleryItemAnalysisWrapper>
                        <VisibilityIcon sx={{ fontSize: '20px', mr: '5px' }} />
                        {stringifyNumToPretty(reelsViews)}
                      </GaleryItemAnalysisWrapper>
                    )}

                    {likeCount != 0 && (
                      <GaleryItemAnalysisWrapper>
                        <LikeIcon />
                        {stringifyNumToPretty(likeCount)}
                      </GaleryItemAnalysisWrapper>
                    )}

                    <GaleryItemAnalysisWrapper>
                      <ChatIcon sx={{ fontSize: '20px', mr: '5px' }} />
                      {stringifyNumToPretty(commentCount)}
                    </GaleryItemAnalysisWrapper>
                  </>
                )
              case USER_INDEXES.tiktok:
                return (
                  <>
                    <GaleryItemAnalysisWrapper>
                      <PlayCircleRoundedIcon sx={{ fontSize: '20px', mr: '5px' }} />
                      {stringifyNumToPretty(playCount)}
                    </GaleryItemAnalysisWrapper>

                    {likeCount != 0 && (
                      <GaleryItemAnalysisWrapper>
                        <LikeIcon />
                        {stringifyNumToPretty(likeCount)}
                      </GaleryItemAnalysisWrapper>
                    )}

                    <GaleryItemAnalysisWrapper>
                      <ShareIcon />
                      {stringifyNumToPretty(shareCount)}
                    </GaleryItemAnalysisWrapper>

                    <GaleryItemAnalysisWrapper>
                      <ChatIcon sx={{ fontSize: '20px', mr: '5px' }} />
                      {stringifyNumToPretty(commentCount)}
                    </GaleryItemAnalysisWrapper>
                  </>
                )
              default:
                return null
            }
          })()}
        </GaleryItemActivityWrapper>
      </GaleryItemOverlay>
    </GaleryItem>
  )
}

LatestPostsGalleryItem.propTypes = {
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  username: PropTypes.string.isRequired,
  imgSrc: PropTypes.string,
  platformPostCode: PropTypes.string,
  likeCount: PropTypes.number,
  commentCount: PropTypes.number,
  playCount: PropTypes.number,
  shareCount: PropTypes.number,
  handleOpenStoriesModal: PropTypes.func,
  tabType: PropTypes.string,
  reelsViews: PropTypes.number,
}

export default LatestPostsGalleryItem
