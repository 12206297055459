import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, CardHeader } from '@mui/material'
import Carousel, { CarouselArrows } from '../index'
import { CommentsItem } from './CommentsItem'
import { LatestSummariesItem } from './LatestSummariesItem'

export const CommentsCarousel = props => {
  const {
    carouselSettings = {},
    title = '',
    subheader = '',
    list = [],
    sx = {},
    maxHeight = '335px',
    withLatestSummaries = false,
    ...other
  } = props || {}

  const [currSlide, setCurrSlide] = useState(0)

  const config = {
    ...carouselSettings,
    afterChange: currentSlide => {
      setCurrSlide(currentSlide)
    },
  }

  const carouselRef = useRef(null)

  const handlePrev = () => {
    carouselRef.current?.slickPrev()
  }

  const handleNext = () => {
    carouselRef.current?.slickNext()
  }

  const ContentItem = withLatestSummaries ? LatestSummariesItem : CommentsItem

  return (
    <Card {...other} sx={{ ...sx }}>
      <CardHeader
        title={title}
        subheader={subheader}
        action={
          <CarouselArrows
            onNext={handleNext}
            onPrevious={handlePrev}
            disabledPrev={currSlide === 0 || !list?.length}
            disabledNext={currSlide === list?.length - config.slidesToShow || !list?.length}
          />
        }
        sx={{
          '& .MuiCardHeader-action': { alignSelf: 'center' },
        }}
      />

      <Carousel ref={carouselRef} {...config}>
        {list?.length ? (
          list?.map(item => <ContentItem key={item.commentId || item.contentId} item={item} />)
        ) : (
          <ContentItem key={'no-data'} />
        )}
      </Carousel>
    </Card>
  )
}

CommentsCarousel.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
  subheader: PropTypes.string,
  carouselSettings: PropTypes.object,
  sx: PropTypes.object,
  withLatestSummaries: PropTypes.bool,
}