import React, { useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'
import { paginateSearchPage } from '../../redux/ducks/searchDucks'
import {
  userGrantSelector,
  currentSearchQuerySkipCountSelector,
  searchResultsSelector,
  currentExploreRecentSearchQuerySkipCountSelector,
} from '../../redux/selectors'

import { searchContentTypes, USERS_PER_PAGE, USERS_PER_PAGE_CONTENT } from '../../constants/search'
import useTranslation from '../../localization/useTranslation'

import Pagination from './Pagination'
import WithUpgradeTip from '../common/popups/WithUpgradeTip'
import ResultCount from '../ResultCount'
import {
  SearchResultContentCreatorIconSvg,
  SearchResultShowMapIconSvg,
} from '../common/icons/icons'
import { MainWrapper, MapTitle, ShowMapWrapper } from './styles'
import { USER_INDEXES } from '../../constants/appSettings'

const MAX_PAGES_LIMIT = 500
//55 users content on page

const SearchPagePagination = ({
  withResultsCount = false,
  searchType = '',
  onlyPagination = false,
  isShowMap = false,
  onHandleShowMap,
  isBottom = false,
  platform = '',
  isExplore = false,
  changeExploreQuery,
}) => {
  const { labelStrings, currentLang } = useTranslation()
  const dispatch = useDispatch()

  const { dataSuccess } = useSelector(searchResultsSelector)

  const perPages =
    searchType === searchContentTypes.CONTENT ? USERS_PER_PAGE_CONTENT : USERS_PER_PAGE

  const [tipCords, setTipCords] = useState({})

  const onPaginationHover = () => {
    if (isMaxPagesLimitExceded && !Object.keys(tipCords)?.length) {
      const { top, left, width, height } = pagRef.current.getBoundingClientRect()
      setTipCords({
        top: top - height,
        left: left + width / 2 - 100, // 100 is a half of tip width
      })
    }
  }

  const pagRef = useRef()

  const { max_result_pages } = useSelector(userGrantSelector)
  const searchCurrentSkipCount = useSelector(currentSearchQuerySkipCountSelector)
  const exploreRecentSearchSkipCount = useSelector(currentExploreRecentSearchQuerySkipCountSelector)
  const currentSkipCount = isExplore ? exploreRecentSearchSkipCount : searchCurrentSkipCount

  const { count: maxResultsCount } = useSelector(searchResultsSelector)

  const maxPageCount = Math.ceil(maxResultsCount / perPages)
  const currentPage = currentSkipCount ? Math.trunc(currentSkipCount / perPages) : 0
  const isMaxPagesLimitExceded = max_result_pages <= currentPage + 1

  const handlePageChange = ({ selected }) => {
    if (selected !== currentPage) {
      const skipCount = Math.trunc(selected * perPages)

      if (skipCount > currentSkipCount) {
        changeExploreQuery &&
          isExplore &&
          changeExploreQuery(prev => ({
            ...prev,
            query: {
              ...prev.query,
              skipCount: skipCount,
            },
          }))
        dispatch(
          // @ts-ignore
          paginateSearchPage({
            skipCount,
            additionalSkipsForBlacklisting: dataSuccess?.additionalSkipsForBlacklisting,
            isExplore,
          })
        )
      } else {
        changeExploreQuery &&
          isExplore &&
          changeExploreQuery(prev => ({
            ...prev,
            query: {
              skipCount: skipCount,
            },
          }))
        dispatch(
          // @ts-ignore
          paginateSearchPage({
            skipCount,
            isExplore,
          })
        )
      }
    }
  }

  const maxPagesDisplayCount = maxPageCount > MAX_PAGES_LIMIT ? MAX_PAGES_LIMIT : maxPageCount

  const PaginationComponent = (
    <Pagination
      currentPage={currentPage}
      maxPagesDisplayCount={maxPagesDisplayCount}
      isMaxPagesLimitExceded={isMaxPagesLimitExceded}
      handlePageChange={handlePageChange}
      disableInitialCallback
    />
  )

  const iconSearchType = useMemo(() => {
    return searchType === searchContentTypes.CREATOR ? (
      <SearchResultContentCreatorIconSvg />
    ) : (
      <ShowMapWrapper onClick={onHandleShowMap}>
        <SearchResultShowMapIconSvg />{' '}
        <MapTitle>{isShowMap ? labelStrings.hideMap : labelStrings.showMap}</MapTitle>
      </ShowMapWrapper>
    )
  }, [searchType, isShowMap, onHandleShowMap, currentLang])

  return (
    <MainWrapper
      ref={pagRef}
      onMouseEnter={onPaginationHover}
      tipCords={tipCords}
      onlyPagination={onlyPagination}
      isBottom={isBottom}
      isShowMap={isShowMap}
      isContentSearch={searchType === searchContentTypes.CONTENT}
    >
      {withResultsCount && <ResultCount count={maxResultsCount} />}
      {isMaxPagesLimitExceded ? (
        <WithUpgradeTip tipText={labelStrings.seeMoreResults}>{PaginationComponent}</WithUpgradeTip>
      ) : (
        PaginationComponent
      )}
      {platform === USER_INDEXES.instagram ? (
        !onlyPagination && <div>{iconSearchType}</div>
      ) : (
        <div></div>
      )}
    </MainWrapper>
  )
}

SearchPagePagination.propTypes = {
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube]),
  withResultsCount: PropTypes.bool,
  searchType: PropTypes.string,
  onlyPagination: PropTypes.bool,
  isShowMap: PropTypes.bool,
  onHandleShowMap: PropTypes.func,
  isBottom: PropTypes.bool,
  isExplore: PropTypes.bool,
  changeExploreQuery: PropTypes.func,
}

export default SearchPagePagination
