import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { userStartupDataLoadingSelector } from '../../../redux/selectors'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'

import CommonSettingSection from '../CommonSettingSection'

import AudienceTokensAddOnSection from './addOnSections/AudienceTokensAddOnSection'
import CampaignsTokensAddOnSection from './addOnSections/CampaignsTokensAddOnSection'

const ContentWrapper = styled.div`
  display: flex;
  // justify-content: space-between;
`

const AddOnsContainer = () => {
  const { labelStrings } = useTranslation()
  const isUserStartUpDataLoading = useSelector(userStartupDataLoadingSelector)

  const Content = (
    <ContentWrapper>
      <AudienceTokensAddOnSection />
      <CampaignsTokensAddOnSection />
    </ContentWrapper>
  )

  return (
    <CommonSettingSection
      title={labelStrings.yourAddOns}
      withLoading
      isLoading={isUserStartUpDataLoading}
      Content={Content}
    />
  )
}


export default AddOnsContainer
