import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import useTranslation from '../../../localization/useTranslation'
import dragNDropService from '../../../services/dragNdropService'
import ProfileListUserRowDetails from './ProfileListViewUserRowDetails'
import { setLastPageBeforeProfileRedirect } from '../../../redux/ducks/appSettingsDucks'
import { setSaveOpenedProfileId } from '../../../redux/ducks/profileSelectedDucks'
import { useDispatch } from 'react-redux'

const ProfileListViewUserRow = ({
  user,
  isCollectionView = false,
  isUnlockedCollectionOpened = false,
  actionHandlers,
  tableForSimilarAccounts,
  applyMocked = false,
  privateRating = '',
  showUserGrowth = false,
  beforeRedirectPage = '',
  isBlackListCollection = false,
  currentFieldsState = {},
  additionalFields = []
}) => {
  const { languageOptions } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const link = `/profile/${user._id}`
  const goToProfile = () => history.push(link)
  const onStarClick = newRating => actionHandlers.modifyUser(user._id, 'stars', parseInt(newRating))
  const selectSingle = () => actionHandlers.selectOne(user._id)
  const userlanguage = languageOptions.find(el => el.value === user.language) || {}
  const isFullTable = !tableForSimilarAccounts // add more limits here for future short tables

  const onRowClick = () => {
    dispatch(setSaveOpenedProfileId(user?._id))
    dispatch(setLastPageBeforeProfileRedirect(beforeRedirectPage))
    dragNDropService.reactToClickEvent({
      callback: goToProfile,
    })
  }

  const stopEventBubbling = e => e.stopPropagation()

  return (
    <ProfileListUserRowDetails
      {...{
        user,
        selectSingle,
        isCollectionView,
        isUnlockedCollectionOpened,
        applyMocked,
        isFullTable,
        privateRating,
        userlanguage,
        showUserGrowth,
        onStarClick,
        onRowClick,
        stopEventBubbling,
        link,
        isBlackListCollection,
        currentFieldsState,
        additionalFields
      }}
    />
  )
}

ProfileListViewUserRow.propsTypes = {
  actionHandlers: PropTypes.shape({
    modifyUser: PropTypes.func,
    selectAllUsers: PropTypes.func,
    selectOne: PropTypes.func,
  }),
  user: PropTypes.object.isRequired,
  isCollectionView: PropTypes.bool,
  isUnlockedCollectionOpened: PropTypes.bool,
  tableForSimilarAccounts: PropTypes.bool,
  applyMocked: PropTypes.bool,
  privateRating: PropTypes.string,
  beforeRedirectPage: PropTypes.string,
  showUserGrowth: PropTypes.bool,
  isBlackListCollection: PropTypes.bool,
  currentFieldsState: PropTypes.object,
  additionalFields: PropTypes.array
}

export default ProfileListViewUserRow

