import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../localization/useTranslation'
import availableLangs from '../../localization/availableLangs'
import { PersonIcon } from '../common/icons'
import {
  MainWrapper,
  PersonIconWrapper,
  LimitContent,
  LimitMessage,
  ExceedLimitMessage,
  LimitLineOuterWrapper,
  LimitLineInnerWrapper,
  ChangeSeatsPlanWrapper,
} from '../common/styledWrappers/campaignLimitMonitorStyles'

const LimitMonitorContent = ({
  disabled = false,
  isTrackLimitExist = false,
  limitLinePercent = 0,
  exceedLimitClickHandler,
  exceedLimitText = '',
  itemsToAdd = 0,
  showChangeSeatsPlan = false,
  isAddLimitExceeded = false,
}) => {
  const { labelStrings, currentLang } = useTranslation()

  const isSmallerFontForLimitMessage = currentLang === availableLangs.de

  return (
    <MainWrapper disabled={disabled}>
      <PersonIconWrapper>
        <PersonIcon />
      </PersonIconWrapper>

      <LimitContent>
        {isTrackLimitExist && (
          <>
            <LimitLineOuterWrapper>
              <LimitLineInnerWrapper widthPercent={limitLinePercent} />
            </LimitLineOuterWrapper>
            {isAddLimitExceeded ? (
              <ExceedLimitMessage
                onClick={exceedLimitClickHandler}
                isSmallerFont={isSmallerFontForLimitMessage}
              >
                {exceedLimitText}
              </ExceedLimitMessage>
            ) : (
              <LimitMessage isSmallerFont={isSmallerFontForLimitMessage}>
                {labelStrings.addMoreInfluncersDynamic(itemsToAdd)}
              </LimitMessage>
            )}
          </>
        )}
      </LimitContent>
      {showChangeSeatsPlan && (
        <ChangeSeatsPlanWrapper onClick={exceedLimitClickHandler}>
          {labelStrings.changeSeatPlan}
        </ChangeSeatsPlanWrapper>
      )}
    </MainWrapper>
  )
}

LimitMonitorContent.propTypes = {
  disabled: PropTypes.bool,
  isTrackLimitExist: PropTypes.bool,
  limitLinePercent: PropTypes.number,
  exceedLimitClickHandler: PropTypes.func,
  exceedLimitText: PropTypes.string,
  itemsToAdd: PropTypes.number,
  showChangeSeatsPlan: PropTypes.bool,
  isAddLimitExceeded: PropTypes.bool,
}

export default LimitMonitorContent
