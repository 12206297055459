import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useTranslation from '../../../localization/useTranslation'
import { withReduxActions } from '../../../hocs/withRedux'
import { logoutUser } from '../../../redux/ducks/userDucks'
import { toggleSignUp } from '../../../redux/ducks/appSettingsDucks'
import {
  userGrantSelector,
  allCampaignsSelector,
  currentAudienceTokensSelector,
  userAudienceTokensSelector,
} from '../../../redux/selectors'

import { TryForFreeButton } from '../../common/buttons/styledButtons'
import ProfileNavMenu from './ProfileNavMenu'

const ProfileNavContainer = ({ isUserAuthorized = false, logoutUser, toggleSignUp }) => {
  const history = useHistory()
  const { labelStrings } = useTranslation()
  const { package_advanced, enable_tracking, in_trial: isTrial } = useSelector(userGrantSelector)
  const { isChangeInProgress: showAudienceTokensChange } = useSelector(
    currentAudienceTokensSelector
  )
  const { audienceTokensUsed, audienceTokensTotal } = useSelector(userAudienceTokensSelector)
  const { trackNow = 0 } = useSelector(allCampaignsSelector)

  const onLogoutClick = () => logoutUser(history)
  const goToSettings = () => history.push('/settings')

  const allowTokensStats = !!package_advanced
  const showCampaignsTrackStats = enable_tracking && allowTokensStats && !!trackNow

  const openMenuManually = showAudienceTokensChange // add more conditions here if needed

  return isUserAuthorized ? (
    <ProfileNavMenu
      onLogoutClick={onLogoutClick}
      goToSettings={goToSettings}
      showTokensStats={allowTokensStats || audienceTokensUsed !== 0 || audienceTokensTotal !== 0}
      showCampaignsTrackStats={showCampaignsTrackStats}
      openMenuManually={openMenuManually}
      isTrial={isTrial}
    />
  ) : (
    <TryForFreeButton onClick={toggleSignUp}>{labelStrings.startTrial}</TryForFreeButton>
  )
}

ProfileNavContainer.propTypes = {
  isUserAuthorized: PropTypes.bool,
  logoutUser: PropTypes.func,
  toggleSignUp: PropTypes.func,
}

export default withReduxActions({ logoutUser, toggleSignUp })(ProfileNavContainer)
