import React from 'react'
import PropTypes from 'prop-types'
import {
	Box,
	Card,
	Divider,
	Paper,
	Typography,
	styled,
	Stack,
	List,
	ListItem,
	ListItemText,
} from '@mui/material'
import Iconify from '../iconify'
import useTranslation from '../../../localization/useTranslation'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { useSelector } from 'react-redux'
import { currentOpenedCampaignSelector } from '../../../redux/selectors'
import { pretifyBigNum } from '../../../utils'
import { TooltipComponent } from '../tooltip/TooltipComponent'

const StyledCpmWrapper = styled('div')({
	marginTop: '8px',
	textAlign: 'start',
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
	div: {
		display: 'flex',
		minWidth: '100px',
		fontSize: '12px',
		lineHeight: '18px',
		justifyContent: 'space-between',
		'& p': {
			marginBottom: 0,
		},
	},
	span: {
		minWidth: '50px',
		color: '#637381',
	},
})

const typographyStyles = {
	fontWeight: 600,
	fontSize: '0.685rem',
	color: '#FFFFFF',
	maxWidth: '300px',
	wordWrap: 'break-word',
	whiteSpace: 'pre-line',
}

export const SmallCardWidget = props => {
	const {
		sx = {},
		icon,
		total,
		title,
		subTitle,
		color,
		dangerColor = '#FF5D5A',
		iconText,
		postMedianAvg,
		сpmAvg,
		reelsMedianAvg,
		storiesMedianAvg,
		shortsMedianAvg,
		videosMedianAvg,
		cpmMedian,
		maxCreatorValue,
		minCreatorValue,
		totalReachAvg,
		totalSpendAvg,
		totalContentAvg,
		missingCreators,
		averagePerContent,
		isPdf,
		...other
	} = props
	const { labelStrings } = useTranslation()

	const { settings } = useSelector(currentOpenedCampaignSelector)

	const campaignCurrency = settings?.campaignCurrency ? settings?.campaignCurrency : '$'

	const missingCreatorsData =
    missingCreators?.length > 5 ? missingCreators?.slice(0, 5) : missingCreators

	const titleTooltip = (
		<Stack>
			<Typography sx={typographyStyles}>
				{labelStrings.notAllCreatorsAssignedPricing(subTitle.includes('CPM') ? 'CPM' : 'CPE')}
			</Typography>
			<Stack>
				<Typography sx={{ ...typographyStyles, marginTop: '2px' }}>
					{labelStrings.missingCreators}:
				</Typography>
				<List dense={true} sx={{ p: 0 }}>
					{missingCreatorsData?.map(missingCreator => {
						return (
							<ListItem key={missingCreator} sx={{ paddingRight: 0, paddingLeft: 0.5, py: 0 }}>
								<ListItemText
									primary={<Typography sx={typographyStyles}>@{missingCreator}</Typography>}
								/>
							</ListItem>
						)
					})}
					{missingCreatorsData?.length === 5 && (
						<ListItem sx={{ py: 0, px: 0.5 }}>
							<ListItemText
								sx={typographyStyles}
								primary={<Typography sx={typographyStyles}>and more...</Typography>}
							/>
						</ListItem>
					)}
				</List>
			</Stack>
		</Stack>
	)

	return (
		<Card
			{...other}
			sx={{
				minHeight: '128px',
				px: '1rem',
				border: isPdf ? '1px solid rgba(145, 158, 171, 0.24)' : 'none',
			}}
		>
			{!isPdf && !!missingCreators?.length && (
				<Box sx={{ zIndex: 'tooltip', position: 'absolute', right: '10px', top: '5px' }}>
					<TooltipComponent text={titleTooltip} placement="right-start">
						<WarningAmberOutlinedIcon style={{ fill: dangerColor, cursor: 'pointer' }} />
					</TooltipComponent>
				</Box>
			)}
			<Paper
				variant="outlined"
				sx={{ py: 1.5, textAlign: 'center', border: 'none' }}
			>
				{iconText ? (
					<Typography variant="h6" sx={{ mt: 0.5 }}>
						{iconText}
					</Typography>
				) : (
					<Iconify icon={icon} sx={sx} width={32} color={color} />
				)}

				<Typography
					variant="body2"
					sx={{ color: 'text.secondary', fontSize: '12px', lineHeight: '16px', mt: 1 }}
				>
					{title}
					{!!title && <br />}
					{subTitle}
				</Typography>

				<Typography variant="h6">
					{pretifyBigNum(total) ? pretifyBigNum(total) + ' ' + campaignCurrency : '-'}
				</Typography>
				{сpmAvg && (
					<Typography
						variant="body2"
						sx={{ fontSize: '12px', lineHeight: '12px', color: 'text.secondary' }}
					>
            (Median {pretifyBigNum(сpmAvg) || '-'}{' '}
						{campaignCurrency === 'EUR' ? '€' : campaignCurrency})
					</Typography>
				)}
				{!!(postMedianAvg || storiesMedianAvg || reelsMedianAvg) && (
					<StyledCpmWrapper>
						{/* <Divider
              sx={{ borderColor: 'solid rgba(145, 158, 171, 0.24)', width: '100%', mb: 1 }}
            /> */}
						<div>
							<span>Posts</span> {pretifyBigNum(postMedianAvg) || '-'}{' '}
							{campaignCurrency === 'EUR' ? '€' : campaignCurrency}
						</div>
						<div>
							<span>Stories</span> {pretifyBigNum(storiesMedianAvg) || '-'}{' '}
							{campaignCurrency === 'EUR' ? '€' : campaignCurrency}
						</div>
						<div>
							<span>Reels</span> {pretifyBigNum(reelsMedianAvg) || '-'}{' '}
							{campaignCurrency === 'EUR' ? '€' : campaignCurrency}
						</div>
					</StyledCpmWrapper>
				)}
				{!!(minCreatorValue && maxCreatorValue) && (
					<StyledCpmWrapper>
						<Stack>
							<Typography sx={{ fontSize: '10px', lineHeight: '12px', textAlign: 'center' }}>
                Range of Creator {subTitle.includes('CPM') ? 'CPM' : 'CPE'}s{' '}
							</Typography>
							<Typography sx={{ fontSize: '10px', lineHeight: '12px', textAlign: 'center' }}>
								{pretifyBigNum(minCreatorValue)}{' '}
								{campaignCurrency === 'EUR' ? '€' : campaignCurrency} -{' '}
								{pretifyBigNum(maxCreatorValue)}{' '}
								{campaignCurrency === 'EUR' ? '€' : campaignCurrency}
							</Typography>
						</Stack>
					</StyledCpmWrapper>
				)}
				{!!averagePerContent && (
					<StyledCpmWrapper>
						<Typography
							variant="body2"
							sx={{
								color: 'text.secondary',
								fontSize: '12px',
								lineHeight: '16px',
								mt: 1,
								textAlign: 'center',
							}}
						>
              Average Per Content
							<br />
							{pretifyBigNum(averagePerContent)}{' '}
							{campaignCurrency === 'EUR' ? '€' : campaignCurrency}
						</Typography>
					</StyledCpmWrapper>
				)}
				{!!(videosMedianAvg || shortsMedianAvg) && (
					<StyledCpmWrapper>
						<Divider
							sx={{ borderColor: 'solid rgba(145, 158, 171, 0.24)', width: '100%', mb: 1 }}
						/>
						<div>
							<span>Videos</span> {pretifyBigNum(videosMedianAvg) || '-'}{' '}
							{campaignCurrency === 'EUR' ? '€' : campaignCurrency}
						</div>
						<div>
							<span>Shorts</span> {pretifyBigNum(shortsMedianAvg) || '-'}{' '}
							{campaignCurrency === 'EUR' ? '€' : campaignCurrency}
						</div>
					</StyledCpmWrapper>
				)}
				{!!(totalReachAvg || totalSpendAvg || totalContentAvg) && (
					<StyledCpmWrapper>
						<Box sx={{ width: '100%', fontSize: '10px' }}>
							<Box component="span" sx={{ fontSize: '10px' }}>
								{subTitle.includes('CPM') ? 'Reached' : 'Interactions'}
							</Box>
							<Typography
								variant="body2"
								sx={{ fontSize: '10px', minWidth: '40px', textAlign: 'left' }}
							>
								{pretifyBigNum(totalReachAvg) || '-'}
							</Typography>
						</Box>
						<Box sx={{ width: '100%' }}>
							<Box component="span" sx={{ fontSize: '10px' }}>
                Expenses ({campaignCurrency === 'EUR' ? '€' : campaignCurrency})
							</Box>
							<Typography
								variant="body2"
								sx={{ fontSize: '10px', minWidth: '40px', textAlign: 'left' }}
							>
								{pretifyBigNum(totalSpendAvg) || '-'}
							</Typography>
						</Box>
						<Box sx={{ width: '100%' }}>
							<Box component="span" sx={{ fontSize: '10px' }}>
                Content Pieces
							</Box>
							<Typography
								variant="body2"
								sx={{ fontSize: '10px', minWidth: '40px', textAlign: 'left' }}
							>
								{pretifyBigNum(totalContentAvg) || '-'}
							</Typography>
						</Box>
					</StyledCpmWrapper>
				)}
			</Paper>
		</Card>
	)
}

SmallCardWidget.propTypes = {
	sx: PropTypes.object,
	icon: PropTypes.string,
	total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	title: PropTypes.string,
	subTitle: PropTypes.string,
	color: PropTypes.string,
	dangerColor: PropTypes.string,
	iconText: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	сpmAvg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	postMedianAvg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	reelsMedianAvg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	storiesMedianAvg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	shortsMedianAvg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	videosMedianAvg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	cpmMedian: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	maxCreatorValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	minCreatorValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	totalReachAvg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	totalSpendAvg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	totalContentAvg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	averagePerContent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	missingCreators: PropTypes.array,
	isPdf: PropTypes.bool,
}
