import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'

import {
  userGrantSelector,
  suggestedCreatorsArraySelector,
  recentSearchArraySelector,
} from '../../../../redux/selectors'

import useTranslation from '../../../../localization/useTranslation'
import { EXPLORE_VIEW_TYPE } from '../../../../constants/appSettings'
import WithTip from '../../../../components/common/popups/WithTip'
import {
  SearchContainerWrapper,
  SearchbarContent,
  WithScrollWrapper,
} from '../../../../components/common/styledWrappers'
import { SocialTabsWrapper, WidthMaxWrapper } from '../../../../components/common/tabs/tabsStyles'
import { PlaformSwitchButton } from '../../../../components/common/buttons/styledButtons'
import { Box, Stack, Typography, IconButton } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import { format, parseISO } from 'date-fns'
import { fShortenNumber } from '../../../utils/formatNumber'
import { changeExploreQuery } from '../../../../redux/ducks/exploreDucks'
import { useHistory, useParams } from 'react-router-dom'

const SearchBarContainer = ({
  isSearchEnabled = false,
  userindex = '',
  exploreViewType = '',
  onChangeExploreViewType,
  selectedData = {},
  setSelectedData,
  onHandleRecentSearchResults,
  onHandleGetSuggestedUsersById,
  onOpenModalLookalikeAi,
  onOpenModalDeleteSuggestionsList,
}) => {
  const { labelStrings, currentLang } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { suggestionsListId = '' } = useParams()

  const suggestedCreatorsArray = useSelector(suggestedCreatorsArraySelector)
  const recentSearchArray = useSelector(recentSearchArraySelector)

  const { no_plan: noPlan, build_lookalikes } = useSelector(userGrantSelector, shallowEqual)

  const OuterWrapper = isSearchEnabled ? Fragment : WithTip

  const onHandleClickRecentSearch = data => {
    setSelectedData(data)
    dispatch(changeExploreQuery(data?.query))
    onHandleRecentSearchResults(data)
  }

  const onHandleClickSuggestions = data => {
    history.replace(`/explore/${data?.id}`)
    setSelectedData(data)
    onHandleGetSuggestedUsersById(data)
  }

  return (
    <OuterWrapper
      {...(!isSearchEnabled && {
        withSignup: !noPlan,
        tipText: noPlan
          ? labelStrings.notActivatedAccountTipTitle
          : labelStrings.disabledFunctionInYourPlanTip,
        skipSpecialUpgradeText: noPlan,
        position: 'center center',
      })}
    >
      <div>
        <SocialTabsWrapper isDisabled={!userindex}>
          <WidthMaxWrapper>
            <PlaformSwitchButton
              onClick={() => onChangeExploreViewType(EXPLORE_VIEW_TYPE.suggestions)}
              userindex={userindex}
              isSelected={exploreViewType === EXPLORE_VIEW_TYPE.suggestions}
              style={{
                maxWidth: '156px',
                width: '100%',
                fontSize: '13px',
              }}
              // isDisabled={!suggestedCreatorsArray?.length}
              isExplore={currentLang === 'de'}
            >
              {labelStrings.explorePage.suggestions}
            </PlaformSwitchButton>
          </WidthMaxWrapper>
          <WidthMaxWrapper>
            <PlaformSwitchButton
              onClick={() => onChangeExploreViewType(EXPLORE_VIEW_TYPE.recentSearches)}
              userindex={userindex}
              isSelected={exploreViewType === EXPLORE_VIEW_TYPE.recentSearches}
              // isDisabled={!recentSearchArray?.length}
              isExplore={currentLang === 'de'}
              style={{
                fontSize: '13px',
              }}
            >
              {labelStrings.explorePage.recentSearches}
            </PlaformSwitchButton>
          </WidthMaxWrapper>
        </SocialTabsWrapper>

        {exploreViewType === EXPLORE_VIEW_TYPE.suggestions && (
          <>
            {build_lookalikes ? (
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{ width: '100%', padding: `0 7% 1% 4%` }}
              >
                <PlaformSwitchButton
                  onClick={onOpenModalLookalikeAi}
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    fontSize: '13px',
                    border: 'none',
                  }}
                  userindex={userindex}
                  isDisabled={false}
                  isRainbowUnselected={true}
                >
                  {labelStrings?.buildLookalike?.btnText}
                </PlaformSwitchButton>
              </Stack>
            ) : (
              <WithTip
                tipText={labelStrings.disabledFunctionInYourPlanTip}
                userindex={userindex}
                position="bottom center"
              >
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: '100%', padding: `0 7% 1% 4%` }}
                >
                  <PlaformSwitchButton
                    onClick={onOpenModalLookalikeAi}
                    style={{
                      width: '100%',
                      justifyContent: 'center',
                      fontSize: currentLang === 'de' ? '11px' : '13px',
                    }}
                    userindex={userindex}
                    isDisabled={true}
                  >
                    {labelStrings?.buildLookalike?.btnText}
                  </PlaformSwitchButton>
                </Stack>
              </WithTip>
            )}
          </>
        )}

        <SearchbarContent isActive={isSearchEnabled}>
          <WithScrollWrapper showScroll isSearch isSideBar height={`calc(100vh - 115px)`}>
            <SearchContainerWrapper isSearchEnabled={isSearchEnabled}>
              {exploreViewType === EXPLORE_VIEW_TYPE.suggestions && (
                <Stack spacing={1}>
                  {suggestedCreatorsArray?.map(el => {
                    const isActive = el?.id === (selectedData?.id || suggestionsListId)
                    const isNotUpdatedLookalike =
                      el.reference === 'lookalike' &&
                      (el.updated === null || el?.updated === 'null')
                    return (
                      <Stack
                        key={el.id}
                        onClick={() => !isNotUpdatedLookalike && onHandleClickSuggestions(el)}
                        sx={{
                          border: `0.5px solid ${isActive ? '#FF5D5A' : '#BC9797'}`,
                          borderRadius: '13px',
                          backgroundColor: isActive ? '#FF5D5A' : '#FFF9F9',
                          p: 2,
                          cursor: isNotUpdatedLookalike ? 'normal' : 'pointer',
                          '&:hover': {
                            opacity: 0.8,
                          },
                          boxShadow: isActive ? `0px 13px 67px -8px rgba(0, 0, 0, 0.25)` : 'unset',
                        }}
                        direction="row"
                        alignItems="center"
                        spacing={1}
                      >
                        <Stack sx={{ width: '100%' }} spacing={1} justifyContent="space-between">
                          <Typography
                            sx={{
                              color: isActive ? '#FFF' : '#BC9797',
                              fontFamily: 'Inter',
                              fontSize: '10px',
                              fontWeight: 600,
                              lineHeight: `12px`,
                              textTransform: 'uppercase',
                            }}
                          >
                            {`${labelStrings.explorePage.basedOn} ${el?.reference}`}
                          </Typography>

                          <Typography
                            sx={{
                              color: isActive ? '#FFF' : '#784E4E',
                              fontFamily: 'Inter',
                              fontSize: '18px',
                              fontWeight: 700,
                              lineHeight: `21px`,
                            }}
                          >
                            {el.formattedString}
                          </Typography>

                          {
                            <Typography
                              sx={{
                                color: isActive ? '#FFF' : '#BC9797',
                                fontFamily: 'Inter',
                                fontSize: '10px',
                                fontWeight: 600,
                                lineHeight: `12px`,
                                textTransform: 'uppercase',
                              }}
                            >
                              {isNotUpdatedLookalike ? (
                                <>{labelStrings.buildLookalike.inProgress}</>
                              ) : (
                                <>
                                  {el.reference === 'lookalike' ? (
                                    <>
                                      {`${labelStrings.explorePage.updatedOn} ${
                                        el?.updated ? format(parseISO(el?.updated), 'MMM dd') : ''
                                      }`}
                                    </>
                                  ) : (
                                    <>
                                      {el.type === 'similar'
                                        ? labelStrings.explorePage.similarAccounts
                                        : `${labelStrings.explorePage.newlyAddedTo} INFLUDATA`}
                                      -{' '}
                                      {`${labelStrings.explorePage.updatedOn} ${
                                        el?.updated ? format(parseISO(el?.updated), 'MMM dd') : ''
                                      }`}
                                    </>
                                  )}
                                </>
                              )}
                            </Typography>
                          }
                        </Stack>

                        <Stack justifyContent="center" alignItems="center">
                          {!isNotUpdatedLookalike && (
                            <Box>
                              <ChevronRightIcon sx={{ color: isActive ? '#FFF' : '#BC9797' }} />
                            </Box>
                          )}

                          <IconButton
                            aria-label="delete"
                            onClick={e => {
                              e.stopPropagation()
                              onOpenModalDeleteSuggestionsList({ id: el?.id })
                            }}
                          >
                            <DeleteForeverOutlinedIcon
                              sx={{ color: isActive ? '#FFF' : '#BC9797' }}
                            />
                          </IconButton>
                        </Stack>
                      </Stack>
                    )
                  })}
                </Stack>
              )}

              {exploreViewType === EXPLORE_VIEW_TYPE.recentSearches && (
                <Stack spacing={1}>
                  {recentSearchArray?.map(el => {
                    const isActive = el?.date === selectedData?.date
                    return (
                      <Stack
                        key={el.date}
                        onClick={() => onHandleClickRecentSearch(el)}
                        sx={{
                          border: `0.5px solid ${isActive ? '#FF5D5A' : '#BC9797'}`,
                          borderRadius: '13px',
                          backgroundColor: isActive ? '#FF5D5A' : '#FFF9F9',
                          p: 2,
                          cursor: 'pointer',
                          '&:hover': {
                            opacity: 0.8,
                          },
                          boxShadow: isActive ? `0px 13px 67px -8px rgba(0, 0, 0, 0.25)` : 'unset',
                        }}
                        direction="row"
                        alignItems="center"
                        spacing={1}
                      >
                        <Stack sx={{ width: '100%' }} spacing={1} justifyContent="space-between">
                          <Typography
                            sx={{
                              color: isActive ? '#FFF' : '#BC9797',
                              fontFamily: 'Inter',
                              fontSize: '10px',
                              fontWeight: 600,
                              lineHeight: `12px`,
                              textTransform: 'uppercase',
                            }}
                          >
                            {`${labelStrings.explorePage.searchedOn} ${format(
                              parseISO(el?.date),
                              'MMMM dd'
                            )}`}
                          </Typography>

                          <Typography
                            sx={{
                              color: isActive ? '#FFF' : '#784E4E',
                              fontFamily: 'Inter',
                              fontSize: '18px',
                              fontWeight: 700,
                              lineHeight: `21px`,
                            }}
                          >
                            {el.formattedString}
                          </Typography>

                          <Typography
                            sx={{
                              color: isActive ? '#FFF' : '#BC9797',
                              fontFamily: 'Inter',
                              fontSize: '10px',
                              fontWeight: 600,
                              lineHeight: `12px`,
                              textTransform: 'capitalize',
                            }}
                          >
                            {`${el.platform}, ${fShortenNumber(el.resultsCount)} Results`}
                          </Typography>
                        </Stack>
                        <Box>
                          <ChevronRightIcon sx={{ color: isActive ? '#FFF' : '#BC9797' }} />
                        </Box>
                      </Stack>
                    )
                  })}
                </Stack>
              )}
            </SearchContainerWrapper>
          </WithScrollWrapper>
        </SearchbarContent>
      </div>
    </OuterWrapper>
  )
}

SearchBarContainer.propTypes = {
  isSearchEnabled: PropTypes.bool,
  userindex: PropTypes.string,
  exploreViewType: PropTypes.string,
  onChangeExploreViewType: PropTypes.func,
  selectedData: PropTypes.object,
  setSelectedData: PropTypes.func,
  onHandleRecentSearchResults: PropTypes.func,
  onHandleGetSuggestedUsersById: PropTypes.func,
  onOpenModalLookalikeAi: PropTypes.func,
  onOpenModalDeleteSuggestionsList: PropTypes.func,
}

export default SearchBarContainer
