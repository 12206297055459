import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { themeCommonStyles } from '../../../../themes/infludataThemes'
import { CloseIcon } from '../../icons/icons'
import { USER_INDEXES } from '../../../../constants/appSettings'

import {
  ModalOverlay,
  ModalWrapper,
  ModalWindow,
  ModalHeader,
  ModalHeaderContent,
  ButtonClose,
  ModalContent,
} from './commonStyles'
import { Portal } from '../../Portal/Portal'

const Overlay = ({ children, withoutPortal }) => {
  return withoutPortal ? <>{children}</> : <Portal>{children}</Portal>
}

const Modal = ({
  headerContent = null,
  width = '400px',
  platform = USER_INDEXES.instagram,
  hasCloseButton = true,
  closeWhenOutsideClick = true,
  closeModal,
  children = null,
  withoutPortal = false,
}) => {
  const onOverlayClick = () => {
    if (closeWhenOutsideClick) closeModal()
  }

  useEffect(() => {
    const close = e => {
      if (e.keyCode === 27) {
        closeModal()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  return (
    <Overlay withoutPortal={withoutPortal}>
      <ModalOverlay className="modal__overlay" platform={platform} onClick={onOverlayClick}>
        <ModalWrapper>
          <ModalWindow
            className="modal__window"
            width={width}
            onClick={event => event.stopPropagation()}
          >
            <ModalHeader className="modal__header">
              <ModalHeaderContent className="modal__header-content">
                {headerContent}
              </ModalHeaderContent>
              {hasCloseButton && (
                <ButtonClose className="modal__close-button" onClick={closeModal}>
                  <CloseIcon color={themeCommonStyles.colors.white} />
                </ButtonClose>
              )}
            </ModalHeader>
            <ModalContent className="modal__content">{children}</ModalContent>
          </ModalWindow>
        </ModalWrapper>
      </ModalOverlay>
    </Overlay>
  )
}

Modal.propTypes = {
  headerContent: PropTypes.node,
  width: PropTypes.string,
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube]),
  hasCloseButton: PropTypes.bool,
  closeWhenOutsideClick: PropTypes.bool,
  closeModal: PropTypes.func,
  children: PropTypes.node,
  withoutPortal: PropTypes.bool,
}

export default Modal
