import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import merge from 'lodash/merge'
import { isBefore } from 'date-fns'
// form
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import { Box, Stack, Button, Tooltip, TextField, IconButton, DialogActions } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { MobileDateTimePicker } from '@mui/x-date-pickers-pro'
// components
import Iconify from '../iconify'
import { ColorSinglePicker } from '../color-utils'
import FormProvider, { RHFTextField, RHFSwitch } from '../hook-form'
import { getCurrentDatePlusOneHour } from '../../utils/formatTime'

// ----------------------------------------------------------------------

const getInitialValues = (event, range) => {
  const initialEvent = {
    title: '',
    description: '',
    color: '#1890FF',
    allDay: false,
    start: range ? new Date(range.start).toISOString() : new Date().toISOString(),
    end: range ? new Date(range.end).toISOString() : new Date().toISOString(),
  }

  if (event || range) {
    return merge({}, initialEvent, event)
  }

  return initialEvent
}

export default function CalendarForm({
  event,
  range,
  colorOptions,
  onCreateUpdateEvent,
  onDeleteEvent,
  onCancel,
  isEventOutsideCalendar,
  isDeleteAndEditBlocked
}) {
  const hasEventData = !!event

  const EventSchema = Yup.object().shape({
    title: Yup.string()
      .max(255)
      .required('Title is required'),
    description: Yup.string().max(5000),
  })

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues: getInitialValues(event, range),
  })

  const {
    reset,
    watch,
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, dirtyFields, touchedFields }
  } = methods

  const values = watch()

  const watchedStartDate = values.start
  useEffect(() => {
    setValue('end', getCurrentDatePlusOneHour(watchedStartDate))
  }, [watchedStartDate])

  const onSubmit = async data => {
    try {
      const newEvent = {
        title: data.title,
        description: data.description,
        color: data.color,
        allDay: data.allDay,
        start: data.start,
        end: data.end,
      }
      onCreateUpdateEvent(newEvent)
      onCancel()
      reset()
    } catch (error) {
      console.error(error)
    }
  }

  const isDateError =
    !values.allDay && values.start && values.end
      ? isBefore(new Date(values.end), new Date(values.start))
      : false

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ px: 3 }}>
        <RHFTextField name="title" label="Title" />

        <RHFTextField name="description" label="Description" multiline rows={3} />

        <RHFSwitch name="allDay" label="All day" />

        <Controller
          name="start"
          control={control}
          defaultValue={null}
          render={({ field }) => {
            return (
              <MobileDateTimePicker
                {...field}
                value={new Date(field.value)}
                onChange={newValue => field.onChange(newValue)}
                label="Start date"
                format="dd/MM/yyyy hh:mm a"
                orientation='landscape'
                defaultValue={new Date(values.start)}
                minDate={!isEventOutsideCalendar ? new Date(values.start) : null}
                maxDate={!isEventOutsideCalendar ? new Date(values.end) : null}
                renderInput={params => <TextField {...params} fullWidth />}
              />
            )
          }}
        />

        <Controller
          name="end"
          control={control}
          render={({ field }) => (
            <MobileDateTimePicker
              {...field}
              onChange={newValue => field.onChange(newValue)}
              label="End date"
              value={new Date(field.value)}
              format="dd/MM/yyyy hh:mm a"
              orientation='landscape'
              minDate={new Date(values.start)}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  error={!!isDateError}
                  helperText={isDateError && 'End date must be later than start date'}
                />
              )}
            />
          )}
        />

        <Controller
          name="color"
          control={control}
          render={({ field }) => (
            <ColorSinglePicker
              value={field.value}
              onChange={field.onChange}
              colors={colorOptions}
            />
          )}
        />
      </Stack>

      <DialogActions>
        {hasEventData && !isDeleteAndEditBlocked && (
          <Tooltip title="Delete Event">
            <IconButton onClick={onDeleteEvent}>
              <Iconify icon="eva:trash-2-outline" />
            </IconButton>
          </Tooltip>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={onCancel}>
          Cancel
        </Button>

        {!isDeleteAndEditBlocked && (
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {hasEventData ? 'Update' : 'Add'}
          </LoadingButton>
        )}
      </DialogActions>
    </FormProvider>
  )
}

CalendarForm.propTypes = {
  event: PropTypes.object,
  range: PropTypes.object,
  onCancel: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onCreateUpdateEvent: PropTypes.func,
  isEventOutsideCalendar: PropTypes.bool,
  colorOptions: PropTypes.arrayOf(PropTypes.string),
}
