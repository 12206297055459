import { Skeleton, Stack, useMediaQuery } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { getNavbarHeight } from '../../../utils'
import { searchContentTypes } from '../../../constants/search'

const SkeletonTableItem = () => {
  return (
    <Stack
      sx={{ py: 1, borderBottom: '0.5px solid rgb(244, 232, 232)', mt: '0 !important' }}
      direction="row"
      alignItems="center"
      justifyContent="start"
      spacing={2}
    >
      <Stack sx={{ width: '150px', ml: '40px !important' }}>
        <Skeleton variant="circular" animation="wave" width={81} height={81} />
      </Stack>

      <Stack spacing={0.5} sx={{ width: '180px' }}>
        <Stack direction="row" spacing={0.5}>
          <Skeleton variant="circular" animation="wave" width={34} height={34} />
          <Skeleton variant="circular" animation="wave" width={34} height={34} />
          <Skeleton variant="circular" animation="wave" width={34} height={34} />
        </Stack>
        <Stack direction="row" spacing={0.5}>
          <Skeleton variant="circular" animation="wave" width={34} height={34} />
          <Skeleton variant="circular" animation="wave" width={34} height={34} />
          <Skeleton variant="circular" animation="wave" width={34} height={34} />
        </Stack>
      </Stack>
      <Stack spacing={0.5} sx={{ width: '305px' }}>
        <Skeleton variant="rounded" animation="wave" width={80} height={20} />
        <Skeleton variant="rounded" animation="wave" width={'70%'} height={45} />
      </Stack>

      <Stack spacing={0.5} sx={{ width: '600px' }}>
        <Skeleton variant="rounded" animation="wave" width={150} height={20} />
        <Skeleton variant="rounded" animation="wave" width={'90%'} height={45} />
      </Stack>
      <Stack spacing={0.5} sx={{ width: '210px' }}>
        <Skeleton variant="rounded" animation="wave" width={40} height={19} />
        <Skeleton variant="rounded" animation="wave" width={40} height={19} />
        <Skeleton variant="rounded" animation="wave" width={40} height={19} />
      </Stack>
    </Stack>
  )
}

const SkeletonTableHeader = () => {
  return (
    <Stack sx={{ borderBottom: '0px solid rgb(188, 151, 151)', width: '100%', overflow: 'hidden' }}>
      <Stack
        sx={{ pl: 5, width: '100%', pb: 1 }}
        direction="row"
        alignItems="center"
        justifyContent="start"
        spacing={2}
      >
        <Stack sx={{ width: '150px' }}>
          <Skeleton variant="rounded" animation="wave" width={90} height={10} />
        </Stack>

        <Stack sx={{ width: '180px' }}>
          <Skeleton variant="rounded" animation="wave" width={60} height={10} />
        </Stack>

        <Stack spacing={0.3} sx={{ width: '305px' }}>
          <Skeleton variant="rounded" animation="wave" width={60} height={10} />
          <Skeleton variant="rounded" animation="wave" width={100} height={10} />
          <Skeleton variant="rounded" animation="wave" width={80} height={10} />
        </Stack>

        <Stack spacing={0.3} sx={{ width: '600px' }}>
          <Skeleton variant="rounded" animation="wave" width={60} height={10} />
          <Skeleton variant="rounded" animation="wave" width={70} height={10} />
        </Stack>

        <Stack spacing={0.3} sx={{ width: '210px' }}>
          <Skeleton variant="rounded" animation="wave" width={60} height={10} />
          <Skeleton variant="rounded" animation="wave" width={70} height={10} />
          <Skeleton variant="rounded" animation="wave" width={60} height={10} />
        </Stack>
      </Stack>
    </Stack>
  )
}

export const SkeletonLoader = ({ searchType }) => {
  const ref = useRef()

  const mediaQueryMaxHeight = useMediaQuery('(max-width:1480px)')

  const [heightContainer, setHeightContainer] = useState(0)

  const getCountRows = Math.round(heightContainer / 140) //140 -This value is the approximate height of the element
  const generateRows = [...Array(getCountRows).keys()]

  useEffect(() => {
    if (!ref.current || searchType === searchContentTypes.CONTENT) {
      return
    }

    const resizeObserver = new ResizeObserver(() => {
      if (ref.current.offsetHeight !== heightContainer) {
        setHeightContainer(ref.current.offsetHeight)
      }
    })

    resizeObserver.observe(ref.current)

    return function cleanup() {
      resizeObserver.disconnect()
    }
  }, [ref.current])

  return (
    <Stack
      ref={ref}
      sx={{
        width: '100%',
        height: `calc(100vh - ${getNavbarHeight()}px)`,
        p: 2,
        '& .MuiSkeleton-root': {
          backgroundColor: searchType === searchContentTypes.CREATOR ? '#F4F6F8' : '#F4F6F8',
        },
      }}
      spacing={2}
    >
      {searchType === searchContentTypes.CONTENT ? (
        <Stack spacing={2} sx={{ width: '100%', height: '100%' }}>
          {/* filters */}
          <Stack direction="row" spacing={2}>
            <Skeleton variant="rounded" animation="wave" width={'100%'} height={130} />
            <Skeleton variant="rounded" animation="wave" width={200} height={130} />
          </Stack>

          {/* top pagination */}
          <Skeleton variant="rounded" animation="wave" width={'100%'} height={70} />

          {/* main content section */}
          <Stack direction="row" spacing={2} sx={{ height: '100%', width: '100%' }}>
            <Skeleton variant="rounded" animation="wave" width={'100%'} height={'100%'} />
            <Skeleton variant="rounded" animation="wave" width={'100%'} height={'100%'} />
            <Skeleton variant="rounded" animation="wave" width={'100%'} height={'100%'} />
            <Skeleton variant="rounded" animation="wave" width={'100%'} height={'100%'} />
            {!mediaQueryMaxHeight && (
              <Skeleton variant="rounded" animation="wave" width={'100%'} height={'100%'} />
            )}
          </Stack>

          {/* bottom pagination */}
          <Skeleton variant="rounded" animation="wave" width={'100%'} height={70} />
        </Stack>
      ) : (
        <>
          {/* top pagination */}
          <Stack sx={{ px: 2 }} direction="row" alignItems="center" justifyContent="space-between">
            <Skeleton variant="rounded" animation="wave" width={120} height={20} />
            <Skeleton variant="rounded" animation="wave" width={410} height={20} />
            <div></div>
          </Stack>

          {/* main content section */}
          <Stack
            spacing={2}
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: '#FFF9F9',
              borderRadius: '16px',
            }}
          >
            {/* Sorting, export btns  */}
            <Stack sx={{ p: '15px 15px 10px 25px' }} direction="row" justifyContent="space-between">
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Skeleton variant="rounded" animation="wave" width={64} height={10} />
                <Skeleton variant="rounded" animation="wave" width={170} height={35} />
              </Stack>
              <Stack direction="row" spacing={2}>
                <Stack direction="row" spacing={1}>
                  <Skeleton variant="rounded" animation="wave" width={35} height={35} />
                  <Skeleton variant="rounded" animation="wave" width={35} height={35} />
                </Stack>
                <Skeleton variant="rounded" animation="wave" width={50} height={35} />
              </Stack>
            </Stack>

            <SkeletonTableHeader />

            {/* table content */}
            <Stack>
              {generateRows?.map(el => (
                <SkeletonTableItem key={el} />
              ))}
            </Stack>
          </Stack>

          {/* bottom pagination */}
          <Stack sx={{ px: 2 }} direction="row" alignItems="center" justifyContent="center">
            <Skeleton variant="rounded" animation="wave" width={410} height={20} />
          </Stack>
        </>
      )}
    </Stack>
  )
}
