import { LoadingButton } from '@mui/lab'
import { Grid, Box, Stack, MenuItem, InputLabel } from '@mui/material'
import React from 'react'
import { RHFTextField, RHFDateField, RHFSelect } from '../../../components/hook-form'
import FormProvider from '../../../components/hook-form/FormProvider'
import { StyledButton } from './styles'
import useTranslation from '../../../../localization/useTranslation'

export const AddValueManuallyFields = props => {
  const {
    methods,
    onSubmit,
    handleSubmit,
    isSubmitting,
    handleClose,
    isApplyLevelCreator,
    creatorsList,
  } = props
  const { labelStrings } = useTranslation()
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Stack sx={{ minHeight: '112px' }}>
            {isApplyLevelCreator && (
              <Box sx={{ my: 2 }}>
                <RHFSelect name="creatorId" label={labelStrings.chooseCreators} size="small">
                  {creatorsList?.length ? (
                    creatorsList?.map(creator => (
                      <MenuItem key={creator.creatorId} value={creator.creatorId}>
                        {creator.username}
                      </MenuItem>
                    ))
                  ) : (
                    <div></div>
                  )}
                </RHFSelect>
              </Box>
            )}
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ width: '100%', my: !isApplyLevelCreator ? 2 : 0 }}
            >
              <Box sx={{ width: '200px' }}>
                <RHFDateField size="small" name="date" label={labelStrings.date} />
              </Box>
              <Box sx={{ width: '200px' }}>
                <RHFTextField
                  size="small"
                  name="value"
                  label={labelStrings.value}
                  helperText={``}
                />
              </Box>
            </Stack>
          </Stack>

          <Stack
            direction="row"
            spacing={1.5}
            alignItems="flex-center"
            justifyContent="center"
            sx={{ mt: 2 }}
          >
            <StyledButton variant="outlined" sx={{ fontWeight: 'bold' }} onClick={handleClose}>
              {labelStrings.cancel}
            </StyledButton>

            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              {labelStrings.confirm}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
