import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api'
import {
  ContentMapWrapper,
  MapsTooltipWrapper,
  ProfileCardImgWrapper,
  ProfileContentCard,
} from './styles'
import { mapStyles } from './mapStyles'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveMapMarker } from '../../../../redux/ducks/searchDucks'
import { searchContentActiveMapMarker } from '../../../../redux/selectors'

const DefaultMarkerIconSvg = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 10.045V9.873C5 6.077 8.077 3 11.873 3C15.669 3 18.746 6.077 18.746 9.873V10.045C18.746 13.506 14.364 18.716 12.598 20.676C12.209 21.108 11.537 21.108 11.148 20.676C9.382 18.716 5 13.506 5 10.045Z" fill="#784E4E"/>
<path d="M9.87305 9.95497C9.87305 11.06 10.768 11.955 11.873 11.955C12.978 11.955 13.873 11.06 13.873 9.95497V9.91797C13.873 8.81297 12.978 7.91797 11.873 7.91797C10.768 7.91797 9.87305 8.81297 9.87305 9.91797" fill="#F4E8E8"/>
</svg>`

const ActiveMarkerSvg = `<svg width="34" height="34" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.33325 16.7417V16.455C8.33325 10.1283 13.4616 5 19.7883 5C26.1149 5 31.2433 10.1283 31.2433 16.455V16.7417C31.2433 22.51 23.9399 31.1933 20.9966 34.46C20.3483 35.18 19.2283 35.18 18.5799 34.46C15.6366 31.1933 8.33325 22.51 8.33325 16.7417Z" fill="#FF5D5A"/>
<path d="M16.4551 16.5915C16.4551 18.4332 17.9467 19.9249 19.7884 19.9249C21.6301 19.9249 23.1217 18.4332 23.1217 16.5915V16.5299C23.1217 14.6882 21.6301 13.1965 19.7884 13.1965C17.9467 13.1965 16.4551 14.6882 16.4551 16.5299" fill="#F4E8E8"/>
</svg>
`

export const MapContent = props => {
  const { filteredContentForMap = [], activeCard = {} } = props
  const dispatch = useDispatch()
  const activeMapMarker = useSelector(searchContentActiveMapMarker)

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })

  const [currPos, setCurrPos] = useState({
    lat: 0,
    lng: 0
  })

  const [activeMarker, setActiveMarker] = useState({})

  const getCardData = filteredContentForMap?.filter(
    el => el.locationArray[0] === activeMarker['lng'] && el.locationArray[1] === activeMarker['lat']
  )

  const onHandleActive = e => {
    const lat = e.latLng?.lat()
    const lng = e.latLng?.lng()
    setActiveMarker({ lat, lng })
  }

  const handleClickScroll = (profile) => {
    const element = document.getElementById(profile._id);
    if (element) {
      dispatch(setActiveMapMarker(profile))
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const Markers = () =>
    filteredContentForMap?.map(el => {
      const id = el.contentId
      const lat = el.locationArray[1]
      const lng = el.locationArray[0]

      const currMarkerIcon =
        id === activeCard.contentId || getCardData[0]?.contentId === id || activeMapMarker._id === id
          ? ActiveMarkerSvg
          : DefaultMarkerIconSvg
      return (
        <Marker
          icon={{
            url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(currMarkerIcon),
          }}
          position={{ lat, lng }}
          key={id}
          cursor="pointer"
          onMouseOver={e => onHandleActive(e)}
          onMouseOut={() => setActiveMarker({})}
          onClick={() => handleClickScroll(el)}
        />
      )
    })

  if (loadError) return <ContentMapWrapper>Error loading maps</ContentMapWrapper>
  if (!isLoaded) return <ContentMapWrapper>Loading maps</ContentMapWrapper>

  return (
    <ContentMapWrapper>
      <GoogleMap
        mapContainerClassName="map-container"
        center={currPos}
        zoom={2}
        options={{
          styles: mapStyles,
          panControl: false,
          keyboardShortcuts: false,
          disableDefaultUI: true,
          fullscreenControl: false,
          zoomControl: true,
        }}
      >
        {Markers()}
      </GoogleMap>
      <MapsTooltipWrapper>
        {!!Object.keys(activeMarker)?.length && (
          <ProfileContentCard withMarginTop>
            <ProfileCardImgWrapper>
              <img src={getCardData[0]?.imageUrl} alt={getCardData[0]?.username} />
            </ProfileCardImgWrapper>
          </ProfileContentCard>
        )}
      </MapsTooltipWrapper>
    </ContentMapWrapper>
  )
}

MapContent.propTypes = {
  filteredContentForMap: PropTypes.array,
  activeCard: PropTypes.object,
}
