import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DialogTitle, CloseWrapper } from '../styledWrappers'
import { ActionButton } from '../buttons/styledButtons'
import { Wrapper } from './dialogsCommonStyles'
import { CloseIcon } from '../icons'

import useTranslation from '../../../localization/useTranslation'

const DowngradeWrapper = styled(Wrapper)`
  height: 250px;
  justify-content: space-around;
  padding: 20px;
  text-align: center;
`

const MessageWrapper = styled.div`
  padding: 0 20px;
`

const CloseIconWrapper = styled(CloseWrapper)`
  top: 10px;
  right: 15px;
`

const ButtonsGroupWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
`

const FreeUserAccessLimitDialog = ({ closeHandler, confirmHandler }) => {
  const { labelStrings } = useTranslation()
  return (
    <DowngradeWrapper>
      <CloseIconWrapper onClick={closeHandler}>
        <CloseIcon />
      </CloseIconWrapper>
      <DialogTitle>{labelStrings.thanksForUseFreeVersion}</DialogTitle>
      <MessageWrapper>{labelStrings.freeUserAccessLimitMessage}</MessageWrapper>
      <ButtonsGroupWrapper>
        <ActionButton onClick={closeHandler}>{labelStrings.back}</ActionButton>

        <ActionButton onClick={confirmHandler}>{labelStrings.showPlans}</ActionButton>
      </ButtonsGroupWrapper>
    </DowngradeWrapper>
  )
}

FreeUserAccessLimitDialog.propTypes = {
  closeHandler: PropTypes.func,
  confirmHandler: PropTypes.func,
}

export default FreeUserAccessLimitDialog
