import * as Yup from 'yup'

export const EditContentOverviewSchema = Yup.object().shape({
  // label: Yup.mixed()
  //   .test('type', '', value => {
  //     return typeof (value === 'boolean' || typeof value === 'string') && value !== ''
  //   }),
  // reach: Yup.number(),
  // caption: Yup.string(),
  // likes: Yup.number(),
  // comments: Yup.string(),
  // foundPhrases: Yup.string(),
  // shares: Yup.number(),
  // reachedAudience: Yup.number(),
  // customField: Yup.number(),
  // nonOrgReach: Yup.number(),
  // nonOrgLikes: Yup.number(),
  // nonOrgComments: Yup.number(),
  // nonOrgShare: Yup.number(),
  // nonOrgEngagementRate: Yup.number(),
  // nonOrgAmountSpentInEur: Yup.number(),
  // nonOrgReachSwitch: Yup.boolean(),
})
