import React, { useEffect, useMemo, useRef, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Box, Stack, Tooltip } from '@mui/material'
import {
  currentSearchQuerySelector,
  searchResultsSelector,
  userGrantSelector,
} from '../../redux/selectors'
import useTranslation from '../../localization/useTranslation'
import SingleSelect from '../common/selects/SingleSelect'
import { ChevronArrowIcon } from '../common/icons'
import queryString from 'query-string'

import { SORTING_DIRECTIONS } from '../../constants/sortings'
import WithUpgradeTip from '../common/popups/WithUpgradeTip'

const MAX_LIMIT_COUNT_DOWNLOAD_SEARCH_RESULTS = 500

import {
  SortContainer,
  SortInnerWrapper,
  SortByLabel,
  SelectWrapper,
  SortDirectionsWrapper,
  SortDirectionIconWrapper,
  SortLabelSelectWrapper,
} from '../common/styledWrappers/campaignUsersSortingStyles'

import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'
import routes from '../../routes'
import { format } from 'date-fns'
import { LoadingButton } from '@mui/lab'
import { CSVLink } from 'react-csv'

const SortingCommonTool = ({
  sortToolId,
  sortingOptions,
  changeSortingHandler,
  currentSortingValue,
  changeSortingDirectionHandler,
  currentSortingDirection,
  socialPlatformIndex = '',
  isDisabled,
  notTip,
  isFullWidth,
  isContent,
  isDownloadSearchResults,
  // noDisabledTip
}) => {
  const { labelStrings } = useTranslation()

  const { download_results } = useSelector(userGrantSelector, shallowEqual)
  const { count: searchResultsCount } = useSelector(searchResultsSelector, shallowEqual) || {}
  const currentQuery = useSelector(currentSearchQuerySelector, shallowEqual)

  const [isLoadingCSV, setIsLoadingCSV] = useState(false)
  const [csvData, setCsvData] = useState({
    header: [],
    data: [],
  })

  const csvLinkRef = useRef()

  const currDate = new Date()
  const formattedDate = format(currDate, 'dd-MM-yyyy HH:mm:ss')

  const TipWrapper = ({ children }) => {
    if (notTip) return children
    return isDisabled ? (
      <WithUpgradeTip userindex={socialPlatformIndex} tipText={labelStrings.useSorting}>
        {children}
      </WithUpgradeTip>
    ) : (
      <>{children}</>
    )
  }

  const isActiveDesc = currentSortingDirection === SORTING_DIRECTIONS.descend
  const isActiveAsc = currentSortingDirection === SORTING_DIRECTIONS.ascend

  const isPossibleDownloadSearchResults =
    searchResultsCount <= MAX_LIMIT_COUNT_DOWNLOAD_SEARCH_RESULTS

  const downloadSearchResultsAsCsvTipText = useMemo(() => {
    if (!isPossibleDownloadSearchResults && download_results) {
      return labelStrings.downloadResultsAsCSVNotPossible
    }
    if (download_results && isPossibleDownloadSearchResults) {
      return labelStrings.downloadResultsAsCSV
    }
    return labelStrings.featureIsNotAvailable
  }, [isPossibleDownloadSearchResults, download_results])

  const onDownloadCSVSearchRes = async () => {
    setIsLoadingCSV(true)
    setCsvData({ data: [], header: [] })
    try {
      const stringifiedQuery = queryString.stringify(currentQuery)

      const csvLink = `${routes.collab.api.getCSVDataFromSearch}?${stringifiedQuery}`
      const response = await fetch(csvLink)
      const { data, header } = await response.json()
      if (data?.length || header?.length) {
        setCsvData({ data, header })
      }
    } catch (error) {
      console.error('Error downloading CSV:', error)
    } finally {
      setIsLoadingCSV(false)
    }
  }

  useEffect(() => {
    if (
      csvData.header?.length > 0 &&
      csvLinkRef.current &&
      csvLinkRef.current.link &&
      !isLoadingCSV
    ) {
      setTimeout(() => {
        csvLinkRef.current.link.dataset.interception = 'off'
        csvLinkRef.current.link.click()
      })
    }
  }, [csvData.header, isLoadingCSV])

  return (
    <SortContainer
      className="sorting_main_container"
      isFullWidth={isFullWidth}
      isContent={isContent}
    >
      <TipWrapper>
        <SortInnerWrapper className="sorting_elements_container">
          <SortLabelSelectWrapper>
            <SortByLabel isDisabled={isDisabled} className="sorting_label_container">
              {labelStrings.sortedBy}:
            </SortByLabel>
            <SelectWrapper isDisabled={isDisabled} className="sorting_select_container">
              <SingleSelect
                id={sortToolId}
                options={sortingOptions}
                onChange={changeSortingHandler}
                value={currentSortingValue}
              />
            </SelectWrapper>
          </SortLabelSelectWrapper>

          {!isContent && (
            <SortDirectionsWrapper isDisabled={isDisabled} className="sorting_directions_container">
              <SortDirectionIconWrapper
                isAscDirection
                onClick={changeSortingDirectionHandler(SORTING_DIRECTIONS.ascend)}
                isActive={isActiveAsc}
                userindex={socialPlatformIndex}
              >
                <ChevronArrowIcon
                  isActiveIcon={isActiveAsc}
                  userindex={socialPlatformIndex}
                  isArrow
                />
              </SortDirectionIconWrapper>

              <SortDirectionIconWrapper
                onClick={changeSortingDirectionHandler(SORTING_DIRECTIONS.descend)}
                isActive={isActiveDesc}
                userindex={socialPlatformIndex}
              >
                <ChevronArrowIcon
                  isActiveIcon={isActiveDesc}
                  userindex={socialPlatformIndex}
                  isArrow
                />
              </SortDirectionIconWrapper>
            </SortDirectionsWrapper>
          )}
          {isDownloadSearchResults && (
            <Stack sx={{ height: '35px', ml: 1 }} direction="row" alignItems="center">
              <Tooltip title={downloadSearchResultsAsCsvTipText}>
                <Box>
                  <LoadingButton
                    loading={isLoadingCSV}
                    onClick={onDownloadCSVSearchRes}
                    disabled={!download_results || !isPossibleDownloadSearchResults}
                  >
                    {!isLoadingCSV && (
                      <DownloadRoundedIcon
                        sx={{
                          fill:
                            download_results && isPossibleDownloadSearchResults
                              ? '#784E4E'
                              : '#637381',
                          opacity: download_results && isPossibleDownloadSearchResults ? 1 : 0.5,
                        }}
                      />
                    )}
                  </LoadingButton>

                  {!!csvData?.header?.length && (
                    <CSVLink
                      asyncOnClick
                      ref={csvLinkRef}
                      data={csvData?.data}
                      headers={csvData?.header}
                      target="_blank"
                      filename={`search-results-${formattedDate}.csv`}
                    />
                  )}
                </Box>
              </Tooltip>
            </Stack>
          )}
        </SortInnerWrapper>
      </TipWrapper>
    </SortContainer>
  )
}

SortingCommonTool.propTypes = {
  sortToolId: PropTypes.string.isRequired,
  sortingOptions: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
  ).isRequired,
  changeSortingHandler: PropTypes.func,
  currentSortingValue: PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
    .isRequired,
  changeSortingDirectionHandler: PropTypes.func,
  currentSortingDirection: PropTypes.string.isRequired,
  socialPlatformIndex: PropTypes.string,
  isDisabled: PropTypes.bool,
  notTip: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isContent: PropTypes.bool,
  isDownloadSearchResults: PropTypes.bool,
}

export default SortingCommonTool
