import React from 'react'
import { MenuItem } from '@mui/material'
import MenuPopover from '../../components/menu-popover'

export const PopoverItemMenu = props => {
  const {
    openPopover,
    handleClosePopover,
    onEditRow,
    onDeleteRow,
    handlePauseUnpause,
    isPaused,
  } = props

  return (
    <>
      <MenuPopover open={openPopover} onClose={handleClosePopover} arrow="right-top">
        <MenuItem
          onClick={() => {
            onEditRow()
            handleClosePopover()
          }}
        >
          Edit Goal
        </MenuItem>

        <MenuItem
          disabled={isPaused}
          onClick={() => {
            handlePauseUnpause(true)
            handleClosePopover()
          }}
        >
          Pause Goal
        </MenuItem>

        <MenuItem
          disabled={!isPaused}
          onClick={() => {
            handlePauseUnpause(false)
            handleClosePopover()
          }}
        >
          Unpause Goal
        </MenuItem>

        <MenuItem
          onClick={() => {
            onDeleteRow()
            handleClosePopover()
          }}
        >
          Delete Goal
        </MenuItem>
      </MenuPopover>
    </>
  )
}
