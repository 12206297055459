import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TableRow, TableCell, IconButton } from '@mui/material'
import Iconify from '../../components/iconify'
import { PopoverItemMenu } from './PopoverItemMenu'
import { format } from 'date-fns'
import { pretifyBigNum } from '../../../utils'

export const MentionsKeywordsTableRow = ({ row, onEditRow, onDeleteRow }) => {
  const { value, type, count, latestActivity, startingDate } = row

  const [openPopover, setOpenPopover] = useState(null)

  const handleOpenPopover = event => {
    setOpenPopover(event.currentTarget)
  }

  const handleClosePopover = () => {
    setOpenPopover(null)
  }

  return (
    <>
      <TableRow hover>
        <TableCell align="left">
          {value}
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>{type}</TableCell>

        <TableCell align="left">{pretifyBigNum(count)} times</TableCell>

        <TableCell align="left">{latestActivity ? format(new Date(latestActivity), 'MMM do, yyyy'): ''}</TableCell>

        <TableCell align="left">{format(new Date(startingDate), 'MMM do, yyyy')}</TableCell>

        <TableCell align="right">
          <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <PopoverItemMenu
        openPopover={openPopover}
        handleClosePopover={handleClosePopover}
        onEditRow={onEditRow}
        onDeleteRow={onDeleteRow}
      />
    </>
  )
}

MentionsKeywordsTableRow.propTypes = {
  row: PropTypes.object,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
}
