import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import AudienceReportsAuth from './AudienceReportsAuth'
import { Box, Stack, useMediaQuery } from '@mui/material'
import { audienceReportsSelector } from '../../../redux/selectors'
import { useDispatch, useSelector } from 'react-redux'
import {
  getReport,
  setAuthKey,
  setIsShowAuthPage,
  setReportsData,
  setUserData,
} from '../../../redux/ducks/audienceReports'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { OkPage } from './statusSubpages/OkPage'
import { NoTokensLeftPage } from './statusSubpages/NoTokensLeftPage'
import { SubInactivePage } from './statusSubpages/SubInactivePage'
import { WillBeAddedPage } from './statusSubpages/WillBeAddedPage'
import { UnableToAddPage } from './statusSubpages/UnableToAddPage'
import { Footer } from './statusSubpages/Footer'
import { Header } from './statusSubpages/Header'
import { UnlockedReportsPage } from './statusSubpages/UnlockedReportsPage'
import LoadingSpinner from '../../../components/common/spinners/LoadingSpinner'
import { OkPagePdfViewFollowers } from './pdf/OkPagePdfViewFollowers'
import { OkPagePdfViewLikers } from './pdf/OkPagePdfViewLikers'
import Scrollbar from '../../components/scrollbar/Scrollbar'

const _APP_WIDTH = '1000px'
const _APP_HEIGHT = '700px'

const AudienceReportsContainer = () => {
  const { search } = useLocation() || {}
  const dispatch = useDispatch()
  const { reports, isShowAuthPage, user, magic_authKey, isLoading, isLikers } =
    useSelector(audienceReportsSelector) || {}

  const pdfExportRef = useRef(null)
  const pdfExportRefLikers = useRef(null)

  const mediaQueryMaxHeight = useMediaQuery('(max-height:768)')
  const mediaQueryMaxHeightSecond = useMediaQuery('(max-height:680px)')
  const mediaQueryMaxWidth = useMediaQuery('(max-width:1300px)')

  const [authKeyFromStorage, setAuthKeyFromStorage] = useLocalStorage('magic_authKey', '')

  const magicLinkIdToken = reports?.magicLinkId || magic_authKey || authKeyFromStorage

  const params = new URLSearchParams(search)

  const usernameParam = params.get('username')
  const instagramIdParam = params.get('instagramId')
  const teamIdParam = params.get('teamId')

  const isShowLoginPage = isShowAuthPage

  const [isRenderUnlockedReports, setIsRenderUnlockedReports] = useState(false)

  const onUserLogout = async () => {
    // await magicInstance.user.logout()
    // @ts-ignore
    dispatch([setIsShowAuthPage(true), setUserData({}), setReportsData({}), setAuthKey('')])
    setAuthKeyFromStorage('')

    // Destroy the authKey cookie
    const cookieName = 'magic_authKey'
    const cookieOptions = {
      expires: new Date(0), // Set expiration to a past date to delete the cookie
      path: '/', // Set the path where the cookie is valid
    }

    // Set the cookie with an expired date to destroy it
    document.cookie = `${cookieName}=; expires=${cookieOptions.expires.toUTCString()}; path=${
      cookieOptions.path
    }`
  }

  useEffect(() => {
    // @ts-ignore
    dispatch(
      getReport({ username: usernameParam, instagramId: instagramIdParam, teamId: teamIdParam })
    )
  }, [usernameParam, instagramIdParam, user?.email])

  const statusPages = {
    ['ok']: (
      <OkPage
        audienceData={reports?.audienceData}
        ref={isLikers ? pdfExportRefLikers : pdfExportRef}
        isLikers={isLikers}
      />
    ),
    ['no_tokens_left']: <NoTokensLeftPage />,
    ['sub_inactive']: (
      <SubInactivePage magicLinkId={magicLinkIdToken} hasChargebeeId={reports?.hasChargebeeId} />
    ),
    ['will_be_added']: <WillBeAddedPage />,
    ['unable_to_add']: <UnableToAddPage />,
    ['invalid_input']: <UnableToAddPage />,
  }

  return (
    <>
      <Stack justifyContent="center" alignItems="center" sx={{ width: '100%', height: '100vh' }}>
        {isLoading && <LoadingSpinner showOnFullPage isTransparent isInsidePageLoad isColoredBg />}
        {isShowLoginPage ? (
          <Box sx={{ width: _APP_WIDTH, height: _APP_HEIGHT }}>
            <AudienceReportsAuth />
          </Box>
        ) : (
          <Stack
            sx={{
              width: reports?.status === 'ok' && !isRenderUnlockedReports ? '100%' : 'auto',
              maxWidth: '1600px',
              maxHeight: '900px',
              minWidth: '1000px',
              minHeight: '700px',
              height: reports?.status === 'ok' && !isRenderUnlockedReports ? '100%' : 'auto',
              backgroundColor: '#FFF9F9',
              border: '1px solid #784E4E',
              borderRadius: '9px',
              p: 2,
            }}
            justifyContent="space-between"
          >
            <Scrollbar
              sx={{
                maxHeight: mediaQueryMaxHeight
                  ? mediaQueryMaxHeightSecond
                    ? '680px'
                    : '768px'
                  : '100%',
                maxWidth: mediaQueryMaxWidth ? '1300px !important' : '100%',
                overflowX: mediaQueryMaxWidth ? 'auto' : 'hidden',
              }}
            >
              <Header reports={reports} />

              {/* Main content */}
              {isRenderUnlockedReports ? (
                <UnlockedReportsPage
                  unlockedUsers={reports?.unlockedUsers}
                  setIsRenderUnlockedReports={setIsRenderUnlockedReports}
                />
              ) : (
                <Box>{statusPages[reports?.status]}</Box>
              )}

              <Footer
                reports={reports}
                onUserLogout={onUserLogout}
                setIsRenderUnlockedReports={setIsRenderUnlockedReports}
              />
            </Scrollbar>
          </Stack>
        )}
      </Stack>
      <OkPagePdfViewFollowers ref={pdfExportRef} data={reports?.audienceData} reports={reports} />
      <OkPagePdfViewLikers
        ref={pdfExportRefLikers}
        data={reports?.audienceData}
        reports={reports}
      />
    </>
  )
}

export default AudienceReportsContainer
