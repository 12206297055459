import React, { useEffect } from 'react'
import { ModalComponent } from '../../components/modal/ModalComponent'
import { EditTextFields } from './Form/EditTextFields'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { EditCreatorSchema } from './Form/FormSchema.js'
import {
  preparedPriceCreatorFields,
  preparedContactCreatorFields,
  isChangedEnableCreatorPricing,
  isChangedContactCreatorFields,
  preparedCountryFields,
  preparedLanguageFields,
  preparedAgeFields,
  isChangedManualAudienceReportFields,
  preparedManualAudienceReport,
} from './utils'
import useTranslation from '../../../localization/useTranslation'

export const EditCreator = props => {
  const {
    openEditContentModal,
    setOpenEditContentModal,
    editItem,
    setEditItem,
    editFields,
    onEditCreator,
  } = props
  const { labelStrings, countryOptions } = useTranslation()

  const defaultValues = {
    overwriteAudience: false,
    shouldContactCreator: false,
    enablePricing: false,
    status: false,
    email: '',
    phone: '',
    username: '',
    overwriteName: '',
    bitlyLink: '',
    pricing: {
      collaborationPrice: '',
      priceForStory: '',
      priceForPost: '',
      priceForReel: '',
      priceForTikTok: '',
      priceForYTVideo: '',
      priceForYTShort: '',
      additionalCosts: '',
    },
    pricingTrack: 'trackEntireCollaboration',
    countryArray: [],
    ageArray: [],
    genderArray: {},
    languageArray: [],
  }

  const methods = useForm({
    resolver: yupResolver(EditCreatorSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
    resetField,
    setValue,
    watch,
    getValues,
  } = methods

  const changeInputs = () => {
    const arr = []
    const notAllowUpdatesList = ['pricingTrack']
    Object.keys(defaultValues).forEach(name => {
      !notAllowUpdatesList.includes(name) && arr.push({ name: name, value: editFields[name] })
    })

    arr.forEach(item => {
      setValue(item.name, editFields[item.name])
    })

    const countryArray = getValues('countryArray')
    const languageArray = getValues('languageArray')
    const ageArray = getValues('ageArray')
    const countryUpdatedArray = preparedCountryFields(countryArray, countryOptions)
    const languageUpdatedArray = preparedLanguageFields(languageArray)
    const ageUpdatedArray = preparedAgeFields(ageArray)
    setValue('countryArray', countryUpdatedArray)
    setValue('languageArray', languageUpdatedArray)
    setValue('ageArray', ageUpdatedArray)
    setValue('status', editItem?.status === 'archived' ? true : false)
    setValue('bitlyLink', editItem?.fromCampaignData?.bitlyLink?.shortenedUrl)
  }

  useEffect(() => {
    if (editFields) {
      changeInputs()
    }
  }, [editFields])

  const handleClose = () => {
    setOpenEditContentModal(false)
    setEditItem(null)
    reset({
      ...defaultValues,
    })
  }

  const onSubmit = async data => {
    const pricing = preparedPriceCreatorFields(data, editFields)
    const changedEnablePricing = isChangedEnableCreatorPricing(data, editFields)

    const shouldContactCreator = isChangedContactCreatorFields(data, editFields)
    const creatorContactMethods = preparedContactCreatorFields(data, editFields)

    const isChangedManualAudienceReport = isChangedManualAudienceReportFields(
      data,
      editFields,
      countryOptions
    )
    const isChangedOverwriteAudience = data.overwriteAudience !== editFields.overwriteAudience
    const manualAudienceReport = preparedManualAudienceReport(data, countryOptions)

    try {
      await new Promise(resolve => setTimeout(resolve, 500))

      const fieldsToChange = {
        ...(data?.status ? { isArchived: data.status ? true : false } : ''),
        ...(changedEnablePricing ? pricing : ''),
        ...(data['enablePricing'] !== editFields['enablePricing']
          ? { enablePricing: data.enablePricing }
          : {}),
        ...(shouldContactCreator && creatorContactMethods ? { creatorContactMethods } : {}),
        ...(data['shouldContactCreator'] !== editFields['shouldContactCreator']
          ? { shouldContactCreator: data.shouldContactCreator }
          : {}),
        ...(data['username'] !== editFields['username'] ? { username: data.username } : ''),
        ...(data['overwriteName'] !== editFields['overwriteName']
          ? { overwriteName: data.overwriteName }
          : {}),
        ...(data['bitlyLink'] !== editFields['bitlyLink'] ? { bitlyLink: data.bitlyLink } : {}),
        ...(isChangedOverwriteAudience ? { overwriteAudience: data.overwriteAudience } : {}),
        ...(data.overwriteAudience && isChangedManualAudienceReport
          ? { manualAudienceReport }
          : {}),
      }
      Object.keys(fieldsToChange)?.length && onEditCreator(fieldsToChange)
      handleClose()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <ModalComponent
      isOpenModal={openEditContentModal}
      closeModal={handleClose}
      title={labelStrings.creatorOverview.editCreator}
    >
      <EditTextFields
        editFields={editFields}
        onSubmit={onSubmit}
        editItem={editItem}
        handleSubmit={handleSubmit}
        methods={methods}
        isSubmitting={isSubmitting}
        resetField={resetField}
        setValue={setValue}
        watch={watch}
        getValues={getValues}
      />
    </ModalComponent>
  )
}
