import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'
import {
  TableRow,
  HeaderCell,
  TableTipWrapper,
  HeaderTextWrapper,
  CheckboxWrapper,
} from './tableStyles'
import Checkbox from '../../common/checkboxes/Checkbox'
import LinkToFaqQuestion from '../../faqs/LinkToFaqQuestion'
import { SOCIAL_PLATFORMS_NAMES } from '../../../constants/appSettings'

// Table head component
const ProfileListHeadRow = ({
  selectAll,
  areAllSelected = false,
  isCollectionView = false,
  isUnlockedCollectionOpened = false,
  tableForSimilarAccounts = false,
  isBlackListCollection = false,
  hasNotCheckboxes = false,
  lookalikeTypeData = ''
}) => {
  const { labelStrings } = useTranslation()

  const isFullTable = !tableForSimilarAccounts // add more limits here for future short tables
  const hasCheckbox = isCollectionView && !isUnlockedCollectionOpened && !isBlackListCollection
  const hasStarRating = isCollectionView && !isUnlockedCollectionOpened
  const hasUnlockedStatus = isCollectionView && isUnlockedCollectionOpened

  return (
    <TableRow>
      {hasCheckbox && !hasNotCheckboxes && (
        <HeaderCell width={30}>
          <CheckboxWrapper>
            <Checkbox onChange={selectAll} isChecked={areAllSelected} />
          </CheckboxWrapper>
        </HeaderCell>
      )}
      <HeaderCell>
        <HeaderTextWrapper width={100} style={{ margin: '0 auto' }}>
          {labelStrings.qualityRating}
          {isFullTable && (
            <TableTipWrapper>
              <LinkToFaqQuestion
                extraRatio={40}
                faqQuestionId="" //TODO: FAQLINK
                userindex={SOCIAL_PLATFORMS_NAMES.instagram.index}
              />
            </TableTipWrapper>
          )}
        </HeaderTextWrapper>
      </HeaderCell>
      {isFullTable && (
        <HeaderCell width={90}>
          <HeaderTextWrapper style={{ paddingLeft: '15px' }}>
            {labelStrings.options}
            <TableTipWrapper>
              <LinkToFaqQuestion
                extraRatio={20}
                faqQuestionId="" //TODO: FAQLINK
                userindex={SOCIAL_PLATFORMS_NAMES.instagram.index}
              />
            </TableTipWrapper>
          </HeaderTextWrapper>
        </HeaderCell>
      )}
      <HeaderCell width={170}>
        <div>{labelStrings.username}</div>
        <div>{labelStrings.locationLanguage}</div>
        <div>{labelStrings.category}</div>
      </HeaderCell>
      <HeaderCell width={350}>
        <div>{labelStrings.name}</div>
        <div>{labelStrings.bio}</div>
      </HeaderCell>
      <HeaderCell width={125}>
        <div>{labelStrings.followers}</div>
        <div>
          {labelStrings.views}/{labelStrings.post}
        </div>
        <div>{labelStrings.engagement}</div>
      </HeaderCell>
      {hasStarRating && !isBlackListCollection && lookalikeTypeData !== 'LOOKALIKE_TYPE' && (
        <HeaderCell width={125}>
          <HeaderTextWrapper>
            {labelStrings.rating}
            <TableTipWrapper>
              <LinkToFaqQuestion
                extraRatio={20}
                faqQuestionId="" //TODO: FAQLINK
                userindex={SOCIAL_PLATFORMS_NAMES.instagram.index}
              />
            </TableTipWrapper>
          </HeaderTextWrapper>
        </HeaderCell>
      )}

      {lookalikeTypeData === 'LOOKALIKE_TYPE' && (
        <HeaderCell width={125}>
          <div>{labelStrings.matchall}</div>
        </HeaderCell>
      )}

      {hasUnlockedStatus && (
        <HeaderCell width={125}>
          <HeaderTextWrapper>
            {labelStrings.unlockedStatus}
            <TableTipWrapper>
              <LinkToFaqQuestion
                extraRatio={20}
                faqQuestionId="" //TODO: FAQLINK
                userindex={SOCIAL_PLATFORMS_NAMES.instagram.index}
              />
            </TableTipWrapper>
          </HeaderTextWrapper>
        </HeaderCell>
      )}
    </TableRow>
  )
}

ProfileListHeadRow.propTypes = {
  selectAll: PropTypes.func,
  areAllSelected: PropTypes.bool,
  isCollectionView: PropTypes.bool,
  isUnlockedCollectionOpened: PropTypes.bool,
  tableForSimilarAccounts: PropTypes.bool,
  isBlackListCollection: PropTypes.bool,
  hasNotCheckboxes: PropTypes.bool,
  lookalikeTypeData: PropTypes.string
}

export default ProfileListHeadRow
