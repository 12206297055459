// @ts-nocheck
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Stack } from '@mui/material'
import { DragDropContext, Droppable } from '@hello-pangea/dnd'
import { SkeletonPipelineColumn } from './SkeletonPipelineColumn'
import { currentOpenedCampaignSelector, pipelineSelector } from '../../../redux/selectors'
import { PipelineColumn } from './column/PipelineColumn'
import PipelineColumnAdd from './column/PipelineColumnAdd'
import {
  addEditCardTaskPipeline,
  addEditColumnPipeline,
  addEditPipelineData,
  changeBoardCardLoadingPipeline,
  changeBoardCardsPipeline,
  changeCreator,
  persistColumnPipeline,
} from '../../../redux/ducks/campaignsDucks'
import { PipelineModalAddCreator } from './PipelineModalAddTask'

const findAndChangeCardId = (cardId, newColumnId, data) => {
  const updatedData = {}

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const element = data[key]
      if (element.cardId === cardId) {
        element.inColumnId = newColumnId
      }
      updatedData[key] = element
    }
  }

  return updatedData
}

export const PipelineContainer = () => {
  const dispatch = useDispatch()

  const { campaignId } = useSelector(currentOpenedCampaignSelector)
  const { board, isLoading } = useSelector(pipelineSelector)

  const [openModal, setOpenModal] = useState(false)
  const [currentColumn, setCurrentColumn] = useState(null)

  const onDragEnd = res => {
    const { destination, source, draggableId, type } = res

    if (!destination) return

    if (destination.droppableId === source.droppableId && destination.index === source.index) return

    if (type === 'COLUMN') {
      const payload = {
        method: 'change',
        campaignId,
        columnId: draggableId,
        position: destination.index + 1,
      }

      const newColumnOrder = Array.from(board.columnOrder)
      newColumnOrder?.splice(source.index, 1)
      newColumnOrder?.splice(destination.index, 0, draggableId)

      dispatch([
        persistColumnPipeline(newColumnOrder),
        addEditColumnPipeline({ ...payload, withoutRefetch: true }),
      ])
      return
    }

    if (type === 'TASK') {
      const toColumn = destination.droppableId
      const cardId = draggableId

      const updatedCardsData = findAndChangeCardId(cardId, toColumn, board?.cards)

      const payload = {
        method: 'change',
        campaignId,
        cardId,
        inColumnId: toColumn,
        disablePreloader: true,
      }

      dispatch([changeBoardCardsPipeline(updatedCardsData), addEditPipelineData(payload)])
    }
  }

  const onHandleOpenAddTaskModal = () => {
    setOpenModal(true)
  }

  const onHandleCloseAddTaskModal = () => {
    setOpenModal(false)
    setCurrentColumn(null)
  }

  const handleUpdateColumn = (newName, column) => {
    if (newName !== column.columnName) {
      dispatch(
        addEditColumnPipeline({
          columnId: column.columnId,
          campaignId,
          columnName: newName,
          method: 'change',
        })
      )
    }
  }

  const handleDeleteColumn = columnId => {
    dispatch(
      addEditColumnPipeline({
        columnId,
        method: 'remove',
      })
    )
  }

  const handleAddTask = creator => {
    dispatch(
      addEditCardTaskPipeline({
        method: 'add',
        inColumnId: currentColumn?.columnId,
        subText: creator?.subText,
        dueDate: creator?.due,
        assignedTo: creator?.assignedTo,
        cardId: creator?.cardId,
        creatorId: creator.creatorId,
        priority: 'none',
      })
    )
  }

  const handleDeleteTask = cardId => {
    dispatch(
      addEditCardTaskPipeline({
        method: 'remove',
        cardId,
      })
    )
  }

  const handleUpdateTask = (fields, column) => {
    const { subText, dueDate, assignedTo, priority, cardId, creatorId } = fields || {}
    const payload = {
      method: 'change',
      inColumnId: column?.columnId,
      cardId,
      creatorId,
      subText,
      dueDate,
      assignedTo,
      priority,
    }
    dispatch(addEditCardTaskPipeline(payload))
  }

  const handleActiveInCampaign = creatorId => {
    dispatch([
      changeBoardCardLoadingPipeline(true),
      changeCreator({
        creatorId,
        campaignId,
        isOnboarding: false,
        withPipeline: true,
      }),
    ])
  }

  return (
    <Container maxWidth={false} sx={{ height: 1 }} disableGutters>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="board" direction="horizontal" type="COLUMN">
          {provided => {
            return (
              <Stack
                {...provided.droppableProps}
                ref={provided.innerRef}
                spacing={1}
                direction="row"
                alignItems="flex-start"
                sx={{
                  minHeight: `calc(100vh - 247px)`,
                  height: 1,
                  overflowY: 'hidden',
                  msOverflowStyle: 'none',
                  scrollbarWidth: 'none',
                  overflowX: 'auto',
                  '&::-webkit-scrollbar': {
                    height: '5px',
                    borderRadius: '8px',
                    cursor: 'pointer',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                    borderRadius: '8px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#784E4E',
                    borderRadius: '8px',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    background: '#555',
                    borderRadius: '8px',
                  },
                }}
              >
                {isLoading || !board.columnOrder?.length ? (
                  <SkeletonPipelineColumn />
                ) : (
                  board.columnOrder?.map((columnId, index) => (
                    <PipelineColumn
                      index={index}
                      key={columnId}
                      column={board.columns[columnId]}
                      cards={board.cards}
                      campaignId={campaignId}
                      onHandleOpenAddTaskModal={onHandleOpenAddTaskModal}
                      onHandleCloseAddTaskModal={onHandleCloseAddTaskModal}
                      handleUpdateColumn={handleUpdateColumn}
                      handleDeleteColumn={() => handleDeleteColumn(columnId)}
                      handleAddTask={handleAddTask}
                      handleDeleteTask={handleDeleteTask}
                      handleUpdateTask={handleUpdateTask}
                      setCurrentColumn={setCurrentColumn}
                      handleActiveInCampaign={handleActiveInCampaign}
                    />
                  ))
                )}

                {provided.placeholder}
                <PipelineColumnAdd campaignId={campaignId} />
              </Stack>
            )
          }}
        </Droppable>
      </DragDropContext>
      <PipelineModalAddCreator
        openModal={openModal}
        closeModal={onHandleCloseAddTaskModal}
        handleAddTask={handleAddTask}
      />
    </Container>
  )
}
