export const GTAG_ID = 'AW-708812696'

export const GTAG_SCRIPT_SRC = `https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`

export const GA_EVENTS = {
  categories: {
    signupHq: 'signup_hq',
  },
  actions: {
    signupHq: 'signup_hq',
  },
}

export const GTAG_CONVERSION_LABEL = '__hqCPu-jP4CEJi__tEC'
