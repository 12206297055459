import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { withReduxActions } from '../hocs/withRedux'
import { logoutUser } from '../redux/ducks/userDucks'
import { throttle } from '../utils'

const maxAfkTime = 1000 * 60 * 60 * 3 // 3h
const checkUserActivityInterval = 1000 * 15 // 15sec

const UserActivityWatcher = ({ logoutUser }) => {
  useEffect(() => {
    const setLastChangedTime = throttle(() => {
      localStorage.setItem('lastUserActivityTime', Date.now().toString())
    }, 5000)

    setLastChangedTime()

    window.addEventListener('mousemove', setLastChangedTime)
    window.addEventListener('keydown', setLastChangedTime)

    const interval = setInterval(() => {
      const lastUserActivityTime = +localStorage.getItem('lastUserActivityTime')
      if (!lastUserActivityTime) return
      const logoutTime = lastUserActivityTime + maxAfkTime
      if (logoutTime <= Date.now()) logoutUser()
    }, checkUserActivityInterval)

    return () => {
      window.removeEventListener('mousemove', setLastChangedTime)
      window.removeEventListener('keydown', setLastChangedTime)
      localStorage.removeItem('lastUserActivityTime')
      clearInterval(interval)
    }
  }, [])

  return null
}

UserActivityWatcher.propTypes = {
  logoutUser: PropTypes.func.isRequired,
}

export default withReduxActions({ logoutUser })(UserActivityWatcher)
