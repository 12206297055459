import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import PieChart from '../../../charts/PieChart'
import useTranslation from '../../../../localization/useTranslation'
import disabledChartData from '../../../charts/disabledChartsData'
import { themeCommonStyles } from '../../../../themes/infludataThemes'
import { ChartTitle } from './styles'
import { Box } from '@mui/material'

const getMaleAndFemalePerc = genderMtoF => {
  if (Array.isArray(genderMtoF)) {
    return { malePerc: genderMtoF[0], femalePerc: genderMtoF[1] }
  }
  return { malePerc: genderMtoF.male, femalePerc: genderMtoF.female }
}

const AudienceGenderChart = ({ userindex = '', genderMtoF = [], isDisabledAnalysis = true, isForPdf = false, isSmall = false }) => {
  const { labelStrings, currentLang } = useTranslation()
  const isDataExist = obj => (Array.isArray(obj) && !!obj.length) || (obj.female && obj.male)

  const createAudienceGenderChartData = useCallback(() => {
    if (isDisabledAnalysis || !isDataExist(genderMtoF)) {
      return disabledChartData.disabledPieChartData({ items: 2 })
    }

    const { malePerc, femalePerc } = getMaleAndFemalePerc(genderMtoF)

    let colorFemale = themeCommonStyles.colors.salmon
    let colorMale = themeCommonStyles.getColorPaletteForPlatform(userindex).secondary

    const gendersMap = {
      female: { text: labelStrings.female, color: colorFemale },
      male: { text: labelStrings.male, color: colorMale },
    }

    return {
      values: [
        ['Type', 'Value'],
        [gendersMap.female.text.toUpperCase(), femalePerc],
        [gendersMap.male.text.toUpperCase(), malePerc],
      ],
      colors: [gendersMap.female.color, gendersMap.male.color],
    }
  }, [genderMtoF, isDisabledAnalysis, currentLang])

  return (
    <>
      {!isForPdf && (
        <Box sx={{ mb: isSmall ? '15px' : '40px' }}>
          <ChartTitle>{labelStrings.gender}</ChartTitle>
        </Box>
      )}
      <PieChart
        chartData={createAudienceGenderChartData()}
        title={isForPdf ? '' : labelStrings.gender}
        userindex={userindex}
        withCustomLegend
        isDisabledChart={isDisabledAnalysis}
        notEnoughData={!isDisabledAnalysis && !isDataExist(genderMtoF)}
        isForPdf={isForPdf}
      />
    </>
  )
}

AudienceGenderChart.propTypes = {
  userindex: PropTypes.string,
  genderMtoF: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isDisabledAnalysis: PropTypes.bool,
  isForPdf: PropTypes.bool,
  isSmall: PropTypes.bool,
}

export default AudienceGenderChart
