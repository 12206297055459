import { LoadingButton } from '@mui/lab'
import { Stack, MenuItem, Typography, Box, InputAdornment } from '@mui/material'
import React from 'react'
import { RHFTextField, RHFSelect } from '../../../components/hook-form'
import FormProvider from '../../../components/hook-form/FormProvider'
import { StyledButton } from './styles'
import { inputSymbol } from '../utils'
import useTranslation from '../../../../localization/useTranslation'

export const Fields = props => {
  const { labelStrings } = useTranslation()
  const { methods, onSubmit, handleSubmit, isSubmitting, handleClose, watch } = props

  const selectValue = watch('type')

  const handleKeyDown = event => {
    if (event.key === '@' || event.key === '#' || event.key === ' ') {
      event.preventDefault()
    }
  }
  const handleKeyDownWithSpace = event => {
    if (event.key === '@' || event.key === '#') {
      event.preventDefault()
    }
  }

  const selectOptions = [
    { value: 'mention', label: labelStrings.mention },
    { value: 'hashtag', label: '# Hashtag' },
    { value: 'text', label: labelStrings.freeText },
    { value: 'url', label: 'Link/Url (also parts)' },
  ]

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack
        direction="column"
        spacing={1.5}
        justifyContent="space-between"
        sx={{ my: 1, height: '290px', width: '100%' }}
      >
        <Box>
          <Stack>
            <RHFSelect
              name="type"
              label={labelStrings.selectType}
              InputLabelProps={{ shrink: true }}
            >
              {selectOptions?.map(currency => (
                <MenuItem key={currency.value} value={currency.value}>
                  {currency.label}
                </MenuItem>
              ))}
            </RHFSelect>
          </Stack>

          <Stack sx={{ mt: 4 }}>
            <RHFTextField
              name="value"
              label={labelStrings.inputSearchTerm}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{inputSymbol[selectValue]}</InputAdornment>
                ),
                onKeyDown: selectValue === 'text' ? handleKeyDownWithSpace : handleKeyDown,
              }}
              inputProps={{
                maxLength: 60,
              }}
            />
            <Stack sx={{ mt: 2, px: 1 }}>
              <Typography
                sx={{
                  fontSize: '14px',
                  color: '#637381',
                  code: { fontWeight: 700, fontSize: '16px', lineHeight: '18px' },
                }}
              >
                <code>#</code> or <code>@</code> {labelStrings.keywordsMentionInputHelperTextTop}
              </Typography>
              <Typography sx={{ fontSize: '14px', color: '#637381' }}>
                {labelStrings.keywordsMentionInputHelperTextMiddle} (e.g <code>"company.com/"</code>
                )
              </Typography>
              <Typography sx={{ fontSize: '14px', color: '#637381' }}>
                {labelStrings.keywordsMentionInputHelperTextBottom}
              </Typography>
            </Stack>
          </Stack>
        </Box>

        <Stack direction="row" alignItems="center" justifyContent="center">
          <StyledButton variant="outlined" sx={{ fontWeight: 'bold', mx: 1 }} onClick={handleClose}>
            {labelStrings.cancel}
          </StyledButton>

          <LoadingButton sx={{ mx: 1 }} type="submit" variant="contained" loading={isSubmitting}>
            {labelStrings.confirm}
          </LoadingButton>
        </Stack>
      </Stack>
    </FormProvider>
  )
}
