import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../../localization/useTranslation'
import {
  RatingDescriptionTipContainer,
  RatingDescriptionTipLabel,
  RatingDescriptionOverlay,
  RatingDescriptionMain,
  RatingDescriptionText,
} from './styles'

const RatingDescriptionTip = ({ scoreDesc = {
    main: '',
    text: '',
  } 
}) => {
  const { labelStrings } = useTranslation()
  const { main, text } = scoreDesc || {}

  return (
    <RatingDescriptionTipContainer>
      <RatingDescriptionTipLabel>{labelStrings.whatDoesThisScoreMean}?</RatingDescriptionTipLabel>
      <RatingDescriptionOverlay>
        <RatingDescriptionMain>{main}</RatingDescriptionMain>
        <RatingDescriptionText dangerouslySetInnerHTML={{ __html: text }} />
      </RatingDescriptionOverlay>
    </RatingDescriptionTipContainer>
  )
}

RatingDescriptionTip.propTypes = {
  scoreDesc: PropTypes.shape({
    main: PropTypes.string,
    text: PropTypes.string,
  }),
}

export default RatingDescriptionTip
