import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { SearchLabel } from '../styledWrappers'
import SingleCheckbox from './SingleCheckbox'
import {
  InstagramIcon,
  TikTokIcon,
  YoutubeIcon,
  LinkedInIcon,
  TwitterIcon,
  SnapchatIcon,
} from '../icons'
import LinkToFaqQuestion from '../../faqs/LinkToFaqQuestion'

const TitleWrapper = styled.div`
  width: fit-content;
  position: relative;
`

const MainWrapper = styled.div`
  margin: 20px 0 15px 0;
  position: relative;
`

const CheckboxGroupWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: ${props => props.theme.colors.darkBrown};
  width: 100%;
  justify-content: space-between;
`

const createSocialIcon = Icon => (
  <Icon tabIcon height="16px" width="16px" isActiveIcon={false} isGrey />
)

const SOCIALS_APPEAR_MAP = [
  // all possible options
  { label: 'Instagram', checked: false, value: 'instagram', icon: createSocialIcon(InstagramIcon) },
  { label: 'TikTok', checked: false, value: 'tiktok', icon: createSocialIcon(TikTokIcon) },
  { label: 'Twitter', checked: false, value: 'twitter', icon: createSocialIcon(TwitterIcon) },
  { label: 'Youtube', checked: false, value: 'youtube', icon: createSocialIcon(YoutubeIcon) },
  { label: 'LinkedIn', checked: false, value: 'linkedin', icon: createSocialIcon(LinkedInIcon) },
  { label: 'Snapchat', checked: false, value: 'snapchat', icon: createSocialIcon(SnapchatIcon) },
]

const CheckboxesGroupSocialAppear = ({
  handleCheckboxes,
  appearsOn,
  selectedPlatform = '',
  title = '',
  faqQuestionId = '',
}) => {
  // memoized checkboxes options func
  const createInfluencersApperarOnOptions = useCallback(() => {
    const optionalValues = ['instagram', 'tiktok', 'youtube']
    return SOCIALS_APPEAR_MAP.reduce((acc, el) => {
      if (optionalValues.includes(el.value) && el.value === selectedPlatform.toLowerCase()) {
        return acc
      }
      acc.push({ ...el, checked: appearsOn.includes(el.value) })
      return acc
    }, [])
  }, [appearsOn, selectedPlatform])

  const [currentState, changeCurrentState] = useState(createInfluencersApperarOnOptions())

  useEffect(() => {
    changeCurrentState(createInfluencersApperarOnOptions())
  }, [appearsOn, selectedPlatform])

  const handleOptionChange = elementlabel => ({ target: { checked } }) => {
    const updatedState = currentState.map(el => {
      if (el.label === elementlabel) return { ...el, checked }
      return el.label === elementlabel ? { ...el, checked } : el
    })
    changeCurrentState(updatedState)
    handleCheckboxes(
      updatedState.reduce((arr, el) => {
        if (el.checked) arr.push({ label: el.label, value: el.value })
        return arr
      }, [])
    )
  }

  return (
    <MainWrapper>
      <TitleWrapper>
        <SearchLabel>{title}</SearchLabel>
        <LinkToFaqQuestion
          faqQuestionId={faqQuestionId}
          linkPositions={{ top: 8, right: -28 }}
          tipIconSize={14}
        />
      </TitleWrapper>

      <CheckboxGroupWrapper>
        {currentState.map(element => {
          return (
            <SingleCheckbox
              key={element.label}
              onChange={handleOptionChange(element.label)}
              checked={element.checked}
              labelText={element.label}
              withIcon={!!element.icon}
              {...(element.icon && { Icon: element.icon })}
            />
          )
        })}
      </CheckboxGroupWrapper>
    </MainWrapper>
  )
}

CheckboxesGroupSocialAppear.propTypes = {
  handleCheckboxes: PropTypes.func,
  appearsOn: PropTypes.string.isRequired,
  selectedPlatform: PropTypes.string.isRequired,
  title: PropTypes.string,
  faqQuestionId: PropTypes.string,
}

export default CheckboxesGroupSocialAppear
