import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'
import CloseButton from '../buttons/CloseButton'
import PrimaryButton from '../buttons/PrimaryButton'
import CancelPrimaryButton from '../buttons/CancelPrimaryButton'
import { DialogBluredWrapper, DialogBluredTitle } from './dialogsCommonStyles'

import LoadingSpinner from '../spinners/LoadingSpinner'

const Wrapper = styled(DialogBluredWrapper)`
  max-width: 489px;
  padding-bottom: 10px;
  overflow: hidden;
`

const ButtonsWrapper = styled.div`
  ${props => props.theme.flex.centerAll}
  flex-direction: column;
`

const TipWrapper = styled.div`
  font-size: 15px;
  margin-bottom: 15px;
  margin-top: 30px;
  padding: 0 3%;
`

const ChangePasswordDialog = ({ close, confirm, isLoading = false }) => {
  const { labelStrings } = useTranslation()

  return (
    <Wrapper>
      <DialogBluredTitle noUpperCase>{labelStrings.changeMyPassword}</DialogBluredTitle>
      <CloseButton close={close} top="14px" right="14px" />

      <TipWrapper>{labelStrings.weWillSendPassChangeLink}</TipWrapper>

      <ButtonsWrapper>
        <PrimaryButton onClick={confirm} label={labelStrings.confirm} isBig />
        <CancelPrimaryButton onClick={close} />
      </ButtonsWrapper>

      {isLoading && <LoadingSpinner isTransparent height="100%" width="100%" />}
    </Wrapper>
  )
}

ChangePasswordDialog.propTypes = {
  close: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

export default ChangePasswordDialog
