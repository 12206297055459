import React from 'react'
import PropTypes from 'prop-types'
import { useDrag, useDrop } from 'react-dnd'
import { DragNDropIcon, CloseIcon } from '../../../common/icons/icons'
import { ButtonIconWrapper } from '../commonStyles'
import { SelectOptionItem, SelectOptionInput } from './styles'

const USER_NOTE_SELECT_OPTION = 'USER_NOTE_SELECT_OPTION'

const DraggableSelectOption = ({
  option,
  index,
  moveOptionByDragNDrop,
  changeOptionById,
  deleteOptionById,
}) => {
  const dropRef = React.useRef(null)
  const dragRef = React.useRef(null)

  const [{ handlerId }, drop] = useDrop({
    accept: USER_NOTE_SELECT_OPTION,
    collect: monitor => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item, monitor) => {
      if (!dropRef.current) return

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) return

      const hoverBoundingRect = dropRef.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return

      moveOptionByDragNDrop(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: USER_NOTE_SELECT_OPTION,
    item: () => ({
      id: option.id,
      index,
    }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  preview(drop(dropRef))
  drag(dragRef)

  const opacity = isDragging ? 0 : 1

  const onChange = event => changeOptionById(option.id, event.target.value)
  const onDelete = () => deleteOptionById(option.id)

  return (
    <SelectOptionItem ref={dropRef} id={option.id} data-handler-id={handlerId} style={{ opacity }}>
      <ButtonIconWrapper ref={dragRef} isDragButton>
        <DragNDropIcon />
      </ButtonIconWrapper>

      <SelectOptionInput value={option.value} onChange={onChange} autoComplete="off" />

      <ButtonIconWrapper onClick={onDelete}>
        <CloseIcon />
      </ButtonIconWrapper>
    </SelectOptionItem>
  )
}

DraggableSelectOption.propTypes = {
  option: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  moveOptionByDragNDrop: PropTypes.func,
  changeOptionById: PropTypes.func,
  deleteOptionById: PropTypes.func,
}

export default DraggableSelectOption
