import styled from 'styled-components'

export const CollectionSearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 208px;
  width: 100%;
  min-height: 40px;
  height: auto;
  margin-right: ${props => props.collectionViewMode ? '21px' : '0px'};
  border: 1px solid #784e4e;
  background-color: white;
  border-radius: 8px;
  position: relative;
`
export const CollectionSearchInput = styled.input`
  padding-left: 10px;
  width: 100%;
  border: none;
  &:focus {
    outline: none;
  }
`
export const SearchIconWrapper = styled.div`
  padding-right: 10px;
  & svg {
    fill: ${props => props.theme.color.darkBrown};
  }
`
export const ClearInputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  height: inherit;
  width: 10px;
  font-size: 20px;
  color: ${props => props.theme.color.darkBrown};
  font-family: ${props => props.theme.fontFamilyInter};
  &::before {
    display: block;
    content: '+';
    transform: rotateZ(45deg);
    cursor: pointer;
    font-size: 25px;
  }
`