import styled from 'styled-components'
import { Divider } from './index'

export const ListWrapper = styled.div`
  margin: 20px auto 20px 0;
  display: flex;
  width: 100vw;
  min-width: 1100px;
  overflow: scroll;
  height: 90%;
  border: 1px solid ${props => props.theme.color.mineShaft20};
  border-top: none;
  ${props => props.theme.scrollbarStyles};
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #784e4e;
    border-radius: 5px;
    border: 2px solid #eed9d9;
  }
  &::-webkit-scrollbar-thumb:hover {
    border: 0px solid #f4f4f4;
  }
  &::-webkit-scrollbar-track-piece:start {
    margin-left: 10px;
  }
  &::-webkit-scrollbar-track-piece:end {
    margin-right: 10px;
  }
  @media (max-width: 1100px) {
    margin: 0 10px;
  }
`
export const ComparedColumnWrapper = styled.div`
  
  width: 270px;
  min-width: 270px;
  //
  // height: 5000px;
  height: fit-content;
  // border-right: 1px solid ${props => props.theme.color.mineShaft20};
  background: #FFF8F7;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 5px;
  margin-bottom: 50px;
  & > * {
    font-family: ${props => props.theme.fontFamilyInter};
  }
  & .loading_spinner_wrapper {
    z-index: 200;
  }
  & .loading_spinner_wrap {
    align-self: flex-start;
    margin-top: 300px;
    position: sticky;
    position: -webkit-sticky;
    top: 300px;
  }
`

export const ComparedNavColumnWrapper = styled(ComparedColumnWrapper)`
  width: 295px;
  min-width: auto;
  margin: 0;
  margin-bottom: 50px;
  margin-right: 5px;
  position: sticky;
  position: -webkit-sticky;
  width: fit-content;
  top: 0;
  left: 0;
  z-index: 50;
  // background: ${props => props.theme.color.midGrey};
  border-radius: 0px 16px 0px 0px;
  // border-right: 1px solid ${props => props.theme.color.mineShaft50};
`

export const ComparedVerticalStickedWrapper = styled.div`
  position: sticky;
  position: -webkit-sticky;
  height: 130px;
  width: 100%;
  top: 0;
  z-index: 10;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
`

export const NavColumnSection = styled.div`
  ${props => props.theme.flex.centerAll}
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${props =>
    props.isMocked
      ? props.theme.color.midGrey
      : props.isNav
      ? props.theme.color.provincialPink
      : props.theme.pickColorDependOnPlatform(props.index).comparedBG};
  ${props =>
    props.isNav
      ? `
    border-top-right-radius: 16px;
  `
      : `
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  `};

  ${props => props.justifySpace && `justify-content: space-between; padding: 10px 0 20px 0;`}
  ${props =>
    !props.isMocked &&
    !props.isNav &&
    `
    transition: all 0.2s ease;
    cursor: pointer;
    &:hover {
      background: ${props.theme.pickColorDependOnPlatform(props.index).comparedHoveredBG};
    }
  `}
`

export const Fonted = styled.div`
  font-family: ${props => props.theme.fontFamilyInter};
  color: ${props => props.theme.color.mineShaft};
  font-size: 15px;
`

export const AvatarWrapper = styled.div`
  height: fit-content;
  width: 100%;
  margin: 8px 0;
  // height: 70px;
  cursor: pointer;
  ${props => props.theme.flex.centerAll}
`

export const LinkStyled = styled(Fonted)`
  color: ${props => props.theme.pickColorDependOnPlatform(props.userindex).primary};
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  width: fit-content;
  margin: 0 auto;

  &:hover {
    opacity: 0.7;
  }
  ${props =>
    props.isMocked &&
    `
    color: ${props.theme.color.lightGrey};
    background: ${props.theme.color.lightGrey};
  `}
`

export const RemoveComparedWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 8px;
  z-index: 50;
  & svg {
    height: 30px;
    width: 30px;
    fill: ${props => props.theme.pickColorDependOnPlatform(props.index).light};
  }
  transition: all 0.2s ease;
  &:hover {
    transform: scale(1.05);
  }
`

export const ComparedRow = styled(Fonted)`
  width: 100%;
  height: ${props => props.customHeight || 42}px;
  color: ${props => (props.isNav ? props.theme.color.grey : props.theme.color.mineShaft)};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 10px;
  ${props => !!props.customHeight && props.withMargin && 'margin: 8px 0;'}
  ${props =>
    props.customHeight &&
    !props.isNav &&
    `
    // text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    // white-space: nowrap;
    text-align: center;
  `}
 ${props =>
   props.isNav &&
   `
    justify-content: flex-end;
    text-align: right;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
 `}
 ${props => props.isBold && 'font-weight: bold;'}
 ${props => props.withBG && `background: ${props.theme.color.fairPink};`}
 ${props =>
   props.isLink &&
   `
  cursor: pointer;
  color: ${props.theme.pickColorDependOnPlatform(props.index).primary};
  text-decoration: underline;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  padding: 4% 0;
  padding-left: 1px;
  &:hover {
    opacity: 0.7;
  }
 `}
 ${props =>
   props.withStartSection &&
   `
    border-top: 1px solid ${
      props.isNav ? props.theme.pickColorDependOnPlatform(props.index).comparedBG : '#F2DCDC'
    };
    ${props.isNav &&
      `
      &::before {
        position: absolute;
        background: #FFF8F7;
        content: '${props.startSectionLabel}';
        left: 0;
        top: ${props.customHeight ? '-10%' : '-33%'};
        // top: calc(100% - 115%);
        padding: 5px 20px;
        color: ${
          props.isMocked
            ? props.theme.color.mineShaft
            : props.theme.pickColorDependOnPlatform(props.index).primary
        }
      }
    `}
 `};
 ${props =>
   props.isMocked &&
   `
  opacity: 0.3;
  
  &::after {
    position: absolute;
    content: '';
    height: 49%;
    width: 100%;
    top: 17%;
    left: 0;
    background: ${props.theme.color.grey};
    // z-index: 2;
  }
 `};
`

export const SubSectionsDivider = styled(Divider)`
  position: absolute;
  bottom: 0;
  border: 1px solid ${props => props.theme.pickColorDependOnPlatform(props.index).comparedBG};
  width: 80%;
`

export const BubbledText = styled.div`
  background: ${props =>
    props.rank !== undefined ? props.theme.rankColors[props.rank || 0] : 'transparent'};
  margin: 0 auto;
  padding: 2px 8px;
  border-radius: 13px;
  font-weight: 600;
  text-align: center;
  position: relative;
  ${props => props.withMinWidth && `min-width: 98px;`}
  ${props => props.theme.flex.centerAll}
  ${props =>
    // STAR FOR BEST VALUE
    props.rank === props.theme.rankColors.length - 1 &&
    `
    &::before {
      position: absolute;
      content: '🌟';
      color: ${props.theme.color.yellow};
      left: -25px;
      font-size: 16px;
    }
  `} 
 
`

export const QualitySubScoreWrapper = styled.div`
  ${props => props.theme.flex.centerAll}
  width: 100%;
  padding-left: 15px;
  justify-content: flex-start;
`

export const QualitySubScoreValue = styled(BubbledText)`
  margin: 0 5px;
  white-space: nowrap;
  background: ${props => {
    if (typeof props.rank !== 'number') return 'transparent'
    return props.theme.rankColors[props.rank]
  }};
`

export const QualitySubScoreLabel = styled.span`
  font-size: 13px;
  line-height: 15px;
  text-transform: capitalize;
`

export const OuterAudienceLockedWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  ${props => props.theme.flex.centerAll};
  flex-direction: column;
  height: 100%;
`

export const AudienceLockedWrapper = styled.div`
  // overflow: hidden;

  border-radius: 9px;
  height: 38px;
  width: fit-content;
  position: relative;
  transition: all 0.2s ease;
  ${props => props.theme.flex.centerAll};
  border: 1px solid ${props => props.theme.pickColorDependOnPlatform(props.index).primary};
  &:hover {
    opacity: 0.7;
  }
  &:active {
    background: ${props => props.theme.color.white};
    & > * {
      opacity: 0.5;
    }
  }
  cursor: pointer;
  margin: 15px auto 10px auto;
`

export const IconWrapper = styled.div`
  padding: 6px;
  background: ${props => props.theme.pickColorDependOnPlatform(props.index).primary};
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  & svg {
    width: 28px;
    height: 26px;
  }
`

export const LabelWrapper = styled.div`
  text-transform: uppercase;
  color: ${props => props.theme.pickColorDependOnPlatform(props.index).primary};
  font-weight: bold;
  font-size: 13px;
  max-width: 100px;
  text-align: center;
  line-height: 13px;
`

export const AvailableReport = styled.div`
  color: #673c3b;
  font-size: 11px;
  text-transform: uppercase;
  &:hover {
    opacity: 1;
  }
`

export const ProfileCategory = styled.span`
  color: ${props => props.theme.color.mineShaft};
  font-size: 12px;
  line-height: 10px;
  height: 10px;
`
