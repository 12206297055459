import React, { useEffect, useState } from 'react'
import { Button, Chip, Stack, TextField, Typography } from '@mui/material'
import Scrollbar from '../../../components/scrollbar/Scrollbar'
import useTranslation from '../../../../localization/useTranslation'

export const StepEight = props => {
  const { setValue, getValues, isSubmitting } = props

  const { labelStrings } = useTranslation()

  const [typeName, setTypeName] = useState('')
  const [typeNames, setTypeNames] = useState([])

  const valueFromFormState = getValues('trackedMetrics')

  const onChangeTypeName = event => {
    const { target } = event
    setTypeName(target.value)
  }

  const onAddTypeNames = () => {
    const updatedValue = [...typeNames, { type: 'text', value: typeName }]
    setTypeNames(updatedValue)
    setValue('trackedMetrics', updatedValue)
    setTypeName('')
  }

  const onRemoveTypeName = removeItem => {
    const updatedValue = typeNames?.filter(
      item => item.value !== removeItem.value || item.type !== removeItem.type
    )
    setTypeNames(updatedValue)
    setValue('trackedMetrics', updatedValue)
  }

  useEffect(() => {
    setTypeNames(valueFromFormState)
  }, [valueFromFormState?.length])

  return (
    <Stack spacing={1.5} alignItems="center">
      <Typography
        align="center"
        component="label"
        sx={{
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '24px',
          whiteSpace: 'pre-line',
          px: 2,
          mb: 1,
        }}
      >
        {labelStrings.createCampaign.steps.eight.label}
      </Typography>
      <Stack spacing={1} direction="row" alignItems="center" sx={{ width: '100%', px: 4 }}>
        <TextField
          name="value"
          label={labelStrings.createCampaign.steps.eight.fieldLabel}
          variant="outlined"
          disabled={isSubmitting}
          value={typeName}
          onChange={onChangeTypeName}
          inputProps={{
            maxLength: 60,
          }}
          sx={{ width: '100%' }}
        />
        <Button disabled={!typeName || isSubmitting} onClick={onAddTypeNames}>
          {labelStrings.createCampaign.add}
        </Button>
      </Stack>

      <Scrollbar
        sx={{
          maxHeight: '250px',
          width: '525px',
        }}
      >
        <Stack
          direction="row"
          sx={{ width: '100%', flexWrap: 'wrap', gap: 1, px: 3.5 }}
          justifyContent="start"
        >
          {typeNames?.map((item, idx) => {
            if (item.type !== 'text') return
            return (
              <Chip
                sx={{
                  borderColor: 'transparent',
                  backgroundColor: '#F4F6F8',
                  py: 3.5,
                  px: 1,
                  width: 'fit-content',
                  fontWeight: 'bold',
                  '.MuiChip-avatar': { width: '36px', height: '36px', mr: 0.5 },
                }}
                key={idx}
                label={item.value}
                variant="outlined"
                onDelete={() => onRemoveTypeName(item)}
              />
            )
          })}
        </Stack>
      </Scrollbar>
      <Typography
        sx={{
          color: '#637381',
          lineHeight: 1.5,
          fontSize: `0.75rem`,
          fontFamily: `Public Sans,sans-serif`,
          fontWeight: 400,
          marginTop: 2,
          marginRight: `14px`,
          marginBottom: 0,
          marginLeft: `14px`,
          textAlign: 'center',
          whiteSpace: 'pre-line',
        }}
      >
        {labelStrings.createCampaign.steps.eight.helperText}
      </Typography>
    </Stack>
  )
}
