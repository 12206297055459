import React from 'react'
import { ModalComponent } from '../../../components/modal/ModalComponent'
import { Box, Button, MenuItem, Stack, TextField, Typography } from '@mui/material'
import useTranslation from '../../../../localization/useTranslation'

export const ContentMatchModal = props => {
  const {
    showChangeMatchedModal,
    setShowChangeMatchedModal,
    matchedValue,
    handleChangeMatchedValue,
    handleChangeMatchedContent,
    matchedModalData,
  } = props
  const { labelStrings, currentLang } = useTranslation()

  return (
    <ModalComponent
      isOpenModal={showChangeMatchedModal}
      closeModal={() => setShowChangeMatchedModal(false)}
      title={labelStrings.campaignCreatorOverview.matchModal.title}
      contentWidth={currentLang === 'de' ? '650px' : '600px'}
      contentHeight={'380px'}
      isCenterTitle
    >
      <Stack spacing={2} justifyContent="center" alignItems="center">
        <Typography sx={{ whiteSpace: 'pre-line', fontSize: '0.85rem' }} textAlign="center">
          {labelStrings.campaignCreatorOverview.matchModal.contentTitle}
        </Typography>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 3 }}>
          <TextField
            fullWidth
            select
            label={labelStrings.campaignCreatorOverview.matchModal.selectTitle}
            value={matchedValue}
            onChange={handleChangeMatchedValue}
            size="small"
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  sx: {
                    maxHeight: 260,
                  },
                },
              },
            }}
            sx={{
              maxWidth: { sm: 280 },
              textTransform: 'capitalize',
            }}
          >
            <MenuItem
              value={'phrase'}
              sx={{
                mx: 1,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {labelStrings.campaignCreatorOverview.matchModal.selectOptionsTitles.phrase}
            </MenuItem>
            <MenuItem
              value={'all'}
              sx={{
                mx: 1,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {labelStrings.campaignCreatorOverview.matchModal.selectOptionsTitles.all}
            </MenuItem>
            <MenuItem
              value={'none'}
              sx={{
                mx: 1,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {labelStrings.campaignCreatorOverview.matchModal.selectOptionsTitles.none}
            </MenuItem>
          </TextField>
        </Box>
        <Button
          sx={{ maxWidth: '240px' }}
          variant="contained"
          onClick={() => {
            handleChangeMatchedContent({ matchedValue, creatorId: matchedModalData?.id })
            setShowChangeMatchedModal()
          }}
        >
          {labelStrings.confirm}
        </Button>
      </Stack>
    </ModalComponent>
  )
}
