import React from 'react'
import PropTypes from 'prop-types'
import DraggableSelectOption from './DraggableSelectOption'
import { SelectOptionsContainer } from './styles'

const SelectOptions = ({ options, moveOptionByDragNDrop, changeOptionById, deleteOptionById }) => {
  return (
    <SelectOptionsContainer>
      {options.map((option, index) => {
        return (
          <DraggableSelectOption
            key={option.id}
            option={option}
            index={index}
            moveOptionByDragNDrop={moveOptionByDragNDrop}
            changeOptionById={changeOptionById}
            deleteOptionById={deleteOptionById}
          />
        )
      })}
    </SelectOptionsContainer>
  )
}

SelectOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  moveOptionByDragNDrop: PropTypes.func,
  changeOptionById: PropTypes.func,
  deleteOptionById: PropTypes.func,
}

export default SelectOptions
