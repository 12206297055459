import React from 'react'
import { ListItemIcon, MenuItem, Typography } from '@mui/material'
import MenuPopover from '../../components/menu-popover'
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import useTranslation from '../../../localization/useTranslation'

export const PopoverItemMenu = props => {
  const { openPopover, handleClosePopover, onEditRow, onDeleteRow } = props
  const { labelStrings } = useTranslation()

  return (
    <>
      <MenuPopover open={openPopover} onClose={handleClosePopover} arrow="right-top">
        <MenuItem
          onClick={() => {
            onEditRow()
            handleClosePopover()
          }}
        >
          <ListItemIcon
            sx={{
              mx: 0,
              minWidth: '30px !important',
              svg: {
                mr: '0 !important',
              },
            }}
          >
            <ModeEditOutlineRoundedIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">{labelStrings.edit}</Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            onDeleteRow()
            handleClosePopover()
          }}
        >
          <ListItemIcon
            sx={{
              mx: 0,
              minWidth: '30px !important',
              svg: {
                mr: '0 !important',
              },
            }}
          >
            <DeleteForeverRoundedIcon />
          </ListItemIcon>
          <Typography variant="inherit">{labelStrings.delete}</Typography>
        </MenuItem>
      </MenuPopover>
    </>
  )
}
