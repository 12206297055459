import React, { useMemo } from 'react'
import { Stack, Typography, Box, Avatar, Divider } from '@mui/material'
import EmvChart from '../../../../../qualityScores/EmvChart'
import useTranslation from '../../../../../../localization/useTranslation'
import { prepareProfileChartData } from '../../../../../../utils/profileCharts'
import { defaultEmvChartData } from '../../../../infludataQualityRating'
import { getUrlWithProxy, pretifyBigNum } from '../../../../../../utils'
import { FollowerChartPdf } from '../FollowerChartPdf'
import { CreatorsCard } from './subpages/CreatorsCards'
import { MiddleChartSection } from './subpages/MiddleChartSection'
import { TopThreeBrandsAmbassadors } from './subpages/TopThreeBrandsAmbassadors'
import { PDF_INFLUDATA_LOGO } from '../../../../../common/icons'
import { gradientTextSVG, modifyArrayLength } from '../utils'


export const FirstPagePdf = props => {
  const { profile, currency } = props || {}

  const { labelStrings } = useTranslation()

  const { username, followers, profilePicURL, creatorCollaborations, dataLog, _index } =
    profile || {}

  const { metadata, mentionAccountsAndTimeline } = creatorCollaborations || {}
  const emvValue = metadata?.totalMediaValue

  const emvChartData = defaultEmvChartData?.map(item => ({
    ...item,
    isActive: emvValue >= item.value,
  }))

  const followersChartData = useMemo(() => prepareProfileChartData(dataLog, _index), [
    dataLog,
    _index,
  ])

  return (
    <>
      {/* header section */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 3, mt: 1 }}
      >
        <Stack sx={{ mb: 1 }}>
          <Typography
            sx={{
              fontSize: '50px',
              fontWeight: 700,
              fontFamily: 'Inter',
              color: '#784E4E',
              lineHeight: '78px',
              marginBottom: '20px'
            }}
          >
            Brand Performance - {username}
          </Typography>
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 600,
              fontFamily: 'Inter',
              color: '#784E4E',
              lineHeight: '5px',
            }}
          >
            Creator Marketing Analytics on Instagram - last 12 months
          </Typography>
        </Stack>
        <Box sx={{ width: '214px', height: '77px' }}>
          <PDF_INFLUDATA_LOGO />
        </Box>
      </Stack>
      {/*  */}

      {/* avatar section         */}
      <Stack sx={{ mb: 3 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: '#FFF',
            border: '1px solid #F4E8E8',
            borderRadius: '24px',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              position: 'relative',
              '&::after': {
                content: '" "',
                marginLeft: '8px',
                borderLeft: '1px solid #F4E8E8',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: '100%',
              },
            }}
          >
            <Stack spacing={2} direction="row" alignItems="center" sx={{ p: 2 }}>
              <Box>
                <Avatar
                  src={getUrlWithProxy(profilePicURL)}
                  sx={{ width: '180px', height: '180px' }}
                />
              </Box>
              <Stack sx={{ mb: '16px !important' }}>
                <Typography
                  sx={{
                    color: '#442424',
                    fontWeight: 700,
                    fontSize: '30px',
                  }}
                >
                  @{username}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography
                    sx={{
                      color: '#442424',
                      fontWeight: 700,
                      fontSize: '25px',
                    }}
                  >
                    {pretifyBigNum(followers)}
                  </Typography>
                  <Typography
                    sx={{
                      color: '#442424',
                      fontWeight: 400,
                      fontSize: '25px',
                    }}
                  >
                    {labelStrings?.followers}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>

          <Stack
            alignItems="center"
            sx={{
              width: '100%',
              position: 'relative',
              '&::after': {
                content: '" "',
                borderLeft: '1px solid #F4E8E8',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: '100%',
              },
            }}
          >
            <Box>
              <FollowerChartPdf
                chartData={followersChartData?.followers || []}
                platform={profile?._index}
                monthlyGrowthFollowers={[
                  profile.monthlyGrowthObject?.monthlyDifference || 0,
                  profile.monthlyGrowthObject?.monthlyChange || 0,
                ]}
              />
            </Box>
          </Stack>

          <Stack alignItems="center" justifyContent="space-between" sx={{ width: '100%', py: 2 }}>
            <Stack alignItems="center" sx={{ pb: 1 }}>
              <Typography
                sx={{
                  fontSize: '14px',
                  color: '#BC9797',
                  lineHeight: 'initial',
                }}
              >
                Earned Media Value (EMV)
              </Typography>
              <Typography
                sx={{
                  color: '#442424',
                  fontWeight: 'bold',
                  fontSize: '40px',
                  lineHeight: 'initial',
                }}
              >
                {currency} {pretifyBigNum(emvValue)}{' '}
              </Typography>
            </Stack>

            <Box
              sx={{
                width: '100%',
                '& div': {
                  marginBottom: 0,
                },
                position: 'relative',
                '&::before': {
                  content: '" "',
                  border: '1px solid #F4E8E8',
                  position: 'absolute',
                  top: '-10px',
                  left: 0,
                  width: '100%',
                },
              }}
            >
              <Box sx={{ pt: 1 }}>
                <EmvChart benchmarkArray={emvChartData} />
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Stack>
      {/*  */}

      <Stack>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 700,
            fontFamily: 'Inter',
            color: '#784E4E',
            lineHeight: 'initial',
            mb: 2,
          }}
        >
          {labelStrings?.brandsTimelineText?.creatorsThatMentionedTheBrand}
        </Typography>

        <Stack>
          <CreatorsCard
            username={username}
            currency={currency}
            mentionAccountsAndTimeline={mentionAccountsAndTimeline}
            metadata={metadata}
          />
        </Stack>
      </Stack>

      <Divider color="#FFF" sx={{ height: '2px', my: 2 }} />

      <Stack>
        <MiddleChartSection
          metadata={metadata}
          platform={_index}
          creatorsCount={mentionAccountsAndTimeline?.length}
        />
      </Stack>

      <Divider color="#FFF" sx={{ height: '2px', my: 2 }} />

      <Stack>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 700,
            fontFamily: 'Inter',
            color: '#784E4E',
            lineHeight: 'initial',
          }}
        >
          {`TOP THREE BRAND AMBASSADORS (LAST 365 DAYS)`}
        </Typography>
        <TopThreeBrandsAmbassadors
          currentTimelineChartData={modifyArrayLength(mentionAccountsAndTimeline, 3)}
          timelineChartCreatorsCount={3}
          dateStart={metadata.dateStart}
          dateEnd={metadata.dateEnd}
          timelineDataCount={mentionAccountsAndTimeline?.length}
          mentionAccountsAndTimeline={mentionAccountsAndTimeline}
        />
      </Stack>
    </>
  )
}
