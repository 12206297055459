import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import lottie from 'lottie-web'
import { generateRandomId } from '../../../utils'
import spinnerAnimation from './spinnerAnimation.json'
import { OverlayContainer, SpinnerWrapper } from './commonStyles'

const Spinner = ({ size = '100px', isOverlay = false, color = '', overlayBgColor = '' }) => {
  const spinnerId = generateRandomId()

  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector(`#${spinnerId}`),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: spinnerAnimation,
    })
  }, [])

  const spinnerComponent = <SpinnerWrapper id={spinnerId} size={size} color={color} />

  if (isOverlay) {
    return (
      <OverlayContainer overlayBgColor={overlayBgColor}>
        {spinnerComponent}
      </OverlayContainer>
    )
  }
  return spinnerComponent
}

Spinner.propTypes = {
  size: PropTypes.string,
  isOverlay: PropTypes.bool,
  color: PropTypes.string,
  overlayBgColor: PropTypes.string,
}

export default Spinner
