import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useTranslation from '../../../../localization/useTranslation'
import { selectedProfileSelector } from '../../../../redux/selectors'
import InfludataQualityRating from '../../infludataQualityRating'
import Activity from '../../detailedInfludataRating/Activity'
import { Section, SectionTitle } from '../commonStyles'
import PageLayout from '../PageLayout/PageLayout'

const IgPageQuialityRating = ({ isBranded = true }) => {
  const { profile } = useSelector(selectedProfileSelector)
  const { _id: profileId, username, _index: platform, profileScore } = profile || {}

  const { labelStrings } = useTranslation()

  return (
    <PageLayout
      profileId={profileId}
      username={username}
      platform={platform}
      isBranded={isBranded}
    >
      <Section hasBorderBottom>
        <SectionTitle platform={platform}>{labelStrings.infludataQualityRating}</SectionTitle>
        <InfludataQualityRating
          overallScore={profileScore.overallScore}
          platform={platform}
          isForPdf
        />
      </Section>

      <Section>
        <SectionTitle platform={platform}>{labelStrings.detailedQualityRating}</SectionTitle>
        <Activity platform={platform} profileScores={profileScore} isForPdf />
      </Section>
    </PageLayout>
  )
}

IgPageQuialityRating.propTypes = {
  isBranded: PropTypes.bool,
}

export default IgPageQuialityRating
