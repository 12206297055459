import React, { useEffect } from 'react'
import { ModalComponent } from '../../components/modal/ModalComponent'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fields } from './Form/Fields'
import { EditMentionsKeywordsSchema } from './Form/FormSchema.js'
import { inputSymbol } from './utils'
import useTranslation from '../../../localization/useTranslation'

export const EditItem = props => {
  const {
    openEditFieldModal,
    setOpenEditFieldModal,
    editField,
    setEditField,
    onEdit,
    onCreate,
  } = props
  const { labelStrings } = useTranslation()

  const methods = useForm({
    resolver: yupResolver(EditMentionsKeywordsSchema),
    defaultValues: {
      value: '',
      type: '',
    },
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
    watch,
  } = methods

  const handleClose = () => {
    reset({
      value: '',
      type: ''
    })
    setOpenEditFieldModal(false)
    setEditField(null)
  }

  const onSubmit = async data => {
    const action = editField?.metricId ? onEdit : onCreate
    try {
      await new Promise(resolve => setTimeout(resolve, 500))
      action({
        ...data,
        value: `${inputSymbol[data.type] || ''}${data.value}`,
        metricId: editField?.metricId,
      })
      handleClose()
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    reset({
      value: editField?.value?.replace(/[@#]/g, ''),
      type: editField?.type,
    })
  }, [editField])

  return (
    <ModalComponent
      isOpenModal={openEditFieldModal}
      closeModal={handleClose}
      title={`${editField?.metricId ? labelStrings.editA : labelStrings.addNew} ${labelStrings.searchTerm}`}
      contentWidth="479px"
      contentHeight="420px"
    >
      <Fields
        onSubmit={onSubmit}
        editField={editField}
        handleSubmit={handleSubmit}
        methods={methods}
        isSubmitting={isSubmitting}
        handleClose={handleClose}
        watch={watch}
      />
    </ModalComponent>
  )
}
