import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Stack,
  Avatar,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  Tooltip
} from '@mui/material'
import Iconify from '../../../components/iconify'
import { PopoverItemMenu } from '../PopoverItemMenu'
import { USER_INDEXES } from '../../../../constants/appSettings'
import useTranslation from '../../../../localization/useTranslation'


const INITIAL_INPUTS_STATE = {
  collaborationPrice: 0,
  additionalCosts: 0,
  priceForPost: 0,
  priceForReel: 0,
  priceForStory: 0,
  priceForTikTok: 0,
  priceForYTShort: 0,
  priceForYTVideo: 0,
}

export const CpmsUserTableRow = ({
  row,
  onEditRow,
  onArchiveRow,
  onAddRemoveBrandAccount,
  handleViewEditCreator,
  onAddNonRecognizedContent,
  setMatchedModalData,
  onViewCreatorAnalytics,
  onActivateCreator,
  currency,
  onEditCreator,
}) => {
  const { name, avatarUrl, platform, status, isBrandAccount, fromCampaignData } = row || {}

  const { pricing } = fromCampaignData || {}

  const { labelStrings } = useTranslation()

  const [openPopover, setOpenPopover] = useState(null)

  const [inputsState, setInputsState] = useState(INITIAL_INPUTS_STATE)
  const [prevInputsState, setPrevInputsState] = useState(INITIAL_INPUTS_STATE)

  const isDisabledPriceForCollaboration = Object.keys(inputsState).some(
    key => key !== 'collaborationPrice' && inputsState[key]
  )
  const isDisableAllFieldsExceptPriceForCollaboration = !!inputsState.collaborationPrice

  const isInstagramValue =
    inputsState.priceForPost || inputsState.priceForReel || inputsState.priceForStory
  const isYouTubeValue = inputsState.priceForYTVideo || inputsState.priceForYTShort
  const isTikTokValue = inputsState.priceForTikTok

  useEffect(() => {
    if (pricing) {
      setInputsState(pricing)
      setPrevInputsState(pricing)
    }
  }, [pricing])

  const handleOpenPopover = event => {
    setOpenPopover(event.currentTarget)
  }

  const handleClosePopover = () => {
    setOpenPopover(null)
  }

  const onChangeInputsPrice = ({ target }) => {
    const { id, value } = target || {}
    setInputsState({
      ...inputsState,
      [id]: Number(value),
    })
  }

  const onHandleBlurEditField = ({ target }) => {
    const { id, value } = target || {}

    if (!value || Number(value) === Number(prevInputsState[id])) return
    setPrevInputsState({
      ...prevInputsState,
      [id]: Number(value),
    })

    onEditCreator(
      {
        pricing: {
          ...inputsState,
          [id]: Number(value),
        },
        enablePricing: true,
      },
      row.id,
      row,
      true
    )
  }
  const borderStyle = {
    borderRight: '0.5px solid grey',
  }

  return (
    <>
      <TableRow
        hover
        sx={{
          backgroundColor: isBrandAccount ? '#DEFFD8' : 'unset',
          '&:hover': {
            backgroundColor: isBrandAccount ? '#c7e5c2 !important' : 'unset',
          },
        }}
      >
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={name} src={avatarUrl} />

            <Typography variant="subtitle2" noWrap>
              {name}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {platform}
        </TableCell>

        <TableCell align="left" style={borderStyle}>
          <Tooltip title={isDisabledPriceForCollaboration ? labelStrings.disabledText : ""}>
            <div style={{ width: '100%' }}>
              <TextField
                id="collaborationPrice"
                size="small"
                type="number"
                value={inputsState.collaborationPrice || ''}
                onChange={onChangeInputsPrice}
                onBlur={onHandleBlurEditField}
                InputProps={{
                  endAdornment: inputsState.collaborationPrice ? <InputAdornment position="end">{currency}</InputAdornment> : null
                }}
                sx={{
                  '.MuiTypography-root': { lineHeight: 'inherit' },
                  backgroundColor: isDisabledPriceForCollaboration ? '#F4F6F8' : 'inherit',
                  borderRadius: isDisabledPriceForCollaboration ? '8px' : 'inherit',
                }}
                disabled={isDisabledPriceForCollaboration}
              />
            </div>
          </Tooltip>
        </TableCell>


        <TableCell align="left">
          <Tooltip title={isDisableAllFieldsExceptPriceForCollaboration ? labelStrings.disabledText : ""}>
            <div style={{ width: '100%' }}>

              {platform === USER_INDEXES.instagram && (
                <TextField
                  id="priceForPost"
                  size="small"
                  type="number"
                  value={isInstagramValue || isYouTubeValue ? inputsState.priceForPost : ''}
                  onChange={onChangeInputsPrice}
                  onBlur={onHandleBlurEditField}
                  InputProps={
                    inputsState.priceForPost
                      ? {
                          endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
                        }
                      : null
                  }
                  sx={{
                    '.MuiTypography-root': {
                      lineHeight: 'inherit',
                    },
                    backgroundColor: isDisableAllFieldsExceptPriceForCollaboration
                      ? '#F4F6F8'
                      : 'inherit',
                    borderRadius: isDisableAllFieldsExceptPriceForCollaboration ? '8px' : 'inherit',
                  }}
                  disabled={isDisableAllFieldsExceptPriceForCollaboration}
                />
              )}

              {platform === USER_INDEXES.tiktok && (
                <TextField
                  id="priceForTikTok"
                  size="small"
                  type="number"
                  value={inputsState.priceForTikTok || ''}
                  onChange={onChangeInputsPrice}
                  onBlur={onHandleBlurEditField}
                  InputProps={
                    inputsState.priceForTikTok
                      ? {
                          endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
                        }
                      : null
                  }
                  sx={{
                    '.MuiTypography-root': {
                      lineHeight: 'inherit',
                    },
                    backgroundColor: isDisableAllFieldsExceptPriceForCollaboration
                      ? '#F4F6F8'
                      : 'inherit',
                    borderRadius: isDisableAllFieldsExceptPriceForCollaboration ? '8px' : 'inherit',
                  }}
                  disabled={isDisableAllFieldsExceptPriceForCollaboration}
                />
              )}

              {platform === USER_INDEXES.youtube && (
                <TextField
                  id="priceForYTVideo"
                  size="small"
                  type="number"
                  value={isYouTubeValue ? inputsState.priceForYTVideo : ''}
                  onChange={onChangeInputsPrice}
                  onBlur={onHandleBlurEditField}
                  InputProps={
                    inputsState.priceForYTVideo
                      ? {
                          endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
                        }
                      : null
                  }
                  sx={{
                    '.MuiTypography-root': {
                      lineHeight: 'inherit',
                    },
                    backgroundColor: isDisableAllFieldsExceptPriceForCollaboration
                      ? '#F4F6F8'
                      : 'inherit',
                    borderRadius: isDisableAllFieldsExceptPriceForCollaboration ? '8px' : 'inherit',
                  }}
                  disabled={isDisableAllFieldsExceptPriceForCollaboration}
                />
              )}
            </div>
          </Tooltip>
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          <Tooltip title={isDisableAllFieldsExceptPriceForCollaboration ? labelStrings.disabledText : ""}>
            <div style={{ width: '100%' }}>
              {platform === USER_INDEXES.youtube ? (
                <TextField
                  id="priceForYTShort"
                  size="small"
                  type="number"
                  value={isYouTubeValue ? inputsState.priceForYTShort : ''}
                  onChange={onChangeInputsPrice}
                  onBlur={onHandleBlurEditField}
                  InputProps={
                    inputsState.priceForYTShort
                      ? {
                          endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
                        }
                      : null
                  }
                  sx={{
                    '.MuiTypography-root': {
                      lineHeight: 'inherit',
                    },
                    backgroundColor:
                      isDisableAllFieldsExceptPriceForCollaboration || platform === USER_INDEXES.tiktok
                        ? '#F4F6F8'
                        : 'inherit',
                    borderRadius:
                      isDisableAllFieldsExceptPriceForCollaboration || platform === USER_INDEXES.tiktok
                        ? '8px'
                        : 'inherit',
                  }}
                  disabled={
                    isDisableAllFieldsExceptPriceForCollaboration || platform === USER_INDEXES.tiktok
                  }
                />
              ) : (
                <TextField
                  id="priceForReel"
                  size="small"
                  type="number"
                  value={
                    platform === USER_INDEXES.instagram && isInstagramValue
                      ? inputsState.priceForReel
                      : ''
                  }
                  onChange={onChangeInputsPrice}
                  onBlur={onHandleBlurEditField}
                  InputProps={
                    inputsState.priceForReel
                      ? {
                          endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
                        }
                      : null
                  }
                  sx={{
                    '.MuiTypography-root': {
                      lineHeight: 'inherit',
                    },
                    backgroundColor:
                      isDisableAllFieldsExceptPriceForCollaboration || platform === USER_INDEXES.tiktok
                        ? '#F4F6F8'
                        : 'inherit',
                    borderRadius:
                      isDisableAllFieldsExceptPriceForCollaboration || platform === USER_INDEXES.tiktok
                        ? '8px'
                        : 'inherit',
                  }}
                  disabled={
                    isDisableAllFieldsExceptPriceForCollaboration || platform === USER_INDEXES.tiktok
                  }
                />
              )}
            </div>
          </Tooltip>
        </TableCell>

        <TableCell align="left">
          <Tooltip title={isDisableAllFieldsExceptPriceForCollaboration ? labelStrings.disabledText : ""}>
              <div style={{ width: '100%' }}>
                <TextField
                  id="priceForStory"
                  size="small"
                  type="number"
                  value={
                    isInstagramValue && platform === USER_INDEXES.instagram
                      ? inputsState.priceForStory
                      : ''
                  }
                  onChange={onChangeInputsPrice}
                  onBlur={onHandleBlurEditField}
                  InputProps={
                    inputsState.priceForStory
                      ? {
                          endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
                        }
                      : null
                  }
                  sx={{
                    '.MuiTypography-root': {
                      lineHeight: 'inherit',
                    },
                    backgroundColor:
                      isDisableAllFieldsExceptPriceForCollaboration || platform !== USER_INDEXES.instagram
                        ? '#F4F6F8'
                        : 'inherit',
                    borderRadius:
                      isDisableAllFieldsExceptPriceForCollaboration || platform !== USER_INDEXES.instagram
                        ? '8px'
                        : 'inherit',
                  }}
                  disabled={
                    isDisableAllFieldsExceptPriceForCollaboration || platform !== USER_INDEXES.instagram
                  }
                />
            </div>
          </Tooltip>
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          <Tooltip title={isDisableAllFieldsExceptPriceForCollaboration ? labelStrings.disabledText : ""}>
              <div style={{ width: '100%' }}>
                <TextField
                  id="additionalCosts"
                  size="small"
                  type="number"
                  value={
                    (isInstagramValue && platform === USER_INDEXES.instagram) ||
                    (isYouTubeValue && platform === USER_INDEXES.youtube) ||
                    (isTikTokValue && platform === USER_INDEXES.tiktok)
                      ? inputsState.additionalCosts
                      : ''
                  }
                  onChange={onChangeInputsPrice}
                  onBlur={onHandleBlurEditField}
                  InputProps={
                    !isDisableAllFieldsExceptPriceForCollaboration && inputsState.additionalCosts
                      ? {
                          endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
                        }
                      : null
                  }
                  sx={{
                    '.MuiTypography-root': {
                      lineHeight: 'inherit',
                    },
                    backgroundColor: isDisableAllFieldsExceptPriceForCollaboration
                      ? '#F4F6F8'
                      : 'inherit',
                    borderRadius: isDisableAllFieldsExceptPriceForCollaboration ? '8px' : 'inherit',
                  }}
                  disabled={isDisableAllFieldsExceptPriceForCollaboration}
                />
            </div>
          </Tooltip>
        </TableCell>

        <TableCell align="right">
          <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <PopoverItemMenu
        openPopover={openPopover}
        handleClosePopover={handleClosePopover}
        onEditRow={onEditRow}
        onArchiveRow={onArchiveRow}
        status={status}
        rowIsBrandAccount={isBrandAccount}
        onAddRemoveBrandAccount={onAddRemoveBrandAccount}
        handleViewEditCreator={handleViewEditCreator}
        onAddNonRecognizedContent={onAddNonRecognizedContent}
        setMatchedModalData={() => setMatchedModalData(row)}
        onViewCreatorAnalytics={onViewCreatorAnalytics}
        onActivateCreator={onActivateCreator}
      />
    </>
  )
}

CpmsUserTableRow.propTypes = {
  row: PropTypes.object,
  onEditRow: PropTypes.func,
  onArchiveRow: PropTypes.func,
  onAddRemoveBrandAccount: PropTypes.func,
  onAddNonRecognizedContent: PropTypes.func,
  setMatchedModalData: PropTypes.func,
  onViewCreatorAnalytics: PropTypes.func,
  onActivateCreator: PropTypes.func,
  currency: PropTypes.string,
  onEditCreator: PropTypes.func,
}
