import React, { useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeDashboardId, getAllNewCampaigns } from '../../redux/ducks/campaignsDucks'
import DashboardLayout from '../layouts/DashboardLayout'
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { campaignDashboard, isSignUpOpenedSelector } from '../../redux/selectors'
import { toggleSignUp } from '../../redux/ducks/appSettingsDucks'

const CampaignsPage = () => {
  const { campaignId } = useParams()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const isSignupOpened = useSelector(isSignUpOpenedSelector)
  const { currentDashboardId: dashboardIdFromState } = useSelector(campaignDashboard)

  const params = new URLSearchParams(search)
  const dashboardIdFromQueryURL = params.get('dashboardId')
  const campaignIdFromQueryURL = params.get('campaignId')

  useEffect(() => {
    dispatch(changeDashboardId(dashboardIdFromQueryURL))
  }, [])

  useEffect(() => {
    if (dashboardIdFromState === dashboardIdFromQueryURL || !dashboardIdFromState) {
      dispatch(changeDashboardId(dashboardIdFromQueryURL))
    }

    dispatch(
      // @ts-ignore
      getAllNewCampaigns({
        campaignId: campaignIdFromQueryURL || campaignId,
      })
    )
  }, [campaignId, dashboardIdFromQueryURL, campaignIdFromQueryURL])

  useLayoutEffect(() => {
    if(isSignupOpened) {
      dispatch(toggleSignUp())
    }
  }, [isSignupOpened])

  return <DashboardLayout />
}

export default CampaignsPage
