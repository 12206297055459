import httpService from '../../services/httpService'
import { selectedProfileDetailsSelector, isProfileLoadingSelector } from '../selectors'
import { setError } from './errorDucks'
import { changeAudienceTokens } from './userDucks'
import { ERROR_MSG } from '../../constants/errorMessages'
import { API_STATUS_CODES } from '../../constants/appSettings'
import {
  COMPARED_PROFILE_LOADED_OBJ_PART,
  COMPARED_PROFILE_DEFAULT_LOADING_OBJ,
} from '../../constants/comparedProfiles'
import { USER_INDEXES } from '../../constants/appSettings'

import { compareProfilesSelector, comparedProfilesPlatformSelector } from '../selectors'
import { toggleLoadingProfileAction, fetchProfileById } from './profileSelectedDucks'
import {
  changeCompareList,
  processAddToStoreComparedProfileWithSorting,
} from './compareProfilesDucks'

export const unlockSelectedProfileAudienceToken = ({ userIds = [], platform } = {}) => async (
  dispatch,
  getState
) => {
  // take only one token for now. update this logic when it comes to apply many tokens at once
  // TODO: add notify via email later
  try {
    if (!userIds.length) return
    dispatch([toggleLoadingProfileAction()])
    await httpService.unlockAudienceAnalysisReport({ objectIds: userIds, platform })
    const currentProfile = selectedProfileDetailsSelector(getState())
    const profileIdToFetch = userIds[0]
    dispatch([toggleLoadingProfileAction()])
    const successFetch = await dispatch(
      fetchProfileById({
        profileId: profileIdToFetch,
        refetchExistingProfile: currentProfile._id === profileIdToFetch,
      })
    )
    if (successFetch && platform !== USER_INDEXES.tiktok) {
      dispatch([changeAudienceTokens({ tokensToAdd: 1 })])
    }
  } catch (err) {
    const isProfileLoading = isProfileLoadingSelector(getState())
    if (isProfileLoading) dispatch([toggleLoadingProfileAction()])
    let errKey = ERROR_MSG.failUnlockAudienceReport
    if (err.response && err.response.status === API_STATUS_CODES.forbidden) {
      errKey = ERROR_MSG.forbiddenUnlockAudienceReport
    }
    dispatch([setError(errKey)])
  }
}

export const unlockComparedProfileAudienceToken = ({ profileId, platform }) => async (
  dispatch,
  getState
) => {
  if (!profileId) return
  const comparedProfiles = compareProfilesSelector(getState())

  try {
    dispatch(
      changeCompareList({
        ...comparedProfiles,
        [profileId]: {
          ...comparedProfiles[profileId],
          ...COMPARED_PROFILE_DEFAULT_LOADING_OBJ,
        },
      })
    )

    try {
      await httpService.unlockAudienceAnalysisReport({ objectIds: [profileId], platform })
      dispatch([changeAudienceTokens({ tokensToAdd: 1 })])
    } catch (err) {
      throw err
    }

    const comparedUser = await httpService.fetchComparedUserById(profileId)
    if (comparedUser?.length) {
      const platform = comparedProfilesPlatformSelector(getState())
      dispatch(
        processAddToStoreComparedProfileWithSorting({
          comparedUser: comparedUser[0],
          platform,
        })
      )
    } else {
      throw new Error()
    }
  } catch (err) {
    dispatch(
      changeCompareList({
        ...comparedProfiles,
        [profileId]: {
          ...comparedProfiles[profileId],
          ...COMPARED_PROFILE_DEFAULT_LOADING_OBJ,
        },
      })
    )
    let errKey = ERROR_MSG.failUnlockAudienceReport
    if (err.response && err.response.status === API_STATUS_CODES.forbidden) {
      errKey = ERROR_MSG.forbiddenUnlockAudienceReport
    }
    dispatch([setError(errKey)])
  }
}
