import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Box, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { SOCIAL_PLATFORMS_NAMES } from '../../../constants/appSettings'
import { changeAppSocialPlatform } from '../../../redux/ducks/appSettingsDucks'
import { InstagramSwitchIcon, TikTokSwitchIcon, YouTubeSwitchIcon } from './icons'

export const SocialPlatformSwitcher = ({ currentPlatform = '' }) => {
  const dispatch = useDispatch()
  const [tabValue, setTabValue] = useState(SOCIAL_PLATFORMS_NAMES.instagram.name)

  const onChangeSocialTabs = e => {
    const value = e.target.value
    setTabValue(value)
    // @ts-ignore
    dispatch(changeAppSocialPlatform(value))
  }

  useEffect(() => {
    if (tabValue !== currentPlatform) {
      setTabValue(currentPlatform)
    }
  }, [currentPlatform])

  return (
    <Box>
      <TextField
        fullWidth
        select
        label="Platform"
        value={tabValue}
        onChange={onChangeSocialTabs}
        // disabled={isLoading}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                maxHeight: 260,
              },
            },
          },
        }}
        sx={{
          minWidth: '150px',
          maxWidth: { sm: 240 },
          textTransform: 'capitalize',
        }}
      >
        <MenuItem
          value={SOCIAL_PLATFORMS_NAMES.instagram.name}
          sx={{
            mx: 1,
            borderRadius: 0.75,
            typography: 'body2',
            textTransform: 'capitalize',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <InstagramSwitchIcon sx={{ fontSize: 24 }} />
            <Typography>Instagram</Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          value={SOCIAL_PLATFORMS_NAMES.tiktok.name}
          sx={{
            mx: 1,
            borderRadius: 0.75,
            typography: 'body2',
            textTransform: 'capitalize',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <TikTokSwitchIcon sx={{ fontSize: 24 }} />
            <Typography>TikTok</Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          value={SOCIAL_PLATFORMS_NAMES.youtube.name}
          sx={{
            mx: 1,
            borderRadius: 0.75,
            typography: 'body2',
            textTransform: 'capitalize',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <YouTubeSwitchIcon sx={{ fontSize: 24 }} />
            <Typography>YouTube</Typography>
          </Stack>
        </MenuItem>
      </TextField>
    </Box>
  )
}

SocialPlatformSwitcher.propTypes = {
  currentPlatform: PropTypes.string,
}
