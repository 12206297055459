import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'
import { CloseIcon } from '../icons'
import { DialogTitle } from '../styledWrappers'
import { ActionButton } from '../buttons/styledButtons'
import { Wrapper as CommonWrapper } from './dialogsCommonStyles'

const EditWrapper = styled.div`
  // padding-top: 12px;
  margin: 20px 0 30px 0;
  label {
    font-family: ${props => props.theme.fontFamilyInter};
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 3.85px;
    color: #272727;
    padding-left: 12px;
    text-transform: uppercase;
  }
`

const Wrapper = styled(CommonWrapper)`
  max-width: 600px;
`

const CloseWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`
const SpacesWrapper = styled.div`
  margin: 10px 0;
  text-align: center;
`

const CancelWrapper = styled.div`
  cursor: pointer;
  color: ${props => props.theme.primaryColor};
  text-decoration: underline;
  margin-top: 20px;
`

const RemoveItemsFromCollectionDialog = ({ itemsCount, close, removeItemsHandler }) => {
  const { labelStrings } = useTranslation()

  const closeHandler = () => close()
  return (
    <Wrapper>
      <DialogTitle>{labelStrings.warning}</DialogTitle>
      <CloseWrapper onClick={closeHandler}>
        <CloseIcon />
      </CloseWrapper>
      <EditWrapper>
        <SpacesWrapper>
          {labelStrings.removeNItemsWarning(itemsCount)}
          <br />
          <b>{labelStrings.areYouSureToProceed}</b>
        </SpacesWrapper>
      </EditWrapper>

      <ActionButton onClick={removeItemsHandler}>{labelStrings.remove}</ActionButton>
      <CancelWrapper onClick={close}>{labelStrings.cancel}</CancelWrapper>
    </Wrapper>
  )
}

RemoveItemsFromCollectionDialog.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
  removeItemsHandler: PropTypes.func.isRequired,
}

export default RemoveItemsFromCollectionDialog
