import styled from 'styled-components'
import { PDF_PAGE_FORMATS, PDF_PAGE_PX_DIMENSIONS } from '../../../constants/exportPdfSettings'

export const A4Page = styled.div`
  ${props => {
    const { width, height } = PDF_PAGE_PX_DIMENSIONS[PDF_PAGE_FORMATS.a4]
    return `
      width: ${width}px;
      height: ${height}px;
    `
  }}
  ${props => props.customStyles || ''}
`

export const Section = styled.div`
  ${props => !props.noPadding && 'padding: 30px 0'};
  ${props => props.smallPadding && 'padding: 15px 0'};
  ${props =>
    props.hasBorderBottom &&
    `
    border-bottom: 1px solid ${props.theme.colors.lightBrown};
  `}
`

export const SectionTitle = styled.div`
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  margin-bottom: ${props => props.smallMb ? '10px' : '20px'};
  color: ${props => props.theme.getColorPaletteForPlatform(props.platform).main};
`
