import styled from 'styled-components'
import { PlaformSwitchButton } from '../buttons/styledButtons'
import { SearchLabel } from '.'

export const AudienceCountriesWrapper = styled.div`
  width: 100%;
`

export const AudienceWrapperWithPadding = styled.div`
  padding: 0 15px;
`

export const AudienceCountrySelectSection = styled.div``

export const SelectWrapper = styled.div`
  width: 90%;
  margin-bottom: 10px;
  position: relative;
  & .creatable-multi-select__control {
    border-color: #ceadac;
  }
`

export const AddIconWrapper = styled.div`
  position: absolute;
  right: -26px;
  top: 5px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  & svg {
    height: 15px;
    width: 15px;
    fill: ${props => props.theme.platformColor};
  }
  & span {
    margin: 0;
  }
  ${props => props.isDisabled && props.theme.disabled}
`

export const AudienceCountriesNavWrapper = styled.div`
  margin-bottom: 0px;
  label {
    margin-bottom: 0.3rem;
    margin-top: 0.3rem;
  }
`

export const AudienceCountriesButton = styled(PlaformSwitchButton)`
  font-size: 10px;
  height: 20px;
  pointer-events: none; //TODO: until cities are introduced
`

export const SliderWrapper = styled.div`
  position: relative;
  width: 95%;
  margin: 10px auto;
  ${props => props.isDeactivated && props.theme.disabled}

  & .single_range_slider {
    height: 30px !important;
  }

  & .single_range_slider_rail_one {
    height: 1px !important;
  }

  & .single_range_slider_rail_two {
    height: 1px !important;
    background-color: ${props => props.theme.colors.darkBrown};
    transform: unset;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      right: -4px;
      height: 7px;
      width: 7px;
      background: ${props => props.theme.colors.darkBrown};
      border-radius: 50%;
      top: -3px;
      z-index: 1;
    }
  }

  & .single-range-slider-handles_role_slider {
    height: 10px !important;
    width: 10px !important;
    background: ${props => props.theme.platformColor} !important;
    box-shadow: unset;
  }

  & .single-range-slider-tracks_single_track {
    height: 3px !important;
    background: ${props => props.theme.platformColor} !important;
    top: -1px !important;
    transform: unset;
    border-radius: 10px;
  }

  & .single-range-slider-ticks_single_tick_line {
    height: 10px !important;
    margin-top: 0 !important;
    background: ${props => props.theme.color.clamShellText} !important;
  }

  & .single-range-slider-ticks_single_tick_label {
    margin-top: 10px !important;
    color: ${props => props.theme.color.clamShellText} !important;
    font-size: 8px;
  }
`

export const TitleWrapper = styled.div`
  position: relative;
  width: fit-content;
  z-index: 10;
  & svg {
    stroke: ${props => props.theme.color.clamShellText};
  }
  ${props =>
    props.putLower &&
    `
    top: 10px;
    z-index: 10;
  `}
`

export const SliderTitle = styled(SearchLabel)`
  padding-left: 0;
  margin: 6px 0;
  font-size: 10px;
  letter-spacing: 0;
  color: ${props => props.theme.color.clamShellText};
`

export const CurrentValueLabel = styled.div`
  position: absolute;
  right: -5px;
  top: ${props => props.customTop || 8}px;
  font-weight: bold;
  font-size: 10px;
  color: ${props => props.theme.platformColor};
`

export const AudienceSearchSectionsDivider = styled.div`
  border-top: 0.5px solid #ffded6;
  width: 100%;
  height: 0;
  margin: 1.5px 0;
`

export const AudienceMultiSliderWrapper = styled.div`
  & .tick_text {
    color: ${props => props.theme.color.clamShellText};
  }
  & .tick_percent {
    background-color: ${props => props.theme.color.clamShellText};
  }
`

export const SliderTipLabel = styled.div`
  position: absolute;
  bottom: ${props => props.bottomPosition || -10}%;
  left: ${props => props.leftPosition || 0}%;
  font-size: 8px;
  text-transform: uppercase;
  color: ${props => props.theme.color.clamShellText};
`
