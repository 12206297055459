import styled from 'styled-components'
import { USER_INDEXES } from '../../../../constants/appSettings'
import { convertHexToRgba } from '../../../../utils'

const trackHeight = 2
const thumbHeight = 12

export const MasonryWrapper = styled.div`
  margin-top: 10px;
  .content-masonry-grid {
    display: flex;
    width: auto;
  }
  .content-masonry-grid_column {
  }
`
export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
`
export const ProfileContentCard = styled.div`
  ${props => props.withMarginTop && `margin-top: 10px`};
  display: block;
  background: #fff9f9;
  border: 0.5px solid #cbb3b3;
  border-radius: 13px;
  width: 100%;
  height: auto;
  max-width: 244px;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: filter 0.3s ease-in-out;
  &:last-child: {
    margin-right: 0;
    margin-bottom: 0;
  }
  &:hover {
    filter: drop-shadow(0px 17px 35px rgba(120, 78, 78, 0.45));
  }
  cursor: pointer;
  ${props => props.isActive && `
    filter: drop-shadow(0px 17px 35px rgba(120, 78, 78, 0.45));
  `};
`
export const ProfileCardImgWrapper = styled.div`
  position: relative;
  padding: 9px;
  img {
    border-radius: 9px;
    width: 100%;
    height: auto;
  }
  .topBtnWrapper {
    position: absolute;
    right: 15px;
    top: 15px;
    display: flex;
    align-items: center;
    button {
      height: 35px;
      width: 35px;
      border: none;
      border-radius: 4px;
      background-color: ${props => props.theme.colors.lightMauve};
      color: ${props => props.theme.colors.brown};
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      :last-child {
        margin-right: 0;
      }
      &:hover {
        filter: brightness(90%);
      }
    }
  }
  .bottomBtnWrapper {
    position: absolute;
    bottom: 15px;
    left: 15px;
    width: 88%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      ${props => props.isHideContentBtnBottom && `width: 100%`};
      padding: 5px;
      border-radius: 4px;
      border: none;
      margin-right: 6px;
      background-color: ${props => props.theme.colors.lightMauve};
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: ${props => props.theme.colors.brown};
      &:last-child {
        margin-right: 0;
        min-width: 93px;
      }
      span {
        text-transform: capitalize;
      }
      &:hover {
        filter: brightness(90%);
      }
    }
  }
`
export const ProfileCardText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px 8px 16px;
  .rightType {
    width: 50%;
    h2 {
      color: #bc9797;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      text-transform: capitalize;
      padding: 0;
      margin: 0;
    }
  }
  .leftLocation {
    width: 50%;
    display: flex;
    align-items: center;
    div {
      display: flex;
      line-height: initial;
    }
    span {
      font-size: 10px;
      font-weight: 700;
      color: ${props => props.theme.colors.salmon};
      padding-left: 4px;
      line-height: normal;
    }
  }
`
export const ProfileStatistics = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 249px;
  width: 100%;
  border-top: 0.5px solid #cbb3b3;
`
export const ProfileStatisticsItem = styled.div`
  width: 121px;
  display: flex;
  flex-direction: column;
  border-bottom: 0.5px solid #cbb3b3;
  &:nth-child(odd) {
    border-right: 0.5px solid #cbb3b3;
    padding-left: 20px;
    padding-right: 2px;
  }
  &:nth-child(even) {
    padding-left: 11px;
    padding-right: 2px;
  }
  span {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: ${props => props.theme.colors.mauve};
    ${'' /* padding-right: 5px; */}
    padding-top: 2px;
  }
`
export const ProfileTextNumber = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: ${props => props.theme.colors.brown};
  padding-bottom: 2px;
`
export const ProfileText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: ${props => props.theme.colors.brown};
  padding-bottom: 2px;
  overflow-x: hidden;
  text-overflow: ellipsis;
`
export const ProfileFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  h2 {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: ${props => props.theme.colors.mauve};
    text-transform: capitalize;
    margin: 0;
    padding: 0;
  }
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 14px;
  padding-bottom: 12px;
  div {
    width: 100%;
    font-weight: 600;
    font-size: 10px;
    line-height: normal;
    color: ${props => props.theme.colors.brown};
    white-space: pre-line;
    word-break: break-word;
  }
`
export const FiltersWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 13px;
  margin-bottom: 10px;
`
export const ContentTimelineWrapper = styled.div`
  background: ${props => props.theme.colors.offWhite};
  border: 0.5px solid ${props => props.theme.colors.mauve};
  border-radius: 13px;
  padding: 5px 20px;
  ${props => !props.isTikTok && `margin-right: 16px`};
  width: 100%;
  height: 133px;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
`
export const ChartWrapper = styled.div`
  width: 100%;
  height: 60px;
`

export const ContentTypeWrapper = styled.div`
  background: ${props => props.theme.colors.offWhite};
  border: 0.5px solid ${props => props.theme.colors.mauve};
  border-radius: 13px;
  padding: 20px;
  width: 100%;
  max-width: 174px;
  height: 133px;
`
export const ShowMoreBtn = styled.button`
  margin: 0 auto;
  padding: 0;
  font-size: 12px;
  border: none;
  border: none;
  width: 100%;
  background: transparent;
  color: ${props => props.theme.colors.brown};
  font-weight: bold;
  padding-top: 5px;
  line-height: initial;
  &:after {
    content: '';
    display: block;
    border-bottom: 1px solid ${props => props.theme.colors.brown};
    width: 80px;
    margin: 0 auto;
  }
`
export const ContentMapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 470px;
  min-height: auto;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  margin-bottom: 10px;
  background: #f4e8e8;
  border: 0.5px solid ${props => props.theme.colors.mauve};
  .map-container {
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
  }
`
export const SliderStyled = styled.div`
  background-color: ${props => props.theme.colors.mauve};
  margin-left: ${thumbHeight * -0.5}px;
  margin-top: ${thumbHeight * -0.5}px;
  width: ${thumbHeight}px;
  height: ${thumbHeight}px;
  border: 0;
  border-radius: 50%;
  white-space: nowrap;
  position: absolute;
  z-index: 2;
  cursor: pointer;
`
export const RailHotspot = styled.div`
  background-color: #f4e8e8;
  width: 100%;
  height: ${trackHeight}px;
  position: absolute;
  pointer-events: none;
`
export const RailStyled = styled.div`
  width: 100%;
  height: ${thumbHeight * 2}px;
  top: ${thumbHeight * -1}px;
  position: absolute;
  cursor: pointer;
`
export const TrackStyled = styled.div`
  background-color: ${props => props.theme.colors.mauve};
  height: ${trackHeight}px;
  position: absolute;
  z-index: 1;
  pointer-events: none;
`
export const TrackHotspotStyled = styled.div`
  height: ${thumbHeight}px;
  left: 0
  top: 5px;
  ${'' /* top: ${thumbHeight * -0.5}px; */}
  position: absolute;
  cursor: pointer;
  color: #BC9797;
  font-weight: 700;
  font-size: 13px;

  ${'' /* &:nth-child(3) {
    left: 0
    top: 5px;
  } */}

  &:last-child {
    left: auto;
    right: 0;
    top: 5px;
  }

  ${
    '' /* margin-left: ${props => -(100 / props.count) / 2}%;
  width: ${props => 100 / props.count}%;
  left: ${props => props.tick && props.tick.percent}%; */
  }
`

export const SliderIntervals = styled.div`
  width: 50%;
  color: ${props => props.theme.colors.mauve};
  font-weight: 700;
  font-size: 14px;
  span {
    color: ${props => props.theme.colors.darkBrown};
  }
`

export const LoaderWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 400px);
`
export const MapsTooltipWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`
export const NotLoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  color: ${props => props.theme.colors.darkBrown};
`
export const ToolTitle = styled.div`
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  text-align: left;
  color: ${props => props.theme.colors.offWhite};
`

export const ToolItem = styled.button`
  ${props => props.isDisabled && props.theme.disabled}
  width: 135px;
  height: 45px;
  padding: 8px 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
  border: none;
  border-radius: 12px 0px 0 12px;
  background-color: ${props => {
    let activeColor = props.theme.colors.salmon
    let disabledColor = convertHexToRgba(props.theme.colors.darkBrown, 0.5)

    if (props.platform === USER_INDEXES.tiktok) {
      activeColor = props.theme.colors.aqua
      disabledColor = convertHexToRgba(props.theme.colors.aqua, 0.4)
    }

    if (props.isActive) return activeColor
    if (props.isDisabled) return disabledColor
    return convertHexToRgba(props.theme.colors.darkBrown, 0.85)
  }};

  ${'' /* & + & {
    margin-top: 15px;
  } */}

  &:hover {
    ${props =>
      !props.isActive &&
      `
      background-color: ${props.theme.colors.darkBrown};

      ${ToolTitle} {
        color: ${props.theme.colors.white};
      }
    `}
  }

  ${props =>
    !!props.numberInBubble &&
    `
    &:before {
      content: '${props.numberInBubble}';
      position: absolute;
      top: 5.5px;
      right: -1px;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
      font-weight: bold;
      font-size: 13px;
      ${'' /* line-height: 15px; */}
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: ${props.theme.colors.darkBrown};
      color: ${props.theme.colors.offWhite};
    }
  `}

  ${props => props.isDisabled && 'pointer-events: none;'}
`

export const ToolIconWrapper = styled.div`
  ${'' /* margin-right: 15px; */}
`

export const DateSortWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
