import React from 'react'
import PropTypes from 'prop-types'

import useTranslation from '../../../localization/useTranslation'
import { TT_SCORE_NAMES } from '../../../constants/profile'
import { USER_INDEXES } from '../../../constants/appSettings'

import RatingDescriptionTip from '../detailedInfludataRating/ratingDescriptionTip/RatingDescriptionTip'
import {
  RatingTitleWrapper,
  RatingTitle,
  TiktokStatisticsGridItem,
  StatisticCommonValue,
  StatisticCommonLabel,
} from './commonStyles'

const PostsPerWeek = ({ postsPerWeek = 0, isForPdf = false }) => {
  const { scoreStrings } = useTranslation()
  const postPerWeekStrings = scoreStrings[USER_INDEXES.tiktok][TT_SCORE_NAMES.postsPerWeek]

  return (
    <TiktokStatisticsGridItem>
      <RatingTitleWrapper>
        <RatingTitle>{postPerWeekStrings.title}</RatingTitle>
        {!isForPdf && <RatingDescriptionTip scoreDesc={postPerWeekStrings.description} />}
      </RatingTitleWrapper>

      <StatisticCommonValue isForPdf={isForPdf}>{postsPerWeek}</StatisticCommonValue>
      <StatisticCommonLabel>{postPerWeekStrings.inAverage}</StatisticCommonLabel>
    </TiktokStatisticsGridItem>
  )
}

PostsPerWeek.propTypes = {
  postsPerWeek: PropTypes.number,
  isForPdf: PropTypes.bool,
}

export default PostsPerWeek
