import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import useTranslation from "../../../localization/useTranslation"
import CreatableSelect from "react-select/creatable"
import { themeCommonStyles } from "../../../themes/infludataThemes"

const MultiInputWrapper = styled.div`
  margin: 15px 0;

  label {
    font-size: 13px;
    line-height: 16px;
    text-align: left;
    padding-left: 15px;
    margin-bottom: 5px;
    color: ${(props) => props.theme.colors.darkBrown};
  }

  & svg {
    height: 14px;
    width: 14px;
  }
`

const NoOptionsTextWrapper = styled.div`
  font-family: ${(props) => props.theme.fontFamilyInter};
  font-size: 13px;
  line-height: 16px;
  padding: 10px;
  color: ${(props) => props.theme.colors.darkBrown};
`

const MultiInput = ({
  values = [],
  labelText = "",
  noOptionsMessageText = "",
  onChange,
  validateInputFunc = null,
}) => {
  const { labelStrings } = useTranslation()

  return (
    <MultiInputWrapper id='multiinput_wrapper'>
      {labelText && <label htmlFor='multiinput'>{labelText}</label>}
      <CreatableSelect
        id='multiinput'
        isMulti
        components={{
          DropdownIndicator: null,
          ClearIndicator: null,
          NoOptionsMessage: () => (
            <NoOptionsTextWrapper>{noOptionsMessageText}</NoOptionsTextWrapper>
          ),
          Placeholder: () => null,
        }}
        onChange={onChange}
        value={values}
        formatCreateLabel={
          validateInputFunc ||
          ((userInput) => `${labelStrings.create} ${userInput}`)
        }
        styles={{
          container: (provided, state) => ({
            ...provided,
            height: "80%",
          }),
          control: (provided, state) => {
            return {
              ...provided,
              borderRadius: "13px",
              border: `1px solid ${themeCommonStyles.colors.darkBrown}`,
              boxShadow: "none",
              overflow: "hidden",
              paddingLeft: "5px",
              cursor: "text",
              outline: "none",
              "&:hover": {
                borderColor: themeCommonStyles.colors.darkBrown,
              },
            }
          },
          input: (provided, state) => ({
            ...provided,
            fontSize: "15px",
            lineHeight: "19px",
            color: `${themeCommonStyles.colors.brown}`,
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            alignItems: "baseline",
            alignContent: "flex-start",
            padding: "8px",
            maxHeight: "150px",
            minHeight: "100px",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: themeCommonStyles.colors.lightMauve,
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "16px",
              border: `2px solid ${themeCommonStyles.colors.mauve}`,
              backgroundClip: "padding-box",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              border: `0px solid ${themeCommonStyles.colors.mauve}`,
            },
          }),
          multiValue: (provided, state) => ({
            ...provided,
            padding: "2px 10px",
            margin: "4px",
            background: themeCommonStyles.colors.darkBrown,
            borderRadius: "13px",
            display: "flex",
            alignItems: "center",
          }),
          multiValueLabel: (provided, state) => ({
            ...provided,
            fontSize: "13px",
            fontWeight: 700,
            lineHeight: "15px",
            textTransform: "uppercase",
            color: themeCommonStyles.color.white,
          }),
          multiValueRemove: (provided, state) => {
            return {
              ...provided,
              color: themeCommonStyles.color.white,
              cursor: "pointer",
              padding: "0",
              marginLeft: "7px",
              "&:hover": {
                background: "transparent",
              },
              "& svg": {
                height: "16px",
                width: "16px",
                borderRadius: "50%",
                border: `1.5px solid ${themeCommonStyles.color.white}`,
                "&:hover": {
                  background: "transparent",
                  fill: themeCommonStyles.color.white,
                },
              },
            }
          },
          menu: (provided, state) => {
            return {
              ...provided,
              fontFamily: themeCommonStyles.fontFamilyInter,
              fontSize: "13px",
              lineHeight: "16px",
              color: themeCommonStyles.colors.darkBrown,
              border: `0.5px solid ${themeCommonStyles.colors.mediumBrown}`,
              borderRadius: "13px",
              background: themeCommonStyles.colors.lightBrown,
              boxShadow: "none",
            }
          },
          option: (provided, state) => {
            return {
              ...provided,
              background: "transparent",
              cursor: "pointer",
              ":active": {
                background: "transparent",
              },
            }
          },
        }}
      />
    </MultiInputWrapper>
  )
}

MultiInput.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ),
  labelText: PropTypes.string,
  noOptionsMessageText: PropTypes.string,
  onChange: PropTypes.func,
  validateInputFunc: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.instanceOf(null),
  ]),
}

export default MultiInput
