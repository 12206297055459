import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { ADDITIONAL_FIELD_PROP_TYPE } from "../../../../constants/additionalFields"
import { USER_INDEXES } from "../../../../constants/appSettings"
import { isAdditionalFieldsLoadingSelector } from "../../../../redux/selectors"
import AdditionalFieldContainer from "./AdditionalFieldContainer"
import Spinner from "../../../common/spinners/Spinner"
import {
  AdditionalFieldsContainer,
  AdditionalFieldsGrid,
  AdditionalFieldsGridItem,
} from "./styles"

const AdditionalFields = ({
  profileId = "",
  platform = "",
  additionalFields = [],
  isDisabled = false,
  isCollection = false,
  userAdditionalFields = [],
}) => {
  const isLoading = useSelector(isAdditionalFieldsLoadingSelector)

  return (
    <AdditionalFieldsContainer
      isCollection={isCollection}
      isLoading={isLoading}
      isDisabled={isDisabled}
    >
      {isLoading && <Spinner isOverlay />}

      {isCollection && (
        <>
          {additionalFields?.map((additionalField) => {
            const userAdditionalFieldsData = userAdditionalFields?.find(
              (el) => el.code === additionalField.code,
            )
            return (
              <AdditionalFieldsGridItem
                key={additionalField.code}
                isCollection={additionalField.type !== "boolean"}
              >
                <AdditionalFieldContainer
                  profileId={profileId}
                  platform={platform}
                  additionalFieldData={{
                    ...additionalField,
                    ...(userAdditionalFieldsData
                      ? userAdditionalFieldsData
                      : {}),
                  }}
                  isCollection={isCollection}
                />
              </AdditionalFieldsGridItem>
            )
          })}
        </>
      )}

      {!isCollection && (
        <AdditionalFieldsGrid>
          {additionalFields?.map((additionalField) => (
            <AdditionalFieldsGridItem key={additionalField.code}>
              <AdditionalFieldContainer
                profileId={profileId}
                platform={platform}
                additionalFieldData={additionalField}
              />
            </AdditionalFieldsGridItem>
          ))}
        </AdditionalFieldsGrid>
      )}
    </AdditionalFieldsContainer>
  )
}

AdditionalFields.propTypes = {
  profileId: PropTypes.string.isRequired,
  platform: PropTypes.oneOf([
    USER_INDEXES.instagram,
    USER_INDEXES.tiktok,
    USER_INDEXES.youtube,
  ]).isRequired,
  additionalFields: PropTypes.arrayOf(ADDITIONAL_FIELD_PROP_TYPE).isRequired,
  isDisabled: PropTypes.bool,
  isCollection: PropTypes.bool,
  userAdditionalFields: PropTypes.array,
}

export default AdditionalFields
