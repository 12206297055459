import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import useDebounce from '../../../../hooks/useDebounce'
import { updateSearch } from '../../../../redux/ducks/searchDucks'
import { currentSearchQuerySelector, searchDataSelector } from '../../../../redux/selectors'
import LoadingSpinner from '../../../common/spinners/LoadingSpinner'
import { ProfileContentBarChart } from './ProfileContentBarChart'
import { ProfileContentRangeSlider } from './ProfileContentRangeSlider'
import { ContentTimelineWrapper, DateSortWrapper, NotLoadingWrapper, SliderIntervals } from './styles'
import useTranslation from '../../../../localization/useTranslation'
import { USER_INDEXES } from '../../../../constants/appSettings'
import SearchResultsSortingTool from '../../../sortingTools/SearchResultsSortingTool'

const timelineLoadingErrorValue = 'none found'

const formatDate = (date, formatType = 'yyyy-MM-dd') => {
  const dt = new Date(date)
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)
  return format(dtDateOnly, formatType)
}

export const ProfileContentTimeline = ({ platform }) => {
  const { labelStrings } = useTranslation()
  const dispatch = useDispatch()
  const {
    timelineBucket: { data, status, error },
    isTimelineBucketLoaded,
  } = useSelector(searchDataSelector)
  const { uploadedFrom, uploadedTo } = useSelector(currentSearchQuerySelector)
  const timelineValuesFromQuery = [
    uploadedFrom && new Date(uploadedFrom).getTime(),
    uploadedTo && new Date(uploadedTo).getTime(),
  ]

  const range = !!data?.length && [
    new Date('2022-01-01').getTime(),
    new Date().getTime(),
  ]

  const [domain, setDomain] = useState([])
  const [values, setValues] = useState([])
  const [update, setUpdate] = useState([])

  const [sliderTouchValue, setSliderTouchValue] = useState([])
  const debouncedRangeValues = useDebounce(sliderTouchValue, 2000)

  useEffect(() => {
    if (range?.length) {
      setDomain(range)
      setValues(timelineValuesFromQuery[0] ? timelineValuesFromQuery : range)
      setUpdate(range)
    }
  }, [data])

  useEffect(() => {
    if (
      debouncedRangeValues?.length &&
      update[0] === debouncedRangeValues[0] &&
      update[1] === debouncedRangeValues[1]
    ) {
      dispatch(
        updateSearch({
          newValuesObj: {
            uploadedFrom: formatDate(debouncedRangeValues[0]),
            uploadedTo: formatDate(debouncedRangeValues[1]),
          },
        })
      )
    }
  }, [debouncedRangeValues, update])

  return (
    <ContentTimelineWrapper isTikTok={platform === USER_INDEXES.tiktok}>
    <SearchResultsSortingTool isFullWidth isContent />
  
    {/* 
    {!data?.length || !values?.length || !domain?.length || isTimelineBucketLoaded ? (
      <>
        {!status && error === timelineLoadingErrorValue ? (
          <NotLoadingWrapper>{labelStrings.timelineIsNotAvailable}</NotLoadingWrapper>
        ) : (
          <LoadingSpinner isSimple />
        )}
      </>
    ) : (
      <>
        <DateSortWrapper>
          <SliderIntervals>
            Selected interval: <span>{formatDate(update[0], 'MMM dd,yyyy')}</span> -{' '}
            <span>{formatDate(update[1], 'MMM dd,yyyy')}</span>
          </SliderIntervals>
        </DateSortWrapper>
        <ProfileContentBarChart timeline={data} domain={domain} highlight={update} />
        <ProfileContentRangeSlider
          domain={domain}
          values={values}
          setValues={setValues}
          setUpdate={setUpdate}
          setSliderTouchValue={setSliderTouchValue}
        />
      </>
    )}
    */}
  
  </ContentTimelineWrapper>
)  }

ProfileContentTimeline.propTypes = {
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
}
