import React from 'react'
import { Box, Stack, Typography, LinearProgress } from '@mui/material'
import { StyledPaper, ShowSentimentBtn } from './styles'
import Scrollbar from '../../scrollbar/Scrollbar'
import { TYPE_RANGE_COLORS, getRangeAndColor } from './utils'

export const SentimentAnalytics = ({ item, onShowSentimentAnalytics, isNotWidget }) => {
  const { comments, commentPositivityRate, controversialCommentRate, commentSummary } = item

  return (
    <StyledPaper sx={{ height: '539px', marginBottom: isNotWidget ? '16px' : 0 }}>
      <Stack spacing={2.5} sx={{ p: 3 }}>
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: 700, fontSize: '18px', lineHeight: '28px' }}
        >
          Comments: {comments}
        </Typography>
        <Box>
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 700, fontSize: '18px', lineHeight: '28px', mb: 2 }}
          >
            Comments Summary:
          </Typography>
          <Scrollbar sx={{ maxHeight: '180px', width: '100%' }}>
            <Typography
              variant="caption"
              sx={{
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '22px',
                display: 'block',
                paddingRight: 1.5,
              }}
            >
              {commentSummary}
            </Typography>
          </Scrollbar>
        </Box>
        <Box>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '24px' }}
            >
              Controversial Rate
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '22px' }}
            >
              {Math.round((controversialCommentRate / 100) * 5)}/5{' '}
              <span style={{ color: '#637381', fontWeight: 600 }}>
                ({controversialCommentRate}%)
              </span>
            </Typography>
          </Stack>
          <Box
            sx={{
              width: '100%',
              mr: 1,
              '& .MuiLinearProgress-root': {
                backgroundColor: 'rgb(210, 215, 211)',
              },
            }}
          >
            <LinearProgress
              sx={{
                '& .MuiLinearProgress-bar': {
                  backgroundColor: getRangeAndColor(
                    controversialCommentRate,
                    TYPE_RANGE_COLORS.CONTROVERSIAL_COMMENT_RATE
                  ).color,
                },
              }}
              variant="determinate"
              value={controversialCommentRate}
            />
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: 600, fontSize: '14px', lineHeight: '22px', textAlign: 'center' }}
            >
              {
                getRangeAndColor(
                  controversialCommentRate,
                  TYPE_RANGE_COLORS.CONTROVERSIAL_COMMENT_RATE
                ).range
              }
            </Typography>
          </Box>
        </Box>
        <Box>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '24px' }}
            >
              Positivity Rate
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '22px' }}
            >
              {Math.round((commentPositivityRate / 100) * 5)}/5{' '}
              <span style={{ color: '#637381', fontWeight: 600 }}>({commentPositivityRate}%)</span>
            </Typography>
          </Stack>
          <Box
            sx={{
              width: '100%',
              mr: 1,
              '& .MuiLinearProgress-root': {
                backgroundColor: 'rgb(210, 215, 211)',
              },
            }}
          >
            <LinearProgress
              sx={{
                '& .MuiLinearProgress-bar': {
                  backgroundColor: getRangeAndColor(
                    commentPositivityRate,
                    TYPE_RANGE_COLORS.COMMENT_POSITIVITY_RATE
                  ).color,
                },
              }}
              variant="determinate"
              value={commentPositivityRate}
            />
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: 600, fontSize: '14px', lineHeight: '22px', textAlign: 'center' }}
            >
              {
                getRangeAndColor(commentPositivityRate, TYPE_RANGE_COLORS.COMMENT_POSITIVITY_RATE)
                  .range
              }
            </Typography>
          </Box>
        </Box>
        <ShowSentimentBtn
          sx={{
            width: '100%',
            backgroundColor: 'transparent',
            color: '#FF5D5A',
            border: '1px solid #FF5D5A',
            padding: '6px 16px',
            marginTop: '32px !important',
          }}
          onClick={onShowSentimentAnalytics}
          variant="contained"
        >
          Close
        </ShowSentimentBtn>
      </Stack>
    </StyledPaper>
  )
}
