import styled from 'styled-components'

export const FormElement = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`

export const Label = styled.label`
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  padding-left: 15px;
  margin-bottom: 5px;
  color: ${props => (props.hasError ? props.theme.colors.salmon : props.theme.colors.darkBrown)};
`

export const Tip = styled.div`
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  padding-left: 15px;
  margin-top: -5px;
  margin-bottom: 5px;
  color: ${props => (props.hasError ? props.theme.colors.salmon : props.theme.colors.darkBrown)};
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const StyledInput = styled.input`
  font-size: 15px;
  line-height: 19px;
  padding: 12px 15px;
  min-width: 100%;
  max-width: 100%;
  border: 1px solid
    ${props => (props.hasError ? props.theme.colors.salmon : props.theme.colors.darkBrown)};
  color: ${props => props.theme.colors.brown};
  border-radius: 13px;
  outline: none;

  // Hide arrows from number input
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::placeholder {
    font-size: 15px;
    line-height: 19px;
    color: ${props => props.theme.colors.mediumBrown};
  }
`

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  user-select: none;
`

export const ErrorMessage = styled.div`
  font-size: 12px;
  line-height: 15px;
  padding-left: 15px;
  padding-top: 5px;
  color: ${props => props.theme.colors.salmon};
`

export const InputAutocompleteWrapper = styled.div`
  position: relative;

  .input {
    border-radius: 7px;
  }
`

export const AutocompleteOptions = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  background-color: ${props => props.theme.colors.offWhite};
  border: 0.5px solid ${props => props.theme.colors.darkBrown};
  border-radius: 6px;
  overflow: hidden;
`

export const AutocompleteOption = styled.div`
  padding: 10px;
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.brown};
  background-color: ${props => (props.isSelected ? props.theme.colors.lightMauve : 'transparent')};
  cursor: pointer;

  &:hover {
    background-color: ${props =>
      props.isSelected ? props.theme.colors.lightMauve : props.theme.colors.lightBrown};
  }
`
