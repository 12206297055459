import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'
import { USER_INDEXES } from '../../../constants/appSettings'
import {
  ADDITIONAL_FIELD_TYPES,
  ADDITIONAL_FIELD_TYPES_ARR,
} from '../../../constants/additionalFields'
import { themeCommonStyles } from '../../../themes/infludataThemes'
import {
  FieldTypeStringIcon,
  FieldTypeYesNoChoiseIcon,
  FieldTypeDateIcon,
  FieldTypeNumberIcon,
  FieldTypeSingleSelectIcon,
  FieldTypeMultiSelectIcon,
  SelectArrowIcon,
} from '../icons/icons'
import {
  SelectWidthContainer,
  FieldTypeSelectContainer,
  FieldTypeSelectName,
  SelectArrowIconWrapper,
  FieldTypeSelectItems,
  FieldTypeSelectItem,
  IconWrapper,
  FieldTypeSelectItemText,
} from './commonStyles'

const AdditionalFieldTypeSelect = ({
  platform = '',
  selectedFieldType = '',
  canChangeFieldType = true,
  onSelectFieldType,
}) => {
  const { labelStrings, additionalFieldTypes } = useTranslation()

  const [isSelectOpened, setIsSelectOpened] = useState(!selectedFieldType)

  const toggleIsSelectOpened = () => {
    if (!canChangeFieldType) return

    if (!!selectedFieldType) {
      setIsSelectOpened(!isSelectOpened)
    } else {
      setIsSelectOpened(true)
    }
  }

  const onSelectItem = itemId => {
    setIsSelectOpened(false)
    onSelectFieldType(itemId)
  }

  const getFieldTypeIcon = fieldType => {
    const [color1, color2] = themeCommonStyles.getMainGradientColorsForPlatform(platform)

    switch (fieldType) {
      case ADDITIONAL_FIELD_TYPES.string:
        return <FieldTypeStringIcon gradientColor1={color1} gradientColor2={color2} />
      case ADDITIONAL_FIELD_TYPES.boolean:
        return <FieldTypeYesNoChoiseIcon gradientColor1={color1} gradientColor2={color2} />
      case ADDITIONAL_FIELD_TYPES.date:
        return <FieldTypeDateIcon gradientColor1={color1} gradientColor2={color2} />
      case ADDITIONAL_FIELD_TYPES.number:
        return <FieldTypeNumberIcon gradientColor1={color1} gradientColor2={color2} />
      case ADDITIONAL_FIELD_TYPES.singleSelect:
        return <FieldTypeSingleSelectIcon gradientColor1={color1} gradientColor2={color2} />
      case ADDITIONAL_FIELD_TYPES.multiSelect:
        return <FieldTypeMultiSelectIcon gradientColor1={color1} gradientColor2={color2} />
    }
  }

  let selectName = labelStrings.fieldType
  if (!!selectedFieldType) {
    selectName = (
      <>
        <IconWrapper>{getFieldTypeIcon(selectedFieldType)}</IconWrapper>
        {additionalFieldTypes[selectedFieldType]}
      </>
    )
  }

  return (
    <SelectWidthContainer>
      <FieldTypeSelectContainer>
        <FieldTypeSelectName onClick={toggleIsSelectOpened} canChangeFieldType={canChangeFieldType}>
          {selectName}
          {canChangeFieldType && (
            <SelectArrowIconWrapper isSelectOpened={isSelectOpened}>
              <SelectArrowIcon />
            </SelectArrowIconWrapper>
          )}
        </FieldTypeSelectName>

        <FieldTypeSelectItems isSelectOpened={isSelectOpened}>
          {ADDITIONAL_FIELD_TYPES_ARR.map((fieldType, index) => {
            return (
              <FieldTypeSelectItem key={index} onClick={() => onSelectItem(fieldType)}>
                <IconWrapper>{getFieldTypeIcon(fieldType)}</IconWrapper>
                <FieldTypeSelectItemText>{additionalFieldTypes[fieldType]}</FieldTypeSelectItemText>
              </FieldTypeSelectItem>
            )
          })}
        </FieldTypeSelectItems>
      </FieldTypeSelectContainer>
    </SelectWidthContainer>
  )
}

AdditionalFieldTypeSelect.propTypes = {
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  selectedFieldType: PropTypes.oneOf([...ADDITIONAL_FIELD_TYPES_ARR, '']),
  canChangeFieldType: PropTypes.bool,
  onSelectFieldType: PropTypes.func,
}

export default AdditionalFieldTypeSelect
