export const MIN_CHART_COUNT = 4 // we need min 2 points to create chart. First arr element is titles

export const LINE_CHART_COUNT_FOR_MONTHS_FORMAT = 4

export const LINE_CHART_DATE_FORMAT = {
  months: 'MMM yyyy',
  insideMonth: 'dd.MMM',
  default: 'MMM, yy',
}

export const CHART_INCREASE_VALUE_RATIOS = {
  lessThan10: {
    value: 10,
    ratio: 20,
  },
  lessThan20: {
    value: 20,
    ratio: 5,
  },
}

export const MULTI_RANGE_SLIDER_DEFAULT_RANGES = {
  mapping: [
    2000,
    4000,
    5000,
    6000,
    7000,
    8000,
    9000,
    10000,
    11000,
    12000,
    13000,
    14000,
    15000,
    16000,
    17000,
    18000,
    19000,
    20000,
    25000,
    30000,
    35000,
    40000,
    45000,
    50000,
    60000,
    70000,
    80000,
    90000,
    100000,
    150000,
    200000,
    250000,
    300000,
    350000,
    400000,
    450000,
    500000,
    600000,
    700000,
    800000,
    900000,
    1000000,
    1500000,
    2000000,
    2500000,
    3000000,
    3500000,
    4000000,
    4500000,
    5000000,
    5500000,
    6000000,
    6500000,
    7000000,
    7500000,
    8000000,
    8500000,
    9000000,
    9500000,
    Infinity,
  ],

  presentationMapping: [
    '2',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '25',
    '30',
    '35',
    '40',
    '45',
    '50',
    '60',
    '70',
    '80',
    '90',
    '100',
    '150',
    '200',
    '250',
    '300',
    '350',
    '400',
    '450',
    '500',
    '600',
    '700',
    '800',
    '900',
    '1',
    '1.5',
    '2,',
    '2.5',
    '3',
    '3.5',
    '4',
    '4.5',
    '5',
    '5.5',
    '6',
    '6.5',
    '7',
    '7.5',
    '8',
    '8.5',
    '9',
    '9.5',
    '∞',
  ],
}
