import React from 'react'
import { LoadingButton } from '@mui/lab'
import { Stack, MenuItem, Chip, Tooltip } from '@mui/material'
import { RHFSelect, RHFAutocomplete } from '../../../components/hook-form'
import FormProvider from '../../../components/hook-form/FormProvider'
import { StyledButton } from './styles'
import useTranslation from '../../../../localization/useTranslation'

export const Fields = props => {
  const { labelStrings } = useTranslation()
  const { methods, onSubmit, handleSubmit, isSubmitting, handleClose, dashboards } = props

  const { watch } = methods;
  const emails = watch('emails');


  const intervalOptions = [
    { value: 'daily', label: labelStrings.notificationsIntervalOptions.daily },
    { value: 'weekly', label: labelStrings.notificationsIntervalOptions.weekly },
    { value: 'monthly', label: labelStrings.notificationsIntervalOptions.monthly },
    { value: 'quarterly', label: labelStrings.notificationsIntervalOptions.quarterly },
  ]

  const dashboardOptions = dashboards?.map(dashboard => {
    return {
      value: dashboard.dashboardId,
      label: dashboard.dashboardName,
    }
  })

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack
        direction="column"
        spacing={1.5}
        justifyContent="space-between"
        sx={{ my: 1, height: '270px', width: '100%' }}
      >
        <Stack spacing={2}>
          <RHFSelect
            name="dashboardId"
            label={labelStrings.selectDashboard}
            InputLabelProps={{ shrink: true }}
          >
            {dashboardOptions?.length ? (
              dashboardOptions?.map(interval => (
                <MenuItem key={interval.value} value={interval.value}>
                  {interval.label}
                </MenuItem>
              ))
            ) : (
              <MenuItem></MenuItem>
            )}
          </RHFSelect>

          <RHFSelect
            name="interval"
            label={labelStrings.selectInterval}
            InputLabelProps={{ shrink: true }}
          >
            {intervalOptions?.length ? (
              intervalOptions?.map(interval => (
                <MenuItem key={interval.value} value={interval.value}>
                  {interval.label}
                </MenuItem>
              ))
            ) : (
              <MenuItem></MenuItem>
            )}
          </RHFSelect>

          <RHFAutocomplete
            name="emails"        
            label={labelStrings.emailRecipients}
            variant="outlined"
            multiple
            id="emails"
            freeSolo
            options={[]}
            filterSelectedOptions
            renderTags={(value, getTagProps) =>
              value?.map((email, index) => <Chip label={email} {...getTagProps({ index })} />)
           }
            helperText=""          
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="end">
          <StyledButton variant="outlined" sx={{ fontWeight: 'bold', mx: 1 }} onClick={handleClose}>
            {labelStrings.cancel}
          </StyledButton>

          <Tooltip title={emails && emails.length > 0 ? "" : labelStrings.clickEnterWarningNotifications}>
            <span>
              <LoadingButton 
                sx={{ mx: 1 }} 
                type="submit" 
                variant="contained" 
                loading={isSubmitting}
                disabled={!emails || emails.length === 0}
              >
                {labelStrings.confirm}
              </LoadingButton>
            </span>
          </Tooltip>

        </Stack>
      </Stack>
    </FormProvider>
  )
}
