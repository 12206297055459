import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Alert, Box, Divider, List, Stack, Typography } from '@mui/material'
import { CreatorsListItem } from './CreatorsListItem'
import { StyledCardCreatorList, StyledSkeleton } from './styles'
import useTranslation from '../../../../../localization/useTranslation'
import AddProfileToInfludataContainerMUI from '../../../../components/add-profile-to-infludata/AddProfileToInfludataContainerMUI'
import Scrollbar from '../../../../components/scrollbar/Scrollbar'

export const CreatorsList = props => {
  const {
    creators = [],
    notFoundData = false,
    loadingCreators = false,
    currentPlatform = '',
    creatorsName = '',
    showList = false,
    error = false,
    isAllowedToAddCreators = false,
    creatorIdFromState = '',
    setCreatorIdFromState,
    onClearInput,
    isSetCreatorId = true,
    isDisabledInput
  } = props || {}

  const { labelStrings } = useTranslation()

  const isExactUsernameFound = useMemo(() => {
    if (!creators.length || !creatorsName) return false
    return creators.some(u => u.username === creatorsName)
  }, [creators?.length, creatorsName])

  return (
    <StyledCardCreatorList sx={{ visibility: showList ? 'visible' : 'hidden' }}>
      {!notFoundData && error && !creators?.length && (
        <Box sx={{ padding: 2 }}>
          <Typography
            sx={{ fontWeight: 600, textAlign: 'center', fontSize: '14px', lineHeight: '22px' }}
          >
            {labelStrings.somethingWentWrong}
          </Typography>
        </Box>
      )}
      {notFoundData && (
        <Stack spacing={1} sx={{ padding: 2 }}>
          <Typography
            sx={{ fontWeight: 600, textAlign: 'center', fontSize: '14px', lineHeight: '22px' }}
          >
            {labelStrings.noCreatorsFound}
          </Typography>
          <Divider />
          <AddProfileToInfludataContainerMUI
            key="add_creators_to_campaign"
            usernameToAdd={creatorsName}
            userindex={currentPlatform?.toLowerCase()}
            usersFound={notFoundData}
            isExactUsernameFound={isExactUsernameFound}
          />
        </Stack>
      )}
      {loadingCreators && (
        <Stack spacing={1} sx={{ padding: 2, height: '336px' }}>
          <StyledSkeleton animation="wave" variant="rectangular" width="100%" height={200} />
          <StyledSkeleton animation="wave" variant="rectangular" width="60%" height={80} />
          <StyledSkeleton animation="wave" variant="rectangular" width="40%" height={80} />
        </Stack>
      )}
      {!notFoundData && !loadingCreators && !!creators?.length && !isDisabledInput && (
        <List>
          <Scrollbar sx={{ maxHeight: '336px', padding: 1 }}>
            {!isAllowedToAddCreators && (
              <Alert
                sx={{
                  fontSize: '14px',
                  '& .MuiAlert-message': {
                    padding: '2px 0',
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'pre-line',
                  },
                  '& .MuiAlert-icon': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}
                severity="error"
              >
                {labelStrings.reachedCreatorLimit}
              </Alert>
            )}
            {isAllowedToAddCreators &&
              creators?.map(creator => (
                <Fragment key={creator._id}>
                  <CreatorsListItem
                    creator={creator}
                    showList={showList}
                    isAllowedToAddCreators={isAllowedToAddCreators}
                    creatorIdFromState={creatorIdFromState}
                    setCreatorIdFromState={setCreatorIdFromState}
                    onClearInput={onClearInput}
                    isSetCreatorId={isSetCreatorId}
                  />
                </Fragment>
              ))}
          </Scrollbar>
        </List>
      )}
    </StyledCardCreatorList>
  )
}

CreatorsList.propTypes = {
  creators: PropTypes.array,
  notFoundData: PropTypes.bool,
  loadingCreators: PropTypes.bool,
  currentPlatform: PropTypes.string,
  creatorsName: PropTypes.string,
  showList: PropTypes.bool,
  error: PropTypes.bool,
  isAllowedToAddCreators: PropTypes.bool,
  creatorIdFromState: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  setCreatorIdFromState: PropTypes.func,
  onClearInput: PropTypes.func,
  isSetCreatorId: PropTypes.bool,
  isDisabledInput: PropTypes.bool
}