import React from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'
import styled from 'styled-components'
import { SearchLabel } from '../styledWrappers'
import LinkToFaqQuestion from '../../faqs/LinkToFaqQuestion'
import WithUpgradeTip from '../popups/WithUpgradeTip'
import { findSocialPlatformIndex } from '../../../utils'

const RoundedCreatableSelect = styled(ReactSelect)`
  .creatable-multi-select__control {
    border-radius: 7px;
    cursor: pointer;
    font-size: 14px;
    border-color: ${props => props.theme.colors.darkBrown};
  }

  .creatable-multi-select__multi-value {
    border-radius: 4px;
    font-size: 14px;
    position: relative;
    cursor: pointer;
    background-color: ${props => props.theme.platformColor};
    &:hover {
      .creatable-multi-select__multi-value__label,
      .creatable-multi-select__multi-value__remove {
        background-color: ${props => props.theme.platformColorLight};
      }
    }

    .creatable-multi-select__multi-value__label {
      position: relative;
      top: 0px;
      padding-left: 10px;
      background-color: ${props => props.theme.platformColor};

      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      color: #ffffff;
    }

    .creatable-multi-select__multi-value__remove {
      background-color: ${props => props.theme.platformColor};
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      color: #ffffff;
      & svg {
        border: 1px solid #ffffff;
        border-radius: 50%;
        margin-top: 1px;
      }
    }
  }

  .creatable-multi-select__menu {
    border-radius: 0;
    z-index: 1000;

    .creatable-multi-select__option--is-focused {
      background-color: ${props => props.theme.platformColorLight};
      color: #ffffff;
    }
    .creatable-multi-select__option--is-selected {
      background-color: ${props => props.theme.platformColor};
      color: #ffffff;
      // border-radius: 7px;
    }
  }

  .creatable-multi-select__control--is-focused {
    border-color: ${props => props.theme.platformColorLight} !important;
    box-shadow: 0 0 0 1px ${props => props.theme.platformColor} !important;
  }

  .creatable-multi-select__value-container {
    padding-left: ${props => (props.areManyKeywords ? 12 : 12)}px;
    line-height: initial;
    div {
      display: flex;
      align-items: center;
    }
  }

  .creatable-multi-select__menu {
    /* border-radius: 14px; */
    z-index: 1000;

    .creatable-multi-select__option--is-focused {
      background-color: ${props => props.theme.platformColorLight};
      color: #ffffff;
    }
    .creatable-multi-select__option--is-selected {
      background-color: ${props => props.theme.platformColor};
      color: #ffffff;
    }
  }
  .creatable-multi-select__placeholder {
    font-size: ${props => props.placeholderFontSize || 16}px;
    color: ${props => props.theme.colors.darkBrown};
  }
  .creatable-multi-select__dropdown-indicator {
    color: ${props => props.theme.colors.darkBrown};
  }
`

const SelectWrapper = styled.div`
  position: relative;
  margin: 10px 0 0;
  & > * {
    color: ${props => props.theme.colors.darkBrown};
  }
`
const LabelWrapper = styled.div`
  position: relative;
  width: fit-content;
`

const SelectContainer = styled.div`
  ${props => props.isDisabled && props.theme.disableBlock};
`

const MultiSelectWithoutCreatable = ({
  onChange,
  options = [{}],
  value = [{}],
  faqQuestionId = null,
  id = '',
  labelText = '',
  placeholder = '',
  isEnabled = false,
  selectedPlatform = '',
  upgradeTipText = '',
  showMenuOnClick = true,
  selectHeight = 35,
  showSeparator = false,
}) => {
  const areManyKeywords = value?.length >= 3
  const renderFaqTip = faqQuestionId !== null

  const MultiSelectWithoutCreatableComponent = (
    <SelectContainer isDisabled={!isEnabled}>
      <RoundedCreatableSelect
        id={id}
        isMulti
        onChange={onChange}
        options={options}
        value={value}
        openMenuOnClick={showMenuOnClick}
        className="creatable-multi-select"
        classNamePrefix="creatable-multi-select"
        areManyKeywords={areManyKeywords}
        {...(placeholder && { placeholder: placeholder })}
        placeholderFontSize={placeholder.length >= 29 ? 13 : 15}
        menuShouldScrollIntoView={false}
        cacheOptions
        styles={{
          control: base => ({
            ...base,
            minHeight: selectHeight,
            height: 'fit-content',
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            height: '100%',
            minHeight: '100%',
          }),
          input: (provided, state) => ({
            ...provided,
            height: '100%',
            minHeight: '100%',
            fontSize: 15,
          }),
          indicatorSeparator: state => ({
            display: showSeparator ? 'block' : 'none',
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '100%',
          }),
          menuList: base => ({
          ...base,
          '::-webkit-scrollbar': {
            width: `6px`,
            backgroundColor: `#BC9797`,
            borderRadius: `16px`
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: '#784E4E',
            borderRadius: '16px',
            border: '2px solid #BC9797',
            minHeight: '50px'
          },
          '::-webkit-scrollbar-thumb:hover': {
            border: '0px solid #f4f4f4'
          },
        }),
        }}
      />
    </SelectContainer>
  )

  return (
    <SelectWrapper>
      {labelText && (
        <LabelWrapper>
          <SearchLabel htmlFor={id}>{labelText}</SearchLabel>
          {renderFaqTip && (
            <LinkToFaqQuestion
              faqQuestionId={faqQuestionId}
              linkPositions={{ top: 8, right: -28 }}
              tipIconSize={14}
            />
          )}
        </LabelWrapper>
      )}

      {isEnabled ? (
        MultiSelectWithoutCreatableComponent
      ) : (
        <WithUpgradeTip
          tipText={upgradeTipText}
          userindex={findSocialPlatformIndex(selectedPlatform)}
        >
          {MultiSelectWithoutCreatableComponent}
        </WithUpgradeTip>
      )}
    </SelectWrapper>
  )
}

MultiSelectWithoutCreatable.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.array,
  faqQuestionId: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
  isEnabled: PropTypes.bool,
  selectedPlatform: PropTypes.string,
  upgradeTipText: PropTypes.string,
  showMenuOnClick: PropTypes.bool,
  selectHeight: PropTypes.number,
  showSeparator: PropTypes.bool,
}

export default MultiSelectWithoutCreatable
