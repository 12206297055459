export const applyFilter = ({ inputData, comparator, filterFieldName }) => {
  const stabilizedThis = inputData.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })

  inputData = stabilizedThis.map(el => el[0])

  if (filterFieldName) {
    inputData = inputData.filter(
      field =>
        field.customFieldName?.toLowerCase().indexOf(filterFieldName.toLowerCase()) !== -1 ||
        field.metricName?.toLowerCase().indexOf(filterFieldName.toLowerCase()) !== -1 ||
        field.trackingMethod?.toLowerCase().indexOf(filterFieldName.toLowerCase()) !== -1
    )
  }

  return inputData
}