import React from 'react'
import PropTypes from 'prop-types'

import Popup from 'reactjs-popup'

import { WithPopupWrapper } from '../../common/styledWrappers'
import RemoveItemsFromCollectionDialog from '../../common/dialogs/RemoveItemsFromCollectionDialog'
import ManageCollectionsItemsDialog from '../../common/dialogs/ManageCollectionsItemsDialog'

const CollectionBulkModalsMediator = ({
  openDialogType = '',
  modalsTypes = {},
  closeModalHandler,
  removeItemsHandler,
  manageColelctionsItemsList = [],
  manageCommonHandler,
  removeItemsCount = 0,
}) => {
  const showRemoveDialog = openDialogType === modalsTypes.remove
  const showManageDialog =
    openDialogType === modalsTypes.copy || openDialogType === modalsTypes.move

  return (
    <WithPopupWrapper isTransparent>
      <Popup
        open={!!openDialogType}
        position="right center"
        onClose={closeModalHandler}
        closeOnDocumentClick
        closeOnEscape
        modal
      >
        <>
          {showRemoveDialog && (
            <RemoveItemsFromCollectionDialog
              itemsCount={removeItemsCount}
              close={closeModalHandler}
              removeItemsHandler={removeItemsHandler}
            />
          )}
          {showManageDialog && (
            <ManageCollectionsItemsDialog
              type={openDialogType}
              close={closeModalHandler}
              // collectionsList={collectionDataArr.filter(el => el.collectionId !== collectionId)}
              collectionsList={manageColelctionsItemsList}
              manageHandler={manageCommonHandler}
            />
          )}
        </>
      </Popup>
    </WithPopupWrapper>
  )
}

CollectionBulkModalsMediator.propTypes = {
  openDialogType: PropTypes.string,
  modalsTypes: PropTypes.object,
  closeModalHandler: PropTypes.func,
  removeItemsHandler: PropTypes.func,
  manageColelctionsItemsList: PropTypes.array,
  manageCommonHandler: PropTypes.func,
  removeItemsCount: PropTypes.number,
}

export default CollectionBulkModalsMediator
