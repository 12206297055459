import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'

import useTranslation from '../../../localization/useTranslation'
import { withReduxActions } from '../../../hocs/withRedux'
import { USER_INDEXES } from '../../../constants/appSettings'
import { fetchSimilarAccounts } from '../../../redux/ducks/profileSelectedDucks'
import {
  similarAccountsSelector,
  userGrantSelector,
  isUserAuthenticatedSelector,
} from '../../../redux/selectors'
import { themeCommonStyles } from '../../../themes/infludataThemes'

import InfoTip from '../../common/tips/InfoTip'
import Spinner from '../../common/spinners/Spinner'
import SimilarAccountsTable from './SimilarAccountsTable'
import SimilarAccountsNoResults from './SimilarAccountsNoResults'

import GradientTransparentButton from '../../common/buttons/GradientTransparentButton'
import {
  SectionTitleWrapper,
  SectionTitleName,
  SectionTitleBorder,
} from '../commonProfilePageStyles'
import {
  SimilarAccountsContainer,
  SimilarAccountsMinHeightWrapper,
  ActionButtonsWrapper,
} from './styles'

const SKIP_STEP = 6

const SimilarAccounts = ({ profileId = '', platform, fetchSimilarAccounts }) => {
  const { labelStrings, profileSectionDescs } = useTranslation()

  const { usersList, skipCount, isLoading } = useSelector(similarAccountsSelector)
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const { basic_profile_stats } = useSelector(userGrantSelector, shallowEqual)

  const canFetchSimilarUsers = isUserAuthenticated && profileId && !!basic_profile_stats
  const hasResults = !!usersList.length
  const hasButtonShowMore = hasResults && usersList.length == SKIP_STEP
  const hasButtonShowLess = !!skipCount

  const fetchSimilarUsers = async (skipCount = 0) => {
    if (canFetchSimilarUsers) fetchSimilarAccounts({ skipCount })
  }

  const onShowClick = async (isLess = false) => {
    if (isLess) {
      const newSkipCount = skipCount - SKIP_STEP
      fetchSimilarUsers(newSkipCount > 0 ? newSkipCount : 0)
    } else {
      fetchSimilarUsers(skipCount + SKIP_STEP)
    }
  }

  return (
    <SimilarAccountsContainer>
      <SectionTitleWrapper>
        <SectionTitleName platform={platform}>{labelStrings.similarAccounts}</SectionTitleName>
        <InfoTip>
          <div dangerouslySetInnerHTML={{ __html: profileSectionDescs.similarAccounts }} />
        </InfoTip>
        <SectionTitleBorder />
      </SectionTitleWrapper>

      {(() => {
        if (!canFetchSimilarUsers) {
          return <SimilarAccountsTable platform={platform} isSkeletonMode />
        }
        if (isLoading && !hasResults) {
          return (
            <SimilarAccountsMinHeightWrapper>
              <Spinner isOverlay />
            </SimilarAccountsMinHeightWrapper>
          )
        }
        if (!hasResults) {
          return (
            <SimilarAccountsMinHeightWrapper>
              <SimilarAccountsNoResults />
            </SimilarAccountsMinHeightWrapper>
          )
        }
        return (
          <>
            <SimilarAccountsTable users={usersList} platform={platform} isLoading={isLoading} />

            <ActionButtonsWrapper>
              {hasButtonShowLess && (
                <GradientTransparentButton
                  gradient={themeCommonStyles.getGradientsForPlatform(platform).main}
                  backgroundColor={themeCommonStyles.colors.offWhite}
                  onClick={() => onShowClick(true)}
                >
                  {labelStrings.goBack}
                </GradientTransparentButton>
              )}

              {hasButtonShowMore && (
                <GradientTransparentButton
                  gradient={themeCommonStyles.getGradientsForPlatform(platform).main}
                  backgroundColor={themeCommonStyles.colors.offWhite}
                  onClick={() => onShowClick()}
                >
                  {labelStrings.showMore}
                </GradientTransparentButton>
              )}
            </ActionButtonsWrapper>
          </>
        )
      })()}
    </SimilarAccountsContainer>
  )
}

SimilarAccounts.propTypes = {
  profileId: PropTypes.string,
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  fetchSimilarAccounts: PropTypes.func,
}

export default withReduxActions({ fetchSimilarAccounts })(SimilarAccounts)
