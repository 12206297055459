import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectedPlatformSelector } from '../../../../redux/selectors'
import useDebounce from '../../../../hooks/useDebounce'
import httpService from '../../../../services/httpService'
import { SuggestedCreatorsInput } from './SuggestedCreatorsInput'
import { Stack } from '@mui/material'

const AudienceReportsSearch = ({ isAllowedToAddCreators = false }) => {
  const currentPlatform = useSelector(selectedPlatformSelector)

  const [loadingCreators, setLoadingCreators] = useState(false)
  const [creatorsData, setCreatorsData] = useState([])
  const [notFoundData, setNotFoundData] = useState(false)
  const [error, setError] = useState(false)

  const [inputValue, setInputValue] = useState('')
  const debouncedInputValue = useDebounce(inputValue, 500)

  const fetchSuggestedCreators = async searchString => {
    try {
      setLoadingCreators(true)
      setNotFoundData(false)
      setError(false)
      setCreatorsData([])
      const creatorsUsers = await httpService.fetchAudienceSuggestedUsers(
        searchString,
        currentPlatform?.toLowerCase()
      )
      if (!creatorsUsers?.length) {
        setNotFoundData(true)
      }
      if (creatorsUsers?.length) {
        setCreatorsData(creatorsUsers)
      }
      setLoadingCreators(false)
    } catch (err) {
      setError(true)
      setLoadingCreators(false)
      setCreatorsData([])
      setNotFoundData(false)
    }
  }

  useEffect(() => {
    if (debouncedInputValue === inputValue && debouncedInputValue.length >= 2) {
      fetchSuggestedCreators(debouncedInputValue)
    }
    if (!debouncedInputValue?.length) {
      setCreatorsData([])
      setNotFoundData(false)
      setLoadingCreators(false)
      setError(false)
    }
  }, [debouncedInputValue, currentPlatform])

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <SuggestedCreatorsInput
        creatorsData={creatorsData}
        notFoundData={notFoundData}
        error={error}
        currentPlatform={currentPlatform}
        debouncedInputValue={debouncedInputValue}
        inputValue={inputValue}
        loadingCreators={loadingCreators}
        setInputValue={setInputValue}
        setCreatorsData={setCreatorsData}
        setLoadingCreators={setLoadingCreators}
        setNotFoundData={setNotFoundData}
        isAllowedToAddCreators={isAllowedToAddCreators}
      />
    </Stack>
  )
}

AudienceReportsSearch.propTypes = {
  isAllowedToAddCreators: PropTypes.bool,
}

export default AudienceReportsSearch
