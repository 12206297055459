import { Button, Slider, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useTranslation from '../../../localization/useTranslation'

const decimalToPercentage = decimalValue => {
  return Math.round(decimalValue * 100) + '%'
}

const DEFAULT_POST = 19
const DEFAULT_STORY = 20
const DEFAULT_REEL = 31.5

const DEFAULT_TIKTOK = 13.65

const DEFAULT_VIDEO = 26.25
const DEFAULT_SHORT = 17.85

const CustomSlider = ({
  title,
  value,
  handler,
  minValue = 0.2,
  maxValue = 6,
  stepValue = 0.1,
  marks = false,
  currentCurrency,
  currentValueMin,
  currentValueMax
}) => {
  return (
    <Stack sx={{ width: '100%', maxWidth: '190px' }} justifyContent="center">
      <Stack sx={{ mb: 0.5 }}>
        <Typography textAlign="center" sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
          {title}
        </Typography>
        <Typography sx={{ textTransform: 'uppercase' }} textAlign="center">
          {(currentValueMin*value).toFixed(0)}-{(currentValueMax*value).toFixed(0)} {currentCurrency}
        </Typography>
      </Stack>
      <Slider
        marks={marks}
        min={minValue}
        max={maxValue}
        step={stepValue}
        defaultValue={1}
        value={value}
        onChange={handler}
      />
      <Typography sx={{ color: value < 1 ? 'red' : 'green' }} textAlign="center">
        {decimalToPercentage(value)}
      </Typography>
    </Stack>
  )
}

export const AudienceAnalyticsCpmSettings = props => {
  const { labelStrings } = useTranslation()

  const { onSubmit, initialCpmAdjustments, currentCurrency } = props
  const { ig_posts, ig_reels, ig_storys, tt_videos, yt_shorts, yt_videos } =
    initialCpmAdjustments || {}

  const [instagramValue, setInstagramValue] = useState({
    post: 1,
    story: 1,
    reel: 1,
  })
  const [tikTokValue, setTikTokValue] = useState(1)
  const [ytValue, setYtValue] = useState({
    video: 1,
    shorts: 1,
  })

  useEffect(() => {
    if (ig_posts || ig_reels || ig_storys) {
      setInstagramValue(prev => ({
        ...prev,
        post: ig_posts,
        story: ig_storys,
        reel: ig_reels,
      }))
    }

    if (tt_videos) {
      setTikTokValue(tt_videos)
    }

    if (yt_shorts || yt_videos) {
      setYtValue(prev => ({
        ...prev,
        video: yt_videos,
        shorts: yt_shorts,
      }))
    }
  }, [ig_posts, ig_storys, ig_reels, tt_videos, yt_shorts, yt_videos])

  const handleChangeTikTok = (event, newValue) => {
    if (typeof newValue === 'number') {
      setTikTokValue(newValue)
    }
  }

  const handleChangeInstagram = (newValue, key) => {
    if (typeof newValue === 'number') {
      setInstagramValue(prev => ({
        ...prev,
        [key]: newValue,
      }))
    }
  }

  const handleChangeYt = (newValue, key) => {
    if (typeof newValue === 'number') {
      setYtValue(prev => ({
        ...prev,
        [key]: newValue,
      }))
    }
  }

  const onConfirm = () => {
    onSubmit({
      ig_posts: instagramValue.post,
      ig_storys: instagramValue.story,
      ig_reels: instagramValue.reel,
      tt_videos: tikTokValue,
      yt_videos: ytValue.video,
      yt_shorts: ytValue.shorts,
    })
  }

  return (
    <Stack>
      <Stack>
        <Typography textAlign="center" sx={{ whiteSpace: 'pre-line' }}>
          {labelStrings.cpmSettings.titleInsideModal}
        </Typography>

        <Stack sx={{ my: 2 }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>Instagram</Typography>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{ mt: 0.5 }}
            spacing={3}
          >
            <Stack sx={{ width: '100%' }} justifyContent="center">
              <CustomSlider
                value={instagramValue.post}
                handler={(e, newValue) => handleChangeInstagram(newValue, 'post')}
                title={'Post'}
                currentCurrency={currentCurrency}
                currentValueMin={DEFAULT_POST*0.75}
                currentValueMax={DEFAULT_POST*1.5}
              />
            </Stack>
            <Stack sx={{ width: '100%' }} justifyContent="center">
              <CustomSlider
                value={instagramValue.story}
                handler={(e, newValue) => handleChangeInstagram(newValue, 'story')}
                title={'Story'}
                currentCurrency={currentCurrency}
                currentValueMin={DEFAULT_STORY*0.75}
                currentValueMax={DEFAULT_STORY*1.5}
              />
            </Stack>
            <Stack sx={{ width: '100%' }} justifyContent="center">
              <CustomSlider
                value={instagramValue.reel}
                handler={(e, newValue) => handleChangeInstagram(newValue, 'reel')}
                title={'Reel'}
                currentCurrency={currentCurrency}
                currentValueMin={DEFAULT_REEL*0.75}
                currentValueMax={DEFAULT_REEL*1.5}              />
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Stack sx={{ my: 2, width: '100%', maxWidth: '190px' }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>TikTok</Typography>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              sx={{ mt: 0.5 }}
            >
              <Stack sx={{ width: '100%' }} justifyContent="center">
                <CustomSlider
                  value={tikTokValue}
                  handler={handleChangeTikTok}
                  title={'Video'}
                  currentCurrency={currentCurrency}
                  currentValueMin={DEFAULT_TIKTOK*0.75}
                  currentValueMax={DEFAULT_TIKTOK*1.5}    
                />
              </Stack>
            </Stack>
          </Stack>

          <Stack sx={{ my: 2, width: '100%', maxWidth: '405px' }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>YouTube</Typography>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              sx={{ mt: 0.5 }}
              spacing={3}
            >
              <Stack sx={{ width: '100%' }} justifyContent="center">
                <CustomSlider
                  value={ytValue.video}
                  handler={(e, newValue) => handleChangeYt(newValue, 'video')}
                  title={'Video'}
                  currentCurrency={currentCurrency}
                  currentValueMin={DEFAULT_VIDEO*0.75}
                  currentValueMax={DEFAULT_VIDEO*1.5}    
                />
              </Stack>
              <Stack sx={{ width: '100%' }} justifyContent="center">
                <CustomSlider
                  value={ytValue.shorts}
                  handler={(e, newValue) => handleChangeYt(newValue, 'shorts')}
                  title={'Shorts'}
                  currentCurrency={currentCurrency}
                  currentValueMin={DEFAULT_SHORT*0.75}
                  currentValueMax={DEFAULT_SHORT*1.5}    
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
        <Typography sx={{ fontSize: '12px' }}>{labelStrings.cpmSettings.confirmText}</Typography>
        <Button variant="contained" onClick={onConfirm}>
          {labelStrings.confirm}
        </Button>
      </Stack>
    </Stack>
  )
}
