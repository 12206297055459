import React from 'react'
import PropTypes from 'prop-types'
import { USER_INDEXES } from '../../../../constants/appSettings'
import PrivateNoteTextarea from './PrivateNoteTextarea'
import PrivateNote from './PrivateNote'
import { PrivateNotesContainer, PrivateNoteList } from './styles'
import useTranslation from '../../../../localization/useTranslation'
import WithTip from '../../../common/popups/WithTip'

const sortCommentsByDate = commentsArr =>
  commentsArr?.sort(
    (comment1, comment2) => Date.parse(comment2.timeStamp) - Date.parse(comment1.timeStamp)
  )

const sortCommentsByIsPinned = commentsArr =>
  commentsArr?.sort((comment1, comment2) => +comment2.isPinned - +comment1.isPinned)

const PrivateNotes = ({ platform = '', commentThread = [], isDisabled = false }) => {
  const { labelStrings } = useTranslation()

  const sortedCommentThread = sortCommentsByIsPinned(sortCommentsByDate(commentThread || []))
  const hasComments = !!sortedCommentThread?.length

  return (
    <>
      {!isDisabled ? (
        <WithTip
          tipText={labelStrings.disabledFunctionInYourPlanTip}
          userindex={platform}
          position="bottom center"
        >
          <div>
            <PrivateNotesContainer hasComments={hasComments} isDisabled={!isDisabled}>
              <PrivateNoteTextarea platform={platform} />

              {hasComments && (
                <PrivateNoteList>
                  {sortedCommentThread?.map(commentData => (
                    <PrivateNote
                      key={commentData.code}
                      commentData={commentData}
                      platform={platform}
                    />
                  ))}
                </PrivateNoteList>
              )}
            </PrivateNotesContainer>
          </div>
        </WithTip>
      ) : (
        <PrivateNotesContainer hasComments={hasComments} isDisabled={!isDisabled}>
          <PrivateNoteTextarea platform={platform} />

          {hasComments && (
            <PrivateNoteList>
              {sortedCommentThread.map(commentData => (
                <PrivateNote key={commentData.code} commentData={commentData} platform={platform} />
              ))}
            </PrivateNoteList>
          )}
        </PrivateNotesContainer>
      )}
    </>
  )
}

PrivateNotes.propTypes = {
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  commentThread: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      comment: PropTypes.string,
      isPinned: PropTypes.bool,
      linkToResources: PropTypes.string,
      timeStamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      user: PropTypes.string,
    })
  ),
  isDisabled: PropTypes.bool
}

export default PrivateNotes
