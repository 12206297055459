import React, { forwardRef } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Stack, Divider } from '@mui/material'
import AudienceCountriesCitiesChart from '../../../../components/influencerProfile/audienceAnalysis/audienceAnalysisCharts/AudienceCountriesCitiesChart'
import AudienceGenderChart from '../../../../components/influencerProfile/audienceAnalysis/audienceAnalysisCharts/AudienceGenderChart'
import AudienceLanguageChart from '../../../../components/influencerProfile/audienceAnalysis/audienceAnalysisCharts/AudienceLanguageChart'
import AudienceAgeGroupsChart from '../../../../components/influencerProfile/audienceAnalysis/audienceAnalysisCharts/AudienceAgeGroupsChart'
import AudienceTypeChart from '../../../../components/influencerProfile/audienceAnalysis/audienceAnalysisCharts/AudienceTypeChart'
//import AudienceCommonMentionsChart from '../../../../components/influencerProfile/audienceAnalysis/audienceAnalysisCharts/AudienceCommonMentionsChart'

import GradientTransparentButton from '../../../../components/common/buttons/GradientTransparentButton'
import { OkPagePdfContainer } from '../pdf/OkPagePdfContainer'
import { setIsLikersValue } from '../../../../redux/ducks/audienceReports'

export const OkPage = forwardRef(({ audienceData, isLikers }, ref) => {
  const dispatch = useDispatch()

  const chartsData = isLikers
    ? audienceData?.audienceAnalysisLikers
    : audienceData?.audienceAnalysis

  const platform = audienceData?._index

  const onShowLikers = () => {
    dispatch(setIsLikersValue(true))
  }

  const onHideLikers = () => {
    dispatch(setIsLikersValue(false))
  }

  return (
    <Stack spacing={2} sx={{ px: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack spacing={1} direction="row" alignItems="center">
          <GradientTransparentButton onClick={onHideLikers} isActive={!isLikers}>
            Followers
          </GradientTransparentButton>
          <GradientTransparentButton
            onClick={onShowLikers}
            isActive={isLikers}
            isDisabled={!audienceData?.audienceAnalysisLikers}
          >
            Likers
          </GradientTransparentButton>
        </Stack>

        <OkPagePdfContainer
          pdfRef={ref?.current}
          username={audienceData?.username}
          platform={audienceData?._index}
        />
      </Stack>

      <Stack justifyContent="space-between" spacing={1}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Box sx={{ maxWidth: '500px', width: '100%', maxHeight: '330px', height: '100%' }}>
            <AudienceCountriesCitiesChart
              userindex={platform}
              countryArray={chartsData?.countryArray}
              cityArray={chartsData?.cityArray}
              followersCount={audienceData?.followers}
              isDisabledAnalysis={false}
              isLikersReport={isLikers}
              isSmall
            />
          </Box>
          <Box
            sx={{
              maxWidth: '500px',
              width: '100%',
              maxHeight: '330px',
              height: '100%',
              borderRight: '1px solid #F4E8E8',
              borderLeft: '1px solid #F4E8E8',
              px: 2,
            }}
          >
            <AudienceGenderChart
              userindex={platform}
              genderMtoF={chartsData?.genderMtoF}
              isDisabledAnalysis={false}
              isSmall
            />
          </Box>
          <Box sx={{ maxWidth: '500px', width: '100%', maxHeight: '330px', height: '100%' }}>
            <AudienceTypeChart
              userindex={platform}
              audienceType={chartsData?.audienceType}
              isDisabledAnalysis={false}
              isSmall
            />
          </Box>
        </Stack>

        <Divider
          sx={{ mb: '8px !important', width: '100%', height: '1px', borderColor: '#F4E8E8' }}
        />

        <Stack direction="row" justifyContent="space-between">
          <Box sx={{ maxWidth: '500px', width: '100%', maxHeight: '280px', height: '100%' }}>
            <AudienceLanguageChart
              userindex={platform}
              languageArray={chartsData?.languageArray}
              isDisabledAnalysis={false}
              followersCount={audienceData?.followers}
              isLikersReport={isLikers}
              isSmall
            />
          </Box>
          <Box
            sx={{
              maxWidth: '500px',
              width: '100%',
              maxHeight: '330px',
              height: '100%',
              borderRight: '1px solid #F4E8E8',
              borderLeft: '1px solid #F4E8E8',
              px: 2,
            }}
          >
            <AudienceAgeGroupsChart
              userindex={platform}
              averageBirthyearArray={chartsData?.averageBirthyearArray}
              isDisabledAnalysis={false}
              followersCount={audienceData?.followers}
              birthyearMedian={chartsData?.birthyearMedian}
              isLikersReport={isLikers}
              isSmall
            />
          </Box>
          <Box sx={{ maxWidth: '500px', width: '100%', maxHeight: '280px', height: '100%' }}>
            {/* <AudienceCommonMentionsChart
              userindex={platform}
              mentions={chartsData?.mentions?.slice(0, 7)}
              isDisabledAnalysis={false}
              isSmall
            /> */}
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
})
