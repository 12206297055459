import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import useTranslation from '../../../../localization/useTranslation'
import { pretifyBigNum } from '../../../../utils'

import {
  StyledSmartMediaValueItem,
  SmartMediaValueItemTitleWrapper,
  SmartMediaValueItemTitleBorder,
  SmartMediaValueItemTitle,
  PerStoryWrapper,
  PerStoryRange,
  PerStoryLabel,
  PerViewsWrapper,
  PerViewsRange,
  PerViewsLabel,
} from './styles'

const SmartMediaValueItem = ({ perMediaLabel = '', cpm, isForPdf = '', currency = '', platform = '' }) => {
  const { labelStrings } = useTranslation()
  const currencyTitle = useMemo(() => currency === 'eur' ? labelStrings.inEUR : labelStrings.inUSD, [currency]);
  const cpmPerContentFrom = useMemo(() =>currency === 'eur' ? Math.round(cpm.perContent.from * 0.87) : cpm.perContent.from, [currency, cpm]);
  const cpmPerContentTo = useMemo(() =>currency === 'eur' ? Math.round(cpm.perContent.to * 0.87) : cpm.perContent.to, [currency, cpm]);
  const cpmPerMillFrom = useMemo(() =>currency === 'eur' ? Math.round(cpm.perMill.from * 0.87) : cpm.perMill.from, [currency, cpm]);
  const cpmPerMillTo = useMemo(() =>currency === 'eur' ? Math.round(cpm.perMill.to * 0.87) : cpm.perMill.to, [currency, cpm]);
  
  return (
    <StyledSmartMediaValueItem>
      <SmartMediaValueItemTitleWrapper isForPdf={isForPdf}>
        {!isForPdf && <SmartMediaValueItemTitleBorder />}
        <SmartMediaValueItemTitle>{perMediaLabel}</SmartMediaValueItemTitle>
      </SmartMediaValueItemTitleWrapper>

      <PerStoryWrapper isForPdf={isForPdf}>
        <PerStoryRange isForPdf={isForPdf} platform={platform}>
          {`${pretifyBigNum(cpmPerContentFrom)} - ${pretifyBigNum(cpmPerContentTo)}`}
        </PerStoryRange>
        <PerStoryLabel isForPdf={isForPdf} platform={platform}>{`${perMediaLabel} ${currencyTitle}`}</PerStoryLabel>
      </PerStoryWrapper>

      <PerViewsWrapper isForPdf={isForPdf}>
        <PerViewsRange isForPdf={isForPdf}>{`${cpmPerMillFrom} - ${cpmPerMillTo}`}</PerViewsRange>
        <PerViewsLabel>{`${labelStrings.per1000Views} ${currencyTitle}`}</PerViewsLabel>
      </PerViewsWrapper>
    </StyledSmartMediaValueItem>
  )
}

SmartMediaValueItem.propTypes = {
  perMediaLabel: PropTypes.string,
  cpm: PropTypes.shape({
    perContent: PropTypes.shape({
      from: PropTypes.number,
      to: PropTypes.number,
    }),
    perMill: PropTypes.shape({
      from: PropTypes.number,
      to: PropTypes.number,
    }),
  }).isRequired,
  isForPdf: PropTypes.bool,
  currency: PropTypes.string,
  platform: PropTypes.string
}

export default SmartMediaValueItem
