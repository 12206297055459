import styled from 'styled-components'
import { RoundedInput, Label } from '../index'

export const Form = styled.form`
  ${'' /* background: ${props => props.theme.color.lightPink}; */}
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  ${'' /* padding: 0 50px; */}
`

export const Input = styled(RoundedInput)`
  width: 190px;
  height: 45px;
  padding-left: 20px;
  ${props =>
    props.notValid &&
    `
    border-color: ${props.theme.primaryColor};
  `}
`
export const LongInput = styled(Input)`
  width: 396px;
`
export const PasswordInputWrapper = styled.div`
  position: relative;
  margin-top: 5px;
`

export const PasswordInputIconWrapper = styled.div`
  position: absolute;
  top: -20px;
  right: 21px;
  height: fit-content;
  width: fit-content;
`

export const InputLabel = styled(Label)`
  color: ${props => props.theme.color.lightBlack};
  margin: 0;
  margin-left: 20px;
  margin-top: 10px;
  ${props =>
    props.notValid &&
    `
    color: ${props.theme.primaryColor};
  `}
`
export const NamesWrapper = styled.div`
  display: flex;
`

export const SubmitWrapper = styled.div`
  margin: 15px auto;
`

export const PasswordTipWrapper = styled.div`
  color: ${props => (props.notValid ? props.theme.primaryColor : props.theme.textColor)};
  font-size: 10px;
  font-family: ${props => props.theme.fontFamilyInter};
  text-transform: none;
  letter-spacing: 0px;
`

export const RequiredNote = styled.span`
  color: ${props => props.theme.primaryColor};
  padding: 0 5px;
`

export const CountryAndPhoneWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
`
export const CountrySelectWrapper = styled.div`
  & .creatable-multi-select__control {
    height: 45px;
    width: 190px;
    ${props => props.notValid && `border-color: ${props.theme.primaryColor};`}
  }
`

export const PhoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const SelectWrapper = styled.div`
  height: 70px;
  width: 190px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  & .creatable-multi-select__control {
    height: 45px;
    width: 190px;
    ${props => props.notValid && `border-color: ${props.theme.primaryColor};`}
  }
`

export const CompanySizeAndPositionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
`
