import styled from 'styled-components'

export const SponsoredMediaScoresContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${props => (!props.isForPdf ? 'flex-end' : 'flex-start')};
`

export const SponsoredMediaScoreItems = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1275px) {
    width: 100%;
  }
`

export const StyledSponsoredMediaScore = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const TitleBorder = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.colors.lightBrown};
`

export const Title = styled.div`
  font-size: 15px;
  line-height: 19px;
  font-weight: 700;
  color: ${props => props.theme.colors.darkBrown};
  text-transform: uppercase;
  margin-left: 15px;
  white-space: nowrap;
`

export const ScoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;

  ${props =>
    !props.isForPdf &&
    `
    .comparison-to-similar-influencers-chart {
      margin-right: 100px;
    }

    @media screen and (max-width: 1275px) {
      .comparison-to-similar-influencers-chart {
        margin-right: 25px;
      }
    }
  `}
`

export const ProgressBarWrapper = styled.div`
  width: 110px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  ${props => props.isForPdf && 'margin-left: 50px'}

  .progress_outer {
    width: 100%;
  }
`

export const SponsoredValue = styled.div`
  font-size: 32px;
  font-weight: 600;
  line-height: 34px;
  ${props =>
    props.isForPdf ? `margin-bottom: 20px;
    ` : ''}

  ${props =>
    props.isForPdf ? `color: ${props.theme.primaryColor};` : props.theme.getTextGradientStyles()}
`

export const Label = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: ${props => props.theme.colors.darkBrown};
  text-transform: uppercase;
  text-align: center;
  ${props => props.isForPdf && 'margin-bottom: 8px'};
`
