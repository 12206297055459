import React from 'react'
import PropTypes from 'prop-types'
import SuggestedCreatorsInputContainer from '../../components/suggested-сreators'
import {
  Box,
  Avatar,
  Dialog,
  Button,
  ListItem,
  Typography,
  ListItemText,
  ListItemAvatar,
  DialogTitle,
  DialogContent,
} from '@mui/material'
import Scrollbar from '../../components/scrollbar/Scrollbar'
import Iconify from '../../components/iconify/Iconify'
import { SearchNotFound } from '../../components/search-not-found/SearchNotFound'

export const PipelineAssignedToDialog = ({
  open,
  onClose,
  campaignId,
  assignCreators,
  onAssignCreators,
  onUnsignedCreators,
}) => {
  const isNotFound = !assignCreators?.length

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ pb: 0 }}>
        Assigned Creators <Typography component="span">({assignCreators?.length})</Typography>
      </DialogTitle>

      <Box sx={{ px: 3, py: 2.5 }}>
        <SuggestedCreatorsInputContainer
          campaignId={campaignId}
          creatorsTableList={assignCreators}
          onAssignCreators={onAssignCreators}
          isAllowedToAddCreators={true}
          isPipeline
        />
      </Box>

      <DialogContent sx={{ p: 0, pb: 2 }}>
        {isNotFound ? (
          <SearchNotFound sx={{ mt: 3, mb: 30 }} />
        ) : (
          <Scrollbar
            sx={{
              px: 2,
              height: '375px',
            }}
          >
            {assignCreators?.map(creator => {
              return (
                <ListItem
                  key={typeof creator === 'string' ? creator : creator._id}
                  secondaryAction={
                    <Button
                      onClick={() =>
                        onUnsignedCreators(typeof creator === 'string' ? creator : creator._id)
                      }
                      size="small"
                      color={'inherit'}
                      startIcon={<Iconify icon={'clarity:remove-line'} />}
                    >
                      Unsigned
                    </Button>
                  }
                  sx={{ height: 64 }}
                >
                  <ListItemAvatar>
                    <Avatar src={creator.profilePicURL} />
                  </ListItemAvatar>

                  <ListItemText
                    primaryTypographyProps={{ typography: 'subtitle2', sx: { mb: 0.25 } }}
                    secondaryTypographyProps={{ typography: 'caption' }}
                    primary={creator.displayName}
                    secondary={creator.username}
                  />
                </ListItem>
              )
            })}
          </Scrollbar>
        )}
      </DialogContent>
    </Dialog>
  )
}

PipelineAssignedToDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  assignCreators: PropTypes.array,
  onAssignCreators: () => {},
  onUnsignedCreators: () => {},
}
