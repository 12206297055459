import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { USER_INDEXES } from '../../../../constants/appSettings'
import useTranslation from '../../../../localization/useTranslation'
import { selectedProfileSelector } from '../../../../redux/selectors'
import {
  constructLinkToWECFileServer,
  convertValueToPercentage,
  getUrlWithProxy,
  pretifyBigNum,
} from '../../../../utils'
import ProfileAvatar from '../../../profileCommon/profileAvatar'
import FollowersHistory from '../../followersHistory/FollowersHistory'
import ViewsPerPost from '../../viewsPerPost/ViewsPerPost'
import { themeCommonStyles } from '../../../../themes/infludataThemes'
import PageLayout from '../PageLayout/PageLayout'
import { Section, SectionTitle } from '../commonStyles'
import * as Styled from './styles'
import { prepareProfileChartData } from '../../../../utils/profileCharts'

const ProfileOverviewPage = ({
  setIsFollowersChartReady,
  setIsViewsPerPostChartReady,
  isBranded = true,
  isShowFollowerTrendGraph = true,
}) => {
  const { profile } = useSelector(selectedProfileSelector)
  const {
    _id: profileId,
    username,
    _index: platform,
    profileScore,
    profilePicURL,
    displayName,
    description,
    followers,
    following,
    posts,
    engagementMean,
    monthlyGrowthFollowers,
    dataLog,
    engagementRateShorts,
    engagementRatePosts,
    medianViewCountShorts,
    medianViewCountPosts,
  } = profile || {}

  const { labelStrings } = useTranslation()

  const avatarSrc = useMemo(() => getUrlWithProxy(constructLinkToWECFileServer(profilePicURL)), [
    profilePicURL,
  ])

  let score
  switch (platform) {
    case USER_INDEXES.instagram:
      score = profileScore.overallScore
      break
    case USER_INDEXES.tiktok:
      score = profileScore.overallScore
      break
    case USER_INDEXES.youtube:
      score = profileScore.overallScore
      break
  }

  const renderFollowerTrendGraph = {
    [USER_INDEXES.instagram]: (
      <Section>
        <SectionTitle platform={platform}>{labelStrings.followersHistory}</SectionTitle>
        <FollowersHistory
          chartData={prepareProfileChartData(dataLog, platform).followers}
          platform={platform}
          monthlyGrowthFollowers={monthlyGrowthFollowers}
          onChartReadyCallback={() => setIsFollowersChartReady(true)}
          isForPdf
        />
      </Section>
    ),
    [USER_INDEXES.tiktok]: (
      <Section>
        <SectionTitle platform={platform}>{labelStrings.viewsPerPost}</SectionTitle>
        <ViewsPerPost
          chartData={prepareProfileChartData(dataLog, platform).views}
          platform={platform}
          monthlyGrowthFollowers={profile.monthlyGrowthFollowers}
          onChartReadyCallback={() => setIsViewsPerPostChartReady(true)}
          isForPdf
        />
      </Section>
    ),
    [USER_INDEXES.youtube]: (
      <Section>
        <SectionTitle platform={platform}>{labelStrings.followersHistory}</SectionTitle>
        <FollowersHistory
          chartData={prepareProfileChartData(dataLog, platform).followers}
          platform={platform}
          monthlyGrowthFollowers={monthlyGrowthFollowers}
          onChartReadyCallback={() => setIsFollowersChartReady(true)}
          isForPdf
        />
      </Section>
    ),
  }

  return (
    <PageLayout profileId={profileId} username={username} platform={platform} isBranded={isBranded}>
      <Styled.ProfileOverview>
        <Styled.AvatarWrapper>
          <ProfileAvatar
            score={score}
            profilePicUrl={avatarSrc}
            userindex={platform}
            unfilledAreaBgColor={themeCommonStyles.getColorPaletteForPlatform(platform).dark}
            size={150}
            withRating
            withGradient
            withShadow={false}
            animated={false}
            isForPdf
          />
        </Styled.AvatarWrapper>

        <Styled.ProfileInfo>
          <Styled.FieldLabel>{labelStrings.username}</Styled.FieldLabel>
          <Styled.Username platform={platform}>{username}</Styled.Username>

          <Styled.FieldLabel>{labelStrings.nameNBio}</Styled.FieldLabel>
          <Styled.DisplayName platform={platform}>{displayName}</Styled.DisplayName>

          <Styled.Description>{description}</Styled.Description>
        </Styled.ProfileInfo>
      </Styled.ProfileOverview>

      <Styled.Statistics>
        <Styled.StatisticsBlock>
          <Styled.FieldLabel>{labelStrings.profileData}</Styled.FieldLabel>
          {platform === USER_INDEXES.youtube ? (
            <>
              <Styled.StatisticItem>
                <Styled.StatisticsLabel>{labelStrings.subscribers}: </Styled.StatisticsLabel>
                <Styled.StatisticsValue platform={platform}>
                  {pretifyBigNum(followers)}
                </Styled.StatisticsValue>
              </Styled.StatisticItem>

              <Styled.StatisticItem>
                <Styled.StatisticsLabel>{labelStrings.videos}: </Styled.StatisticsLabel>
                <Styled.StatisticsValue platform={platform}>
                  {pretifyBigNum(posts)}
                </Styled.StatisticsValue>
              </Styled.StatisticItem>
            </>
          ) : (
            <>
              <Styled.StatisticItem>
                <Styled.StatisticsLabel>{labelStrings.followers}: </Styled.StatisticsLabel>
                <Styled.StatisticsValue platform={platform}>
                  {pretifyBigNum(followers)}
                </Styled.StatisticsValue>
              </Styled.StatisticItem>

              <Styled.StatisticItem>
                <Styled.StatisticsLabel>{labelStrings.following}: </Styled.StatisticsLabel>
                <Styled.StatisticsValue platform={platform}>
                  {pretifyBigNum(following)}
                </Styled.StatisticsValue>
              </Styled.StatisticItem>

              <Styled.StatisticItem>
                <Styled.StatisticsLabel>{labelStrings.posts}: </Styled.StatisticsLabel>
                <Styled.StatisticsValue platform={platform}>{posts}</Styled.StatisticsValue>
              </Styled.StatisticItem>
            </>
          )}
        </Styled.StatisticsBlock>

        <Styled.StatisticsBlock>
          {platform !== USER_INDEXES.youtube && (
            <Styled.FieldLabel>{labelStrings.statistics}</Styled.FieldLabel>
          )}

          {(() => {
            switch (platform) {
              case USER_INDEXES.instagram:
                const {
                  medianViewsPerPost,
                  medianViewsPerStoryObject,
                  medianPlaysPerReel,
                  engagementReels,
                } = profile

                const medianViewsPerPostToDisplay = medianViewsPerPost
                  ? pretifyBigNum(medianViewsPerPost)
                  : '-'
                let medianViewsPerStoryToDisplay = ''
                if (medianViewsPerStoryObject) {
                  const { from, to } = medianViewsPerStoryObject
                  medianViewsPerStoryToDisplay = `${pretifyBigNum(from)} - ${pretifyBigNum(to)}`
                }
                const hasEngagementReels = engagementReels && parseFloat(engagementReels) !== 0

                return (
                  <>
                    <Styled.StatisticItem>
                      <Styled.StatisticsLabel>{labelStrings.viewsPerPost}: </Styled.StatisticsLabel>
                      <Styled.StatisticsValue platform={platform}>
                        {medianViewsPerPostToDisplay}
                      </Styled.StatisticsValue>
                    </Styled.StatisticItem>

                    {medianViewsPerStoryToDisplay && (
                      <Styled.StatisticItem>
                        <Styled.StatisticsLabel>
                          {labelStrings.viewsPerStory}:{' '}
                        </Styled.StatisticsLabel>
                        <Styled.StatisticsValue platform={platform}>
                          {medianViewsPerStoryToDisplay}
                        </Styled.StatisticsValue>
                      </Styled.StatisticItem>
                    )}

                    {medianPlaysPerReel && (
                      <Styled.StatisticItem>
                        <Styled.StatisticsLabel>
                          {labelStrings.playsPerReel}:{' '}
                        </Styled.StatisticsLabel>
                        <Styled.StatisticsValue platform={platform}>
                          {pretifyBigNum(medianPlaysPerReel)}
                        </Styled.StatisticsValue>
                      </Styled.StatisticItem>
                    )}

                    <Styled.StatisticItem>
                      <Styled.StatisticsLabel>
                        {labelStrings.engagementPosts}:{' '}
                      </Styled.StatisticsLabel>
                      <Styled.StatisticsValue platform={platform}>
                        {engagementMean || '-'}
                      </Styled.StatisticsValue>
                    </Styled.StatisticItem>

                    {hasEngagementReels && (
                      <Styled.StatisticItem>
                        <Styled.StatisticsLabel>
                          {labelStrings.engagementReels}:{' '}
                        </Styled.StatisticsLabel>
                        <Styled.StatisticsValue platform={platform}>
                          {engagementReels || '-'}
                        </Styled.StatisticsValue>
                      </Styled.StatisticItem>
                    )}
                  </>
                )
              case USER_INDEXES.tiktok:
                const { totalViews } = profile
                const totalViewsToDisplay = totalViews ? pretifyBigNum(totalViews) : '-'

                return (
                  <>
                    <Styled.StatisticItem>
                      <Styled.StatisticsLabel>{labelStrings.engagement}: </Styled.StatisticsLabel>
                      <Styled.StatisticsValue platform={platform}>
                        {engagementMean}
                      </Styled.StatisticsValue>
                    </Styled.StatisticItem>

                    <Styled.StatisticItem>
                      <Styled.StatisticsLabel>{labelStrings.totalPlays}: </Styled.StatisticsLabel>
                      <Styled.StatisticsValue platform={platform}>
                        {totalViewsToDisplay}
                      </Styled.StatisticsValue>
                    </Styled.StatisticItem>
                  </>
                )
              case USER_INDEXES.youtube:
                return (
                  <Styled.StatisticsBlockRow>
                    <Styled.StatisticsBlockWithoutMargin>
                      <Styled.FieldLabel>{labelStrings.statisticsShorts}</Styled.FieldLabel>
                      <Styled.StatisticItem>
                        <Styled.StatisticsLabel>{labelStrings.engagement}: </Styled.StatisticsLabel>
                        <Styled.StatisticsValue platform={platform}>
                          {engagementRateShorts
                            ? convertValueToPercentage(engagementRateShorts)
                            : '-'}
                        </Styled.StatisticsValue>
                      </Styled.StatisticItem>

                      <Styled.StatisticItem>
                        <Styled.StatisticsLabel>
                          {labelStrings.viewsMedian}:{' '}
                        </Styled.StatisticsLabel>
                        <Styled.StatisticsValue platform={platform}>
                          {medianViewCountShorts ? pretifyBigNum(medianViewCountShorts) : '-'}
                        </Styled.StatisticsValue>
                      </Styled.StatisticItem>
                    </Styled.StatisticsBlockWithoutMargin>

                    <Styled.StatisticsBlockWithoutMargin>
                      <Styled.FieldLabel>{labelStrings.statisticsVideos}</Styled.FieldLabel>
                      <Styled.StatisticItem>
                        <Styled.StatisticsLabel>{labelStrings.engagement}: </Styled.StatisticsLabel>
                        <Styled.StatisticsValue platform={platform}>
                          {engagementRatePosts
                            ? convertValueToPercentage(engagementRatePosts)
                            : '-'}
                        </Styled.StatisticsValue>
                      </Styled.StatisticItem>

                      <Styled.StatisticItem>
                        <Styled.StatisticsLabel>
                          {labelStrings.viewsMedian}:{' '}
                        </Styled.StatisticsLabel>
                        <Styled.StatisticsValue platform={platform}>
                          {medianViewCountPosts ? pretifyBigNum(medianViewCountPosts) : '-'}
                        </Styled.StatisticsValue>
                      </Styled.StatisticItem>
                    </Styled.StatisticsBlockWithoutMargin>
                  </Styled.StatisticsBlockRow>
                )
              default:
                return null
            }
          })()}
        </Styled.StatisticsBlock>
      </Styled.Statistics>
      {isShowFollowerTrendGraph && renderFollowerTrendGraph[platform]}
    </PageLayout>
  )
}

ProfileOverviewPage.propTypes = {
  setIsFollowersChartReady: PropTypes.func,
  setIsViewsPerPostChartReady: PropTypes.func,
  isBranded: PropTypes.bool,
  isShowFollowerTrendGraph: PropTypes.bool,
}

export default ProfileOverviewPage
