import React from 'react'
import { Stack, Box, Typography } from '@mui/material'
import { CreatorsList } from '../mentionedProfiles/creatorsTableView'
import { PDF_INFLUDATA_LOGO } from '../../../../common/icons'
import { gradientTextSVG } from './utils'

export const CreatorTablePdfPage = props => {
  const { mentionAccountsAndTimeline, username } = props || {}
  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 1, mb: 3 }}
      >
        <Stack>
          <Typography
            sx={{
              fontSize: '50px',
              fontWeight: 700,
              fontFamily: 'Inter',
              color: '#784E4E',
              lineHeight: '78px',
              marginBottom: '20px'
            }}
          >
            Creator Index: {username}
          </Typography>
          <Typography
            sx={{
              fontSize: '20',
              fontWeight: 600,
              fontFamily: 'Inter',
              color: '#784E4E',
              lineHeight: '5px',
            }}
          >
            Creator Marketing Analytics on Instagram - last 12 months
          </Typography>
        </Stack>
        <Box sx={{ width: '214px', height: '77px' }}>
          <PDF_INFLUDATA_LOGO />
        </Box>
      </Stack>

      <CreatorsList
        data={mentionAccountsAndTimeline}
        isLoading={false}
        isForPdf
        isBrandPerformancePdf
      />
    </Stack>
  )
}
