import React, { useEffect, useState } from 'react'
import { validateEmail } from '../../../utils'
import useTranslation from '../../../localization/useTranslation'
import { EMAIL_VALID_REGEX } from '../../../constants/login'
import { Form } from '../../../components/common/styledWrappers/signupFormsStyles/signupStepOneFormStyles'
import { Stack, TextField, Typography, Box } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import MailOutlinedIcon from '@mui/icons-material/MailOutlined'
import { useDispatch, useSelector } from 'react-redux'
import { onUserLoginByMagicLink } from '../../../redux/ducks/audienceReports'
import { audienceReportsSelector } from '../../../redux/selectors'
import { PDF_INFLUDATA_LOGO } from '../../../components/common/icons'


const AudienceReportsAuth = () => {
  const { labelStrings } = useTranslation()
  const dispatch = useDispatch()

  const { user } = useSelector(audienceReportsSelector) || {}

  const [formState, changeFormState] = useState({
    email: '',
    isValidEmail: true,
    errorText: '',
  })

  const [isLoading, setIsLoading] = useState(false)

  const isEmailInvalid = !formState.isValidEmail
  const hasErrorText =
    !!formState.errorText?.length &&
    formState.errorText !== labelStrings.signupLocalization.workEmailAddress

  const changeEmail = ({ target }) => changeFormState({ ...formState, email: target.value })
  const onFocusInput = () =>
    !formState.isValidEmail && changeFormState({ ...formState, isValidEmail: true })

  const submitForm = async e => {
    e.preventDefault()
    const isValidEmail = !!formState.email && validateEmail(formState.email.trim())
    if (isValidEmail) {
      const { email } = formState
      //submit action, send email to MagicLink and get auth token
      setIsLoading(true)
      // @ts-ignore
      dispatch(onUserLoginByMagicLink(email))
      changeFormState({ ...formState, isValidEmail: true })
    }
  }

  const onBlurEmailInput = () => {
    if (formState.email.trim() === '') {
      changeFormState({
        ...formState,
        errorText: labelStrings.signupLocalization.workEmailAddress,
      })
    } else if (!formState.email.match(EMAIL_VALID_REGEX)) {
      changeFormState({
        ...formState,
        errorText: labelStrings.signupLocalization.emailError,
      })
    } else {
      changeFormState({
        ...formState,
        errorText: '',
      })
    }
  }

  // If the user exists then disable loading
  useEffect(() => {
    if (isLoading && user?.email) {
      setIsLoading(false)
    }
  }, [user?.email, isLoading])

  return (
    <Form onSubmit={submitForm}>
      <Stack alignItems="center" justifyContent="center" sx={{ width: '100%', height: '100%' }}>
        <Stack spacing={3} sx={{ width: '400px', p: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <PDF_INFLUDATA_LOGO width={220} height={100} />
          </Box>
          <Typography variant="h5" align="center" gutterBottom>
            Instagram Audience Reports
          </Typography>
          <Typography variant="body1" align="center">
            Please log in or sign up with your email to get access to our Instagram Audience Reports.
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <MailOutlinedIcon
              sx={{
                color: isEmailInvalid || hasErrorText ? '#FF5630' : 'action.active',
                mr: 1,
                my: 0.5,
              }}
            />
            <TextField
              variant="standard"
              sx={{
                width: '100%',
                '& .MuiFormHelperText-root': {
                  fontSize: '15px',
                },
                '& .MuiInput-input': {
                  fontSize: '18px',
                  fontFamily: 'Inter, sans-serif',
                },
              }}
              label={labelStrings.email}
              id="signup-email"
              type="text"
              size="medium"
              onChange={changeEmail}
              onFocus={onFocusInput}
              onBlur={onBlurEmailInput}
              value={formState.email}
              error={isEmailInvalid || hasErrorText}
              helperText={
                isEmailInvalid ? labelStrings.reenterNotValidEmail : formState.errorText || null
              }
              autoFocus
            />
          </Stack>
          <LoadingButton loading={isLoading} variant="contained" onClick={submitForm}>
            {labelStrings.confirm}
          </LoadingButton>
          <Typography variant="caption" align="center">
            Please contact us via support@infludata.com if you have a subscription with us, but can't access your account.
          </Typography>
        </Stack>
      </Stack>
    </Form>
  )
}

export default AudienceReportsAuth