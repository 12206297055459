import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { CSVLink } from 'react-csv'
import useTranslation from '../../localization/useTranslation'
import EditCollectionModal from '../collections/collectionTools/EditCollectionModal'
import DeleteCollectionModal from '../collections/collectionTools/DeleteCollectionModal'
import httpService from '../../services/httpService'

const Wrapper = styled.div`
  z-index: 200;
`

const csvHttpRequest = async collectionId => {
  const response = await httpService.fetchCSV(collectionId)
  const { data = [], header } = await response.json()
  return { data, header }
}

const CollectionOptionsRenderer = ({
  collection,
  deleteCollection,
  isEditOpen = false,
  isDeleteOpen = false,
  getCsv = false,
  resetChosenItem,
}) => {
  const { collectionId, name: collectionName } = collection
  const collectionDate = new Date().toLocaleDateString()
  const [state, setState] = useState({ header: null, data: null })
  const csvButtonRef = useRef()
  const { labelStrings } = useTranslation()

  useEffect(() => {
    if (getCsv) {
      getCSVData(collectionId)
    }
  }, [getCsv])

  useEffect(() => {
    if (state.data && state.header) {
      setTimeout(() => {
        csvButtonRef.current.link.click();
      }, 100); // Introducing a delay of 50 milliseconds
    }
  }, [state.data, state.header]);


  const getCSVData = async collectionId => {
    try {
      const { data, header } = await csvHttpRequest(collectionId)
      setState({ data, header })
    } catch (err) {
      toast(labelStrings.failGetCSV)
    }
  }

  const onDeleteCollection = () => {
    deleteCollection()
    resetChosenItem()
  }

  return (
    <Wrapper>
      {isEditOpen && (
        <EditCollectionModal collectionData={collection} closeModal={resetChosenItem} />
      )}
      {isDeleteOpen && (
        <DeleteCollectionModal
          collectionName={collectionName}
          closeModal={resetChosenItem}
          deleteCollection={onDeleteCollection}
        />
      )}
      <>
        {state.data && (
          <CSVLink
            ref={csvButtonRef}
            headers={state.header}
            data={state.data}
            filename={`${collectionName}-${collectionDate}.csv`}
            style={{ display: 'none' }}
            onClick={resetChosenItem}
          >
            {labelStrings.exportAsCSV}
          </CSVLink>
        )}
      </>
    </Wrapper>
  )
}

CollectionOptionsRenderer.propTypes = {
  collection: PropTypes.object.isRequired,
  editCollection: PropTypes.func,
  deleteCollection: PropTypes.func,
  isEditOpen: PropTypes.bool,
  isDeleteOpen: PropTypes.bool,
  getCsv: PropTypes.bool,
  resetChosenItem: PropTypes.func,
}

export default CollectionOptionsRenderer