import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Chart } from 'react-google-charts'
import LoadingSpinner from '../../../../../../common/spinners/LoadingSpinner'
import NotEnoughDataForChartMessage from '../../../../../../charts/NotEnoughDataForChartMessage'

const PieChartWithLegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  min-height: 220px;
`

const ChartContainer = styled.div`
  max-height: ${props => props.chartHeight} || 250px;
  max-width: ${props => props.chartWidth} || 250px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
  position: relative;
  text {
    font-family: Inter, sans-serif;
  }
  #labelOverlay {
    width: 90px;
    height: 45px;
    position: absolute;
    top: 101px;
    left: 80px;
    text-align: center;
    cursor: default;
  }
`

const CustomLegend = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 20px;
`

const CustomLegendItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const CustomLegendColormark = styled.div`
  width: 18px;
  height: 18px;
  background-color: ${props => props.color};
  border-radius: 5px;
  margin-top: 18px;
`

const CustomLegendText = styled.div`
  font-size: 13px;
  line-height: 16px;
  margin-left: 5px;
  ${props =>
    props.isDisabled
      ? `
    color: ${props.theme.colors.lightBrown};
    background-color: ${props.theme.colors.lightBrown};
    user-select: none;
  `
      : `
    color: ${props.theme.colors.darkBrown};
  `}
`

const CustomLegendPercentage = styled.div`
  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
  margin-left: 5px;
  ${props =>
    props.isDisabled
      ? `
    color: ${props.theme.colors.lightBrown};
    background-color: ${props.theme.colors.lightBrown};
    user-select: none;
  `
      : `
    color: ${props.theme.colors.darkBrown};
  `}
`

const PdfPieChart = ({
  chartData,
  withCustomLegend,
  userindex = '',
  isDisabledChart = true,
  notEnoughData = false,
  isForPdf = false,
  isBrandValuePerformancePdf,
  sumValues = 0,
  totalValue = 0,
  chartHeight = 250,
  chartWidth = 250,
}) => {
  const isChartDisabled = isDisabledChart || notEnoughData
  const createCustomLegend = () => {
    return chartData.values?.reduce((arr, item, idx) => {
      if (idx) {
        arr.push(
          <CustomLegendItem isForPdf={isForPdf} key={item[0] + item[1]}>
            <CustomLegendColormark isForPdf={isForPdf} color={chartData.colors[idx - 1]} />
            <CustomLegendText
              isBrandValuePerformancePdf={isBrandValuePerformancePdf}
              isDisabled={isChartDisabled}
            >
              {item[0]}
            </CustomLegendText>
            <CustomLegendPercentage
              isBrandValuePerformancePdf={isBrandValuePerformancePdf}
              isDisabled={isChartDisabled}
            >
              {`${((item[1] / sumValues) * 100)?.toFixed(0)}%`}{' '}
            </CustomLegendPercentage>
            <CustomLegendText
              style={{ fontWeight: 600, fontSize: '10px', marginTop: '4px' }}
              isBrandValuePerformancePdf={isBrandValuePerformancePdf}
              isDisabled={isChartDisabled}
            >
              ({item[1]} creators)
            </CustomLegendText>
          </CustomLegendItem>
        )
      }

      return arr
    }, [])
  }

  return (
    <PieChartWithLegendContainer
      withCustomLegend={withCustomLegend}
      isDisabled={isChartDisabled}
      isForPdf={isForPdf}
      isBrandValuePerformancePdf={isBrandValuePerformancePdf}
    >
      {withCustomLegend && (
        <CustomLegend isForPdf={isForPdf} lengthItems={chartData?.values?.length}>
          {createCustomLegend()}
        </CustomLegend>
      )}
      <ChartContainer chartWidth={chartWidth} chartHeight={chartHeight}>
        <Chart
          className="pie-chart"
          chartType="PieChart"
          loader={<LoadingSpinner />}
          data={chartData.values}
          options={{
            fontName: 'Inter, sans-serif',
            backgroundColor: 'transparent',
            enableInteractivity: false,
            pieHole: 0.4,
            colors: chartData.colors,
            legend: {
              height: 200,
              position: withCustomLegend || isChartDisabled ? 'none' : 'left',
              alignment: 'center',
              textStyle: {
                color: '#272727',
                fontSize: 12,
                fontName: 'Inter',
              },
            },
            width: chartWidth || 230,
            height: chartHeight || 230,
            pieSliceText: isChartDisabled ? 'none' : 'percentage',
            pieSliceTextStyle: {
              color: '#ffffff',
              fontSize: 11,
              fontName: 'Inter, sans-serif',
              bold: true,
            },
            tooltip: {
              trigger: 'selection',
              showColorCode: true,
              textStyle: {
                color: '#272727',
                fontSize: 12,
                fontName: 'Inter, sans-serif',
              },
            },
            sliceVisibilityThreshold: 0.005,
          }}
          rootProps={{ 'data-testid': '3' }}
        />
        {!!totalValue && (
          <div id="labelOverlay">
            <CustomLegendText
              style={{ marginLeft: 0, fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
            >
              {totalValue}
            </CustomLegendText>
            <CustomLegendText
              style={{ marginLeft: 0, fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}
            >
              Median
            </CustomLegendText>
          </div>
        )}
      </ChartContainer>
      {notEnoughData && <NotEnoughDataForChartMessage platform={userindex} isForPdf={isForPdf} />}
    </PieChartWithLegendContainer>
  )
}

PdfPieChart.propTypes = {
  chartData: PropTypes.object.isRequired,
  withCustomLegend: PropTypes.bool,
  isDisabledChart: PropTypes.bool,
  notEnoughData: PropTypes.bool,
  sumValues: PropTypes.number,
  totalValue: PropTypes.number,
  chartHeight: PropTypes.number,
  chartWidth: PropTypes.number,
}

export default PdfPieChart
