import React from 'react'
import {
  PickersLayoutContentWrapper,
  PickersLayoutRoot,
  pickersLayoutClasses,
  usePickerLayout,
} from '@mui/x-date-pickers-pro'
import { Box } from '@mui/material'

export const CustomLayout = props => {
  const { content, shortcuts } = usePickerLayout(props)
  return (
    <PickersLayoutRoot
      ownerState={props}
      sx={{
        overflow: 'auto',
        [`.${pickersLayoutClasses.shortcuts}`]: {
          gridColumn: 1,
          gridRow: 1,
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
        {shortcuts}
        <PickersLayoutContentWrapper className={pickersLayoutClasses.contentWrapper}>
          {content}
        </PickersLayoutContentWrapper>
      </Box>
    </PickersLayoutRoot>
  )
}
