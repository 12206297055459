import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useTranslation from '../../../localization/useTranslation'
import WithTip from '../../common/popups/WithTip'
import { ForbiddenDropTipWrapper } from './collectionsSidebarStyles'

const Wrapper = styled.div`
  & .popup-content {
    & > * {
      z-index: 11000 !important;
    }
    // min-height: fit-content;
    padding-bottom: 10px !important;
    padding-top: 10px !important;
    position: fixed !important;
    z-index: 11000 !important;
    top: ${props =>
      props.popupCords && props.popupCords.top ? props.popupCords.top - 45 : 0}px !important;
    left: ${props =>
      props.popupCords && props.popupCords.right ? props.popupCords.right - 45 : 0}px !important;
  }
`

const TipInnerComponent = styled.div`
  position: absolute;
  // ${props => props.theme.flex.centerAll}
  height: 20px;
  width: 20px;
`

const ForbiddenDropTip = ({ canDragItemToCollection = {}, activeForDropCollectionId = null }) => {
  const [forbiddenDropItemCords, setForbiddenItemCords] = useState({})

  useEffect(() => {
    if (activeForDropCollectionId) {
      const el = document.getElementById(activeForDropCollectionId)
      if (el) {
        const cords = el.getBoundingClientRect()
        setForbiddenItemCords(cords)
      }
    }
  }, [activeForDropCollectionId])

  const { labelStrings } = useTranslation()

  return (
    <Wrapper popupCords={forbiddenDropItemCords}>
      {canDragItemToCollection.forbidden && (
        <WithTip
          position="top left"
          TipComponent={
            <ForbiddenDropTipWrapper>
              <span>{labelStrings.notAllowedToDropToCollection}.</span>
              <span>{canDragItemToCollection.reason}</span>
            </ForbiddenDropTipWrapper>
          }
          defaultOpen
        >
          <TipInnerComponent />
        </WithTip>
      )}
    </Wrapper>
  )
}

ForbiddenDropTip.propTypes = {
  canDragItemToCollection: PropTypes.object,
  activeForDropCollectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
}

export default ForbiddenDropTip
