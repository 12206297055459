import React, { useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { RangeSlidersToggleButton } from '../buttons/styledButtons'
import CommonMultiRangeSlider from './CommonMultiRangeSlider'
import { pretifyBigNum } from '../../../utils'
import { SEARCH_SLIDERS_RANGE } from '../../../constants/search'
import LinkToFaqQuestion from '../../faqs/LinkToFaqQuestion'
import useTranslation from '../../../localization/useTranslation'
import { SOCIAL_PLATFORMS_NAMES } from '../../../constants/appSettings'

const defaultRangesArr = [SEARCH_SLIDERS_RANGE.min, SEARCH_SLIDERS_RANGE.max]

const SlidersWrapper = styled.div`
  margin-top: 15px;
  position: relative;
`

const SliderNameWrapper = styled.div`
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 1.65px;
  color: ${props => props.theme.colors.darkBrown};
  display: flex;
  ${props => (props.isSingle ? `justify-content: space-between;` : 'justify-content: space-evenly')}
`

const SliderReachWrapper = styled.div`
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 1.65px;
  color: ${props => props.theme.colors.mauve};
  display: flex;
  justify-content: flex-start;
  padding-left: 12px;
`

const SliderButtonWithInfo = styled(RangeSlidersToggleButton)`
  position: relative;
  font-size: 10px;
  font-weight: 700;
  border-radius: 8px;
  height: unset;
  padding: 3.5px 6px;
  font-family: ${props => props.theme.fontFamilyInter};
`

const SelectedValuesWrapper = styled.div`
  color: ${props => props.theme.platformColor};
  font-weight: bold;
  font-size: 10px;
  position: relative;
  top: -7px;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
`

const SearchRangeSlidersGroup = ({
  changeFollowers,
  changePlays,
  changeViews,
  changePlaysPerReel,
  changeVideoViews,
  changeShortsViews,
  followersValues = defaultRangesArr,
  playsValues = defaultRangesArr,
  viewsValues = defaultRangesArr,
  playsPerReelValues = defaultRangesArr,
  videoViewsValues,
  shortsViewValues,
  faqQuestionId = null,
  selectedPlatform = '',
  query = {},
  isCreator = true,
  isContentFilter = false
}) => {
  const { labelStrings } = useTranslation()

  const createSlidersMap = useCallback(
    () => ({
      followers: {
        name: 'followers',
        label:
          selectedPlatform === SOCIAL_PLATFORMS_NAMES.youtube.name
            ? labelStrings.subscribers
            : labelStrings.followers,
        handler: changeFollowers,
        values: followersValues,
      },
      plays: {
        name: 'plays',
        label: labelStrings.playsWithK,
        handler: changePlays,
        values: playsValues,
      },
      views: {
        name: 'views',
        label: labelStrings.averageReach,
        handler: changeViews,
        values: viewsValues,
      },
      playsPerReel: {
        name: 'playsPerReel',
        label: labelStrings.playsPerReel,
        handler: changePlaysPerReel,
        values: playsPerReelValues,
      },
      shorts: {
        name: 'shorts',
        label: labelStrings.shortsViews,
        handler: changeShortsViews,
        values: shortsViewValues,
      },
      videos: {
        name: 'videos',
        label: labelStrings.videoViews,
        handler: changeVideoViews,
        values: videoViewsValues,
      },
    }),
    [
      followersValues,
      viewsValues,
      playsValues,
      playsPerReelValues,
      videoViewsValues,
      shortsViewValues,
      selectedPlatform,
    ]
  )

  const SLIDERS_MAP = createSlidersMap()

  const identifyActiveSlider = () => {
    switch (selectedPlatform) {
      case SOCIAL_PLATFORMS_NAMES.instagram.name:
        if (!!query.viewsMin || !!query.viewsMax) {
          return SLIDERS_MAP.views.name
        } else if (!!query.reelPlaysMin || !!query.reelPlaysMax) {
          return SLIDERS_MAP.playsPerReel.name
        }
        break
      case SOCIAL_PLATFORMS_NAMES.tiktok.name:
        if (!!query.playsMin || !!query.playsMax) {
          return SLIDERS_MAP.plays.name
        }
        break
    }
    return SLIDERS_MAP.followers.name
  }

  const [selectedSlider, changeSelectedSlider] = useState(identifyActiveSlider())
  const [currentValues, setCurrentValues] = useState(SLIDERS_MAP[selectedSlider].values)

  useEffect(() => {
    const newSlider = isCreator
      ? identifyActiveSlider()
      : isContentFilter
      ? SLIDERS_MAP.views.name
      : SLIDERS_MAP.followers.name
    changeSelectedSlider(newSlider)

    setCurrentValues(SLIDERS_MAP[newSlider].values)
  }, [selectedPlatform, isCreator])

  useEffect(() => {
    setCurrentValues(SLIDERS_MAP[selectedSlider].values)
  }, [
    followersValues,
    playsValues,
    viewsValues,
    playsPerReelValues,
    shortsViewValues,
    videoViewsValues,
  ])

  const toggleSlider = name => () => {
    changeSelectedSlider(name)
    setCurrentValues(SLIDERS_MAP[name].values)
  }
  const handleValues = sliderHandleFunc => valuesArr => {
    setCurrentValues(valuesArr)
    sliderHandleFunc(valuesArr)
  }

  const renderFaqTip = faqQuestionId !== null

  const sliderCurrentValues = useMemo(() => {
    return currentValues.map(value =>
      value === Infinity ? labelStrings.unlimited : pretifyBigNum(value)
    )
  }, [currentValues])

  const sliderButtonPlatform = useMemo(() => {
    const hasTikTok = (
      <SliderButtonWithInfo
        onClick={toggleSlider(SLIDERS_MAP.plays.name)}
        isSelected={SLIDERS_MAP.plays.name === selectedSlider}
      >
        {SLIDERS_MAP.plays.label}
      </SliderButtonWithInfo>
    )

    const hasInstagram = (
      <>
        <SliderButtonWithInfo
          onClick={toggleSlider(SLIDERS_MAP.views.name)}
          isSelected={SLIDERS_MAP.views.name === selectedSlider}
          isYouTubePlatform
        >
          {SLIDERS_MAP.views.label}
        </SliderButtonWithInfo>
        <SliderButtonWithInfo
          onClick={toggleSlider(SLIDERS_MAP.playsPerReel.name)}
          isSelected={SLIDERS_MAP.playsPerReel.name === selectedSlider}
          isYouTubePlatform
        >
          {SLIDERS_MAP.playsPerReel.label}
        </SliderButtonWithInfo>
      </>
    )

    const hasYouTube = (
      <>
        <SliderButtonWithInfo
          onClick={toggleSlider(SLIDERS_MAP.shorts.name)}
          isSelected={SLIDERS_MAP.shorts.name === selectedSlider}
          isInstagramPlatform
        >
          {SLIDERS_MAP.shorts.label}
        </SliderButtonWithInfo>
        <SliderButtonWithInfo
          onClick={toggleSlider(SLIDERS_MAP.videos.name)}
          isSelected={SLIDERS_MAP.videos.name === selectedSlider}
          isInstagramPlatform
        >
          {SLIDERS_MAP.videos.label}
        </SliderButtonWithInfo>
      </>
    )

    return {
      [SOCIAL_PLATFORMS_NAMES.tiktok.name]: hasTikTok,
      [SOCIAL_PLATFORMS_NAMES.instagram.name]: hasInstagram,
      [SOCIAL_PLATFORMS_NAMES.youtube.name]: hasYouTube,
    }
  }, [selectedPlatform, selectedSlider])

  return (
    <SlidersWrapper>
      {renderFaqTip && (
        <LinkToFaqQuestion
          faqQuestionId={faqQuestionId}
          linkPositions={{ top: -18, right: -24 }}
          tipIconSize={14}
        />
      )}
      <>
        {isCreator ? (
          <SliderNameWrapper>
            <SliderButtonWithInfo
              isSelected={SLIDERS_MAP.followers.name === selectedSlider}
              onClick={toggleSlider(SLIDERS_MAP.followers.name)}
              isFollowersSlider
              isInstagramPlatform={selectedPlatform === SOCIAL_PLATFORMS_NAMES.instagram.name}
            >
              {SLIDERS_MAP.followers.label}
            </SliderButtonWithInfo>

            {sliderButtonPlatform[selectedPlatform]}
          </SliderNameWrapper>
        ) : (
          <SliderReachWrapper>{isContentFilter ? labelStrings.reach : labelStrings.followers}</SliderReachWrapper>
        )}
      </>

      <div>
        <CommonMultiRangeSlider
          id={SLIDERS_MAP[selectedSlider].name}
          onChange={handleValues(SLIDERS_MAP[selectedSlider].handler)}
          onUpdate={setCurrentValues}
          values={SLIDERS_MAP[selectedSlider].values}
        />
      </div>
      <SelectedValuesWrapper>
        {sliderCurrentValues[0]} - {sliderCurrentValues[1]}
      </SelectedValuesWrapper>
    </SlidersWrapper>
  )
}

SearchRangeSlidersGroup.propTypes = {
  changeFollowers: PropTypes.func,
  changePlays: PropTypes.func,
  changeViews: PropTypes.func,
  changePlaysPerReel: PropTypes.func,
  followersValues: PropTypes.array,
  playsValues: PropTypes.array,
  viewsValues: PropTypes.array,
  playsPerReelValues: PropTypes.array,
  faqQuestionId: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
  selectedPlatform: PropTypes.string,
  query: PropTypes.object,
  isCreator: PropTypes.bool,
  isContentFilter: PropTypes.bool
}

export default SearchRangeSlidersGroup
