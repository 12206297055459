import React from 'react'
import PropTypes from 'prop-types'
import { VerifiedDownloadIcon, VerifiedProfileIcon } from '../../common/icons/icons'
import InfoTip from '../../common/tips/InfoTip'
import { SectionTitleBorder } from '../commonProfilePageStyles'
import useTranslation from '../../../localization/useTranslation'
import { USER_INDEXES } from '../../../constants/appSettings'
import { themeCommonStyles } from '../../../themes/infludataThemes'
import {
  ProfileOverviewVerifiedContainer,
  VerifiedInformationColumn,
  VerifiedContent,
  VerifiedSectionText,
  VerifiedSectionTitleName,
  VerifiedUploadFile,
  CopyIconWrapper,
} from './styles'
import { CopyIcon } from '../../common/icons'
import { copyToClipboard } from '../../../utils'

export const ProfileOverviewVerified = ({ platform = '', collaborationInfo }) => {
  const { labelStrings, profileSectionDescs } = useTranslation()

  const {
    message,
    uploadedFiles,
    management,
    whatsApp,
    contactMail,
    contactLink,
  } = collaborationInfo || {}

  const onCopyIconClick = copyValue => {
    if (copyValue) {
      copyToClipboard(copyValue)
    }
  }

  return (
    <ProfileOverviewVerifiedContainer>
      <VerifiedSectionText>
        <VerifiedProfileIcon width={17} height={18} />
        <VerifiedSectionTitleName platform={platform}>
          {labelStrings.verifiedInformation}
        </VerifiedSectionTitleName>
        <InfoTip iconColor={themeCommonStyles.colors.white}>
          <div dangerouslySetInnerHTML={{ __html: profileSectionDescs.verifiedInformation }} />
        </InfoTip>
        <SectionTitleBorder />
      </VerifiedSectionText>
      <VerifiedContent>
        {message && (
          <VerifiedInformationColumn>
            <h4>{labelStrings.infoForCollaborations}</h4>
            <div className="verified-information__message">{message}</div>
          </VerifiedInformationColumn>
        )}
        {(management || whatsApp || contactMail || contactLink) && (
          <VerifiedInformationColumn>
            <h4>{labelStrings.contactInformation}</h4>
            <div className="verified-information__contact">
              {management && (
                <div>
                  <div className="column-left">{labelStrings.management}:</div>
                  <span>{management}</span>
                </div>
              )}
              {whatsApp && (
                <div>
                  <div className="column-left">WhatsApp:</div>
                  <span>{whatsApp}</span>
                </div>
              )}
              {contactMail && (
                <div>
                  <div className="column-left">{labelStrings.email}:</div>
                  <CopyIconWrapper
                    platform={platform}
                    onClick={() => onCopyIconClick(contactMail)}
                    isDisabled={!contactMail}
                  >
                    {contactMail ? (
                      <>
                        <span>
                          <a href={`mailto:${contactMail}`} target="_blank">
                            {contactMail}
                          </a>
                        </span>
                        <CopyIcon width={16} height={18} />
                      </>
                    ) : (
                      '-'
                    )}
                  </CopyIconWrapper>
                </div>
              )}
              {contactLink && (
                <div>
                  <div className="column-left">{labelStrings.contactForm}:</div>
                  <CopyIconWrapper
                    platform={platform}
                    onClick={() => onCopyIconClick(contactLink)}
                    isDisabled={!contactMail}
                  >
                    {contactMail ? (
                      <>
                        <span>
                          <a href={contactLink} target="_blank">
                            {contactLink}
                          </a>
                        </span>
                        <CopyIcon width={16} height={18} />
                      </>
                    ) : (
                      '-'
                    )}
                  </CopyIconWrapper>
                </div>
              )}
            </div>
          </VerifiedInformationColumn>
        )}

        {!!uploadedFiles?.length && (
          <VerifiedInformationColumn maxWidth="440px" downloads>
            <h4>{labelStrings.downloads}</h4>
            <div className="verified-information__download">
              {uploadedFiles.map(file => {
                return (
                  <VerifiedUploadFile href={file.url} key={file.name} download target="_blank">
                    <VerifiedDownloadIcon /> {file.name}
                  </VerifiedUploadFile>
                )
              })}
            </div>
          </VerifiedInformationColumn>
        )}
      </VerifiedContent>
    </ProfileOverviewVerifiedContainer>
  )
}

ProfileOverviewVerified.propTypes = {
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube]),
  collaborationInfo: PropTypes.object,
}