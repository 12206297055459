import React from 'react'
import NewPageLayout from '../../../../../components/influencerProfile/profilePdfDocument/PageLayout/NewPageLayout'
import { Box, Grid, Typography } from '@mui/material'
import { BarChart, PieChart, StackedColumnChart } from '../../../../components/chart/charts'
import { UploadedContentTimeline } from '../../UploadedContentTimeline'

const dataReachedAudience = data => {
  if (!Array.isArray(data)) return []

  return data?.map(el => {
    const { value, absoluteReach, ...other } = el
    return {
      label: Object.values(other),
      value: Math.floor(value),
      absoluteReach: absoluteReach,
    }
  })
}

const transformData = chartData => {
  if (!Array.isArray(chartData)) return []

  const result = [
    { name: 'Instagram Posts', data: [] },
    { name: 'Instagram Reels', data: [] },
    { name: 'Instagram Stories', data: [] },
    { name: 'TikTok Videos', data: [] },
    { name: 'YouTube Shorts', data: [] },
    { name: 'YouTube Videos', data: [] },
  ]

  chartData?.forEach(item => {
    item.instagram_post ? result[0].data.push(item.instagram_post) : result[0].data.push(0)
    item.instagram_reel ? result[1].data.push(item.instagram_reel) : result[1].data.push(0)
    item.instagram_story || item.instagram_story_group
      ? result[2].data.push((item.instagram_story || 0) + (item.instagram_story_group || 0))
      : result[2].data.push(0)
    item.tiktok_video ? result[3].data.push(item.tiktok_video) : result[3].data.push(0)
    item.youtube_short ? result[4].data.push(item.youtube_short) : result[4].data.push(0)
    item.youtube_video ? result[5].data.push(item.youtube_video) : result[5].data.push(0)
  })

  return result
}

const reachedTitles = {
  ['reached_audience_country']: 'Reached Audience by Country',
  ['reached_audience_city']: 'Reached Audience by City',
  ['reached_audience_gender']: 'Reached Audience by Gender',
  ['reached_audience_language']: 'Reached Audience by Language',
  ['reached_audience_age']: 'Reached Audience by Age',
}

export const FivethPdfPage = props => {
  const {
    reachedChartData,
    mostSuccessfulCreatorsByReachData,
    publishedByTimeAndFormatData,
    mostUsedKeywordBarData,
    uploadedContentNewsData,
    contentByReachBucketData,
  } = props

  /* Data for Published Content Pieces by Time and Format Graph */
  const dataPublishedByTimeAndFormat = transformData(publishedByTimeAndFormatData?.value)
  const timePublishedByTimeAndFormat = publishedByTimeAndFormatData?.value?.map(el => el.date)
  /* Data for Most Successful Creators by Reach Graph */
  const dataMostSuccessfulCreatorsByReach = transformData(mostSuccessfulCreatorsByReachData?.value)
  const timeMostSuccessfulCreatorsByReach = mostSuccessfulCreatorsByReachData?.value?.map(
    el => el.username
  )
  /* Data for Published Content Pieces by ReachGraph */
  const labelsContentByReachBucket = contentByReachBucketData?.dataArray?.map(el => el.bucket)
  const valuesContentByReachBucket = contentByReachBucketData?.dataArray?.map(el => el.content)
  /* Data for Most used keywords, links and mentions Graph */
  const dataMostUsedKeywordBar = mostUsedKeywordBarData?.value?.map(el => {
    return {
      label: el.value,
      value: el.count,
    }
  })

  const slicedReachedChartData =
    reachedChartData?.length > 3 ? reachedChartData?.slice(0, 4) : reachedChartData

  return (
    <>
      <NewPageLayout>
        <Grid container spacing={2}>
          {mostSuccessfulCreatorsByReachData?.graphId && (
            <Grid item xs={12} md={6}>
              <StackedColumnChart
                isPdf
                title="Most Successful Creators by Reach (Top 15)"
                chart={{
                  labels: timeMostSuccessfulCreatorsByReach,
                  colors: ['#CA5AFF', '#FFE700', '#2D99FF', '#FFBC00', '#FF6C40', '#2CD9C5'],
                  series: [
                    {
                      data: dataMostSuccessfulCreatorsByReach,
                    },
                  ],
                }}
              />
            </Grid>
          )}
          {publishedByTimeAndFormatData?.graphId && (
            <Grid item xs={12} md={6}>
              <StackedColumnChart
                isPdf
                title="Published Content Pieces by Time and Format"
                chart={{
                  labels: timePublishedByTimeAndFormat,
                  colors: ['#CA5AFF', '#FFE700', '#2D99FF', '#FFBC00', '#FF6C40', '#2CD9C5'],
                  series: [
                    {
                      data: dataPublishedByTimeAndFormat,
                    },
                  ],
                }}
              />
            </Grid>
          )}
          {contentByReachBucketData?.graphId && (
            <Grid item xs={12} md={6}>
              <Box sx={{ position: 'relative' }}>
                <Typography
                  sx={{
                    position: 'absolute',
                    zIndex: 5,
                    top: '50%',
                    transform: `rotate(-90deg)`,
                    left: '-22px',
                    fontSize: '12px',
                    color: '#97A3AF',
                    fontWeight: 'bold',
                  }}
                >
                  Content Pieces
                </Typography>
                <StackedColumnChart
                  isPdf
                  title="Published Content Pieces by Reach"
                  chart={{
                    labels: labelsContentByReachBucket,
                    colors: ['#FFE4A9'],
                    series: [
                      {
                        data: [{ name: '', data: valuesContentByReachBucket }],
                      },
                    ],
                  }}
                />
                <Typography
                  sx={{
                    zIndex: 5,
                    position: 'absolute',
                    right: '50%',
                    bottom: '8px',
                    fontSize: '12px',
                    color: '#97A3AF',
                    fontWeight: 'bold',
                  }}
                >
                  Reach
                </Typography>
              </Box>
            </Grid>
          )}
          {mostUsedKeywordBarData?.graphId && (
            <Grid item xs={12} md={6}>
              <BarChart
                title="Most used keywords, links and mentions"
                subheader=""
                chart={{
                  series: dataMostUsedKeywordBar || [],
                  colors: ['#FF5D5A'],
                }}
              />
            </Grid>
          )}
        </Grid>
      </NewPageLayout>
      <NewPageLayout>
        <Grid container sx={{ py: 3 }} spacing={2}>
          {slicedReachedChartData?.map(reachedChart => {
            return (
              <Grid item xs={12} md={6} key={reachedChart.graphId}>
                <PieChart
                  title={reachedTitles[reachedChart?.graphId]}
                  chart={{
                    colors: ['#FF5D5A', '#3FC0FC', '#FFBC00', '#76DB6B'],
                    series: dataReachedAudience(reachedChart?.value),
                  }}
                  height="410px"
                  isPdf
                />
              </Grid>
            )
          })}
        </Grid>
      </NewPageLayout>
      <NewPageLayout>
        <Grid container sx={{ py: 3 }}>
          <Grid item xs={12} md={6}>
            <PieChart
              title={reachedTitles[reachedChartData[reachedChartData?.length - 1]?.graphId]}
              chart={{
                colors: ['#FF5D5A', '#3FC0FC', '#FFBC00', '#76DB6B'],
                series: dataReachedAudience(reachedChartData[reachedChartData?.length - 1]?.value),
              }}
              height="410px"
              isPdf
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <UploadedContentTimeline
              title="Uploaded Content (with keywords found)"
              list={uploadedContentNewsData?.value}
              isPdf
            />
          </Grid>
        </Grid>
      </NewPageLayout>
    </>
  )
}
