// @ts-nocheck
import React, { useState } from 'react'
import { Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { editNotifications } from '../../../redux/ducks/campaignsDucks'
import { currentOpenedCampaignSelector } from '../../../redux/selectors'
import { TopControls } from '../../sections/notifications/TopControls'
import { NotificationsTable } from '../../sections/notifications/NotificationsTable'
import { EditItem } from '../../sections/notifications/EditItem'

export const Notifications = () => {
  const dispatch = useDispatch()
  const { campaignId, notifications, dashboards, isLoading } = useSelector(
    currentOpenedCampaignSelector
  )

  const [openEditModal, setOpenEditModal] = useState(false)
  const [editItem, setEditItem] = useState({})

  const onCreate = ({ emails, interval, dashboardId }) => {
    const payload = {
      method: 'add',
      emails,
      interval,
      campaignId,
      dashboardId,
    }
    dispatch(editNotifications(payload))
  }

  const onRemove = ({ notificationId }) => {
    const payload = {
      method: 'remove',
      campaignId,
      notificationId,
    }
    dispatch(editNotifications(payload))
  }

  const onEdit = ({ emails, interval, notificationId, dashboardId }) => {
    const payload = {
      method: 'change',
      emails,
      interval,
      notificationId,
      campaignId,
      dashboardId,
    }
    dispatch(editNotifications(payload))
  }

  return (
    <>
      <Box sx={{ p: 3 }}>
        <TopControls onCreate={setOpenEditModal} disabled={isLoading} />
        <NotificationsTable
          data={notifications || []}
          openModal={setOpenEditModal}
          setEditItem={setEditItem}
          isLoading={isLoading}
          onRemove={onRemove}
          onEdit={onEdit}
          dashboards={dashboards}
        />
      </Box>
      <EditItem
        openEditFieldModal={openEditModal}
        setOpenEditFieldModal={setOpenEditModal}
        editField={editItem}
        setEditField={setEditItem}
        onEdit={onEdit}
        onCreate={onCreate}
        dashboards={dashboards}
      />
    </>
  )
}
