import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import useTranslation from '../../localization/useTranslation'
import WithUpgradeTip from '../common/popups/WithUpgradeTip'

const Item = styled.div`
  color: #272727;
  font-size: 15px;
  line-height: 24px;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  ${props => props.disabled && props.theme.disableBlock}
`

const CSVWrapper = styled.div`
  ${props =>
    props.withCustomTipCords &&
    `
    & .collection-sidebar-menu-content {
      right: ${props.tipCords.x || 20}px !important;
      left: unset !important;
      top: ${props.tipCords.y || 60}px !important;
      position: fixed !important;
    }
`}
`

const CollectionOptionsItems = ({
  enableExports = false,
  itemClickHandler,
  isTrialUser = false,
  disableCollectionChange = true,
}) => {
  const { labelStrings } = useTranslation()

  const TRIAL_TIP_HEIGHT = 90
  const [tipCords, setTipCords] = useState({})

  useEffect(() => {
    if (enableExports && isTrialUser) {
      const label = document.getElementById('export_trial_csv')
      if (label) {
        const cords = label.getBoundingClientRect() || {}
        setTipCords({
          x: cords.right ? window.innerWidth - cords.right : 0,
          y: cords.top ? cords.top - TRIAL_TIP_HEIGHT : 0,
        })
      }
    }
  }, [])

  const renderCSV = () => {
    if (isTrialUser) {
      return (
        <WithUpgradeTip
          specialUpgradeText={`${labelStrings.notAvailableInTrial}. ${labelStrings.askToActivate}`}
          withOpenTrialLimitModal
        >
          <Item disabled id="export_trial_csv">
            {labelStrings.exportAsCSV}
          </Item>
        </WithUpgradeTip>
      )
    }
    if (!enableExports) {
      return (
        <WithUpgradeTip tipText={labelStrings.exportCollection}>
          <Item disabled>{labelStrings.exportAsCSV}</Item>
        </WithUpgradeTip>
      )
    }
    return <Item onClick={itemClickHandler('csv')}>{labelStrings.exportAsCSV}</Item>
  }

  return (
    <div>
      {!disableCollectionChange && (
        <>
          <Item onClick={itemClickHandler('edit')}>{labelStrings.editCollection}</Item>
          <Item onClick={itemClickHandler('remove')}>{labelStrings.removeCollection}</Item>
        </>
      )}
      <CSVWrapper withCustomTipCords={enableExports && isTrialUser} tipCords={tipCords}>
        {renderCSV()}
      </CSVWrapper>
    </div>
  )
}

CollectionOptionsItems.propTypes = {
  enableExports: PropTypes.bool,
  itemClickHandler: PropTypes.func,
  isTrialUser: PropTypes.bool,
  disableCollectionChange: PropTypes.bool,
}

export default CollectionOptionsItems
