import styled from 'styled-components'
import { convertHexToRgba } from '../../../utils'

export const SpinnerWrapper = styled.div`
  width: ${props => props.size};
  height: ${props => props.size};

  svg {
    path {
      stroke: ${props => props.color || props.theme.colors.mediumBrown};
    }
  }
`

export const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 5;
  background-color: ${props =>
    props.overlayBgColor || convertHexToRgba(props.theme.colors.offWhite, 0.5)};
`
