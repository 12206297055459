// @ts-nocheck
import React from 'react'
import { Box, Link, Modal, Stack, Typography, useMediaQuery } from '@mui/material'
import { toggleSignIn } from '../../../../redux/ducks/appSettingsDucks'
import PdfLogo from '../../../../images/influData-Logo-Pdf-Layout.png'
import { useDispatch } from 'react-redux'
import SignupStepOneForm from '../SignupStepOneForm'
import SignupStepOneVerifyEmail from '../SignupStepOneVerifyEmail'
import LoadingSpinner from '../../../common/spinners/LoadingSpinner'
import useTranslation from '../../../../localization/useTranslation'
import { useHistory } from 'react-router-dom'
import useResponsive from '../../../../new-ui/hooks/useResponsive'

const style = {
  width: '100%',
  height: '100%',
  bgcolor: 'background.paper',
  overflow: 'hidden',
}

export const SignupNotVerifiedView = props => {
  const {
    goToStepTwoGoogle,
    goToStepTwo,

    stepTwoVerifiedSignupToken,
    stepOneData,

    isOpened,
    onClose,
    isLoading,

    isTrialCampaigns,
  } = props

  const { labelStrings, currentLang } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const mediaQueryMaxHeight = useMediaQuery('(max-height:680px)')
  const isMobile = useResponsive('down', 'sm')

  const { email, requestedSignupToken } = stepOneData || {}

  const onLoginClick = () => {
    history.push('/')
    dispatch(toggleSignIn())
  }

  const brandsImgArr = [
    'https://infludata.com/wp-content/uploads/henkel-1.svg',
    'https://infludata.com/wp-content/uploads/obi.svg',
    'https://infludata.com/wp-content/uploads/gymondo.svg',
    'https://infludata.com/wp-content/uploads/Teufel.svg',
    'https://infludata.com/wp-content/uploads/beiersdorf-1.svg',
    'https://infludata.com/wp-content/uploads/deichmann2.svg',
    'https://infludata.com/wp-content/uploads/der_touristik-1.svg',
    'https://infludata.com/wp-content/uploads/Kia.svg',
    'https://infludata.com/wp-content/uploads/MotelOne.svg',
    'https://infludata.com/wp-content/uploads/Losteria.svg',
    'https://infludata.com/wp-content/uploads/Babor.svg',
    'https://infludata.com/wp-content/uploads/sky-logo-new.svg',
  ]
  const badges = [
    'https://infludata.com/wp-content/uploads/influData_Used_by_OMR_Badge.png',
    'https://infludata.com/wp-content/uploads/influData-OMR-Top-Rated-Badge_02-2024.png',
    'https://infludata.com/wp-content/uploads/Capterra-Badge-1.webp',
  ]

  return (
    <Modal keepMounted open={isOpened} onClose={onClose}>
      <Box sx={style}>
        <Stack direction={isMobile ? 'column' : 'row'} justifyContent="space-between">
          <Stack sx={{ width: isMobile ? '100%' : '60%', height: '100vh' }}>
            {isLoading && (
              <LoadingSpinner
                width={isMobile ? '100%' : '60vw'}
                height="100%"
                showOnFullPage
                isTransparent
                isOuterPageLoad
              />
            )}
            <Stack
              direction={isMobile ? 'column' : 'row'}
              spacing={isMobile ? 3 : 0}
              alignItems="center"
              justifyContent="space-between"
              sx={{ p: mediaQueryMaxHeight || isMobile ? '15px 80px' : '50px 80px' }}
            >
              <Box>
                <Link href="/" underline="none">
                  <Box
                    sx={{
                      img: {
                        maxHeight: isMobile ? '70px' : '60px',
                      },
                    }}
                  >
                    <img src={PdfLogo} alt="InfluData" />
                  </Box>
                </Link>
              </Box>
              <Stack spacing={0.5} direction="row" alignItems="center">
                <Typography
                  sx={{ fontFamily: `Lato, sans-serif`, fontSize: '13px', color: `#6B7280` }}
                >
                  {labelStrings.signupLocalization.alreadyHaveAnAccount}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: `Lato, sans-serif`,
                    cursor: 'pointer',
                    fontSize: '13px',
                    color: '#FF726F',
                    fontWeight: 'bold',
                    '&:hover': {
                      opacity: 0.8,
                    },
                  }}
                  onClick={onLoginClick}
                >
                  {labelStrings.login}.
                </Typography>
              </Stack>
            </Stack>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ width: '100%', height: '100%', px: isMobile ? 2 : 0 }}
            >
              <Stack
                spacing={4}
                justifyContent="center"
                sx={{ maxWidth: currentLang === 'de' ? '420px' : '400px', width: '100%' }}
              >
                <Stack alignItems="center">
                  <Typography
                    textAlign="center"
                    sx={{
                      fontFamily: `Lato, sans-serif`,
                      fontSize: '26px',
                      fontWeight: 600,
                      color: '#1F2937',
                    }}
                  >
                    {requestedSignupToken
                      ? labelStrings.signupLocalization.verifyYourEmailAddress
                      : isTrialCampaigns
                      ? labelStrings.signupLocalization.trialCampaignsLabel
                      : labelStrings.signupLocalization.createYouFreeAccount}
                  </Typography>
                  <Typography
                    textAlign="center"
                    sx={{ fontFamily: `Lato, sans-serif`, fontSize: '15px', color: '#6B7280' }}
                  >
                    {requestedSignupToken
                      ? labelStrings.signupLocalization.weveSentDigitalCode(email)
                      : labelStrings.signupLocalization.noCreditNoCardNeeded}
                  </Typography>
                </Stack>

                {requestedSignupToken ? (
                  <SignupStepOneVerifyEmail
                    stepTwoVerifiedSignupToken={stepTwoVerifiedSignupToken}
                    email={stepOneData?.email}
                    isVerifiedSignupToken={stepOneData.verifiedSignupToken}
                  />
                ) : (
                  <SignupStepOneForm
                    goToStepTwo={goToStepTwo}
                    goToStepTwoGoogle={goToStepTwoGoogle}
                    stepTwoVerifiedSignupToken={stepTwoVerifiedSignupToken}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
          {!isMobile && (
            <Stack
              sx={{
                width: '40%',
                height: '100vh',
                background: `radial-gradient(circle at 55% 191%, rgb(215, 162, 45), rgb(215, 162, 45) 30%, rgb(215, 74, 71) 100%) 0% 0% no-repeat`,
                p: 2,
              }}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box sx={{ height: '150px' }}></Box>

              <Stack justifyContent="center">
                <Typography
                  textAlign="center"
                  sx={{
                    fontFamily: `Lato, sans-serif`,
                    fontSize: '19px',
                    color: '#FFF',
                    fontWeight: 600,
                    mb: 5,
                  }}
                >
                  {labelStrings.signupLocalization.lovedByTheWorld}
                </Typography>

                <Stack
                  alignItems="center"
                  justifyContent="center"
                  direction="row"
                  sx={{
                    flexWrap: 'wrap',
                    gap: 3,
                    maxWidth: '530px',
                    '& img': {
                      width: '100px', // Adjust this value to make the images smaller
                      height: 'auto', // Keeps the aspect ratio
                      filter: 'brightness(0) invert(1)', // Attempt to make the image appear white
                    },
                  }}
                >
                  {brandsImgArr?.map((link, idx) => {
                    return <img src={link} key={idx} alt="" />
                  })}
                </Stack>
                <Box sx={{ height: '60px' }}></Box>
                <Typography
                  textAlign="center"
                  sx={{
                    fontFamily: `Lato, sans-serif`,
                    fontSize: '19px',
                    color: '#FFF',
                    fontWeight: 600,
                    mb: 5,
                  }}
                >
                  {labelStrings.signupLocalization.topRated}
                </Typography>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  direction="row"
                  sx={{
                    flexWrap: 'wrap',
                    gap: 3,
                    maxWidth: '530px',
                    '& img': {
                      width: '120px', // Adjust this value to make the images smaller
                      height: 'auto', // Keeps the aspect ratio
                    },
                  }}
                >
                  {badges?.map((link, idx) => {
                    return <img src={link} key={idx} alt="" />
                  })}
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Box>
    </Modal>
  )
}
