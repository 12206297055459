import React from 'react'
import PropTypes from 'prop-types'

import useTranslation from '../../../localization/useTranslation'
import { TT_SCORE_NAMES } from '../../../constants/profile'
import { USER_INDEXES } from '../../../constants/appSettings'

import InfludataRating from '../../qualityScores/InfludataRating'
import RatingDescriptionTip from '../detailedInfludataRating/ratingDescriptionTip/RatingDescriptionTip'
import { RatingTitleWrapper, RatingTitle, TiktokStatisticsGridItem } from './commonStyles'

const EngagementRate = ({ engagementMean = 0, isForPdf = false }) => {
  const { scoreStrings } = useTranslation()
  const engagementRateStrings = scoreStrings[USER_INDEXES.tiktok][TT_SCORE_NAMES.engagementRate]

  return (
    <TiktokStatisticsGridItem>
      <RatingTitleWrapper>
        <RatingTitle>{engagementRateStrings.title}</RatingTitle>
        {!isForPdf && <RatingDescriptionTip scoreDesc={engagementRateStrings.description} />}
      </RatingTitleWrapper>

      <InfludataRating
        scoreValue={engagementMean / 10}
        engagementMeanValue={`${engagementMean}%`}
        platform={USER_INDEXES.tiktok}
        isForPdf={isForPdf}
        isTitleHidden
        isEngagementMean
        isSmallRatingValue
      />
    </TiktokStatisticsGridItem>
  )
}

EngagementRate.propTypes = {
  engagementMean: PropTypes.number,
  isForPdf: PropTypes.bool,
}

export default EngagementRate
