import React from 'react'
import { Button, Stack, Typography, Box, useMediaQuery } from '@mui/material'
import useTranslation from '../../../../localization/useTranslation'
import { useDispatch } from 'react-redux'
import { addMoreSignupInfo } from '../../../../redux/ducks/userDucks'
import Scrollbar from '../../../../new-ui/components/scrollbar/Scrollbar'
import useResponsive from '../../../../new-ui/hooks/useResponsive'

const StyledFinishBtn = {
  fontFamily: `Lato, sans-serif`,
  marginTop: '28px',
  fontWeight: 'bold',
  fontSize: '17px',
  lineHeight: '16px',
  color: '#ffffff',
  minWidth: '64px',
  width: 'fit-content',
  height: '46px',
  textTransform: 'capitalize',
  border: 'none',
  borderRadius: '24px',
  background:
    'transparent linear-gradient(89deg, #FF726F 0%, #FFCA55 120%) 0% 0% no-repeat padding-box',
}

export const LookingFor = props => {
  const { username, customerId, afterSignupData, setAfterSignupData, handleContinue } = props || {}
  const { features, platforms, businessType } = afterSignupData || {}

  const { labelStrings } = useTranslation()
  const dispatch = useDispatch()
  const mediaQueryMaxHeight = useMediaQuery('(max-height:680px)')
  const isMobile = useResponsive('down', 'sm')

  const isActiveEl = name => {
    return !!features[name]
  }

  const btnPlatforms = [
    {
      name: 'discovery',
      label: labelStrings.signupLocalization.featuresObject.discovery,
      value: false,
      tooltip: 'this is an exampletooltip',
    },
    {
      name: 'audience',
      label: labelStrings.signupLocalization.featuresObject.audience,
      value: false,
      tooltip: 'this is an exampletooltip',
    },
    {
      name: 'analytics',
      label: labelStrings.signupLocalization.featuresObject.analytics,
      value: false,
      tooltip: 'this is an exampletooltip',
    },
    {
      name: 'overlaps',
      label: labelStrings.signupLocalization.featuresObject.overlaps,
      value: false,
      tooltip: 'this is an exampletooltip',
    },
    {
      name: 'reporting',
      label: labelStrings.signupLocalization.featuresObject.reporting,
      value: false,
      tooltip: 'this is an exampletooltip',
    },
    {
      name: 'management',
      label: labelStrings.signupLocalization.featuresObject.management,
      value: false,
      tooltip: 'this is an exampletooltip',
    },
    {
      name: 'sentiment',
      label: labelStrings.signupLocalization.featuresObject.sentiment,
      value: false,
      tooltip: 'this is an exampletooltip',
    },
    {
      name: 'exports',
      label: labelStrings.signupLocalization.featuresObject.exports,
      value: false,
      tooltip: 'this is an exampletooltip',
    },
  ]

  const onChangeFeatures = ({ name }) => {
    setAfterSignupData(prev => ({
      ...prev,
      features: {
        ...prev.features,
        [name]: !prev.features[name],
      },
    }))
  }

  const onContinue = () => {
    handleContinue()
    dispatch(
      addMoreSignupInfo({
        customerId: customerId || '',
        platforms,
        features,
        businessType,
      })
    )
  }

  return (
    <Stack sx={{ width: '100%', height: '100%' }}>
      <Typography
        sx={{ fontFamily: `Lato, sans-serif`, fontSize: '26px', fontWeight: 600, color: '#1F2937' }}
      >
        {labelStrings.signupLocalization.welcome}, {username}!
      </Typography>
      <Stack spacing={2} sx={{ mt: 3, pb: 5 }}>
        <Stack>
          <Typography
            sx={{
              fontFamily: `Lato, sans-serif`,
              fontSize: '18px',
              fontWeight: 700,
              color: '#1F2937',
            }}
          >
            {labelStrings.signupLocalization.whatAreYouLooking}
          </Typography>
          <Typography
            sx={{
              fontFamily: `Lato, sans-serif`,
              fontSize: '15px',
              fontWeight: 400,
              color: '#6B7280',
              ml: 1,
            }}
          >
            {labelStrings.signupLocalization.pleaseClickAllAnswers}
          </Typography>
        </Stack>

        <Scrollbar
          sx={{
            maxHeight: isMobile ? '280px' : '350px',
          }}
        >
          <Stack direction={isMobile ? 'column' : 'row'} justifyContent={isMobile ? 'center' : 'start'} sx={{ px: isMobile ? 1 : 0, flexWrap: isMobile ? 'nowrap' :'wrap', maxWidth: '550px', rowGap: '8px', columnGap: '8px' }}>
            {btnPlatforms?.map(el => {
              return (
                <Button
                  key={el.name}
                  variant="outlined"
                  size="small"
                  sx={{
                    maxWidth: isMobile ? 'auto' : '164px',
                    backgroundColor: isActiveEl(el.name) ? '#FF726F' : 'initial',
                    color: isActiveEl(el.name) ? '#FFF' : '#1F2937',
                    borderColor: !isActiveEl(el.value) ? 'lightgrey' : '#FF726F',
                    minHeight: isMobile ? '42px' : '60px',
                    height: 'auto',
                    width: mediaQueryMaxHeight || isMobile ? '100%' : `calc(50% - 8px)`, // Adjust for gap, assumes gap of 8px
                    fontSize: '12px', // Smaller font size
                    '&:hover': {
                      opacity: '0.8',
                      backgroundColor: isActiveEl(el.name) ? '#FF726F' : 'initial',
                      color: isActiveEl(el.name) ? 'white' : '#1F2937',
                    },
                  }}
                  onClick={() => onChangeFeatures({ name: el.name })}
                >
                  {el.label}
                </Button>
              )
            })}
          </Stack>
        </Scrollbar>
      </Stack>

      <Button
        disabled={Object.values(features).every(value => value === false)}
        variant="contained"
        onClick={onContinue}
        sx={
          !Object.values(features).every(value => value === false)
            ? { ...StyledFinishBtn, width: isMobile ? '100%' : 'fit-content' }
            : {
                fontFamily: `Lato, sans-serif`,
                fontSize: StyledFinishBtn.fontSize,
                fontWeight: StyledFinishBtn.fontWeight,
                height: StyledFinishBtn.height,
                borderRadius: StyledFinishBtn.borderRadius,
                width: isMobile ? '100%' : 'fit-content',
              }
        }
      >
        {labelStrings.signupLocalization.continue}
      </Button>
    </Stack>
  )
}
