import availableLangs from './availableLangs'
import { ADDITIONAL_FIELD_TYPES } from '../constants/additionalFields'

export default {
  [availableLangs.en]: {
    [ADDITIONAL_FIELD_TYPES.string]: 'Text',
    [ADDITIONAL_FIELD_TYPES.boolean]: 'Yes/No Choice',
    [ADDITIONAL_FIELD_TYPES.date]: 'Date',
    [ADDITIONAL_FIELD_TYPES.number]: 'Number',
    [ADDITIONAL_FIELD_TYPES.singleSelect]: 'Single Select',
    [ADDITIONAL_FIELD_TYPES.multiSelect]: 'Multi Select',
  },
  [availableLangs.de]: {
    [ADDITIONAL_FIELD_TYPES.string]: 'Text',
    [ADDITIONAL_FIELD_TYPES.boolean]: 'Ja/Nein-Auswahl',
    [ADDITIONAL_FIELD_TYPES.date]: 'Datum',
    [ADDITIONAL_FIELD_TYPES.number]: 'Nummer',
    [ADDITIONAL_FIELD_TYPES.singleSelect]: 'Einzelauswahl',
    [ADDITIONAL_FIELD_TYPES.multiSelect]: 'Mehrfachauswahl',
  },
}
