import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, Route, Switch, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import { Box, Container } from '@mui/material'
import Nav from './dashboard/nav'
import {
  calendarDataSelector,
  campaignDashboard,
  currentOpenedCampaignSelector,
  getAllCampaignsSelector,
  isLoadingNewCampaignSelector,
  isUserAuthenticatedSelector,
} from '../../redux/selectors'
import { NAVBAR_FOOTER_SUM_HEIGHT } from '../../constants/appSettings'
import Scrollbar from '../components/scrollbar/Scrollbar'

import {
  CreatorOverview,
  Dashboard,
  ContentOverview,
  Calendar,
  CustomFields,
  DataEnrichment,
  //FilesAndUploads,
  Goals,
  Notifications,
  Settings,
  // YouOffers,
  // YouReports,
  AirtableView,
  KeywordsMentionsManager,
} from '../pages/CampaignsSubpages'
import Page404 from '../../pages/Page404'
import { arrayUrlsWithoutDashboard } from './dashboard/nav/config'
import { Pipeline } from '../pages/CampaignsSubpages/Pipeline'
import { editCampaignSettings } from '../../redux/ducks/campaignsDucks'
import { DashboardSkeletonLoader } from '../sections/dashboard/SkeletonLoader'

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
})

export default function DashboardLayout() {
  const { campaignId } = useParams()
  const history = useHistory()
  const { search, ...location } = useLocation()
  const dispatch = useDispatch()

  const pageUrl = location?.pathname?.split('/')
  const isNotDashboardPage = pageUrl[pageUrl?.length - 1] !== 'dashboard'
  const isCalendarPage = pageUrl[pageUrl?.length - 1] === 'calendar'

  const params = new URLSearchParams(search)
  const dashboardIdFromQueryURL = params.get('dashboardId')

  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const {
    data: dashboardStartupData,
    error: dashboardError,
    isLoading: isLoadingDashboard,
  } = useSelector(campaignDashboard)
  const campaignsList = useSelector(getAllCampaignsSelector)
  const currentCampaign = useSelector(currentOpenedCampaignSelector)
  const { isLoading: isLoadingCalendar } = useSelector(calendarDataSelector)
  const isLoadingNewCampaign = useSelector(isLoadingNewCampaignSelector)

  const [isLoaded, setIsLoaded] = useState(false)

  const isLoadedForChangeHeightSpinner = isCalendarPage && isLoadingCalendar

  const isChangedUrlQuery = arrayUrlsWithoutDashboard?.find(
    el => el === pageUrl[pageUrl?.length - 1]
  )

  useEffect(() => {
    if (isNotDashboardPage && !isLoadingNewCampaign && !currentCampaign?.campaignId) {
      setIsLoaded(true)
    } else if (!isNotDashboardPage && !dashboardStartupData?.length && isLoadingDashboard) {
      setIsLoaded(true)
    } else if (!isLoadingNewCampaign && !isLoadingDashboard) {
      setIsLoaded(false)
    } else {
      setIsLoaded(false)
    }
  }, [
    currentCampaign?.campaignId,
    isNotDashboardPage,
    isLoadingNewCampaign,
    currentCampaign.startingDate,
    dashboardStartupData?.length,
    isLoadingDashboard,
  ])

  useEffect(() => {
    if (
      (!campaignId && currentCampaign.campaignId) ||
      (!dashboardIdFromQueryURL && currentCampaign.campaignId && !isChangedUrlQuery)
    ) {
      history.replace(
        `/campaigns/${currentCampaign.campaignId}/dashboard?dashboardId=${currentCampaign.dashboards[0]?.dashboardId}`
      )
    }
  }, [campaignId, currentCampaign.campaignId, isChangedUrlQuery])

  const onActivateCampaign = () => {
    dispatch(
      // @ts-ignore
      editCampaignSettings({
        campaignId,
        setAsActive: true,
      })
    )
  }

  return (
    <StyledRoot>
      <Nav
        campaignsList={campaignsList}
        campaignId={campaignId || currentCampaign.campaignId}
        dashboardId={
          currentCampaign?.dashboards?.length && currentCampaign?.dashboards[0]?.dashboardId
        }
        startingDate={currentCampaign?.startingDate}
        onActivateCampaign={onActivateCampaign}
        isArchived={currentCampaign?.isArchived}
        isLoaded={isLoaded && isUserAuthenticated && !dashboardError?.message}
        isLoadedCampaign={currentCampaign?.isLoading}
        isOnboardingCampaign={currentCampaign?.isOnboarding}
      />

      <Scrollbar
        sx={{
          maxHeight: `calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px)`,
          width: '100%',
          overflowY: isLoadedForChangeHeightSpinner || isLoaded ? 'hidden' : 'auto',
        }}
      >
        {isLoaded && (
          <Box sx={{ zIndex: 9999, overflow: 'hidden' }}>
            <DashboardSkeletonLoader />
          </Box>
        )}
        <Container
          disableGutters
          maxWidth={false}
          sx={{
            minHeight: `calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px)` /* maxWidth: '1512px' */,
          }}
        >
          <Switch>
            <Route path="/campaigns/:campaignId/dashboard">
              <Dashboard isLoading={isLoaded && isUserAuthenticated && !dashboardError?.message} />
            </Route>
            <Route path="/campaigns/:campaignId/creator-overview" component={CreatorOverview} />
            <Route path="/campaigns/:campaignId/airtable-view" component={AirtableView} />
            <Route path="/campaigns/:campaignId/content-overview" component={ContentOverview} />
            <Route path="/campaigns/:campaignId/calendar" component={Calendar} />
            <Route path="/campaigns/:campaignId/data-enrichment" component={DataEnrichment} />
            <Route path="/campaigns/:campaignId/goals" component={Goals} />
            <Route path="/campaigns/:campaignId/custom-fields" component={CustomFields} />
            <Route
              path="/campaigns/:campaignId/keywords-mentions-manager"
              component={KeywordsMentionsManager}
            />
            {/* <Route path="/campaigns/:campaignId/files-and-uploads" component={FilesAndUploads} /> */}
            {/* <Route path="/campaigns/:campaignId/your-reports" component={YouReports} /> */}
            {/* <Route path="/campaigns/:campaignId/your-offers" component={YouOffers} /> */}
            <Route path="/campaigns/:campaignId/notifications" component={Notifications} />
            <Route path="/campaigns/:campaignId/campaign-settings" component={Settings} />
            <Route path="/campaigns/:campaignId/pipeline" component={Pipeline} />

            <Route path="/campaigns/:campaignId/*">
              <Page404 withoutPageContainer />
            </Route>
          </Switch>
        </Container>
      </Scrollbar>
    </StyledRoot>
  )
}
