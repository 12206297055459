import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Draggable } from '@hello-pangea/dnd'
import { Paper, Typography, Box, Avatar, Stack, Badge } from '@mui/material'
import MessageIcon from '@mui/icons-material/Message'; // Importing the MessageIcon
import PipelineDetails from './details/PipelineDetails'

const colorsPriority = {
  high: '#FF5630',
  medium: '#FFAB00',
  low: '#00B8D9',
  none: '',
}

export const PipelineTaskCard = ({ card, onDeleteTask, index, handleUpdateTask, handleActiveInCampaign }) => {
  const { username, subText, priority, profilePicUrl } = card || {}

  const [openDetails, setOpenDetails] = useState(false)

  const handleOpenDetails = () => {
    setOpenDetails(true)
  }

  const handleCloseDetails = () => {
    setOpenDetails(false)
  }

  return (
    <>
      <Draggable draggableId={card.cardId} index={index}>
        {provided => (
          <Paper
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            sx={{
              width: 1,
              borderRadius: 1,
              overflow: 'hidden',
              position: 'relative',
              boxShadow: theme => theme.customShadows.z1,
              '&:hover': {
                boxShadow: theme => theme.customShadows.z20,
              },
              backgroundColor: card.status === 'active' ? '#CBFFD1' : 'inherit',
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              onClick={handleOpenDetails}
              sx={{ cursor: 'pointer', width: '100%', position: 'relative', minHeight: '80px' }}
            >
              <Box sx={{ pl: 1.5 }}>
                <Avatar alt={username} src={profilePicUrl} />
              </Box>

              <Stack sx={{ pr: 1, pl: 2, maxWidth: '160px' }}>
                <Typography
                  noWrap
                  variant="subtitle2"
                  sx={{
                    transition: theme =>
                      theme.transitions.create('opacity', {
                        duration: theme.transitions.duration.shortest,
                      }),
                    width: '100%',
                  }}
                >
                  {username}
                </Typography>
                <Typography
                  noWrap
                  sx={{
                    fontSize: '14px',
                  }}
                >
                  {subText}
                </Typography>
              </Stack>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%', // Center vertically in the parent container
                  right: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  transform: 'translateY(-180%)', // Adjust vertically to center
                  justifyContent: 'flex-end', // Ensures content is aligned to the right
                }}
              >
                {card.commentThread && card.commentThread.length > 0 && (
                  <Badge
                    badgeContent={card.commentThread.length}
                    color="primary"
                    sx={{
                      ml: 'auto', // Ensures it takes up space to align to the right
                      mr: 1
                    }}
                  >
                    <MessageIcon sx={{ fontSize: '1rem' }} /> {/* Adjust icon size as needed */}
                  </Badge>
                )}
                {colorsPriority[priority] && ( // Check if priority is defined and not 'none'
                  <Box
                    sx={{
                      display: 'inline-block',
                      borderRadius: '50%',
                      height: '15px',
                      width: '15px',
                      backgroundColor: colorsPriority[priority],
                      ml: 1, // Adds space between the message icon and priority indicator if both are present
                    }}
                  />
                )}
              </Box>

            </Stack>
          </Paper>
        )}
      </Draggable>

      <PipelineDetails
        task={card}
        openDetails={openDetails}
        onCloseDetails={handleCloseDetails}
        onDeleteTask={() => onDeleteTask(card.cardId)}
        handleUpdateTask={handleUpdateTask}
        handleActiveInCampaign={handleActiveInCampaign}
      />
    </>
  )
}

PipelineTaskCard.propTypes = {
  card: PropTypes.object,
  index: PropTypes.number,
  onDeleteTask: PropTypes.func,
  handleUpdateTask: PropTypes.func,
  handleActiveInCampaign: PropTypes.func
}
