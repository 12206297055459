export const USER_ROLES = {
  anonymous: 'anonymous',
  authenticated: 'authenticated',
}

export const USER_MIN_SEATS = 1

export const PACKAGES_BILL_TYPES = {
  month: 'month',
  year: 'year',
}

export const AVALIABLE_PACKAGES = {
  free: 'infludata-free',
  advanced: 'infludata-advanced',
  pro: 'infludata-pro',
  enterprise: 'infludata-enterprise', // not final yet
  advanced_year: 'infludata-advanced-y',
  advanced_month: 'infludata-advanced-m',
  // pro_month_25tokens: 'infludata-pro-m-25',
  // pro_month_100tokens: 'infludata-pro-m-100',
  // pro_month_500tokens: 'infludata-pro-m-500',
  // pro_year_25tokens: 'infludata-pro-y-25',
  // pro_year_100tokens: 'infludata-pro-y-100',
  // pro_year_500tokens: 'infludata-pro-y-500',
}

export const PRO_PACKAGE_REPORTS_PLANS = {
  year: {
    reports300: 'infludata-pro-year-300',
    reports1200: 'infludata-pro-year-1200',
    reports1600: 'infludata-pro-year-6000',
  },
  days90: {
    reports75: 'infludata-pro-90d-75',
    reports300: 'infludata-pro-90d-300',
    reports1500: 'infludata-pro-90d-1500',
  },
}

export const PRO_PACKAGE_REPORTS_PRICING = {
  year: {
    [PRO_PACKAGE_REPORTS_PLANS.year.reports300]: {
      count: 25 * 12,
      pricePerYear: 0,
      pricePerMonth: 0,
    },
    [PRO_PACKAGE_REPORTS_PLANS.year.reports1200]: {
      count: 100 * 12,
      pricePerYear: 200 * 12,
      pricePerMonth: 200,
    },
    [PRO_PACKAGE_REPORTS_PLANS.year.reports1600]: {
      count: 500 * 12,
      pricePerYear: 600 * 12,
      pricePerMonth: 600,
    },
  },
  days90: {
    [PRO_PACKAGE_REPORTS_PLANS.days90.reports75]: {
      count: 25 * 3,
      pricePer90days: 0,
    },
    [PRO_PACKAGE_REPORTS_PLANS.days90.reports300]: {
      count: 100 * 3,
      pricePer90days: 1500,
    },
    [PRO_PACKAGE_REPORTS_PLANS.days90.reports1500]: {
      count: 500 * 3,
      pricePer90days: 4500,
    },
  },
}

export const TOKENS_MAP = {
  tokens25: 'tokens25',
  tokens100: 'tokens100',
  tokens500: 'tokens500',
}

export const TOKENS_COUNT_KEYS_MAP = {
  25: TOKENS_MAP.tokens25,
  100: TOKENS_MAP.tokens100,
  500: TOKENS_MAP.tokens500,
}

export const TOKENS_COUNT_RANGE = Object.keys(TOKENS_COUNT_KEYS_MAP).map(n => parseInt(n, 10))

export const PACKAGES_PRICES = {
  [AVALIABLE_PACKAGES.free]: { month: 0, year: 0 },
  [AVALIABLE_PACKAGES.advanced]: { month: 199, year: 2388 },
  [AVALIABLE_PACKAGES.pro]: { month: 399, days90: 2499 },
  proTokensPriceRange: {
    [TOKENS_MAP.tokens25]: { month: 449, year: 399 },
    [TOKENS_MAP.tokens100]: { month: 599, year: 599 },
    [TOKENS_MAP.tokens500]: { month: 839, year: 999 },
  },
}

export const AVAILABLE_PRO_PACKAGE_TOKENS = {
  [TOKENS_MAP.tokens25]: {
    count: 25,
    price: PACKAGES_PRICES.proTokensPriceRange[TOKENS_MAP.tokens25],
    packages: {
      month: AVALIABLE_PACKAGES.pro_month_25tokens,
      year: AVALIABLE_PACKAGES.pro_year_25tokens,
    },
    tokensPriceAddon: 0,
  },
  [TOKENS_MAP.tokens100]: {
    count: 100,
    price: PACKAGES_PRICES.proTokensPriceRange[TOKENS_MAP.tokens100],
    packages: {
      month: AVALIABLE_PACKAGES.pro_month_100tokens,
      year: AVALIABLE_PACKAGES.pro_year_100tokens,
    },
    tokensPriceAddon: 200,
  },
  [TOKENS_MAP.tokens500]: {
    count: 500,
    price: PACKAGES_PRICES.proTokensPriceRange[TOKENS_MAP.tokens500],
    packages: {
      month: AVALIABLE_PACKAGES.pro_month_500tokens,
      year: AVALIABLE_PACKAGES.pro_year_500tokens,
    },
    tokensPriceAddon: 600,
  },
}

export const PRO_TOKENS_COUNT_KEYS = Object.keys(AVAILABLE_PRO_PACKAGE_TOKENS).reduce(
  (obj, key) => ({
    ...obj,
    [AVAILABLE_PRO_PACKAGE_TOKENS[key].count]: key,
  }),
  {}
)

export const GRANTS_PACKAGES_MAP = {
  [AVALIABLE_PACKAGES.free]: 'package_free',
  [AVALIABLE_PACKAGES.advanced]: 'package_advanced',
  [AVALIABLE_PACKAGES.pro]: 'package_pro',
}

export const SCHEDULED_FOR_DELETE_GRANT = 'scheduled_for_clear'

export const CANCELED_PACKAGE_GRANT_NAMES = {
  advanced: 'cancelled_infludata-advanced',
  pro: 'cancelled_infludata-pro',
}
