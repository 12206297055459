import React from 'react'
import useTranslation from '../../../localization/useTranslation'
import { Alert, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material'
import { ModalComponent } from '../../components/modal/ModalComponent'

export const WrongCampaign = props => {
  const { isOpenModal, closeModal, errorSelectedCampaign, handleChangeCampaign, campaignsList } =
    props || {}

  const { labelStrings } = useTranslation()
  return (
    <ModalComponent
      title={labelStrings.wrongCampaign.errorText}
      isOpenModal={isOpenModal}
      closeModal={closeModal}
      contentWidth="600px"
      contentHeight="260px"
      // smallPadding
      isCenterTitle
    >
      <Stack justifyContent="center" alignItems="center">
        <Stack sx={{ width: '500px', height: '200px' }} spacing={2}>
          <Alert
            severity="warning"
            sx={{
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'center',
              fontSize: '16px',
            }}
          >
            {labelStrings.wrongCampaign.selectText}
          </Alert>
          <FormControl fullWidth>
            <InputLabel>{labelStrings.campaigns}</InputLabel>
            <Select
              value={errorSelectedCampaign}
              label={labelStrings.campaigns}
              // size="small"
              onChange={handleChangeCampaign}
              // open
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: '318px',
                  },
                },
              }}
            >
              {campaignsList
                ?.filter(campaign => !campaign.isDemoCampaign)
                ?.map(campaign => (
                  <MenuItem key={campaign.campaignId} value={campaign.campaignId}>
                    {campaign.campaignName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
    </ModalComponent>
  )
}
