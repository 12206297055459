import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import useTranslation from '../../../../localization/useTranslation'
import { withReduxActions } from '../../../../hocs/withRedux'

import PrimaryButton from '../../../common/buttons/PrimaryButton'
import ComparedProfilesSortingTool from '../../../sortingTools/ComparedProfilesSortingTool'
import UserProfileAvatar from '../../../userProfile/UserProfileAvatar'
import { CloseCircledIcon } from '../../../common/icons'

import {
  NavColumnSection,
  AvatarWrapper,
  LinkStyled,
  RemoveComparedWrapper,
  ComparedVerticalStickedWrapper,
  ProfileCategory,
} from '../../../common/styledWrappers/comparedProfilesStyles'
import { SOCIAL_PLATFORMS_NAMES } from '../../../../constants/appSettings'
import { pagesBeforeProfileRedirect } from '../../../../constants/pagesBeforeProfileRedirect'
import { setLastPageBeforeProfileRedirect } from '../../../../redux/ducks/appSettingsDucks'

const ColumnNav = ({
  isNavColumn = false,
  socialPlatformIndex = '',
  isMocked = true,
  removeComparedProfile,
  profile = {},
  resetComparedProfiles,
  disabledSorting = true,
  setLastPageBeforeProfileRedirect,
}) => {
  const { labelStrings } = useTranslation()
  const history = useHistory()

  const goToProfile = () => {
    setLastPageBeforeProfileRedirect(pagesBeforeProfileRedirect.COMPARE)
    history.push(`/profile/${profile._id}`)
  }

  const removeFromCompared = e => {
    e.stopPropagation()
    removeComparedProfile(profile._id, profile._index)
  }

  const isInstagram = socialPlatformIndex === SOCIAL_PLATFORMS_NAMES.instagram.index

  return (
    <ComparedVerticalStickedWrapper
      {...(!isNavColumn && {
        onClick: goToProfile,
      })}
    >
      <NavColumnSection
        justifySpace={isNavColumn}
        isMocked={isMocked}
        index={socialPlatformIndex}
        isNav={isNavColumn}
      >
        {isNavColumn ? (
          <>
            <PrimaryButton
              label={labelStrings.clearList}
              noBG
              onClick={resetComparedProfiles}
              isDisabled={isMocked}
              userindex={socialPlatformIndex}
            />

            <ComparedProfilesSortingTool
              isDisabled={isMocked || disabledSorting}
              socialPlatformIndex={socialPlatformIndex}
            />
          </>
        ) : (
          <>
            <AvatarWrapper>
              <UserProfileAvatar
                profileScore={isMocked ? 0 : profile.profileScore?.overallScore}
                avatarSize={75}
                userindex={isMocked ? '' : socialPlatformIndex}
                profilePicURL={isMocked ? '' : profile.profilePicURL || ''}
                withShadow={!isMocked}
                animated={!isMocked}
                withParentWidth
              />
            </AvatarWrapper>
            <LinkStyled userindex={socialPlatformIndex} isBold isMocked={isMocked}>
              {profile.username}
            </LinkStyled>
            {isInstagram && <ProfileCategory>{profile.instaCategory || ''}</ProfileCategory>}

            {!isMocked && (
              <RemoveComparedWrapper onClick={removeFromCompared} index={socialPlatformIndex}>
                <CloseCircledIcon />
              </RemoveComparedWrapper>
            )}
          </>
        )}
      </NavColumnSection>
    </ComparedVerticalStickedWrapper>
  )
}

ColumnNav.propTypes = {
  isNavColumn: PropTypes.bool,
  isMocked: PropTypes.bool,
  socialPlatformIndex: PropTypes.string,
  profile: PropTypes.object,
  removeComparedProfile: PropTypes.func,
  resetComparedProfiles: PropTypes.func,
  setLastPageBeforeProfileRedirect: PropTypes.func,
  disabledSorting: PropTypes.bool,
}

export default withReduxActions({ setLastPageBeforeProfileRedirect })(ColumnNav)
