import React, { useState } from 'react'
import PropTypes from 'prop-types'

import useTranslation from '../../../localization/useTranslation'
import { COLLECTIONS_ACCESS_TYPES } from '../../../constants/collections'
import { MAX_NAME_LIMIT, MAX_DESCRIPTION_LIMIT, USER_INDEXES } from '../../../constants/appSettings'
import { withReduxActions } from '../../../hocs/withRedux'
import { createNewCollection, editCollection } from '../../../redux/ducks/collectionsDucks'

import Modal from '../../common/modals/Modal/Modal'
import Input from '../../common/inputs/Input'
import Textarea from '../../common/textarea/Textarea'
import Checkbox from '../../common/checkboxes/Checkbox'
import Spinner from '../../common/spinners/Spinner'
import { GradientButton } from '../../common/buttons/styledButtons'
import { EditCollectionModalWrapper, CheckboxesWrapper, FlexRow } from './commonStyles'

const EditCollectionModal = ({
  collectionData = {},
  isNewCollection = false,
  platform = '',
  closeModal,
  createNewCollection,
  editCollection,
  withoutPortal = false,
}) => {
  const { labelStrings, errors } = useTranslation()

  const [collectionState, setCollectionState] = useState({
    collectionId: collectionData?.collectionId,
    name: collectionData?.name,
    description: collectionData?.description,
    access: COLLECTIONS_ACCESS_TYPES.private,
  })
  const [collectionNameError, setCollectionNameError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onChangeCollectionName = event => {
    setCollectionNameError('')
    setCollectionState({ ...collectionState, name: event.target.value })
  }

  const onChangeCollectionDescription = event =>
    setCollectionState({ ...collectionState, description: event.target.value })

  const onChangeCollectionAccess = newAccess =>
    setCollectionState({ ...collectionState, access: newAccess })

  const onSaveCollection = async () => {
    if (!collectionState.name) {
      return setCollectionNameError(errors.fieldIsRequired)
    }

    const { collectionId, name, description, access } = collectionState
    if (
      !isNewCollection &&
      collectionData?.name === name &&
      collectionData?.description === description
    ) {
      return closeModal()
    }

    setIsLoading(true)
    const handler = isNewCollection
      ? () => createNewCollection({ name, description, access })
      : () => editCollection({ collectionId, name, description })
    await handler()
    setIsLoading(false)
    closeModal()
  }

  const headerContent = isNewCollection
    ? labelStrings.createNewCollection
    : labelStrings.editCollectionTitle

  return (
    <Modal
      headerContent={headerContent}
      platform={platform}
      closeModal={closeModal}
      withoutPortal={withoutPortal}
    >
      <EditCollectionModalWrapper>
        <Input
          id="collection-name"
          name="collection-name"
          label={labelStrings.collectionName}
          value={collectionState.name}
          maxLength={MAX_NAME_LIMIT}
          onChange={onChangeCollectionName}
          errorMessage={collectionNameError}
        />

        <Textarea
          id="collection-description"
          name="collection-description"
          value={collectionState.description}
          maxLength={MAX_DESCRIPTION_LIMIT}
          label={`${labelStrings.collectionDescription} (${labelStrings.optional})`}
          onChange={onChangeCollectionDescription}
        />

        {isNewCollection && (
          <CheckboxesWrapper>
            <Checkbox
              label={labelStrings.privateCollection}
              isChecked={collectionState.access === COLLECTIONS_ACCESS_TYPES.private}
              onChange={() => onChangeCollectionAccess(COLLECTIONS_ACCESS_TYPES.private)}
            />
            <Checkbox
              label={labelStrings.publicCollection}
              isChecked={collectionState.access === COLLECTIONS_ACCESS_TYPES.public}
              onChange={() => onChangeCollectionAccess(COLLECTIONS_ACCESS_TYPES.public)}
            />
          </CheckboxesWrapper>
        )}

        <FlexRow>
          <GradientButton platform={platform} onClick={onSaveCollection}>
            {isNewCollection ? labelStrings.createNewCollection : labelStrings.editCollection}
          </GradientButton>
        </FlexRow>

        {isLoading && <Spinner isOverlay />}
      </EditCollectionModalWrapper>
    </Modal>
  )
}

EditCollectionModal.propTypes = {
  collectionData: PropTypes.shape({
    collectionId: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    count: PropTypes.number,
    access: PropTypes.string,
  }),
  isNewCollection: PropTypes.bool,
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube]),
  closeModal: PropTypes.func,
  createNewCollection: PropTypes.func,
  editCollection: PropTypes.func,
  withoutPortal: PropTypes.bool,
}

export default withReduxActions({ createNewCollection, editCollection })(EditCollectionModal)
