import styled, { css } from 'styled-components'
import { USER_INDEXES } from '../../../../constants/appSettings'

// Additional Fields
export const AdditionalFieldsContainer = styled.div`
  position: relative;
  ${props => !props.isCollection && 'margin: 20px 0 30px'};

  ${props => props.isDisabled && props.theme.disabled};

  ${props =>
    props.isLoading &&
    `
    min-height: 280px;
  `}
`

export const AdditionalFieldsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 20px;
`

export const AdditionalFieldsGridItem = styled.div`
  width: 100%;
  height: 100%;
  ${props => props.isCollection && `
    display: flex;
    align-items: center;
    min-height: 31px;
    border-radius: 9px;
    border: 1px solid ${props.theme.colors.lightBrown};
  `}
`

// Additional Field Container
export const AdditionalFieldInputCommon = styled.input`
  font-size: 15px;
  line-height: 19px;
  width: 100%;
  border: none;
  outline: none;
  color: ${props => props.theme.colors.brown};
  background: transparent;
  text-align: center;

  ${props =>
    (props.isDateInput || props.isSelectInput) &&
    `
    cursor: pointer;
  `}
  font-weight: ${props =>
    props.isDateInput || props.isSelectInput || props.isNumberInput ? '600' : 'normal'};

  // Hide arrows from number input
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }
`

export const AdditionalFieldYesNoCheckboxWrapper = styled.div`
  width: 100%;
`

const additionalFieldIconWrapperStyles = css`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 15px 10px;
  background: transparent;
  ${'' /* background-color: ${props => props.theme.colors.white}; */}
  user-select: none;
`

export const AdditionalFieldInputIconWrapper = styled.div`
  ${additionalFieldIconWrapperStyles}
`

export const AdditionalFieldInputLinkWrapper = styled.a`
  ${additionalFieldIconWrapperStyles}
`

export const CalendarWrapper = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  right: 0;
  z-index: 5;

  .DayPicker-Day--highlighted {
    ${props => {
      if (props.platform === USER_INDEXES.tiktok) {
        return `background: ${props.theme.gradients.mainTiktok};`
      }
    }}
  }
`

// Additional Field Select
export const SelectOptionsDropdown = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  border: 0.5px solid ${props => props.theme.colors.mediumBrown};
  border-radius: 10px;
  overflow: hidden;
  z-index: 3;
  min-width: 220px;
`

export const SelectOption = styled.div`
  font-size: 15px;
  line-height: 19px;
  padding: 7px 0;
  text-align: center;
  color: ${props => props.theme.colors.brown};
  background-color: ${props =>
    props.isSelected ? props.theme.colors.mediumBrown : props.theme.colors.white};
  cursor: pointer;

  &:hover {
    background-color: ${props =>
      props.isSelected ? props.theme.colors.mediumBrown : props.theme.colors.lightBrown};
  }
`

// Additional Field Multi Select
export const MultiSelectSelectedItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  position: relative;
  scroll-behavior: smooth;

  ${props =>
    props.hasScroll &&
    `
    padding: 0 40px 0 20px;
  `}
`

export const MultiSelectScrollButton = styled.div`
  position: absolute;
  top: 50%;
  ${props => {
    if (props.isScrollToLeft) {
      return `
        padding-right: 10px;
        background: linear-gradient(90deg, ${props.theme.colors.white} 41.84%, rgba(255, 255, 255, 0) 100%);
      `
    } else if (props.isScrollToRight) {
      return `
        right: 40px;
        padding-left: 10px;
        background: linear-gradient(270deg, ${props.theme.colors.white} 41.84%, rgba(255, 255, 255, 0) 100%);
      `
    }
  }}
  transform: translateY(-50%);
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;

  .icon_arrow-in-circle {
    background-color: ${props => props.theme.colors.white};
  }
`

export const MultiSelectSelectedItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  border: 1px solid ${props => props.theme.colors.brown};
  background: transparent;
  border-radius: 25px;
  padding: 2px 10px;

  & + & {
    margin-left: 10px;
  }
`

export const MultiSelectSelectedItemText = styled.span`
  white-space: nowrap;
  font-size: 15px;
  font-weight: 600;
  line-height: 19px;
  color: ${props => props.theme.colors.brown};
`

export const MultiSelectSelectedItemRemoveButton = styled.button`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  margin-left: 5px;
`

export const MultiSelectOption = styled(SelectOption)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  padding: 7px 10px;
`

export const MultiSelectCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 22px;
  min-width: 22px;
  margin-right: 15px;
  border: 1px solid ${props => props.theme.colors.darkBrown};
  border-radius: 6px;
  background-color: ${props => (props.isSelected ? props.theme.colors.mauve : 'transparent')};

  .icon_tick {
    display: ${props => (props.isSelected ? 'block' : 'none')};
  }
`

export const MultiSelectOptionValue = styled.div`
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.brown};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

// Additional Field Tools
export const AdditionalFieldToolsOverlay = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  right: -50px;
  padding: 15px;
  background-color: ${props => props.theme.colors.darkBrown};
  border: 2px solid ${props => props.theme.colors.mediumBrown};
  border-radius: 17px;
  z-index: 3;
`

export const AdditionalFieldToolsItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const AdditionalFieldToolsItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 6px;
  padding: 8px 15px;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.mauve};
  }
`

export const AdditionalFieldToolsItemIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`

export const AdditionalFieldToolsItemTitle = styled.div`
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.offWhite};
`

// Note with Label and Tools Wrapper
export const NoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`

export const NoteLegend = styled.label`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  background-color: ${props => props.theme.colors.lightBrown};
  border: 0.5px solid ${props => props.theme.colors.mediumBrown};
  border-bottom: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 0;
  padding: 10px 20px;
`

export const NoteLabel = styled.span`
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: ${props => props.theme.colors.darkBrown};
  text-transform: uppercase;
  margin-right: 10px;
  max-width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const NoteTip = styled.span`
  font-size: 13px;
  line-height: 15px;
  color: ${props => props.theme.colors.mauve};
`

export const NoteToolsButton = styled.div`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer;
`

export const NoteInputWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 56px;
  border: 0.5px solid ${props => props.theme.colors.mediumBrown};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: ${props => props.theme.colors.white};
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`

export const editingFieldOverlayPosition = {
  top: 'calc(100% + 10px)',
  right: '-50px',
}
