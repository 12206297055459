import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box, Stack } from '@mui/material'
import { fShortenNumber } from '../../utils/formatNumber'
import Iconify from '../iconify'
import Chart from '../chart'

export const ColorfulSmallCardWidget = ({
  title,
  total,
  icon,
  color = 'primary',
  chart,
  metricLabel,
  dateRange,
  reachedRate,
  subText,
  goalLevel,
  sx = {},
  isPdf,
  ...other
}) => {
  const { series = [], options } = chart

  const colors = {
    red: {
      bg: '#FFEBDE',
      elements: '#B72D38',
    },
    yellow: {
      bg: '#FEFDCF',
      elements: '#B77D00',
    },
    green: {
      bg: '#EEFDE3',
      elements: '#359D3C',
    },
  }

  const bgColor =
    !reachedRate || reachedRate < 50
      ? colors.red.bg
      :  reachedRate < 100
      ? colors.yellow.bg
      : colors.green.bg

  const elementsColor =
  !reachedRate || reachedRate < 50
      ? colors.red.elements
      :  reachedRate < 100
      ? colors.yellow.elements
      : colors.green.elements

  const chartOptions = {
    colors: [elementsColor],
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '78%',
        },
        track: {
          margin: 0,
          background: '#fff',
          borderRadius: '8px',
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: 6,
            color: 'black',
            fontSize: '14px',
            fontWeight: 700,
          },
        },
      },
    },
    ...options,
  }

  return (
    <Box
      sx={{
        borderRadius: 2,
        bgcolor: bgColor,
        paddingBottom: 1,
        minHeight: '130px',
        boxShadow:
          '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
        position: 'relative',
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          p: 1,
          paddingTop: 2,
          borderRadius: 2,
          overflow: 'hidden',
          position: 'relative',
          color: 'common.white',
          bgcolor: bgColor,
          ...sx,
        }}
        {...other}
      >
        <Chart type="radialBar" series={[series]} options={chartOptions} width={86} height={86} />

        <Box sx={{ ml: 1.5 }}>
          <Typography
            variant="body2"
            sx={{
              opacity: 0.72,
              fontSize: '12px',
              lineHeight: '18px',
              textTransform: 'uppercase',
              fontWeight: 700,
              color: elementsColor,
              py: isPdf ? 0.2 : 0
            }}
          >
            {subText}
          </Typography>
          <Typography
            variant="h4"
            sx={{ fontSize: '20px', lineHeight: '26px', fontWeight: 700, color: '#212B36', py: isPdf ? 1 : 0 }}
          >
            {' '}
            {fShortenNumber(total)} {metricLabel}
          </Typography>

          <Typography
            variant="body2"
            sx={{
              fontSize: '14px',
              lineHeight: '16px',
              fontWeight: 700,
              opacity: 0.72,
              color: '#212B36',
              py: isPdf ? 0.2 : 0
            }}
          >
            {title}
          </Typography>
          <Typography
          variant="body2"
          sx={{
            fontSize: '14px',
            lineHeight: '16px',
            fontWeight: 700,
            opacity: 0.72,
            color: '#212B36',
            py: isPdf ? 0.2 : 0
          }}
        >
          {"Range: " + dateRange}
        </Typography>

        </Box>
      </Stack>
      <Iconify
        icon={icon}
        sx={{
          width: 120,
          height: 120,
          opacity: 0.25,
          position: 'absolute',
          color: elementsColor,
          bottom: '-32px',
          right: '-10px',
        }}
      />
    </Box>
  )
}

ColorfulSmallCardWidget.propTypes = {
  sx: PropTypes.object,
  chart: PropTypes.object,
  color: PropTypes.string,
  title: PropTypes.string,
  dateRange: PropTypes.string,
  total: PropTypes.number,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  subText: PropTypes.string,
  metricLabel: PropTypes.string,
  isPdf: PropTypes.bool
}
