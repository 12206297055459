import React from 'react'
import PropTypes from 'prop-types'
import { USER_INDEXES } from '../../../constants/appSettings'
import AudienceCountriesCitiesChart from './audienceAnalysisCharts/AudienceCountriesCitiesChart'
import AudienceGenderChart from './audienceAnalysisCharts/AudienceGenderChart'
import AudienceLanguageChart from './audienceAnalysisCharts/AudienceLanguageChart'
import AudienceAgeGroupsChart from './audienceAnalysisCharts/AudienceAgeGroupsChart'
import AudienceTypeChart from './audienceAnalysisCharts/AudienceTypeChart'
import AudienceCommonMentionsChart from './audienceAnalysisCharts/AudienceCommonMentionsChart'
import { AudienceAnalysisChartsGrid, AudienceAnalysisChartsGridItem } from './styles'

const AudienceAnalysisCharts = ({
  audienceAnalysis = {
    countryArray: [],
    cityArray: [],
    genderMtoF: {},
    languageArray: [],
    averageBirthyearArray: [],
    birthyearMedian: 0,
    audienceType: {},
    mentions: [],
  },
  platform = '',
  followersNumber = 0,
  isDisabledAnalysis = false,
  hasSmartMediaValue = false,
  hasAudienceTypeAndMentionsCharts = true,
  isLikersReport = false,
}) => (
  <AudienceAnalysisChartsGrid
    isDisabledAnalysis={isDisabledAnalysis}
    hasSmartMediaValue={hasSmartMediaValue}
  >
    <AudienceAnalysisChartsGridItem>
      <AudienceCountriesCitiesChart
        userindex={platform}
        countryArray={audienceAnalysis.countryArray}
        cityArray={audienceAnalysis.cityArray}
        followersCount={followersNumber}
        isDisabledAnalysis={isDisabledAnalysis}
        isLikersReport={isLikersReport}
      />
    </AudienceAnalysisChartsGridItem>

    <AudienceAnalysisChartsGridItem>
      <AudienceGenderChart
        userindex={platform}
        genderMtoF={audienceAnalysis.genderMtoF}
        isDisabledAnalysis={isDisabledAnalysis}
      />
    </AudienceAnalysisChartsGridItem>

    <AudienceAnalysisChartsGridItem>
      <AudienceLanguageChart
        userindex={platform}
        languageArray={audienceAnalysis.languageArray}
        isDisabledAnalysis={isDisabledAnalysis}
        followersCount={followersNumber}
        isLikersReport={isLikersReport}
      />
    </AudienceAnalysisChartsGridItem>

    <AudienceAnalysisChartsGridItem>
      <AudienceAgeGroupsChart
        userindex={platform}
        averageBirthyearArray={audienceAnalysis.averageBirthyearArray}
        isDisabledAnalysis={isDisabledAnalysis}
        followersCount={followersNumber}
        birthyearMedian={audienceAnalysis.birthyearMedian}
        isLikersReport={isLikersReport}
      />
    </AudienceAnalysisChartsGridItem>

    {hasAudienceTypeAndMentionsCharts && (
      <>
        <AudienceAnalysisChartsGridItem>
          <AudienceTypeChart
            userindex={platform}
            audienceType={audienceAnalysis.audienceType}
            isDisabledAnalysis={isDisabledAnalysis}
          />
        </AudienceAnalysisChartsGridItem>

        <AudienceAnalysisChartsGridItem>
          <AudienceCommonMentionsChart
            userindex={platform}
            mentions={audienceAnalysis.mentions}
            isDisabledAnalysis={isDisabledAnalysis}
          />
        </AudienceAnalysisChartsGridItem>
      </>
    )}
  </AudienceAnalysisChartsGrid>
)

AudienceAnalysisCharts.propTypes = {
  audienceAnalysis: PropTypes.shape({
    countryArray: PropTypes.array,
    cityArray: PropTypes.array,
    genderMtoF: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    languageArray: PropTypes.array,
    averageBirthyearArray: PropTypes.array,
    birthyearMedian: PropTypes.number,
    audienceType: PropTypes.object,
    mentions: PropTypes.array,
  }),
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  followersNumber: PropTypes.number,
  isDisabledAnalysis: PropTypes.bool,
  hasSmartMediaValue: PropTypes.bool,
  hasAudienceTypeAndMentionsCharts: PropTypes.bool,
  isLikersReport: PropTypes.bool,
}

export default AudienceAnalysisCharts
