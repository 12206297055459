import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Stack, Avatar, TableRow, TableCell, IconButton, Typography } from '@mui/material'
import Label from '../../components/label'
import Iconify from '../../components/iconify'
import { PopoverItemMenu } from './PopoverItemMenu'
import { fShortenNumber } from '../../utils/formatNumber'
import { format, formatDistanceToNow } from 'date-fns'

export const UserTableRow = ({
  row,
  onEditRow,
  onArchiveRow,
  onAddRemoveBrandAccount,
  handleViewEditCreator,
  onAddNonRecognizedContent,
  setMatchedModalData,
  onViewCreatorAnalytics,
  onActivateCreator,
  setOpenArchiveModal,
  setOpenDeleteModal
}) => {
  const {
    name,
    avatarUrl,
    platform,
    reachInViews,
    likes,
    matchMethod,
    // shares,
    // clicks,
    lastActivity,
    added,
    status,
    isBrandAccount,
  } = row

  const statusValue = status ? status : 'archived'

  const [openPopover, setOpenPopover] = useState(null)

  const handleOpenPopover = event => {
    setOpenPopover(event.currentTarget)
  }

  const handleClosePopover = () => {
    setOpenPopover(null)
  }

  return (
    <>
      <TableRow
        hover
        sx={{
          backgroundColor: isBrandAccount ? '#DEFFD8' : 'unset',
          '&:hover': {
            backgroundColor: isBrandAccount ? '#c7e5c2 !important' : 'unset',
          },
        }}
      >
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={name} src={avatarUrl} />

            <Typography variant="subtitle2" noWrap>
              {name}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {platform}
        </TableCell>

        <TableCell align="left">{fShortenNumber(reachInViews) || 0}</TableCell>

        <TableCell align="left">{fShortenNumber(likes) || 0}</TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>{matchMethod || 'Phrase'}</TableCell>

        {/* <TableCell align="left">{pretifyBigNum(shares)}</TableCell>

        <TableCell align="left">{pretifyBigNum(clicks)}</TableCell> */}

        <TableCell align="left">
          {lastActivity
            ? `${formatDistanceToNow(new Date(lastActivity), { addSuffix: true })}`?.replace(
                'about',
                ''
              )
            : '-'}
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {added ? format(new Date(added), 'MMM do, yyyy') : '-'}
        </TableCell>

        <TableCell align="left">
          <Label
            variant="soft"
            color={statusValue === 'active' ? 'success' : statusValue === 'archived' ? 'error' : 'warning'}
            sx={{ textTransform: 'capitalize' }}
          >
            {statusValue}
          </Label>
        </TableCell>

        <TableCell align="right">
          <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <PopoverItemMenu
        openPopover={openPopover}
        handleClosePopover={handleClosePopover}
        onEditRow={onEditRow}
        onArchiveRow={onArchiveRow}
        status={status}
        rowIsBrandAccount={isBrandAccount}
        onAddRemoveBrandAccount={onAddRemoveBrandAccount}
        handleViewEditCreator={handleViewEditCreator}
        onAddNonRecognizedContent={onAddNonRecognizedContent}
        setMatchedModalData={() => setMatchedModalData(row)}
        onViewCreatorAnalytics={onViewCreatorAnalytics}
        onActivateCreator={onActivateCreator}
        rowData={row}
        setOpenArchiveModal={setOpenArchiveModal}
        setOpenDeleteModal={setOpenDeleteModal}
      />
    </>
  )
}

UserTableRow.propTypes = {
  row: PropTypes.object,
  onEditRow: PropTypes.func,
  onArchiveRow: PropTypes.func,
  onAddRemoveBrandAccount: PropTypes.func,
  onAddNonRecognizedContent: PropTypes.func,
  setMatchedModalData: PropTypes.func,
  onViewCreatorAnalytics: PropTypes.func,
  onActivateCreator: PropTypes.func,
  setOpenArchiveModal: PropTypes.func,
  setOpenDeleteModal: PropTypes.func
}
