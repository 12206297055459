import React, { forwardRef } from 'react'
import { Stack, Box } from '@mui/material'
import styled from 'styled-components'
import { Portal } from '../../../../components/common/Portal/Portal'
import { Header } from '../statusSubpages/Header'
import { OkPageFollowers } from './OkPageFollowers'

const ProfilePdfDocumentHideWrapper = styled.div`
  /* Hide redered components */
  position: fixed;
  top: -9999px;
  left: -9999px;
  z-index: -9999;

  /* Show rendered components (for dev) */
  ${'' /* position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: scroll;
  z-index: 9999;
  background: white;
  border: 1px solid black; */}
`

export const OkPagePdfViewFollowers = forwardRef(({ data, reports }, ref) => {
  const platform = data?._index

  return (
    <Portal wrapperId="audience-report-pdf">
      <ProfilePdfDocumentHideWrapper style={{ backgroundColor: '#FCF3F3' }}>
        <Box ref={ref} sx={{ backgroundColor: '#FCF3F3', p: 3 }}>
          <Stack spacing={3}>
            <Header reports={reports} isForPdf />

            <Box sx={{ px: 2 }}>
              <OkPageFollowers platform={platform} audienceData={data} />
            </Box>
          </Stack>
        </Box>
      </ProfilePdfDocumentHideWrapper>
    </Portal>
  )
})
