import { styled, Paper, IconButton, Button } from '@mui/material'

export const StyledPaper = styled(Paper)({
  marginRight: '15px',
  borderRadius: '16px',
  backgroundColor: '#F9FAFB',
  '& video': {
    objectFit: 'contain',
  },
  '&:hover': {
    filter: `brightness(90%)`,
  },
})

export const StyledCardTitle = styled('span')({
  fontWeight: 'bold',
  fontSize: '12px',
  marginRight: '5px',
})

export const StyledIconBtn = styled(IconButton)({
  borderRadius: '8px',
  width: '35px',
  height: '35px',
})

export const ShowSentimentBtn = styled(Button)({
  width: 'fit-content',
  color: '#212B36',
  fontWeight: 700,
  fontSize: '13px',
  lineHeight: '22px',
  backgroundColor: '#DFE3E8',
  boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.16)',
  borderRadius: '8px',
  padding: '4px 10px',
  '&:hover': {
    color: 'white'
  }
})
