import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { TopControls } from '../../sections/pipeline/TopContorls'
import { PipelineContainer } from '../../sections/pipeline/PipelineContainer'
import { useDispatch, useSelector } from 'react-redux'
import { getPipelineData } from '../../../redux/ducks/campaignsDucks'
import { currentOpenedCampaignSelector, pipelineSelector } from '../../../redux/selectors'

export const Pipeline = () => {
  const dispatch = useDispatch()
  const { campaignId } = useSelector(currentOpenedCampaignSelector)
  const { board } = useSelector(pipelineSelector)
  
  const boardCardsLength = board.cards && !!Object.keys(board.cards)?.length

  useEffect(() => {
    if (campaignId && !boardCardsLength) {
      // @ts-ignore
      dispatch(getPipelineData({ campaignId }))
    }
  }, [campaignId, boardCardsLength])

  return (
    <>
      <Box sx={{ px: 3, pt: 3, pb: 1 }}>
        <TopControls />
        <Box sx={{ mt: '28px' }}>
          <PipelineContainer />
        </Box>
      </Box>
    </>
  )
}
