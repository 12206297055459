import React from 'react'
import { Stack, Paper, Button, InputBase, Avatar } from '@mui/material'
// import Iconify from '../../../components/iconify/Iconify'

export const PipelineDetailsCommentInput = ({ comment, setComment, onAddComment }) => {
  const onChangeText = ({ target: { value } }) => {
    setComment({
      ...comment,
      commentText: value || '',
    })
  }

  // const onChangeAttach = file => {
  //   setComment({
  //     ...comment,
  //     commentAttach: file,
  //   })
  // }

  // const handleFileUpload = event => {
  //   const file = event.target.files[0]
  //   const reader = new FileReader()

  //   reader.onloadend = () => {
  //     onChangeAttach(reader.result)
  //   }

  //   reader.readAsDataURL(file)
  // }

  const onSubmitComment = () => {
    onAddComment()
    setComment({
      ...comment,
      commentText: '',
    })
  }

  return (
    <Stack direction="row" spacing={2} sx={{ py: 3, px: 2.5 }}>
      <Avatar src={``} alt={`avatar`} />

      <Paper variant="outlined" sx={{ p: 1, flexGrow: 1 }}>
        <InputBase
          onChange={onChangeText}
          value={comment.commentText}
          fullWidth
          multiline
          rows={2}
          placeholder="Type a message"
          sx={{ px: 1 }}
        />

        <Stack direction="row" alignItems="center" justifyContent="end">
          {/* <Stack direction="row" flexGrow={1}>
            <Tooltip title="Add photo">
              <IconButton size="small" aria-label="Add photo" component="label">
                <input onChange={handleFileUpload} hidden accept="image/*" type="file" />
                <Iconify icon="ic:round-add-photo-alternate" />
              </IconButton>
            </Tooltip>

            {comment.commentAttach && (
              <Badge
                badgeContent={'x'}
                color="primary"
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    filter: `brightness(70%)`,
                  },
                }}
                onClick={() => onChangeAttach(null)}
              >
                <img src={comment.commentAttach} alt="Uploaded Image" height="40" />
              </Badge>
            )}
          </Stack> */}

          <Button onClick={onSubmitComment} variant="contained">
            Comment
          </Button>
        </Stack>
      </Paper>
    </Stack>
  )
}
