import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import useOutsideClick from '../../../../hooks/useOutsideClick'
import useTranslation from '../../../../localization/useTranslation'
import { USER_INDEXES } from '../../../../constants/appSettings'
import { GradientButton } from '../../../common/buttons/styledButtons'
import {
  LimitExceededOverlay,
  LimitExceededContent,
  LimitExceededContentTitle,
  LimitExceededContentText,
  limitExceededOverlayPosition,
} from './styles'

const AdditionalFieldsLimitExceeded = ({ platform, closeOverlay }) => {
  const { labelStrings } = useTranslation()

  const overlayRef = useRef(null)
  useOutsideClick({ ref: overlayRef, callback: closeOverlay })

  return (
    <LimitExceededOverlay ref={overlayRef} position={limitExceededOverlayPosition}>
      <LimitExceededContent>
            <LimitExceededContentTitle>
              {labelStrings.youReachedTheLimitOfAdditionalFields}
            </LimitExceededContentTitle>
            <LimitExceededContentText>
              {labelStrings.maxAvailableAdditionalFields}.
            </LimitExceededContentText>
      </LimitExceededContent>
    </LimitExceededOverlay>
  )
}

AdditionalFieldsLimitExceeded.propTypes = {
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  closeOverlay: PropTypes.func,
}

export default AdditionalFieldsLimitExceeded
