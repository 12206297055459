import React from 'react'
import useTranslation from '../../../../localization/useTranslation'
import ConfirmDialog from '../../../components/confirm-dialog/ConfirmDialog'
import { Button, Typography } from '@mui/material'

export const DeleteModal = props => {
  const {openConfirm, handleCloseConfirm, handleDeleteRows} = props

  const { labelStrings } = useTranslation()
  
  return (
    <>
      <ConfirmDialog
        open={!!openConfirm}
        onClose={handleCloseConfirm}
        title={labelStrings.campaignCreatorOverview.deleteModal.attention}
        content={
          <Typography textAlign='center' sx={{ py: 2 }}>
            {labelStrings.campaignCreatorOverview.deleteModal.bodyText}
          </Typography>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows(openConfirm)
              handleCloseConfirm()
            }}
          >
            {labelStrings.delete}
          </Button>
        }
      />
    </>
  )
}
