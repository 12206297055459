import React, { useEffect } from 'react'
import { ModalComponent } from '../../components/modal/ModalComponent'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fields } from './Form/Fields'
import { EditMentionsKeywordsSchema } from './Form/FormSchema.js'
import useTranslation from '../../../localization/useTranslation'

export const EditItem = props => {
  const {
    openEditFieldModal,
    setOpenEditFieldModal,
    editField,
    setEditField,
    onEdit,
    onCreate,
    dashboards,
  } = props
  const { labelStrings } = useTranslation()

  const methods = useForm({
    resolver: yupResolver(EditMentionsKeywordsSchema),
    defaultValues: {
      dashboardId: '',
      emails: [],
      interval: '',
    },
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods

  const handleClose = () => {
    reset({
      dashboardId: '',
      emails: [],
      interval: '',
    })
    setOpenEditFieldModal(false)
    setEditField(null)
  }

  const onSubmit = async data => {
    const action = editField?.notificationId ? onEdit : onCreate
    try {
      const payload = {
        emails: data?.emails,
        dashboardId: data?.dashboardId,
        interval: data.interval,
        notificationId: editField?.notificationId ? editField?.notificationId : '',
      }
      await new Promise(resolve => setTimeout(resolve, 500))
      action(payload)
      handleClose()
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    reset({
      dashboardId: editField?.dashboardId,
      emails: editField?.emails,
      interval: editField?.interval
        ? editField?.interval
        : editField?.notificationId
        ? 'weekly'
        : '',
    })
  }, [editField])

  return (
    <ModalComponent
      isOpenModal={openEditFieldModal}
      closeModal={handleClose}
      title={`${editField?.notificationId ? labelStrings.editA : labelStrings.addNew} ${
        labelStrings.notification
      }`}
      contentWidth="479px"
      contentHeight="400px"
    >
      <Fields
        onSubmit={onSubmit}
        editField={editField}
        handleSubmit={handleSubmit}
        methods={methods}
        isSubmitting={isSubmitting}
        handleClose={handleClose}
        dashboards={dashboards}
      />
    </ModalComponent>
  )
}
