import styled from 'styled-components'

export const ModalPdfWrapper = styled.div`
  padding: 15px;
`
export const TitleName = styled.div`
  padding: 0 10px;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  ${props => props.theme.getTextGradientStyles({ platform: props.platform })}
  margin-top: 20px;
  margin-bottom: 20px;
`
export  const OptionsWrapper = styled.div`
  .single_checkbox_label_text {
    font-weight: 700;
    text-transform: capitalize;
  }
`
export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const BtnWrapper = styled.div`
  padding: 15px 30px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }

`