import React, { useState } from 'react'
import { Avatar, Box, Button, Chip, Stack, Typography } from '@mui/material'
import Scrollbar from '../../../components/scrollbar/Scrollbar'
import useTranslation from '../../../../localization/useTranslation'
import AddCreatorsTo from './add-creator'

const _BRANDS_OR_CREATORS = {
  BRANDS: 'brands',
  CREATORS: 'creators',
}

const _MAX_LIMIT_ADD_USERS = {
  BRANDS: 2,
  CREATORS: 19,
}

export const AddCreators = props => {
  const {
    onConfirmFirstStep,
    openModalLookalikeAi,
    setIsEnabledImportFromCollection,
    currentType,
    setCurrentType,
    creators,
    setCreators,
  } = props || {}

  const { labelStrings, currentLang } = useTranslation()

  const creatorIds = creators?.map(creator => creator._id)

  const isImportCreatorFromCollection =
    currentType === _BRANDS_OR_CREATORS.CREATORS && !creators?.length

  const isAllowedToAddCreators =
    (currentType === _BRANDS_OR_CREATORS.BRANDS &&
      creators?.length <= _MAX_LIMIT_ADD_USERS.BRANDS) ||
    (currentType === _BRANDS_OR_CREATORS.CREATORS &&
      creators?.length <= _MAX_LIMIT_ADD_USERS.CREATORS)

  const addCreator = creator => {
    const updatedValue = [...creators, creator]
    setCreators(updatedValue)
  }

  const removeCreator = creatorId => {
    const updatedValue = creators?.filter(creator => creator._id !== creatorId)
    setCreators(updatedValue)
  }

  return (
    <Stack spacing={1.5} alignItems="center">
      <Stack sx={{ px: 3, pb: 1 }}>
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
          <Button
            variant={currentType === _BRANDS_OR_CREATORS.CREATORS ? 'contained' : 'outlined'}
            size="medium"
            onClick={() => {
              setCurrentType(_BRANDS_OR_CREATORS.CREATORS)
              setCreators([])
            }}
          >
            {labelStrings?.brandsTimelineText?.creators}
          </Button>
          <Button
            variant={currentType === _BRANDS_OR_CREATORS.BRANDS ? 'contained' : 'outlined'}
            size="medium"
            onClick={() => {
              setCurrentType(_BRANDS_OR_CREATORS.BRANDS)
              setCreators([])
            }}
          >
            Brand Collaborations
          </Button>
        </Stack>
        <Typography
          sx={{
            color: '#637381',
            lineHeight: 1.5,
            fontSize: `0.75rem`,
            fontFamily: `Public Sans,sans-serif`,
            fontWeight: 400,
            marginTop: 2,
            marginRight: `14px`,
            marginBottom: 0,
            marginLeft: `14px`,
          }}
        >
          {currentType === _BRANDS_OR_CREATORS.BRANDS
            ? labelStrings?.buildLookalike?.brandsHelperText
            : labelStrings?.buildLookalike?.creatorsHelperText}
        </Typography>
      </Stack>

      <Box sx={{ width: '464px' }}>
        <AddCreatorsTo
          isAllowedToAddCreators={true}
          creatorIdFromState={creatorIds}
          setCreatorIdFromState={addCreator}
          isSetCreatorId={false}
          isDisabledInput={!isAllowedToAddCreators}
          creatorType={currentType}
          changeCreatorsData={setCreators}
          openModalLookalikeAi={openModalLookalikeAi}
        />
      </Box>

      <Scrollbar
        sx={{
          maxHeight: '240px',
          width: '525px',
        }}
      >
        <Stack
          direction="row"
          sx={{ width: '100%', flexWrap: 'wrap', gap: 1, px: 3.5 }}
          justifyContent="start"
        >
          {creators?.map(creator => (
            <Chip
              sx={{
                borderColor: 'transparent',
                backgroundColor: '#F4F6F8',
                py: 3.5,
                px: 1,
                width: 'fit-content',
                fontWeight: 'bold',
                '.MuiChip-avatar': { width: '36px', height: '36px', mr: 0.5 },
              }}
              key={creator._id}
              avatar={<Avatar alt={creator?.displayName} src={creator?.profilePicURL} />}
              label={`@${creator?.username}`}
              variant="outlined"
              onDelete={() => removeCreator(creator._id)}
            />
          ))}
        </Stack>
      </Scrollbar>

      {!isAllowedToAddCreators && (
        <Typography
          sx={{
            color: '#637381',
            lineHeight: 1.5,
            fontSize: `0.75rem`,
            fontFamily: `Public Sans,sans-serif`,
            fontWeight: 400,
            textAlign: `center`,
            marginTop: 2,
            marginRight: `14px`,
            marginBottom: 0,
            marginLeft: `14px`,
            px: currentLang === 'de' ? 8 : 2,
          }}
        >
          {labelStrings?.buildLookalike?.addToCreatorsLimitText}
        </Typography>
      )}

      {isImportCreatorFromCollection && (
        <Box>
          <Button
            sx={{ textTransform: 'unset' }}
            variant="outlined"
            onClick={() => setIsEnabledImportFromCollection(true)}
          >
            + {labelStrings.importCreatorsFromCollection}
          </Button>
        </Box>
      )}

      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'end', px: 4 }}>
        <Button
          sx={{ mt: 1, mb: 2 }}
          variant="contained"
          onClick={() =>
            onConfirmFirstStep({ platform: creators[0]?._index, userList: creatorIds })
          }
          disabled={
            currentType === _BRANDS_OR_CREATORS.CREATORS
              ? !(
                  creators?.length <= _MAX_LIMIT_ADD_USERS.CREATORS + 1 &&
                  creators?.length >= _MAX_LIMIT_ADD_USERS.BRANDS + 1
                )
              : !creators?.length
          }
        >
          {labelStrings?.signupLocalization?.continue}
        </Button>
      </Box>
    </Stack>
  )
}
