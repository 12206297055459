import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ModalComponent } from '../../components/modal/ModalComponent'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { createCampaignSchema } from './Form/FormSchema.js'
import {
  changeCampaignTopCreators,
  changeCampaignTopHashtags,
  createNewCampaign,
  getExploreData,
} from '../../../redux/ducks/campaignsDucks'
import { StepOne } from './Steps/StepOne'
import { StepTwo } from './Steps/StepTwo'
import { StepThree } from './Steps/StepThree'
import { StepFour } from './Steps/SteoFour'
import { StepFive } from './Steps/StepFive'
import { StepSix } from './Steps/StepSix'
import { StepSeven } from './Steps/StepSeven'
import { StepEight } from './Steps/StepEight'
import { StepFinish } from './Steps/StepFinish'
import { LoadingButton } from '@mui/lab'
import { Box, Button, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import FormProvider from '../../components/hook-form/FormProvider'
import useTranslation from '../../../localization/useTranslation'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import { createCampaignSelector } from '../../../redux/selectors'
import { CopyCampaignFirst } from './Steps/CopyCampaign/CopyCampaignFirst'
import { CopyCampaignLast } from './Steps/CopyCampaign/CopyCampaignLast'

const fieldIndex = {
  1: 'campaignName',
  2: 'startAsInactive',
  3: 'trackedMetrics',
  4: 'brandAccountIncludeAll',
  5: 'campaignCurrency',
  6: 'creatorIds',
  7: 'trackedMetrics',
}

export const CreateCampaign = props => {
  const {
    openModal,
    setOpenModal,
    currentStep,
    goToNextStep,
    goToPrevStep,
    setStep,
    resetStep,
  } = props

  const { labelStrings, currentLang } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const { topCreators, topHashtags, statusCode, newCopyCampaignId } =
    useSelector(createCampaignSelector, shallowEqual) || {}

  const [isCopyCampaign, setIsCopyCampaign] = useState(false)

  const isActivatedCopyCampaign = isCopyCampaign && currentStep > 1

  const [creatorPlatform, setCreatorPlatform] = useState('')
  const [creatorsMention, setCreatorsMention] = useState([])

  const methods = useForm({
    resolver: yupResolver(createCampaignSchema),
    defaultValues: {
      campaignName: '',
      startAsInactive: '',
      brandAccountIncludeAll: '',
      brandAccountIds: [],
      campaignCurrency: 'EUR',
      creatorIds: [],
      trackedMetrics: [],
      overwriteStartingDate: null,
    },
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
    setValue,
    watch,
    getValues,
  } = methods

  const trackedMetricsValues = getValues('trackedMetrics')

  const watchedValueBrandAccountIncludeAll = watch('brandAccountIncludeAll')
  const watchedValueStartAsInactive = watch('startAsInactive')
  const watchedValueOverwriteStartingDate = watch('overwriteStartingDate')
  const watchedTrackedMetricsValue = watch('trackedMetrics')

  const handleClose = (event, reason) => {
    if (reason && reason == 'backdropClick' && 'escapeKeyDown') return

    setOpenModal(false)
    resetStep()
    reset({
      campaignName: '',
      startAsInactive: '',
      brandAccountIncludeAll: '',
      brandAccountIds: [],
      campaignCurrency: 'EUR',
      creatorIds: [],
      trackedMetrics: [],
      overwriteStartingDate: null,
    })

    // @ts-ignore
    dispatch([changeCampaignTopCreators([]), changeCampaignTopHashtags([])])
  }

  const onSubmit = async fields => {
    try {
      const { brandAccountIds, creatorIds, startAsInactive } = fields
      const payload = {
        ...fields,
        startAsInactive: startAsInactive == '1' || startAsInactive == '4' ? false : true,
        creatorIds: creatorIds?.map(creator => creator._id || creator.profileId),
        brandAccountIds: brandAccountIds?.map(creator => creator._id || creator.profileId),
      }
      // @ts-ignore
      await dispatch(createNewCampaign({ fields: payload, history, goToNextStep }))
    } catch (error) {
      console.error(error)
    }
  }

  const getStepContent = useMemo(() => {
    const stepComponents = [
      StepOne,
      StepTwo,
      StepThree,
      StepFour,
      StepFive,
      StepSix,
      StepSeven,
      StepEight,
      StepFinish,
    ]
    const stepIndex = currentStep - 1
    const StepComponent = stepComponents[stepIndex] || StepOne

    return (
      <StepComponent
        setValue={setValue}
        getValues={getValues}
        watch={watch}
        topHashtags={topHashtags}
        topCreators={topCreators}
        isSubmitting={isSubmitting}
        setCreatorPlatform={setCreatorPlatform}
        setCreatorsMention={setCreatorsMention}
        creatorPlatform={creatorPlatform}
        creatorsMention={creatorsMention}
        statusCode={statusCode}
      />
    )
  }, [
    currentStep,
    setValue,
    getValues,
    watch,
    watchedValueStartAsInactive,
    topHashtags,
    topCreators,
    isSubmitting,
    setCreatorsMention,
    setCreatorPlatform,
    creatorPlatform,
    creatorsMention,
  ])

  const getCopyCampaignStepContent = useMemo(() => {
    const stepComponents = [CopyCampaignFirst, CopyCampaignLast]

    const stepIndex = currentStep - 2
    const StepComponent = stepComponents[stepIndex] || StepOne

    return (
      <StepComponent
        setValue={setValue}
        getValues={getValues}
        goToNextStep={goToNextStep}
        setOpenModal={setOpenModal}
        newCopyCampaignId={newCopyCampaignId}
        resetStep={resetStep}
        reset={reset}
      />
    )
  }, [currentStep, getValues, goToNextStep, setOpenModal, newCopyCampaignId, resetStep, reset])

  const isCurrentStepFieldValue =
    !watch(fieldIndex[currentStep])?.length ||
    !!(
      currentStep === 2 &&
      (watchedValueStartAsInactive === '3' || watchedValueStartAsInactive === '4') &&
      !watchedValueOverwriteStartingDate
    )

  const handleGoToNextStep = () => {
    if (isCopyCampaign) {
      setIsCopyCampaign(false)
    }

    if (currentStep === 3 && !!creatorsMention?.length) {
      dispatch(
        // @ts-ignore
        getExploreData({
          mentions: creatorsMention,
          index: creatorPlatform?.toLowerCase(),
        })
      )
    }

    !watchedTrackedMetricsValue?.length && currentStep === 3 ? setStep(5) : goToNextStep()
  }

  useEffect(() => {
    if (
      currentStep === 4 &&
      (watchedValueBrandAccountIncludeAll === 'false' ||
        watchedValueBrandAccountIncludeAll === 'true')
    ) {
      setValue('brandAccountIds', trackedMetricsValues)
    }
  }, [watchedValueBrandAccountIncludeAll, currentStep])

  const onCopyCampaign = () => {
    setIsCopyCampaign(true)
    goToNextStep()
  }

  return (
    <ModalComponent
      isOpenModal={openModal}
      closeModal={handleClose}
      title={``}
      contentWidth={currentLang === 'de' ? '640px' : '560px'}
      smallPaddingContent
      isFixedContentHeight={false}
      withoutScrollWrapper
      disableEscapeKeyDown
    >
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(formData => onSubmit(formData))}
        isDisabledEnterKeySubmit
      >
        <Stack sx={{ py: 2, pb: isActivatedCopyCampaign ? 0 : 2 }}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ width: '100%', position: 'relative' }}
          >
            {((currentStep > 1 && currentStep < 9) || (isCopyCampaign && currentStep < 3)) && (
              <IconButton onClick={goToPrevStep} sx={{ position: 'absolute', left: 0 }}>
                <ArrowBackIosNewRoundedIcon sx={{ fontSize: '18px', color: 'black' }} />
              </IconButton>
            )}
            <Typography
              component="h1"
              align="center"
              sx={{ fontSize: '18px', lineHeight: '28px', fontWeight: 'bold', flex: 1 }}
            >
              {isActivatedCopyCampaign && currentStep === 3
                ? labelStrings.copyCampaign.finish.title
                : isActivatedCopyCampaign
                ? labelStrings.copyCampaign.first.title
                : labelStrings.createCampaign.createCampaignMainLabel}
            </Typography>
          </Stack>

          <Stack sx={{ py: 3 }} justifyContent="center" alignItems="center">
            {isActivatedCopyCampaign ? getCopyCampaignStepContent : getStepContent}
          </Stack>

          <Stack
            sx={{ px: 2 }}
            spacing={1}
            alignItems="center"
            justifyContent={currentStep === 1 ? 'space-between' : 'end'}
            direction="row"
          >
            {currentStep === 9 ? (
              <Button sx={{ textTransform: 'none' }} variant="contained" onClick={handleClose}>
                {labelStrings.finish}
              </Button>
            ) : (
              <>
                {currentStep === 1 && (
                  <Tooltip title={isCurrentStepFieldValue ? labelStrings.specifyNameCampaign : ''}>
                    <Box>
                      <Button
                        onClick={onCopyCampaign}
                        disabled={isCurrentStepFieldValue}
                        variant="outlined"
                      >
                        {labelStrings.copyCampaign.first.title}
                      </Button>
                    </Box>
                  </Tooltip>
                )}
                {!isActivatedCopyCampaign && (
                  <Stack direction="row" justifyContent="end" alignItems="end" spacing={2}>
                    <Button
                      onClick={handleClose}
                      sx={{ color: 'black', borderColor: 'black' }}
                      variant="outlined"
                    >
                      {labelStrings.cancel}
                    </Button>
                    {(currentStep === 3 ||
                      currentStep === 4 ||
                      currentStep === 6 ||
                      currentStep === 7) && (
                      <Button
                        onClick={handleGoToNextStep}
                        sx={{ color: 'black', borderColor: 'black' }}
                        variant="outlined"
                      >
                        {currentStep !== 4
                          ? labelStrings.createCampaign.skip
                          : labelStrings.createCampaign.continueWithoutBrandAccount}
                      </Button>
                    )}

                    {currentStep === 8 ? (
                      <>
                        <LoadingButton
                          sx={{ textTransform: 'none' }}
                          type="submit"
                          variant="contained"
                          loading={isSubmitting}
                        >
                          {labelStrings.finish}
                        </LoadingButton>
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={handleGoToNextStep}
                        disabled={isCurrentStepFieldValue}
                      >
                        {labelStrings.next}
                      </Button>
                    )}
                  </Stack>
                )}
              </>
            )}
          </Stack>
        </Stack>
      </FormProvider>
    </ModalComponent>
  )
}
