import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import useOutsideClick from '../../../../hooks/useOutsideClick'
import { DATE_FORMAT_STRINGS } from '../../../../constants/dayjs'
import { USER_INDEXES } from '../../../../constants/appSettings'
import { CalendarIcon } from '../../../common/icons/icons'
import Calendar from '../../../common/calendar/Calendar'
import {
  AdditionalFieldInputCommon,
  AdditionalFieldInputIconWrapper,
  CalendarWrapper,
} from './styles'

const AdditionalFieldInputDate = ({ id = '', value = '', platform = '', onChange }) => {
  const [isCalendarOpened, setIsCalendarOpened] = useState(false)

  const calendarWrapperRef = useRef(null)
  useOutsideClick({
    ref: calendarWrapperRef,
    callback: event => {
      if (!event.target.closest(`#af-${id}__button_open-calendar`)) {
        setIsCalendarOpened(false)
      }
    },
  })

  const isDateValid = !!Date.parse(value)
  let dateToDisplay = ''
  let dateToHighlight = null

  if (isDateValid) {
    dateToDisplay = dayjs(value).format(DATE_FORMAT_STRINGS.localizedDate)
    dateToHighlight = new Date(value)
  }

  const onChangeDate = newDate => {
    onChange(dayjs(newDate).format(DATE_FORMAT_STRINGS.iso8601))
    setIsCalendarOpened(false)
  }

  const openCalendar = () => setIsCalendarOpened(true)

  return (
    <>
      <AdditionalFieldInputCommon
        value={dateToDisplay}
        onClick={openCalendar}
        readOnly
        isDateInput
      />

      <AdditionalFieldInputIconWrapper id={`af-${id}__button_open-calendar`} onClick={openCalendar}>
        <CalendarIcon />
      </AdditionalFieldInputIconWrapper>

      {isCalendarOpened && (
        <CalendarWrapper ref={calendarWrapperRef} platform={platform}>
          <Calendar dateToHighlight={dateToHighlight} changeDate={onChangeDate} />
        </CalendarWrapper>
      )}
    </>
  )
}

AdditionalFieldInputDate.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  changeDate: PropTypes.func,
}

export default AdditionalFieldInputDate
