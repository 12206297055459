import React, { useMemo, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import { MOCKED_COMPARED_PROFILES } from '../../../constants/comparedProfiles'
import { SOCIAL_PLATFORMS_NAMES } from '../../../constants/appSettings'
import { MIN_USERS_FOR_SORTING } from '../../../constants/sortings'

import CompareColumn from './compareColumn'
import { ListWrapper } from '../../common/styledWrappers/comparedProfilesStyles'

const ComparedProfilesList = ({
  comparedProfiles = {},
  isMocked = true,
  removeComparedProfile,
  resetComparedProfiles,
  isInstagramCompare = true,
  isTikTokCompare = false,
  isYouTubeCompare = false,
  allComparedAudiencesBlocked = true,
  comparedRanks = {},
}) => {
  const NAVS_COLUMN_NAME = 'navs'
  const listRef = useRef()

  useEffect(() => {
    if (listRef && listRef.current) {
      listRef.current.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [isMocked])

  const profilesToRender = useMemo(() => {
    const profiles = isMocked ? MOCKED_COMPARED_PROFILES : comparedProfiles

    return {
      [NAVS_COLUMN_NAME]: {},
      ...profiles,
    }
  }, [comparedProfiles, isMocked])

  const socialPlatformIndex = useMemo(() => {
    if (isInstagramCompare) return SOCIAL_PLATFORMS_NAMES.instagram.index
    if (isTikTokCompare) return SOCIAL_PLATFORMS_NAMES.tiktok.index
    if (isYouTubeCompare) return SOCIAL_PLATFORMS_NAMES.youtube.index
    return ''
  }, [isInstagramCompare, isTikTokCompare, isYouTubeCompare])

  const handlers = {
    removeComparedProfile,
    resetComparedProfiles,
  }
  
  const disabledSorting = Object.keys(profilesToRender)?.length < MIN_USERS_FOR_SORTING //2 items+nav column

  return (
    <ListWrapper ref={listRef}>
      {Object.keys(profilesToRender)?.map(key => {
        return (
          <CompareColumn
            key={key}
            profile={profilesToRender[key]}
            isMocked={isMocked}
            isNavColumn={key === NAVS_COLUMN_NAME}
            socialPlatformIndex={socialPlatformIndex}
            allComparedAudiencesBlocked={allComparedAudiencesBlocked}
            disabledSorting={disabledSorting}
            comparedRanks={comparedRanks}
            {...handlers}
          />
        )
      })}
    </ListWrapper>
  )
}

ComparedProfilesList.propTypes = {
  comparedProfiles: PropTypes.object,
  isMocked: PropTypes.bool,
  removeComparedProfile: PropTypes.func,
  resetComparedProfiles: PropTypes.func,
  isInstagramCompare: PropTypes.bool,
  isTikTokCompare: PropTypes.bool,
  isYouTubeCompare: PropTypes.bool,
  allComparedAudiencesBlocked: PropTypes.bool,
  comparedRanks: PropTypes.object,
}

export default ComparedProfilesList
