import React from 'react'
import PropTypes from 'prop-types'
import './simpleVennDiagram.scss'
import { pretifyBigNum } from '../../../../utils'

export const SimpleVennDiagram = ({ overlapFollowers = 0, countFollowerX = 0, countFollowerY = 0 }) => (
  <div className="venn">
    <div className="circle">
      <p>
        <strong>{pretifyBigNum(countFollowerX)}</strong>
      </p>
    </div>

    <div className="circle">
      <p>
        <strong>{pretifyBigNum(countFollowerY)}</strong>
      </p>
    </div>

    <div className="joined">
      <p>
        <strong>{pretifyBigNum(overlapFollowers)}</strong>
      </p>
    </div>
  </div>
)

SimpleVennDiagram.propTypes = {
  overlapFollowers: PropTypes.number,
  countFollowerX: PropTypes.number,
  countFollowerY: PropTypes.number
}