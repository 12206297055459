import React from 'react'
import PropTypes from 'prop-types'

import useTranslation from '../../../localization/useTranslation'
import { unlockSelectedProfileAudienceToken } from '../../../redux/ducks/audienceTokensActions'
import { withReduxActions } from '../../../hocs/withRedux'
import { USER_INDEXES } from '../../../constants/appSettings'

import { themeCommonStyles } from '../../../themes/infludataThemes'
import { GradientButton } from '../../common/buttons/styledButtons'
import { UnlockedIcon } from '../../common/icons/icons'
import {
  AudienceReportMessageContainer,
  LockIconWrapper,
  AudienceReportMessageTitle,
  AudienceReportMessageText,
} from './styles'

const AudienceReportLocked = ({
  userId,
  platform,
  isTokensAvailable,
  isProfileTokenValid = false,
  isLockedInstant,
  isBlockedReport,
  isTrial = false,
  unlockSelectedProfileAudienceToken,
}) => {
  const { labelStrings } = useTranslation()

  const unlockAudienceAnalysis = () => {
    if (isTokensAvailable) unlockSelectedProfileAudienceToken({ userIds: [userId], platform })
  }

  const [gradientColor1, gradientColor2] = themeCommonStyles.getMainGradientColorsForPlatform(
    platform
  )

  return (
    <AudienceReportMessageContainer>
      {!isBlockedReport && (
        <LockIconWrapper>
          <UnlockedIcon
            isGradient={isTokensAvailable}
            gradientColor1={gradientColor1}
            gradientColor2={gradientColor2}
            {...(!isTokensAvailable && {
              color: themeCommonStyles.getColorPaletteForPlatform(platform).disabled,
            })}
          />
        </LockIconWrapper>
      )}

      {!isBlockedReport && (
        <GradientButton
          platform={platform}
          onClick={unlockAudienceAnalysis}
          isDisabled={!isTokensAvailable}
        >
          {labelStrings.unlockAnalysis}
        </GradientButton>
      )}

      {(() => {
        if (!isTokensAvailable && !isProfileTokenValid) {
          return (
            <AudienceReportMessageText>
              {isTrial
                ? labelStrings.audienceTokensTrialLimit
                : `${labelStrings.noAudienceTokensLeft}.`}
            </AudienceReportMessageText>
          )
        }

        return (
          <>
            <AudienceReportMessageTitle>
              {isBlockedReport
                ? labelStrings.audienceReportInfluencerDisabled
                : isLockedInstant
                ? labelStrings.audienceReportWillBeAvailableInstantly
                : labelStrings.audienceReportWillBeAvailableIn30Min}
            </AudienceReportMessageTitle>

            <AudienceReportMessageText>
              {isBlockedReport ? (
                <>
                  {labelStrings.unableToProvideAboutAudience}
                  <br />
                  {labelStrings.influencersRestrictsAccessToLikersFollowers}
                </>
              ) : (
                <>
                  {labelStrings.unlockAnalysisTip}
                  <br />
                  {labelStrings.unlockReportSpendTokenTip}
                </>
              )}
            </AudienceReportMessageText>
          </>
        )
      })()}
    </AudienceReportMessageContainer>
  )
}

AudienceReportLocked.propTypes = {
  userId: PropTypes.string.isRequired,
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube]),
  isTokensAvailable: PropTypes.bool.isRequired,
  isProfileTokenValid: PropTypes.bool,
  isLockedInstant: PropTypes.bool.isRequired,
  isBlockedReport: PropTypes.bool.isRequired,
  isTrial: PropTypes.bool,
  unlockSelectedProfileAudienceToken: PropTypes.func,
}

export default withReduxActions({ unlockSelectedProfileAudienceToken })(AudienceReportLocked)
