import React, { useEffect, useState } from 'react'
import { Box, FormControl, Stack, Button, Tooltip } from '@mui/material'
import { MultipleSelect } from '../../components/common/multiple-select'
import PickerDateRange from '../../components/pickers/PickerDateRange'
import useTranslation from '../../../localization/useTranslation'
import { dateFormatMaskYYYY_MM_dd } from '../dashboard/utils'
import { compareAsc, format } from 'date-fns'
// import { shallowEqual, useSelector } from 'react-redux'
// import { userGrantSelector } from '../../../redux/selectors'
// import { subDays } from 'date-fns'

const filteredByContentOptions = [
  { label: 'Posts (IG)', value: 'ig_posts' },
  { label: 'Stories (IG)', value: 'ig_stories' },
  { label: 'Reels (IG)', value: 'ig_reels' },
  { label: 'TIkToks', value: 'tt_videos' },
  { label: 'Videos (YT)', value: 'yt_videos' },
  { label: 'Shorts (YT)', value: 'yt_shorts' },
]

export const TopControls = props => {
  const {
    selectedCreators,
    setSelectedCreators,
    dateRange,
    setDateRange,
    onHandlePlay,
    creatorsOptions,
    selectedCreatorsFromQuery,
    openUnmatchedContent,
    selectedFilteredByContent,
    setSelectedFilteredByContent,
    selectedFilteredByContentFromQuery,
    onChangeDates,
  } = props

  const { labelStrings } = useTranslation()
  // const { campaign_content_limit_days } = useSelector(userGrantSelector, shallowEqual) || {}

  const limitPastDays = null /*campaign_content_limit_days
    ? subDays(new Date(), campaign_content_limit_days)
    : null*/

  const [openCreatorsSelect, setOpenCreatorsSelect] = useState(false)
  const [openContentTypesSelect, setOpenContentTypesSelect] = useState(false)

  const getAllOptionsValues = creatorsOptions?.map(option => option.value)
  const getAllOptionsValuesFilteredByContent = filteredByContentOptions?.map(option => option.value)

  useEffect(() => {
    if (getAllOptionsValues?.length && !selectedCreatorsFromQuery?.length) {
      setSelectedCreators(getAllOptionsValues)
    }
    if (
      getAllOptionsValuesFilteredByContent?.length &&
      !selectedFilteredByContentFromQuery?.length
    ) {
      setSelectedFilteredByContent(getAllOptionsValuesFilteredByContent)
    }
  }, [
    getAllOptionsValues?.length,
    selectedCreatorsFromQuery?.length,
    getAllOptionsValuesFilteredByContent?.length,
    selectedFilteredByContentFromQuery?.length,
  ])

  const onHandleConfirm = () => {
    onHandlePlay({ startingDateFromParams: null, endingDateFromParams: null })
    setOpenCreatorsSelect(false)
    setOpenContentTypesSelect(false)
  }

  const onAccessDates = newDates => {
    const startingDate = newDates[0] ? format(newDates[0], dateFormatMaskYYYY_MM_dd) : null
    const endingDate = newDates[1] ? format(newDates[1], dateFormatMaskYYYY_MM_dd) : null

    onChangeDates({ startingDateFromParams: startingDate, endingDateFromParams: endingDate })
  }

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={openUnmatchedContent ? 'start' : 'space-between' }
        spacing={1}
        sx={{ maxWidth: '590px', width: '100%' }}
      >
        <FormControl fullWidth sx={{ maxWidth: '150px' }}>
          <MultipleSelect
            size="small"
            open={openCreatorsSelect}
            onOpen={() => setOpenCreatorsSelect(true)}
            onClose={() => setOpenCreatorsSelect(false)}
            options={creatorsOptions || []}
            label={labelStrings.campaignContentOverview.creators}
            setSelected={setSelectedCreators}
            selected={selectedCreators}
            withConfirmAction={onHandleConfirm}
          />
        </FormControl>
        {!openUnmatchedContent && (
          <PickerDateRange
            datesValue={dateRange}
            setDatesValue={setDateRange}
            minDate={limitPastDays}
            onAccess={onAccessDates}
          />
        )}
        <FormControl fullWidth sx={{ maxWidth: '150px' }}>
          <MultipleSelect
            size="small"
            open={openContentTypesSelect}
            onOpen={() => setOpenContentTypesSelect(true)}
            onClose={() => setOpenContentTypesSelect(false)}
            options={filteredByContentOptions || []}
            label={labelStrings.filteredByContentLabel}
            setSelected={setSelectedFilteredByContent}
            selected={selectedFilteredByContent}
            withConfirmAction={onHandleConfirm}
          />
        </FormControl>
      </Stack>
      <Box>
        <Tooltip title={labelStrings.campaignContentOverview.exportTooltipText}>
          <span>
            <Button disabled variant="outlined">
              {labelStrings.campaignContentOverview.export}
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Stack>
  )
}
