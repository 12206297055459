import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const SimilarAccountsContainer = styled.div`
  margin-top: 50px;

  @media screen and (max-width: 1360px) {
    padding: 0;
  }
`

export const SimilarAccountsMinHeightWrapper = styled.div`
  min-height: 500px;
  height: 500px;
  position: relative;
`

export const TableContainer = styled.div`
  position: relative;

  .unavailable-section-overlay {
    background: rgba(252, 243, 243, 0.7);
  }

  @media screen and (max-width: 1360px) {
    padding: 0;
  }
`

export const Table = styled.table`
  width: 100%;
  margin-top: 30px;
  table-layout: fixed;
`

export const TBody = styled.tbody``

export const Tr = styled.tr`
  border-bottom: 1px solid ${props => props.theme.colors.lightBrown};

  .overlay-inner {
    width: 66px;
    height: 66px;
  }

  &:hover {
    ${props =>
      !props.isTableHead &&
      `
      background-color: ${props.theme.colors.ultraLightBrown};
      cursor: pointer;

      .overlay-inner {
        opacity: 1;
      }
    `}
  }
`

export const Th = styled.th`
  width: ${props => props.width || '25%'};
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-transform: uppercase;
  text-align: left;
  color: ${props => props.theme.colors.darkBrown};
  padding: 10px 5px;
`

export const Td = styled.td`
  width: ${props => props.width || '25%'};
`

export const WithLinkToProfile = styled(Link)`
  display: block;
  color: unset;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 100%;

  &:hover {
    text-decoration: none;
  }
`

export const AvatarWrapper = styled.div`
  padding: 10px 0;

  .rating_meter {
    circle {
      stroke-width: 7.5;
    }
  }
`

export const textGradientStyles = css`
  font-size: 13px;
  line-height: 15px;
  font-weight: 800;
  ${props => props.theme.getTextGradientStyles({ platform: props.platform })};
`

export const textCommonStyles = css`
  font-size: 13px;
  line-height: 16px;
  color: ${props => props.theme.colors.brown};
`

export const Username = styled.div`
  ${textGradientStyles}
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Country = styled.div`
  ${textCommonStyles}
  max-width: 100%;
`

export const Language = styled.div`
  ${textCommonStyles}
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
`

export const DisplayName = styled.div`
  ${textGradientStyles}
  max-width: 100%;
  word-break: break-word;
`

export const Biography = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: ${props => props.theme.colors.brown};
  max-width: 100%;
  word-break: break-word;
`

export const Followers = styled.div`
  font-size: 13px;
  font-weight: 800;
  line-height: 15px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.brown};
`

export const ImressionsPost = styled.div`
  font-size: 13px;
  font-weight: 800;
  line-height: 15px;
  text-transform: uppercase;
  ${textGradientStyles}
`

export const EngagementPercent = styled.div`
  font-size: 13px;
  font-weight: 800;
  line-height: 15px;
  color: ${props => props.theme.colors.brown};
`

export const SIMILAR_ACCOUNT_AVATAR_SIZE = 72

export const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 35px;

  .gradient-transparent-button + .gradient-transparent-button {
    margin-left: 10px;
  }
`

export const AvatarSkeleton = styled.div`
  width: 87px;
  height: 87px;
  background-color: ${props => props.theme.colors.lightBrown};
  border-radius: 50%;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.offWhite};
  }
`

export const TrSkeleton = styled(Tr)`
  &:hover {
    background-color: unset;
    cursor: default;
  }
`

export const TdWrapperSkeleton = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 10px;
  position: relative;

  .unavailable-section-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`

export const TdSkeleton = styled.td`
  &:last-child {
    ${TdWrapperSkeleton} {
      padding-right: 25px;
    }
  }
`

export const ValueSkeleton = styled.div`
  width: 70%;
  height: 5px;
  background-color: ${props => props.theme.colors.lightBrown};

  &:first-child {
    width: 100%;
  }

  & + & {
    margin-top: 17px;
  }
`

export const NoResultsFoundContainter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const NoResultsFoundMessage = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 3px;
  color: ${props => props.theme.colors.brown};
`
