import React from 'react'
import { useSelector } from 'react-redux'
import { currentOpenedCampaignSelector } from '../../../redux/selectors'
import { CarouselContent } from './CarouselContent'

export const MainSection = () => {
  const currentCampaign = useSelector(currentOpenedCampaignSelector)
  const { users } = currentCampaign

  const imgs = users[0]?.postArray?.slice(0, 5)
  const stories = users[0]?.storyArray?.slice(0, 5)
  const mergedMockData = imgs && stories && [...imgs, ...stories]

  const _mockedContentData = mergedMockData?.map((item, idx) => {
    return {
      id: `e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1${idx}`,
      name: 'Jayvion Simon',
      avatar: 'https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg',
      dateTimeAt: '2023-02-23T10:02:22.000Z',
      likes: 232,
      comments: 15100,
      estReach: 23000001,
      value: '1.3k - 2.4k',
      reachedTargetGroup: '65%',
      found: ['@brandTag', '#brandhash'],
      contentImg: item.url,
      contentVideo: item.videoUrl,
      contentVideoPreview: item.storyPreview,
      contentType: item.videoUrl ? 'story' : 'post',
    }
  })

  return (
    <>
      <CarouselContent
          mockedContentData={_mockedContentData}
        />
    </>
  )
}