import React, { useCallback, useEffect, useState } from 'react'
import useTranslation from '../../../../localization/useTranslation'
import { Form } from '../../../../components/common/styledWrappers/signupFormsStyles/signupStepOneFormStyles'
import {
  Button,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { defaultCountries, FlagImage, parseCountry, usePhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'

import { findCountryIsoCode, replaceAllEmojiInStr } from '../../../../utils'
import {
  contactPersonOptions,
} from '../../../../constants/signUpStepTwoOptions'
import GlobeImgFlag from '../../../../images/globe-flag-country.png'

const StyledFinishBtn = {
  fontFamily: `Lato, sans-serif`,
  marginTop: '48px',
  fontWeight: 'bold',
  fontSize: '17px',
  lineHeight: '16px',
  color: '#ffffff',
  width: '100%',
  textTransform: 'capitalize',
  border: 'none',
  background:
    'transparent linear-gradient(89deg, #FF726F 0%, #FFCA55 120%) 0% 0% no-repeat padding-box',
}

const INPUT_TYPES = {
  fN: 'firstName',
  lN: 'lastName',
  com: 'company',
  cntr: 'country',
  cP: 'contactPerson', 
  nt: 'notes',
}

const validateForExistingValue = value => !!value

export const ContactInformation = props => {
  const { setSteps, changeSignupState } = props || {}

  const { labelStrings, countryOptions } = useTranslation()

  const [formState, changeFormState] = useState({
    [INPUT_TYPES.fN]: '',
    [INPUT_TYPES.lN]: '',
    [INPUT_TYPES.com]: '',
    [INPUT_TYPES.cntr]: '',
    [INPUT_TYPES.cP]: 'guided tour',
    [INPUT_TYPES.nt]: '',
    isValidContactPerson: true,
  })

  const createCountriesOptions = useCallback(() => {
    const options = countryOptions?.filter(opt => !!opt.value)
    options.push({ value: 'Other', label: labelStrings.other })
    return options
  }, [countryOptions])
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    defaultCountry: 'de',
    value: formState.phone,
    countries: defaultCountries,
    onChange: data => {
      const { phone } = data
      changeFormState({ ...formState, [INPUT_TYPES.phne]: phone })
    },
  })

  const countries = createCountriesOptions()

  const onChangeInputHandler = inputType => ({ target }) =>
    changeFormState({ ...formState, [inputType]: target.value })

  const onSelectCountry = event => {
    const {
      target: { value },
    } = event

    const countryCode = findCountryIsoCode(countryOptions, value)
    setCountry(countryCode ? countryCode : 'de')


    return changeFormState({ ...formState, [INPUT_TYPES.cntr]: value })
  }

  const resetInputValidation = validationName => () =>
    !formState[validationName] && changeFormState({ ...formState, [validationName]: true })

  const onSelectContactPerson = event => {
    const {
      target: { value },
    } = event
    return changeFormState({ ...formState, [INPUT_TYPES.cP]: value })
  }

  const submitForm = async e => {
    e.preventDefault()

    const isValidContactPerson = validateForExistingValue(formState?.contactPerson)

    changeFormState({
      ...formState,
      isValidContactPerson,
    })

    if (isValidContactPerson) {
      const { isValidContactPerson, ...restOfForm } = formState || {}
      changeSignupState(prevState => ({
        ...prevState,
        stepOneData: { ...prevState.stepOneData },
        stepTwoData: {
          ...restOfForm,
          phone: '',
          companySize: '',
          position: ''
        },
      }))
      setSteps(prevStep => prevStep + 1)
    }
  }

  const isValidSelectedCountry = countries?.find(el => el.value === formState.country)
  const isValidContactPerson = contactPersonOptions?.find(
    el => el.value === formState.contactPerson
  )

  const countyLabel = ({ value, label, isoCode }) => (
    <Stack direction="row" alignItems="center">
      {isoCode &&
        isoCode
          .split('-')
          .map(code => (
            <img
              key={code}
              src={code === 'all' ? GlobeImgFlag : `https://flagcdn.com/16x12/${code}.png`}
              alt={code}
              style={{ marginRight: '5px' }}
            />
          ))}
      {replaceAllEmojiInStr(label)}
    </Stack>
  )

  useEffect(() => {
    if (!formState[INPUT_TYPES.cntr] && country.name) {
      changeFormState({ ...formState, [INPUT_TYPES.cntr]: country?.name })
    }
  }, [country?.name, formState[INPUT_TYPES.cntr]])

  return (
    <Form onSubmit={submitForm}>
      <Stack direction="row" spacing={2} sx={{ py: 1.5 }}>
        <TextField
          size="medium"
          sx={{
            '& .MuiInputBase-input': {
              background: 'white',
              borderRadius: '8px',
            },
          }}
          label={labelStrings.firstName}
          id="first-name"
          type="text"
          onChange={onChangeInputHandler(INPUT_TYPES.fN)}
          error={false}
          helperText={null}
        />
        <TextField
          size="medium"
          sx={{
            '& .MuiInputBase-input': {
              background: 'white',
              borderRadius: '8px',
            },
          }}
          label={labelStrings.lastName}
          id="last-name"
          type="text"
          onChange={onChangeInputHandler(INPUT_TYPES.lN)}
          error={false}
          helperText={null}
        />
      </Stack>

      <Stack sx={{ width: '100%' }}>
        <TextField
          size="medium"
          sx={{
            '& .MuiInputBase-input': {
              background: 'white',
              borderRadius: '8px',
            },
          }}
          label={labelStrings.company}
          id="company"
          type="text"
          onChange={onChangeInputHandler(INPUT_TYPES.com)}
          error={false}
          helperText={null}
        />
      </Stack>

      <Stack direction="row" sx={{ py: 1.5 }}>
        <TextField
          select
          size="medium"
          id="country-select"
          defaultValue={''}
          value={isValidSelectedCountry?.value || ''}
          label={labelStrings.countryCompany}
          onChange={onSelectCountry}
          sx={{
            width: '100%',
            '& .MuiInputBase-input': {
              background: 'white',
              borderRadius: '8px',
            },
          }}
          inputProps={{ 'aria-label': 'label' }}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  maxHeight: 200,
                },
              },
            },
            sx: { textTransform: 'capitalize' },
          }}
          error={false}
          helperText={null}
        >
          {countries?.map(({ value = '', isoCode = '', label = '' }) => {
            return (
              <MenuItem key={value} value={value}>
                {countyLabel({ value, label, isoCode })}
              </MenuItem>
            )
          })}
        </TextField>
      </Stack>

      <Stack spacing={1.5}>
        <TextField
          select
          size="medium"
          id="contact-person"
          value={isValidContactPerson?.value || ''}
          label={`Contact Person`}
          onChange={onSelectContactPerson}
          onFocus={resetInputValidation('isValidContactPerson')}
          sx={{
            width: '100%',
            '& .MuiInputBase-input': {
              background: 'white',
              borderRadius: '8px',
            },
          }}
          inputProps={{ 'aria-label': 'label' }}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  maxHeight: 170,
                },
              },
            },
            sx: { textTransform: 'capitalize' },
          }}
          error={!formState.isValidContactPerson}
          helperText={!formState.isValidContactPerson ? `This Field is Required` : null}
        >
          {contactPersonOptions?.map(({ value = '', label = '' }) => {
            return (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            )
          })}
        </TextField>

        <TextField
          size="large"
          sx={{
            '& .MuiInputBase-input': {
              background: 'white',
              borderRadius: '8px',
              minHeight: '40px',
            },
          }}
          label={`Notes`}
          id="notes"
          type="text"
          multiline
          maxRows={2}
          onChange={onChangeInputHandler(INPUT_TYPES.nt)}
          error={false}
          helperText={null}
        />
      </Stack>

      <Button variant="contained" sx={StyledFinishBtn} type="submit" size="large">
        {labelStrings.signupLocalization.continue}
      </Button>
    </Form>
  )
}