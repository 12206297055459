import { Button, TableCell, TableRow } from '@mui/material'
import { format } from 'date-fns'
import React from 'react'
import { fPercent } from '../../../../new-ui/utils/formatNumber'

export const YourOverlapsFieldTableRow = ({ row, onOpenOverlap }) => {
  const {
    platform,
    added,
    overlap,
    influencerMetadata
  } = row

  const creatorsNames = influencerMetadata?.map(element => `@${element.username}`).join(', ')

  return (
    <TableRow hover>
      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {platform}
      </TableCell>

      <TableCell sx={{ maxWidth: '250px' }} align="left">
        {creatorsNames || 'user'}
      </TableCell>

      <TableCell align="left">{format(new Date(added), 'do MMMM yyyy HH:mm')}</TableCell>

      <TableCell align="left">{overlap && overlap.totalScore ? fPercent(overlap.totalScore) : '-'}</TableCell>

      <TableCell align="center">
        {overlap ? (
            <Button sx={{ color: '#442424' }} onClick={onOpenOverlap}>
              Open
            </Button>
          ) : (
            'Requested'
          )}
      </TableCell>
    </TableRow>
  )
}
