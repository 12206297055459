export const COMPARED_LOADING = 'IS_COMPARED_PROFILE_LOADING'
export const COMPARED_PROFILES_LIMIT = 20
export const MIN_USERS_FOR_RANKS = 2
export const NAME_ROUTE_COMPARE_PROFILES = '/compare-profiles'

export const COMPARED_PROFILE_DEFAULT_LOADING_OBJ = {
  [COMPARED_LOADING]: true,
}

export const COMPARED_PROFILE_LOADED_OBJ_PART = {
  [COMPARED_LOADING]: false,
}

export const MOCKED_COMPARED_SINGLE_PROFILE = Array.from(new Array(20)).reduce(
  (obj, _, idx) => ({ ...obj, [`mocked${idx}`]: `mocked${idx}` }),
  {}
)

export const MOCKED_COMPARED_PROFILES = ['id1', 'id2', 'id3', 'id4', 'id5'].reduce(
  (obj, id) => ({
    ...obj,
    [id]: {
      _id: id,
      ...MOCKED_COMPARED_SINGLE_PROFILE,
    },
  }),
  {}
)

export const COMPARED_RANKS_PROP_NAMES = {
  followers: 'followers',
  viewsPerPost: 'viewsPerPost',
  avgPostTime: 'avgPostTime',
  engagement: 'engagementMean',
  monthlyGrowthChange: 'monthlyGrowthObject.monthlyChange',
  monthlyGrowthDiff: 'monthlyGrowthObject.monthlyDifference',
  avgLikesPerPost: 'avgLikesPerPost',
  avgCommentsPerPost: 'avgCommentsPerPost',
  audienceRealPeoplePercentage: 'audienceAnalysis.audienceType.realPeoplePercentage',
  storiesDay: 'storiesDay',
  reelsWeek: 'reelsWeek',
  engagementReels: 'engagementReels',
  viewsPerStory: 'viewsPerStory',
  playsPerReel: 'playsPerReel',
  postWeek: 'postWeek',
  totalViews: 'totalViews',
  profileScore: 'profileScore',
  postsPerWeek: 'postsPerWeek',
  viralPosts: 'viralPosts',
  expectedAudiencePerPost: 'expectedAudiencePerPost',
  profileScoreYT: 'profileScoreYT',
  engagementRatePosts: 'engagementRatePosts',
  medianViewCountPosts: 'medianViewCountPosts',
  engagementRateShorts: 'engagementRateShorts',
  medianViewCountShorts: 'medianViewCountShorts',
  growthRateYT: 'growthRateYT',
  growthRateFollowersYT: 'growthRateFollowersYT',
  averageViewsGrowthVideos: 'averageViewsGrowthVideos',
  growthByAverageViews30dVideos: 'growthByAverageViews30dVideos',
  averageViewsGrowthShorts: 'averageViewsGrowthShorts',
  growthByAverageViews30dShorts: 'growthByAverageViews30dShorts'
}

export const COMPARED_PROFILES_DEFAULT_RANKS = Object.keys(COMPARED_RANKS_PROP_NAMES).reduce(
  (obj, prop) => {
    obj[prop] = {}
    return obj
  },
  {}
)
