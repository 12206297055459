import styled, { css } from 'styled-components'
import { USER_INDEXES } from '../../../../constants/appSettings'
import { convertHexToRgba } from '../../../../utils'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
  border-radius: 25px;
  background-color: ${props => {
    const { colors } = props.theme
    switch (props.platform) {
      case USER_INDEXES.instagram:
        return colors.offWhite
      case USER_INDEXES.tiktok:
        return convertHexToRgba(colors.aquaUltraLight, 0.3)
    }
  }};
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 1px solid ${props => props.theme.colors.lightBrown};
  padding: 15px 40px;
`

const commonTextStyles = css`
  font-size: 13px;
  line-height: 16px;
  color: ${props => props.theme.colors.mediumBrown};
`

export const ReportLabel = styled.div`
  ${commonTextStyles}
  font-weight: bold;
  max-width: 100%;
  margin-bottom: 18px;
`

export const UsernameWrapper = styled.a`
  ${props =>
    props.isBranded &&
    `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  `}
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover {
    text-decoration: none;
  }
`

export const Username = styled.div`
  font-size: 15px;
  line-height: 19px;
  font-weight: bold;
  color: ${props => props.theme.colors.mediumBrown};
  margin-right: 5px;
  margin-bottom: 18px;
`

export const PlatformIconWrapper = styled.div`
  .icon_instagram,
  .icon_tiktok {
    width: 30px;
    height: 30px;
  }
`

export const LogoIconWrapper = styled.div`
  svg {
    g,
    path,
    rect {
      fill: ${props => props.theme.colors.mediumBrown};
    }
  }
`

export const Main = styled.div`
  flex: 1;
  padding: 15px 30px;
  overflow: hidden;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.white};
  padding: 15px 40px;
`

export const PageNumber = styled.div`
  ${commonTextStyles}
  font-weight: bold;
`

export const Message = styled.div`
  ${commonTextStyles}
  max-width: 475px;
  text-align: center;
`

export const Link = styled.a`
  ${commonTextStyles}
  text-decoration: underline;
  text-transform: lowercase;

  &:hover,
  &:active {
    ${commonTextStyles}
    text-decoration: underline;
  }
`
