import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import WithUpgradeTip from "../common/popups/WithUpgradeTip"
import { NavMenuItemWrapper } from "../common/styledWrappers/navbarStyles"

const NavbarLinkItem = ({
  linkPath = "",
  text = "",
  Icon,
  withSignup = false,
  signupTipText = "",
  isActive = false,
  isDisabled = false,
}) => {
  const onNavClick = (event) => event.preventDefault()

  const _createNavItem = ({ isUserAuthorized = false } = {}) => (
    <NavMenuItemWrapper isActive={isActive} isDisabled={isDisabled}>
      <Link
        to={`/${linkPath}`}
        {...(!isUserAuthorized && { onClick: onNavClick })}
      >
        <Icon sx={{ fontSize: 26 }} />
        {text && <span>{text}</span>}
      </Link>
    </NavMenuItemWrapper>
  )

  return withSignup ? (
    <WithUpgradeTip
      withSignup={withSignup}
      tipText={signupTipText}
      position='bottom center'
    >
      {_createNavItem({ isUserAuthorized: false })}
    </WithUpgradeTip>
  ) : (
    _createNavItem({ isUserAuthorized: true })
  )
}

NavbarLinkItem.propTypes = {
  linkPath: PropTypes.string,
  text: PropTypes.string,
  Icon: PropTypes.elementType,
  withSignup: PropTypes.bool,
  signupTipText: PropTypes.string,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

export default NavbarLinkItem
