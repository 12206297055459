import React from 'react'
import { useHistory } from 'react-router-dom'
import { Stack, Typography, Avatar, Box } from '@mui/material'
import Scrollbar from '../../../components/scrollbar/Scrollbar'

export const UnlockedReportsPage = props => {
  const { unlockedUsers, setIsRenderUnlockedReports } = props || {}

  const history = useHistory()

  const onRenderReport = ({ instagramId }) => {
    setIsRenderUnlockedReports(false)
    history?.push(`/audience-reports?instagramId=${instagramId}`)
  }

  return (
    <Stack alignItems="start" spacing={2}>
      <Typography align="center" sx={{ color: '#784E4E', fontWeight: 'bold', fontSize: '18px' }}>
        Your Unlocked Reports
      </Typography>
      <Box sx={{ width: '100%', height: '450px' }}>
        <Scrollbar sx={{ width: '100%', maxHeight: '440px', p: 1 }}>
          <Stack
            direction="row"
            justifyContent={unlockedUsers?.length >= 4 ? 'center' : 'start'}
            sx={{ flexWrap: 'wrap', gap: 2, rowGap: 2 }}
          >
            {unlockedUsers?.map(user => {
              return (
                <Stack
                  direction="row"
                  key={user?._id}
                  spacing={1}
                  alignItems="center"
                  onClick={() => onRenderReport({ instagramId: user?.instagramId })}
                  sx={{
                    width: 'fit-content',
                    '&:hover': {
                      opacity: 0.8,
                    },
                    cursor: 'pointer',
                    border: '1px solid #784E4E',
                    borderRadius: '9px',
                    px: 2,
                    py: 1,
                  }}
                >
                  <Avatar src={user?.profilePicURL} />
                  <Typography sx={{ color: '#784E4E' }}>@{user?.username}</Typography>
                </Stack>
              )
            })}
          </Stack>
        </Scrollbar>
      </Box>
    </Stack>
  )
}
