// @ts-nocheck
import React, { forwardRef } from 'react'
import { DashboardHeaderPdfView } from './DashboardHeaderPdfView'
import { Box, styled } from '@mui/material'
import { PdfViews } from './PdfViews'

const ProfilePdfDocumentHideWrapper = styled(Box)({
  position: 'fixed',

  /* Hide redered components */
  top: '-9999px',
  left: '-9999px',
  zIndex: -9999,

  /* Show rendered components (for dev) */
  // top: 0,
  // left: 0,
  // bottom: 0,
  // overflow: 'scroll',
  // zIndex: 9999,
  // background: 'white',
  // border: '1px solid black',
})

const DashboardPdfDocument = forwardRef(({ currentOpenedDashboard, currentCampaign }, ref) => {
  return (
    <ProfilePdfDocumentHideWrapper>
      <Box ref={ref}>
        <Box sx={{ px: 2 }}>
          <DashboardHeaderPdfView
            dashboardName={currentOpenedDashboard?.dashboardName}
            creators={currentCampaign?.creators}
            campaignName={currentCampaign?.campaignName}
            campaignDescription={currentCampaign?.campaignDescription}
            creatorsSelectValue={currentOpenedDashboard?.selectedCreators?.length}
            selectedDates={currentOpenedDashboard?.selectedDates}
          />
        </Box>
        <PdfViews />
      </Box>
    </ProfilePdfDocumentHideWrapper>
  )
})

export default DashboardPdfDocument
