import styled from 'styled-components'

export const SelectWidthContainer = styled.div`
  margin: 0 -10px 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  user-select: none;
`

export const FieldTypeSelectContainer = styled.div`
  width: 100%;
  padding: 5px 10px;
  background-color: ${props => props.theme.colors.lightBrown};
  border-radius: 10px;
  font-family: ${props => props.theme.fontFamily.inter};
`

export const FieldTypeSelectName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
  color: ${props => props.theme.colors.darkBrown};
  padding: 5px 10px;
  cursor: ${props => (props.canChangeFieldType ? 'pointer' : 'default')};
  position: relative;
`

export const SelectArrowIconWrapper = styled.div`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: ${props => (props.isSelectOpened ? 'none' : 'block')};
`

export const FieldTypeSelectItems = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  border-radius: 7px;
  padding: 10px;
  margin: 5px 0;
  display: ${props => (props.isSelectOpened ? 'block' : 'none')};
`

export const FieldTypeSelectItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.brown};
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 6px;

  &:hover {
    background-color: ${props => props.theme.colors.lightBrown};
  }
`

export const IconWrapper = styled.div`
  width: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
`

export const FieldTypeSelectItemText = styled.div``

export const SelectWrapper = styled.div`
  & > * {
    color: ${props => props.theme.color.grey};
  }
`
export const LabelWrapper = styled.div`
  display: flex;
  position: relative;
  margin: 10px 0px 0 0;
  width: fit-content;
`