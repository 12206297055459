import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Avatar, ListItemAvatar } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import useTranslation from '../../../localization/useTranslation'
import { StyledAddedText, StyledListItem, StyledListItemText } from './styles'

export const CreatorsListItem = props => {
  let {
    creator = {},
    onAddCreatorsToCampaign,
    creatorsTableList = [],
    showList = false,
    isAllowedToAddCreators = false,
    isPipeline = false,
    onAssignCreators,
  } = props || {}

  const { labelStrings } = useTranslation()

  const [loadingCreator, setLoadingCreator] = useState(false)
  const [selectedCreatorId, setSelectedCreatorId] = useState(null)

  const findCreatorInTable = creatorId => {
    return !!creatorsTableList?.find(creator => creator._id === creatorId)
  }

  const onOpenCreatorProfile = () => {
    window.open(`${process.env.REACT_APP_PREFIX}profile/${creator._id}`, '_blank')
  }

  const handleAddCreator = (e, creatorId) => {
    e.stopPropagation()

    if (!isAllowedToAddCreators) return

    if (!isPipeline) {
      setLoadingCreator(true)
      onAddCreatorsToCampaign(creatorId)
      setSelectedCreatorId(creatorId)
    }

    if (isPipeline) {
      onAssignCreators(creatorId)
    }
  }

  useEffect(() => {
    if (findCreatorInTable(selectedCreatorId) && loadingCreator) {
      setLoadingCreator(false)
      setSelectedCreatorId(null)
    }
  }, [creatorsTableList?.length])

  return (
    <StyledListItem
      onClick={onOpenCreatorProfile}
      secondaryAction={
        <>
          {findCreatorInTable(creator._id) ? (
            <StyledAddedText>
              {isPipeline ? labelStrings.campaignPipeline.assigned : labelStrings.added}
            </StyledAddedText>
          ) : (
            <LoadingButton
              loading={loadingCreator && showList}
              variant="outlined"
              onClick={e => {
                if (isPipeline) {
                  handleAddCreator(e, creator)
                } else {
                  handleAddCreator(e, creator._id)
                }
              }}
              sx={{ width: '32px', height: '28px', minWidth: '32px' }}
            >
              {!loadingCreator && <AddRoundedIcon />}
            </LoadingButton>
          )}
        </>
      }
    >
      <ListItemAvatar>
        <Avatar
          alt={creator.username ? creator.username : creator.displayName}
          src={creator.profilePicURL}
        />
      </ListItemAvatar>
      <StyledListItemText
        primary={creator.username ? `@${creator.username}` : `@${creator.displayName}`}
      />
    </StyledListItem>
  )
}

CreatorsListItem.propTypes = {
  creator: PropTypes.object,
  onAddCreatorsToCampaign: PropTypes.func,
  creatorsTableList: PropTypes.array,
  showList: PropTypes.bool,
  isAllowedToAddCreators: PropTypes.bool,
  isPipeline: PropTypes.bool,
  onAssignCreators: PropTypes.func,
}