import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { themeCommonStyles } from '../../../../themes/infludataThemes'
import TagCloud from 'react-tag-cloud'
import NotEnoughDataForChartMessage from '../../../charts/NotEnoughDataForChartMessage'
import useTranslation from '../../../../localization/useTranslation'
import {
  ChartTitle,
  TipDescriptionContainer,
  TipDescriptionLabel,
  TipDescriptionOverlay,
  TipDescriptionText,
} from './styles'

const AudienceTagsCloud = ({ userindex = '', data = [], isForPdf = false }) => {
  const { labelStrings } = useTranslation()

  const generateTags = useMemo(
    () =>
      data?.map(tag => (
        <div
          key={tag.word}
          style={{
            fontSize: tag.value >= 15 ? 38 : tag.value >= 5 ? 26 : 16,
            fontWeight: 'bold',
            color:
              tag.value >= 15
                ? themeCommonStyles.colors.salmon
                : tag.value >= 5
                ? themeCommonStyles.colors.mauve
                : themeCommonStyles.colors.mediumBrown,
          }}
        >
          {tag.word}
        </div>
      )),
    [data]
  )

  return (
    <>
      {!isForPdf && <ChartTitle>{labelStrings.wordCloudComments}</ChartTitle>}
      {!isForPdf && (
        <TipDescriptionContainer>
          <TipDescriptionLabel>{labelStrings.whatDoesThisWordCloudMean}?</TipDescriptionLabel>
          <TipDescriptionOverlay>
            <TipDescriptionText dangerouslySetInnerHTML={{ __html: labelStrings.wordCloudTip }} />
          </TipDescriptionOverlay>
        </TipDescriptionContainer>
      )}

      {!!data?.length ? (
        <TagCloud
          style={{
            fontFamily: 'Inter',
            fontSize: 26,
            fontWeight: 'bold',
            fontStyle: 'normal',
            color: () => themeCommonStyles.colors.mediumBrown,
            padding: 5,
            // @ts-ignore
            width: '100%',
            height: '100%',
          }}
        >
          {generateTags}
        </TagCloud>
      ) : (
        <NotEnoughDataForChartMessage platform={userindex} isForPdf={isForPdf} />
      )}
    </>
  )
}

AudienceTagsCloud.propTypes = {
  userindex: PropTypes.string,
  isForPdf: PropTypes.bool,
  data: PropTypes.array,
}

export default AudienceTagsCloud
