import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import useOutsideClick from '../../../../hooks/useOutsideClick'
import useTranslation from '../../../../localization/useTranslation'
import { USER_INDEXES } from '../../../../constants/appSettings'
import { themeCommonStyles } from '../../../../themes/infludataThemes'
import ConfirmationModal from '../../../common/modals/Modal/ConfirmationModal'
import { EditIcon, DeleteIcon } from '../../../common/icons/icons'
import {
  AdditionalFieldToolsOverlay,
  AdditionalFieldToolsItems,
  AdditionalFieldToolsItem,
  AdditionalFieldToolsItemIconWrapper,
  AdditionalFieldToolsItemTitle,
} from './styles'

const AdditionalFieldTools = ({
  platform,
  openFieldEditing,
  deleteAdditionalFieldById,
  toggleIsToolsOpened,
}) => {
  const { labelStrings } = useTranslation()

  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false)

  const wrapperRef = useRef(null)
  useOutsideClick({
    ref: wrapperRef,
    callback: event => {
      if (!event.target.closest('.confirmation-modal')) {
        toggleIsToolsOpened()
      }
    },
  })

  const onEditClick = () => {
    openFieldEditing()
    toggleIsToolsOpened()
  }

  const onDeleteClick = () => {
    deleteAdditionalFieldById()
    setIsDeleteConfirmModalOpen(false)
    toggleIsToolsOpened()
  }

  const onCancelClick = () => {
    setIsDeleteConfirmModalOpen(false)
    toggleIsToolsOpened()
  }

  const iconGradients = themeCommonStyles.getMainGradientColorsForPlatform(platform)

  return (
    <>
      <AdditionalFieldToolsOverlay ref={wrapperRef}>
        <AdditionalFieldToolsItems>
          <AdditionalFieldToolsItem onClick={onEditClick}>
            <AdditionalFieldToolsItemIconWrapper>
              <EditIcon
                gradientColor1={iconGradients[0]}
                gradientColor2={iconGradients[1]}
                isGradient
              />
            </AdditionalFieldToolsItemIconWrapper>
            <AdditionalFieldToolsItemTitle>{labelStrings.editField}</AdditionalFieldToolsItemTitle>
          </AdditionalFieldToolsItem>

          <AdditionalFieldToolsItem onClick={() => setIsDeleteConfirmModalOpen(true)}>
            <AdditionalFieldToolsItemIconWrapper>
              <DeleteIcon
                gradientColor1={iconGradients[0]}
                gradientColor2={iconGradients[1]}
                isGradient
              />
            </AdditionalFieldToolsItemIconWrapper>
            <AdditionalFieldToolsItemTitle>
              {labelStrings.deleteField}
            </AdditionalFieldToolsItemTitle>
          </AdditionalFieldToolsItem>
        </AdditionalFieldToolsItems>
      </AdditionalFieldToolsOverlay>

      {isDeleteConfirmModalOpen && (
        <ConfirmationModal
          platform={platform}
          confirmationText={labelStrings.confirmAdditionalFieldDeletion}
          confirmButtonText={labelStrings.deleteField}
          cancelButtonText={labelStrings.cancel}
          onConfirm={onDeleteClick}
          onCancel={onCancelClick}
        />
      )}
    </>
  )
}

AdditionalFieldTools.propTypes = {
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  openFieldEditing: PropTypes.func,
  deleteAdditionalFieldById: PropTypes.func,
  toggleIsToolsOpened: PropTypes.func,
}

export default AdditionalFieldTools
