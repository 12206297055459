export const BUY_SEATS_MAP = {
  '0': 0,
  '10': 10,
  '50': 50,
  '100': 100,
  '250': 250,
  '500': 500,
  '>500': 600,
}

export const SEATS_PRICES_MAP = {
  '0': 0,
  '10': 9.9,
  '50': 7.9,
  '100': 6.9,
  '250': 5.9,
  '500': 4.9,
}

export const DEFAULT_CAMPAIGN_SEATS = {
  pro: 20,
  advanced: 5,
}
