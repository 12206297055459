// @ts-nocheck
import React from 'react'
import { Button, Stack, Typography } from '@mui/material'
import useTranslation from '../../../../localization/useTranslation'
import SignUpSuccess from '../../SignUpSuccess'
import useResponsive from '../../../../new-ui/hooks/useResponsive'

export const ScheduleQuickDemo = props => {
  const { handleContinue } = props || {}

  const { labelStrings } = useTranslation()
  const isMobile = useResponsive('down', 'sm')

  return (
    <Stack sx={{ width: '100%', height: '100%' }}>
      <Typography
        sx={{
          fontFamily: `Lato, sans-serif`,
          fontSize: isMobile ? '16px' : '26px',
          fontWeight: 600,
          color: '#1F2937',
          textAlign: isMobile ? 'center' : 'left',
          lineHeight: isMobile ? 'initial' : 'inherit',
          mt: isMobile ? 1 : 0
        }}
      >
        {labelStrings.signupLocalization.lastStep}
      </Typography>
      {!isMobile ? (
        <Stack spacing={3} sx={{ mt: isMobile ? 0 : 3, pb: 5 }}>
          <Typography
            sx={{
              fontFamily: `Lato, sans-serif`,
              fontSize: '16px',
              fontWeight: 600,
              color: '#1F2937',
            }}
          >
            {labelStrings.signupLocalization.aQuickDemo}
          </Typography>

          <Typography
            sx={{
              fontFamily: `Lato, sans-serif`,
              fontSize: '16px',
              fontWeight: 600,
              color: '#1F2937',
            }}
          >
            {labelStrings.signupLocalization.ifYouAreOnlyInterested}
          </Typography>
        </Stack>
      ) : (
        <SignUpSuccess isStepSignup />
      )}

      <Stack direction="row" alignItems="center" sx={{ mt: isMobile ? 5 : 3 }}>
        <Button
          variant={isMobile ? 'outlined' : 'text'}
          sx={{
            fontSize: '17px',
            lineHeight: '16px',
            fontFamily: `Lato, sans-serif`,
            textDecoration: 'none',
            width: isMobile ? '100%' : 'fit-content',
            borderRadius: '24px',
            py: isMobile ? 2 : 0,
            maxHeight: '46px'
          }}
          onClick={handleContinue}
        >
          {labelStrings.signupLocalization.skipForNow}
        </Button>
      </Stack>
    </Stack>
  )
}
