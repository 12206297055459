import React, { useState, useEffect, useMemo, Fragment } from 'react'
import PropTypes from 'prop-types'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'

import {
  selectedPlatformSelector,
  currentSearchQuerySelector,
  userGrantSelector,
  availableCountriesCitiesSelector,
} from '../../../redux/selectors'
import {
  resetSearchResults,
  fetchStartupSearch,
  updateSearch,
  abortSearchProccess,
} from '../../../redux/ducks/searchDucks'
import useTranslation from '../../../localization/useTranslation'
import { parseArrValuesToString } from '../../../utils'
import { getSuggestionsFromCacheOrFetch, cancelAllFetches } from './searchbarTipsFetches'
import SearchbarMediator from './SearchbarMediator'
import SocialPlatformTabs from '../../common/tabs/SocialPlatformTabs_'
import {
  SearchContainerWrapper,
  WithScrollWrapper,
  SearchbarContent,
} from '../../common/styledWrappers'
import { USER_INDEXES } from '../../../constants/appSettings'
import WithTip from '../../common/popups/WithTip'
import { ToggleSwitchSearchContent } from '../../toggleSwitch/ToggleSwitchSearchContent'

const SearchBarContainer = ({ isSearchEnabled, userindex = '', searchType = '', onChangeSearchType }) => {
  const { labelStrings } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const selectedPlatform = useSelector(selectedPlatformSelector, shallowEqual)
  const currentQuery = useSelector(currentSearchQuerySelector, shallowEqual)

  const availableCountriesCities = useSelector(availableCountriesCitiesSelector, shallowEqual)
  const {
    enable_business_search: allowBusinessSearch,
    package_free: isFreeUser,
    no_plan: noPlan,
    audience_search_enabled,
    minimum_growth,
    content_search,
  } = useSelector(userGrantSelector, shallowEqual)

  const [suggestionsKeywords, changeSuggestionsKeywords] = useState([])

  useEffect(() => {
    isSearchEnabled && dispatch(fetchStartupSearch(location))
    return () => {
      if (isSearchEnabled) {
        dispatch(abortSearchProccess())
        cancelAllFetches()
      }
    }
  }, [isSearchEnabled])

  useEffect(() => {
    if (isSearchEnabled) {
      ;(async () => {
        const suggestionsKeywords = await getSuggestionsFromCacheOrFetch(currentQuery)
        if (suggestionsKeywords && suggestionsKeywords.length)
          changeSuggestionsKeywords(suggestionsKeywords)
      })()
    }

    return () => {
      isSearchEnabled && cancelAllFetches()
    }
  }, [currentQuery])

  // reset search params and results
  const clearSearch = () => {
    cancelAllFetches()
    dispatch(resetSearchResults(history))
  }

  // update search reusable handler
  const changeSearch = newValuesObj => dispatch(updateSearch({ newValuesObj }))

  // search handlers map
  const handlersMap = useMemo(
    () => ({
      country: newValuesObj => changeSearch({ country: parseArrValuesToString(newValuesObj), city: '' }),
      city: newValuesObj => changeSearch({ city: parseArrValuesToString(newValuesObj) }),
      followers: ([min, max]) => changeSearch({ followerMin: min, followerMax: max }),
      plays: ([min, max]) => changeSearch({ playsMin: min, playsMax: max }),
      views: ([min, max]) => changeSearch({ viewsMin: min, viewsMax: max }),
      playsPerReel: ([min, max]) => changeSearch({ reelPlaysMin: min, reelPlaysMax: max }),
      language: selectedLang => changeSearch({ language: selectedLang.value }),
      gender: selectedGender => changeSearch({ gender: selectedGender.value }),
      keywords: selectedKeywordsArr =>
        changeSearch({ keywords: parseArrValuesToString(selectedKeywordsArr), categories: '' }),
      categories: selectedCategoriesArr =>
        changeSearch({ categories: parseArrValuesToString(selectedCategoriesArr), keywords: '' }),
      email: () => changeSearch({ mustIncludeEmail: !currentQuery.mustIncludeEmail }),
      appearsOn: selectedValuesArr =>
        changeSearch({ appearsOn: parseArrValuesToString(selectedValuesArr) }),
      businessSearch: ({ target }) => changeSearch({ businessSearch: target.checked }),
      growthRate: value => changeSearch({ growthRate: value }),
      audience: audience => changeSearch({ audience }),
      accountType: typeValue => changeSearch({ isShortsCreator: typeValue }),
      youTubeShorts: ([min, max]) => changeSearch({ shortsPlaysMin: min, shortsPlaysMax: max }),
      youtubeVideos: ([min, max]) => changeSearch({ viewsMin: min, viewsMax: max }),
      location: placesId => changeSearch({ placesId, radiusKM: 10 }),
      radiusKM: km => changeSearch({ radiusKM: km }),
      engagementRate: value => changeSearch({ engagementRate: Number(value) }),
      hideCreatorsInCollection: ({ target }) => changeSearch({ hideCreatorsInCollection: !!(target.checked) }),
    }),
    [currentQuery]
  )

  const permissions = useMemo(
    () => ({
      allowBusinessSearch: !!allowBusinessSearch,
      allowCitiesSearch: !isFreeUser,
      allowKeywordsSearch: !isFreeUser, //TODO: set it to true, if we want free user to use keywords search
      allowAudienceSearch:
        (userindex === USER_INDEXES.instagram || userindex === USER_INDEXES.youtube) &&
        audience_search_enabled,
    }),
    [allowBusinessSearch, isFreeUser, audience_search_enabled, userindex]
  )

  const OuterWrapper = isSearchEnabled ? Fragment : WithTip

  return (
    <OuterWrapper
      {...(!isSearchEnabled && {
        withSignup: !noPlan,
        tipText: noPlan
          ? labelStrings.notActivatedAccountTipTitle
          : labelStrings.disabledFunctionInYourPlanTip,
        skipSpecialUpgradeText: noPlan,
        position: 'center center',
      })}
    >
      <div>
        <ToggleSwitchSearchContent
          onChange={onChangeSearchType}
          checked={searchType}
          isContentSearch={content_search}
        />
        <SocialPlatformTabs userindex={userindex} />
        <SearchbarContent isActive={isSearchEnabled}>
          <WithScrollWrapper showScroll isSearch isSideBar>
            <SearchContainerWrapper isSearchEnabled={isSearchEnabled}>
              <SearchbarMediator
                clearSearch={clearSearch}
                query={currentQuery}
                handlersMap={handlersMap}
                suggestionsKeywords={suggestionsKeywords}
                selectedPlatform={selectedPlatform}
                permissions={permissions}
                availableCountriesCities={availableCountriesCities}
                userindex={userindex}
                grants={{ minimum_growth }}
                searchType={searchType}
              />
            </SearchContainerWrapper>
          </WithScrollWrapper>
        </SearchbarContent>
      </div>
    </OuterWrapper>
  )
}

SearchBarContainer.propTypes = {
  isSearchEnabled: PropTypes.bool,
  userindex: PropTypes.string,
  searchType: PropTypes.string,
  onChangeSearchType: PropTypes.func,
}

export default SearchBarContainer
