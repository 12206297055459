import * as Yup from 'yup'

export const CustomFieldSchema = Yup.object().shape({
  dataFormat: Yup.string().required('Data Format is Required'),
  customFieldName: Yup.string().required('Custom Field Name is Required'),
  metricName: Yup.string().required('Metric Name is Required'),
  applyLevel: Yup.string().required('Granular Type is Required'),
  applyContentArray: Yup.mixed()
    .required('Metric Applies is Required')
    .test('type', 'Metric Applies is Required', value => {
      return typeof (value === 'object' || typeof value === 'string') && value !== ''
    }),
})

export const AddValueManuallySchema = Yup.object().shape({
  value: Yup.mixed().test('type', 'Value is Required', value => {
    return typeof (value === 'number' || typeof value === 'string') && value !== ''
  }),
  date: Yup.mixed().test('type', 'Date is Required', value => {
    return typeof (value === 'object' || typeof value === 'string') && value !== ''
  }),
  creatorId: Yup.string(),
})
