import React from "react"
import PropTypes from "prop-types"
import { USER_INDEXES } from "../../../constants/appSettings"
import {
  TabsWrapper,
  TabWrapper,
  TabContentWrapper,
  DisabledTipWrapper,
} from "./tabsStyles"
import useTranslation from "../../../localization/useTranslation"
import WithTip from "../popups/WithTip"

const Tabs = ({
  tabs = [],
  activeTabId = "",
  isDisabled = false,
  platform = USER_INDEXES.instagram,
  switchTab,
  igDisabled = false,
  ttDisabled = false,
}) => {
  const { labelStrings } = useTranslation()

  return (
    <TabsWrapper className='tabs'>
      {tabs?.map((tab) => {
        if (!Object.keys(tab)?.length) return

        const isActive = tab?.id === activeTabId
        const classes = ["tab"]
        isActive
          ? classes.push("tab_is-active")
          : classes.push("tab_is-not-active")
        isDisabled && classes.push("tab_is-disabled")
        const className = classes.join(" ")

        const isIgDisabled = tab.id === "instagram" && !igDisabled
        const isTtDisabled = tab.id === "tiktok" && !ttDisabled
        const isDisabledOverlap = tab.overlapDisabled && tab.id === "overlap"

        const onTabClick = () => {
          if (
            tab?.isDisabled ||
            isIgDisabled ||
            isTtDisabled ||
            isDisabledOverlap
          )
            return
          switchTab(tab?.id)
        }

        if (isDisabledOverlap || tab.overlapDisabled) {
          return
        }

        return (
          <TabWrapper
            className={className}
            key={tab?.id}
            onClick={onTabClick}
            isActive={isActive}
            isDisabled={
              isDisabled ||
              tab?.isDisabled ||
              isIgDisabled ||
              isTtDisabled ||
              isDisabledOverlap
            }
            platform={platform}
          >
            {tab?.isDisabled ? (
              <WithTip
                tipText={
                  <DisabledTipWrapper>
                    {labelStrings.featureIsNotAvailable}
                  </DisabledTipWrapper>
                }
                userindex={platform}
                position='bottom left'
              >
                <div>
                  <TabContentWrapper
                    className='tab-content'
                    platform={platform}
                    isActive={isActive}
                    isDisabled={
                      isDisabled ||
                      tab?.isDisabled ||
                      isIgDisabled ||
                      isTtDisabled
                    }
                  >
                    {tab?.content}
                  </TabContentWrapper>
                </div>
              </WithTip>
            ) : (
              <TabContentWrapper
                className='tab-content'
                platform={platform}
                isActive={isActive}
                isDisabled={
                  isDisabled ||
                  tab?.isDisabled ||
                  isIgDisabled ||
                  isTtDisabled ||
                  isDisabledOverlap
                }
              >
                {tab?.content}
              </TabContentWrapper>
            )}
          </TabWrapper>
        )
      })}
    </TabsWrapper>
  )
}

const tabIdType = PropTypes.oneOfType([PropTypes.number, PropTypes.string])

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: tabIdType,
      content: PropTypes.node,
    }),
  ),
  activeTabId: tabIdType,
  isDisabled: PropTypes.bool,
  platform: PropTypes.oneOf([
    USER_INDEXES.instagram,
    USER_INDEXES.tiktok,
    USER_INDEXES.youtube,
  ]),
  switchTab: PropTypes.func,
  igDisabled: PropTypes.bool,
  ttDisabled: PropTypes.bool,
}

export default Tabs
