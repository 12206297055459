import { actionProvider } from './actionsProvider'
import { USER_ROLES, SCHEDULED_FOR_DELETE_GRANT } from '../../constants/user'
import { ERROR_MSG } from '../../constants/errorMessages'
import { resetSearchResults, updateSearchTypeStatus } from './searchDucks'
import { resetCollections } from './collectionsDucks'
import { resetCampaigns } from './campaignsDucks'
import { resetProfile } from './profileSelectedDucks'
import { setError } from './errorDucks'
import { toggleSignIn, toggleTrialUserLimit, resetAllAppSettings } from './appSettingsDucks'
import { resetComparedProfiles } from './compareProfilesDucks'
import httpService from '../../services/httpService'
import sessionCacheService from '../../services/sessionCacheService'
import {
  prepareUserGrant,
  hasInfludataCookie,
  delayFunc,
  encodeStringForFormData,
  notEmptyObject,
} from '../../utils'
import {
  userSubSelector,
  userIdSelector,
  refreshGrantSelector,
  userGrantSelector,
  userCredsSelector,
  errorSelector,
  isUserLoggedInSelector,
  userDetailsSelector,
  isUserDetailsErrorSelector,
  currentAudienceTokensSelector,
  userUsedSeatsSelector,
  isProcessSignupSelector,
} from '../selectors'
import { API_STATUS_CODES } from '../../constants/appSettings'
import { searchContentTypes } from '../../constants/search'
import urlQueryService from '../../services/urlQueryService'
import { googleLogout } from '@react-oauth/google'

// action types
const SET_USER_CREDS = 'SET_USER_CREDS'
const SET_USER_SUB = 'SET_USER_SUB'
const SET_SALES_MANAGER = 'SET_SALES_MANAGER'
const USER_CREDS_LOAD_IN_PROGRESS = 'USER_DATA_LOAD_IN_PROGRESS'
const TOGGLE_IS_STARTUPDATA_PRESENT = 'TOGGLE_IS_STARTUPDATA_PRESENT'
const TOGGLE_IS_STARTUPDATA_LOADING = 'TOGGLE_IS_STARTUPDATA_LOADING'
const SET_USER_DETAILS = 'SET_USER_DETAILS'
const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS'
const TOGGLE_USER_DETAILS_LOADING = 'TOGGLE_USER_DETAILS_LOADING'
const TOGGLE_USER_DETAILS_ERROR = 'TOGGLE_USER_DETAILS_ERROR'
const CHANGE_AUDIENCE_TOKENS_COUNT = 'CHANGE_AUDIENCE_TOKENS_COUNT'
const CHANGE_HUBSPOT_TOKEN = 'CHANGE_HUBSPOT_TOKEN'
const CHANGE_AUDIENCE_TOKENS_TOTAL = 'CHANGE_AUDIENCE_TOKENS_TOTAL'
const CHANGE_AUDIENCE_TOKENS_RESET_DATE = 'CHANGE_AUDIENCE_TOKENS_RESET_DATE'
const TOGGLE_AUDIENCE_TOKENS_CHANGE = 'TOGGLE_AUDIENCE_TOKENS_CHANGE'
const CHANGE_USER_USED_SEATS = 'CHANGE_USER_USED_SEATS'
const TOGGLE_USER_USED_SEATS_CHANGE = 'TOGGLE_USER_USED_SEATS_CHANGE'
const SET_USER_EMAIL_FROM_LANDING = 'SET_USER_EMAIL_FROM_LANDING'
const SET_GOOGLE_ANALYTICS_FROM_LANDING = 'SET_GOOGLE_ANALYTICS_FROM_LANDING'
const SET_USER_AUTH_ERROR = 'SET_USER_AUTH_ERROR'
const SET_USER_TRACK = 'SET_USER_TRACK'
const CHANGE_PROCESS_SIGNUP = 'CHANGE_PROCESS_SIGNUP'

const initState = {
  id: '',
  username: '',
  grant: {},
  role: '',
  isUserCredsLoading: true,
  isUserLoggedIn: hasInfludataCookie(),
  isProcessSignup: false,
  refreshGrant: () => {},
  userSub: {
    currentPlan: '',
    subId: '',
  },
  isUserStartupDataPresent: false,
  isUserStartupDataLoading: false,
  details: {
    isLoading: false,
    isError: false,
    data: {},
  },
  audienceTokens: {
    isChangeInProgress: false,
    audienceTokensUsed: 0,
    tokensResetDate: Date.now(),
    audienceTokensTotal: 0,
  },
  seats: {
    isChangeInProgress: false,
    seatsUsed: [],
  },
  salesManager: {},
  userEmailFromLanding: '',
  googleAnalyticsData: {},
  authError: false,
  userTrack: false,
}

// reducer
export const userReducer = (state = initState, action = {}) => {
  switch (action.type) {
    case SET_USER_CREDS:
      return { ...state, ...action.payload }
    case USER_CREDS_LOAD_IN_PROGRESS:
      return { ...state, isUserCredsLoading: !state.isUserCredsLoading }
    case SET_USER_SUB:
      return { ...state, userSub: { ...action.payload } }
    case TOGGLE_IS_STARTUPDATA_PRESENT:
      return { ...state, isUserStartupDataPresent: !state.isUserStartupDataPresent }
    case TOGGLE_IS_STARTUPDATA_LOADING:
      return { ...state, isUserStartupDataLoading: !state.isUserStartupDataLoading }
    case SET_USER_DETAILS:
      return { ...state, details: { ...state.details, data: action.payload } }
    case UPDATE_USER_DETAILS:
      return {
        ...state,
        details: { ...state.details, data: { ...state.details.data, ...action.payload } },
      }
    case TOGGLE_USER_DETAILS_LOADING:
      return { ...state, details: { ...state.details, isLoading: !state.details.isLoading } }
    case TOGGLE_USER_DETAILS_ERROR:
      return { ...state, details: { ...state.details, isError: !state.details.isError } }
    case CHANGE_AUDIENCE_TOKENS_COUNT:
      return {
        ...state,
        audienceTokens: { ...state.audienceTokens, audienceTokensUsed: action.payload },
      }
    case CHANGE_AUDIENCE_TOKENS_TOTAL:
      return {
        ...state,
        audienceTokens: { ...state.audienceTokens, audienceTokensTotal: action.payload },
      }
    case CHANGE_HUBSPOT_TOKEN:
      return {
        ...state,
        hubspotToken: action.payload,
      }
    case CHANGE_AUDIENCE_TOKENS_RESET_DATE:
      return {
        ...state,
        audienceTokens: { ...state.audienceTokens, tokensResetDate: action.payload },
      }
    case TOGGLE_AUDIENCE_TOKENS_CHANGE:
      return {
        ...state,
        audienceTokens: {
          ...state.audienceTokens,
          isChangeInProgress: !state.audienceTokens.isChangeInProgress,
        },
      }
    case TOGGLE_USER_USED_SEATS_CHANGE:
      return {
        ...state,
        seats: { ...state.seats, isChangeInProgress: !state.seats.isChangeInProgress },
      }
    case CHANGE_USER_USED_SEATS:
      return { ...state, seats: { ...state.seats, seatsUsed: action.payload } }
    case SET_SALES_MANAGER:
      return { ...state, salesManager: action.payload }
    case SET_USER_EMAIL_FROM_LANDING:
      return { ...state, userEmailFromLanding: action.payload }
    case SET_GOOGLE_ANALYTICS_FROM_LANDING:
      const googleAnalyticsQuery = {}
      Object.keys(action.payload).map(key => {
        if (action.payload[key]) googleAnalyticsQuery[key] = action.payload[key]
      })
      const googleAnalyticsData = notEmptyObject(googleAnalyticsQuery)
        ? googleAnalyticsQuery
        : state.googleAnalyticsData
      return { ...state, googleAnalyticsData }
    case SET_USER_TRACK:
      return {
        ...state,
        userTrack: action.payload,
      }
    case SET_USER_AUTH_ERROR:
      return {
        ...state,
        authError: action.payload,
      }
    case CHANGE_PROCESS_SIGNUP:
      return {
        ...state,
        isProcessSignup: action.payload,
      }
    default:
      return state
  }
}

// actions
export const setUserCreds = payload => actionProvider(SET_USER_CREDS, payload)
export const toggleUserCredsLoad = () => actionProvider(USER_CREDS_LOAD_IN_PROGRESS)
export const setUserSub = payload => actionProvider(SET_USER_SUB, payload)
export const setUserStartUpDataPresent = () => actionProvider(TOGGLE_IS_STARTUPDATA_PRESENT)
export const toggleUserStartUpDataLoading = () => actionProvider(TOGGLE_IS_STARTUPDATA_LOADING)
export const setUserDetails = payload => actionProvider(SET_USER_DETAILS, payload)
export const updateUserDetails = payload => actionProvider(UPDATE_USER_DETAILS, payload)
export const toggleUserDetailsLoading = () => actionProvider(TOGGLE_USER_DETAILS_LOADING)
export const toogleUserDetailsError = () => actionProvider(TOGGLE_USER_DETAILS_ERROR)
export const toggleAudienceTokensChange = () => actionProvider(TOGGLE_AUDIENCE_TOKENS_CHANGE)
export const changeAudienceTokensCount = payload =>
  actionProvider(CHANGE_AUDIENCE_TOKENS_COUNT, payload)
export const changeAudienceTokensTotal = payload =>
  actionProvider(CHANGE_AUDIENCE_TOKENS_TOTAL, payload)
export const changeHubspotToken = payload => actionProvider(CHANGE_HUBSPOT_TOKEN, payload)
export const changeAudienceTokensResetDate = payload =>
  actionProvider(CHANGE_AUDIENCE_TOKENS_RESET_DATE, payload)
export const toggleUserUsedSeatsChange = () => actionProvider(TOGGLE_USER_USED_SEATS_CHANGE)
export const changeUserUsedSeats = payload => actionProvider(CHANGE_USER_USED_SEATS, payload)
export const setSalesManager = payload => actionProvider(SET_SALES_MANAGER, payload)
export const setUserEmailFromLanding = payload =>
  actionProvider(SET_USER_EMAIL_FROM_LANDING, payload)
export const setGoogleAnalyticsDataFromLanding = payload =>
  actionProvider(SET_GOOGLE_ANALYTICS_FROM_LANDING, payload)
export const setUserAuthError = payload => actionProvider(SET_USER_AUTH_ERROR, payload)
export const setUserTrack = payload => actionProvider(SET_USER_TRACK, payload)
export const changeProcessSignup = payload => actionProvider(CHANGE_PROCESS_SIGNUP, payload)

// async actions
export const changeUserGrantFromContext = (context, history) => async dispatch => {
  const { id, username, grants: grantsContext, props, refresh, sessionId } = context
  if (
    grantsContext &&
    grantsContext?.length &&
    grantsContext.includes(SCHEDULED_FOR_DELETE_GRANT)
  ) {
    return dispatch([warnAboutDeletedAcount(history)])
  }

  if (!sessionId || !id || (!username && (!grantsContext || !grantsContext?.length))) {
    dispatch(
      setUserCreds({ role: USER_ROLES.anonymous, isUserCredsLoading: false, refreshGrant: refresh })
    )
  }

  if (
    id &&
    username &&
    grantsContext &&
    grantsContext?.length &&
    !grantsContext?.includes(SCHEDULED_FOR_DELETE_GRANT)
  ) {
    await dispatch(setUserSubscription({ username }))

    const grants = [...grantsContext]

    dispatch([
      setUserCreds({
        id,
        username,
        grant: prepareUserGrant({ grants, props }),
        role: USER_ROLES.authenticated,
        isUserCredsLoading: false,
        refreshGrant: refresh,
      }),
    ])
  }
}

export const setUserSubscription = ({ username, callCount = 3 }) => async dispatch => {
  try {
    const [currentSubscription] = await httpService.fetchCurrentSubscription(
      encodeURIComponent(username)
    )
    if (currentSubscription) {
      const { id: subId, plan_id: currentPlan } = currentSubscription
      dispatch(setUserSub({ subId, currentPlan }))
    }
  } catch (err) {
    if (callCount) {
      delayFunc(() => dispatch(setUserSubscription({ username, callCount: callCount - 1 })), 200)
    } else {
      dispatch(setError(ERROR_MSG.serverNotRespond))
    }
  }
}

export const loginUser = ({ email, password, method, isOnboardingUser = false }) => async (
  dispatch,
  getState
) => {
  const isProcessSignup = isProcessSignupSelector(getState())
  try {
    !isProcessSignup && dispatch([toggleUserCredsLoad()])
    const urlParams = new URLSearchParams()
    urlParams.append('email', email)
    urlParams.append('password', encodeStringForFormData(password))
    urlParams.append('method', method)

    const loginResponse = await httpService.signin(urlParams)
    if (loginResponse.ok) {
      const userCreds = userCredsSelector(getState())
      dispatch([setUserCreds({ ...userCreds, isUserLoggedIn: isProcessSignup ? false : true }), !isOnboardingUser && toggleSignIn()])
    } else {
      throw ERROR_MSG.invalidUser
    }
  } catch (err) {
    dispatch(setUserAuthError(!!err))
    if (!isOnboardingUser) dispatch([toggleUserCredsLoad()])
    const isInvalidCredentials = typeof err === 'string' && err === ERROR_MSG.invalidUser
    dispatch([setError(isInvalidCredentials ? ERROR_MSG.invalidUser : ERROR_MSG.failLogin)])
  }
}

export const logoutUser = history => async dispatch => {
  await httpService.signout()
  sessionCacheService.resetSearchCache()
  googleLogout()

  dispatch([
    // order matters
    resetUserCredentials(),
    resetSearchResults(history),
    resetCollections(),
    resetCampaigns(),
    resetProfile(),
    resetComparedProfiles(),
    resetAllAppSettings(),
    updateSearchTypeStatus(searchContentTypes.CREATOR),
  ])

  urlQueryService.setNewSearchQuery({
    latestQuery: null,
  })
}

export const logoutUnathorizedUser = () => (dispatch, getState) => {
  const { isError, toastMsgName } = errorSelector(getState())

  if (isError || toastMsgName === ERROR_MSG.pleaseAuthorize) return
  dispatch([logoutUser(), setError(ERROR_MSG.pleaseAuthorize)])
}

export const resetUserCredentials = () => (dispatch, getState) => {
  dispatch(setUserCreds({ ...initState, isUserCredsLoading: false, isUserLoggedIn: false }))
}

export const upgradeUserPlan = ({ toPlanId = '', closeCB = null } = {}) => async (
  dispatch,
  getState
) => {
  if (!toPlanId) return

  const subscription = userSubSelector(getState())
  const customerId = userIdSelector(getState())
  try {
    dispatch(toggleUserCredsLoad())
    const { subId, currentPlan } = subscription
    if (!subId) {
      return dispatch(setError(ERROR_MSG.failUpgrade))
    }
    const response = await httpService.fetchPlanUpgrade({
      subId,
      toPlanId,
      customerId,
      currentPlan,
    })

    if (response && response.ok) {
      const { hosted_page: hostedPage } = await response.json()
      await dispatch(
        openChargebeeHP({
          hostedPage,
          closeCB: closeCB || (isCancelled => {}),
        })
      )
    }

    dispatch(toggleUserCredsLoad())
  } catch (err) {
    dispatch([toggleUserCredsLoad(), setError(ERROR_MSG.failUpgrade)])
  }
}

export const cancelUserPlan = () => async (dispatch, getState) => {
  const subscription = userSubSelector(getState())
  const refreshGrant = refreshGrantSelector(getState())
  try {
    dispatch(toggleUserCredsLoad())
    const { subId, currentPlan } = subscription
    if (!subId) {
      dispatch(setError(ERROR_MSG.failCancelPlan))
    }
    const response = await httpService.cancelSubscription({
      subId,
      currentPlan,
    })
    if (response && response.ok) {
      await refreshGrant()
      dispatch(toggleUserCredsLoad())
      return true
    }
    dispatch(toggleUserCredsLoad())
  } catch (err) {
    dispatch([toggleUserCredsLoad(), setError(ERROR_MSG.failCancelPlan)])
  }
}

export const openChargebeeHP = ({ successCB, errorCB, closeCB, hostedPage }) => async (
  dispatch,
  getState
) => {
  const refreshGrant = refreshGrantSelector(getState())
  try {
    const cb = await window.Chargebee.init({ site: process.env.CHARGEBEE_SITE })
    if (hostedPage) {
      let isCancelled = true
      await cb.openCheckout({
        hostedPage: () => Promise.resolve(hostedPage),
        success: () => {
          isCancelled = false
          ;(async () => {
            successCB ? await successCB() : await refreshGrant()
          })()
        },
        error: () => (errorCB ? errorCB() : dispatch(setError(ERROR_MSG.failPayment))),
        close: () => closeCB && closeCB(isCancelled),
      })
      return true
    }
  } catch (err) {
    dispatch(setError(ERROR_MSG.failPayment))
  }
}

export const reactivateUserSubscription = () => async (dispatch, getState) => {
  const subscription = userSubSelector(getState())
  const { non_renewing, cancelledSubId, expiration_date } = userGrantSelector(getState())
  const refreshGrant = refreshGrantSelector(getState())
  const { subId } = subscription

  try {
    dispatch(toggleUserCredsLoad())
    if (non_renewing) {
      if (!subId) return dispatch(setError(ERROR_MSG.failReactivate))
      await httpService.reactivateActiveSubscription({ subId })
    } else if (cancelledSubId) {
      await httpService.reactivateInactiveSubscription({ subId: cancelledSubId })
    }

    await refreshGrant()
    if (expiration_date) dispatch(toggleUserCredsLoad())
  } catch (err) {
    dispatch([toggleUserCredsLoad(), setError(ERROR_MSG.failReactivate)])
  }
}

export const forceImmediateTrialActivation = () => async (dispatch, getState) => {
  const userSub = userSubSelector(getState())
  const refreshGrant = refreshGrantSelector(getState())
  const { subId } = userSub
  dispatch([toggleTrialUserLimit()])
  try {
    dispatch([toggleUserCredsLoad()])
    await httpService.forceTrialActivation({ subId })
    await refreshGrant()
  } catch (err) {
    dispatch([toggleUserCredsLoad(), setError(ERROR_MSG.failTrialActivate)])
  }
}

export const deleteAccount = () => async (dispatch, getState) => {
  const userId = userIdSelector(getState())
  try {
    if (!userId) throw new Error('no subId')
    dispatch([toggleUserCredsLoad()])
    await httpService.deleteAccount(userId)
    dispatch([toggleUserCredsLoad()])
    return true
  } catch (err) {
    dispatch([toggleUserCredsLoad(), setError(ERROR_MSG.failDeleteAccount)])
    return false
  }
}

export const warnAboutDeletedAcount = history => (dispatch, getState) => {
  const currentError = errorSelector(getState())
  const isUserLoggedIn = isUserLoggedInSelector(getState())
  const { isError, toastMsgName } = currentError
  if (!isError && toastMsgName !== ERROR_MSG.deletedAccountWarning) {
    dispatch([setError(ERROR_MSG.deletedAccountWarning), isUserLoggedIn && logoutUser(history)])
  }
}

export const changeUserPassword = email => async dispatch => {
  try {
    await httpService.fetchForgetPassword(email)
    return true
  } catch (err) {
    dispatch([setError(ERROR_MSG.failChangePassword)])
    return false
  }
}

export const getUserDetails = () => async (dispatch, getState) => {
  try {
    const isDetailsError = isUserDetailsErrorSelector(getState())
    if (isDetailsError) dispatch(toogleUserDetailsError())

    const userId = userIdSelector(getState())
    if (!userId) return
    dispatch(toggleUserDetailsLoading())
    const userDetails = await httpService.fetchAccountDetails(userId)
    dispatch([setUserDetails(userDetails), toggleUserDetailsLoading()])
    await dispatch(getHostedPageForEditPayments())
  } catch (err) {
    dispatch([
      toggleUserDetailsLoading(),
      toogleUserDetailsError(),
      setError(ERROR_MSG.failLoadUserDetails),
    ])
  }
}

export const updateUserBillingDetails = newBillingDetails => async (dispatch, getState) => {
  try {
    const userId = userIdSelector(getState())
    const currentUserDetails = userDetailsSelector(getState())
    await httpService.updateBillingInfo({
      custid: userId,
      billingData: newBillingDetails,
    })
    const updatedUserDetails = {
      ...currentUserDetails,
      billing_address: {
        ...currentUserDetails.billing_address,
        ...newBillingDetails.billing_address,
        vat_number: newBillingDetails.vat_number,
      },
    }

    dispatch([updateUserDetails(updatedUserDetails)])
  } catch (err) {
    if (err.response && err.response.status === API_STATUS_CODES.badRequest) {
      const { data = {} } = err.response
      const { error_param, error_msg } = data
      const errMessageName =
        ERROR_MSG[error_param] || error_msg || ERROR_MSG.failUpdateBillingDetails
      return dispatch([setError(errMessageName)])
    }
    dispatch([setError(ERROR_MSG.failUpdateBillingDetails)])
  }
}

export const getHostedPageForEditPayments = () => async (dispatch, getState) => {
  try {
    const userId = userIdSelector(getState())
    if (!userId) return
    const currentUserDetails = userDetailsSelector(getState())
    const { hosted_page: hostedPage } = await httpService.getPaymentMethod(userId)
    const updatedUserDetails = {
      ...currentUserDetails,
      profile_data: {
        ...currentUserDetails.profile_data,
        hostedPage,
      },
    }
    dispatch([updateUserDetails(updatedUserDetails)])
  } catch (err) {
    dispatch([setError(ERROR_MSG.failGetEditPaymentPage)])
  }
}

export const changePaymentMethod = () => async (dispatch, getState) => {
  const { profile_data = {} } = userDetailsSelector(getState())
  const { hostedPage } = profile_data
  if (hostedPage) {
    dispatch(openChargebeeHP({ hostedPage }))
  }
}

export const changeAudienceTokens = ({ tokensToAdd = 0, isVisibleChange = true } = {}) => async (
  dispatch,
  getState
) => {
  // tokensToAdd can be negative, in this case it will decrement. in case of 0 -> do nothing
  if (!tokensToAdd) return
  const { audienceTokensUsed } = currentAudienceTokensSelector(getState())
  const newTokensCount = audienceTokensUsed + tokensToAdd
  if (isVisibleChange) {
    dispatch(toggleAudienceTokensChange())

    // with delay to allow user see the changes in ui
    delayFunc(() => {
      dispatch(changeAudienceTokensCount(newTokensCount))
      delayFunc(() => dispatch(toggleAudienceTokensChange()), 2000)
    }, 800)

    return
  }

  dispatch(changeAudienceTokensCount(newTokensCount))
}

export const updateSubUsers = ({ email, isRemove = false }) => async (dispatch, getState) => {
  try {
    dispatch(toggleUserUsedSeatsChange())
    await (isRemove ? httpService.removeSubUser(email) : httpService.createSubUser(email))
    const subUsersList = userUsedSeatsSelector(getState())
    dispatch([
      changeUserUsedSeats(
        isRemove ? subUsersList.filter(mail => mail !== email) : [...subUsersList, email]
      ),
      toggleUserUsedSeatsChange(),
    ])

    return true
  } catch (err) {
    console.log('SUB ERROR', { err })
    dispatch([
      toggleUserUsedSeatsChange(),
      setError(isRemove ? ERROR_MSG.failremoveSubUser : ERROR_MSG.failAddSubUser),
    ])
    return false
  }
}

export const purchaseOneTimeItems = (addonId = '') => async (dispatch, getState) => {
  const { subId } = userSubSelector(getState())
  const refreshGrant = refreshGrantSelector(getState())
  if (!addonId || !subId) return
  try {
    dispatch(toggleUserCredsLoad())
    await httpService.purchaseOneTimeItems({ subId, addonId })
    await refreshGrant()
  } catch (err) {
    dispatch([toggleUserCredsLoad(), setError(ERROR_MSG.failPurchaseOneTimeTokens)])
  }
}

export const createNewSubUser = ({ emails }) => async dispatch => {
  try {
    await httpService.createNewSubUser({ emails })
  } catch (err) {
    // dispatch([setError(ERROR_MSG.failPurchaseOneTimeTokens)])
  }
}

export const addMoreSignupInfo = ({
  customerId,
  platforms,
  features,
  businessType,
}) => async dispatch => {
  try {
    await httpService.addMoreSignupInfo({ customerId, platforms, features, businessType })
  } catch (err) {
    // dispatch([setError(ERROR_MSG.failPurchaseOneTimeTokens)])
  }
}
