import {
  Card,
  ListItem,
  ListItemText,
  Skeleton,
  Tab,
  Tabs,
  TextField,
  Typography,
  styled,
} from '@mui/material'

export const StyledCreatorsSuggestedInput = styled(TextField)({
  maxWidth: '376px',
  width: '100%',
  fontSize: '14px',
  '& .MuiInputBase-root': {
    paddingLeft: '3px',
    paddingRight: '6px',
    borderRadius: '10px',
    outline: 'unset',
  },
  '& .MuiFormLabel-root': {
    left: '5px',
    top: '-1px',
  },
  '& input::placeholder': {
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: `Inter, sans-serif`,
  },
})

export const StyledCardCreatorList = styled(Card)({
  maxWidth: '326px',
  maxHeight: '286px',
  width: '100%',
  position: 'absolute',
  zIndex: 3,
  top: 5,
  padding: 0,
  '.MuiList-root': {
    paddingTop: 0,
  },
})

export const StyledListItem = styled(ListItem)({
  maxHeight: '50px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(255, 93, 90, 0.08)',
    borderRadius: '8px',
  },
})

export const StyledListItemText = styled(ListItemText)({
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '22px',
  color: '#212B36',
  '.MuiTypography-root': {
    fontSize: '14px',
  },
})

export const StyledSocialPlatformTabs = styled(Tabs)({
  minHeight: '26px',
  paddingRight: '1px',
  transition: 'transition: all 300ms ease',
  '& .MuiTabs-indicator': {
    backgroundColor: 'white',
    height: '100%',
    zIndex: 1,
    borderRadius: '8px',
  },
})

export const StyledSocialPlatformTab = styled(Tab)({
  minHeight: '26px',
  minWidth: '26px',
  zIndex: 2,
  '&:hover': {
    backgroundColor: '#F9FAFB',
    borderRadius: '8px',
  },
})

export const StyledSkeleton = styled(Skeleton)({
  borderRadius: '8px',
})

export const StyledAddedText = styled(Typography)({
  color: 'rgba(54, 179, 126, 1)',
  fontWeight: 500,
  textTransform: 'lowercase',
})
