import React from 'react'

export const CalendarIcon = ({ height = '22', width = '22', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.3 4.263h15.4a1.927 1.927 0 0 1 1.925 1.925v11.87a1.927 1.927 0 0 1-1.925 1.925H3.3a1.927 1.927 0 0 1-1.925-1.925V6.188A1.927 1.927 0 0 1 3.3 4.263Zm0 15.079h15.4c.709 0 1.283-.575 1.283-1.284V8.433H2.017v9.625c0 .71.574 1.284 1.283 1.284Z"
      fill="#637381"
    />
    <path
      d="M16.454 3.62V2.018a.642.642 0 0 0-1.283 0V3.62h1.283Zm-9.624 0V2.018a.642.642 0 1 0-1.284 0V3.62h1.283Z"
      fill="#637381"
    />
    <path
      opacity={0.48}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.94 11.03a1 1 0 0 1 1-1h1.85a1 1 0 0 1 1 1v1.3a1 1 0 0 1-1 1H4.94a1 1 0 0 1-1-1v-1.3Zm0 4.583a1 1 0 0 1 1-1h1.85a1 1 0 0 1 1 1v1.3a1 1 0 0 1-1 1H4.94a1 1 0 0 1-1-1v-1.3Zm6.135-1a1 1 0 0 0-1 1v1.3a1 1 0 0 0 1 1h1.85a1 1 0 0 0 1-1v-1.3a1 1 0 0 0-1-1h-1.85Zm-1-3.583a1 1 0 0 1 1-1h1.85a1 1 0 0 1 1 1v1.3a1 1 0 0 1-1 1h-1.85a1 1 0 0 1-1-1v-1.3Zm6.135-1a1 1 0 0 0-1 1v1.3a1 1 0 0 0 1 1h1.85a1 1 0 0 0 1-1v-1.3a1 1 0 0 0-1-1h-1.85Z"
      fill="#637381"
    />
  </svg>
)

export const DashboardIcon = ({ height = '22', width = '22', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m18.682 7.856-1.128 1.696a7.333 7.333 0 0 1-.201 6.948H4.646a7.333 7.333 0 0 1 9.635-10.22l1.695-1.128A9.167 9.167 0 0 0 3.071 17.417c.325.563.925.912 1.576.916h12.696a1.833 1.833 0 0 0 1.595-.916 9.167 9.167 0 0 0-.247-9.57l-.01.009Z"
      fill="#637381"
    />
    <path
      opacity={0.48}
      d="M9.707 14.126a1.833 1.833 0 0 0 2.595 0l5.188-7.783-7.783 5.189a1.833 1.833 0 0 0 0 2.594Z"
      fill="#637381"
    />
  </svg>
)

export const ContentOverviewIcon = ({ height = '22', width = '22', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.333 2.75c1.013 0 1.834.82 1.834 1.833v9.167a1.834 1.834 0 0 1-1.834 1.833H3.667a1.833 1.833 0 0 1-1.834-1.833V4.583c0-1.012.821-1.833 1.834-1.833h14.666ZM10.542 5.5H5.958c-.76 0-1.375.616-1.375 1.375v1.833c0 .76.616 1.375 1.375 1.375h4.584c.759 0 1.375-.615 1.375-1.375V6.875c0-.76-.616-1.375-1.375-1.375Z"
      fill="#637381"
    />
    <path
      opacity={0.48}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 14.667c.506 0 .917.41.917.916v2.75h2.75a.917.917 0 0 1 0 1.834H7.333a.917.917 0 0 1 0-1.834h2.75v-2.75c0-.506.41-.916.917-.916Z"
      fill="#637381"
    />
  </svg>
)

export const CreatorOverviewIcon = ({ height = '22', width = '22', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.105 2.556C3.773 2.556.458 5.496.458 8.7s2.862 5.06 2.862 5.06l-.465 1.764a.272.272 0 0 0 .397.305l2.09-1.184c.925.294 2.887.388 3.078.397a5.015 5.015 0 0 1-.204-1.38c0-2.474 2.153-5.957 6.94-5.957.108 0 .217.003.326.007-.637-3.048-3.802-5.156-7.377-5.156Z"
      fill="#637381"
    />
    <path
      opacity={0.48}
      d="M21.542 13.274c0-2.806-3.293-4.862-6.387-4.862-4.376 0-6.233 3.16-6.233 5.25 0 2.092 1.857 5.248 6.233 5.248.968 0 1.82-.139 2.56-.385l1.646 1.045a.172.172 0 0 0 .26-.184l-.374-1.62c1.555-1.084 2.295-2.791 2.295-4.492Z"
      fill="#637381"
    />
  </svg>
)

export const AirtableViewIcon = ({ height = '22', width = '22', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="1" y="1" width="20" height="20" stroke="#637381" strokeWidth="2" />
    <line x1="1" y1="6" x2="21" y2="6" stroke="#637381" strokeWidth="2" />
    <line x1="1" y1="11" x2="21" y2="11" stroke="#637381" strokeWidth="2" />
    <line x1="1" y1="16" x2="21" y2="16" stroke="#637381" strokeWidth="2" />
    <line x1="7" y1="1" x2="7" y2="21" stroke="#637381" strokeWidth="2" />
    <line x1="14" y1="1" x2="14" y2="21" stroke="#637381" strokeWidth="2" />
  </svg>
)

export const FilesUploadsIcon = ({ height = '22', width = '22', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.48}
      d="M16.236 6.522c.696.007 1.663.01 2.484.007a.48.48 0 0 0 .342-.814l-4.019-4.222c-.298-.313-.82-.097-.82.337v2.658c0 1.115.909 2.034 2.013 2.034"
      fill="#637381"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.377 15.427h-5.43a.758.758 0 0 1-.75-.757.75.75 0 0 1 .75-.746h5.43a.75.75 0 0 1 .75.746c0 .414-.34.757-.75.757m-5.43-6.544h3.374c.41 0 .749.342.749.756a.75.75 0 0 1-.75.746H7.947a.75.75 0 0 1-.748-.746c0-.414.34-.756.748-.756m11.221-.888c-.43 0-.938.01-1.248.01-.46 0-1.058-.01-1.817-.01-1.827-.01-3.334-1.522-3.334-3.368V1.381a.46.46 0 0 0-.46-.464h-5.33c-2.487 0-4.503 2.047-4.503 4.547v10.87c0 2.622 2.116 4.75 4.712 4.75h7.947c2.476 0 4.482-2.027 4.482-4.528V8.449a.45.45 0 0 0-.449-.454"
      fill="#637381"
    />
  </svg>
)

export const OffersIcon = ({ height = '22', width = '22', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.664 2.292h13.211a2.83 2.83 0 0 1 2.83 2.83v9.437a2.83 2.83 0 0 1-2.83 2.83h-9.86a.944.944 0 0 0-.52.133l-4.237 2.567a.944.944 0 0 1-1.425-.812V5.123a2.83 2.83 0 0 1 2.831-2.831ZM6.551 9.84a.944.944 0 1 0 1.888 0 .944.944 0 0 0-1.888 0Zm4.719.943a.944.944 0 1 1 0-1.887.944.944 0 0 1 0 1.887Zm2.83-.943a.944.944 0 1 0 1.888 0 .944.944 0 0 0-1.887 0Z"
      fill="#637381"
    />
  </svg>
)

export const ReportsIcon = ({ height = '22', width = '22', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.125 18.792 2.75 20.167V1.833l1.375 1.375L5.5 1.833l1.375 1.375L8.25 1.833l1.375 1.375L11 1.833l1.375 1.375 1.375-1.375 1.375 1.375L16.5 1.833l1.375 1.375 1.375-1.375v18.334l-1.375-1.375-1.375 1.375-1.375-1.375-1.375 1.375-1.375-1.375L11 20.167l-1.375-1.375-1.375 1.375-1.375-1.375L5.5 20.167l-1.375-1.375ZM16.5 6.417V8.25h-11V6.417h11Zm-11 3.666v1.834h11v-1.834h-11Zm0 3.667v1.833h11V13.75h-11Z"
      fill="#637381"
    />
  </svg>
)

export const NotificationsIcon = ({ height = '22', width = '22', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m17.16 12.283 1.65 1.66a1.503 1.503 0 0 1-1.063 2.557h-3.08v.312A3.52 3.52 0 0 1 11 20.167a3.52 3.52 0 0 1-3.667-3.355V16.5h-3.08a1.503 1.503 0 0 1-1.063-2.557l1.65-1.66v-4.28a6.178 6.178 0 0 1 6.985-6.115 6.288 6.288 0 0 1 5.335 6.307v4.088ZM11 18.333a1.723 1.723 0 0 0 1.833-1.521V16.5H9.167v.312A1.723 1.723 0 0 0 11 18.333Z"
      fill="#637381"
    />
  </svg>
)

export const GoalsIcon = ({ height = '22', width = '22', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.48}
      d="M18.637 1.833a1.68 1.68 0 1 1-.8 3.16l-2.773 3.189a1.68 1.68 0 1 1-3.166.866l-2.02-.695a1.677 1.677 0 0 1-2.122.396L5.12 10.961a1.68 1.68 0 1 1-.77-.957l2.63-2.208a1.68 1.68 0 1 1 3.279-.612l2.031.7a1.677 1.677 0 0 1 1.844-.505l2.905-3.342a1.68 1.68 0 0 1 1.598-2.204Z"
      fill="#637381"
    />
    <path
      d="M19.48 9.725a.84.84 0 0 1 .84.84v9.858a.458.458 0 0 1-.459.458h-2.444a.458.458 0 0 1-.459-.458v-9.858a.84.84 0 0 1 .84-.84h1.681Zm-5.043 5.89a.84.84 0 0 1 .84.84v3.968a.458.458 0 0 1-.458.458h-2.444a.458.458 0 0 1-.458-.458v-3.968a.84.84 0 0 1 .84-.84h1.68Zm-5.041-1.688a.84.84 0 0 1 .84.84v5.656a.458.458 0 0 1-.458.458H7.333a.458.458 0 0 1-.458-.458v-5.655a.84.84 0 0 1 .84-.84h1.68Zm-5.042 4.321a.84.84 0 0 1 .84.84v1.335a.458.458 0 0 1-.458.458H2.292a.458.458 0 0 1-.459-.458v-1.335a.84.84 0 0 1 .84-.84h1.681Z"
      fill="#637381"
    />
  </svg>
)

export const DataEnrichmentIcon = ({ height = '22', width = '22', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M11 .678C5.305.678.687 5.296.687 10.991S5.306 21.303 11 21.303s10.313-4.617 10.313-10.312C21.313 5.296 16.694.678 11 .678Zm-.945 19.201a8.952 8.952 0 0 1-7.29-5.41l-.046-.11 7.336 4.106v1.414Zm0-2.99-7.868-4.404a9.016 9.016 0 0 1-.085-2.34l7.953 4.453v2.291Zm0-3.867-7.7-4.31a8.87 8.87 0 0 1 .666-1.752l7.034 3.942v2.12Zm0-3.696-6.32-3.542A8.998 8.998 0 0 1 5.007 4.36l5.048 2.765v2.201Zm0-3.769-3.852-2.11a8.892 8.892 0 0 1 3.852-1.344v3.454Zm9.883 5.434a8.928 8.928 0 0 1-8.508 8.927V2.064a8.928 8.928 0 0 1 8.508 8.927Z"
        fill="#637381"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h22v22H0z" />
      </clipPath>
    </defs>
  </svg>
)

export const CustomFieldsIcon = ({ height = '22', width = '22', ...props }) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.2}
      d="M15.125 4.803h-8.25a.687.687 0 0 0-.688.688v3.437c0 .38.308.688.688.688h8.25c.38 0 .688-.308.688-.688V5.491a.687.687 0 0 0-.688-.688Zm3.438 7.563H6.874a.687.687 0 0 0-.688.687v3.438c0 .38.308.687.688.687h11.688c.38 0 .687-.307.687-.687v-3.438a.687.687 0 0 0-.688-.687Z"
      fill="#637381"
    />
    <path
      d="M18.563 11.678H6.874A1.375 1.375 0 0 0 5.5 13.053v3.438a1.375 1.375 0 0 0 1.375 1.375h11.688a1.375 1.375 0 0 0 1.375-1.375v-3.438a1.375 1.375 0 0 0-1.375-1.375Zm0 4.813H6.874v-3.438h11.688v3.438ZM4.124 3.428v15.125a.688.688 0 1 1-1.375 0V3.428a.688.688 0 1 1 1.375 0Zm2.75 6.875h8.25A1.375 1.375 0 0 0 16.5 8.928V5.491a1.375 1.375 0 0 0-1.375-1.375h-8.25A1.375 1.375 0 0 0 5.5 5.49v3.437a1.375 1.375 0 0 0 1.375 1.375Zm0-4.812h8.25v3.437h-8.25V5.491Z"
      fill="#637381"
    />
  </svg>
)

export const CampaignSettingsIcon = ({ height = '22', width = '22', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={11} cy={11} r={1.375} fill="#637381" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.628 8.59h.999a1.55 1.55 0 0 1 1.54 1.548v1.779a1.54 1.54 0 0 1-1.485 1.53h-1a.312.312 0 0 0-.265.202.312.312 0 0 0 0 .34l.669.668a1.53 1.53 0 0 1 0 2.173l-1.228 1.228a1.513 1.513 0 0 1-1.082.459c-.41-.006-.8-.17-1.091-.459l-.697-.706a.312.312 0 0 0-.339 0c-.137.055-.238.147-.238.276v.999a1.55 1.55 0 0 1-1.55 1.54H10.13a1.54 1.54 0 0 1-1.54-1.485v-1a.312.312 0 0 0-.202-.265.348.348 0 0 0-.375 0l-.706.678a1.53 1.53 0 0 1-2.173 0l-1.228-1.247a1.522 1.522 0 0 1-.458-1.09c.005-.41.17-.801.458-1.091l.742-.679a.312.312 0 0 0 0-.339c-.055-.137-.146-.238-.275-.238h-.999a1.55 1.55 0 0 1-1.54-1.55V10.13c0-.85.69-1.54 1.54-1.54h.944a.312.312 0 0 0 .266-.202.348.348 0 0 0 0-.375l-.678-.679a1.53 1.53 0 0 1 0-2.2l1.256-1.228a1.513 1.513 0 0 1 1.081-.458c.41.005.801.17 1.091.458l.679.742a.312.312 0 0 0 .339 0c.137-.055.238-.146.238-.275v-.999a1.55 1.55 0 0 1 1.55-1.54h1.778a1.54 1.54 0 0 1 1.494 1.54v.944a.31.31 0 0 0 .202.266.348.348 0 0 0 .375 0l.706-.678a1.53 1.53 0 0 1 2.173 0l1.228 1.256c.293.287.458.68.458 1.09.002.409-.164.8-.458 1.082l-.742.679a.312.312 0 0 0 0 .339c.054.137.146.238.274.238Zm-9.592 3.638a3.208 3.208 0 1 0 5.928-2.456 3.208 3.208 0 0 0-5.928 2.456Z"
      fill="#637381"
    />
  </svg>
)

export const PlayVideoIcon = ({ height = '52', width = '52', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="52" height="52" rx="26" fill="#F9FAFB" />
    <path
      d="M23.305 36.5a3.605 3.605 0 0 0 2.275-.805l8.925-7.367a2.975 2.975 0 0 0 0-4.655l-8.925-7.368a3.675 3.675 0 0 0-3.867-.455 3.08 3.08 0 0 0-1.838 2.783v14.735a3.08 3.08 0 0 0 1.837 2.782 3.91 3.91 0 0 0 1.593.35Z"
      fill="#212B36"
    />
  </svg>
)

export const DownloadContent = ({ height = 30, width = 30, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="30" height="30" rx="4" fill="#F9FAFB" />
    <rect x="7" y="21" width="16" height="2" rx="1" fill="#212B36" />
    <rect x="7" y="23" width="4" height="2" rx="1" transform="rotate(-90 7 23)" fill="#212B36" />
    <rect x="21" y="23" width="4" height="2" rx="1" transform="rotate(-90 21 23)" fill="#212B36" />
    <path
      d="M15 18a1 1 0 0 1-.58-.18l-4-2.82a1 1 0 1 1 1.16-1.63L15 15.76l3.4-2.56a1 1 0 1 1 1.2 1.6l-4 3a1 1 0 0 1-.6.2Z"
      fill="#212B36"
    />
    <path d="M15 16a1 1 0 0 1-1-1V7a1 1 0 1 1 2 0v8a1 1 0 0 1-1 1Z" fill="#212B36" />
  </svg>
)

export const EditCampaignCardContentIcon = ({ width = 24, height = 24, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m16.66 4.6 2.74 2.74a1.92 1.92 0 0 1 .07 2.71l-9 9a2 2 0 0 1-1.21.57L5.09 20H5a1 1 0 0 1-1-1.09l.43-4.17A2 2 0 0 1 5 13.53l9-9a2 2 0 0 1 2.66.07ZM13.32 8 16 10.68l2-1.95L15.27 6l-1.95 2Z"
      fill="#212B36"
    />
  </svg>
)

export const NewInstagramIcon = ({ width = 19, height = 18, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.122 2.813a2.81 2.81 0 0 0-2.81 2.809v6.756a2.81 2.81 0 0 0 2.81 2.81h6.756a2.809 2.809 0 0 0 2.81-2.81V5.623a2.809 2.809 0 0 0-2.81-2.81H6.123ZM2.188 5.622a3.934 3.934 0 0 1 3.934-3.934h6.757a3.934 3.934 0 0 1 3.934 3.934v6.757a3.934 3.934 0 0 1-3.935 3.934H6.122a3.934 3.934 0 0 1-3.934-3.935V5.622Zm11.02-1.15h.004a.815.815 0 1 1-.003 0Zm.313.817h.507v.002l-.507-.002Zm0 0a.309.309 0 0 1-.306.308l-.003-.562v.562a.31.31 0 0 1-.31-.309v-.003a.31.31 0 0 1 .619.003Zm-5.532 2.2a2.138 2.138 0 1 1 3.022 3.023A2.138 2.138 0 0 1 7.99 7.489Zm3.818-.796a3.262 3.262 0 1 0-4.614 4.614 3.262 3.262 0 0 0 4.614-4.614Z"
      fill="#212B36"
    />
  </svg>
)
export const NewTikTokIcon = ({ width = 19, height = 18, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 2.813A3.187 3.187 0 0 0 3.312 6v6A3.188 3.188 0 0 0 6.5 15.188h6A3.188 3.188 0 0 0 15.688 12V6A3.188 3.188 0 0 0 12.5 2.812h-6ZM2.187 6A4.312 4.312 0 0 1 6.5 1.687h6A4.313 4.313 0 0 1 16.813 6v6a4.313 4.313 0 0 1-4.313 4.313h-6A4.313 4.313 0 0 1 2.187 12V6Zm8.438-.938c.31 0 .563.252.563.563A1.313 1.313 0 0 0 12.5 6.938a.562.562 0 1 1 0 1.125c-.469 0-.924-.136-1.313-.384v2.633a2.625 2.625 0 0 1-5.25 0 2.253 2.253 0 0 1 2.254-2.25h.184a.563.563 0 0 1 0 1.126h-.184a1.129 1.129 0 0 0-1.129 1.125 1.5 1.5 0 0 0 3 0V5.625c0-.31.252-.563.563-.563Z"
      fill="#212B36"
    />
  </svg>
)
export const PlusIcon = ({ width = 19, height = 18, fill = '#212B36', ...props }) => (
  <svg
    width={19}
    height={18}
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.75 8.25h-4.5v-4.5a.75.75 0 0 0-1.5 0v4.5h-4.5a.75.75 0 0 0 0 1.5h4.5v4.5a.75.75 0 0 0 1.5 0v-4.5h4.5a.75.75 0 0 0 0-1.5Z"
      fill={fill}
    />
  </svg>
)

export const AccessIcon = ({ width = 21, height = 20, fill = '#fff', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.35 5.175a.833.833 0 0 0-1.175.142l-5.834 7.5-2.858-3.484a.833.833 0 1 0-1.3 1.042l3.475 4.317a.836.836 0 0 0 .65.308.833.833 0 0 0 .692-.317l6.525-8.333a.833.833 0 0 0-.175-1.175Zm4.166 0a.833.833 0 0 0-1.175.142l-5.833 7.5-.508-.625-1.05 1.35.916 1.141a.833.833 0 0 0 1.3-.008l6.525-8.333a.833.833 0 0 0-.175-1.167ZM8.833 9.533l-1.075 1.35-.408-.541a.833.833 0 0 1 .125-1.175.833.833 0 0 1 1.191.166l.167.2Z"
      fill={fill}
    />
  </svg>
)

export const CrossIcon = ({ width = 21, height = 20, fill = '#fff', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m11.44 10 2.863-2.858a.669.669 0 0 0-.946-.946L10.5 9.06 7.643 6.196a.669.669 0 1 0-.946.946l2.864 2.857-2.864 2.858a.666.666 0 0 0 0 .946.666.666 0 0 0 .946 0l2.857-2.864 2.857 2.864a.666.666 0 0 0 .946 0 .666.666 0 0 0 0-.946L11.439 10Z"
      fill={fill}
    />
  </svg>
)
export const PlayDashIcon = ({ width = 25, height = 24, fill = '#FF5D5A', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 256 256"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g strokeWidth={0} />
    <g strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M59 61.922c0-9.768 13.016-15.432 22.352-11.615 10.695 7.017 101.643 58.238 109.869 65.076 8.226 6.838 10.585 17.695-.559 25.77-11.143 8.074-99.712 60.203-109.31 64.73-9.6 4.526-21.952-1.632-22.352-13.088-.4-11.456 0-121.106 0-130.873zm13.437 8.48c0 2.494-.076 112.852-.216 115.122-.23 3.723 3 7.464 7.5 5.245 4.5-2.22 97.522-57.704 101.216-59.141 3.695-1.438 3.45-5.1 0-7.388C177.488 121.952 82.77 67.76 80 65.38c-2.77-2.381-7.563 1.193-7.563 5.023z"
      stroke={fill}
      fillRule="evenodd"
      fill={fill}
    />
  </svg>
)

export const KeywordsMentionsIcon = ({ width = 25, height = 24, fill = '#637381', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      stroke={fill}
      strokeWidth={2}
      strokeLinecap="round"
      d="M4 7.25h12m-12 5.5h12M7.75 4l-1 12m6.5-12-1 12"
    />
  </svg>
)

export const KanbanIcon = ({ width = 22, height = 22, ...props }) => (
  <svg
    width={width}
    height={height}
    fill="rgb(99, 115, 129)"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="ViewKanbanIcon"
    {...props}
  >
    <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7V7h2v10zm4-5h-2V7h2v5zm4 3h-2V7h2v8z" />
  </svg>
)
