import React from 'react'
import { Button, Card, Container, Dialog, DialogTitle, Stack } from '@mui/material'
import {
  CalendarFilterDrawer,
  CalendarForm,
  CalendarToolbar,
  StyledCalendar,
} from '../../components/calendar'
import FullCalendar from '@fullcalendar/react' // => request placed at the top
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import timelinePlugin from '@fullcalendar/timeline'
import Iconify from '../../components/iconify/Iconify'
import { COLOR_OPTIONS } from './utils'

export const CalendarView = props => {
  const {
    date,
    view,
    picker,
    events,
    calendarRef,
    openForm,
    isDesktop,
    isDeleteAndEditBlocked,

    handleClickDateNext,
    handleClickDatePrev,
    handleClickToday,
    handleChangeView,

    selectedEvent,
    setSelectedEventId,
    handleDropEvent,
    handleSelectEvent,
    handleResizeEvent,
    handleCreateUpdateEvent,
    handleDeleteEvent,

    selectedRange,
    handleSelectRange,

    handleOpenModal,
    handleCloseModal,

    openFilter,
    setOpenFilter,
    filterEventColor,
    dataFiltered,
    handleFilterEventColor,
    handleResetFilter,

    isEventOutsideCalendar,
    setIsEventOutsideCalendar
  } = props

  const handleNewEvent = () => {
    setIsEventOutsideCalendar(true)
    handleOpenModal()
  }

  return (
    <>
      <Container maxWidth={'xl'}>
        <Stack direction="row" alignItems="center" justifyContent="end" mb={3}>
          <Button
            variant="outlined"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleNewEvent}
          >
            New Event
          </Button>
        </Stack>

        <Card>
          <StyledCalendar>
            <CalendarToolbar
              date={date}
              view={view}
              onNextDate={handleClickDateNext}
              onPrevDate={handleClickDatePrev}
              onToday={handleClickToday}
              onChangeView={handleChangeView}
              onOpenFilter={() => setOpenFilter(true)}
            />

            <FullCalendar
              weekends
              editable
              droppable
              selectable
              firstDay={1}  // Setting the start of the week to Monday
              rerenderDelay={10}
              allDayMaintainDuration
              eventResizableFromStart
              ref={calendarRef}
              initialDate={date}
              initialView={view}
              dayMaxEventRows={3}
              eventDisplay="block"
              events={dataFiltered}
              headerToolbar={false}
              initialEvents={events}
              select={handleSelectRange}
              eventDrop={handleDropEvent}
              eventClick={handleSelectEvent}
              eventResize={handleResizeEvent}
              height={isDesktop ? 720 : 'auto'}
              plugins={[
                listPlugin,
                dayGridPlugin,
                timelinePlugin,
                timeGridPlugin,
                interactionPlugin,
              ]}
            />
          </StyledCalendar>
        </Card>
      </Container>

      <Dialog fullWidth maxWidth="xs" open={openForm} onClose={handleCloseModal}>
        <DialogTitle>{selectedEvent ? 'Edit Event' : 'Add Event'}</DialogTitle>

        <CalendarForm
          event={selectedEvent}
          range={selectedRange}
          onCancel={handleCloseModal}
          onCreateUpdateEvent={handleCreateUpdateEvent}
          onDeleteEvent={handleDeleteEvent}
          isEventOutsideCalendar={isEventOutsideCalendar}
          colorOptions={COLOR_OPTIONS}
          isDeleteAndEditBlocked={isDeleteAndEditBlocked}
        />
      </Dialog>

      <CalendarFilterDrawer
        events={events}
        picker={picker}
        openFilter={openFilter}
        colorOptions={COLOR_OPTIONS}
        onResetFilter={handleResetFilter}
        filterEventColor={filterEventColor}
        onCloseFilter={() => setOpenFilter(false)}
        onFilterEventColor={handleFilterEventColor}
        onSelectEvent={eventId => {
          if (eventId) {
            handleOpenModal()
            setSelectedEventId(eventId)
          }
        }}
      />
    </>
  )
}
