import React from 'react'
import { Skeleton, TableCell, TableRow } from '@mui/material'

export const TableRowsLoader = ({ rowsNum, thNum }) => {
  return (
    <>
      {[...Array(rowsNum)].map((row, index) => {
        return (
          <TableRow key={index}>
            <TableCell component="th" scope="row">
              <Skeleton animation="wave" variant="text" />
            </TableCell>
            {[...Array(thNum)].map((th, idx) => {
              return (
                <TableCell key={idx}>
                  <Skeleton animation="wave" variant="text" />
                </TableCell>
              )
            })}
          </TableRow>
        )
      })}
    </>
  )
}
