import availableLangs from './availableLangs'
import { IG_SCORE_NAMES, TT_SCORE_NAMES, YT_SCORE_NAMES } from '../constants/profile'
import { USER_INDEXES } from '../constants/appSettings'

export default {
  [availableLangs.en]: {
    [USER_INDEXES.youtube]: {
      [YT_SCORE_NAMES.video]: {
        title: 'Video',
        description: {
          main:
            'This value gives an indication of how often this influencer publishes videos on YouTube compared to other influencers.',
          text:
            "From our experience a good influencer shouldn't <b>post too rarly an shouldn't be inactive</b>.<br>On the other hand, if postings are <b>too frequent</b>, the audience could not see all of the posts and the individual impact of one post is lower.<br><br><b>Calculation:</b><br> Average (Time Difference between the last 12 Videos)",
        },
      },
      [YT_SCORE_NAMES.shorts]: {
        title: 'Shorts',
        description: {
          main:
            'This value gives an indication of how often this influencer publishes Shorts on YouTube compared to other influencers.',
          text:
            "From our experience a good influencer shouldn't <b>post too rarly an shouldn't be inactive</b>.<br>On the other hand, if postings are <b>too frequent</b>, the audience could not see all of the shorts and the individual impact of one post is lower.<br><br><b>Calculation:</b><br> Average (Time Difference between the last 12 Shorts)",
        },
      },
      [YT_SCORE_NAMES.growthRate]: {
        title: 'Growth Rate',
        description: {
          main:
            'This score shows the recent growth of this account and the horizontal bars provides an indication of how this value compares to influencers with similar subscriber counts.',
          text:
            'From our experience growth is <b>the most important information for determining the quality of one influencer</b>. Good growth has a positive effect on most other scores and is a reliable metric of the success of your campaigns.<br><br><b>Calculation:</b><br>Subscriber Count (now) / (Subscriber Count (now) - Subscriber Count (30 days ago))',
        },
      },
      [YT_SCORE_NAMES.positiveReactions]: {
        title: 'Positive Reactions',
        description: {
          main:
            'This score shows what amount of comments on the creator\'s videos and Shorts had a positive semantic and the horizontal bars provide an indication of how this value compares to creators with similar subscriber counts.',
          text:
            'If you consider collaborating with a creator, you might want to make sure that the viewers <b>react positively on the content that is posted</b>. The detected positive words are detected in each language and the comments will be divided in positive, neutral and negative semantics.<br><br><b>Calculation:</b><br>Comments with positive Semantic / Comments with positive, negative and neutral Semantic',
        },
      },
      [YT_SCORE_NAMES.negativeReactions]: {
        title: 'Negative Reactions',
        description: {
          main:
            'This score shows what amount of comments on the creator\'s videos and Shorts had a negative semantic and the horizontal bars provide an indication of how this value compares to creators with similar subscriber counts.',
          text:
            'If you consider collaborating with a creator, you might want to make sure that the viewers <b>have a low rate of negative comments</b>, also to reduce the risk of a negative impact towards your brand. The detected negative words are detected in each language and the comments will be divided in positive, neutral and negative semantics.<br><br><b>Calculation:</b><br>Comments with negative Semantic / Comments with positive, negative and neutral Semantic',
        },
      },
      [YT_SCORE_NAMES.videoLikes]: {
        title: 'Video Likes',
        description: {
          main:
            'This score shows the average amount of likes a video received compared to the views a video received. The horizontal bars provide an indication of how this value compares to influencers with similar subscriber counts.',
          text:
           'A high score indicates that a lot of the viewers are actually <b>engaging with the content and liking what they see</b>. Since YouTube doesn\'t show dislikes anymore, a low score of this metric correlates with high amount of dislikes.<br><br><b>Calculation:</b><br> Median ( Video (Like Count) / Video (View Count) ) for the last 12 videos.',
        },
      },
      [YT_SCORE_NAMES.videoComments]: {
        title: 'Videos-Comments',
        description: {
          main:
            'This score sets the number of comments per video in perspective with the number of views the video received. The horizontal bars provide an indication of how this value compares to influencers with similar subscriber counts.',
          text:
            'A high score indicates that the content is <b>interesting, engaging and the influencer might have a deeper conntection to the subscriber base</b>.<br><br><b>Calculation:</b><br> Median ( Video(Comment Count) / Video (View Count) ) for the last 12 videos',
        },
      },
      [YT_SCORE_NAMES.videoEngagement]: {
        title: 'Videos-Engagement',
        description: {
          main:
            'This score sets the engagement - this means the likes and comments of videos - in perspective with the average views of the influencer\'s videos. The horizontal bars provide an indication of how this value compares to influencers with similar subscriber counts.',
          text:
            'A good engagement for videos indicates deep and engaging content and videos that are more likely to be watched completely.<br><br><b>Calculation:</b><br> Median (Video(Likes) + Video(Comments)) / Video(Views) for the last 12 videos',
        },
      },
      [YT_SCORE_NAMES.videoViewsTotal]: {
        title: 'Videos-Views Total',
        description: {
          main:
            'This score sets the average total views per video in perspective with the subscriber count and the horizontal bars provide an indication of how this value compares to influencers with similar subscriber counts.',
          text:
            'A low score could indicate that the influencer has a high amount of inactive subscribers and also a <b>low rate of videos that are promoted to non-subscribers</b>.<br><br><b>Calculation:</b><br>Median (Video (View Count) / Subscriber Count) of the last 12 videos',
        },
      },
      [YT_SCORE_NAMES.videoViewsGrowth]: {
        title: 'Videos-Views Growth',
        description: {
          main:
            'This score shows the increase or decrease of the view counts over the last 30 days and the horizontal bars provide an indication of how this value compares to influencers with similar subscriber counts.',
          text:
            'A healthy influencer should have increasing numbers of views on the videos. A low score could indicate <b>less interest in the influencer\'s content or just one or a few viral videos</b>.<br><br><b>Calculation:</b><br>Median View Count (now) / Median View Count (30 days ago)',
        },
      },
      [YT_SCORE_NAMES.videoAverageDuration]: {
        title: 'Videos-Average Duration',
        description: {
          main:
            'This score shows the average duration of videos and the horizontal bars provide an indication of how this value compares to influencers with similar subscriber counts.',
          text:
            '<b>This metric is not included in the overall score and doesn\'t provide information about the quality.</b><br> It gives you an idea of the length of videos and helps you determine if the influencer is a fit for your purpose.<br><br><b>Calculation:</b><br>Median (Video Length in Seconds) of the last 12 videos',
        },
      },
      [YT_SCORE_NAMES.shortsLikes]: {
        title: 'Shorts-Likes',
        description: {
          main:
            'This score shows the average amount of likes a Short received compared to the views this Short received. The horizontal bars provide an indication of how this value compares to influencers with similar subscriber counts.',
          text:
           'A high score indicates that a lot of the viewers are actually <b>engaging with the content and liking what they see</b>. Since YouTube doesn\'t show dislikes anymore, a low score of this metric correlates with high amount of dislikes.<br><br><b>Calculation:</b><br> Median ( Short (Like Count) / Short (View Count) ) for the last 12 Shorts.',
        },
      },
      [YT_SCORE_NAMES.shortsComments]: {
        title: 'Shorts-Comments',
        description: {
          main:
            'This score sets the number of comments per Short in perspective with the number of views the Short received. The horizontal bars provide an indication of how this value compares to influencers with similar subscriber counts.',
          text:
            'A high score indicates that the content is <b>interesting, engaging and the influencer might have a deeper conntection to the subscriber base</b>.<br><br><b>Calculation:</b><br> Median ( Short(Comment Count) / Short (View Count) ) for the last 12 Shorts',
        },
      },
      [YT_SCORE_NAMES.shortsEngagement]: {
        title: 'Shorts-Engagement',
        description: {
          main:
            'This score sets the engagement - this means the likes and comments of Short - in perspective with the average views of the influencer\'s Shorts. The horizontal bars provide an indication of how this value compares to influencers with similar subscriber counts.',
          text:
            'A good engagement for Shorts indicates deep and engaging content and Shorts that are more likely to be watched completely.<br><br><b>Calculation:</b><br> Median (Short(Likes) + Short(Comments)) / Short(Views) for the last 12 Shorts',
        },
      },
      [YT_SCORE_NAMES.shortsViewsTotal]: {
        title: 'Shorts-Views Total',
        description: {
          main:
            'This score sets the average total views per Short in perspective with the subscriber count and the horizontal bars provide an indication of how this value compares to influencers with similar subscriber counts.',
          text:
            'A low score could indicate that the influencer has a high amount of inactive subscribers and also a <b>low rate of Shorts that are promoted to non-subscribers</b>.<br><br><b>Calculation:</b><br>Median (Short (View Count) / Subscriber Count) of the last 12 Shorts',
        },
      },
      [YT_SCORE_NAMES.shortsViewsGrowth]: {
        title: 'Shorts-Views Growth',
        description: {
          main:
            'This score shows the increase of decrease of the view counts over the last 30 days and the horizontal bars provide an indication of how this value compares to influencers with similar subscriber counts.',
          text:
            'A healthy influencer should have increasing numbers of views on the Shorts. A low score could indicate <b>less interest in the influencer\'s content or just one or a few viral Shorts</b>.<br><br><b>Calculation:</b><br>Median View Count (now) / Median View Count (30 days ago)',
        },
      },
      [YT_SCORE_NAMES.shortsAverageDuration]: {
        title: 'Shorts-Average Duration',
        description: {
          main:
            'This score shows the average duration of Shorts and the horizontal bars provide an indication of how this value compares to influencers with similar subscriber counts.',
          text:
            '<b>This metric is not included in the overall score and doesn\'t provide information about the quality.</b><br> It gives you an idea of the length of Shorts and helps you determine if the influencer is a fit for your purpose.<br><br><b>Calculation:</b><br>Median (Short Length in Seconds) of the last 12 Shorts',
        },
      }
    },
    [USER_INDEXES.instagram]: {
      overallScore: {
        title: 'Overall Score',
        description:
          'This score is a combination of all the specific scores. It should help you to give a rough estimation on the performance and quality.',
      },
      brandPerformanceOverall: {
        title: 'Brand Performance Overall',
        titleChart: 'Earned Media Value',
        description:
          'This is the total earned media value of all collaborations and creators that mentioned this brand in the last 365 days. The bars are a benchmark how the EMV compares to other brands accounts.',
      },
      [IG_SCORE_NAMES.postFrequency]: {
        title: 'Post Frequency',
        description: {
          main:
            'This value gives an indication of how often this influencer publishes posts on Instagram compared to other influencers.',
          text:
            "From our experience a good influencer shouldn't <b>post too rarly an shouldn't be inactive</b>.<br>On the other hand, if postings are <b>too frequent</b>, the audience could not see all of the posts and the individual impact of one post is lower.<br><br><b>Calculation:</b><br> Average (Time Difference between the last 12 Posts)",
        },
      },
      [IG_SCORE_NAMES.storyFrequency]: {
        title: 'Story Frequency',
        description: {
          main:
            'This value gives an indication of how often this influencer publishes stories on Instagram compared to other influencers.',
          text:
            "The frequency of stories can <b>highly differentiate between influencers</b>. Many influencers don't publish stories at all and others publish stories every other hour. When stories are rarely published, the average story views tend to be a lot lower.<br><br><b>Calculation:</b><br> Average (Time Difference between the last 12 Stories)",
        },
      },
      [IG_SCORE_NAMES.reelFrequency]: {
        title: 'Reel Frequency',
        description: {
          main:
            'This value gives an indication of how often this influencer publishes reels on Instagram compared to other influencers.',
          text:
            "Reels are quite new to Instagram and many influencers don't make use of it at all. The upside of using reels for marketing are the transparent metrics, since <b>engagement as well as the plays are publicly available</b>. This makes reels interesting for most predictable results of your campaigns.<br><br><b>Calculation:</b><br> Average (Time Difference between the last 12 Reels)",
        },
      },
      [IG_SCORE_NAMES.postsLikes]: {
        title: 'Posts — Like Engagement',
        description: {
          main:
            'This score sets the number of likes per post in perspective with the number of followers of this influencer. The horizontal bars provide an indication of how this value compares to influencers with similar follower counts.',
          text:
            'A high score indicates that a lot of the followers are actually <b>viewing the post and engaging with the content</b>. Also, it could indicate that the number of inactive followers is lower compared to to simliar influencers. <br><br><b>Calculation:</b><br> Median ( Post(Like Count) / Follower Count ) for the last 12 posts',
        },
      },
      [IG_SCORE_NAMES.postsComments]: {
        title: 'Posts — Comment Engagement',
        description: {
          main:
            'This score sets the number of comments per post in perspective with the number of followers of this influencer. The horizontal bars provide an indication of how this value compares to influencers with similar follower counts.',
          text:
            'A high score indicates that the content is <b>interesting, engaging and the influencer might have a deeper conntection to the follower base</b>.<br><br><b>Calculation:</b><br> Median ( Post(Comment Count) / Follower Count ) for the last 12 posts',
        },
      },
      [IG_SCORE_NAMES.growthRate]: {
        title: 'Growth Rate',
        description: {
          main:
            'This score shows the recent growth of this account and the horizontal bars provide an indication of how this value compares to influencers with similar follower counts.',
          text:
            'From our experience growth is <b>the most important information for determining the quality of one influencer</b>. Good growth has a positive effect on most other scores and is a reliable metric of the success of your campaigns.<br><br><b>Calculation:</b><br>Follower Count (now) / (Follower Count (now) - Follower Count (30 days ago))',
        },
      },
      [IG_SCORE_NAMES.reelsPlays]: {
        title: 'Reels — Plays',
        description: {
          main:
            'This score sets the median plays per reel in perspective with the number of followers of this influencer. The horizontal bars provide an indication of how this value compares to influencers with similar follower counts.',
          text:
            "We recommend that campaigns that include reels should <b>not be dependent on the follower count</b> and should <b>only take the number of plays per reel</b> into account. When this score is on the lower end, it indicates that the followers have a rather superficial connection to the content or the influencer didn't succeed in creating entertaining reels.<br><br><b>Calculation:</b><br>Median( Reel(Plays) newer than 90 days )",
        },
      },
      [IG_SCORE_NAMES.reelsEngagement]: {
        title: 'Reels — Engagement',
        description: {
          main:
            'This score sets the engagement - this means the likes and comments of reels - in perspective with the average plays of the influencer\'s reels. The horizontal bars provide an indication of how this value compares to influencers with similar follower counts.',
          text:
            'A good engagement for reels indicates deep and engaging content and videos that are less likely to be skipped.<br><br><b>Calculation:</b><br>(Reel(Likes) + Reel(Comments)) / Reel(Plays)',
        },
      },
      [IG_SCORE_NAMES.reelsWatchTime]: {
        title: 'Reels — Watch Time',
        description: {
          main:
            'This metric tells how many hours one individual reel is watched and sets it in perspective with influencer that have a similar number of followers.',
          text:
            'Some reels can only be a few seconds long and others have a duration of up to one minute. This is particulary interesting for campaigns to calculate the <b>overall time one sponsored reel is actually running on screen</b> <br><br><b>Calculation:</b><br>Reel(Finished Views) * Reel(Duration)',
        },
      },
      [IG_SCORE_NAMES.suspiciousActivitiesFollowers]: {
        title: 'Suspicious and Mass Followers',
        description: {
          main:
            'This metric tells the percentage of follower that were detected as suspicious or inactive and the bars provide a comparison to influencers with a similar follower count.',
          text:
            "Inactive followers don't show any signs of engagement or their account seems to be not active for a longer period of time.<br>Suspicious profiles mostly don't have a profile picture and no or only a small number of posts and a generic bio.<br><br><b>Calculation:</b><br>(Number of Suspicious Profiles + Number of Inactive Profiles) / Follower Count",
        },
      },
      [IG_SCORE_NAMES.suspiciousActivities]: {
        title: 'Suspicious Activities',
        description: {
          main: 'We detect common signs of fake or suspicious activities.',
          text:
            "Activities are only found if they have a significant impact on the account or if they happend over a longer period of time. Also, if these activities happened a long time ago and don't happen anymore, they are not shown here.<br><br>Note: These activities are not neccessarily the fault of the influencer itself and e.g. fake followers can be purchased for any acccount by anybody.",
        },
        followUnfollowActivity: 'follow-unfollow activity',
        fakeFollowers: 'Fake Followers',
        foundSuspiciousActivity: 'Suspicious Activity detected.',
        notFoundSuspiciousActivity: 'No signs of Suspicious Activity were detected.',
        likesViewsDisabled: 'Like Count For Posts Disabled',
        isInactive: 'Profile is inactive'
      },
      [IG_SCORE_NAMES.sponsoredPosts]: {
        title: 'Sponsored Posts',
        description: {
          main:
            'This metric shows how many sponsored content pieces this influencer published on the profile.',
          text:
            'This does not indicate if the influencer got any compensation or what type of collaboration the influencer participated in. We show all mentions that were found within the content pieces and the captions.',
        },
        brandsThatWereTagged:
          'These are brands that were tagged in stories and posts in the last 180 days',
        brandsThatWereTaggedNotFound:
          "We haven't found any brands that were tagged in stories and posts in the last 180 days",
        sponsoredPosts: 'Sponsored Posts',
        sponsoredStories: 'Sponsored Stories',
        sponsoredReels: 'Sponsored Reels',
      },
      [IG_SCORE_NAMES.sponsoredStories]: {
        title: 'Sponsored Stories',
        description: {
          main:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          text:
            'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. <b>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</b> Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
      },
    },
    [USER_INDEXES.tiktok]: {
      [TT_SCORE_NAMES.averageDurationPosts]: {
        title: 'Average Duration',
        description: {
          main: 'This values indicates the average duration of a video that was posted.',
          text:
            "This score indicates the average video duration for this TikTok creator. Understanding their typical video length helps in planning <b>collaboration</b> or <b>sponsored content</b>. While duration doesn't directly determine quality, it influences <b>viewer engagement</b>. For collaborative or sponsored videos, matching this average duration can optimize <b>audience retention</b> and <b>effectiveness</b>, as significantly longer or shorter videos might not align well with the creator's usual content style.",
        },
        inAverage: 'on average',
      },
      [TT_SCORE_NAMES.commentEngagementPosts]: {
        title: 'Comment Engagement',
        description: {
          main: 'This metric reflects the average level of user engagement, as measured by the number of comments on each video.',
          text:
            "This figure represents the average engagement through <b>comments</b> on the videos. Comments are a strong indicator of an <b>actively engaged audience</b>, demonstrating that viewers are not just watching but also <b>interacting</b> with the content. A higher average suggests a more engaged and interactive user base, often indicating a <b>deeper connection</b> between the audience and the creator. This level of engagement can be a valuable measure of how <b>compelling</b> and <b>relatable</b> the content is, fostering a sense of community and dialogue around the creator's work.",
        },
        inAverage: 'on average',
      },
      [TT_SCORE_NAMES.engagementPosts]: {
        title: 'Total Engagement',
        description: {
          main: 'This value indicates the creator\'s average total engagement, encompassing likes, comments, and shares.',
          text:
            "Total engagement is a critical metric for <b>brands</b>, as it encapsulates the overall audience interaction with the creator's content. A higher engagement rate not only signifies <b>popular content</b> but also indicates a <b>strong, active community</b> around the creator. For brands, this means <b>greater visibility</b> and <b>potential reach</b>. Engaged audiences are more likely to notice and react to brand collaborations, making them valuable for marketing and promotional efforts. This metric provides insight into how well the audience resonates with the content, which is essential for brands seeking <b>impactful collaborations</b> and partnerships.",
        },
        inAverage: 'on average',
      },
      [TT_SCORE_NAMES.likeEngagementPosts]: {
        title: 'Like Engagement',
        description: {
          main: 'This metric reflects the average level of user engagement, as measured by the number of likes on each video.',
          text:
            "Like engagement is a fundamental indicator of <b>audience approval</b> and interest. For brands, the number of likes is a clear, quantifiable measure of how well the content resonates with viewers. A high like count signifies content that is not only engaging but also aligns well with the audience's <b>preferences</b> and <b>values</b>. This is crucial for brands as it points to the potential effectiveness of their products or messages when associated with the creator's content. Likes serve as an immediate reaction to content, offering insights into its appeal and the audience's likelihood of favoring <b>brand-related content</b>.",
        },
        inAverage: 'on average',
      },
      [TT_SCORE_NAMES.postFrequency]: {
        title: 'Post Frequency',
        description: {
          main: 'This metric indicates the average number of posts published by the creator each week.',
          text:
            "The frequency of posts is a key factor in maintaining and growing an <b>engaged audience</b> on TikTok. We recommend creators post <b>between 1 and 10 times per week</b>, depending on their content type and audience engagement. Posting less frequently may suggest inactivity or a lack of emphasis on TikTok, potentially leading to decreased audience engagement. Conversely, posting too frequently might overwhelm followers and dilute the impact of individual posts. This could be counterproductive, especially in campaigns, as it risks being perceived as spammy and may reduce the significance of each post. Striking the right balance is crucial for <b>sustained audience interest</b> and <b>effective campaign execution</b>.",
        },
        inAverage: 'on average',
      },
      [TT_SCORE_NAMES.viewsPerPost]: {
        title: 'Views per Post',
        description: {
          main: 'This metric shows the average number of views per post, in comparison with similar creators.',
          text:
            "A high number of views per post is a key indicator of <b>content reach</b> and <b>audience engagement</b>. It signifies not only the popularity of the content but also the creator's ability to captivate a wide audience, which is crucial for successful collaborations. When compared to the follower count, a high view count indicates that the content is not just reaching the creator's existing followers but is also being shared and viewed beyond the immediate follower base. This extended reach is particularly valuable for brands and collaborators, as it suggests the potential for <b>broader impact</b> and <b>greater visibility</b>. In collaborations, content that consistently garners a high view count can significantly enhance the effectiveness of marketing campaigns, driving awareness and engagement beyond the creator's core audience.",
        },
        inAverage: 'on average',
      },
      [TT_SCORE_NAMES.growthFollowers12M]: {
        title: 'Growth Followers (12M)',
        description: {
          main: 'This metric tracks the long-term growth in followers over a 12-month period.',
          text:
            "Long-term follower growth is a crucial indicator of a creator's <b>sustained appeal</b> and relevance. Consistent growth over 12 months demonstrates not only the creator's ability to attract new followers but also to retain them with engaging content. This is particularly important in the context of collaborations, as it indicates a <b>growing and engaged audience</b>. Brands and collaborators often seek creators with a proven track record of expanding their audience, as this suggests the potential for a <b>wider reach</b> and <b>more impactful collaborations</b>. A steady increase in followers over a longer period is a strong signal of the creator's influence and the likelihood of successful partnerships, making it a valuable metric for potential collaborators to consider.",
        },
        inAverage: 'on average',
      },
      [TT_SCORE_NAMES.growthFollowers6M]: {
        title: 'Growth Followers (6M)',
        description: {
          main: 'This metric indicates the growth in followers over a 6-month period.',
          text:
            "The follower growth over a 6-month span is an important measure of a creator's <b>current momentum</b> and appeal. It reflects how effectively they are attracting and retaining new followers in a relatively short timeframe. For collaborators and brands, this metric is valuable as it shows the creator's recent growth trajectory, which is crucial for timely and relevant collaborations. A strong growth rate in this period suggests the creator's content is resonating well with audiences now, making them a potentially powerful partner for current and upcoming campaigns. It also provides insights into <b>emerging trends</b> and audience preferences, which are key for crafting effective collaborative strategies.",
        },
        inAverage: 'on average',
      },
      [TT_SCORE_NAMES.growthFollowers1M]: {
        title: 'Growth Followers (1M)',
        description: {
          main: 'This metric indicates the growth in followers over the last month.',
          text:
            "Monitoring follower growth over a 1-month period offers a snapshot of the creator's <b>immediate impact</b> and relevance. Rapid growth in this short time frame can be a strong indicator of viral content or a particularly successful engagement strategy. For brands and collaborators, this metric is especially important as it highlights the creator's current popularity and potential for immediate impact in collaborations. A significant increase in followers within a month signals a high level of audience engagement and interest, which is ideal for brands looking to capitalize on <b>trending topics</b>, viral content, or timely marketing initiatives. It reflects the creator's ability to quickly captivate and expand their audience, an invaluable trait for short-term campaigns.",
        },
        inAverage: 'on average',
      },
      [TT_SCORE_NAMES.growthViews12M]: {
        title: 'Growth Views (12M)',
        description: {
          main: 'This metric tracks the increase in video views over a 12-month period.',
          text:
            "The growth of views over 12 months provides a comprehensive picture of the creator's <b>long-term appeal</b> and ability to attract viewers. This extended timeframe helps in understanding the sustained popularity of their content. For collaborators and brands, a steady increase in views across a year indicates a consistently engaging content strategy, making the creator a reliable partner for long-term campaigns. It reflects the creator's success in not only maintaining but also expanding their audience reach, which is crucial for collaborations aiming for a <b>lasting impact</b>.",
        },
        inAverage: 'on average',
      },
      [TT_SCORE_NAMES.growthViews6M]: {
        title: 'Growth Views (6M)',
        description: {
          main: 'This metric measures the increase in video views over the past 6 months.',
          text:
            "View growth over a 6-month period is an indicator of the creator's <b>current trend</b> and audience engagement. It reflects how the creator's content is performing in the more immediate past, showing their capability to capture and retain viewer interest. For brands and collaborators, this metric is particularly telling of the creator's recent effectiveness and potential for upcoming collaborations. A significant growth in views during this time suggests that the creator's content is highly relevant and engaging at present, offering valuable opportunities for timely and resonant marketing initiatives.",
        },
        inAverage: 'on average',
      },
      [TT_SCORE_NAMES.growthViews1M]: {
        title: 'Growth Views (1M)',
        description: {
          main: 'This value represents the increase in video views over the last month.',
          text:
            "The growth in views over a one-month span offers an immediate insight into the creator's <b>current content performance</b> and audience reach. A surge in views within this short period can often be indicative of viral content or highly effective audience engagement strategies. This is a crucial metric for collaborators and brands, as it highlights the creator's immediate influence and the potential for impactful, short-term collaborations. Rapid growth in views is a strong sign of the creator's ability to quickly engage and captivate audiences, making them an ideal partner for fast-paced campaigns and trending content.",
        },
        inAverage: 'on average',
      },
      /* TEMP */      
      [TT_SCORE_NAMES.postsPerWeek]: {
        title: 'Posts per Week',
        description: {
          main: 'This values indicates how often this creator is publishing posts per week',
          text:
            "We recommend that creators should publish <b>between 1 and 10 posts per week</b> dependent on the content.</br>Lower post frequencies could indicate inactivity or too little focus on activity on TikTok.</br>Higher frequencies could be perceived as spammy and individual post won't have siginificant impact, which could be a disadvantage for campaigns.",
        },
        inAverage: 'on average',
      },
      [TT_SCORE_NAMES.viralPosts]: {
        title: 'Viral posts',
        description: {
          main:
            'This value indicates how many videos reach viewers outside the usual audience of the creator.',
          text:
            'A viral post is counted, if the <b>viewers of a single post exceed the median of all other recent posts by a factor of 100</b> or if the video is shown on the so-called "Explore Page" and is shown to a broad audience. This effect is helpful for campaigns to reach a bigger target group, but in case of smaller creators it is rather unlikely for a post to go viral.',
        },
        outOfTheLast: 'of the last',
      },
      [TT_SCORE_NAMES.oneHitWonder]: {
        title: 'One Hit Wonder',
        description: {
          main:
            'In case the creator is a "One Hit Wonder", only one or very few posts got viral and the usual posts gain very little views.',
          text:
            "It happens quite often on TikTok, that <b>creators go viral and gain a large follower base only with one single post</b>. When not looking carfully, this creator can be seen as successful with a large audience.</br>When comparing the viral posts to usual posts and to the total follower number, we can detect pretty easily that most of the audience won't see any other post of this creator.",
        },
      },
      [TT_SCORE_NAMES.profileScore]: {
        title: 'Profile Score',
        description: {
          main:
            'This profile score is an influData rating, which should give marketers and brands a quick indication, how this creator is performing, compared to creators with a similar follower count.',
          text:
            'The minimum value <b>is 1 and the maximum value is 10, where 10 is the best score</b> possible.</br>For our calculation we use different weightings on our metrics:</br>Engagement rate and expected audience per post have a high impact.</br>Rate of viral posts and the post frequency have a smaller impact.',
        },
      },
      [TT_SCORE_NAMES.expectedAudiencePerPost]: {
        title: 'Expected Audience per Post',
        description: {
          main: 'This value gives you an indication how many users one post will reach',
          text:
            'It is based on the <b>median plays of the most recent posts</b>.</br>If brands or agencies plan to start a collaboration with a creator, this value gives you a rough idea how many users will be reached with one post.</br>Note: In many cases there is a high variance in plays per posts and sponsored posts might have a smaller audience than non-sponsored post.',
        },
      },
      [TT_SCORE_NAMES.engagementRate]: {
        title: 'Engagement Rate',
        description: {
          main:
            'This value sets the number of comments and likes in perspective to the plays per post.',
          text:
            'The engagement rate indicates <b>what part of the viewers are actually engaging with the content.</b></br>A higher rate (>10%) indicates, that the creator might have a deeper relationship with the audience and more engaging content.</br>A low score (<3%) often results from rather superficial content.',
        },
      },
      /* */
    }
  },
  [availableLangs.de]: {
    [USER_INDEXES.youtube]: {
      [YT_SCORE_NAMES.video]: {
        title: 'Video',
        description: {
          main:
            'Dieser Wert gibt an, wie oft ein Influencer Videos auf YouTube veröffentlicht. Das Balkendiagramm zeigt den Vergleich zu Influencern ähnlicher Subscriberzahl.',
          text:
            'Unserer Erfahrung nach sollte ein guter Influencer <b>aktiv sein und nicht zu selten Videos veröffentlichen</b>. Videos sollten <b>nicht öfter als ein- bis zweimal am Tag</b> veröffentlicht werden, da die Audience nicht alle Videos sehen wird und einzelne Posts weniger Einfluss haben und weniger relevat werden.<br><br><b>Berechnung:</b><br>Durchschnitt des Zeitunterschiedes zwischen den letzten 12 Videos zum jeweils nächstälteren Post',
        },
      },
      [YT_SCORE_NAMES.shorts]: {
        title: 'Shorts',
        description: {
          main:
            'Dieser Wert gibt an, wie oft ein Influencer Shorts auf YouTube veröffentlicht. Das Balkendiagramm zeigt den Vergleich zu Influencern ähnlicher Subscriberzahl.',
          text:
            'Unserer Erfahrung nach sollte ein guter Influencer <b>aktiv sein und nicht zu selten Shorts veröffentlichen</b>. Ebenfalls sollten Shorts <b>nicht öfter als ein- bis zweimal am Tag</b> veröffentlicht werden, da die Audience nicht alle Shorts sehen wird und einzelne Shorts weniger Einfluss haben und weniger relevat werden.<br><br><b>Berechnung:</b><br>Durchschnitt des Zeitunterschiedes zwischen den letzten 12 Shorts zum jeweils nächstälteren Short',
        },
      },
      [YT_SCORE_NAMES.growthRate]: {
        title: 'Wachstumsrate',
        description: {
          main:
            'Dieser Wert zeigt das Wachstum des Influencers in den letzten 30 Tagen an und das Balkendiagramm zeigt außerdem, wie sich das Wachstum mit Influencern einer ähnlichen Größe nach Subscribern verhält.',
          text:
            'Unserer Erfahrung nach ist das Wachstum <b>die aussagekräftigste Information, um die Qualität des Influencers zu bestimmen.</b> Ein hohes Subscriber-Wachstum hat einen positiven Effekt auf die meisten anderen Scores und ist ein sehr verlässlicher Wert um den Erfolg von Kampagnen vorherzusehen.<br><br><b>Berechnung:</b><br>Subscriber-Zahl (aktuell) / (Subscriber-Zahl (aktuell) - Subscriber-Zahl (vor 30 Tagen))',
        },
      },
      [YT_SCORE_NAMES.positiveReactions]: {
        title: 'Positive Reaktionen',
        description: {
          main:
            'Dieser Wert zeigt, welcher Anteil der Kommentare unter den Videos und Shorts eine positive Semantik aufweist und das Balkendiagramm zeigt außerdem, wie sich das Wachstum mit Influencern einer ähnlichen Größe nach Subscribern verhält.',
          text:
            'Wenn du mit einem Creator zusammenarbeitest, dann ist es durchaus wichtig zu wissen, wie die Community auf die veröffentlichten Videos reagiert und man sucht eher <b>nach einer positiven und loyalen Community</b>. influData erkennt die Stimmung der einzelnen Kommentare und unterscheidet in positive, negative und neutrale Kommentare.<br><br><b>Berechnung:</b><br>Kommentare mit einer positiven Semantik / (Kommentare mit einer positiven, negativen und neutralen Semantik)',
        },
      },
      [YT_SCORE_NAMES.negativeReactions]: {
        title: 'Negative Reaktionen',
        description: {
          main:
            'Dieser Wert zeigt, welcher Anteil der Kommentare unter den Videos und Shorts eine negative Semantik aufweist und das Balkendiagramm zeigt außerdem, wie sich das Wachstum mit Influencern einer ähnlichen Größe nach Subscribern verhält.',
          text:
            'Wenn du mit einem Creator zusammenarbeitest, dann sollte man darauf achten, dass die Kommentare zumindest neutral oder positiv sind und die negativen Kommentare eher gering sind. Ebenso ist die Gefahr, dass eine Kollaboration <b>einen negativen Einfluss auf die Brand hat, eher geringer</b>. influData erkennt die Stimmung der einzelnen Kommentare und unterscheidet in positive, negative und neutrale Kommentare.<br><br><b>Berechnung:</b><br>Kommentare mit einer negativer Semantik / (Kommentare mit einer positiven, negativen und neutralen Semantik)',
        },
      },
      [YT_SCORE_NAMES.videoLikes]: {
        title: 'Videos-Likes',
        description: {
          main:
            'Dieser Wert setzt die Anzahl der Likes in das Verhältnis zur Anzahl der Views des Videos. Das Balkendiagramm zeigt den Vergleich zu Influencern ähnlicher Subscriberzahl.',
          text:
            'Ein hoher Wert sagt aus, dass eine große Anzahl der Viewer das Video <b>tatsächlich sieht und das Video den Nutzern auch wirklich gefällt.</b>. YouTube zeigt die Zahl der Dislikes nicht mehr an und somit kann durch einen niedrigen Score eine hohe Anzahl an Dislikes erwartet werden.<br><br><b>Berechnung:</b><br> Median ( Video(Zahl der Likes) / Video(View-Zahl) )<br>Es werden die letzten 12 Videos berücksichtigt.',
        },
      },
      [YT_SCORE_NAMES.videoComments]: {
        title: 'Videos - Kommentare',
        description: {
          main:
            'Dieser Wert setzt die Anzahl der Kommentare in das Verhältnis zur Anzahl der Views des Videos. Das Balkendiagramm zeigt den Vergleich zu Influencern ähnlicher Subscriberzahl.',
          text:
          'Ein hoher Wert sagt aus, dass der Inhalt <b>interessant, anziehend und weniger oberflächlich ist.</b> Daher kann auch von einer höheren Verbindung zwischen Influencer und Followerschaft ausgegangen werden.<br><br><b>Berechnung:</b><br> Median ( Video(Zahl der Kommentare) / Video(View-Zahl) )<br>Es werden die letzten 12 Videos berücksichtigt.'
        },
      },
      [YT_SCORE_NAMES.videoEngagement]: {
        title: 'Videos - Engagement',
        description: {
          main:
            'Dieser Wert setzt das Engagement - also die Likes und Kommentare - der Videos in das Verhältnis zur durschschnittlichen Zahl der Video-Plays des Influencers. Das Balkendiagramm zeigt den Vergleich zu Influencern ähnlicher Subscriberzahl.',
          text:
            'Ein gutes Engagement der Videos entsteht durch <b>unterhaltsamen Content, der zur Interaktion anregt</b>. Das bedeutet auch, dass die Videos dieses Influencer <b>öfter bis zum Ende geschaut werden</b>.<br><br><b>Berechnung:</b><br>(Video(Likes) + Video(Kommentare)) / Video(Views)',
        },
      },
      [YT_SCORE_NAMES.videoViewsTotal]: {
        title: 'Videos - Views Insgesamt',
        description: {
          main:
            'Dieser Wert setzt die durchschnittlichen Views pro Video in das Verhältnis zur Subscriberzahl und das Balkendiagramm zeigt, wie sich dieser Wert zu Influencern einer ähnlichen Größe nach Subscribern verhält.',
          text:
            'Bei einem niedrigen Score kann erwartet werden, dass <b> dem Influencer eine hohe Anzahl an inaktiven Profilen folgen und auch wenige Videos Nicht-Subscribern angezeigt werden.</b><br><br><b>Berechnung:</b><br>Median (Video (View-Zahl) / Subscriber-Zahl) der letzten 12 Videos',
        },
      },
      [YT_SCORE_NAMES.videoViewsGrowth]: {
        title: 'Videos - Views Wachstum',
        description: {
          main:
            'Dieser Wert zeigt das Wachstum des View-Zahlen des Influencers in den letzten 30 Tagen an und das Balkendiagramm zeigt außerdem, wie sich das Wachstum mit Influencern einer ähnlichen Größe nach Subscribern verhält.',
          text:
            'Ein gesundes Influencer-Profil sollte eine stetig wachsende Anzahl an Views aufweisen. Ein niedriger Wert könnte bedeuten, dass <b>sich das Interesse der Community an den Videos vermindert oder dass nur ein bzw. wenige Videos viral ausgespielt wurden</b>.<br><br><b>Berechnung:</b><br>Median View-Zahl (aktuell) / Median View-Zahl (vor 30 Tagen))',
        },
      },
      [YT_SCORE_NAMES.videoAverageDuration]: {
        title: 'Videos - Dauer (Schnitt)',
        description: {
          main:
            'Dieser Wert zeigt die durschnittliche Dauer der Videos und das Balkendiagramm zeigt außerdem, wie sich dieser Wert mit Influencern einer ähnlichen Größe nach Subscribern verhält.',
          text:
            '<b>Diese Metrik wird nicht in den Gesamtscore einbezogen und gibt keine Information über die Qualität des Influencers</b>.<br> Dieser Wert dient dazu, dass man sich ein Bild der durchschnittlichen Länge der Videos machen kann und entscheiden kann, ob dieser Influencer zu einer Kampagne passt.<br><br><b>Berechnung:</b><br>Median (Videolänge in Sekunden) der letzten 12 Videos',
        },
      },
      [YT_SCORE_NAMES.shortsLikes]: {
        title: 'Shorts - Likes',
        description: {
          main:
            'Dieser Wert setzt die Anzahl der Likes in das Verhältnis zur Anzahl der Views des Shorts. Das Balkendiagramm zeigt den Vergleich zu Influencern ähnlicher Subscriberzahl.',
          text:
            'Ein hoher Wert sagt aus, dass eine große Anzahl der Viewer das Short <b>tatsächlich sieht und das Video den Nutzern auch wirklich gefällt.</b>.<br><br><b>Berechnung:</b><br> Median ( Short(Zahl der Likes) / Short(View-Zahl) )<br>Es werden die letzten 12 Shorts berücksichtigt.',
        },
      },
      [YT_SCORE_NAMES.shortsComments]: {
        title: 'Shorts - Kommentare',
        description: {
          main:
            'Dieser Wert setzt die Anzahl der Kommentare in das Verhältnis zur Anzahl der Views des Shorts. Das Balkendiagramm zeigt den Vergleich zu Influencern ähnlicher Subscriberzahl.',
          text:
          'Ein hoher Wert sagt aus, dass der Inhalt <b>interessant, anziehend und weniger oberflächlich ist.</b> Daher kann auch von einer höheren Verbindung zwischen Influencer und Followerschaft ausgegangen werden.<br><br><b>Berechnung:</b><br> Median ( Short(Zahl der Kommentare) / Short(View-Zahl) )<br>Es werden die letzten 12 Shorts berücksichtigt.'
        },
      },
      [YT_SCORE_NAMES.shortsEngagement]: {
        title: 'Shorts - Engagement',
        description: {
          main:
            'Dieser Wert setzt das Engagement - also die Likes und Kommentare - der Shorts in das Verhältnis zur durschschnittlichen Zahl der Short-Plays des Influencers. Das Balkendiagramm zeigt den Vergleich zu Influencern ähnlicher Subscriberzahl.',
          text:
            'Ein gutes Engagement der Shorts entsteht durch <b>unterhaltsamen Content, der zur Interaktion anregt</b>. Das bedeutet auch, dass die Shorts dieses Influencer <b>öfter bis zum Ende geschaut werden</b>.<br><br><b>Berechnung:</b><br>(Short(Likes) + Short(Kommentare)) / Short(Views)',
        },
      },
      [YT_SCORE_NAMES.shortsViewsTotal]: {
        title: 'Shorts - Views (gesamt)',
        description: {
          main:
            'Dieser Wert setzt die durchschnittlichen Views pro Short in das Verhältnis zur Subscriberzahl und das Balkendiagramm zeigt, wie sich dieser Wert zu Influencern einer ähnlichen Größe nach Subscribern verhält.',
          text:
            'Bei einem niedrigen Score kann erwartet werden, dass <b> dem Influencer eine hohe Anzahl an inaktiven Profilen folgen und auch wenige Videos Nicht-Subscribern angezeigt werden.</b><br><br><b>Berechnung:</b><br>Median (Short (View-Zahl) / Subscriber-Zahl) der letzten 12 Shorts',
        },
      },
      [YT_SCORE_NAMES.shortsViewsGrowth]: {
        title: 'Shorts - Views-Wachstum',
        description: {
          main:
            'Dieser Wert zeigt das Wachstum des View-Zahlen des Influencers in den letzten 30 Tagen an und das Balkendiagramm zeigt außerdem, wie sich das Wachstum mit Influencern einer ähnlichen Größe nach Subscribern verhält.',
          text:
            'Ein gesundes Influencer-Profil sollte eine stetig wachsende Anzahl an Views aufweisen. Ein niedriger Wert könnte bedeuten, dass <b>sich das Interesse der Community an den Shorts vermindert oder dass nur ein bzw. wenige Shorts viral ausgespielt wurden</b>.<br><br><b>Berechnung:</b><br>Median View-Zahl (aktuell) / Median View-Zahl (vor 30 Tagen))',
        },
      },
      [YT_SCORE_NAMES.shortsAverageDuration]: {
        title: 'Shorts - Dauer',
        description: {
          main:
            'Dieser Wert zeigt die durschnittliche Dauer der Shorts und das Balkendiagramm zeigt außerdem, wie sich dieser Wert mit Influencern einer ähnlichen Größe nach Subscribern verhält.',
          text:
            '<b>Diese Metrik wird nicht in den Gesamtscore einbezogen und gibt keine Information über die Qualität des Influencers</b>.<br> Dieser Wert dient dazu, dass man sich ein Bild der durchschnittlichen Länge der Shorts machen kann und entscheiden kann, ob dieser Influencer zu einer Kampagne passt.<br><br><b>Berechnung:</b><br>Median (Shortlänge in Sekunden) der letzten 12 Shorts',
        },
      },
    },
    [USER_INDEXES.instagram]: {
      overallScore: {
        title: 'Gesamtscore',
        description:
          'Ein Score, der sich aus allen spezifischen Scores zusammensetzt. Dieser Score soll helfen, eine grobe Einschätzung über die Qualität des Influencers zu geben und einen Vergleich mit Influencern herzustellen, die eine ähnliche Anzahl an Followern haben.',
      },
      brandPerformanceOverall: {
        title: 'Markenleistung insgesamt',
        titleChart: 'Erzielter Medienwert',
        description:
          'Dies ist der gesamte Medienwert aller Creator, die diese Brand in den letzten 365 Tagen erwähnt haben. Die Balken sind ein Maßstab dafür, wie der EMV im Vergleich zu Accounts anderer Brands abschneidet.',
      },
      [IG_SCORE_NAMES.postFrequency]: {
        title: 'Post - Frequenz',
        description: {
          main:
            'Dieser Wert gibt an, wie oft ein Influencer Posts auf Instagram veröffentlicht. Das Balkendiagramm zeigt den Vergleich zu Influencern ähnlicher Followerzahl.',
          text:
            'Unserer Erfahrung nach sollte ein guter Influencer <b>aktiv sein und nicht zu selten Posts veröffentlichen</b>. Ebenfalls sollten Posts <b>nicht öfter als ein- bis zweimal am Tag</b> veröffentlicht werden, da die Audience nicht alle Posts sehen wird und einzelne Posts weniger Einfluss haben und weniger relevat werden.<br><br><b>Berechnung:</b><br>Durchschnitt des Zeitunterschiedes zwischen den letzten 12 Posts zum jeweils nächstälteren Post',
        },
      },
      [IG_SCORE_NAMES.storyFrequency]: {
        title: 'Story - Frequenz',
        description: {
          main:
            'Dieser Wert gibt an, wie oft ein Influencer Instagram Storys veröffentlicht. Das Balkendiagramm zeigt den Vergleich zu Influencern ähnlicher Followerzahl.',
          text:
            'Die Frequenz der Storys kann sich <b>stark zwischen den Influencern unterscheiden</b>. Viele Influencer veröffentlichen kaum Storys, während andere Influencer mehrmals pro Stunde Storys posten. Werden selten Storys gepostet, dann kann man eher von einer geringen Anzahl an Nutzern ausgehen, die die Storys anschauen.<br><br><b>Berechnung:</b><br>Durchschnitt des Zeitunterschiedes zwischen den letzten 12 Storys zur jeweils nächstälteren Story',
        },
      },
      [IG_SCORE_NAMES.reelFrequency]: {
        title: 'Reels - Frequenz',
        description: {
          main:
            'Dieser Wert gibt an, wie oft ein Influencer Instagram Reels veröffentlicht. Das Balkendiagramm zeigt den Vergleich zu Influencern ähnlicher Followerzahl.',
          text:
            '"Reels" ist eine relativ neue Funktion von Instagram und viele Influencer nutzen diese im Moment noch selten bis überhaupt nicht. Der große Vorteil, Reels für Marketing-Kampagnen zu nutzen, besteht darin, dass die Metriken <b>besonders transparent sind und alle relevanten Daten öffentlich abrufbar sind</b>. Hier ergeben sich sowohl in der Recherche, als auch bei der Kampagnenauswertung erhebliche Vorteile. Reels sind daher besonders interessant, wenn ein hoher Wert auf die Vorhersehbarkeit des Erfolgs einer Kampagne Wert gelegt wird.<br><br><b>Berechnung:</b><br>Durchschnitt des Zeitunterschiedes zwischen den letzten 12 Reels zum jeweils nächstälteren Reel',
        },
      },
      [IG_SCORE_NAMES.postsLikes]: {
        title: 'Posts — Like-Engagement',
        description: {
          main:
            'Dieser Wert setzt die Anzahl der Likes pro Post in das Verhältnis zur Anzahl der Follower des Influencers. Das Balkendiagramm zeigt den Vergleich zu Influencern ähnlicher Followerzahl.',
          text:
            'Ein hoher Wert sagt aus, dass eine große Anzahl der Follower den Post <b>tatsächlich sieht und auch mit dem Inhalt interagiert</b>. Zusätzlich kann man auch davon ausgehen, dass vergeleichsweise wenige Follower inaktiv sind und die Reichweite auch über die Follower hinausgeht (Viralität).<br><br><b>Berechnung:</b><br> Median ( Post(Zahl der Likes) / Followerzahl )<br>Es werden die letzten 12 Posts berücksichtigt.',
        },
      },
      [IG_SCORE_NAMES.postsComments]: {
        title: 'Posts — Comment-Engagement',
        description: {
          main:
            'Dieser Wert setzt die Anzahl der Kommentare pro Post in das Verhältnis zur Anzahl der Follower des Influencers. Das Balkendiagramm zeigt den Vergleich zu Influencern ähnlicher Followerzahl.',
          text:
            'Ein hoher Wert sagt aus, dass der Inhalt <b>interessant, anziehend und weniger oberflächlich ist.</b> Daher kann auch von einer höheren Verbindung zwischen Influencer und Followerschaft ausgegangen werden.<br><br><b>Berechnung:</b><br> Median ( Post(Zahl der Kommentare) / Followerzahl )<br>Es werden die letzten 12 Posts berücksichtigt.',
        },
      },
      [IG_SCORE_NAMES.growthRate]: {
        title: 'Followerwachstum',
        description: {
          main:
            'Dieser Wert zeigt das Wachstum des Influencers in den letzten 30 Tagen an und das Balkendiagramm zeigt außerdem, wie sich das Wachstum mit Influencern einer ähnlichen Größe nach Followern verhält.',
          text:
            'Unserer Erfahrung nach ist das Wachstum <b>die aussagekräftigste Information, um die Qualität des Influencers zu bestimmen.</b> Ein hohes Followerwachstum hat einen positiven Effekt auf die meisten anderen Scores und ist ein sehr verlässlicher Wert um den Erfolg von Kampagnen vorherzusehen.<br><br><b>Berechnung:</b><br>Followerzahl (aktuell) / (Followerzahl (aktuell) - Followerzahl (vor 30 Tagen))',
        },
      },
      [IG_SCORE_NAMES.reelsPlays]: {
        title: 'Reels — Plays',
        description: {
          main:
            'Dieser Wert setzt die Anzahl der Plays pro Reel in das Verhältnis zur Anzahl der Follower des Influencers. Das Balkendiagramm zeigt den Vergleich zu Influencern ähnlicher Followerzahl.',
          text:
            'Wir empfehlen, dass Kampagnen, die Reels beinhalten, <b>nicht nach der Followerzahl des Influencer berechnet werden, sondern nur nach den durchschnittlichen Plays pro Reel.</b> Ist dieser Wert eher am unteren Ende, könnte es entweder bedeuten, dass der Influencer keine besonders große Bindung zu seinen Followern hat, oder auch, dass dieser Influencer keine unterhaltsamen oder interessanten Reels produziert und deshalb keine Viralität entsteht.<br><br><b>Berechnung:</b><br>Median( Reel(Plays), die innerhalb der letzten 90 Tage veröffentlicht wurden )',
        },
      },
      [IG_SCORE_NAMES.reelsEngagement]: {
        title: 'Reels — Engagement',
        description: {
          main:
            'Dieser Wert setzt das Engagement - also die Likes und Kommentare - der Reels in das Verhältnis zur durschschnittlichen Zahl der Reel-Plays des Influencers. Das Balkendiagramm zeigt den Vergleich zu Influencern ähnlicher Followerzahl.',
          text:
            'Ein gutes Engagement der Reels entsteht durch <b>unterhaltsamen Content, der zur Interaktion anregt</b>. Das bedeutet auch, dass die Videos dieses Influencer eher <b>weniger oft übersprungen werden</b>.<br><br><b>Berechnung:</b><br>(Reel(Likes) + Reel(Kommentare)) / Reel(Plays)',
        },
      },
      [IG_SCORE_NAMES.reelsWatchTime]: {
        title: 'Reels — Watch Time',
        description: {
          main:
            'Dieser Wert sagt aus, wie viele Stunden ein Reel im Durchschnitt von allen Nutzern angesehen wird. Das Balkendiagramm zeigt den Vergleich zu Influencern ähnlicher Followerzahl.',
          text:
            'Einige Reels sind nur wenige Sekunden lang, während andere Reels bis zu einer Minute dauern können. Dieser Unterschied ist bei den Play-Zahlen nicht ersichtlich, aber wird bei der "Watch Time" berücksichtigt. Für Kampagnen kann es daher interessant sein, wie <b>viele Stunden ein gesponsorter Reel tatsächlich auf den Bildschirmen abgespielt wird.</b><br><br><b>Berechnung:</b><br>Reel(Plays, die zu Ende geschaut wurden) * Reel(Dauer)',
        },
      },
      [IG_SCORE_NAMES.suspiciousActivitiesFollowers]: {
        title: 'Verdächtige und Massenfollower',
        description: {
          main:
            'Dieser Wert gibt Auskunft über den Anteil der Follower, die als verdächtig oder inaktiv eingestuft wurden und das Balkendiagramm zeigt den Vergleich zu Influencern ähnlicher Followerzahl.',
          text:
            'Inaktive Follower zeigen <b>keine Anzeichen von Aktivität</b> auf ihrem eigenen Account und auch nicht bei den Influencern, denen gefolgt wird. <br>Verdächtige Profile haben oft <b>kein Profilbild und eine kleine Anzahl an Followern</b>. Dazu kommt oft eine allgemein gehaltene Biographie und eine geringe Anzahl an veröffentlichten Posts. Beide dieser Followertypen sind uninteressant für Werbeaktivitäten<br><br><b>Berechnung:</b><br>(Anzahl der verdächtigen Profile + Anzahl der inaktiven Profile) / Followerzahl',
        },
      },
      [IG_SCORE_NAMES.suspiciousActivities]: {
        title: 'Verdächtige Aktivitäten',
        description: {
          main:
            'Wir untersuchen Influencer nach weitverbreiteten Methoden, die genutzt werden, um unnatürliche Follower oder Engagement zu erlangen.',
          text:
            'Diese Aktivitäten werden grundsätzlich nur gefunden, wenn sie einen <b>sbignifikanten Einfluss</b> auf den Account haben oder wenn sie <b>über einen längeren Zeitraum</b> erfolgt sind. Liegen diese Aktivitäten länger in der Vergangenheit, dann werden sie hier nicht angezeigt.<br>Anmerkung: Diese Aktivitäten müssen nicht unbedingt vom Influencer selbst durchführt worden sein. Es können zum Beispiel Fake-Follower für jeden beliebigen Account gekauft werden, auch um Influencer damit zu schaden.',
        },
        followUnfollowActivity: 'Follow-Unfollow-Aktivität',
        fakeFollowers: 'Fake-Follower',
        foundSuspiciousActivity: 'Es wurden verdächtige Aktivitäten gefunden.',
        notFoundSuspiciousActivity: 'Es wurden keine verdächtigen Aktivitäten gefunden.',
        likesViewsDisabled: 'Post-Likes sind deaktiviert',
        isInactive: 'Profil ist inaktiv'
      },
      [IG_SCORE_NAMES.sponsoredPosts]: {
        title: 'Gesponserter Content',
        description: {
          main:
            'Diese Werte zeigen, wie viele Inhalte des Influencers die Erwähnung (Mention) einer Marke enthalten.',
          text:
            'Es muss sich hierbei <b>nicht unbedingt</b> um eine Partnerschaft handeln und es werden auch Erähnungen einbezogen, die ohne jegliche Gegenleistung bzw. auch ohne Kenntis des Unternehmens erfolgt sind.',
        },
        brandsThatWereTagged:
          'Brands, die in den letzten 180 Tagen in Stories, Posts & Reels getaggt wurden',
        brandsThatWereTaggedNotFound:
          'Wir haben keine Brands gefunden, die in den letzten 180 Tagen getagged wurden',
        sponsoredPosts: 'Gesponserte Posts',
        sponsoredStories: 'Gesponserte Storys',
        sponsoredReels: 'Gesponserte Reels',
      },
      [IG_SCORE_NAMES.sponsoredStories]: {
        title: 'Gesponserte Stories',
        description: {
          main:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          text:
            'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. <b>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</b> Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
      },
    },
    [USER_INDEXES.tiktok]: {
      [TT_SCORE_NAMES.averageDurationPosts]: {
        title: 'Durchschnittliche Dauer',
        description: {
          main: 'Dieser Wert zeigt die durchschnittliche Dauer eines geposteten Videos an.',
          text:
            "Dieser Wert gibt die durchschnittliche Videodauer dieses TikTok-Creators an. Das Verständnis ihrer typischen Videolänge hilft bei der Planung von <b>Collaboration</b> oder <b>sponsored content</b>. Während die Dauer nicht direkt die Qualität bestimmt, beeinflusst sie das <b>viewer engagement</b>. Bei kooperativen oder gesponserten Videos kann die Anpassung an diese Durchschnittsdauer die <b>Audience Retention</b> und <b>Effektivität</b> optimieren, da deutlich längere oder kürzere Videos möglicherweise nicht gut zum üblichen Inhaltsstil des Creators passen.",
        },
        inAverage: 'im Durchschnitt',
      },
      [TT_SCORE_NAMES.commentEngagementPosts]: {
        title: 'Comment Engagement',
        description: {
          main: 'Dieser Wert spiegelt das durchschnittliche Level des User-Engagements wider, gemessen an der Anzahl der Kommentare pro Video.',
          text:
            "Dieser Wert repräsentiert das durchschnittliche Engagement durch <b>Kommentare</b> bei den Videos. Kommentare sind ein starker Indikator für eine <b>aktiv engagierte Audience</b>, was zeigt, dass die Zuschauer nicht nur zuschauen, sondern auch mit dem Inhalt <b>interagieren</b>. Ein höherer Durchschnitt deutet auf eine stärker engagierte und interaktive Nutzerbasis hin, oft ein Hinweis auf eine <b>tiefere Verbindung</b> zwischen der Audience und dem Creator. Dieses Engagement-Niveau kann ein wertvolles Maß dafür sein, wie <b>fesselnd</b> und <b>relatable</b> der Inhalt ist, was ein Gefühl der Gemeinschaft und des Dialogs um die Arbeit des Creators fördert.",
        },
        inAverage: 'im Durchschnitt',
      },
      [TT_SCORE_NAMES.engagementPosts]: {
        title: 'Gesamt-Engagement',
        description: {
          main: 'Dieser Wert zeigt das durchschnittliche Gesamt-Engagement des Creators an, einschließlich Likes, Kommentare und Shares.',
          text:
            "Gesamt-Engagement ist ein kritischer Wert für <b>Marken</b>, da es die gesamte Interaktion der Audience mit dem Inhalt des Creators umfasst. Eine höhere Engagement-Rate zeigt nicht nur <b>beliebten Inhalt</b> an, sondern weist auch auf eine <b>starke, aktive Community</b> um den Creator herum hin. Für Marken bedeutet dies <b>größere Sichtbarkeit</b> und <b>potenzielle Reichweite</b>. Engagierte Zuschauer werden wahrscheinlicher Marken-Kollaborationen bemerken und darauf reagieren, was sie wertvoll für Marketing- und Werbeaktionen macht. Dieser Wert bietet Einblicke, wie gut die Audience mit dem Inhalt resoniert, was für Marken entscheidend ist, die nach <b>impactful collaborations</b> und Partnerschaften suchen.",
        },
        inAverage: 'im Durchschnitt',
      },
      [TT_SCORE_NAMES.likeEngagementPosts]: {
        title: 'Like Engagement',
        description: {
          main: 'Dieser Wert spiegelt das durchschnittliche Level des User-Engagements wider, gemessen an der Anzahl der Likes pro Video.',
          text:
            "Like Engagement ist ein grundlegender Indikator für <b>Zustimmung</b> und Interesse der Audience. Für Marken ist die Anzahl der Likes ein klares, quantifizierbares Maß dafür, wie gut der Inhalt bei den Zuschauern ankommt. Eine hohe Anzahl an Likes zeigt, dass der Inhalt nicht nur einbindend ist, sondern auch gut mit den <b>Präferenzen</b> und <b>Werten</b> der Audience übereinstimmt. Dies ist für Marken entscheidend, da es auf die potenzielle Effektivität ihrer Produkte oder Botschaften hinweist, wenn sie mit dem Inhalt des Creators verbunden sind. Likes dienen als sofortige Reaktion auf den Inhalt und bieten Einblicke in dessen Attraktivität und die Wahrscheinlichkeit, dass die Audience <b>markenbezogenen Inhalt</b> bevorzugt.",
        },
        inAverage: 'im Durchschnitt',
      },
      [TT_SCORE_NAMES.postFrequency]: {
        title: 'Post-Frequenz',
        description: {
          main: 'Dieser Wert gibt die durchschnittliche Anzahl an Posts an, die der Creator jede Woche veröffentlicht.',
          text:
            "Die Frequenz der Posts ist ein Schlüsselfaktor für die Aufrechterhaltung und das Wachstum einer <b>engagierten Audience</b> auf TikTok. Wir empfehlen, dass Creators <b>zwischen 1 und 10 Mal pro Woche</b> posten, abhängig von ihrem Inhaltstyp und der Audience-Bindung. Weniger häufiges Posten kann auf Inaktivität oder mangelnde Betonung auf TikTok hindeuten, was zu verminderter Audience-Bindung führen kann. Im Gegensatz dazu könnte zu häufiges Posten die Follower überwältigen und den Einfluss einzelner Posts verwässern. Dies könnte kontraproduktiv sein, insbesondere bei Kampagnen, da es als spammy wahrgenommen werden könnte und die Bedeutung jedes Posts reduzieren könnte. Das richtige Gleichgewicht zu finden, ist entscheidend für ein <b>dauerhaftes Publikumsinteresse</b> und eine <b>effektive Kampagnendurchführung</b>.",
        },
        inAverage: 'im Durchschnitt',
      },
      [TT_SCORE_NAMES.viewsPerPost]: {
        title: 'Views pro Post',
        description: {
          main: 'Dieser Wert zeigt die durchschnittliche Anzahl an Views pro Post im Vergleich zu ähnlichen Creators.',
          text:
            "Eine hohe Anzahl an Views pro Post ist ein Schlüsselindikator für <b>Content-Reichweite</b> und <b>Audience-Bindung</b>. Es zeigt nicht nur die Beliebtheit des Inhalts, sondern auch die Fähigkeit des Creators, eine breite Audience zu fesseln, was für erfolgreiche Kollaborationen entscheidend ist. Im Vergleich zur Follower-Zahl zeigt eine hohe View-Zahl, dass der Inhalt nicht nur die bestehenden Follower des Creators erreicht, sondern auch darüber hinaus geteilt und angesehen wird. Diese erweiterte Reichweite ist besonders wertvoll für Marken und Kollaborateure, da sie das Potenzial für <b>größere Wirkung</b> und <b>größere Sichtbarkeit</b> andeutet. Bei Kollaborationen kann Inhalt, der konsequent eine hohe View-Zahl erzielt, die Effektivität von Marketingkampagnen erheblich steigern und das Bewusstsein und die Bindung über das Kernpublikum des Creators hinaus fördern.",
        },
        inAverage: 'im Durchschnitt',
      },
      [TT_SCORE_NAMES.growthFollowers12M]: {
        title: 'Follower-Wachstum (12M)',
        description: {
          main: 'Dieser Wert verfolgt das langfristige Wachstum der Follower über einen Zeitraum von 12 Monaten.',
          text:
            "Langfristiges Follower-Wachstum ist ein entscheidender Indikator für den <b>anhaltenden Appeal</b> und die Relevanz eines Creators. Ein stetiges Wachstum über 12 Monate zeigt nicht nur die Fähigkeit des Creators, neue Follower anzuziehen, sondern auch, sie mit einbindendem Inhalt zu halten. Dies ist besonders wichtig im Kontext von Kollaborationen, da es eine <b>wachsende und engagierte Audience</b> anzeigt. Marken und Kollaborateure suchen oft nach Creators mit nachweislichem Erfolg bei der Erweiterung der Audience, da dies das Potenzial für eine <b>breitere Reichweite</b> und <b>einflussreichere Kollaborationen</b> andeutet. Ein stetiger Zuwachs an Followern über einen längeren Zeitraum ist ein starkes Signal für den Einfluss des Creators und die Wahrscheinlichkeit erfolgreicher Partnerschaften, was es zu einem wertvollen Wert für potenzielle Kollaborateure macht.",
        },
        inAverage: 'im Durchschnitt',
      },
      [TT_SCORE_NAMES.growthFollowers6M]: {
        title: 'Follower-Wachstum (6M)',
        description: {
          main: 'Dieser Wert zeigt das Wachstum der Follower über einen Zeitraum von 6 Monaten an.',
          text:
            "Das Follower-Wachstum über einen Zeitraum von 6 Monaten ist ein wichtiger Maßstab für den <b>aktuellen Schwung</b> und die Anziehungskraft eines Creators. Es reflektiert, wie effektiv sie neue Follower anziehen und halten in einer relativ kurzen Zeitspanne. Für Kollaborateure und Marken ist dieser Wert wertvoll, da er die aktuelle Wachstumskurve des Creators zeigt, was für zeitnahe und relevante Kollaborationen entscheidend ist. Eine starke Wachstumsrate in dieser Periode deutet darauf hin, dass der Inhalt des Creators derzeit gut bei den Zielgruppen ankommt, was sie zu einem potenziell mächtigen Partner für aktuelle und kommende Kampagnen macht. Es bietet auch Einblicke in <b>neue Trends</b> und Publikumspräferenzen, die für die Entwicklung effektiver Kollaborationsstrategien wichtig sind.",
        },
        inAverage: 'im Durchschnitt',
      },
      [TT_SCORE_NAMES.growthFollowers1M]: {
        title: 'Follower-Wachstum (1M)',
        description: {
          main: 'Dieser Wert zeigt das Wachstum der Follower im letzten Monat an.',
          text:
            "Die Überwachung des Follower-Wachstums über einen Zeitraum von 1 Monat bietet einen Einblick in den <b>unmittelbaren Einfluss</b> und die Relevanz eines Creators. Ein schnelles Wachstum in diesem kurzen Zeitrahmen kann ein starker Indikator für virale Inhalte oder eine besonders erfolgreiche Engagement-Strategie sein. Für Marken und Kollaborateure ist dieser Wert besonders wichtig, da er die aktuelle Popularität des Creators und das Potenzial für unmittelbaren Einfluss in Kollaborationen hervorhebt. Ein signifikanter Anstieg der Follower innerhalb eines Monats signalisiert ein hohes Maß an Audience-Bindung und Interesse, ideal für Marken, die auf <b>trendige Themen</b>, virale Inhalte oder zeitnahe Marketinginitiativen setzen möchten. Es spiegelt die Fähigkeit des Creators wider, schnell seine Audience zu fesseln und zu erweitern, eine unschätzbare Eigenschaft für kurzfristige Kampagnen.",
        },
        inAverage: 'im Durchschnitt',
      },
      [TT_SCORE_NAMES.growthViews12M]: {
        title: 'Wachstum Views (12M)',
        description: {
          main: 'Dieser Wert erfasst die Zunahme der Videoaufrufe über einen Zeitraum von 12 Monaten.',
          text:
            "Das Wachstum der Views über 12 Monate bietet ein umfassendes Bild vom <b>langfristigen Appeal</b> des Creators und seiner Fähigkeit, Zuschauer anzuziehen. Dieser erweiterte Zeitrahmen hilft, die anhaltende Beliebtheit ihres Inhalts zu verstehen. Für Kollaborateure und Marken zeigt ein stetiger Anstieg der Views über ein Jahr eine konsequent einnehmende Content-Strategie, was den Creator zu einem verlässlichen Partner für Langzeitkampagnen macht. Es spiegelt den Erfolg des Creators wider, nicht nur seine Audience zu halten, sondern auch zu erweitern, was für Kollaborationen, die auf einen <b>nachhaltigen Impact</b> abzielen, entscheidend ist.",
        },
        inAverage: 'Im Durchschnitt',
      },
      [TT_SCORE_NAMES.growthViews6M]: {
        title: 'Wachstum Views (6M)',
        description: {
          main: 'Dieser Wert misst die Zunahme der Videoaufrufe über die letzten 6 Monate.',
          text:
            "Das Wachstum der Views über einen Zeitraum von 6 Monaten ist ein Indikator für den <b>aktuellen Trend</b> und die Zuschauerbindung des Creators. Es zeigt, wie gut die Inhalte des Creators in der jüngsten Vergangenheit performen und ihre Fähigkeit, das Interesse der Zuschauer zu fesseln und zu halten. Für Marken und Kollaborateure ist dieser Wert besonders aussagekräftig für die kürzliche Effektivität und das Potenzial für bevorstehende Kollaborationen. Ein signifikantes Wachstum der Views in dieser Zeit deutet darauf hin, dass die Inhalte des Creators aktuell sehr relevant und einnehmend sind, was wertvolle Möglichkeiten für zeitnahe und resonanzstarke Marketinginitiativen bietet.",
        },
        inAverage: 'Im Durchschnitt',
      },
      [TT_SCORE_NAMES.growthViews1M]: {
        title: 'Wachstum Views (1M)',
        description: {
          main: 'Dieser Wert stellt die Zunahme der Videoaufrufe im letzten Monat dar.',
          text:
            "Das Wachstum der Views über einen Monat bietet sofortige Einblicke in die <b>aktuelle Content-Performance</b> und die Reichweite des Creators. Ein Anstieg der Views in diesem kurzen Zeitraum kann oft auf viralen Content oder besonders effektive Strategien zur Zuschauerbindung hinweisen. Für Kollaborateure und Marken ist dieser Wert entscheidend, da er den unmittelbaren Einfluss des Creators und das Potenzial für wirkungsvolle, kurzfristige Kollaborationen hervorhebt. Ein schnelles Wachstum der Views ist ein starkes Zeichen für die Fähigkeit des Creators, schnell die Audience zu fesseln und zu erweitern, was ihn zu einem idealen Partner für schnelle Kampagnen und Trend-Content macht.",
        },
        inAverage: 'Im Durchschnitt',
      },
      /* TEMP */
      [TT_SCORE_NAMES.postsPerWeek]: {
        title: 'Posts Pro Woche',
        description: {
          main: 'Dieser Wert gibt an, wie viele Videos ein Creator pro Woche veröffentlicht.',
          text:
            'Wir empfehlen, dass Creator pro Woche abhängig von der Art des Contents <b>zwischen einem und 10 Videos</b> veröffentlichen sollten.</br>Werden seltener Videos veröffentlicht, dann kann dies auf Inaktivität hindeuten oder dass zu wenig Fokus auf den Kanal gelegt wird.</br>Wird zu oft gepostet, kann dies von den Followern als Spam gesehen werden und einzelne Videos werden kaum noch einen starken Einfluss auf die Zuschauen haben, was einen Nachteil für Kampagnen bedeuten kann.',
        },
        inAverage: 'im Schnitt',
      },
      [TT_SCORE_NAMES.viralPosts]: {
        title: 'Virale Posts',
        description: {
          main:
            'Dieser Wert sagt aus, wie viele Videos auch außerhalb der Followerschaft einen signifikanten Teil an Zuschauern erhalten.',
          text:
            'Von viralen Posts spricht man, wenn die <b>Anzahl der Zuschauer auf einem Video höher ist, als der 100-fache Wert des Medians aller anderen Videos</b> oder aber das Video durch die "Explore-Page" vielen Zuschauern außerhalb der Followerschaft angezeigt wurde.</br>Bei der Durchführung von Kampagnen ist oft eine hohe Viralität erwünscht, um ein Publikum weit über die Zielgruppe hinaus zu erreichen.</br>Bei kleineren Creatorn sind virale Videos jedoch eher selten zu erwarten.',
        },
        outOfTheLast: 'der letzten',
      },
      [TT_SCORE_NAMES.oneHitWonder]: {
        title: 'One Hit Wonder',
        description: {
          main:
            '"One-Hit-Wonder" bedeutet, dass dieser Creator nur einen oder zumindest sehr wenige Videos veröffentlicht hat, die viral ausgespielt wurden - der Großteil der Videos erreicht aber nur einen sehr kleinen Teil der Followerschaft.',
          text:
            'Es passiert auf TikTok recht häufig, dass <b>Creator mit einem einzigen Video eine sehr große Followerschaft aufbauen</b>. Videos, die danach gepostet werden, erreichen dann nur noch selten überhaupt einen Teil der aufgebauten Followerschaft und bleiben unbeachtet.</br>Dieser Effekt kann auf den ersten Blick nicht einfach erkannt werden, da man durch die Followerzahl vermuten könnte, dass es sich um einen Creator mit einer fälschlicherweise großen Reichweite handelt.',
        },
      },
      [TT_SCORE_NAMES.profileScore]: {
        title: 'Profile Score',
        description: {
          main:
            'Der "Score" des Creators stellt eine Bewertung von influData dar, die es Unternehmen und Brands erleichtern soll, den Creator ohne detaillierte Prüfung einzuschätzen.',
          text:
            'Der Score reicht <b>von mindestens 1 bis maximal 10</b>, wobei 10 den besten Score darstellt.</br>Wir nutzen für die Berechnung des Scores in erster Linie den Vergleich zu Creatorn in einer ähnlichen Größe nach Followern. Am stärksten gewichten wir das Engagement und die Reichweite des Videos im Verhältnis zu den Followern und weniger stark die Anzahl der viralen Posts und die Häufigkeit der Posts. ',
        },
      },
      [TT_SCORE_NAMES.expectedAudiencePerPost]: {
        title: 'Erwartete Plays Pro Post',
        description: {
          main: 'Dieser Wert zeigt die durchschnittliche Reichweite der Videos an.',
          text:
            'Er wird errechnet durch den <b>Median der Plays der letzten 12 veröffentlichen Videos.</b></br>Wenn eine Zusammenarbeit gestartet wird, dann ist die Reichweite für der Erfolg einer Kampagne ausschlaggebender als die Followerzahl.</br>Bemerkung: Oft existiert eine hohe Varianz zwischen den verschiedenen Videos und Werbevideos tendieren eher dazu, weniger oft ausgespielt zu werden, da sie für das Publikum weniger interessant sein können, als andere Posts.',
        },
      },
      [TT_SCORE_NAMES.engagementRate]: {
        title: 'Engagement Rate',
        description: {
          main:
            'Dieser Wert zeigt das Verhältnis von Kommentaren und Likes zu den durchschnittlichen Plays.',
          text:
            'Durch die "Engagement-Rate" ist erkennbar, <b>wie viele der Zuschauer wirklich mit den Videos interagieren</b>. Ein hoher Wert (>10%) zeigt, dass der Creator eine größere Bindung zu seinem Publikum aufbaut und der Content sich eher mit den Zuschauern beschäftigt.</br>Ein niedriger Wert (<3%) hingegen lässt oft auf oberflächlicheren Content schließen.',
        },
      },
      /* */
    },
  }
}
