import React, { useEffect, useState } from 'react'
import useTranslation from '../../../../localization/useTranslation'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { addProfilesToOverlap, getAllOverlaps } from '../../../../redux/ducks/collectionsDucks'
import { openedCollectionSelector } from '../../../../redux/selectors'
import { Stack, Typography, Button } from '@mui/material'
import { YourOverlapsTable } from './YourOverlapsTable'
import { useHistory } from 'react-router-dom'
import { YourOverlapsCharts } from './YourOverlapsCharts'
import { ModalComponent } from '../../../../new-ui/components/modal/ModalComponent'
import { CreateOverlapContainer } from './CreateOverlapContainer'

export const YourOverlaps = () => {
  const { labelStrings } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { overlap } = useSelector(openedCollectionSelector, shallowEqual)
  const { isLoading, overlapsData } = overlap

  const [openOverlapCharts, setOpenOverlapCharts] = useState(false)
  const [currentCreatorOverlap, setCurrentCreatorOverlap] = useState(null)

  const [isCreateOverlap, setIsCreateOverlap] = useState(false)

  useEffect(() => {
    // @ts-ignore
    dispatch(getAllOverlaps())
  }, [])

  const onOpenOverlap = creatorOverlap => {
    setCurrentCreatorOverlap(creatorOverlap)
    setOpenOverlapCharts(true)
  }

  const goBack = () => {
    history.push('/collections/your-overlaps')
    setOpenOverlapCharts(false)
    setCurrentCreatorOverlap(null)
  }

  const onCreateOverlap = profileIds => {
    // @ts-ignore
    dispatch(addProfilesToOverlap(profileIds))
    setIsCreateOverlap(false)
  }

  return (
    <>
      <Stack>
        {!openOverlapCharts ? (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: '20px 15px' }}
            >
              <Typography
                sx={{ fontSize: '24px', fontWeight: 'bold', lineHeight: '26px', color: '#442424' }}
              >
                {labelStrings.collectionOverlap.yourOverlaps}
              </Typography>
              <Button
                onClick={() => setIsCreateOverlap(true)}
                variant="outlined"
                sx={{
                  textTransform: 'uppercase',
                  fontSize: '14px',
                  fontFamily: 'Inter',
                  lineHeight: '19px',
                  border: '1px solid #BC9797',
                  background: 'transparent',
                  backgroundImage: 'linear-gradient(90deg, #FFBC00 0.35%, #FF5D5A 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: '#BC9797',
                  '&:hover': {
                    background: 'linear-gradient(90deg, #FFBC00 0.35%, #FF5D5A 100%)',
                    WebkitTextFillColor: 'white',
                    border: 'none',
                    padding: '6px 16px',
                  },
                }}
              >
                + {labelStrings?.createOverlap}
              </Button>
            </Stack>
            <YourOverlapsTable
              tableData={overlapsData}
              isLoading={isLoading}
              onOpenOverlap={onOpenOverlap}
            />
          </>
        ) : (
          <YourOverlapsCharts currentCreatorOverlap={currentCreatorOverlap} goBack={goBack} />
        )}
      </Stack>
      <ModalComponent
        isOpenModal={isCreateOverlap}
        closeModal={() => setIsCreateOverlap(false)}
        contentHeight="auto"
        contentWidth="auto"
        withoutScrollWrapper
        smallPadding
        isCenterTitle
      >
        <Stack>
          <Typography
            sx={{
              fontWeight: 'bold',
              p: 2,
              fontSize: '18px',
            }}
            textAlign="center"
          >
            {labelStrings?.createOverlap}
          </Typography>
          <CreateOverlapContainer openModal={isCreateOverlap} onConfirm={onCreateOverlap} />
        </Stack>
      </ModalComponent>
    </>
  )
}
