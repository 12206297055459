import { LoadingButton } from '@mui/lab'
import { Grid, Box, Stack } from '@mui/material'
import React from 'react'
import { RHFTextField, RHFRadioGroup, RHFMultiCheckboxGroup } from '../../../components/hook-form'
import FormProvider from '../../../components/hook-form/FormProvider'
import { StyledHeading, StyledSubheader } from '../styles'
import { StyledButton } from './styles'
import useTranslation from '../../../../localization/useTranslation'

export const Fields = props => {
  const { methods, onSubmit, handleSubmit, isSubmitting, handleClose } = props
  const { labelStrings } = useTranslation()
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Box sx={{ marginTop: 0 }}>
            <Box>
              <StyledHeading>{labelStrings.selectDataFormat}</StyledHeading>
              <RHFRadioGroup
                row
                name="dataFormat"
                label=""
                spacing={3}
                sx={{ marginLeft: 1 }}
                options={[
                  { value: 'float', label: 'Decimal Number (1.22, 5.33, etc.)' },
                  { value: 'integer', label: 'Integer Number (1,5, 100, etc.)' },
                  { value: 'currency', label: 'Currency' },
                ]}
              />
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
                sx={{
                  marginTop: 1,
                }}
              >
                <RHFTextField
                  name="customFieldName"
                  label={labelStrings.customFieldTitle}
                  helperText={labelStrings.customFieldTitleHelperText}
                />
                <RHFTextField
                  name="metricName"
                  label={labelStrings.typeMetricName}
                  helperText={labelStrings.typeMetricNameHelperText}
                />
              </Box>
            </Box>

            <Box sx={{ marginTop: 3 }}>
              <StyledHeading sx={{ marginBottom: '3px' }}>
                {labelStrings.customFieldSpecificTitle}
              </StyledHeading>
              <StyledSubheader>{labelStrings.customFieldSpecificSubTitle}</StyledSubheader>

              <RHFRadioGroup
                row
                name="applyLevel"
                spacing={3}
                sx={{ marginLeft: 1 }}
                options={[
                  { value: 'content', label: 'Content Specific' },
                  { value: 'creator', label: 'Influencer Specific' },
                  { value: 'campaign', label: 'Campaign Specific' },
                ]}
              />
            </Box>

            <Box sx={{ marginTop: 3 }}>
              <StyledHeading sx={{ marginBottom: '3px' }}>
                {labelStrings.customFieldMetricAppliesTitle}
              </StyledHeading>
              <StyledSubheader>{labelStrings.youCanChooseMoreThanOneOption}</StyledSubheader>

              <RHFMultiCheckboxGroup
                row
                name="applyContentArray"
                sx={{ marginLeft: 1 }}
                data={[
                  {
                    label: '',
                    options: [
                      { value: 'ig_posts', label: 'Instagram Posts' },
                      { value: 'ig_stories', label: 'Instagram Stories' },
                      { value: 'ig_reels', label: 'Instagram Reels' },
                      { value: 'tt_videos', label: 'TikTok Videos' },
                      { value: 'yt_videos', label: 'YouTube Videos (long form/regular)' },
                      { value: 'yt_shorts', label: 'YouTube Shorts' },
                    ],
                  },
                ]}
              />
            </Box>
          </Box>
          <Stack
            direction="row"
            spacing={1.5}
            alignItems="flex-center"
            justifyContent="flex-end"
            sx={{ my: 2 }}
          >
            <StyledButton variant="outlined" sx={{ fontWeight: 'bold' }} onClick={handleClose}>
              {labelStrings.cancel}
            </StyledButton>

            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              {labelStrings.confirm}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
