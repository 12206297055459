import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { selectedPlatformSelector } from '../../redux/selectors'
import useTranslation from '../../localization/useTranslation'
import WithTip from '../common/popups/WithTip'
import { InfoIcon } from '../common/icons'
import { InfoIconWrapper } from '../common/styledWrappers'
import { SOCIAL_PLATFORMS_NAMES, INFLUDATA_HOSTS } from '../../constants/appSettings'

const TipWrapper = styled.div`
  position: absolute;
  right: ${props => props.linkPositions.right}px;
  top: ${props => props.linkPositions.top}px;

  & .collection-sidebar-menu-content,
  & .popup-content {
    width: 200px !important;
  }
  ${props =>
    props.tipCords.left &&
    props.tipCords.top &&
    `
 
  & .popup-content {
    position: fixed !important;
    top: ${props.tipCords.top}px !important;
    left: ${props.tipCords.left}px !important;
  }
`}
`

const LinkWrapper = styled.a`
  color: ${props => props.theme.color.darkGrey};
  text-decoration: underline;
  text-align: center;
  font-size: 12px;
  &:hover {
    color: ${props => props.theme.color.darkGrey};
    opacity: 0.8;
  }
`

const TooltipWrapper = styled.div`
  color: ${props => props.theme.color.black};
`

const MainTipComponentWrapper = styled.div`
  width: 200px;
  text-align: center;
`

const InvisibleWrapper = styled.div`
  visibility: hidden;
  position: fixed;
`

const LinkToFaqQuestion = ({
  faqQuestionId = '',
  tipPosition = null,
  showLeft = false,
  showRight = false,
  extraRatio = 0,
  userindex = '',
  linkPositions = {
    top: -3,
    right: -29,
  },
  tipIconSize = 16,
}) => {
  const TOP_OFFSET_RATIO = 15
  const LEFT_OFFSET_RATIO = 25
  const TIP_ICON_SIZE = tipIconSize || 16
  const TIP_WIDTH_OFFSET = 101 + TIP_ICON_SIZE

  const { currentLang, labelStrings } = useTranslation()
  const selectedPlatformName = useSelector(selectedPlatformSelector)

  const [tipCords, setTipCords] = useState({
    top: 0,
    left: 0,
    textHeight: 0,
  })
  const tipRef = useRef()
  const tipTextRef = useRef()

  useEffect(() => {
    const hidden = tipTextRef.current
    if (!hidden) return
    setTipCords({
      ...tipCords,
      textHeight: hidden.getBoundingClientRect().height + TOP_OFFSET_RATIO,
    })
  }, [])

  const onHover = () => {
    const cords = tipRef.current.getBoundingClientRect()
    const { left, bottom } = cords
    const topPos = (() => {
      return bottom - tipCords.textHeight - extraRatio
    })()

    const leftPos = (() => {
      if (showLeft) return Math.floor(left - TIP_WIDTH_OFFSET * 2 + LEFT_OFFSET_RATIO)
      if (showRight) return Math.floor(left - LEFT_OFFSET_RATIO)
      return Math.floor(left - TIP_WIDTH_OFFSET)
    })()

    setTipCords({
      ...tipCords,
      top: topPos > 0 ? topPos : -topPos,
      left: leftPos,
    })
  }

  const createPosition = () => {
    if (showLeft) return 'top right'
    if (showRight) return 'top left'
    return 'top center'
  }

  const createUserindex = () => {
    if (userindex) return userindex
    const socialPlatform = Object.values(SOCIAL_PLATFORMS_NAMES).find(
      obj => obj.name === selectedPlatformName
    )
    return socialPlatform ? socialPlatform.index : ''
  }

  const linkToFaqQuestion = faqQuestionId
    ? `${INFLUDATA_HOSTS.faq}/${currentLang}/support/solutions/articles/${faqQuestionId}`
    : INFLUDATA_HOSTS.faq

  // inner common components
  const TipComponent = (
    <MainTipComponentWrapper>
      {labelStrings.faqLinkDefaultTip && (
        <TooltipWrapper>{labelStrings.faqLinkDefaultTip}</TooltipWrapper>
      )}
      <LinkWrapper href={linkToFaqQuestion} target="_blank">
        {labelStrings.faqLinkClickText}
      </LinkWrapper>
    </MainTipComponentWrapper>
  )

  const IconComponent = (
    <InfoIconWrapper className="tip_icon_wrapper">
      <InfoIcon height={`${TIP_ICON_SIZE}px`} width={`${TIP_ICON_SIZE}px`} />
    </InfoIconWrapper>
  )

  if (!faqQuestionId) return null

  // render
  return tipPosition ? (
    <TipWrapper tipCords={{}} linkPositions={linkPositions}>
      <WithTip position={tipPosition} TipComponent={TipComponent} userindex={createUserindex()}>
        {IconComponent}
      </WithTip>
    </TipWrapper>
  ) : (
    <>
      {!tipCords.textHeight && <InvisibleWrapper ref={tipTextRef}>{TipComponent}</InvisibleWrapper>}
      <TipWrapper
        ref={tipRef}
        tipCords={tipCords}
        onMouseEnter={onHover}
        linkPositions={linkPositions}
      >
        <WithTip
          TipComponent={TipComponent}
          position={createPosition()}
          userindex={createUserindex()}
        >
          {IconComponent}
        </WithTip>
      </TipWrapper>
    </>
  )
}

LinkToFaqQuestion.propTypes = {
  faqQuestionId: PropTypes.string,
  tipPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
  showLeft: PropTypes.bool,
  showRight: PropTypes.bool,
  extraRatio: PropTypes.number,
  userindex: PropTypes.string,
  linkPositions: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
  }),
  tipIconSize: PropTypes.number,
}

export default LinkToFaqQuestion
