import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useTranslation from '../../../localization/useTranslation'

import { AUDIENCE_ANALYSIS_SECTIONS_NAMES } from '../../../constants/audienceAnalysis'
import { Divider } from '../../common/styledWrappers'
import { BubbledText } from '../../common/styledWrappers/comparedProfilesStyles'
import { getExoticLangName } from '../../../utils'

const SectionWrapper = styled.div`
  display: flex;
  height: 100%;
  ${props => props.theme.flex.centerAll}
  flex-direction: column;
  width: 100%;
  position: relative;
  ${props =>
    props.withTopPadding &&
    `
    padding-top: 25px;
  `}
`

const SectionRow = styled.div`
  font-size: 14px;
  ${props => props.theme.flex.centerAll}
  justify-content: space-between;
  width: ${props => (props.extendedRowWidth ? 95 : 80)}%;
  line-height: 16px;
`

const Type = styled.span`
  font-weight: bold;
  text-transform: capitalize;
  text-align: left;
  max-width: 75%;
  width: fit-content;
  word-break: break-word;
`
const Value = styled.div`
  ${props => props.theme.flex.centerAll}
  width: ${props => (!props.extendedRowWidth ? 45 : 30)}%;

  justify-content: space-between;
`

const MiniDivider = styled(Divider)`
  width: 30%;
  border: 1px solid ${props => props.theme.pickColorDependOnPlatform(props.index).comparedBG};
  margin: 0 5px;
`

const Median = styled.div`
  color: ${props => props.theme.pickColorDependOnPlatform(props.index).primary};
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 12px;
  left: 0;
`

const ValueText = styled(BubbledText)``

const ComparedAnalysisDetailsSection = ({
  audienceData = null,
  sectionNameKey = '',
  index = '',
  withMedian = false,
  comparedRanks = null,
  profileId = '',
}) => {
  const { labelStrings, countryOptions, languageOptions } = useTranslation()
  const audienceTypesNamesMap = {
    realPeoplePercentage: labelStrings.realPeople,
    influencersPercentage: labelStrings.influencers,
    massFollowersPercentage: labelStrings.massFollowers,
    suspiciousPercentage: labelStrings.suspicious,
    negative: labelStrings.negative,
    positive: labelStrings.positive,
    neutral: labelStrings.neutral
  }

  const normalizedData = useMemo(() => {
    if (!audienceData) return null
    if (Array.isArray(audienceData)) {
      if (sectionNameKey === AUDIENCE_ANALYSIS_SECTIONS_NAMES.gender) {
        const [malePerc, femalePerc] = audienceData
        return [
          { type: labelStrings.male, value: malePerc },
          { type: labelStrings.female, value: femalePerc },
        ]
      }
    }
    if (sectionNameKey === AUDIENCE_ANALYSIS_SECTIONS_NAMES.gender) {
      const { male, female } = audienceData
      return [
        { type: labelStrings.male, value: male },
        { type: labelStrings.female, value: female },
      ]
    }
    if (sectionNameKey === AUDIENCE_ANALYSIS_SECTIONS_NAMES.audienceType) {
      return Object.keys(audienceData).reduce((arr, key) => {
        arr.push({
          type: audienceTypesNamesMap[key],
          value: audienceData[key],
          ...(audienceTypesNamesMap[key] === audienceTypesNamesMap.realPeoplePercentage && {
            rank: comparedRanks.audienceRealPeoplePercentage[profileId],
          }),
        })
        return arr
      }, [])
    }
    return (
      Array.isArray(audienceData) &&
      audienceData.map(item => {
        let { value, median, ...restOfItem } = item
        let [typeValue] = Object.values(restOfItem)
        if (sectionNameKey === AUDIENCE_ANALYSIS_SECTIONS_NAMES.country) {
          const country = countryOptions.find(el => el.value === typeValue)
          typeValue = country ? country.label : typeValue //as default if not found
        }
        if (sectionNameKey === AUDIENCE_ANALYSIS_SECTIONS_NAMES.lang) {
          const lang = languageOptions.find(el => el.value === typeValue)
          typeValue = lang ? lang.label : getExoticLangName(item.language)
        }
        if (sectionNameKey === AUDIENCE_ANALYSIS_SECTIONS_NAMES.age) {
          if (median) median = `${labelStrings.averageAgeIs} ${median}`
        }

        return { type: typeValue, value, median }
      })
    )
  }, [audienceData])

  const extendedRowWidth = [
    AUDIENCE_ANALYSIS_SECTIONS_NAMES.country,
    AUDIENCE_ANALYSIS_SECTIONS_NAMES.mentions,
    AUDIENCE_ANALYSIS_SECTIONS_NAMES.audienceType,
  ].includes(sectionNameKey)

  return (
    !!normalizedData && (
      <SectionWrapper withTopPadding={!!withMedian}>
        {normalizedData.map(({ value, type, median, rank = {} }) => {
          return (
            <SectionRow key={`${type + value}`} extendedRowWidth={extendedRowWidth}>
              {median ? (
                <Median index={index}>{median}</Median>
              ) : (
                <>
                  <Type>{type}</Type>
                  <Value extendedRowWidth={extendedRowWidth}>
                    <MiniDivider index={index} />
                    <ValueText rank={rank.colorIdx}>{value}%</ValueText>
                  </Value>
                </>
              )}
            </SectionRow>
          )
        })}
      </SectionWrapper>
    )
  )
}

ComparedAnalysisDetailsSection.propTypes = {
  audienceData: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.instanceOf(null),
  ]),
  sectionNameKey: PropTypes.string,
  index: PropTypes.string,
  withMedian: PropTypes.bool,
  comparedRanks: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
  profileId: PropTypes.string,
}

export default ComparedAnalysisDetailsSection
