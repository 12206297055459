import styled from 'styled-components'
import { GradientButton } from '../../common/buttons/styledButtons'

// Notes and Additional Info
export const ButtonAddNewField = styled(GradientButton)`
  margin-left: 15px;
`

export const fieldCreationOverlayPosition = {
  top: 'calc(100% + 10px)',
  right: '-30px',
}

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const ButtonIconWrapper = styled.div`
  border: none;
  background-color: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.isDragButton ? 'move' : 'pointer')};
  ${props => props.isDragButton && 'padding: 5px 0;'}
`

export const UnavailableSectionWrapper = styled.div`
  margin: 100px 0;

  .unavailable-section-overlay {
    background: none;
  }
`
