import styled from 'styled-components'

export const ViewsPerPostContainer = styled.div`
  ${props => !props.isForPdf && 'margin: 50px 0'};
`

export const ViewsPerPostWrapper = styled.div`
  overflow: hidden;
  ${props => !props.isForPdf && 'padding-left: 25px'};
`
