export const pagesBeforeProfileRedirect = {
  ANALYZE_SEARCH: 'analyze_search',
  CAMPAIGN: 'campaign',
  COLLECTION: 'collection',
  COMPARE: 'compare',
  OTHER: 'other',
  SEARCH: 'search',
  SIMILAR: 'similar',
  EXPLORE: 'explore'
}
