import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'
import Modal from '../../common/modals/Modal/Modal'
import { GradientButton, CancelButton } from '../../common/buttons/styledButtons'
import { ContentWrapper, Message, ActionButtonsWrapper } from './commonStyles'
import { COLLECTIONS_ACCESS_TYPES } from '../../../constants/collections'

const ChangeAccessModal = ({ currentAccessStatus, isSubUser, closeModal, changeAccessStatus }) => {
  const { labelStrings } = useTranslation()

  let headerContent
  let message

  switch (currentAccessStatus) {
    case COLLECTIONS_ACCESS_TYPES.public:
      headerContent = labelStrings.makeCollectionPrivate
      message = labelStrings.collectionWillBecomePrivateMainUser
      break
    case COLLECTIONS_ACCESS_TYPES.private:
      headerContent = labelStrings.makeCollectionPublic
      message = labelStrings.collectionWillBecomePublicMainUser
      break
  }

  if (isSubUser) {
    message = labelStrings.collectionWillBecomePublicSubUser
  }

  return (
    <Modal headerContent={headerContent} closeModal={closeModal}>
      <ContentWrapper>
        <Message isCentered>
          <b>{labelStrings.areYouSure}?</b>
        </Message>
        <Message>{message}</Message>
      </ContentWrapper>
      <ActionButtonsWrapper>
        <CancelButton onClick={closeModal}>{labelStrings.cancel}</CancelButton>
        <GradientButton onClick={changeAccessStatus}>{labelStrings.confirm}</GradientButton>
      </ActionButtonsWrapper>
    </Modal>
  )
}

ChangeAccessModal.propTypes = {
  currentAccessStatus: PropTypes.oneOf([
    COLLECTIONS_ACCESS_TYPES.private,
    COLLECTIONS_ACCESS_TYPES.public,
  ]).isRequired,
  isSubUser: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  changeAccessStatus: PropTypes.func,
}

export default ChangeAccessModal
