import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useTranslation from '../../../localization/useTranslation'
import { openedCollectionSelector, collectionsSelector } from '../../../redux/selectors'
import { unlockAudienceReports } from '../../../redux/ducks/collectionsDucks'
import { withReduxActions } from '../../../hocs/withRedux'
import { SOCIAL_PLATFORMS_NAMES, USER_INDEXES } from '../../../constants/appSettings'
import { pretifyBigNum } from '../../../utils'
import AudienceAnalysisCharts from '../../influencerProfile/audienceAnalysis/AudienceAnalysisCharts'
import { InfoCircleIcon } from '../../common/icons/icons'
import * as Styled from './styles'
import CollectionUnlockBtn from '../CollectionOverlap/CollectionUnlockBtn'

const CollectionAudience = ({ unlockAudienceReports }) => {
  const { labelStrings } = useTranslation()

  const openedCollection = useSelector(openedCollectionSelector)
  const userCollections = useSelector(collectionsSelector)

  const currentCollection = userCollections?.find(collection => collection.collectionId === openedCollection.id)

  const { userdata, metadata } = openedCollection.users
  const { audienceReport } = metadata
  const {
    toUnlockArray,
    unlockedButUnavailableCount,
    overallFollowersIG,
    overallFollowersTT,
    overallReachIG,
    overallReachTT,
    uniqueData,
  } = audienceReport || {}

  const collectionLength = userdata?.length
  const audienceTotalInfluencers = currentCollection?.count - toUnlockArray?.length - unlockedButUnavailableCount
  const unlockedUsersLength = currentCollection?.count - toUnlockArray?.length
  const isAllInfluecnersUnlocked = collectionLength === unlockedUsersLength

  const totalFollowers = overallFollowersIG + overallFollowersTT
  const totalExpectedReach = overallReachIG + overallReachTT

  const uniqueInstagram = uniqueData?.instagram || {}
  const uniqueTiktok = uniqueData?.tiktok || {}

  const totalUniqueFollowers = uniqueTiktok?.uniqueFollowers + uniqueInstagram?.uniqueFollowers
  const totalUniqueReach = uniqueTiktok?.uniqueReach + uniqueInstagram?.uniqueReach

  return (
    <Styled.Container>
      <Styled.AudienceOverlapInfo>
        <Styled.Row>
          <Styled.TotalInfluencers>
            <Styled.Label>{labelStrings.totalInfluencers}:</Styled.Label>
            &ensp;
            <Styled.Value>{audienceTotalInfluencers}</Styled.Value>
          </Styled.TotalInfluencers>

          <Styled.UnlockedInfluencers>
            <Styled.IconWrapper>
              <InfoCircleIcon isGradient />
            </Styled.IconWrapper>
            <Styled.UnlockedInfluencersText>
              {isAllInfluecnersUnlocked
                ? labelStrings.allInfluencersAreUnlocked
                : labelStrings.onlyNFromNInfluencersAreUnlocked(
                    unlockedUsersLength,
                    currentCollection?.count
                  )}
            </Styled.UnlockedInfluencersText>
            {!isAllInfluecnersUnlocked && (
              <>
                &ensp;
                <CollectionUnlockBtn 
                  confirmAction={unlockAudienceReports}
                  unlockedReportsCount={toUnlockArray?.length}
                />
                &ensp;
                <Styled.UnlockedInfluencersText>
                  {labelStrings.toShowExactMetrics}
                </Styled.UnlockedInfluencersText>
              </>
            )}
            &ensp;
            {unlockedButUnavailableCount !== 0 && (
              <Styled.UnlockedInfluencersText>
                {labelStrings.missingReports}
              </Styled.UnlockedInfluencersText>
            )}
          </Styled.UnlockedInfluencers>
        </Styled.Row>

        <Styled.Row>
          <Styled.FollowersInTotal>
            <Styled.Label>{labelStrings.followersInTotal}:</Styled.Label>
            &ensp;
            <Styled.Value>{pretifyBigNum(totalFollowers)}</Styled.Value>
            &ensp;
            {!!overallFollowersIG && !!overallFollowersTT && (
              <Styled.Value>
                ({pretifyBigNum(overallFollowersIG)} {SOCIAL_PLATFORMS_NAMES.instagram.prettyName} |{' '}
                {pretifyBigNum(overallFollowersTT)} {SOCIAL_PLATFORMS_NAMES.tiktok.prettyName})
              </Styled.Value>
            )}
          </Styled.FollowersInTotal>

          <Styled.ExpectedReach>
            <Styled.Label>{labelStrings.expectedReach}:</Styled.Label>
            &ensp;
            <Styled.Value>{pretifyBigNum(totalExpectedReach)}</Styled.Value>
            &ensp;
            {!!overallReachIG && !!overallReachTT && (
              <Styled.Value>
                ({pretifyBigNum(overallReachIG)} {SOCIAL_PLATFORMS_NAMES.instagram.prettyName} |{' '}
                {pretifyBigNum(overallReachTT)} {SOCIAL_PLATFORMS_NAMES.tiktok.prettyName})
              </Styled.Value>
            )}
          </Styled.ExpectedReach>
        </Styled.Row>

        <Styled.Row>
          {!!totalUniqueFollowers && (
            <Styled.FollowersInTotal>
              <Styled.Label>{labelStrings?.uniqueFollowers}:</Styled.Label>
              &ensp;
              <Styled.Value>{pretifyBigNum(totalUniqueFollowers)}</Styled.Value>
              &ensp;
              {uniqueInstagram?.uniqueFollowers && uniqueTiktok?.uniqueFollowers && (
                <Styled.Value>
                  (
                  {`${pretifyBigNum(uniqueInstagram?.uniqueFollowers) || 0} ${
                    SOCIAL_PLATFORMS_NAMES.instagram.prettyName
                  }` || 0}
                  {' | '}
                  {`${pretifyBigNum(uniqueTiktok?.uniqueFollowers) || 0} ${
                    SOCIAL_PLATFORMS_NAMES.tiktok.prettyName
                  }` || 0}
                  )
                </Styled.Value>
              )}
            </Styled.FollowersInTotal>
          )}

          {!!totalUniqueReach && (
            <Styled.ExpectedReach>
              <Styled.Label>{labelStrings?.uniqueReach}:</Styled.Label>
              &ensp;
              <Styled.Value>{pretifyBigNum(totalUniqueReach)}</Styled.Value>
              &ensp;
              {uniqueInstagram?.uniqueReach && uniqueTiktok?.uniqueReach && (
                <Styled.Value>
                  (
                  {`${pretifyBigNum(uniqueInstagram?.uniqueReach) || 0} ${
                    SOCIAL_PLATFORMS_NAMES.instagram.prettyName
                  }`}
                  {' | '}
                  {`${pretifyBigNum(uniqueTiktok?.uniqueReach) || 0} ${
                    SOCIAL_PLATFORMS_NAMES.tiktok.prettyName
                  }`}
                  )
                </Styled.Value>
              )}
            </Styled.ExpectedReach>
          )}
        </Styled.Row>
      </Styled.AudienceOverlapInfo>
      <Styled.AudienceAnalysisChartsWrapper>
        <AudienceAnalysisCharts
          audienceAnalysis={audienceReport}
          platform={USER_INDEXES.instagram}
          followersNumber={totalFollowers}
          isDisabledAnalysis={false}
          hasSmartMediaValue={false}
          hasAudienceTypeAndMentionsCharts={false}
        />
      </Styled.AudienceAnalysisChartsWrapper>
    </Styled.Container>
  )
}

CollectionAudience.propTypes = {
  unlockAudienceReports: PropTypes.func,
}

export default withReduxActions({ unlockAudienceReports })(CollectionAudience)
