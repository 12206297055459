import availableLangs from './availableLangs'
export default {
  [availableLangs.en]: {
    all: 'All',
    german: 'German',
    english: 'English',
    spanish: 'Spanish',
    french: 'French',
    portuguese: 'Portuguese',
    italian: 'Italian',
    danish: 'Danish',
    russian: 'Russian',
    latvian: 'Latvian',
    czech: 'Czech',
    polish: 'Polish',
    arabic: 'Arabic',
    thai: 'Thai',
    bulgarian: 'Bulgarian',
    dutch: 'Dutch',
    finnish: 'Finnish',
    japanese: 'Japanese',
    hungarian: 'Hungarian',
    norwegian: 'Norwegian',
    greek: 'Greek',
    romanian: 'Romanian',
    turkish: 'Turkish',
    hebrew: 'Hebrew',
    ligurian: 'Ligurian',
    chinese: 'Chinese',
    hindi: 'Hindi',
    korean: 'Korean',
    vietnamese: 'Vietnamese',
    swedish: 'Swedish',
    indonesian: 'Indonesian',
    malay: 'Malay',
    persian: 'Persian',
    swahili: 'Swahili',
    ukrainian: 'Ukrainian',
    serbian: 'Serbian',
    bengali: 'Bengali',
    punjabi: 'Punjabi',
    telugu: 'Telugu',
    marathi: 'Marathi',
    tamil: 'Tamil',
    urdu: 'Urdu',
    gujarati: 'Gujarati',
    malayalam: 'Malayalam',
  },
  [availableLangs.de]: {
    all: 'alle',
    german: 'deutsch',
    english: 'englisch',
    spanish: 'spanisch',
    french: 'französisch',
    portuguese: 'portugiesisch',
    italian: 'italienisch',
    danish: 'dänisch',
    russian: 'russisch',
    latvian: 'lettisch',
    czech: 'tschechisch',
    polish: 'polnisch',
    arabic: 'arabisch',
    bulgarian: 'bulgarisch',
    dutch: 'niederländisch',
    finnish: 'finnisch',
    japanese: 'japanisch',
    hungarian: 'ungarisch',
    norwegian: 'norwegisch',
    greek: 'griechisch',
    romanian: 'rumänisch',
    turkish: 'türkisch',
    hebrew: 'hebräisch',
    ligurian: 'ligurisch',
    thai: 'thailändisch',
    chinese: 'Chinesisch',
    hindi: 'hindi',
    korean: 'koreanisch',
    vietnamese: 'vietnamesisch',
    swedish: 'schwedisch',
    indonesian: 'indonesisch',
    malay: 'malaiisch',
    persian: 'persisch',
    swahili: 'swahili',
    ukrainian: 'ukrainisch',
    serbian: 'serbisch',
    bengali: 'bengalisch',
    punjabi: 'pandschabi',
    telugu: 'telugu',
    marathi: 'marathi',
    tamil: 'tamil',
    urdu: 'urdu',
    gujarati: 'gujarati',
    malayalam: 'malayalam',
  },
}
