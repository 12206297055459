import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { withReduxActions } from '../../../hocs/withRedux'
import { limitTrailAudienceTokensAccess } from '../../../redux/ducks/appSettingsDucks'
import { userAudienceTokensSelector, userOneTimeTokensSelector } from '../../../redux/selectors'
import useTranslation from '../../../localization/useTranslation'
import TokensRemainingStat from './TokensRemainingStat'

const AudienceReportsTokensStat = ({ isTrial = false, limitTrailAudienceTokensAccess }) => {
  const { labelStrings } = useTranslation()
  const {
    audienceTokensMonthly,
    audienceTokensUsed,
    tokensResetDate,
    audienceTokensTotal,
  } = useSelector(userAudienceTokensSelector)
  const oneTimeTokens = useSelector(userOneTimeTokensSelector)

  const TrialComponent = useMemo(
    () => (
      <>
        <div>{labelStrings.audienceTokensTrialLimit}</div>
        <div>{labelStrings.pleaseContactSupport}</div>
        {/*<div>
          <span className="click_trial_activate" onClick={limitTrailAudienceTokensAccess}>
            {labelStrings.clickHere}
          </span>
          &nbsp;
          {labelStrings.toActivateTrialForAudienceTokens}

        </div>*/}
      </>
    ),
    [isTrial]
  )

  const tokensLimit = oneTimeTokens.reduce((acc, token) => {
    return token.extraTokens + acc
  }, audienceTokensMonthly)
  const tokensRemain = audienceTokensTotal - audienceTokensUsed
  const showTrialTip = tokensRemain <= 0 && isTrial
  return (
    <TokensRemainingStat
      tokensName={labelStrings.audienceReportTokens}
      tokensLimit={audienceTokensTotal}
      audienceTokensMonthly={audienceTokensMonthly}
      currentTokens={audienceTokensUsed}
      tokensRemain={tokensRemain}
      tokensResetDate={tokensResetDate}
      {...(showTrialTip && { TrialComponent })}
    />
  )
}

AudienceReportsTokensStat.propTypes = {
  isTrial: PropTypes.bool,
  limitTrailAudienceTokensAccess: PropTypes.func,
}

export default withReduxActions({ limitTrailAudienceTokensAccess })(AudienceReportsTokensStat)
