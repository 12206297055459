import { SESSION_STORAGE_NAMES } from '../constants/sessionStorageNames'

class SessionCacheService {
  async getCachedCountriesCities() {
    const cached = window.sessionStorage.getItem(SESSION_STORAGE_NAMES.cachedCountriesCities)
    return cached ? await JSON.parse(cached) : null
  }

  setCachedCountriesCities(countriesCities) {
    window.sessionStorage.setItem(
      SESSION_STORAGE_NAMES.cachedCountriesCities,
      JSON.stringify(countriesCities)
    )
  }
  async getCachedSuggestions() {
    const parsed = await JSON.parse(
      window.sessionStorage.getItem(SESSION_STORAGE_NAMES.cachedSuggesstions)
    )
    if (parsed) {
      const { query = {}, suggestionsKeywords = [] } = parsed
      for (const key in query) {
        if (query[key] === 'Infinity') query[key] = Infinity
      }
      return { query, suggestionsKeywords }
    }
    return parsed
  }

  setCachedSuggestions({ query, suggestionsKeywords }) {
    for (const key in query) {
      if (query[key] === Infinity) query[key] = 'Infinity'
    }
    window.sessionStorage.setItem(
      SESSION_STORAGE_NAMES.cachedSuggesstions,
      JSON.stringify({ query, suggestionsKeywords })
    )
  }

  setCachedSocialPlatform(platformName) {
    window.sessionStorage.setItem(
      SESSION_STORAGE_NAMES.cachedPlatform,
      JSON.stringify({ name: platformName })
    )
  }

  getCachedSocialPlatform() {
    const platform = JSON.parse(window.sessionStorage.getItem(SESSION_STORAGE_NAMES.cachedPlatform))
    return platform ? platform.name : ''
  }

  resetCachedSocialPlatform() {
    window.sessionStorage.removeItem(SESSION_STORAGE_NAMES.cachedPlatform)
  }

  resetSearchCache() {
    window.sessionStorage.removeItem(SESSION_STORAGE_NAMES.cachedSuggesstions)
  }

  setComparedProfiles({
    profiles = null,
    platform = '',
    sortKey = '',
    sortDir = '',
    ranks = null,
  } = {}) {
    const fromStorage = this.getComparedProfiles()

    window.sessionStorage.setItem(
      SESSION_STORAGE_NAMES.comparedProfiles,
      JSON.stringify({
        profiles: profiles || fromStorage.profiles,
        platform: platform || fromStorage.platform,
        sortKey: sortKey || fromStorage.sortKey,
        sortDir: sortDir || fromStorage.sortDir,
        ranks: ranks || fromStorage.ranks,
      })
    )
  }

  getComparedProfiles() {
    const fromSession = window.sessionStorage.getItem(SESSION_STORAGE_NAMES.comparedProfiles)
    const comparedProfiles = fromSession ? JSON.parse(fromSession) : null
    return comparedProfiles || {}
  }

  removeComparedProfiles() {
    window.sessionStorage.removeItem(SESSION_STORAGE_NAMES.comparedProfiles)
  }

  // not used for now
  // setLastOpenedCollectionId(collectionId) {
  //   window.sessionStorage.setItem('lastCollection', collectionId)
  // }
}

export default new SessionCacheService()
