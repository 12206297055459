import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { ProfilePageWrapper } from '../components/common/styledWrappers'
import SettingsContainer from '../components/appUserSettings/SettingsContainer'
import Footer from '../components/Footer'

const PageWrapper = styled(ProfilePageWrapper).attrs(props => ({
  id: props.pageId,
}))`
  margin: 0;
  padding-left: 0;
  padding-top: 5px;
  overflow: hidden scroll;
  ${props => props.theme.withScroll};
  @media screen and (max-width: 990px) {
    height: ${window.innerWidth}px;
    overflow-y: auto;
    position: relative;
    footer {
      display: none;
    }
  }
`

const SettingsPage = () => {
  const pageId = 'settings_page'

  return (
    <PageWrapper
      // @ts-ignore
      pageId={pageId}
    >
      <Helmet>
        <script
          src="https://js.chargebee.com/v2/chargebee.js"
          data-cb-site={process.env.CHARGEBEE_SITE}
        ></script>
      </Helmet>
      <SettingsContainer
        // @ts-ignore
        pageId={pageId}
      />
      <Footer/>
    </PageWrapper>
  )
}

export default SettingsPage
