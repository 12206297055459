import ReactSelect from 'react-select'
import styled, { css } from 'styled-components'
import { getNavbarHeight } from '../../../utils'
import { NAVBAR_FOOTER_SUM_HEIGHT, SIDEBAR_WIDTH } from '../../../constants/appSettings'
import ArrowChecked from '../../../images/CheckedArrow.png'

export const FocusEffect = css`
  &:focus {
    border-color: ${props => props.theme.platformColor};
    box-shadow: 0 0 0 1px ${props => props.theme.platformColor};
    outline: 0;
  }
`

export const StyledCheckbox = styled.input.attrs(props => ({
  type: 'checkbox',
  checked: props.checked,
}))`
  width: 24px;
  height: 24px;
  appearance: none;
  box-sizing: border-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid ${props => props.theme.colors.darkBrown};
  border-radius: 6px;
  cursor: pointer;

  &:checked {
    background-image: ${`url(${ArrowChecked})`};
    background-repeat: no-repeat;
    background-position: center;
    background-color: ${props => props.theme.colors.mauve};
    color: white;
  }
  :focus {
    outline: none;
  }
`

export const Label = styled.label`
  font-size: 11px;
  line-height: 17px;
  font-family: ${props => props.theme.fontFamilyInter};
  text-transform: uppercase;
  letter-spacing: 1.65px;
  color: ${props => props.theme.colors.darkBrown};
`

export const RoundedInput = styled.input`
  border-radius: 100vw;
  border: 1px solid ${props => props.theme.colors.darkBrown};
  padding: 12px;
  width: 100%;

  outline: 0;

  ${props => !props.noFocusEffect && FocusEffect}
  ${props =>
    props.notValid &&
    `
    border-color: ${props.theme.platformColor};
    box-shadow: 0 0 0 1px ${props.theme.platformColor};
    outline: 0;
  `}
`

export const RoundedTextArea = styled.textarea`
  border-radius: 7px;
  border: 1px solid #b4b4b4;
  padding: 12px;
  width: 100%;
  resize: none;
  height: 200px;
  outline: none;
  &:focus {
    outline: none;
  }
  ${props => !props.noFocusEffect && FocusEffect}
`

export const Title = styled.span`
  display: block;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 4.9px;
  text-transform: uppercase;
  padding-left: 12px;
  margin-bottom: 12px;
`

export const DialogWrapper = styled.div`
  padding: 31px;
  max-height: 100vh;
  overflow-x: hidden;
`

export const LinkEffect = css`
  color: ${props => props.theme.textColor};
  &:hover {
    color: ${props => props.theme.primaryColor};
  }
`

export const SidebarContainer = styled.div`
  width: ${SIDEBAR_WIDTH}px;
  max-width: ${SIDEBAR_WIDTH}px;
  padding-top: 26px;
  padding-bottom: 26px;
  background: ${props => props.theme.color.lightGrey} 0% 0% no-repeat padding-box;
  min-height: calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px);
`

export const SidebarScroll = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${SIDEBAR_WIDTH}px;
  max-height: 86vh;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #e0e0e0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c7c7c7;
  }
  scrollbar-width: thin;
  background: ${props => props.theme.color.lightGrey};
`

export const WithScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100% !important;
  max-height: ${props => props.height ? props.height : `calc(100vh - 150px)`};
  min-height: ${props => props.height ? props.height : `calc(100vh - 150px)`};
  ${props => props.showScroll && props.theme.withScroll};
  ${props =>
    props.isSideBar &&
    `
    &::-webkit-scrollbar {
      width: 6px;
      background-color: #BC9797;
      border-radius:16px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #784E4E;
      border-radius:16px;
      border: 2px solid #BC9797;
    }
    &::-webkit-scrollbar-thumb:hover {
      border: 0px solid #f4f4f4
    }
  `};
  background: ${props => props.theme.color.white};
  ${props => props.rightTopRadius && `border-radius: 0 ${props.rightTopRadius} 0 0;`};
  padding-bottom: 50px;
  ${props =>
    props.watchHeight &&
    `
    @media (min-height: 1144px) {
      max-height: unset;
    }
  `}
`

export const SearchbarContent = styled.div`
  width: 92%;
  margin: 0 auto;
  ${props => !props.isActive && props.theme.disabled}
`

export const Layout = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;
  position: relative;
  z-index: 1;
`

export const SearchContainerWrapper = styled.div`
  width: 100%;
  padding: 5px 15px;
  padding-left: 0;
  background: ${props => props.theme.color.white};
  margin-bottom: 12px;
  ${props => !props.isSearchEnabled && props.theme.disabled}
`

export const ClearSearchContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  margin-top: 14px;
`

export const SearchLabel = styled.label`
  font-size: 13px;
  line-height: 16px;
  font-family: ${props => props.theme.fontFamilyInter};
  text-transform: uppercase;
  letter-spacing: 1.65px;
  color: ${props => props.theme.colors.mauve};
  padding-left: 12px;
  position: relative;
`

export const CommentContainer = styled.div`
  margin-top: 24px;
  margin-right: calc(24px + 34px);
  margin-left: 50px;
  padding: 0px;
  ${props => props.isNarrow && `width: 30%;`}
`

export const RoundedSelect = styled(ReactSelect)`
  .creatable-multi-select__control {
    cursor: pointer;
    border-radius: 7px;
    font-size: 14px;
    border-color: ${props => props.theme.colors.darkBrown};
    ${props => props.isRounded && `border-radius: 25px;`}
    &:hover {
      border-color: ${props => props.theme.colors.darkBrown};
    }
  }

  .creatable-multi-select__control--is-focused {
    border-color: ${props => props.theme.platformColorLight} !important;
    box-shadow: 0 0 0 1px ${props => props.theme.platformColor} !important;
  }

  .creatable-multi-select__value-container {
    padding-left: 12px;
    line-height: initial;
    div {
      display: flex;
      align-items: center;
    }
  }

  .creatable-multi-select__menu {
    z-index: 1000;
    font-size: 14px;

    .creatable-multi-select__menu-list {
      @media (max-height: 900px) {
        ${props =>
          props.shortened &&
          ` 
          height: 170px;
          overflow-y: auto;`}
      }
    }
    .creatable-multi-select__option--is-focused {
      background-color: ${props => props.theme.platformColorLight};
      color: #ffffff;
    }
    .creatable-multi-select__option--is-selected {
      background-color: ${props => props.theme.platformColor};
      color: #ffffff;
    }

    .creatable-multi-select__multi-value__label {
    }
  }
  .creatable-multi-select__placeholder {
    font-size: ${props => props.placeholderFontSize || 16}px;
    color: ${props => props.theme.colors.darkBrown};
  }
  .creatable-multi-select__dropdown-indicator {
    color: ${props => props.theme.colors.darkBrown};
  }
`
export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${SIDEBAR_WIDTH}px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #e0e0e0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c7c7c7;
  }
  scrollbar-width: thin;
`

export const MainContainer = styled.div`
  position: relative;
  width: 97%;
  height: 100%;
  min-height: calc(100vh - ${getNavbarHeight()}px);
  padding: 0 20px;
  ${props => props.isCollections && 'padding-left: 0;'}
  overflow-x: hidden;
`

export const ProfilePageWrapper = styled(MainContainer)`
  padding-top: 30px;
  position: relative;
  width: 100%;
  padding-right: 0;
  height: calc(100vh - ${getNavbarHeight()}px);
  min-height: calc(100vh - ${getNavbarHeight()}px);
`

export const NewProfilePageWrapper = styled(ProfilePageWrapper)`
  padding-top: 0;
  padding: 0;
  .profile-container {
    ${'' /* padding-left: 20px; */}
  }
  ${props => props.theme.withScroll};
`

export const ComparePageWrapper = styled(ProfilePageWrapper)`
  padding-top: 0;
  height: 100%;
`

export const ListHeader = styled.div`
  display: flex;
`

export const SearchListHeader = styled(ListHeader)`
  position: relative;
  ${props =>
    props.isShowMap &&
    `
    flex-direction: column; 
  `}
`

export const ListHeaderSection = styled.div`
  display: flex;
  flex-direction: column;
`

export const ToolbarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
`

export const ResultCountWrapper = styled.div`
  font-family: ${props => props.theme.fontFamilyInter};
  font-size: 15px;
  color: ${props => props.theme.colors.mauve};
  font-weight: 700;
`

export const NotFoundContainer = styled.div`
  width: 420px;
  letter-spacing: 1.65px;
  color: #888888;
  margin: auto;
`
export const Content = styled.div`
  min-height: 100%;
`

export const PageContentWrapper = styled.div`
  ${props => props.isLoading && 'pointer-events: none;'}
`

export const Divider = styled.hr`
  height: 0;
  width: ${props => props.width || '100%'};
  margin: ${props => (props.width ? '0 auto' : '0')};
  padding: 0;
  border: 2px solid rgb(255, 255, 255);
`

export const SidebarTopDivider = styled(Divider)`
  border-width: 5px;
`

export const SidebarOuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${props => (props.isNavType === 'min' ? 140 : props.blockWidth || SIDEBAR_WIDTH)}px;
  width: ${props => (props.isNavType === 'min' ? 140 : props.blockWidth || SIDEBAR_WIDTH)}px;
  position: sticky;
  top: 0;
  height: 100%;

  @media (min-height: 1000px) {
    height: 100vh;
  }
  @media (max-width: 1100px) {
    height: ${window ? window.innerWidth : 500}vh;
  }
`
export const SidebarMenuLinesSection = styled.div`
  position: absolute;
  height: 200px;
  width: 40px;
  background: ${props => props.theme.color.midGrey};
  border-radius: 10px 10px 0px 0px;
  right: 0;
  z-index: 1;
  top: 20px;
`

export const CenterWrapper = styled.div`
  height: 100%;
  min-height: calc(100vh - ${getNavbarHeight()}px);
  ${props => props.theme.flex.centerAll}
`

export const EmailContainer = styled.div`
  position: relative;
  width: auto;

  input {
    color: ${props => props.theme.textColor};
    font-size: 12px;

    ${FocusEffect}

    border: 1px solid #b4b4b4;
    padding: 4px;
    padding-right: 25px;
    border-radius: 3px;
    cursor: auto;

    outline: 0;

    &:focus {
      border-color: ${props => props.theme.platformColor} !important;
      box-shadow: 0 0 0 1px ${props => props.theme.platformColor} !important;
    }
  }

  svg {
    position: absolute;
    right: 7px;
    top: 2px;
    height: 25px;
    width: 25px;
    max-width: 12px;
    cursor: pointer;

    path {
      fill: #b7b7b7;
    }

    &:hover {
      fill: #a8a8a8;
    }
  }

  &:hover {
    svg {
      path {
        fill: #919191;
      }
    }
  }
`

export const BubbleBlock = styled.span`
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0;
  color: #ffffff;
  border-radius: 9px;
  padding: 3px 9px;
  margin-left: ${props => (props.isNavType === 'min' ? 0 : 9)}px;
  ${props =>
    props.withLightStyle
      ? `
        background: ${props.theme.color.white};
        color: ${props.theme.primaryColor}; 
      `
      : `
        background: ${
          props.withPrimaryBG ? props.theme.primaryColorLighter : props.theme.colors.mauve
        };
      `}
`

export const WithPopupWrapper = styled.div`
  .popup-content {
    ${props => props.enableHeader && `width: unset !important;`}
    ${props => props.width && `width: ${props.width} !important;`}
    ${props => props.isRounded && `border-radius: 9px;`}
    padding: 0 !important;
    ${props =>
      props.isTransparent &&
      `
     background: transparent !important;
     border: none !important;
    `}
  }
  .popup-overlay {
    // overwrite default styles of popup lib to cover only content without sidebar, footer and header
    z-index: ${props => (props.aboveAll ? 5000 : 200)} !important;
    animation-name: modalTransition;
    animation-delay: 0s;
    animation-duration: ${props => (props.withBlurBG ? 0.05 : 0.5)}s;
    ${props =>
      props.enableHeader &&
      `top: ${props.top ? 0 : getNavbarHeight() + (props.popupHeightOffset || 0)}px !important;`}
    ${props =>
      props.withBlurBG &&
      `
      background: #FFF1EF33 !important;

    `}

    ${props =>
      !props.fullScreen &&
      `
    top: ${getNavbarHeight() + (props.popupHeightOffset || 0)}px !important;
    bottom: 0 !important;
    left: 0 !important;
    `}

    ${props => props.enableFooter && `bottom: 55px !important;`}

    &::before {
      content: '';
      position: absolute;
      height: ${props => props.bgHeight || 100}%;
      width: 100%;
      ${props => props.bgStickToBottom && `bottom: 0;`}
      ${props =>
        props.withBlurBG
          ? `
        backdrop-filter: blur(16px);
        -webkit-backdrop-filter: blur(16px);
      `
          : `
        background: ${props.theme.popupGradientBackground};
        opacity: 0.8;
      `}
    }
  }
`
export const CloseWrapper = styled.div`
  position: absolute;
  top: 32px;
  right: 36px;
  cursor: pointer;
`

export const ProfilePageLink = styled.a`
  font-weight: regular;
  cursor: pointer;
  font-size: 15px;
  line-height: 20px;
  font-family: ${props => props.theme.fontFamilyRoboto};
  letter-spacing: 0;
  color: ${props => props.theme.color.darkGrey};
  text-decoration: underline;
  margin: 0 10px;
  &:hover {
    color: ${props => props.theme.pickColorDependOnPlatform(props.userindex).primary};
  }
`

export const ProfileSectionCommonHeader = styled.div`
  align-self: self-start;
  position: relative;
  display: flex;
  align-items: center;
`

export const TTStatsLabel = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 4.9px;
  color: ${props => props.theme.pickColorDependOnPlatform(props.userindex).primary};
  font-family: ${props => props.theme.fontFamilyInter};
  text-transform: uppercase;
`

export const ListWrapper = styled.div`
  padding-bottom: ${props => (props.hasPagination ? '0' : '30px')};
`

export const InfoIconWrapper = styled.div`
  position: absolute;
  top: -9px;
  right: 10px;
  cursor: pointer;
  & > svg {
    stroke: ${props => props.theme.color.grey};
  }
`
export const DialogTitle = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 4.9px;
  text-transform: ${props => (props.noUpperCase ? 'none' : 'uppercase')};
  text-align: center;
`

export const EmptyListMessagesWrapper = styled.div`
  width: fit-content;
  margin: 0 auto;
  ${props => props.theme.flex.centerAll};
  flex-direction: column;
`
export const TipUnderlineTextWrapper = styled.span`
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

export const OptionalTextWrapper = styled.span`
  font-family: ${props => props.theme.fontFamilyInter};
  font-size: 11px;
  color: #9b9b9b;
  text-transform: none;
  letter-spacing: 0px;
`

export const NotificationMark = styled.div`
  position: absolute;
  height: 10px;
  width: 10px;
  background: ${props => props.theme.pickColorDependOnPlatform(props.userindex).primary};
  border-radius: 50%;
  top: 33%;
  left: -10px;
`
export const VerticalDivider = styled.div`
  height: 60px;
  width: 6px;
  background: ${props => props.theme.color.white};
`

export const SearchSortingWrapper = styled.div`
  height: ${props => !props.isContent && '50px'};
`
export const TipHoveredText = styled.div`
  width: 255px;
  font-size: 13px;
  font-family: ${props => props.theme.fontFamilyInter};
  color: ${props => props.theme.color.mineShaft};
  text-align: left;
`

export const ContactUs = styled.span`
  color: ${props => props.theme.primaryColor};
  cursor: pointer;
  text-decoration: underline;
`
export const TipIconWrapper = styled.div`
  width: fit-content;
  cursor: pointer;
  padding-bottom: 2px;
  svg {
    height: 15px;
    width: 15px;
    opacity: 0.6;
  }
`
export const SectionsDivider = styled.div`
  height: 0;
  border: 0.5px solid #9b9b9b44;
  margin: 0 auto;
  width: ${props => props.width || '100%'};
`
export const RoundedToolsWrapper = styled.div`
  background-color: ${props => props.theme.colors.lightBrown}
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px;
`

export const ToolContainer = styled.div`
  display: flex;
  justify-content: start;
  width: 85px;
  height: 100%;
  align-items: center;
  flex-wrap: wrap;

  ${props => props.isComparePage && `width: 100%`}

  ${props => props.isSearchPage && `width: 120px;`}

  ${props => props.isBlackListCollection && `padding-left: 10px;`}

  ${props =>
    props.biggerIcons &&
    `
  & svg {
    height: 20px;
    width: 20px;
  }
`}
  ${props =>
    !props.isSelectedProfileView &&
    `
  flex-wrap: wrap;
  align-items: center;
`}
`
export const ClickHere = styled.span`
  color: ${props => props.theme.pickColorDependOnPlatform(props.userindex).primary};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`

export const SortingComparedProfilesWrapper = styled.div`
  width: 100%;
  position: relative;
  & .sorting_elements_container {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-family: ${props => props.theme.fontFamilyInter};
  }
  & .sorting_label_container {
    max-width: 100%;
    min-width: 70px;
    text-align: left;
    font-family: ${props => props.theme.fontFamilyInter};
  }
  & .sorting_select_container {
    width: 100%;
    font-family: ${props => props.theme.fontFamilyInter};
  }
  & .sorting_directions_container {
    margin-left: 10px;
  }
`

export const Upgrade = styled.span`
  text-transform: ${props => (props.noLowerCase ? 'unset' : 'lowercase')};
  text-decoration: underline;
  color: ${props => props.theme.pickColorDependOnPlatform(props.userindex).primary};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`
export const SettingsContainerWrapper = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  padding-bottom: 50px;
`
export const CollectionAccessButtonWrapper = styled.div`
  margin: 0 10px;
  & svg {
    fill: ${props => props.theme.primaryColor};
    ${props =>
      props.isPublic &&
      `
    margin: 0 0 3px 0;
  `}
  }
`
