import styled from 'styled-components'

export const StyledLinkToFaq = styled.a`
  font-size: 13px;
  line-height: 16px;
  color: ${props => props.theme.colors.brown};
  text-decoration: underline;
  margin-bottom: 10px;

  &:hover {
    color: ${props => props.theme.colors.brown};
  }
`
