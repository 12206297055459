import React from 'react'
import { useSelector } from 'react-redux'
import { currentOpenedCampaignSelector } from '../../../../redux/selectors'
import FormProvider from '../../../components/hook-form/FormProvider'
import { Grid, Box, Stack, Typography, MenuItem, Button } from '@mui/material'
import { RHFTextField, RHFRadioGroup, RHFSwitch, RHFSelect } from '../../../components/hook-form'
import { StyledHeading, StyledSubheader } from '../styles'
import { StyledButton } from './styles'
import useTranslation from '../../../../localization/useTranslation'

export const Fields = props => {
  const { labelStrings } = useTranslation()
  const { customFields, defaultCustomFields } = useSelector(currentOpenedCampaignSelector)
  const {
    methods,
    onSubmit,
    handleSubmit,
    handleClose,
    editGoal,
    selectedGoal,
    setSelectedGoal,
    watchValue,
  } = props

  const goalLevelFieldValue = watchValue('goalLevel')
  const goalDataFormatValue = selectedGoal?.dataFormat
  const goalApplyLevel = selectedGoal?.applyLevel

  const isDefaultCustomFields = defaultCustomFields?.length ? defaultCustomFields : []
  const isCustomFields = customFields?.length ? customFields : []

  const mergeCustomFields = [...isDefaultCustomFields, ...isCustomFields]?.map(customField => {
    return {
      label: customField.customFieldName,
      value: customField.customFieldId,
      ...customField,
    }
  })

  const onClose = () => {
    setSelectedGoal(null)
    handleClose()
  }

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} sx={{ marginTop: -2 }}>
          <Grid item md={12}>
            <RHFTextField
              name="goalName"
              label={labelStrings.campaignsGoal.goalTitle}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item md={12}>
            <RHFSwitch
              name="isPaused"
              label={labelStrings.campaignsGoal.isPaused}
              helperText=""
              defaultValue={false}
            />
          </Grid>

          <Grid item md={12}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFSelect
                name="connectedCustomField"
                label={labelStrings.campaignsGoal.selectAGoal}
                InputLabelProps={{ shrink: true }}
                rules={{ required: true }}
              >
                {mergeCustomFields?.map(option => (
                  <MenuItem
                    key={option.customFieldId}
                    value={option.value}
                    onClick={() => setSelectedGoal(option)}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </RHFSelect>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <RHFTextField
                  name="goalAmount"
                  label={labelStrings.campaignsGoal.whenIsThisGoalReached}
                  inputType={'number'}
                  inputTypeMask={goalDataFormatValue === 'integer' ? 'integer' : 'float'}
                  rules={{ required: true }}
                />
                <Typography sx={{ marginLeft: 3 }}>
                  {selectedGoal?.metricName
                    ? selectedGoal?.metricName
                    : editGoal?.metricLabel
                    ? editGoal?.metricLabel
                    : labelStrings.campaignsGoal.metricLabel}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={12}>
            <Box sx={{ marginTop: 0 }}>
              <Box>
                <StyledHeading sx={{ marginBottom: '3px' }}>
                  {labelStrings.campaignsGoal.whichLevel}
                </StyledHeading>
                <StyledSubheader>{labelStrings.campaignsGoal.levelCant}</StyledSubheader>

                <RHFRadioGroup
                  row
                  name="goalLevel"
                  label=""
                  spacing={3}
                  sx={{ marginLeft: 1 }}
                  rules={{ required: true }}
                  options={[
                    {
                      value: 'content',
                      label: labelStrings.campaignsGoal.perContentPiece,
                      disabled: goalApplyLevel === 'creator' || goalApplyLevel === 'campaign',
                    },
                    {
                      value: 'creator',
                      label: labelStrings.campaignsGoal.perInfluencer,
                      disabled: goalApplyLevel === 'campaign',
                    },
                    {
                      value: 'campaign',
                      label: labelStrings.campaignsGoal.perCampaign,
                      disabled: false,
                    },
                  ]}
                />
              </Box>

              <Box sx={{ marginTop: 3 }}>
                <StyledHeading sx={{ marginBottom: '3px' }}>
                  {labelStrings.campaignsGoal.timePeriod}
                </StyledHeading>
                <StyledSubheader>{labelStrings.campaignsGoal.setQuarterly}</StyledSubheader>

                <RHFRadioGroup
                  row
                  name="goalInterval"
                  label=""
                  spacing={3}
                  sx={{ marginLeft: 1 }}
                  rules={{ required: true }}
                  options={[
                    { value: 'campaign', label: labelStrings.campaignsGoal.fullCampaign },
                    { value: 'weekly', label: labelStrings.campaignsGoal.perWeek },
                    { value: 'monthly', label: labelStrings.campaignsGoal.perMonth },
                    { value: 'quarterly', label: labelStrings.campaignsGoal.perQuarter },
                  ]}
                />
              </Box>

              <Box sx={{ marginTop: 3 }}>
                <StyledHeading sx={{ marginBottom: '3px' }}>
                  {labelStrings.campaignsGoal.customGoal}
                </StyledHeading>
                <StyledSubheader>
                  {labelStrings.campaignsGoal.whenAnAddingInfluencer}
                </StyledSubheader>
                <RHFRadioGroup
                  row
                  name="isCreatorIndividual"
                  label=""
                  spacing={3}
                  sx={{ marginLeft: 1 }}
                  tooltipTitle={labelStrings.campaignsGoal.customGoalForEachInfluencerTooltipTitle}
                  options={[
                    {
                      value: true,
                      label: labelStrings.yes,
                      disabled: goalLevelFieldValue !== 'creator',
                    },
                    {
                      value: false,
                      label: labelStrings.no,
                      disabled: goalLevelFieldValue !== 'creator',
                    },
                  ]}
                />
              </Box>
            </Box>
            <Stack
              direction="row"
              spacing={1.5}
              alignItems="flex-center"
              justifyContent="flex-end"
              sx={{ my: 2 }}
            >
              <StyledButton variant="outlined" sx={{ fontWeight: 'bold' }} onClick={onClose}>
                {labelStrings.cancel}
              </StyledButton>

              <Button type="submit" variant="contained">
                {editGoal?.goalId
                  ? labelStrings.campaignsGoal.editGoal
                  : labelStrings.campaignsGoal.addGoal}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  )
}
