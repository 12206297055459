import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin: 15px 0;
  border-radius: 13px;
  overflow: hidden;
  background-color: ${props => props.theme.colors.offWhite};
`

export const AudienceOverlapInfo = styled.div`
  padding: 20px 35px;
  border-bottom: 1px solid ${props => props.theme.colors.lightBrown};

  @media screen and (max-width: 1300px) {
    padding: 20px;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  & + & {
    margin-top: 10px;
  }
`

export const TotalInfluencers = styled.div`
  margin-right: 40px;
  display: inline-flex;
  align-items: center;
`

export const UnlockedInfluencers = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 15px;
  border-radius: 26px;
  background-color: ${props => props.theme.colors.lightBrown};
  white-space: nowrap;
`

export const IconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
`

export const UnlockedInfluencersText = styled.span`
  font-size: 13px;
  line-height: 16px;
  color: ${props => props.theme.colors.brown};
`

export const UnlockAllButton = styled.button`
  display: inline;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
  color: ${props => props.theme.colors.salmon};
  text-decoration: underline;
`

export const Label = styled.span`
  white-space: nowrap;
  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
  color: ${props => props.theme.colors.mauve};
`

export const Value = styled(Label)`
  white-space: nowrap;
  color: ${props => props.theme.colors.brown};
`

export const FollowersInTotal = styled.div`
  margin-right: 60px;
  display: inline-flex;
  align-items: center;
`

export const ExpectedReach = styled.div`
  display: inline-flex;
  align-items: center;
`

export const AudienceAnalysisChartsWrapper = styled.div`
  .audience-analysis-charts_grid-item {
    padding: 40px !important;
  }

  .horizontal-bar-chat_label {
    font-size: 12px;
    line-height: 15px;
  }

  @media screen and (max-width: 1300px) {
    .audience-analysis-charts_grid-item {
      padding: 20px !important;
    }
  }

  @media screen and (max-width: 1200px) {
    .audience-analysis-charts_grid {
      grid-template-columns: repeat(1, 1fr);
    }

    .audience-analysis-charts_grid-item {
      border: none;

      &:not(:last-child) {
        border-bottom: 1px solid ${props => props.theme.colors.lightBrown};
      }

      & > div {
        max-width: 60%;
      }
    }
  }
`
