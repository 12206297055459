export const transformDataToTableHead = data => {
  if (!data?.length) return []

  return data[0]?.slice(1)?.map(el => {
    return {
      id: el,
      label: el,
      align: 'center',
    }
  })
}