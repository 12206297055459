import React from 'react'
import { Box } from '@mui/material'
import { MainSection } from '../../sections/data-enrichment'

export const DataEnrichment = () => {
  return (
    <Box sx={{ px: 3 }}>
      <MainSection />
    </Box>
  )
}
