import { encodeBtoa } from '../utils'
import {
  CAMPAIGNS_SORTING_TYPES,
  COLLECTIONS_SORTING_TYPES,
  SORTING_DIRECTIONS,
} from '../constants/sortings'
import { SHOW_HITS_NAME } from '../constants/filters'

class LocalStorageService {
  constructor() {
    this.storage = null
    if (this.checkIfLocalStorageExists()) {
      this.useLocalStorage()
    }
  }

  checkIfLocalStorageExists() {
    return !!window && !!window.localStorage
  }

  useLocalStorage() {
    this.storage = window.localStorage
  }

  _preExecArgCheckAction(argToCheck, func) {
    if (!argToCheck || !this.storage) return null
    return func()
  }

  getCampaignSorting(campaignId = '') {
    return this._preExecArgCheckAction(campaignId, () => {
      const sortTypeStr = this.storage.getItem(encodeBtoa(campaignId))
      if (!sortTypeStr) return null
      const [sortType, sortDirection] = sortTypeStr.split(' ')
      return { sortType: +sortType, sortDirection }
    })
  }

  setCampaignSorting(
    campaignId = '',
    sortType = CAMPAIGNS_SORTING_TYPES.trackingStart.type,
    sortDirection = SORTING_DIRECTIONS.descend
  ) {
    return this._preExecArgCheckAction(campaignId, () => {
      this.storage.setItem(encodeBtoa(campaignId), `${sortType} ${sortDirection}`)
    })
  }

  removeCampaignSorting(campaignId = '') {
    return this._preExecArgCheckAction(campaignId, () => {
      this.storage.removeItem(encodeBtoa(campaignId))
    })
  }

  setCampaignFilters(campaignId = '', newFilters = {}) {
    return this._preExecArgCheckAction(campaignId, () => {
      const storageKey = `${SHOW_HITS_NAME}_${encodeBtoa(campaignId)}`
      const { showOnlyHits } = newFilters // if more filters appear in future, desctuct them and add to storage string
      this.storage.setItem(storageKey, `${showOnlyHits}`)
    })
  }

  getCampaignFilters(campaignId = '') {
    return this._preExecArgCheckAction(campaignId, () => {
      const storageKey = `${SHOW_HITS_NAME}_${encodeBtoa(campaignId)}`
      const filters = this.storage.getItem(storageKey)
      if (!filters) return null
      const [showOnlyHits] = filters.split(' ') // if more filters appear in future, desctuct from string and return within object
      return { showOnlyHits }
    })
  }

  removeCampaignFilters(campaignId = '') {
    return this._preExecArgCheckAction(campaignId, () => {
      const storageKey = `${SHOW_HITS_NAME}_${encodeBtoa(campaignId)}`
      this.storage.removeItem(storageKey)
    })
  }

  removeCampaignRelatedData(campaignId = '') {
    this.removeCampaignSorting(campaignId)
    this.removeCampaignFilters(campaignId)
  }

  getCollectionSorting(collectionId = '') {
    return this._preExecArgCheckAction(collectionId, () => {
      const sortTypeStr = this.storage.getItem(encodeBtoa(collectionId))
      if (!sortTypeStr) return null
      const [sortType, sortDirection] = sortTypeStr.split(' ')
      return { sortType: +sortType, sortDirection }
    })
  }

  setCollectionSorting(
    collectionId = '',
    sortType = COLLECTIONS_SORTING_TYPES.position.type,
    sortDirection = SORTING_DIRECTIONS.descend
  ) {
    return this._preExecArgCheckAction(collectionId, () => {
      this.storage.setItem(encodeBtoa(collectionId), `${sortType} ${sortDirection}`)
    })
  }

  removeCollectionSorting(collectionId = '') {
    return this._preExecArgCheckAction(collectionId, () => {
      this.storage.removeItem(encodeBtoa(collectionId))
    })
  }
}

const localStorageService = new LocalStorageService()

export default localStorageService
