import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../../localization/useTranslation'

import {
  rowsListFactory,
  rowsListFactoryTikTok,
  rowsListFactoryYouTube,
} from './comparedRowFactories/comparedDataFactories'
import { comparedRowFactory } from './comparedRowFactories/comparedComponentsFactories'

import { COMPARED_LOADING } from '../../../../constants/comparedProfiles'

import {
  ComparedColumnWrapper,
  ComparedNavColumnWrapper,
} from '../../../common/styledWrappers/comparedProfilesStyles'

import ColumnNav from './ColumnNav'
import LoadingSpinner from '../../../common/spinners/LoadingSpinner'
import { SOCIAL_PLATFORMS_NAMES } from '../../../../constants/appSettings'
import { Box } from '@mui/material'
import ProfileToolsMediator from '../../../profileCommon/profileTools/ProfileToolsMediator'
import AddToCampaignPipelineModal from '../../../campaigns/campaignTools/OverviewTools/AddToCampaignPipelineModal'

const CompareColumn = ({
  profile = {},
  isMocked = true,
  isNavColumn = false,
  removeComparedProfile,
  resetComparedProfiles,
  socialPlatformIndex = '',
  allComparedAudiencesBlocked = true,
  disabledSorting = true,
  comparedRanks = {},
}) => {
  const { labelStrings, scoreStrings } = useTranslation()

  const [isOpenedPipelineModal, setIsOpenPipelineModal] = useState(false)

  const stopEventBubbling = e => e.stopPropagation()

  const rowsList = useMemo(
    () =>
      rowsListFactory({
        labelStrings,
        profile,
        isNavColumn,
        scoreStrings: scoreStrings.instagram,
        allComparedAudiencesBlocked,
        comparedRanks,
        socialPlatformIndex,
      }),
    [
      labelStrings,
      profile,
      isNavColumn,
      allComparedAudiencesBlocked,
      comparedRanks,
      socialPlatformIndex,
    ]
  )

  const rowsListTikTok = useMemo(
    () =>
      rowsListFactoryTikTok({
        labelStrings,
        profile,
        isNavColumn,
        socialPlatformIndex: socialPlatformIndex,
        allComparedAudiencesBlocked,
        comparedRanks,
      }),
    [
      labelStrings,
      profile,
      isNavColumn,
      allComparedAudiencesBlocked,
      comparedRanks,
      socialPlatformIndex,
    ]
  )

  const rowsListYouTube = useMemo(
    () =>
      rowsListFactoryYouTube({
        labelStrings,
        profile,
        isNavColumn,
        scoreStrings: scoreStrings.youtube,
        socialPlatformIndex: socialPlatformIndex,
        allComparedAudiencesBlocked,
        comparedRanks,
      }),
    [
      labelStrings,
      profile,
      isNavColumn,
      allComparedAudiencesBlocked,
      comparedRanks,
      socialPlatformIndex,
    ]
  )

  const getPlatformsRows = {
    [SOCIAL_PLATFORMS_NAMES.instagram.index]: rowsList,
    [SOCIAL_PLATFORMS_NAMES.tiktok.index]: rowsListTikTok,
    [SOCIAL_PLATFORMS_NAMES.youtube.index]: rowsListYouTube,
  }

  const platformRows = getPlatformsRows[socialPlatformIndex]
  const isProfileLoading = !!profile[COMPARED_LOADING]

  const MainWrapper = isNavColumn ? ComparedNavColumnWrapper : ComparedColumnWrapper
  return (
    <>
      <MainWrapper>
        {isProfileLoading && (
          <LoadingSpinner
            isTransparent
            spinnerType="spin"
            spinnerHeight={50}
            spinnerWidth={50}
            width="100%"
            height="100%"
          />
        )}
        <ColumnNav
          isNavColumn={isNavColumn}
          isMocked={isMocked}
          socialPlatformIndex={socialPlatformIndex}
          removeComparedProfile={removeComparedProfile}
          resetComparedProfiles={resetComparedProfiles}
          profile={profile}
          disabledSorting={disabledSorting}
        />

        {profile?._id ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ProfileToolsMediator
              user={profile}
              isCollectionView={true}
              isSearchView={false}
              stopEventBubbling={stopEventBubbling}
              setIsOpenPipelineModal={() => setIsOpenPipelineModal(true)}
              isComparePage
            />
          </Box>
        ) : (
          <Box sx={{ height: '40px' }}></Box>
        )}

        {platformRows?.map((row, idx) => {
          const withBG = (() => {
            if (isMocked) return false
            if (row.withBG !== undefined) return row.withBG
            return !!idx && idx % 2 !== 0 && !isNavColumn
          })()

          return comparedRowFactory({
            ...row,
            isNav: isNavColumn,
            withBG,
            index: socialPlatformIndex,
            key: row.label || `${row.label}${idx}`,
            isMocked,
          })
        })}
      </MainWrapper>
      <AddToCampaignPipelineModal
        profile={profile}
        platform={profile._index}
        isOpened={isOpenedPipelineModal}
        closeModal={() => setIsOpenPipelineModal(false)}
        disableAutoFocus
        disableEnforceFocus
      />
    </>
  )
}

CompareColumn.propTypes = {
  profile: PropTypes.object,
  isMocked: PropTypes.bool,
  isNavColumn: PropTypes.bool,
  removeComparedProfile: PropTypes.func,
  resetComparedProfiles: PropTypes.func,
  socialPlatformIndex: PropTypes.string,
  allComparedAudiencesBlocked: PropTypes.bool,
  disabledSorting: PropTypes.bool,
  comparedRanks: PropTypes.object,
}

export default CompareColumn
