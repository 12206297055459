import React from 'react'
import PropTypes from 'prop-types'
import {
  Stack,
  TableRow,
  TableCell,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@mui/material'
import { fShortenNumber } from '../../../../utils/formatNumber'
import { socialIcon } from './utils'
import useTranslation from '../../../../../localization/useTranslation'

const typographyStyles = {
  fontWeight: 600,
  fontSize: '0.685rem',
  color: '#FFFFFF',
  maxWidth: '300px',
  wordWrap: 'break-word',
  whiteSpace: 'pre-line',
}

export const CpeRows = ({ row }) => {
  const {
    label,
    maxCreatorValue,
    minCreatorValue,
    missingCreators,
    totalContent,
    totalCpe,
    totalInteractions,
    totalSpend,
  } = row

  const { labelStrings } = useTranslation()

  const isBoldCell = label?.endsWith('_total') || label === 'overall' || label === 'tiktok'
  const isDivider = label?.startsWith('tiktok')

  const titleTooltip = (
    <Stack>
      <Typography sx={typographyStyles}>
        {labelStrings.notAllCreatorsAssignedPricing('CPE')}
      </Typography>
      <Stack>
        <Typography sx={{ ...typographyStyles, marginTop: '2px' }}>
          {labelStrings.missingCreators}:
        </Typography>
        <List dense={true} sx={{ p: 0 }}>
          {missingCreators?.map((missingCreator, idx) => {
            if (idx > 4) return
            return (
              <ListItem key={missingCreator} sx={{ paddingRight: 0, paddingLeft: 0.5, py: 0 }}>
                <ListItemText
                  primary={<Typography sx={typographyStyles}>@{missingCreator}</Typography>}
                />
              </ListItem>
            )
          })}
          {missingCreators?.length > 5 && (
            <ListItem sx={{ py: 0, px: 0.5 }}>
              <ListItemText
                sx={typographyStyles}
                primary={<Typography sx={typographyStyles}>and more...</Typography>}
              />
            </ListItem>
          )}
        </List>
      </Stack>
    </Stack>
  )

  return (
    <TableRow
      hover
      sx={{
        borderTop: isDivider && isBoldCell ? '1px solid rgba(145, 158, 171, 0.24)' : 'inherit',
        borderBottom:
          label === 'overall' || (isDivider && isBoldCell)
            ? '1px solid rgba(145, 158, 171, 0.24)'
            : 'inherit',
      }}
    >
      <TableCell
        align={label === 'overall' ? 'center' : 'left'}
        sx={{ borderRight: '2px solid rgba(145, 158, 171, 0.24)' }}
      >
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
          {label !== 'overall' ? (
            <Stack direction="row" spacing={0.5}>
              <Box sx={{ width: '25px', height: '25px', display: 'inline-block' }}>
                {socialIcon[label?.split('_')[0]]}
              </Box>
              <Typography
                noWrap
                variant={isBoldCell ? 'subtitle2' : 'inherit'}
                sx={{ textTransform: 'capitalize', minWidth: '75px' }}
              >
                {label?.split('_')[1]}
              </Typography>
            </Stack>
          ) : (
            <Typography
              textAlign="center"
              noWrap
              variant={'subtitle2'}
              sx={{ textTransform: 'capitalize' }}
            >
              {label}
            </Typography>
          )}
        </Stack>
      </TableCell>

      <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
        <Typography noWrap variant={isBoldCell ? 'subtitle2' : 'inherit'}>
          {totalCpe ? fShortenNumber(totalCpe) : ''}
        </Typography>
      </TableCell>

      <TableCell align="center">
        <Typography noWrap variant={isBoldCell ? 'subtitle2' : 'inherit'}>
          {totalSpend ? fShortenNumber(totalSpend) : ''}
        </Typography>
      </TableCell>

      <TableCell align="center">
        <Typography noWrap variant={isBoldCell ? 'subtitle2' : 'inherit'}>
          {totalInteractions ? fShortenNumber(totalInteractions) : ''}
        </Typography>
      </TableCell>

      <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
        <Typography noWrap variant={isBoldCell ? 'subtitle2' : 'inherit'}>
          {totalContent ? fShortenNumber(totalContent) : ''}
        </Typography>
      </TableCell>

      <TableCell align="center">
        <Typography noWrap variant={isBoldCell ? 'subtitle2' : 'inherit'}>
          {minCreatorValue ? fShortenNumber(minCreatorValue) : ''}
        </Typography>
      </TableCell>

      <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
        <Typography noWrap variant={isBoldCell ? 'subtitle2' : 'inherit'}>
          {maxCreatorValue ? fShortenNumber(maxCreatorValue) : ''}
        </Typography>
      </TableCell>

      <TableCell align="center">
        <Tooltip title={titleTooltip} placement="top" arrow>
          <Typography
            sx={{ color: isBoldCell ? '#FF5D5A' : 'inherit', cursor: 'pointer' }}
            noWrap
            variant={isBoldCell ? 'subtitle2' : 'inherit'}
          >
            {missingCreators?.length ? `${fShortenNumber(missingCreators?.length)} Creators` : ''}
          </Typography>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

CpeRows.propTypes = {
  row: PropTypes.object,
}
