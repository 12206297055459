import React from 'react'
import NewPageLayout from '../../../../../components/influencerProfile/profilePdfDocument/PageLayout/NewPageLayout'
import { SentimentPdf } from '../SentimentPdf'

export const ThirdPdfPage = ({
  publishedContentChartData,
  latestCommentsWithBrandData,
  mostLikedCommentsData,
}) => {
  return (
    <NewPageLayout>
      <SentimentPdf
        publishedContentChartData={publishedContentChartData}
        latestCommentsWithBrandData={latestCommentsWithBrandData}
        mostLikedCommentsData={mostLikedCommentsData}
      />
    </NewPageLayout>
  )
}
