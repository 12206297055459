import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { CloseIcon } from '../icons'
import { CloseWrapper, BubbleBlock, DialogTitle } from '../styledWrappers'
import useTranslation from '../../../localization/useTranslation'
import {
  EditWrapper,
  Wrapper,
  CollectionInfoWrapper,
  DisabledCollectionInfoWrapper,
} from './dialogsCommonStyles'
import { canAddToCollectionDynamicSelector, noRerenderEqFn } from '../../../redux/selectors'
import WithUpgradeTip from '../popups/WithUpgradeTip'
import { UNLOCKED_COLLECTION_NAME } from '../../../constants/collections'
import { NAVBAR_FOOTER_SUM_HEIGHT } from '../../../constants/appSettings'

const DialogWrapper = styled(Wrapper)`
  height: calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px - 20px);
  max-height: calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px - 20px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  ${props => props.theme.scrollStyle}
`

const ManageCollectionsItemsDialog = ({ type, close, collectionsList, manageHandler }) => {
  const { labelStrings } = useTranslation()

  const TITLES_MAP = {
    move: labelStrings.moveToCollection,
    copy: labelStrings.copyToCollection,
  }

  const canManageItems = useSelector(canAddToCollectionDynamicSelector, noRerenderEqFn)
  const canMoveItemsToCollection = collectionId => canManageItems({ collectionId })

  const itemClickHandler = collectionId => async () => manageHandler(type, collectionId)
  const closeHandler = () => close()
  return (
    <DialogWrapper>
      <DialogTitle>{TITLES_MAP[type]}</DialogTitle>
      <CloseWrapper onClick={closeHandler}>
        <CloseIcon />
      </CloseWrapper>
      <EditWrapper>
        {collectionsList.map(col => {
          if (col.collectionId === UNLOCKED_COLLECTION_NAME) return null
          const canManage = canMoveItemsToCollection(col.collectionId)
          return canManage ? (
            <CollectionInfoWrapper
              key={col.collectionId}
              onClick={itemClickHandler(col.collectionId)}
            >
              {col.name}
              <BubbleBlock>{col.count}</BubbleBlock>
            </CollectionInfoWrapper>
          ) : (
            <WithUpgradeTip key={col.collectionId} tipText="add more users to collection">
              <DisabledCollectionInfoWrapper>
                {col.name}
                <BubbleBlock>{col.count}</BubbleBlock>
              </DisabledCollectionInfoWrapper>
            </WithUpgradeTip>
          )
        })}
      </EditWrapper>
    </DialogWrapper>
  )
}

ManageCollectionsItemsDialog.propTypes = {
  type: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  collectionsList: PropTypes.array.isRequired,
  manageHandler: PropTypes.func,
}

export default ManageCollectionsItemsDialog
