import availableLangs from './availableLangs'
export default {
  [availableLangs.en]: {
    healthyFood: 'Healthy Food',
    fitness: 'Fitness',
    vegan: 'Vegan',
    mother: 'Mother',
    father: 'Father',
    family: 'Family',
    diet: 'Diet',
    vegetarian: 'Vegetarian',
    photography: 'Photography',
    travel: 'Travel',
    dogs: 'Dogs',
    cats: 'Cats',
  },
  [availableLangs.de]: {
    healthyFood: 'Gesundes Essen',
    fitness: 'Fitness',
    vegan: 'Veganer',
    mother: 'Mutter',
    father: 'Vater',
    family: 'Familie',
    diet: 'Ernährung',
    vegetarian: 'Vegetarier',
    photography: 'Photographie',
    travel: 'Reisen',
    dogs: 'Hunde',
    cats: 'Katzen',
  },
}
