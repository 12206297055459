import availableLangs from './availableLangs'

export default {
  [availableLangs.en]: {
    all: 'All',
    female: 'female',
    male: 'male',
  },
  [availableLangs.de]: {
    all: 'Alle',
    female: 'weiblich',
    male: 'männlich',
  },
}
