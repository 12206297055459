import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  MenuItem,
  Drawer,
  Select,
  FormControl,
  InputLabel,
  Divider,
  Chip,
  Stack,
  Button,
  IconButton,
  Typography,
} from '@mui/material'
import NavSection from '../../../components/nav-section'
import { NAVBAR_HEIGHT } from '../../../../constants/appSettings'
import useTranslation from '../../../../localization/useTranslation'
import { CreateCampaigns } from '../../../components/campaigns/CreateCampaings'
import { CampaignLimit } from '../../../components/campaigns/CampaignLimit'
import { StyledScrollbar } from './styles'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  changeCampaign,
  changeLoadingCurrentCampaign,
  changeLoadingDashboard,
} from '../../../../redux/ducks/campaignsDucks'
import NavToggleButton from './NavToggleBtn'
import { useLocalStorage } from '../../../../hooks/useLocalStorage'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined'

const NAV_WIDTH = 299
export const NAV_SMALL_WIDTH = 88

export default function Nav({
  campaignId = '',
  campaignsList,
  dashboardId,
  startingDate,
  onActivateCampaign,
  isLoaded,
  isLoadedCampaign,
  isArchived,
  isOnboardingCampaign,
}) {
  const { labelStrings } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const [isMinimalNav, setIsMinimalNav] = useLocalStorage('isMinimalNavbar', false)
  const [navType, setNavType] = useState('default')

  const [isExpandCampaigns, setIsExpandCampaigns] = useState(null)

  const [curCampaign, setCurCampaign] = useState(campaignId)

  const isValidCampaignId = campaignsList?.find(campaign => campaign.campaignId === curCampaign)


  useEffect(() => {
    setCurCampaign(campaignId)
  }, [campaignId])

  useEffect(() => {
    if (isMinimalNav) {
      setNavType(isMinimalNav === 'false' || isMinimalNav === false ? 'default' : 'min')
    }
  }, [isMinimalNav])

  const onChangeNavType = () => {
    const value = isMinimalNav === 'false' || isMinimalNav === false ? true : false
    setIsMinimalNav(value)
    setNavType(value ? 'min' : 'default')
  }

  const campaignMenuSelectItems = useMemo(() => {
    const regularCampaigns = campaignsList
      ?.filter(campaign => !campaign.isDemoCampaign && !campaign.isArchived)
      ?.map(campaign => (
        <MenuItem key={campaign.campaignId} value={campaign.campaignId}>
          {campaign.campaignName}
        </MenuItem>
      ))

    const archivedCampaigns = campaignsList
      ?.filter(campaign => campaign.isArchived)
      ?.map(campaign => (
        <MenuItem key={campaign.campaignId} value={campaign.campaignId}>
          {campaign.campaignName}
        </MenuItem>
      ))

    const demoCampaigns = campaignsList
      ?.filter(campaign => campaign.isDemoCampaign)
      ?.map(campaign => (
        <MenuItem
          key={campaign.campaignId}
          value={campaign.campaignId}
          sx={{
            color: 'red',
            fontSize: '0.8rem',
          }}
        >
          {campaign.campaignName}
        </MenuItem>
      ))
    return [
      ...regularCampaigns,
      ...(archivedCampaigns && archivedCampaigns?.length
        ? [
            <Divider key="divider" />,
            <MenuItem
              key="demo-campaign-label"
              sx={{
                fontWeight: 'bold',
                color: '#666', // make it less dark black
                fontSize: '0.85rem', // make it a bit smaller
                pointerEvents: 'none', // this makes it non-selectable
              }}
            >
              Archived Campaigns
            </MenuItem>,
            ...archivedCampaigns,
          ]
        : ''),
      ...(demoCampaigns && demoCampaigns.length
        ? [
            <Divider key="divider" />,
            <MenuItem
              key="demo-campaign-label"
              sx={{
                fontWeight: 'bold',
                color: '#666', // make it less dark black
                fontSize: '0.85rem', // make it a bit smaller
                pointerEvents: 'none', // this makes it non-selectable
              }}
            >
              Demo Campaigns
            </MenuItem>,
            <MenuItem
              key="demo-subheadline"
              sx={{
                color: '#999', // make it even lighter than the label
                fontSize: '0.7rem', // smaller font size for the sub headline
                fontStyle: 'italic', // to emphasize the note
                pointerEvents: 'none', // this makes it non-selectable
              }}
            >
              Not possible to remove or edit.
            </MenuItem>,
            ...demoCampaigns,
          ]
        : ''),
    ]
  }, [campaignsList])

  const handleChangeCampaign = event => {
    const value = event.target.value
    setCurCampaign(value)
    // @ts-ignore
    dispatch([
      changeLoadingDashboard(true),
      changeLoadingCurrentCampaign(true),
      changeCampaign({ campaignId: value, history }),
    ])
    setIsExpandCampaigns(false)
  }

  const onHandleExpandChangeCampaignsList = () => {
    setIsExpandCampaigns(true)
    setNavType('default')
    localStorage.setItem('isMinimalNavbar', 'false')
  }

  const handleCloseCampaignsList = () => {
    setIsExpandCampaigns(false)
  }

  const onHandleOpenCampaignsList = () => {
    setIsExpandCampaigns(true)
  }

  const renderContent = (
    <StyledScrollbar>
      <Box sx={{ px: 2.5, py: 2.5, display: 'inline-flex', width: '100%' }}>
        <CreateCampaigns navType={navType} />
      </Box>

      {navType !== 'default' ? (
        <Stack alignItems="center" sx={{ pb: 1 }}>
          <Box sx={{ px: 1 }}>
            <IconButton
              aria-controls="custom-menu"
              aria-haspopup="true"
              onClick={onHandleExpandChangeCampaignsList}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '12px',
                '&:hover': {
                  color: '#FF5D5A',
                },
              }}
            >
              <ExpandCircleDownOutlinedIcon color="default" />
              <Typography
                sx={{ mt: 0.5, lineHeight: 'initial', fontWeight: 'bold', fontSize: '10px' }}
              >
                Change Campaign
              </Typography>
            </IconButton>
          </Box>

          {!isLoaded && !isLoadedCampaign && startingDate && !isArchived && (
            <Stack
              direction="row"
              justifyContent={startingDate ? 'end' : 'space-between'}
              alignItems="center"
              sx={{
                mt: 1.5,
              }}
            >
              {!!campaignId && (
                <Chip
                  sx={{ fontWeight: 'bold', fontSize: '10px' }}
                  color={'success'}
                  size="small"
                  label={labelStrings.running}
                />
              )}
            </Stack>
          )}
        </Stack>
      ) : (
        <Box
          sx={{
            px: 2.5,
            py: 1,
            width: '100%',
          }}
        >
          <FormControl fullWidth>
            <InputLabel size="small">{labelStrings.campaigns}</InputLabel>
            <Select
              autoWidth
              value={curCampaign}
              required
              label={labelStrings.campaigns}
              size="small"
              onChange={handleChangeCampaign}
              open={!!isExpandCampaigns}
              onOpen={onHandleOpenCampaignsList}
              onClose={handleCloseCampaignsList}
              disabled={!isValidCampaignId}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: '318px',
                  },
                },
              }}
            >
              {campaignMenuSelectItems}
            </Select>
          </FormControl>
          {!isLoaded && !isLoadedCampaign && (
            <Stack
              direction="row"
              justifyContent={startingDate ? 'end' : 'space-between'}
              alignItems="center"
              sx={{
                mt: 1,
              }}
              spacing={1}
            >
              {((isOnboardingCampaign && !isArchived)) && (
                <Button color={'success'} onClick={onActivateCampaign} variant="text">
                  {labelStrings.activateCampaign}
                </Button>
              )}
              {!!campaignId && (
                <Chip
                  sx={{ fontWeight: 'bold' }}
                  color={startingDate && !isArchived ? 'success' : 'warning'}
                  size="small"
                  label={startingDate && !isArchived ? labelStrings.running : labelStrings.inactive}
                />
              )}
            </Stack>
          )}
        </Box>
      )}
      <Divider sx={{ borderStyle: 'dashed' }} />

      <NavSection
        sx={{ py: navType === 'default' ? 2 : 0 }}
        dashboardId={dashboardId}
        disabledLinks={!isValidCampaignId}
        navType={navType}
      />

      <Box sx={{ flexGrow: 1 }} />

      {navType === 'default' && !isLoaded && (
        <>
          <Divider sx={{ my: 2, borderStyle: 'dashed' }} />
          <Box sx={{ py: 2 }}>
            <CampaignLimit />
          </Box>
        </>
      )}
    </StyledScrollbar>
  )

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: navType === 'default' ? { lg: NAV_WIDTH } : NAV_SMALL_WIDTH,
      }}
    >
      <Drawer
        className="drawer"
        open
        variant="permanent"
        PaperProps={{
          sx: {
            width: navType === 'default' ? NAV_WIDTH : NAV_SMALL_WIDTH,
            bgcolor: 'background.default',
            borderRightStyle: 'dashed',
            top: `${NAVBAR_HEIGHT}px`,
            zIndex: 100,
          },
        }}
      >
        <NavToggleButton onChangeNavType={onChangeNavType} navType={navType} />
        {renderContent}
      </Drawer>
    </Box>
  )
}

Nav.propTypes = {
  campaignId: PropTypes.string,
  campaignsList: PropTypes.array,
  startingDate: PropTypes.string,
  isOnboardingCampaign: PropTypes.bool,
  isArchived: PropTypes.bool,
}
