import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../localization/useTranslation'
import {
  ADDITIONAL_FIELD_TYPES,
  PRO_PLAN_ADDITIONAL_FIELDS_LIMIT,
  PLAN_ADDITIONAL_FIELDS_LIMIT,
} from '../../../constants/additionalFields'
import {
  userGrantSelector,
  userAdditionalFieldsSelector,
  isAdditionalFieldCreatingSelector,
  isUserAuthenticatedSelector,
} from '../../../redux/selectors'
import { createAdditionalField } from '../../../redux/ducks/additionalFieldsDucks'
import AdditionalFieldDetails from './additionalFieldEditing/AdditionalFieldDetails'
import AdditionalFields from './additionalFields/AdditionalFields'
import AdditionalFieldsLimitExceeded from './additionalFieldEditing/AdditionalFieldsLimitExceeded'
import PrivateNotes from './privateNotes/PrivateNotes'
import UnavailableProfileSection from '../../common/unavailableProfileSection'
import InfoTip from '../../common/tips/InfoTip'
import {
  ButtonAddNewField,
  UnavailableSectionWrapper,
  fieldCreationOverlayPosition,
} from './commonStyles'
import {
  SectionTitleWrapper,
  SectionTitleName,
  SectionTitleBorder,
} from '../commonProfilePageStyles'
import WithTip from '../../common/popups/WithTip'

const NotesAndAdditionalInfo = ({ profile = {} }) => {
  const { labelStrings, profileSectionDescs } = useTranslation()
  const dispatch = useDispatch()
  const { _index: platform } = profile || {}

  const {
    no_plan: isNoPlan,
    enable_collections,
    additional_fields: hasAdditionalFields,
  } = useSelector(userGrantSelector, shallowEqual)

  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const userAdditionalFields = useSelector(userAdditionalFieldsSelector)
  const isAdditionalFieldCreating = useSelector(isAdditionalFieldCreatingSelector)

  const [isFieldCreationOverlayOpened, setIsFieldCreationOverlayOpened] = useState(false)
  const [isLimitExceededOverlayOpened, setIsLimitExceededOverlayOpened] = useState(false)

  const isSectionDisabled = !isUserAuthenticated || isNoPlan

  const closeFieldCreationOverlay = () => setIsFieldCreationOverlayOpened(false)
  const closeLimitExceededOverlay = () => setIsLimitExceededOverlayOpened(false)

  let isLimitExceeded = false
  if (hasAdditionalFields) {
    isLimitExceeded = userAdditionalFields?.length >= PRO_PLAN_ADDITIONAL_FIELDS_LIMIT
  } else if (isUserAuthenticated && !isNoPlan) {
    isLimitExceeded = userAdditionalFields?.length >= PLAN_ADDITIONAL_FIELDS_LIMIT
  }

  const onAddNewFieldClick = () => {
    if (isLimitExceeded) {
      setIsLimitExceededOverlayOpened(true)
    } else {
      setIsFieldCreationOverlayOpened(true)
    }
  }

  const createNote = async ({ label, type, allowedValues }) => {
    await dispatch(createAdditionalField({ label, type, allowedValues }))
  }

  const additionalFieldsWithValues = userAdditionalFields?.map(additionalField => {
    let value = profile.additionalFields?.find(item => item.code === additionalField.code)?.value
    if (typeof value !== 'boolean' && !value) {
      value = additionalField.type === ADDITIONAL_FIELD_TYPES.multiSelect ? [] : ''
    }
    return { ...additionalField, value }
  })

  return (
    <>
      <SectionTitleWrapper>
        <SectionTitleName
          // @ts-ignore
          platform={platform}
        >
          {`${labelStrings.privateNotes} & ${labelStrings.additionalInfo}`}
        </SectionTitleName>
        <InfoTip>
          <div
            dangerouslySetInnerHTML={{ __html: profileSectionDescs.privateNotesAdditionalInfo }}
          />
        </InfoTip>
        <SectionTitleBorder />
        {!hasAdditionalFields || isSectionDisabled ? (
          <WithTip
            tipText={labelStrings.disabledFunctionInYourPlanTip}
            userindex={platform}
            position="bottom center"
          >
            <div>
              <ButtonAddNewField
                isDisabled={!hasAdditionalFields || isSectionDisabled}
                platform={platform}
                onClick={onAddNewFieldClick}
              >
                {labelStrings.addNewField}
              </ButtonAddNewField>
            </div>
          </WithTip>
        ) : (
          <ButtonAddNewField
            isDisabled={!hasAdditionalFields || isSectionDisabled}
            platform={platform}
            onClick={onAddNewFieldClick}
          >
            {labelStrings.addNewField}
          </ButtonAddNewField>
        )}

        {isLimitExceededOverlayOpened && (
          <AdditionalFieldsLimitExceeded
            closeOverlay={closeLimitExceededOverlay}
            platform={platform}
          />
        )}

        {isFieldCreationOverlayOpened && (
          <AdditionalFieldDetails
            platform={profile._index}
            closeOverlay={closeFieldCreationOverlay}
            position={fieldCreationOverlayPosition}
            saveNote={createNote}
            isLoading={isAdditionalFieldCreating}
          />
        )}
      </SectionTitleWrapper>

      {!isSectionDisabled ? (
        <>
          {!hasAdditionalFields ? (
            <WithTip
              tipText={labelStrings.disabledFunctionInYourPlanTip}
              userindex={platform}
              position="bottom center"
            >
              <div>
                <AdditionalFields
                  profileId={profile._id}
                  platform={platform}
                  additionalFields={additionalFieldsWithValues}
                  isDisabled={!hasAdditionalFields}
                />
              </div>
            </WithTip>
          ) : (
            <AdditionalFields
              profileId={profile._id}
              platform={platform}
              additionalFields={additionalFieldsWithValues}
            />
          )}

          <PrivateNotes
            platform={platform}
            commentThread={profile.commentThread}
            isDisabled={hasAdditionalFields || enable_collections}
          />
        </>
      ) : (
        <UnavailableSectionWrapper>
          <UnavailableProfileSection
            // @ts-ignore
            unavailableSectionLabel={labelStrings.toSeePrivateNotes}
            linkToFaqText={labelStrings.whatAreThePrivateNotes}
            platform={platform}
          />
        </UnavailableSectionWrapper>
      )}
    </>
  )
}

NotesAndAdditionalInfo.propTypes = {
  profile: PropTypes.object.isRequired,
}

export default NotesAndAdditionalInfo
