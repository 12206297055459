import PropTypes from 'prop-types'
import React from 'react'
import { Box, Card, CardHeader, IconButton } from '@mui/material'
import Chart, { useChart } from '../index'
import { TooltipComponent } from '../../tooltip/TooltipComponent'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { pretifyBigNum } from '../../../../utils'

export const BarChart = ({ title, subheader, chart, height = '464px', helperText = '', ...other }) => {
  const { colors, series, options } = chart
  const chartSeries = series.map(i => i.value)

  const chartOptions = useChart({
    colors,
    tooltip: {
      marker: { show: false },
      y: {
        formatter: value => pretifyBigNum(value),
        title: {
          formatter: () => '',
        },
      },
    },
    noData: {
      text: 'No data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '16px',
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '28%',
        borderRadius: 2,
      },
    },
    xaxis: {
      categories: series.map(i => i.label),
      labels: {
        show: !chartSeries?.length ? false : true,
      },
      ...options?.xaxis
    },
    yaxis: {
      labels: {
        show: !chartSeries?.length ? false : true,
      },
      ...options?.yaxis
    },
    ...options,
  })

  return (
    <Card {...other} sx={{ height }}>
      <CardHeader
        title={title}
        subheader={subheader}
        {...(helperText?.length && {
          action: (
            <IconButton aria-label="Helper-Text">
              <TooltipComponent text={helperText}>
                <InfoOutlinedIcon />
              </TooltipComponent>
            </IconButton>
          ),
        })}
      />

      <Box sx={{ mx: 3 }} dir="ltr">
        <Chart type="bar" series={[{ data: chartSeries }]} options={chartOptions} height={364} />
      </Box>
    </Card>
  )
}

BarChart.propTypes = {
  chart: PropTypes.object,
  title: PropTypes.string,
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.string
}
