import React, { useState } from 'react'
import { Box, Button, MenuItem, Stack, TextField, Typography } from '@mui/material'
import MenuPopover from '../../components/menu-popover'
import { TooltipComponent } from '../../components/tooltip/TooltipComponent'
import useTranslation from '../../../localization/useTranslation'
import { shallowEqual, useSelector } from 'react-redux'
import { userGrantSelector } from '../../../redux/selectors'
import ConfirmDialog from '../../components/confirm-dialog'

export const PopoverItemMenu = props => {
  const {
    openPopover,
    handleClosePopover,
    onEditRow,
    onDeleteRow,
    setOpenAddValueModal,
    trackingMethod,
    applyLevel,
    onHandleCurrentRow,
    setOpenWebHook,
    rowData,
  } = props

  const { labelStrings } = useTranslation()
  const { campaigns_allow_bitly_edit } = useSelector(userGrantSelector, shallowEqual)

  const [isShowConfirm, setIsShowConfirm] = useState(false)
  const [confirmValue, setConfirmValue] = useState('')

  const onChangeConfirmValue = ({ target }) => {
    setConfirmValue(target.value)
  }

  const isApplyLevelContent = applyLevel === 'content'
  const isBitlyField = trackingMethod === 'bitly'

  const customFieldName = rowData?.customFieldName
  const isPossibleDeleteCustomField =
    customFieldName?.toLocaleLowerCase() === confirmValue?.toLocaleLowerCase()

  return (
    <>
      <MenuPopover open={openPopover} onClose={handleClosePopover} arrow="right-top">
        {!isBitlyField && (
          <TooltipComponent
            placement="left-start"
            text={isApplyLevelContent ? labelStrings.addValueManuallyHoverText : ''}
          >
            <div>
              <MenuItem
                disabled={isApplyLevelContent || isBitlyField}
                onClick={() => {
                  setOpenAddValueModal(true)
                  onHandleCurrentRow()
                  handleClosePopover()
                }}
              >
                {labelStrings.addValueManually}
              </MenuItem>
            </div>
          </TooltipComponent>
        )}

        <TooltipComponent
          arrow="right-top"
          text={
            isBitlyField && !campaigns_allow_bitly_edit ? labelStrings.campaignAllowBitlyEdit : ''
          }
          placement="left-start"
        >
          <Box>
            <MenuItem
              disabled={isBitlyField && !campaigns_allow_bitly_edit}
              onClick={() => {
                onEditRow()
                handleClosePopover()
              }}
            >
              {isBitlyField ? labelStrings.editBitlyField : labelStrings.editCustomField}
            </MenuItem>
          </Box>
        </TooltipComponent>

        <MenuItem
          onClick={() => {
            setIsShowConfirm(true)
            handleClosePopover()
          }}
        >
          {isBitlyField ? labelStrings.deleteBitlyField : labelStrings.deleteCustomField}
        </MenuItem>

        {!isBitlyField && (
          <MenuItem
            onClick={() => {
              setOpenWebHook(true)
              onHandleCurrentRow()
              handleClosePopover()
            }}
          >
            {labelStrings.addWebhookTracking}
          </MenuItem>
        )}
      </MenuPopover>

      <ConfirmDialog
        open={isShowConfirm}
        onClose={() => setIsShowConfirm(false)}
        title={labelStrings.confirmToDeleteField(customFieldName)}
        maxWidth={'sm'}
        content={
          <Stack alignItems="center" spacing={1.5}>
            <Typography textAlign="center">{labelStrings.customFieldDeleteAttention}</Typography>
            <Typography textAlign="center">
              {labelStrings.customFieldDeleteBitlyTracking}
            </Typography>

            <Stack>
              <Typography textAlign="center">
                {labelStrings.customFieldDeleteConfirm(customFieldName)}
              </Typography>
              <TextField
                sx={{ mb: 1 }}
                variant="standard"
                size="small"
                onChange={onChangeConfirmValue}
                value={confirmValue}
              />
            </Stack>
          </Stack>
        }
        action={
          <Button
            disabled={!isPossibleDeleteCustomField}
            variant="contained"
            color="error"
            onClick={onDeleteRow}
          >
            {labelStrings.deleteField}
          </Button>
        }
      />
    </>
  )
}
