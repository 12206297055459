import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { RoundedInput, Label, DialogTitle } from '../../common/styledWrappers'
import { ActionButton } from '../../common/buttons/styledButtons'
import PasswordIcon from '../../common/icons/PasswordIcon'
import { validatePassword } from '../../../utils'
import useTranslation from '../../../localization/useTranslation'

const MainWrapper = styled.div`
  ${props => props.theme.flex.centerAll}
  flex-direction: column;
  padding: 25px 50px;
`

const FormWrapper = styled.form`
  ${props => props.theme.flex.centerAll};
  flex-direction: column;
  justify-content: space-around;
  margin-top: 35px;
  width: 80%;
  height: 200px;
  position: relative;
`

const InputBlockWrapper = styled.div`
  width: 100%;
  ${props => props.theme.flex.centerAll};
  position: relative;
  flex-direction: column;
`

const Input = styled(RoundedInput)`
  height: 37px;
  font-size: 14px;
  color: ${props => props.theme.textColor};
  background: ${props => props.theme.color.white};
  ${props => !props.isValid && `border-color: ${props.theme.primaryColor};`}
`

const InputLabel = styled(Label)`
  color: #3e4860;
  margin-bottom: 5px;
`

const NotValidLabel = styled.span`
  position: absolute;
  top: 0;
  right: -50px;
  font-size: 11px;
  color: ${props => props.theme.primaryColor};
`

const PasswordsEqualFailLabel = styled(NotValidLabel)`
  font-size: 14px;
  top: -25px;
  left: 10px;
  right: -10px;
  text-align: center;
`

const DialogSubTitle = styled(DialogTitle)`
  font-weight: 500;
  font-family: ${props => props.theme.fontFamilyInter};
  letter-spacing: unset;
  text-transform: unset;
  margin-top: 20px;
`

const ResetPasswordForm = ({ resetPasswordHandler }) => {
  const [formDataObj, setFormDataObj] = useState({
    password: '',
    passwordCopy: '',
    isValidPassword: true,
    isValidPasswordCopy: true,
    arePasswordsSame: true,
    showPassword: false,
    showPasswordCopy: false,
  })

  const { labelStrings } = useTranslation()

  const changeFormDataObj = newValueObj => setFormDataObj({ ...formDataObj, ...newValueObj })

  const onPasswordChange = ({ target }) => changeFormDataObj({ password: target.value })
  const onPasswordCopyChange = ({ target }) => changeFormDataObj({ passwordCopy: target.value })

  const toggleShowPassword = () => changeFormDataObj({ showPassword: !formDataObj.showPassword })
  const toggleShowPasswordCopy = () =>
    changeFormDataObj({ showPasswordCopy: !formDataObj.showPasswordCopy })

  const resetValidation = () => {
    if (
      !formDataObj.isValidPassword ||
      !formDataObj.isValidPasswordCopy ||
      !formDataObj.arePasswordsSame
    ) {
      changeFormDataObj({
        isValidPassword: true,
        isValidPasswordCopy: true,
        arePasswordsSame: true,
      })
    }
  }

  const onSubmit = async e => {
    e.preventDefault()
    const isValidPassword =
      !!formDataObj.password && validatePassword({ password: formDataObj.password })
    const isValidPasswordCopy =
      formDataObj.passwordCopy && validatePassword({ password: formDataObj.passwordCopy })
    if (!isValidPassword || !isValidPasswordCopy) {
      return changeFormDataObj({ isValidPassword, isValidPasswordCopy })
    }
    const arePasswordsSame = formDataObj.passwordCopy === formDataObj.password
    if (!arePasswordsSame) return changeFormDataObj({ arePasswordsSame })

    const { password } = formDataObj
    await resetPasswordHandler(password)
  }

  return (
    <MainWrapper>
      <DialogTitle>{labelStrings.setNewPassword}</DialogTitle>
      <DialogSubTitle>{labelStrings.passwordLimit}</DialogSubTitle>
      <FormWrapper onSubmit={onSubmit}>
        {!formDataObj.arePasswordsSame && (
          <PasswordsEqualFailLabel>{labelStrings.passwordsShouldBeEqual}</PasswordsEqualFailLabel>
        )}
        <InputBlockWrapper>
          <InputLabel htmlFor="password_input">{labelStrings.enterPassword}</InputLabel>
          {!formDataObj.isValidPassword && (
            <NotValidLabel>{labelStrings.notValidPassword}</NotValidLabel>
          )}
          <Input
            id="password_input"
            type={formDataObj.showPassword ? 'text' : 'password'}
            noFocusEffect
            onChange={onPasswordChange}
            onFocus={resetValidation}
            value={formDataObj.password}
            isValid={formDataObj.isValidPassword}
          />
          <PasswordIcon
            toggleShowPassword={toggleShowPassword}
            showPassword={formDataObj.showPassword}
          />
        </InputBlockWrapper>
        <InputBlockWrapper>
          <InputLabel htmlFor="repass_input">{labelStrings.repeatPassword}</InputLabel>
          {!formDataObj.isValidPasswordCopy && (
            <NotValidLabel>{labelStrings.pleaseRepeatPassword}</NotValidLabel>
          )}
          <Input
            id="repass_input"
            type={formDataObj.showPasswordCopy ? 'text' : 'password'}
            noFocusEffect
            onChange={onPasswordCopyChange}
            onFocus={resetValidation}
            value={formDataObj.passwordCopy}
            isValid={formDataObj.isValidPasswordCopy}
          />
          <PasswordIcon
            toggleShowPassword={toggleShowPasswordCopy}
            showPassword={formDataObj.showPasswordCopy}
          />
        </InputBlockWrapper>

        <ActionButton type="submit">{labelStrings.setNewPassword}</ActionButton>
      </FormWrapper>
    </MainWrapper>
  )
}

ResetPasswordForm.propTypes = {
  resetPasswordHandler: PropTypes.func,
}

export default ResetPasswordForm
