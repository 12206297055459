import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CloseCircledIcon } from '../icons'

const CloseWrapper = styled.div`
  position: absolute;
  top: ${props => props.top};
  right: ${props => props.right};
  cursor: pointer;
  & svg {
    fill: ${props => props.theme.color.grey};
  }
`

const CloseButton = ({ close, top = '23px', right = '24px' }) => {
  return (
    <CloseWrapper onClick={close} top={top} right={right}>
      <CloseCircledIcon height="30px" width="30px" />
    </CloseWrapper>
  )
}

CloseButton.propTypes = {
  close: PropTypes.func,
  top: PropTypes.string,
  right: PropTypes.string,
}

export default CloseButton
