import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'
import { TableRow, HeaderCell, CheckboxWrapper } from '../profileListTable/tableStyles'
import Checkbox from '../../common/checkboxes/Checkbox'
import { ProfileListViewSortToggle } from './ProfileListViewSortToggle'
import { INITIAL_LIST_VIEW_COLLECTIONS_PROPERTIES } from '../../../constants/collections'

const ProfileListViewHeadRow = ({
  selectAll,
  areAllSelected = false,
  isCollectionView = false,
  isUnlockedCollectionOpened = false,
  isBlackListCollection = false,
  tableHeaderProps = {},
  currentFieldsState = {},
  additionalFields = [],
}) => {
  const { labelStrings, currentLang } = useTranslation()
  const { onSortTable, sortDirection, setSortDirection } = tableHeaderProps || {}

  const additionalFieldsProperties = additionalFields?.map(field => {
    return {
      show: field.isHidden,
      width: 240,
      key: field.code,
      label: field.label,
      edit: true,
      isAdditionalField: true
    }
  })

  const headerData = [...INITIAL_LIST_VIEW_COLLECTIONS_PROPERTIES, ...additionalFieldsProperties]

  const [activeCell, setActiveCell] = useState('')

  const hasCheckbox = isCollectionView && !isUnlockedCollectionOpened

  const onHandleTableHead = (key, isAdditionalField) => {
    if(isAdditionalField) return
    setActiveCell(key)
    onSortTable({ column: { sortKey: key }, hiddenArrFields: null, viewMode: null })
  }

  const renderTableHeadCell = useMemo(() => {
    return (
      <>
        {headerData?.map(cell => {
          const key = cell.key

          if (!currentFieldsState[key]?.show) {
            return
          }

          return (
            <HeaderCell width={cell.width || 150} key={key}>
              <div onClick={() => onHandleTableHead(key, cell.isAdditionalField)}>
                {
                  cell.isAdditionalField ? 
                  <>
                  <div className="cell-label">
                    {cell.label}</div>
                  </>
                :
                <ProfileListViewSortToggle
                  setSortDirection={setSortDirection}
                  sortDirection={sortDirection}
                  keyField={key}
                  currentCell={activeCell}
                >
                  <div className="cell-label">
                    {labelStrings.tableHeadLabels[key]}
                  </div>
                </ProfileListViewSortToggle>
                }
              </div>
            </HeaderCell>
          )
        })}
      </>
    )
  }, [currentLang, sortDirection, currentFieldsState])

  return (
    <TableRow>
      {hasCheckbox && !isBlackListCollection && (
        <HeaderCell width={30}>
          <CheckboxWrapper>
            <Checkbox onChange={selectAll} isChecked={areAllSelected} />
          </CheckboxWrapper>
        </HeaderCell>
      )}
      {renderTableHeadCell}
    </TableRow>
  )
}

ProfileListViewHeadRow.propTypes = {
  selectAll: PropTypes.func,
  areAllSelected: PropTypes.bool,
  isCollectionView: PropTypes.bool,
  isUnlockedCollectionOpened: PropTypes.bool,
  isBlackListCollection: PropTypes.bool,
  tableHeaderProps: PropTypes.object,
  currentFieldsState: PropTypes.object,
  additionalFields: PropTypes.array,
}

export default ProfileListViewHeadRow
