import React from 'react'
import PropTypes from 'prop-types'
import { Box, Stack, Typography } from '@mui/material'
import { currentOpenedCampaignSelector } from '../../../../redux/selectors'
import { useSelector } from 'react-redux'
import PdfLogo from '../../../../images/influData-Logo-Pdf-Layout.png'

const NewPageLayout = ({ children = null, isBranded = false }) => {
  const { campaignName } = useSelector(currentOpenedCampaignSelector)

  return (
      <>
      <span className="html2pdf__page-break" />
        <Stack direction='row' alignItems="center" justifyContent="space-between" sx={{ mb: 3, pt: 2  }}>
          <Stack>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 700,
                lineHeight: '16px',
                color: '#919EAB',
                textTransform: 'uppercase',
              }}
            >
              campaign name
            </Typography>
            <Typography
              sx={{
                fontSize: '32px',
                fontWeight: 700,
                lineHeight: '16px',
                color: '#FF5D5A',
                mb: 3
              }}
            >
              {campaignName}
            </Typography>
          </Stack>
          <Box sx={{ maxWidth: '150px'}}>
            <img  alt='influData' src={PdfLogo} />
          </Box>
        </Stack>
        <Box
          sx={{
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            wordBreak: 'keep-all',
            hyphens: 'auto',
            mt: 1,
            overflow: 'hidden'
          }}
        >
          {children}
        </Box>
      </>
  )
}

NewPageLayout.propTypes = {
  children: PropTypes.node,
}

export default NewPageLayout
