import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import useTranslation from '../../localization/useTranslation'
import { CHART_INCREASE_VALUE_RATIOS } from '../../constants/components'
import { USER_INDEXES } from '../../constants/appSettings'
import Pagination from '../pagination/Pagination'
import Tabs from '../common/tabs/Tabs'
import { HorizontalBarChartInfo, ChartName } from './chartsCommonWrappers'
import NotEnoughDataForChartMessage from './NotEnoughDataForChartMessage'

const paginationItemStyles = css`
  font-size: 15px !important;
  line-height: 19px !important;
  color: ${props => props.theme.colors.darkBrown} !important;
  text-decoration: underline !important;
`

const Wrapper = styled.div`
  position: relative;

  ${props =>
    props.isDisabled &&
    `
    ${props.theme.disabled}
    opacity: 0.9;
  `}

  .disabled-chart-message__container {
    ${props =>
      !props.isForPdf
        ? `
      top: 50%;
      left: 20%;
    `
        : `
      top: 40%;
      left: 5%; 
    `}
    transform: none;
  }

  .pagination-wrapper {
    justify-content: flex-start;

    .page_num {
      a {
        ${paginationItemStyles}
      }

      &.disabled_btn a {
        ${paginationItemStyles}
      }

      &.current_page a {
        ${paginationItemStyles}
        font-weight: 700;
      }
    }

    .nav_btn.next,
    .nav_btn.prev {
      display: none;
    }
  }
`

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  ${props => props.isForPdf && 'max-height: 270px'}; 
  /* ${props => !props.isForPdf && 'max-width: 92%'}; */
`

const LabelWrapper = styled.div`
  font-family: ${props => props.theme.fontFamilyInter};
  ${props =>
    !props.isForPdf
      ? `
    font-size: 15px;
    line-height: 19px;
    min-width: 50%;
    max-width: 50%;
  `
      : `
    font-size: 10px;
    line-height: 13px;
    font-weight: 600;
    min-width: 55%;
    max-width: 55%;
  `}
  ${props =>
    props.isSmall &&
    `
  font-size: 10px;
    line-height: 13px;
    font-weight: 600;
    min-width: 55%;
    max-width: 55%;
  `}
  letter-spacing: 0px;
  color: ${props => props.theme.colors.darkBrown};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LabelTextWrapper = styled.div`
  ${props =>
    props.isDisabled
      ? `
    color: ${props.theme.colors.lightBrown};
    background: ${props.theme.colors.lightBrown};
    width: 90px;
    user-select: none;
  `
      : `
    max-width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: visible;

  `}
`

const ItemWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  flex-wrap: nowrap;
`

const BarWrapper = styled.div`
  flex: 1;
  height: 100%;
  padding: 5px 0;
  display: flex;
  align-items: center;
  border-left: 1px solid
    ${props => (props.isDisabled ? props.theme.colors.lightBrown : props.theme.colors.mediumBrown)};
`

const Bar = styled.div`
  width: ${props => props.percent || 50}%;
  height: ${props => (!props.isForPdf ? '25px' : '20px')};
  background: ${props => {
    let bgGradient = props.theme.gradients.mainDeg(270)
    let bgDisabled = props.theme.colors.lightBrown

    if (props.platform === USER_INDEXES.tiktok) {
      bgGradient = props.theme.gradients.mainTiktokDeg(270)
    }

    return props.isDisabled ? bgDisabled : bgGradient
  }};
  border-radius: 0 4px 4px 0;
  position: relative;

  &::before {
    content: '${props => props.barTip}';
    position: absolute;
    top: -25px;
    left: 0;
    display: block;
    font-size: 11px;
    font-family: ${props => props.theme.fontFamilyInter};
    letter-spacing: 0px;
    color: ${props => props.theme.textColor};
    text-transform: uppercase;
  }
`

const BarTip = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  color: ${props => props.theme.colors.darkBrown};
  margin: 0 10px;
  ${props => props.isForPdf && `margin-bottom: 12px;`}

  ${props =>
    props.isDisabled &&
    `
    color: ${props.theme.colors.lightBrown};
    background: ${props.theme.colors.lightBrown};
    user-select: none;
  `}
`

const BarTipPercent = styled.span`
  ${props => {
    if (props.isDisabled) return ''
    return props.isForPdf
      ? `color: ${props.theme.getColorPaletteForPlatform(props.platform).main};`
      : props.theme.getTextGradientStyles({ platform: props.platform })
  }}
`

const BarTipCount = styled(BarTip)`
  color: ${props => !props.isDisabled && props.theme.colors.darkBrown};
  margin-left: 5px;
`

const ChartSwitcherWrapper = styled.div`
  display: flex;
  margin-left: 40px;

  & button {
    text-transform: uppercase;
    margin-right: 10px;
  }
`

const ONE_LINE_NAME_LENGTH = 25
//const GERMAN_SS_SYMBOL = 'ß'

const HorizontalBarChart = ({
  chartData,
  userindex = '',
  styles,
  isMultiChart = false,
  chartTitle = '',
  isDisabledChart = true,
  itemsPerPage = null,
  isForPdf = false,
  isSmall = false,
  isForPdfAudience = false,
}) => {
  const { currentLang } = useTranslation()

  const [selectedChart, setSelectedChart] = useState({
    name: '',
    data: [],
    notEnoughData: [],
    isLikersReport: false,
  })

  useEffect(() => {
    setSelectedChart({
      name: chartData[0].title,
      data: chartData[0].data,
      notEnoughData: chartData[0].notEnoughData,
      isLikersReport: chartData[0].isLikersReport,
    })
  }, [chartData, currentLang])

  const [activeTabId, setActiveTabId] = useState(isMultiChart ? chartData[0].title : null)
  const [renderedChartData, setRenderedChartData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)

  const hasPagination = !!itemsPerPage && selectedChart.data.length > itemsPerPage
  const maxPagesCount = hasPagination ? Math.ceil(selectedChart.data.length / itemsPerPage) : null

  const prepareChartDataForRender = chartData => {
    const maxValue = Math.max.apply(
      null,
      chartData.map(item => item.value)
    )

    return chartData.map(item => {
      let value = item.value
      if (maxValue < CHART_INCREASE_VALUE_RATIOS.lessThan10.value) {
        value = item.value * CHART_INCREASE_VALUE_RATIOS.lessThan10.ratio
      } else if (maxValue < CHART_INCREASE_VALUE_RATIOS.lessThan20.value) {
        value = item.value * CHART_INCREASE_VALUE_RATIOS.lessThan20.ratio
      } else if (value < 1) {
        value = 1 // for too small percentages (e.g.: 0.3%) to make them visible on the chart
      }
      return { ...item, value }
    })
  }

  useEffect(() => {
    setActiveTabId(chartData[0].title)
  }, [currentLang])

  useEffect(() => {
    let preparedChartData = prepareChartDataForRender(selectedChart.data)
    if (hasPagination) {
      const firstItemOnPageIndex = currentPage * itemsPerPage
      const lastItemOnPageIndex = firstItemOnPageIndex + itemsPerPage
      preparedChartData = preparedChartData.slice(firstItemOnPageIndex, lastItemOnPageIndex)
    }
    setRenderedChartData(preparedChartData)
  }, [
    chartData,
    activeTabId,
    currentPage,
    selectedChart.isLikersReport,
    selectedChart.notEnoughData,
  ])

  const isLongChartName = selectedChart.name && selectedChart.name.length > ONE_LINE_NAME_LENGTH

  const isRenderedChartDisabled =
    isDisabledChart || (!isDisabledChart && selectedChart.notEnoughData)
  const isChartWrapperDisabled = isMultiChart ? isDisabledChart : isRenderedChartDisabled

  // const uppercaseLabelBeforeRender = label => {
  //   if (label.includes(GERMAN_SS_SYMBOL)) {
  //     // browser by default replaces ß to ss symbols when uppercased, and we want to keep ß
  //     const splitted = label.split(GERMAN_SS_SYMBOL)
  //     return splitted.map(str => str.toUpperCase()).join(GERMAN_SS_SYMBOL)
  //   }
  //   return label.toUpperCase()
  // }

  const switchSelectedChart = selectedChartId => {
    const newSelected = chartData.find(item => item.title === selectedChartId)
    setSelectedChart({
      name: newSelected.title,
      data: newSelected.data,
      notEnoughData: newSelected.notEnoughData,
      isLikersReport: newSelected.isLikersReport,
    })
    setActiveTabId(selectedChartId)
  }

  const onPageChange = newPage => setCurrentPage(newPage.selected)

  const getPaginationLabels = page => {
    const firstItemOnPage = (page - 1) * itemsPerPage + 1
    const lastItemOnPage =
      page === maxPagesCount ? selectedChart.data.length : firstItemOnPage + itemsPerPage - 1

    return firstItemOnPage !== lastItemOnPage
      ? `${firstItemOnPage} - ${lastItemOnPage}`
      : firstItemOnPage
  }

  return (
    <Wrapper
      stylesObj={styles}
      isDisabled={isChartWrapperDisabled}
      isLongChartName={isLongChartName}
      isForPdf={isForPdf}
    >
      <HorizontalBarChartInfo isSmall={isSmall} isForPdf={isForPdf}>
        <ChartName>{chartTitle}</ChartName>

        {isMultiChart && !isForPdfAudience && (
          <ChartSwitcherWrapper>
            <Tabs
              tabs={chartData.map(item => ({ id: item.title, content: item.title }))}
              activeTabId={activeTabId}
              switchTab={switchSelectedChart}
              platform={userindex}
              isDisabled={isDisabledChart}
            />
          </ChartSwitcherWrapper>
        )}
      </HorizontalBarChartInfo>
      <ChartWrapper isForPdf={isForPdf}>
        {renderedChartData.map(item => (
          <ItemWrapper key={`${item?.country ? item.country : item.label}/${currentLang}`}>
            <LabelWrapper
              withCount={!!item.barTip.count}
              className="horizontal-bar-chat_label"
              isForPdf={isForPdf}
              isSmall={isSmall}
            >
              <LabelTextWrapper
                isDisabled={isRenderedChartDisabled}
                style={{ textTransform: 'uppercase' }}
              >
                {item.label}
              </LabelTextWrapper>

              <BarTip
                isForPdf={isForPdf}
                isDisabled={isRenderedChartDisabled}
                userindex={userindex}
              >
                <BarTipPercent
                  isDisabled={isRenderedChartDisabled}
                  platform={userindex}
                  isForPdf={isForPdf}
                >
                  {item.barTip.percent}
                </BarTipPercent>

                {item.barTip.count && (
                  <BarTipCount isDisabled={isRenderedChartDisabled}>
                    {item.barTip.count}
                  </BarTipCount>
                )}
              </BarTip>
            </LabelWrapper>

            <BarWrapper isDisabled={isRenderedChartDisabled} userindex={userindex}>
              <Bar
                percent={item.value}
                platform={userindex}
                isDisabled={isRenderedChartDisabled}
                isForPdf={isForPdf}
              />
            </BarWrapper>
          </ItemWrapper>
        ))}
      </ChartWrapper>

      {hasPagination && (
        <Pagination
          currentPage={currentPage}
          maxPagesDisplayCount={maxPagesCount}
          handlePageChange={onPageChange}
          pageLabelBuilder={getPaginationLabels}
          isMaxPagesLimitExceded={false}
          disableInitialCallback
        />
      )}

      {selectedChart.notEnoughData && (
        <NotEnoughDataForChartMessage platform={userindex} isForPdf={isForPdf} />
      )}
    </Wrapper>
  )
}

HorizontalBarChart.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.number,
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
          barTip: PropTypes.shape({
            percent: PropTypes.string,
            count: PropTypes.string,
          }),
        })
      ),
      notEnoughData: PropTypes.bool,
    })
  ).isRequired,
  userindex: PropTypes.string,
  isMultiChart: PropTypes.bool,
  chartTitle: PropTypes.string,
  isDisabledChart: PropTypes.bool,
  itemsPerPage: PropTypes.number,
  isForPdf: PropTypes.bool,
  isSmall: PropTypes.bool,
  isForPdfAudience: PropTypes.bool,
}

export default HorizontalBarChart
