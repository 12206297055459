import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Paper,
  Stack,
  Dialog,
  Button,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormHelperText,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { CalendarPicker, LocalizationProvider } from '@mui/x-date-pickers'
import useResponsive from '../../hooks/useResponsive'

export default function DateRangePicker({
  title = 'Select date range',
  variant = 'input',
  //
  startDate,
  endDate,
  //
  onChangeStartDate,
  onChangeEndDate,
  //
  open,
  onClose,
  //
  isError,
  singleCalendar,
}) {
  const isDesktop = useResponsive('up', 'md')

  const isCalendarView = variant === 'calendar'

  const [startDateFromState, setStartDateFromState] = useState(null)
  const [endDateFromState, setEndDateFromState] = useState(null)

  const onChangeStartDateFromState = date => {
    setStartDateFromState(date)
  }

  const onChangeEndDateFromState = date => {
    setEndDateFromState(date)
  }

  const onApply = () => {
    if (startDateFromState && onChangeStartDate) {
      onChangeStartDate(startDateFromState)
    }

    if (endDateFromState && onChangeEndDate) {
      onChangeEndDate(endDateFromState)
    }

    onClose()
  }

  useEffect(() => {
    if (startDate) {
      onChangeStartDateFromState(startDate)
    }
    if (endDate) {
      onChangeEndDateFromState(endDate)
    }
  }, [endDate, startDate])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog
        fullWidth
        maxWidth={isCalendarView ? false : 'xs'}
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            ...(isCalendarView && {
              maxWidth: singleCalendar ? 420 : 720,
            }),
          },
        }}
      >
        <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

        <DialogContent
          sx={{
            ...(isCalendarView &&
              isDesktop && {
                overflow: 'unset',
              }),
          }}
        >
          <Stack
            spacing={isCalendarView ? 3 : 2}
            direction={isCalendarView && isDesktop ? 'row' : 'column'}
            justifyContent="center"
            sx={{
              pt: 1,
              '& .MuiCalendarPicker-root': {
                ...(!isDesktop && {
                  width: 'auto',
                }),
              },
            }}
          >
            {isCalendarView ? (
              singleCalendar ? (
                <Paper
                  variant="outlined"
                  sx={{ borderRadius: 2, borderColor: 'divider', borderStyle: 'dashed' }}
                >
                  <CalendarPicker date={endDateFromState} onChange={onChangeEndDateFromState} />
                </Paper>
              ) : (
                <>
                  <Paper
                    variant="outlined"
                    sx={{ borderRadius: 2, borderColor: 'divider', borderStyle: 'dashed' }}
                  >
                    <CalendarPicker
                      date={startDateFromState}
                      onChange={onChangeStartDateFromState}
                    />
                  </Paper>

                  <Paper
                    variant="outlined"
                    sx={{ borderRadius: 2, borderColor: 'divider', borderStyle: 'dashed' }}
                  >
                    <CalendarPicker date={endDateFromState} onChange={onChangeEndDateFromState} />
                  </Paper>
                </>
              )
            ) : (
              <>
                <DatePicker
                  label="Start date"
                  value={startDateFromState}
                  onChange={onChangeStartDateFromState}
                  renderInput={params => <TextField {...params} />}
                />

                <DatePicker
                  label="End date"
                  value={endDateFromState}
                  onChange={onChangeEndDateFromState}
                  renderInput={params => <TextField {...params} />}
                />
              </>
            )}
          </Stack>

          {isError && (
            <FormHelperText error sx={{ px: 2 }}>
              End date must be later than start date
            </FormHelperText>
          )}
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={onClose}>
            Cancel
          </Button>

          <Button disabled={isError} variant="contained" onClick={onApply}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  )
}

DateRangePicker.propTypes = {
  open: PropTypes.bool,
  isError: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  onChangeEndDate: PropTypes.func,
  onChangeStartDate: PropTypes.func,
  variant: PropTypes.oneOf(['input', 'calendar']),
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  singleCalendar: PropTypes.bool,
}
