import React, { useCallback, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { allCampaignsSelector, userGrantSelector } from '../../../redux/selectors'
import { LinearProgress, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
// import { useHistory } from 'react-router-dom'
import BuyCampaignsSeatsContainer from '../../../components/appUserSettings/addOns/changeAddOns/BuyCampaignsSeatsContainer'

const BorderLinearProgress = styled(LinearProgress)({
  borderRadius: 5,
  width: '80%',
})
const StyledTextLimit = styled('span')({
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '18px',
  color: '#637381',
})

export const CampaignLimit = () => {
  // const history = useHistory()

  const { max_users_trackable, enable_tracking, package_advanced } = useSelector(
    userGrantSelector,
    shallowEqual
  )
  const { trackNow, trackMax } = useSelector(allCampaignsSelector)

  const [isBuyTokensModalOpened, setIsBuyTokensModalOpened] = useState(false)
  const toggleIsBuyTokensModalOpened = () => setIsBuyTokensModalOpened(!isBuyTokensModalOpened)

  const isTrackLimitExist = !!enable_tracking || !!trackMax
  const maxTrackLimit = max_users_trackable || trackMax || 0

  const calcLimitLineWidth = useCallback(() => {
    const MAX_WIDTH = 100
    const MIN_WIDTH = 0
    if (!maxTrackLimit || !trackNow) return MIN_WIDTH
    if (maxTrackLimit && trackNow && maxTrackLimit === trackNow) return MAX_WIDTH
    const output = (trackNow * MAX_WIDTH) / maxTrackLimit
    return output < MAX_WIDTH ? output : MAX_WIDTH
  }, [max_users_trackable, trackNow, trackMax])

  // const exceedLimitClickHandler = () => {
  //   if (package_advanced) return history.push('/settings')
  //   toggleIsBuyTokensModalOpened()
  // }

  return (
    <Box sx={{ width: '100%', px: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <BorderLinearProgress variant="determinate" value={calcLimitLineWidth()} />
        <StyledTextLimit>{calcLimitLineWidth()?.toFixed(1)}%</StyledTextLimit>
      </Box>
      <Box sx={{ paddingLeft: '20px' }}>
        <StyledTextLimit>{`Limit: ${trackNow} of ${maxTrackLimit} creators`}</StyledTextLimit>
      </Box>
      {/*<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', my: 2 }}>
        <Button size='small' onClick={exceedLimitClickHandler} variant="outlined">Upgrade</Button>
        </Box>*/}

      <BuyCampaignsSeatsContainer
        isModalOpened={isBuyTokensModalOpened}
        closeModal={toggleIsBuyTokensModalOpened}
      />
    </Box>
  )
}
