import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/de'
import Cookies from 'js-cookie'

dayjs.extend(localizedFormat)

export const LocalizationContext = React.createContext({
  // default values
  currentLang: '',
  localizations: {},
  languageOptions: [],
  genderOptions: [],
  categoryOptions: [],
  countryOptions: [],
  languages: {},
  gender: {},
  categories: {},
  additionalFieldTypes: {},
  audienceGenderOptions: {},
  countries: {},
  flags: {},
  labelStrings: {},
  errors: {},
  changeCurrentLang: () => {},
  pricingPlans: {},
  scoreStrings: {},
  months: {},
  profileSectionDescs: {},
})

const LocalizationProvider = ({ children, localizationObject }) => {
  const {
    localizations,
    languageOptions,
    genderOptions,
    categoryOptions,
    countryOptions,
  } = localizationObject

  const selectedLanguageFromCookies = Cookies.get('selectedLanguage')

  // localizations.setLanguage('de') // use for testing german translations
  const [currentLang, changeCurrentLang] = useState(localizations.getLanguage())

  const changeLang = lang => {
    localizations.setLanguage(lang)
    changeCurrentLang(localizations.getLanguage())
    dayjs.locale(lang)
    Cookies.set('selectedLanguage', lang)
  }

  useEffect(() => {
    if (selectedLanguageFromCookies) {
      changeLang(selectedLanguageFromCookies)
    }
  }, [selectedLanguageFromCookies])

  const {
    languages,
    gender,
    categories,
    additionalFieldTypes,
    audienceGenderOptions,
    flags,
    labelStrings,
    errors,
    countries,
    pricingPlans,
    scoreStrings,
    months,
    profileSectionDescs,
  } = localizations

  const providerValue = {
    currentLang,
    changeCurrentLang: changeLang,
    languageOptions: languageOptions(currentLang),
    genderOptions: genderOptions(currentLang),
    categoryOptions: categoryOptions(currentLang),
    countryOptions: countryOptions(currentLang),
    countries,
    languages,
    gender,
    categories,
    additionalFieldTypes,
    audienceGenderOptions,
    flags,
    labelStrings,
    errors,
    pricingPlans,
    scoreStrings,
    months,
    profileSectionDescs,
  }

  useEffect(() => {
    dayjs.locale(localizations.getLanguage())
  }, [])

  return (
    <LocalizationContext.Provider value={providerValue}>{children}</LocalizationContext.Provider>
  )
}

LocalizationProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  localizationObject: PropTypes.object.isRequired,
}

export default LocalizationProvider
