import styled from 'styled-components'

export const ChartContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
`

export const BarsAndLabelsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
`

export const Bars = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: auto;
`

export const BarWrapper = styled.div`
  width: 8px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  & + & {
    margin-left: 8px;
  }
`

export const BarFilled = styled.div`
  width: 100%;
  min-width: ${props => props.minWidth};
  height: ${props => props.height * 100}%;
  border-radius: 4px;

  ${props => {
    if (props.isActive) {
      return `
        background-color: ${props.theme.scoreRankColors[Math.round(props.score)]};
        `
    } else {
      return `
        background-color: transparent;
        border: 1px solid ${props.theme.scoreRankColors[Math.round(props.score)]};
      `
    }
  }}
`

export const BarIndicator = styled.div`
  position: absolute;
  top: -10px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  height: calc(100% + 10px);
  background-color: ${props => props.theme.colors.darkBrown};

  &:before {
    content: '';
    width: 5px;
    height: 5px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    background-color: ${props => props.theme.colors.darkBrown};
  }
`

export const BarLabels = styled.div`
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 5px;
`

export const BarLabel = styled.div`
  font-size: 10px;
  line-height: 12px;
  line-height: 1;
  color: ${props => props.theme.colors.darkBrown};
  white-space: nowrap;
  position: relative;
  padding-left: 12px;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 10px;
    height: 0.5px;
    background-color: ${props => props.theme.colors.mediumBrown};
  }
`
