import styled from 'styled-components'
import { DialogTitle as DefaultTitle } from '../styledWrappers'

export const EditWrapper = styled.div`
  // padding-top: 12px;
  margin: 20px 0 0 0;
  width: 100%;
`

export const Wrapper = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #ffa093;
  padding: 39px;
  ${props => props.theme.flex.centerAll};
  flex-direction: column;
  position: relative;
  max-width: 500px;
  margin: 0 auto;
`

export const WrapperWithScroll = styled(Wrapper)`
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: unset;
  ${props => props.theme.withScroll};
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #bc9797;
    border-radius: 16px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #784e4e;
    border-radius: 16px;
    border: 2px solid #bc9797;
  }
  &::-webkit-scrollbar-thumb:hover {
    border: 0px solid #f4f4f4;
  }
`

export const IconWrapper = styled.div`
  position: absolute;
  right: 22px;
`

export const CollectionInfoWrapper = styled.div`
  position: relative;
  background-color: ${props =>
    props.isSelected ? props.theme.primaryColor : props.theme.primaryColorVeryLight};
  color: ${props => (props.isSelected ? props.theme.color.white : props.theme.textColor)};

  ${props =>
    props.isSelected &&
    `
  & > span {
    background: ${props.theme.color.white};
    color: ${props.theme.primaryColor};
  }
`}

  font-size: 15px;
  line-height: 18px;
  letter-spacing: 3.5px;
  border-radius: 100vw;
  padding: 12px 16px;
  margin-bottom: 10px;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 150ms;

  &:hover {
    background-color: ${props =>
      props.isSelected ? props.theme.primaryColorLighter : props.theme.primaryColor};
    color: ${props => props.theme.color.white};

    & > span {
      background: ${props => props.theme.color.white};
      color: ${props => props.theme.primaryColor};
    }
  }
`

export const DisabledCollectionInfoWrapper = styled(CollectionInfoWrapper)`
  opacity: 0.7;
  background-color: ${props => props.theme.color.grey};
  color: ${props => props.theme.color.white};
  & > span {
    background-color: ${props => props.theme.primaryColor};
    color: ${props => props.theme.color.white};
  }

  &:hover {
    background-color: ${props => props.theme.color.grey};
    color: ${props => props.theme.color.white};
    & > span {
      background-color: ${props => props.theme.primaryColor};
      color: ${props => props.theme.color.white};
    }
  }
`

export const CreateCollectionWrapper = styled(CollectionInfoWrapper)`
  margin-top: 10px;
  margin-bottom: 30px;
  background-color: ${props => props.theme.primaryColor};
  color: ${props => props.theme.color.white};
  &:hover {
    background-color: ${props => props.theme.primaryColorLighter};
  }
`

export const Split = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  width: 100%;
`

export const CloseWrapper = styled.div`
  position: absolute;
  top: 17px;
  right: 21px;
  cursor: pointer;
`

export const CloseWrapperForScrolledWrapper = styled(CloseWrapper)`
  top: 1%;
  right: 3%;
`

export const TitleWrapper = styled.div`
  text-align: center;
`

export const MessageWrapper = styled.div`
text-align: center;
margin 20px 0;
`

export const DialogBluredWrapper = styled(Wrapper)`
  box-shadow: 0px 6px 20px #8436302b;
  border-radius: 15px;
  & > *:not(button) {
    font-family: ${props => props.theme.fontFamilyInter};
    color: ${props => props.theme.color.mineShaft};
  }
`

export const DialogBluredTitle = styled(DefaultTitle)`
  text-transform: none;
  font-weight: bold;
  font-size: 30px;
  // font-family: ${props => props.theme.fontFamilyInter};
  letter-spacing: 0;
`
