import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useTranslation from '../../../../localization/useTranslation'
import { selectedProfileSelector } from '../../../../redux/selectors'
import { Section, SectionTitle } from '../commonStyles'
import PageLayout from '../PageLayout/PageLayout'
import FollowersHistory from '../../followersHistory/FollowersHistory'
import { prepareProfileChartData } from '../../../../utils/profileCharts'
import { YT_TAB_SHORTS, YT_TAB_VIDEOS } from '../../../../constants/profile'
import { shallowEqual } from 'react-redux'

const AverageViewsChartPage = ({ isBranded = true }) => {
  const { labelStrings } = useTranslation()

  const { profile } = useSelector(selectedProfileSelector, shallowEqual)
  const { _id: profileId, username, _index: platform, dataLog } = profile || {}

  return (
    <PageLayout
      profileId={profileId}
      username={username}
      platform={platform}
      isBranded={isBranded}
    >
      <Section smallPadding>
        <SectionTitle
          platform={platform}
          smallMb
        >{`${labelStrings.average} ${labelStrings.videoViews}`}</SectionTitle>
        <FollowersHistory
          platform={profile._index}
          monthlyGrowthFollowers={profile.monthlyGrowthObject}
          allChartData={prepareProfileChartData(dataLog, platform)}
          ytCurrentType={YT_TAB_VIDEOS}
          isForPdf
          isYt
        />
      </Section>
      <Section smallPadding>
        <SectionTitle
          platform={platform}
          smallMb
        >{`${labelStrings.average} ${labelStrings.shortsViews}`}</SectionTitle>
        <FollowersHistory
          platform={profile._index}
          monthlyGrowthFollowers={profile.monthlyGrowthObject}
          allChartData={prepareProfileChartData(dataLog, platform)}
          ytCurrentType={YT_TAB_SHORTS}
          isForPdf
          isYt
        />
      </Section>
    </PageLayout>
  )
}

AverageViewsChartPage.propTypes = {
  isBranded: PropTypes.bool,
}

export default AverageViewsChartPage
