import magicInstance from '../../new-ui/pages/AudienceReports/MagicLinkInstance'
import httpService from '../../services/httpService'
import { actionProvider } from './actionsProvider'

// action types
const SET_REPORTS_DATA = 'SET_REPORTS_DATA'
const SET_USER_DATA = 'SET_USER_DATA'
const SET_AUTH_KEY_TO_STORE = 'SET_AUTH_KEY_TO_STORE'
const SET_IS_SHOW_AUTH_PAGE = 'SET_IS_SHOW_AUTH_PAGE'
const SET_IS_LIKERS = 'SET_IS_LIKERS'
const SET_ERROR = 'SET_ERROR'
const SET_LOADING = 'SET_ERROR'

const initState = {
  reports: {},
  user: {},
  isLoading: false,
  error: null,
  magic_authKey: '',
  isShowAuthPage: false,
  isLikers: false,
}

// reducer
export const audienceReportsReducer = (state = initState, action = {}) => {
  switch (action.type) {
    case SET_REPORTS_DATA:
      return { ...state, reports: action.payload }
    case SET_USER_DATA:
      return { ...state, user: action.payload }
    case SET_AUTH_KEY_TO_STORE:
      return { ...state, magic_authKey: action.payload }
    case SET_IS_SHOW_AUTH_PAGE:
      return { ...state, isShowAuthPage: action.payload }
    case SET_IS_LIKERS:
      return { ...state, isLikers: action.payload }
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

// actions
export const setReportsData = payload => actionProvider(SET_REPORTS_DATA, payload)
export const setUserData = payload => actionProvider(SET_USER_DATA, payload)
export const setAuthKey = payload => actionProvider(SET_AUTH_KEY_TO_STORE, payload)
export const setIsShowAuthPage = payload => actionProvider(SET_IS_SHOW_AUTH_PAGE, payload)
export const setIsLikersValue = payload => actionProvider(SET_IS_LIKERS, payload)
export const changeLoading = payload => actionProvider(SET_LOADING, payload)
export const setError = payload => actionProvider(SET_ERROR, payload)

// async actions
export const getReport = ({ username, instagramId, teamId }) => async dispatch => {
  dispatch([setIsShowAuthPage(false), changeLoading(true)])
  try {
    const reportData = await httpService.audiencesGetReport({ instagramId, username, teamId })

    if (reportData?.status) {
      dispatch(setReportsData(reportData))
    }
  } catch (err) {
    const isNoAuthStatus = err?.response?.status === 401
    if (isNoAuthStatus) {
      localStorage.removeItem('magic_authKey')
      dispatch([setIsShowAuthPage(true), setReportsData({}), setUserData({}), setAuthKey('')])
    }
  } finally {
    dispatch(changeLoading(false))
  }
}

export const onJoinTeam = ({ magicId, joinCode }) => async dispatch => {
  dispatch(changeLoading(true))

  try {
    const response = await httpService.joinTeam({ magicId, joinCode })

    if (response?.status === 200) {
      dispatch({
        type: 'JOIN_TEAM_SUCCESS',
        payload: response.data,
      })
      // Dispatch actions to update the state with the joined team data
      // Additional actions or state updates based on the response
    } else {
      // Handle other status codes if needed
      console.error('Error joining team:', response)
      // Dispatch an action to handle the error case
      dispatch({
        type: 'JOIN_TEAM_ERROR',
        payload: response,
      })
    }
  } catch (err) {
    console.error('Error joining team:', err)
    // Dispatch an action to handle the error case
    dispatch({
      type: 'JOIN_TEAM_ERROR',
      payload: err,
    })
  } finally {
    dispatch(changeLoading(false))
  }
}

export const onUserLoginByMagicLink = email => async dispatch => {
  try {
    const authKey = await magicInstance.auth.loginWithMagicLink({ email })

    if (authKey) {
      localStorage.setItem('magic_authKey', authKey)
      dispatch([onUserLogin(authKey), setAuthKey(authKey)])
    }
  } catch (err) {
    console.error(err)
  }
}

export const onUserLogin = token => async dispatch => {
  dispatch(changeLoading(true))

  try {
    const userData = await httpService.audiencesLogin(token)
    if (userData?.email) {
      dispatch(setUserData(userData))

      // Set the authKey cookie
      const cookieName = 'magic_authKey'
      const cookieValue = token
      const cookieOptions = {
        expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000), // Set the expiration date (e.g., 1 day from now)
        path: '/', // Set the path where the cookie is valid
        secure: true, // Set to true if using HTTPS
        sameSite: 'strict', // Adjust the SameSite attribute as needed
      }

      // Set the cookie using the cookie options
      document.cookie = `${cookieName}=${cookieValue}; expires=${cookieOptions.expires.toUTCString()}; path=${
        cookieOptions.path
      }; secure=${cookieOptions.secure}; SameSite=${cookieOptions.sameSite}`
    }

    dispatch(changeLoading(false))
  } catch (err) {
    console.error(err)
    dispatch([changeLoading(false), setError(err)])
  }
}

export const renewSubscription = () => async () => {
  try {
    const response = await httpService.renewSubscriptionNow()
    return response.status
  } catch (err) {
    return err.response.status
  }
}
