import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import useTranslation from '../../../localization/useTranslation'
import { userGrantSelector } from '../../../redux/selectors'
import UnavailableProfileSection from '../../common/unavailableProfileSection'
import InfoTip from '../../common/tips/InfoTip'
import {
  SectionTitleWrapper,
  SectionTitleName,
  SectionTitleBorder,
} from '../commonProfilePageStyles'
import { ContactSalesWrapper } from './commonStyles'
import ActivityYT from './youTube/Activity'
import AudienceEngagementOverall from './youTube/AudienceEngagement/AudienceEngagement'
import AudienceEngagementShorts from './youTube/AudienceEngagement/AudienceEngagementShorts'
import AudienceEngagementVideos from './youTube/AudienceEngagement/AudienceEngagementVideos'

const DetailedInfludataRatingYT = ({ profile }) => {
  const { profileScore, _index: platform } = profile
  const { labelStrings, profileSectionDescs } = useTranslation()

  const { basic_profile_stats } = useSelector(userGrantSelector, shallowEqual)
  const isSectionDisabled = !basic_profile_stats

  return (
    <>
      <SectionTitleWrapper>
        <SectionTitleName platform={platform}>
          {labelStrings.detailedQualityRating}
        </SectionTitleName>
        <InfoTip>
          <div dangerouslySetInnerHTML={{ __html: profileSectionDescs.detailedQualityRating }} />
        </InfoTip>
        <SectionTitleBorder />
      </SectionTitleWrapper>

      {!isSectionDisabled ? (
        <>
          <ActivityYT platform={platform} profileScores={profileScore} />

          <AudienceEngagementOverall platform={platform} profileScores={profileScore} />
          <AudienceEngagementVideos platform={platform} profileScores={profileScore}/>
          <AudienceEngagementShorts platform={platform} profileScores={profileScore}/>
        </>
      ) : (
        <ContactSalesWrapper>
          <UnavailableProfileSection
            unavailableSectionLabel={labelStrings.toSeeDetailedInfludataRating}
            faqQuestionId=""
            linkToFaqText={labelStrings.whatIsDetailedInfludataRating}
          />
        </ContactSalesWrapper>
      )}
    </>
  )
}

export default DetailedInfludataRatingYT
