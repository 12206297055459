import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import useTranslation from '../../../../localization/useTranslation'
import { selectedProfileSelector } from '../../../../redux/selectors'
import AudienceTagsCloud from '../../audienceAnalysis/audienceAnalysisCharts/AudienceTagsCloud'
import {
  AudienceGenderChart,
  AudienceLanguageChart,
  AudienceTypeChart,
} from '../../audienceAnalysis/audienceAnalysisCharts/index'
import { Section, SectionTitle } from '../commonStyles'
import PageLayout from '../PageLayout/PageLayout'
import * as Styled from './styles'
import {
  SectionTitleBorder,
  SectionTitleName,
  SectionTitleWrapper,
} from '../../commonProfilePageStyles'

const AudienceAnalysisPage = ({ isBranded = true }) => {
  const { labelStrings } = useTranslation()
  const { profile } = useSelector(selectedProfileSelector, shallowEqual)
  const {
    _id: profileId,
    username,
    _index: platform,
    followers: followersNumber,
    audienceAnalysis,
  } = profile || {}

  if (!audienceAnalysis) return null

  const { languageArray, genderObject, sentimentAnalysis, wordCloud } = audienceAnalysis

  return (
    <PageLayout profileId={profileId} username={username} platform={platform} isBranded={isBranded}>
      <Section>
        <SectionTitle platform={platform}>{labelStrings.audienceAnalysis}</SectionTitle>

        <Styled.Grid>
          <Styled.GridItem>
            <SectionTitleWrapper>
              <SectionTitleName platform={platform} isForPdf={true}>
                {labelStrings.language}
              </SectionTitleName>
              <SectionTitleBorder isForPdf />
            </SectionTitleWrapper>
            <AudienceLanguageChart
              userindex={platform}
              languageArray={languageArray}
              isDisabledAnalysis={false}
              followersCount={followersNumber}
              isForPdf
            />
          </Styled.GridItem>

          <Styled.GridItem>
            <SectionTitleWrapper>
              <SectionTitleName platform={platform} isForPdf={true}>
                {labelStrings.gender}
              </SectionTitleName>
              <SectionTitleBorder isForPdf />
            </SectionTitleWrapper>
            <AudienceGenderChart
              userindex={platform}
              genderMtoF={genderObject}
              isDisabledAnalysis={false}
              isForPdf
            />
          </Styled.GridItem>

          <Styled.GridItem>
            <SectionTitleWrapper>
              <SectionTitleName platform={platform} isForPdf={true}>
                {labelStrings.audienceType}
              </SectionTitleName>
              <SectionTitleBorder isForPdf />
            </SectionTitleWrapper>
            <AudienceTypeChart
              userindex={platform}
              audienceType={sentimentAnalysis}
              isDisabledAnalysis={false}
              isForPdf
            />
          </Styled.GridItem>

          <Styled.GridItem>
            <SectionTitleWrapper>
              <SectionTitleName platform={platform} isForPdf={true}>
                {labelStrings.wordCloudComments}
              </SectionTitleName>
              <SectionTitleBorder isForPdf />
            </SectionTitleWrapper>
            <AudienceTagsCloud userindex={platform} data={wordCloud} isForPdf />
          </Styled.GridItem>
        </Styled.Grid>
      </Section>
    </PageLayout>
  )
}

AudienceAnalysisPage.propTypes = {
  isBranded: PropTypes.bool,
}

export default AudienceAnalysisPage
