import React from 'react'

class UpgradePage extends React.Component {
	render() {
		return (
			<div>
				<h1>Upgrade now!</h1>
				<p>The functionality you requested is not available in your plan.</p>

				<p>
					Upgrade now to receive instant access.
				</p>
			</div>
		)
	}
}

export default UpgradePage
