import React from 'react'
import PropTypes from 'prop-types'

import { USER_INDEXES } from '../../../../constants/appSettings'
import useTranslation from '../../../../localization/useTranslation'

import { Portal } from '../../Portal/Portal'
import { GradientButton, CancelButton } from '../../buttons/styledButtons'
import {
  ModalOverlay,
  ModalWrapper,
  ModalWindow,
  ModalContent,
  ConfirmationModalMessage,
  ConfirmationModalFooter,
} from './commonStyles'

const ConfirmationModal = ({
  platform = '',
  confirmationText = '',
  confirmButtonText = '',
  cancelButtonText = '',
  onConfirm,
  onCancel,
}) => {
  const { labelStrings } = useTranslation()

  return (
    <Portal wrapperId='confirmation-modal-portal'>
      <ModalOverlay platform={platform} className="confirmation-modal">
        <ModalWrapper>
          <ModalWindow>
            <ModalContent>
              <ConfirmationModalMessage>
                {confirmationText || `${labelStrings.areYouSure}?`}
              </ConfirmationModalMessage>
              <ConfirmationModalFooter>
                <GradientButton platform={platform} onClick={onConfirm}>
                  {confirmButtonText || labelStrings.confirm}
                </GradientButton>
                <CancelButton onClick={onCancel}>
                  {cancelButtonText || labelStrings.cancel}
                </CancelButton>
              </ConfirmationModalFooter>
            </ModalContent>
          </ModalWindow>
        </ModalWrapper>
      </ModalOverlay>
    </Portal>
  )
}

ConfirmationModal.propTypes = {
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube]),
  confirmationText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
}

export default ConfirmationModal
