import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Icon = styled.span`
    margin: ${props => (props.tabIcon ? 0 : 6)}px;
    cursor: pointer;
    .block-user-last-path {
      stroke: ${props => {
        if (props.isActiveIcon) {
          if (props.tabIcon) {
            return props.inFocus
              ? props.theme.getColorPaletteForPlatform(props.userindex).main
              : props.theme.color.darkGrey
          }
          return props.theme.getColorPaletteForPlatform(props.userindex).main
        }
        if (props.isWhite) return props.theme.color.white
        return props.isGrey ? props.theme.colors.mauve : props.theme.colors.darkBrown
      }};      
    }

    svg {
      margin-bottom: ${props => props.isArrow ? 0 : '1.5px'};
      path {
        fill: ${props => {
          if (props.isActiveIcon) {
            if (props.tabIcon) {
              return props.inFocus
                ? props.theme.getColorPaletteForPlatform(props.userindex).main
                : props.theme.color.darkGrey
            }
            if(props.isArrow) return props.theme.color.white
            return props.theme.getColorPaletteForPlatform(props.userindex).main
          }
          if (props.isWhite) return props.theme.color.white
          if(props.isArrow) return props.theme.colors.mauve
          return props.theme.colors.darkBrown;
        }};
      }
    }

    &:hover {
      .block-user-last-path{
            stroke: ${props => {
              if (props.isWhite) return props.theme.color.white
              if (props.tabIcon) {
                return props.inFocus
                    ? props.theme.pickColorDependOnPlatform(props.userindex).light
                    : // : props.theme.color.darkGrey
                  props.isGrey
                    ? props.theme.color.grey
                    : props.theme.color.darkGrey
              }
              return props.theme.colors.mauve
            }}; }
      svg {
        path {
          fill: ${props => {
            if (props.isWhite) return props.theme.color.white
            if (props.tabIcon) {
              return props.inFocus
                  ? props.theme.pickColorDependOnPlatform(props.userindex).light
                  : // : props.theme.color.darkGrey
                props.isGrey
                  ? props.theme.colors.mauve
                  : props.theme.colors.darkBrown
            }
            if(!props.isActive && props.isArrow) {
              return props.theme.colors.darkBrown
            }
            return props.theme.colors.mauve
          }};
        }
      }
  `

const IconContainer = ({
  isActiveIcon = 'undefined',
  tabIcon = false,
  isWhite = false,
  isGrey = false,
  inFocus = false,
  userindex = '',
  children = null,
  isArrow = false
}) => {
  const isActive = isActiveIcon === undefined ? true : isActiveIcon
  return (
    <Icon
      isActiveIcon={isActive}
      tabIcon={tabIcon}
      isWhite={isWhite}
      isGrey={isGrey}
      userindex={userindex}
      inFocus={inFocus}
      isArrow={isArrow}
      className="_icon-container"
    >
      {children}
    </Icon>
  )
}

IconContainer.propTypes = {
  children: PropTypes.node.isRequired,
  isActiveIcon: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(undefined)]),
  tabIcon: PropTypes.bool,
  isWhite: PropTypes.bool,
  isGrey: PropTypes.bool,
  inFocus: PropTypes.bool,
  isArrow: PropTypes.bool,
  userindex: PropTypes.string,
}

export default IconContainer
