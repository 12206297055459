import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { withReduxActions } from '../../../hocs/withRedux'
import {
  addElementToOpenedCollection,
  removeElementFromOpenedCollection,
  moveElementFromOpenedCollection,
} from '../../../redux/ducks/collectionsDucks'
import {
  collectionsSelector,
  openedCollectionSelector,
  collectionUsersCountDiffSelector,
} from '../../../redux/selectors'
import { filterSelectedUsersFromUsersList, delayFunc } from '../../../utils'

import CollectionsBulkActions from './CollectionsBulkActions'
import CollectionBulkModalsMediator from './CollectionBulkModalsMediator'

const CollectionsBulkActionsContainer = ({
  collectionId,
  removeElementFromOpenedCollection,
  addElementToOpenedCollection,
  moveElementFromOpenedCollection,
}) => {
  const collectionDataArr = useSelector(collectionsSelector)
  const openedCollection = useSelector(openedCollectionSelector)
  const getCollectionCountDiff = useSelector(collectionUsersCountDiffSelector)

  const DIALOG_TYPES = {
    copy: 'copy',
    remove: 'remove',
    move: 'move',
  }

  const [openDialogType, changeOpenDialogType] = useState(null)
  const [showSuccessMsg, toggleShowSuccessMsg] = useState(false)
  const [stateCollectionsArray, updateStateCollectionsArray] = useState(collectionDataArr)
  const [successOperationDetails, setSuccessOperationDetails] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    // watch on collections array changes to show the user success message
    const { collectionId } = successOperationDetails
    if (collectionId) {
      const targetCollection = collectionDataArr.find(c => c.collectionId === collectionId)
      const targetPrevCollection = stateCollectionsArray.find(c => c.collectionId === collectionId)
      if (
        targetCollection &&
        targetPrevCollection &&
        targetCollection.count !== targetPrevCollection.count
      ) {
        toggleShowSuccessMsg(!showSuccessMsg)
        updateStateCollectionsArray(collectionDataArr)
      }
    }
  }, [collectionDataArr])

  const selectedUsersInOpenedCollection = filterSelectedUsersFromUsersList(openedCollection.users.userdata)
  const selectedUsersIds = selectedUsersInOpenedCollection.map(u => u._id)
  const areAnySelectedInOpenedCollection = !!selectedUsersInOpenedCollection.length

  const buttonsClicker = dialogType => () => changeOpenDialogType(dialogType)

  const commonItemsHandler = async (asyncCB, _collectionId) => {
    changeOpenDialogType(null)
    setIsLoading(true)
    const success = await asyncCB(selectedUsersIds, _collectionId)
    setIsLoading(false)
    success && closeModalHandler(_collectionId)
    success && toggleShowSuccessMsg(!showSuccessMsg)
  }

  const removeItemsHandler = async () =>
    await commonItemsHandler(removeElementFromOpenedCollection, collectionId)

  const moveItemsHandler = async targetCollectionId =>
    await commonItemsHandler(moveElementFromOpenedCollection, targetCollectionId)

  const copyItemsHandler = async collectionId =>
    await commonItemsHandler(addElementToOpenedCollection, collectionId)

  const manageCommonHandler = (type, collectionId) => {
    if (type === DIALOG_TYPES.copy) copyItemsHandler(collectionId)
    if (type === DIALOG_TYPES.move) moveItemsHandler(collectionId)
  }

  const closeModalHandler = collectionId => {
    if (collectionId) {
      const { prevCount, latestCount, diff = 0 } = getCollectionCountDiff(collectionId) || {}

      const _calcDiff = () => {
        if (diff === selectedUsersIds.length) return selectedUsersIds.length
        if (diff < selectedUsersIds.length) return diff
      }
      const _calcDuplicate = () => {
        return diff < selectedUsersIds.length ? selectedUsersIds.length - diff : 0
      }
      const itemsCount =
        openDialogType === DIALOG_TYPES.copy ? _calcDiff() : selectedUsersIds.length
      setSuccessOperationDetails({
        type: openDialogType,
        itemsCount,
        collectionId,
        ...(openDialogType === DIALOG_TYPES.copy && { duplicateCount: _calcDuplicate() }),
      })
      delayFunc(closeSuccessMsg, 2000)
    }
    changeOpenDialogType(null)
  }

  const closeSuccessMsg = () => {
    if (openDialogType) changeOpenDialogType(null)
    toggleShowSuccessMsg(false)
    setSuccessOperationDetails({})
  }

  // vars
  const manageColelctionsItemsList = collectionDataArr.filter(
    el => el.collectionId !== collectionId
  )

  const isAnySelected =
    areAnySelectedInOpenedCollection ||
    showSuccessMsg ||
    !!Object.keys(successOperationDetails).length

  return (
    <>
      <CollectionsBulkActions
        isAnySelected={isAnySelected}
        isLoading={isLoading}
        showSuccessMsg={showSuccessMsg}
        successOperationDetails={successOperationDetails}
        closeSuccessMsg={closeSuccessMsg}
        buttonsClicker={buttonsClicker}
        modalsTypes={DIALOG_TYPES}
      />

      {/* modal popop depending on dialog type */}
      <CollectionBulkModalsMediator
        openDialogType={openDialogType}
        modalsTypes={DIALOG_TYPES}
        closeModalHandler={closeModalHandler}
        removeItemsHandler={removeItemsHandler}
        manageColelctionsItemsList={manageColelctionsItemsList}
        manageCommonHandler={manageCommonHandler}
        removeItemsCount={selectedUsersInOpenedCollection.length}
      />
    </>
  )
}

CollectionsBulkActionsContainer.propTypes = {
  collectionId: PropTypes.string.isRequired,
  removeElementFromOpenedCollection: PropTypes.func,
  addElementToOpenedCollection: PropTypes.func,
  moveElementFromOpenedCollection: PropTypes.func,
}

export default withReduxActions({
  addElementToOpenedCollection,
  removeElementFromOpenedCollection,
  moveElementFromOpenedCollection,
})(CollectionsBulkActionsContainer)
