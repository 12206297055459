import { USER_INDEXES } from '../constants/appSettings'
import { MIN_CHART_COUNT } from '../constants/components'

export const prepareProfileChartData = (chartData, platform) => {
  const preparedChartData = {
    followers: [],
    views: [],
    medianViewsVideos: [],
    medianViewsShorts: [],
  }

  if (!chartData || !chartData?.length || chartData?.length < MIN_CHART_COUNT) {
    return preparedChartData
  }

  const initialLengthArray = chartData[0]?.map(() => new Array())
  const chartDataAccumulator = initialLengthArray

  chartData?.length && chartData.forEach(charDataItem => {
    const date = charDataItem[0]
    return charDataItem?.forEach((item, index) => {
      if (index === 0) return
      if (item !== undefined && item !== null) {
        return chartDataAccumulator[index - 1]?.push([date, item])
      }
    })
  })

  switch (platform) {
    case USER_INDEXES.instagram:
      preparedChartData.followers = chartDataAccumulator[0]
      break
    case USER_INDEXES.tiktok:
      preparedChartData.followers = chartDataAccumulator[0]
      preparedChartData.views = chartDataAccumulator[1]
      break
    case USER_INDEXES.youtube:
      preparedChartData.followers = chartDataAccumulator[0]
      preparedChartData.medianViewsVideos = chartDataAccumulator[1]
      preparedChartData.medianViewsShorts = chartDataAccumulator[2]
      break
    default:
      break
  }

  return preparedChartData
}
