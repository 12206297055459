import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Avatar, ListItemAvatar } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { StyledAddedText, StyledListItem, StyledListItemText } from './styles'
import useTranslation from '../../../../../localization/useTranslation'
import { TooltipComponent } from '../../../../components/tooltip/TooltipComponent'

export const CreatorsListItem = props => {
  let {
    creator = {},
    showList = false,
    isAllowedToAddCreators = false,
    creatorIdFromState = '',
    setCreatorIdFromState,
    onClearInput,
    isSetCreatorId = true,
  } = props || {}

  isAllowedToAddCreators = true

  const { labelStrings } = useTranslation()

  const [loadingCreator, setLoadingCreator] = useState(false)
  const [selectedCreatorId, setSelectedCreatorId] = useState(null)

  const findCreatorInTable = creatorId => creatorId === creatorIdFromState
  const findCreatorsInTable = creatorId => creatorIdFromState?.includes(creatorId)

  const onOpenCreatorProfile = () => {
    window.open(`${process.env.REACT_APP_PREFIX}profile/${creator._id}`, '_blank')
  }

  const handleAddCreator = (e, creatorId) => {
    e.stopPropagation()

    if (!isAllowedToAddCreators) return

    setCreatorIdFromState(creatorId)
    setSelectedCreatorId(creatorId)

    if (isSetCreatorId) {
      setLoadingCreator(true)
      onClearInput()
    }
  }

  useEffect(() => {
    if (findCreatorInTable(selectedCreatorId) && loadingCreator) {
      setLoadingCreator(false)
      setSelectedCreatorId(null)
    }
  }, [creatorIdFromState])

  return (
    <StyledListItem
      onClick={onOpenCreatorProfile}
      secondaryAction={
        <>
          {isSetCreatorId
            ? findCreatorInTable(creator._id)
            : findCreatorsInTable(creator._id) && (
                <StyledAddedText>{labelStrings.added}</StyledAddedText>
              )}

          {!findCreatorInTable(creator._id) && !findCreatorsInTable(creator._id) && (
            <TooltipComponent
              text={!isAllowedToAddCreators ? labelStrings.addCreatorsLimitMessage : ''}
            >
              <div>
                <LoadingButton
                  loading={loadingCreator && showList}
                  variant="outlined"
                  onClick={e => {
                    handleAddCreator(e, isSetCreatorId ? creator._id : creator)
                  }}
                  sx={{ width: '32px', height: '28px', minWidth: '32px' }}
                  disabled={!isAllowedToAddCreators}
                >
                  {!loadingCreator && <AddRoundedIcon />}
                </LoadingButton>
              </div>
            </TooltipComponent>
          )}
        </>
      }
    >
      <ListItemAvatar>
        <Avatar
          alt={creator.username ? creator.username : creator.displayName}
          src={creator.profilePicURL}
        />
      </ListItemAvatar>
      <StyledListItemText
        primary={creator.username ? `@${creator.username}` : `@${creator.displayName}`}
      />
    </StyledListItem>
  )
}

CreatorsListItem.propTypes = {
  creator: PropTypes.object,
  showList: PropTypes.bool,
  isAllowedToAddCreators: PropTypes.bool,
  isPipeline: PropTypes.bool,
  onAssignCreators: PropTypes.func,
  creatorIdFromState: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  setCreatorIdFromState: PropTypes.func,
  onClearInput: PropTypes.func,
  isSetCreatorId: PropTypes.bool,
}