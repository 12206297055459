import httpService from '../../services/httpService'
import { changeProfileAdditionalFields } from './profilesCommonActions'

const GET_ADDITIONAL_FIELDS_PENDING = 'GET_ADDITIONAL_FIELDS_PENDING'
const GET_ADDITIONAL_FIELDS_SUCCEEDED = 'GET_ADDITIONAL_FIELDS_SUCCEEDED'
const GET_ADDITIONAL_FIELDS_FAILED = 'GET_ADDITIONAL_FIELDS_FAILED'
const CREATE_ADDITIONAL_FIELD_PENDING = 'CREATE_ADDITIONAL_FIELD_PENDING'
const CREATE_ADDITIONAL_FIELD_SUCCEEDED = 'CREATE_ADDITIONAL_FIELD_SUCCEEDED'
const CREATE_ADDITIONAL_FIELD_FAILED = 'CREATE_ADDITIONAL_FIELD_FAILED'
const CHANGE_ADDITIONAL_FIELD_PENDING = 'CHANGE_ADDITIONAL_FIELD_PENDING'
const CHANGE_ADDITIONAL_FIELD_SUCCEEDED = 'CHANGE_ADDITIONAL_FIELD_SUCCEEDED'
const CHANGE_ADDITIONAL_FIELD_FAILED = 'CHANGE_ADDITIONAL_FIELD_FAILED'
const DELETE_ADDITIONAL_FIELD_SUCCEEDED = 'DELETE_ADDITIONAL_FIELD_SUCCEEDED'

const initialState = {
  additionalFieldsList: [],
  isLoading: false,
  isCreating: false,
  isChanging: false,
  isDeleting: false,
}

export const additionalFieldsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADDITIONAL_FIELDS_PENDING:
      return { ...state, isLoading: true }
    case GET_ADDITIONAL_FIELDS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        additionalFieldsList: action.payload,
      }
    case GET_ADDITIONAL_FIELDS_FAILED:
      return { ...state, isLoading: false }
    case CREATE_ADDITIONAL_FIELD_PENDING:
      return { ...state, isCreating: true }
    case CREATE_ADDITIONAL_FIELD_SUCCEEDED:
      return {
        ...state,
        isCreating: false,
        additionalFieldsList: [...state.additionalFieldsList, action.payload],
      }
    case CREATE_ADDITIONAL_FIELD_FAILED:
      return { ...state, isCreating: false }
    case CHANGE_ADDITIONAL_FIELD_PENDING:
      return { ...state, isChanging: true }
    case CHANGE_ADDITIONAL_FIELD_SUCCEEDED:
      return {
        ...state,
        isChanging: false,
        additionalFieldsList: state.additionalFieldsList.map(item => {
          if (item.code === action.payload.code) return action.payload
          return item
        }),
      }
    case CHANGE_ADDITIONAL_FIELD_FAILED:
      return { ...state, isChanging: false }
    case DELETE_ADDITIONAL_FIELD_SUCCEEDED:
      return {
        ...state,
        additionalFieldsList: state.additionalFieldsList.filter(item => {
          return item.code !== action.payload
        }),
      }
    default:
      return state
  }
}

export const getAdditionalFieldsPending = () => dispatch => {
  dispatch({ type: GET_ADDITIONAL_FIELDS_PENDING })
}

export const getAdditionalFieldsSucceeded = additionalFields => dispatch => {
  dispatch({
    type: GET_ADDITIONAL_FIELDS_SUCCEEDED,
    payload: additionalFields,
  })
}

export const getAdditionalFieldsFailed = () => dispatch => {
  dispatch({ type: GET_ADDITIONAL_FIELDS_FAILED })
}

export const createAdditionalField = ({ label, type, allowedValues = null }) => async dispatch => {
  dispatch({ type: CREATE_ADDITIONAL_FIELD_PENDING })
  try {
    const createdField = await httpService.addAdditionalField({ label, type, allowedValues })
    dispatch({
      type: CREATE_ADDITIONAL_FIELD_SUCCEEDED,
      payload: createdField,
    })
  } catch (err) {
    dispatch({ type: CREATE_ADDITIONAL_FIELD_FAILED })
  }
}

export const changeAdditionalField = ({
  additionalField,
  newLabel = null,
  newAllowedValues = null,
}) => async dispatch => {
  dispatch({ type: CHANGE_ADDITIONAL_FIELD_PENDING })
  try {
    if (newLabel) {
      await httpService.editAdditionalFields({
        fieldCode: additionalField.code,
        fieldProperty: 'label',
        newValue: newLabel,
      })
    }
    if (newAllowedValues) {
      await httpService.editAdditionalFields({
        fieldCode: additionalField.code,
        fieldProperty: 'allowedValues',
        newValue: newAllowedValues,
      })
    }

    dispatch({
      type: CHANGE_ADDITIONAL_FIELD_SUCCEEDED,
      payload: {
        ...additionalField,
        ...(newLabel && { label: newLabel }),
        ...(newAllowedValues && { allowedValues: newAllowedValues }),
      },
    })
  } catch (err) {
    dispatch({ type: CHANGE_ADDITIONAL_FIELD_FAILED })
  }
}

export const deleteAdditionalField = code => async dispatch => {
  let isDeleted = false
  try {
    await httpService.editAdditionalFields({
      fieldCode: code,
      fieldProperty: 'isHidden',
      newValue: 'true',
    })
    isDeleted = true
    dispatch({
      type: DELETE_ADDITIONAL_FIELD_SUCCEEDED,
      payload: code,
    })
  } catch (err) {}
  return isDeleted
}

export const changeAdditionalFieldValue = ({ profileId, valueObj, changeField = 'additionalFields' }) => async dispatch => {
  let isChangedSuccessfully = false
  try {
    await httpService.changeProfileElement({
      objectId: profileId,
      changeField,
      newValue: valueObj,
    })
    dispatch(changeProfileAdditionalFields(valueObj))
    isChangedSuccessfully = true
  } catch (err) {
    isChangedSuccessfully = false
  }
  return isChangedSuccessfully
}
