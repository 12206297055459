import queryString from 'query-string'
import axios from 'axios'
import { actionProvider } from './actionsProvider'
//import { setError } from './errorDucks'
import { areSearchQueriesEqual, filterQueryParams } from '../../utils/search'
import {
  selectedPlatformSelector,
  searchLoadingStatusSelector,
  currentSearchQuerySelector,
  searchDataSelector,
  searchResultsSelector,
  searchResultsPresentSelector,
  searchTypeSelector,
  userGrantSelector,
  currentExploreRecentSearchQuery,
} from '../selectors'
import {
  setSelectedUsersCommonAction,
  modifyProfileElementEverywhere,
} from './profilesCommonActions'
import { triggerSocialPlatform, limitAccess } from './appSettingsDucks'
import { SOCIAL_PLATFORMS_NAMES, API_STATUS_CODES } from '../../constants/appSettings'
import { searchContentTypes, SEARCH_SLIDERS_RANGE } from '../../constants/search'
import {
  SEARCH_SORTING_TYPES,
  SORTING_DIRECTIONS,
  // SORTING_DIRECTIONS_SHORT_TO_LONG_NAMES,
  SORTING_DIRECTIONS_LONG_TO_SHORT_NAMES,
} from '../../constants/sortings'
//import { ERROR_MSG } from '../../constants/errorMessages'
import urlQueryService from '../../services/urlQueryService'
import httpService from '../../services/httpService'
import sessionCacheService from '../../services/sessionCacheService'
import { changeExploreQuery } from './exploreDucks'

// action types
const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS'
const RESET_SEARCH_RESULTS = 'RESET_SEARCH_RESULTS'
const UPDATE_SEARCH_USERS_LIST = 'UPDATE_SEARCH_USERS_LIST'
const CHANGE_CURRENT_SEARCH_QUERY = 'CHANGE_CURRENT_SEARCH_QUERY'
const CHANGE_SEARCH_LOADING_STATUS = 'CHANGE_SEARCH_LOADING_STATUS'
const CHANGE_SEARCH_TYPE = 'CHANGE_SEARCH_TYPE'
const SET_TIMELINE_BUCKET_RESULTS = 'SET_TIMELINE_BUCKET_RESULTS'
const CHANGE_TIMELINE_BUCKET_LOADING = 'CHANGE_TIMELINE_BUCKET_LOADING'
const SET_SEARCH_LOCATION = 'SET_SEARCH_LOCATION'
const SET_CONTENT_SEARCH_ACTIVE_MARKER = 'SET_CONTENT_SEARCH_ACTIVE_MARKER'

const initState = {
  results: {
    users: [],
    content: [],
    count: 0,
    dataSuccess: {},
  },
  timelineBucket: {
    data: [],
  },
  isTimelineBucketLoaded: false,
  searchLocation: [],
  currentSearchQuery: {
    city: '',
    country: null,
    language: null,
    followerMin: SEARCH_SLIDERS_RANGE.min,
    followerMax: SEARCH_SLIDERS_RANGE.max,
    gender: null,
    skipCount: 0,
    additionalSkipsForBlacklisting: 0,
    keywords: '',
    categories: '',
    matchAnyKeyword: false,
    mustIncludeEmail: false,
    appearsOn: '',
    index: SOCIAL_PLATFORMS_NAMES.instagram.index,
    sorting: '',
    growthRate: null,
    engagementRate: null,
    audienceCountries: '',
    audienceGender: '',
    audienceLanguages: '',
    audienceRealPeople: null,
    audienceAgeRanges: '',
    isShortsCreator: '',
    contentTypes: '',
    uploadedFrom: '',
    uploadedTo: '',
    placesId: '',
    radiusKM: '',
    hideCreatorsInCollection: false
  },
  isSearchLoading: false,
  searchType: searchContentTypes.CREATOR,
  searchContentActiveMapMarker: {},
}

const createInitState = () => {
  const socialPlatform = sessionCacheService.getCachedSocialPlatform()
  if (socialPlatform === SOCIAL_PLATFORMS_NAMES.tiktok.name) {
    return {
      ...initState,
      currentSearchQuery: {
        ...initState.currentSearchQuery,
        index: SOCIAL_PLATFORMS_NAMES.tiktok.index,
      },
    }
  }
  if (socialPlatform === SOCIAL_PLATFORMS_NAMES.youtube.name) {
    return {
      ...initState,
      currentSearchQuery: {
        ...initState.currentSearchQuery,
        index: SOCIAL_PLATFORMS_NAMES.youtube.index,
      },
    }
  }
  return initState
}

// reducer
export const searchReducer = (state = createInitState(), action = {}) => {
  switch (action.type) {
    case SET_SEARCH_RESULTS:
      const { results } = action.payload
      return { ...state, results }
    case RESET_SEARCH_RESULTS:
      return { ...createInitState(), searchType: state.searchType }
    case UPDATE_SEARCH_USERS_LIST:
      return {
        ...state,
        results: { ...state.results, users: action.payload },
      }
    case CHANGE_CURRENT_SEARCH_QUERY:
      return {
        ...state,
        currentSearchQuery: action.payload,
      }
    case CHANGE_SEARCH_TYPE:
      return { ...state, searchType: action.payload }
    case CHANGE_SEARCH_LOADING_STATUS:
      return { ...state, isSearchLoading: action.payload }
    case SET_TIMELINE_BUCKET_RESULTS:
      return { ...state, timelineBucket: action.payload }
    case CHANGE_TIMELINE_BUCKET_LOADING:
      return {
        ...state,
        isTimelineBucketLoaded: action.payload,
      }
    case SET_SEARCH_LOCATION:
      return { ...state, searchLocation: action.payload }
    case SET_CONTENT_SEARCH_ACTIVE_MARKER:
      return { ...state, searchContentActiveMapMarker: action.payload }
    default:
      return state
  }
}

// actions
export const setSearchResults = payload => actionProvider(SET_SEARCH_RESULTS, payload)
export const clearSearch = () => actionProvider(RESET_SEARCH_RESULTS)
export const updateSearchUsersList = payload => actionProvider(UPDATE_SEARCH_USERS_LIST, payload)
export const updateCurrentSearchQuery = payload =>
  actionProvider(CHANGE_CURRENT_SEARCH_QUERY, payload)
export const changeSearchLoadingStatus = payload =>
  actionProvider(CHANGE_SEARCH_LOADING_STATUS, payload)
export const setTimelineBucketResults = payload =>
  actionProvider(SET_TIMELINE_BUCKET_RESULTS, payload)
export const changeTimelineBucketLoading = payload =>
  actionProvider(CHANGE_TIMELINE_BUCKET_LOADING, payload)
export const setSearchLocationData = payload => actionProvider(SET_SEARCH_LOCATION, payload)
export const setActiveMapMarker = payload =>
  actionProvider(SET_CONTENT_SEARCH_ACTIVE_MARKER, payload)
export const changeSearchType = payload => actionProvider(CHANGE_SEARCH_TYPE, payload)
// async actions

export const abortSearchProccess = () => (dispatch, getState) => {
  const isLoading = searchLoadingStatusSelector(getState())
  if (isLoading) dispatch(changeSearchLoadingStatus(false))
  httpService.fetchSearchResults(null) //aborts current search api call
}

export const resetSearchResults = history => (dispatch, getState) => {
  sessionCacheService.resetSearchCache()
  history && history.push(process.env.REACT_APP_PREFIX + 'search')
  dispatch(clearSearch())
}
// start up search action
export const fetchStartupSearch = location => async (dispatch, getState) => {
  if (location.search) {
    const currentQuery = currentSearchQuerySelector(getState())

    const latestQuery = urlQueryService.parseCurrentSearchQuery(location, currentQuery)
    const areCurrentResultsPresent = searchResultsPresentSelector(getState())
    if (!areSearchQueriesEqual(currentQuery, latestQuery) || !areCurrentResultsPresent) {
      const { index } = latestQuery
      const newPlatform = Object.values(SOCIAL_PLATFORMS_NAMES)?.find(obj => obj.index === index)
      dispatch([
        triggerSocialPlatform(
          newPlatform ? newPlatform.name : SOCIAL_PLATFORMS_NAMES.instagram.name
        ),
        changeCurrentSearchQuery({ ...latestQuery, radiusKM: '', placesId: '' }),
      ])
    }
  } else {
    const {
      currentSearchQuery: latestQuery,
      results: { users },
    } = searchDataSelector(getState())
    if (users?.length) {
      urlQueryService.setNewSearchQuery({
        latestQuery,
      })
    }
  }
}

export const fetchSearchResults = searchQueryParams => async (dispatch, getState) => {
  const { index, ...restOfQueryParams } = searchQueryParams // index is not included in api search query
  const currentSearchType = searchTypeSelector(getState())
  const { timelineBucket } = searchDataSelector(getState())
  const { enable_ig_search, enable_tt_search, enable_yt_search } = userGrantSelector(getState())
  const platform = selectedPlatformSelector(getState())

  const stringifiedQuery = queryString.stringify({
    ...restOfQueryParams,
    index,
    fromContentSearch: currentSearchType === searchContentTypes.CONTENT ? true : undefined,
  })

  const searchCred = {
    [SOCIAL_PLATFORMS_NAMES.instagram.name]: enable_ig_search,
    [SOCIAL_PLATFORMS_NAMES.tiktok.name]: enable_tt_search,
    [SOCIAL_PLATFORMS_NAMES.youtube.name]: enable_yt_search,
  }

  if (!searchCred[platform]) return

  dispatch(changeSearchLoadingStatus(true))
  try {
    const response =
      (await httpService.fetchSearchResults(stringifiedQuery, currentSearchType)) || {}
    const { users = [], count = 0, content = [], dataSuccess } = response
    dispatch([
      setSearchResults({
        results: {
          users: users.map(u => ({ ...u, isSelected: false })),
          content,
          count,
          dataSuccess,
        },
      }),
      changeSearchLoadingStatus(false),
      !timelineBucket?.data?.length && getTimelineForContent({ ...restOfQueryParams, index }),
    ])
  } catch (err) {
    if (err.response && err.response.status === API_STATUS_CODES.upgradeRequired) {
      return dispatch([limitAccess(), changeSearchLoadingStatus(false)])
    }
    if (!axios.isCancel(err)) {
      //setError(ERROR_MSG.failSearch),
      dispatch(changeSearchLoadingStatus(true))
    }
  }
}

export const updateSearch = ({ newValuesObj }) => (dispatch, getState) => {
  const currentSearchQuery = currentSearchQuerySelector(getState())
  const currentPlatform = selectedPlatformSelector(getState())
  const searchRes = searchResultsSelector(getState())

  const updatedSearchQuery = filterQueryParams(currentSearchQuery, newValuesObj, currentPlatform)
  const isQueryTheSame = areSearchQueriesEqual(currentSearchQuery, updatedSearchQuery)
  if (!isQueryTheSame) {
    urlQueryService.setNewSearchQuery({
      latestQuery: updatedSearchQuery,
    })

    const isRefetch = Object.keys(newValuesObj).every(
      el =>
        el === 'contentTypes' ||
        el === 'uploadedFrom' ||
        el === 'uploadedTo' ||
        el === 'skipCount' ||
        el === 'additionalSkipsForBlacklisting'
    )
    if (isRefetch) {
      dispatch(changeCurrentSearchQuery(updatedSearchQuery))
    } else {
      const updatedSearchQueryWithResetTimeline = filterQueryParams(
        updatedSearchQuery,
        { uploadedFrom: '', uploadedTo: '' },
        currentPlatform
      )

      urlQueryService.setNewSearchQuery({
        latestQuery: updatedSearchQueryWithResetTimeline,
      })

      dispatch(updateCurrentSearchQuery(updatedSearchQueryWithResetTimeline))

      if (!!searchRes.content?.length) {
        dispatch([setTimelineBucketResults({ data: [] }), fetchSearchResults(updatedSearchQuery)])
      } else {
        dispatch(fetchSearchResults(updatedSearchQuery))
      }
    }
  }
}

export const updateRecentSearch = ({ newValuesObj }) => (dispatch, getState) => {
  const currentRecentSearchQuery = currentExploreRecentSearchQuery(getState())
  const currentPlatform = selectedPlatformSelector(getState())
  const searchRes = searchResultsSelector(getState())

  const updatedSearchQuery = filterQueryParams(
    currentRecentSearchQuery,
    newValuesObj,
    currentPlatform
  )
  dispatch(changeExploreQuery(updatedSearchQuery))

  const isQueryTheSame = areSearchQueriesEqual(currentRecentSearchQuery, updatedSearchQuery)
  if (!isQueryTheSame) {
    const isRefetch = Object.keys(newValuesObj).every(
      el =>
        el === 'contentTypes' ||
        el === 'uploadedFrom' ||
        el === 'uploadedTo' ||
        el === 'skipCount' ||
        el === 'additionalSkipsForBlacklisting'
    )

    if (isRefetch) {
      dispatch(fetchSearchResults(updatedSearchQuery))
    } else {
      if (!!searchRes.content?.length) {
        dispatch([setTimelineBucketResults({ data: [] }), fetchSearchResults(updatedSearchQuery)])
      } else {
        dispatch(fetchSearchResults(updatedSearchQuery))
      }
    }
  }
}

export const changeCurrentSearchQuery = updatedSearchQuery => dispatch => {
  dispatch([updateCurrentSearchQuery(updatedSearchQuery), fetchSearchResults(updatedSearchQuery)])
}

export const paginateSearchPage = ({
  skipCount,
  additionalSkipsForBlacklisting,
  isExplore = false,
}) => dispatch => {
  isExplore
    ? dispatch(updateRecentSearch({ newValuesObj: { skipCount, additionalSkipsForBlacklisting } }))
    : dispatch(updateSearch({ newValuesObj: { skipCount, additionalSkipsForBlacklisting } }))
}

// TODO: probably refactor this later
export const setSearchUsersSelected = ({ userId = null, selectAll = true }) => (
  dispatch,
  getState
) => {
  const { users } = searchResultsSelector(getState())
  dispatch(
    setSelectedUsersCommonAction({
      nextAction: updateSearchUsersList,
      users,
      userId,
      selectAll,
    })
  )
}

export const changeUserInSearchList = (idToUpdate, changeField, newValue) => async dispatch => {
  dispatch(modifyProfileElementEverywhere({ profileId: idToUpdate, changeField, newValue }))
}

export const sortSearchResults = ({ sortKey, sortDirection }) => async (dispatch, getState) => {
  if (!sortKey || !sortDirection) return
  const currentSearchQuery = currentSearchQuerySelector(getState())
  const { sorting: currentQuerySorting } = currentSearchQuery
  const updatedQuery = { ...currentSearchQuery, skipCount: 0 }

  if (sortKey === SEARCH_SORTING_TYPES.profileScore.sortKey && !!currentQuerySorting) {
    updatedQuery.sorting = '' // set to default
  } else {
    const newSortingDirection =
      sortDirection === SORTING_DIRECTIONS.descend
        ? ''
        : SORTING_DIRECTIONS_LONG_TO_SHORT_NAMES.ascend

    const updatedSortingStr = newSortingDirection
      ? `${newSortingDirection},${sortKey}`
      : `${sortKey}`
    updatedQuery.sorting = updatedSortingStr
  }

  urlQueryService.setNewSearchQuery({
    latestQuery: updatedQuery,
  })
  dispatch(changeCurrentSearchQuery(updatedQuery))
}

export const sortExploreRecentSearchResults = ({ sortKey, sortDirection }) => async (
  dispatch,
  getState
) => {
  if (!sortKey || !sortDirection) return
  const currentSearchQuery = currentExploreRecentSearchQuery(getState())
  const { sorting: currentQuerySorting } = currentSearchQuery
  const updatedQuery = { ...currentSearchQuery, skipCount: 0 }

  if (sortKey === SEARCH_SORTING_TYPES.profileScore.sortKey && !!currentQuerySorting) {
    updatedQuery.sorting = '' // set to default
  } else {
    const newSortingDirection =
      sortDirection === SORTING_DIRECTIONS.descend
        ? ''
        : SORTING_DIRECTIONS_LONG_TO_SHORT_NAMES.ascend

    const updatedSortingStr = newSortingDirection
      ? `${newSortingDirection},${sortKey}`
      : `${sortKey}`
    updatedQuery.sorting = updatedSortingStr
  }

  dispatch([changeExploreQuery(updatedQuery), fetchSearchResults(updatedQuery)])
}

export const updateSearchTypeStatus = searchType => async (dispatch, getState) => {
  const currentPlatform = selectedPlatformSelector(getState())
  const currentQuery =
    searchType === searchContentTypes.CONTENT &&
    currentPlatform === SOCIAL_PLATFORMS_NAMES.instagram
      ? { ...currentSearchQuerySelector(getState()), contentTypes: 'posts,stories,reels' }
      : currentSearchQuerySelector(getState())

  dispatch(updateCurrentSearchQuery(currentQuery))
  urlQueryService.setNewSearchQuery({
    latestQuery: currentQuery,
  })

  dispatch(changeSearchType(searchType))

  if (
    currentPlatform === SOCIAL_PLATFORMS_NAMES.youtube.name &&
    searchType === searchContentTypes.CONTENT
  ) {
    dispatch(triggerSocialPlatform(SOCIAL_PLATFORMS_NAMES.instagram.name))
  }
}

export const getTimelineForContent = query => async (dispatch, getState) => {
  const currentSearchType = searchTypeSelector(getState())
  const stringifiedQuery = queryString.stringify({
    ...query,
    uploadedFrom: '',
    uploadedTo: '',
  })

  if (currentSearchType !== searchContentTypes.CONTENT) return
  dispatch(setTimelineBucketResults({ data: [] }))

  dispatch(changeTimelineBucketLoading(true))
  try {
    const { timelineBucket = [], ...other } =
      (await httpService.fetchTimelineForContent(stringifiedQuery)) || {}
    dispatch(
      setTimelineBucketResults({
        data: timelineBucket,
        ...other,
      })
    )
    dispatch(changeTimelineBucketLoading(false))
  } catch (err) {
    console.error(err)
  }
}

export const getGoogleLocationByLetters = ({ letters }) => async dispatch => {
  try {
    const googleLocationByLetters = await httpService.getGoogleLocationByLetters({ letters })
    dispatch(setSearchLocationData(googleLocationByLetters))
  } catch (err) {
    console.error(err)
  }
}

export const addSavedContentToCollection = ({ contentIds, collectionId }) => async dispatch => {
  try {
    await httpService.addElementToCollection({ contentIds, collectionId })
    // dispatch(setSearchLocationData(googleLocationByLetters))
  } catch (err) {
    console.error(err)
  }
}

export const removeSavedContentFromCollection = ({
  contentIds,
  collectionId,
}) => async dispatch => {
  //removeElementFromCollection
  try {
    await httpService.removeElementFromCollection({ contentIds, collectionId })
    // dispatch(setSearchLocationData(googleLocationByLetters))
  } catch (err) {
    console.error(err)
  }
}
