import React, { useRef, useState } from 'react'
import { Box, Card, CardContent, CardHeader, IconButton, Menu, MenuItem } from '@mui/material'
import { UsersList } from './UsersList'
import { TooltipComponent } from '../../../components/tooltip/TooltipComponent'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import DownloadIcon from '@mui/icons-material/Download'
import html2canvas from 'html2canvas'
import pptxgen from 'pptxgenjs'

export const BitlyTrackingTable = ({ data, isShowOnlyOverview, helperText }) => {
  const {
    audienceCity,
    audienceCountry,
    audienceDevice,
    audienceReferral,
    audienceReferralDomain,
    overview,
    label
  } = data || {}

  const tableRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleDownloadClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleDownloadClose = () => {
    setAnchorEl(null)
  }

  const exportToPowerPoint = async () => {
    const tableElement = tableRef.current
    const tableImage = await html2canvas(tableElement)
    const tableDataUrl = tableImage.toDataURL()

    const pptx = new pptxgen()
    const slide = pptx.addSlide()

    const tableAspectRatio = tableElement.offsetWidth / tableElement.offsetHeight
    const slideWidth = 10
    const slideHeight = slideWidth / tableAspectRatio

    slide.addImage({
      data: tableDataUrl,
      x: 0,
      y: 0,
      w: slideWidth,
      h: slideHeight,
    })

    // @ts-ignore
    pptx.writeFile('bitlyTrackingTable.pptx')
    handleDownloadClose()
  }

  const exportToImage = async () => {
    const tableElement = tableRef.current
    const tableImage = await html2canvas(tableElement)
    const tableDataUrl = tableImage.toDataURL('image/png')

    const link = document.createElement('a')
    link.href = tableDataUrl
    link.download = 'bitlyTrackingTable.png'
    link.click()
    handleDownloadClose()
  }

  return (
    <Card sx={{ mb: '24px !important', mt: 1 }}>
      <CardHeader
        sx={{ pt: '24px' }}
        title={label}
        action={
          <>
            {helperText?.length && (
              <IconButton aria-label="Helper-Text">
                <TooltipComponent text={helperText}>
                  <InfoOutlinedIcon />
                </TooltipComponent>
              </IconButton>
            )}
            <IconButton aria-label="Download" onClick={handleDownloadClick}>
              <DownloadIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleDownloadClose}>
              <MenuItem onClick={exportToPowerPoint}>Download as PPTX</MenuItem>
              <MenuItem onClick={exportToImage}>Download as Image</MenuItem>
            </Menu>
          </>
        }
      />

      <CardContent
        sx={{
          p: 2,
          pb: `16px !important`,
          '& .MuiTimelineItem-missingOppositeContent:before': {
            display: 'none',
          },
        }}
      >
        <Box ref={tableRef}>
          <UsersList
            overviewRowData={overview}
            audienceCity={audienceCity}
            audienceCountry={audienceCountry}
            audienceDevice={audienceDevice}
            audienceReferral={audienceReferral}
            audienceReferralDomain={audienceReferralDomain}
            isShowOnlyOverview={isShowOnlyOverview}
          />
        </Box>
      </CardContent>
    </Card>
  )
}
