import queryString from 'query-string'
import axios from 'axios'
import httpService from '../../../services/httpService'
import sessionCacheService from '../../../services/sessionCacheService'
import { areSearchQueriesEqual } from '../../../utils/search'

const fetchSuggestionsDecorator = () => {
  let cancelToken = axios.CancelToken.source()
  return async function(data) {
    if (!data && cancelToken) {
      cancelToken.cancel('CANCELED SUGGESTIONS')
      return
    }
    const query = queryString.stringify({ ...data })
    const { keywords } = data
    cancelToken = axios.CancelToken.source()
    if (!data.keywords) return
    try {
      if (keywords !== '') {
        return await httpService.getRelatedTerms(query, {
          cancelToken: cancelToken.token,
        })
      }
    } catch (err) {
      return []
    }
  }
}

export const fetchSuggestions = fetchSuggestionsDecorator()

export const getSuggestionsFromCacheOrFetch = async currentQuery => {
  let suggestionsKeywords = []
  const fromCache = await sessionCacheService.getCachedSuggestions()
  const { query, suggestionsKeywords: suggestionsKeywordsFromCache } = fromCache || {}
  // split out skipCount and sorting from api call
  const { skipCount: querySkipCount, sorting: querySkipSorting, ...restOfQuery } = query || {}
  const { skipCount: currentSkipCount, sorting: currentSkipSorting, ...restOfCurrentQuery } =
    currentQuery || {}
  if (suggestionsKeywordsFromCache && areSearchQueriesEqual(restOfQuery, restOfCurrentQuery)) {
    suggestionsKeywords = suggestionsKeywordsFromCache
  } else {
    suggestionsKeywords = await fetchSuggestions(restOfCurrentQuery)
    if (suggestionsKeywords && suggestionsKeywords.length)
      sessionCacheService.setCachedSuggestions({ query: { ...currentQuery }, suggestionsKeywords })
  }
  return suggestionsKeywords
}

export const cancelAllFetches = () => {
  ;(async () => {
    await fetchSuggestions()
  })()
}
