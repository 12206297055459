import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Tab,
  Tabs,
  Card,
  Table,
  Divider,
  TableBody,
  TableContainer,
  Typography,
  Stack,
  Tooltip,
  Box,
} from '@mui/material'
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
} from '../../../components/table'
import Scrollbar from '../../../components/scrollbar'
import useTranslation from '../../../../localization/useTranslation'
import { OverviewRows } from './TableRows/OverviewRows'
import { DynamicallyRows } from './TableRows/DynamicallyRows'
import { TooltipComponent } from '../../../components/tooltip/TooltipComponent'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { transformDataToTableHead } from './TableRows/utils'

export const UsersList = props => {
  const { labelStrings } = useTranslation()
  const {
    overviewRowData,
    audienceCity,
    audienceCountry,
    audienceDevice,
    audienceReferral,
    audienceReferralDomain,
    isShowOnlyOverview,
  } = props

  const isForPdf = false

  const { dense, page, order, orderBy, rowsPerPage, setPage } = useTable({
    defaultOrderBy: 'overview',
    defaultOrder: 'desc',
  })

  const denseHeight = !dense ? 52 : 72

  const [isHideFields, setIsHideFields] = useState(true)

  const [filterStatus, setFilterStatus] = useState('overview')

  const handleFilterStatus = (event, newValue) => {
    setPage(0)
    setFilterStatus(newValue)
  }

  const TABLE_HEAD_OVERVIEW = [
    { id: 'overview', label: ``, align: 'left' },
    {
      id: 'reach',
      label: `# ${labelStrings.bitlyTrackingTable.clicks}`,
      align: 'center',
    },
    {
      id: 'engagements',
      label: `${labelStrings.bitlyTrackingTable.clicksPDay}`,
      align: 'center',
    },
    {
      id: 'cpm',
      label: labelStrings.bitlyTrackingTable.lastClick,
      align: 'center',
    },
    {
      id: 'cpe',
      label: labelStrings.bitlyTrackingTable.topCountry,
      align: 'center',
    },
    {
      id: 'emv',
      label: labelStrings.bitlyTrackingTable.topReferral,
      align: 'center',
    },
  ]

  const tableHeadAudienceCountry = transformDataToTableHead(audienceCountry)
  const tableHeadAudienceCity = transformDataToTableHead(audienceCity)
  const tableHeadAudienceDevice = transformDataToTableHead(audienceDevice)
  const tableHeadAudienceReferral = transformDataToTableHead(audienceReferral)
  const tableHeadAudienceReferralDomain = transformDataToTableHead(audienceReferralDomain)

  const renderedTableRows = {
    ['overview']: {
      tableRow: TABLE_HEAD_OVERVIEW,
      rowData: isHideFields ? overviewRowData?.slice(0, 1) : overviewRowData,
      renderedRows: row => <OverviewRows row={row} setIsHideFields={setIsHideFields} />,
    },
    ['audience_country']: {
      tableRow: [{ id: 'total', label: ``, align: 'left' }, ...tableHeadAudienceCountry],
      rowData: isHideFields ? audienceCountry?.slice(1, 2) : audienceCountry?.slice(1),
      renderedRows: row => <DynamicallyRows row={row} setIsHideFields={setIsHideFields} />,
    },
    ['audience_city']: {
      tableRow: [{ id: 'total', label: ``, align: 'left' }, ...tableHeadAudienceCity],
      rowData: isHideFields ? audienceCity?.slice(1, 2) : audienceCity?.slice(1),
      renderedRows: row => <DynamicallyRows row={row} setIsHideFields={setIsHideFields} />,
    },
    ['audience_referral']: {
      tableRow: [{ id: 'total', label: ``, align: 'left' }, ...tableHeadAudienceReferral],
      rowData: isHideFields ? audienceReferral?.slice(1, 2) : audienceReferral?.slice(1),
      renderedRows: row => <DynamicallyRows row={row} setIsHideFields={setIsHideFields} />,
    },
    ['audience_referral_domain']: {
      tableRow: [{ id: 'total', label: ``, align: 'left' }, ...tableHeadAudienceReferralDomain],
      rowData: isHideFields
        ? audienceReferralDomain?.slice(1, 2)
        : audienceReferralDomain?.slice(1),
      renderedRows: row => <DynamicallyRows row={row} setIsHideFields={setIsHideFields} />,
    },
    ['audience_device']: {
      tableRow: [{ id: 'total', label: ``, align: 'left' }, ...tableHeadAudienceDevice],
      rowData: isHideFields ? audienceDevice?.slice(1, 2) : audienceDevice?.slice(1),
      renderedRows: row => <DynamicallyRows row={row} setIsHideFields={setIsHideFields} />,
    },
  }

  const STATUS_OPTIONS = [
    {
      value: 'overview',
      label: labelStrings.campaignsMetricsTable.tabsLabels.overview,
      disabled: false,
    },
    {
      value: 'audience_country',
      label: labelStrings.bitlyTrackingTable.audienceCountry,
      disabled: isShowOnlyOverview,
    },
    {
      value: 'audience_city',
      label: labelStrings.bitlyTrackingTable.audienceCity,
      disabled: isShowOnlyOverview,
    },
    {
      value: 'audience_referral',
      label: labelStrings.bitlyTrackingTable.audienceReferral,
      disabled: isShowOnlyOverview,
    },
    {
      value: 'audience_referral_domain',
      label: labelStrings.bitlyTrackingTable.audienceReferralDomain,
      disabled: isShowOnlyOverview,
    },
    {
      value: 'audience_device',
      label: labelStrings.bitlyTrackingTable.audienceDevice,
      disabled: isShowOnlyOverview,
    },
  ]

  const isNotFound = !renderedTableRows[filterStatus]?.rowData?.length

  const isShowLearnMore = renderedTableRows[filterStatus]?.rowData?.some(
    item => !!item.isEngagementRateEstimated || !!item.isReachEstimated
  )

  const tabItemLabelWithTooltip = (label, tooltipText) => (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <Box>{label}</Box>
      <TooltipComponent text={tooltipText} placement="bottom-start">
        <InfoOutlinedIcon sx={{ fontSize: '18px', fill: '#d0d5d9', cursor: 'pointer' }} />
      </TooltipComponent>
    </Stack>
  )

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Card>
          <Tabs
            value={filterStatus}
            onChange={handleFilterStatus}
            sx={{
              px: 2,
              bgcolor: 'background.neutral',
            }}
          >
            {STATUS_OPTIONS.map((tab, idx) => (
              <Tab
                key={`${tab.id}_${idx}`}
                label={
                  tab.tooltipText && !isForPdf
                    ? tabItemLabelWithTooltip(tab.label, tab.tooltipText)
                    : tab.label
                }
                value={tab.value}
                disabled={tab.disabled}
              />
            ))}
          </Tabs>

          <Divider />

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              <Table size={!dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={renderedTableRows[filterStatus]?.tableRow}
                  rowCount={renderedTableRows[filterStatus]?.rowData?.length}
                  isFirstRowBorder
                  sx={{
                    '& .MuiTableCell-root': {
                      backgroundColor: 'white',
                    },
                  }}
                />

                <TableBody>
                  {renderedTableRows[filterStatus]?.rowData?.map((row, idx) => (
                    <Fragment key={idx}>
                      {renderedTableRows[filterStatus]?.renderedRows(row)}
                    </Fragment>
                  ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(
                      page,
                      rowsPerPage,
                      renderedTableRows[filterStatus]?.rowData?.length
                    )}
                  />
                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
              {isShowLearnMore && (
                <Stack
                  sx={{ width: '100%', pr: 3, my: 1 }}
                  direction="row"
                  justifyContent="end"
                  alignItems="center"
                  spacing={0.5}
                >
                  <Typography sx={{ fontSize: '12px' }}>
                    {labelStrings.campaignsMetricsTable.starLabel}
                  </Typography>

                  <Tooltip placement="top" title={renderedTableRows[filterStatus]?.learnMoreText}>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontFamily: 'Public Sans, sans-serif',
                        cursor: 'pointer',
                      }}
                    >
                      {labelStrings.campaignsMetricsTable.learnMore}
                    </Typography>
                  </Tooltip>
                </Stack>
              )}
            </Scrollbar>
          </TableContainer>
        </Card>
      </Grid>
    </Grid>
  )
}

UsersList.propTypes = {
  overviewRowData: PropTypes.array,
  audienceCity: PropTypes.array,
  audienceCountry: PropTypes.array,
  audienceDevice: PropTypes.array,
  audienceReferral: PropTypes.array,
  audienceReferralDomain: PropTypes.array,
  isShowOnlyOverview: PropTypes.bool,
}
