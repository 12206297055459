// @ts-nocheck
import React, { memo, useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import CollectionsBarItem from './collectionBarItem'
import { CollectionList, DraggableContainer } from './collectionsSidebarStyles'
import { delayFunc } from '../../../utils'
import dragNdropService from '../../../services/dragNdropService'
import { DRAG_EVENT_TYPES } from '../../../constants/appSettings'
import { useDispatch } from 'react-redux'
import { changeCollectionOrder } from '../../../redux/ducks/collectionsDucks'
import { usePrevious } from '../../../hooks/usePrevious'
import './collection.css'

let listCords = null // this is put outside react component to make it visible for closured fn fired on sudscription to drag

const arrayMove = (arr, fromIndex, toIndex) => {
  return (arr = arr.slice()), arr.splice(toIndex, 0, ...arr.splice(fromIndex, 1)), arr
}

const CollectionsBarItemsList = ({
  collectionsList = [],
  activeForDropCollectionId = null,
  canDragItemToCollection = {},
  onDropLeaveCollectionList,
  isDragCaptured,
  dropOperations = {},
  isNavType = 'default',
  onHandleOpenViewType,
  ...restOfProps
}) => {
  const dispatch = useDispatch()
  const listRef = useRef()
  const [listData, setListData] = useState(collectionsList)
  const prevListData = usePrevious(listData)
  const [currentCollection, setCurrentCollection] = useState(null)
  const [firedChangeOrder, setFiredChangeOrder] = useState(false)
  const isNotDraggable = collection =>
    collection?.collectionId !== 'fav' && collection?.collectionId !== 'unlocked'

  useEffect(() => {
    if (collectionsList?.length) {
      setListData(collectionsList)
    }
  }, [collectionsList])

  const CURSOR_WIDTH_OFFSET = 5

  useEffect(() => {
    delayFunc(
      () => {
        if (listRef && listRef.current && isDragCaptured) {
          listCords = listRef.current.getBoundingClientRect()
        }
      },
      400 // not less tahn 400. otherwise, because of transition cords will be incorrect
    ) // delay needed because of transitions in sidebar header where some elements hide
  }, [collectionsList, isDragCaptured])

  useEffect(() => {
    const fnId = `collections_list_fn`
    dragNdropService.subscribeToDrag(DRAG_EVENT_TYPES.addToCollection, {
      fn: onDragLeave,
      fnId,
    })
    return () => {
      dragNdropService.unsubscribeFromDrag(DRAG_EVENT_TYPES.addToCollection, fnId)
    }
  }, [])

  const onDragLeave = ({ mouseCords }) => {
    if (!listCords) return
    const { clientX, clientY } = mouseCords
    const { top, bottom, left, right } = listCords
    if (
      clientX <= left ||
      clientX >= right - CURSOR_WIDTH_OFFSET ||
      clientY <= top ||
      clientY >= bottom
    ) {
      onDropLeaveCollectionList()
    }
  }

  const dragStartHandler = (e, collection) => {
    e.currentTarget.style.opacity = '0.4'
    setCurrentCollection(collection)
  }

  const dragEnterHandler = (e, collection) => {
    e.preventDefault()
  }

  const dragOverHandler = (e, collection) => {
    e.currentTarget.classList.add('draggable')

    if (!isNotDraggable(collection) || !isNotDraggable(currentCollection)) {
      e.currentTarget.classList.remove('draggable')
    }

    if (e.preventDefault) {
      e.preventDefault()
    }

    return false
  }

  const dragLeaveHandler = (e, collection) => {
    e.currentTarget.classList.remove('draggable')
  }

  const dragEndHandler = (e, collection) => {
    e.currentTarget.style.opacity = '1'
    if (listRef.current) {
      // @ts-ignore
      listRef.current.childNodes.forEach(function(item) {
        item.classList.remove('draggable')
      })
    }
  }

  const firstUpdate = useRef(true)
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    const idsListData = listData
      ?.map(collection => collection.collectionId)
      ?.filter(item => item !== 'fav' && item !== 'unlocked')

    if (JSON.stringify(listData) !== JSON.stringify(prevListData)) {
      dispatch(changeCollectionOrder(idsListData))
    }
  }, [firedChangeOrder])

  const dropHanlder = (e, collection) => {
    if (e.stopPropagation) {
      e.stopPropagation()
    }

    if (!isNotDraggable(collection) || !isNotDraggable(currentCollection)) {
      return false
    }

    if (isNotDraggable(collection)) {
      listData.forEach((el, idx) => {
        if (el.collectionId === collection?.collectionId) {
          const getEndIndex = listData.indexOf(el)
          const getStartIndex = listData.indexOf(currentCollection)
          setListData(arrayMove(listData, getStartIndex, getEndIndex))
        }
      })
      setFiredChangeOrder(!firedChangeOrder)
    }
  }

  return (
    !!listData?.length && (
      <CollectionList onDragLeave={onDragLeave} ref={listRef}>
        {isNavType === 'min' ? (
          <>
            {listData?.map(collection => (
              <CollectionsBarItem
                key={collection?.collectionId}
                collection={collection}
                isHoveredForDrop={activeForDropCollectionId === collection?.collectionId}
                {...(activeForDropCollectionId === collection?.collectionId && {
                  canDragToCollection: canDragItemToCollection,
                  isDropOperationInProgress: !!dropOperations.inProgress,
                  isDropOperationSuccess: !!dropOperations.success,
                })}
                enableItemClick={!dropOperations.inProgress}
                isNavType={isNavType}
                onHandleOpenViewType={onHandleOpenViewType}
                {...restOfProps}
              />
            ))}
          </>
        ) : (
          <>
            {listData?.map(collection => (
              <DraggableContainer
                key={collection?.collectionId}
                onMouseDown={e => !isNotDraggable(collection) && e.preventDefault()}
                onDragStart={e => dragStartHandler(e, collection)}
                onDragEnter={e => dragEnterHandler(e, collection)}
                onDragOver={e => dragOverHandler(e, collection)}
                onDragLeave={e => dragLeaveHandler(e, collection)}
                onDrop={e => dropHanlder(e, collection)}
                onDragEnd={e => dragEndHandler(e, collection)}
                draggable={isNotDraggable(collection)}
                isRender={isNotDraggable(collection)}
              >
                <CollectionsBarItem
                  key={collection?.collectionId}
                  collection={collection}
                  isHoveredForDrop={activeForDropCollectionId === collection?.collectionId}
                  {...(activeForDropCollectionId === collection?.collectionId && {
                    canDragToCollection: canDragItemToCollection,
                    isDropOperationInProgress: !!dropOperations.inProgress,
                    isDropOperationSuccess: !!dropOperations.success,
                  })}
                  enableItemClick={!dropOperations.inProgress}
                  onHandleOpenViewType={onHandleOpenViewType}
                  {...restOfProps}
                />
              </DraggableContainer>
            ))}
          </>
        )}
      </CollectionList>
    )
  )
}

CollectionsBarItemsList.propTypes = {
  collectionsList: PropTypes.array,
  activeForDropCollectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
  canDragItemToCollection: PropTypes.object,
  onDropLeaveCollectionList: PropTypes.func,
  isDragCaptured: PropTypes.bool,
  dropOperations: PropTypes.object,
  isNavType: PropTypes.string,
  onHandleOpenViewType: PropTypes.func
}

export default memo(CollectionsBarItemsList)
