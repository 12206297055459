import React from 'react'
import PropTypes from 'prop-types'
import { Stack, TableRow, TableCell, Typography, Button } from '@mui/material'
import useTranslation from '../../../../../localization/useTranslation'

export const DynamicallyRows = ({ row, setIsHideFields }) => {
  const { labelStrings } = useTranslation()

  const isBoldCell = row[0]?.toLowerCase() === 'total'

  return (
    <TableRow
      hover
      sx={{
        borderTop: isBoldCell ? '1px solid rgba(145, 158, 171, 0.24)' : 'inherit',
        borderBottom:
          row[0]?.toLowerCase() === 'total' || isBoldCell
            ? '1px solid rgba(145, 158, 171, 0.24)'
            : 'inherit',
      }}
    >
      <TableCell
        align={row[0]?.toLowerCase() === 'total' ? 'center' : 'left'}
        sx={{ borderRight: '2px solid rgba(145, 158, 171, 0.24)', width: '200px' }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={row[0]?.toLowerCase() === 'total' ? 'center' : 'left'}
          spacing={2}
        >
          {row[0]?.toLowerCase() !== 'total' ? (
            <Typography
              noWrap
              variant={isBoldCell ? 'subtitle2' : 'inherit'}
              sx={{ textTransform: 'capitalize', minWidth: '75px' }}
            >
              {row[0] || 'total'}
            </Typography>
          ) : (
            <Typography
              textAlign="center"
              noWrap
              variant={'subtitle2'}
              sx={{ textTransform: 'capitalize' }}
            >
              {row[0]}
            </Typography>
          )}
        </Stack>
        {isBoldCell && (
          <Stack sx={{ mt: 2, borderTop: '1px solid rgba(145, 158, 171, 0.24)' }}>
            <Button sx={{ mt: 1 }} onClick={() => setIsHideFields(prev => !prev)}>
              {labelStrings.showHide}
            </Button>
          </Stack>
        )}
      </TableCell>

      {row?.map((el, idx) => {
        if (idx === 0) return
        return (
          <TableCell
            key={idx}
            align="center"
            sx={{ textTransform: 'capitalize', verticalAlign: isBoldCell ? 'top' : 'center' }}
          >
            <Typography noWrap variant={isBoldCell ? 'subtitle2' : 'inherit'}>
              {row[idx] ? row[idx] : ''}
            </Typography>
          </TableCell>
        )
      })}
    </TableRow>
  )
}

DynamicallyRows.propTypes = {
  row: PropTypes.array,
}
