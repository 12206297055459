import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  compareProfilesSelector,
  isCompareProfilesExistSelector,
  comparedProfilesPlatformSelector,
  comparedProfilesAudienceAllLockedSelector,
  comparedProfilesRanksSelector,
} from '../../redux/selectors'
import { withReduxActions } from '../../hocs/withRedux'
import {
  manageProfileInCompareList,
  resetComparedProfiles,
} from '../../redux/ducks/compareProfilesDucks'

import { SOCIAL_PLATFORMS_NAMES } from '../../constants/appSettings'

import ComparedProfilesList from './comparedProfilesList/ComparedProfilesList'

const ComparedProfilesContainer = ({
  notAllowed = true,
  manageProfileInCompareList,
  resetComparedProfiles,
}) => {
  const comparedProfiles = useSelector(compareProfilesSelector)
  const isCompareProfilesExist = useSelector(isCompareProfilesExistSelector)
  const comparedPlatform = useSelector(comparedProfilesPlatformSelector)
  const allComparedAudiencesBlocked = useSelector(comparedProfilesAudienceAllLockedSelector)
  const comparedRanks = useSelector(comparedProfilesRanksSelector)

  const removeComparedProfile = (profileId, userindex) =>
    manageProfileInCompareList({ profileId, toAdd: false, platform: userindex })

  const isMocked = !isCompareProfilesExist || notAllowed

  return (
    <ComparedProfilesList
      isMocked={isMocked}
      comparedProfiles={comparedProfiles}
      removeComparedProfile={removeComparedProfile}
      resetComparedProfiles={resetComparedProfiles}
      isInstagramCompare={comparedPlatform === SOCIAL_PLATFORMS_NAMES.instagram.index}
      isTikTokCompare={comparedPlatform === SOCIAL_PLATFORMS_NAMES.tiktok.index}
      isYouTubeCompare={comparedPlatform === SOCIAL_PLATFORMS_NAMES.youtube.index}
      allComparedAudiencesBlocked={allComparedAudiencesBlocked}
      comparedRanks={comparedRanks}
    />
  )
}

ComparedProfilesContainer.propTypes = {
  notAllowed: PropTypes.bool,
  manageProfileInCompareList: PropTypes.func,
  resetComparedProfiles: PropTypes.func,
}

export default withReduxActions({ manageProfileInCompareList, resetComparedProfiles })(
  ComparedProfilesContainer
)
