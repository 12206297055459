import React from 'react'
import PropTypes from 'prop-types'
import ReactLoading from 'react-loading'
import styled from 'styled-components'
import { getNavbarHeight } from '../../../utils'
import { NAVBAR_FOOTER_SUM_HEIGHT, NAVBAR_HEIGHT } from '../../../constants/appSettings'

const LoadingWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  height: ${props =>
    props.showOnFullPage ? `calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px)` : '100%'};
  ${props =>
    props.isTransparent &&
    `
    position: ${props.isOuterPageLoad ? 'fixed' : 'absolute'};
    top: 0;
    left: 0;
    background: ${props.isColoredBg ? 'white' : '#f5f5f5c7'};
    
    
    pointer-events: bounding-box;
    height: ${props.height || '100vh'};
    width: ${props.width || '100vw'};
    // z-index: 200;
    @media screen and (min-width: 900px) and (max-width: 1050px) {
      height: ${props.height || '11%'};
      width: ${props.width || '100%'};
    }
    @media screen and (min-width: 780px) and (max-width: 900px) {
      height: ${props.height || '20%'};
      width: ${props.width || '100%'};
    }
    @media screen and (min-width: 600px) and (max-width: 780px) {
      height: ${props.height || '30%'};
      width: ${props.width || '100%'};
    }
    @media screen and (max-width: 600px) {
      height: ${props.height || '80%'};
      width: ${props.width || '100%'};
    }
    
  `}
  ${props => {
    if (props.isSearchLoad) {
      return `
      background: #f5f5f5c7;
      box-shadow: 0px 8px 19px 5px #f5f5f5c7; 
      position: fixed;
      top: ${getNavbarHeight()}px;
      left: 312px;
      width: calc(100% - 312px);
      `
    }
  }}
  ${props => {
    if (props.isExplore) {
      return `
      background: #f5f5f5c7;
      box-shadow: 0px 8px 19px 5px #f5f5f5c7; 
      position: fixed;
      top: ${getNavbarHeight()}px;
      left: 340px;
      width: calc(100% - 340px);
      `
    }
  }}
  z-index: 9998;

  ${props => props.isSimple && `z-index: 1000;`}

  ${props =>
    props.withTransparentShadow &&
    `
   box-shadow: 0px 0px 19px 5px #f5f5f5c7; 
  `}

  ${props =>
    props.isPdfExport &&
    `
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    pointer-events: none;
    background: #f5f5f5c7;
    pointer-events: bounding-box;
  `}

  ${props =>
    props.isInModal &&
    `
    & svg {
      height: 40px !important;
      width: 40px !important;
    }
  `}

  ${props =>
    props.isInsidePageLoad &&
    `
    & .loading_spinner_wrap {
      position: relative;
      top -${NAVBAR_HEIGHT}px;
    }
  `}
`
const LoadingInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  ${props =>
    props.forSearch &&
    `
    width: 98%;
    margin-left: auto;
    background: ${props.theme.colors.white};
  `}
`

const LoadingSpinner = ({
  showOnFullPage = false,
  isTransparent = false,
  isSearchLoad = false,
  height,
  width,
  withTransparentShadow = false,
  isPdfExport = false,
  isInModal = false,
  isInsidePageLoad = false,
  isOuterPageLoad = false,
  spinnerType = 'bars',
  spinnerHeight = 64,
  spinnerWidth = 64,
  forSearch = false,
  isSimple = false,
  isColoredBg = false,
  isExplore = false
}) => {
  const possibleSpinnerTypes = [
    'bars',
    'blank',
    'balls',
    'bubbles',
    'cubes',
    'cylon',
    'spin',
    'spinningBubbles',
    'spokes',
  ]

  const type = possibleSpinnerTypes.some(t => t === spinnerType)
    ? possibleSpinnerTypes.find(t => t === spinnerType)
    : possibleSpinnerTypes[0]

  return (
    <LoadingWrapper
      showOnFullPage={showOnFullPage}
      isTransparent={isTransparent}
      withTransparentShadow={withTransparentShadow}
      isSearchLoad={isSearchLoad}
      isExplore={isExplore}
      height={height}
      width={width}
      isPdfExport={isPdfExport}
      isInModal={isInModal}
      isInsidePageLoad={isInsidePageLoad}
      isOuterPageLoad={isOuterPageLoad}
      isSimple={isSimple}
      isColoredBg={isColoredBg}
      className="loading_spinner_wrapper"
    >
      <LoadingInnerWrapper
        className="spinner-border text-primary"
        role="status"
        forSearch={forSearch}
      >
        <ReactLoading
          type={type}
          color={'rgba(64, 61, 61, 0.38)'}
          height={spinnerHeight}
          width={spinnerWidth}
          className="loading_spinner_wrap"
        />
      </LoadingInnerWrapper>
    </LoadingWrapper>
  )
}

LoadingSpinner.propTypes = {
  showOnFullPage: PropTypes.bool,
  isTransparent: PropTypes.bool,
  isSearchLoad: PropTypes.bool,
  withTransparentShadow: PropTypes.bool,
  isPdfExport: PropTypes.bool,
  isInModal: PropTypes.bool,
  isInsidePageLoad: PropTypes.bool,
  isOuterPageLoad: PropTypes.bool,
  spinnerType: PropTypes.string,
  spinnerHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  spinnerWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  forSearch: PropTypes.bool,
  isSimple: PropTypes.bool,
  isColoredBg: PropTypes.bool,
  isExplore: PropTypes.bool
}

export default LoadingSpinner
