import { useState, useEffect } from 'react'
import { WINDOW_WIDTH } from '../constants/windowSizes'

const useWindowResize = () => {
  const [sizes, setSizes] = useState({
    height: window ? window.innerHeight : 0,
    width: window ? window.innerWidth : 0,
    devicePixelRatio: window ? window.devicePixelRatio : 0,
    isZoomIn: false,
    isZoomOut: false,
  })

  const onResizeHandler = () => {
    setSizes({
      height: window.innerHeight,
      width: window.innerWidth,
      devicePixelRatio: window.devicePixelRatio,
      isZoomIn: window.devicePixelRatio > sizes.devicePixelRatio,
      isZoomOut: window.devicePixelRatio < sizes.devicePixelRatio,
    })
  }

  useEffect(() => {
    window && window.addEventListener('resize', onResizeHandler)

    return () => window && window.removeEventListener('resize', onResizeHandler)
  }, [])

  const { width: windowWidth, devicePixelRatio, isZoomIn, isZoomOut } = sizes
  const { desktop, smallDesktop, tablet, mobile } = WINDOW_WIDTH

  const isDesktop = windowWidth >= desktop
  const isSmallDesktop = windowWidth < desktop
  const isBigTablet = windowWidth < smallDesktop && windowWidth > tablet
  const isTablet = windowWidth <= tablet && windowWidth > mobile
  const isMobile = windowWidth <= mobile
  return {
    ...sizes,
    isDesktop,
    isSmallDesktop,
    isBigTablet,
    isTablet,
    isMobile,
    isZoomIn,
    isZoomOut,
    devicePixelRatio,
  }
}

export default useWindowResize
