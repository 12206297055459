export const applyFilter = ({ inputData, comparator, filterSearchTerm }) => {
  const stabilizedThis = inputData?.map((el, index) => [el, index])

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })

  inputData = stabilizedThis?.map(el => el[0])

  if (filterSearchTerm) {
    inputData = inputData.filter(
      field =>
        field.dashboardId?.toLowerCase().indexOf(filterSearchTerm.toLowerCase()) !== -1 ||
        field.emails?.includes(filterSearchTerm.toLowerCase())
    )
  }

  return inputData
}
