// @ts-nocheck
import React from 'react'
import PropTypes from 'prop-types'
import { Box, Switch, TablePagination, FormControlLabel, IconButton, Stack } from '@mui/material'
import useTranslation from '../../../localization/useTranslation'
import DownloadIcon from '@mui/icons-material/Download'
import { CSVLink } from 'react-csv'

const TablePaginationCustom = ({
  dense,
  onChangeDense,
  rowsPerPageOptions = [25, 50, 100, 200],
  sx = {},
  csvReport,
  ...other
}) => {
  const wrapperConfig = csvReport?.data?.length
    ? {
        direction: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }
    : {}

  const { labelStrings } = useTranslation()
  return (
    <Stack {...wrapperConfig} sx={{ borderTop: 'solid 1px rgba(145, 158, 171, 0.24)' }}>
      {csvReport?.data?.length && (
        <Box sx={{ px: 2 }}>
          <IconButton aria-label="Export to CSV" sx={{ width: '40px', height: '40px' }}>
            <CSVLink {...csvReport}>
              <DownloadIcon sx={{ fontSize: '24px', fill: '#637381' }} />
            </CSVLink>
          </IconButton>
        </Box>
      )}

      <Box sx={{ position: 'relative', ...sx }}>
        <TablePagination
          labelRowsPerPage={labelStrings.rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          sx={{
            pr: 6,
            borderTop: 'none',
          }}
          {...other}
        />

        {onChangeDense && (
          <FormControlLabel
            label={labelStrings.dense}
            control={<Switch checked={dense} onChange={onChangeDense} />}
            sx={{
              pl: 2,
              py: 1.5,
              top: 0,
              position: {
                sm: 'absolute',
              },
            }}
          />
        )}
      </Box>
    </Stack>
  )
}

TablePaginationCustom.propTypes = {
  dense: PropTypes.bool,
  onChangeDense: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  csvReport: PropTypes.object,
  sx: PropTypes.object,
}

export default TablePaginationCustom
