// import React from 'react'
// import {
//   ContentOverviewIcon,
//   DashboardIcon,
//   CalendarIcon,
//   NotificationsIcon,
//   CreatorOverviewIcon,
//   AirtableViewIcon,
//   CustomFieldsIcon,
//   GoalsIcon,
//   DataEnrichmentIcon,
//   CampaignSettingsIcon,
//   KeywordsMentionsIcon,
//   KanbanIcon,
// } from '../../../assets/icons/navbar'


// export const navConfigCampaign = [
//   {
//     title: 'dashboard',
//     path: 'dashboard',
//     icon: <DashboardIcon />,
//     disabled: false,
//   },
//   {
//     title: 'content overview',
//     smallTitle: 'content',
//     path: 'content-overview',
//     icon: <ContentOverviewIcon />,
//     disabled: false,
//   },
//   {
//     title: 'creator overview',
//     smallTitle: 'creator',
//     path: 'creator-overview',
//     icon: <CreatorOverviewIcon />,
//     disabled: false,
//   },
//   {
//     title: 'calendar',
//     path: 'calendar',
//     icon: <CalendarIcon />,
//     disabled: false,
//   },
//   {
//     title: 'creator pipelines',
//     smallTitle: 'pipelines',
//     path: 'pipeline',
//     icon: <KanbanIcon />,
//     disabled: false
//   },
// ]
// export const navConfigManagement = [
//   {
//     title: 'data enrichment',
//     path: 'data-enrichment',
//     icon: <DataEnrichmentIcon />,
//     disabled: true,
//   },
//   {
//     title: 'goals',
//     path: 'goals',
//     icon: <GoalsIcon />,
//     disabled: false,
//   },
//   {
//     title: 'custom fields',
//     path: 'custom-fields',
//     icon: <CustomFieldsIcon />,
//     disabled: false,
//   },
//   {
//     title: 'keywords & mentions manager',
//     smallTitle: 'keywords',
//     path: 'keywords-mentions-manager',
//     icon: <KeywordsMentionsIcon />,
//     disabled: false,
//   },
//   // {
//   //   title: 'files and uploads',
//   //   path: 'files-and-uploads',
//   //   icon: <FilesUploadsIcon />,
//   //   disabled: true
//   // },
// ]
// export const navConfigReportingAndExport = [
//   {
//     title: 'Airtable View',
//     smallTitle: 'airtable',
//     path: 'airtable-view',
//     icon: <AirtableViewIcon />,
//     disabled: false,
//   },
//   // {
//   //   title: 'your reports',
//   //   path: 'your-reports',
//   //   icon: <ReportsIcon />,
//   //   disabled: true
//   // },
//   // {
//   //   title: 'your offers',
//   //   path: 'your-offers',
//   //   icon: <OffersIcon />,
//   //   disabled: true
//   // },
// ]
// export const navConfigSettings = [
//   {
//     title: 'notifications',
//     path: 'notifications',
//     icon: <NotificationsIcon />,
//     disabled: false,
//   },
//   {
//     title: 'campaign settings',
//     smallTitle: 'settings',
//     path: 'campaign-settings',
//     icon: <CampaignSettingsIcon />,
//     disabled: false,
//   },
// ]

export const arrayUrlsWithoutDashboard = [
  'content-overview',
  'creator-overview',
  'calendar',
  'pipeline',
  'data-enrichment',
  'goals',
  'custom-fields',
  'keywords-mentions-manager',
  'airtable-view',
  'notifications',
  'campaign-settings'
]
