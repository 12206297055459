import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { VerticalDivider } from './index'
import { SIDEBAR_WIDTH, NAVBAR_HEIGHT } from '../../../constants/appSettings'

export const IconWrapper = styled.span`
  padding: 2px;
  padding-right: 6px;
  & svg path {
    fill: #ffffff;
  }
`
export const NavbarBGWrapper = styled.div`
  position: relative;
  background: ${props => {
    if (!props.isOnPageWithNewDesign || props.isProfileLoading) {
      return props.theme.newNavbarGradientBackground
    }
    return props.theme.newNavbarGradientBackground
  }};
`
export const NavbarContent = styled.div`
  display: flex;
  height: ${NAVBAR_HEIGHT}px;
  justify-content: space-between;
  align-items: center;
  position: relative;
`
export const NavItem = styled.div`
  ${props => props.theme.flex.centerAll}
  padding:  0 20px;
  height: 100%;
  & button {
    margin: 0;
  }

  ${props =>
    props.isUserNotAuthorized &&
    `
    ${props.theme.disableBlock};
    & span {
      font-weight: 400;
    }
  `}
  a {
    color: #ffffff;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0;
    font-family: ${props => props.theme.fontFamilyInter};
  }
  ${props =>
    props.withCounter &&
    props.count &&
    `
    ${props.theme.withBubbledCouter(
      props,
      `top: 50%; right: ${props.isSmallDesktop ? 8 : 0}px; border: 1px solid ${
        props.theme.color.pink
      };`
    )}
  `}
`

export const LoginContainerWrapper = styled(NavItem)`
  width: fit-content;
  display: flex;
  justify-content: space-around;
  padding: 0;
`

export const StyledNavLink = styled(Link)`
  &:hover {
    text-decoration: underline;
    color: #ffffff;
  }
  @media screen and (max-width: 1570px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`
export const NavLinkContainer = styled.div`
  display: flex;
  width: 60%;
  justify-content: flex-start;
  align-items: center;
`
export const InfludataLogoWrapper = styled.div`
  ${props => props.theme.flex.centerAll}
  height: 100%;
  padding-left: 20px;
  padding-right: 58px;
  max-width: ${SIDEBAR_WIDTH}px;
`
export const AnalyzeNavbarLabelItem = styled(NavItem)`
  cursor: pointer;
  color: ${props => props.theme.color.white};
  width: 112px;
  background: ${props => props.theme.yellowGradient};
  & span {
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0;
    font-family: ${props => props.theme.fontFamilyInter};
    font-weight: 800;
    margin-bottom: 2px;
  }
  &:hover {
    text-decoration: underline;
  }
  &:active {
    opacity: 0.85;
  }
`
export const NavItemsDivider = styled(VerticalDivider)`
  opacity: 0.3;
  width: 1px;
  margin: auto ${props => props.margin || 0}px;
  height: 50%;
`
export const LoginItemsDivider = styled(NavItemsDivider)`
  margin-right: 10px;
  ${props => props.isInvisible && 'opacity: 0;'}
`
export const NavMenuItemWrapper = styled.div`
  ${props => props.isDisabled && props.theme.disabled}
  min-height: 63px;
  max-height: 65px;
  max-width: 185px;
  min-width: 50px;
  margin-right: 20px;
  ${props => props.theme.flex.centerAll};

  box-sizing: border-box;
  transition: all 300ms ease;
  position: relative;
  cursor: pointer;

  &:before {
    position: absolute;
    bottom: 0;
    background: #f9e0e0;
    height: 5px;
    display: block;
    content: '';
    width: 0;
    transition: all 300ms ease;
    transform: scaleX(0);
  }

  ${props =>
    props.isActive
      ? `
    &:before {
      width: 100%;
      transform: scaleX(1);
    }
  `
      : `
      &:hover {
        &:before {
          width: 100%;
          transform: scaleX(1);
        }
      } 
  `}

  a {
    ${props => props.theme.flex.centerAll};
    color: ${props => props.theme.colors.offWhite};

    span {
      font-family: ${props => props.theme.fontFamilyInter};
      font-size: 13px;
      font-weight: 500;
      ${'' /* font-weight: ${props => (props.isActive ? '600' : '500')}; */}
      text-transform: capitalize;
      line-height: 16px;
    }

    svg {
      path {
        fill: ${props =>
          props.isActive ? props.theme.colors.white : props.theme.colors.offWhite};
      }
    }
    &:hover {
      text-decoration: none;
      color: ${props => props.theme.colors.offWhite};
    }
  }
`

export const LangSwitchWrapper = styled.div`
  position: absolute;
  left: 3px;
  top: 3px;
`
export const AnalyzeBarWrapper = styled.div`
  ${props => props.theme.flex.centerAll}
  height: 40px;
  min-width: 319px;
  max-width: 350px;
  border-radius: 11px;
  background: transparent linear-gradient(111deg, #ffffff 0%, #ffffff 100%) 0% 0% no-repeat
    padding-box;
`
export const SuggestUsersWrapper = styled.div`
  width: 290px;
  height: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 1300px) {
    width: 250px;
  }
  ${props => props.disabled && props.theme.disabled};
`
export const TabIndicator = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  background: ${props => props.theme.color.white};
  box-shadow: 0px 0px 4px -2px #784E4E;
  top: 5px;
  left: 5px;
  border-radius: 5px;
  transition: all 300ms ease;
`
export const NavSocialPlatformWrapper = styled.div`
  position: relative;
  height: 100%;
  padding: 2px;
`

export const NavSecondLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 320px;
`

export const ToolsForBrandsWrapper = styled.div`
  margin-left: 20px;
`