import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ModalComponent } from '../../components/modal/ModalComponent'
import { Fields } from './Form/Fields'
import { EditDashboardSchema } from './Form/FormSchema.js'
import useTranslation from '../../../localization/useTranslation'

export const EditDashboardFields = ({
  dashboardCurrentFieldsData = {},
  dashboardAlwaysVisibleFieldsData,
  openEditModal = false,
  setOpenEditModal,
  onEditDashboard,
}) => {
  const { labelStrings } = useTranslation()

  const {
    cpmCpe = [],
    cpeOrganic = [],
    customTracking = [],
    goals = [],
    reachedAudience = [],
    overview = [],
    sentiment = [],
    publishedContent = [],
    emv = [],
    main_metrics_table = [],
  } = dashboardAlwaysVisibleFieldsData || {}

  const getFieldOptions = fields => {
    return fields?.map(field => {
      return {
        value: field,
        label: labelStrings.filedLabels[field],
      }
    })
  }

  const cpmFields = getFieldOptions(cpmCpe)
  const cpeOrganicFields = getFieldOptions(cpeOrganic)
  const emvFields = getFieldOptions(emv)
  const overviewFields = getFieldOptions(overview)
  const customTrackingFields = getFieldOptions(customTracking)
  const goalsFields = getFieldOptions(goals)
  const reachedAudienceFields = getFieldOptions(reachedAudience)
  const sentimentFields = getFieldOptions(sentiment)
  const publishedContentFields = getFieldOptions(publishedContent)
  const mainMetricsTableFields = getFieldOptions(main_metrics_table)

  const methods = useForm({
    resolver: yupResolver(EditDashboardSchema),
    defaultValues: {
      cpmCpe: [],
      cpeOrganic: [],
      customTracking: [],
      goals: [],
      overview: [],
      publishedContent: [],
      reachedAudience: [],
      sentiment: [],
      mainMetricsTable: [],
    },
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods

  const handleClose = () => {
    reset({
      cpmCpe: [],
      cpeOrganic: [],
      customTracking: [],
      goals: [],
      overview: [],
      publishedContent: [],
      reachedAudience: [],
      sentiment: [],
      mainMetricsTable: [],
    })
    setOpenEditModal(false)
  }

  const onSubmit = async data => {
    try {
      await new Promise(resolve => setTimeout(resolve, 500))
      const payload = Object.values(data).flat()
      onEditDashboard({ visibleGraphElements: payload })
      handleClose()
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    reset({
      ...dashboardAlwaysVisibleFieldsData,
      goals: !!dashboardCurrentFieldsData.goals?.length
        ? ['goal_reached_widgets']
        : dashboardCurrentFieldsData.goals,
      customTracking: !!dashboardCurrentFieldsData.customTracking?.length
        ? ['custom_tracking_counts']
        : dashboardCurrentFieldsData.customTracking,
    })
  }, [dashboardCurrentFieldsData])

  return (
    <Box>
      <ModalComponent
        isOpenModal={openEditModal}
        closeModal={handleClose}
        title={`Edit Dashboard Fields`}
        contentWidth="794px"
      >
        <Fields
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          methods={methods}
          isSubmitting={isSubmitting}
          handleClose={handleClose}
          //
          cpmFields={cpmFields}
          cpeOrganicFields={cpeOrganicFields}
          overviewFields={overviewFields}
          customTrackingFields={customTrackingFields}
          goalsFields={goalsFields}
          reachedAudienceFields={reachedAudienceFields}
          sentimentFields={sentimentFields}
          publishedContentFields={publishedContentFields}
          emvFields={emvFields}
          mainMetricsTableFields={mainMetricsTableFields}
        />
      </ModalComponent>
    </Box>
  )
}

EditDashboardFields.propTypes = {
  dashboardCurrentFieldsData: PropTypes.object,
  dashboardAlwaysVisibleFieldsData: PropTypes.object,
  openEditModal: PropTypes.bool,
  setOpenEditModal: PropTypes.func,
  onEditDashboard: PropTypes.func,
  setCurrVisibleFields: PropTypes.func,
}