import styled from 'styled-components'
import { GradientButton, CancelButton } from '../../../common/buttons/styledButtons'

// Note Details Overlay
export const AdditionalFieldDetailsOverlay = styled.div`
  position: absolute;
  ${props => `
    top: ${props.position.top || 'auto'};
    bottom: ${props.position.bottom || 'auto'};
    left: ${props.position.left || 'auto'};
    right: ${props.position.right || 'auto'};
  `}
  width: 320px;
  background-color: ${props => props.theme.colors.white};
  border: 2px solid ${props => props.theme.colors.mediumBrown};
  border-radius: 17px;
  padding: 30px;
  z-index: 105;

  .form-element {
    margin-bottom: 20px;
  }
`

export const AdditionalFieldSelectTools = styled.div`
  margin: 20px -10px;
  padding-top: 20px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -22px;
    height: 1px;
    width: 320px;
    background-color: ${props => props.theme.colors.mediumBrown};
  }
`

export const AdditionalFieldSelectError = styled.div`
  font-size: 12px;
  line-height: 15px;
  padding-left: 5px;
  color: ${props => props.theme.colors.salmon};
  margin-top: 5px;
`

// Select Options
export const SelectOptionsContainer = styled.div``

// Draggable Select Option
export const SelectOptionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & + & {
    margin-top: 15px;
  }
`

export const SelectOptionInput = styled.input`
  border: 1px solid ${props => props.theme.colors.darkBrown};
  border-radius: 5px;
  margin: 0 5px;
  padding: 3px 10px;
  min-width: 1px;
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.brown};
  outline: none;
`

export const ButtonAddSelectOption = styled.button`
  width: 100%;
  padding: 5px 10px;
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: none;
  background-color: ${props => props.theme.colors.lightBrown};
  border-radius: 7px;
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.brown};
`

export const ButtonTextAddSelectOption = styled.span`
  padding-left: 20px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
`

export const ButtonSave = styled(GradientButton)`
  text-transform: uppercase;
`

export const ButtonCancel = styled(CancelButton)`
  margin-right: 10px;
`

export const LimitExceededOverlay = styled(AdditionalFieldDetailsOverlay)``

export const LimitExceededContent = styled.div`
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.darkBrown};
  text-align: left;

  button {
    display: block;
    margin: 20px auto 0;
  }
`

export const LimitExceededContentTitle = styled.div`
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
`

export const LimitExceededContentText = styled.div`
  text-align: left;
`

export const limitExceededOverlayPosition = {
  top: 'calc(100% + 10px)',
  right: '-30px',
}
