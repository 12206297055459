import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
// import ToggleSwitch from './index'
import { ToggleSwitchContainer, ToggleSwitchLabel } from './styles'
import { SearchContentCreatorIconSvg, SearchContentIconSvg } from '../common/icons/icons'
import { searchContentTypes } from '../../constants/search'
import { useSelector } from 'react-redux'
import { isUserAuthenticatedSelector } from '../../redux/selectors'
import ToggleSwitchSearch from './ToggleSwitchSearch'

export const ToggleSwitchSearchContent = props => {
  const { checked, onChange, isContentSearch = false } = props || {}
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)

  const isChecked = useMemo(() => checked === searchContentTypes.CREATOR ? true : false, [checked])

  return (
    <ToggleSwitchContainer isDisabled={!isUserAuthenticated || !isContentSearch}>
      <ToggleSwitchLabel isActive={isChecked}>
        <SearchContentCreatorIconSvg isGradient={isChecked} /> <span>CREATOR</span>
      </ToggleSwitchLabel>
      <ToggleSwitchSearch id="search-collection" checked={!isChecked} onChange={onChange} />
      <ToggleSwitchLabel isActive={!isChecked}>
        <span>CONTENT</span> <SearchContentIconSvg isGradient={!isChecked} />
      </ToggleSwitchLabel>
    </ToggleSwitchContainer>
  )
}

ToggleSwitchSearchContent.propTypes = {
  checked: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isContentSearch: PropTypes.bool
}