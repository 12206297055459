import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useTranslation from '../localization/useTranslation'

import { ResultCountWrapper } from './common/styledWrappers'

const CountWapper = styled.span`
  font-weight: 700;
`

const ResultCount = ({ count = 0 }) => {
  const { labelStrings } = useTranslation()
  const tenThousandResults = 10000
  const oneMillionResults = 1000000

  const isRenderCounter = !!count
  let counterStr
  if (count === tenThousandResults) {
    counterStr = '> 10k'
  } else if (count >= oneMillionResults) {
    counterStr = '> 1 Mio.'
  } else {
    counterStr = `${count}`
  }

  return (
    <ResultCountWrapper>
      {isRenderCounter && (
        <span>
          {labelStrings.results}: <CountWapper>{counterStr}</CountWapper>
        </span>
      )}
    </ResultCountWrapper>
  )
}

ResultCount.propTypes = {
  count: PropTypes.number,
}

export default ResultCount
