import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Card, Table, TableBody, TableContainer } from '@mui/material'
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from '../../components/table'
import Scrollbar from '../../components/scrollbar'
import { GoalTableToolbar } from './GoalTableToolbar'
import { GoalTableRow } from './GoalTableRow'
import { STATUS_OPTIONS, TABLE_HEAD, applyFilter } from './utils'
import { TableRowsLoader } from '../../components/table/TableRowSkeleton'

export const GoalsList = props => {
  const { openModal, setEditField, data, handleGoal, isLoading, setCurrPage } = props
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    // onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable()

  const [filterGoalName, setFilterGoalName] = useState('')
  const [filterStatus, setFilterStatus] = useState('all')

  const dataFiltered = applyFilter({
    inputData: data,
    comparator: getComparator(order, orderBy),
    filterGoalName,
    filterStatus,
  })

  const denseHeight = dense ? 52 : 72

  const isFiltered = filterGoalName !== '' || filterStatus !== 'all'

  const isNotFound =
    (!isLoading && !dataFiltered?.length && !!filterGoalName) ||
    (!isLoading && !dataFiltered?.length && !!filterStatus)

  const handleFilterName = event => {
    setPage(0)
    setFilterGoalName(event.target.value)
  }

  const handleDeleteRow = goalId => {
    handleGoal({
      method: 'remove',
      goalId,
    })
  }

  const handlePauseUnpause = params => {
    handleGoal({
      method: 'change',
      goalId: params.goalId,
      isPaused: params.isPaused,
    })
  }

  const handleEditRow = row => {
    setEditField(row)
    openModal(true)
  }

  const handleFilterStatutes = event => {
    const {
      target: { value },
    } = event

    setPage(0)
    setFilterStatus(value)
  }

  const handleResetFilter = () => {
    setFilterGoalName('')
    setFilterStatus('all')
  }

  useEffect(() => {
    setCurrPage(page)
  }, [page])

  return (
    <>
      <Grid container sx={{ my: 4 }}>
        <Grid item xs={12} md={12}>
          <Card>
            <GoalTableToolbar
              isFiltered={isFiltered}
              filterName={filterGoalName}
              filterStatuses={filterStatus}
              onFilterStatuses={handleFilterStatutes}
              optionsStatus={STATUS_OPTIONS}
              onFilterName={handleFilterName}
              onResetFilter={handleResetFilter}
              isLoading={isLoading}
            />

            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <Scrollbar>
                <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data?.length}
                  />

                  <TableBody>
                    {isLoading ? (
                      <TableRowsLoader rowsNum={8} thNum={7} />
                    ) : (
                      !isLoading &&
                      dataFiltered
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(row => (
                          <GoalTableRow
                            key={row.goalId}
                            row={row}
                            onDeleteRow={() => handleDeleteRow(row.goalId)}
                            onEditRow={() => handleEditRow(row)}
                            handlePauseUnpause={handlePauseUnpause}
                          />
                        ))
                    )}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(page, rowsPerPage, data.length)}
                    />

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={dataFiltered.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              dense={dense}
              onChangeDense={onChangeDense}
              sx={{ '& p': { m: 0 } }}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

GoalsList.propTypes = {
  openModal: PropTypes.func,
  setEditField: PropTypes.func,
  data: PropTypes.array,
  handleGoal: PropTypes.func,
  isLoading: PropTypes.bool,
  setCurrPage: PropTypes.func
}
