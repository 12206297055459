import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ArrowIcon } from '../icons'
import WithUpgradeTip from '../popups/WithUpgradeTip'
import LinkToFaqQuestion from '../../faqs/LinkToFaqQuestion'
import WithTip from '../popups/WithTip'
import { shallowEqual, useSelector } from 'react-redux'
import { isUserInTrialSelector, selectedPlatformSelector } from '../../../redux/selectors'

const DropdownOuterWrapper = styled.div`
  color: ${props => props.theme.platformColor};
  position: relative;
  ${props => props.isDisabled && props.theme.disabled}
`

const ArrowWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 15px;
  & > svg {
    transition: transform 0.2s linear;
    fill: ${props => (props.isDisabled ? '#DDD7D7' : props.theme.platformColor)};
    ${props =>
      props.isOpenDropdown &&
      `
      transform: rotateZ(180deg);
    `}
  }
`

const DropdownVisibleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 5px;
  align-items: center;

  ${props =>
    props.isDisabled &&
    `
    opacity: 0.5;
    color: #DDD7D7;
  `}
`

const DropdownTitleWrapper = styled.h3`
  padding: 0 15px;
  text-transform: uppercase;
  margin: 0;
  font-size: 15px;
  font-weight: 700px;
  letter-spacing: 0.65px;
  font-weight: bold;
  line-height: initial;
  color: ${props => props.theme.getTextGradientStyles({ platform: props.platform?.toLowerCase() })};
  font-family: ${props => props.theme.fontFamilyInter};
  &:hover {
    color: ${props => props.theme.platformColorLight};
  }
`

const DroppingWrapper = styled.div`
  max-height: ${props => (props.isOpenDropdown ? '1000px' : '0px')};
  transition: all 0.25s linear;
  ${props => props.isDisabled && props.theme.disabled}
  ${props =>
    !props.isOpenDropdown &&
    `
    overflow: hidden;
  `}
`
const TitleWrapper = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  & svg {
    stroke: ${props => (props.isDisabled ? '#DDD7D7' : props.theme.platformColor)};
  }
  ${props =>
    props.isDisabled &&
    `
    color: #DDD7D7;
  `}
`

const DisabledLabel = styled.div`
  color: ${props => props.theme.platformColor};
  position: absolute;
  right: 10px;
  top: -1px;
  height: 100%;
  letter-spacing: 0.65px;
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  ${props =>
    props.isTrial &&
    `
    top: 40px;
    height: unset;
    right: 5px;
    text-align: right;
  `}
  ${props => props.theme.flex.centerAll}
  width: 100%;
  justify-content: flex-end;
  ${props =>
    props.notImplemented &&
    `
    text-transform: none;
    top: 10px;
  `}
`

const SearchbarDropdownContainer = ({
  isOpenByDefault,
  children = null,
  titleText = '',
  isActive = false,
  faqQuestionId = '',
  scrollHandler = null,
  upgradeTipText = '',
  notImplemented = false,
  notImplementedLabel = false,
  notImplementedTipText = '',
  userindex = '',
  closeDropdownCB,
}) => {
  const dropRef = useRef()

  const [isOpenDropdown, changeOpenDropdown] = useState(isOpenByDefault)
  const isTrialUser = useSelector(isUserInTrialSelector)
  const selectedPlatform = useSelector(selectedPlatformSelector, shallowEqual)

  useEffect(() => {
    if (isActive !== isOpenDropdown) changeOpenDropdown(isActive)
  }, [isActive])

  const toggleOpenDropdown = () => {
    if (!isOpenDropdown && scrollHandler) return scrollHandler()
    if (!isActive) return
    closeDropdownCB()
    changeOpenDropdown(!isOpenDropdown)
  }

  const DropDownVisibleWrapperComponent = (
    <DropdownVisibleWrapper onClick={toggleOpenDropdown} isDisabled={!isActive}>
      <TitleWrapper isDisabled={!isActive}>
        <DropdownTitleWrapper platform={selectedPlatform}>{titleText}</DropdownTitleWrapper>

        <LinkToFaqQuestion
          faqQuestionId={faqQuestionId}
          tipIconSize={14}
          linkPositions={{ top: 4, right: -33 }}
        />
      </TitleWrapper>

      <ArrowWrapper isOpenDropdown={isOpenDropdown} isDisabled={!isActive}>
        <ArrowIcon />
      </ArrowWrapper>
    </DropdownVisibleWrapper>
  )

  return (
    <DropdownOuterWrapper ref={dropRef}>
      {!isActive && !notImplemented ? (
        <WithUpgradeTip userindex={userindex} tipText={upgradeTipText}>
          {DropDownVisibleWrapperComponent}
        </WithUpgradeTip>
      ) : (
        DropDownVisibleWrapperComponent
      )}

      <DroppingWrapper
        isDisabled={isTrialUser && !isActive}
        isOpenDropdown={isOpenDropdown || (isTrialUser && !isActive)}
      >
        {children}
      </DroppingWrapper>

      {!isActive && (
        <>
          {notImplemented && (
            <WithTip tipText={notImplementedTipText} userindex={userindex} position="top center">
              <DisabledLabel notImplemented>{notImplementedLabel}</DisabledLabel>
            </WithTip>
          )}
        </>
      )}
    </DropdownOuterWrapper>
  )
}

SearchbarDropdownContainer.propTypes = {
  isUserAuthenticated: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  titleText: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  faqQuestionId: PropTypes.string,
  scrollHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(null)]),
  upgradeTipText: PropTypes.string,
  isOpenByDefault: PropTypes.bool,
  disabledLabel: PropTypes.string,
  notImplemented: PropTypes.bool,
  userindex: PropTypes.string,
  notImplementedTipText: PropTypes.string,
  closeDropdownCB: PropTypes.func,
}

export default SearchbarDropdownContainer
