import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, shallowEqual } from 'react-redux'
import { toast } from 'react-toastify'
import { withReduxActions } from './withRedux'
import { resetError } from '../redux/ducks/errorDucks'
import { errorMsgNameSelector, isErrorSelector } from '../redux/selectors'
import useTranslation from '../localization/useTranslation'

const WithToastError = WrappedComponent => {
  const HOC = ({ resetError = () => {}, ...props }) => {
    const isError = useSelector(isErrorSelector, shallowEqual)
    const toastMsgName = useSelector(errorMsgNameSelector, shallowEqual)

    const { errors } = useTranslation()

    useEffect(() => {
      if (isError) toast(errors[toastMsgName] || toastMsgName, { onClose: resetError })
    }, [toastMsgName])

    return <WrappedComponent {...props} />
  }

  HOC.propTypes = {
    resetError: PropTypes.func,
  }
  return withReduxActions({ resetError })(HOC)
}

export default WithToastError
