import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import dayjs from 'dayjs'
import useTranslation from '../../../localization/useTranslation'
import { DATE_FORMAT_STRINGS } from '../../../constants/dayjs'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
  color: ${props => props.theme.colors.brown};
`

const LabelWrapper = styled.div``

const UnlockedDateWrapper = styled.div`
  font-weight: bold;
`

const StatusWrapper = styled.div`
  color: ${props => props.isAudienceAvailable
    ? props.theme.color.green
    : props.theme.color.yellow
  };
`

const ProfileUnlockedStatusContent = ({ unlockedOn = '', isAudienceAvailable = false }) => {
  const { labelStrings } = useTranslation()

  const formattedUnlockedOn = dayjs(unlockedOn).format(DATE_FORMAT_STRINGS.localizedDate)

  return (
    <ContentWrapper>
      <LabelWrapper>{labelStrings.unlockedOn}</LabelWrapper>
      <UnlockedDateWrapper>{formattedUnlockedOn}</UnlockedDateWrapper>
      <LabelWrapper>{labelStrings.status}</LabelWrapper>
      <StatusWrapper isAudienceAvailable={isAudienceAvailable}>
        {isAudienceAvailable ? labelStrings.available : labelStrings.requested}
      </StatusWrapper>
    </ContentWrapper>
  )
}

ProfileUnlockedStatusContent.propTypes = {
  unlockedOn: PropTypes.string,
  isAudienceAvailable: PropTypes.bool,
}

export default ProfileUnlockedStatusContent
