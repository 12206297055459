// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import useTranslation from '../../../../localization/useTranslation'
import { USER_INDEXES } from '../../../../constants/appSettings'

import {
  Modal,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  Backdrop,
  Fade,
  IconButton,
  Button,
} from '@mui/material'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import {
  addCreatorToCampaign,
  addEditCardTaskPipeline,
  fetchCampaign,
  getBoardSuccessPipeline,
  getPipelineData,
  onlyFetchNewCampaigns,
  setCurrentOpenedCampaign,
} from '../../../../redux/ducks/campaignsDucks'
import {
  allCampaignsSelector,
  currentOpenedCampaignSelector,
  getAllCampaignsSelector,
  pipelineSelector,
} from '../../../../redux/selectors'
import {
  boxModalStyles,
  btnActivateDirectlyInCampaignStyles,
  choosePipelineTextStyles,
  modalSubtitleStyles,
  modalTitleStyles,
  selectTitleStyles,
  isActivateDirectlyStyles,
} from '../commonStyles'
import Scrollbar from '../../../../new-ui/components/scrollbar/Scrollbar'

const AddToCampaignPipelineModal = ({
  profile = {},
  isOpened = false,
  closeModal,
  disableAutoFocus = false,
  disableEnforceFocus = false,
}) => {
  const { labelStrings, currentLang } = useTranslation()
  const dispatch = useDispatch()

  const profileId = profile?._id

  const { trackNow, trackMax } = useSelector(allCampaignsSelector)
  const isAllowedToAddCreators = trackNow < trackMax

  const { creators } = useSelector(currentOpenedCampaignSelector)
  const campaignsListData = useSelector(getAllCampaignsSelector)
  const { board } = useSelector(pipelineSelector)
  const { columnOrder, columns, cards } = board || {}

  const [selectedPipelineStage, setSelectedPipelineStage] = useState('')
  const [selectedCampaign, setSelectedCampaign] = useState('')
  const [isConfirmActivateDirectlyInCampaigns, setIsConfirmActivateDirectlyInCampaigns] = useState(
    false
  )

  const sortedColumnsPipeline = columnOrder?.map(columnId => columns[columnId])
  const matchingCards = Object.values(cards)?.filter(card => card.creatorId === profileId)
  const inColumnId = matchingCards?.find(card => card.inColumnId)?.inColumnId
  const isActivateDirectlyInCampaign = creators?.some(
    el => el.creatorId === profileId && !el.isOnboarding
  )

  const campaignMenuSelectItems = useMemo(() => {
    return campaignsListData
      ?.filter(campaign => !campaign.isDemoCampaign)
      ?.map(campaign => (
        <MenuItem key={campaign.campaignId} value={campaign.campaignId}>
          {campaign.campaignName}
        </MenuItem>
      ))
  }, [campaignsListData?.length])

  const handleChangeCampaign = event => {
    const value = event.target.value
    setIsConfirmActivateDirectlyInCampaigns(false)
    setSelectedPipelineStage('')
    setSelectedCampaign(value)
    dispatch([fetchCampaign(value), getPipelineData({ campaignId: value, disablePreloader: true })])
  }

  useEffect(() => {
    if (isOpened) {
      dispatch([
        onlyFetchNewCampaigns(),
        getBoardSuccessPipeline({
          cards: {},
          columns: {},
          columnOrder: [],
        }),
        setCurrentOpenedCampaign({}),
      ])
    }
  }, [isOpened])

  useEffect(() => {
    if (selectedCampaign && isAllowedToAddCreators) {
      setIsConfirmActivateDirectlyInCampaigns(
        isActivateDirectlyInCampaign ? true : isActivateDirectlyInCampaign
      )
      setSelectedPipelineStage(inColumnId)
    }
  }, [inColumnId, isActivateDirectlyInCampaign, selectedCampaign, isAllowedToAddCreators])

  const onSelectedPipelinesStages = stage => {
    if (selectedPipelineStage === stage) {
      setSelectedPipelineStage('')
    } else {
      setSelectedPipelineStage(stage)
    }

    if (!isActivateDirectlyInCampaign) {
      setIsConfirmActivateDirectlyInCampaigns(false)
    }

    dispatch(
      addEditCardTaskPipeline({
        method: inColumnId ? (selectedPipelineStage === stage ? 'remove' : 'change') : 'add',
        inColumnId: stage,
        campaignId: selectedCampaign,
        creatorId: profileId,
        cardId: matchingCards?.length ? matchingCards[0]?.cardId : '',
        assignedTo: [],
        withoutRefetch: true,
      })
    )
    closeModal()
  }

  const onActivatedInCampaign = () => {
    if (!isAllowedToAddCreators) return

    if (!isActivateDirectlyInCampaign) {
      setIsConfirmActivateDirectlyInCampaigns(!isConfirmActivateDirectlyInCampaigns)
      setSelectedPipelineStage('')
      dispatch(
        addCreatorToCampaign({
          campaignId: selectedCampaign,
          creatorId: profileId,
        })
      )
      closeModal()
    }
  }

  return (
    <Modal
      disableAutoFocus={disableAutoFocus}
      disableEnforceFocus={disableEnforceFocus}
      open={isOpened}
      onClose={closeModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 350,
          sx: {
            background: `linear-gradient(90deg,rgba(255,188,0,0.8) 0.35%,rgba(255,93,90,0.8) 100%)`,
            backdropFilter: ` blur(10px)`,
          },
        },
      }}
    >
      <Fade in={isOpened}>
        <Box
          boxShadow={3}
          sx={{
            ...boxModalStyles,
            maxHeight: isAllowedToAddCreators ? '750px' : boxModalStyles.maxHeight,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ height: '58px', px: 2, backgroundColor: '#784E4E', position: 'relative' }}
          >
            <Typography
              sx={{
                ...modalTitleStyles,
                fontSize: currentLang === 'de' ? '14px' : modalTitleStyles.fontSize,
              }}
            >
              {labelStrings.addToCampaignPipeline}
            </Typography>
            <IconButton onClick={closeModal} sx={{ position: 'absolute', right: '18px' }}>
              <HighlightOffOutlinedIcon sx={{ color: 'white' }} />
            </IconButton>
          </Stack>
          <Stack justifyContent="center" sx={{ px: 3, pt: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
              <Typography sx={modalSubtitleStyles}>
                {labelStrings.campaignPipelineModalSubtext}
              </Typography>
            </Box>
            {!campaignsListData?.length && selectedCampaign ? (
              <Stack direction="row" justifyContent="center">
                <Typography
                  sx={{
                    ...modalSubtitleStyles,
                    fontSize: '15px',
                    fontWeight: 'bold',
                    maxWidth: 'fit-content',
                  }}
                >
                  {labelStrings.noCampaignCreatedYet}
                </Typography>
              </Stack>
            ) : (
              <>
                <Stack spacing={1.5}>
                  <Typography sx={selectTitleStyles}>{labelStrings.chooseCampaign}</Typography>
                  <FormControl>
                    <InputLabel
                      size="small"
                      sx={{ color: '#784E4E !important', textAlign: 'center', fontWeight: 'bold' }}
                    >
                      {labelStrings.selectYourCampaign}
                    </InputLabel>
                    <Select
                      autoWidth
                      value={selectedCampaign}
                      label={labelStrings.selectYourCampaign}
                      size="small"
                      onChange={handleChangeCampaign}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            width: '100%',
                            maxWidth: '360px',
                            maxHeight: '318px',
                            marginTop: '4px',
                          },
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#784E4E',
                        },
                        '& .MuiSvgIcon-root': {
                          color: '#784E4E',
                        },
                      }}
                    >
                      {campaignMenuSelectItems}
                    </Select>
                  </FormControl>
                </Stack>

                <Stack spacing={2} sx={{ pt: selectedCampaign ? 3 : 0 }}>
                  {selectedCampaign && (
                    <Stack spacing={isActivateDirectlyInCampaign ? 0.5 : 0}>
                      <Button
                        size="small"
                        variant="outlined"
                        disabled={!isAllowedToAddCreators}
                        sx={
                          isConfirmActivateDirectlyInCampaigns
                            ? isActivateDirectlyStyles
                            : btnActivateDirectlyInCampaignStyles
                        }
                        onClick={onActivatedInCampaign}
                      >
                        {isConfirmActivateDirectlyInCampaigns
                          ? labelStrings.activateInCampaigns
                          : labelStrings.activateDirectlyInCampaign}
                      </Button>
                      {isActivateDirectlyInCampaign && (
                        <Typography
                          sx={{
                            ...modalSubtitleStyles,
                            maxWidth: '100%',
                            color: '#FF695B',
                            fontWeight: 600,
                          }}
                        >
                          {labelStrings.removeCreator}
                        </Typography>
                      )}
                    </Stack>
                  )}
                  {!isAllowedToAddCreators && selectedCampaign && (
                    <Stack alignItems="center" justifyContent="center" spacing={0.5}>
                      <Typography
                        sx={{
                          ...modalSubtitleStyles,
                          fontSize: '15px',
                          fontWeight: 'bold',
                          maxWidth: 'fit-content',
                        }}
                      >
                        {labelStrings.dontHaveAnyCampaignSeatsLeft}
                      </Typography>
                      <Typography
                        sx={{
                          ...modalSubtitleStyles,
                          fontSize: '15px',
                          fontWeight: 'bold',
                          maxWidth: 'fit-content',
                        }}
                      >
                        {labelStrings.importantAddingToPipeline}
                      </Typography>
                    </Stack>
                  )}

                  {!!sortedColumnsPipeline?.length && selectedCampaign && (
                    <>
                      <Typography sx={choosePipelineTextStyles}>
                        {labelStrings.orChoosePipelineStage}
                      </Typography>

                      <Scrollbar sx={{ maxHeight: '299px', p: 1, pr: 1.5 }}>
                        <Stack spacing={1}>
                          {sortedColumnsPipeline?.map(stage => {
                            const isActiveStage = selectedPipelineStage === stage.columnId
                            return (
                              <Button
                                key={stage.columnId}
                                variant="outlined"
                                sx={{
                                  ...btnActivateDirectlyInCampaignStyles,
                                  minHeight: '40px',
                                  backgroundColor: isActiveStage ? '#6CB75B' : 'inherit',
                                  color: isActiveStage ? '#FFF' : '#784E4E',
                                  '&:hover': {
                                    color: isActiveStage ? '#FFF' : '#784E4E',
                                    backgroundColor: isActiveStage
                                      ? 'rgba(108, 183, 91, 0.7)'
                                      : 'rgba(120, 78, 78, 0.08)',
                                    borderColor: '#784E4E',
                                  },
                                }}
                                onClick={() => onSelectedPipelinesStages(stage.columnId)}
                              >
                                {stage.columnName}
                              </Button>
                            )
                          })}
                        </Stack>
                      </Scrollbar>
                    </>
                  )}
                  {!sortedColumnsPipeline?.length && selectedCampaign && (
                    <Stack direction="row" justifyContent="center">
                      <Typography
                        sx={{
                          ...modalSubtitleStyles,
                          fontSize: '15px',
                          fontWeight: 'bold',
                          maxWidth: 'fit-content',
                        }}
                      >
                        {labelStrings.noPipelineStagesCreatedYet}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </>
            )}
          </Stack>
        </Box>
      </Fade>
    </Modal>
  )
}

AddToCampaignPipelineModal.propTypes = {
  profile: PropTypes.object.isRequired,
  isOpened: PropTypes.bool,
  closeModal: PropTypes.func,
  disableAutoFocus: PropTypes.bool,
  disableEnforceFocus: PropTypes.bool,
}

export default AddToCampaignPipelineModal
