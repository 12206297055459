import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useTranslation from '../../../localization/useTranslation'
import { AddToCampaignIcon } from '../../common/icons'
import WithTip from '../../common/popups/WithTip'
import WithUpgradeTip from '../../common/popups/WithUpgradeTip'
import { USER_INDEXES, SOCIAL_PLATFORMS_NAMES } from '../../../constants/appSettings'
import EditCampaignModal from '../../campaigns/campaignTools/EditCampaignModal'

const IconWrapper = styled.span`
  position: relative;
  ${props =>
    props.count &&
    `
    &::before {
      content: '${props.count}';
      position: absolute;
      top: -4px;
      right: 0;
      font-size: 10px;
      line-height: 1;
      font-weight: 400;
      height: 14px;
      width: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${props.theme.getColorPaletteForPlatform(props.userindex).main};
      color: ${props.theme.color.white};
      border-radius: 50%;
    }
  `}

  ${props => !props.enableCampaigns && props.theme.disableBlock};
`

const ProfileCampaignTool = ({
  user,
  enableCampaigns = false,
  isUserAuthenticated = false,
  setIsOpenPipelineModal,
}) => {
  const { labelStrings } = useTranslation()

  const [isCreateCampaignOpened, setIsCreateCampaignOpened] = useState(false)

  const isUserFromTikTok = user._index === USER_INDEXES.tiktok
  const userInCampaignsCount = user.campaignArray ? user.campaignArray.length : 0

  const AddIcon = (
    <IconWrapper
      enableCampaigns={enableCampaigns && !isUserFromTikTok}
      count={userInCampaignsCount}
      userindex={user._index}
    >
      <AddToCampaignIcon userindex={user._index} isActiveIcon={false} isGrey={isUserFromTikTok} />
    </IconWrapper>
  )

  const renderVisibleComponent = () => (
    <WithTip
      tipText={
        isUserFromTikTok
          ? labelStrings.notAvaliableCampaignTracking(SOCIAL_PLATFORMS_NAMES.tiktok.prettyName)
          : labelStrings.addToCampaignPipeline
      }
      userindex={user._index}
      position="top center"
    >
      {AddIcon}
    </WithTip>
  )

  return (
    <>
      {enableCampaigns ? (
        <div
          onClick={() => {
            if (!isUserFromTikTok) {
              setIsOpenPipelineModal(true)
            }
          }}
        >
          {renderVisibleComponent()}
        </div>
      ) : (
        <WithUpgradeTip
          tipText={labelStrings.trackInfluencers}
          userindex={user._index}
          withSignup={!isUserAuthenticated}
        >
          {AddIcon}
        </WithUpgradeTip>
      )}

      {isCreateCampaignOpened && (
        <EditCampaignModal
          platform={user._index}
          isNewCampaign
          closeModal={() => setIsCreateCampaignOpened(false)}
          withoutPortal
        />
      )}
    </>
  )
}

ProfileCampaignTool.propTypes = {
  user: PropTypes.object.isRequired,
  enableCampaigns: PropTypes.bool,
  isUserAuthenticated: PropTypes.bool,
  setIsOpenPipelineModal: PropTypes.func,
}

export default ProfileCampaignTool
