import { styled } from '@mui/material'
import Scrollbar from '../../../components/scrollbar/Scrollbar'
import { NAVBAR_FOOTER_SUM_HEIGHT } from '../../../../constants/appSettings'

export const StyledScrollbar = styled(Scrollbar)({
  overflowY: 'auto',
  overflowX: 'hidden',
  zIndex: 9998,
  height: `calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px)`,
  '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
})
