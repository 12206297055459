import { Card, CardHeader, Stack } from '@mui/material'
import React from 'react'
import { ColumnAreaChart } from '../../../components/chart/charts'
import { CommentsItem } from '../../../components/carousel/comments/CommentsItem'

const separateDataPublishedContent = (data = []) => {
  return data?.reduce(
    (acc, item) => {
      acc.date.push(item.date?.substr(0, 10))
      acc.followers.push(item.followers)
      acc.publishedContentCount.push(item.publishedContentCount)
      acc.reachedUsersSum.push(item.reachedUsersSum)
      return acc
    },
    {
      date: [],
      followers: [],
      publishedContentCount: [],
      reachedUsersSum: [],
    }
  )
}
const ucFirst = str => {
  if (!str) return str
  return str[0].toUpperCase() + str.slice(1)
}

export const SentimentPdf = ({
  publishedContentChartData,
  latestCommentsWithBrandData,
  mostLikedCommentsData,
}) => {
  const publishedContentData = separateDataPublishedContent(publishedContentChartData?.dataArray)
  return (
    <Stack spacing={2}>
      <ColumnAreaChart
        helperText={``}
        title="Published Content & Reach"
        subheader=""
        chart={{
          labels: publishedContentData?.date,
          series: [
            {
              name: 'Actual Reach',
              type: 'line',
              fill: 'solid',
              data: publishedContentData?.reachedUsersSum,
            },
            {
              name: publishedContentChartData?.labelBrandAccount
                ? `${ucFirst(
                    publishedContentChartData?.labelBrandAccount
                  )}'s Growth in Followers/Subscribers`
                : 'Growth in Followers: No brand account added',
              type: 'line',
              fill: 'solid',
              data: publishedContentData?.followers,
            },
            {
              name: 'Content Pieces',
              type: 'column',
              fill: 'solid',
              data: publishedContentData?.publishedContentCount,
            },
          ],
          colors: ['#FFBC00', '#2D99FF', '#ff4441'],
          minFollowersValue: Math.min(...publishedContentData?.followers),
          maxContentPieces: Math.max(...publishedContentData?.publishedContentCount),
          isData: publishedContentChartData?.dataArray,
        }}
      />

      <Stack direction="row" spacing={2}>
        <Card sx={{ border: '1px solid rgba(145, 158, 171, 0.24)' }}>
          <CardHeader title={`Most Liked Comments`} />
          <CommentsItem item={mostLikedCommentsData?.value[0]} isPdf />
        </Card>

        <Card sx={{ border: '1px solid rgba(145, 158, 171, 0.24)' }}>
          <CardHeader title={`Latest Comments with Brand Mentions`} />
          <CommentsItem item={latestCommentsWithBrandData?.value[0]} isPdf />
        </Card>
      </Stack>
    </Stack>
  )
}
