import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import useTranslation from '../../../localization/useTranslation'
import { CloseWrapper } from '../styledWrappers'
import { Wrapper } from './dialogsCommonStyles'
import { CloseIcon } from '../icons'
import { ActionButton } from '../buttons/styledButtons'

const DialogWrapper = styled(Wrapper)`
  height: 250px;
  justify-content: space-around;
  padding: 20px 25px;
  text-align: center;
`

const MessageWrapper = styled.div`
  display: flex;
  align-item: center;
  justify-content: center;
  flex-direction: column;
`

const Note = styled.div`
  font-family: ${props => props.theme.fontFamilyInter};
  font-size: 15px;
  margin-top: 10px;
  text-align: center;
  color: ${props => props.theme.color.mineShaft};
`

const CloseIconWrapper = styled(CloseWrapper)`
  top: 10px;
  right: 15px;
`

const ButtonsGroupWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
`

const BuyAudienceTokensDialog = ({ onSubmit, onClose }) => {
  const { labelStrings } = useTranslation()

  return (
    <DialogWrapper>
      <CloseIconWrapper onClick={onClose}>
        <CloseIcon />
      </CloseIconWrapper>
      <MessageWrapper>
        <Note>{labelStrings.paymentForTokens}</Note>
        <Note>{labelStrings.wantToContinue}</Note>
      </MessageWrapper>

      <ButtonsGroupWrapper>
        <ActionButton onClick={onSubmit}>{labelStrings.yes}</ActionButton>

        <ActionButton onClick={onClose}>{labelStrings.no}</ActionButton>
      </ButtonsGroupWrapper>
    </DialogWrapper>
  )
}

BuyAudienceTokensDialog.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
}

export default BuyAudienceTokensDialog
