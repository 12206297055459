import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import HorizontalBarChart from '../../../charts/HorizontalBarChart'
import useTranslation from '../../../../localization/useTranslation'
import disabledChartData from '../../../charts/disabledChartsData'
import { Box } from '@mui/material'
import { truncateString } from '../../detailedInfludataRating/brandValue/mentionedProfiles/utils'

const AudienceCommonMentionsChart = ({ userindex = '', mentions = [], isDisabledAnalysis = true, isSmall = false, isForPdf = false }) => {
  const { labelStrings } = useTranslation()
  const isDataExist = arr => !!arr && !!arr.length

  const createAudienceMentionsChartData = useCallback(() => {
    if (isDisabledAnalysis || !isDataExist(mentions)) {
      return disabledChartData.disabledHorisontalSingleChartData()
    }

    return mentions.map(item => ({
      value: item.value,
      label: !isForPdf ? truncateString(item.mention, 24) : <Box sx={{ mb: '12px' }}>{truncateString(item.mention, 24)}</Box>,
      barTip: {
        percent: `${item.value}%`,
      },
    }))
  }, [mentions, isDisabledAnalysis])

  return (
    <HorizontalBarChart
      userindex={userindex}
      chartData={[
        {
          title: labelStrings.audienceMentions,
          data: createAudienceMentionsChartData(),
          notEnoughData: !isDisabledAnalysis && !isDataExist(mentions),
        },
      ]}
      isDisabledChart={isDisabledAnalysis}
      chartTitle={isForPdf ? '' : labelStrings.audienceMentions}
      isForPdf={isForPdf}
      isSmall={isSmall}
    />
  )
}

AudienceCommonMentionsChart.propTypes = {
  userindex: PropTypes.string,
  mentions: PropTypes.array,
  isDisabledAnalysis: PropTypes.bool,
  isForPdf: PropTypes.bool,
  isSmall: PropTypes.bool
}

export default AudienceCommonMentionsChart
