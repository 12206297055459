import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import html2pdf from 'html2pdf.js'
import { setError } from '../../../redux/ducks/errorDucks'
import { withReduxActions } from '../../../hocs/withRedux'
import { SOCIAL_PLATFORMS_NAMES, USER_INDEXES } from '../../../constants/appSettings'
import {
  PDF_PAGE_UNITS,
  PDF_PAGE_FORMATS,
  PDF_PAGE_ORIENTATIONS,
  PDF_PAGE_DIVIDER_CLASS,
} from '../../../constants/exportPdfSettings'
import { ERROR_MSG } from '../../../constants/errorMessages'
import { Portal } from '../../common/Portal/Portal'
import ProfilePdfDocument from './ProfilePdfDocument'
import ProfilePdfModal from './profilePdfModal'
import {
  INITIAL_MODAL_OPTIONS_IG,
  INITIAL_MODAL_OPTIONS_TT,
  INITIAL_MODAL_OPTIONS_YT,
} from '../../../constants/profile'

const ProfilePdfRender = ({ username, platform, ButtonComponent, setError }) => {
  const pdfContentRef = useRef()

  const initialModalOptions =
    platform === USER_INDEXES.tiktok
      ? INITIAL_MODAL_OPTIONS_TT
      : platform === USER_INDEXES.youtube
      ? INITIAL_MODAL_OPTIONS_YT
      : INITIAL_MODAL_OPTIONS_IG

  const [showPdfModal, setShowPdfModal] = useState(false)
  const [optionsModal, setOptionsModal] = useState(initialModalOptions)

  const onHandleChangeModalOption = idx => {
    const selectedOption = { ...optionsModal[idx], value: !optionsModal[idx].value }
    const mergedOptions = optionsModal?.map(option =>
      option.id === selectedOption.id ? selectedOption : option
    )
    setOptionsModal(mergedOptions)
  }

  const defaultPdfExportState = {
    isExportInit: false,
    isExportInProgress: false,
    isMarkupReady: false,
    dateOfReport: new Date().toString(),
  }
  const [pdfExportState, setPdfExportState] = useState(defaultPdfExportState)
  const changePdfExportState = (currentState = {}) =>
    setPdfExportState({ ...pdfExportState, ...currentState })

  useEffect(() => {
    const { isExportInit, isMarkupReady, isExportInProgress } = pdfExportState
    if (isExportInit && isExportInProgress && isMarkupReady) {
      ;(async () => {
        await triggerExportPdf()
      })()
    }
  }, [pdfExportState.isMarkupReady])

  const triggerExportPdf = async () => {
    // with delay to make sure all markup was done (due to transitions)
    const pdfContentElement = pdfContentRef && pdfContentRef.current
    if (pdfContentElement) {
      await createAndSavePdf(pdfContentElement)
    } else {
      setError(ERROR_MSG.failExportPdf)
    }
    setPdfExportState(defaultPdfExportState)
  }

  const createPdfFileName = () => {
    const { dateOfReport } = pdfExportState
    const platformName = SOCIAL_PLATFORMS_NAMES[platform].prettyName
    return `${platformName}User_${username}_${dateOfReport}.pdf`
  }
  // Some browsers have issues and abort when image cant be loaded..
  const cleanHtmlContent = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent.innerHTML, 'text/html');
  
    // Find all img tags
    const imgs = doc.querySelectorAll('img');
    imgs.forEach(img => {
      // Check if the src attribute contains an incomplete or null URL
      if (!img.src || img.src.includes('null') || img.src.endsWith('/api/collab/proxy-cors/')) {
        // Remove the img element or handle it as needed
        img.remove();
      }
    });
  
    // Replace all instances of ß with ss
    let htmlString = doc.body.innerHTML.replace(/ß/g, 'ss');
  
    htmlContent.innerHTML = htmlString;
    return htmlContent;
  };
  

  const createAndSavePdf = async targetElement => {
    const cleanedHtml = cleanHtmlContent(targetElement);
    const pdfOptions = {
      margin: 0,
      filename: createPdfFileName(),
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        scale: 1.5,
        imageTimeout: 7000,
        useCORS: true,
        logging: process.env.REACT_APP_NODE_ENV !== 'prod',
      },
      jsPDF: {
        unit: PDF_PAGE_UNITS.mm,
        format: PDF_PAGE_FORMATS.a4,
        orientation: PDF_PAGE_ORIENTATIONS.portrait,
        putOnlyUsedFonts: true,
      },
      pagebreak: { avoid: [`.${PDF_PAGE_DIVIDER_CLASS}`] },
    }
    // console.log('HTML content before PDF generation:', cleanedHtml.innerHTML);

    try {
      await html2pdf()
        .set(pdfOptions)
        .from(cleanedHtml)
        .toPdf()
        .get('pdf')
        .then(function(pdf) {
          const totalPages = pdf.internal.getNumberOfPages()
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i)
            pdf.setFontSize(13)
            pdf.setTextColor('#CBB3B3')
            pdf.text(
              pdf.internal.pageSize.getWidth() / 2.15,
              pdf.internal.pageSize.getHeight() - 4,
              'Page ' + i
            )
          }
        })
        .save()
    } catch (err) {
      console.log({ err })
      // clean up pdfs overlays
      document.body.removeChild(document.querySelector('.html2pdf__overlay'))
      document.body.removeChild(document.querySelector('.html2canvas-container'))
      setError(ERROR_MSG.failExportPdf)
    }
  }

  const onExportClick = async () => {
    setShowPdfModal(true)
  }

  const onExportModalClick = ({ exportType }) => {
    if (exportType === 'PDF') {
      if (pdfExportState.isExportInProgress || pdfExportState.isExportInit) return
      changePdfExportState({ isExportInit: true, isExportInProgress: true })
    }

    // if(exportType === 'PPX') {
    //   console.log('ppx')
    // }

    setShowPdfModal(false)
  }

  const setMarkupReady = () => changePdfExportState({ isMarkupReady: true })

  const { isExportInit, isExportInProgress, dateOfReport, isMarkupReady } = pdfExportState

  return (
    <>
      <ButtonComponent isExportInProgress={isExportInProgress} onExportClick={onExportClick} />
      {showPdfModal && (
        <ProfilePdfModal
          onExportModalClick={onExportModalClick}
          closeModal={() => setShowPdfModal(false)}
          optionsModal={optionsModal}
          onHandleChangeModalOption={onHandleChangeModalOption}
          platform={platform}
        />
      )}
      {isExportInit && (
        <Portal wrapperId="profile-pdf-document">
          <ProfilePdfDocument
            ref={pdfContentRef}
            format={PDF_PAGE_FORMATS.a4}
            platform={platform}
            dateOfReport={dateOfReport}
            setMarkupReady={setMarkupReady}
            optionsModal={optionsModal}
          />
        </Portal>
      )}
    </>
  )
}

ProfilePdfRender.propTypes = {
  username: PropTypes.string.isRequired,
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  ButtonComponent: PropTypes.elementType.isRequired,
  setError: PropTypes.func,
}

export default withReduxActions({ setError })(ProfilePdfRender)
