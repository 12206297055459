import React from "react"
import PropTypes from "prop-types"
import useTranslation from "../../../../localization/useTranslation"
import {
  // INFLUDATA_HOSTS,
  SOCIAL_LINKS_PATHS,
  USER_INDEXES,
} from "../../../../constants/appSettings"
import {
  InstagramIcon,
  TikTokIcon,
  YouTubeSocialNetworkIcon,
} from "../../../common/icons/icons"
import { InfludataWecLogoIcon } from "../../../common/icons"
import { A4Page } from "../commonStyles"
import * as Styled from "./styles"

const PageLayout = ({
  profileId = "",
  username = "",
  pageNumber = 0,
  platform = "",
  children = null,
  isBranded = true,
}) => {
  const { labelStrings } = useTranslation()

  //const linkToInfluDataProfile = `${INFLUDATA_HOSTS.infludataApp}/profile/${profileId}`
  const linkToProfileAccount = SOCIAL_LINKS_PATHS[platform](username)

  return (
    <>
      <A4Page customStyles='padding: 30px 20px'>
        <Styled.Container platform={platform}>
          <Styled.Header>
            <Styled.ReportLabel>
              {labelStrings.reportForInfluencer}
            </Styled.ReportLabel>

            <Styled.UsernameWrapper
              href={linkToProfileAccount}
              target='_blank'
              isBranded={isBranded}
            >
              <Styled.Username>{username}</Styled.Username>
              <Styled.PlatformIconWrapper>
                {(() => {
                  switch (platform) {
                    case USER_INDEXES.instagram:
                      return <InstagramIcon isGradient />
                    case USER_INDEXES.tiktok:
                      return <TikTokIcon isGradient />
                    case USER_INDEXES.youtube:
                      return <YouTubeSocialNetworkIcon />
                    default:
                      return null
                  }
                })()}
              </Styled.PlatformIconWrapper>
            </Styled.UsernameWrapper>

            {isBranded && (
              <Styled.LogoIconWrapper>
                <InfludataWecLogoIcon width='150px' />
              </Styled.LogoIconWrapper>
            )}
          </Styled.Header>

          <Styled.Main>{children}</Styled.Main>

          {/* <Styled.Footer>
          <Styled.PageNumber>Page {pageNumber}</Styled.PageNumber> 
            <Styled.Message>
              {`${labelStrings.thisPdfMightBeOutdated} ${labelStrings.ifYouWantUpToDateInformation}`}
              {', '}
              <Styled.Link href={linkToInfluDataProfile} target="_blank">
                {labelStrings.clickHere}
              </Styled.Link>
            </Styled.Message> 
          </Styled.Footer>
        */}
        </Styled.Container>
      </A4Page>
      <div className='html2pdf__page-break' />
    </>
  )
}

PageLayout.propTypes = {
  profileId: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  pageNumber: PropTypes.number,
  platform: PropTypes.oneOf([
    USER_INDEXES.instagram,
    USER_INDEXES.tiktok,
    USER_INDEXES.youtube,
  ]).isRequired,
  children: PropTypes.node,
  isBranded: PropTypes.bool,
}

export default PageLayout
