import React from 'react'
import { useSelector } from 'react-redux'

import {
  isUserAuthenticatedSelector,
  userGrantSelector,
  userCredsLoadProgressSelector,
  isCompareProfilesExistSelector,
} from '../redux/selectors'

import CompareProfilesPageTips from '../components/pagesTips/CompareProfilesPageTips'
import ComparedProfilesContainer from '../components/comparedProfiles/ComparedProfilesContainer'

import { ComparePageWrapper } from '../components/common/styledWrappers'

const CompareProfilesPage = () => {
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const { package_free: isFreeUser } = useSelector(userGrantSelector)
  const isUserCredsLoading = useSelector(userCredsLoadProgressSelector)
  const isCompareListHasProfiles = useSelector(isCompareProfilesExistSelector)

  const notAllowed = !isUserAuthenticated || !!isFreeUser

  return (
    <ComparePageWrapper>
      {!isUserCredsLoading && (
        <>
          <ComparedProfilesContainer notAllowed={notAllowed} />
          {!isCompareListHasProfiles && <CompareProfilesPageTips notAllowed={notAllowed} />}
        </>
      )}
    </ComparePageWrapper>
  )
}

export default CompareProfilesPage
