import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import useTranslation from '../../../localization/useTranslation'

import { HeartFull, HeartEmpty } from '../../common/icons'
import WithTip from '../../common/popups/WithTip'
import WithUpgradeTip from '../../common/popups/WithUpgradeTip'
import { debouncedAsync } from '../../../utils'
import { Button } from '@mui/material'

const IconWrapper = styled.div`
  ${props => props.disabled && props.theme.disableBlock};
  position: relative;
`

const ProfileFavsTool = ({
  isFav,
  removeFromFavs,
  addToFavs,
  userindex = '',
  canAddToFavs,
  enableFavorites,
  isUserAuthenticated,
}) => {
  const [favSelected, toggleFavSelected] = useState(isFav) // bool
  const { labelStrings } = useTranslation()

  const [isLoaded, setIsLoaded] = useState(false)

  const changeUserFavs = useCallback(
    debouncedAsync(async latestFav => {
      setIsLoaded(true)
      if (!latestFav && !canAddToFavs) return await removeFromFavs()
      if (enableFavorites && canAddToFavs) {
        return await (latestFav ? addToFavs() : removeFromFavs())
      }
    }),
    [canAddToFavs, enableFavorites, isLoaded]
  )
  useEffect(() => {
    if (!canAddToFavs && favSelected && !isFav) toggleFavSelected(false)
  }, [canAddToFavs])

  useEffect(() => {
    toggleFavSelected(isFav)
  }, [isFav])

  useEffect(() => {
    if (isFav === favSelected) return
    ;(async () => {
      const success = await changeUserFavs(favSelected)

      if (success) setIsLoaded(false)
      if (!success) toggleFavSelected(!favSelected)
    })()
  }, [favSelected])

  const FullHeartIcon = (
    <IconWrapper disabled={!enableFavorites}>
      <HeartFull userindex={userindex} />
    </IconWrapper>
  )
  const EmptyHeartIcon = (
    <IconWrapper disabled={!enableFavorites}>
      <HeartEmpty userindex={userindex} isActiveIcon={false} />
    </IconWrapper>
  )

  const clickHandler = () => {
    if (isLoaded) return
    if (!enableFavorites) return
    if (!canAddToFavs && !isFav) return
    toggleFavSelected(!favSelected)
  }

  return (
    <Button
      onClick={clickHandler}
      disableFocusRipple
      disableRipple
      sx={{
        width: 'fit-content',
        minWidth: '30px',
        cursor: isLoaded ? 'wait' : 'pointer',
        '&:hover': {
          backgroundColor: 'transparent',
          span: {
            cursor: isLoaded ? 'wait' : 'pointer',
            svg: {
              cursor: isLoaded ? 'wait' : 'pointer',
            },
          },
        },
        svg: {
          opacity: isLoaded ? 0.5 : 1,
        },
      }}
    >
      {!enableFavorites || !canAddToFavs ? (
        !canAddToFavs && favSelected ? (
          <WithTip
            tipText={labelStrings.removeFromFavorites}
            position="top center"
            userindex={userindex}
          >
            <span>{FullHeartIcon}</span>
          </WithTip>
        ) : (
          <WithUpgradeTip
            tipText={labelStrings.addMoreUsers}
            userindex={userindex}
            withSignup={!isUserAuthenticated}
          >
            {EmptyHeartIcon}
          </WithUpgradeTip>
        )
      ) : (
        <WithTip
          tipText={favSelected ? labelStrings.removeFromFavorites : labelStrings.addToFavorites}
          position="top center"
          userindex={userindex}
        >
          <span>{favSelected ? FullHeartIcon : EmptyHeartIcon}</span>
        </WithTip>
      )}
    </Button>
  )
}

ProfileFavsTool.propTypes = {
  isFav: PropTypes.bool,
  removeFromFavs: PropTypes.func,
  addToFavs: PropTypes.func,
  userindex: PropTypes.string,
  canAddToFavs: PropTypes.bool,
  enableFavorites: PropTypes.bool,
  isUserAuthenticated: PropTypes.bool,
}

export default ProfileFavsTool
