export const downloadMedia = (url, mediaFormat, index) => {
  const currentDate = new Date()
  const formattedDate = currentDate
    .toISOString()
    .slice(0, 19)
    .replace(/:/g, '-')
    .replace('T', '_')
  // Create a temporary anchor element
  const anchorElement = document.createElement('a')
  anchorElement.style.display = 'none'
  // Create a new XMLHttpRequest for each media
  const xhr = new XMLHttpRequest()
  xhr.open('GET', url, true)
  xhr.responseType = 'blob'

  xhr.onload = () => {
    if (xhr.status === 200) {
      // Create a temporary URL for the media blob
      const mediaUrl = URL.createObjectURL(xhr.response)

      // Update the anchor element with the media URL and trigger the download
      anchorElement.href = mediaUrl
      anchorElement.download = `media${index ? `-${index + 1}` : ''}-${formattedDate}.${mediaFormat}`
      anchorElement.click()

      // Clean up the temporary URL
      URL.revokeObjectURL(mediaUrl)
    }
  }

  xhr.send()
}

export const CARD_HEIGHT = 539
export const CARD_HEIGHT_BRAND_CONTENT = 570
export const CARD_EDIT_HEIGHT = 488

export const getPercent = value => {
  // if (value < 0 || value > 1) {
  //   throw new Error('Value must be between 0 and 1')
  // }
  const returnedValue = value * 100
  return !isNaN(returnedValue) ? returnedValue.toFixed(1) + '%' : 0
}

export const TYPE_RANGE_COLORS = {
  COMMENT_POSITIVITY_RATE: 'COMMENT_POSITIVITY_RATE',
  CONTROVERSIAL_COMMENT_RATE: 'CONTROVERSIAL_COMMENT_RATE',
}

export const getRangeAndColor = (number, type) => {
  const ranges = [
    {
      range: 'Very Low',
      min: 0,
      max: 19,
      color: type === TYPE_RANGE_COLORS.CONTROVERSIAL_COMMENT_RATE ? '#36B37E' : '#ff4441',
    },
    {
      range: 'Low',
      min: 20,
      max: 39,
      color: type === TYPE_RANGE_COLORS.CONTROVERSIAL_COMMENT_RATE ? '#CAE318' : '#F39F01',
    },
    {
      range: 'Medium',
      min: 40,
      max: 59,
      color: type === TYPE_RANGE_COLORS.CONTROVERSIAL_COMMENT_RATE ? '#FBEF03' : '#FBEF03',
    },
    {
      range: 'High',
      min: 60,
      max: 79,
      color: type === TYPE_RANGE_COLORS.CONTROVERSIAL_COMMENT_RATE ? '#F39F01' : '#CAE318',
    },
    {
      range: 'Very High',
      min: 80,
      max: 100,
      color: type === TYPE_RANGE_COLORS.CONTROVERSIAL_COMMENT_RATE ? '#ff4441' : '#36B37E',
    },
  ]

  const defaultRange = {
    range: 'Invalid Range',
    color: type === TYPE_RANGE_COLORS.CONTROVERSIAL_COMMENT_RATE ? '#36B37E' : '#ff4441',
  }

  const { range, color } =
    ranges.find(rangeObj => number >= rangeObj.min && number <= rangeObj.max) || defaultRange

  return { range, color }
}
