import React, { useState } from 'react'
import { Box, Button } from '@mui/material'
import useTranslation from '../../../localization/useTranslation'
import { useSelector } from 'react-redux'
import { canCreateCampaignsSelector } from '../../../redux/selectors'
import WithUpgradeTip from '../../../components/common/popups/WithUpgradeTip'
import { CreateCampaign } from '../../sections/create-campaign/CreateCampaign'
import { useStep } from '../../hooks/useStep'

export const CreateCampaigns = ({ navType }) => {
  const { labelStrings } = useTranslation()

  const [isCreateCampaignOpen, setIsCreateCampaignOpen] = useState(false)

  const [currentStep, helpers] = useStep(9)
  // @ts-ignore
  const { goToNextStep, goToPrevStep, setStep, reset } = helpers

  const canCreateCampaigns = useSelector(canCreateCampaignsSelector)

  const toggleIsCreateCampaignOpen = () => {
    if (!canCreateCampaigns) return
    setIsCreateCampaignOpen(!isCreateCampaignOpen)
  }

  return (
    <Box sx={{ width: '100%' }}>
      {canCreateCampaigns ? (
        <>
          <Button
            sx={{ width: '100%', minWidth: navType !== 'default' ? '25px' : '64px' }}
            variant="contained"
            disabled={!canCreateCampaigns}
            onClick={toggleIsCreateCampaignOpen}
            size={navType !== 'default' ? 'small' : 'medium'}
          >
            {navType === 'default' ? labelStrings.createNewCampaign : '+'}
          </Button>
          {isCreateCampaignOpen && (
            <CreateCampaign
              openModal={isCreateCampaignOpen}
              setOpenModal={setIsCreateCampaignOpen}
              currentStep={currentStep}
              goToNextStep={goToNextStep}
              goToPrevStep={goToPrevStep}
              setStep={setStep}
              resetStep={reset}
            />
          )}
        </>
      ) : (
        <WithUpgradeTip tipText={labelStrings.createUnlimitedCampaigns} position="bottom center">
          <div>
            <Button
              sx={{ width: '100%', minWidth: navType !== 'default' ? '25px' : '64px' }}
              variant="contained"
              disabled={!canCreateCampaigns}
              onClick={toggleIsCreateCampaignOpen}
              size={navType !== 'default' ? 'small' : 'medium'}
            >
              {navType === 'default' ? labelStrings.createNewCampaign : '+'}
            </Button>
          </div>
        </WithUpgradeTip>
      )}
    </Box>
  )
}
