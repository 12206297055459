import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Popup from 'reactjs-popup'
import { SignButton, ProfileButton } from '../../common/buttons/styledButtons'
import { Divider } from '../../common/styledWrappers'
import { ProfileNavIcon } from '../navbarIcons'
import AudienceReportsTokensStat from '../../tokens/stats/AudienceReportsTokensStat'
import CampaignsSeatsTokensStat from '../../tokens/stats/CampaignsSeatsTokensStat'

import useTranslation from '../../../localization/useTranslation'

const Wrapper = styled.div`
  position: relative;

  & * {
    font-family: ${props => props.theme.fontFamilyInter};
  }
  .profile-popup-nav-content {
    padding: 0 !important;
    border-radius: 10px !important;
    width: 200px !important;
    z-index: 1000 !important;
    left: -165px !important;
    border: none !important;
  }

  .profile-popup-nav-content,
  .email-popup-content {
    z-index: 400;
    background: ${props => props.theme.color.white} !important;
    box-shadow: 0px 6px 20px #8436302b !important;

    & .profile-popup-nav-arrow,
    & .email-popup-arrow {
      z-index: 300;
      left: 184px !important;
      background: ${props =>
        props.showTokensStats ? props.theme.color.kabul : props.theme.color.white} !important;
      box-shadow: 0px 0px 6px transparent !important;
    }
  }
`

const ItemsDivider = styled(Divider)`
  border: none;
  border-top: 1px solid ${props => props.theme.color.mineShaft20};
`

const NavButton = styled(SignButton)`
  color: ${props => props.theme.color.mineShaft};
  font-size: 14px;
  &:hover {
    color: ${props => props.theme.color.darkGrey};
  }
`

const NavItem = styled.div`
  padding: 5px 0;
`

const TokensGroupSection = styled.div`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  position: relative;
  top: -1px;
`

const ProfileNavMenu = ({
  onLogoutClick,
  goToSettings,
  showTokensStats = false,
  showCampaignsTrackStats = false,
  openMenuManually = false,
  isTrial = false,
}) => {
  const { labelStrings } = useTranslation()
  return (
    <Wrapper
      showTokensStats={showTokensStats && showCampaignsTrackStats}
    >
      <Popup
        trigger={
          <div>
            <ProfileButton onClick={goToSettings}>
              <ProfileNavIcon />
            </ProfileButton>
          </div>
        }
        className={'profile-popup-nav'}
        on="hover"
        closeOnDocumentClick
        position="bottom right"
        open={openMenuManually}
      >
        {close => (
          <>
            {showTokensStats && (
              <TokensGroupSection>
                <AudienceReportsTokensStat isTrial={isTrial} />
                {showCampaignsTrackStats && (
                  <>
                    <ItemsDivider />
                    <CampaignsSeatsTokensStat />
                  </>
                )}
              </TokensGroupSection>
            )}
            <NavItem>
              <NavButton onClick={goToSettings}> {labelStrings.editYourAccount}</NavButton>
            </NavItem>
            <ItemsDivider />
            <NavItem>
              <NavButton onClick={onLogoutClick}>{labelStrings.logout}</NavButton>
            </NavItem>
          </>
        )}
      </Popup>
    </Wrapper>
  )
}

ProfileNavMenu.propTypes = {
  onLogoutClick: PropTypes.func,
  goToSettings: PropTypes.func,
  showTokensStats: PropTypes.bool,
  showCampaignsTrackStats: PropTypes.bool,
  openMenuManually: PropTypes.bool,
  isTrial: PropTypes.bool,
}

export default ProfileNavMenu
