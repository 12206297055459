import { Card, ListItem, ListItemText, Skeleton, Tab, Tabs, TextField, Typography, styled } from '@mui/material'

export const StyledCreatorsSuggestedInput = styled(TextField)({
  maxWidth: '376px',
  width: '100%',
  '& .MuiInputBase-root': {
    paddingLeft: '6px',
    paddingRight: '6px',
    borderRadius: '10px',
  },
  '& .MuiFormLabel-root': {
    left: '5px',
    top: '-1px',
  },
})

export const StyledCardCreatorList = styled(Card)({
  maxWidth: '376px',
  maxHeight: '336px',
  width: '100%',
  position: 'absolute',
  zIndex: 3,
  top: 5,
  padding: 0,
})

export const StyledListItem = styled(ListItem)({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(255, 93, 90, 0.08)',
    borderRadius: '8px',
  },
})

export const StyledListItemText = styled(ListItemText)({
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '22px',
  color: '#212B36',
})

export const StyledSocialPlatformTabs = styled(Tabs)({
  minHeight: '36px',
  transition: 'transition: all 300ms ease',
  '& .MuiTabs-indicator': {
    backgroundColor: 'rgba(255, 93, 90, 0.08)',
    height: '100%',
    zIndex: 1,
    borderRadius: '10px',
  },
})

export const StyledSocialPlatformTab = styled(Tab)({
  minHeight: '36px',
  minWidth: '36px',
  zIndex: 2,
  '&:hover': {
    backgroundColor: '#F9FAFB',
    borderRadius: '12px',
  },
})

export const StyledSkeleton = styled(Skeleton)({
  borderRadius: '8px',
})

export const StyledAddedText = styled(Typography)({
  color: 'rgba(54, 179, 126, 1)',
  fontWeight: 500,
  textTransform: 'lowercase'
})