import styled from 'styled-components'
import { SOCIAL_PLATFORMS_NAMES } from '../../../constants/appSettings'
import { RangeSlidersToggleButton } from '../../common/buttons/styledButtons'
const isTiktok = SOCIAL_PLATFORMS_NAMES.tiktok.name

export const RoundedWrapper = styled.div`
  ${props =>
    props.platform === isTiktok
      ? `background: rgba(198, 243, 242, 0.3);`
      : `background-color:#fff9f9`}
  border: ${props => (props.platform === isTiktok ? '0.5px solid #00D3CC' : '0.5px solid #bc9797')};
  color: #bc9797;
  box-sizing: border-box;
  border-radius: 13px;
  padding: ${props =>
    `${props.paddingTop ?? 0}px ${props.paddingLeftRight ?? 13}px ${props.paddingBottom ?? 13}px`};
  margin-bottom: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
`
export const RoundedWrapperTitle = styled.div`
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.darkBrown};
  background: ${props => props.theme.colors.lightBrown};
  border-bottom: 0.5px solid ${props => props.theme.colors.mauve};
  border-radius: 13px 13px 0px 0px;
  padding: 12px 18px;
  text-transform: uppercase;
`

export const WrapperWithPaddings = styled.div`
  padding: 0px 13px 13px;
`

export const CategoryWrapperInput = styled.div`
  margin-top: 15px;
`
export const CategoryLabel = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: ${props => props.theme.colors.mauve};
  letter-spacing: 1.65px;
  padding-left: 12px;
  position: relative;
  text-transform: uppercase;  
  margin: 10px 0px 8px 0;
`

export const BtnWrapper = styled.div`
  margin-left: 10px;
  width: 100%;
  display: flex;
  align-items: center;
`
export const KeywordsBtn = styled(RangeSlidersToggleButton)`
  position: relative;
  font-size: 10px;
  font-weight: 700;
  border-radius: 8px;
  height: unset;
  padding: 3.5px 9px;
  font-family: ${props => props.theme.fontFamilyInter};
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }
`