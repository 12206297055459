import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  isUserDetailsPresentSelector,
  userDetailsSelector,
  isUserDetailsErrorSelector,
} from '../../../redux/selectors'
import { withReduxActions } from '../../../hocs/withRedux'
import { toggleContactUs } from '../../../redux/ducks/appSettingsDucks'
import { updateUserBillingDetails } from '../../../redux/ducks/userDucks'
import BillingDetails from './BillingDetails'

const BillingDetailsContainer = ({ toggleContactUs, updateUserBillingDetails }) => {
  const isUserDetailsPresent = useSelector(isUserDetailsPresentSelector)
  const userDetails = useSelector(userDetailsSelector)
  const isUserDetailsError = useSelector(isUserDetailsErrorSelector)

  const [isUpdateLoading, setIsUpdateLoading] = useState(false)

  const { billing_address = {} } = userDetails

  const isBillingDetailsPresent = !!Object.keys(billing_address).length

  const prepareUpdatedBillingDetails = formUpdateDetails => ({
    vat_number: formUpdateDetails.vatId,
    billing_address: {
      first_name: formUpdateDetails.firstName,
      last_name: formUpdateDetails.lastName,
      company: formUpdateDetails.company,
      line1: formUpdateDetails.addressLine1,
      line2: formUpdateDetails.addressLine2,
      city: formUpdateDetails.city,
      zip: formUpdateDetails.zip,
      state: formUpdateDetails.state,
      email: formUpdateDetails.billingEmail,
      country: formUpdateDetails.country,
    },
  })

  const currentBillingDetails = {
    firstName: billing_address.first_name || '',
    lastName: billing_address.last_name || '',
    company: billing_address.company || '',
    addressLine1: billing_address.line1 || '',
    addressLine2: billing_address.line2 || '',
    zip: billing_address.zip || '',
    city: billing_address.city || '',
    country: billing_address.country || '',
    vatId: billing_address.vat_number || '',
    billingEmail: billing_address.email || '',
  }

  const updateBillingDetails = updatedDetails => {
    ;(async () => {
      setIsUpdateLoading(true)
      await updateUserBillingDetails(prepareUpdatedBillingDetails(updatedDetails))
      setIsUpdateLoading(false)
    })()
  }

  return (
    <BillingDetails
      updateBillingDetails={updateBillingDetails}
      openContactUs={toggleContactUs}
      isUserDetailsPresent={isUserDetailsPresent}
      currentBillingDetails={currentBillingDetails}
      isUpdateLoading={isUpdateLoading}
      isUserDetailsError={isUserDetailsError}
      isBillingDetailsPresent={isBillingDetailsPresent}
    />
  )
}

BillingDetailsContainer.propTypes = {
  toggleContactUs: PropTypes.func,
  updateUserBillingDetails: PropTypes.func,
}

export default withReduxActions({ toggleContactUs, updateUserBillingDetails })(
  BillingDetailsContainer
)
