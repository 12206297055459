import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'
import styled from 'styled-components'
import { MULTI_RANGE_SLIDER_DEFAULT_RANGES } from '../../../constants/components'
import { SearchLabel } from '../styledWrappers'

const TickPercentWrapper = styled.div`
  position: absolute;
  margin-top: 26px;
  margin-left: -0.5px;
  width: 1px;
  height: 10px;
  background-color: #a3a3a3;
  left: ${props => props.percent}%;
`

const TickInnerWrapper = styled.div`
  position: absolute;
  margin-top: 40px;
  color: ${props => props.theme.colors.mauve};
  font-size: 11px;
  font-family: ${props => props.theme.fontFamilyInter};
  text-align: center;
  margin-left: ${props => -(100 / props.count) / 2}%;
  width: ${props => 100 / props.count}%;
  left: ${props => props.percent}%;
`

const HandleOuterWrapper = styled.div`
  left: calc(${props => props.percent}% - 13px);
  top: 10px;
  position: absolute;
  z-index: 2;
  background-color: transparent;
  border-radius: 100vw;
  cursor: pointer;
  width: 30px;
  height: 30px;
`

const HandleMiddleWrapper = styled.div`
  left: 8px;
  top: 11px;
  position: absolute;
  z-index: 3;
  background-color: ${props => props.theme.platformColor};
  margin: 0;
  border: 0;
  height: 10px;
  width: 10px;
  text-align: center;
  cursor: pointer;
  color: ${props => props.theme.platformColor};
  border-radius: 100vw;
`

const HandleInnerWrapper = styled.div`
  font-size: 11px;
  margin-top: -20px;
  color: transparent;
  font-family: 'sans-serif';
`

const TrackWrapper = styled.div`
  position: absolute;
  height: 4px;
  z-index: 1;
  margin-top: 24px;
  background-color: ${props => props.theme.platformColor};
  cursor: pointer;
  left: ${props => props.source.percent}%;
  top: 0px;
  width: ${props => props.target.percent - props.source.percent}%;
`

const SliderWrapper = styled.div`
  position: relative;
  margin-top: 5px;
`

const RailLine = styled.div`
  position: absolute;
  width: 100%;
  height: 1px;
  margin-top: 25px;
  background: ${props => props.theme.colors.darkBrown};
  &::before {
    content: '';
    position: absolute;
    right: -4px;
    height: 7px;
    width: 7px;
    background: ${props => props.theme.colors.darkBrown};
    border-radius: 50%;
    top: -3px;
    z-index: 1;
  }
  &::after {
    content: '';
    position: absolute;
    left: -4px;
    height: 7px;
    width: 7px;
    background: ${props => props.theme.colors.darkBrown};
    border-radius: 50%;
    top: -3px;
    z-index: 1;
  }
`

const sliderStyle = {
  position: 'relative',
  width: '100%',
  height: 50,
  top: '-15px',
  userSelect: 'none',
}

// slider component
const CommonMultiRangeSlider = ({
  values,
  labelText = '',
  id = '',
  onChange,
  onUpdate,
  ranges = MULTI_RANGE_SLIDER_DEFAULT_RANGES,
  showAllRangeSteps = false,
}) => {
  // utils and constants
  const { mapping, presentationMapping } = ranges || {}
  const convertRange = value => mapping[value]
  const convertRangeToPresentation = value => presentationMapping[value]

  // custom elements components
  const Tick = ({ tick, count }) => (
    <div>
      <TickPercentWrapper percent={tick.percent} className="tick_percent" />
      <TickInnerWrapper percent={tick.percent} count={count} className="tick_text">
        {convertRangeToPresentation(tick.value)}
      </TickInnerWrapper>
    </div>
  )

  const Handle = ({ handle: { id, value, percent }, getHandleProps }) => (
    <HandleOuterWrapper percent={percent}>
      <HandleMiddleWrapper {...getHandleProps(id)}>
        <HandleInnerWrapper>{convertRangeToPresentation(value)}</HandleInnerWrapper>
      </HandleMiddleWrapper>
    </HandleOuterWrapper>
  )

  const Track = ({ source, target, getTrackProps }) => (
    <TrackWrapper source={source} target={target} {...getTrackProps()} />
  )

  // main logic
  const [tickIndices, changeTickIndices] = useState([])

  useEffect(() => {
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  const updateDimensions = () => {
    // this changes the array of indexes for presentationMapping array which will be visible
    const defaultIndices = [0, 2, 7, 12, 18, 23, 29, 36, 41, 45, 49, 55, 59]
    const indicies = showAllRangeSteps ? mapping.map((_, idx) => idx) : defaultIndices
    changeTickIndices(indicies)
  }

  const mapValueToTick = value => mapping.indexOf(value)

  const onUpdateMapTickToValue = ([min, max]) => onUpdate && onUpdate([convertRange(min), convertRange(max)])

  const onChangeMapTickToValues = ([min, max]) => {
    onChange && onChange([convertRange(min), convertRange(max)])
  }

  const [min, max] = values
  const minTick = mapValueToTick(min)
  const maxTick = mapValueToTick(max)

  return (
    <SliderWrapper>
      {labelText && <SearchLabel htmlFor={id}>{labelText}</SearchLabel>}
      <Slider
        id={id}
        rootStyle={sliderStyle} // inline styles for the outer div. Can also use className prop.
        domain={[0, mapping.length - 1]}
        step={1}
        mode={2}
        values={[minTick, maxTick]}
        onUpdate={onUpdateMapTickToValue}
        onChange={onChangeMapTickToValues}
        warnOnChanges={true}
      >
        <Rail>
          {(
            { getRailProps } // adding the rail props sets up events on the rail
          ) => <RailLine {...getRailProps()} />}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map(handle => (
                <Handle key={handle.id} handle={handle} getHandleProps={getHandleProps} />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false} left={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
              ))}
            </div>
          )}
        </Tracks>
        <Ticks values={tickIndices}>
          {({ ticks }) => (
            <div className="slider-ticks">
              {ticks.map(tick => (
                <Tick key={tick.id} tick={tick} count={ticks.length} />
              ))}
            </div>
          )}
        </Ticks>
      </Slider>
    </SliderWrapper>
  )
}

CommonMultiRangeSlider.propTypes = {
  values: PropTypes.array.isRequired,
  labelText: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onUpdate: PropTypes.func,
  ranges: PropTypes.object,
  showAllRangeSteps: PropTypes.bool,
}

export default CommonMultiRangeSlider
