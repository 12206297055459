import React from 'react'
import styled from 'styled-components';

const MainContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  min-height: calc(100vh - 40px);  // 100vh and minus the 20px padding at top and bottom
`;



const Tile = styled.div`
  padding: 20px;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  background-color: #fef4ed;
  width: 550px; // set fixed width to prevent tile from growing
  height: 250px; // set fixed width to prevent tile from growing

  h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }

  a {
    color: #007BFF;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;


const ToolsForBrandsPage = () => {
  return (
    <MainContainer id='tools_for_brands'>
      {/* Other tiles can be added similarly */}
      <Tile>
        <h3>influData Chrome Extension</h3>
        <p>You can use our extention to explore our data directly on Instagram without leaving the platform. You get all scores, the growth and can even unlock and view audience reports or add interesting creators to collections. This extention is free for all our pro and all-in customers.</p>
        <a target="_blank" rel="noopener noreferrer" href="https://chrome.google.com/webstore/detail/infludata-chrome-extensio/mhijddlhlfojocbbakefhjkhgfkmnjkg">Download on Chrome Web Store</a>
      </Tile>
    </MainContainer>
  )
};

export default ToolsForBrandsPage;
