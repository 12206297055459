import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../../localization/useTranslation'
import GradientTransparentButton from '../../../common/buttons/GradientTransparentButton'
import Modal from '../../../common/modals/Modal/Modal'
import { themeCommonStyles } from '../../../../themes/infludataThemes'
import { USER_INDEXES } from '../../../../constants/appSettings'
import { BtnWrapper, MainWrapper, ModalPdfWrapper, OptionsWrapper, TitleName } from './styles'
import SingleCheckbox from '../../../common/checkboxes/SingleCheckbox'

const ProfilePdfModal = ({
  platform = '',
  closeModal,
  onExportModalClick,
  optionsModal = [],
  onHandleChangeModalOption,
}) => {
  const { labelStrings } = useTranslation()

  return (
    <Modal
      headerContent={labelStrings.exportProfileAsAPdf}
      platform={platform}
      closeModal={closeModal}
    >
      <ModalPdfWrapper>
        <MainWrapper>
          <OptionsWrapper>
            <TitleName platform={platform}>{labelStrings.pleaseChoseYourOption}</TitleName>
            {optionsModal?.map((checkbox, idx) => {
              return (
                <React.Fragment key={checkbox.id}>
                  <SingleCheckbox
                    onChange={() => onHandleChangeModalOption(idx)}
                    checked={checkbox.value}
                    defaultValue={checkbox.value}
                    labelText={labelStrings.modalPdfOptions[checkbox.id]}
                    faqQuestionId=""
                    isDisabled={false}
                  />
                </React.Fragment>
              )
            })}
          </OptionsWrapper>
        </MainWrapper>
      </ModalPdfWrapper>

      <BtnWrapper>
        <GradientTransparentButton
          onClick={() => onExportModalClick({ exportType: 'PDF' })}
          backgroundColor={themeCommonStyles.colors.offWhite}
          {...(platform
            ? { gradient: themeCommonStyles.getGradientsForPlatform(platform).main }
            : {})}
        >
          {labelStrings.exportAsPdf}
        </GradientTransparentButton>
        <GradientTransparentButton
          isDisabled={true}
          onClick={() => onExportModalClick({ exportType: 'PPX' })}
          backgroundColor={themeCommonStyles.colors.offWhite}
          {...(platform
            ? { gradient: themeCommonStyles.getGradientsForPlatform(platform).main }
            : {})}
        >
          {labelStrings.exportAsPPX}
        </GradientTransparentButton>
      </BtnWrapper>
    </Modal>
  )
}

ProfilePdfModal.propTypes = {
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  closeModal: PropTypes.func,
  onExportModalClick: PropTypes.func,
  optionsModal: PropTypes.array,
  onHandleChangeModalOption: PropTypes.func,
}

export default ProfilePdfModal
