import React from 'react'
import PropTypes from 'prop-types'

import { TickIcon } from '../icons/icons'
import { CheckboxWrapper, StyledCheckbox, Label } from './styles'

const Checkbox = ({ label = '', isChecked = false, onChange, ...checkboxProps }) => {
  return (
    <CheckboxWrapper className="checkbox-wrapper">
      <StyledCheckbox
        className={`checkbox ${isChecked ? 'checkbox_is-checked' : ''}`}
        isChecked={isChecked}
        onClick={onChange}
        {...checkboxProps}
      >
        <TickIcon />
      </StyledCheckbox>
      {label && (
        <Label className="checkbox-label" isChecked={isChecked} onClick={onChange}>
          {label}
        </Label>
      )}
    </CheckboxWrapper>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Checkbox
