import {Box, styled, Typography } from '@mui/material'

export const StyledHeading = styled(Typography)({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#212B36',
  marginBottom: '5px'
})

export const StyledSubheader= styled(Typography)({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '22px',
  color: '#212B36',
  marginBottom: '5px'
})

export const StatusesWrapper = styled(Box)({
  borderRadius: '8px', 
  border: '1px solid rgba(145, 158, 171, 0.12)',
  width: '167px',
  height: '48px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 4px'
})

export const StyledStatus = styled(Box)({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '26px',
  width: '77.5px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px'
})