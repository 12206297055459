import React, { useState } from 'react'
import {
  PDF_PAGE_DIVIDER_CLASS,
  PDF_PAGE_FORMATS,
  PDF_PAGE_ORIENTATIONS,
  PDF_PAGE_UNITS,
} from '../../../../constants/exportPdfSettings'
import html2pdf from 'html2pdf.js'
import { SOCIAL_PLATFORMS_NAMES } from '../../../../constants/appSettings'
import DownloadIcon from '@mui/icons-material/Download'
import { format } from 'date-fns'
import LoadingButton from '@mui/lab/LoadingButton'
import { audienceReportsSelector } from '../../../../redux/selectors'
import { useSelector } from 'react-redux'

const customFont = '../../../../../fonts/Inter/Inter-VariableFont_slnt,wght.ttf'

const cleanHtmlContent = htmlContent => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlContent.innerHTML, 'text/html')

  // Find all img tags
  const imgs = doc.querySelectorAll('img')
  imgs.forEach(img => {
    // Check if the src attribute contains an incomplete or null URL
    if (!img.src || img.src.includes('null') || img.src.endsWith('/api/collab/proxy-cors/')) {
      // Remove the img element or handle it as needed
      img.remove()
    }
  })

  // Replace all instances of ß with ss
  let htmlString = doc.body.innerHTML.replace(/ß/g, 'ss')

  htmlContent.innerHTML = htmlString
  return htmlContent
}

export const OkPagePdfContainer = ({ username, platform, pdfRef }) => {
  const [isLoading, setIsLoading] = useState(false)
  
  const createPdfFileName = () => {
    const dateOfReport = new Date()
    const platformName = SOCIAL_PLATFORMS_NAMES[platform].prettyName
    return `${platformName}User_${username}_${dateOfReport}.pdf`
  }

  const currDate = new Date()
  const prettifyDate = format(currDate, 'dd.MM.yyyy')

  const createAndSavePdf = async ({ refElement }) => {
    if (!refElement) return

    setIsLoading(true)
    const cleanedHtml = cleanHtmlContent(refElement)
    const pdfOptions = {
      margin: 0,
      filename: createPdfFileName(),
      image: { type: 'jpeg', quality: 1 },
      html2canvas: {
        scale: 2, // Increased from 2 to 3 for higher resolution capture
        imageTimeout: 7000,
        useCORS: true,
        logging: process.env.REACT_APP_NODE_ENV !== 'prod',
      },
      jsPDF: {
        unit: PDF_PAGE_UNITS.mm,
        format: [360, 210],
        orientation: PDF_PAGE_ORIENTATIONS.landscape,
        putOnlyUsedFonts: true,
      },
      pagebreak: { avoid: [`.${PDF_PAGE_DIVIDER_CLASS}`] },
    }

    try {
      await html2pdf()
        .set(pdfOptions)
        .from(cleanedHtml)
        .toPdf()
        .get('pdf')
        .then(function(pdf) {
          const totalPages = pdf.internal.getNumberOfPages()
          for (let i = 1; i <= totalPages; i++) {
            if (i !== 1) return

            pdf.addFileToVFS(customFont)

            pdf.setPage(i)
            pdf.setFontSize(9)
            pdf.setTextColor('#784E4E')
            pdf.addFont(customFont, 'Inter', 'bold')

            pdf.text(
              pdf.internal.pageSize.getWidth() / 1.015,
              pdf.internal.pageSize.getHeight() - 8,
              `CREATED ON: ${prettifyDate}`,
              null,
              90
            )
          }
        })
        .save()
      setIsLoading(false)
    } catch (err) {
      console.log({ err })
      // clean up pdfs overlays
      document.body.removeChild(document.querySelector('.html2pdf__overlay'))
      document.body.removeChild(document.querySelector('.html2canvas-container'))
    }
  }

  return (
    <>
      <LoadingButton
        loading={isLoading}
        onClick={async () => await createAndSavePdf({ refElement: pdfRef })}
        sx={{ height: '36px', cursor: 'pointer', width: 'fit-content' }}
      >
        {isLoading ? '' : <DownloadIcon sx={{ fill: '#784E4E' }} />}
      </LoadingButton>
    </>
  )
}
