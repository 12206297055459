import React, { useState } from 'react'
import MenuPopover from '../../components/menu-popover/MenuPopover'
import { Box, Divider, IconButton, InputAdornment, MenuItem, Stack, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { DashboardPdfRender } from './Pdf/DashboardPdfRender'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const defaultPdfExportState = {
  isExportInit: false,
  isExportInProgress: false,
  isMarkupReady: false,
  dateOfReport: new Date().toString(),
}

export const SharedPopover = props => {
  const { openPopover, handleClosePopover, currentDashboard, campaignId, editDashboard } = props
  const { sharePublicToken, dashboardId, dashboardName } = currentDashboard

  const queryLink = `${window.location.origin}/dashboardPreview?campaignId=${campaignId}&dashboardId=${dashboardId}&dashboardToken=${sharePublicToken}`

  const [pdfExportState, setPdfExportState] = useState(defaultPdfExportState)
  const [isExportPdfProgress, setIsExportPdfProgress] = useState(false)

  const [accessValue, setAccessValue] = useState(false)
  const [linkValue, setLinkValue] = useState('')

  const onCopyText = () => {
    return navigator.clipboard.writeText(queryLink)
  }

  const changePdfExportState = (currentState = {}) => {
    setPdfExportState({ ...pdfExportState, ...currentState })
  }

  const onExportPdf = () => {
    if (pdfExportState.isExportInProgress || pdfExportState.isExportInit) return
    changePdfExportState({ isExportInit: true, isExportInProgress: true })
    setIsExportPdfProgress(true)
  }

  const onChangeAccess = event => {
    const {
      target: { value },
    } = event
    const booleanValue = value === 'true' ? true : false

    booleanValue ? setLinkValue(queryLink) : setLinkValue('')

    editDashboard({ isShared: booleanValue, withRefetch: false })
    handleClosePopover()

    return setAccessValue(booleanValue)
  }

  return (
    <>
      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="top-right"
        sx={{ width: 347, mt: 1.5 }}
      >
        <Stack justifyContent="center">
          <Stack sx={{ my: '14px', px: 1 }} spacing={2}>
            <TextField
              select
              // size="small"
              id="access-select"
              defaultValue={'false'}
              value={String(accessValue)}
              label={'Access'}
              onChange={onChangeAccess}
              sx={{
                width: '100%',
                '& .MuiInputBase-input': {
                  background: 'white',
                  borderRadius: '8px',
                },
              }}
              inputProps={{ 'aria-label': 'label' }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      maxHeight: 200,
                    },
                  },
                },
                sx: { textTransform: 'capitalize' },
              }}
            >
              <MenuItem value={'false'}>Don’t Share Dashboard via Link</MenuItem>
              <MenuItem value={'true'}>Share Dashboard View via Link</MenuItem>
            </TextField>

            {linkValue && (
              <TextField
                id="dashboard-link"
                label="Dashboard Link"
                value={linkValue}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="copy link" onClick={onCopyText} edge="end">
                        <ContentCopyIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Stack>
          <Divider sx={{ mb: 1 }} />
          <Box sx={{ margin: '0 auto', py: 1 }}>
            <LoadingButton
              onClick={onExportPdf}
              sx={{ p: '11px 22px' }}
              loading={isExportPdfProgress}
              variant="contained"
              endIcon={<FileDownloadIcon />}
              loadingPosition="end"
            >
              <span>Export Dashboard as PDF</span>
            </LoadingButton>
          </Box>
        </Stack>
      </MenuPopover>
      <DashboardPdfRender
        dashboardName={dashboardName}
        setIsExportPdfProgress={setIsExportPdfProgress}
        changePdfExportState={currState => changePdfExportState(currState)}
        pdfExportState={pdfExportState}
        setPdfExportState={setPdfExportState}
        defaultPdfExportState={defaultPdfExportState}
        currentDashboard={currentDashboard}
      />
    </>
  )
}
