import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
`

export const Wrapper = styled(Row)`
  justify-content: space-between;
  max-width: 100%;
  padding-right: 10%;
  & * {
    font-family: ${props => props.theme.fontFamilyInter};
  }
  @media screen and (max-width: 1250px) {
    padding-right: 5%;
  }
`

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const DownloadIconWrapper = styled.a`
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`

export const InvoiceLink = styled.a`
  color: ${props => props.theme.primaryColor};
  text-decoration: underline;
  font-size: 15px;
  display: block;
  overflow-wrap: anywhere;
  &:hover {
    opacity: 0.6;
  }
`

export const Title = styled.div`
  color: ${props => props.theme.color.grey};
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  margin: 0 0 10px 0;
`

export const SubTitle = styled(Title)`
  color: #9b9b9b;
  font-size: 10px;
`

export const SubTitlesRow = styled(Row)`
  align-items: center;
  // justify-content: space-between;
`

export const WidthWrap = styled.div`
  width: ${props => props.width || '10px'};
  min-width: ${props => props.width || '10px'};
  padding: 0 10px;
  ${props => props.center && props.theme.flex.centerAll}
`

export const TextWrapper = styled.div`
  font-size: 15px;
  color: ${props => props.theme.color.mineShaft};
`

export const InvoicesSection = styled(FlexColumn)`
  // margin-right: 30px;
  // max-width: 66%;
  // width: 66%;
`

export const InvoicesDynamicHeightSection = styled.div`
  transition: all 0.5s linear;
`

export const NextBillingSection = styled(FlexColumn)`
  width: fit-content;
  // margin-right: 15px;
  padding: 0 20px;
`

export const ShowMoreBtn = styled.div`
  margin: 10px 0 10px 0;
  color: ${props => props.theme.primaryColor};
  text-decoration: underline;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`
