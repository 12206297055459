import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import AudienceCountriesCitiesChart from '../../../../components/influencerProfile/audienceAnalysis/audienceAnalysisCharts/AudienceCountriesCitiesChart'
import AudienceGenderChart from '../../../../components/influencerProfile/audienceAnalysis/audienceAnalysisCharts/AudienceGenderChart'
import AudienceLanguageChart from '../../../../components/influencerProfile/audienceAnalysis/audienceAnalysisCharts/AudienceLanguageChart'
import AudienceAgeGroupsChart from '../../../../components/influencerProfile/audienceAnalysis/audienceAnalysisCharts/AudienceAgeGroupsChart'
import AudienceTypeChart from '../../../../components/influencerProfile/audienceAnalysis/audienceAnalysisCharts/AudienceTypeChart'

export const OkPageFollowers = props => {
  const { platform, audienceData } = props || {}
  return (
    <Stack>
      <Box
        sx={{
          width: 'fit-content',
          px: 2,
          borderRadius: '8px',
          backgroundColor: '#FF5D5A',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ml: -2,
        }}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            color: 'white',
            lineHeight: '19px',
            fontSize: '16px',
            mb: 2,
          }}
        >
          Followers
        </Typography>
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ maxHeight: '340px', width: '100%' }}
      >
        <Stack sx={{ width: '45%' }}>
          <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
            <Box sx={{ width: '400px' }}>
              <AudienceCountriesCitiesChart
                userindex={platform}
                countryArray={audienceData?.audienceAnalysis?.countryArray}
                cityArray={audienceData?.audienceAnalysis?.cityArray}
                followersCount={audienceData?.followers}
                isDisabledAnalysis={false}
                isLikersReport={false}
                chartTitle={'Country'}
                chartType="country"
                isSmall
                isForPdf
                isForPdfAudience
              />
            </Box>
            <Box sx={{ width: '300px' }}>
              <AudienceCountriesCitiesChart
                userindex={platform}
                countryArray={audienceData?.audienceAnalysis?.countryArray}
                cityArray={audienceData?.audienceAnalysis?.cityArray}
                followersCount={audienceData?.followers}
                isDisabledAnalysis={false}
                isLikersReport={false}
                chartTitle={'City'}
                chartType="city"
                isSmall
                isForPdf
                isForPdfAudience
              />
            </Box>
          </Stack>
        </Stack>
        <Stack sx={{ width: '27.5%' }}>
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#784E4E',
              lineHeight: '19px',
              fontSize: '15px',
            }}
          >
            GENDER
          </Typography>
          <AudienceGenderChart
            userindex={platform}
            genderMtoF={audienceData?.audienceAnalysis?.genderMtoF}
            isDisabledAnalysis={false}
            isSmall
            isForPdf
          />
        </Stack>
        <Stack sx={{ width: '27.5%' }}>
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#784E4E',
              lineHeight: '19px',
              fontSize: '15px',
            }}
          >
            AUDIENCE TYPE
          </Typography>
          <AudienceTypeChart
            userindex={platform}
            audienceType={audienceData?.audienceAnalysis?.audienceType}
            isDisabledAnalysis={false}
            isSmall
            isForPdf
          />
        </Stack>
      </Stack>
      <Stack direction="row" spacing={10} justifyContent="start" sx={{ mt: '73.5px !important' }}>
        <Stack sx={{ width: '25%' }}>
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#784E4E',
              lineHeight: '19px',
              fontSize: '15px',
            }}
          >
            LANGUAGE
          </Typography>

          <AudienceLanguageChart
            userindex={platform}
            languageArray={audienceData?.audienceAnalysis?.languageArray}
            isDisabledAnalysis={false}
            followersCount={audienceData?.followers}
            isLikersReport={false}
            customHeight="202px"
            isSmall
            isForPdf
          />
        </Stack>
        <Stack sx={{ width: '25%', position: 'relative' }}>
          <Typography
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              fontWeight: 'bold',
              color: '#784E4E',
              lineHeight: '19px',
              fontSize: '15px',
            }}
          >
            AGE GROUPS
          </Typography>
          <AudienceAgeGroupsChart
            userindex={platform}
            averageBirthyearArray={audienceData?.audienceAnalysis?.averageBirthyearArray}
            isDisabledAnalysis={false}
            followersCount={audienceData?.followers}
            birthyearMedian={audienceData?.audienceAnalysis?.birthyearMedian}
            isLikersReport={false}
            customHeight="202px"
            isSmall
            isForPdf
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
