import styled from 'styled-components'
import { USER_INDEXES } from '../../../constants/appSettings'

// AddToCampaignModal
export const CreateCampaignButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid ${props => props.theme.colors.lightBrown};
  background-color: ${props => props.theme.colors.offWhite};
`

export const CampaignList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px 20px;
  max-width: 100%;
  min-width: 100%;
  max-height: 400px;
  overflow-y: auto;
  ${props => props.theme.scrollbarStyles}
`

export const CampaignName = styled.span`
  font-size: 15px;
  font-weight: ${props => (props.isUserInCampaign ? 'bold' : 'normal')};
  line-height: 19px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => (props.isUserInCampaign ? props.theme.colors.white : props.theme.colors.brown)};
`

export const CampaignCount = styled.span`
  font-size: 10px;
  line-height: 12px;
  height: 18px;
  min-width: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.darkBrown};
  border-radius: 9px;
`

export const CampaignItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  width: 100%;
  border-radius: 9px;
  cursor: pointer;

  ${props =>
    props.isUserInCampaign
      ? `
    background: ${(() => {
      if (!!props.platform && props.platform === USER_INDEXES.tiktok) {
        return props.theme.gradients.mainTiktok
      }
      return props.theme.gradients.main
    })()};
    padding: 11px 16px;
  `
      : `
    background: ${props.theme.colors.lightMauve};
    border: 1px solid ${props.theme.colors.lightMauve};
  `}

  &:hover {
    ${props =>
      !props.isUserInCampaign &&
      `
      background-color: ${props.theme.colors.mediumBrown};
      border: 1px solid ${props.theme.colors.mauve};
    `}
  }

  & + & {
    margin-top: 10px;
  }
`

export const ConfirmBlock = styled(CreateCampaignButtonWrapper)`
  border-bottom: none;
  border-top: 1px solid ${props => props.theme.colors.lightBrown};
`

// EditCampaignModal
export const CreateCampaignModalWrapper = styled.div`
  padding: 30px 20px;

  .textarea {
    min-height: 140px;
  }

  label {
    font-weight: 700;
  }

  #multiinput_wrapper {
    margin-bottom: 30px;
  }
`

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const boxModalStyles = {
  backgroundColor: 'white',
  borderRadius: '16px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '408px',
  maxHeight: '700px',
  height: 'auto',
  overflow: 'hidden',
  paddingBottom: '30px'
}
export const modalTitleStyles = {
  textTransform: 'uppercase',
  fontWeight: 'bold',
  color: '#FFF',
  fontSize: '18px',
  fontFamily: 'Inter',
  lineHeight: '21px',
}
export const modalSubtitleStyles = {
  maxWidth: '310px',
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '16px',
  fontFamily: 'Inter',
  textAlign: 'center',
  color: '#784E4E',
}
export const selectTitleStyles = {
  pl: 1.5,
  fontSize: '15px',
  fontFamily: 'Inter',
  fontWeight: 700,
  lineHeight: '19px',
  color: '#784E4E',
}
export const choosePipelineTextStyles = {
  fontWeight: 'bold',
  color: '#784E4E',
  fontSize: '15px',
  textAlign: 'center',
  lineHeight: '19px',
  fontFamily: 'Inter',
}
export const btnActivateDirectlyInCampaignStyles = {
  ...choosePipelineTextStyles,
  borderColor: '#784E4E',
  '&:hover': {
    backgroundColor: 'rgba(120, 78, 78, 0.08)',
    borderColor: '#784E4E',
  },
  '&:disabled': {
    color: 'lightgrey',
    backgroundColor: 'rgba(120, 78, 78, 0.08)',
  },
  '&:active': {
    color: '#FFF',
    backgroundColor: '#6CB75B'
  }
}
export const isActivateDirectlyStyles = {
  ...btnActivateDirectlyInCampaignStyles,
  backgroundColor: '#6CB75B',
  color: '#FFF',
  '&:hover': {
    borderColor: '#784E4E',
    color: '#784E4E',
  },
  '&:disabled': {
    color: 'lightgrey',
    backgroundColor: 'rgba(120, 78, 78, 0.08)',
  },
  '&:active': {
    color: '#FFF',
    backgroundColor: '#6CB75B'
  }
}
