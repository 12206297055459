import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Card, Table, TableBody, TableContainer } from '@mui/material'
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from '../../components/table'
import Scrollbar from '../../components/scrollbar'
import { NotificationsTableToolbar } from './NotificationsTableToolbar'
import { NotificationsTableRow } from './NotificationsTableRow'
import { TableRowsLoader } from '../../components/table/TableRowSkeleton'
import { applyFilter } from './utils'
import useTranslation from '../../../localization/useTranslation'

export const NotificationsTable = props => {
  const { openModal, setEditItem, data, isLoading, onRemove, dashboards } = props
  const { labelStrings } = useTranslation()

  const TABLE_HEAD = [
    { id: 'dashboard', label: labelStrings.notificationTableHead.dashboard, align: 'left' },
    { id: 'interval', label: labelStrings.notificationTableHead.interval, align: 'left' },
    { id: 'recipients', label: labelStrings.notificationTableHead.recipients, align: 'left' },
    { id: '' },
  ]

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable()

  const [filterSearchTerm, setFilterSearchTerm] = useState('')

  const dataFiltered = applyFilter({
    inputData: data,
    comparator: getComparator(order, orderBy),
    filterSearchTerm,
  })

  const denseHeight = dense ? 52 : 72
  const isFiltered = filterSearchTerm !== ''
  const isNotFound =
    (!dataFiltered?.length && !!filterSearchTerm && !isLoading) ||
    (!dataFiltered?.length && !isLoading)

  const handleSearchTerm = event => {
    setPage(0)
    setFilterSearchTerm(event.target.value)
  }

  const handleDeleteRow = notificationId => {
    onRemove({ notificationId })
  }

  const handleEditRow = row => {
    setEditItem(row)
    openModal(true)
  }

  const handleResetFilter = () => {
    setFilterSearchTerm('')
  }

  const getDashboardId = dashboardId => {
    return dashboards?.find(dashboard => dashboard.dashboardId === dashboardId)?.dashboardName
  }

  return (
    <>
      <Grid container sx={{ my: 4 }}>
        <Grid item xs={12} md={12}>
          <Card>
            <NotificationsTableToolbar
              isFiltered={isFiltered}
              filterSearchTerm={filterSearchTerm}
              handleSearchTerm={handleSearchTerm}
              onResetFilter={handleResetFilter}
              disabled={isLoading}
            />

            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <Scrollbar>
                <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={data?.length}
                    onSort={onSort}
                  />

                  <TableBody>
                    {isLoading ? (
                      <TableRowsLoader rowsNum={7} thNum={3} />
                    ) : (
                      !isLoading &&
                      dataFiltered
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(row => (
                          <NotificationsTableRow
                            key={row.notificationId}
                            row={{
                              ...row,
                              dashboardName: getDashboardId(row.dashboardId),
                            }}
                            onDeleteRow={() => handleDeleteRow(row.notificationId)}
                            onEditRow={() => handleEditRow(row)}
                          />
                        ))
                    )}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(page, rowsPerPage, data?.length)}
                    />

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={dataFiltered.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              dense={dense}
              onChangeDense={onChangeDense}
              sx={{ '& p': { m: 0 } }}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

NotificationsTable.propTypes = {
  openModal: PropTypes.func,
  setEditField: PropTypes.func,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  onRemove: PropTypes.func,
  dashboards: PropTypes.array,
}
