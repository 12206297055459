import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { pretifyBigNum } from '../../../utils'

const GrowthMainWrapper = styled.div`
  position: ${props => props.defaultPosition ? 'initial' : 'absolute'};
  top: ${props => props.top || -1}px;
  left: ${props => (props.left ? `${props.left}` : `${props.isAroundZero ? 110 : 100}`)}%;

  z-index: 100;
  font-size: 10px;
  display: flex;
  // flex-direction: column;
  align-items: center;
  font-weight: 400;
  color: ${props => props.theme.color.grey};
`

const GrowthNumbersWrapper = styled.div`
  ${props => props.theme.flex.centerAll};
  color: ${props => {
    if (props.isAroundZero) return props.theme.color.grey
    return props.growthDirection > 0 ? 'green' : 'red'
  }};
`

const DynamicArrowWrapper = styled.span`
  font-size: 16px;
  top: -2px;
  position: relative;
  ${props =>
    props.isAroundZero &&
    `
    transform: rotateZ(45deg);
  `}
`

const CountWrapper = styled.div`
  margin: 0 5px;
  font-size: 15px;
  color: ${props => props.theme.color.mineShaft};
`

const UserGrowthDynamics = ({
  growthData = [],
  topPx = null,
  leftPrecent = null,
  showGrowthCount = false,
  defaultPosition = false,
}) => {
  const [count, percentValue] = growthData
  const percent = percentValue ? Number(percentValue.toFixed(1)) : 0
  const renderGrowth = count !== undefined && percent !== undefined

  const growthDirection = percent === 0 ? 0 : percent > 0 ? 1 : -1
  const isAroundZero = percent < 1 && percent > -1

  const createArrow = () =>
    !growthDirection || isAroundZero || growthDirection > 0 ? (
      <span>&#8599;</span>
    ) : (
      <span>&#8600;</span>
    )
  return (
    renderGrowth && (
      <GrowthMainWrapper
        isAroundZero={isAroundZero}
        top={topPx}
        left={leftPrecent}
        defaultPosition={defaultPosition}
      >
        {showGrowthCount && <CountWrapper>{pretifyBigNum(count)}</CountWrapper>}
        <GrowthNumbersWrapper growthDirection={growthDirection} isAroundZero={isAroundZero}>
          <DynamicArrowWrapper isAroundZero={isAroundZero}>{createArrow()}</DynamicArrowWrapper>
          {percent}%
        </GrowthNumbersWrapper>
      </GrowthMainWrapper>
    )
  )
}

UserGrowthDynamics.propTypes = {
  growthData: PropTypes.array,
  topPx: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(null)]),
  leftPrecent: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(null)]),
  showGrowthCount: PropTypes.bool,
  defaultPosition: PropTypes.bool,
}

export default UserGrowthDynamics
