import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { infludataThemes, GlobalInfludataStyles } from '../themes/infludataThemes'
import { selectedPlatformSelector } from '../redux/selectors'

const WithAppThemeProvider = ({ children }) => {
  const selectedPlatformName = useSelector(selectedPlatformSelector)
  return (
    <ThemeProvider theme={infludataThemes[selectedPlatformName]}>
      {children}
      <GlobalInfludataStyles />
    </ThemeProvider>
  )
}

WithAppThemeProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default WithAppThemeProvider
