import React from 'react'
import PropTypes from 'prop-types'

import useTranslation from '../../../localization/useTranslation'
import { USER_INDEXES } from '../../../constants/appSettings'
import { MIN_CHART_COUNT } from '../../../constants/components'
import { themeCommonStyles } from '../../../themes/infludataThemes'

import LineChart from '../../charts/LineChart'
import InfoTip from '../../common/tips/InfoTip'
import {
  SectionTitleWrapper,
  SectionTitleName,
  SectionTitleBorder,
} from '../commonProfilePageStyles'
import { ViewsPerPostContainer, ViewsPerPostWrapper } from './styles'

const ViewsPerPost = ({
  chartData = [],
  platform = '',
  monthlyGrowthFollowers = [],
  onChartReadyCallback,
  isForPdf = false,
}) => {
  const { labelStrings, profileSectionDescs } = useTranslation()
  const isNotEnoughDataForChart = chartData.length < MIN_CHART_COUNT
  const lineChartId = `${USER_INDEXES[platform]}_profile_followers_chart`

  return (
    <ViewsPerPostContainer isForPdf={isForPdf}>
      {!isForPdf && (
        <SectionTitleWrapper>
          <SectionTitleName platform={platform}>
            {labelStrings.viewsPerPost} ({labelStrings.average})
          </SectionTitleName>
          <InfoTip>
            <div dangerouslySetInnerHTML={{ __html: profileSectionDescs.viewsPerPost }} />
          </InfoTip>
          <SectionTitleBorder />
        </SectionTitleWrapper>
      )}

      <ViewsPerPostWrapper isForPdf={isForPdf}>
        <LineChart
          chartData={chartData}
          userindex={platform}
          faqQuestionId=""
          isDisabledChart={isNotEnoughDataForChart}
          lastMonthGrowthData={monthlyGrowthFollowers}
          id={lineChartId}
          onChartReadyCallback={onChartReadyCallback}
          backgroundColor={!isForPdf ? themeCommonStyles.colors.offWhite : '#EEFCFB'}
          isForPdf={isForPdf}
        />
      </ViewsPerPostWrapper>
    </ViewsPerPostContainer>
  )
}

ViewsPerPost.propTypes = {
  chartData: PropTypes.array,
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  monthlyGrowthFollowers: PropTypes.array,
  onChartReadyCallback: PropTypes.func,
  isForPdf: PropTypes.bool,
}

export default ViewsPerPost
