import React from 'react'
import { Box, Chip, Divider, List, ListItem } from '@mui/material'

export const CustomRangeShortcuts = props => {
  const { items, onChange, isValid } = props

  if (items == null || items.length === 0) {
    return null
  }

  const resolvedItems = items.map(item => {
    const newValue = item.getValue({ isValid })

    return {
      label: item.label,
      onClick: () => {
        onChange(newValue)
      },
      disabled: !isValid(newValue),
    }
  })

  return (
    <Box
      sx={{
        gridRow: 1,
        gridColumn: 2,
      }}
    >
      <List
        dense
        sx={theme => ({
          display: 'flex',
          px: theme.spacing(4),
          '& .MuiListItem-root': {
            pt: 0,
            pl: 0,
            pr: theme.spacing(1),
          },
          maxWidth: '600px',
          flexWrap: 'wrap',
          margin: '0 auto',
        })}
      >
        {resolvedItems?.map(item => {
          return (
            <ListItem key={item.label} sx={{ width: 'auto' }}>
              <Chip {...item} />
            </ListItem>
          )
        })}
      </List>
      <Divider />
    </Box>
  )
}
