import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Stack, MenuItem, IconButton, Button, Alert, Typography } from '@mui/material'
import PipelineInputName from '../PipelineInputName'
import Iconify from '../../../components/iconify/Iconify'
import MenuPopover from '../../../components/menu-popover/MenuPopover'
import ConfirmDialog from '../../../components/confirm-dialog/ConfirmDialog'

PipelineColumnToolBar.propTypes = {
  columnName: PropTypes.string,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  columns: PropTypes.array,
}

export default function PipelineColumnToolBar({ columnName, onDelete, onUpdate, columns }) {
  const renameRef = useRef(null)

  const [value, setValue] = useState(columnName)

  const [openConfirm, setOpenConfirm] = useState(false)

  const [openPopover, setOpenPopover] = useState(null)

  useEffect(() => {
    const { current } = renameRef

    if (openPopover) {
      if (current) {
        current.focus()
      }
    }
  }, [openPopover])

  const handleOpenConfirm = () => {
    setOpenConfirm(true)
  }

  const handleCloseConfirm = () => {
    setOpenConfirm(false)
  }

  const handleOpenPopover = event => {
    setOpenPopover(event.currentTarget)
  }

  const handleClosePopover = () => {
    setOpenPopover(null)
  }

  const handleClickRename = () => {
    handleClosePopover()
  }

  const handleChangeColumnName = event => {
    setValue(event.target.value)
  }

  const handleUpdateColumn = event => {
    if (event.key === 'Enter' && renameRef.current) {
      renameRef.current.blur()
      onUpdate && onUpdate(value)
    }
  }

  const onBlurUpdateColumnName = () => {
    if (value) {
      onUpdate && onUpdate(value)
    }
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        sx={{ py: 1 }}
      >
        <PipelineInputName
          inputRef={renameRef}
          placeholder="Section name"
          value={value}
          onChange={handleChangeColumnName}
          onKeyUp={handleUpdateColumn}
          onBlur={onBlurUpdateColumnName}
        />

        <IconButton
          size="small"
          color={openPopover ? 'inherit' : 'default'}
          onClick={handleOpenPopover}
        >
          <Iconify icon="eva:more-horizontal-fill" />
        </IconButton>
      </Stack>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ mt: 0, ml: 1.25 }}>
        <MenuItem
          onClick={() => {
            handleOpenConfirm()
            handleClosePopover()
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="eva:trash-2-outline" />
          Delete section
        </MenuItem>

        <MenuItem onClick={handleClickRename}>
          <Iconify icon="eva:edit-fill" />
          Rename section
        </MenuItem>
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content={
          <>
            <Typography>Are you sure want to delete column?</Typography>
            <Alert
              severity="error"
              sx={{ typography: 'caption', color: 'error.main', mt: 2, fontSize: '14px' }}
            >
              <strong> NOTE: </strong> Your column needs to be empty to remove it.
            </Alert>
          </>
        }
        action={
          <Button
            disabled={!!columns?.length}
            variant="contained"
            color="error"
            onClick={() => {
              onDelete()
              handleCloseConfirm()
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  )
}
