import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'

import useTranslation from '../../localization/useTranslation'
import { selectedProfileSelector, userGrantSelector } from '../../redux/selectors'

import ReactivationPlanContainer from '../appUserSettings/pricingPlans/ReactivationPlanContainer'
import AnalyzeInlinedNavbarContainer from '../analyzeBar/AnalyzeInlinedNavbarContainer'
import NavbarLinkItem from './NavbarLinkItem'
import NavBarUserRelated from './NavBarUserRelated'
import CompareProfilesDynamicNavItem from './CompareProfilesDynamicNavItem'

import ExploreIcon from '@mui/icons-material/Explore'
import CollectionsIcon from '@mui/icons-material/Collections'
import SearchIcon from '@mui/icons-material/Search'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import BuildIcon from '@mui/icons-material/Build'

import {
  NavbarBGWrapper,
  NavbarContent,
  NavLinkContainer,
  InfludataLogoWrapper,
  ToolsForBrandsWrapper,
} from '../common/styledWrappers/navbarStyles'
import { InfludataWecLogoIcon } from '../common/icons'
import WithTip from '../common/popups/WithTip'
import { DisabledTipWrapper } from '../common/tabs/tabsStyles'

const Navbar = ({
  isUserAuthorized = false,
  allowCollections = false,
  allowTracking = false,
  onLoginClick,
  currentPagePath = '',
}) => {
  const { labelStrings } = useTranslation()
  const location = useLocation()
  const history = useHistory()

  const {
    enable_collections,
    enable_ig_search,
    enable_tt_search,
    enable_yt_search,
    build_lookalikes,
    ...otherGrants
  } = useSelector(userGrantSelector, shallowEqual)

  const { isLoading: isProfileLoading } = useSelector(selectedProfileSelector)
  const [isOnPageWithNewDesign, setIsOnPageWithNewDesig] = useState(false)

  const disabledSearch = !enable_ig_search && !enable_tt_search && !enable_yt_search
  const disabledCollection = !enable_collections

  useEffect(() => {
    if (location.pathname.includes('/profile') || location.pathname.includes('/collections')) {
      setIsOnPageWithNewDesig(true)
    }
  }, [location.pathname])

  useEffect(() => {
    if (disabledSearch && disabledCollection && Object.keys(otherGrants)?.length) {
      history.push('/campaigns')
    }
  }, [Object.keys(otherGrants)?.length])

  return (
    <div id="navbar">
      <ReactivationPlanContainer />
      <NavbarBGWrapper
        isOnPageWithNewDesign={isOnPageWithNewDesign}
        isProfileLoading={isProfileLoading}
      >
        <NavbarContent>
          <Link to="/">
            <InfludataLogoWrapper>
              <InfludataWecLogoIcon />
            </InfludataLogoWrapper>
          </Link>

          <NavLinkContainer>
            <NavbarLinkItem
              linkPath={'search'}
              Icon={SearchIcon}
              isActive={currentPagePath.includes('search')}
              isDisabled={disabledSearch}
            />

            {build_lookalikes ? (
              <NavbarLinkItem
                linkPath={'explore'}
                Icon={ExploreIcon}
                isActive={currentPagePath.includes('explore')}
                isDisabled={!isUserAuthorized || !build_lookalikes}
              />
            ) : (
              <WithTip
                tipText={
                  <DisabledTipWrapper>{labelStrings.featureIsNotAvailable}</DisabledTipWrapper>
                }
                userindex={'instagram'}
                position="bottom left"
              >
                <div>
                  <NavbarLinkItem
                    linkPath={'explore'}
                    Icon={ExploreIcon}
                    isActive={currentPagePath.includes('explore')}
                    isDisabled={!isUserAuthorized || !build_lookalikes}
                  />
                </div>
              </WithTip>
            )}

            {!enable_collections && isUserAuthorized ? (
              <WithTip tipText={labelStrings.collectionDisabledTip} position="bottom center">
                <div>
                  <NavbarLinkItem
                    Icon={CollectionsIcon}
                    linkPath={'collections/fav'}
                    withSignup={!allowCollections && !isUserAuthorized}
                    signupTipText={labelStrings.useCollections}
                    isActive={currentPagePath.includes('collections')}
                    isDisabled={!enable_collections}
                  />
                </div>
              </WithTip>
            ) : (
              <NavbarLinkItem
                Icon={CollectionsIcon}
                linkPath={'collections/fav'}
                withSignup={!allowCollections && !isUserAuthorized}
                signupTipText={labelStrings.useCollections}
                isActive={currentPagePath.includes('collections')}
                isDisabled={disabledCollection}
              />
            )}

            <NavbarLinkItem
              Icon={TrackChangesIcon}
              linkPath={'campaigns'}
              withSignup={!allowTracking && !isUserAuthorized}
              signupTipText={labelStrings.useTracking}
              isActive={currentPagePath.includes('campaigns')}
            />
          </NavLinkContainer>

          <AnalyzeInlinedNavbarContainer />

          <ToolsForBrandsWrapper>
            <NavbarLinkItem
              Icon={BuildIcon}
              linkPath={'tools-for-brands'}
              withSignup={!isUserAuthorized}
              signupTipText={labelStrings.useToolsForBrands}
              isActive={currentPagePath.includes('tools-for-brands')}
            />
          </ToolsForBrandsWrapper>

          <NavBarUserRelated onLoginClick={onLoginClick} isUserAuthorized={isUserAuthorized} />
        </NavbarContent>

        <CompareProfilesDynamicNavItem
          isUserAuthorized={isUserAuthorized}
          currentPagePath={currentPagePath}
        />
      </NavbarBGWrapper>
    </div>
  )
}

Navbar.propTypes = {
  isUserAuthorized: PropTypes.bool,
  allowCollections: PropTypes.bool,
  allowTracking: PropTypes.bool,
  onLoginClick: PropTypes.func,
  currentPagePath: PropTypes.string,
}

export default Navbar
