import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, CardHeader, Box, IconButton, Menu, MenuItem } from '@mui/material'
import { fShortenNumber } from '../../../utils/formatNumber'
import Chart, { useChart } from '../index'
import { StyledStackedColumnChart } from './styles.js'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import DownloadIcon from '@mui/icons-material/Download'
import { TooltipComponent } from '../../tooltip/TooltipComponent'
import html2canvas from 'html2canvas'
import pptxgen from 'pptxgenjs'

export const StackedColumnChart = ({
  title,
  subheader = '',
  chart,
  height = '464px',
  helperText = '',
  isPdf,
  ...other
}) => {
  const { labels, colors, series = [], options } = chart
  const chartRef = useRef(null)
  const headlineRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleDownloadClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleDownloadClose = () => {
    setAnchorEl(null)
  }

  const exportToPowerPoint = async () => {
    const chartElement = chartRef.current
    const headlineElement = headlineRef.current
    const chartImage = await html2canvas(chartElement)
    const headlineImage = await html2canvas(headlineElement, {
      ignoreElements: element => element.classList.contains('ignore-export'),
    })
    const chartDataUrl = chartImage.toDataURL()
    const headlineDataUrl = headlineImage.toDataURL()

    const pptx = new pptxgen()
    const slide = pptx.addSlide()

    const chartAspectRatio = chartElement.offsetWidth / chartElement.offsetHeight
    const slideWidth = 10
    const slideHeight = slideWidth / chartAspectRatio

    slide.addImage({
      data: headlineDataUrl,
      x: 0,
      y: 0,
      w: slideWidth,
      h: 1,
    })

    slide.addImage({
      data: chartDataUrl,
      x: 0,
      y: 1,
      w: slideWidth,
      h: slideHeight,
    })

    pptx.writeFile('Chart.pptx')
    handleDownloadClose()
  }

  const exportToImage = async () => {
    const chartElement = chartRef.current
    const headlineElement = headlineRef.current
    const chartImage = await html2canvas(chartElement)
    const headlineImage = await html2canvas(headlineElement, {
      ignoreElements: element => element.classList.contains('ignore-export'),
    })
    const chartDataUrl = chartImage.toDataURL('image/png')
    const headlineDataUrl = headlineImage.toDataURL('image/png')

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    const chartImageObj = new Image()
    const headlineImageObj = new Image()

    chartImageObj.onload = function() {
      canvas.width = chartImageObj.width
      canvas.height = chartImageObj.height + headlineImageObj.height
      context.drawImage(headlineImageObj, 0, 0)
      context.drawImage(chartImageObj, 0, headlineImageObj.height)

      const mergedDataUrl = canvas.toDataURL('image/png')
      const link = document.createElement('a')
      link.href = mergedDataUrl
      link.download = 'Chart.png'
      link.click()
      handleDownloadClose()
    }

    headlineImageObj.onload = function() {
      chartImageObj.src = chartDataUrl
    }

    headlineImageObj.src = headlineDataUrl
  }

  const chartOptions = useChart({
    chart: {
      stacked: true,
      stackType: 'normal',
    },
    colors,
    stroke: {
      width: 0,
    },
    fill: {
      opacity: 1,
    },
    xaxis: {
      categories: labels,
      tickPlacement: 'on',
      position: 'bottom',
      floating: false,
      labels: {
        trim: true,
      },
    },
    yaxis: {
      labels: {
        formatter: function(value) {
          return fShortenNumber(value)
        },
      },
    },
    noData: {
      text: 'No data available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '16px',
      },
    },
    legend: {
      horizontalAlign: 'left',
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: '40%',
        dataLabels: {
          position: 'top',
          total: {
            enabled: series[0]?.data[0]?.data?.length <= 10, // hide if more than 10 bars
            offsetY: 0,
            offsetX: 0,
            style: {
              fontSize: '13px',
              fontWeight: 500,
              colors: ['#212B36'],
            },
            formatter: function(value) {
              return fShortenNumber(value)
            },
          },
        },
      },
    },
    ...options,
  })

  return (
    <Card {...other} sx={{ height }}>
      <CardHeader
        ref={headlineRef}
        title={title}
        subheader={subheader}
        action={
          <>
            {!!helperText?.length && (
              <IconButton className="ignore-export" aria-label="Helper-Text">
                <TooltipComponent text={helperText}>
                  <InfoOutlinedIcon />
                </TooltipComponent>
              </IconButton>
            )}
            {!isPdf && (
              <>
                <IconButton aria-label="Download" onClick={handleDownloadClick}>
                  <DownloadIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleDownloadClose}>
                  <MenuItem onClick={exportToPowerPoint}>Download as PPTX</MenuItem>
                  <MenuItem onClick={exportToImage}>Download as Image</MenuItem>
                </Menu>
              </>
            )}
          </>
        }
      />

      {series?.map((item, idx) => (
        <Box key={idx} sx={{ mt: 3, mx: 3 }} dir="ltr" ref={chartRef}>
          <StyledStackedColumnChart>
            <Chart type="bar" series={item.data} options={chartOptions} height={364} />
          </StyledStackedColumnChart>
        </Box>
      ))}
    </Card>
  )
}

StackedColumnChart.propTypes = {
  chart: PropTypes.object,
  title: PropTypes.string,
  subheader: PropTypes.string,
  helperText: PropTypes.string,
  isPdf: PropTypes.bool,
}
