import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  ADDITIONAL_FIELD_PROP_TYPE,
  ADDITIONAL_FIELD_TYPES,
} from '../../../../constants/additionalFields'
import { USER_INDEXES } from '../../../../constants/appSettings'
import { isAdditionalFieldChangingSelector } from '../../../../redux/selectors'
import {
  changeAdditionalField,
  changeAdditionalFieldValue,
  deleteAdditionalField,
} from '../../../../redux/ducks/additionalFieldsDucks'
import { withReduxActions } from '../../../../hocs/withRedux'
import NoteWithLabelAndTools from './NoteWithLabelAndTools'
import AdditionalFieldSelect from './AdditionalFieldSelect'
import AdditionalFieldInputDate from './AdditionalFieldInputDate'
import AdditionalFieldInputText from './AdditionalFieldInputText'
import AdditionalFieldMultiSelect from './AdditionalFieldMultiSelect'
import YesNoCheckbox from '../../../common/checkboxes/YesNoCheckbox'
import { AdditionalFieldInputCommon, AdditionalFieldYesNoCheckboxWrapper } from './styles'

const isValidFieldType = fieldType =>
  fieldType === ADDITIONAL_FIELD_TYPES.multiSelect ||
  fieldType === ADDITIONAL_FIELD_TYPES.singleSelect ||
  fieldType === ADDITIONAL_FIELD_TYPES.boolean

const AdditionalFieldContainer = ({
  profileId = '',
  platform = '',
  additionalFieldData,
  changeAdditionalField,
  changeAdditionalFieldValue,
  deleteAdditionalField,
  isCollection = false,
}) => {
  const isFieldChanging = useSelector(isAdditionalFieldChangingSelector)

  const [localAdditionalFieldData, setLocalAdditionalFieldData] = useState(additionalFieldData)
  const { label, code, type, value, allowedValues } = localAdditionalFieldData
  const [isFieldEditingOverlayOpened, setIsFieldEditingOverlayOpened] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  useEffect(() => {
    setLocalAdditionalFieldData(additionalFieldData)
  }, [additionalFieldData.label, additionalFieldData.allowedValues])

  const changeValueLocally = useCallback(newValue => {
    setLocalAdditionalFieldData(prev => ({ ...prev, value: newValue }))
  }, [])

  const changeValue = async ({ newValue, fieldType }) => {
    // Retrieve the old value, spread operator to ensure immutability in case of arrays
    let { value: oldValue } = { ...additionalFieldData };
  
    // Helper function to normalize values
    const normalizeValue = (value) => {
      if (Array.isArray(value)) {
        return value.map(v => typeof v === 'string' ? v.trim() : v);
      } else {
        return typeof value === 'string' ? value.trim() : value;
      }
    };
  
    // Normalize both values for comparison based on their type
    const normalizedOldValue = normalizeValue(oldValue);
    const normalizedNewValue = normalizeValue(newValue);
  
    // Check if the normalized values are equal, considering array comparison
    const valuesAreEqual = Array.isArray(normalizedOldValue) && Array.isArray(normalizedNewValue)
      ? normalizedOldValue.sort().toString() === normalizedNewValue.sort().toString()
      : normalizedOldValue === normalizedNewValue;
  
    // If values are equal, return to prevent further processing
    if (valuesAreEqual) return;
  
    // If they're not equal, change the local value
    changeValueLocally(newValue);
  
    // Perform the localAdditionalFieldData check and return if conditions are met
    if (localAdditionalFieldData.value === newValue && isValidFieldType(fieldType)) return;
  
    try {
      // Attempt to change the additional field value
      await changeAdditionalFieldValue({
        profileId,
        valueObj: { code, value: newValue },
      });
    } catch (error) {
      console.error('Error changing additional field value:', error);
      // If there's an error, revert to the old value
      setLocalAdditionalFieldData({ ...additionalFieldData, value: oldValue });
    }
  };
  
  

  const onChangeAdditionalField = async newData => {
    let newLabel = null
    let newAllowedValues = null

    if (label !== newData.label) {
      newLabel = newData.label
    }
    if (JSON.stringify(allowedValues) !== JSON.stringify(newData.allowedValues)) {
      newAllowedValues = newData.allowedValues
    }

    if (newLabel || newAllowedValues) {
      await changeAdditionalField({
        additionalField: localAdditionalFieldData,
        newLabel,
        newAllowedValues,
      })
    }
  }

  const openFieldEditingOverlay = () => setIsFieldEditingOverlayOpened(true)
  const closeFieldEditingOverlay = () => setIsFieldEditingOverlayOpened(false)

  const deleteAdditionalFieldById = async () => {
    setIsDeleting(true)
    const isDeleted = await deleteAdditionalField(code)
    if (!isDeleted) setIsDeleting(false)
  }

  const RenderedFields = (
    <>
      {(() => {
        switch (type) {
          case ADDITIONAL_FIELD_TYPES.string: {
            const onChange = event => changeValueLocally(event.target.value)
            const onBlur = () =>
              changeValue({ newValue: value, fieldType: ADDITIONAL_FIELD_TYPES.string })

            return <AdditionalFieldInputText value={value} onChange={onChange} onBlur={onBlur} />
          }
          case ADDITIONAL_FIELD_TYPES.number: {
            const onChange = event => changeValueLocally(event.target.value)
            const onBlur = () =>
              changeValue({ newValue: value, fieldType: ADDITIONAL_FIELD_TYPES.number })

            return (
              <AdditionalFieldInputCommon
                type="number"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                autoComplete="off"
                isNumberInput
              />
            )
          }
          case ADDITIONAL_FIELD_TYPES.boolean:
            const isYes = typeof value === 'boolean' ? value : null
            const onChange = isYes =>
              changeValue({ newValue: isYes, fieldType: ADDITIONAL_FIELD_TYPES.boolean })
            return (
              <AdditionalFieldYesNoCheckboxWrapper>
                <YesNoCheckbox platform={platform} isYes={isYes} onChange={onChange} />
              </AdditionalFieldYesNoCheckboxWrapper>
            )
          case ADDITIONAL_FIELD_TYPES.date: {
            const onChange = newDate =>
              changeValue({ newValue: newDate, fieldType: ADDITIONAL_FIELD_TYPES.date })

            return (
              <AdditionalFieldInputDate
                id={code}
                value={value}
                platform={platform}
                onChange={onChange}
              />
            )
          }
          case ADDITIONAL_FIELD_TYPES.singleSelect: {
            const onChange = optionId =>
              changeValue({ newValue: optionId, fieldType: ADDITIONAL_FIELD_TYPES.singleSelect })

            return (
              <AdditionalFieldSelect
                additionalFieldData={localAdditionalFieldData}
                onChange={onChange}
              />
            )
          }
          case ADDITIONAL_FIELD_TYPES.multiSelect: {
            const onChange = optionIds =>
              changeValue({ newValue: optionIds, fieldType: ADDITIONAL_FIELD_TYPES.multiSelect })

            return (
              <AdditionalFieldMultiSelect
                additionalFieldData={localAdditionalFieldData}
                onChange={onChange}
              />
            )
          }
          default:
            return null
        }
      })()}
    </>
  )

  return (
    <>
      {isCollection ? (
        RenderedFields
      ) : (
        <NoteWithLabelAndTools
          platform={platform}
          label={label}
          additionalFieldData={localAdditionalFieldData}
          isFieldEditingOverlayOpened={isFieldEditingOverlayOpened}
          isFieldChanging={isFieldChanging}
          isLoading={isDeleting}
          openFieldEditing={openFieldEditingOverlay}
          closeFieldEditing={closeFieldEditingOverlay}
          deleteAdditionalFieldById={deleteAdditionalFieldById}
          changeAdditionalField={onChangeAdditionalField}
        >
          {RenderedFields}
        </NoteWithLabelAndTools>
      )}
    </>
  )
}

AdditionalFieldContainer.propTypes = {
  profileId: PropTypes.string.isRequired,
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  additionalFieldData: ADDITIONAL_FIELD_PROP_TYPE.isRequired,
  changeAdditionalField: PropTypes.func,
  changeAdditionalFieldValue: PropTypes.func,
  deleteAdditionalField: PropTypes.func,
  isCollection: PropTypes.bool,
}

export default withReduxActions({
  changeAdditionalField,
  deleteAdditionalField,
  changeAdditionalFieldValue,
})(AdditionalFieldContainer)
