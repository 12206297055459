import { Skeleton, Stack, Typography } from '@mui/material'
import React from 'react'
import { NAVBAR_FOOTER_SUM_HEIGHT } from '../../../constants/appSettings'

export const DashboardSkeletonLoader = () => {
  return (
    <Stack sx={{ width: '100%', height: `calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px)`, p: 2, zIndex: 9999 }} spacing={2}>
      {/* top controls section */}
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Skeleton variant="rounded" animation="wave" width={285} height={40} />

        <Stack direction="row" alignItems="center" spacing={2}>
          <Skeleton variant="rounded" animation="wave" width={128} height={40} />
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Skeleton variant="rounded" animation="wave" width={140} height={40} />
            <Typography>–</Typography>
            <Skeleton variant="rounded" animation="wave" width={140} height={40} />
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1}>
          <Skeleton variant="rounded" animation="wave" width={40} height={40} />
          <Skeleton variant="rounded" animation="wave" width={40} height={40} />
        </Stack>
      </Stack>

      {/* main content section */}
      <Stack spacing={2} sx={{ width: '100%', height: '100%' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1.5}>
          <Skeleton sx={{ width: '100%' }} variant="rounded" animation="wave" height={235} />
          <Skeleton sx={{ width: '100%' }} variant="rounded" animation="wave" height={235} />
          <Skeleton sx={{ width: '100%' }} variant="rounded" animation="wave" height={235} />
          <Skeleton sx={{ width: '100%' }} variant="rounded" animation="wave" height={235} />
          <Skeleton sx={{ width: '100%' }} variant="rounded" animation="wave" height={235} />
          <Skeleton sx={{ width: '100%' }} variant="rounded" animation="wave" height={235} />
        </Stack>

        <Stack spacing={1.5} sx={{ width: '100%', height: '100%' }}>
          <Skeleton sx={{ width: '100%' }} variant="rounded" animation="wave" height={'100%'} />
          <Skeleton sx={{ width: '100%' }} variant="rounded" animation="wave" height={'100%'} />
        </Stack>
      </Stack>
    </Stack>
  )
}
