import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../localization/useTranslation'
import { LocationInfoWrapper, LocationNameWrapper, SuggestedLocationsListWrapper } from './styles'

const LocationList = ({
  onLocationSelect,
  setSelectedLocation,
  locationsList = [],
  isVisible = false,
  userindex = '',
  suggestedLocationStaticIdPart = '',
  isInputValue = false,
  disableSearch = false,
}) => {
  const { labelStrings, currentLang } = useTranslation()
  const locationsFound = !!locationsList?.length

  const onHandleClickLocation = ({ id, name }) => {
    onLocationSelect(id)
    setSelectedLocation(name)
  }

  if (disableSearch) {
    return (
      <SuggestedLocationsListWrapper
        isVisible={isVisible}
        userindex={userindex}
        locationsFound={false}
      >
        <LocationInfoWrapper disabledSearch currLang={currentLang}>
          {' '}
          {labelStrings.logInForUseSearchUsers}
        </LocationInfoWrapper>
      </SuggestedLocationsListWrapper>
    )
  }

  return (
    <SuggestedLocationsListWrapper
      isVisible={isVisible}
      userindex={userindex}
      locationsFound={locationsFound}
    >
      {locationsFound
        ? locationsList?.map((location, idx) => {
            return (
              <LocationInfoWrapper
                key={location.place_id + idx}
                onClick={() =>
                  onHandleClickLocation({ id: location.place_id, name: location.description })
                }
                id={`${suggestedLocationStaticIdPart}${location.place_id}${idx}`}
                tabIndex="0"
                platform={userindex}
                notFound={locationsFound}
                enableHover
              >
                <LocationNameWrapper>{location.description}</LocationNameWrapper>
              </LocationInfoWrapper>
            )
          })
        : !!isInputValue && (
            <>
              <LocationInfoWrapper>{labelStrings.noLocationFound}</LocationInfoWrapper>
            </>
          )}
    </SuggestedLocationsListWrapper>
  )
}

LocationList.propTypes = {
  locationsList: PropTypes.array,
  onLocationSelect: PropTypes.func,
  isVisible: PropTypes.bool,
  userindex: PropTypes.string,
  suggestedLocationStaticIdPart: PropTypes.string,
  isInputValue: PropTypes.bool,
  disableSearch: PropTypes.bool,
  setSelectedLocation: PropTypes.func,
}

export default LocationList
