import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ActionButton } from '../buttons/styledButtons'
import { DialogTitle } from '../styledWrappers'
import useTranslation from '../../../localization/useTranslation'

const MainWrapper = styled.div`
  height: 200px;
  ${props => props.theme.flex.centerAll}
  justify-content: space-evenly;
  flex-direction: column;
  padding: 25px 40px;
`

const Title = styled(DialogTitle)`
  padding: 0;
  text-align: center;
`

const SuccessResetPasswordDialog = ({ successResetPasswordHandler }) => {
  const { labelStrings } = useTranslation()
  return (
    <MainWrapper>
      <Title>{labelStrings.newPasswordWasSet}</Title>
      <ActionButton onClick={successResetPasswordHandler}>
        {labelStrings.backToApp}
      </ActionButton>
    </MainWrapper>
  )
}

SuccessResetPasswordDialog.propTypes = {
  successResetPasswordHandler: PropTypes.func,
}

export default SuccessResetPasswordDialog
