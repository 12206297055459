import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { StyledCheckbox, SearchLabel } from '../styledWrappers'
import WithTip from '../popups/WithTip'
import LinkToFaqQuestion from '../../faqs/LinkToFaqQuestion'

const CheckboxOuterWrapper = styled.div`
  ${props => props.isDisabled && props.theme.disabled}
  margin: 5px 0;
  position: relative;
`

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  min-width: ${props => (props.withMinWidth ? '125px' : 'unset')};
  & > * {
    color: ${props => props.theme.color.grey};
  }
`

const CheckboxLabelWrapper = styled.label`
  font-size: 15px;
  line-height: 20px;
  font-family: ${props => props.theme.fontFamilyRoboto};
  margin: 0 0 0 5px;
  cursor: pointer;
  color: ${props => props.theme.colors.darkBrown};
`

const LabelWrapper = styled.div`
  position: relative;
  width: fit-content;
`

const SingleCheckbox = ({
  labelText = '',
  onChange,
  checked = false,
  titleText = '',
  withIcon,
  Icon,
  faqQuestionId = null,
  withMinWidth = true,
  isDisabled = false,
  tipText = '',
}) => {
  const renderTip = faqQuestionId !== null

  const CheckBox = (
    <div>
      <CheckboxOuterWrapper className="single_checkbox_container" isDisabled={isDisabled}>
        {titleText && (
          <LabelWrapper>
            <SearchLabel>{titleText}</SearchLabel>
            {renderTip && (
              <LinkToFaqQuestion
                faqQuestionId={faqQuestionId}
                tipIconSize={14}
                linkPositions={{ top: 7, right: -30 }}
              />
            )}
          </LabelWrapper>
        )}

        <CheckboxWrapper withMinWidth={withMinWidth} className="single_checkbox">
          <StyledCheckbox type="checkbox" onChange={onChange} checked={checked} id={labelText} />
          <LabelWrapper>
            <CheckboxLabelWrapper htmlFor={labelText} className="single_checkbox_label_text">
              {withIcon && Icon}
              {labelText}
            </CheckboxLabelWrapper>
          </LabelWrapper>
        </CheckboxWrapper>
      </CheckboxOuterWrapper>
    </div>
  )

  return isDisabled ? (
    <WithTip tipText={tipText} position="top center" offsetY={-25}>
      {CheckBox}
    </WithTip>
  ) : (
    CheckBox
  )
}

SingleCheckbox.propTypes = {
  labelText: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.bool,
  titleText: PropTypes.string,
  faqQuestionId: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
  withMinWidth: PropTypes.bool,
  isDisabled: PropTypes.bool,
  tipText: PropTypes.string,
}

export default SingleCheckbox
