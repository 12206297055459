import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { EmptyListMessagesWrapper, CenterWrapper } from '../common/styledWrappers'
import useTranslation from '../../localization/useTranslation'
import { ExclamationPoint } from '../common/icons'
import {
  NotActivatedTipWrapper,
  NotActivatedTipTitle,
  NotActivatedTipText,
  NotActivatedIconWrapper,
} from './commonTipsWrappers'

export const ContentWrapper = styled(EmptyListMessagesWrapper)`
  width: ${props => props.isExplore ? '680px' : '535px'};
  text-align: center;
  align-items: center;
  margin-top: -30%;
`

export const NoUsersWrapper = styled.div`
  font-size: 24px;
  line-height: 26px;
  font-weight: bold;
  color: ${props => props.theme.colors.brown};
`

export const TipWrapper = styled.div`
  margin-top: 20px;
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.brown};
`

export const MarkedTextWrapper = styled.span`
  font-weight: bold;
`

export const UpgradeLinkWrapper = styled(Link)`
  color: ${props => props.theme.primaryColor};
  text-decoration: underline;
  &:hover {
    color: ${props => props.theme.primaryColor};
    opacity: 0.9;
  }
`

const CollectionPageTips = ({ disabledCollections = false, noPlan = false, isExplore = false }) => {
  const { labelStrings } = useTranslation()

  const getDisabledCollectionsTip = () =>
    disabledCollections ? (
      <NoUsersWrapper>
        <UpgradeLinkWrapper to="/settings">{labelStrings.contactSales}</UpgradeLinkWrapper>{' '}
        {labelStrings.to} {labelStrings.useCollections}
      </NoUsersWrapper>
    ) : (
      <>
        {' '}
        <NoUsersWrapper>
          {isExplore
            ? labelStrings.explorePage.noInfluencersInSuggestions
            : labelStrings.noInfluencersInCollection}
        </NoUsersWrapper>
        {isExplore ? '' : 
        <TipWrapper>
            {labelStrings.toAddInfluencersGoTo}{' '}
          <MarkedTextWrapper>{labelStrings.searchView}</MarkedTextWrapper>{' '}
          {labelStrings.andAddInfluencers}. {labelStrings.canCreateCollectionByClicking}{' '}
          <MarkedTextWrapper>{labelStrings.createNewCollection}</MarkedTextWrapper>{' '}
          {labelStrings.inLeftMenu}.
        </TipWrapper>
        }
      </>
    )

  const getNoPlanTip = () => (
    <>
      <NotActivatedTipWrapper>
        <NotActivatedIconWrapper>
          <ExclamationPoint />
        </NotActivatedIconWrapper>
        <NotActivatedTipTitle>{labelStrings.collectionsAreDeactivated}</NotActivatedTipTitle>
      </NotActivatedTipWrapper>
      <NotActivatedTipText>{labelStrings.pleaseGetInContact}</NotActivatedTipText>
    </>
  )

  return (
    <CenterWrapper>
      <ContentWrapper disabledCollections={disabledCollections} isExplore={isExplore}>
        {noPlan ? getNoPlanTip() : getDisabledCollectionsTip()}
      </ContentWrapper>
    </CenterWrapper>
  )
}

CollectionPageTips.propTypes = {
  disabledCollections: PropTypes.bool,
  noPlan: PropTypes.bool,
  isExplore: PropTypes.bool,
}

export default CollectionPageTips
