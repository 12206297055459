import React from 'react'
import PropTypes from 'prop-types'
import { USER_INDEXES, SOCIAL_LINKS_PATHS } from '../../../constants/appSettings'
import { TikTokIcon, YouTubeSocialNetworkIcon } from '../../common/icons/icons'
import { SocialMediaContainer, SocialMediaItem } from './styles'
import { themeCommonStyles } from '../../../themes/infludataThemes'
import { NewInstagramPlatformIcon } from '../../common/icons'

const SocialMedia = ({ profile }) => {
  const {
    _index: platform,
    socialHandles,
    channelId
  } = profile || {}
  
  const platformUsernames = {
    [USER_INDEXES.instagram]: socialHandles ? socialHandles.instagramUsername : null,
    [USER_INDEXES.tiktok]: socialHandles ? socialHandles.tiktokUsername : null,
    [USER_INDEXES.youtube]: socialHandles ? channelId || socialHandles.youtubeUsername : null,
  }

  const activePlatforms = Object.keys(platformUsernames)?.filter(p => platformUsernames[p])

  if (!activePlatforms?.length) return null

  return (
    <SocialMediaContainer>
      {activePlatforms.map(p => (
        <SocialMediaItem
          key={p}
          href={SOCIAL_LINKS_PATHS[p](platformUsernames[p])}
          target="_blank"
          platform={platform}
        >
          {p === USER_INDEXES.instagram ? (
            <NewInstagramPlatformIcon
              color={themeCommonStyles.colors.brown}
              width="26px"
              height="26px"
            />
          ) : p === USER_INDEXES.tiktok ? (
            <TikTokIcon color={themeCommonStyles.colors.brown} />
          ) : (
            <YouTubeSocialNetworkIcon color={themeCommonStyles.colors.brown} />
          )}
        </SocialMediaItem>
      ))}
    </SocialMediaContainer>
  )
}

SocialMedia.propTypes = {
  profile: PropTypes.object,
}

export default SocialMedia
