import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { USER_INDEXES } from '../../../../constants/appSettings'
import { selectedProfileSelector } from '../../../../redux/selectors'
import {
  AudienceLanguageChart,
  AudienceCommonMentionsChart,
  AudienceAgeGroupsChart,
} from '../../audienceAnalysis/audienceAnalysisCharts/index'
import PageLayout from '../PageLayout/PageLayout'
import * as Styled from './styles'
import {
  SectionTitleBorder,
  SectionTitleName,
  SectionTitleWrapper,
} from '../../commonProfilePageStyles'
import useTranslation from '../../../../localization/useTranslation'
import { Box, Stack } from '@mui/material'

const AudienceAnalysisPage2 = ({ isBranded = false }) => {
  const { labelStrings } = useTranslation()
  const { profile } = useSelector(selectedProfileSelector)
  const {
    _id: profileId,
    username,
    _index: platform,
    followers: followersNumber,
    audienceAnalysis,
  } = profile || {}

  if (!audienceAnalysis) return null

  const { languageArray, mentions, averageBirthyearArray, birthyearMedian } = audienceAnalysis

  return (
    <PageLayout profileId={profileId} username={username} platform={platform} isBranded={isBranded}>
      <Styled.Grid style={{ gridColumnGap: '40px' }}>
        {platform !== USER_INDEXES.tiktok && (
          <Styled.GridItem>
            <SectionTitleWrapper>
              <SectionTitleName platform={platform} isForPdf={true}>
                {labelStrings.language}
              </SectionTitleName>
              <SectionTitleBorder isForPdf />
            </SectionTitleWrapper>
            <AudienceLanguageChart
              userindex={platform}
              languageArray={languageArray}
              isDisabledAnalysis={false}
              followersCount={followersNumber}
              isForPdf
            />
          </Styled.GridItem>
        )}

        <Styled.GridItem>
          <SectionTitleWrapper>
            <SectionTitleName platform={platform} isForPdf={true}>
              {labelStrings.ageGroups}
            </SectionTitleName>
            <SectionTitleBorder isForPdf />
          </SectionTitleWrapper>
          <AudienceAgeGroupsChart
            userindex={platform}
            averageBirthyearArray={averageBirthyearArray}
            isDisabledAnalysis={false}
            followersCount={followersNumber}
            birthyearMedian={birthyearMedian}
            isForPdf
          />
        </Styled.GridItem>
      </Styled.Grid>
      {platform === USER_INDEXES.instagram && (
        <Stack sx={{ width: 'auto' }}>
          <SectionTitleWrapper>
            <SectionTitleName platform={platform} isForPdf={true}>
              {labelStrings.audienceMentions}
            </SectionTitleName>
            <SectionTitleBorder isForPdf />
          </SectionTitleWrapper>
          <Box sx={{ maxWidth: '350px', mt: '24px' }}>
            <AudienceCommonMentionsChart
              userindex={platform}
              mentions={mentions}
              isDisabledAnalysis={false}
              isForPdf
            />
          </Box>
        </Stack>
      )}
    </PageLayout>
  )
}

AudienceAnalysisPage2.propTypes = {
  isBranded: PropTypes.bool,
}

export default AudienceAnalysisPage2
