import React from 'react'
import { LinkContainer } from '../tableStyles'
import PropTypes from 'prop-types'

const RowCellContent = ({ link, over = false }) => {
  const preventDefaultMethod = e => {
    e.preventDefault()
  }

  return (
    <LinkContainer
      over={over}
      href={link}
      onMouseDown={preventDefaultMethod}
      onClick={preventDefaultMethod}
    />
  )
}

RowCellContent.propTypes = {
  link: PropTypes.string,
  over: PropTypes.bool,
}

export default RowCellContent
