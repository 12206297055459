import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { withReduxActions } from '../../../../hocs/withRedux'
import { toggleContactUs } from '../../../../redux/ducks/appSettingsDucks'
import { changeCampaignSeats } from '../../../../redux/ducks/campaignsDucks'
import {
  userGrantSelector,
  allCampaignsSelector,
  userCredsLoadProgressSelector,
} from '../../../../redux/selectors'
import {
  BUY_SEATS_MAP,
  DEFAULT_CAMPAIGN_SEATS,
  SEATS_PRICES_MAP,
} from '../../../../constants/campaigns'

import BuyCampaignsSeatsModal from '../../../common/modals/BuyCampaignsSeatsModal'

const BuyCampaignsSeatsContainer = ({
  isModalOpened = false,
  closeModal,
  toggleContactUs,
  changeCampaignSeats,
}) => {
  const { purchased_tracking_seats, max_users_trackable } = useSelector(userGrantSelector)
  const { trackNow: currentUsedSeats, trackMax } = useSelector(allCampaignsSelector)
  const isUserCredsLoading = useSelector(userCredsLoadProgressSelector)

  const defaultSeatsNum = DEFAULT_CAMPAIGN_SEATS.pro
  const _maxTrack = max_users_trackable || trackMax // trust grants first
  const purchasedSeats =
    currentUsedSeats > _maxTrack ? currentUsedSeats - _maxTrack : _maxTrack - currentUsedSeats // just in case if smth is wrong and tracked > limit

  const alreadyPurchasedSeats = purchased_tracking_seats || purchasedSeats || 0
  const seatsValues = Object.values(BUY_SEATS_MAP)
  const seatsLabels = Object.keys(BUY_SEATS_MAP)
  const needMoreSeatsValue = seatsValues[seatsValues.length - 1] // last one
  const needMoreSeatsLabel = seatsLabels[seatsLabels.length - 1] // last one label
  const purchasedUsedSeats =
    !currentUsedSeats || currentUsedSeats < defaultSeatsNum ? 0 : currentUsedSeats - defaultSeatsNum // will output the diff between all trackedNow and default 20 allowed users - it's the number of current purchased seats

  const initSelectedSeats = seatsValues.find(n => n > alreadyPurchasedSeats) || needMoreSeatsValue

  const [selectedSeats, changeSelectedSeats] = useState(initSelectedSeats)
  const [isChangeSeatsSuccess, setIsChangeSeatsSuccess] = useState(false)

  useEffect(() => {
    if (initSelectedSeats !== selectedSeats) {
      changeSelectedSeats(initSelectedSeats)
    }
  }, [purchased_tracking_seats])

  const closeModalHandler = () => {
    if (isChangeSeatsSuccess) setIsChangeSeatsSuccess(false)
    closeModal()
  }

  const confirmHandler = async () => {
    const success = await changeCampaignSeats({ newSeats: selectedSeats })
    success ? setIsChangeSeatsSuccess(true) : closeModal()
  }
  const contactUsHandler = () => {
    toggleContactUs()
    closeModal()
  }

  const onChangeSeats = value => changeSelectedSeats(value)

  const needMoreSeats = selectedSeats === needMoreSeatsValue
  const isUpgradeSeats = selectedSeats > alreadyPurchasedSeats
  const isDowngradeSeats = selectedSeats < alreadyPurchasedSeats
  const isCurrentPlan = selectedSeats === alreadyPurchasedSeats
  const selectedSeatsLabel =
    seatsLabels.find(s => BUY_SEATS_MAP[s] === selectedSeats) || needMoreSeatsLabel

  const oneSeatPrice = SEATS_PRICES_MAP[selectedSeats] || 0
  const seatsCharge = oneSeatPrice ? oneSeatPrice * selectedSeats : 0
  const isNotAllowedDowngrade = isDowngradeSeats && selectedSeats < purchasedUsedSeats
  const downgradeExceedLimitSeatsCount = isNotAllowedDowngrade
    ? purchasedUsedSeats - selectedSeats
    : 0

  return (
    <>
      <BuyCampaignsSeatsModal
        {...{
          isModalOpened,
          closeModal: closeModalHandler,
          confirmHandler,
          contactUsHandler,
          seatsMap: BUY_SEATS_MAP,
          initSeat: initSelectedSeats,
          onChangeSeats,
          needMoreSeats,
          isCurrentPlan,
          isUpgradeSeats,
          oneSeatPrice,
          seatsCharge,
          isChangeSeatsSuccess: isChangeSeatsSuccess && !isUserCredsLoading, // wait until new user grants are loaded
          isDowngradeSeats,
          isNotAllowedDowngrade,
          downgradeExceedLimitSeatsCount,
          selectedSeatsLabel,
          alreadyPurchasedSeats,
        }}
      />
    </>
  )
}

BuyCampaignsSeatsContainer.propTypes = {
  isModalOpened: PropTypes.bool,
  closeModal: PropTypes.func,
  toggleContactUs: PropTypes.func,
  changeCampaignSeats: PropTypes.func,
}

export default withReduxActions({ toggleContactUs, changeCampaignSeats })(
  BuyCampaignsSeatsContainer
)
