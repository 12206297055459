export const TABLE_HEAD = [
  { id: 'goalName', label: 'Goal Name', align: 'left' },
  { id: 'period', label: 'Period (Last Start Date)', align: 'left' },
  { id: 'goalLevel', label: 'Goal Level', align: 'left' },
  { id: 'isPaused', label: 'Status', align: 'center' },
  { id: 'metricLabel', label: 'Metric', align: 'center' },
  { id: 'goalAmount', label: 'Value', align: 'center' },
  { id: 'goalReachedRate', label: 'Reached in %', align: 'center' },
  { id: '' },
]
export const STATUS_OPTIONS = [
  { value: 'all', label: 'all' },
  { value: false, label: 'pause' },
  { value: true, label: 'active' },
]

export const applyFilter = ({ inputData, comparator, filterGoalName, filterStatus }) => {
  const stabilizedThis = inputData.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })

  inputData = stabilizedThis.map(el => el[0])

  if (filterGoalName) {
    inputData = inputData.filter(
      goal => goal.goalName?.toLowerCase().indexOf(filterGoalName.toLowerCase()) !== -1
    )
  }

  if (filterStatus !== 'all') {
    inputData = inputData.filter(goal => goal.isPaused !== filterStatus)
  }

  return inputData
}
