// @ts-nocheck
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { SIDEBAR_WIDTH, FOOTER_HEIGHT, SIDEBAR_MINIMAL_WIDTH } from '../../../constants/appSettings'
import { getNavbarHeight } from '../../../utils'

export const Container = styled.div`
  width: ${props =>
    props.isNavType === 'min' ? SIDEBAR_MINIMAL_WIDTH : props.isDrag ? SIDEBAR_WIDTH : 310}px;
  max-height: 100%;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: 13px;
  overflow: hidden;
  ${props => props.disabled && props.theme.disableBlock}
`

export const PopupedContainer = styled(Container)`
  position: fixed;
  top: ${props => props.topPosition}px;
  ${'' /* top: 0; */}
  transition: all 0.2s ease;
  transition-delay: 0.2s;
  left: ${props => (props.isDragCaptured ? -7 : -SIDEBAR_WIDTH)}px;
  width: ${SIDEBAR_WIDTH - 20}px;
  height: ${props =>
    props.isDragCaptured ? window.innerHeight - getNavbarHeight() - FOOTER_HEIGHT : 3000}px;
  ${'' /* overflow-y: scroll; */}
  ${props => props.theme.scrollStyle}
  overflow-x: hidden;
  z-index: 10000;
`

export const Header = styled.div`
  font-size: ${props => (props.isNavType === 'min' ? 11 : 13)}px;
  line-height: 16px;
  font-weight: bold;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.gradients.main};
  text-transform: uppercase;
  padding: ${props => (props.isNavType === 'min' ? '5px 10px' : '10px 20px')};
  text-align: ${props => (props.isNavType === 'min' ? 'center' : 'left')};
  margin: -0.5px;
`

export const CollectionBarContent = styled.div`
  max-height: calc(100% - 40px);
  background-color: ${props => props.theme.colors.offWhite};
  border: 0.5px solid ${props => props.theme.colors.mauve};
  border-top: none;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
`

export const FaqTipWrapper = styled.div`
  & > * {
    line-height: initial;
    letter-spacing: initial;
    font-weight: initial;
    text-transform: none;
  }
`

export const SidebarTitleWrapper = styled.div`
  position: relative;
  width: fit-content;
`
export const OverflowBreakout = styled.div`
  /* position: relative; */
  width: 100%;
  // pointer-events: none;
`

export const CollectionLink = styled.div`
  display: flex;
  flex-direction: ${props => (props.isNavType === 'min' ? 'column' : 'row')};
  align-items: center;
  font-size: 15px;
  line-height: 20px;
  font-family: ${props => props.theme.fontFamilyRoboto};
  ${props => props.isDisabled && props.theme.disabled};
`

export const StyledLink = styled(Link)`
  font-size: 15px;
  line-height: 20px;
  font-family: ${props => props.theme.fontFamilyRoboto};
  ${props => props.isdisabled && props.theme.disableBlock};
`

export const Disabled = styled.div`
  display: flex;
  align-items: center;
  ${props => props.theme.disableBlock}
`

export const Span = styled.span`
  font-size: ${props => (props.isNavType === 'min' ? 13 : 15)}px;
  line-height: ${props => props.isNavType === 'min' ? 'normal' : '20px'};
  ${props => props.isNavType === 'min' && `padding: 0 5px`};
  font-family: ${props => props.theme.fontFamilyRoboto};
  max-width: ${props => (props.isNavType === 'min' ? 75 : 180)}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  ${props =>
    props.isBoldText &&
    `
    font-weight: 900;
  `}
`

export const CollectionList = styled.div`
  max-height: calc(100vh - 235px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0 10px;
  overflow-y: auto;
  ${props => props.theme.withScroll};
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #bc9797;
    border-radius: 16px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #784e4e;
    border-radius: 16px;
    border: 2px solid #bc9797;
  }
  &::-webkit-scrollbar-thumb:hover {
    border: 0px solid #f4f4f4;
  }
`

export const CollectionListItem = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  line-height: 19px;
  padding: 6px 20px;
  position: relative;
  color: ${props => props.theme.colors.brown};
  text-decoration: none;
  border-radius: 6px;
  margin: ${props => (props.nav_type === 'min' ? '2px 0' : '7px 0')};
  height: ${props => (props.nav_type === 'min' ? 45 : 40)}px;

  ${props => props.active === 'true' && `background-color: ${props.theme.colors.mediumBrown};`}

  &:hover {
    text-decoration: none;
    color: ${props => props.theme.colors.brown};
    background-color: ${props => props.theme.colors.mediumBrown};
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 1px;
    background-color: ${props => props.theme.colors.lightBrown};
  }

  svg {
    cursor: pointer;
    line-height: 1;
  }
`

export const CreateCollectionWrapper = styled.div`
  font-size: ${props => (props.isNavType === 'min' ? 12 : 15)}px;
  line-height: 19px;
  letter-spacing: 0;
  color: ${props => props.theme.colors.brown};
  padding: ${props => (props.isNavType === 'min' ? '5px' : '10px 25px')};
  cursor: pointer;
  transition: all 0.2s ease;
  overflow: hidden;
  display: flex;
  flex-direction: ${props => (props.isNavType === 'min' ? 'column' : 'row')};
  justify-content: ${props => (props.isNavType === 'min' ? 'center' : 'flex-start')};
  align-items: center;
  border-bottom: 0.5px solid ${props => props.theme.colors.mauve};

  &:hover {
    background-color: ${props => props.theme.colors.lightBrown};
  }

  ${props => props.disabled && props.theme.disableBlock}
`

export const CreateCollectionIconWrapper = styled.div`
  width: ${props => (props.isNavType === 'min' ? 28 : 32)}px;
  height: ${props => (props.isNavType === 'min' ? 28 : 32)}px;
  border-radius: 50%;
  margin-right: ${props => (props.isNavType === 'min' ? 0 : 10)}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.lightBrown};

  span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 3px;
    padding: 0;
    line-height: 1;

    svg {
      line-height: 1;
    }
  }
`

export const LabelWrapper = styled.span`
  font-size: font-size: ${props => (props.isNavType === 'min' ? 12 : 15)}px;
  line-height: 20px;
  font-family: ${props => props.theme.fontFamilyRoboto};
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`

export const ForbiddenDropTipWrapper = styled.div`
  ${props => props.theme.flex.centerAllColumn}
  // width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
  z-index: 20000;
  color: ${props => props.theme.color.mineShaft};
  font-family: ${props => props.theme.fontFamilyInter};
`

export const UnlockedIconWrapper = styled.div`
  margin-top: -3px;
  margin-right: 5px;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: ${props => props.theme.color.black};
    }
  }
`
export const DraggableContainer = styled.div`
  .sixDots {
    svg {
      visibility: hidden;
    }
  }
  &:hover {
    .sixDots {
      svg {
        visibility: ${props => (props.isRender ? 'visible' : 'hidden')};
      }
    }
  }
  [draggable] {
    cursor: move;
    user-select: none;
  }
  &:target {
    border-bottom: 2px solid black;
  }
`

export const SixDotsDnD = styled.div`
  margin-right: 5px;
  min-height: 22px;
`
