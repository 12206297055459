import React, { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import useTranslation from "../../../../../localization/useTranslation"
import { USER_INDEXES } from "../../../../../constants/appSettings"
import { IG_SCORE_NAMES } from "../../../../../constants/profile"
import RatingDescriptionTip from "../../ratingDescriptionTip/RatingDescriptionTip"
import {
  BtnTimeline,
  TimeLineBtnText,
  MentionedProfileContainer,
} from "./styles"
import {
  RatingTitleWrapper,
  RatingTitle,
  SponsoredPostsStoriesLabel,
} from "../../commonStyles"
import NoDataMessage from "../../NoDataMessage"
import { Paper, Stack, Typography, Box } from "@mui/material"
import { pretifyBigNum } from "../../../../../utils"
import { useLocalStorage } from "../../../../../hooks/useLocalStorage"
import { MentionedBubbleChart } from "./MentionedBubbleChart"
import {
  getContentProfile,
  setBrandCollaborations,
} from "../../../../../redux/ducks/profileSelectedDucks"
import { scrollToAnchor } from "./utils"
import { CreatorsList } from "./creatorsTableView"

const ITEMS_TIMELINE_PER_CHART = 4
const TAB_TIMELINE = "TAB_TIMELINE"
const TAB_CREATORS = "TAB_CREATORS"

const modifyArrayLength = (array, to) => {
  return array?.slice(0, to)
}

const MentionedProfiles = ({
  mentionAccountsAndTimeline = [],
  metadata = {},
  isForPdf = false,
  brandCollaborations = {},
  profileUsername = "username",
  onChangeBrandProfileType,
}) => {
  const dispatch = useDispatch()
  const { labelStrings, scoreStrings } = useTranslation()
  const [currencyFromStorage] = useLocalStorage("currency")

  const [currentTimelineChartData, setCurrentTimelineChartData] = useState([])
  const [timelineChartCreatorsCount, setTimelineChartCreatorsCount] = useState(
    ITEMS_TIMELINE_PER_CHART,
  )

  const [tabType, setTabType] = useState(isForPdf ? TAB_CREATORS : TAB_TIMELINE)

  const isTimeline = useMemo(
    () => tabType === TAB_TIMELINE && !!currentTimelineChartData?.length,
    [tabType, currentTimelineChartData?.length],
  )
  const isCreators = useMemo(
    () => tabType === TAB_CREATORS && !!mentionAccountsAndTimeline?.length,
    [tabType, mentionAccountsAndTimeline?.length],
  )

  const igScoreStrings = scoreStrings[USER_INDEXES.instagram]
  const brandValueStrings = igScoreStrings[IG_SCORE_NAMES.sponsoredPosts]

  const changeTabType = (tabTypeValue) => {
    setTabType(tabTypeValue)
    setTimelineChartCreatorsCount(ITEMS_TIMELINE_PER_CHART)
  }

  useEffect(() => {
    if (mentionAccountsAndTimeline?.length) {
      setCurrentTimelineChartData(
        modifyArrayLength(
          mentionAccountsAndTimeline,
          timelineChartCreatorsCount,
        ),
      )
    }
  }, [mentionAccountsAndTimeline?.length, timelineChartCreatorsCount])

  useEffect(() => {
    if (
      currentTimelineChartData?.length &&
      !mentionAccountsAndTimeline?.length
    ) {
      setTabType(TAB_TIMELINE)
    }
  }, [currentTimelineChartData?.length, mentionAccountsAndTimeline?.length])

  const onShowMoreTimeline = () => {
    setTimelineChartCreatorsCount((prevValue) =>
      prevValue + ITEMS_TIMELINE_PER_CHART > mentionAccountsAndTimeline?.length
        ? mentionAccountsAndTimeline?.length
        : prevValue + ITEMS_TIMELINE_PER_CHART,
    )
  }

  const hasMentionedProfiles = !!mentionAccountsAndTimeline?.length

  const filteredByContentForCreator = ({ username }) => {
    // @ts-ignore
    dispatch([
      setBrandCollaborations({}),
      getContentProfile({
        keywords: `@${username}`,
        username: profileUsername,
      }),
    ])
    scrollToAnchor("branded-content")
  }
  const filteredByContentForBrand = ({ username }) => {
    // @ts-ignore
    dispatch([
      setBrandCollaborations({}),
      getContentProfile({
        keywords: `@${profileUsername}`,
        username: username,
      }),
    ])
    scrollToAnchor("branded-content")
  }

  return (
    <MentionedProfileContainer>
      {!isForPdf && brandCollaborations && (
        <RatingTitleWrapper>
          <RatingTitle>{labelStrings.sponsoredPostsAndStories}</RatingTitle>
          {!isForPdf && (
            <RatingDescriptionTip scoreDesc={brandValueStrings.description} />
          )}
        </RatingTitleWrapper>
      )}

      {!!brandCollaborations && !isForPdf && (
        <Box
          sx={{
            width: "fit-content",
          }}
        >
          <Typography
            sx={{
              fontSize: "10px",
              fontFamily: "Inter",
              color: "#784E4E",
            }}
          >
            {`${labelStrings.notACreator}`}{" "}
            <Typography
              display='inline'
              component='span'
              sx={{
                fontWeight: 500,
                fontSize: "10px",
                fontFamily: "Inter",
                color: "#784E4E",
                cursor: "pointer",
                "&:hover": { opacity: 0.7 },
              }}
              onClick={onChangeBrandProfileType}
            >
              {labelStrings.clickHere},
            </Typography>
            {` ${labelStrings.toChangeCreatorAccount}`}
          </Typography>
        </Box>
      )}

      {brandCollaborations && tabType === TAB_TIMELINE ? (
        <Stack>
          <Paper
            elevation={3}
            sx={{ p: 2, mt: 2, mb: 4, width: "fit-content" }}
          >
            <Stack>
              <Typography
                sx={{
                  maxWidth: "420px",
                  color: "#784E4E",
                  fontSize: "15px",
                  lineHeight: "19px",
                  fontWeight: 700,
                  textTransform: "uppercase",
                  mb: 1,
                }}
              >
                {labelStrings.brandsTimelineText.withinTheLast365Days(
                  profileUsername,
                )}
              </Typography>
              <Typography
                sx={{
                  color: "#6CB75B",
                  fontSize: "15px",
                  lineHeight: "19px",
                  fontWeight: "bold",
                }}
              >
                {labelStrings.brandsTimelineText.brands(
                  brandCollaborations?.mentionAccountsAndTimeline?.length,
                )}
              </Typography>
              <Stack direction='row' alignItems='center' spacing={0.5}>
                <Typography
                  sx={{
                    color: "#6CB75B",
                    fontSize: "15px",
                    lineHeight: "19px",
                    fontWeight: "bold",
                  }}
                >
                  {labelStrings.brandsTimelineText.shared(
                    pretifyBigNum(
                      brandCollaborations?.metadata?.totalContentPieces,
                    ),
                  )}
                </Typography>
                <Typography
                  sx={{
                    color: "#784E4E",
                    fontSize: "15px",
                    lineHeight: "19px",
                    fontWeight: "400",
                  }}
                >
                  {labelStrings.brandsTimelineText.wereShared}
                </Typography>
              </Stack>
              <Stack direction='row' alignItems='center' spacing={0.5}>
                <Typography
                  sx={{
                    color: "#6CB75B",
                    fontSize: "15px",
                    lineHeight: "19px",
                    fontWeight: "bold",
                  }}
                >
                  {labelStrings.brandsTimelineText.generated(
                    pretifyBigNum(
                      brandCollaborations?.metadata?.totalMediaValue,
                    ),
                    currencyFromStorage?.toUpperCase(),
                  )}
                </Typography>

                <Typography
                  sx={{
                    color: "#784E4E",
                    fontSize: "15px",
                    lineHeight: "19px",
                    fontWeight: "400",
                  }}
                >
                  {labelStrings.brandsTimelineText.wasGenerated}
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      ) : (
        brandCollaborations && (
          <SponsoredPostsStoriesLabel isForPdf={isForPdf}>
            {isForPdf
              ? brandValueStrings.brandsThatWereTagged
              : hasMentionedProfiles
                ? `${brandValueStrings.brandsThatWereTagged}:`
                : `${brandValueStrings.brandsThatWereTaggedNotFound}.`}
          </SponsoredPostsStoriesLabel>
        )
      )}

      {!isForPdf && (
        <Stack
          direction='row'
          alignItems='center'
          sx={{ mb: tabType === TAB_TIMELINE ? 0 : 5 }}
          spacing={2}
        >
          <BtnTimeline
            isActive={isTimeline}
            onClick={() => changeTabType(TAB_TIMELINE)}
            isDisabled={!!currentTimelineChartData?.length}
          >
            <TimeLineBtnText isActive={isTimeline}>
              {labelStrings.brandsTimelineText.timeline}
            </TimeLineBtnText>
          </BtnTimeline>
          <BtnTimeline
            isActive={isCreators}
            onClick={() => changeTabType(TAB_CREATORS)}
            isDisabled={!!mentionAccountsAndTimeline?.length}
          >
            <TimeLineBtnText isActive={isCreators}>
              {brandCollaborations
                ? labelStrings.brandsTimelineText.brands("")
                : labelStrings.brandsTimelineText.creators}
            </TimeLineBtnText>
          </BtnTimeline>
        </Stack>
      )}

      {!isTimeline && !isCreators && (
        <NoDataMessage message={labelStrings.noDataAvailable} />
      )}

      {isTimeline && !isForPdf && (
        <>
          <MentionedBubbleChart
            currentTimelineChartData={currentTimelineChartData}
            onShowMoreTimeline={onShowMoreTimeline}
            timelineChartCreatorsCount={timelineChartCreatorsCount}
            dateStart={metadata.dateStart}
            dateEnd={metadata.dateEnd}
            onHandleFiltered={
              brandCollaborations
                ? filteredByContentForCreator
                : filteredByContentForBrand
            }
            timelineDataCount={mentionAccountsAndTimeline?.length}
            mentionAccountsAndTimeline={mentionAccountsAndTimeline}
          />
        </>
      )}

      {isCreators && (
        <Stack
          alignItems='center'
          sx={{
            maxWidth: isForPdf ? "695px" : "1210px",
            width: "100%",
            px: 0,
          }}
        >
          <CreatorsList
            data={mentionAccountsAndTimeline}
            isLoading={false}
            isForPdf={isForPdf}
          />
        </Stack>
      )}
    </MentionedProfileContainer>
  )
}

MentionedProfiles.propTypes = {
  mentionAccountsAndTimeline: PropTypes.array,
  isForPdf: PropTypes.bool,
  brandCollaborations: PropTypes.object,
  profileUsername: PropTypes.string,
  metadata: PropTypes.object,
  onChangeBrandProfileType: PropTypes.func,
}

export default MentionedProfiles
