import * as Yup from 'yup'
import validator from 'validator'

const emailSchema = Yup.string()
  .email()
  .required('Email is Required')
  .test(value => validator.isEmail(value))

export const EditMentionsKeywordsSchema = Yup.object().shape({
  dashboardId: Yup.string().required('Dashboard is Required'),
  emails: Yup.array()
    .of(emailSchema)
    .test('is-valid-emails', 'Invalid email(s) found', value => {
      if (!value) return true
      return value.every(email => {
        return emailSchema.isValidSync(email)
      })
    })
    .max(10, 'You can enter a maximum of 10 emails'),
  interval: Yup.string().required('Interval is Required'),
})
