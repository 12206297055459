import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import useTranslation from '../../localization/useTranslation'
import availableLangs from '../../localization/availableLangs'
import { ChevronArrowIcon } from '../common/icons'
import {
  OuterWrapper,
  LanguageSwitchWrapper,
  IconWrapper,
  ProposedLang,
} from '../common/styledWrappers/languageSwitchStyles'
import { getPngCountryFlags } from '../../utils'

const LanguageSwitcher = ({ inFooter = true }) => {
  const { currentLang, changeCurrentLang } = useTranslation()
  const [isOpened, setIsOpened] = useState(false)
  const toogleIsOpened = () => setIsOpened(!isOpened)

  const langsMap = {
    [availableLangs.de]: 'DE',
    [availableLangs.en]: 'GB',
  }

  const proposedLangMap = {
    [availableLangs.de]: { flag: 'GB', langToChange: availableLangs.en },
    [availableLangs.en]: { flag: 'DE', langToChange: availableLangs.de },
  }

  const onLangSelect = (langToChange, closeCB) => () => {
    changeCurrentLang(langToChange)
    closeCB()
  }

  const popupPosition = inFooter ? 'top center' : 'bottom center'

  return (
    <OuterWrapper>
      <Popup
        position={popupPosition}
        on="hover"
        closeOnDocumentClick={true}
        closeOnEscape={true}
        onOpen={toogleIsOpened}
        onClose={toogleIsOpened}
        trigger={
          <LanguageSwitchWrapper inFooter={inFooter}>
            <span>{getPngCountryFlags(langsMap[currentLang])}</span>
            <IconWrapper isOpened={isOpened} inFooter={inFooter}>
              <ChevronArrowIcon isWhite height="15px" width="15px" />
            </IconWrapper>
          </LanguageSwitchWrapper>
        }
      >
        {close => (
          <ProposedLang onClick={onLangSelect(proposedLangMap[currentLang].langToChange, close)}>
            {getPngCountryFlags(proposedLangMap[currentLang].flag)}
          </ProposedLang>
        )}
      </Popup>
    </OuterWrapper>
  )
}

LanguageSwitcher.propTypes = {
  inFooter: PropTypes.bool,
}

export default LanguageSwitcher
