import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { checkIsUrlValid, checkIsUrlWithHttp } from '../../../../utils'
import { LinkIcon } from '../../../common/icons/icons'
import { AdditionalFieldInputLinkWrapper } from './styles'
import Scrollbar from '../../../../new-ui/components/scrollbar/Scrollbar'

const AutoResizeTextarea = ({ value = '', onChange, ...props }) => {
  const textareaRef = useRef(null)

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [value])

  return <textarea ref={textareaRef} value={value} onChange={onChange} {...props} />
}

const AdditionalFieldInputText = ({ value, onChange, onBlur }) => {
  const [isEditing, setIsEditing] = useState(false)

  const isValidUrl = checkIsUrlValid(value)
  const hasLinkButtonRender = !isEditing && isValidUrl

  let href
  if (isValidUrl) {
    let hasLinkProtocol = checkIsUrlWithHttp(value)
    href = hasLinkProtocol ? value : `//${value}`
  }

  const onFocusInput = () => {
    setIsEditing(true)
  }

  const onBlurInput = () => {
    setIsEditing(false)
    onBlur()
  }

  return (
    <Scrollbar
      sx={{
        maxHeight: `90px`,
        width: '100%',
        overflowY: 'auto',
        paddingRight: '8px',
      }}
    >
      <AutoResizeTextarea
        value={value}
        onChange={onChange}
        onFocus={onFocusInput}
        onBlur={onBlurInput}
        autoComplete="off"
        style={{
          border: 'none',
          width: '100%',
          resize: 'none',
          overflowY: 'hidden',
          outline: 'none',
        }}
        rows="1"
        maxLength={1000}
      />

      {hasLinkButtonRender && (
        <AdditionalFieldInputLinkWrapper href={href} target="_blank">
          <LinkIcon />
        </AdditionalFieldInputLinkWrapper>
      )}
    </Scrollbar>
  )
}

AdditionalFieldInputText.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
}

export default AdditionalFieldInputText
