import React from 'react'
import { SettingsContainer } from '../../sections/campaign-settings/SettingsContainer'
import { currentOpenedCampaignSelector } from '../../../redux/selectors'
import { useSelector } from 'react-redux'
import LoadingSpinner from '../../../components/common/spinners/LoadingSpinner'

export const Settings = () => {
  const { settings, isSingleCamapignLoading } = useSelector(currentOpenedCampaignSelector)
  return (
    <>
      {settings && Object.keys(settings)?.length && !isSingleCamapignLoading ? (
        <SettingsContainer />
      ) : (
        <LoadingSpinner showOnFullPage isTransparent height={'100%'} width={'100%'} />
      )}
    </>
  )
}
