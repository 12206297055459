import React from 'react'
import { ModalComponent } from '../../../new-ui/components/modal/ModalComponent'
import { Box, IconButton, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ReactPlayer from 'react-player'

export const StoriesModal = ({ imageSrc, videoUrl, handleCloseStoriesModal }) => {
  return (
    <ModalComponent
      isOpenModal={!!imageSrc || !!videoUrl}
      closeModal={handleCloseStoriesModal}
      contentHeight="inherit"
      contentWidth="inherit"
      withoutScrollWrapper
      smallPadding
      isCenterTitle
    >
      <Stack sx={{ pb: 4 }} spacing={2}>
        <Stack direction="row" justifyContent="end" sx={{ pt: 1 }}>
          <IconButton sx={{ mr: '-30px' }} onClick={handleCloseStoriesModal}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Box sx={{ width: '100%', maxHeight: '600px' }}>
          {videoUrl ? (
            <Box
              sx={{
                minHeight: '400px',
                '& video': {
                  borderRadius: '7px',
                },
              }}
            >
              <ReactPlayer
                url={videoUrl}
                width="360px"
                height="540px"
                className="react-player profile-video-preview"
                controls
                playing
              />
            </Box>
          ) : (
            <img src={imageSrc} style={{ borderRadius: '7px' }} alt="Story" />
          )}
        </Box>
      </Stack>
    </ModalComponent>
  )
}
