import React from 'react'
import PropTypes from 'prop-types'
import { USER_INDEXES } from '../../../../constants/appSettings'
import { constructLinkToWECFileServer, getUrlWithProxy } from '../../../../utils'
import LatestPostsGalleryItem from './LatestPostsGalleryItem'
import { Gallery } from './styles'
import LatestPostsGalleryItemYt from './LatestPostsGalleryItemYt'

const LatestPostsGallery = ({ profile }) => {
  const { postArray, _index: platform, username, shortsArray } = profile || {}

  let renderPostArray = postArray
  if (platform === USER_INDEXES.tiktok) {
    renderPostArray = postArray?.slice(0, 6)
  }
  if (platform === USER_INDEXES.youtube) {
    const filteredYtData =
      !!postArray?.length && !!shortsArray?.length 
        ? [...postArray?.slice(0, 3), ...shortsArray?.slice(0, 3)]
        : !!postArray?.length ? postArray?.slice(0, 6) : shortsArray?.slice(0, 6)
    renderPostArray = filteredYtData
  }

  return (
    <Gallery>
      {renderPostArray.map((post, index) => {
        let imgSrc = null
        let platformPostCode = null

        switch (platform) {
          case USER_INDEXES.instagram:
            imgSrc = getUrlWithProxy(constructLinkToWECFileServer(post.imageUrl))
            platformPostCode = post.code || post.contentId
            break
          case USER_INDEXES.tiktok:
            imgSrc = getUrlWithProxy(constructLinkToWECFileServer(post.imageUrl))
            platformPostCode = post.id ||  post.contentId
            break
          case USER_INDEXES.youtube:
            imgSrc = getUrlWithProxy(post.imageUrl)
            platformPostCode = post.id ||  post.contentId
            break
        }

        return platform === USER_INDEXES.youtube ? (
          <LatestPostsGalleryItemYt
            key={index}
            platform={platform}
            imgSrc={imgSrc}
            platformPostCode={platformPostCode}
            title={post.title}
            likeCount={post.likeCount}
            commentCount={post.commentCount}
            viewCount={post.viewCount}
            duration={post.duration}
            engagement={post.engagementRate}
            positiveReactions={post.positiveReactions}
            isShorts={post.isShorts}
          />
        ) : (
          <LatestPostsGalleryItem
            key={index}
            platform={platform}
            username={username}
            imgSrc={imgSrc}
            platformPostCode={platformPostCode}
          />
        )
      })}
    </Gallery>
  )
}

LatestPostsGallery.propTypes = {
  profile: PropTypes.object.isRequired,
  isForPdf: PropTypes.bool,
}

export default LatestPostsGallery
