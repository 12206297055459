import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { getPngCountryFlags, pretifyBigNum, replaceAllEmojiInStr } from '../../../../utils'
import useTranslation from '../../../../localization/useTranslation'
import disabledChartData from '../../../charts/disabledChartsData'
import HorizontalBarChart from '../../../charts/HorizontalBarChart'
import { Box, Stack } from '@mui/material'
import { truncateString } from '../../detailedInfludataRating/brandValue/mentionedProfiles/utils'

const TRUNCATE_LIMIT_STRING = 11

const AudienceCountriesCitiesChart = ({
  userindex = '',
  countryArray = [],
  cityArray = [],
  followersCount = 0,
  chartTitle = '',
  chartType = 'multi',
  isDisabledAnalysis = true,
  isForPdf = false,
  isLikersReport = false,
  isSmall = false,
  isForPdfAudience = false,
}) => {
  const { labelStrings, countryOptions, currentLang } = useTranslation()
  const isDataExist = arr => !!arr && !!arr.length

  const createAudienceCountriesChartData = useCallback(() => {
    if (isDisabledAnalysis || !isDataExist(countryArray)) {
      return disabledChartData.disabledHorisontalMultiChartData()
    }

    return countryArray.map(item => {
      const country = countryOptions.find(el => el.value === item.country)
      const countryName = country ? country.label || country.value : item.country // localized country name is not found, we use original name
      const label = (
        <Stack direction="row" alignItems="center" spacing={0.1}>
          {getPngCountryFlags(country?.isoCode || '')}
          <Box sx={{ mb: isForPdf ? '12px !important' : 0 }}>
            {isForPdf
              ? truncateString(replaceAllEmojiInStr(countryName), TRUNCATE_LIMIT_STRING)
              : replaceAllEmojiInStr(countryName)}
          </Box>
        </Stack>
      )
      return {
        value: item.value,
        label: label,
        country: item.country,
        barTip: {
          percent: `${item.value}%`,
          count: isLikersReport
            ? null
            : `(${followersCount ? pretifyBigNum((followersCount * item.value) / 100) : 0})`,
        },
      }
    })
  }, [countryArray, isDisabledAnalysis, currentLang, isLikersReport])

  const createAudienceCitiesChartData = useCallback(() => {
    if (isDisabledAnalysis || !isDataExist(cityArray)) {
      return disabledChartData.disabledHorisontalMultiChartData()
    }

    return cityArray.map(item => ({
      value: item.value,
      label: isForPdf ? <Box sx={{ mb: isForPdf ? '8px' : 0 }}>{item.city}</Box> : item.city,
      barTip: {
        percent: `${item.value}%`,
        //count: `(${pretifyBigNum((followersCount * item.value) / 100)})`,
      },
    }))
  }, [cityArray, isDisabledAnalysis, currentLang, isLikersReport])

  let preparedChartData = []
  if (chartType === 'country' || chartType === 'multi') {
    preparedChartData.push({
      title: labelStrings.country,
      data: createAudienceCountriesChartData(),
      notEnoughData: !isDisabledAnalysis && !isDataExist(countryArray),
      isLikersReport: isLikersReport,
    })
  }
  if (chartType === 'city' || chartType === 'multi') {
    preparedChartData.push({
      title: labelStrings.city,
      data: createAudienceCitiesChartData(),
      notEnoughData: !isDisabledAnalysis && !isDataExist(cityArray),
      isLikersReport: isLikersReport,
    })
  }

  if (!isDataExist(preparedChartData)) return null
  return (
    <HorizontalBarChart
      userindex={userindex}
      chartData={preparedChartData}
      isMultiChart={chartType === 'multi'}
      isDisabledChart={isDisabledAnalysis}
      chartTitle={chartTitle || labelStrings.location}
      {...(!isForPdf ? { itemsPerPage: 5 } : {})}
      isForPdf={isForPdf}
      isSmall={isSmall}
      isForPdfAudience={isForPdfAudience}
    />
  )
}

AudienceCountriesCitiesChart.propTypes = {
  userindex: PropTypes.string,
  countryArray: PropTypes.array,
  cityArray: PropTypes.array,
  followersCount: PropTypes.number,
  chartTitle: PropTypes.string,
  chartType: PropTypes.oneOf(['city', 'country', 'multi']),
  isDisabledAnalysis: PropTypes.bool,
  isForPdf: PropTypes.bool,
  isLikersReport: PropTypes.bool,
  isSmall: PropTypes.bool,
  isForPdfAudience: PropTypes.bool,
}

export default AudienceCountriesCitiesChart
