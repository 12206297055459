import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { calendarDataSelector, currentOpenedCampaignSelector } from '../../../redux/selectors'
import { getCalendarEvents } from '../../../redux/ducks/campaignsDucks'
import { fTimestamp } from '../../utils/formatTime'

export const COLOR_OPTIONS = [
  '#00AB55', // theme.palette.primary.main,
  '#1890FF', // theme.palette.info.main,
  '#54D62C', // theme.palette.success.main,
  '#FFC107', // theme.palette.warning.main,
  '#FF4842', // theme.palette.error.main
  '#04297A', // theme.palette.info.darker
  '#7A0C2E', // theme.palette.error.darker
]

export const useGetEvents = () => {
  const dispatch = useDispatch()

  const { events: data } = useSelector(calendarDataSelector)
  const { campaignId } = useSelector(currentOpenedCampaignSelector)

  useEffect(() => {
    if (campaignId) {
      dispatch(getCalendarEvents({ campaignId }))
    }
  }, [campaignId])

  const events = data?.map(event => ({
    ...event,
    textColor: event.color,
  }))

  return events
}

export const applyFilter = ({
  inputData,
  filterEventColor,
  filterStartDate,
  filterEndDate,
  isError,
}) => {
  const stabilizedThis = inputData?.map((el, index) => [el, index])

  inputData = stabilizedThis?.map(el => el[0])

  if (filterEventColor.length) {
    inputData = inputData?.filter(event => filterEventColor?.includes(event.color))
  }

  if (filterStartDate && filterEndDate && !isError) {
    inputData = inputData?.filter(
      event =>
        fTimestamp(event.start) >= fTimestamp(filterStartDate) &&
        fTimestamp(event.end) <= fTimestamp(filterEndDate)
    )
  }

  return inputData
}
