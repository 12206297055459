import styled from 'styled-components'

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
`

export const ChartName = styled.div`
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: ${props => props.theme.colors.mauve};
  text-transform: uppercase;
  margin-bottom: 10px;
`

export const ChartLabel = styled.div`
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  color: ${props => props.theme.colors.darkBrown};
  margin-bottom: 25px;
  text-transform: uppercase;
  text-align: center;
`

export const BarsAndLabelsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
`

export const Bars = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100px;
`

export const BarWrapper = styled.div`
  width: 7px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  & + & {
    margin-left: 4px;
  }
`

export const BarFilled = styled.div`
  width: 100%;
  height: ${props => props.height * 100}%;
  background-color: ${props => props.theme.scoreRankColors[Math.round(props.score)]};
  border-radius: 4px;

  ${props => {
    if (props.index === 0 || props.index === 10 || props.index === 19) {
      return `
        position: relative;

        &:after {
          content: '${props.p}%';
          position: absolute;
          top: calc(100% + 3px);
          left: 50%;
          transform: translateX(-50%);
          font-size: 10px;
          line-height: 12px;
          color: ${props.theme.colors.darkBrown};
        }
      `
    }
    return ''
  }}
`

export const BarIndicator = styled.div`
  position: absolute;
  top: -10px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  height: calc(100% + 10px);
  background-color: ${props => props.theme.colors.darkBrown};

  &:before {
    content: '';
    width: 5px;
    height: 5px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    background-color: ${props => props.theme.colors.darkBrown};
  }
`

export const BarLabels = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 5px;
`

export const BarLabel = styled.div`
  font-size: 10px;
  line-height: 12px;
  line-height: 1;
  color: ${props => props.theme.colors.darkBrown};
  white-space: nowrap;
  position: relative;
  padding-left: 12px;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 10px;
    height: 0.5px;
    background-color: ${props => props.theme.colors.mediumBrown};
  }
`
