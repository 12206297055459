import React, { useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updateSearch } from '../../../../redux/ducks/searchDucks'
import { currentSearchQuerySelector } from '../../../../redux/selectors'
import SingleCheckbox from '../../../common/checkboxes/SingleCheckbox'
import { ContentTypeWrapper } from './styles'

export const ProfileContentTypesCheckboxes = () => {
  const dispatch = useDispatch()
  const { contentTypes } = useSelector(currentSearchQuerySelector, shallowEqual)

  const [typesChecked, setTypesChecked] = useState({
    posts: true,
    stories: true,
    reels: true,
  })

  useEffect(() => {
    if (contentTypes) {
      setTypesChecked(prev => ({
        ...prev,
        posts: contentTypes?.includes('posts'),
        stories: contentTypes?.includes('stories'),
        reels: contentTypes?.includes('reels'),
      }))
    }
  }, [contentTypes])

  const onChangeTypesChecked = type => {
    setTypesChecked(prev => ({
      ...prev,
      [type]: !prev[type],
    }))
  }

  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) {
      const contentTypes = Object.keys(typesChecked)
        ?.filter(k => typesChecked[k])
        ?.join()
      dispatch(updateSearch({ newValuesObj: { contentTypes } }))
    }
    didMountRef.current = true
  }, [typesChecked, setTypesChecked])

  const typesCheckboxesArray = [
    {
      key: 'posts',
      checked: typesChecked.posts,
      labelText: `Posts`,
      withIcon: false,
    },
    {
      key: 'stories',
      checked: typesChecked.stories,
      labelText: `Stories`,
      withIcon: false,
    },
    {
      key: 'reels',
      checked: typesChecked.reels,
      labelText: `Reels`,
      withIcon: false,
    },
  ]

  return (
    <ContentTypeWrapper>
      {typesCheckboxesArray?.map(checkbox => (
        <SingleCheckbox
          key={checkbox.key}
          onChange={() => onChangeTypesChecked(checkbox.key)}
          checked={checkbox.checked}
          labelText={checkbox.labelText}
          withIcon={checkbox.withIcon}
        />
      ))}
    </ContentTypeWrapper>
  )
}
