import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { isUserAuthenticatedSelector, userGrantSelector } from '../../redux/selectors'
import { toggleSignIn } from '../../redux/ducks/appSettingsDucks'
import { withReduxActions } from '../../hocs/withRedux'
import NavBar from './NavBar'

const NavbarContainer = ({ toggleSignIn }) => {
  const location = useLocation()

  const isUserAuthorized = useSelector(isUserAuthenticatedSelector)
  const { enable_collections, enable_tracking } = useSelector(userGrantSelector)
  const onLoginClick = () => toggleSignIn()

  return (
    <>
      <NavBar
        isUserAuthorized={isUserAuthorized}
        allowCollections={!!enable_collections}
        allowTracking={!!enable_tracking}
        onLoginClick={onLoginClick}
        currentPagePath={location.pathname}
      />
    </>
  )
}

NavbarContainer.propTypes = {
  toggleSignIn: PropTypes.func,
}

export default withReduxActions({ toggleSignIn })(NavbarContainer)
