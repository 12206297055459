import React from 'react'
import PropTypes from 'prop-types'
import * as Styled from './styles'

const EmvChart = ({ benchmarkArray = [], xAxisValues = [] }) => {
  return (
    <Styled.ChartContainer>
      <Styled.BarsAndLabelsWrapper>
        <Styled.Bars>
          {benchmarkArray.map((item, index) => (
            <Styled.BarWrapper key={index} isActive={item.isActive}>
              <Styled.BarFilled
                score={item.score}
                height={item.height}
                minWidth={item.minWidth}
                index={index}
                p={item.p}
                isActive={item.isActive}
              />
            </Styled.BarWrapper>
          ))}
        </Styled.Bars>

        <Styled.BarLabels>
          {xAxisValues.map((value, index) => (
            <Styled.BarLabel key={index}>{value}</Styled.BarLabel>
          ))}
        </Styled.BarLabels>
      </Styled.BarsAndLabelsWrapper>
    </Styled.ChartContainer>
  )
}

EmvChart.propTypes = {
  benchmarkArray: PropTypes.arrayOf(
    PropTypes.shape({
      height: PropTypes.number,
      p: PropTypes.number,
      isActive: PropTypes.bool,
      parsedValue: PropTypes.string,
      score: PropTypes.number,
      value: PropTypes.number,
    })
  ),
  xAxisValues: PropTypes.arrayOf(PropTypes.string),
}

export default EmvChart
