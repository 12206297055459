import styled from 'styled-components'

export const NoDataWrapper = styled.div`
  padding: 0 35px;
  h3 {
    color: ${props => props.theme.colors.darkBrown};
    font-size: 24px;
    line-height: 26px;
    font-weight: 700;
    margin-bottom: 10px;
  }
`

export const DonutChartWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.lightBrown};
  padding: 0 35px;
  .donutLeftSide {
    width: 50%;
    h3 {
      color: ${props => props.theme.colors.darkBrown};
      font-size: 24px;
      line-height: 26px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .chart-description {
      max-width: 248px;
      color: ${props => props.theme.colors.darkBrown};
      font-weight: 400;
      font-size: 15px;
      line-height: 19px;
      margin-bottom: 15px;
    }
  }
  .donutRightSide {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    h3 {
      color: ${props => props.theme.colors.darkBrown};
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      text-align: center;
      padding-bottom: 10px;
    }
    .reactSpeedWrapper {
      max-width: 400px;
      width: 400px;
      max-height: 300px;
      height: 300px;
      .speedometer {
        margin-top: 1rem;
      }
      svg {
        .current-value {
          fill: rgb(255, 138, 48) !important;
        }
      }
    }
  }
`

export const HeatMapChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 35px;
  .topContainer {
    width: 100%;
    display: flex;
  }
  .apexcharts-tooltip {
    border-radius: 13px;
    margin-top: -100px;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: none;
  }
  .apexcharts-tooltip.apexcharts-theme-light {
    border: 1px solid transparent;
    background: transparent;
  }
  .apexcharts-xaxistooltip {
    display: none;
  }
  .heatMapTopLeftSide {
    width: 50%;
    h3 {
      color: ${props => props.theme.colors.darkBrown};
      font-size: 24px;
      line-height: 26px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    div {
      max-width: 250px;
      color: ${props => props.theme.colors.darkBrown};
      font-weight: 400;
      font-size: 15px;
      line-height: 19px;
      margin-bottom: 15px;
    }
    .salmonText {
      color: ${props => props.theme.colors.salmon};
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
    }
  }
  .heatMapTopRightSide {
    width: 50%;
    margin-top: 4rem;
  }
`
