import React from 'react'
import PropTypes from 'prop-types'
import { InfoCircleIcon } from '../icons/icons'
import { InfoTipContainer, InfoTipOverlay } from './commonStyles'
import { themeCommonStyles } from '../../../themes/infludataThemes'

const InfoTip = ({ children = null, iconColor = themeCommonStyles.colors.darkBrown }) => {
  return (
    <InfoTipContainer className="info-tip__container">
      <InfoCircleIcon color={iconColor} />
      <InfoTipOverlay className="info-tip__overlay">{children}</InfoTipOverlay>
    </InfoTipContainer>
  )
}

InfoTip.propTypes = {
  children: PropTypes.node,
  iconColor: PropTypes.string
}

export default InfoTip
