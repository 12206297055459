import { styled } from '@mui/material/styles'
import { ListItemIcon, ListItemButton, Typography } from '@mui/material'
import React from 'react'

export const StyledNavItem = styled(props => <ListItemButton disableGutters {...props} />)(
  ({ theme, is_minimal_nav }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    marginBottom: '5px',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    padding: is_minimal_nav === 'true' ? '5px 10px' : '0 20px',
    paddingRight: '15px',
    '&.active': {
      color: theme.palette.primary.main,
      background: 'rgba(255, 93, 90, 0.08)',
      fontWeight: 'bold',
    },
  })
)

export const StyledNavItemIcon = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const StyledNavCategory = styled(Typography)({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '18px',
  color: '#212B36',
  paddingLeft: '16px',
  textTransform: 'uppercase',
  paddingTop: '20px',
  paddingBottom: '5px',
})


export const styledConfigMinimalNavItem = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiListItemIcon-root': {
    mr: 0
  },
  paddingRight: '10px',
}

export const styledConfigMinimalNavText = {
  width: '100%',
  maxWidth: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
  fontSize: '10px',
  lineHeight: '16px',
  textAlign: 'center',
  marginTop: '1px'
}