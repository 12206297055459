import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../../localization/useTranslation'
import { IG_SCORE_NAMES } from '../../../../constants/profile'
import { USER_INDEXES } from '../../../../constants/appSettings'
import { pretifyBigNum } from '../../../../utils'
import InfludataRating from '../../../qualityScores/InfludataRating'
import ComparisonToSimilarInfluencers from '../../../qualityScores/ComparisonToSimilarInfluencers'
import NoDataMessage from '../NoDataMessage'
import {
  SectionTitleWrapper,
  SectionTitleName,
  SectionTitleBorder,
} from '../../commonProfilePageStyles'
import {
  RatingSectionContainer,
  RatingSectionGrid,
  RatingSectionGridItem,
  RatingTitleWithScoreWrapper,
  RatingTitleWrapper,
  RatingTitle,
  RatingWrapper,
} from '../commonStyles'

const AudienceEngagementPdf = ({ platform, profileScores, isForPdf }) => {
  const { labelStrings, scoreStrings } = useTranslation()
  const igScoreStrings = scoreStrings[USER_INDEXES.instagram]

  const {
    likeEngagementObject,
    commentEngagementObject,
    followerChangeObject,
    playsPerReelObject,
    engagementReelsObject,
    reelsWatchTimeObject,
  } = profileScores || {}

  const formatLabel = label => (!!label && !Number.isNaN(+label) ? pretifyBigNum(+label) : label)

  const scoresArr = [
    {
      name: IG_SCORE_NAMES.postsLikes,
      data: likeEngagementObject,
      label: labelStrings.nOfFollowers(likeEngagementObject?.label),
    },
    {
      name: IG_SCORE_NAMES.postsComments,
      data: commentEngagementObject,
      label: labelStrings.nComments10000Followers(formatLabel(commentEngagementObject?.label)),
    },
    {
      name: IG_SCORE_NAMES.growthRate,
      data: followerChangeObject,
      label: labelStrings.nLast30Days(followerChangeObject?.label),
    },
    {
      name: IG_SCORE_NAMES.reelsPlays,
      data: playsPerReelObject,
      label: labelStrings.nPlaysOnAverage(formatLabel(playsPerReelObject?.label)),
    },
    {
      name: IG_SCORE_NAMES.reelsEngagement,
      data: engagementReelsObject,
      label: labelStrings.nOfViewers(engagementReelsObject?.label),
    },
    {
      name: IG_SCORE_NAMES.reelsWatchTime,
      data: reelsWatchTimeObject,
      label: labelStrings.nHoursPerReel(formatLabel(reelsWatchTimeObject?.label)),
    },
  ]

  const scoreItemsCount = scoresArr.reduce(
    (currentCount, scoreItem) => (!!scoreItem.data ? currentCount + 1 : currentCount),
    0
  )

  const hasReelScores = !!playsPerReelObject && !!engagementReelsObject && !!reelsWatchTimeObject

  return (
    <RatingSectionContainer isForPdf>
      <SectionTitleWrapper>
        <SectionTitleName platform={platform} isForPdf>
          {labelStrings.audienceEngagement}
        </SectionTitleName>
        <SectionTitleBorder isForPdf={isForPdf} />
      </SectionTitleWrapper>

      {!scoreItemsCount && <NoDataMessage isForPdf />}

      <RatingSectionGrid columnsCount={hasReelScores ? 2 : 1} isForPdf>
        {scoresArr.map((scoreItem, index) => {
          const { name: scoreName, label, data: scoreData } = scoreItem
          const { benchmarkArray, score, xAxisHigh, xAxisMid, xAxisLow } = scoreData || {
            benchmarkArray: [],
            score: 0,
            xAxisHigh: null,
            xAxisLow: null,
            xAxisMid: null,
          }
          const xAxisValues = [xAxisHigh, xAxisMid, xAxisLow]

          if (!scoreData) return null

          if (!hasReelScores) {
            return (
              <RatingSectionGridItem key={index}>
                <RatingTitleWithScoreWrapper>
                  <RatingTitleWrapper isForPdf>
                    <RatingTitle>{igScoreStrings[scoreName].title}</RatingTitle>
                  </RatingTitleWrapper>
                </RatingTitleWithScoreWrapper>

                <RatingWrapper isForPdf hasReelScores justifyContent="flex-start">
                  <ComparisonToSimilarInfluencers
                    label={label}
                    benchmarkArray={benchmarkArray}
                    xAxisValues={xAxisValues}
                  />

                  <InfludataRating
                    isSmallRatingValue
                    scoreValue={score}
                    platform={platform}
                    size={125}
                    isForPdf
                  />
                </RatingWrapper>
              </RatingSectionGridItem>
            )
          }

          return (
            <RatingSectionGridItem key={index}>
              <RatingTitleWithScoreWrapper>
                <RatingTitleWrapper>
                  <RatingTitle>{igScoreStrings[scoreName].title}</RatingTitle>
                </RatingTitleWrapper>

                <InfludataRating
                  scoreValue={score}
                  platform={platform}
                  size={50}
                  isTitleHidden
                  isOutOfHidden
                  isForPdf
                  isSmallRatingValue
                />
              </RatingTitleWithScoreWrapper>

              <ComparisonToSimilarInfluencers
                label={label}
                benchmarkArray={benchmarkArray}
                xAxisValues={xAxisValues}
              />
            </RatingSectionGridItem>
          )
        })}
      </RatingSectionGrid>
    </RatingSectionContainer>
  )
}

AudienceEngagementPdf.propTypes = {
  profileScores: PropTypes.object.isRequired,
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  isForPdf: PropTypes.bool,
}

export default AudienceEngagementPdf
