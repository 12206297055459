import React from 'react'
import styled from 'styled-components'
import { CenterWrapper } from '../common/styledWrappers'
import useTranslation from '../../localization/useTranslation'

const MainWrapper = styled(CenterWrapper)`
  height: 86vh;
`

const TipMainTextWrapper = styled.div`
  font-weight: 900;
  font-size: 20px;
  position: relative;
`

const NotFoundProfilePageTip = () => {
  const { labelStrings } = useTranslation()
  return (
    <MainWrapper>
      <TipMainTextWrapper>{labelStrings.notAvaliableProfile}</TipMainTextWrapper>
    </MainWrapper>
  )
}

export default NotFoundProfilePageTip
