import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  userAvaliableSeatsSelector,
  canUserAddMoreSeatsSelector,
  isUserSeatsChangeProgressSelector,
  userUsedSeatsSelector,
  isUserInTrialSelector,
} from '../../../redux/selectors'
import { withReduxActions } from '../../../hocs/withRedux'
import { updateSubUsers } from '../../../redux/ducks/userDucks'
import SubUsers from './SubUsers'
import { validateEmail } from '../../../utils'

const SubUsersContainer = ({ updateSubUsers }) => {
  const availableSeats = useSelector(userAvaliableSeatsSelector)
  const canAddMoreSeats = useSelector(canUserAddMoreSeatsSelector)
  const isSeatsUpdateInProgress = useSelector(isUserSeatsChangeProgressSelector)
  const subUsersEmailsList = useSelector(userUsedSeatsSelector)
  const isTrialUser = useSelector(isUserInTrialSelector)

  const [subUserEmail, changeSubUserEmail] = useState('')
  const onChangeSubUserEmail = ({ target }) => changeSubUserEmail(target.value)

  const [isValidSubUserEmail, changeIsValidSubUserEmail] = useState(true)
  const resetSubUserEmailValidation = () => changeIsValidSubUserEmail(true)

  const addNewSubUser = async () => {
    const checkExistsInList = () => subUsersEmailsList.some(email => email === subUserEmail.trim())
    if (!validateEmail(subUserEmail) || checkExistsInList()) {
      return changeIsValidSubUserEmail(false)
    }
    const success = await updateSubUsers({ email: subUserEmail.trim() })
    if (success) changeSubUserEmail('')
  }

  const removeSubUser = email => () => updateSubUsers({ email, isRemove: true })

  return (
    <SubUsers
      onChangeSubUserEmail={onChangeSubUserEmail}
      subUserEmail={subUserEmail}
      addNewSubUser={addNewSubUser}
      isValidSubUserEmail={isValidSubUserEmail}
      resetSubUserEmailValidation={resetSubUserEmailValidation}
      subUsersEmailsList={subUsersEmailsList}
      availableSeats={availableSeats}
      canAddMoreSeats={canAddMoreSeats}
      removeSubUser={removeSubUser}
      isSeatsUpdateInProgress={isSeatsUpdateInProgress}
      isTrialUser={isTrialUser}
    />
  )
}

SubUsersContainer.propTypes = {
  updateSubUsers: PropTypes.func,
}

SubUsersContainer.defaultTypes = {
  updateSubUsers: () => {},
}

export default withReduxActions({
  updateSubUsers,
})(SubUsersContainer)
