import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import {
  Radio,
  FormLabel,
  RadioGroup,
  FormControl,
  FormHelperText,
  FormControlLabel,
  Typography,
  Stack,
  Tooltip,
} from '@mui/material'
import useTranslation from '../../../localization/useTranslation'

RHFRadioGroup.propTypes = {
  row: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  spacing: PropTypes.number,
  helperText: PropTypes.node,
  tooltipTitle: PropTypes.string,
  isCenterHelperText: PropTypes.bool,
}

export default function RHFRadioGroup({
  row,
  name,
  label = '',
  subLabel = '',
  options = [],
  spacing,
  helperText = '',
  isCenterHelperText,
  tooltipTitle,
  ...other
}) {
  const { control } = useFormContext()
  const { labelStrings } = useTranslation()

  const labelledby = label ? `${name}-${label}` : ''

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl component="fieldset">
          {label && (
            <FormLabel
              component="legend"
              id={labelledby}
              sx={{ typography: 'body2', fontWeight: 600, color: '#212B36' }}
            >
              {label}
            </FormLabel>
          )}

          <RadioGroup
            {...field}
            aria-labelledby={labelledby}
            row={row}
            {...other}
            value={field?.value}
          >
            {options?.map(option => (
              <Stack key={option.value}>
                <Tooltip
                  title={
                    option.disabled
                      ? tooltipTitle
                        ? tooltipTitle
                        : labelStrings.notIncludedInYourCurrentPlan
                      : ''
                  }
                  arrow
                >
                  <FormControlLabel
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                    disabled={option.disabled}
                    sx={{
                      mb: 0,
                      '&:not(:last-of-type)': {
                        mb: spacing || 0,
                      },
                      ...(row && {
                        mr: 2,
                        '&:not(:last-of-type)': {
                          mr: spacing || 2,
                        },
                      }),
                    }}
                  />
                  {option?.subLabel && (
                    <Typography
                      sx={{
                        px: 4,
                        fontSize: '12px',
                        color: option.disabled ? '#919EAB' : 'inherit',
                      }}
                    >
                      {option.subLabel}
                    </Typography>
                  )}
                </Tooltip>
              </Stack>
            ))}
          </RadioGroup>

          {(!!error || helperText) && (
            <FormHelperText
              error={!!error}
              sx={{ mx: 0, textAlign: isCenterHelperText ? 'center' : 'left' }}
            >
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  )
}
