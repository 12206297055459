import React, { useEffect, useRef } from "react"
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material"
import { ListWrapper } from "../../../components/common/styledWrappers"
import ProfileListFullTableContainer from "../../../components/profileCommon/profileListTable/ProfileListFullTableContainer"
import { useSelector } from "react-redux"
import {
  currentSuggestionUsersDataSelector,
  userGrantSelector,
} from "../../../redux/selectors"
import CollectionPageTips from "../../../components/pagesTips/CollectionPageTips"
import useTranslation from "../../../localization/useTranslation"
import { useState } from "react"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded"
import routes from "../../../routes"
import { CSVLink } from "react-csv"
import { LoadingButton } from "@mui/lab"
import { format } from "date-fns"
import { SkeletonLoader } from "../../../components/profileCommon/profileListTable/SkeletonLoader"
import { searchContentTypes } from "../../../constants/search"

const LIMIT_USERS = 100
const _LOOKALIKE_TYPE = "LOOKALIKE_TYPE"
const _CREATOR_BASE_TYPE = "CREATOR_BASE_TYPE"

export const SuggestionsView = ({ data, isFoundSuggestionsList }) => {
  const { formattedString, reference, type, id } = data || {}
  const { labelStrings } = useTranslation()

  const { no_plan } = useSelector(userGrantSelector)
  const { userdata, isLoading, creatorBase } =
    useSelector(currentSuggestionUsersDataSelector) || {}

  const csvLinkRef = useRef()

  const currDate = new Date()
  const formattedDate = format(currDate, "dd-MM-yyyy HH:mm:ss")

  const [lookalikeTypeData, setLookalikeTypeData] = useState(_LOOKALIKE_TYPE)

  const [isLoadingCSV, setIsLoadingCSV] = useState(false)
  const [csvData, setCsvData] = useState({
    header: [],
    data: [],
  })

  const onDownloadCsvData = async () => {
    setIsLoadingCSV(true)
    setCsvData({ data: [], header: [] })
    try {
      const queryParam =
        lookalikeTypeData === _LOOKALIKE_TYPE
          ? "lookalikeListId"
          : "creatorBaseId"

      const csvLink = `${routes.collab.api.getCSVData}?${queryParam}=${id}`
      const response = await fetch(csvLink)
      const { data, header } = await response.json()
      if (data?.length || header?.length) {
        setCsvData({ data, header })
      }
    } catch (error) {
      console.error("Error downloading CSV:", error)
    } finally {
      setIsLoadingCSV(false)
    }
  }

  const first100users =
    lookalikeTypeData === _CREATOR_BASE_TYPE
      ? creatorBase?.userdata?.slice(0, LIMIT_USERS)
      : userdata?.slice(0, LIMIT_USERS)

  const label =
    reference === "lookalike"
      ? `${labelStrings.buildLookalike.yourLookalikeList}: ${formattedString}`
      : `${type} ${labelStrings.explorePage.suggestionsTitle} ${reference} “${formattedString}”`

  const subLabel =
    reference === "lookalike"
      ? labelStrings.buildLookalike.lookalikeSubLabel
      : labelStrings.explorePage.suggestionsSubtitle(type)

  useEffect(() => {
    if (
      csvData.header?.length > 0 &&
      csvLinkRef.current &&
      csvLinkRef.current.link &&
      !isLoadingCSV
    ) {
      setTimeout(() => {
        csvLinkRef.current.link.dataset.interception = "off"
        csvLinkRef.current.link.click()
      })
    }
  }, [csvData.header, isLoadingCSV])

  return (
    <Stack>
      {formattedString && !!userdata?.length && (
        <Stack spacing={2} sx={{ py: 1, pl: 1 }}>
          <Typography
            sx={{
              fontFamily: "Inter, sans-serif",
              fontSize: "18px",
              fontWeight: 700,
              lineHeight: "21px",
              color: "#784E4E",
              textTransform: "capitalize",
            }}
          >
            {label}
          </Typography>
          <Typography
            sx={{
              maxWidth: "680px",
              fontFamily: "Inter, sans-serif",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "19px",
              color: "#784E4E",
            }}
          >
            {subLabel}
          </Typography>
        </Stack>
      )}

      <Stack sx={{ mt: 2 }}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          {reference === "lookalike" &&
            !!first100users?.length &&
            !isLoading && (
              <Stack
                direction='row'
                alignItems='center'
                spacing={1}
                sx={{ mb: 2 }}
              >
                <Button
                  variant={
                    lookalikeTypeData === _LOOKALIKE_TYPE
                      ? "contained"
                      : "outlined"
                  }
                  endIcon={
                    <Tooltip
                      title={labelStrings.buildLookalike.lookalikeTipText}
                    >
                      <InfoOutlinedIcon />
                    </Tooltip>
                  }
                  onClick={() => setLookalikeTypeData(_LOOKALIKE_TYPE)}
                >
                  {labelStrings.buildLookalike.lookalikes}
                </Button>
                <Button
                  variant={
                    lookalikeTypeData === _CREATOR_BASE_TYPE
                      ? "contained"
                      : "outlined"
                  }
                  endIcon={
                    <Tooltip
                      title={labelStrings.buildLookalike.creatorBaseTipText}
                    >
                      <InfoOutlinedIcon />
                    </Tooltip>
                  }
                  onClick={() => setLookalikeTypeData(_CREATOR_BASE_TYPE)}
                >
                  {labelStrings.buildLookalike.creatorBase}
                </Button>
              </Stack>
            )}

          {!!first100users?.length &&
            !isLoading &&
            reference === "lookalike" && (
              <Box>
                <LoadingButton
                  loading={isLoadingCSV}
                  onClick={onDownloadCsvData}
                >
                  {!isLoadingCSV && (
                    <DownloadRoundedIcon
                      sx={{
                        fill: !isLoadingCSV ? "" : "#637381",
                        opacity: !isLoadingCSV ? 1 : 0.5,
                      }}
                    />
                  )}
                </LoadingButton>

                {(!!csvData?.header?.length || !!csvData?.data?.length) && (
                  <CSVLink
                    asyncOnClick
                    ref={csvLinkRef}
                    data={csvData?.data}
                    headers={csvData?.header}
                    target='_blank'
                    filename={`search-results-${formattedDate}.csv`}
                  />
                )}
              </Box>
            )}
        </Stack>

        <ListWrapper>
          {isLoading ? (
            <Box>
              <SkeletonLoader searchType={searchContentTypes.CREATOR} />
            </Box>
          ) : (
            <ProfileListFullTableContainer
              users={first100users}
              isUnlockedCollectionOpened={false}
              isListMode={false}
              lookalikeTypeData={lookalikeTypeData}
              isCollectionView
              isExplore
            />
          )}
        </ListWrapper>

        {!isLoading && !userdata?.length && !isFoundSuggestionsList && (
          <CollectionPageTips noPlan={no_plan} isExplore />
        )}
      </Stack>
    </Stack>
  )
}
