import styled from 'styled-components'
import { USER_INDEXES } from '../../../constants/appSettings'

// AddToCollectionModal
export const CreateCollectionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid ${props => props.theme.colors.lightBrown};
  background-color: ${props => props.theme.colors.offWhite};
`

export const CollectionList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px 20px;
  max-width: 100%;
  min-width: 100%;
  max-height: 400px;
  overflow-y: auto;
  ${props => props.theme.withScroll};
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #bc9797;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #784e4e;
    border-radius: 16px;
    border: 2px solid #bc9797;
  }
  &::-webkit-scrollbar-thumb:hover {
    border: 0px solid #f4f4f4;
  }
`

export const CollectionName = styled.span`
  font-size: 15px;
  font-weight: ${props => (props.isUserInCollection ? 'bold' : 'normal')};
  line-height: 19px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.colors.brown};
`

export const CollectionCount = styled.span`
  font-size: 10px;
  line-height: 12px;
  height: 18px;
  min-width: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.darkBrown};
  border-radius: 9px;
`

export const CollectionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  width: 100%;
  border-radius: 9px;
  cursor: pointer;

  ${props =>
    props.isUserInCollection
      ? `
    background: ${(() => {
      if (!!props.platform && props.platform === USER_INDEXES.tiktok) {
        return props.theme.gradients.mainTiktok
      }
      if (!!props.platform && props.platform === USER_INDEXES.youtube) {
        return props.theme.gradients.mainYoutube
      }
      return props.theme.gradients.main
    })()};
    padding: 11px 16px;
    span {
      color: ${props.theme.colors.white};
    }
  `
      : `
    background: ${props.theme.colors.lightMauve};
    border: 1px solid ${props.theme.colors.lightMauve};
  `}

  &:hover {
    ${props =>
      !props.isUserInCollection &&
      `
      background-color: ${props.theme.colors.mediumBrown};
      border: 1px solid ${props.theme.colors.mauve};
    `}
  }

  & + & {
    margin-top: 10px;
  }
`

export const ConfirmBlock = styled(CreateCollectionButtonWrapper)`
  border-bottom: none;
  border-top: 1px solid ${props => props.theme.colors.lightBrown};
`

// EditCollectionModal
export const EditCollectionModalWrapper = styled.div`
  padding: 30px 20px;

  .form-element {
    label {
      font-weight: 600;
    }
  }

  .textarea-label {
    font-weight: 600;
  }

  .textarea {
    min-height: 140px;
  }
`

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const CheckboxesWrapper = styled(FlexRow)`
  justify-content: flex-start;
  margin-bottom: 20px;

  .checkbox-label {
    white-space: nowrap;
  }
`

export const ContentWrapper = styled.div`
  padding: 15px 20px;
`

export const Message = styled.div`
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.brown};
  text-align: ${props => (props.isCentered ? 'center' : 'left')};

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`

export const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.lightMauve};
  padding: 15px 20px;

  button + button {
    margin-left: 20px;
  }
`
