import React from 'react'
import PropTypes from 'prop-types'
import { RoundedSelect, SearchLabel } from '../styledWrappers'
import LinkToFaqQuestion from '../../faqs/LinkToFaqQuestion'
import { replaceAllEmojiInStr } from '../../../utils'
import GlobeImgFlag from '../../../images/globe-flag-country.png'
import WithTip from '../popups/WithTip'
import { LabelWrapper, SelectWrapper } from './commonStyles'

const SingleSelect = ({
  id,
  value = null,
  defaultValue = null,
  options,
  formatOptionLabelCountryFlag,
  onChange,
  onFocus,
  labelText = '',
  faqQuestionId = null,
  placeholder = '',
  selectHeight = 35,
  showSeparator = false,
  shortened = false,
  disabledTip = false,
  isRounded = false,
  isDisabled = false,
  captureMenuScroll = true,
  maxMenuHeight = 200,
  ...rest
}) => {
  const renderLinktToFaq = faqQuestionId !== null

  const formatOptionLabel = ({ value, label, isoCode }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {isoCode && isoCode.split('-').map(code => (
        <img
          key={code}
          src={code === 'all' ? GlobeImgFlag : `https://flagcdn.com/16x12/${code}.png`}
          alt={code}
          style={{ marginRight: '5px' }}
        />
      ))}
      {replaceAllEmojiInStr(label)}
    </div>
  );
  

  const roundSelect = (
    <RoundedSelect
      id={id}
      {...(defaultValue ? { defaultValue } : { value })}
      options={options}
      {...(formatOptionLabelCountryFlag && { formatOptionLabel })}
      className="creatable-multi-select"
      classNamePrefix="creatable-multi-select"
      onChange={onChange}
      onFocus={onFocus}
      {...(placeholder && { placeholder })}
      placeholderFontSize={placeholder.length >= 29 ? 13 : 15}
      autoFocus={false}
      menuShouldScrollIntoView={false}
      captureMenuScroll={captureMenuScroll}
      maxMenuHeight={maxMenuHeight}
      shortened={shortened}
      isRounded={isRounded}
      isDisabled={isDisabled}
      styles={{
        control: base => ({
          ...base,
          height: selectHeight,
          minHeight: selectHeight,
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          height: '100%',
          minHeight: '100%',
        }),
        input: (provided, state) => ({
          // ...provided,
          height: '100%',
          minHeight: '100%',
          fontSize: 15,
        }),
        indicatorSeparator: state => ({
          display: showSeparator ? 'block' : 'none',
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: '100%',
        }),
        menuList: base => ({
          ...base,
          '::-webkit-scrollbar': {
            width: `6px`,
            backgroundColor: `#BC9797`,
            borderRadius: `16px`
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: '#784E4E',
            borderRadius: '16px',
            border: '2px solid #BC9797',
            minHeight: '50px'
          },
          '::-webkit-scrollbar-thumb:hover': {
            border: '0px solid #f4f4f4'
          },
        }),
      }}
      {...rest}
    />
  )

  return (
    <SelectWrapper>
      {labelText && (
        <LabelWrapper>
          <SearchLabel htmlFor={id}>{labelText}</SearchLabel>
          {renderLinktToFaq && (
            <LinkToFaqQuestion
              faqQuestionId={faqQuestionId}
              linkPositions={{ top: 0, right: -28 }}
              tipIconSize={14}
            />
          )}
        </LabelWrapper>
      )}
      {disabledTip && isDisabled ? (
        <WithTip tipText={disabledTip} position="bottom center">
          <div>{roundSelect}</div>
        </WithTip>
      ) : (
        <>{roundSelect}</>
      )}
    </SelectWrapper>
  )
}

const valueType = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
})

SingleSelect.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([valueType, PropTypes.arrayOf(valueType), PropTypes.number]),
  defaultValue: PropTypes.oneOfType([valueType, PropTypes.arrayOf(valueType), PropTypes.number]),
  options: PropTypes.arrayOf(valueType).isRequired,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  labelText: PropTypes.string,
  faqQuestionId: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
  placeholder: PropTypes.string,
  selectHeight: PropTypes.number,
  showSeparator: PropTypes.bool,
  shortened: PropTypes.bool,
  isRounded: PropTypes.bool,
  isDisabled: PropTypes.bool,
  disabledTip: PropTypes.string,
  maxMenuHeight: PropTypes.number,
  captureMenuScroll: PropTypes.bool
}

export default SingleSelect
