import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DialogTitle, CloseWrapper } from '../styledWrappers'
import { ActionButton } from '../buttons/styledButtons'
import { Wrapper } from './dialogsCommonStyles'
import { CloseIcon } from '../icons'

import useTranslation from '../../../localization/useTranslation'
import { TRIAL_LIMIT_REASONS } from '../../../constants/appSettings'

const DialogWrapper = styled(Wrapper)`
  height: 250px;
  justify-content: space-around;
  padding: 20px 25px;
  text-align: center;
`

const MessageWrapper = styled.div`
  // padding: 0 20px;
`

const CloseIconWrapper = styled(CloseWrapper)`
  top: 10px;
  right: 15px;
`

const ButtonsGroupWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
`

const TrialUserAccessLimitDialog = ({ closeHandler, confirmHandler, trialLimitReason = '' }) => {
  const { labelStrings } = useTranslation()

  const createModalText = () => {
    const textsMap = {
      [TRIAL_LIMIT_REASONS.forbiddenFeature]: labelStrings.activateTrialFeature,
      [TRIAL_LIMIT_REASONS.audienceTokensTrialLimit]: `${labelStrings.please} ${labelStrings.activateTrialForAudienceTokens}`,
    }

    return textsMap[trialLimitReason] || labelStrings.activateTrial
  }

  const createModalTitleText = () => {
    const titlesMap = {
      [TRIAL_LIMIT_REASONS.requestsExceed]: labelStrings.reachTrialLimits,
      [TRIAL_LIMIT_REASONS.forbiddenFeature]: labelStrings.notAvailableInTrial,
      [TRIAL_LIMIT_REASONS.audienceTokensTrialLimit]: labelStrings.audienceTokensTrialLimit,
    }
    return titlesMap[trialLimitReason] || ''
  }

  return (
    <DialogWrapper>
      <CloseIconWrapper onClick={closeHandler}>
        <CloseIcon />
      </CloseIconWrapper>
      <DialogTitle>{createModalTitleText()}</DialogTitle>
      <MessageWrapper>
        {createModalText()}
      </MessageWrapper>

      <ButtonsGroupWrapper>
        <ActionButton onClick={closeHandler}>{labelStrings.back}</ActionButton>

        <ActionButton onClick={confirmHandler}>{labelStrings.contactSales}</ActionButton>
      </ButtonsGroupWrapper>
    </DialogWrapper>
  )
}

TrialUserAccessLimitDialog.propTypes = {
  closeHandler: PropTypes.func,
  confirmHandler: PropTypes.func,
  trialLimitReason: PropTypes.string,
}

export default TrialUserAccessLimitDialog
