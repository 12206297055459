import React from 'react'
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'
import PropTypes from 'prop-types'
import { RailHotspot, RailStyled, SliderStyled, TrackHotspotStyled, TrackStyled } from './styles'
import { format } from 'date-fns'

const RailComponent = ({ getRailProps }) => {
  return (
    <React.Fragment>
      <RailHotspot {...getRailProps()} />
      <RailStyled />
    </React.Fragment>
  )
}

const HandleComponent = ({
  domain: [min, max],
  handle: { id, value, percent },
  getHandleProps,
}) => {
  return (
    <SliderStyled
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      style={{ left: `${percent}%` }}
      {...getHandleProps(id)}
    />
  )
}

const TrackComponent = ({ source, target, getTrackProps, format, tick, count }) => {
  const left = `${source?.percent}%`
  const width = `${target?.percent - source?.percent}%`
  const getTrackedProps = getTrackProps ? getTrackProps() : {}

  return (
    <React.Fragment>
      <TrackStyled style={{ left, width }} />
      <TrackHotspotStyled style={{ left, width }} tick={tick} count={count} {...getTrackedProps}>
        {tick && format(tick.value)}
      </TrackHotspotStyled>
    </React.Fragment>
  )
}

const dayMilliseconds = 1000 * 60 * 60 * 24

function formatTick(ms) {
  const dt = new Date(ms)
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
  return format(dtDateOnly, 'MMM dd,yyyy')
}

export const ProfileContentRangeSlider = ({
  domain = [],
  values = [],
  setValues,
  setUpdate,
  setSliderTouchValue,
}) => {
  return (
    <Slider
      mode={3}
      step={dayMilliseconds}
      domain={domain}
      rootStyle={{
        position: 'relative',
        width: '100%',
      }}
      onUpdate={update => setUpdate(update)}
      onChange={val => setValues(val)}
      values={values}
      onSlideEnd={end => setSliderTouchValue(end)}
    >
      <Rail>{({ getRailProps }) => <RailComponent getRailProps={getRailProps} />}</Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <div className="slider-handles">
            {handles?.map(handle => (
              <HandleComponent
                key={handle.id}
                handle={handle}
                domain={domain}
                getHandleProps={getHandleProps}
              />
            ))}
          </div>
        )}
      </Handles>
      <Tracks left={false} right={false}>
        {({ tracks, getTrackProps }) => (
          <div className="slider-tracks">
            {tracks?.map(({ id, source, target }) => (
              <TrackComponent
                key={id}
                source={source}
                target={target}
                getTrackProps={getTrackProps}
              />
            ))}
          </div>
        )}
      </Tracks>
      <Ticks values={domain}>
        {({ ticks }) => (
          <div className="slider-ticks">
            {ticks?.map(tick => (
              <TrackComponent key={tick.id} tick={tick} count={ticks?.length} format={formatTick} />
            ))}
          </div>
        )}
      </Ticks>
    </Slider>
  )
}

ProfileContentRangeSlider.propTypes = {
  domain: PropTypes.array,
  values: PropTypes.array,
  setValues: PropTypes.func,
  setUpdate: PropTypes.func,
  setSliderTouchValue: PropTypes.func,
}