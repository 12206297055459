import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../../localization/useTranslation'
import { withReduxActions } from '../../../../hocs/withRedux'
import { addProfileComment } from '../../../../redux/ducks/profilesCommonActions'
import { USER_INDEXES } from '../../../../constants/appSettings'
import GradientTransparentButton from '../../../common/buttons/GradientTransparentButton'
import NoteWithLabelAndTools from '../additionalFields/NoteWithLabelAndTools'
import { NoteTextareaWrapper, NoteTextarea } from './styles'
import { themeCommonStyles } from '../../../../themes/infludataThemes'

const PrivateNoteTextarea = ({ platform, addProfileComment }) => {
  const { labelStrings } = useTranslation()

  const [noteValue, setNoteValue] = useState('')
  const [isAddNoteDisabled, setIsAddNoteDisabled] = useState(true)

  const onChangeNote = event => {
    const newValue = event.target.value
    setNoteValue(newValue)
    setIsAddNoteDisabled(!newValue)
  }

  const onFocus = () => {
    setIsAddNoteDisabled(!noteValue)
  }

  const onAddNoteClick = async () => {
    if (!noteValue) return

    setIsAddNoteDisabled(true)
    const isAddedSuccessfully = await addProfileComment(noteValue)
    if (isAddedSuccessfully) {
      setNoteValue('')
    }
  }

  return (
    <NoteWithLabelAndTools
      platform={platform}
      label={labelStrings.privateNotes}
      tip={labelStrings.youCanAddPrivateNotes}
      hasTools={false}
      isTextarea
    >
      <NoteTextareaWrapper>
        <NoteTextarea
          id="new-private-note"
          name="new-private-note"
          value={noteValue}
          onChange={onChangeNote}
          onFocus={onFocus}
        />

        <GradientTransparentButton
          gradient={themeCommonStyles.getGradientsForPlatform(platform).main}
          isDisabled={isAddNoteDisabled}
          onClick={onAddNoteClick}
        >
          {labelStrings.addNote}
        </GradientTransparentButton>
      </NoteTextareaWrapper>
    </NoteWithLabelAndTools>
  )
}

PrivateNoteTextarea.propTypes = {
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  addProfileComment: PropTypes.func,
}

export default withReduxActions({ addProfileComment })(PrivateNoteTextarea)
