import React from 'react'
import { Stack, Typography } from '@mui/material'
import useTranslation from '../../../../localization/useTranslation'

export const StepFinish = ({ statusCode }) => {
  const { labelStrings, currentLang } = useTranslation()

  const isNotEnoughSeats = statusCode === 202

  return (
    <Stack alignItems="center">
      <Typography
        component="label"
        sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '24px', mb: 1, color: isNotEnoughSeats ? 'red' : 'black' }}
      >
        {isNotEnoughSeats
          ? labelStrings.createCampaign.steps.finish.createCampaignTitleStatusCode202
          : labelStrings.createCampaign.steps.finish.label}
      </Typography>
      <Typography
        sx={{
          color: isNotEnoughSeats ? 'red' : '#637381',
          lineHeight: '22px',
          fontSize: `14px`,
          fontFamily: `Inter`,
          fontWeight: 400,
          textAlign: `center`,
          marginTop: 2,
          marginRight: `14px`,
          marginBottom: 0,
          marginLeft: `14px`,
          maxWidth: currentLang === 'de' ? '480px' : '422px',
        }}
      >
        {isNotEnoughSeats
          ? labelStrings.createCampaign.steps.finish.createCampaignTextStatusCode202
          : labelStrings.createCampaign.steps.finish.helperText}
      </Typography>
    </Stack>
  )
}
