import React from 'react'
import { Box, Card, IconButton, Stack } from '@mui/material'
import { DownloadContent } from '../../../assets/icons/navbar'
import Label from '../../label'
import { getUrlWithProxy } from '../../../../utils'
import { downloadMedia } from './utils'

export const WrapperMediaItem = props => {
  const {
    children,
    widthElement,
    heightElement,
    isDataEnrichment,
    contentVideo,
    contentImg,
    contentType,
    platform,
    onHandleSetCurrCreatorsData,
    isDownloadContentModal,
  } = props

  const saveAs = (blob, fileName) => {
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  const handleDownload = async (url, fileName) => {
    if (!url.trim()) {
      return
    }

    const currentDate = new Date()
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace(/:/g, '-')
      .replace('T', '_')

    const proxyUrl = getUrlWithProxy(url)
    const mediaExtension = url
      ?.split('.')
      ?.pop()
      ?.split('?')[0]
      ?.toLowerCase()

    if (url?.includes('infludata')) {
      return downloadMedia(getUrlWithProxy(url), mediaExtension)
    }

    try {
      const response = await fetch(proxyUrl)
      const blob = await response.blob()
      const blobType = blob?.type?.split('/')

      if (blobType?.length) {
        saveAs(blob, `${fileName || blobType[0]}-${formattedDate}.${blobType[1] || mediaExtension}`)
      }
    } catch (error) {
      console.error('Error downloading media:', error)
    }
  }

  return (
    <Box sx={{ width: ' 100%', height: '100%', position: 'relative' }}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          right: '2px',
          display: 'flex !important',
          flexDirection: 'column',
          alignItems: 'end',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            cursor: 'pointer',
            zIndex: 9,
            position: 'absolute',
            top: 0,
            right: 0,
            padding: '10px',
          }}
        >
          {!isDataEnrichment ? (
            <Box sx={{ cursor: 'pointer ' }}>
              {isDownloadContentModal ? (
                <IconButton onClick={() => onHandleSetCurrCreatorsData()}>
                  <DownloadContent />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => handleDownload(contentVideo ? contentVideo : contentImg)}
                >
                  <DownloadContent />
                </IconButton>
              )}
            </Box>
          ) : (
            <Box></Box>
          )}
        </Box>
        <Box>
          <Card
            sx={{
              width: `calc(${widthElement}px - 20px) !important`,
              height: `calc(520px - ${heightElement}px)`,
            }}
          >
            {children}
          </Card>
        </Box>
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="center"
          spacing={1} // Adjust this value to increase/decrease space between labels
          sx={{
            width: '100% !important',
            position: 'absolute',
            top: 0,
            left: 0,
            padding: '10px',
          }}
        >
          <Label
            variant="filled"
            color={
              (contentType === 'shorts' && 'error') ||
              (contentType === 'reel' && 'info') ||
              (contentType === 'video' && platform === 'tiktok' && 'secondary') ||
              (platform === 'youtube' && 'error') ||
              (contentType === 'post' && 'warning') ||
              'success'
            }
          >
            {platform + ' ' + contentType}
          </Label>
        </Stack>
      </Box>
    </Box>
  )
}
