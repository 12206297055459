import React from 'react'
import PropTypes from 'prop-types'
import { FormProvider as Form } from 'react-hook-form'

FormProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  methods: PropTypes.object,
  onSubmit: PropTypes.func,
  isDisabledEnterKeySubmit: PropTypes.bool
}

export default function FormProvider({ children, onSubmit, methods, isDisabledEnterKeySubmit }) {
  const preventEnterKeySubmission = e => {
    const target = e.target
    if (e.key === 'Enter' && target instanceof HTMLInputElement) {
      e.preventDefault()
    }
  }

  return (
    <Form {...methods}>
      <form
        onKeyDown={isDisabledEnterKeySubmit ? preventEnterKeySubmission : null}
        onSubmit={onSubmit}
      >
        {children}
      </form>
    </Form>
  )
}
