import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import useTranslation from '../../../localization/useTranslation'
import { userGrantSelector } from '../../../redux/selectors'
import Activity from './Activity'
import AudienceEngagement from './AudienceEngagement/AudienceEngagement'
import Authenticity from './Authenticity'
import BrandValue from './brandValue/BrandValue'
import UnavailableProfileSection from '../../common/unavailableProfileSection'
import InfoTip from '../../common/tips/InfoTip'
import {
  SectionTitleWrapper,
  SectionTitleName,
  SectionTitleBorder,
} from '../commonProfilePageStyles'
import { ContactSalesWrapper } from './commonStyles'

const DetailedInfludataRating = ({ profile }) => {
  const {
    profileScore,
    _index: platform,
    brandCollaborations,
    creatorCollaborations,
    username,
  } = profile
  const { labelStrings, profileSectionDescs } = useTranslation()

  const { basic_profile_stats } = useSelector(userGrantSelector, shallowEqual)
  const isSectionDisabled = !basic_profile_stats

  return (
    <>
      <SectionTitleWrapper>
        <SectionTitleName platform={platform}>
          {labelStrings.detailedQualityRating}
        </SectionTitleName>
        <InfoTip>
          <div dangerouslySetInnerHTML={{ __html: profileSectionDescs.detailedQualityRating }} />
        </InfoTip>
        <SectionTitleBorder />
      </SectionTitleWrapper>

      {!isSectionDisabled ? (
        creatorCollaborations ? (
          <>
            <BrandValue
              platform={platform}
              profileScores={profileScore}
              creatorCollaborations={creatorCollaborations}
              brandCollaborations={brandCollaborations}
              username={username}
              profile={profile}
            />
            <Activity platform={platform} profileScores={profileScore} />

            <AudienceEngagement platform={platform} profileScores={profileScore} />

            <Authenticity
              platform={platform}
              suspiciousFollowersObject={profileScore.suspiciousFollowersObject}
              suspiciousActivities={profileScore.suspiciousActivities}
            />
          </>
        ) : (
          <>
            <Activity platform={platform} profileScores={profileScore} />

            <AudienceEngagement platform={platform} profileScores={profileScore} />

            <Authenticity
              platform={platform}
              suspiciousFollowersObject={profileScore.suspiciousFollowersObject}
              suspiciousActivities={profileScore.suspiciousActivities}
            />
            <BrandValue
              platform={platform}
              profileScores={profileScore}
              creatorCollaborations={creatorCollaborations}
              brandCollaborations={brandCollaborations}
              username={username}
              profile={profile}
            />
          </>
        )
      ) : (
        <ContactSalesWrapper>
          <UnavailableProfileSection
            unavailableSectionLabel={labelStrings.toSeeDetailedInfludataRating}
            faqQuestionId=""
            linkToFaqText={labelStrings.whatIsDetailedInfludataRating}
          />
        </ContactSalesWrapper>
      )}
    </>
  )
}

export default DetailedInfludataRating
