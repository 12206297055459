import React from 'react'
import { Stack, Typography, Button, Box } from '@mui/material'

export const WillBeAddedPage = () => {
  const handleReloadPage = () => {
    window?.location?.reload()
  }

  return (
    <Stack alignItems="center" spacing={2}>
      <Box sx={{ height: '100px' }} />
      <Stack>
        <Typography align="center" sx={{ color: '#784E4E', fontWeight: 500, fontSize: '18px' }}>
          You report is currently updated.
        </Typography>
        <Typography align="center" sx={{ color: '#784E4E', fontWeight: 500, fontSize: '18px' }}>
          This will take around 10 minutes. Please click the refresh button.
        </Typography>
      </Stack>

      <Button
        onClick={handleReloadPage}
        sx={{ color: '#784E4E', borderColor: '#784E4E', fontSize: '16px' }}
        variant="outlined"
      >
        Refresh Page
      </Button>
      <Box sx={{ height: '100px' }} />
    </Stack>
  )
}
