import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Typography, MenuItem } from '@mui/material'
import { RHFSelect, RHFTextField } from '../../../components/hook-form'
import useTranslation from '../../../../localization/useTranslation'
import { useFieldArray, useFormContext } from 'react-hook-form'

const titleStyle = {
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '22px',
}

export const AudienceDemographicFields = ({ isSubmitting, getValues, watch }) => {
  const { labelStrings, countryOptions, languageOptions } = useTranslation()
  const countryArray = watch('countryArray')
  const languageArray = watch('languageArray')

  const [countrySelected, setCountrySelected] = useState([])
  const [languagesSelected, setLanguagesSelected] = useState([])

  const { control } = useFormContext()
  const { fields: countryFields } = useFieldArray({ control, name: 'countryArray' })
  const { fields: languageFields } = useFieldArray({ control, name: 'languageArray' })
  const { fields: ageFields } = useFieldArray({ control, name: 'ageArray' })

  useMemo(() => {
    if (countryArray.length) {
      for (let c in countryArray) {
        const iso = countryOptions?.find(el => el.value === countryArray[c].country)?.isoCode
        !countrySelected.includes(iso) && setCountrySelected(prevArray => [...prevArray, iso])
      }
    }
  }, [countryArray])

  useMemo(() => {
    if (languageArray.length) {
      for (let l in languageArray) {
        const label = languageOptions?.find(el => el.value === languageArray[l].language)?.label
        !languagesSelected.includes(label) &&
          setLanguagesSelected(prevArray => [...prevArray, label])
      }
    }
  }, [languageArray])

  useEffect(() => {
    if (isSubmitting) {
      setCountrySelected([])
      setLanguagesSelected([])
    }
  }, [isSubmitting])

  const renderCountryFields = useCallback(
    ({
      nameSelect,
      labelSelect = labelStrings.country,
      nameField,
      labelField = labelStrings.percent,
      countryDefaultValue = '',
    }) => {
      const optionsCopy = countryOptions?.filter(el => el.isoCode !== 'all')

      const selectProps = {
        name: nameSelect,
        label: labelSelect,
        size: 'small',
        ...(countryDefaultValue ? { value: countryDefaultValue } : {}),
      }

      const fieldProps = {
        name: nameField,
        label: labelField,
        size: 'small',
        type: 'number',
        inputTypeMask: 'float',
        oneNumberAfterPoint: true,
      }

      return (
        <Box sx={{ display: 'flex', marginTop: 2 }}>
          <RHFSelect {...selectProps} InputLabelProps={{ shrink: true }}>
            {optionsCopy?.map(country => (
              <MenuItem
                key={country.isoCode}
                value={country.isoCode}
                onClick={() => setCountrySelected(prevArray => [...prevArray, country.isoCode])}
                disabled={countrySelected?.includes(country.isoCode)}
              >
                {country.label}
              </MenuItem>
            ))}
          </RHFSelect>
          <Box sx={{ display: 'flex ', alignItems: 'center', marginLeft: 1 }}>
            <RHFTextField {...fieldProps} />
            <Typography sx={{ marginLeft: 1 }}>%</Typography>
          </Box>
        </Box>
      )
    },
    [countryOptions, countrySelected]
  )

  const renderLanguagesFields = useCallback(
    ({
      nameSelect,
      labelSelect = labelStrings.languages,
      nameField,
      labelField = labelStrings.percent,
    }) => {
      const optionsCopy = languageOptions?.filter(el => el.label !== 'All')

      const selectProps = {
        name: nameSelect,
        label: labelSelect,
        size: 'small',
      }

      const fieldProps = {
        name: nameField,
        label: labelField,
        size: 'small',
        type: 'number',
        inputTypeMask: 'float',
        oneNumberAfterPoint: true,
      }
      return (
        <Box sx={{ display: 'flex', marginTop: 2 }}>
          <RHFSelect {...selectProps} InputLabelProps={{ shrink: true }}>
            {optionsCopy?.map(lang => (
              <MenuItem
                key={lang.value}
                value={lang.value}
                onClick={() => setLanguagesSelected(prevArray => [...prevArray, lang.label])}
                disabled={languagesSelected?.includes(lang.label)}
              >
                {lang.label}
              </MenuItem>
            ))}
          </RHFSelect>
          <Box sx={{ display: 'flex ', alignItems: 'center', marginLeft: 1 }}>
            <RHFTextField {...fieldProps} />
            <Typography sx={{ marginLeft: 1 }}>%</Typography>
          </Box>
        </Box>
      )
    },
    [languageOptions, languagesSelected]
  )

  const renderTextFields = useCallback(
    ({
      nameTextField,
      labelTextField = '',
      nameField,
      labelField = labelStrings.percent,
      defaultValue = '',
      defaultValuePercent = '',
      inputTypeMask = 'float',
    }) => {
      const percentProps = {
        name: nameField,
        label: labelField,
        size: 'small',
        type: 'number',
        inputTypeMask: inputTypeMask,
        oneNumberAfterPoint: true,
        ...(defaultValuePercent ? { value: defaultValuePercent } : {}),
      }
      return (
        <Box sx={{ display: 'flex', marginTop: 2 }}>
          <RHFTextField
            name={nameTextField}
            label={labelTextField}
            size="small"
            InputProps={{ readOnly: true }}
            value={defaultValue}
          />
          <Box sx={{ display: 'flex ', alignItems: 'center', marginLeft: 1 }}>
            <RHFTextField {...percentProps} />
            <Typography sx={{ marginLeft: 1 }}>%</Typography>
          </Box>
        </Box>
      )
    },
    []
  )

  return (
    <Box>
      <Box sx={{ my: 3 }}>
        <Typography sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '24px' }}>
          {labelStrings?.creatorOverview.audienceDemographics}
        </Typography>
        <Typography sx={{ fontSize: '14px', lineHeight: '22px' }}>
          {labelStrings?.creatorOverview.audienceDemographicsSubtitle}
        </Typography>
      </Box>
      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
      >
        <Box>
          <Typography sx={titleStyle}>
            {labelStrings.creatorOverview.countriesTopLocation}
          </Typography>
          <Box sx={{ marginRight: 3 }}>
            {countryFields?.map((item, index) => {
              return (
                <Fragment key={item.id}>
                  {renderCountryFields({
                    nameSelect: `countryArray[${index}].country`,
                    nameField: `countryArray[${index}].value`,
                    ...(item.country ? { countryDefaultValue: item.isoCode } : {}),
                  })}
                </Fragment>
              )
            })}
          </Box>
        </Box>

        <Box sx={{ marginLeft: 3 }}>
          <Typography sx={titleStyle}>{labelStrings.creatorOverview.ageRange}</Typography>
          <Box>
            {ageFields?.map((item, index) => {
              return (
                <Fragment key={index}>
                  {renderTextFields({
                    nameTextField: `ageArray[${index}].birthyear`,
                    nameField: `ageArray[${index}].value`,
                    defaultValue: item.birthyear,
                  })}
                </Fragment>
              )
            })}
          </Box>
        </Box>

        <Box sx={{ marginTop: 4, marginRight: 3 }}>
          <Typography sx={titleStyle}>{labelStrings.languages}</Typography>
          <Box>
            {languageFields?.map((_, index) => {
              return (
                <Fragment key={index}>
                  {renderLanguagesFields({
                    nameSelect: `languageArray[${index}].language`,
                    nameField: `languageArray[${index}].value`,
                  })}
                </Fragment>
              )
            })}
          </Box>
        </Box>

        <Box sx={{ marginTop: 4, marginLeft: 3 }}>
          <Typography sx={titleStyle}>{labelStrings.gender}</Typography>
          <Box>
            {renderTextFields({
              nameTextField: 'genderArray.male',
              nameField: 'genderArray.male',
              defaultValue: 'male',
              inputTypeMask: 'integer',
            })}
            {renderTextFields({
              nameTextField: 'genderArray.female',
              nameField: 'genderArray.female',
              defaultValue: 'female',
              inputTypeMask: 'integer',
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
