export const SHOW_HITS_NAME = 'SHOW_HITS'

export const CAMPAIGN_SHOW_HITS_FILTER = {
  enabled: {
    value: true,
    name: 'enabled',
  },
  disabled: {
    value: false,
    name: 'disabled',
  },
}
