import styled from 'styled-components'
import { USER_INDEXES } from '../../../../constants/appSettings'

export const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 10px;
  justify-content: center;
  margin-top: 15px;
`

export const GalleryItem = styled.a`
  display: block;
  background-image: url(${props => props.imgSrc});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 10px;

  ${props => {
    let size = { w: 0, h: 0 }
    switch (props.platform) {
      case USER_INDEXES.instagram:
        size.w = 160
        size.h = 160
        break
      case USER_INDEXES.tiktok:
        size.w = 170
        size.h = 300
        break
      case USER_INDEXES.youtube:
        size.w = 160
        size.h = 160
        break
    }
    return `
      width: ${size.w}px;
      height: ${size.h}px;
    `
  }}
`

export const GalleryItemYt = styled.a`
  display: block;
  background-image: url(${props => props.imgSrc});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 10px;

  ${props => {
    let size = { w: 0, h: 0 }
    switch (props.platform) {
      case USER_INDEXES.instagram:
        size.w = 160
        size.h = 160
        break
      case USER_INDEXES.tiktok:
        size.w = 170
        size.h = 300
        break
      case USER_INDEXES.youtube:
        size.w = 160
        size.h = 160
        break
    }
    return `
      width: ${size.w}px;
      height: ${size.h}px;
    `
  }}
`

export const YtGalleryItem = styled.div`
  background-color: ${props => props.theme.color.white};
  padding: 10px;
  width: 210px;
  min-height: 260px;
  border-radius: 16px;
  color: ${props => props.theme.colors.darkBrown}
`
export const YtGalleryItemTitle = styled.div`
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 12px;
  padding: 0 6px;
  max-width: 190px;
  min-height: 39px;
  max-height: 39px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
`
export const YtGalleryImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 200px;
  max-height: 138px;
  border-radius: 10px;
  margin-bottom: 10px;
`
export const YtIconGalleryWrapper = styled.div`
  display: flex;
  justify-content: ${props => props.isSpaceBetween ? 'space-between' : 'flex-start'};
  align-items: center;
  padding: 0 8px;
  margin-bottom: 15px;
`
export const YtIconSingle = styled.div`
  display: flex;
  line-height: initial;
  font-weight: 700;
  font-size: 12px;
  align-items: center;
  ${props => !props.isMargin && 'margin-right: 15px'};
  svg {
    margin-right: 4px;
  }
`
export const YtGalleryStatistics = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  div {
    line-height: 16px;
    font-size: 13px;
  }
  span {
    font-weight: 600;
    color: ${props => props.theme.colors.salmon}
  }
  margin-bottom: 5px;
`