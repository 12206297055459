import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import PrimaryButton from '../../common/buttons/PrimaryButton'
import useTranslation from '../../../localization/useTranslation'

import BillingFormInput from './BillingFormInput'

const LoginFormWrapper = styled.form`
  ${props => props.theme.flex.centerAll};
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  max-width: 300px;
  min-width: 290px;
`

const ButtonRow = styled(Row)`
  justify-content: flex-end;
  margin-top: 10px;
  width: unset;
  min-width: unset;
`

const BillingDetailsForm = ({
  onSubmit,
  rowOneList = [],
  rowTwoList = [],
  isEditDetails = false,
  onButtonClick,
  isAllValid = true,
}) => {
  const { labelStrings } = useTranslation()

  return (
    <LoginFormWrapper onSubmit={onSubmit}>
      <Row>
        {rowOneList.map(item => (
          <BillingFormInput item={item} key={item.label} isEditDetails={isEditDetails} />
        ))}
      </Row>

      <Row>
        {rowTwoList.map(item => (
          <BillingFormInput item={item} key={item.label} isEditDetails={isEditDetails} />
        ))}
      </Row>
      <ButtonRow>
        <PrimaryButton
          label={isEditDetails ? labelStrings.saveDetails : labelStrings.editDetails}
          onClick={onButtonClick}
          isDisabled={isEditDetails && !isAllValid}
        />
      </ButtonRow>
    </LoginFormWrapper>
  )
}

BillingDetailsForm.propTypes = {
  rowOneList: PropTypes.arrayOf(PropTypes.object),
  rowTwoList: PropTypes.arrayOf(PropTypes.object),
  isEditDetails: PropTypes.bool,
  onButtonClick: PropTypes.func,
  isAllValid: PropTypes.bool,
}

export default BillingDetailsForm
