import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useTranslation from '../../localization/useTranslation'
import { convertHexToRgba } from '../../utils'

const MessageWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55%;
  min-width: 300px;
  max-width: 400px;
  padding: 10px;
  background: ${props => convertHexToRgba(props.theme.colors.white, 0.8)};
  border-radius: 8px;
`

const MessageText = styled.div`
  font-family: ${props => props.theme.fontFamilyInter};
  ${props =>
    props.isForPdf
      ? `color: ${props.theme.getColorPaletteForPlatform(props.platform).main};`
      : props.theme.getTextGradientStyles({ platform: props.platform })}
  font-size: 15px;
  font-weight: bold;
  text-align: center;
`

const NotEnoughDataForChartMessage = ({ platform = '', isForPdf = false }) => {
  const { labelStrings } = useTranslation()

  return (
    <MessageWrapper className="disabled-chart-message__container">
      <MessageText className="disabled-chart-message__text" platform={platform} isForPdf={isForPdf}>
        {labelStrings.notEnoughDataForChart}
      </MessageText>
    </MessageWrapper>
  )
}

NotEnoughDataForChartMessage.propTypes = {
  platform: PropTypes.string,
  isForPdf: PropTypes.bool,
}

export default NotEnoughDataForChartMessage
