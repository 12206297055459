import React from 'react'
import useTranslation from '../../../localization/useTranslation'
import {
  Button,
  Stack,
  Typography,
} from '@mui/material'
import { ModalComponent } from '../../components/modal/ModalComponent'

export const DemoCampaignModal = props => {
  const { isOpenModal, closeModal, onHandleCloseDemoCampaignModal, onRemoveDemoCampaigns } = props || {}

  const { labelStrings } = useTranslation()
  return (
    <ModalComponent
      isOpenModal={isOpenModal}
      closeModal={closeModal}
      title={labelStrings.modalDemoCampaign.label}
      contentWidth="440px"
      contentHeight="auto"
      isCenterTitle
      withoutScrollWrapper
      smallPaddingContent
      smallPadding
    >
      <Stack>
        <Stack spacing={1} sx={{ px: 2, mt: 0 }} alignItems="center">
          <Typography textAlign="center" sx={{ fontSize: '14px', maxWidth: '336px' }}>
            {labelStrings.modalDemoCampaign.subtitleOne}
          </Typography>
          <Typography textAlign="center" sx={{ fontSize: '14px', maxWidth: '336px' }}>
            {labelStrings.modalDemoCampaign.subtitleTwo}
          </Typography>
        </Stack>

        <Stack sx={{ my: 3, px: 2 }} direction="row" justifyContent="space-between" alignItems="center">
          <Button
            onClick={onRemoveDemoCampaigns}
            variant="text"
            sx={{ maxWidth: '200px', lineHeight: '18px' }}
          >
            {labelStrings.modalDemoCampaign.btnLabel}
          </Button>
          <Button
            onClick={onHandleCloseDemoCampaignModal}
            variant="contained"
            sx={{ width: 'fit-content',  }}
          >
            {labelStrings.confirm}
          </Button>
        </Stack>
      </Stack>
    </ModalComponent>
  )
}
