import React from 'react'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import { useHistory } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { limitsSelector } from '../../redux/selectors'

import { withReduxActions } from '../../hocs/withRedux'
import { toggleFreeUserLimit, toggleTrialUserLimit } from '../../redux/ducks/appSettingsDucks'
//import { openHubSpotFormWidget } from '../../utils/hubspot'

import { WithPopupWrapper } from '../common/styledWrappers'

import FreeUserAccessLimitDialog from '../common/dialogs/FreeUserAccessLimitDialog'
import TrialUserAccessLimitDialog from '../common/dialogs/TrialUserAccessLimitDialog'

const LimitsContainer = ({ toggleFreeUserLimit, toggleTrialUserLimit }) => {
  const history = useHistory()

  const { isFreeUserLimited, isTrialUserLimited, trialLimitReason } = useSelector(limitsSelector)

  const confirmHandler = () => {
    if (isFreeUserLimited) {
      toggleFreeUserLimit()
      history.push('/settings')
    } else if (isTrialUserLimited) {
      //openHubSpotFormWidget()
    }
  }

  return (
    <WithPopupWrapper enableHeader fullScreen>
      <Popup open={true} position="right center" closeOnDocumentClick={false} modal>
        <>
          {isFreeUserLimited && (
            <FreeUserAccessLimitDialog
              closeHandler={() => toggleFreeUserLimit()}
              confirmHandler={confirmHandler}
            />
          )}
          {isTrialUserLimited && (
            <TrialUserAccessLimitDialog
              closeHandler={() => toggleTrialUserLimit()}
              confirmHandler={confirmHandler}
              trialLimitReason={trialLimitReason}
            />
          )}
        </>
      </Popup>
    </WithPopupWrapper>
  )
}

LimitsContainer.propTypes = {
  toggleFreeUserLimit: PropTypes.func,
  toggleTrialUserLimit: PropTypes.func,
}

export default withReduxActions({
  toggleFreeUserLimit,
  toggleTrialUserLimit,
})(LimitsContainer)
