import styled from 'styled-components'

export const NotActivatedTipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const NotActivatedTipTitle = styled.div`
  max-width: 500px;
  margin-left: 5px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  position: relative;
  color: ${props => props.theme.textColor};
  letter-spacing: 0.8px;
`

export const NotActivatedTipText = styled(NotActivatedTipTitle)`
  letter-spacing: 0px;
  width: 510px;
  font-weight: 400;
  text-transform: unset;
  font-size: 14px;
`

export const NotActivatedIconWrapper = styled.span`
  ${props => props.theme.flex.centerAll};

  & svg {
    height: 20px;
    width: 20px;
  }
`
