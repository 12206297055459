import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useTranslation from '../../../localization/useTranslation'
import CommonSettingSection from '../CommonSettingSection'
import PrimaryButton from '../../common/buttons/PrimaryButton'
import WithTip from '../../common/popups/WithTip'
import { InfoIcon } from '../../common/icons'
import { ContactUs, TipHoveredText, TipIconWrapper } from '../../common/styledWrappers'

const ContentWrapper = styled.div``

const Username = styled.div`
  font-size: 15px;
  color: ${props => props.theme.color.mineShaft};
`

const LabelWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
`

const Label = styled.span`
  margin-right: 5px;
  text-transform: uppercase;
  color: ${props => props.theme.color.grey};
  font-size: 13px;
`

const AccountSettings = ({ changePassword, email = '', openContactUs }) => {
  const { labelStrings } = useTranslation()
  const Button = <PrimaryButton label={labelStrings.changePassword} onClick={changePassword} />

  const TipComponent = (
    <TipHoveredText>
      {labelStrings.please}{' '}
      <ContactUs onClick={openContactUs}>{labelStrings.contactUs.toLowerCase()}</ContactUs>{' '}
      {labelStrings.ifYouLikeToChangeEmail}
    </TipHoveredText>
  )

  const Content = (
    <ContentWrapper>
      <LabelWrapper>
        <Label>{labelStrings.email}</Label>
        <WithTip position="right center" TipComponent={TipComponent} useWhiteBG>
          <TipIconWrapper>
            <InfoIcon />
          </TipIconWrapper>
        </WithTip>
      </LabelWrapper>

      <Username>{email}</Username>
    </ContentWrapper>
  )

  return <CommonSettingSection title={labelStrings.yourAccount} Button={Button} Content={Content} />
}

AccountSettings.propTypes = {
  changePassword: PropTypes.func,
  email: PropTypes.string,
  openContactUs: PropTypes.func,
}

export default AccountSettings
