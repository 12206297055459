import React from 'react'
import PropTypes from 'prop-types'
import { SimpleVennDiagram } from './SimpleVennDiagram'
import styled from 'styled-components'
import { pretifyBigNum } from '../../../../utils'

const VennDiagramWidget = styled.div`
  min-width: 300px;
  width: 100%;
  margin: 0 auto;
  background-color: #f3e7e7;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 2px 6px -4px #999;
  border-radius: 13px;
`
const TitleUnderCircle = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #784e4e;
`
const TitlesWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 14px;
  min-height: 35px;
`
const DiagramTitleContainer = styled.div`
  width: 100%;
  padding: 0 10px;
`
const BottomContent = styled.div`
  border-top: 1px solid white;
  text-align: center;
  width: 100%;
  padding: 12px 10px;
  color: #784e4e;
  line-height: 19px;
  font-size: 15px;
  font-weight: 700;
`

export const VennDiagramContainer = props => {
  const { data = {}, labelStrings = {}, countFollowerX = 0, countFollowerY = 0 } = props || {}
  const percentValue =
    data?.compared?.userBScore < data?.compared?.userAScore
      ? `${data?.compared?.userAScore.toFixed(1)}%`
      : `${data?.compared?.userBScore.toFixed(1)}%` //data?.heatMap > 0 ? `${data?.heatMap}%` : data?.heatMap
  return (
    <VennDiagramWidget>
      <DiagramTitleContainer>
        <SimpleVennDiagram
          overlapFollowers={data?.compared?.overlapFollowers}
          countFollowerX={countFollowerX}
          countFollowerY={countFollowerY}
        />
        <TitlesWrapper>
          <TitleUnderCircle>{data?.compared?.influencerX}</TitleUnderCircle>
          <TitleUnderCircle>{data?.compared?.influencerY}</TitleUnderCircle>
        </TitlesWrapper>
      </DiagramTitleContainer>
      <BottomContent>{`${percentValue} or ${pretifyBigNum(data?.compared?.overlapFollowers)} ${
        labelStrings?.vennDiagramBottomTitle
      }`}</BottomContent>
    </VennDiagramWidget>
  )
}

VennDiagramContainer.propTypes = {
  data: PropTypes.object,
  labelStrings: PropTypes.object,
  countFollowerX: PropTypes.number,
  countFollowerY: PropTypes.number,
}