export const typographyStyles = {
  textTransform: 'uppercase',
  fontSize: '15px',
  color: '#784E4E',
  fontWeight: 700,
  lineHeight: '19px',
  fontFamily: 'Inter',
}

export const typographyGreenStyles = {
  textTransform: 'uppercase',
  fontSize: '15px',
  color: '#6CB75B',
  fontWeight: 700,
  lineHeight: '19px',
  fontFamily: 'Inter',
}

export const typographyBrownStyles = {
  textTransform: 'uppercase',
  fontSize: '15px',
  color: '#784E4E',
  fontWeight: 700,
  lineHeight: '19px',
  fontFamily: 'Inter',
}

export const typographySmallStyles = {
  fontSize: '15px',
  color: '#784E4E',
  fontWeight: 400,
  lineHeight: '19px',
  fontFamily: 'Inter',
}