export const MIN_USERS_FOR_SORTING = 2

export const SORTING_DIRECTIONS = {
  ascend: 'ascend', // from small to big
  descend: 'descend', // from big to small
}

export const SORTING_DIRECTIONS_SHORT_TO_LONG_NAMES = {
  asc: SORTING_DIRECTIONS.ascend,
  desc: SORTING_DIRECTIONS.descend,
}

export const SORTING_DIRECTIONS_LONG_TO_SHORT_NAMES = {
  [SORTING_DIRECTIONS.ascend]: 'asc',
  [SORTING_DIRECTIONS.descend]: 'desc',
}

export const CAMPAIGNS_SORTING_TYPES = {
  lastHit: {
    sortKey: 'lastHit',
    type: 1,
  },
  username: {
    sortKey: 'username',
    type: 2,
  },
  hitsPosts: {
    sortKey: 'hitsPosts',
    type: 3,
  },
  hitsStories: {
    sortKey: 'hitsStories',
    type: 4,
  },
  trackingStart: {
    sortKey: 'trackingStart',
    type: 5,
  },
}

export const COLLECTIONS_SORTING_TYPES = {
  position: {
    sortKey: 'position',
    type: 0,
  },
  username: {
    sortKey: 'username',
    type: 1,
  },
  profileScore: {
    sortKey: 'profileScore',
    type: 2,
    sortNestedKey: 'profileScore.overall',
  },
  stars: {
    sortKey: 'stars',
    type: 3,
  },
  followers: {
    sortKey: 'followers',
    type: 4,
  },
  impressions: {
    sortKey: 'impressions',
    type: 5,
  },
}

export const SEARCH_SORTING_TYPES = {
  relevance: {
    sortKey: 'relevance',
    localizedKey: 'relevance',
  },
  username: {
    sortKey: 'username',
    localizedKey: 'username',
  },
  profileScore: {
    sortKey: 'profileScore',
    localizedKey: 'scoreOverall',
  },
  followers: {
    sortKey: 'followers',
    localizedKey: 'followers',
  },
  viewsPost: {
    sortKey: 'viewsPost',
    localizedKey: 'viewsPerPost',
  },
  engagementMean: {
    sortKey: 'engagementMean',
    localizedKey: 'engagementMean',
  },
  growthRate: {
    sortKey: 'growthRate',
    localizedKey: 'growthRate',
  }
}

export const SEARCH_CONTENT_SORTING_TYPES = {
  reach: {
    sortKey: 'reach',
    localizedKey: 'reach',
  },
  viral: {
    sortKey: 'viral',
    localizedKey: 'viral',
  },
  uploaded: {
    sortKey: 'uploaded',
    localizedKey: 'uploadedContent',
  },
}

export const COMPARE_SORTING_TYPES = {
  username: {
    sortKey: 'username',
    localizedKey: 'username',
  },
  followers: {
    sortKey: 'followers',
    localizedKey: 'followers',
  },
  viewsPost: {
    sortKey: 'impressions',
    localizedKey: 'viewsPerPost',
  },
  engagement: {
    sortKey: 'engagementMean',
    localizedKey: 'engagement',
  },
  profileScore: {
    sortKey: 'profileScore.overall', // TODO: for tiktok it will be 'profileScore'
    localizedKey: 'qualityScore',
  },
  audienceType: {
    sortKey: 'audienceAnalysis.audienceType.realPeoplePercentage', //by real people number
    localizedKey: 'audienceType',
  },
}
