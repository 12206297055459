import { Tooltip, styled as muiStyled, tooltipClasses } from '@mui/material'
import styled from 'styled-components'

export const UnlockedInfluencers = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  border-radius: 26px;
  background-color: ${props => props.theme.colors.lightBrown};
  white-space: nowrap;
  margin-top: 15px;
`

export const IconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
`

export const UnlockedInfluencersText = styled.span`
  font-size: 13px;
  line-height: 16px;
  color: ${props => props.theme.colors.brown};
`

export const Label = styled.span`
  white-space: nowrap;
  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
  color: ${props => props.theme.colors.mauve};
`

export const CollectionUsersWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .pagination-wrapper {
    padding: 20px 0;
  }
`

export const CollectionUsersResultsWrapper = styled.div`
  flex: 1;
  margin-top: 15px;
`

export const TabsWrapper = styled.div`
  padding: 10px 20px;
  border-radius: 13px;
  background-color: ${props => props.theme.colors.offWhite};
  display: flex;
  align-items: center;
  justify-content: space-between;

  .tabs {
    justify-content: flex-start;
  }

  .tab {
    padding: 7px 15px;

    &_is-active {
      padding: 8px 16px;
    }

    &_is-not-active:hover {
      padding: 8px 16px;
    }
  }
  .tabs-end {
    display: flex;
    align-items: center;
  }
`

export const LightTooltip = muiStyled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: '#442424',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))
