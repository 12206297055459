import styled from 'styled-components'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 15px;
  grid-row-gap: ${props => props.smallGridRowGap ? '25px' : '50px'};
  padding-top: 30px;
`

export const GridItem = styled.div`
  position: relative;
`
