import * as Yup from 'yup'

export const createCampaignSchema = Yup.object().shape({
  // campaignCurrency: Yup.mixed().when('trackPricing', {
  //   is: isUnlimitedCampaign => isUnlimitedCampaign === true || isUnlimitedCampaign === 'true',
  //   then: schema =>
  //     schema.required('Currency is Required').test('type', 'Currency is Required', value => {
  //       return typeof value === 'string' && value !== ''
  //     }),
  // }),
  // startAsInactive: Yup.mixed()
  //   .required('Campaign start immediately is Required')
  //   .test('type', 'Campaign start immediately is Required', value => {
  //     return typeof value === 'boolean'
  //   }),
  // brandAccountIncludeAll: Yup.mixed()
  //   .required('Social media account for your brand is Required')
  //   .test('type', 'Social media account for your brand is Required', value => {
  //     return typeof value === 'boolean' || typeof value === null
  //   }),
  // campaignDescription: Yup.string(),
  // isUnlimitedCampaign: Yup.mixed()
  //   .required('Time Period is Required')
  //   .test('type', 'Time Period is Required', value => {
  //     return typeof (value === 'boolean' || typeof value === 'string') && value !== ''
  //   }),
  // startingDate: Yup.mixed().when('isUnlimitedCampaign', {
  //   is: isUnlimitedCampaign => isUnlimitedCampaign === false || isUnlimitedCampaign === 'false',
  //   then: schema =>
  //     schema
  //       .required('Starting Date is Required')
  //       .test('type', 'Starting Date  is Required', value => {
  //         return typeof (value === 'object' || typeof value === 'string') && value !== ''
  //       }),
  // }),
  // endingDate: Yup.mixed().when('isUnlimitedCampaign', {
  //   is: isUnlimitedCampaign => isUnlimitedCampaign === false || isUnlimitedCampaign === 'false',
  //   then: schema =>
  //     schema.required('Ending Date is Required').test('type', 'Ending Date  is Required', value => {
  //       return typeof (value === 'object' || typeof value === 'string') && value !== ''
  //     }),
  // }),
  // trackedFormats: Yup.mixed()
  //   .required('Tracking Formats is Required')
  //   .test('type', 'Tracking Formats is Required', value => {
  //     return typeof (value === 'object' || typeof value === 'string') && value !== ''
  //   }),
  // trackBrandAccounts: Yup.mixed()
  //   .required('Track Brand Accounts is Required')
  //   .test('type', 'Track Brand Accounts is Required', value => {
  //     return typeof (value === 'boolean' || typeof value === 'string') && value !== ''
  //   }),
  // trackReachOnlyManually: Yup.mixed()
  //   .required('Track Reach Manually is Required')
  //   .test('type', 'Track Reach Manually is Required', value => {
  //     return typeof (value === 'boolean' || typeof value === 'string') && value !== ''
  //   }),
  // trackSponsoredContent: Yup.mixed()
  //   .required('Track Non-Organic Metrics is Required')
  //   .test('type', 'Track Non-Organic Metrics is Required', value => {
  //     return typeof (value === 'boolean' || typeof value === 'string') && value !== ''
  //   }),
  // trackPricing: Yup.mixed()
  //   .required('Include Influencer Costs & Pricing is Required')
  //   .test('type', 'Include Influencer Costs & Pricing is Required', value => {
  //     return typeof (value === 'boolean' || typeof value === 'string') && value !== ''
  //   }),
})
