import React from 'react'
import { MenuItem, Select } from '@mui/material'
import { COLLECTION_VIEW_MODE } from '../../../constants/collections'
import useTranslation from '../../../localization/useTranslation'

export const TypeCollectionView = props => {
  const {
    typeCollectionView,
    onChangeTypeViewCollection,
    onHandleOpenViewType,
    isActiveFirstTab,
    airTableLink
  } = props

  const { labelStrings } = useTranslation()

  return (
    <Select
      size="small"
      value={typeCollectionView}
      onChange={onChangeTypeViewCollection}
      onOpen={onHandleOpenViewType}
      autoWidth
      sx={{
        mr: 2,
        maxHeight: '35px',
        color: isActiveFirstTab ? '#FFF' : '#BC9797',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '15px',
        fontFamily: 'Inter, sans-serif',
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: isActiveFirstTab ? 'transparent' : '#CBB3B3',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: isActiveFirstTab ? 'transparent' : '#CBB3B3',
        },
        '.MuiSvgIcon-root ': {
          fill: isActiveFirstTab ? '#FFF' : '#BC9797',
        },
        background: isActiveFirstTab
          ? 'linear-gradient(90deg,#FFBC00 0.35%,#FF5D5A 100%)'
          : 'transparent',
        '&:hover': {
          background: 'linear-gradient(90deg,#FFBC00 0.35%,#FF5D5A 100%)',
          color: '#FFF',
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '.MuiSvgIcon-root ': {
            fill: '#FFF',
          },
        },
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            bgcolor: '#FFF9F9',
            '& .MuiMenuItem-root': {
              textTransform: 'capitalize',
              fontSize: '15px',
              fontFamily: 'Inter, sans-serif',
              color: '#000000',
              fontWeight: 'bold',
            },
          },
        },
      }}
    >
      <MenuItem value={COLLECTION_VIEW_MODE.detailed}>{labelStrings.detailed}</MenuItem>
      <MenuItem value={COLLECTION_VIEW_MODE.list}>{labelStrings.list}</MenuItem>
      <MenuItem  disabled={!airTableLink} value={COLLECTION_VIEW_MODE.airtable}>Airtable</MenuItem>
    </Select>
  )
}
