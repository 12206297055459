import React from 'react'
import styled from 'styled-components'
import useTranslation from '../../localization/useTranslation'

const LoggedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const TextWrapper = styled.div`
  margin-bottom: 10px;
`

const OkButton = styled.button`
  font-size: 14px;
  line-height: 16px;
  padding: 5px;
  text-transform: uppercase;
  border-radius: 9px;
  border: 1px solid
  background-color: #ffa093;
  color: black;
  width: 25%;
  &:hover {
    filter: brightness(90%);
  }
`

export const LoggedMultipleToast = () => {
  const { labelStrings } = useTranslation()
  return (
    <LoggedWrapper>
      <TextWrapper>
        {labelStrings.loggedMultiplePartFirst}
        <br />
        {labelStrings.loggedMultiplePartLast}
      </TextWrapper>
      <OkButton>OK</OkButton>
    </LoggedWrapper>
  )
}
