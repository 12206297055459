import styled, { css } from 'styled-components'
import { convertHexToRgba } from '../../../utils'
import { GradientButton } from '../../common/buttons/styledButtons'
import SingleSelect from '../../common/selects/SingleSelect'

// Audience Analysis
export const AudienceAnalysisContainer = styled.div`
  position: relative;
`

export const AudienceAnalysisChartsGridItem = styled.div.attrs(() => ({
  className: 'audience-analysis-charts_grid-item',
}))`
  min-height: 400px;
  padding: 40px 0;
  border-bottom: 1px solid ${props => props.theme.colors.lightBrown};
  position: relative;

  &:nth-child(2n) {
    border-left: 1px solid ${props => props.theme.colors.lightBrown};
    padding-left: 50px;
  }

  &:nth-child(5),
  &:nth-child(6) {
    border-bottom: none;
  }
`

export const AudienceAnalysisChartsGrid = styled.div.attrs(() => ({
  className: 'audience-analysis-charts_grid',
}))`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-top: 1px solid
    ${props => (props.isDisabledAnalysis ? props.theme.colors.lightBrown : 'transparent')};

  ${props => {
    if (props.hasSmartMediaValue) {
      return css`
        ${AudienceAnalysisChartsGridItem}:nth-child(1),
        ${AudienceAnalysisChartsGridItem}:nth-child(2) {
          border-top: 1px solid ${props => props.theme.colors.lightBrown};
        }
      `
    }
  }}
`

// Audience Reports Messages
export const AudienceReportMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 50px 0;

  button {
    margin: 10px 0;
  }
`

export const LockIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 12px;
  margin: 10px 0;
`

export const LockedIconWrapper = styled(LockIconWrapper)`
  padding-left: 0;
`

export const AudienceReportMessageTitle = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.brown};
  margin: 10px 0;
`

export const AudienceReportMessageText = styled.div`
  font-size: 15px;
  font-style: normal;
  line-height: 19px;
  color: ${props => props.theme.colors.brown};
  margin: 10px 0;
`

export const AudienceReportNotifyText = styled(AudienceReportMessageText)`
  font-weight: 700;
  color: ${props => props.theme.colors.mauve};
`

export const AudienceReportAvailableInProContainer = styled(AudienceReportMessageContainer)`
  // overlay on Target Audience container
  ${props =>
    props.isOverlay &&
    `
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: ${convertHexToRgba(props.theme.colors.offWhite, 0.7)};
    padding: 25px 0;
  `}
`

// Target Audience
export const TargetAudienceContainer = styled.div`
  padding: 50px 0;

  ${props =>
    props.isDisabled &&
    `
    .progress_inner {
      background: ${props.theme.colors.mediumBrown};
    }
  `}
`

export const TargetAudienceTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`

export const TargetAudienceTitleBorder = styled.div`
  flex: 1;
  height: ${props => (props.isDisabled ? '0px' : '1px')};
  margin: 0 20px;
  background-color: ${props => props.theme.colors.lightBrown};
`

export const TargetAudienceTitle = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.darkBrown};
  text-transform: uppercase;
`

export const ButtonEditValues = styled(GradientButton)`
  margin-left: 20px;
`

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const AudienceGroupsColumn = styled.div`
  display: flex;
  flex-direction: column;

  & + & {
    margin-left: 50px;
  }
`

export const AudenceGroupsContainer = styled(FlexRow)``

export const AudienceGroup = styled.div`
  & + & {
    margin-top: 10px;
  }
`

export const AudienceGroupTitle = styled.div`
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: ${props => props.theme.colors.darkBrown};
  text-transform: uppercase;
  margin-bottom: 2px;
`

export const AudienceGroupValue = styled.div`
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 2px;

  ${props =>
    props.isDisabled
      ? `
    color: ${props.theme.colors.lightBrown};
    background-color: ${props.theme.colors.lightBrown};
    user-select: none;
    margin-bottom: 5px;
  `
      : `
    color: ${props.theme.colors.brown};
  `}
`

export const AudienceGroupValueLabel = styled.span`
  font-weight: normal;
`

export const FollowersTargetGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 30px;
`

export const FollowersTargetGroupPercent = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 34px;

  ${props =>
    props.isDisabled
      ? `
    color: ${props.theme.colors.lightBrown};
    background-color: ${props.theme.colors.lightBrown};
    user-select: none;
    margin-bottom: 5px;
  `
      : `
    ${props.theme.getTextGradientStyles()}
  `}
`

export const FollowersTargetGroupNumber = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;

  ${props =>
    props.isDisabled
      ? `
    color: ${props.theme.colors.lightBrown};
    background-color: ${props.theme.colors.lightBrown};
    user-select: none;
  `
      : `
    ${props.theme.getTextGradientStyles()}
  `}
`

// Target Audience Settings Overlay
export const TargetAudienceSettingsOverlay = styled.div`
  position: absolute;
  top: 100px;
  left: 100px;
  padding: 40px 30px;
  z-index: 3;
  background-color: ${props => props.theme.colors.white};
  border: 2px solid ${props => props.theme.colors.mediumBrown};
  border-radius: 17px;
`

export const TargetAudienceSettingsGroup = styled.div`
  padding-bottom: 30px;
`

export const TargetAudienceSettingsGroupTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`

export const TargetAudienceSettingsGroupTitleBorder = styled.div`
  flex: 1;
  height: 0.5px;
  margin-left: 10px;
  background-color: ${props => props.theme.colors.mauve};
`

export const TargetAudienceSettingsGroupTitle = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: ${props => props.theme.colors.darkBrown};
  text-transform: uppercase;
`

const resetDefaultButtonStyles = css`
  border: none;
  background-color: transparent;
`

export const CountryInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const AddButton = styled.button`
  ${resetDefaultButtonStyles}
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: -5px;
`

export const ContryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 40px;

  & + & {
    margin-top: 10px;
  }
`

export const CountryValue = styled.div`
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: ${props => props.theme.colors.brown};
`

export const RemoveButton = styled.button`
  ${resetDefaultButtonStyles}
  font-size: 10px;
  line-height: 12px;
  color: ${props => props.theme.colors.darkBrown};
  text-transform: uppercase;
  text-decoration: underline;
`

export const SettingsButtonsWrapper = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  button + button {
    margin-left: 10px;
  }
`

export const CurrencySelect = styled(SingleSelect)`
>div {
  width: 200px;
  margin-left: 10px;
  background: ${props => props.theme.color.white};
  border: ${props => (props.isEditDetails ? '0.5px solid #9b9b9b' : 'none')};
  padding: 5px 0px;
  border-radius: 13px !important;
  ${props =>
    props.disabled &&
    props.isEditDetails &&
    `
    background: #EBEBEB;
  `}
}
font-size: 15px;
color: ${props => props.theme.color.mineShaft};
${props => !props.isValid && `border-color: ${props.theme.primaryColor};`}
${props => props.disabled && `pointer-events: none;`}
${props =>
  props.withPrefix &&
  `
  padding: 0;
  padding-left: ${props.isEditDetails ? props.prefixWidth || 0 : 0}px;   
  `}`

export const AudienceMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.mightBeOutdated ? 'center': 'end'};
`
export const AudienceMessageIsUpToDate = styled.div`
  width: fit-content;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: ${props => props.theme.color.white};
  background-color: ${props => props.theme.colors.green}
  padding: 6px 15px;
  border-radius: 12px;
  text-align: center;
`
export const AudienceMessageIsOutdated = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: ${props => props.theme.color.white};
  background-color: ${props => props.theme.colors.salmon};
  text-align: center;
  padding: 6px 0;
  border-radius: 12px;
`