import React from 'react'
import { LoadingButton } from '@mui/lab'
import { Grid, Box, Stack, Typography, MenuItem } from '@mui/material'
import { RHFTextField, RHFRadioGroup, RHFSelect } from '../../../components/hook-form'
import FormProvider from '../../../components/hook-form/FormProvider'
import { StyledButton, StyledHeading, StyledSubheader } from './styles'
import useTranslation from '../../../../localization/useTranslation'

export const Fields = props => {
  const {
    methods,
    onSubmit,
    handleSubmit,
    isSubmitting,
    handleClose,
    fieldValues,
    onShowDeleteCampaignModal,
    onShowArchiveCampaignModal,
    handleOpenEmvModal,
  } = props

  const { labelStrings } = useTranslation()
  const isTrackingPrice = fieldValues('trackingPrice')

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3} sx={{ mt: -2 }}>
        <Grid item md={12}>
          <Typography sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '24px', mb: 2 }}>
            {labelStrings.campaignSettingsObj.setUpYourCampaign}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <RHFTextField sx={{ mb: 3 }} name="campaignName" label={labelStrings.campaignName} />
          </Box>
        </Grid>

        <Grid item xs={12} md={12}>
          <Box sx={{ mt: 0 }}>
            <StyledHeading sx={{ mb: '5px' }}>
              {' '}
              {labelStrings.campaignSettingsObj.additionalSettings}
            </StyledHeading>

            <Box sx={{ mt: 1 }}>
              <StyledSubheader sx={{ fontWeight: 600 }}>
                {labelStrings.campaignSettingsObj.trackReach}
              </StyledSubheader>
              <StyledSubheader>
                {labelStrings.campaignSettingsObj.trackReachSubtitle}
              </StyledSubheader>
              <RHFRadioGroup
                name="trackReachOnlyManually"
                row
                label=""
                sx={{ marginLeft: 1 }}
                options={[
                  { value: false, label: labelStrings.campaignSettingsObj.automatedAndManually },
                  {
                    value: true,
                    label: labelStrings.campaignSettingsObj.onlyManually,
                  },
                ]}
              />
            </Box>

            <Box sx={{ mt: 1 }}>
              <StyledSubheader sx={{ fontWeight: 600 }}>
                {labelStrings.campaignSettingsObj.trackNonOrganic}
              </StyledSubheader>
              <StyledSubheader>
                {labelStrings.campaignSettingsObj.trackNonOrganicSubtitle}
              </StyledSubheader>
              <RHFRadioGroup
                name="trackSponsoredContent"
                row
                label=""
                sx={{ ml: 1 }}
                options={[
                  { value: true, label: labelStrings.yes },
                  { value: false, label: labelStrings.no },
                ]}
              />
            </Box>

            <Box sx={{ mt: 1, marginBottom:'50px' }}>
              <StyledSubheader sx={{ fontWeight: 600 }}>
                {labelStrings.campaignSettingsObj.includeCostPricing}
              </StyledSubheader>
              <StyledSubheader>
                {labelStrings.campaignSettingsObj.includeCostPricingSubtitle}
              </StyledSubheader>
              <RHFRadioGroup
                name="trackPricing"
                row
                label=""
                sx={{ ml: 1 }}
                options={[
                  { value: true, label: labelStrings.yes },
                  { value: false, label: labelStrings.no },
                ]}
              />

              <Box sx={{ width: '280px', marginTop:'20px' }}>
                <StyledSubheader sx={{ fontWeight: 600, marginBottom:'20px' }}>
                    {labelStrings.campaignSettingsObj.selectCurrency}
                  </StyledSubheader>
                <RHFSelect
                  name="campaignCurrency"
                  label={labelStrings.selectCurrency}
                  InputLabelProps={{ shrink: true }}
                  disabled={isTrackingPrice === 'false' || isTrackingPrice === false}
                  size="small"
                >
                  {[
                    { value: 'EUR', label: 'EUR' },
                    { value: 'USD', label: 'USD' },
                  ]?.map(currency => (
                    <MenuItem key={currency.value} value={currency.value}>
                      {currency.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Box>

              <Box sx={{ my: 3 }}>
                <Stack spacing={0.5}>
                  <StyledSubheader sx={{ fontWeight: 600 }}>
                    {labelStrings.campaignSettingsObj.adjustEMVCalc}
                  </StyledSubheader>

                  <StyledSubheader>
                    {labelStrings.campaignSettingsObj.adjustEMVCalcSubtitle}
                  </StyledSubheader>
                  <StyledButton
                    variant="outlined"
                    sx={{ fontWeight: 'bold', textTransform: 'capitalize', maxWidth: '260px' }}
                    onClick={handleOpenEmvModal}
                  >
                    {labelStrings.campaignSettingsObj.adjustEMVCalc}
                  </StyledButton>
                </Stack>
              </Box>
            </Box>
          </Box>

          <Stack
            direction="row"
            spacing={1.5}
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 3}}
          >
            <Stack spacing={1.5} direction="row" alignItems="center">
              <StyledButton
                variant="outlined"
                sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                onClick={onShowDeleteCampaignModal}
              >
                {labelStrings.deleteCampaign}
              </StyledButton>
              <StyledButton
                variant="outlined"
                sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                onClick={onShowArchiveCampaignModal}
              >
                {labelStrings.archiveCampaign}
              </StyledButton>
            </Stack>

            <Stack direction="row" spacing={1.5} alignItems="center">
              <StyledButton variant="outlined" sx={{ fontWeight: 'bold' }} onClick={handleClose}>
                {labelStrings.cancel}
              </StyledButton>

              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {labelStrings.saveChanges}
              </LoadingButton>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
