import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useTranslation from '../../../localization/useTranslation'
import ProgressBar from '../../common/progressBar'
import { formatDateToDDMMYYY, formatUnixToMDY } from '../../../utils'

const TokenSection = styled.div`
  min-height: 80px;
  padding: 15px 0;
  background: ${props => props.theme.color.kabul};
  color: ${props => props.theme.color.white};
  ${props => props.theme.flex.centerAll}
  flex-direction: column;
  font-size: 10px;
  text-transform: uppercase;
  font-family: ${props => props.theme.fontFamilyInter};
  letter-spacing: 0.5px;
  font-weight: 600;
  ${props =>
    props.isDisabled &&
    `
    opacity: 0.5;
  `}
`

const TokensReset = styled.div`
  text-transform: initial;
  width: 90%;
  margin-top: 10px;
`

const Trial = styled.div`
  text-transform: none;
  font-size: 10px;
  ${props => props.theme.flex.centerAll}
  flex-direction: column;
  text-align: center;
  padding: 10px 5px 0 5px;
  & .click_trial_activate {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
`

const TokensRemainingStat = ({
  tokensName = '',
  tokensLimit = 20,
  audienceTokensMonthly = 0,
  currentTokens = 0,
  tokensRemain = 0,
  TrialComponent = null,
  tokensResetDate = 0,
}) => {
  const { labelStrings } = useTranslation()
  return (
    <TokenSection>
      <div>{tokensName}</div>
      <ProgressBar current={currentTokens} limit={tokensLimit} />

      <div>
        {tokensRemain} {labelStrings.remaining} ({labelStrings.of} {tokensLimit})
      </div>
      {tokensResetDate && (
        <TokensReset>
          {/*audienceTokensMonthly}{' '*/}
          {labelStrings.tokensWillBeAddedOn}{' '} 
          {formatDateToDDMMYYY({ dateStr: new Date(tokensResetDate).toString() })}
        </TokensReset>
      )}
      {TrialComponent && <Trial>{TrialComponent}</Trial>}
    </TokenSection>
  )
}

TokensRemainingStat.propTypes = {
  tokensName: PropTypes.string,
  tokensLimit: PropTypes.number,
  audienceTokensMonthly: PropTypes.number,
  currentTokens: PropTypes.number,
  tokensRemain: PropTypes.number,
  tokensResetDate: PropTypes.number,
  TrialComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.instanceOf(null)]),
}

export default TokensRemainingStat
