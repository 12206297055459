import React, { memo, useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useTranslation from '../../localization/useTranslation'
import { withReduxActions } from '../../hocs/withRedux'
import { sortExploreRecentSearchResults, sortSearchResults } from '../../redux/ducks/searchDucks'
import {
  //userGrantSelector,
  selectedPlatformSelector,
  currentSearchQuerySortingSelector,
  currentSearchQueryKeywordsSelector,
  currentSearchQueryCategoriesSelector,
  currentRecentSearchQuerySortingSelector,
  currentRecentSearchQueryKeywordsSelector,
  currentRecentSearchQueryCategoriesSelector,
} from '../../redux/selectors'

import {
  SEARCH_CONTENT_SORTING_TYPES,
  SEARCH_SORTING_TYPES,
  SORTING_DIRECTIONS,
  SORTING_DIRECTIONS_SHORT_TO_LONG_NAMES,
} from '../../constants/sortings'
import { parseSearchQuerySorting, findSocialPlatformIndex } from '../../utils'

import SortingCommonTool from './SortingCommonTool'
import { SearchSortingWrapper } from '../common/styledWrappers'

const SearchResultsSortingTool = ({
  sortSearchResults,
  sortExploreRecentSearchResults,
  isFullWidth,
  isContent,
  isExplore,
  isDownloadSearchResults
}) => {
  const { labelStrings, currentLang } = useTranslation()

  const sortingTypes = isContent ? SEARCH_CONTENT_SORTING_TYPES : SEARCH_SORTING_TYPES

  const currentPlatform = useSelector(selectedPlatformSelector)

  const recentSearchCurrentQuerySorting = useSelector(currentRecentSearchQuerySortingSelector)
  const recentSearchCurrentQueryKeywords = useSelector(currentRecentSearchQueryKeywordsSelector)
  const recentSearchCurrentQueryCategories = useSelector(currentRecentSearchQueryCategoriesSelector)

  const searchCurrentQuerySorting = useSelector(currentSearchQuerySortingSelector)
  const searchCurrentQueryKeywords = useSelector(currentSearchQueryKeywordsSelector)
  const searchCurrentQueryCategories = useSelector(currentSearchQueryCategoriesSelector)

  const currentQuerySorting = isExplore
    ? recentSearchCurrentQuerySorting
    : searchCurrentQuerySorting
  const currentQueryKeywords = isExplore
    ? recentSearchCurrentQueryKeywords
    : searchCurrentQueryKeywords
  const currentQueryCategories = isExplore
    ? recentSearchCurrentQueryCategories
    : searchCurrentQueryCategories

  const allowSorting = true //!!enable_search || !!package_advanced

  const [currentSearchSorting, setCurrentSearchSorting] = useState({
    sortKeyObj: {
      value: '',
      label: '',
    },
    sortDirection: '',
  })

  const _figureOutDefaultSorting = () => {
    return {
      value:
        currentQueryKeywords || currentQueryCategories
          ? sortingTypes.relevance.sortKey
          : sortingTypes.profileScore.sortKey,
      label:
        currentQueryKeywords || currentQueryCategories
          ? labelStrings[sortingTypes.relevance.localizedKey]
          : labelStrings[sortingTypes.profileScore.localizedKey],
    }
  }

  const _figureOutDefaultSortingContent = () => {
    return {
      value: sortingTypes.reach.sortKey,
      label: labelStrings[sortingTypes.reach.localizedKey],
    }
  }

  useEffect(() => {
    const {
      sortDirection: currentSortShortDirection,
      sortKey: currentSortKey,
    } = parseSearchQuerySorting({ querySorting: currentQuerySorting })

    const findInitialKey = !!Object.values(sortingTypes)?.find(
      key => key.sortKey === currentSortKey
    )
    const initialSortKey = findInitialKey ? currentSortKey : ''

    if (
      currentSortShortDirection !== currentSearchSorting.sortDirection ||
      initialSortKey !== currentSearchSorting.sortKeyObj.value
    ) {
      const defaultSorting = isContent
        ? _figureOutDefaultSortingContent()
        : _figureOutDefaultSorting()

      setCurrentSearchSorting({
        sortDirection: currentSortShortDirection
          ? SORTING_DIRECTIONS_SHORT_TO_LONG_NAMES[currentSortShortDirection]
          : SORTING_DIRECTIONS.descend,
        sortKeyObj: {
          value: initialSortKey ? sortingTypes[initialSortKey].sortKey : defaultSorting.value,
          label: initialSortKey
            ? labelStrings[sortingTypes[initialSortKey].localizedKey]
            : defaultSorting.label,
        },
      })
    }
  }, [currentQuerySorting, currentQueryKeywords, currentQueryCategories])

  const findPlatformIndex = useCallback(() => {
    return findSocialPlatformIndex(currentPlatform)
  }, [currentPlatform])

  const prepareSortingOptions = useCallback(() => {
    return Object.values(sortingTypes).reduce((optionsArr, sortType) => {
      optionsArr.push({
        value: sortType.sortKey,
        label: labelStrings[sortType.localizedKey],
      })
      return optionsArr
    }, [])
  }, [currentLang])

  const sortingChangeHandler = newSortValue => {
    if (!allowSorting) return
    const { value: newSortKey = sortingTypes.profileScore.sortKey } = newSortValue || {}
    if (newSortKey === currentSearchSorting.sortKeyObj.value) return // do nothing when choose current sorting
    isExplore
      ? sortExploreRecentSearchResults({
          sortKey: newSortKey,
          sortDirection: SORTING_DIRECTIONS.descend,
        })
      : sortSearchResults({
          sortKey: newSortKey,
          sortDirection: SORTING_DIRECTIONS.descend,
        })
  }

  const sortingDirectionHandler = newSortDirection => () => {
    if (!allowSorting) return
    if (newSortDirection === currentSearchSorting.sortDirection) return // do nothing when choose same sort direction
    isExplore
      ? sortExploreRecentSearchResults({
          sortKey: currentSearchSorting.sortKeyObj.value,
          sortDirection: newSortDirection,
        })
      : sortSearchResults({
          sortKey: currentSearchSorting.sortKeyObj.value,
          sortDirection: newSortDirection,
        })
  }

  return (
    <SearchSortingWrapper isContent={isContent}>
      <SortingCommonTool
        sortToolId="search_sorting"
        sortingOptions={prepareSortingOptions()}
        currentSortingValue={currentSearchSorting.sortKeyObj}
        currentSortingDirection={currentSearchSorting.sortDirection}
        socialPlatformIndex={findPlatformIndex()}
        changeSortingHandler={sortingChangeHandler}
        changeSortingDirectionHandler={sortingDirectionHandler}
        isDisabled={!allowSorting}
        isFullWidth={isFullWidth}
        isContent={isContent}
        isDownloadSearchResults={isDownloadSearchResults}
      />
    </SearchSortingWrapper>
  )
}

SearchResultsSortingTool.propTypes = {
  sortSearchResults: PropTypes.func,
  isFullWidth: PropTypes.bool,
  isContent: PropTypes.bool,
  isExplore: PropTypes.bool,
  isDownloadSearchResults: PropTypes.bool
}

export default withReduxActions({ sortSearchResults, sortExploreRecentSearchResults })(
  memo(SearchResultsSortingTool)
)
