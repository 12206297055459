import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import CommonMultiRangeSlider from './CommonMultiRangeSlider'
import LinkToFaqQuestion from '../../faqs/LinkToFaqQuestion'
import {
  SliderWrapper,
  TitleWrapper,
  SliderTitle,
  CurrentValueLabel,
  AudienceMultiSliderWrapper,
} from '../styledWrappers/audienceSubSearchStyles'

const AudienceSubSearchMultiRangeSlider = ({
  ranges,
  title = '',
  updateHandler,
  values = null,
  faqQuestionId = '',
  usePresentionalLabels = false,
}) => {
  const DEFAULT_VALUES = [ranges.mapping[0], ranges.mapping[ranges.mapping.length - 1]]

  const currentValuesLabel = useMemo(() => {
    if (!values || values.length > 2) return ''
    if (usePresentionalLabels) {
      const [min, max] = values
      const minIdx = ranges.mapping.findIndex(v => v === min)
      const maxIdx = ranges.mapping.findIndex(v => v === max)
      return `${ranges.presentationMapping[minIdx]} - ${ranges.presentationMapping[maxIdx]}`
    }
    return `${values[0]} - ${values[1]}`
  }, [values])

  return (
    <SliderWrapper isSmaller>
      {currentValuesLabel && (
        <CurrentValueLabel customTop={16}>{currentValuesLabel}</CurrentValueLabel>
      )}

      <TitleWrapper putLower>
        <SliderTitle>{title}</SliderTitle>
        <LinkToFaqQuestion
          faqQuestionId={faqQuestionId}
          linkPositions={{ top: 9, right: -28 }}
          tipIconSize={14}
        />
      </TitleWrapper>
      <AudienceMultiSliderWrapper>
        <CommonMultiRangeSlider
          id="audience_ages_range"
          values={values || DEFAULT_VALUES}
          ranges={ranges}
          showAllRangeSteps
          onChange={updateHandler}
        />
      </AudienceMultiSliderWrapper>
    </SliderWrapper>
  )
}

AudienceSubSearchMultiRangeSlider.propTypes = {
  ranges: PropTypes.object.isRequired,
  title: PropTypes.string,
  updateHandler: PropTypes.func,
  values: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.instanceOf(null)]),
  faqQuestionId: PropTypes.string,
  usePresentionalLabels: PropTypes.bool,
}

export default AudienceSubSearchMultiRangeSlider
