import styled from 'styled-components'
import TextareaAutosize from 'react-textarea-autosize'

// Private Notes
export const PrivateNotesContainer = styled.div`
  ${props => props.isDisabled && props.theme.disabled};
  border: ${props => (props.hasComments ? `0.5px solid ${props.theme.colors.lightBrown}` : 'none')};
  box-sizing: border-box;
  border-radius: 12px;
  .gradient-transparent-button {
    display: block;
    margin: 0 auto 20px auto;
  }
  .note__container {
    ${props =>
      props.hasComments &&
      `
      width: calc(100% + 1px);
      margin: -0.5px -0.5px 0 -0.5px;
    `}
  }
`

// Private Note Textarea
export const NoteTextareaWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`

export const NoteTextarea = styled(TextareaAutosize)`
  width: 100%;
  flex: 1;
  border: none;
  resize: none;
  outline: none;
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 10px;
  color: ${props => props.theme.colors.brown};
  overflow: hidden;
  & + .gradient-transparent-button {
    margin: 0;
  }
`

export const PrivateNoteList = styled.div`
  padding: 20px 30px 45px 30px;
  min-height: 140px;
  max-height: 300px;
  overflow-y: auto;
  ${props => props.theme.scrollbarStyles}
`

// Private Note
export const PrivateNoteItem = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.lightBrown};
  border-radius: 10px;
  position: relative;
  padding: 10px 20px;
  & + & {
    margin-top: 15px;
  }
`

export const PrivateNoteDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 10px;
`

export const PrivateNoteDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  & + & {
    margin-left: 20px;
  }
`

export const PrivateNoteDetailsLabel = styled.span`
  color: ${props => props.theme.colors.mauve};
  margin-right: 5px;
`

export const PrivateNoteFrom = styled.span`
  color: ${props => props.theme.colors.brown};
  font-weight: bold;
`

export const PrivateNoteDate = styled.span`
  color: ${props => props.theme.colors.brown};
`

export const PrivateNoteText = styled.div`
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.brown};
`

export const PrivateNoteActionsButton = styled.button`
  position: absolute;
  top: 10px;
  right: 15px;
  margin: 0;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
`

export const PrivateNotePinIndicator = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
`

export const PrivateNoteEditingButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: -5px;
  button + button {
    margin-left: 15px;
  }
`

// Private Note Actions
export const PrivateNoteActionsOverlay = styled.div`
  position: absolute;
  top: -15px;
  left: calc(100% - 40px);
  transform: translateX(-100%);
  min-width: 150px;
  width: max-content;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.mediumBrown};
  z-index: 5;
  overflow: hidden;
`

export const PrivateNoteAction = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  background-color: ${props => props.theme.colors.darkBrown};
  &:hover {
    background-color: ${props => props.theme.colors.mauve};
  }
  & + & {
    border-top: 1px solid ${props => props.theme.colors.mediumBrown};
  }
`

export const PrivateNoteActionText = styled.div`
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.offWhite};
  margin-left: 10px;
`

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`