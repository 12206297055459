import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Stack,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Divider,
  Button,
} from '@mui/material'
import PropTypes from 'prop-types'
import { validateEmail } from '../../utils'
import useTranslation from '../../localization/useTranslation'
import { GoogleLogin } from '@react-oauth/google'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { useDispatch } from 'react-redux'
import { setUserAuthError } from '../../redux/ducks/userDucks'

const LoginFormWrapper = styled.form`
  ${props => props.theme.flex.centerAll};
  flex-direction: column;
  justify-content: space-around;
  margin-top: 35px;
  width: 85%;
  height: 280px;
  position: relative;
  border-radius: 9px;
`

const NotValidLabel = styled.span`
  position: absolute;
  top: 0;
  right: ${props => props.right || -10}px;
  font-size: 11px;
  color: ${props => props.theme.primaryColor};
`

const BackaendLoginFailLabel = styled(NotValidLabel)`
  font-size: 14px;
  top: -25px;
  left: 10px;
`
const StylesFormTitle = {
  fontWeight: 'bold',
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '4.9px',
  textTransform: 'uppercase',
  textAlign: 'center',
  padding: '10px 0',
}

const StyledLoginBtn = {
  fontWeight: 'bold',
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '3.5px',
  color: '#ffffff',
  width: '100%',
  height: '40px',
  textTransform: 'uppercase',
  border: 'none',
  background:
    'transparent linear-gradient(89deg, #FF726F 0%, #FFCA55 120%) 0% 0% no-repeat padding-box',
}

const LoginForm = ({ loginUser }) => {
  const dispatch = useDispatch()
  const { labelStrings } = useTranslation()

  const [formDataObj, setFormDataObj] = useState({
    email: '',
    password: '',
    isValidEmail: true,
    isValidPassword: true,
    showPassword: false,
    backendLoginFail: false,
  })

  const changeFormDataObj = newValueObj => setFormDataObj({ ...formDataObj, ...newValueObj })

  const onEmailChange = ({ target }) => changeFormDataObj({ email: target.value })
  const onPasswordChange = ({ target }) => changeFormDataObj({ password: target.value })
  const toggleShowPassword = () => changeFormDataObj({ showPassword: !formDataObj.showPassword })

  const validatePassword = () => !!formDataObj.password

  const resetValidation = ({ type }) =>
    (!formDataObj[type] || formDataObj.backendLoginFail) &&
    changeFormDataObj({ [type]: true, backendLoginFail: false })

  const resetEmailValidation = () => resetValidation({ type: 'isValidEmail' })
  const resetPasswordValidation = () => resetValidation({ type: 'isValidPassword' })

  const onSubmit = async e => {
    e.preventDefault()
    if (formDataObj.backendLoginFail) return
    const isValidEmail = !!formDataObj.email && validateEmail(formDataObj.email.trim())
    const isValidPassword = !!formDataObj.password && validatePassword()

    if (!isValidEmail || !isValidPassword) {
      changeFormDataObj({ isValidEmail, isValidPassword })
      return
    }


    const { email, password } = formDataObj
    dispatch(loginUser({ email: email.toLowerCase().trim(), password, method: 'chargebee' }))
  }

  const onSubmitGoogle = async googleData => {
    const token = googleData.credential
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    const payload = JSON.parse(jsonPayload)
    const encryptedCredential = googleData.credential

    const { email } = payload
    dispatch(loginUser({ email: email, password: encryptedCredential, method: 'google' }))
  }

  const errResponseGoogle = response => {
    dispatch(setUserAuthError(!!response))
    // console.log('ERROR WITH GOOGLE SIGNUP', response)
    // send the token to your server for verification
    // fetch('/api/signup', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({ token: response.tokenId }),
    // });
  }

  return (
    <>
      <Typography sx={StylesFormTitle}>{labelStrings.loginToInfludata}</Typography>
      <Divider sx={{ height: '1px', width: '100%', color: 'black' }} />
      <LoginFormWrapper onSubmit={onSubmit}>
        {formDataObj.backendLoginFail && (
          <BackaendLoginFailLabel>{labelStrings.loginFail}</BackaendLoginFailLabel>
        )}

        <TextField
          size="small"
          sx={{ width: '100%' }}
          label={labelStrings.email}
          id="email_input"
          type="text"
          onChange={onEmailChange}
          onFocus={resetEmailValidation}
          value={formDataObj.email}
          error={!formDataObj.isValidEmail}
          helperText={!formDataObj.isValidEmail ? labelStrings.notValidEmail : null}
        />

        <TextField
          size="small"
          sx={{ width: '100%' }}
          label={labelStrings.password}
          id="password_input"
          type={formDataObj.showPassword ? 'text' : 'password'}
          onChange={onPasswordChange}
          onFocus={resetPasswordValidation}
          value={formDataObj.password}
          error={!formDataObj.isValidPassword}
          helperText={!formDataObj.isValidPassword ? labelStrings.passwordCantBeEmpty : null}
          inputProps={{ maxLength: 40 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={toggleShowPassword}
                  onMouseDown={e => e.preventDefault()}
                  edge="end"
                >
                  {formDataObj.showPassword ? <VisibilityOff /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Stack sx={{ py: 1.5 }} justifyContent="center" alignItems="center">
          <Button sx={StyledLoginBtn} variant="contained" type="submit">
            {labelStrings.login}
          </Button>
          <Typography sx={{ py: 0.5 }}>{labelStrings.or}</Typography>
          <GoogleLogin onSuccess={onSubmitGoogle} onError={errResponseGoogle} useOneTap />
        </Stack>
      </LoginFormWrapper>
    </>
  )
}

LoginForm.propTypes = {
  loginUser: PropTypes.func,
}

export default LoginForm
