import React, { memo, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'
import { parseStringToArrOfObjects } from '../../../utils'
import { SOCIAL_PLATFORMS_NAMES, USER_INDEXES } from '../../../constants/appSettings'
import { searchContentTypes, SEARCH_SLIDERS_RANGE } from '../../../constants/search'

import SearchRangeSlidersGroup from '../../common/sliders/SearchRangeSlidersGroup'
import { ClearSearchContainer } from '../../common/styledWrappers'
import { ClearSearchButton } from '../../common/buttons/styledButtons'
import SingleCheckbox from '../../common/checkboxes/SingleCheckbox'
import CheckboxesGroupSocialAppear from '../../common/checkboxes/CheckboxesGroupSocialAppear'
import SingleSelect from '../../common/selects/SingleSelect'
import MultiSelect from '../../common/selects/MultiSelect'
import GrowthRateRangeSlider from '../../common/sliders/GrowthRateRangeSlider'
import AudienceSubSearchContainer from './audienceSubSearch/AudienceSubSearchContainer'
import CheckBoxesGroupAccountType from '../../common/checkboxes/CheckBoxesGroupAccountType'
import {
  BtnWrapper,
  CategoryLabel,
  CategoryWrapperInput,
  KeywordsBtn,
  RoundedWrapper,
  RoundedWrapperTitle,
  WrapperWithPaddings,
} from './styles'
import GoogleLocationSearchContainer from '../../googleLocationByLetters/GoogleLocationSearchContainer'
import MultiSelectWithoutCreatable from '../../common/selects/MultiSelectWithoutCreatable'
import EngagementRateRangeSlider from '../../common/sliders/EngagementRateRangeSlider'

const _KEYWORDS_BTN_VALUE = {
  freeText: 'freeText',
  categories: 'categories',
}

const radiusSelectOptions = [
  { value: '', label: 'Off' },
  { value: '0.1', label: '100m / 320ft' },
  { value: '0.2', label: '200m / 650ft' },
  { value: '0.5', label: '500m / 0.3mi' },
  { value: '1', label: '1km / 0.6mi' },
  { value: '10', label: '10km / 6mi' },
  { value: '50', label: '50km / 30mi' },
  { value: '100', label: '100km / 60mi' },
]

const keywordsCategoriesOptions = [
  { value: 'fashion', label: 'Fashion & Style' },
  { value: 'fitness', label: 'Fitness & Wellness' },
  { value: 'sports', label: 'Athletics & Sports' },
  { value: 'beauty', label: 'Beauty & Cosmetics' },
  { value: 'food', label: 'Food & Drink' },
  { value: 'diet', label: 'Healthy Nutrition & Diet' },
  { value: 'veganism', label: 'Veganism & Vegetarianism' },
  { value: 'travel', label: 'Travel & Adventure' },
  { value: 'interior', label: 'Home & Interior Design' },
  { value: 'tech', label: 'Technology & Gadgets' },
  { value: 'art', label: 'Art & Creativity' },
  { value: 'comedy', label: 'Comedy' },
  { value: 'education', label: 'Education & Learning' },
  { value: 'family', label: 'Parenting & Family' },
  { value: 'media', label: 'Entertainment & Media' },
  { value: 'gaming', label: 'Gaming' },
  { value: 'business', label: 'Business & Finance' },
  { value: 'automotive', label: 'Automotive & Vehicles' },
  { value: 'sustainability', label: 'Sustainability & Environment' },
  { value: 'animals', label: 'Animals & Pets' },
  { value: 'charity', label: 'Charity & Activism' },
  { value: 'politics', label: 'Politics' },
]

const SearchbarMediator = ({
  clearSearch,
  query,
  handlersMap,
  suggestionsKeywords = [],
  selectedPlatform = '',
  permissions = {},
  availableCountriesCities,
  userindex = '',
  grants = {},
  searchType = '',
}) => {
  const { labelStrings, languageOptions, genderOptions, countryOptions } = useTranslation()

  const [btnKwdValue, setBtnKwdValue] = useState(_KEYWORDS_BTN_VALUE.freeText)
  const isFreeText = btnKwdValue === _KEYWORDS_BTN_VALUE.freeText
  const isCategories = btnKwdValue === _KEYWORDS_BTN_VALUE.categories

  useEffect(() => {
    if (query.categories) {
      setBtnKwdValue(_KEYWORDS_BTN_VALUE.categories)
    }
  }, [query])

  const countryCitiesOptions = useMemo(() => {
    if (!query.country || !availableCountriesCities[query.country]) return []
    return availableCountriesCities[query.country].map(city => ({ value: city, label: city }))
  }, [query.country, availableCountriesCities])

  const isTiktokPlatform = selectedPlatform === SOCIAL_PLATFORMS_NAMES.tiktok.name
  const isYouTubePlatform = selectedPlatform === SOCIAL_PLATFORMS_NAMES.youtube.name

  const setValuesWithDefault = (min, max) => [
    min || SEARCH_SLIDERS_RANGE.min,
    max || SEARCH_SLIDERS_RANGE.max,
  ] // values for sliders with default

  const isCreator = searchType === searchContentTypes.CREATOR

  return (
    <>
      {isCreator ? (
        <>
          {isYouTubePlatform && (
            <RoundedWrapper paddingTop={5} paddingBottom={5} platform={selectedPlatform}>
              {/* must include account type select */}
              <CheckBoxesGroupAccountType
                labelTextFirst={labelStrings.shortCreatorsOnly}
                labelTextSecond={labelStrings.regularShortsAndCreators}
                onChange={handlersMap.accountType}
                checked={query.isShortsCreator === '' ? false : true}
                titleText={labelStrings.accountType}
                faqQuestionId="" // TODO: FAQLINK
              />
            </RoundedWrapper>
          )}
          <RoundedWrapper platform={selectedPlatform}>
            {/* country select */}
            <MultiSelect
              id="countrySelect"
              labelText={labelStrings.country}
              value={query.country ? parseStringToArrOfObjects(query.country) : []}
              options={countryOptions}
              onChange={handlersMap.country}
              placeholder={labelStrings.selectMultiCountries}
              isEnabled={true}
              selectedPlatform={selectedPlatform}
              faqQuestionId=""
            />

            {/* cities select */}
            <MultiSelect
              id="citySelect"
              labelText={labelStrings.city}
              value={query.city ? parseStringToArrOfObjects(query.city) : []}
              options={countryCitiesOptions}
              onChange={handlersMap.city}
              placeholder={labelStrings.selectMultiCities}
              isEnabled={!!permissions.allowCitiesSearch}
              selectedPlatform={selectedPlatform}
              upgradeTipText={labelStrings.useCitySearch}
            />
          </RoundedWrapper>

          <RoundedWrapper paddingTop={0} paddingBottom={0} platform={selectedPlatform}>
            {/* followers and plays inline ranges selects */}
            <SearchRangeSlidersGroup
              changeFollowers={handlersMap.followers}
              changePlays={handlersMap.plays}
              changeViews={handlersMap.views}
              changeVideoViews={handlersMap.youtubeVideos}
              changeShortsViews={handlersMap.youTubeShorts}
              changePlaysPerReel={handlersMap.playsPerReel}
              followersValues={setValuesWithDefault(query.followerMin, query.followerMax)}
              playsValues={setValuesWithDefault(query.playsMin, query.playsMax)}
              viewsValues={setValuesWithDefault(query.viewsMin, query.viewsMax)}
              playsPerReelValues={setValuesWithDefault(query.reelPlaysMin, query.reelPlaysMax)}
              videoViewsValues={setValuesWithDefault(query.viewsMin, query.viewsMax)}
              shortsViewValues={setValuesWithDefault(query.shortsPlaysMin, query.shortsPlaysMax)}
              faqQuestionId=""
              selectedPlatform={selectedPlatform}
              query={query}
            />
          </RoundedWrapper>

          <RoundedWrapper platform={selectedPlatform}>
            {/* Language select*/}
            <SingleSelect
              id="languageSelect"
              labelText={labelStrings.language}
              value={languageOptions.find(el => el.value === query.language)}
              options={languageOptions}
              onChange={handlersMap.language}
              placeholder={labelStrings.selectLanguage}
              faqQuestionId=""
            />

            {/* keywords */}
            <>
              <CategoryWrapperInput>
                <CategoryLabel>{labelStrings.keywords}</CategoryLabel>
                <BtnWrapper>
                  <KeywordsBtn
                    onClick={() => setBtnKwdValue(_KEYWORDS_BTN_VALUE.freeText)}
                    isSelected={isFreeText}
                  >
                    free text
                  </KeywordsBtn>
                  <KeywordsBtn
                    isSelected={isCategories}
                    onClick={() => setBtnKwdValue(_KEYWORDS_BTN_VALUE.categories)}
                  >
                    {labelStrings.categoriesAI}
                  </KeywordsBtn>
                </BtnWrapper>
              </CategoryWrapperInput>

              {isFreeText ? (
                <MultiSelect
                  labelText={''}
                  id="keywordsSelect"
                  value={query.keywords ? parseStringToArrOfObjects(query.keywords) : []}
                  options={suggestionsKeywords}
                  onChange={handlersMap.keywords}
                  placeholder={labelStrings.typeYourKeyword}
                  faqQuestionId="80000601848"
                  isEnabled={!!permissions.allowKeywordsSearch}
                  selectedPlatform={selectedPlatform}
                  upgradeTipText={labelStrings.useKeywordsSearch}
                  showMenuOnClick={false}
                />
              ) : (
                <MultiSelectWithoutCreatable
                  id="categoriesSelect"
                  labelText={''}
                  value={query.categories ? parseStringToArrOfObjects(query.categories) : []}
                  options={keywordsCategoriesOptions}
                  onChange={handlersMap.categories}
                  placeholder={labelStrings.pleaseSelectCategories}
                  faqQuestionId="80000601848"
                  isEnabled={!!permissions.allowKeywordsSearch}
                  selectedPlatform={selectedPlatform}
                  upgradeTipText={labelStrings.useKeywordsSearch}
                  showMenuOnClick={true}
                />
              )}
            </>

            {/* Gender select */}
            <SingleSelect
              id="genderSelect"
              labelText={labelStrings.gender}
              value={genderOptions.find(el => el.value === query.gender)}
              options={genderOptions}
              onChange={handlersMap.gender}
              placeholder={labelStrings.selectGender}
              faqQuestionId=""
            />

            {/* Min growth rate range slider */}
            <GrowthRateRangeSlider
              value={query.growthRate}
              faqQuestionId=""
              onChange={handlersMap.growthRate}
              grantMinimumGrowth={grants.minimum_growth}
            />

            {/* Min engagement rate range slider */}
            {!isYouTubePlatform && (
              <EngagementRateRangeSlider
                value={query.engagementRate}
                faqQuestionId=""
                onChange={handlersMap.engagementRate}
                grantMinimumEngagement={true}
              />
            )}
          </RoundedWrapper>

          {!isTiktokPlatform && (
            <RoundedWrapper
              paddingTop={0}
              paddingLeftRight={0}
              paddingBottom={0}
              platform={selectedPlatform}
            >
              {/*search by audience  dropdowned section, only for instagram now*/}
              <AudienceSubSearchContainer
                changeAudienceSearch={handlersMap.audience}
                isEnabled={permissions.allowAudienceSearch}
                userindex={userindex}
                formatOptionLabelCountryFlag
                audience={{
                  audienceCountries: query.audienceCountries,
                  audienceGender: query.audienceGender,
                  audienceRealPeople: query.audienceRealPeople,
                  audienceAgeRanges: query.audienceAgeRanges,
                  audienceLanguages: query.audienceLanguages,
                }}
              />
            </RoundedWrapper>
          )}
          <RoundedWrapper paddingTop={10} platform={selectedPlatform}>
            {/* must include email select */}
            <SingleCheckbox
              onChange={handlersMap.email}
              checked={query.mustIncludeEmail}
              labelText={labelStrings.mustIncludeEmail}
              titleText={labelStrings.email}
              faqQuestionId=""
            />
            {/* appears on select */}
            <CheckboxesGroupSocialAppear
              handleCheckboxes={handlersMap.appearsOn}
              appearsOn={query.appearsOn}
              selectedPlatform={selectedPlatform}
              title={labelStrings.appearOn}
              faqQuestionId=""
            />

            {/* must business search select */}
            {!isTiktokPlatform && (
              <SingleCheckbox
                onChange={handlersMap.businessSearch}
                checked={!!query.businessSearch || false}
                labelText={labelStrings.brandAccounts.brandSearch}
                titleText={labelStrings.brandAccounts.brand}
                faqQuestionId=""
                isDisabled={!permissions.allowBusinessSearch}
                tipText={labelStrings.disabledFunctionInYourPlanTip}
              />
            )}
            <SingleCheckbox
              onChange={handlersMap.hideCreatorsInCollection}
              checked={
                query.hideCreatorsInCollection === 'true'
                  ? true
                  : query.hideCreatorsInCollection === 'false'
                  ? false
                  : !!query.hideCreatorsInCollection
              }
              labelText={labelStrings.hideCreatorsInCollectionLabel}
              faqQuestionId=""
            />
          </RoundedWrapper>
        </>
      ) : (
        <>
          <RoundedWrapper paddingTop={0} paddingLeftRight={0} paddingBottom={0}>
            <RoundedWrapperTitle>Content filter</RoundedWrapperTitle>

            <WrapperWithPaddings>
              {/* keywords */}
              <MultiSelect
                labelText={labelStrings.keywords}
                value={query.keywords ? parseStringToArrOfObjects(query.keywords) : []}
                options={suggestionsKeywords}
                onChange={handlersMap.keywords}
                placeholder={labelStrings.typeYourKeyword}
                faqQuestionId="80000601848"
                isEnabled={!!permissions.allowKeywordsSearch}
                selectedPlatform={selectedPlatform}
                upgradeTipText={labelStrings.useKeywordsSearch}
                showMenuOnClick={false}
              />

              <SearchRangeSlidersGroup
                changeFollowers={handlersMap.followers}
                changePlays={handlersMap.plays}
                changeViews={handlersMap.views}
                changeVideoViews={handlersMap.youtubeVideos}
                changeShortsViews={handlersMap.youTubeShorts}
                changePlaysPerReel={handlersMap.playsPerReel}
                followersValues={setValuesWithDefault(query.followerMin, query.followerMax)}
                playsValues={setValuesWithDefault(query.playsMin, query.playsMax)}
                viewsValues={setValuesWithDefault(query.viewsMin, query.viewsMax)}
                playsPerReelValues={setValuesWithDefault(query.reelPlaysMin, query.reelPlaysMax)}
                videoViewsValues={setValuesWithDefault(query.viewsMin, query.viewsMax)}
                shortsViewValues={setValuesWithDefault(query.shortsPlaysMin, query.shortsPlaysMax)}
                faqQuestionId="" // TODO: FAQLINK
                selectedPlatform={selectedPlatform}
                query={query}
                isCreator={isCreator}
                isContentFilter
              />

              <SingleSelect
                id="languageSelect"
                labelText={labelStrings.language}
                value={languageOptions.find(el => el.value === query.language)}
                options={languageOptions}
                onChange={handlersMap.language}
                placeholder={labelStrings.selectLanguage}
                faqQuestionId=""
              />

              {userindex === USER_INDEXES.instagram && (
                <>
                  <CategoryWrapperInput>
                    <CategoryLabel>{labelStrings.location}</CategoryLabel>
                    <GoogleLocationSearchContainer changeLocation={handlersMap.location} />
                  </CategoryWrapperInput>
                  <SingleSelect
                    id="radiusSelect"
                    labelText={labelStrings.locationRadius}
                    value={radiusSelectOptions.find(
                      el => el.value === query.radiusKM || el.value === `${query.radiusKM}`
                    )}
                    options={radiusSelectOptions}
                    onChange={({ value }) => handlersMap.radiusKM(value)}
                    placeholder={labelStrings.off}
                    isDisabled={!query.placesId}
                    disabledTip={labelStrings.tipSelectLocation}
                    maxMenuHeight={150}
                    captureMenuScroll={false}
                    faqQuestionId=""
                  />
                </>
              )}
            </WrapperWithPaddings>
          </RoundedWrapper>

          <RoundedWrapper paddingTop={0} paddingLeftRight={0} paddingBottom={0}>
            <RoundedWrapperTitle>Creator filter</RoundedWrapperTitle>
            <WrapperWithPaddings>
              {/* country select */}
              <MultiSelect
                id="countrySelect"
                labelText={labelStrings.country}
                value={query.country ? parseStringToArrOfObjects(query.country) : []}
                options={countryOptions}
                onChange={handlersMap.country}
                placeholder={labelStrings.selectMultiCountries}
                isEnabled={true}
                selectedPlatform={selectedPlatform}
                faqQuestionId=""
              />

              {/* Gender select */}
              <SingleSelect
                id="genderSelect"
                labelText={labelStrings.gender}
                value={genderOptions.find(el => el.value === query.gender)}
                options={genderOptions}
                onChange={handlersMap.gender}
                placeholder={labelStrings.selectGender}
                faqQuestionId=""
              />

              <SearchRangeSlidersGroup
                changeFollowers={handlersMap.followers}
                changePlays={handlersMap.plays}
                changeViews={handlersMap.views}
                changeVideoViews={handlersMap.youtubeVideos}
                changeShortsViews={handlersMap.youTubeShorts}
                changePlaysPerReel={handlersMap.playsPerReel}
                followersValues={setValuesWithDefault(query.followerMin, query.followerMax)}
                playsValues={setValuesWithDefault(query.playsMin, query.playsMax)}
                viewsValues={setValuesWithDefault(query.viewsMin, query.viewsMax)}
                playsPerReelValues={setValuesWithDefault(query.reelPlaysMin, query.reelPlaysMax)}
                videoViewsValues={setValuesWithDefault(query.viewsMin, query.viewsMax)}
                shortsViewValues={setValuesWithDefault(query.shortsPlaysMin, query.shortsPlaysMax)}
                faqQuestionId=""
                selectedPlatform={selectedPlatform}
                query={query}
                isCreator={isCreator}
              />

              {/* Min growth rate range slider */}
              <GrowthRateRangeSlider
                value={query.growthRate}
                faqQuestionId=""
                onChange={handlersMap.growthRate}
                grantMinimumGrowth={grants.minimum_growth}
              />
            </WrapperWithPaddings>
          </RoundedWrapper>
        </>
      )}

      <ClearSearchContainer>
        <ClearSearchButton type="button" onClick={clearSearch} userindex={userindex}>
          {labelStrings.clearSearch}
        </ClearSearchButton>
      </ClearSearchContainer>
    </>
  )
}

SearchbarMediator.propTypes = {
  selectedPlatform: PropTypes.string,
  clearSearch: PropTypes.func,
  query: PropTypes.object.isRequired,
  handlersMap: PropTypes.object.isRequired,
  citiesForCountry: PropTypes.array,
  suggestionsKeywords: PropTypes.array,
  permissions: PropTypes.object,
  availableCountriesCities: PropTypes.object,
  userindex: PropTypes.string,
  grants: PropTypes.object,
  searchType: PropTypes.string,
}

export default memo(SearchbarMediator)
