import styled, { css } from 'styled-components'

export const ProfileOverview = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 20px;
`

export const AvatarWrapper = styled.div`
  align-self: center;
  margin-right: 50px;

  .rating_meter {
    circle {
      stroke-width: 7.5;
    }
  }
`

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const commonTextStyles = css`
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.brown};
`
export const FieldLabel = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: ${props => props.theme.colors.mauve};
  text-transform: uppercase;
  margin-bottom: 3px;
`
export const Username = styled.div`
  font-size: 24px;
  line-height: 26px;
  font-weight: bold;
  color: ${props => props.theme.getColorPaletteForPlatform(props.platform).main};
  margin-bottom: 10px;
`

export const DisplayName = styled.div`
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  color: ${props => props.theme.getColorPaletteForPlatform(props.platform).main};
  margin-bottom: 10px;
`

export const Description = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: ${props => props.theme.colors.brown};
  margin-bottom: 10px;
`

export const Contact = styled.a`
  ${commonTextStyles}
  text-decoration: underline;

  &:hover,
  &:active {
    ${commonTextStyles}
  }
`

export const Statistics = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 40px;
  border-bottom: 1px solid ${props => props.theme.colors.lightBrown};
`

export const StatisticsBlock = styled.div`
  flex: 1;
  margin-top: 20px;
`

export const StatisticsBlockWithoutMargin = styled.div`
  flex: 1;
`

export const StatisticsBlockRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`

export const StatisticItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const StatisticsLabel = styled.div`
  ${commonTextStyles}
  margin-right: 5px;
`
export const StatisticsValue = styled.div`
  font-size: 15px;
  line-height: 19px;
  font-weight: bold;
  color: ${props => props.theme.getColorPaletteForPlatform(props.platform).main};
`
