import React from 'react'
import { CreatorTablePdfPage } from '../CreatorTablePdfPage'

export const LastPdfPage = props => {
  const { mentionAccountsAndTimeline, username } = props || {}
  return (
    <>
      <CreatorTablePdfPage
        mentionAccountsAndTimeline={mentionAccountsAndTimeline}
        username={username}
      />
    </>
  )
}
