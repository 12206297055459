import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { InstagramSwitchIcon, TikTokSwitchIcon, YouTubeSwitchIcon } from './icons'
import { SOCIAL_PLATFORMS_NAMES } from '../../../../../constants/appSettings'
import { StyledSocialPlatformTab, StyledSocialPlatformTabs } from './styles'

export const SocialPlatformSwitcher = ({
  currentPlatform = '',
  changeCurrentPlatform,
  changeCreatorsData,
}) => {
  const [tabValue, setTabValue] = useState(currentPlatform || SOCIAL_PLATFORMS_NAMES.instagram.name)

  const onChangeSocialTabs = (e, value) => {
    if (value === tabValue) return
    setTabValue(value)
    changeCurrentPlatform(value)
    changeCreatorsData([])
  }

  useEffect(() => {
    if (tabValue !== currentPlatform) {
      setTabValue(currentPlatform)
    }
  }, [currentPlatform])

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: '4px',
        border: '1px solid rgba(145, 158, 171, 0.32)',
      }}
    >
      <StyledSocialPlatformTabs isCreateOverlap value={tabValue} onChange={onChangeSocialTabs}>
        <StyledSocialPlatformTab
          disabled={false}
          sx={{ marginRight: '5px !important' }}
          icon={
            <InstagramSwitchIcon
              sx={{ fontSize: 22 }}
              color={tabValue === SOCIAL_PLATFORMS_NAMES.instagram.name ? 'primary' : ''}
            />
          }
          aria-label="Instagram"
          value={SOCIAL_PLATFORMS_NAMES.instagram.name}
        />
        <StyledSocialPlatformTab
          disabled={false}
          sx={{ marginRight: '5px !important' }}
          icon={
            <TikTokSwitchIcon
              sx={{ fontSize: 18 }}
              color={tabValue === SOCIAL_PLATFORMS_NAMES.tiktok.name ? 'primary' : ''}
            />
          }
          aria-label="TikTok"
          value={SOCIAL_PLATFORMS_NAMES.tiktok.name}
        />
        <StyledSocialPlatformTab
          disabled={false}
          sx={{ marginRight: '0 !important' }}
          icon={
            <YouTubeSwitchIcon
              sx={{ fontSize: 22 }}
              color={tabValue === SOCIAL_PLATFORMS_NAMES.youtube.name ? 'primary' : ''}
            />
          }
          aria-label="YouTube"
          value={SOCIAL_PLATFORMS_NAMES.youtube.name}
        />
      </StyledSocialPlatformTabs>
    </Box>
  )
}

SocialPlatformSwitcher.propTypes = {
  currentPlatform: PropTypes.string,
  changeCurrentPlatform: PropTypes.func,
  changeCreatorsData: PropTypes.func,
}