import { Skeleton, Stack, Typography } from '@mui/material'
import React from 'react'
import { getNavbarHeight } from '../../../utils'

export const ContentOverviewSkeletonLoader = () => {
  return (
    <Stack sx={{ width: '100%', height: `calc(100vh - ${getNavbarHeight()}px - 55px)`, p: 2 }} spacing={2}>
      {/* top controls section */}
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <Skeleton variant="rounded" animation="wave" width={150} height={40} />
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Skeleton variant="rounded" animation="wave" width={125} height={40} />
            <Typography>–</Typography>
            <Skeleton variant="rounded" animation="wave" width={125} height={40} />
          </Stack>
          <Skeleton variant="rounded" animation="wave" width={150} height={40} />
        </Stack>
        <Skeleton variant="rounded" animation="wave" width={77} height={40} />
      </Stack>

      {/* main content section  title*/}
      <Stack spacing={1}>
        <Skeleton variant="rounded" animation="wave" width={250} height={30} />
        <Skeleton variant="rounded" animation="wave" width={170} height={21} />
        <Skeleton variant="rounded" animation="wave" width={500} height={21} />
      </Stack>

      {/* main content section */}
      <Stack spacing={2} sx={{ width: '100%', height: '100%' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1.5}
          sx={{ width: '100%', height: '100%' }}
        >
          <Skeleton sx={{ width: '100%' }} variant="rounded" animation="wave" height={'100%'} />
          <Skeleton sx={{ width: '100%' }} variant="rounded" animation="wave" height={'100%'} />
          <Skeleton sx={{ width: '100%' }} variant="rounded" animation="wave" height={'100%'} />
          <Skeleton sx={{ width: '100%' }} variant="rounded" animation="wave" height={'100%'} />
          <Skeleton sx={{ width: '100%' }} variant="rounded" animation="wave" height={'100%'} />
        </Stack>
      </Stack>
    </Stack>
  )
}
