import styled from 'styled-components'

export const DescriptionTipLabel = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: ${props => props.theme.colors.salmon};
  text-decoration: underline;
`

export const DescriptionOverlay = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  width: 250px;
  padding: 20px;
  border: 2px solid ${props => props.theme.colors.mediumBrown};
  background-color: ${props => props.theme.colors.white};
  border-radius: 17px;
  z-index: 5;
  cursor: default;
`

export const DescriptionMain = styled.div`
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.brown};
  margin-bottom: 20px;
`

export const DescriptionText = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: ${props => props.theme.colors.darkBrown};
`

export const DescriptionTipContainer = styled.div`
  width: fit-content;
  position: relative;
  cursor: pointer;

  ${DescriptionOverlay} {
    display: none;
  }

  &:hover {
    ${DescriptionOverlay} {
      display: block;
    }
  }
`
