import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import VerticalBarChart from '../../../charts/VerticalBarChart'
import useTranslation from '../../../../localization/useTranslation'
import disabledChartData from '../../../charts/disabledChartsData'

const AudienceAgeGroupsChart = ({
  userindex = '',
  averageBirthyearArray = [],
  isDisabledAnalysis = true,
  followersCount = 0,
  birthyearMedian,
  isForPdf = false,
  isLikersReport = false,
  isSmall = false,
  customHeight
}) => {
  const { labelStrings } = useTranslation()
  const isDataExist = arr => !!arr && !!arr?.length

  const createAudienceAgeGroupsChartData = useCallback(() => {
    if (isDisabledAnalysis || !isDataExist(averageBirthyearArray)) {
      return disabledChartData.disabledVerticalChartData()
    }

    return averageBirthyearArray.reduce((resArr, item) => {
      if (item.birthyear && item.value) {
        resArr.push({
          label: item.birthyear,
          value: item.value,
          followersCount: isLikersReport ? null : (followersCount * item.value) / 100,
          grayed: item.birthyear === '55+',
          valueMale: item.valueMale,
          valueFemale: item.valueFemale,
        })
      }
      return resArr
    }, [])
  }, [averageBirthyearArray, isDisabledAnalysis])

  const createAgeMedian = () => {
    if (isDisabledAnalysis || !isDataExist(averageBirthyearArray)) return ''
    const medianItem = averageBirthyearArray[averageBirthyearArray.length - 1]
    return medianItem.median
      ? `${labelStrings.ageIs} ${medianItem.median}`
      : birthyearMedian
      ? `${labelStrings.ageIs} ${birthyearMedian}`
      : ''
  }

  return (
    <VerticalBarChart
      title={isForPdf ? '' : labelStrings.ageGroups}
      userindex={userindex}
      chartData={createAudienceAgeGroupsChartData()}
      median={createAgeMedian()}
      isDisabledChart={isDisabledAnalysis}
      notEnoughData={!isDisabledAnalysis && !isDataExist(averageBirthyearArray)}
      isForPdf={isForPdf}
      isSmall={isSmall}
      customHeight={customHeight}
    />
  )
}

AudienceAgeGroupsChart.propTypes = {
  userindex: PropTypes.string,
  averageBirthyearArray: PropTypes.array,
  isDisabledAnalysis: PropTypes.bool,
  followersCount: PropTypes.number,
  birthyearMedian: PropTypes.number,
  isForPdf: PropTypes.bool,
  isLikersReport: PropTypes.bool,
  isSmall: PropTypes.bool
}

export default AudienceAgeGroupsChart
