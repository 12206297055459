import React from 'react'
import PropTypes from 'prop-types'

import useTranslation from '../../../localization/useTranslation'
import { TT_SCORE_NAMES } from '../../../constants/profile'
import { USER_INDEXES } from '../../../constants/appSettings'

import RatingDescriptionTip from '../detailedInfludataRating/ratingDescriptionTip/RatingDescriptionTip'
import {
  RatingTitleWrapper,
  RatingTitle,
  TiktokStatisticsGridItem,
  StatisticCommonValue,
} from './commonStyles'

const OneHitWonder = ({ oneHitWonder = false, isForPdf = false }) => {
  const { labelStrings, scoreStrings } = useTranslation()
  const oneHitWonderStrings = scoreStrings[USER_INDEXES.tiktok][TT_SCORE_NAMES.oneHitWonder]

  return (
    <TiktokStatisticsGridItem>
      <RatingTitleWrapper>
        <RatingTitle>{oneHitWonderStrings.title}</RatingTitle>
        {!isForPdf && <RatingDescriptionTip scoreDesc={oneHitWonderStrings.description} />}
      </RatingTitleWrapper>

      <StatisticCommonValue isForPdf={isForPdf}>
        {oneHitWonder ? labelStrings.yes : labelStrings.no}
      </StatisticCommonValue>
    </TiktokStatisticsGridItem>
  )
}

OneHitWonder.propTypes = {
  oneHitWonder: PropTypes.bool,
  isForPdf: PropTypes.bool,
}

export default OneHitWonder
