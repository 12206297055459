export const PDF_PAGE_DIVIDER_CLASS = 'pdf_page_divider'

export const PDF_PAGE_FORMATS = {
  a4: 'a4',
  //add more if needed. see jsPdf lib docs
}

export const PDF_PAGE_UNITS = {
  mm: 'mm',
  px: 'px',
  inch: 'in',
  // add more, if needed
}

export const PDF_PAGE_ORIENTATIONS = {
  landscape: 'landscape',
  portrait: 'portrait',
}

export const PDF_PAGE_PX_DIMENSIONS = {
  [PDF_PAGE_FORMATS.a4]: {
    height: 1120,
    width: 795,
  },
}
