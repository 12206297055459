import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import useOutsideClick from '../../../../hooks/useOutsideClick'
import { ADDITIONAL_FIELD_PROP_TYPE } from '../../../../constants/additionalFields'
import { ArrowIcon } from '../../../common/icons/icons'
import {
  AdditionalFieldInputCommon,
  AdditionalFieldInputIconWrapper,
  SelectOptionsDropdown,
  SelectOption,
} from './styles'

const AdditionalFieldSelect = ({ additionalFieldData, onChange }) => {
  const { code: id, allowedValues: options, value: selectedValueId } = additionalFieldData || {}

  const [isSelectOpened, setIsSelectOpened] = useState(false)

  const optionsDropdownRef = useRef(null)
  useOutsideClick({
    ref: optionsDropdownRef,
    callback: event => {
      if (!event.target.closest(`#af-${id}__button_open-select`)) {
        setIsSelectOpened(false)
      }
    },
  })

  const selectedOption = options?.find(option => option.id === selectedValueId)
  const selectedOptionValue = selectedOption?.value || ''

  const openSelect = () => {
    setIsSelectOpened(true)
  }

  return (
    <>
      <AdditionalFieldInputCommon
        value={selectedOptionValue}
        onClick={openSelect}
        readOnly
        isSelectInput
      />

      <AdditionalFieldInputIconWrapper id={`af-${id}__button_open-select`} onClick={openSelect}>
        <ArrowIcon />
      </AdditionalFieldInputIconWrapper>

      {isSelectOpened && (
        <SelectOptionsDropdown ref={optionsDropdownRef}>
          {options?.map(option => {
            const isSelected = option.id === selectedValueId

            const onSelectOption = () => {
              onChange(option.id)
              setIsSelectOpened(false)
            }

            return (
              <SelectOption key={option.id} isSelected={isSelected} onClick={onSelectOption}>
                {option.value}
              </SelectOption>
            )
          })}
        </SelectOptionsDropdown>
      )}
    </>
  )
}

AdditionalFieldSelect.propTypes = {
  additionalFieldData: ADDITIONAL_FIELD_PROP_TYPE.isRequired,
  onChange: PropTypes.func,
}

export default AdditionalFieldSelect
