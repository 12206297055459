import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../../localization/useTranslation'
import { TT_SCORE_NAMES } from '../../../../constants/profile'
import { USER_INDEXES } from '../../../../constants/appSettings'
import ComparisonToSimilarInfluencers from '../../../qualityScores/ComparisonToSimilarInfluencers'
import RatingDescriptionTip from '../ratingDescriptionTip/RatingDescriptionTip'
import NoDataMessage from '../NoDataMessage'
import {
  SectionTitleWrapper,
  SectionTitleName,
  SectionTitleBorder,
} from '../../commonProfilePageStyles'
import {
  RatingSectionContainer,
  RatingSectionGrid,
  RatingSectionGridItem,
  RatingTitleWrapper,
  RatingTitle,
  RatingWrapper,
} from '../commonStyles'

const ActivityTT = ({ platform = '', profileScores, isForPdf = false }) => {
  const { labelStrings, scoreStrings } = useTranslation()
  const ttScoreStrings = scoreStrings[USER_INDEXES.tiktok]

  const { postFrequencyObject, averageDurationPostsObject } = profileScores || {}

  const scoresArr = [
    {
      name: TT_SCORE_NAMES.postFrequency,
      data: postFrequencyObject,
      label: labelStrings.nVideosPerWeek(postFrequencyObject?.label),
    },
    {
      name: TT_SCORE_NAMES.averageDurationPosts,
      data: averageDurationPostsObject,
      label: labelStrings.nSecondsOnAverage(averageDurationPostsObject?.label),
    },
  ]

  return (
    <RatingSectionContainer isForPdf={isForPdf}>
      <SectionTitleWrapper>
        <SectionTitleName platform={platform} isForPdf={isForPdf}>
          {labelStrings.activity}
        </SectionTitleName>
        <SectionTitleBorder />
      </SectionTitleWrapper>

      <RatingSectionGrid columnsCount={!isForPdf ? 3 : 2} isForPdf={isForPdf}>
        {scoresArr?.map((scoreItem, index) => {
          const { name: scoreName, label, data: scoreData } = scoreItem
          const { benchmarkArray, xAxisHigh, xAxisMid, xAxisLow } = scoreData || {
            benchmarkArray: [],
            xAxisHigh: null,
            xAxisLow: null,
            xAxisMid: null,
          }
          const xAxisValues = [xAxisHigh, xAxisMid, xAxisLow]

          return (
            <RatingSectionGridItem key={index}>
              <RatingTitleWrapper isForPdf={isForPdf}>
                <RatingTitle>{ttScoreStrings[scoreName].title}</RatingTitle>
                {!isForPdf && (
                  <RatingDescriptionTip scoreDesc={ttScoreStrings[scoreName].description} />
                )}
              </RatingTitleWrapper>

              {!!scoreData ? (
                <RatingWrapper justifyContent="center" isForPdf={isForPdf}>
                  <ComparisonToSimilarInfluencers
                    label={label}
                    benchmarkArray={benchmarkArray}
                    xAxisValues={xAxisValues}
                  />
                </RatingWrapper>
              ) : (
                <NoDataMessage isForPdf={isForPdf} />
              )}
            </RatingSectionGridItem>
          )
        })}
      </RatingSectionGrid>
    </RatingSectionContainer>
  )
}

ActivityTT.propTypes = {
  profileScores: PropTypes.object.isRequired,
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  isForPdf: PropTypes.bool,
}

export default ActivityTT
