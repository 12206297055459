// @ts-nocheck
import React, { useState } from 'react'
import { Button, Chip, Stack, TextField, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { EMAIL_VALID_REGEX } from '../../../../constants/login'
import { useDispatch } from 'react-redux'
import { createNewSubUser } from '../../../../redux/ducks/userDucks'
import useTranslation from '../../../../localization/useTranslation'
import useResponsive from '../../../../new-ui/hooks/useResponsive'

const StyledFinishBtn = {
  fontFamily: `Lato, sans-serif`,
  fontWeight: 'bold',
  fontSize: '17px',
  lineHeight: '16px',
  color: '#ffffff',
  minWidth: '64px',
  width: 'fit-content',
  height: '46px',
  textTransform: 'capitalize',
  border: 'none',
  borderRadius: '24px',
  background:
    'transparent linear-gradient(89deg, #FF726F 0%, #FFCA55 120%) 0% 0% no-repeat padding-box',
}

export const InvitesTeammates = props => {
  const { afterSignupData, setAfterSignupData, handleContinue } = props || {}
  const { teammatesEmails } = afterSignupData || {}

  const dispatch = useDispatch()
  const { labelStrings } = useTranslation()
  const isMobile = useResponsive('down', 'sm')

  const [inputValue, setInputValue] = useState('')
  const [isInputError, setIsInputError] = useState(false)

  const handleInputChange = e => {
    setInputValue(e.target.value)
  }

  const handleAddEmail = () => {
    if (inputValue.trim() === '' || teammatesEmails.some(email => email === inputValue?.trim())) {
      setIsInputError(true)
      return
    }

    if (teammatesEmails.length < 5 && isValidEmail(inputValue)) {
      setIsInputError(false)
      setAfterSignupData(prev => ({
        ...prev,
        teammatesEmails: [...prev.teammatesEmails, inputValue.trim()],
      }))
      setInputValue('')
    }
  }

  const handleDeleteEmail = index => {
    const updatedEmails = [...teammatesEmails]
    updatedEmails.splice(index, 1)
    setAfterSignupData(prev => ({
      ...prev,
      teammatesEmails: updatedEmails,
    }))
  }

  const isValidEmail = email => {
    const isValid = EMAIL_VALID_REGEX.test(email)

    if (!isValid) {
      setIsInputError(true)
    }
    return isValid
  }

  const successAddEmails = () => {
    handleContinue()
    dispatch(createNewSubUser({ emails: teammatesEmails }))
  }

  return (
    <Stack sx={{ width: '100%', height: '100%' }}>
      <Typography
        sx={{ fontFamily: `Lato, sans-serif`, fontSize: '26px', fontWeight: 600, color: '#1F2937' }}
      >
        {labelStrings.signupLocalization.inviteTeammates}
      </Typography>
      <Stack spacing={3} sx={{ mt: 3, pb: 5 }}>
        <Typography
          sx={{
            fontFamily: `Lato, sans-serif`,
            fontSize: '16px',
            fontWeight: 600,
            color: '#1F2937',
          }}
        >
          {labelStrings.signupLocalization.theyllbeAbleToUse}
        </Typography>

        <Stack spacing={1} sx={{ maxWidth: isMobile ? '100%' : '450px' }}>
          <TextField
            fullWidth
            label={labelStrings.signupLocalization.enterEmailAddress}
            variant="outlined"
            value={inputValue}
            onChange={handleInputChange}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                handleAddEmail()
              }
            }}
            InputProps={{
              endAdornment: (
                <AddIcon sx={{ cursor: 'pointer' }} onClick={handleAddEmail} color="primary" />
              ),
            }}
            disabled={teammatesEmails?.length === 5}
            error={isInputError}
            helperText={isInputError && 'Incorrect email'}
          />
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ maxWidth: '370px', flexWrap: 'wrap' }}
          >
            {teammatesEmails.map(email => (
              <Chip
                key={email}
                label={email}
                onDelete={() => handleDeleteEmail(email)}
                style={{ margin: 4 }}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>

      <Stack
        direction={isMobile ? 'column' : 'row'}
        alignItems="center"
        spacing={isMobile ? 4 : 3}
        sx={{ mt: 3 }}
      >
        <Button
          disabled={!teammatesEmails?.length}
          variant="contained"
          onClick={successAddEmails}
          sx={
            teammatesEmails?.length
              ? { ...StyledFinishBtn, width: isMobile ? '100%' : 'fit-content' }
              : {
                  fontFamily: `Lato, sans-serif`,
                  fontSize: StyledFinishBtn.fontSize,
                  fontWeight: StyledFinishBtn.fontWeight,
                  height: StyledFinishBtn.height,
                  borderRadius: StyledFinishBtn.borderRadius,
                  width: isMobile ? '100%' : 'fit-content',
                }
          }
        >
          {labelStrings.signupLocalization.continue}
        </Button>
        <Button
          variant={isMobile ? 'outlined' : 'text'}
          sx={{
            fontSize: '17px',
            lineHeight: '16px',
            fontFamily: `Lato, sans-serif`,
            textDecoration: 'none',
            width: isMobile ? '100%' : 'fit-content',
            borderRadius: '24px',
            py: isMobile ? 2 : 0,
            maxHeight: '46px',
          }}
          onClick={handleContinue}
        >
          {labelStrings.signupLocalization.skipForNow}
        </Button>
      </Stack>
    </Stack>
  )
}
