import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import useTranslation from '../../../../localization/useTranslation'
import { selectedProfileSelector } from '../../../../redux/selectors'
import { USER_INDEXES } from '../../../../constants/appSettings'
import { DATE_FORMAT_STRINGS } from '../../../../constants/dayjs'
import { constructLinkToWECFileServer, getUrlWithProxy } from '../../../../utils'
import ProfileAvatar from '../../../profileCommon/profileAvatar'
import LatestPostsGallery from '../latestPostsGallery/LatestPostsGallery'
import { themeCommonStyles } from '../../../../themes/infludataThemes'
import { InstagramIcon, TikTokIcon, YouTubeClassicIcon } from '../../../common/icons/icons'
import { InfludataWecLogoIcon } from '../../../common/icons'
import { A4Page } from '../commonStyles'
import * as Styled from './styles'

const TitlePage = ({ dateOfReport, isBranded = true }) => {
  const { profile } = useSelector(selectedProfileSelector)
  const { _index: platform, profileScore, profilePicURL, username } = profile || {}

  const { labelStrings } = useTranslation()

  const avatarSrc = useMemo(() => getUrlWithProxy(constructLinkToWECFileServer(profilePicURL)), [profilePicURL])

  let score
  switch (platform) {
    case USER_INDEXES.instagram:
      score = profileScore.overallScore
      break
    case USER_INDEXES.tiktok:
      score = profileScore.overallScore
      break
    case USER_INDEXES.youtube:
        score = profileScore.overallScore
        break
  }

  return (
    <A4Page>
      <Styled.Container>
        <Styled.Header>
          <Styled.DateBlock>
            <Styled.DateLabel platform={platform}>{labelStrings.generatedOn}:</Styled.DateLabel>
            <Styled.Date platform={platform}>
              {dayjs(dateOfReport).format(DATE_FORMAT_STRINGS.defaultDate)}
            </Styled.Date>
          </Styled.DateBlock>

          <Styled.PlatformIconWrapper isBranded={isBranded}>
            {(() => {
              switch (platform) {
                case USER_INDEXES.instagram:
                  return <InstagramIcon isGradient />
                case USER_INDEXES.tiktok:
                  return <TikTokIcon isGradient />
                case USER_INDEXES.youtube:
                  return <YouTubeClassicIcon isGradient width={32} height={32}/>
                default:
                  return null
              }
            })()}
          </Styled.PlatformIconWrapper>

          { isBranded && <Styled.LogoIconWrapper>
            <InfludataWecLogoIcon width="150px" />
          </Styled.LogoIconWrapper> }
        </Styled.Header>

        <Styled.Main platform={platform}>
          <Styled.AvatarWrapper>
            <ProfileAvatar
              score={score}
              profilePicUrl={avatarSrc}
              userindex={platform}
              unfilledAreaBgColor={themeCommonStyles.getColorPaletteForPlatform(platform).dark}
              size={125}
              withRating
              withGradient
              withShadow={false}
              animated={false}
              isForPdf
            />
            <Styled.Username platform={platform}>{username}</Styled.Username>
          </Styled.AvatarWrapper>

          <Styled.GaleryWrapper>
            <LatestPostsGallery profile={profile} />
          </Styled.GaleryWrapper>
        </Styled.Main>
      </Styled.Container>
    </A4Page>
  )
}

TitlePage.propTypes = {
  dateOfReport: PropTypes.string.isRequired,
  isBranded: PropTypes.bool
}

export default TitlePage
