import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { pretifyBigNum } from '../../../utils'

import { UpArrowIcon, DownArrowIcon, RightArrowIcon } from '../icons'
import { BubbledText } from '../styledWrappers/comparedProfilesStyles'

const Wrapper = styled.span`
  color: ${props => props.theme.color.minShaft};
  width: 100%;
  height: 100%;
  ${props => props.theme.flex.centerAll}
`

const IconWrapper = styled.span`
  padding-bottom: 2px;
`

const ComparedValueGrowthDynamics = ({ value = 0, noPretty = false, isPercent = false, isMocked = true, rank = {} }) => {
  const DIR_MAP = {
    up: {
      Icon: UpArrowIcon,
    },
    down: {
      Icon: DownArrowIcon,
    },
    zero: {
      Icon: RightArrowIcon,
    },
  }

  const directionRender = useMemo(() => {
    if ((value > -1 && value < 1) || isMocked) return DIR_MAP.zero
    if (value > 1) return DIR_MAP.up
    return DIR_MAP.down
  }, [value])

  const { Icon } = directionRender

  const renderValue = isMocked ? 0 : noPretty ? value : pretifyBigNum(value)

  return (
    <Wrapper>
      <BubbledText rank={rank.colorIdx} withMinWidth>
        {renderValue}
        {isPercent ? '%' : ''} &nbsp;{' '}
        <IconWrapper>
          <Icon height="13px" width="13px" />
        </IconWrapper>
      </BubbledText>
    </Wrapper>
  )
}

ComparedValueGrowthDynamics.propTypes = {
  value: PropTypes.number,
  noPretty: PropTypes.bool,
  isPercent: PropTypes.bool,
  isMocked: PropTypes.bool,
  rank: PropTypes.object,
}

export default ComparedValueGrowthDynamics
