export const companySizesOptions = [
  { value: '0-5', label: '0-5' },
  { value: '6-10', label: '6-10' },
  { value: '10-50', label: '10-50' },
  { value: '50-100', label: '50-100' },
  { value: '100-500', label: '100-500' },
  { value: '500+', label: '500+' },
]

export const positionOptions = [
  { value: 'CEO/Business Owner', label: 'CEO/Business Owner' },
  { value: 'Head of Marketing', label: 'Head of Marketing' },
  { value: 'Head of Influencer-Marketing', label: 'Head of Influencer-Marketing' },
  { value: 'Marketing Manager', label: 'Marketing Manager' },
  { value: 'Marketing Agent', label: 'Marketing Agent' },
  { value: 'Student / Intern', label: 'Student / Intern' },
  { value: 'Other', label: 'Other' },
]

export const contactPersonOptions = [
  { value: 'imanuel', label: 'Imanuel' },
  { value: 'melita', label: 'Melita' },
  { value: 'johannes', label: 'Johannes' },
  { value: 'timo', label: 'Timo' },
  { value: 'michael', label: 'Michael' },
  { value: 'malou', label: 'Malou' },
  { value: 'daniel', label: 'Daniel' },
  { value: 'alex', label: 'Alex' },
  { value: 'guided tour', label: 'Guided Tour' },
]
