import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ModalComponent } from '../../components/modal/ModalComponent'
import { Stack } from '@mui/material'
import useTranslation from '../../../localization/useTranslation'
import httpService from '../../../services/httpService'
import LoadingSpinner from '../../../components/common/spinners/LoadingSpinner'
import { getUrlWithProxy } from '../../../utils'
import LoadingButton from '@mui/lab/LoadingButton'

export const DownloadContentModal = ({ isOpened, setOpenedDownloadModal, currentCreatorsData }) => {
  const { labelStrings } = useTranslation()

  const { platform, id, _id } = currentCreatorsData || {}

  const currId = id || _id

  const [modalData, setModalData] = useState({
    data: null,
    loading: false,
    error: null,
  })

  const isEmptyData = Array.isArray(modalData?.data) ? !modalData.data?.length : !modalData?.data?.link

  const [isLoadingBlob, setIsLoadingBlob] = useState({})

  const fetchContentDownloadLinks = async ({ platform = '', id = '' }) => {
    setModalData(prev => ({
      ...prev,
      loading: true,
    }))
    try {
      const data = await httpService.getContentDownloadLinks({
        platform,
        id,
      })

      setModalData(prev => ({
        ...prev,
        data,
        loading: false,
      }))
    } catch (err) {
      setModalData(prev => ({
        ...prev,
        error: err,
      }))
    }
  }

  useEffect(() => {
    if (isOpened && platform && currId && isEmptyData) {
      fetchContentDownloadLinks({ platform, id: currId })
    }
  }, [isOpened, platform, currId, isEmptyData])

  const handleClose = () => {
    setOpenedDownloadModal({})
    setModalData({
      data: [],
      loading: false,
      error: null,
    })
  }

  const handleDownload = async (url, fileName) => {
    if (!url.trim()) {
      return
    }

    setIsLoadingBlob(prev => ({
      ...prev,
      [fileName]: true,
    }))
    const proxyUrl = getUrlWithProxy(url)

    try {
      const response = await fetch(proxyUrl)
      const blob = await response.blob()
      const blobType = blob?.type?.split('/')

      if (blobType?.length) {
        saveAs(blob, `${fileName || blobType[0]}.${blobType[1]}`)
        setIsLoadingBlob(prev => ({
          ...prev,
          [fileName]: false,
        }))
      }
    } catch (error) {
      console.error('Error downloading media:', error)
    } finally {
      setIsLoadingBlob(prev => ({
        ...prev,
        [fileName]: false,
      }))
    }
  }

  const saveAs = (blob, fileName) => {
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  return (
    <ModalComponent
      mponent
      isOpenModal={isOpened}
      closeModal={handleClose}
      title={labelStrings.downloadHighQuality}
      contentWidth="500px"
      contentHeight="auto"
      maxHeightForScrollbarContent="400px"
      isCenterTitle
    >
      <Stack spacing={1} sx={{ pb: 2 }} alignItems="center">
        {modalData?.loading ? (
          <LoadingSpinner isInModal />
        ) : (
          <>
            {Array.isArray(modalData?.data) ? (
              modalData?.data?.map(media => {
                return (
                  <LoadingButton
                    loading={isLoadingBlob[media?.type] || false}
                    variant="contained"
                    key={media?.type}
                    onClick={() => handleDownload(media?.link, media?.type)}
                    sx={{ width: '300px', textTransform: 'capitalize' }}
                  >
                    {labelStrings.download} {media?.type?.replace('_', ' ')}
                  </LoadingButton>
                )
              })
            ) : (
              <LoadingButton
                loading={isLoadingBlob[modalData?.data?.type] || false}
                variant="contained"
                onClick={() => handleDownload(modalData?.data?.link, modalData?.data?.type)}
                sx={{ width: '300px', textTransform: 'capitalize' }}
              >
                {labelStrings.download} {modalData?.data?.type?.replace('_', ' ')}
              </LoadingButton>
            )}
          </>
        )}
      </Stack>
    </ModalComponent>
  )
}

DownloadContentModal.propTypes = {
  isOpened: PropTypes.bool,
  setOpenedDownloadModal: PropTypes.func,
  item: PropTypes.object,
}