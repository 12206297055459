import styled from 'styled-components'

export const RatingWithDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${props => (!props.isForPdf ? '30px 0' : '10px 0')};
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 435px;
`

export const OverallScoreTitle = styled.div`
  font-size: 15px;
  line-height: 19px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => props.theme.colors.darkBrown};
  margin-bottom: 5px;
`

export const OverallScoreText = styled.div`
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.brown};
`
