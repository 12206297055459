import React from 'react'
import {
  ComparedRow,
  BubbledText,
  QualitySubScoreWrapper,
  QualitySubScoreLabel,
  QualitySubScoreValue,
  SubSectionsDivider,
} from '../../../../common/styledWrappers/comparedProfilesStyles'
import { pretifyBigNum, notExistingValue } from '../../../../../utils'
import ComparedAudienceLocked from '../../../../userProfile/profileAudienceAnalysis/ComparedAudienceLocked'
import ComparedAnalysisDetailsSection from '../../../../userProfile/profileAudienceAnalysis/ComparedAnalysisDetailsSection'

export const bubbledTextComponentFactory = ({
  withMinWidth = false,
  noPretty = false,
  withAbsRankValue = false,
} = {}) => ({ value, isMocked, rank }) => {
  const _renderValue = () => {
    if (notExistingValue(value)) return '-'
    if (typeof value === 'string') return value
    return value ? (noPretty ? value : pretifyBigNum(value, 1)) : 0
  }

  const renderRank = () => {
    if (notExistingValue(value)) return
    if (withAbsRankValue) {
      const colorIdx = Math.floor(value - 1)
      return colorIdx >= 0 ? colorIdx : 0
    }
    if (!!rank) return rank.colorIdx
  }

  return (
    !isMocked && (
      <BubbledText withMinWidth={withMinWidth} rank={renderRank()}>
        {_renderValue()}
      </BubbledText>
    )
  )
}

export const qualitySubScoreComponentFactory = ({ scoreLabel = '' }) => ({ value }) => {
  let rank = null
  let renderValue = '-/10'

  if (value) {
    rank = Math.ceil(value - 1)
    renderValue = `${value.toFixed(0)}/10`
  }

  return (
    <QualitySubScoreWrapper>
      <QualitySubScoreValue rank={rank}>{renderValue}</QualitySubScoreValue>
      <QualitySubScoreLabel>{scoreLabel}</QualitySubScoreLabel>
    </QualitySubScoreWrapper>
  )
}

export const lockedAudienceFactory = profile => () => {
  return <ComparedAudienceLocked profile={profile} />
}

export const audienceSectionFactory = () => ({
  value,
  nameKey,
  index,
  withMedian,
  comparedRanks,
  profileId,
}) => (
  <ComparedAnalysisDetailsSection
    audienceData={value}
    sectionNameKey={nameKey}
    index={index}
    withMedian={withMedian}
    comparedRanks={comparedRanks}
    profileId={profileId}
  />
)

export const comparedRowFactory = row => {
  const _createLabel = labelValue => {
    if (!labelValue) return '-'
    const isNumber = typeof labelValue === 'number'
    if (isNumber) {
      return row.noPretty ? labelValue : pretifyBigNum(labelValue)
    }
    return labelValue
  }

  const { RenderComponent } = row
  return (
    <ComparedRow
      key={row.key}
      isNav={row.isNav}
      isBold={row.isBold}
      customHeight={row.height}
      withMargin={!row.noMargin}
      withBG={row.withBG}
      isLink={row.isLink}
      index={row.index}
      isMocked={row.isMocked}
      {...(row.withStartSection && {
        withStartSection: row.withStartSection,
        startSectionLabel: row.startSectionLabel,
      })}
    >
      {RenderComponent ? <RenderComponent {...row} /> : _createLabel(row.value)}
      {row.withSubSectionDevider && <SubSectionsDivider index={row.index} />}
    </ComparedRow>
  )
}
