import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { userGrantSelector } from '../../../redux/selectors'
import { TableWrapper, Table } from './tableStyles'
import ProfileListHeadRow from './ProfileListHeadRow'
import ProfileListUserRow from './profileListUserRow'

import ProfileListViewHeadRow from '../profileListView/ProfileListViewHeadRow'
import ProfileListViewUserRow from '../profileListView/ProfileListViewUserRow'
import SearchResultsSortingTool from '../../sortingTools/SearchResultsSortingTool'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { Stack, Typography } from '@mui/material'
import useTranslation from '../../../localization/useTranslation'
// import SuggestionsSortingTool from '../../../new-ui/pages/Explore/SuggestionsSortingTool'

const ProfileListFullTableContainer = ({
  areAllSelected,
  isCollectionView = false,
  isUnlockedCollectionOpened = false,
  users = [],
  isBlackListCollection = false,
  actionsHandlers,
  beforeRedirectPage = '',
  onBlockUnblockUser,
  isSelectedProfileView = false,
  isListMode = false,
  tableHeaderProps = {},
  currentFieldsState = {},
  additionalFields = [],
  isExplore = false,
  lookalikeTypeData = '',
  isDownloadSearchResults = false,
}) => {
  const { labelStrings } = useTranslation()
  const userGrant = useSelector(userGrantSelector)
  const { selectAllUsers, ...restOfHandlers } = actionsHandlers || {}
  const selectAll = () => selectAllUsers(!areAllSelected)

  const { pathname } = useLocation()
  const isSearchPage = pathname === '/search' || pathname === '/search/'

  const { package_advanced: isAdvancedUser, enable_collections: collectionsAllowed } = userGrant

  const showUserGrowth = isAdvancedUser

  const isPrivateRating = collectionsAllowed

  const allowAddToCollectionsViaDrag = !!collectionsAllowed && !isUnlockedCollectionOpened

  const detailedTableView = useMemo(() => {
    return (
      <tbody>
        <ProfileListHeadRow
          selectAll={selectAll}
          areAllSelected={areAllSelected}
          isCollectionView={isCollectionView}
          isUnlockedCollectionOpened={isUnlockedCollectionOpened}
          isBlackListCollection={isBlackListCollection}
          hasNotCheckboxes={isExplore}
          lookalikeTypeData={lookalikeTypeData}
        />
        {users?.map((user, idx) => {
          return (
            <ProfileListUserRow
              key={user._id + idx}
              user={user}
              beforeRedirectPage={beforeRedirectPage}
              isCollectionView={isCollectionView}
              isUnlockedCollectionOpened={isUnlockedCollectionOpened}
              actionHandlers={restOfHandlers}
              privateRating={isPrivateRating}
              showUserGrowth={showUserGrowth}
              allowAddToCollectionsViaDrag={allowAddToCollectionsViaDrag}
              isBlackListCollection={isBlackListCollection}
              onBlockUnblockUser={onBlockUnblockUser}
              isSelectedProfileView={isSelectedProfileView}
              hasNotCheckboxes={isExplore}
              isExplore={isExplore}
              lookalikeTypeData={lookalikeTypeData}
            />
          )
        })}
      </tbody>
    )
  }, [
    users,
    beforeRedirectPage,
    isCollectionView,
    isUnlockedCollectionOpened,
    restOfHandlers,
    isPrivateRating,
    showUserGrowth,
    allowAddToCollectionsViaDrag,
    isBlackListCollection,
    onBlockUnblockUser,
    selectAll,
    areAllSelected,
    isExplore,
    lookalikeTypeData,
  ])

  const listTableView = () => {
    return (
      <tbody>
        <ProfileListViewHeadRow
          selectAll={selectAll}
          areAllSelected={areAllSelected}
          isCollectionView={isCollectionView}
          isUnlockedCollectionOpened={isUnlockedCollectionOpened}
          isBlackListCollection={isBlackListCollection}
          tableHeaderProps={tableHeaderProps}
          currentFieldsState={currentFieldsState}
          additionalFields={additionalFields}
          isDownloadSearchResults={isDownloadSearchResults}
        />
        {users?.map((user, idx) => {
          return (
            <ProfileListViewUserRow
              key={user._id + idx}
              user={user}
              beforeRedirectPage={beforeRedirectPage}
              isCollectionView={isCollectionView}
              isUnlockedCollectionOpened={isUnlockedCollectionOpened}
              actionHandlers={restOfHandlers}
              privateRating={isPrivateRating}
              showUserGrowth={showUserGrowth}
              allowAddToCollectionsViaDrag={allowAddToCollectionsViaDrag}
              isBlackListCollection={isBlackListCollection}
              onBlockUnblockUser={onBlockUnblockUser}
              currentFieldsState={currentFieldsState}
              additionalFields={additionalFields}
            />
          )
        })}
      </tbody>
    )
  }

  const renderTable = useMemo(() => (!isListMode ? detailedTableView : listTableView()), [
    isListMode,
    selectAll,
    areAllSelected,
  ])

  return (
    <>
      {isSearchPage || isExplore ? (
        <>
          {!!users?.length && (
            <TableWrapper isListMode={isListMode}>
              {isSearchPage && (
                <SearchResultsSortingTool
                  isExplore={isExplore}
                  isDownloadSearchResults={isDownloadSearchResults}
                  isFullWidth
                />
              )}
              <Table>{renderTable}</Table>
            </TableWrapper>
          )}
        </>
      ) : !!users?.length ? (
        <TableWrapper isListMode={isListMode}>
          <Table>{renderTable}</Table>
        </TableWrapper>
      ) : (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ backgroundColor: '#FFF9F9', width: '100%', height: '200px', borderRadius: '13px' }}
        >
          <Typography
            sx={{ fontWeight: 'bold', color: '#BC9797' }}
          >{`${labelStrings.user} ${labelStrings.notFound}`}</Typography>
        </Stack>
      )}
    </>
  )
}

ProfileListFullTableContainer.propTypes = {
  areAllSelected: PropTypes.bool,
  isCollectionView: PropTypes.bool,
  isUnlockedCollectionOpened: PropTypes.bool,
  isSelectedProfileView: PropTypes.bool,
  beforeRedirectPage: PropTypes.string,
  users: PropTypes.array,
  isBlackListCollection: PropTypes.bool,
  actionsHandlers: PropTypes.shape({
    modifyUser: PropTypes.func,
    selectAllUsers: PropTypes.func,
    selectOne: PropTypes.func,
  }),
  onBlockUnblockUser: PropTypes.func,
  isListMode: PropTypes.bool,
  tableHeaderProps: PropTypes.object,
  currentFieldsState: PropTypes.object,
  additionalFields: PropTypes.array,
  isExplore: PropTypes.bool,
  lookalikeTypeData: PropTypes.string,
  isDownloadSearchResults: PropTypes.bool,
}

export default ProfileListFullTableContainer
