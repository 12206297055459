// @ts-nocheck
import React, { useMemo } from 'react'
import { renderToString } from 'react-dom/server'
import { Avatar, Box, Paper, Stack, Typography } from '@mui/material'
import { addMonths, subMonths } from 'date-fns'
import Chart, { useChart } from '../../../../../../../new-ui/components/chart'
import useTranslation from '../../../../../../../localization/useTranslation'
import { getUrlWithProxy, pretifyBigNum } from '../../../../../../../utils'

export const TopThreeBrandsAmbassadors = props => {
  const {
    currentTimelineChartData,
    timelineChartCreatorsCount,
    dateStart,
    dateEnd,
    onHandleFiltered,
    timelineDataCount,
    mentionAccountsAndTimeline,
  } = props
  const { labelStrings } = useTranslation()

  const bubbleChartData = useMemo(() => {
    return currentTimelineChartData?.map((el, idx) => {
      const currBubbleArr = el.bubbleArray
      return {
        name: el?.username,
        data: currBubbleArr?.map(bubble => [
          bubble.bubbleTimestamp,
          currentTimelineChartData.length - idx,
          bubble.bubbleSize,
        ]),
      }
    })
  }, [currentTimelineChartData?.length])

  const chartHeight = useMemo(() => {
    // Ensure there's a minimum height for the chart
    const minHeight = 600
    let spacing = 125
    let userCount =
      timelineChartCreatorsCount > timelineDataCount
        ? timelineDataCount
        : timelineChartCreatorsCount

    if (userCount < 5) {
      spacing = 150
    } else if (userCount < 10) {
      spacing = 138
    } else if (userCount < 15) {
      spacing = 133
    } else if (userCount < 22) {
      spacing = 129
    } else if (userCount < 28) {
      spacing = 127
    } else if (userCount < 50) {
      spacing = 126
    }
    const dynamicHeight = userCount * spacing

    return dynamicHeight
  }, [timelineChartCreatorsCount])

  const bubbleOptions = useChart({
    options: {
      chart: {
        type: 'bubble',
        toolbar: {
          show: false,
        },
        events: {
          dataPointSelection: function(event, chartContext, config) {
            const indexEl = config?.seriesIndex
            const clickedCreatorObj = config?.w?.config?.series[indexEl]
            onHandleFiltered({ username: clickedCreatorObj?.name })
          },
        },
      },
      plotOptions: {
        bubble: {
          zScaling: false,
          minBubbleRadius: 8,
          maxBubbleRadius: 20,
        },
      },
      tooltip: {
        enabled: true,
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
          try {
            let startDate = new Date(
              mentionAccountsAndTimeline[seriesIndex].collaborationStart
            ).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })
            let endDate = new Date(
              mentionAccountsAndTimeline[seriesIndex].collaborationEnd
            ).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })
            return renderToString(
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '12px',
                  borderRadius: '4px',
                  boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
                }}
              >
                <div
                  style={{
                    color: '#784E4E',
                    fontSize: '15px',
                    lineHeight: '19px',
                    fontWeight: '700',
                  }}
                >
                  {startDate} - {endDate}
                </div>
                <div
                  style={{
                    color: '#784E4E',
                    fontSize: '15px',
                    lineHeight: '19px',
                    fontWeight: '500',
                  }}
                >
                  {labelStrings.brandsTimelineText.clickToFilterContent}
                </div>
              </div>
            )
          } catch (e) {
            console.log('error', e)
            return ''
          }
        },
      },
      zoom: {
        enabled: false,
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeFormatter: {
            year: 'yyyy',
            month: 'MMM yyyy', // Display format for months
            day: 'dd MMM',
            hour: 'HH:mm',
          },
        },
        min: subMonths(new Date(dateStart), 1).getTime(),
        max: addMonths(new Date(dateEnd), 2).getTime(),
        reversedStacks: true,
      },
      grid: {
        show: true,
        yaxis: {
          lines: {
            show: false,
          },
        },
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
        reversed: false,
        tickAmount: timelineChartCreatorsCount,
        zoom: {
          autoScaleYaxis: true,
        },
        max: function() {
          return timelineChartCreatorsCount + 0.5 // Or simply use timelineChartCreatorsCount if it fits better
        },
        min: 0,
      },
      legend: {
        show: false,
      },
      zoom: {
        enabled: false,
        autoScaleYaxis: true,
      },
      dataLabels: {
        enabled: false, // Disable data labels
      },
    },
  })

  return (
    <Stack direction={'row'} justifyContent="space-between" sx={{ position: 'relative' }}>
      <Stack sx={{ width: 'fit-content', minWidth: '251px' }}>
        <Stack spacing={2.5} sx={{ position: 'absolute', top: '40px' }}>
          {currentTimelineChartData?.map((creator, index) => (
            <Box
              key={`${creator.username}/${creator.reach}`}
              onClick={() => onHandleFiltered({ username: creator.username })}
              sx={{ position: 'relative', width: '240px' }}
            >
              <Paper
                elevation={3}
                sx={{
                  p: 1,
                  borderRadius: '13px',
                  border: '1px solid #4E8E8',
                  cursor: 'pointer',
                  '&:hover': {
                    filter: `brightness(90%)`,
                  },
                }}
              >
                <Stack direction="row" spacing={3} alignItems="center">
                  <Stack alignItems="center" spacing={0} sx={{ width: '40%' }}>
                    <Avatar
                      sx={{ width: '50px', height: '50px' }}
                      src={getUrlWithProxy(creator?.profilePicURL)}
                    />
                    <Typography
                      sx={{
                        fontSize: '10px',
                        fontWeight: 600,
                        color: '#6CB75B',
                        maxWidth: '70px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        pb: 1,
                      }}
                    >
                      @{creator?.username}
                    </Typography>
                  </Stack>
                  <Stack sx={{ width: '60%', pb: '16px !important' }}>
                    <Typography
                      sx={{
                        lineHeight: 'initial',
                        fontSize: '15px',
                        fontWeight: 700,
                        color: '#784E4E',
                        mb: 0.5,
                        textTransform: 'capitalize',
                        maxWidth: '120px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        pb: 1,
                      }}
                    >
                      {creator?.displayName?.length > 14 ? creator?.displayName.slice(0,12) + '...' : creator?.displayName}
                    </Typography>
                    {creator?.mediaValue > 0 && (
                      <Typography
                        sx={{
                          lineHeight: '16px',
                          fontSize: '13px',
                          fontWeight: 400,
                          color: '#784E4E',
                        }}
                      >
                        EMV: {pretifyBigNum(creator.mediaValue)}
                      </Typography>
                    )}
                    <Typography
                      sx={{
                        lineHeight: '16px',
                        fontSize: '13px',
                        fontWeight: 400,
                        color: '#784E4E',
                      }}
                    >
                      {labelStrings.reach}:{' '}
                      {creator.reach && creator.reach > 1000
                        ? pretifyBigNum(creator.reach)
                        : '< 1k'}
                    </Typography>
                    <Typography
                      sx={{
                        lineHeight: '16px',
                        fontSize: '13px',
                        fontWeight: 400,
                        color: '#784E4E',
                      }}
                    >
                      {pretifyBigNum(creator?.contentCount)}{' '}
                      {labelStrings.brandsTimelineText.contentPcs}
                    </Typography>
                  </Stack>
                </Stack>
              </Paper>
              {index !== currentTimelineChartData.length - 1 && (
                <Box
                  sx={{
                    maxWidth: '1220px',
                    position: 'absolute',
                    bottom: -10,
                    left: 0,
                    width: '1140px',
                    height: '1px',
                    bgcolor: 'divider',
                  }}
                />
              )}
            </Box>
          ))}
        </Stack>
      </Stack>
      <Box
        sx={{
          width: '100%',
          '& .apexcharts-menu-icon': { display: 'none' },
          '& .apexcharts-series-markers': {
            cursor: 'pointer',
          },
        }}
      >
        <Chart
          options={bubbleOptions.options}
          series={bubbleChartData}
          type="bubble"
          height={chartHeight}
          width={900}
        />
      </Box>
    </Stack>
  )
}
