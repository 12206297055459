import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Popup from 'reactjs-popup'
import useTranslation from '../../../localization/useTranslation'
import DeleteMyAccountDialog from '../../common/dialogs/DeleteMyAccountDialog'
import PrimaryButton from '../../common/buttons/PrimaryButton'
import { WithPopupWrapper } from '../../common/styledWrappers'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 20px 5%;
`

const DeleteAccount = ({
  isModalOpened = false,
  toggleModal,
  confirmDeleteAccount,
  successDeleteAccountHandler,
  isSuccessDelete = false,
}) => {
  const { labelStrings } = useTranslation()

  const deleteBtnText = labelStrings.deleteYourAccount
    ? labelStrings.deleteYourAccount.toUpperCase()
    : ''
  return (
    <Wrapper>
      <WithPopupWrapper isTransparent fullScreen enableHeader={!isSuccessDelete} withBlurBG>
        <Popup
          open={isModalOpened}
          position="right center"
          closeOnDocumentClick={false}
          closeOnEscape={false}
          modal
        >
          {close => (
            <DeleteMyAccountDialog
              close={toggleModal}
              confirm={confirmDeleteAccount}
              successDeleteAccountHandler={successDeleteAccountHandler}
              isSuccessDelete={isSuccessDelete}
            />
          )}
        </Popup>
      </WithPopupWrapper>

      {deleteBtnText && <PrimaryButton label={deleteBtnText} onClick={toggleModal} />}
    </Wrapper>
  )
}

DeleteAccount.propTypes = {
  isModalOpened: PropTypes.bool,
  toggleModal: PropTypes.func,
  confirmDeleteAccount: PropTypes.func,
  successDeleteAccountHandler: PropTypes.func,
  isSuccessDelete: PropTypes.bool,
}

export default DeleteAccount
