import React from 'react'
import { Grid, Button, Tooltip } from '@mui/material'

export const TopControls = () => {
  return (
    <>
      <Grid
        container
        sx={{
          height: '40px',
          justifyContent: 'end',
          alignItems: 'center',
        }}
      >
        <Grid item>
          <Tooltip title='To export the creators, please click "Airtable View" on the left menu, then click the tab "Creators" and then "Grid View" to export CSVs and PDFs. You are able to filter the creators beforehand and customize your export.'>
            <span>
                <Button disabled variant="outlined">
                    Export
                </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  )
}
