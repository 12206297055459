import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { USER_INDEXES } from '../../../constants/appSettings'
import useTranslation from '../../../localization/useTranslation'

import { themeCommonStyles } from '../../../themes/infludataThemes'
import GradientTransparentButton from '../buttons/GradientTransparentButton'

const YesNoCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const OptionButton = styled(props => (
  <GradientTransparentButton
    gradient={themeCommonStyles.getGradientsForPlatform(props.platform).main}
    {...props}
  />
))`
  width: 75px;

  ${props =>
    props.isSelected &&
    `
    box-shadow: none;

    .gradient-transparent-button__content-wrapper {
      background: transparent;
      -webkit-background-clip: ${props.theme.colors.white};
      -webkit-text-fill-color: ${props.theme.colors.white};
    }
  `}

  & + & {
    margin-left: 15px;
  }
`

const YesNoCheckbox = ({ platform = '', isYes = false, onChange }) => {
  const { labelStrings } = useTranslation()
  const isEmptyValue = isYes === null

  return (
    <YesNoCheckboxWrapper>
      <OptionButton
        isSelected={!isEmptyValue && isYes}
        platform={platform}
        onClick={() => onChange(true)}
      >
        {labelStrings.yes}
      </OptionButton>
      <OptionButton
        isSelected={!isEmptyValue && !isYes}
        platform={platform}
        onClick={() => onChange(false)}
      >
        {labelStrings.no}
      </OptionButton>
    </YesNoCheckboxWrapper>
  )
}

YesNoCheckbox.propTypes = {
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube]),
  isYes: PropTypes.bool,
  onChange: PropTypes.func,
}

export default YesNoCheckbox
