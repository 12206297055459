import React from 'react'
import { CalendarContainer } from '../../sections/calendar/CalendarContainer'
import { useSelector } from 'react-redux'
import { calendarDataSelector } from '../../../redux/selectors'
import LoadingSpinner from '../../../components/common/spinners/LoadingSpinner'
import { Box } from '@mui/material'

export const Calendar = () => {
  const { isLoading } = useSelector(calendarDataSelector)  
  return (
    <Box sx={{ py: 3 }}>
      {isLoading && (
        <LoadingSpinner showOnFullPage isTransparent height={'100%'} width={'100%'} />
      )}
      <CalendarContainer />
    </Box>
  )
}
