import React, { useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import useTranslation from '../../../../localization/useTranslation'
import { DATE_FORMAT_STRINGS } from '../../../../constants/dayjs'
import { USER_INDEXES } from '../../../../constants/appSettings'
import { withReduxActions } from '../../../../hocs/withRedux'
import {
  editProfileComment,
  deleteProfileComment,
} from '../../../../redux/ducks/profilesCommonActions'
import PrivateNoteActions from './PrivateNoteActions'
import { themeCommonStyles } from '../../../../themes/infludataThemes'
import { CancelButton, GradientButton } from '../../../common/buttons/styledButtons'
import { ThreeDotsIcon, PinnedIcon } from '../../../common/icons/icons'
import {
  PrivateNoteItem,
  PrivateNoteDetails,
  PrivateNoteDetailsWrapper,
  PrivateNoteDetailsLabel,
  PrivateNoteFrom,
  PrivateNoteDate,
  PrivateNoteText,
  PrivateNoteActionsButton,
  PrivateNoteEditingButtons,
  PrivateNotePinIndicator,
  NoteTextarea,
} from './styles'

const PrivateNote = ({ commentData, platform, editProfileComment, deleteProfileComment }) => {
  const { labelStrings } = useTranslation()

  const [isActionsOpened, setIsActionsOpened] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [localComment, setLocalComment] = useState(commentData.comment)

  const formattedDate = dayjs(commentData.timeStamp).format(DATE_FORMAT_STRINGS.defaultDateTime)

  const openActions = () => setIsActionsOpened(true)
  const closeActions = () => setIsActionsOpened(false)

  const isOldComment = commentData.code === 'no_code'

  const onChangeNoteText = event => {
    setLocalComment(event.target.value)
  }

  const saveEditedNote = async () => {
    if (commentData.comment === localComment) {
      setIsEditing(false)
      return
    }
    await editProfileComment({ ...commentData, comment: localComment })
    setIsEditing(false)
  }

  const cancelNoteEditing = () => {
    setLocalComment(commentData.comment)
    setIsEditing(false)
  }

  const onPinClick = () => {
    editProfileComment({ ...commentData, isPinned: !commentData.isPinned })
    closeActions()
  }

  const onEditClick = () => {
    setIsEditing(true)
    closeActions()
  }

  const onDeleteClick = () => {
    deleteProfileComment(commentData.code)
    closeActions()
  }

  return (
    <PrivateNoteItem>
      {!isOldComment && (
        <PrivateNoteDetails>
          <PrivateNoteDetailsWrapper>
            <PrivateNoteDetailsLabel>{labelStrings.from}: </PrivateNoteDetailsLabel>
            <PrivateNoteFrom>{commentData.user}</PrivateNoteFrom>
          </PrivateNoteDetailsWrapper>

          <PrivateNoteDetailsWrapper>
            <PrivateNoteDetailsLabel>{labelStrings.date}:</PrivateNoteDetailsLabel>
            <PrivateNoteDate>{formattedDate}</PrivateNoteDate>
          </PrivateNoteDetailsWrapper>
        </PrivateNoteDetails>
      )}

      {isEditing ? (
        <>
          <NoteTextarea
            id={`note-${commentData.code}`}
            name={`note-${commentData.code}`}
            value={localComment}
            onChange={onChangeNoteText}
          />

          <PrivateNoteEditingButtons>
            <CancelButton onClick={cancelNoteEditing}>{labelStrings.cancel}</CancelButton>
            <GradientButton platform={platform} onClick={saveEditedNote}>
              {labelStrings.save}
            </GradientButton>
          </PrivateNoteEditingButtons>
        </>
      ) : (
        <PrivateNoteText>{commentData.comment}</PrivateNoteText>
      )}

      {!isOldComment && (
        <PrivateNoteActionsButton
          id={`note-${commentData.code}__open-actions`}
          onClick={openActions}
        >
          <ThreeDotsIcon />
        </PrivateNoteActionsButton>
      )}

      {commentData.isPinned &&
        (() => {
          const [color1, color2] = themeCommonStyles.getMainGradientColorsForPlatform(platform)
          return (
            <PrivateNotePinIndicator>
              <PinnedIcon isGradient gradientColor1={color1} gradientColor2={color2} />
            </PrivateNotePinIndicator>
          )
        })()}

      {isActionsOpened && (
        <PrivateNoteActions
          noteId={commentData.code}
          isPinned={commentData.isPinned}
          platform={platform}
          closeOverlay={closeActions}
          onPinClick={onPinClick}
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
        />
      )}
    </PrivateNoteItem>
  )
}

PrivateNote.propTypes = {
  commentData: PropTypes.shape({
    code: PropTypes.string,
    comment: PropTypes.string,
    isPinned: PropTypes.bool,
    linkToResources: PropTypes.string,
    timeStamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    user: PropTypes.string,
  }).isRequired,
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  editProfileComment: PropTypes.func,
  deleteProfileComment: PropTypes.func,
}

export default withReduxActions({ editProfileComment, deleteProfileComment })(PrivateNote)
