import React, { useRef, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { RoundedInput, Label, TipIconWrapper } from '../../common/styledWrappers'
import WithTip from '../../common/popups/WithTip'
import { InfoIcon } from '../../common/icons'
import SingleSelect from '../../common/selects/SingleSelect'
import useTranslation from '../../../localization/useTranslation'

const InfoIconWrapper = styled(TipIconWrapper)`
  margin: 0 10px;
  ${props =>
    !props.isRequired &&
    `
    position: relative;
    left: -7px;
  `}
`

const InputBlockWrapper = styled.div`
  width: 100%;
  ${props => props.theme.flex.centerAll};
  position: relative;
  flex-direction: column;
  align-items: flex-start;
`

const Select = styled(SingleSelect)`
  >div {
    width: 265px;
    background: ${props => props.theme.color.white};
    border: ${props => (props.isEditDetails ? '0.5px solid #9b9b9b' : 'none')};
    padding: 5px 0px;
    border-radius: 13px !important;
    ${props =>
      props.disabled &&
      props.isEditDetails &&
      `
      background: #EBEBEB;
    `}
  }
  font-size: 15px;
  color: ${props => props.theme.color.mineShaft};
  ${props => !props.isValid && `border-color: ${props.theme.primaryColor};`}
  ${props => props.disabled && `pointer-events: none;`}
  ${props =>
    props.withPrefix &&
    `
    padding: 0;
    padding-left: ${props.isEditDetails ? props.prefixWidth || 0 : 0}px;
    
  `}
`

const Input = styled(RoundedInput)`
  height: ${props => (props.isEditDetails ? '43' : '35')}px;
  
  padding: 0 12px;
  font-size: 15px;
  border: ${props => (props.isEditDetails ? '0.5px solid #9b9b9b' : 'none')};
  border-radius: 13px;
  color: ${props => props.theme.color.mineShaft};
  background: ${props => props.theme.color.white};
  ${props => !props.isValid && `border-color: ${props.theme.primaryColor};`}
  ${props =>
    props.disabled &&
    props.isEditDetails &&
    `
    background: #EBEBEB;
    color: #B4B4B4;
  `}
  ${props => !props.isEditDetails && `pointer-events: none;`}
  ${props =>
    props.withPrefix &&
    `
    padding: 0;
    padding-left: ${props.isEditDetails ? props.prefixWidth || 0 : 0}px;
    
  `}
 
`

const InputLabel = styled(Label)`
  color: ${props => props.theme.color.grey};
  letter-spacing: 0;
  font-family: ${props => props.theme.fontFamilyInter};
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 3px;
  margin-left: 10px;
  margin-top: 10px;
  position: relative;
  ${props =>
    props.isRequired &&
    `
    &::before {
      position: absolute;
      content: "*";
      color: ${props.theme.primaryColor};
      top: -3px;
      right: -7px;
    }
  `}
`

const TippedRowFlex = styled.div`
  display: flex;
  align-items: flex-end;
`

const InputInnerWrapper = styled.div`
  display: flex;
  align-items: center;
`

const PrefixWrapper = styled.span`
  color: ${props => props.theme.color.grey};
  letter-spacing: 0;
  font-family: ${props => props.theme.fontFamilyInter};
  font-size: 15px;
  font-weight: 600;
  margin-left: ${props => props.prefixMarginLeft || 10}px;
  ${props =>
    props.isEditDetails &&
    `
    position: absolute;
  `}
  ${props => !props.isVisible && 'display: none;'}
`

const BillingFormInput = ({ item = {}, isEditDetails }) => {
  const prefixRef = useRef()
  const { labelStrings, countryOptions } = useTranslation()

  const prefixMarginLeft = 10
  const getPrefixWidth = () => {
    return prefixRef && prefixRef.current
      ? prefixRef.current.getBoundingClientRect().width + prefixMarginLeft
      : 0
  }

  const createCountriesOptions = useCallback(() => {
    const options = countryOptions.filter(opt => !!opt.value)
    return options
  }, [countryOptions])

  const countries = createCountriesOptions()
  return (
    <InputBlockWrapper key={item.label}>
      {item.Tip && isEditDetails ? (
        <TippedRowFlex>
          <InputLabel isRequired={item.isRequired}>{item.label}</InputLabel>
          <WithTip TipComponent={item.Tip} position="right center" useWhiteBG>
            <InfoIconWrapper isRequired={item.isRequired}>
              <InfoIcon />
            </InfoIconWrapper>
          </WithTip>
        </TippedRowFlex>
      ) : (
        <InputLabel isRequired={item.isRequired}>{item.label}</InputLabel>
      )}
      <InputInnerWrapper>
        <PrefixWrapper
          isEditDetails={isEditDetails}
          ref={prefixRef}
          prefixMarginLeft={prefixMarginLeft}
          isVisible={!!item.defaultPrefix}
        >
          {item.defaultPrefix}
        </PrefixWrapper>

        {item.label === labelStrings.country && isEditDetails ? (
          <Select
            id="country-select"
            disabled={item.isDisabled}
            value={countries.find(el => el.value === item.value)}
            isEditDetails={isEditDetails}
            options={countries}
            isValid={item.isValid}
            selectHeight={43}
            onChange={item.onChange}
            escapeClearsValue={true}
            placeholder={labelStrings.selectCountry}
          />
        ) : (
          <Input
            value={item.value}
            onChange={item.onChange}
            noFocusEffect
            disabled={item.isDisabled}
            isEditDetails={isEditDetails}
            isValid={item.isValid}
            withPrefix={!!item.defaultPrefix}
            prefixWidth={!!item.defaultPrefix && getPrefixWidth()}
            {...(item.onFocus && { onFocus: item.onFocus })}
            {...(item.onBlur && { onBlur: item.onBlur })}
          />
        )}
      </InputInnerWrapper>
    </InputBlockWrapper>
  )
}

BillingFormInput.propTypes = {
  item: PropTypes.object,
  isEditDetails: PropTypes.bool,
}

export default BillingFormInput
