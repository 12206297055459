import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  userGrantSelector,
  userAudienceTokensSelector,
  userOneTimeTokensSelector,
} from '../../../../redux/selectors'
import { PRICING_PLANS_ELEMENT_ID } from '../../../../constants/appSettings'
import { scrollElementIntoView } from '../../../../utils'
import useTranslation from '../../../../localization/useTranslation'
import BuyAudienceTokensModal from '../changeAddOns/BuyAudienceTokensModal'
import ReusableAddOnSection from './ReusableAddOnSection'
import { withReduxActions } from '../../../../hocs/withRedux'
import { purchaseOneTimeItems } from '../../../../redux/ducks/userDucks'

const AudienceTokensAddOnSection = ({ purchaseOneTimeItems }) => {
  const { labelStrings } = useTranslation()

  const { isEnterprise, in_trial } = useSelector(userGrantSelector)
  const {
    audienceTokensUsed,
    tokensResetDate,
    audienceTokensTotal,
    audienceTokensMonthly,
  } = useSelector(userAudienceTokensSelector)
  const oneTimeTokens = useSelector(userOneTimeTokensSelector)

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [addonId, setAddonId] = useState('')

  const showAddon = (audienceTokensUsed && audienceTokensTotal) !== 0;
  const isAbleToBuyTokens = !!isEnterprise && !in_trial

  const onClickChangeToken = () => {
    scrollElementIntoView(PRICING_PLANS_ELEMENT_ID)
  }

  const openConfirmModal = addonId => () => {
    setIsConfirmModalOpen(true)
    setAddonId(addonId)
  }

  const handleSubmit = () => {
    purchaseOneTimeItems(addonId)
    setIsConfirmModalOpen(false)
  }

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false)
  }

  const tokensTexts = {
    currentTokens: labelStrings.audienceTokensFromEnterprisePackage(audienceTokensTotal),
    oneTimeTokens,
  }

  const tokensLeftNum = audienceTokensTotal - audienceTokensUsed

  return (
    showAddon && (
      <>
        <ReusableAddOnSection
          title={labelStrings.audienceReportTokens}
          changeTokenLabel={labelStrings.changeAudienceTokens}
          onClickChangeToken={onClickChangeToken}
          tokensLeft={`${labelStrings.tokensLeft(
            tokensLeftNum
          )} (${audienceTokensUsed}/${audienceTokensTotal} ${labelStrings.used})`}
          audienceTokensMonthly={audienceTokensMonthly}
          tokensTexts={tokensTexts}
          progressbar={{
            limit: audienceTokensTotal,
            current: audienceTokensUsed,
          }}
          tokensResetDate={tokensResetDate}
          isChangeTokenDisabled={false}
          isAbleToBuyTokens={isAbleToBuyTokens}
          openConfirmModal={openConfirmModal}
        />
        <BuyAudienceTokensModal
          onSubmit={handleSubmit}
          open={isConfirmModalOpen}
          onClose={closeConfirmModal}
        />
      </>
    )
  )
}

AudienceTokensAddOnSection.propTypes = {
  purchaseOneTimeItems: PropTypes.func,
}

export default withReduxActions({ purchaseOneTimeItems })(AudienceTokensAddOnSection)
