import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'
import { IG_SCORE_NAMES } from '../../../constants/profile'
import { USER_INDEXES } from '../../../constants/appSettings'
import ComparisonToSimilarInfluencers from '../../qualityScores/ComparisonToSimilarInfluencers'
import RatingDescriptionTip from './ratingDescriptionTip/RatingDescriptionTip'
import NoDataMessage from './NoDataMessage'
import {
  SectionTitleWrapper,
  SectionTitleName,
  SectionTitleBorder,
} from '../commonProfilePageStyles'
import {
  RatingSectionContainer,
  RatingSectionGrid,
  RatingSectionGridItem,
  RatingTitleWrapper,
  RatingTitle,
  RatingWrapper,
} from './commonStyles'

const Activity = ({ platform, profileScores, isForPdf = false }) => {
  const { labelStrings, scoreStrings } = useTranslation()
  const igScoreStrings = scoreStrings[USER_INDEXES.instagram]

  const { postFrequencyObject, storyFrequencyObject, reelFrequencyObject } = profileScores || {}

  const scoresArr = [
    {
      name: IG_SCORE_NAMES.postFrequency,
      data: postFrequencyObject,
      label: labelStrings.nPostsPerWeek(postFrequencyObject?.label),
    },
    {
      name: IG_SCORE_NAMES.storyFrequency,
      data: storyFrequencyObject,
      label: labelStrings.nStoriesPerDay(storyFrequencyObject?.label),
    },
    {
      name: IG_SCORE_NAMES.reelFrequency,
      data: reelFrequencyObject,
      label: labelStrings.nReelsPerWeek(reelFrequencyObject?.label),
    },
  ]

  return (
    <RatingSectionContainer isForPdf={isForPdf}>
      <SectionTitleWrapper>
        <SectionTitleName platform={platform} isForPdf={isForPdf}>
          {labelStrings.activity}
        </SectionTitleName>
        <SectionTitleBorder isForPdf={isForPdf} />
      </SectionTitleWrapper>

      <RatingSectionGrid columnsCount={!isForPdf ? 3 : 2} isForPdf={isForPdf}>
        {scoresArr.map((scoreItem, index) => {
          const { name: scoreName, label, data: scoreData } = scoreItem
          const { benchmarkArray, xAxisHigh, xAxisMid, xAxisLow } = scoreData || {
            benchmarkArray: [],
            xAxisHigh: null,
            xAxisLow: null,
            xAxisMid: null,
          }
          const xAxisValues = [xAxisHigh, xAxisMid, xAxisLow]

          return (
            <RatingSectionGridItem key={index}>
              <RatingTitleWrapper isForPdf={isForPdf}>
                <RatingTitle>{igScoreStrings[scoreName].title}</RatingTitle>
                {!isForPdf && (
                  <RatingDescriptionTip scoreDesc={igScoreStrings[scoreName].description} />
                )}
              </RatingTitleWrapper>

              {!!scoreData ? (
                <RatingWrapper justifyContent="center" isForPdf={isForPdf}>
                  <ComparisonToSimilarInfluencers
                    label={label}
                    benchmarkArray={benchmarkArray}
                    xAxisValues={xAxisValues}
                  />
                </RatingWrapper>
              ) : (
                <NoDataMessage isForPdf={isForPdf} />
              )}
            </RatingSectionGridItem>
          )
        })}
      </RatingSectionGrid>
    </RatingSectionContainer>
  )
}

Activity.propTypes = {
  profileScores: PropTypes.object.isRequired,
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  isForPdf: PropTypes.bool,
}

export default Activity
