import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useTranslation from '../../../localization/useTranslation'
import GradientTransparentButton from '../../common/buttons/GradientTransparentButton'
import LoadingSpinner from '../../common/spinners/LoadingSpinner'
import { CloseIcon } from '../../common/icons/icons'

const Wrapper = styled.div`
  position: relative;
  transition: all 0.2s ease;
  overflow: hidden;
  ${props =>
    props.anySelected
      ? `
    opacity: 1;
    pointer-events: auto;
    height: 50px;
    margin: 20px 0;
      `
      : `
    opacity: 0;
    pointer-events: none;
    height: 0;
    margin: 0;
  `}

  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > button {
    margin-left: 10px;
  }
`
const CloseSuccessWrapper = styled.span`
  margin-left: 10px;
  cursor: pointer;
`

const ActionText = styled.span`
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  font-family: ${props => props.theme.fontFamilyRoboto};
  letter-spacing: 0;
  color: ${props => props.theme.colors.brown};
  margin-right: 12px;
`

const SuccessMessageWrapper = styled.div`
  font-size: 14px;
  line-height: 19px;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.salmon};
  color: ${props => props.theme.colors.white};
  border-radius: 12px;

  & svg {
    line-height: 1;

    path {
      fill: ${props => props.theme.color.white};
    }
  }
`

const CollectionsBulkActions = ({
  isAnySelected,
  isLoading,
  showSuccessMsg,
  successOperationDetails = {},
  closeSuccessMsg,
  buttonsClicker,
  modalsTypes = {},
}) => {
  const { labelStrings } = useTranslation()
  const SUCCESS_MSG_PARTS = {
    move: labelStrings.movedNItems,
    copy: labelStrings.copiedNItems,
    remove: labelStrings.removedNItems,
  }

  const optionsRenderList = [
    { text: labelStrings.remove, onClick: buttonsClicker(modalsTypes.remove) },
    { text: labelStrings.move, onClick: buttonsClicker(modalsTypes.move) },
    { text: labelStrings.copy, onClick: buttonsClicker(modalsTypes.copy) },
  ]

  const successMsgDynamicLabel = SUCCESS_MSG_PARTS[successOperationDetails.type]

  return (
    <Wrapper anySelected={isAnySelected}>
      {isLoading && <LoadingSpinner isTransparent height="100%" width="100%" />}

      {showSuccessMsg && (
        <SuccessMessageWrapper>
          {successMsgDynamicLabel && successMsgDynamicLabel(successOperationDetails.itemsCount)}
          {!!successOperationDetails.duplicateCount && (
            <>
              {'. '} {labelStrings.alreadyInCollectionItems(successOperationDetails.duplicateCount)}
            </>
          )}
          <CloseSuccessWrapper onClick={closeSuccessMsg}>
            <CloseIcon />
          </CloseSuccessWrapper>
        </SuccessMessageWrapper>
      )}
      {!showSuccessMsg && (
        <>
          <ActionText>{labelStrings.bulkActionText}</ActionText>
          {optionsRenderList.map((item,index) => (
            <GradientTransparentButton onClick={item.onClick} key={index}>
              {item.text}
            </GradientTransparentButton>
          ))}
        </>
      )}
    </Wrapper>
  )
}

CollectionsBulkActions.propTypes = {
  isAnySelected: PropTypes.bool,
  isLoading: PropTypes.bool,
  showSuccessMsg: PropTypes.bool,
  successOperationDetails: PropTypes.object,
  closeSuccessMsg: PropTypes.func,
  buttonsClicker: PropTypes.func,
  modalsTypes: PropTypes.object,
}

export default CollectionsBulkActions
