import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { ColumnAreaChart } from '../../components/chart/charts/ColumnAreaChart'
import { Sentiment } from './Sentiment'
import { Goals } from './Goals'
import { StackedColumnChart } from '../../components/chart/charts'
import useTranslation from '../../../localization/useTranslation'
import { pretifyBigNum } from '../../../utils'
import { separateDataPublishedContent } from './utils'

export const SentimentGoals = ({
  latestCommentsWithBrandData = {},
  mostLikedCommentsData = {},
  goalsData = [],
  sentimentBrandFocusGraph = {},
  sentimentControversialGraph = {},
  sentimentPositivityGraph = {},
  publishedContentChartData = {},
  latestContentWithSummariesData = {},
}) => {
  const { labelStrings } = useTranslation()

  const publishedContentData = separateDataPublishedContent(publishedContentChartData?.dataArray)

  const sentimentBrandFocusGraphData = separateDataPublishedContent(sentimentBrandFocusGraph?.value)

  const sortedSentimentControversialGraphData = sentimentControversialGraph?.value?.toSorted(
    (a, b) => a.averageControversialRate - b.averageControversialRate
  )
  const sentimentControversialGraphData = separateDataPublishedContent(
    sortedSentimentControversialGraphData
  )

  const sortedSentimentPositivityGraphData = sentimentPositivityGraph?.value?.toSorted(
    (a, b) => a.averagePositivityRate - b.averagePositivityRate
  )
  const sentimentPositivityGraphData = separateDataPublishedContent(
    sortedSentimentPositivityGraphData
  )

  if (
    !goalsData?.length &&
    !latestCommentsWithBrandData?.graphId &&
    !mostLikedCommentsData?.graphId &&
    !publishedContentChartData?.graphId
  )
    return null

  const generatePublishedContentReachYaxisOptions = () => {
    const maxContentPieces = Math.max(...publishedContentData?.publishedContentCount) || 1
    return [
      {
        axisTicks: {
          show: true,
        },
        labels: {
          show: !publishedContentChartData?.dataArray?.length ? false : true,
          formatter: function(value) {
            return pretifyBigNum(value)
          },
        },
        title: {
          text: publishedContentChartData?.dataArray?.length ? labelStrings.reach : '',
          offsetY: -156,
          offsetX: 25,
          rotate: 0,
        },
      },
      {
        max: maxContentPieces === 0 ? 1 : maxContentPieces * 3.3,
        min: 0,
        show: false,
      },
    ]
  }

  return (
    <Grid container spacing={2} sx={{ py: 5, display: 'flex' }}>
      {publishedContentChartData?.graphId && (
        <Grid item xs={12} md={12}>
          <ColumnAreaChart
            helperText={``}
            title="Published Content & Reach"
            subheader=""
            chart={{
              labels: publishedContentData?.date,
              series: [
                {
                  name: 'Actual Reach',
                  type: 'line',
                  fill: 'solid',
                  data: publishedContentData?.reachedUsersSum,
                },
                {
                  name: 'Content Pieces',
                  type: 'column',
                  fill: 'solid',
                  data:
                    publishedContentData?.publishedContentCount?.length === 1 &&
                    publishedContentData?.publishedContentCount[0] === 0
                      ? []
                      : publishedContentData?.publishedContentCount,
                },
              ],
              colors: [
                '#FFBC00',
                '#2D99FF',
                '#ff4441',
                '#CA5AFF',
                '#0366A3',
                '#FF6C40',
                '#C4CDD5',
                '#2D99FF',
                '#1C7CDB',
                '#057ABF',
                '#015287',
              ],
              isData: publishedContentChartData?.dataArray,
              options: {
                yaxis: generatePublishedContentReachYaxisOptions(),
                tooltip: {
                  enabled: true,
                  shared: true,
                  intersect: false,
                  y: {
                    formatter: (value, obj, t) => {
                      const text =
                        obj?.seriesIndex === 0
                          ? 'Users'
                          : obj?.seriesIndex === obj?.series?.length - 1
                          ? 'Uploads'
                          : ''
                      if (typeof value !== 'undefined') {
                        return `${pretifyBigNum(value)} ${text}`
                      }
                      return value
                    },
                  },
                },
              },
            }}
          />
        </Grid>
      )}

      {/* 
      {!mostLikedCommentsData?.value?.length &&
      !latestCommentsWithBrandData?.value?.length &&
      !latestContentWithSummariesData?.value?.length ? null : ( */}
      <Grid item xs={12} md={12}>
        <Sentiment
          mostLikedComments={mostLikedCommentsData}
          latestCommentsWithBrand={latestCommentsWithBrandData}
          latestContentWithSummariesData={latestContentWithSummariesData}
        />
      </Grid>

      {sentimentBrandFocusGraph?.value?.length > 0 && (
        <Grid item sm={4} md={4}>
          <StackedColumnChart
            title={labelStrings.sentimentCharts.brandFocus.label}
            helperText={labelStrings.sentimentCharts.brandFocus.helperText}
            chart={{
              labels: sentimentBrandFocusGraphData?.username,
              colors: ['#CA5AFF', '#FFE700', '#FF6C40', '#C4CDD5'],
              series: [
                {
                  data: [
                    {
                      name: labelStrings.sentimentCharts.brandFocus.legend.brandFocusProduct,
                      data: sentimentBrandFocusGraphData?.averageBrandFocusRate,
                      countedContent: sentimentBrandFocusGraphData?.countedContent,
                    },
                    {
                      name: labelStrings.sentimentCharts.brandFocus.legend.creatorFocus,
                      data: sentimentBrandFocusGraphData?.averageCreatorFocusRate,
                      countedContent: sentimentBrandFocusGraphData?.countedContent,
                    },
                    {
                      name: labelStrings.sentimentCharts.brandFocus.legend.lowQuality,
                      data: sentimentBrandFocusGraphData?.averageLowQualityRate,
                      countedContent: sentimentBrandFocusGraphData?.countedContent,
                    },
                  ],
                },
              ],
              options: {
                yaxis: {
                  labels: {
                    formatter: function(value) {
                      return `${pretifyBigNum(value)}%`
                    },
                  },
                },
                plotOptions: {
                  bar: {
                    borderRadius: 4,
                    columnWidth: '40%',
                    dataLabels: {
                      position: 'top',
                      total: {
                        enabled: true,
                        style: {
                          fontSize: '13px',
                          fontWeight: 500,
                          colors: ['#212B36'],
                        },
                        formatter: function(value, other) {
                          const {
                            dataPointIndex,
                            config: { series },
                          } = other

                          return `(${series[0]?.countedContent[dataPointIndex]})`
                        },
                      },
                    },
                  },
                },
              },
            }}
          />
        </Grid>
      )}
      {sentimentPositivityGraph?.value?.length > 0 && (
        <Grid item sm={4} md={4}>
          <StackedColumnChart
            title={labelStrings.sentimentCharts.positivityRate.label}
            helperText={labelStrings.sentimentCharts.positivityRate.helperText}
            chart={{
              labels: sentimentPositivityGraphData?.username,
              colors: ['#6BB65A', '#FF6C40'],
              series: [
                {
                  data: [
                    {
                      name: labelStrings.sentimentCharts.positivityRate.legend.positiveSentiment,
                      data: sentimentPositivityGraphData?.averagePositivityRate,
                      countedContent: sentimentPositivityGraphData?.countedContent,
                    },
                  ],
                },
              ],
              options: {
                yaxis: {
                  labels: {
                    formatter: function(value) {
                      return `${pretifyBigNum(value)}%`
                    },
                  },
                },
                plotOptions: {
                  bar: {
                    borderRadius: 4,
                    columnWidth: '40%',
                    dataLabels: {
                      total: {
                        enabled: sentimentPositivityGraph.value.length <= 10 ? true : false,
                        style: {
                          fontSize: '13px',
                          fontWeight: 500,
                          colors: ['#212B36'],
                        },
                        formatter: function(value, other) {
                          const {
                            dataPointIndex,
                            config: { series },
                          } = other
                          return `${pretifyBigNum(value)}% (${
                            series[0]?.countedContent[dataPointIndex]
                          })`
                        },
                      },
                    },
                  },
                },
              },
            }}
          />
        </Grid>
      )}

      {sentimentControversialGraph?.value?.length > 0 && (
        <Grid item sm={4} md={4}>
          <StackedColumnChart
            title={labelStrings.sentimentCharts.controversialRate.label}
            helperText={labelStrings.sentimentCharts.controversialRate.helperText}
            chart={{
              labels: sentimentControversialGraphData?.username,
              colors: ['#FF6C40', '#6BB65A'],
              series: [
                {
                  data: [
                    {
                      name: labelStrings.sentimentCharts.controversialRate.legend.controversial,
                      data: sentimentControversialGraphData?.averageControversialRate,
                      countedContent: sentimentPositivityGraphData?.countedContent,
                    },
                  ],
                },
              ],
              options: {
                yaxis: {
                  labels: {
                    formatter: function(value) {
                      return `${pretifyBigNum(value, 2)}%`
                    },
                  },
                },
                plotOptions: {
                  bar: {
                    borderRadius: 4,
                    columnWidth: '40%',
                    dataLabels: {
                      total: {
                        enabled: sentimentPositivityGraph.value.length <= 10 ? true : false,
                        style: {
                          fontSize: '13px',
                          fontWeight: 500,
                          colors: ['#212B36'],
                        },
                        formatter: function(value, other) {
                          const {
                            dataPointIndex,
                            config: { series },
                          } = other
                          return `${pretifyBigNum(value, 2)}% (${
                            series[0]?.countedContent[dataPointIndex]
                          })`
                        },
                      },
                    },
                  },
                },
              },
            }}
          />
        </Grid>
      )}

      {!goalsData?.length ? null : (
        <Grid item xs={12} md={12}>
          <Goals goalsData={goalsData} />
        </Grid>
      )}
    </Grid>
  )
}

SentimentGoals.propTypes = {
  publishedContentChartData: PropTypes.object,
  goalsData: PropTypes.array,
  mostLikedCommentsData: PropTypes.object,
  latestCommentsWithBrandData: PropTypes.object,
  latestContentWithSummariesData: PropTypes.object,
  sentimentBrandFocusGraph: PropTypes.object,
  sentimentControversialGraph: PropTypes.object,
  sentimentPositivityGraph: PropTypes.object,
}