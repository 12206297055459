import { themeCommonStyles } from '../themes/infludataThemes'
import { COMPARED_RANKS_PROP_NAMES } from '../constants/comparedProfiles'
import { sortArrayOfObjects } from '../utils'
import { SORTING_DIRECTIONS } from '../constants/sortings'
import { SOCIAL_PLATFORMS_NAMES } from '../constants/appSettings'

class RelativeRankingService {
  calcComparedProfilesRankings({ profiles, rankPropNamesMap = COMPARED_RANKS_PROP_NAMES }) {
    // console.time('RANKS')
    
    let profilesList = Object.values(profiles)?.map(profile => {
      const ttRanks = profile._index === SOCIAL_PLATFORMS_NAMES.tiktok.index ? {
        totalPlays: profile?.totalViews,
        profileScore: profile.profileScore,
        postsPerWeek: profile.postsPerWeek,
        viralPosts:  (profile.viralCountArray[0] / (profile.viralCountArray[1] / 100)),
        expectedAudiencePerPost: profile.impressions,
      } : {}

      const ytRanks = profile._index === SOCIAL_PLATFORMS_NAMES.youtube.index ? {
        profileScoreYT: profile.profileScore.overallScore,
        engagementRatePosts: profile.engagementRatePosts,
        medianViewCountPosts: profile.medianViewCountPosts,
        engagementRateShorts: profile.engagementRateShorts,
        medianViewCountShorts: profile.medianViewCountShorts,
        growthRateYT: profile.monthlyGrowthFollowers && profile.monthlyGrowthFollowers[1],
        growthRateFollowersYT: profile.monthlyGrowthFollowers && profile.monthlyGrowthFollowers[0],
        averageViewsGrowthVideos: profile?.monthlyGrowthObject?.postViews?.monthlyChange,
        growthByAverageViews30dVideos: profile?.monthlyGrowthObject?.postViews?.monthlyDifference,
        averageViewsGrowthShorts: profile?.monthlyGrowthObject?.shortViews?.monthlyChange,
        growthByAverageViews30dShorts: profile?.monthlyGrowthObject?.shortViews?.monthlyDifference,
      } : {}

      return {
        ...profile,
        postWeek: Math.round(profile?.profileScore?.postFrequencyObject?.actualValue * 100) / 100,
        storiesDay: profile?.profileScore?.storyFrequencyObject?.actualValue,
        reelsWeek: profile?.profileScore?.reelFrequencyObject?.actualVaule,
        playsPerReel: profile?.medianPlaysPerReel,
        viewsPerPost: profile?.medianViewsPerPost,
        viewsPerStory: (profile?.medianViewsPerStoryObject?.from + profile?.medianViewsPerStoryObject?.to) / 2,
        monthlyGrowthDiff:  profile?.monthlyGrowthObject?.monthlyDifference,
        monthlyGrowthChange: profile?.monthlyGrowthObject?.monthlyChange,
        ...ttRanks,
        ...ytRanks
      }
    })
    const rankPropNamesList = Object.keys(rankPropNamesMap)

    // for averagePostTime we use decend sort, so less days between posts is better
    const decendSortPropsList = [rankPropNamesMap.avgPostTime]

    const ranks = {}
    for (const key of rankPropNamesList) {
      const prop = rankPropNamesMap[key]
      profilesList = sortArrayOfObjects({
        array: profilesList,
        sortKey: prop,
        mutate: true,
        transformStringToNumber: prop === rankPropNamesMap.engagement,
        sortDirection: decendSortPropsList.includes(prop)
          ? SORTING_DIRECTIONS.descend
          : SORTING_DIRECTIONS.ascend,
      })
      const items = profilesList.reduce((obj, p, idx) => {
        obj[p._id] = {
          rank: idx,
          colorIdx: this._getComparedProfilesRankColorsIdx(idx, profilesList.length - 1),
          value: p[prop],
        }
        return obj
      }, {})
      ranks[key] = items
    }
    // console.timeEnd('RANKS')
    return ranks
  }

  _getComparedProfilesRankColorsIdx = (rank, comapredProfilesLength) => {
    const { rankColors } = themeCommonStyles
    const rankRatio = rank / comapredProfilesLength
    const index = Math.round(rankColors?.length * rankRatio)
    return index ? index - 1 : 0
  }
}

export default new RelativeRankingService()
