// @ts-nocheck
import React from 'react'
import styled, { css } from 'styled-components'
import { USER_INDEXES } from '../../../constants/appSettings'
import { generateRandomId } from '../../../utils'
import { FocusEffect } from '../styledWrappers'

export const ButtonEffect = css`
  color: #ffffff;
  background: ${props => props.theme.platformColor};
  border-radius: 100vw;
  border: none;

  &:hover {
    background-color: ${props => !props.disableProps && props.theme.platformColorLight};
    transition: background-color 150ms;
  }

  ${props => !props.noFocusEffect && FocusEffect}
`

export const Button = styled.button`
  ${ButtonEffect}
`

export const ActionButton = styled.button`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 3.5px;
  color: #ffffff;
  border-radius: 100vw;
  padding: 12px 16px;
  text-transform: uppercase;
  height: 37px;
  border: none;
  ${props => props.disabled && props.theme.disableBlock};
  ${ButtonEffect}
  background: ${props => props.theme.buttonGradientBakground};
`

export const DialogActionButton = styled(ActionButton)`
  ${props => props.disabled && 'opacity: 0.8;'};
`

export const SmallButton = styled.button`
  font-family: ${props => props.theme.fontFamilyInter};
  font-weight: medium;
  font-size: 11px;
  line-height: 17px;
  letter-spacing: 0;
  text-transform: uppercase;
  background-color: ${props => props.theme.platformColor};
  ${ButtonEffect}
  margin-left: 24px;
`
export const ClearSearchButton = styled(Button)`
  border-radius: 9px;
  font-size: 15px;
  text-align: center;
  font-weight: 700;
  padding: 7px 16px;
  text-transform: uppercase;
  background: ${props => props.theme.getGradientsForPlatform(props.userindex).main};
`

export const RangeSlidersToggleButton = styled(SmallButton)`
  padding: 0 15px;
  margin: 0;
  height: 30px;
  font-weight: 500;
  position: relative;
  width: auto;
  color: ${props => (props.isSelected ? props.theme.color.white : props.theme.colors.darkBrown)};
  background-color: ${props =>
    props.isSelected ? props.theme.platformColor : props.theme.colors.lightMauve};
  &:hover {
    background-color: ${props =>
      props.isSelected ? props.theme.platformColor : props.theme.colors.lightMauve};
  }
  &:focus {
    border-color: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
`

export const OptionsButton = styled(Button)`
  background: ${props => props.theme.color.white};
  color: ${props =>
    props.isDisabled
      ? props.theme.color.midGrey
      : props.theme.pickColorDependOnPlatform(props.userindex).primary};
  border: 1px solid
    ${props =>
      props.isDisabled
        ? props.theme.color.midGrey
        : props.theme.pickColorDependOnPlatform(props.userindex).light};
  font-weight: 800;
  display: flex;
  align-items: center;
  // padding: 5px;
  font-size: 14px;
  ${props =>
    props.isActive &&
    `
    background-color: ${
      props.isDisabled
        ? props.theme.color.midGrey
        : props.theme.pickColorDependOnPlatform(props.userindex).primary
    };
    color: ${props.theme.color.white};
    & svg {
      path {
        fill: ${props.theme.color.white};
      }
    }
  `}
  & > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  &:active {
    border-color: ${props => props.theme.pickColorDependOnPlatform(props.userindex).primary};
    box-shadow: 0 0 0 1px ${props => props.theme.pickColorDependOnPlatform(props.userindex).primary};
    outline: none;
  }
  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${props => !props.disableProps && props.theme.pickColorDependOnPlatform(props.userindex).primary};
    color: ${props => !props.disableProps && props.theme.color.white};
    & svg {
      path {
        fill: ${props => props.theme.color.white};
      }
    }
  }
`
export const DeclineButton = styled.button`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 3.5px;
  color: #272727;
  background: ${props => props.theme.secondaryColor};
  border-radius: 100vw;
  padding: 12px 16px;
  text-transform: uppercase;
  border: none;
  ${FocusEffect}
`
export const SignButton = styled.button`
  outline: none;
  border: none;
  background: none;
  color: #ffffff;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0;
  margin: 0 15px;
  min-width: 70px;
  font-family: ${props => props.theme.fontFamilyInter};

  &.active {
    font-weight: bold;
  }
  &:focus,
  &:active {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
    color: #ffffff;
  }
`

export const ProfileButton = styled(SignButton)`
  min-width: unset;
  margin-right: 7px !important;
  &:hover {
    opacity: 0.6;
  }
`

export const TryForFreeButton = styled(OptionsButton)`
  min-height: 37px;
  height: 100%;
  width: 112px;
  // box-shadow: 0px 0px 15px #cc440e88;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 2.1px;
  font-family: ${props => props.theme.fontFamilyInter};
  border-radius: unset;
  border: none;
  color: ${props => props.theme.color.white};
  background: ${props => props.theme.yellowGradient};
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
  &:active {
    opacity: 0.85;
  }

  ${props => props.theme.flex.centerAll}
`

export const PrimaryDefaultButton = styled(OptionsButton)`
  border: 1px solid ${props => props.theme.pickColorDependOnPlatform(props.userindex).primary};
  border-radius: 10px;
  color: ${props => props.theme.pickColorDependOnPlatform(props.userindex).primary};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  font-family: ${props => props.theme.fontFamilyInter};
  padding: 5px 10px;
  word-break: break-word;
  ${props =>
    props.isBig &&
    `
    font-size: 15px;
    padding: 8px 10px;
    border-radius: 14px;
  `}
  ${props =>
    props.isDisabled &&
    `
    pointer-events: none;
    color: ${props.theme.color.grey};
    border: 1px solid ${props.theme.color.grey};
    opacity: 0.8;
  `}
  ${props => props.withMaxWidth && `max-width: ${props.maxWidth};`}
  ${props => props.noBG && 'background: transparent;'}
`

export const PlaformSwitchButton = styled(PrimaryDefaultButton)`
  ${props => props.isDisabled && props.theme.disabled}
  border-radius: 8px;
  margin-right: 4px;
  padding: 5px 16.5px;
  outline: none;
  ${props => props.isExplore && `font-size: 11.5px;`}
  
  text-align: center;

  &:focus {
    outline: none;
    border: 1px solid transparent;
  }

  &:active {
    border: 1px solid transparent;
    box-shadow: unset;
  }

  ${props =>
    props.isSelected
      ? `
        border: solid 1px transparent;
        background: ${props.theme.getGradientsForPlatform(props.userindex).main};
        background-origin: border-box;
        color: ${props.theme.color.white};
      `
      : `
        border: 1px solid ${props.theme.colors.mauve};
        background: ${props.isRainbowUnselected ? 'linear-gradient(135deg, violet, indigo, blue, green, darkorange, red)' : 'transparent'};
        color: ${props.isRainbowUnselected ? props.theme.color.white : props.theme.colors.mauve};
      `}
  
  &:hover {
    ${props => !props.isSelected && `
      border: 1px solid transparent;
      background: ${props.isRainbowUnselected ? 'red' : props.theme.pickColorDependOnPlatform(props.ownIndex).primary};
      color: ${props.theme.color.white};
    `}
  }
`;



const StyledGradientButton = styled.button.attrs(({ isDisabled }) => ({ disabled: isDisabled }))`
  padding: 10px 15px;
  border-radius: 9px;
  border: none;
  font-family: ${props => props.theme.fontFamilyInter};
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 15px;
  color: ${props => props.theme.colors.white};
  user-select: none;

  ${props => {
    let bg = props.theme.gradients.main
    let bgHover = props.theme.gradients.darkOrange
    let bgDisabled = props.theme.colors.mediumBrown

    if (props.platform === USER_INDEXES.tiktok) {
      bg = props.theme.gradients.mainTiktok
      bgHover = props.theme.gradients.blueSalmon
      bgDisabled = props.theme.colors.aquaUltraLight
    }

    if (props.platform === USER_INDEXES.youtube) {
      bg = props.theme.gradients.mainYoutube
      bgHover = props.theme.gradients.mainYoutubeOpacity
      bgDisabled = props.theme.colors.mediumBrown
    }

    return `
      background: ${props.isDisabled ? bgDisabled : bg};

      &:hover {
        background: ${props.isDisabled ? bgDisabled : bgHover};
      }
    `
  }}
`

export const GradientButton = ({ id, ...props }) => (
  <StyledGradientButton id={id || generateRandomId()} {...props} />
)

export const StyledCancelButton = styled.button.attrs(({ isDisabled }) => ({
  disabled: isDisabled,
}))`
  border: none;
  background: none;
  font-size: 15px;
  line-height: 19px;
  text-decoration: underline;
  color: ${props => props.theme.colors.darkBrown};
`

export const CancelButton = ({ id, ...props }) => (
  <StyledCancelButton id={id || generateRandomId()} {...props} />
)
