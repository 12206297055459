import PropTypes from 'prop-types'

export const PRO_PLAN_ADDITIONAL_FIELDS_LIMIT = 20

export const PLAN_ADDITIONAL_FIELDS_LIMIT = 20

export const ADDITIONAL_FIELD_TYPES = {
  string: 'string',
  number: 'number',
  date: 'date',
  boolean: 'boolean',
  singleSelect: 'singleselect',
  multiSelect: 'multiselect',
}

export const ADDITIONAL_FIELD_TYPES_ARR = Object.keys(ADDITIONAL_FIELD_TYPES).map(
  key => ADDITIONAL_FIELD_TYPES[key]
)

export const ADDITIONAL_FIELD_PROP_TYPE = PropTypes.shape({
  code: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf([...ADDITIONAL_FIELD_TYPES_ARR, '']),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  allowedValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  created: PropTypes.string,
  isHidden: PropTypes.bool,
})
