import React from 'react'
import { Box, Button, Card, Stack, Typography } from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import useTranslation from '../../../../localization/useTranslation'
import { DonutChart } from '../Charts/DonutChart'
import { HeatMapChart } from '../Charts/HeatMapChart'

export const YourOverlapsCharts = props => {
  const { currentCreatorOverlap, goBack } = props

  const { labelStrings } = useTranslation()

  return (
    <Stack spacing={2} sx={{ pb: '20px' }}>
      <Stack sx={{ pt: '6px' }}>
        <Button
          sx={{
            width: 'fit-content',
            color: '#784E4E',
            borderColor: '#784E4E',
            '&:hover': {
              borderColor: '#784E4E',
            },
          }}
          onClick={goBack}
          variant="outlined"
          startIcon={<ArrowBackRoundedIcon />}
        >
          {labelStrings.back}
        </Button>
      </Stack>

      <Card
        sx={{
          backgroundColor: '#FFF9F9',
          pt: '20px',
        }}
      >
        <Stack
          spacing={5}
          direction="row"
          alignItems="center"
          sx={{ borderBottom: `1px solid #F4E8E8`, px: '35px', pb: '15px' }}
        >
          <Stack direction="row" spacing={0.5}>
            <Typography
              sx={{ fontSize: '13px', lineHeight: '16px', fontWeight: 'bold', color: '#BC9797' }}
            >
              {labelStrings.totalInfluencers}:
            </Typography>
            <Typography
              sx={{ fontSize: '13px', lineHeight: '16px', fontWeight: 'bold', color: '#442424' }}
            >
              {currentCreatorOverlap?.overlap?.influencerNameList?.length}
            </Typography>
          </Stack>
        </Stack>

        <Box sx={{ pt: '20px' }}>
          <DonutChart
            totalOverlap={currentCreatorOverlap?.overlap?.totalScore}
            tabSelect={currentCreatorOverlap?.platform}
          />
        </Box>
        <Box>
          <HeatMapChart
            metadata={currentCreatorOverlap?.overlap?.collectionOverlap}
            userdata={currentCreatorOverlap?.influencerMetadata}
            tabSelect={currentCreatorOverlap.platform}
          />
        </Box>
      </Card>
    </Stack>
  )
}
