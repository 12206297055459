import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { RoundedInput, Label, DialogTitle } from '../../common/styledWrappers'
import { ActionButton } from '../../common/buttons/styledButtons'
import { validateEmail } from '../../../utils'
import useTranslation from '../../../localization/useTranslation'

const FormWrapper = styled.form`
  ${props => props.theme.flex.centerAll};
  flex-direction: column;
  justify-content: space-around;
  margin-top: 35px;
  width: 90%;
  height: 200px;
  position: relative;
`

const InputBlockWrapper = styled.div`
  width: 100%;
  ${props => props.theme.flex.centerAll};
  position: relative;
  flex-direction: column;
`

const Input = styled(RoundedInput)`
  height: 37px;
  font-size: 14px;
  color: ${props => props.theme.textColor};
  background: ${props => props.theme.color.white};
  ${props => !props.isValid && `border-color: ${props.theme.primaryColor};`}
`

const InputLabel = styled(Label)`
  color: #3e4860;
  margin-bottom: 5px;
`

const NotValidLabel = styled.span`
  text-align: center;
  position: absolute;
  top: 0;
  right: 20px;
  font-size: 11px;
  color: ${props => props.theme.primaryColor};
`

const BackaendLoginFailLabel = styled(NotValidLabel)`
  font-size: 14px;
  top: -25px;
  left: 40px;
`

const TipWrapper = styled.div`
  color: ${props => props.theme.color.grey};
  font-size: 14px;
  font-family: ${props => props.theme.fontFamilyInter};
  text-align: center;
`

const ButtonsGroupWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`

const EmailToGetLinkForm = ({ sendLinkToEmail, noUserFound = false, resetNoUserFound }) => {
  const [formDataObj, setFormDataObj] = useState({
    email: '',
    isValidEmail: true,
  })

  const { labelStrings } = useTranslation()

  const changeFormDataObj = newValueObj => setFormDataObj({ ...formDataObj, ...newValueObj })

  const onEmailChange = ({ target }) => changeFormDataObj({ email: target.value })

  const resetValidation = () => {
    if (noUserFound) resetNoUserFound()
    changeFormDataObj({ isValidEmail: true })
  }
  const onSubmit = async e => {
    e.preventDefault()
    if (noUserFound) return
    const isValidEmail = !!formDataObj.email && validateEmail(formDataObj.email)
    if (!isValidEmail) return changeFormDataObj({ isValidEmail })
    const { email } = formDataObj
    await sendLinkToEmail(email.toLowerCase())
  }

  return (
    <>
      <DialogTitle>{labelStrings.restorePassword}</DialogTitle>

      <FormWrapper onSubmit={onSubmit}>
        <TipWrapper>{labelStrings.weWillSendPassRecoveryLink}</TipWrapper>
        {noUserFound && (
          <BackaendLoginFailLabel>{labelStrings.noUserWithSuchEmail}</BackaendLoginFailLabel>
        )}
        <InputBlockWrapper>
          <InputLabel htmlFor="email_input">{labelStrings.email}</InputLabel>
          {!formDataObj.isValidEmail && <NotValidLabel>{labelStrings.notValidEmail}</NotValidLabel>}
          <Input
            id="email_input"
            type="text"
            noFocusEffect
            onChange={onEmailChange}
            onFocus={resetValidation}
            value={formDataObj.email}
            isValid={formDataObj.isValidEmail}
          />
        </InputBlockWrapper>
        <ButtonsGroupWrapper>
          <ActionButton type="submit">{labelStrings.send}</ActionButton>
        </ButtonsGroupWrapper>
      </FormWrapper>
    </>
  )
}

EmailToGetLinkForm.propTypes = {
  sendLinkToEmail: PropTypes.func,
  noUserFound: PropTypes.bool,
  resetNoUserFound: PropTypes.func,
}

export default EmailToGetLinkForm
