export const IG_SCORE_NAMES = {
  postFrequency: 'postFrequency',
  storyFrequency: 'storyFrequency',
  reelFrequency: 'reelFrequency',
  postsLikes: 'postsLikes',
  postsComments: 'postsComments',
  growthRate: 'growthRate',
  reelsPlays: 'reelsPlays',
  reelsEngagement: 'reelsEngagement',
  reelsWatchTime: 'reelsWatchTime',
  suspiciousActivities: 'suspiciousActivities',
  suspiciousActivitiesFollowers: 'suspiciousActivitiesFollowers',
  sponsoredPosts: 'sponsoredPosts',
  sponsoredStories: 'sponsoredStories',
}

export const YT_SCORE_NAMES = {
  video: 'video',
  shorts: 'shorts',
  growthRate: 'growthRate',
  positiveReactions: 'positiveReactions',
  negativeReactions: 'negativeReactions',
  videoLikes: 'videoLikes',
  videoComments: 'videoComments',
  videoEngagement: 'videoEngagement',
  videoViewsTotal: 'videoViewsTotal',
  videoViewsGrowth: 'videoViewsGrowth',
  videoAverageDuration: 'videoAverageDuration',
  shortsLikes: 'shortsLikes',
  shortsComments: 'shortsComments',
  shortsEngagement: 'shortsEngagement',
  shortsViewsTotal: 'shortsViewsTotal',
  shortsViewsGrowth: 'shortsViewsGrowth',
  shortsAverageDuration: 'shortsAverageDuration',
  followersChangeObject: 'followersChangeObject'
}

export const IG_SCORE_NAMES_ARRAY = Object.keys(IG_SCORE_NAMES).map(key => IG_SCORE_NAMES[key])

export const TT_SCORE_NAMES = {
  averageDurationPosts: 'averageDurationPosts',
  commentEngagementPosts: 'commentEngagementPosts',
  engagementPosts: 'engagementPosts',
  profileScore: 'profileScore',
  likeEngagementPosts: 'likeEngagementPosts',
  postFrequency: 'postFrequency',
  viewsPerPost: 'viewsPerPost',
  growthFollowers12M: 'growthFollowers12M',
  growthFollowers6M: 'growthFollowers6M',
  growthFollowers1M: 'growthFollowers1M',
  growthViews12M: 'growthViews12M',
  growthViews6M: 'growthViews6M',
  growthViews1M: 'growthViews1M',
  /* temp */
  postsPerWeek: 'postsPerWeek',
  viralPosts: 'viralPosts',
  oneHitWonder: 'oneHitWonder',
  expectedAudiencePerPost: 'expectedAudiencePerPost',
  engagementRate: 'engagementRate',
  videoAverageDuration: 'videoAverageDuration',
  /* */
}

export const TT_SCORE_NAMES_ARRAY = Object.keys(TT_SCORE_NAMES).map(key => TT_SCORE_NAMES[key])

export const COMPARE_INFLUENCERS_SCORES_ARRAY = [
  { name: 'postsLikes', porfileObjScoreName: 'likeEngagementObject' },
  { name: 'postsComments', porfileObjScoreName: 'commentEngagementObject' },
  { name: 'growthRate', porfileObjScoreName: 'followerChangeObject' },
  { name: 'reelsPlays', porfileObjScoreName: 'playsPerReelObject' },
  { name: 'reelsEngagement', porfileObjScoreName: 'engagementReelsObject' },
  { name: 'reelsWatchTime', porfileObjScoreName: 'reelsWatchTimeObject' },
  {
    name: 'suspiciousActivitiesFollowers',
    porfileObjScoreName: 'suspiciousFollowersObject',
  },
]
export const COMPARE_INFLUENCERS_SCORES_ARRAY_YT = [
  { name: 'growthRate', porfileObjScoreName: 'followersChangeObject' },
  { name: 'positiveReactions', porfileObjScoreName: 'sentimentPositiveObject' },
  { name: 'negativeReactions', porfileObjScoreName: 'sentimentNegativeObject' },
  { name: 'shortsLikes', porfileObjScoreName: 'likeEngagementShortsObject' },
  { name: 'shortsComments', porfileObjScoreName: 'commentEngagementShortsObject' },
  { name: 'shortsEngagement', porfileObjScoreName: 'engagementShortsObject' },
  { name: 'shortsViewsTotal', porfileObjScoreName: 'viewsPerShortObject' },
  { name: 'shortsViewsGrowth', porfileObjScoreName: 'shortsViewChangeObject' },
  { name: 'shortsAverageDuration', porfileObjScoreName: 'averageDurationShortsObject' },
  { name: 'videoLikes', porfileObjScoreName: 'likeEngagementPostsObject' },
  { name: 'videoComments', porfileObjScoreName: 'commentEngagementPostsObject' },
  { name: 'videoEngagement', porfileObjScoreName: 'engagementPostsObject' },
  { name: 'videoViewsTotal', porfileObjScoreName: 'viewsPerPostObject' },
  { name: 'videoViewsGrowth', porfileObjScoreName: 'postsViewChangeObject' },
  { name: 'videoAverageDuration', porfileObjScoreName: 'averageDurationPostsObject' },
]

export const AUDIENCE_AGE_RANGES = {
  mapping: [13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35, 37],
  presentationMapping: [
    '13',
    '15',
    '17',
    '19',
    '21',
    '23',
    '25',
    '27',
    '29',
    '31',
    '33',
    '35',
    '>37',
  ],
}

export const AUDIENCE_GENDER_OPTIONS = {
  onlyMale: 'only_male',
  mostlyMale: 'mostly_male',
  ratherMale: 'rather_male',
  off: 'off',
  ratherFemale: 'rather_female',
  mostlyFemale: 'mostly_female',
  onlyFemale: 'only_female',
}

export const AUDIENCE_GENDER_RANGES = {
  [AUDIENCE_GENDER_OPTIONS.onlyMale]: -3,
  [AUDIENCE_GENDER_OPTIONS.mostlyMale]: -2,
  [AUDIENCE_GENDER_OPTIONS.ratherMale]: -1,
  [AUDIENCE_GENDER_OPTIONS.off]: 0,
  [AUDIENCE_GENDER_OPTIONS.ratherFemale]: 1,
  [AUDIENCE_GENDER_OPTIONS.mostlyFemale]: 2,
  [AUDIENCE_GENDER_OPTIONS.onlyFemale]: 3,
}

export const YT_TAB_VIDEOS = 'ytVideos'
export const YT_TAB_SHORTS = 'ytShorts'
export const LATEST_SHORTS = 'LATEST_SHORTS'
export const LATEST_POSTS = 'LATEST_POSTS'
export const LATEST_REELS = 'LATEST_REELS'
export const LATEST_STORIES = 'LATEST_STORIES'
export const MOST_VIEWED_POSTS = 'MOST_VIEWED_POSTS'
export const MOST_VIEWED_REELS = 'MOST_VIEWED_REELS'

export const INITIAL_MODAL_OPTIONS_IG = [
  // { value: true, id: `hideYourCustomFields` },
  { value: true, id: `showQualityScores` },
  { value: true, id: `showAudienceReport` },
  { value: true, id: `showFirstPage` },
  { value: true, id: `showFollowerTrendGraph` },
  { value: true, id: `showBrandMentions` },
  { value: true, id: `showSmartMediaValue` },
  { value: true, id: `includeInfludataBranding` },
]

export const INITIAL_MODAL_OPTIONS_TT = [
  // { value: true, id: `hideYourCustomFields` },
  { value: true, id: `showQualityScores` },
  { value: true, id: `showAudienceReport` },
  { value: true, id: `showFirstPage` },
  { value: true, id: `showFollowerTrendGraph` },
  { value: true, id: `includeInfludataBranding` },
]

export const INITIAL_MODAL_OPTIONS_YT = [
  // { value: true, id: `hideYourCustomFields` },
  { value: true, id: `showQualityScores` },
  { value: true, id: `showAudienceReport` },
  { value: true, id: `showFirstPage` },
  { value: true, id: `showFollowerTrendGraph` },
  { value: true, id: `showSmartMediaValue` },
  { value: true, id: `includeInfludataBranding` },
]
