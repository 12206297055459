import {Box, styled, Typography } from '@mui/material'

export const StyledHeading = styled(Typography)({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#212B36',
  marginBottom: '5px'
})

export const StyledSubheader= styled(Typography)({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '22px',
  color: '#212B36',
  marginBottom: '5px'
})
