import React, { forwardRef } from 'react'
import { Stack, Box } from '@mui/material'
import { Portal } from '../../../../common/Portal/Portal'
import styled from 'styled-components'
import { LastPdfPage } from './pages/LastPage'
import { FirstPagePdf } from './pages/FirstPage'

const ProfilePdfDocumentHideWrapper = styled.div`
  /* Hide redered components */
  position: fixed;
  top: -9999px;
  left: -9999px;
  z-index: -9999;

  /* Show rendered components (for dev) */
  ${'' /* position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: scroll;
  z-index: 9999;
  background: white;
  border: 1px solid black; */}
`

export const BrandValuePerformancePdfView = forwardRef(({ profile, currency }, ref) => {
  return (
    <Portal wrapperId="profile-pdf-brand-performance">
      <ProfilePdfDocumentHideWrapper>
        <Stack ref={ref} sx={{ backgroundColor: '#FCF3F3', px: 5, py: 3 }}>
          <>
            <Box sx={{ height: '1905px' }}>
              <FirstPagePdf profile={profile} currency={currency} />
            </Box>

            <div className="html2pdf__page-break" />
          </>

          <>
            <Box sx={{ height: '1888px' }}>
              <LastPdfPage
                mentionAccountsAndTimeline={
                  profile?.creatorCollaborations?.mentionAccountsAndTimeline
                }
                username={profile?.username}
              />
            </Box>
            <div className="html2pdf__page-break" />
          </>
        </Stack>
      </ProfilePdfDocumentHideWrapper>
    </Portal>
  )
})
