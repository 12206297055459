import React from 'react'
import { useHistory } from 'react-router-dom'
import { ModalComponent } from '../../components/modal/ModalComponent'
import { Button, Stack, Typography } from '@mui/material'
import useTranslation from '../../../localization/useTranslation'
import { useDispatch } from 'react-redux'
import {
  changeDataTrackedMetrics,
  changeLoadingTrackedMetrics,
} from '../../../redux/ducks/campaignsDucks'

export const AttentionModal = props => {
  const { isOpenModal, closeModal, campaignId } = props

  const { labelStrings } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const onGoToKeywordManager = () => {
    // @ts-ignore
    dispatch([changeLoadingTrackedMetrics(true), changeDataTrackedMetrics([])])
    history.push(`/campaigns/${campaignId}/keywords-mentions-manager`)
    closeModal()
  }

  return (
    <ModalComponent
      isOpenModal={isOpenModal}
      closeModal={closeModal}
      title={labelStrings.attentionDashboardModal.titleModal}
      contentWidth="550px"
      contentHeight="260px"
      isCenterTitle
      withoutScrollWrapper
    >
      <Stack>
        <Stack spacing={1}>
          <Typography textAlign="center" sx={{ fontWeight: 'bold' }}>
            {labelStrings.attentionDashboardModal.titleInsideModal}
          </Typography>
          <Typography textAlign="center" sx={{ fontSize: '14px' }}>
            {labelStrings.attentionDashboardModal.subtitleInsideModal}
          </Typography>
        </Stack>

        <Stack sx={{ mt: 3 }} direction="row" justifyContent="center">
          <Button variant="contained" sx={{ width: 'fit-content' }} onClick={onGoToKeywordManager}>
            {labelStrings.attentionDashboardModal.btnText}
          </Button>
        </Stack>
      </Stack>
    </ModalComponent>
  )
}
