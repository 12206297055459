import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../localization/useTranslation'
import { ClearInputWrapper, InputWrapper, SuggestInput, SuggestLabel } from './styles'

const SuggestLocationsInput = ({
  id = '',
  withLabel = false,
  inputValue = '',
  onChange,
  onBlur,
  onFocus,
  withParentHeight = false,
  withPlaceholder = false,
  placeholderText = '',
  isAnalyzeSuggest = false,
  clearInputHandler,
  userindex = '',
}) => {
  const { labelStrings } = useTranslation()

  return (
    <>
      {withLabel && <SuggestLabel htmlFor={id}>{labelStrings.username}</SuggestLabel>}
      <InputWrapper withParentHeight={withParentHeight}>
        <SuggestInput
          id={id}
          onChange={onChange}
          value={inputValue}
          autoComplete="off"
          onBlur={onBlur}
          onFocus={onFocus}
          {...(withPlaceholder && { placeholder: placeholderText || labelStrings.enterUsername })}
          withParentHeight={withParentHeight}
          userindex={userindex}
          isAnalyzeSuggest={isAnalyzeSuggest}
        />
        {inputValue && (
          <ClearInputWrapper
            onClick={clearInputHandler}
            withParentHeight={withParentHeight}
            isAnalyzeSuggest={isAnalyzeSuggest}
          />
        )}
      </InputWrapper>
    </>
  )
}

SuggestLocationsInput.propTypes = {
  id: PropTypes.string,
  withLabel: PropTypes.bool,
  inputValue: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  withParentHeight: PropTypes.bool,
  withPlaceholder: PropTypes.bool,
  placeholderText: PropTypes.string,
  isAnalyzeSuggest: PropTypes.bool,
  clearInputHandler: PropTypes.func,
  userindex: PropTypes.string,
}

export default SuggestLocationsInput
