import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../../../../localization/useTranslation'
import { IG_SCORE_NAMES, YT_SCORE_NAMES } from '../../../../../../constants/profile'
import { USER_INDEXES } from '../../../../../../constants/appSettings'
import { pretifyBigNum } from '../../../../../../utils'
import InfludataRating from '../../../../../qualityScores/InfludataRating'
import ComparisonToSimilarInfluencers from '../../../../../qualityScores/ComparisonToSimilarInfluencers'
import NoDataMessage from '../../../NoDataMessage'
import {
  SectionTitleWrapper,
  SectionTitleName,
  SectionTitleBorder,
} from '../../../../commonProfilePageStyles'
import {
  RatingSectionContainer,
  RatingSectionGrid,
  RatingSectionGridItem,
  RatingTitleWithScoreWrapper,
  RatingTitleWrapper,
  RatingTitle,
} from '../../../commonStyles'

const AudienceEngagementOverallPdf = ({ platform, profileScores, isForPdf }) => {
  const { labelStrings, scoreStrings } = useTranslation()
  const ytScoreStrings = scoreStrings[USER_INDEXES.youtube]

  const { sentimentNegativeObject, sentimentPositiveObject, followersChangeObject } = profileScores || {}

  const formatLabel = label => (!!label && !Number.isNaN(+label) ? pretifyBigNum(+label) : label)

  const scoresArr = [
    {
      name: YT_SCORE_NAMES.growthRate,
      data: followersChangeObject,
      label: labelStrings.nLast30Days(followersChangeObject?.label),
    },
    {
      name: YT_SCORE_NAMES.positiveReactions,
      data: sentimentPositiveObject,
      label: labelStrings.nCommentsPositive(formatLabel(sentimentPositiveObject?.label)),
    },
    {
      name: YT_SCORE_NAMES.negativeReactions,
      data: sentimentNegativeObject,
      label: labelStrings.nCommentsNegative(sentimentNegativeObject?.label),
    },
  ]

  const scoreItemsCount = scoresArr.reduce(
    (currentCount, scoreItem) => (!!scoreItem.data ? currentCount + 1 : currentCount),
    0
  )

  return (
    <RatingSectionContainer isForPdf>
      <SectionTitleWrapper>
        <SectionTitleName platform={platform} isForPdf>
          {labelStrings.audienceEngagementOverall}
        </SectionTitleName>
        <SectionTitleBorder isForPdf={isForPdf} />
      </SectionTitleWrapper>

      {!scoreItemsCount && <NoDataMessage isForPdf />}

      <RatingSectionGrid columnsCount={2} isForPdf>
        {scoresArr.map((scoreItem, index) => {
          const { name: scoreName, label, data: scoreData } = scoreItem
          const { benchmarkArray, score, xAxisHigh, xAxisMid, xAxisLow } = scoreData || {
            benchmarkArray: [],
            score: 0,
            xAxisHigh: null,
            xAxisLow: null,
            xAxisMid: null,
          }
          const xAxisValues = [xAxisHigh, xAxisMid, xAxisLow]

          if (!scoreData) return null

          return (
            <RatingSectionGridItem key={index}>
              <RatingTitleWithScoreWrapper>
                <RatingTitleWrapper>
                  <RatingTitle>{ytScoreStrings[scoreName].title}</RatingTitle>
                </RatingTitleWrapper>

                <InfludataRating
                  scoreValue={score}
                  platform={platform}
                  size={50}
                  isTitleHidden
                  isOutOfHidden
                  isForPdf
                  isSmallRatingValue
                />
              </RatingTitleWithScoreWrapper>

              <ComparisonToSimilarInfluencers
                label={label}
                benchmarkArray={benchmarkArray}
                xAxisValues={xAxisValues}
              />
            </RatingSectionGridItem>
          )
        })}
      </RatingSectionGrid>
    </RatingSectionContainer>
  )
}

AudienceEngagementOverallPdf.propTypes = {
  profileScores: PropTypes.object.isRequired,
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  isForPdf: PropTypes.bool,
}

export default AudienceEngagementOverallPdf
