import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import useTranslation from '../../../localization/useTranslation'
import { TT_SCORE_NAMES } from '../../../constants/profile'
import { USER_INDEXES } from '../../../constants/appSettings'

import ProgressBar from '../../common/progressBar'
import RatingDescriptionTip from '../detailedInfludataRating/ratingDescriptionTip/RatingDescriptionTip'
import {
  RatingTitleWrapper,
  RatingTitle,
  TiktokStatisticsGridItem,
  StatisticCommonValue,
  StatisticCommonLabel,
} from './commonStyles'

const ProgressBarWrapper = styled.div`
  width: 100%;

  .progress_inner {
    background: ${props => props.theme.gradients.mainTiktok};
  }
`

const ViralPosts = ({ viralCountArray = [0, 0], isForPdf = false }) => {
  const { scoreStrings } = useTranslation()
  const viralPostsStrings = scoreStrings[USER_INDEXES.tiktok][TT_SCORE_NAMES.viralPosts]

  return (
    <TiktokStatisticsGridItem>
      <RatingTitleWrapper>
        <RatingTitle>{viralPostsStrings.title}</RatingTitle>
        {!isForPdf && <RatingDescriptionTip scoreDesc={viralPostsStrings.description} />}
      </RatingTitleWrapper>

      <StatisticCommonValue isForPdf={isForPdf}>{viralCountArray[0]}</StatisticCommonValue>
      <StatisticCommonLabel>
        {viralPostsStrings.outOfTheLast} {viralCountArray[1]}
      </StatisticCommonLabel>

      <ProgressBarWrapper>
        <ProgressBar
          current={viralCountArray[0]}
          limit={viralCountArray[1]}
          platform={USER_INDEXES.tiktok}
        />
      </ProgressBarWrapper>
    </TiktokStatisticsGridItem>
  )
}

ViralPosts.propTypes = {
  viralCountArray: PropTypes.arrayOf(PropTypes.number),
  isForPdf: PropTypes.bool,
}

export default ViralPosts
