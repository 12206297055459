import React, { useMemo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import anonProfilePic from "../../../images/anonProfilePic.jpg"
import { constructLinkToWECFileServer } from "../../../utils"
import Image from "../../../new-ui/components/image/Image"
import Img from "react-image"

const ImgWrapper = styled.div`
  & > .profile-pic {
    ${(props) =>
      props.withShadow &&
      `box-shadow: 0px 0px 8px ${
        props.theme.pickColorDependOnPlatform(props.userindex).primary
      };`}
  }

  height: fit-content;
  width: fit-content;
  align-self: center;
`

const ProfilePic = ({
  profilePicUrl = "",
  size = 0,
  userindex = "",
  withShadow = true,
  isForPdf,
}) => {
  const imgSrcUrl = useMemo(
    () => constructLinkToWECFileServer(profilePicUrl),
    [profilePicUrl],
  )

  const boarderRad = size / 2
  const createImg = ({ isUnloader = false } = {}) => (
    <img
      className='profile-pic'
      style={{
        borderWidth: 1,
        borderColor: "rgba(0,0,0,0.2)",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: size,
        maxHeight: size,
        backgroundColor: "#fff",
        borderRadius: boarderRad,
        imageRendering: "auto",
        ...(isUnloader && { height: size, width: size }),
      }}
      src={anonProfilePic}
    />
  )

  return imgSrcUrl ? (
    <ImgWrapper
      userindex={userindex}
      withShadow={withShadow}
      className='profile-pic_wrapper'
    >
      {isForPdf ? (
        <Img
          className='profile-pic'
          src={imgSrcUrl}
          style={{
            borderWidth: 0,
            borderColor: "rgba(0,0,0,0)",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: size,
            maxHeight: size,
            height: size,
            width: size,
            backgroundColor: "#fff",
            borderRadius: boarderRad,
            imageRendering: "auto",
          }}
          unloader={createImg({ isUnloader: true })}
          loader={createImg({ isUnloader: true })}
        />
      ) : (
        <Image
          className='profile-pic'
          sx={{
            borderWidth: 0,
            borderColor: "rgba(0,0,0,0)",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: size,
            maxHeight: size,
            height: size,
            width: size,
            backgroundColor: "#fff",
            borderRadius: boarderRad,
            imageRendering: "auto",
          }}
          src={imgSrcUrl}
        />
      )}
    </ImgWrapper>
  ) : (
    createImg()
  )
}

ProfilePic.propTypes = {
  profilePicUrl: PropTypes.string,
  size: PropTypes.number,
  userindex: PropTypes.string,
  withShadow: PropTypes.bool,
  isForPdf: PropTypes.bool,
}

export default ProfilePic
