import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import { selectedProfileSelector } from '../../../../redux/selectors'
import SmartMediaValue from '../../audienceAnalysis/smartMediaValue/SmartMediaValue'
import AudienceEngagementOverallPdf from '../../detailedInfludataRating/youTube/AudienceEngagement/EngagementPdf/AudienceEngagementOverallPdf'
import { Section } from '../commonStyles'
import PageLayout from '../PageLayout/PageLayout'
import { useLocalStorage } from '../../../../hooks/useLocalStorage'

const EngagementOverallPage = ({ isBranded = true, isShowQualityScores = true, isShowSmartMediaValue = true }) => {
  const { profile } = useSelector(selectedProfileSelector, shallowEqual)
  const { _id: profileId, username, _index: platform, profileScore, cpms } = profile || {}

  const [currencyFromStorage] = useLocalStorage('currency')

  return (
    <PageLayout profileId={profileId} username={username} platform={platform} isBranded={isBranded}>
      {isShowQualityScores && (
        <Section>
          <AudienceEngagementOverallPdf platform={platform} profileScores={profileScore} isForPdf />
        </Section>
      )}

      {isShowSmartMediaValue && (
        <Section noPadding>
          <SmartMediaValue
            cpms={cpms}
            platform={platform}
            isForPdf
            currency={currencyFromStorage}
          />
        </Section>
      )}
    </PageLayout>
  )
}

EngagementOverallPage.propTypes = {
  isBranded: PropTypes.bool,
  isShowQualityScores: PropTypes.bool,
  isShowSmartMediaValue: PropTypes.bool,
}

export default EngagementOverallPage
