import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'
import LinkToFaqQuestion from '../../faqs/LinkToFaqQuestion'
import { SOCIAL_PLATFORMS_NAMES } from '../../../constants/appSettings'

import { Header, FaqTipWrapper, SidebarTitleWrapper } from './collectionsSidebarStyles'

const CollectionsBarHeader = ({ showHeaderForDrag, isNavType = 'default' }) => {
  const { labelStrings } = useTranslation()
  return (
    <Header isNavType={isNavType}>
      <SidebarTitleWrapper>
        {showHeaderForDrag ? (
          <>{labelStrings.bulkAddToCollections}</>
        ) : (
          <>
            {labelStrings.yourCollections}
            <FaqTipWrapper>
              <LinkToFaqQuestion
                extraRatio={10}
                faqQuestionId="" //TODO: FAQLINK collections sidebar title
                userindex={SOCIAL_PLATFORMS_NAMES.instagram.index}
              />
            </FaqTipWrapper>
          </>
        )}
      </SidebarTitleWrapper>
    </Header>
  )
}

CollectionsBarHeader.propTypes = {
  showHeaderForDrag: PropTypes.bool,
  isNavType: PropTypes.string
}

export default CollectionsBarHeader
