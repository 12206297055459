import React from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import useTranslation from '../../../../localization/useTranslation'
import PersonIcon from '@mui/icons-material/Person'
import ApartmentIcon from '@mui/icons-material/Apartment'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import useResponsive from '../../../../new-ui/hooks/useResponsive'

export const DescribeYourBusiness = props => {
  const { username, afterSignupData, setAfterSignupData, handleContinue } = props || {}
  const { businessType } = afterSignupData || {}

  const { labelStrings } = useTranslation()
  const isMobile = useResponsive('down', 'sm')

  const onChangePlatform = value => {
    setAfterSignupData(prev => ({
      ...prev,
      businessType: value,
    }))
    handleContinue()
  }

  const btnBusinessTypes = [
    {
      name: labelStrings.signupLocalization.consumerBrand,
      value: 'brand',
      icon: <ShoppingBasketIcon />,
    },
    { name: labelStrings.signupLocalization.agency, value: 'agency', icon: <ApartmentIcon /> },
    {
      name: labelStrings.signupLocalization.marketingFreelancer,
      value: 'freelancer',
      icon: <PersonIcon />,
    },
    { name: labelStrings.signupLocalization.other, value: 'other', icon: null },
  ]

  return (
    <Stack sx={{ width: '100%', height: '100%' }}>
      <Typography
        sx={{ fontFamily: `Lato, sans-serif`, fontSize: '26px', fontWeight: 600, color: '#1F2937' }}
      >
        {labelStrings.signupLocalization.welcome}, {username}!
      </Typography>
      <Stack spacing={2} sx={{ mt: 3, pb: 5 }}>
        <Typography
          sx={{
            fontFamily: `Lato, sans-serif`,
            fontSize: '16px',
            fontWeight: 600,
            color: '#1F2937',
          }}
        >
          {labelStrings.signupLocalization.describeYourBusiness}
        </Typography>

        <Stack
          spacing={1}
          sx={{ px: isMobile ? 1 : 4, maxWidth: isMobile ? '100%' : '450px' }}
          justifyContent="center"
          alignItems="center"
        >
          {btnBusinessTypes?.map(el => {
            const isActive = el.value === businessType
            return (
              <Button
                key={el.name}
                variant="outlined"
                size="medium"
                sx={{
                  width: '100%',
                  backgroundColor: isActive ? '#FF726F' : 'initial',
                  color: isActive ? '#FFF' : '#1F2937',
                  borderColor: !isActive ? 'lightgrey' : '#FF726F',
                  minHeight: '42px',
                  '&:hover': {
                    opacity: '0.8',
                    backgroundColor: isActive ? '#FF726F' : 'initial',
                    color: isActive ? '#FFF' : '#FF726F',
                  },
                }}
                onClick={() => onChangePlatform(el.value)}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="start"
                  spacing={1}
                  sx={{ width: '192px' }}
                >
                  <Box>{el.icon}</Box>
                  <Typography>{el.name}</Typography>
                </Stack>
              </Button>
            )
          })}
        </Stack>
      </Stack>
    </Stack>
  )
}
