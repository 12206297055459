import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { CommentsCarousel } from '../../components/carousel/comments'

const carouselSettings = {
  dots: false,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
}

export const Sentiment = ({
  mostLikedComments = {},
  latestCommentsWithBrand = {},
  latestContentWithSummariesData = {},
}) => (
  <Grid container spacing={2}>
    {/* {mostLikedComments.graphId && ( */}
      <Grid item xs={12} md={4}>
        <CommentsCarousel
          title="Most Liked Comments"
          list={mostLikedComments?.value}
          carouselSettings={carouselSettings}
          sx={{ maxHeight: '390px' }}
        />
      </Grid>
    {/* )} */}
    {/* {latestCommentsWithBrand?.graphId && ( */}
      <Grid item xs={12} md={4}>
        <CommentsCarousel
          title="Latest Comments with Brand Mentions"
          list={latestCommentsWithBrand?.value}
          carouselSettings={carouselSettings}
          sx={{ maxHeight: '390px' }}
        />
      </Grid>
    {/* )} */}
    {/* {latestContentWithSummariesData?.graphId && ( */}
      <Grid item xs={12} md={4}>
        <CommentsCarousel
          title="Latest Summaries of Comments"
          list={latestContentWithSummariesData?.value}
          carouselSettings={carouselSettings}
          sx={{ maxHeight: '390px' }}
          withLatestSummaries
        />
      </Grid>
    {/* )} */}
  </Grid>
)

Sentiment.propTypes = {
  mostLikedComments: PropTypes.object,
  latestCommentsWithBrand:  PropTypes.object,
  latestContentWithSummariesData: PropTypes.object,
}