import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { GrantsContext } from '../components/SessionGuard';
import { withReduxActions } from './withRedux'
import { changeUserGrantFromContext } from '../redux/ducks/userDucks'

const WithGrantsContext = ({ children, changeUserGrantFromContext }) => {
  const context = useContext(GrantsContext)
  const history = useHistory()

  useEffect(() => {
    changeUserGrantFromContext(context, history)
  }, [context])
  return children
}

WithGrantsContext.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  changeUserGrantFromContext: PropTypes.func,
}

export default withReduxActions({ changeUserGrantFromContext })(WithGrantsContext)
