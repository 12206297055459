import React from 'react'
import { Box } from '@mui/material'
import { ContentCharts } from '../ContentCharts'
import { useSelector } from 'react-redux'
import { campaignDashboard, currentOpenedCampaignSelector } from '../../../../redux/selectors'
import { processDashboardData } from '../utils'
import { FirstPdfPage } from './pages/FirstPage'
import { SecondPdfPage } from './pages/SecondPage'
import { ThirdPdfPage } from './pages/ThirdPage'
import { FourthPdfPage } from './pages/FourthPage'
import { FivethPdfPage } from './pages/FivethPage'

export const PdfViews = () => {
  const { data: dashboardStartupData } = useSelector(campaignDashboard)
  const currentCampaign = useSelector(currentOpenedCampaignSelector)
  const { settings } = currentCampaign

  const reducedDashboardData = processDashboardData(dashboardStartupData)

  const publishedContentElementsData =
    reducedDashboardData?.published_content_elements?.value?.array?.slice(0, 3) || []

  return (
    <Box sx={{ px: 3, maxWidth: '100vh' }}>
      <FirstPdfPage
        countsWidget={reducedDashboardData?.count_widgets}
        customTracking={reducedDashboardData?.custom_tracking}
      />

      <SecondPdfPage
        cpmOrganicData={reducedDashboardData?.cpm_organic}
        cpeOrganicData={reducedDashboardData?.cpe_organic}
        emvData={reducedDashboardData?.emv}
      />

      <ThirdPdfPage
        publishedContentChartData={reducedDashboardData.published_content_reach_linechart}
        latestCommentsWithBrandData={reducedDashboardData?.latest_comments_with_brand}
        mostLikedCommentsData={reducedDashboardData?.most_liked_comments}
      />

      <FourthPdfPage
        settings={settings}
        latestSummariesData={reducedDashboardData?.latest_comment_summaries_with_brand?.value}
        goalsData={reducedDashboardData?.goal_reached}
        publishedContentElementsData={publishedContentElementsData}
        publishedContentElementsCount={
          reducedDashboardData?.published_content_elements?.value?.publishedCount
        }
        publishedContentElementsTextLabel={
          reducedDashboardData?.published_content_elements?.value?.textLabel
        }
      />

      <FivethPdfPage
        reachedChartData={reducedDashboardData?.reached_audience}
        mostSuccessfulCreatorsByReachData={
          reducedDashboardData?.most_successful_creators_reach_stacked_bar
        }
        publishedByTimeAndFormatData={reducedDashboardData?.published_content_time_stacked_bar}
        mostUsedKeywordBarData={reducedDashboardData?.most_used_keywords_bar}
        uploadedContentNewsData={reducedDashboardData?.uploaded_content_news}
        contentByReachBucketData={reducedDashboardData?.content_by_reach_bucket_graph}
      />
    </Box>
  )
}
