import styled from 'styled-components'
import { USER_INDEXES } from '../../../constants/appSettings'

export const TiktokStatisticsContainer = styled.div`
  ${props => !props.isForPdf && 'margin-top: 50px'};
`

export const TiktokStatisticsGrid = styled.div`
  width: 100%;
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
`

export const TiktokStatisticsGridItem = styled.div`
  min-height: 200px;
  padding: 10px 25px;
  border-right: 1px solid ${props => props.theme.colors.lightBrown};
  border-bottom: 1px solid ${props => props.theme.colors.lightBrown};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &:nth-child(3n) {
    border-right: none;
  }

  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(6) {
    border-bottom: none;
  }
`

export const RatingTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 25px;
`

export const RatingTitle = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.darkBrown};
  margin-right: 10px;
`

export const StatisticCommonValue = styled.div`
  font-size: 32px;
  line-height: 34px;
  font-weight: bold;
  margin-bottom: 5px;
  ${props => {
    if (props.isForPdf) {
      return `color: ${props.theme.getColorPaletteForPlatform(USER_INDEXES.tiktok).main}`
    }
    return props.theme.getTextGradientStyles({ platform: USER_INDEXES.tiktok })
  }}
`

export const StatisticCommonLabel = styled.div`
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: ${props => props.theme.colors.darkBrown};
`

export const ContactSalesWrapper = styled.div`
  margin: 100px 0;
`
