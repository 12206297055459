import styled from 'styled-components'

export const SmartMediaValueSection = styled.div`
  padding: ${props => (!props.isForPdf ? '40px 0' : '15px 0')};
`

export const SmartMediaValueItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

export const StyledSmartMediaValueItem = styled.div`
  width: calc(100% / 3);

  & + & {
    margin-left: 50px;
  }
`

export const SmartMediaValueItemTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => (!props.isForPdf ? 'space-between' : 'center')};
  align-items: center;
`

export const SmartMediaValueItemTitleBorder = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.colors.lightBrown};
`

export const SmartMediaValueItemTitle = styled.div`
  font-size: 15px;
  line-height: 19px;
  font-weight: 700;
  color: ${props => props.theme.colors.darkBrown};
  text-transform: uppercase;
  margin-left: 15px;
  white-space: nowrap;
`

export const PerStoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${props => (!props.isForPdf ? '30px' : '10px')};
`

export const PerStoryRange = styled.div`
  ${props =>
    !props.isForPdf
      ? `
    font-size: 32px;
    line-height: 34px;
  `
      : `
    font-size: 21px;
    line-height: 24px;
  `}
  font-weight: 600;
  ${props =>
    props.isForPdf
      ? `color: ${props.theme.primaryColor};`
      : props.theme.getTextGradientStyles({ platform: props.platform })}
`

export const PerStoryLabel = styled.div`
  font-size: 16px;
  line-height: 19px;
  ${props =>
    props.isForPdf ? `color: ${props.theme.primaryColor};` : props.theme.getTextGradientStyles({ platform: props.platform })}
`

export const PerViewsWrapper = styled(PerStoryWrapper)`
  margin-top: ${props => (!props.isForPdf ? '15px' : '10px')};
`

export const PerViewsRange = styled.div`
  ${props =>
    !props.isForPdf
      ? `
    font-size: 24px;
    line-height: 26px;
  `
      : `
    font-size: 21px;
    line-height: 24px;
  `}
  font-weight: 700;
  color: ${props => props.theme.colors.darkBrown};
`

export const PerViewsLabel = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: ${props => props.theme.colors.darkBrown};
`
