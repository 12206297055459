import availableLangs from './availableLangs'
import { AUDIENCE_GENDER_OPTIONS } from '../constants/profile'

export default {
  [availableLangs.en]: {
    [AUDIENCE_GENDER_OPTIONS.onlyMale]: 'only male',
    [AUDIENCE_GENDER_OPTIONS.mostlyMale]: 'mostly male',
    [AUDIENCE_GENDER_OPTIONS.ratherMale]: 'rather male',
    [AUDIENCE_GENDER_OPTIONS.off]: 'off',
    [AUDIENCE_GENDER_OPTIONS.ratherFemale]: 'rather female',
    [AUDIENCE_GENDER_OPTIONS.mostlyFemale]: 'mostly female',
    [AUDIENCE_GENDER_OPTIONS.onlyFemale]: 'only female',
  },
  [availableLangs.de]: {
    [AUDIENCE_GENDER_OPTIONS.onlyMale]: 'nur männlich',
    [AUDIENCE_GENDER_OPTIONS.mostlyMale]: 'meist männlich',
    [AUDIENCE_GENDER_OPTIONS.ratherMale]: 'eher männlich',
    [AUDIENCE_GENDER_OPTIONS.off]: 'aus',
    [AUDIENCE_GENDER_OPTIONS.ratherFemale]: 'eher weiblich',
    [AUDIENCE_GENDER_OPTIONS.mostlyFemale]: 'meist weiblich',
    [AUDIENCE_GENDER_OPTIONS.onlyFemale]: 'nur weiblich',
  },
}
