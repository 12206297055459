import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import {
  Checkbox,
  FormLabel,
  FormGroup,
  FormControl,
  FormHelperText,
  FormControlLabel,
  Box,
} from '@mui/material'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'

RHFCheckbox.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
  isNotDefault: PropTypes.bool,
}

export function RHFCheckbox({ name, helperText = '', isNotDefault = false, ...other }) {
  const { control } = useFormContext()

  const checkBoxProperties = isNotDefault
    ? {}
    : {
        icon: <RadioButtonUncheckedIcon />,
        checkedIcon: <RadioButtonCheckedIcon />,
      }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value || false} {...checkBoxProperties} />}
            {...other}
          />

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          )}
        </div>
      )}
    />
  )
}

// ----------------------------------------------------------------------

RHFMultiCheckbox.propTypes = {
  row: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  spacing: PropTypes.number,
  helperText: PropTypes.node,
}

export function RHFMultiCheckbox({
  row,
  name,
  label = '',
  options,
  spacing,
  helperText = '',
  ...other
}) {
  const { control } = useFormContext()

  const getSelected = (selectedItems, item) =>
    selectedItems.includes(item)
      ? selectedItems.filter(value => value !== item)
      : [...selectedItems, item]

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const value = field.value || []

        return (
          <FormControl component="fieldset">
            {label && (
              <FormLabel component="legend" sx={{ typography: 'body2' }}>
                {label}
              </FormLabel>
            )}

            <FormGroup
              sx={{
                ...(row && {
                  flexDirection: 'row',
                }),
                '& .MuiFormControlLabel-root': {
                  '&:not(:last-of-type)': {
                    mb: spacing || 0,
                  },
                  ...(row && {
                    mr: 0,
                    '&:not(:last-of-type)': {
                      mr: spacing || 2,
                    },
                  }),
                },
              }}
            >
              {options?.map((option, idx) => {
                if (!option.label) return

                return (
                  <FormControlLabel
                    key={option.value || idx}
                    control={
                      <Checkbox
                        checked={value.includes(option.value)}
                        onChange={() => field.onChange(getSelected(value, option.value))}
                      />
                    }
                    label={option.label}
                    {...other}
                  />
                )
              })}
            </FormGroup>

            {(!!error || helperText) && (
              <FormHelperText error={!!error} sx={{ mx: 0 }}>
                {error ? error?.message : helperText}
              </FormHelperText>
            )}
          </FormControl>
        )
      }}
    />
  )
}

export function RHFMultiCheckboxGroup({
  row,
  isRow,
  name,
  label = '',
  data,
  spacing,
  helperText = '',
  ...other
}) {
  const { control } = useFormContext()

  const getSelected = (selectedItems, item) =>
    selectedItems.includes(item)
      ? selectedItems.filter(value => value !== item)
      : [...selectedItems, item]

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const value = field.value || []

        return (
          <FormControl component="fieldset" sx={{ flexDirection: isRow ? 'row' : 'column' }}>
            {data?.map(el => {
              return (
                <Box
                  key={el.label}
                  sx={{ marginRight: isRow ? 8 : 0, '&:last-child': { marginRight: 0 } }}
                >
                  <FormLabel
                    component="legend"
                    focused={false}
                    sx={{
                      typography: 'body2',
                      fontWeight: isRow ? '700' : '400',
                      color: '#212B36'
                    }}
                  >
                    {el.label}
                  </FormLabel>

                  <FormGroup
                    sx={{
                      ...(row && {
                        flexDirection: 'row',
                      }),
                      '& .MuiFormControlLabel-root': {
                        '&:not(:last-of-type)': {
                          mb: spacing || 0,
                        },
                        ...(row && {
                          mr: 0,
                          '&:not(:last-of-type)': {
                            mr: spacing || 2,
                          },
                        }),
                      },
                    }}
                  >
                    {el?.options?.map(option => (
                      <FormControlLabel
                        key={option.value}
                        control={
                          <Checkbox
                            checked={value.includes(option.value)}
                            onChange={() => field.onChange(getSelected(value, option.value))}
                          />
                        }
                        label={option.label}
                        {...other}
                      />
                    ))}
                  </FormGroup>
                </Box>
              )
            })}

            {(!!error || helperText) && (
              <FormHelperText error={!!error} sx={{ mx: 0 }}>
                {error ? error?.message : helperText}
              </FormHelperText>
            )}
          </FormControl>
        )
      }}
    />
  )
}
