import React, { useState } from 'react'
import styled from 'styled-components'
import Popup from 'reactjs-popup'
import { useDispatch, useSelector } from 'react-redux'
import { isAccountAlreadyExistsSelector, userAuthErrorSelector } from '../../redux/selectors'
import { toggleSignUp, toggleSignIn } from '../../redux/ducks/appSettingsDucks'
import { loginUser } from '../../redux/ducks/userDucks'
import { setError } from '../../redux/ducks/errorDucks'
import { ERROR_MSG } from '../../constants/errorMessages'

import { WithPopupWrapper } from '../common/styledWrappers'
import { CloseIcon } from '../common/icons'
import LoginForm from '../forms/LoginForm'
import EmailToGetLinkForm from '../forms/forgetPassword/EmailToGetLinkForm'
import LoadingSpinner from '../common/spinners/LoadingSpinner'
import useTranslation from '../../localization/useTranslation'
import httpService from '../../services/httpService'
import { API_STATUS_CODES } from '../../constants/appSettings'
import { Alert, Box, Divider, IconButton } from '@mui/material'
import useResponsive from '../../new-ui/hooks/useResponsive'

const LoginWrapper = styled.div`
  width: 451px;
  ${'' /* height: 390px; */}
  background: ${props =>
    props.isAccountAlreadyExists ? props.theme.color.lightPink : props.theme.color.white};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  ${props =>
    props.isAccountAlreadyExists &&
    `
    & form {
      margin-top: 10px;
    }
  `}
  border-radius: 9px;
`

const SignLinkWrapper = styled.span`
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  color: ${props => props.theme.primaryColor};
  &:hover {
    color: ${props => props.theme.primaryColorLighter};
  }
`

const ForgotPasswordWrapper = styled(SignLinkWrapper)`
  display: block;
  text-align: center;

  margin: 10px 0px;
`

const SignUpWrapper = styled.div`
  font-size: 14px;
  cursor: default;
  color: ${props => props.theme.textColor};
`

const AlreadyHasAccountWrapper = styled.div`
  letter-spacing: 0px;
  font-size: 15px;
  width: 300px;
  margin: 17px auto;
`

const LoginContainer = () => {
  const { labelStrings } = useTranslation()
  const dispatch = useDispatch()

  const authError = useSelector(userAuthErrorSelector)

  const isMobile = useResponsive('down', 'sm')

  const [forgetPasswordState, changeIsForgetPasswordState] = useState({
    isForgetPassword: false,
    noUserFound: false,
    successSendLink: false,
    isLoading: false,
  })

  const setForgetPasswordState = newValObj =>
    changeIsForgetPasswordState({ ...forgetPasswordState, ...newValObj })

  const resetNoUserFound = () => setForgetPasswordState({ noUserFound: false })
  const toggleForgetPassword = () =>
    setForgetPasswordState({
      isForgetPassword: !forgetPasswordState.isForgetPassword,
    })

  const isAccountAlreadyExists = useSelector(isAccountAlreadyExistsSelector)

  const forgotPasswordHandler = async email => {
    try {
      setForgetPasswordState({ isLoading: true })
      await httpService.fetchForgetPassword(email)
      setForgetPasswordState({ isLoading: false, successSendLink: true })
    } catch (err) {
      if (err.response && err.response.status === API_STATUS_CODES.notFound) {
        setForgetPasswordState({ isLoading: false, noUserFound: true })
      } else {
        setForgetPasswordState({ isLoading: false })
        dispatch(setError(ERROR_MSG.sendResetPassLinkFail))
      }
    }
  }

  const signupHandler = () => dispatch(toggleSignUp())
  const closeLoginHandler = () => dispatch(toggleSignIn())

  const { isForgetPassword, noUserFound, successSendLink, isLoading } = forgetPasswordState
  return (
    <WithPopupWrapper enableHeader fullScreen isTransparent>
      {isLoading && <LoadingSpinner isTransparent showOnFullPage />}
      <Popup open={true} position="right center" closeOnDocumentClick={false} modal>
        <Box sx={{ maxWidth: isMobile ? '350px': '100%' }}>
          <IconButton
            onClick={closeLoginHandler}
            sx={{ position: 'absolute', right: '5px', top: '5px' }}
          >
            <CloseIcon />
          </IconButton>
          <LoginWrapper
            isAccountAlreadyExists={isAccountAlreadyExists}
            style={{ padding: isMobile ? '10px' : '30px', maxWidth: isMobile ? '350px' : '450px' }}
          >
            {isForgetPassword ? (
              successSendLink ? (
                <AlreadyHasAccountWrapper>
                  {labelStrings.passRecoveryInstructions}
                </AlreadyHasAccountWrapper>
              ) : (
                <EmailToGetLinkForm
                  sendLinkToEmail={forgotPasswordHandler}
                  noUserFound={noUserFound}
                  resetNoUserFound={resetNoUserFound}
                />
              )
            ) : (
              <>
                {(isAccountAlreadyExists || authError) && (
                  <Alert
                    sx={{
                      my: 1,
                      fontSize: '12px',
                      padding: '2px 6px',
                      '& .MuiAlert-message': {
                        padding: '2px 0',
                        display: 'flex',
                        alignItems: 'center',
                        whiteSpace: 'pre-line',
                      },
                      '& .MuiAlert-icon': {
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '8px',
                      },
                    }}
                    variant="outlined"
                    severity="error"
                  >
                    {isAccountAlreadyExists
                      ? labelStrings.alreadyExistAccount
                      : labelStrings.invalidUser 
                    }
                  </Alert>
                )}
                <LoginForm loginUser={loginUser} />
                <Divider sx={{ height: '1px', width: '100%', color: 'black' }} />
                <ForgotPasswordWrapper onClick={toggleForgetPassword}>
                  {labelStrings.forgotPassword}
                </ForgotPasswordWrapper>
                <SignUpWrapper>
                  {labelStrings.noAccountYet}{' '}
                  <SignLinkWrapper onClick={signupHandler}>
                    {labelStrings.tryForFree}
                  </SignLinkWrapper>
                </SignUpWrapper>
              </>
            )}
          </LoginWrapper>
        </Box>
      </Popup>
    </WithPopupWrapper>
  )
}

export default LoginContainer
