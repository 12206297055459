import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TableRow, TableCell, IconButton, Chip } from '@mui/material'
import Iconify from '../../components/iconify'
import { PopoverItemMenu } from './PopoverItemMenu'
import useTranslation from '../../../localization/useTranslation'

export const NotificationsTableRow = ({ row, onEditRow, onDeleteRow }) => {
  const { labelStrings } = useTranslation()

  const { dashboardId, emails, interval, dashboardName } = row

  const [openPopover, setOpenPopover] = useState(null)

  const handleOpenPopover = event => {
    setOpenPopover(event.currentTarget)
  }

  const handleClosePopover = () => {
    setOpenPopover(null)
  }

  return (
    <>
      <TableRow hover>
        <TableCell align="left">{dashboardName || dashboardId}</TableCell>

        <TableCell align="left">
          {interval ? labelStrings.notificationPageRowInterval(interval) : '-'}
        </TableCell>

        <TableCell align="left" sx={{ maxWidth: '400px' }}>
          {emails?.map(el => {
            return <Chip label={el} key={`${el}`} sx={{ mr: 0.5, my: '4px !important' }} />
          })}
        </TableCell>

        <TableCell align="right">
          <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <PopoverItemMenu
        openPopover={openPopover}
        handleClosePopover={handleClosePopover}
        onEditRow={onEditRow}
        onDeleteRow={onDeleteRow}
      />
    </>
  )
}

NotificationsTableRow.propTypes = {
  row: PropTypes.object,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
}
