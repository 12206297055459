import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { StyledCheckbox, SearchLabel } from '../styledWrappers'
import LinkToFaqQuestion from '../../faqs/LinkToFaqQuestion'

const CheckboxOuterWrapper = styled.div`
  margin: 5px 0;
  position: relative;
`
const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  min-width: ${props => (props.withMinWidth ? '125px' : 'unset')};
  & > * {
    color: ${props => props.theme.colors.darkBrown};
  }
`
const CheckboxLabelWrapper = styled.label`
  font-size: 15px;
  line-height: 20px;
  font-family: ${props => props.theme.fontFamilyRoboto};
  color: ${props => props.theme.colors.darkBrown};
  margin: 0 0 0 5px;
  cursor: pointer;
`
const LabelWrapper = styled.div`
  position: relative;
  width: fit-content;
`

const firstCheckboxId = 'creators_only'
const secondCheckboxId = 'creators_&_regular'

const CheckBoxesGroupAccountType = ({
  labelTextFirst = '',
  labelTextSecond = '',
  onChange,
  checked = false,
  titleText = '',
  withMinWidth = true,
  faqQuestionId = null, // TODO: FAQLINK
}) => {
  const renderTip = faqQuestionId !== null
  const [isShortsCreator, setIsShortsCreator] = useState(false)

  useEffect(() => {
    setIsShortsCreator(checked)
  }, [checked])

  const handler = value => {
    setIsShortsCreator(value)
    onChange(value ? true : '')
  }

  return (
    <CheckboxOuterWrapper className="single_checkbox_container">
      {titleText && (
        <LabelWrapper>
          <SearchLabel>{titleText}</SearchLabel>
          {renderTip && (
            <LinkToFaqQuestion
              faqQuestionId={faqQuestionId}
              tipIconSize={14}
              linkPositions={{ top: 7, right: -30 }}
            />
          )}
        </LabelWrapper>
      )}

      <CheckboxWrapper
        withMinWidth={withMinWidth}
        className="single_checkbox"
        onClick={() => handler(false)}
      >
        <StyledCheckbox
          type="checkbox"
          onChange={() => handler(false)}
          checked={!isShortsCreator}
          id={secondCheckboxId}
        />
        <LabelWrapper>
          <CheckboxLabelWrapper htmlFor={labelTextSecond} className="single_checkbox_label_text">
            {labelTextSecond}
          </CheckboxLabelWrapper>
        </LabelWrapper>
      </CheckboxWrapper>

      <CheckboxWrapper
        withMinWidth={withMinWidth}
        className="single_checkbox"
        onClick={() => handler(true)}
      >
        <StyledCheckbox
          type="checkbox"
          onChange={() => handler(true)}
          checked={isShortsCreator}
          id={firstCheckboxId}
        />
        <LabelWrapper>
          <CheckboxLabelWrapper htmlFor={labelTextFirst} className="single_checkbox_label_text">
            {labelTextFirst}
          </CheckboxLabelWrapper>
        </LabelWrapper>
      </CheckboxWrapper>
    </CheckboxOuterWrapper>
  )
}

CheckBoxesGroupAccountType.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  titleText: PropTypes.string,
  faqQuestionId: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
  labelTextFirst: PropTypes.string,
  labelTextSecond: PropTypes.string,
  withMinWidth: PropTypes.bool,
}

export default CheckBoxesGroupAccountType
