import React from 'react'
import PropTypes from 'prop-types'

import useTranslation from '../../../localization/useTranslation'
import { constructLinkToWECFileServer, findCountryIsoCode, getPngCountryFlags, pretifyBigNum } from '../../../utils'
import { USER_INDEXES } from '../../../constants/appSettings'

import ProfileAvatar from '../../profileCommon/profileAvatar'
import Spinner from '../../common/spinners/Spinner'
import SimilarAccountsSkeleton from './SimilarAccountsSkeleton'
import {
  TableContainer,
  Table,
  TBody,
  Tr,
  Th,
  Td,
  WithLinkToProfile,
  AvatarWrapper,
  Username,
  Country,
  Language,
  DisplayName,
  Biography,
  Followers,
  ImressionsPost,
  EngagementPercent,
  SIMILAR_ACCOUNT_AVATAR_SIZE,
} from './styles'
import { Stack } from '@mui/material'

const SimilarAccountsTable = ({ users = [], platform = '', isSkeletonMode = false, isLoading = false }) => {
  const { labelStrings, countryOptions, languageOptions } = useTranslation()

  const tableCellWidths = {
    qualityScore: '23%',
    usernameCountry: '23%',
    nameBio: '36%',
    enagement: '18%',
  }

  const localizeCountry = countryEnglish => {
    const country = countryOptions.find(option => option.value == countryEnglish)
    return country ? country.label : countryEnglish
  }

  const localizeLanguage = lang => {
    const langOption = languageOptions.find(option => option.value === lang)
    return langOption ? langOption.label : lang
  }

  return (
    <TableContainer>
      {isLoading && <Spinner isOverlay />}

      <Table>
        <TBody>
          <Tr isTableHead>
            <Th width={tableCellWidths.qualityScore}>{labelStrings.qualityScore}</Th>

            <Th width={tableCellWidths.usernameCountry}>
              {labelStrings.username}
              <br />
              {labelStrings.country}
              <br />
              {labelStrings.language}
            </Th>

            <Th width={tableCellWidths.nameBio}>
              {labelStrings.name}
              <br />
              {labelStrings.bio}
            </Th>

            <Th width={tableCellWidths.enagement}>
              {labelStrings.followers}&nbsp;/ {labelStrings.impressions}&nbsp;/{' '}
              {labelStrings.postEngagement}&nbsp;
            </Th>
          </Tr>

          {(() => {
            if (isSkeletonMode) {
              return <SimilarAccountsSkeleton platform={platform} />
            }
            return users.map((user, index) => {
              return (
                <Tr key={index}>
                  <Td width={tableCellWidths.qualityScore}>
                    <WithLinkToProfile to={`/profile/${user._id}`}>
                      <AvatarWrapper>
                        <ProfileAvatar
                          score={user.profileScore.overall}
                          profilePicUrl={constructLinkToWECFileServer(user.profilePicURL)}
                          userindex={platform}
                          withRating
                          withGradient={platform !== USER_INDEXES.tiktok}
                          withShadow={false}
                          animated
                          withOverlay
                          size={SIMILAR_ACCOUNT_AVATAR_SIZE}
                        />
                      </AvatarWrapper>
                    </WithLinkToProfile>
                  </Td>

                  <Td width={tableCellWidths.usernameCountry}>
                    <WithLinkToProfile to={`/profile/${user._id}`}>
                      <Username platform={platform}>{user.username}</Username>
                      <Country>
                        {getPngCountryFlags(findCountryIsoCode(countryOptions, user.country)) ? (
                          <Stack direction="row" alignItems="center">
                            {getPngCountryFlags(findCountryIsoCode(countryOptions, user.country))}{' '}
                            {user.country}
                          </Stack>
                        ) : (
                          user.country
                        )}{' '}
                      </Country>
                      <Language>{localizeLanguage(user.language)}</Language>
                    </WithLinkToProfile>
                  </Td>

                  <Td width={tableCellWidths.nameBio}>
                    <WithLinkToProfile to={`/profile/${user._id}`}>
                      <DisplayName platform={platform}>{user.displayName}</DisplayName>
                      <Biography>{user.description}</Biography>
                    </WithLinkToProfile>
                  </Td>

                  <Td width={tableCellWidths.enagement}>
                    <WithLinkToProfile to={`/profile/${user._id}`}>
                      <Followers>{user.followers ? pretifyBigNum(user.followers) : '-'}</Followers>
                      <ImressionsPost platform={platform}>
                        {user.medianViewsPerPost ? pretifyBigNum(user.medianViewsPerPost) : '-'}
                      </ImressionsPost>
                      <EngagementPercent>{user.engagementMean || '-'}</EngagementPercent>
                    </WithLinkToProfile>
                  </Td>
                </Tr>
              )
            })
          })()}
        </TBody>
      </Table>
    </TableContainer>
  )
}

SimilarAccountsTable.propTypes = {
  users: PropTypes.array,
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube]).isRequired,
  isSkeletonMode: PropTypes.bool,
  isLoading: PropTypes.bool,
}

export default SimilarAccountsTable
