import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { userGrantSelector } from '../../../redux/selectors'

import useTranslation from '../../../localization/useTranslation'
import { formatUnixToMDY } from '../../../utils'

const ReactivationPlanWrapper = styled.div`
  background: #f79100;
  transition: all 0.2s ease;
  overflow: hidden;
  ${props => props.theme.flex.centerAll};
  height: 30px;
`

const TextsWrapper = styled.div`
  margin: 0 auto;
  width: fit-content;
`

const PhraseWrapper = styled.span`
  margin: 0 5px;
  font-size: 13px;
  color: ${props => props.theme.color.white};
  font-weight: 800;
  font-family: ${props => props.theme.fontFamilyInter};
`

const ReactivationPlanContainer = () => {
  const { pricingPlans, currentLang } = useTranslation()
  const { reactivateTexts } = pricingPlans
  const { in_trial, trial_end_date } = useSelector(userGrantSelector)

  const createTrialEndDateStr = () => {
    return reactivateTexts.trialActiveUntil(formatUnixToMDY(trial_end_date, currentLang))
  }

  if (true || !in_trial) {
    return null
  }
  return (
    <ReactivationPlanWrapper>
      <TextsWrapper>
        <PhraseWrapper>{createTrialEndDateStr()}</PhraseWrapper>
      </TextsWrapper>
    </ReactivationPlanWrapper>
  )
}

export default ReactivationPlanContainer
