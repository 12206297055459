import React, { useState } from 'react'
import { Avatar, Box, Button, Chip, Stack, Typography } from '@mui/material'
import useTranslation from '../../../../localization/useTranslation'
import Scrollbar from '../../../../new-ui/components/scrollbar/Scrollbar'
import AddCreatorsTo from './CreateOverlap'

export const CreateOverlapContainer = props => {
  const { onConfirm, openModal } = props || {}

  const { labelStrings } = useTranslation()

  const [creators, setCreators] = useState([])
  const creatorIds = creators?.map(creator => creator._id)

  const isAllowedToAddCreators = creators?.length <= 10

  const addCreator = creator => {
    const updatedValue = [...creators, creator]
    setCreators(updatedValue)
  }

  const removeCreator = creatorId => {
    const updatedValue = creators?.filter(creator => creator._id !== creatorId)
    setCreators(updatedValue)
  }

  return (
    <Stack spacing={1.5} alignItems="center" sx={{ height: '510px', position: 'relative' }}>
      <Stack sx={{ px: 3, pb: 1 }}>
        <Typography
          textAlign="center"
          sx={{
            color: '#637381',
            lineHeight: 1.5,
            fontSize: `0.75rem`,
            fontFamily: `Public Sans,sans-serif`,
            fontWeight: 400,
            marginTop: 2,
            marginRight: `14px`,
            marginBottom: 0,
            marginLeft: `14px`,
            maxWidth: '400px',
          }}
        >
          {labelStrings?.createOverlapHelperText}
        </Typography>
      </Stack>

      <Box sx={{ width: '380px' }}>
        <AddCreatorsTo
          isAllowedToAddCreators={true}
          creatorIdFromState={creatorIds}
          setCreatorIdFromState={addCreator}
          isSetCreatorId={false}
          isDisabledInput={!isAllowedToAddCreators}
          creatorType={''}
          changeCreatorsData={setCreators}
          openModal={openModal}
        />
      </Box>

      <Stack sx={{ minHeight: '240px' }}>
        <Scrollbar
          sx={{
            maxHeight: '280px',
            width: '440px',
          }}
        >
          <Stack
            direction="row"
            sx={{ width: '100%', flexWrap: 'wrap', gap: 1, px: 3.5 }}
            justifyContent="start"
          >
            {creators?.map(creator => (
              <Chip
                sx={{
                  borderColor: 'transparent',
                  backgroundColor: '#F4F6F8',
                  py: 3.5,
                  px: 1,
                  width: 'fit-content',
                  fontWeight: 'bold',
                  '.MuiChip-avatar': { width: '36px', height: '36px', mr: 0.5 },
                }}
                key={creator._id}
                avatar={<Avatar alt={creator?.displayName} src={creator?.profilePicURL} />}
                label={`@${creator?.username}`}
                variant="outlined"
                onDelete={() => removeCreator(creator._id)}
              />
            ))}
          </Stack>
        </Scrollbar>

        {!isAllowedToAddCreators && (
          <Typography
            sx={{
              color: '#637381',
              lineHeight: 1.5,
              fontSize: `0.75rem`,
              fontFamily: `Public Sans,sans-serif`,
              fontWeight: 400,
              textAlign: `center`,
              marginTop: 2,
              marginRight: `14px`,
              marginBottom: 0,
              marginLeft: `14px`,
              px: 3,
            }}
          >
            {labelStrings?.buildLookalike?.addToCreatorsLimitText}
          </Typography>
        )}
      </Stack>

      <Box sx={{ position: 'absolute', right: '24px', bottom: 0 }}>
        <Button
          sx={{ mt: 5, mb: 2 }}
          variant="contained"
          onClick={() => onConfirm(creatorIds)}
          disabled={creators?.length < 2}
        >
          {labelStrings?.confirm}
        </Button>
      </Box>
    </Stack>
  )
}
