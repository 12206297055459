import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { EditTextFields } from './Form/EditTextField'
import { ModalComponent } from '../../components/modal/ModalComponent'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { EditContentOverviewSchema } from './Form/FormSchema.js'
import { useSelector } from 'react-redux'
import { currentOpenedCampaignSelector } from '../../../redux/selectors'

const defaultValues = {
  label: '',
  reach: '',
  caption: '',
  likes: '',
  comments: '',
  foundPhrases: '',
  shares: '',
  // nonOrgReach: '',
  // nonOrgLikes: '',
  // nonOrgComments: '',
  // nonOrgShare: '',
  // nonOrgEngagementRate: 0,
  // nonOrgAmountSpentInEur: 0,
  // nonOrgReachSwitch: false,
}

export const EditContent = ({
  openEditContentModal = false,
  setOpenEditContentModal,
  setEditItem,
  editItem = {},
  onRemoveContentFromTracking,
  onEditContent,
}) => {
  const { campaignId, customFields: customFieldsData } = useSelector(currentOpenedCampaignSelector)

  const contentType = editItem?.contentType

  const filteredCustomFieldsByContent = customFieldsData?.filter(
    fields => fields.applyLevel === 'content'
  )
  const initialCustomFieldsData = !!editItem?.customFields?.length
    ? editItem?.customFields
    : filteredCustomFieldsByContent
  const customFields = initialCustomFieldsData?.reduce((result, item) => {
    result[item.customFieldId] = item.currentValue || item.value
    return result
  }, {})

  const methods = useForm({
    resolver: yupResolver(EditContentOverviewSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
    getValues,
  } = methods

  const handleClose = () => {
    setOpenEditContentModal(false)
    setEditItem(null)
    reset()
  }

  const onSubmit = async data => {
    try {
      const {
        label,
        reach,
        caption,
        comments,
        foundPhrases,
        likes,
        shares,
        ...customFieldsInputData
      } = data
      const customFieldsPayload = Object.keys(customFieldsInputData || {})?.map(key => ({
        customFieldId: key,
        value: customFieldsInputData[key] ? Number(customFieldsInputData[key]) : null,
      }))

      await new Promise(resolve => setTimeout(resolve, 500))

      const contentId =
        contentType === 'story_group' ? { contentIds: editItem?.ids } : { contentId: editItem?.id }

      const payload = {
        ...contentId,
        campaignId,
        fieldsToChange: {
          overwriteViews: reach ? Number(reach) : null,
          label: label ? label : '',
          customFields: customFieldsPayload,
        },
      }
      onEditContent(payload)
      handleClose()
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    reset({
      ...customFields,
      likes: editItem?.likes,
      comments: editItem?.comments,
      foundPhrases: editItem?.found?.map(phrases => phrases),
      caption: editItem?.caption,
      shares: editItem?.shares,
      reach: editItem?.reach,
      label: editItem?.label,
    })
  }, [editItem])

  return (
    <ModalComponent
      isOpenModal={openEditContentModal}
      closeModal={handleClose}
      title="View and Edit Content"
    >
      <EditTextFields
        onSubmit={onSubmit}
        editItem={editItem}
        handleSubmit={handleSubmit}
        methods={methods}
        isSubmitting={isSubmitting}
        onRemoveContentFromTracking={onRemoveContentFromTracking}
        filteredCustomFieldsByContent={filteredCustomFieldsByContent}
        getValues={getValues}
      />
    </ModalComponent>
  )
}

EditContent.propTypes = {
  openEditContentModal: PropTypes.bool,
  setOpenEditContentModal: PropTypes.func,
  setEditItem: PropTypes.func,
  editItem: PropTypes.object,
  onRemoveContentFromTracking: PropTypes.func,
}