import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import useTranslation from '../../../localization/useTranslation'
import { withReduxActions } from '../../../hocs/withRedux'
import { toggleSignUp, limitTrialFeaturesAccess } from '../../../redux/ducks/appSettingsDucks'
import WithTip from './WithTip'
import { TipUnderlineTextWrapper } from '../styledWrappers'

const TipMainWrapper = styled.div`
  cursor: default;
  width: 200px;
  color: ${props => props.theme.color.black} !important;
`

const WithUpgradeTip = ({
  children = null,
  userindex = '',
  position = 'top center',
  toggleSignUp,
  tipText = '',
  withSignup = false,
  specialUpgradeText = '',
  skipSpecialUpgradeText = false,
  withOpenTrialLimitModal = false,

  limitTrialFeaturesAccess,
}) => {
  const history = useHistory()
  const { labelStrings } = useTranslation()
  const sighupClickhandler = () => {
    if (withOpenTrialLimitModal) return limitTrialFeaturesAccess()
    withSignup ? toggleSignUp() : history.push('/settings')
  }

  const renderTipMainText = () => {
    if (skipSpecialUpgradeText) return ''
    if (specialUpgradeText) return specialUpgradeText
    return withSignup ? labelStrings.signup : labelStrings.contactSales
  }

  const renderExtraTipText = () => {
    if (skipSpecialUpgradeText) return tipText
    return specialUpgradeText ? '' : `${labelStrings.to} ${tipText}`
  }

  const TipComponent = (
    <TipMainWrapper className="popup_tip_wrapper">
      <TipUnderlineTextWrapper onClick={sighupClickhandler}>
        {renderTipMainText()}
      </TipUnderlineTextWrapper>{' '}
      {renderExtraTipText()}
    </TipMainWrapper>
  )

  return (
    <WithTip TipComponent={TipComponent} position={position} userindex={userindex}>
      {children}
    </WithTip>
  )
}

WithUpgradeTip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  userindex: PropTypes.string,
  position: PropTypes.string,
  toggleSignUp: PropTypes.func,
  tipText: PropTypes.string,
  withSignup: PropTypes.bool,
  specialUpgradeText: PropTypes.string,
  skipSpecialUpgradeText: PropTypes.bool,
  withOpenTrialLimitModal: PropTypes.bool,
  limitTrialFeaturesAccess: PropTypes.func,
}

export default withReduxActions({
  toggleSignUp,
  limitTrialFeaturesAccess,
})(WithUpgradeTip)
