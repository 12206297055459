import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SingleSelect from '../../../common/selects/SingleSelect'
import AudienceSubSearchSingleRangeSlider from '../../../common/sliders/AudienceSubSearchSingleRangeSlider'
import { AddNewItemIcon, CloseCircleIcon } from '../../../common/icons'
import {
  AudienceCountriesWrapper,
  AddIconWrapper,
  SelectWrapper,
} from '../../../common/styledWrappers/audienceSubSearchStyles'
import useTranslation from '../../../../localization/useTranslation'
import { AUDIENCE_COUNTRIES_SUBSEARCH_RATES } from '../../../../constants/search'

const AudienceLanguagesSubSearch = ({
  options,
  selectedList = [],
  placeholder = '',
  idPrefix,
  onChangeAudienceLanguages,
  selectsLimit = 3,
  onChangeAudienceLanguagesRange,
}) => {
  const { labelStrings } = useTranslation()
  const _getUniqueId = () => Math.random()

  const [selects, changeSelects] = useState([])

  useEffect(() => {
    changeSelects(selectedList.length ? selectedList.map(_ => _getUniqueId()) : [_getUniqueId()])
  }, [selectedList])

  const canAddSelects = selects.length >= 1 && selects.length < selectsLimit
  const addNewSelect = () => {
    if (canAddSelects) changeSelects([...selects, _getUniqueId()])
  }

  const removeSelect = idx => () => {
    const copied = [...selects]
    copied.splice(idx, 1)
    changeSelects(copied)
    if (selectedList[idx]) {
      onChangeAudienceLanguages(selectedList[idx].language)(null)
    }
  }

  return (
    <AudienceCountriesWrapper>
      {selects.map((value, idx) => {
        const isLastOne = idx === selects.length - 1
        const selected = selectedList[idx] || {}
        return (
          <div key={value}>
            <SelectWrapper>
              <SingleSelect
                id={idPrefix + idx}
                value={options.find(el => el.value === selected.language)}
                options={options}
                onChange={onChangeAudienceLanguages(selected.language)}
                placeholder={placeholder}
              />
              <AddIconWrapper
                onClick={isLastOne ? addNewSelect : removeSelect(idx)}
                isDisabled={!canAddSelects && isLastOne}
              >
                {isLastOne ? <AddNewItemIcon /> : <CloseCircleIcon />}
              </AddIconWrapper>
            </SelectWrapper>
            <AudienceSubSearchSingleRangeSlider
              faqQuestionId="" // TODO: FAQLINK
              onChange={onChangeAudienceLanguagesRange(selected.language)}
              value={selected.range}
              isDisabled={!selected.language}
              disabledTip={labelStrings.pleaseSelectLanguage}
              title={labelStrings.minAudienceUsingLanguage}
              sliderRanges={AUDIENCE_COUNTRIES_SUBSEARCH_RATES}
            />
          </div>
        )
      })}
    </AudienceCountriesWrapper>
  )
}

AudienceLanguagesSubSearch.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ).isRequired,
  selectedList: PropTypes.arrayOf(
    PropTypes.shape({ country: PropTypes.string, min: PropTypes.number })
  ),
  placeholder: PropTypes.string,
  idPrefix: PropTypes.string.isRequired,
  onChangeAudienceLanguages: PropTypes.func,
  selectsLimit: PropTypes.number,
  onChangeAudienceLanguagesRange: PropTypes.func,
}

export default AudienceLanguagesSubSearch
