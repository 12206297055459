import React from 'react'
import { Box, Link, Modal, Stack, Typography, useMediaQuery, Button } from '@mui/material'
import useTranslation from '../../../localization/useTranslation'
import LoadingSpinner from '../../../components/common/spinners/LoadingSpinner'
import { PDF_INFLUDATA_LOGO } from '../../../components/common/icons'
import { Email } from './subpages/Email'
import { ContactInformation } from './subpages/ContactInformation'
import { CalendlyLinkMeet } from './subpages/CalendlyLinkMeet'

const btnStyles = {
  fontFamily: `Lato, sans-serif`,
  marginTop: '24px',
  fontWeight: 'bold',
  fontSize: '17px',
  lineHeight: '16px',
  color: '#ffffff',
  width: '100%',
  textTransform: 'capitalize',
  border: 'none',
  background:
    'transparent linear-gradient(89deg, #FF726F 0%, #FFCA55 120%) 0% 0% no-repeat padding-box',
}

export const SignupOmrView = props => {
  const { step, setSteps, changeSignupState, isLoading, signupState, callRequestCatchOmr } =
    props || {}

  const { labelStrings } = useTranslation()
  const mediaQueryMaxHeight = useMediaQuery('(max-height:680px)')

  const onRestart = () => {
    setSteps(0)
    changeSignupState({
      stepOneData: {
        email: '',
      },
      stepTwoData: {
        firstName: '',
        lastName: '',
        company: '',
        country: '',
        phone: '',
        companySize: '',
        position: '',
        contactPerson: '',
      },
      isLoading: false,
    })
  }

  const brandsImgArr = [
    'https://www.infludata.com/wp-content/uploads/henkel-1.svg',
    'https://www.infludata.com/wp-content/uploads/obi.svg',
    'https://www.infludata.com/wp-content/uploads/gymondo.svg',
    'https://www.infludata.com/wp-content/uploads/Teufel.svg',
    'https://www.infludata.com/wp-content/uploads/beiersdorf-1.svg',
    'https://www.infludata.com/wp-content/uploads/deichmann2.svg',
    'https://www.infludata.com/wp-content/uploads/der_touristik-1.svg',
    'https://www.infludata.com/wp-content/uploads/Kia.svg',
    'https://www.infludata.com/wp-content/uploads/MotelOne.svg',
    'https://www.infludata.com/wp-content/uploads/Losteria.svg',
    'https://www.infludata.com/wp-content/uploads/Babor.svg',
    'https://www.infludata.com/wp-content/uploads/sky-logo-new.svg',
  ]
  const badges = [
    'https://www.infludata.com/wp-content/uploads/influData_Used_by_OMR_Badge.png',
    'https://www.infludata.com/wp-content/uploads/influData-OMR-Top-Rated-Badge_02-2024.png',
    'https://www.infludata.com/wp-content/uploads/Capterra-Badge-1.webp',
  ]

  const referralsArray = [
    {
      name:
        'Hannah Sollé - Teamlead Influencer Marketing, hi!shaare.that (Medialabel Network GmbH)',
      text:
        '"The best foundation for successful influencer marketing is data. InfluData allows marketers to easily see accurate assessments of key KPIs. At hi!share.that, we\'ve been using InfluData for 2 years and are discovering more and more creators for our community."',
      imageLink: 'https://www.infludata.com/wp-content/uploads/Hannah_Solle-1.webp',
    },
    {
      name: 'Sarah Emmerich - Creator Marketing Expert',
      text:
        '"influData makes it easier for me to find influencers in different niches and sizes for my clients. Also, influData is my most valuable tool in analyzing influencers I’ve already selected, because I get a quality analysis about the target audience and all the important insights without even having contacted the influencer or their management."',
      imageLink: 'https://www.infludata.com/wp-content/uploads/Sarah_Emmerich.webp',
    },
  ]

  const renderStepView = {
    [0]: (
      <Stack spacing={2} sx={{ mb: '120px !important' }}>
        <Stack alignItems="center">
          <Typography
            textAlign="center"
            sx={{
              fontFamily: `Lato, sans-serif`,
              fontSize: '26px',
              fontWeight: 600,
              color: '#1F2937',
            }}
          >
            Your Next Creator Marketing Platform!
          </Typography>
          <Typography
            textAlign="center"
            sx={{ fontFamily: `Lato, sans-serif`, fontSize: '16px', color: '#6B7280' }}
          >
            Among all sign-ups during the OMR 2024, we are giving away a one-year influData All-In
            license (valued at approximately €15,000)
          </Typography>
        </Stack>
        <Email setSteps={setSteps} changeSignupState={changeSignupState} />
      </Stack>
    ),
    [1]: (
      <Stack spacing={2} sx={{ mb: '120px !important' }}>
        <Typography
          textAlign="center"
          sx={{
            fontFamily: `Lato, sans-serif`,
            fontSize: '26px',
            fontWeight: 600,
            color: '#1F2937',
          }}
        >
          Your Contact Information
        </Typography>
        <ContactInformation setSteps={setSteps} changeSignupState={changeSignupState} />
      </Stack>
    ),
    [2]: (
      <Stack>
        <Typography
          textAlign="center"
          sx={{
            fontFamily: `Lato, sans-serif`,
            fontSize: '26px',
            fontWeight: 600,
            color: '#1F2937',
          }}
        >
          {labelStrings?.signupLocalization?.lastStep}
        </Typography>
        <Typography textAlign="center" sx={{ fontFamily: `Lato, sans-serif`, fontSize: '16px' }}>
          {labelStrings?.signupLocalization?.collectLeadQuickDemoSubtitle}
        </Typography>
        <Button
          sx={btnStyles}
          onClick={() => {
            setSteps(prevStep => prevStep + 1)
            callRequestCatchOmr()
          }}
          size="large"
          sx={{
            fontFamily: `Lato, sans-serif`,
            marginTop: '24px',
            fontWeight: 'bold',
            fontSize: '17px',
            lineHeight: '16px',
            color: '#ffffff',
            width: '100%',
            textTransform: 'capitalize',
            border: 'none',
            background:
              'transparent linear-gradient(89deg, #FF726F 0%, #FFCA55 120%) 0% 0% no-repeat padding-box',
          }}
        >
          {labelStrings?.signupLocalization?.continue}
        </Button>
      </Stack>
    ),
    [3]: (
      <Stack>
        <Typography
          textAlign="center"
          sx={{
            fontFamily: `Lato, sans-serif`,
            fontSize: '26px',
            fontWeight: 600,
            color: '#1F2937',
          }}
        >
          {labelStrings?.signupLocalization?.thankYouForSigningUp}
        </Typography>
        <Typography textAlign="center" sx={{ fontFamily: `Lato, sans-serif`, fontSize: '16px' }}>
          {labelStrings?.signupLocalization?.weAreLookingForward}
        </Typography>
        <Button onClick={onRestart} size="large" sx={btnStyles}>
          {labelStrings?.signupLocalization?.restart}
        </Button>
      </Stack>
    ),
  }

  const renderViewRightSide = {
    [0]: (
      <Stack justifyContent="center">
        <Typography
          textAlign="center"
          sx={{
            fontFamily: `Lato, sans-serif`,
            fontSize: '26px',
            color: '#FFF',
            fontWeight: 600,
            mb: 5,
          }}
        >
          {labelStrings.signupLocalization.lovedByTheWorld}
        </Typography>

        <Stack
          alignItems="center"
          justifyContent="center"
          direction="row"
          sx={{
            flexWrap: 'wrap',
            gap: 3,
            maxWidth: '530px',
            '& img': {
              width: '100px', // Adjust this value to make the images smaller
              height: 'auto', // Keeps the aspect ratio
              filter: 'brightness(0) invert(1)', // Attempt to make the image appear white
            },
          }}
        >
          {brandsImgArr?.map((link, idx) => {
            return <img src={link} key={idx} alt="" />
          })}
        </Stack>
        <Box sx={{ height: '60px' }}></Box>
        <Typography
          textAlign="center"
          sx={{
            fontFamily: `Lato, sans-serif`,
            fontSize: '26px',
            color: '#FFF',
            fontWeight: 600,
            mb: 5,
          }}
        >
          {labelStrings.signupLocalization.topRated}
        </Typography>
        <Stack
          alignItems="center"
          justifyContent="center"
          direction="row"
          sx={{
            flexWrap: 'wrap',
            gap: 3,
            maxWidth: '530px',
            '& img': {
              width: '120px', // Adjust this value to make the images smaller
              height: 'auto', // Keeps the aspect ratio
            },
          }}
        >
          {badges?.map((link, idx) => {
            return <img src={link} key={idx} alt="" />
          })}
        </Stack>
      </Stack>
    ),
    [1]: (
      <Stack>
        <Typography
          textAlign="center"
          sx={{
            fontFamily: `Lato, sans-serif`,
            fontSize: '19px',
            color: '#FFF',
            fontWeight: 600,
            mb: 3,
          }}
        >
          {labelStrings.signupLocalization.whatCustomersSaying}
        </Typography>
        <Stack
          sx={{
            maxWidth: '500px',
            height: '290px',
          }}
          spacing={2}
        >
          <Stack
            spacing={0.5}
            justifyContent="center"
            alignItems="center"
            sx={{ width: '100%', height: '100%' }}
          >
            <Box
              sx={{
                maxWidth: '220px',
                maxHeight: '220px',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
              }}
            >
              <img
                src={referralsArray[step]?.imageLink || referralsArray[0].imageLink}
                style={{
                  borderRadius: '6px',
                }}
              />
            </Box>
            <Typography
              textAlign="center"
              sx={{
                fontFamily: `Lato, sans-serif`,
                fontSize: '18px',
                color: '#FFFFFF',
                fontWeight: 'bold',
              }}
            >
              {referralsArray[step]?.name || referralsArray[0]?.name}
            </Typography>
          </Stack>

          <Typography
            textAlign="center"
            sx={{ fontFamily: `Lato, sans-serif`, fontSize: '18px', color: '#FFFFFF' }}
          >
            {referralsArray[step]?.text || referralsArray[0].text}
          </Typography>
        </Stack>
      </Stack>
    ),
    [2]: (
      <Box sx={{ mb: 4 }}>
        <CalendlyLinkMeet
          email={signupState?.stepOneData?.email}
          firstName={signupState?.stepTwoData?.firstName}
          lastName={signupState?.stepTwoData?.lastName}
        />
      </Box>
    ),
  }

  return (
    <Modal keepMounted open={true}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: 'background.paper',
          overflow: 'hidden',
        }}
      >
        <Stack direction="row" justifyContent="space-between" sx={{ height: '100%' }}>
          {/* left-side */}
          <Stack sx={{ width: '50%', height: '100%' }}>
            {isLoading && (
              <LoadingSpinner
                width="60vw"
                height="100%"
                showOnFullPage
                isTransparent
                isOuterPageLoad
              />
            )}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ p: mediaQueryMaxHeight ? '15px 80px' : '20px 80px' }}
            >
              <Box>
                <Link href="/" underline="none">
                  <Box
                    sx={{
                      img: {
                        maxHeight: '60px',
                      },
                    }}
                  >
                    <PDF_INFLUDATA_LOGO />
                  </Box>
                </Link>
              </Box>
            </Stack>
            <Stack justifyContent="center" alignItems="center" sx={{ width: '100%', mt: '10px' }}>
              <Stack justifyContent="center" sx={{ maxWidth: '450px', width: '100%' }}>
                {renderStepView[step]}
              </Stack>
            </Stack>
          </Stack>
          {/* right-side */}
          <Stack
            sx={{
              width: '50%',
              height: '100%',
              background: `radial-gradient(circle at 55% 191%, rgb(215, 162, 45), rgb(215, 162, 45) 30%, rgb(215, 74, 71) 100%) 0% 0% no-repeat`,
              p: 2,
            }}
            justifyContent="start"
            alignItems="center"
          >
            <Box sx={{ height: '350px' }}></Box>
            {renderViewRightSide[step === 3 ? 1 : step]}
            <Box sx={{ height: '350px' }}></Box>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  )
}
