import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useTranslation from '../../../../localization/useTranslation'
import { selectedProfileSelector } from '../../../../redux/selectors'
import {
  AudienceAgeGroupsChart,
  AudienceCountriesCitiesChart,
  AudienceGenderChart,
  AudienceLanguageChart,
  AudienceTypeChart,
} from '../../audienceAnalysis/audienceAnalysisCharts/index'
import { Section, SectionTitle } from '../commonStyles'
import PageLayout from '../PageLayout/PageLayout'
import * as Styled from './styles'
import { USER_INDEXES } from '../../../../constants/appSettings'
import {
  SectionTitleBorder,
  SectionTitleName,
  SectionTitleWrapper,
} from '../../commonProfilePageStyles'

const AudienceAnalysisPage1 = ({ isBranded = false }) => {
  const { labelStrings } = useTranslation()
  const { profile } = useSelector(selectedProfileSelector)
  const {
    _id: profileId,
    username,
    _index: platform,
    followers: followersNumber,
    audienceAnalysis,
  } = profile || {}

  if (!audienceAnalysis) return null

  const {
    countryArray = [],
    cityArray = [],
    genderMtoF,
    audienceType,
    languageArray,
    averageBirthyearArray,
    birthyearMedian,
  } = audienceAnalysis

  return (
    <PageLayout profileId={profileId} username={username} platform={platform} isBranded={isBranded}>
      <Section noPadding={platform === USER_INDEXES.tiktok}>
        <SectionTitle smallMb platform={platform}>
          {labelStrings.audienceAnalysis}
        </SectionTitle>

        <SectionTitleWrapper>
          <SectionTitleName platform={platform} isForPdf={true}>
            {labelStrings.location}
          </SectionTitleName>
          <SectionTitleBorder isForPdf />
        </SectionTitleWrapper>

        <Styled.Grid smallGridRowGap={platform === USER_INDEXES.tiktok}>
          <Styled.GridItem>
            <AudienceCountriesCitiesChart
              userindex={platform}
              countryArray={countryArray}
              followersCount={followersNumber}
              chartTitle={labelStrings.country}
              chartType="country"
              isDisabledAnalysis={false}
              isForPdf
            />
          </Styled.GridItem>

          <Styled.GridItem>
            <AudienceCountriesCitiesChart
              userindex={platform}
              cityArray={cityArray}
              followersCount={followersNumber}
              chartTitle={labelStrings.city}
              chartType="city"
              isDisabledAnalysis={false}
              isForPdf
            />
          </Styled.GridItem>

          <Styled.GridItem>
            <SectionTitleWrapper>
              <SectionTitleName platform={platform} isForPdf={true}>
                {labelStrings.gender}
              </SectionTitleName>
              <SectionTitleBorder isForPdf />
            </SectionTitleWrapper>
            <AudienceGenderChart
              userindex={platform}
              genderMtoF={genderMtoF}
              isDisabledAnalysis={false}
              isForPdf
            />
          </Styled.GridItem>

          {platform === USER_INDEXES.instagram && (
            <Styled.GridItem>
              <SectionTitleWrapper>
                <SectionTitleName platform={platform} isForPdf={true}>
                  {labelStrings.audienceType}
                </SectionTitleName>
                <SectionTitleBorder isForPdf />
              </SectionTitleWrapper>
              <AudienceTypeChart
                userindex={platform}
                audienceType={audienceType}
                isDisabledAnalysis={false}
                isForPdf
              />
            </Styled.GridItem>
          )}

          {platform === USER_INDEXES.tiktok && (
            <Styled.GridItem>
              <>
                <SectionTitleWrapper>
                  <SectionTitleName platform={platform} isForPdf={true}>
                    {labelStrings.language}
                  </SectionTitleName>
                  <SectionTitleBorder isForPdf />
                </SectionTitleWrapper>
                <AudienceLanguageChart
                  userindex={platform}
                  languageArray={languageArray}
                  isDisabledAnalysis={false}
                  followersCount={followersNumber}
                  isForPdf
                />
              </>
              <>
                <SectionTitleWrapper>
                  <SectionTitleName platform={platform} isForPdf={true}>
                    {labelStrings.ageGroups}
                  </SectionTitleName>
                  <SectionTitleBorder isForPdf />
                </SectionTitleWrapper>
                <AudienceAgeGroupsChart
                  userindex={platform}
                  averageBirthyearArray={averageBirthyearArray}
                  isDisabledAnalysis={false}
                  followersCount={followersNumber}
                  birthyearMedian={birthyearMedian}
                  isForPdf
                />
              </>
            </Styled.GridItem>
          )}
        </Styled.Grid>
      </Section>
    </PageLayout>
  )
}

AudienceAnalysisPage1.propTypes = {
  isBranded: PropTypes.bool,
}

export default AudienceAnalysisPage1
