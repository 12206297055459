import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Masonry from 'react-masonry-css'
import { ProfileContentItem } from './ProfileContentItem'
import { ContentWrapper, LoaderWrapper, MasonryWrapper } from './styles'
import LoadingSpinner from '../../../common/spinners/LoadingSpinner'

const breakpointColumnsObj = {
  default: 6,
  1890: 5,
  1640: 4,
  1390: 3,
  1140: 2,
  350: 1,
}

export const ProfileContentTable = ({ content = [], isSearchLoading = false, setActiveCard, activeCard = {}, openedCollection = {} }) => {
  const [renderedContent, setRenderedContent] = useState(content)
  
  const { id: currCollectionId } = openedCollection || {}
  const isContentFav = currCollectionId === 'fav'

  useEffect(() => {
    setRenderedContent(content)
  }, [content])

  return (
    <>
      {isSearchLoading ? (
        <LoaderWrapper>
          <LoadingSpinner forSearch />
        </LoaderWrapper>
      ) : (
        <ContentWrapper>
          <MasonryWrapper>
            <Masonry
              className="content-masonry-grid"
              columnClassName="content-masonry-grid_column"
              breakpointCols={breakpointColumnsObj}
            >
              {renderedContent?.map((profile, idx) => (
                <ProfileContentItem
                  profile={profile}
                  key={`${profile.profileId}${idx}`}
                  setActiveCard={setActiveCard}
                  activeCard={activeCard}
                  setRenderedContent={setRenderedContent}
                  isContentFav={isContentFav}
                />
              ))}
            </Masonry>
          </MasonryWrapper>
        </ContentWrapper>
      )}
    </>
  )
}

ProfileContentTable.propTypes = {
  content: PropTypes.array,
  isSearchLoading: PropTypes.bool,
  setActiveCard: PropTypes.func,
  activeCard: PropTypes.object,
  openedCollection: PropTypes.object
}