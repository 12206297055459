import React from 'react'
import styled from 'styled-components'
import useTranslation from '../localization/useTranslation'
import PageContainer from './PageContainer'

const Page404Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: -10;
`

const Page404Message = styled.div`
  font-size: 32px;
  line-height: 36px;
  color: ${props => props.theme.primaryColor};
  font-weight: bold;
`

const Page404 = ({ withoutPageContainer = false }) => {
  const { labelStrings } = useTranslation()

  const Page404Content = withoutPageContainer ? (
    <Page404Wrapper>
      <Page404Message>{labelStrings.pageNotFound}!</Page404Message>
    </Page404Wrapper>
  ) : (
    <PageContainer>
      <Page404Wrapper>
        <Page404Message>{labelStrings.pageNotFound}!</Page404Message>
      </Page404Wrapper>
    </PageContainer>
  )

  return Page404Content
}

export default Page404
