export const EMAIL_VALID_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const LOGIN_INPUT_TYPES = { email: 'email', password: 'password' }
export const API_SIGN_ROUTES = {
  login: '/api/profile/authenticate?onSuccess=/',
  //   login: '/api/profile/authenticate?method=chargebee&onSuccess=/account-portal', // new route, not used now
  logout: '/api/profile/logout',
}

/**
 * NOTE: phone validation regex matches the following formats:
 * (123) 456-7890
 * +(123) 456-7890
 * +(123)-456-7890
 * +(123) - 456-7890
 * +(123) - 456-78-90
 * 123-456-7890
 * 123.456.7890
 * 1234567890
 * +31636363634
 * 075-63546725
 */
export const PHONE_VALID_REGEX = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\.\/0-9]*$/
