import React from 'react'
import useTranslation from '../../../localization/useTranslation'
import LinkToFaq from '../../common/links/LinkToFaq'
import { NoResultsFoundContainter, NoResultsFoundMessage } from './styles'

const SimilarAccountsNoResults = () => {
  const { labelStrings } = useTranslation()

  return (
    <NoResultsFoundContainter>
      <NoResultsFoundMessage>{labelStrings.noSimilarAccountsFound}</NoResultsFoundMessage>
      <LinkToFaq>{labelStrings.whatAreSimilarAccounts}</LinkToFaq>
    </NoResultsFoundContainter>
  )
}

export default SimilarAccountsNoResults
