import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import { Autocomplete, TextField } from '@mui/material'

RHFAutocomplete.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.node,
}

export default function RHFAutocomplete({ name, label, helperText, ...other }) {
  const { control, setValue } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <Autocomplete
            {...field}
            onChange={(event, newValue) => setValue(name, newValue, { shouldValidate: true })}
            value={field.value || []}
            renderInput={params => (
              <TextField
                label={label}
                error={!!error}
                helperText={error ? error?.message : helperText}
                value={field?.value || ''}
                {...params}
              />
            )}
            {...other}
          />
        )
      }}
    />
  )
}
