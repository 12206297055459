import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, Button, DialogTitle, DialogActions, DialogContent } from '@mui/material'

const ConfirmDialog = ({ title, content, action, open, onClose, maxWidth = 'xs', ...other }) => {
  return (
    <Dialog fullWidth maxWidth={maxWidth} open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 2, textAlign: 'center' }}>{title}</DialogTitle>

      {content && <DialogContent sx={{ typography: 'body2', display: 'flex', justifyContent: 'center' }}> {content} </DialogContent>}

      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Cancel
        </Button>
        {action}
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.node,
  action: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
}

export default ConfirmDialog
