import styled from 'styled-components'

export const RatingSectionContainer = styled.div`
  ${props =>
    !props.isForPdf &&
    `
    padding: 20px;
    margin-top: 20px;
  `}
`

export const RatingSectionGridItem = styled.div`
  min-height: 300px;
  padding: 30px;
  margin-top: -9px;
  position: relative;
`

export const RatingSectionGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${props => props.columnsCount}, 1fr);

  ${RatingSectionGridItem} {
    ${props =>
      props.isForPdf &&
      `
      margin-top: 0;
      min-height: 225px;
      padding: 20px;
    `}

    border-right: 1px solid ${props => props.theme.colors.lightBrown};
    border-bottom: 1px solid ${props => props.theme.colors.lightBrown};

    &:nth-child(${props => props.columnsCount}n) {
      padding-right: 0;
      border-right: none;
    }
    &:nth-child(${props => props.columnsCount}n + 1) {
      padding-left: 0;
    }
  }
`

export const RatingTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => (!props.isForPdf ? 'flex-start' : 'center')};
  align-items: center;
  flex-wrap: wrap;
  ${props => props.isForPdf && 'margin-bottom: 15px'};
`

export const RatingTitleWithScoreWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  ${RatingTitleWrapper} {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const RatingTitle = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.darkBrown};
  margin-right: 10px;
`

export const SponsoredPostsStoriesLabel = styled(RatingTitle)`
  max-width: 350px;
  margin: ${props => props.isForPdf ? '6px 0 21px' : '30px 0 50px'};
  text-transform: none;
  ${props => props.isForPdf && `font-weight: 400`};
  ${props => props.isForPdf && `padding-left: 55px`};
`

export const RatingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: ${props => props.justifyContent || 'space-between'};
  margin-top: ${props => (!props.isForPdf ? '30px' : '15px')};
  ${props =>
    props.hasReelScores &&
    `
    .comparison-to-similar-influencers-chart {
      margin-right: 50px;
    }
  `}
`

export const ContactSalesWrapper = styled.div`
  padding: 100px 0;
`
