import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'
import { Stack } from '@mui/material'
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded'
import { StyledBtnClickHere, TextWrapper } from './styles'

const AddProfileToInfludata = ({ onAddUserClick }) => {
  const { labelStrings, currentLang } = useTranslation()
  return (
    <Stack alignItems="center" justifyContent="center">
      <TextWrapper>
        {labelStrings.notInHereYet}? {labelStrings.noProblem}!
      </TextWrapper>

      <Stack
        direction={currentLang === 'de' ? 'column' : 'row'}
        alignItems="center"
        justifyContent="center"
        sx={{ width: '100%' }}
      >
        <Stack direction="row" alignItems="center">
          <PersonAddAltRoundedIcon />
          <StyledBtnClickHere variant="text" onClick={onAddUserClick}>
            {labelStrings.clickHere}
          </StyledBtnClickHere>
        </Stack>

        <TextWrapper>{labelStrings.toAddUserToInfludata}</TextWrapper>
      </Stack>
    </Stack>
  )
}

AddProfileToInfludata.propTypes = {
  onAddUserClick: PropTypes.func,
}

export default AddProfileToInfludata
