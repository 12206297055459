import { USER_INDEXES } from '../constants/appSettings'
import availableLangs from './availableLangs'

export default {
  [availableLangs.en]: {
    privateNotesAdditionalInfo:
      'Prices, Addresses, Dates, Tags etc. - Collaborate with your team. You are able to create your individual fields that will be present on all influencers and everyone in your team will see them and will have access. You can remove the fields anytime or edit them. Add comments and notes and communicate with your team.',
    followersHistory:
      'The follower graph shows the number of follower historically. This gives you an idea when this influencer gained or lost followers.',
    viewsHistory:
      'This graphs shows the history of the average views. We always analyze the 10 most recent videos/shorts.',

    qualityRating: {
      [USER_INDEXES.instagram]: 'The Overall Score is calculated based on the detailed scores and has an individual weighting.<br><b>High Impact:<br></b>Follower Growth</br>Comment Engagement</br>Like Engagement</br>Suspicious Follower</br><b>Medium Impact:</b></br>Post Frequency</br>Reels Engagement, Plays and Watch Time</br><b>Low Impact:</b></br>Frequencies of Stories and Reels',
      [USER_INDEXES.youtube]:'The Overall Score is calculated based on the detailed scores and has an individual weighting.<br><b>High Impact:<br></b>Follower Growth</br>Comment Engagement</br>Like Engagement</br><b>Medium Impact:</b></br>Post Frequency</br>Shorts Engagement</br>Plays of Videos & Shorts</br><b>Low Impact:</b></br>Shorts Frequency</br>Positive & Negative Reactions</br>Change in Plays of Shorts & Videos'
    },
    similarAccounts:
      //'Similar accounts are based on the biography, the follower count and the location of the influencer you are looking at.',
      'Similar accounts are influencers that are also followed by the audience of the influencer you are looking at.',
    detailedQualityRating:
      'Detailed scores are based on the most important metrics that we identified when it comes to influencer markeing. Each individual score is important based on your campaign goals.',
    tiktokStatistics:
      'TikTok statistics provide the most important metrics that we identified as important for influencer marketing.',
    viewsPerPost:
      'Views per Post provide the average number of plays per individual post over time.',
    verifiedInformation: 'verified information'
  },
  [availableLangs.de]: {
    privateNotesAdditionalInfo:
      'Preise, Adressdaten oder Kontaktdatum - Du hast hier die Möglichkeit mit deinem Team zu kommunizieren und eigene Felder zu erstellen. Diese Felder sind automatisch bei jedem Influencer vorhanden und du kannst diese jederzeit löschen oder bearbeiten. Außerdem hast du die Möglichkeit dich mit deinem Team über die Kommentarfunktion auszutauschen.',
    followersHistory:
      'Dieser Followergraph zeigt die Anzahl der Follower im Verlauf der Zeit. Du kannst hier sehen, wenn Follower gewonnen oder verloren wurden.',
    viewsHistory:
      'Dieser Graph zeigt den Verlauf der durchschnittlichen Anzahl der Views an. Es werden jeweils die letzten 10 Videos/Shorts herangezogen',

      qualityRating: {
        [USER_INDEXES.instagram]: 'Der Gesamtscore berechnet sich auf Grundlage der einzelnen Mini-Scores, die unterschiedlich gewichtet werden.</br> <br><b>Großer Einfluss</b>:<br>Followerwachstum<br>Comment-Engagement</br>Like-Engagement</br>Verdächtige Follower</br><b>Mittlerer Einfluss:</b></br>Posthäufigkeit</br>Reels-Engagement, Plays und Watch Time</br><b>Niedrigen Einfluss:</b></br>Frequenz der Storys und Reels',
        [USER_INDEXES.youtube]: 'Der Gesamtscore berechnet sich auf Grundlage der einzelnen Mini-Scores, die unterschiedlich gewichtet werden.<b>Großer Einfluss:</b><br>Followerwachstum<br>Comment-Engagement</br>Like-Engagement</br><b>Mittlerer Einfluss:</b></br>Videohäufigkeit</br>Shorts-Engagement</br>Aufrufe der Videos & Shorts</br></br><b>Geringer Einfluss:</b></br>Shorthäufigkeit</br>Positive und Negative Reaktionen</br>Veränderung der Views der Videos & Shorts</br>'
      },
      similarAccounts:
      'Ähnliche Profile sind Influencer, die eine ähnliche Audience wie der Influencer aufweisen.',
    detailedQualityRating:
      'Detaillierte Scores basieren auf den wichtigsten Metriken, die wir für erfolgreiches Influencer-Marketing identifiziert haben. Jeder dieser Scores kann je nach Ziel der Kampagne eine unterschiedliche Wichtigkeit haben.',
    tiktokStatistics:
      'Unsere TikTok-Statistiken fassen die wichtigsten Metriken eines TikTok-Creators zusammen.',
    viewsPerPost:
      'Aufrufe pro Post zeigen die durchschnittlichen Plays eines individuellen Posts im Verlauf der Zeit.',
    verifiedInformation: 'verified information'
  },
}
