import React from 'react'
import PropTypes from 'prop-types'
import { Stack, InputAdornment, TextField, MenuItem, Button } from '@mui/material'
import Iconify from '../../components/iconify'

export const GoalTableToolbar = ({
  isFiltered,
  filterName,

  filterStatuses,
  optionsStatus,

  onFilterName,
  onFilterStatuses,
  onResetFilter,
  onlySearch,

  isLoading,
}) => {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: 'column',
        sm: 'row',
      }}
      sx={{ px: 2.5, py: 3 }}
    >
      {!onlySearch && (
        <TextField
          fullWidth
          select
          label="Status"
          value={filterStatuses}
          onChange={onFilterStatuses}
          disabled={isLoading}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  maxHeight: 260,
                },
              },
            },
          }}
          sx={{
            maxWidth: { sm: 240 },
            textTransform: 'capitalize',
          }}
        >
          {optionsStatus?.map(option => (
            <MenuItem
              key={option.label}
              value={option.value}
              sx={{
                mx: 1,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}

      <TextField
        fullWidth
        disabled={isLoading}
        value={filterName}
        onChange={onFilterName}
        placeholder="Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      />

      {isFiltered && (
        <Button
          color="error"
          sx={{ flexShrink: 0 }}
          onClick={onResetFilter}
          startIcon={<Iconify icon="eva:trash-2-outline" />}
        >
          Clear
        </Button>
      )}
    </Stack>
  )
}

GoalTableToolbar.propTypes = {
  isFiltered: PropTypes.bool,
  filterName: PropTypes.string,
  filterStatuses: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onFilterName: PropTypes.func,
  onFilterStatuses: PropTypes.func,
  onResetFilter: PropTypes.func,
  optionsStatus: PropTypes.arrayOf(PropTypes.object),
  onlySearch: PropTypes.bool,
}
