import styled from 'styled-components'

export const RatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const RatingName = styled.div`
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: ${props => props.theme.colors.mauve};
  text-transform: uppercase;
  margin-bottom: 10px;
`

export const RatingMeterWrapper = styled.div`
  ${props => `
    width: ${props.scoreSize * 1.2}px;
    height: ${props.scoreSize * 1.2}px;
  `}
  position: relative;
  user-select: none;

  .rating_meter {
    circle {
      stroke-width: ${props => (props.isOverallScore ? 11 : 9)};
    }
  }
`

export const RatingBackground = styled.div`
  ${props => `
    width: ${props.scoreSize * 1.2}px;
    height: ${props.scoreSize * 1.2}px;
  `}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const RatingValue = styled.div`
  ${props => {
    let fontSize = Math.round((props.scoreSize * 32) / 100)
    let lineHeight = Math.round((props.scoreSize * 34) / 100)
    let marginBottom = props.isForPdf ? (props.isSmallRatingValue ? '22px' : '5px') : '0px' // adjust this value as needed
    return `
      font-size: ${fontSize}px;
      line-height: ${lineHeight}px;
      margin-bottom: ${marginBottom};
    `
  }};
  font-weight: 600;
  ${props => props.isOutOfHidden && 'transform: scale(1.5);'}
  ${props => props.isEngagementMean && 'transform: scale(0.85);'}
  ${props =>
    props.isForPdf
      ? `color: ${props.theme.getColorPaletteForPlatform(props.platform).main};`
      : props.theme.getTextGradientStyles({ platform: props.platform })}
`

export const RatingText = styled.div`
  ${props => {
    let fontSize = Math.round((props.scoreSize * 15) / 100);
    let lineHeight = Math.round((props.scoreSize * 19) / 100);
    let marginBottom = props.isForPdf ? '30px' : '0px'; // adjust this value as needed
    return `
      font-size: ${fontSize}px;
      line-height: ${lineHeight}px;
      margin-bottom: ${marginBottom};
    `
  }};
  font-weight: 600;
  ${props =>
    props.isForPdf
      ? `color: ${props.theme.getColorPaletteForPlatform(props.platform).main};`
      : props.theme.getTextGradientStyles({ platform: props.platform })}
`;
