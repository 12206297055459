import React, { useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import useOutsideClick from '../../../../hooks/useOutsideClick'
import useTranslation from '../../../../localization/useTranslation'
import { generateRandomId } from '../../../../utils'
import { USER_INDEXES } from '../../../../constants/appSettings'
import {
  ADDITIONAL_FIELD_TYPES,
  ADDITIONAL_FIELD_PROP_TYPE,
} from '../../../../constants/additionalFields'
import AdditionalFieldTypeSelect from '../../../common/selects/AdditionalFieldTypeSelect'
import SelectOptions from './SelectOptions'
import Input from '../../../common/inputs/Input'
import { AddIcon } from '../../../common/icons/icons'
import Spinner from '../../../common/spinners/Spinner'
import { IconWrapper } from '../commonStyles'
import {
  AdditionalFieldDetailsOverlay,
  AdditionalFieldSelectTools,
  AdditionalFieldSelectError,
  ButtonAddSelectOption,
  ButtonTextAddSelectOption,
  ButtonsWrapper,
  ButtonSave,
  ButtonCancel,
} from './styles'

const AdditionalFieldDetails = ({
  additionalFieldData = {
    code: '',
    label: '',
    type: '',
    value: '',
    allowedValues: [],
    created: '',
    isHidden: false,
  },
  platform = '',
  position = {
    top: '',
    bottom: '',
    left: '',
    right: '',
  },
  isEditing = false,
  isLoading = false,
  saveNote,
  closeOverlay,
}) => {
  const { labelStrings, errors } = useTranslation()

  const [localAdditionalFieldData, setLocalAdditionalFieldData] = useState(additionalFieldData)
  const { code, label: fieldName, type, value, allowedValues } = localAdditionalFieldData || {}
  const [fieldNameError, setFieldNameError] = useState('')
  const [allowedValuesError, setAllowedValuesError] = useState('')

  const wrapperRef = useRef(null)
  useOutsideClick({ ref: wrapperRef, callback: closeOverlay })

  const isSingleOrMultiSelect =
    type === ADDITIONAL_FIELD_TYPES.singleSelect || type === ADDITIONAL_FIELD_TYPES.multiSelect
  const hasFieldNameAndType = !!fieldName && !!type
  let canSaveField = hasFieldNameAndType && !fieldNameError

  if (isSingleOrMultiSelect) {
    const hasOptions = allowedValues.length !== 0
    const hasEmptyOption = allowedValues.some(option => !option.value)
    canSaveField =
      hasFieldNameAndType && !fieldNameError && hasOptions && !hasEmptyOption && !allowedValuesError
  }

  const onChangeFieldName = event => {
    setLocalAdditionalFieldData({
      ...localAdditionalFieldData,
      label: event.target.value,
    })
  }

  const onChangeFieldType = newType => {
    if (!isEditing) {
      setLocalAdditionalFieldData({
        ...localAdditionalFieldData,
        type: newType,
        value: newType === ADDITIONAL_FIELD_TYPES.multiSelect ? [] : '',
        allowedValues: [],
      })
    }
  }

  const addSelectOption = () => {
    setLocalAdditionalFieldData({
      ...localAdditionalFieldData,
      allowedValues: [...allowedValues, { id: generateRandomId(), value: '' }],
    })
    setAllowedValuesError('')
  }

  const changeSelectOptionById = (optionId, newValue) => {
    setLocalAdditionalFieldData({
      ...localAdditionalFieldData,
      allowedValues: allowedValues.map(option => {
        if (option.id === optionId) {
          return { ...option, value: newValue }
        }
        return option
      }),
    })
  }

  const moveSelectOptionByDragNDrop = useCallback(
    (dragIndex, hoverIndex) => {
      const draggedOption = allowedValues[dragIndex]

      const newOptions = [...allowedValues]
      newOptions.splice(dragIndex, 1)
      newOptions.splice(hoverIndex, 0, draggedOption)

      setLocalAdditionalFieldData({
        ...localAdditionalFieldData,
        allowedValues: newOptions,
      })
    },
    [fieldName, allowedValues]
  )

  const deleteSelectOptionById = optionId => {
    setLocalAdditionalFieldData({
      ...localAdditionalFieldData,
      allowedValues: allowedValues.filter(option => option.id !== optionId),
    })
  }

  const validateFieldName = () => {
    if (fieldName.length < 2 || fieldName.length > 250) {
      setFieldNameError(errors.additionalFieldNameIsNotValid)
    } else {
      setFieldNameError('')
    }
  }

  const onSaveClick = async () => {
    if (isSingleOrMultiSelect && allowedValues.length < 2) {
      return setAllowedValuesError(errors.additionalFieldOptionsAreLessThanTwo)
    }
    await saveNote(localAdditionalFieldData)
    closeOverlay()
  }

  return (
    <AdditionalFieldDetailsOverlay ref={wrapperRef} position={position}>
      {isLoading && <Spinner isOverlay />}

      <Input
        id="note_field-name"
        name="note_field-name"
        type="text"
        label={labelStrings.name}
        value={fieldName}
        errorMessage={fieldNameError}
        onChange={onChangeFieldName}
        onBlur={validateFieldName}
        autoComplete="off"
      />

      <AdditionalFieldTypeSelect
        selectedFieldType={type}
        onSelectFieldType={onChangeFieldType}
        canChangeFieldType={!isEditing}
        platform={platform}
      />

      {hasFieldNameAndType &&
        (() => {
          switch (type) {
            case ADDITIONAL_FIELD_TYPES.singleSelect:
              return (
                <AdditionalFieldSelectTools>
                  <SelectOptions
                    options={allowedValues}
                    moveOptionByDragNDrop={moveSelectOptionByDragNDrop}
                    changeOptionById={changeSelectOptionById}
                    deleteOptionById={deleteSelectOptionById}
                  />

                  <ButtonAddSelectOption onClick={addSelectOption}>
                    <IconWrapper>
                      <AddIcon />
                    </IconWrapper>
                    <ButtonTextAddSelectOption>
                      {labelStrings.addAnOption}
                    </ButtonTextAddSelectOption>
                  </ButtonAddSelectOption>

                  <AdditionalFieldSelectError>{allowedValuesError}</AdditionalFieldSelectError>
                </AdditionalFieldSelectTools>
              )
            case ADDITIONAL_FIELD_TYPES.multiSelect:
              return (
                <AdditionalFieldSelectTools>
                  <ButtonAddSelectOption onClick={addSelectOption}>
                    <IconWrapper>
                      <AddIcon />
                    </IconWrapper>
                    <ButtonTextAddSelectOption>
                      {labelStrings.addAnOption}
                    </ButtonTextAddSelectOption>
                  </ButtonAddSelectOption>

                  <SelectOptions
                    options={allowedValues}
                    moveOptionByDragNDrop={moveSelectOptionByDragNDrop}
                    changeOptionById={changeSelectOptionById}
                    deleteOptionById={deleteSelectOptionById}
                  />

                  <AdditionalFieldSelectError>{allowedValuesError}</AdditionalFieldSelectError>
                </AdditionalFieldSelectTools>
              )
            default:
              return null
          }
        })()}

      <ButtonsWrapper>
        <ButtonCancel onClick={closeOverlay}>{labelStrings.cancel}</ButtonCancel>

        {canSaveField && (
          <ButtonSave platform={platform} onClick={onSaveClick}>
            {!isEditing ? labelStrings.createField : labelStrings.saveField}
          </ButtonSave>
        )}
      </ButtonsWrapper>
    </AdditionalFieldDetailsOverlay>
  )
}

AdditionalFieldDetails.propTypes = {
  additionalFieldData: ADDITIONAL_FIELD_PROP_TYPE,
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  position: PropTypes.shape({
    top: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
  }),
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  saveNote: PropTypes.func,
  closeOverlay: PropTypes.func,
}

export default AdditionalFieldDetails
