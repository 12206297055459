import React, { useEffect, useState } from 'react'
import { Stack, Typography, Link, Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import { renewSubscription } from '../../../../redux/ducks/audienceReports'
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded'

const renewSubscriptionMessage = {
  [200]: 'This page will refresh in 30 seconds and your reports will be available.',
  [400]: 'Your renewal was not successful, please contact us to resolve this issue.',
  [500]: 'Your renewal was not successful, please contact us to resolve this issue.',
}
const _DELAY_REFRESH_PAGE = 30000

export const NoTokensLeftPage = () => {
  const dispatch = useDispatch()

  const [isLinkClicked, setIsLinkClicked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [renewSubscriptionStatusCode, setRenewSubscriptionStatusCode] = useState(null)

  const handleSubscriptionClick = () => {
    if (!isLinkClicked) {
      setIsLinkClicked(true)
      window.open('https://infludata-audience.chargebeeportal.com', '_blank', 'noopener,noreferrer')
    }
  }

  const handleRenewSubscription = async () => {
    setIsLoading(true)
    try {
      // @ts-ignore
      const response = await dispatch(renewSubscription())
      console.log('Renew subscription response:', response) // Add logging here
      setRenewSubscriptionStatusCode(response) // Correctly capturing the status code
    } catch (error) {
      console.error('Error renewing subscription:', error)
      // Optionally, set a status code to handle error messages
      setRenewSubscriptionStatusCode(error.response?.status || 500)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (renewSubscriptionStatusCode === 200) {
      const timeoutId = setTimeout(() => {
        window.location.reload()
      }, _DELAY_REFRESH_PAGE)

      return () => clearTimeout(timeoutId)
    }
  }, [renewSubscriptionStatusCode])

  return (
    <Stack alignItems="center" spacing={2}>
      <Stack>
        <Typography align="center" sx={{ color: '#784E4E', fontWeight: 500, fontSize: '21px' }}>
          You are out of reports.
        </Typography>
        <Typography align="center" sx={{ color: '#784E4E', fontWeight: 500, fontSize: '14px', marginLeft: '100px', marginRight: '100px' }}>
          Please manage or change your plan, wait until the next term or renew the subscription now. If you choose to renew your subscription now, you will be billed now and you start your next term immediately.  
        </Typography>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Link
          href="javascript:void(0)"
          data-cb-type="portal"
          onClick={handleSubscriptionClick}
          sx={{
            color: '#784E4E',
            fontWeight: 600,
            fontSize: '18px',
            textDecoration: 'none',
            cursor: 'pointer',
            border: '1px solid #784E4E',
            width: 'fit-content',
            py: 1,
            px: 2,
            borderRadius: '9px',
            '&:hover': {
              opacity: 0.7,
              textDecoration: 'none',
              color: '#784E4E',
            },
          }}
        >
          Edit Subscription
        </Link>

        <Button
          onClick={handleRenewSubscription}
          disableRipple
          disabled={isLoading || renewSubscriptionStatusCode}
          sx={{
            fontSize: '18px',
            fontWeight: 600,
            border: '1px solid #784E4E',
            color: '#784E4E',
            '&:hover': {
              opacity: 0.7,
              backgroundColor: 'transparent',
              borderColor: '#784E4E',
            },
          }}
          variant="outlined"
        >
          Renew Subscription Now
        </Button>
      </Stack>

      {renewSubscriptionStatusCode && (
        <Stack justifyContent="center">
          {renewSubscriptionStatusCode === 200 && (
            <Typography align="center" sx={{ color: '#784E4E', fontWeight: 600, fontSize: '18px' }}>
              Thank you for renewing.
            </Typography>
          )}
          <Stack direction="row" alignItems="center">
            {renewSubscriptionStatusCode === 200 && (
              <HourglassTopRoundedIcon sx={{ fill: '#784E4E' }} />
            )}
            <Typography align="center" sx={{ color: '#784E4E', fontWeight: 600, fontSize: '18px' }}>
              {renewSubscriptionMessage[renewSubscriptionStatusCode]}
            </Typography>
          </Stack>
        </Stack>
      )}

      <Typography align="center" sx={{ color: '#784E4E', fontWeight: 500, fontSize: '18px', paddingBottom: '250px' }}>
        You are still able to view your unlocked reports by clicking the button below.
      </Typography>
    </Stack>
  )
}
