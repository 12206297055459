// @ts-nocheck
import PropTypes from 'prop-types'
import React, { useState, useRef, useEffect } from 'react'
import { styled, alpha } from '@mui/material/styles'
import {
  Stack,
  Drawer,
  Tooltip,
  Divider,
  TextField,
  Box,
  IconButton,
  Avatar,
  Badge,
  Typography,
  Chip,
} from '@mui/material'
import { useDateRangePicker } from '../../../components/date-range-picker'
import { PipelineDetailsToolbar } from './PipelineDetailsToolbar'
import { PipelineAssignedToDialog } from '../PipelineAssignedToDialog'
import DateRangePicker from '../../../components/date-range-picker/DateRangePicker'
import { PipelineDetailsPrioritizes } from './PipelineDetailsPrioritizes'
import { PipelineDetailsCommentList } from './PipelineDetailsCommentList'
import { PipelineDetailsCommentInput } from './PipelineDetailsCommentInput'
import Scrollbar from '../../../components/scrollbar/Scrollbar'
import Iconify from '../../../components/iconify/Iconify'
import { useDispatch, useSelector } from 'react-redux'
import { addRemovePipelineComment } from '../../../../redux/ducks/campaignsDucks'
import {
  currentOpenedCampaignSelector,
  pipelineSelector,
  userCredsSelector,
} from '../../../../redux/selectors'
import Spinner from '../../../../components/common/spinners/Spinner'

const StyledLabel = styled('span')(({ theme }) => ({
  ...theme.typography.caption,
  width: 120,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}))

PipelineDetails.propTypes = {
  task: PropTypes.object,
  openDetails: PropTypes.bool,
  onDeleteTask: PropTypes.func,
  onCloseDetails: PropTypes.func,
  handleUpdateTask: PropTypes.func,
  handleActiveInCampaign: PropTypes.func,
}

export default function PipelineDetails({
  task,
  openDetails,
  onCloseDetails,
  onDeleteTask,
  handleUpdateTask,
  handleActiveInCampaign,
}) {
  const dispatch = useDispatch()
  const fileInputRef = useRef(null)

  const { id: userId, username: userEmail } = useSelector(userCredsSelector)
  const { isLoadingCardData } = useSelector(pipelineSelector)
  const { campaignId } = useSelector(currentOpenedCampaignSelector)

  const [openAssignedCreators, setOpenAssignedCreators] = useState(false)
  const [prioritize, setPrioritize] = useState('low')
  const [taskDescription, setTaskDescription] = useState(task.subText)
  const [comment, setComment] = useState({
    commentText: '',
    commentAttach: null,
  })
  const [assignCreators, setAssignCreators] = useState([])

  const onAssignCreators = creator => {
    setAssignCreators(prev => [...prev, creator])
  }

  const onUnsignedCreators = creatorId => {
    setAssignCreators(prev => prev?.filter(creator => creator._id !== creatorId))
  }

  const {
    endDate,
    onChangeEndDate,
    open: openPicker,
    onOpen: onOpenPicker,
    onClose: onClosePicker,
    isSelected: isSelectedValuePicker,
    isError,
    shortLabel,
  } = useDateRangePicker(new Date(), null, true)

  const handleOpenAssignedCreators = () => {
    setOpenAssignedCreators(true)
  }

  const handleCloseAssignedCreators = () => {
    setOpenAssignedCreators(false)
  }

  const handleClickAttach = () => {
    fileInputRef.current?.click()
  }

  const handleChangeTaskDescription = event => {
    setTaskDescription(event.target.value)
  }

  const onAddComment = () => {
    const payload = {
      method: 'add',
      commentBy: userId,
      cardId: task?.cardId,
      commentText: comment.commentText,
      attachment: comment.commentAttach,
      email: userEmail,
    }
    dispatch(addRemovePipelineComment(payload))
  }

  const onRemoveComment = ({ commentId }) => {
    const payload = {
      method: 'remove',
      cardId: task?.cardId,
      commentId: commentId,
    }
    dispatch([
      setComment({ commentText: '', commentAttach: null }),
      addRemovePipelineComment(payload),
    ])
  }

  useEffect(() => {
    if (task.priority) {
      setPrioritize(task.priority)
    }
    if (task.assignedTo?.length) {
      setAssignCreators(task.assignedTo)
    }
    if (task?.dueDate) {
      onChangeEndDate(new Date(task.dueDate))
    }
  }, [task.priority, task.assignedTo, task.dueDate])

  const handleUpdatePrioritize = event => {
    const updatedPrioritize = event.target.value
    setPrioritize(updatedPrioritize)
    handleUpdateTask({ ...task, priority: updatedPrioritize })
  }

  const handleUpdateTaskDescription = event => {
    const updatedDescription = event.target.value
    handleUpdateTask({ ...task, subText: updatedDescription })
  }

  const handleUpdateAssigned = data => {
    if (!data) return

    if (typeof data === 'string') {
      const updated = assignCreators?.filter(creator => creator !== data)
      onUnsignedCreators(data)
      handleUpdateTask({ ...task, assignedTo: updated })
    } else {
      const updated = [...assignCreators, data._id]
      onAssignCreators(data)
      handleUpdateTask({ ...task, assignedTo: updated })
    }
  }

  const handleUpdateEndDate = newEndDate => {
    onChangeEndDate(newEndDate)
    handleUpdateTask({ ...task, dueDate: newEndDate })
  }

  return (
    <Drawer
      open={openDetails}
      onClose={onCloseDetails}
      anchor="right"
      PaperProps={{
        sx: {
          width: {
            xs: 1,
            sm: 480,
          },
        },
      }}
    >
      {isLoadingCardData && <Spinner isOverlay color="#FF5D5A" overlayBgColor="#f5f5f5c7" />}

      <PipelineDetailsToolbar
        taskName={task.username}
        fileInputRef={fileInputRef}
        onAttach={handleClickAttach}
        onDelete={onDeleteTask}
        onCloseDetails={onCloseDetails}
        taskStatus={task.status}
        handleActiveInCampaign={() => handleActiveInCampaign(task.creatorId)}
      />

      <Divider />

      <Scrollbar>
        <Stack spacing={2} sx={{ px: 2.5, pt: 3, pb: 5 }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '18px', textTransform: 'capitalize' }}>
            {task.username}
          </Typography>

          <Stack direction="row" justifyContent="end">
            <Chip
              label={task?.status}
              size="small"
              sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}
              color={
                task.status === 'active'
                  ? 'success'
                  : task.status === 'onboarding'
                  ? 'secondary'
                  : 'warning'
              }
            />
          </Stack>

          <Stack direction="row" alignItems="center">
            <StyledLabel>Platform</StyledLabel>

            <Typography sx={{ fontSize: '14px', textTransform: 'capitalize' }}>
              {task?.platform}
            </Typography>
          </Stack>

          <Stack direction="row">
            <StyledLabel sx={{ height: 40, lineHeight: '40px', my: 0.5 }}>Assignee</StyledLabel>

            <Stack direction="row" flexWrap="wrap" alignItems="center">
              <Badge
                badgeContent={assignCreators?.length}
                color="primary"
                size="small"
                sx={{
                  '.MuiBadge-badge': {
                    top: '3px',
                    right: '4px',
                  },
                }}
              >
                <Avatar alt={''} src={''} />
              </Badge>

              <Tooltip title="Add assignee">
                <IconButton
                  onClick={handleOpenAssignedCreators}
                  sx={{
                    p: 1,
                    ml: 0.5,
                    bgcolor: theme => alpha(theme.palette.grey[500], 0.08),
                    border: theme => `dashed 1px ${theme.palette.divider}`,
                  }}
                >
                  <Iconify icon="eva:plus-fill" />
                </IconButton>
              </Tooltip>

              <PipelineAssignedToDialog
                assignCreators={assignCreators}
                open={openAssignedCreators}
                onClose={handleCloseAssignedCreators}
                onAssignCreators={handleUpdateAssigned}
                onUnsignedCreators={handleUpdateAssigned}
                campaignId={campaignId}
              />
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center">
            <StyledLabel>Next Activity Due</StyledLabel>
            <>
              {isSelectedValuePicker ? (
                <Box
                  onClick={onOpenPicker}
                  sx={{
                    typography: 'body2',
                    cursor: 'pointer',
                    //fontWeight: 'bold',
                    '&:hover': { opacity: 0.72 },
                  }}
                >
                  {shortLabel}
                </Box>
              ) : (
                <Tooltip title="Add due date">
                  <IconButton
                    onClick={onOpenPicker}
                    sx={{
                      p: 1,
                      ml: 0.5,
                      bgcolor: theme => alpha(theme.palette.grey[500], 0.08),
                      border: theme => `dashed 1px ${theme.palette.divider}`,
                    }}
                  >
                    <Iconify icon="eva:plus-fill" />
                  </IconButton>
                </Tooltip>
              )}

              <DateRangePicker
                variant="calendar"
                title="Choose due date"
                endDate={endDate}
                onChangeEndDate={handleUpdateEndDate}
                open={openPicker}
                onClose={onClosePicker}
                isSelected={isSelectedValuePicker}
                isError={isError}
                singleCalendar
              />
            </>
          </Stack>

          <Stack direction="row" alignItems="center">
            <StyledLabel>Prioritize</StyledLabel>

            <PipelineDetailsPrioritizes
              prioritize={prioritize}
              onChangePrioritize={handleUpdatePrioritize}
            />
          </Stack>

          <Stack direction="row">
            <StyledLabel>Status/Tasks</StyledLabel>

            <TextField
              fullWidth
              multiline
              size="small"
              value={taskDescription || ''}
              onChange={handleChangeTaskDescription}
              onBlur={handleUpdateTaskDescription}
              InputProps={{
                sx: { typography: 'body2' },
              }}
            />
          </Stack>
        </Stack>

        {!!task.commentThread?.length && (
          <PipelineDetailsCommentList
            comments={task.commentThread}
            onRemoveComment={onRemoveComment}
          />
        )}
      </Scrollbar>

      <Divider />

      <PipelineDetailsCommentInput
        comment={comment}
        setComment={setComment}
        onAddComment={onAddComment}
        userEmail={userEmail}
      />
    </Drawer>
  )
}
