export const COLLECTION_CHANGES_TYPES = {
  add: 'add',
  remove: 'remove',
  move: 'move',
}

export const FAV_COLLECTION_NAME = 'fav'

export const UNLOCKED_COLLECTION_NAME = 'unlocked'

export const COLLECTIONS_ACCESS_TYPES = {
  public: 'public',
  private: 'private',
}

export const PAGINATION_ITEMS_PER_PAGE = 100

export const COLLECTION_VIEW_MODE = {
  list: 'list',
  detailed: 'detailed',
  airtable: 'airtable'
}

export const ARROW_SORT_POSITION = {
  desc: 'desc',
  asc: 'asc',
}

export const COLLECTIONS_TABS_TYPE = {
  listTab: 'list',
  overlapTab: 'overlap',
  audienceTab: 'audience',
  savedContent: 'savedContent'
}

export const BLACKLIST = 'blacklist'

export const INITIAL_LIST_VIEW_COLLECTIONS_PROPERTIES = [
  { show: true, width: 150, key: 'profileScore', edit: true },
  { show: true, width: 150, key: 'username', edit: false },
  { show: true, width: 150, key: 'country', edit: true },
  { show: true, width: 150, key: 'city', edit: true },
  { show: true, width: 150, key: 'displayName', edit: true },
  { show: true, width: 150, key: 'followers', edit: true },
  { show: true, width: 150, key: 'language', edit: true },
  { show: true, width: 200, key: 'engagementMean', edit: true },
  { show: true, width: 200, key: 'monthlyGrowthFollowers', edit: true },
  { show: true, width: 200, key: 'monthlyGrowthPercentage', edit: true },
  { show: true, width: 150, key: 'impressions', edit: true },
  { show: true, width: 150, key: 'dateAdded', edit: true },
  { show: true, width: 150, key: 'stars', edit: true },
  { show: true, width: 150, key: '_index', edit: true },
  { show: true, width: 150, key: 'comment', edit: true },
]

export const COLLECTION_YOUR_OVERLAPS_PATH = '/collections/your-overlaps'