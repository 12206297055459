import styled from 'styled-components'

export const MainOverlay = styled.div`
  position: relative;
  & svg {
    fill: ${props => props.theme.primaryColor};
  }
`
export const AdditionalFieldDetailsOverlay = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  width: 290px;
  background-color: ${props => props.theme.colors.white};
  border: 2px solid ${props => props.theme.colors.mediumBrown};
  border-radius: 17px;
  ${'' /* padding-bottom: 5px;
  padding-top: 6px; */}
  z-index: 105;
  /*for scroll */
  height: 285px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const SwitchScrollContainer = styled.div`
  width: 100%;
  max-height: 255px;
  overflow-y: auto;
  ${props => props.theme.withScroll};
`
export const SwitchWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 0.5px solid ${props => props.theme.colors.lightBrown};
  &:last-child {
    border: none;
  }
`
export const SwitchOverlayText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #442424;
  text-transform: capitalize;
  max-width: 199px;
  width: 100%;
  word-wrap: break-word;
`