import styled from 'styled-components'

export const SuspiciousActivitiesResultMessage = styled.div`
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.brown};
  margin: 5px 0 30px;
`

export const SuspiciousActivitiesItemsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-row-gap: 30px;
  grid-column-gap: 20px;
  justify-content: center;
  align-items: center;
`

export const SuspiciousActivitiesItem = styled.div`
  width: 225px;
  height: 70px;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  user-select: none;

  ${props =>
    props.isFound
      ? `
    color: ${props.theme.colors.white};
    background: ${props.theme.gradients.main};
    border: none;
  `
      : `
    color: ${props.theme.colors.darkBrown};
    background: ${props.theme.colors.offWhite};
    border: 1px solid ${props.theme.colors.lightBrown};
  `}

  &:before {
    content: '${props => props.tipContent}';
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 15px;
    font-size: 13px;
    line-height: 15px;
    color: ${props => props.theme.colors.white};
    background: ${props =>
      props.isFound
        ? props.theme.gradients.main
        : props.theme.colors.mediumBrown};
    border-radius: 17px;
    white-space: nowrap;
  }
`
