import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  ADDITIONAL_FIELD_TYPES,
  ADDITIONAL_FIELD_PROP_TYPE,
} from '../../../../constants/additionalFields'
import { USER_INDEXES } from '../../../../constants/appSettings'
import AdditionalFieldTools from './AdditionalFieldTools'
import AdditionalFieldDetails from '../additionalFieldEditing/AdditionalFieldDetails'
import Spinner from '../../../common/spinners/Spinner'
import { ToolsIcon } from '../../../common/icons/icons'
import {
  NoteContainer,
  NoteLegend,
  NoteLabel,
  NoteTip,
  NoteToolsButton,
  NoteInputWrapper,
  editingFieldOverlayPosition,
} from './styles'

const NoteWithLabelAndTools = ({
  platform = '',
  label = '',
  tip = '',
  additionalFieldData = {
    id: '',
    fieldName: '',
    fieldType: ADDITIONAL_FIELD_TYPES.string,
    value: '',
    options: [],
  },
  hasTools = true,
  isTextarea = false,
  isFieldEditingOverlayOpened = false,
  isFieldChanging = false,
  isLoading = false,
  openFieldEditing,
  closeFieldEditing,
  changeAdditionalField,
  deleteAdditionalFieldById,
  children = null,
}) => {
  const [isToolsOpened, setIsToolOpened] = useState(false)

  const toggleIsToolsOpened = () => setIsToolOpened(!isToolsOpened)

  return (
    <NoteContainer className="note__container">
      <NoteLegend onClick={event => event.stopPropagation()}>
        <NoteLabel>{label}</NoteLabel>
        <NoteTip>{tip}</NoteTip>

        {hasTools && (
          <NoteToolsButton onClick={toggleIsToolsOpened}>
            <ToolsIcon />
          </NoteToolsButton>
        )}

        {isToolsOpened && (
          <AdditionalFieldTools
            platform={platform}
            openFieldEditing={openFieldEditing}
            deleteAdditionalFieldById={deleteAdditionalFieldById}
            toggleIsToolsOpened={toggleIsToolsOpened}
          />
        )}

        {isFieldEditingOverlayOpened && (
          <AdditionalFieldDetails
            additionalFieldData={additionalFieldData}
            platform={platform}
            isEditing
            isLoading={isFieldChanging}
            saveNote={changeAdditionalField}
            closeOverlay={closeFieldEditing}
            position={editingFieldOverlayPosition}
          />
        )}
      </NoteLegend>

      <NoteInputWrapper isTextarea={isTextarea}>{children}</NoteInputWrapper>

      {isLoading && <Spinner isOverlay />}
    </NoteContainer>
  )
}

NoteWithLabelAndTools.propTypes = {
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  label: PropTypes.string,
  tip: PropTypes.string,
  additionalFieldData: ADDITIONAL_FIELD_PROP_TYPE,
  hasTools: PropTypes.bool,
  isTextarea: PropTypes.bool,
  isFieldEditingOverlayOpened: PropTypes.bool,
  isFieldChanging: PropTypes.bool,
  isLoading: PropTypes.bool,
  openFieldEditing: PropTypes.func,
  closeFieldEditing: PropTypes.func,
  changeAdditionalField: PropTypes.func,
  deleteAdditionalFieldById: PropTypes.func,
  children: PropTypes.node,
}

export default NoteWithLabelAndTools
