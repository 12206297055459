import React from 'react'
import PropTypes from 'prop-types'
import { Stack, Typography, Box, styled, LinearProgress } from '@mui/material'
import { fDateTime } from '../../../utils/formatTime'
import Scrollbar from '../../scrollbar/Scrollbar'
import { fShortenNumber } from '../../../utils/formatNumber'
import { getUrlWithProxy } from '../../../../utils'
// import ReactPlayer from 'react-player'
// import { PlayVideoIcon } from '../../../assets/icons/navbar'

const sliderTitle = {
  [0]: 'Very Low',
  [1]: 'Low',
  [2]: 'Medium',
  [3]: 'Medium',
  [4]: 'High',
  [5]: 'Very High',
}

const TYPE_RANGE_COLORS = {
  POSITIVITY_RATE: 'POSITIVITY_RATE',
  CALC_CONTROVERSIAL: 'CALC_CONTROVERSIAL',
}

const StyledImg = styled('img')({
  borderRadius: '12px',
  width: '100%',
  height: '200px',
  objectFit: 'cover',
})

export const LatestSummariesItem = ({ item, isPdf = false }) => {
  const {
    username,
    uploaded,
    imageUrl,
    positivityRate,
    commentSummary,
    calcControverial,
    comments,
  } = item || {}

  // const [startVideo, setStartVideo] = useState(false)

  // const onHandlePlayVideo = () => {
  //   setStartVideo(!startVideo)
  // }

  const CustomSliderStyles = (value, type) => {
    const colorsPositivityRate = {
      [0]: '#ff4441',
      [1]: '#ff4441',
      [2]: '#F39F01',
      [3]: '#FBEF03',
      [4]: '#CAE318',
      [5]: '#36B37E',
    }

    const colorsCalcControverial = {
      [0]: '#36B37E',
      [1]: '#36B37E',
      [2]: '#CAE318',
      [3]: '#FBEF03',
      [4]: '#F39F01',
      [5]: '#ff4441',
    }

    const colors =
      type === TYPE_RANGE_COLORS.POSITIVITY_RATE ? colorsPositivityRate : colorsCalcControverial

    return {
      padding: 0,
      '& .MuiLinearProgress-bar': {
        backgroundColor: colors[value],
      },
    }
  }

  return (
    <Stack
      spacing={2}
      direction={'row'}
      alignItems={'flex-end'}
      sx={{
        position: 'relative',
        p: theme => theme.spacing(3, 3, 2, 3),
      }}
    >
      {item && !!Object.keys(item)?.length ? (
        <>
          <Stack spacing={1} sx={{ width: '40%' }} alignItems={'center'}>
            <Box sx={{ width: '100%', height: '100%' }}>
              <StyledImg src={imageUrl} />
            </Box>

            <Box
              sx={{
                width: '100% ',
                marginTop: 1,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  width: '200px',
                  display: 'flex',
                  height: '45px',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  '& .MuiLinearProgress-root': {
                    backgroundColor: 'rgb(239, 239, 240)',
                  },
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 700,
                    fontSize: '12px',
                    lineHeight: '18px',
                    textTransform: 'uppercase',
                    mb: isPdf ? 0.8 : 0,
                  }}
                >
                  Positivity: {positivityRate}/5
                </Typography>
                <LinearProgress
                  value={positivityRate * 20}
                  variant="determinate"
                  sx={CustomSliderStyles(positivityRate, TYPE_RANGE_COLORS.POSITIVITY_RATE)}
                />
                <Typography variant="caption">{sliderTitle[positivityRate]}</Typography>
              </Box>
            </Box>
          </Stack>
          <Stack spacing={1} sx={{ width: '60%', paddingLeft: 2 }}>
            <Box>
              <Typography variant="subtitle2">Creator: {username}</Typography>
              <Typography
                variant="caption"
                sx={{ color: 'text.secondary', mt: 0.5, display: 'block' }}
              >
                Posted {fDateTime(uploaded)}
              </Typography>
              <Typography variant="caption">Comments: {fShortenNumber(comments)}</Typography>
            </Box>
            <Scrollbar sx={{ maxHeight: '120px' }}>
              <Typography
                sx={{ paddingRight: 2, width: '100%', mb: isPdf ? 1 : 'inherit' }}
                variant="body2"
              >
                {commentSummary}
              </Typography>
            </Scrollbar>
            <Box
              sx={{
                width: '100% ',
                marginTop: 1,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  width: '200px',
                  display: 'flex',
                  height: '45px',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  '& .MuiLinearProgress-root': {
                    backgroundColor: 'rgb(239, 239, 240)',
                  },
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 700,
                    fontSize: '12px',
                    lineHeight: '18px',
                    textTransform: 'uppercase',
                    mb: isPdf ? 0.8 : 0,
                  }}
                >
                  Controversial: {calcControverial}/5
                </Typography>
                <LinearProgress
                  value={calcControverial * 20}
                  variant="determinate"
                  sx={CustomSliderStyles(calcControverial, TYPE_RANGE_COLORS.CALC_CONTROVERSIAL)}
                />
                <Typography variant="caption">{sliderTitle[calcControverial]}</Typography>
              </Box>
            </Box>
          </Stack>
        </>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '70px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: '16px',
              width: '100%',
            }}
            variant="body2"
          >
            {'No data available'}
          </Typography>
        </Box>
      )}
    </Stack>
  )
}

LatestSummariesItem.propTypes = {
  item: PropTypes.shape({
    tags: PropTypes.array,
    name: PropTypes.string,
    avatar: PropTypes.string,
    description: PropTypes.string,
    postedAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  }),
  isPdf: PropTypes.bool,
}