import { useState, useEffect } from 'react'

export const statuses = {
  loading: 'loading',
  ready: 'ready',
  error: 'error',
  idle: 'idle',
}

const useScript = src => {
  const [status, setStatus] = useState(src ? statuses.loading : statuses.idle)

  useEffect(() => {
    if (!src) {
      setStatus(statuses.idle)
      return
    }

    let script = document.querySelector(`script[src="${src}"]`)
    if (!script) {
      script = document.createElement('script')
      script.src = src
      script.async = true
      script.setAttribute('data-status', statuses.loading)
      document.body.appendChild(script)
      const setAttributeFromEvent = event => {
        script.setAttribute('data-status', event.type === 'load' ? statuses.ready : statuses.error)
      }
      script.addEventListener('load', setAttributeFromEvent)
      script.addEventListener('error', setAttributeFromEvent)
    } else {
      setStatus(script.getAttribute('data-status'))
    }

    const setStateFromEvent = event => {
      setStatus(event.type === 'load' ? statuses.ready : statuses.error)
    }

    script.addEventListener('load', setStateFromEvent)
    script.addEventListener('error', setStateFromEvent)

    return () => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent)
        script.removeEventListener('error', setStateFromEvent)
      }
    }
  }, [src])

  return status
}

export default useScript
