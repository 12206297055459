import { Grid, Paper, styled } from '@mui/material'

export const StyledCardTitle = styled('span')({
  fontWeight: 'bold',
  fontSize: '12px',
  marginRight: '5px',
})

export const StyledPaper = styled(Paper)({
  marginRight: '15px',
  borderRadius: '16px',
  backgroundColor: '#F9FAFB',
})

export const BtnWrapper = styled(Grid)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  button: {
    marginLeft: '8px',
    width: '83px',
  },
  '& :first-of-type': {
    marginLeft: 0,
  },
})