import styled from 'styled-components'

export const RatingDescriptionTipLabel = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: ${props => props.theme.colors.darkBrown};
  text-transform: uppercase;
  text-decoration: underline;
`

export const RatingDescriptionOverlay = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: -50%;
  width: 350px;
  padding: 20px;
  border: 2px solid ${props => props.theme.colors.mediumBrown};
  background-color: ${props => props.theme.colors.white};
  border-radius: 17px;
  z-index: 5;
  cursor: default;
`

export const RatingDescriptionMain = styled.div`
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.brown};
  margin-bottom: 20px;
`

export const RatingDescriptionText = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: ${props => props.theme.colors.darkBrown};
`

export const RatingDescriptionTipContainer = styled.div`
  position: relative;
  cursor: pointer;

  ${RatingDescriptionOverlay} {
    display: none;
  }

  &:hover {
    ${RatingDescriptionOverlay} {
      display: block;
    }
  }
`
