import React from 'react'
import useTranslation from '../../../../../localization/useTranslation'
import { TT_SCORE_NAMES } from '../../../../../constants/profile'
import { USER_INDEXES } from '../../../../../constants/appSettings'
import InfludataRating from '../../../../qualityScores/InfludataRating'
import ComparisonToSimilarInfluencers from '../../../../qualityScores/ComparisonToSimilarInfluencers'
import RatingDescriptionTip from '../../ratingDescriptionTip/RatingDescriptionTip'
import NoDataMessage from '../../NoDataMessage'
import { pretifyBigNum } from '../../../../../utils'
import {
  SectionTitleWrapper,
  SectionTitleName,
  SectionTitleBorder,
} from '../../../commonProfilePageStyles'
import {
  RatingSectionContainer,
  RatingSectionGrid,
  RatingSectionGridItem,
  RatingTitleWithScoreWrapper,
  RatingTitleWrapper,
  RatingTitle,
} from '../../commonStyles'

const AudienceEngagementGrowthRate = ({ platform, profileScores }) => {
  const { labelStrings, scoreStrings } = useTranslation()
  const ttScoreStrings = scoreStrings[USER_INDEXES.tiktok]

  const {
    growthrateFollowers1MObject,
    growthrateFollowers6MObject,
    growthrateFollowers12MObject,
    growthrateViews1MObject,
    growthrateViews6MObject,
    growthrateViews12MObject
  } = profileScores

  const formatLabel = label => (!!label && !Number.isNaN(+label) ? pretifyBigNum(+label) : label)

  const scoresArr = [
    {
      name: TT_SCORE_NAMES.growthFollowers1M,
      data: growthrateFollowers1MObject,
      label: labelStrings.nFollowerGrowth(growthrateFollowers1MObject?.label),
    },
    {
      name: TT_SCORE_NAMES.growthFollowers6M,
      data: growthrateFollowers6MObject,
      label: labelStrings.nFollowerGrowth(formatLabel(growthrateFollowers6MObject?.label)),
    },
    {
      name: TT_SCORE_NAMES.growthFollowers12M,
      data: growthrateFollowers12MObject,
      label: labelStrings.nFollowerGrowth(growthrateFollowers12MObject?.label),
    },
    {
      name: TT_SCORE_NAMES.growthViews1M,
      data: growthrateViews1MObject,
      label: labelStrings.nVideoViewsGrowth(growthrateViews1MObject?.label),
    },
    {
      name: TT_SCORE_NAMES.growthViews6M,
      data: growthrateViews6MObject,
      label: labelStrings.nVideoViewsGrowth(growthrateViews6MObject?.label),
    },
    {
      name: TT_SCORE_NAMES.growthViews12M,
      data: growthrateViews12MObject,
      label: labelStrings.nVideoViewsGrowth(growthrateViews12MObject?.label),
    }
  ]

  const scoreItemsCount = scoresArr.reduce(
    (currentCount, scoreItem) => (!!scoreItem.data ? currentCount + 1 : currentCount),
    0
  )

  return (
    <RatingSectionContainer>
      <SectionTitleWrapper>
        <SectionTitleName platform={platform}>{labelStrings.growthRate}</SectionTitleName>
        <SectionTitleBorder />
      </SectionTitleWrapper>

      {!scoreItemsCount && <NoDataMessage />}

      <RatingSectionGrid columnsCount={3}>
        {scoresArr.map((scoreItem, index) => {
          const { name: scoreName, label, data: scoreData } = scoreItem
          const { benchmarkArray, score, xAxisHigh, xAxisMid, xAxisLow } = scoreData || {
            benchmarkArray: [],
            score: 0,
            xAxisHigh: null,
            xAxisLow: null,
            xAxisMid: null,
          }
          const xAxisValues = [xAxisHigh, xAxisMid, xAxisLow]

          if (!scoreData) return null

          return (
            <RatingSectionGridItem key={index}>
              <RatingTitleWithScoreWrapper>
                <RatingTitleWrapper>
                  <RatingTitle>{ttScoreStrings[scoreName].title}</RatingTitle>
                  <RatingDescriptionTip scoreDesc={ttScoreStrings[scoreName].description} />
                </RatingTitleWrapper>

                <InfludataRating
                  scoreValue={score}
                  platform={platform}
                  size={50}
                  isTitleHidden
                  isOutOfHidden
                />
              </RatingTitleWithScoreWrapper>

              <ComparisonToSimilarInfluencers
                label={label}
                benchmarkArray={benchmarkArray}
                xAxisValues={xAxisValues}
              />
            </RatingSectionGridItem>
          )
        })}
      </RatingSectionGrid>
    </RatingSectionContainer>
  )
}

export default AudienceEngagementGrowthRate
