import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { ArrowAscendSvg, ArrowDescSvg } from '../../common/icons'
import { ARROW_SORT_POSITION } from '../../../constants/collections'

const ArrowSortWrapper = styled.div`
  padding: 0 2px;
  cursor: pointer;
`
const ArrowsSortWrapper = styled.div`
  display: flex;
  margin: 0 5px;
`
const ContentWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  .cell-label {
    max-width: 100px;
    width: min-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const ProfileListViewSortToggle = props => {
  const { children, setSortDirection, sortDirection, 
    currentCell, keyField } = props
  const { desc, asc } = ARROW_SORT_POSITION

  const [isTouch, setIsTouch] = useState(false)

  const isActiveField = keyField === currentCell

  const changeSort = () => {
    setSortDirection(prev => (prev === asc ? desc : asc))
    setIsTouch(true)
  }

  const hasActive = useCallback(
    sortType => {
      return sortDirection === sortType && isTouch && isActiveField
    },
    [sortDirection, isTouch, isActiveField]
  )

  return (
    <ContentWrapper onClick={changeSort}>
      {children}
      <ArrowsSortWrapper>
        <ArrowSortWrapper>
          <ArrowDescSvg isActive={hasActive(desc)} />
        </ArrowSortWrapper>
        <ArrowSortWrapper>
          <ArrowAscendSvg isActive={hasActive(asc)} />
        </ArrowSortWrapper>
      </ArrowsSortWrapper>
    </ContentWrapper>
  )
}
