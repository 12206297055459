import React, { useState, useCallback } from 'react'
import useTranslation from '../../localization/useTranslation'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { userGrantSelector, allCampaignsSelector } from '../../redux/selectors'

import LimitMonitorContent from './LimitMonitorContent'
import BuyCampaignsSeatsContainer from '../appUserSettings/addOns/changeAddOns/BuyCampaignsSeatsContainer'

const CampaignsLimitMonitorContainer = ({ disabled = false }) => {
  const { labelStrings } = useTranslation()
  const history = useHistory()
  const { max_users_trackable, enable_tracking, package_advanced } = useSelector(
    userGrantSelector, shallowEqual
  )
  const { trackNow, trackMax } = useSelector(allCampaignsSelector)

  const [isBuyTokensModalOpened, setIsBuyTokensModalOpened] = useState(false)
  const toggleIsBuyTokensModalOpened = () => setIsBuyTokensModalOpened(!isBuyTokensModalOpened)

  const exceedLimitClickHandler = () => {
    if (package_advanced) return history.push('/settings')
    toggleIsBuyTokensModalOpened()
  }

  const isTrackLimitExist = !!enable_tracking || !!trackMax // for allowed campaigns trackMax is always greater than zero
  const maxTrackLimit = max_users_trackable || trackMax || 0 // prefer data from grant limits first

  const calcLimitLineWidth = useCallback(() => {
    const MAX_WIDTH = 100
    const MIN_WIDTH = 0
    if (!maxTrackLimit || !trackNow) return MIN_WIDTH
    if (maxTrackLimit && trackNow && maxTrackLimit === trackNow) return MAX_WIDTH
    const output = (trackNow * MAX_WIDTH) / maxTrackLimit
    return output < MAX_WIDTH ? output : MAX_WIDTH
  }, [max_users_trackable, trackNow, trackMax])

  const calcItemsToAdd = useCallback(() => {
    if (trackNow === 0) return maxTrackLimit
    if (!trackNow || !maxTrackLimit) return 0
    const usersLeftToAdd = maxTrackLimit - trackNow
    return usersLeftToAdd > 0 ? usersLeftToAdd : 0
  }, [max_users_trackable, trackNow, trackMax])

  const createExceedLimitText = () => {
    const usersNums = `(${trackNow}/${maxTrackLimit})`
    if (!enable_tracking) return `${labelStrings.campaignBuyTokens}${usersNums}`
    if (package_advanced || enable_tracking) return `${labelStrings.campaignUpgradeToAddUsers}${usersNums}`
  }

  const itemsToAdd = calcItemsToAdd()

  return (
    <>
      <LimitMonitorContent
        isTrackLimitExist={isTrackLimitExist}
        limitLinePercent={calcLimitLineWidth()}
        exceedLimitClickHandler={exceedLimitClickHandler}
        exceedLimitText={createExceedLimitText()}
        itemsToAdd={itemsToAdd}
        disabled={disabled}
        showChangeSeatsPlan={!!enable_tracking || !!isTrackLimitExist && !!itemsToAdd}
        isAddLimitExceeded={!!enable_tracking || !itemsToAdd}
      />

      <BuyCampaignsSeatsContainer
        isModalOpened={isBuyTokensModalOpened}
        closeModal={toggleIsBuyTokensModalOpened}
      />
    </>
  )
}

CampaignsLimitMonitorContainer.propTypes = {
  disabled: PropTypes.bool,
}

export default CampaignsLimitMonitorContainer
