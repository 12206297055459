import { useEffect } from 'react'
import useScript, { statuses } from './hooks/useScript'
import { GTAG_ID, GTAG_SCRIPT_SRC } from './constants/gaTracking'

const GoogleAnalytics = () => {
  const status = useScript(GTAG_SCRIPT_SRC)

  useEffect(() => {
    if (status === statuses.ready) {
      window.dataLayer = window.dataLayer || []
      function gtag() {
        window.dataLayer.push(arguments)
      }
      gtag('js', new Date())
      gtag('config', GTAG_ID)
    }
  }, [status])

  return null
}

export default GoogleAnalytics
