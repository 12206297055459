import React from 'react'
import useTranslation from '../../../../localization/useTranslation'
import { IG_SCORE_NAMES } from '../../../../constants/profile'
import { USER_INDEXES } from '../../../../constants/appSettings'
import InfludataRating from '../../../qualityScores/InfludataRating'
import ComparisonToSimilarInfluencers from '../../../qualityScores/ComparisonToSimilarInfluencers'
import RatingDescriptionTip from '../ratingDescriptionTip/RatingDescriptionTip'
import NoDataMessage from '../NoDataMessage'
import { pretifyBigNum } from '../../../../utils'
import {
  SectionTitleWrapper,
  SectionTitleName,
  SectionTitleBorder,
} from '../../commonProfilePageStyles'
import {
  RatingSectionContainer,
  RatingSectionGrid,
  RatingSectionGridItem,
  RatingTitleWithScoreWrapper,
  RatingTitleWrapper,
  RatingTitle,
} from '../commonStyles'

const AudienceEngagement = ({ platform, profileScores }) => {
  const { labelStrings, scoreStrings } = useTranslation()
  const igScoreStrings = scoreStrings[USER_INDEXES.instagram]

  const {
    likeEngagementObject,
    commentEngagementObject,
    followerChangeObject,
    playsPerReelObject,
    engagementReelsObject,
    reelsWatchTimeObject,
  } = profileScores

  const formatLabel = label => (!!label && !Number.isNaN(+label) ? pretifyBigNum(+label) : label)

  const scoresArr = [
    {
      name: IG_SCORE_NAMES.postsLikes,
      data: likeEngagementObject,
      label: labelStrings.nOfFollowers(likeEngagementObject?.label),
    },
    {
      name: IG_SCORE_NAMES.postsComments,
      data: commentEngagementObject,
      label: labelStrings.nComments10000Followers(formatLabel(commentEngagementObject?.label)),
    },
    {
      name: IG_SCORE_NAMES.growthRate,
      data: followerChangeObject,
      label: labelStrings.nLast30Days(followerChangeObject?.label),
    },
    {
      name: IG_SCORE_NAMES.reelsPlays,
      data: playsPerReelObject,
      label: labelStrings.nPlaysOnAverage(formatLabel(playsPerReelObject?.label)),
    },
    {
      name: IG_SCORE_NAMES.reelsEngagement,
      data: engagementReelsObject,
      label: labelStrings.nOfViewers(engagementReelsObject?.label),
    },
    {
      name: IG_SCORE_NAMES.reelsWatchTime,
      data: reelsWatchTimeObject,
      label: labelStrings.nHoursPerReel(formatLabel(reelsWatchTimeObject?.label)),
    },
  ]

  const scoreItemsCount = scoresArr.reduce(
    (currentCount, scoreItem) => (!!scoreItem.data ? currentCount + 1 : currentCount),
    0
  )

  return (
    <RatingSectionContainer>
      <SectionTitleWrapper>
        <SectionTitleName platform={platform}>{labelStrings.audienceEngagement}</SectionTitleName>
        <SectionTitleBorder />
      </SectionTitleWrapper>

      {!scoreItemsCount && <NoDataMessage />}

      <RatingSectionGrid columnsCount={3}>
        {scoresArr.map((scoreItem, index) => {
          const { name: scoreName, label, data: scoreData } = scoreItem
          const { benchmarkArray, score, xAxisHigh, xAxisMid, xAxisLow } = scoreData || {
            benchmarkArray: [],
            score: 0,
            xAxisHigh: null,
            xAxisLow: null,
            xAxisMid: null,
          }
          const xAxisValues = [xAxisHigh, xAxisMid, xAxisLow]

          if (!scoreData) return null

          return (
            <RatingSectionGridItem key={index}>
              <RatingTitleWithScoreWrapper>
                <RatingTitleWrapper>
                  <RatingTitle>{igScoreStrings[scoreName].title}</RatingTitle>
                  <RatingDescriptionTip scoreDesc={igScoreStrings[scoreName].description} />
                </RatingTitleWrapper>

                <InfludataRating
                  scoreValue={score}
                  platform={platform}
                  size={50}
                  isTitleHidden
                  isOutOfHidden
                />
              </RatingTitleWithScoreWrapper>

              <ComparisonToSimilarInfluencers
                label={label}
                benchmarkArray={benchmarkArray}
                xAxisValues={xAxisValues}
              />
            </RatingSectionGridItem>
          )
        })}
      </RatingSectionGrid>
    </RatingSectionContainer>
  )
}

export default AudienceEngagement
