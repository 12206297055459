import React from 'react'
import PropTypes from 'prop-types'
import { SwitchStyled } from './styles'

const ToggleSwitchSearch = ({ id, name, checked, onChange, size, disabled }) => {
  const isSize =
  size === 'small-switch' ? ' small-switch' : size === 'medium-switch' ? ' medium-switch' : ''

  const handleKeyPress = e => {
    if (e.keyCode !== 32) return

    e.preventDefault()
    onChange(!checked)
  }

  return (
    <SwitchStyled className={'toggle-switch-styled' + isSize}>
      <input
        type="checkbox"
        name={name}
        className="toggle-switch-checkbox-styled"
        id={id}
        checked={checked}
        onChange={e => onChange(e.target.checked)}
        disabled={disabled}
      />
      {id && (
        <label
          className="toggle-switch-label-styled"
          tabIndex={disabled ? -1 : 1}
          onKeyDown={e => handleKeyPress(e)}
          htmlFor={id}
        >
          <span
            className={
              disabled ? 'toggle-switch-inner-styled toggle-switch-disabled-styled' : 'toggle-switch-inner-styled'
            }
            // data-yes={optionLabels[0]}
            // data-no={optionLabels[1]}
            tabIndex={-1}
          />
          <span
            className={
              disabled ? 'toggle-switch-switch-styled toggle-switch-disabled-styled' : 'toggle-switch-switch-styled'
            }
            tabIndex={-1}
          />
        </label>
      )}
    </SwitchStyled>
  )
}

ToggleSwitchSearch.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  optionLabels: PropTypes.array,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small-switch', 'medium-switch']),
}

export default ToggleSwitchSearch