import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { USER_INDEXES, SOCIAL_LINKS_PATHS } from '../../../../constants/appSettings'
import {
  YtGalleryImg,
  YtGalleryItem,
  YtGalleryItemTitle,
  YtGalleryStatistics,
  YtIconGalleryWrapper,
  YtIconSingle,
} from './styles'
import {
  convertValueToPercentage,
  cutStringToMaxLength,
  pretifyBigNum,
} from '../../../../utils'
import {
  LikeIcon,
  CommentIcon,
  EyeIcon,
  YouTubeShortsCreateIcon,
  YouTubeClassicIcon,
} from '../../../common/icons/icons'

const LatestPostsGalleryItemYt = ({
  platform = '',
  isShorts = false,
  imgSrc = '',
  platformPostCode = '',
  likeCount = 0,
  commentCount = 0,
  viewCount = 0,
  duration = 0,
  engagement = 0,
  positiveReactions = '',
  title = '',
}) => {
  const [isError, setIsError] = useState(false)

  const isAllItems = [viewCount, likeCount, commentCount]?.every(Boolean)

  useEffect(() => {
    const img = new Image()
    img.crossOrigin = '*'
    img.src = imgSrc
    img.onerror = () => setIsError(true)
  }, [])

  const linkToPost = SOCIAL_LINKS_PATHS.ytVideoLink(platformPostCode)

  if (isError) return null

  return (
    <a href={linkToPost} target="_blank">
      <YtGalleryItem>
        <YtGalleryImg src={imgSrc} />
        <YtGalleryItemTitle>
          {cutStringToMaxLength({ str: title, maxLength: 50 })}
        </YtGalleryItemTitle>
        <YtIconGalleryWrapper isSpaceBetween={isAllItems}>
          {viewCount && (
            <YtIconSingle isMargin={isAllItems}>
              <EyeIcon color={'#784E4E'} width={14} height={16} />
              {pretifyBigNum(viewCount)}
            </YtIconSingle>
          )}
          {likeCount && (
            <YtIconSingle isMargin={isAllItems}>
              <LikeIcon color={'#784E4E'} width={14} height={16} />
              {pretifyBigNum(likeCount)}
            </YtIconSingle>
          )}
          {commentCount && (
            <YtIconSingle>
              <CommentIcon color={'#784E4E'} width={14} height={16} />
              {pretifyBigNum(commentCount)}
            </YtIconSingle>
          )}
        </YtIconGalleryWrapper>
        <YtGalleryStatistics>
          <div>
            <div>
              Duration: <span>{`${duration || 0} sec`}</span>
            </div>
            <div>
              Engagement: <span>{`${convertValueToPercentage(engagement) || 0}`}</span>
            </div>
            {positiveReactions && (
              <div>
                Reactions: <span>{`${convertValueToPercentage(positiveReactions)}% Positive`}</span>
              </div>
            )}
          </div>
          {isShorts ? <YouTubeShortsCreateIcon /> : <YouTubeClassicIcon />}
        </YtGalleryStatistics>
      </YtGalleryItem>
    </a>
  )
}

LatestPostsGalleryItemYt.propTypes = {
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  imgSrc: PropTypes.string,
  platformPostCode: PropTypes.string,
  title: PropTypes.string,
  likeCount: PropTypes.number,
  commentCount: PropTypes.number,
  viewCount: PropTypes.number,
  duration: PropTypes.number,
  engagement: PropTypes.number,
  positiveReactions: PropTypes.string,
  isShorts: PropTypes.bool,
}

export default LatestPostsGalleryItemYt
