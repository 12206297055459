import React from 'react'
import { Box, Card, Stack, Typography } from '@mui/material'
import PdfImgLogo from '../../../../images/BrandPdfLogo.png'
import { fDate } from '../../../utils/formatTime'

export const DashboardHeaderPdfView = props => {
  const {
    campaignName,
    dashboardName,
    campaignDescription,
    selectedDates,
    creatorsSelectValue,
    creators,
  } = props

  return (
    <Card sx={{ backgroundColor: '#F9FAFB', my: 2, px: 9, py: 3 }}>
      <Stack alignItems="center" justifyContent="space-between" direction="row">
        <Stack spacing={1}>
          <Box>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 700,
                lineHeight: '16px',
                textTransform: 'uppercase',
                color: '#919EAB',
              }}
            >
              Campaign name
            </Typography>
            <Typography
              sx={{
                fontSize: '32px',
                fontWeight: 700,
                lineHeight: '16px',
                color: '#FF5D5A',
                mb: 3,
              }}
            >
              {campaignName}
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 700,
                lineHeight: '16px',
                textTransform: 'uppercase',
                color: '#919EAB',
              }}
            >
              Dashboard name
            </Typography>
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 700,
                lineHeight: '16px',
                color: '#454F5B',
                mb: 3,
              }}
            >
              {dashboardName}
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 700,
                lineHeight: '16px',
                textTransform: 'uppercase',
                color: '#919EAB',
              }}
            >
              Description
            </Typography>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '16px',
                color: '#454F5B',
                mb: 3,
              }}
            >
              {campaignDescription}
            </Typography>
          </Box>
        </Stack>

        <Stack>
          <Box sx={{ mb: 1, maxHeight: '70px', maxWidth: '200px' }}>
            <img alt="InfluData" src={PdfImgLogo} style={{
              imageRendering: 'high-quality',
              width: '100%',
              height: 'auto'
            }}/>
          </Box>
          <Stack spacing={3} direction="row" alignItems="center">
            <Stack>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 700,
                  lineHeight: '16px',
                  textTransform: 'uppercase',
                  color: '#919EAB',
                }}
              >
                From
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '16px',
                  mb: 3,
                }}
              >
                {selectedDates?.from ? fDate(selectedDates?.from, 'dd/MM/yyyy') : ''}
              </Typography>
            </Stack>
            <Stack>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 700,
                  lineHeight: '16px',
                  textTransform: 'uppercase',
                  color: '#919EAB',
                }}
              >
                To
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '16px',
                  mb: 3,
                }}
              >
                {selectedDates?.to ? fDate(selectedDates?.to, 'dd/MM/yyyy') : ''}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row">
            <Stack>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 700,
                  lineHeight: '16px',
                  textTransform: 'uppercase',
                  color: '#919EAB',
                }}
              >
                Selected Creators
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '16px',
                  mb: 3,
                }}
              >
                {creatorsSelectValue} of {creators?.length}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  )
}
