import React, { useCallback, memo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Chart } from 'react-google-charts'

import useTranslation from '../../localization/useTranslation'
import { stringifyNumToPretty, convertHexToRgba } from '../../utils'
import { disabledLineChartData } from './disabledChartsData'
import { themeCommonStyles } from '../../themes/infludataThemes'
import { USER_INDEXES } from '../../constants/appSettings'
import {
  MIN_CHART_COUNT,
  LINE_CHART_COUNT_FOR_MONTHS_FORMAT,
  LINE_CHART_DATE_FORMAT,
} from '../../constants/components'

import LoadingSpinner from '../common/spinners/LoadingSpinner'
import LinkToFaqQuestion from '../faqs/LinkToFaqQuestion'
import ChartGrowthDynamics from '../common/dynamicsLabels/ChartGrowthDynamics'
import {
  ChartWrapper,
  ChartTitle,
  ChartTitletextWrapper,
  FaqtipWrapper,
} from './chartsCommonWrappers'

const LineChartWrapper = styled(ChartWrapper)`
  position: relative;

  ${props => props.isDisabled && `
    ${props.theme.disabled}
    opacity: 1;

    & svg {
      opacity: 0.2;
    }
  `}
`

const LineChartTitle = styled(ChartTitle)`
  ${props => props.isDisabled && `
    & > span {
      color: ${props.theme.color.midGrey};
    }
  `}
`

const DisabledChartMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: ${props => convertHexToRgba(props.theme.colors.white, 0.8)};
  border-radius: 15px;
  z-index: 100;
  opacity: 0.9;
  text-align: center;
`

const DisabledChartMessageText = styled.div`
  font-size: 15px;
  line-height: 19px;
  font-weight: 700;
  ${props =>
    props.isForPdf
      ? `color: ${props.theme.getColorPaletteForPlatform(props.platform).main};`
      : props.theme.getTextGradientStyles({ platform: props.platform })}
`

// chart texts styles
const defaultTextStyle = {
  fontName: 'Inter',
  fontSize: 10,
  italic: false,
  color: themeCommonStyles.colors.darkBrown,
}

const createCustomTooltip = (value, date) => {
  const valueText = stringifyNumToPretty(value)
  const [month, day, year] = date.toDateString().split(' ')
  const prettyDate = `${month} ${day}, ${year}`

  return `
    <div class="line-chart-custom-tooltip">
      <div class="line-chart-custom-tooltip__followers">${valueText}</div>
      <div class="line-chart-custom-tooltip__date">${prettyDate}</div>
    </div>
  `
}

const getLineColor = userindex => {
  switch (userindex) {
    case USER_INDEXES.instagram:
      return themeCommonStyles.colors.salmon
    case USER_INDEXES.tiktok:
      return themeCommonStyles.colors.aquaLight
    default:
      return themeCommonStyles.colors.salmon
  }
}

const LineChart = ({
  chartData = [],
  userindex = '',
  title = '',
  faqQuestionId = '',
  isDisabledChart = true,
  lastMonthGrowthData = [],
  id = '',
  onChartReadyCallback = null,
  backgroundColor = themeCommonStyles.color.white,
  isForPdf = false,
  withFaq = true
}) => {
  const { labelStrings } = useTranslation()

  const hAxisTextStyle = {
    ...defaultTextStyle,
  }
  const vAxisTextStyle = {
    ...defaultTextStyle,
  }

  const prepareChartData = useCallback(() => {
    const dataToMap = isDisabledChart ? disabledLineChartData() : chartData
    const uniqueDatesSet = new Set() // to avoid duplicated dates in chart data
    const uniqueMonthsSet = new Set() // to calc the number of different months in chart data, dates format depends on it

    const preparedData = dataToMap.reduce((finalArr, item, idx) => {
      const updatedItem = [...item]
      if (idx === 0) {
        updatedItem.push({ role: 'tooltip', type: 'string', p: { html: true } })
        finalArr.push(updatedItem)
      } else {
        if (updatedItem[0] && !uniqueDatesSet.has(updatedItem[0])) {
          uniqueDatesSet.add(updatedItem[0])
          const parts = item[0].split('-')
          const [year, month, day] = parts
          uniqueMonthsSet.add(month)
          updatedItem[0] = new Date(year, month - 1, day)
          updatedItem.push(createCustomTooltip(item[1], updatedItem[0]))
          finalArr.push(updatedItem)
        }
      }
      return finalArr
    }, [])

    return {
      preparedData,
      chartDatesFormat:
        uniqueMonthsSet.size > LINE_CHART_COUNT_FOR_MONTHS_FORMAT
          ? LINE_CHART_DATE_FORMAT.default
          : LINE_CHART_DATE_FORMAT.insideMonth,
    }
  }, [chartData])

  const renderFaq = faqQuestionId !== null

  const { preparedData, chartDatesFormat } = prepareChartData()
  const isChartWithMinData = preparedData.length === MIN_CHART_COUNT

  const chartSizes = { w: 760, h: 300 }
  if (isForPdf) {
    chartSizes.w = 800
    chartSizes.h = 400
  }

  return (
    <LineChartWrapper userindex={userindex} isDisabled={isDisabledChart} {...(id && { id })}>
      {title && (
        <LineChartTitle userindex={userindex} isDisabled={isDisabledChart}>
          <ChartTitletextWrapper>
            {title}
            {renderFaq && (
              <FaqtipWrapper>
                <LinkToFaqQuestion
                  userindex={userindex}
                  tipPosition="top center"
                  faqQuestionId={faqQuestionId}
                />
              </FaqtipWrapper>
            )}
          </ChartTitletextWrapper>
        </LineChartTitle>
      )}
      {lastMonthGrowthData && (
        <ChartGrowthDynamics period="lastmonth" growthData={lastMonthGrowthData} />
      )}
      {isDisabledChart && (
        <DisabledChartMessage>
          <DisabledChartMessageText platform={userindex} isForPdf={isForPdf}>
            {labelStrings.noDataforChart}
          </DisabledChartMessageText>
        </DisabledChartMessage>
      )}
      <Chart
        width={chartSizes.w}
        height={chartSizes.h}
        chartType="LineChart"
        loader={<LoadingSpinner />}
        data={preparedData}
        chartEvents={[
          {
            eventName: 'ready',
            callback({ chartWrapper }) {
              onChartReadyCallback && onChartReadyCallback(chartWrapper)
            },
          },
        ]}
        options={{
          backgroundColor,
          hAxis: {
            title: '',
            format: chartDatesFormat || LINE_CHART_DATE_FORMAT.default,
            textStyle: hAxisTextStyle,
            titleTextStyle: hAxisTextStyle,
            viewWindowMode: 'maximized',
            ...(isChartWithMinData
              ? {
                  gridlines: {
                    minSpacing: 100,
                    multiple: 1000,
                  },
                  minorGridlines: {
                    minSpacing: 100,
                    multiple: 1000,
                  },
                }
              : {
                  gridlines: {
                    minSpacing: 50,
                    multiple: 1000,
                  },
                  minorGridlines: {
                    minSpacing: 25,
                    multiple: 1000,
                  },
                }),
          },
          vAxis: {
            title: '',
            textStyle: vAxisTextStyle,
            titleTextStyle: vAxisTextStyle,
            viewWindowMode: 'maximized',
          },
          series: {
            0: {
              curveType: 'function',
              color: isDisabledChart
                ? themeCommonStyles.colors.mediumBrown
                : getLineColor(userindex),
            },
            1: {
              curveType: 'function',
              color: isDisabledChart
                ? themeCommonStyles.colors.mediumBrown
                : getLineColor(userindex),
            },
          },
          legend: 'none',
          tooltip: {
            isHtml: true,
            trigger: 'visible',
          },
        }}
        rootProps={{ 'data-testid': '2' }}
      />
    </LineChartWrapper>
  )
}

LineChart.propTypes = {
  chartData: PropTypes.array.isRequired,
  userindex: PropTypes.string,
  withFaq: PropTypes.bool,
  title: PropTypes.string,
  faqQuestionId: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
  isDisabledChart: PropTypes.bool,
  lastMonthGrowthData: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.instanceOf(null),
  ]),
  id: PropTypes.string,
  backgroundColor: PropTypes.string,
  onChartReadyCallback: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(null)]),
  isForPdf: PropTypes.bool,
}

export default memo(LineChart)
