import React, { useState } from 'react'
import { SignupOmrView } from './SignupOmrView'
import httpService from '../../../services/httpService'

export const SignupOmrContainer = () => {
  const [step, setSteps] = useState(0)
  const [signupState, changeSignupState] = useState({
    stepOneData: {
      email: '',
    },
    stepTwoData: {
      firstName: '',
      lastName: '',
      company: '',
      country: '',
      phone: '',
      companySize: '',
      position: '',
      contactPerson: '',
    },
    isLoading: false,
  })

  const [catchOmrReq, setCatchOmrReq] = useState({
    isSuccess: false,
    isLoading: false,
    error: null,
  })

  const callRequestCatchOmr = async () => {
    setCatchOmrReq(prev => ({
      ...prev,
      isLoading: true,
    }))
    try {
      const payload = {
        email: signupState?.stepOneData?.email,
        ...signupState.stepTwoData,
      }

      const response = await httpService.catchOMR(payload)
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      setCatchOmrReq({
        isLoading: false,
        isSuccess: true,
        error: null,
      })
    } catch (err) {
      setCatchOmrReq(prev => ({
        ...prev,
        isLoading: false,
        error: err,
      }))
    } finally {
      setCatchOmrReq({
        isLoading: false,
        isSuccess: false,
        error: null,
      })
    }
  }

  return (
    <SignupOmrView
      step={step}
      setSteps={setSteps}
      signupState={signupState}
      changeSignupState={changeSignupState}
      callRequestCatchOmr={callRequestCatchOmr}
    />
  )
}
