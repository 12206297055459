import React from 'react'
import PropTypes from 'prop-types'
import { USER_INDEXES } from '../../../constants/appSettings'
import useTranslation from '../../../localization/useTranslation'
import RatingMeter from '../../RatingMeter'
import * as Styled from './styles'

const InfludataRating = ({
  scoreValue = 0,
  platform = '',
  engagementMeanValue = '',
  size = 0,
  isOverallScore,
  isTitleHidden,
  isOutOfHidden,
  isEngagementMean,
  isForPdf = false,
  isSmallRatingValue
}) => {
  const { labelStrings } = useTranslation()

  let scoreSize
  if (size) {
    scoreSize = size
  } else if ((isOverallScore || isEngagementMean) && !isForPdf) {
    scoreSize = 130
  } else {
    scoreSize = 115
  }
  let displayedScoreValue;

  if (isEngagementMean) {
      displayedScoreValue = engagementMeanValue;
  } else if (scoreValue === 10) {
      displayedScoreValue = scoreValue;
  } else if (scoreValue) {
      displayedScoreValue = scoreValue.toFixed(1);
  } else if (!scoreValue) {
    displayedScoreValue = 0
  }
  
  return (
    <Styled.RatingContainer>
      {!isTitleHidden && <Styled.RatingName>{labelStrings.infludataRating}</Styled.RatingName>}
      <Styled.RatingMeterWrapper isOverallScore={isOverallScore} scoreSize={scoreSize}>
        <RatingMeter
          value={scoreValue}
          userindex={platform}
          animated={!isForPdf}
          size={scoreSize}
          withGradient={!isForPdf}
        />
        <Styled.RatingBackground scoreSize={scoreSize}>
          <Styled.RatingValue
            scoreSize={scoreSize}
            platform={platform}
            isEngagementMean={isEngagementMean}
            isOutOfHidden={isOutOfHidden}
            isForPdf={isForPdf}
            isSmallRatingValue={isSmallRatingValue}
          >
            {displayedScoreValue}
          </Styled.RatingValue>

          {!isEngagementMean && !isOutOfHidden && (
            <Styled.RatingText scoreSize={scoreSize} platform={platform} isForPdf={isForPdf}>
              {labelStrings.outOf10}
            </Styled.RatingText>
          )}
        </Styled.RatingBackground>
      </Styled.RatingMeterWrapper>
    </Styled.RatingContainer>
  )
}

InfludataRating.propTypes = {
  scoreValue: PropTypes.number,
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube]),
  engagementMeanValue: PropTypes.string,
  size: PropTypes.number,
  isOverallScore: PropTypes.bool,
  isTitleHidden: PropTypes.bool,
  isOutOfHidden: PropTypes.bool,
  isEngagementMean: PropTypes.bool,
  isForPdf: PropTypes.bool,
  isSmallRatingValue: PropTypes.bool
}

export default InfludataRating
