import React from 'react'
import PropTypes from 'prop-types'
import { ProfileContentTimeline } from './ProfileContentTimeline'
import { ProfileContentTypesCheckboxes } from './ProfileContentTypesCheckboxes'
import { FiltersWrapper } from './styles'
import { USER_INDEXES } from '../../../../constants/appSettings'

export const ProfileContentTopFilters = ({ platform }) => {
  return (
    <FiltersWrapper>
      <ProfileContentTimeline platform={platform} />
      {platform === USER_INDEXES.instagram && <ProfileContentTypesCheckboxes />}
    </FiltersWrapper>
  )
}

ProfileContentTopFilters.propTypes = {
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
}
