import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import multi from 'redux-multi'

// reducers
import { profileSelectedReducer } from './ducks/profileSelectedDucks'
import { userReducer, logoutUnathorizedUser } from './ducks/userDucks'
import { errorReducer } from './ducks/errorDucks'
import { collectionsReducer } from './ducks/collectionsDucks'
import { searchReducer } from './ducks/searchDucks'
import { campaignsReducer } from './ducks/campaignsDucks'
import { appSettingsReducer } from './ducks/appSettingsDucks'
import { compareProfilesReducer } from './ducks/compareProfilesDucks'
import { additionalFieldsReducer } from './ducks/additionalFieldsDucks'
import { exploreReducer } from './ducks/exploreDucks'
import { audienceReportsReducer } from './ducks/audienceReports'
import httpService from '../services/httpService'

const rootReducer = combineReducers({
  profileSelected: profileSelectedReducer,
  userCredentials: userReducer,
  userCollections: collectionsReducer,
  search: searchReducer,
  error: errorReducer,
  userCampaigns: campaignsReducer,
  appSettings: appSettingsReducer,
  compareProfiles: compareProfilesReducer,
  userAdditionalFields: additionalFieldsReducer,
  explore: exploreReducer,
  audienceReports: audienceReportsReducer
})

const devTools =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ serialize: true })
const isDevMode = process.env.REACT_APP_NODE_ENV === 'dev'

const composeEnhancers = isDevMode && devTools ? devTools : compose
const enhancer = composeEnhancers(applyMiddleware(thunk, multi))

export const store = createStore(rootReducer, enhancer)

// cetralized 401 error handle: when user is in app and cookies expire, we should logout user
httpService.registerCallbackForUnathorized(() => store.dispatch(logoutUnathorizedUser()))
