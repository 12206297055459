import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import { TextField } from '@mui/material'

RHFTextField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
  inputType: PropTypes.string,
}

const numberRegExp = /^[+-]?\d+(\.\d+)?$/

export default function RHFTextField({
  name,
  inputType,
  inputTypeMask = '',
  helperText = '',
  oneNumberAfterPoint = false,
  ...other
}) {
  const { control, setValue } = useFormContext()

  const handleInputChange = event => {
    let inputValue = event.target.value
    
    if (inputTypeMask === 'integer') {
      inputValue = inputValue.replace(/\D/g, '') // Remove non-digit characters
    } else if (inputTypeMask === 'float') {

      if (oneNumberAfterPoint && inputValue?.match(numberRegExp)?.[1]) {
        const val = inputValue?.match(numberRegExp)?.[1]
        const length = val && val.toString().length > 2 
        inputValue = length ? inputValue.slice(0,-1) : inputValue
      }

      inputValue = inputValue.replace(/[^\d.]/g, '') // Remove non-digit and non-decimal characters
    }

    // Update the field value
    setValue(name, inputValue)
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          type={inputType}
          onChange={handleInputChange}
          value={
            typeof field.value === 'number' && field.value === 0
              ? ''
              : field.value
              ? field.value
              : ''
          }
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
          sx={{
            '	.MuiFormHelperText-root': {
              margin: 0,
              marginTop: 1,
              padding: 0,
            },
            ...other?.sx
          }}
        />
      )}
    />
  )
}
