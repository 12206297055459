import React from 'react'
import PropTypes from 'prop-types'
import { InputAdornment } from '@mui/material'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { CustomLayout } from './CustomLayout'
import { CustomRangeShortcuts } from './CustomRangeShortcuts'

import {
  startOfToday,
  subDays,
  subMonths,
  subQuarters,
  subYears,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from 'date-fns'

export default function PickerDateRange(props) {
  const { datesValue, setDatesValue, minDate, onAccess } = props

  const shortcutsItems = [
    {
      label: 'Last 7 Days',
      getValue: () => {
        const last7DaysStart = subDays(startOfToday(), 6)
        const last7DaysEnd = startOfToday()
        return [last7DaysStart, last7DaysEnd]
      },
    },
    {
      label: 'Last 14 Days',
      getValue: () => {
        const last14DaysStart = subDays(startOfToday(), 13)
        const last14DaysEnd = startOfToday()
        return [last14DaysStart, last14DaysEnd]
      },
    },
    {
      label: 'Last 30 Days',
      getValue: () => {
        const last30DaysStart = subDays(startOfToday(), 29)
        const last30DaysEnd = startOfToday()
        return [last30DaysStart, last30DaysEnd]
      },
    },
    {
      label: 'This Month',
      getValue: () => {
        const thisMonthStart = startOfMonth(startOfToday())
        const thisMonthEnd = startOfToday()
        return [thisMonthStart, thisMonthEnd]
      },
    },
    {
      label: 'This Quarter',
      getValue: () => {
        const thisQuarterStart = startOfQuarter(startOfToday())
        const thisQuarterEnd = startOfToday()
        return [thisQuarterStart, thisQuarterEnd]
      },
    },
    {
      label: 'This Year',
      getValue: () => {
        const thisYearStart = startOfYear(startOfToday())
        const thisYearEnd = startOfToday()
        return [thisYearStart, thisYearEnd]
      },
    },
    {
      label: 'Last Month',
      getValue: () => {
        const lastMonthStart = startOfMonth(subMonths(startOfToday(), 1))
        const lastMonthEnd = startOfMonth(startOfToday())
        return [lastMonthStart, lastMonthEnd]
      },
    },
    {
      label: 'Last Quarter',
      getValue: () => {
        const lastQuarterStart = startOfQuarter(subQuarters(startOfToday(), 1))
        const lastQuarterEnd = startOfQuarter(startOfToday())
        return [lastQuarterStart, lastQuarterEnd]
      },
    },
    {
      label: 'Last Year',
      getValue: () => {
        const lastYearStart = startOfYear(subYears(startOfToday(), 1))
        const lastYearEnd = startOfYear(startOfToday())
        return [lastYearStart, lastYearEnd]
      },
    },
    { label: 'All', getValue: () => [null, null] },
  ]

  return (
    <>
      <DateRangePicker
        localeText={{ start: 'Start Date', end: 'End' }}
        size="small"
        minDate={new Date(minDate)}
        maxDate={new Date()}
        defaultValue={[null, null]}
        value={datesValue}
        onChange={val => setDatesValue(val)}
        onAccept={onAccess}
        format="dd. MMM yy"
        slots={{
          shortcuts: CustomRangeShortcuts,
          layout: CustomLayout,
        }}
        slotProps={{
          shortcuts: {
            items: shortcutsItems,
          },
          fieldSeparator: { sx: { ml: '8px !important' } },
          textField: () => ({
            size: 'small',
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <DateRangeIcon />
                </InputAdornment>
              ),
            },
            sx: {
              maxWidth: { md: 140, marginLeft: '4px !important' },
            },
          }),
        }}
      />
    </>
  )
}

PickerDateRange.propTypes = {
  datesValue: PropTypes.array,
  setDatesValue: PropTypes.func,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onAccess: PropTypes.func
}
