import React, { useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useTranslation from '../../../localization/useTranslation'
import WithTip from '../../common/popups/WithTip'
import WithUpgradeTip from '../../common/popups/WithUpgradeTip'
import { COMPARED_PROFILES_LIMIT } from '../../../constants/comparedProfiles'
import { debouncedAsync } from '../../../utils'

import { CompareProfilesIcon } from '../../common/icons'
import { ClickHere } from '../../common/styledWrappers'

const Wrapper = styled.div`
  position: relative;
  & .popup-content {
    top: unset !important;
    bottom: 30px !important;
  }
`

const IconWrapper = styled.div`
  margin: 0 5px;
  ${props => props.disabled && props.theme.disableBlock};
  & > span {
    margin: 3px;
  }
`
const TipWrapper = styled.div`
  ${props => props.theme.flex.centerAll}
  flex-direction: column;
  width: 100%;
  text-align: center;
`
const ClickWrapper = styled(ClickHere)`
  color: ${props => props.theme.color.mineShaft};
`

const ProfileCompareTool = ({
  userindex = '',
  enableCompareProfiles,
  isUserAuthenticated,
  isAlreadyCompared,
  manageCompareProfile,
  isForbiddenPlatform,
  isInCollections,
  resetComparedProfiles,
  comparedProfilesCount = 0,
}) => {
  const { labelStrings } = useTranslation()

  const [isCompared, setIsCompared] = useState(false)

  useEffect(() => {
    if (isCompared !== isAlreadyCompared) setIsCompared(isAlreadyCompared)
  }, [isAlreadyCompared])

  const isDisabled = !isUserAuthenticated || !enableCompareProfiles
  const isLimitReached = comparedProfilesCount >= COMPARED_PROFILES_LIMIT
  const isAddExeedsLimits = !isCompared && isLimitReached
  const isActionInProgress = isCompared !== isAlreadyCompared

  const noActions = isDisabled || isForbiddenPlatform || isAddExeedsLimits

  // handlers
  const handleCompare = useCallback(
    debouncedAsync(async toAdd => await manageCompareProfile(toAdd), 500),
    [isAlreadyCompared, isDisabled, isAddExeedsLimits]
  )

  const onCompareClick = async () => {
    if (noActions) return
    const toAdd = !isCompared
    setIsCompared(toAdd)
    const success = await handleCompare(toAdd)
    if (!success) setIsCompared(false)
  }

  // helper components
  const CompareIcon = (
    <IconWrapper disabled={noActions} onClick={onCompareClick}>
      <CompareProfilesIcon
        userindex={userindex}
        isActiveIcon={noActions ? false : isCompared}
        {...(!isCompared && { tabIcon: false })}
      />
    </IconWrapper>
  )

  const _createTip = useCallback(() => {
    if (!isActionInProgress && (isForbiddenPlatform || isAddExeedsLimits)) {
      return (
        <TipWrapper>
          {isForbiddenPlatform
            ? labelStrings.cantAddManyPlatformsToCompare
            : labelStrings.comparedProfilesLimitReach}
          .
          {isInCollections && isForbiddenPlatform && (
            <>
              &nbsp;{labelStrings.useAnotherCollection} {labelStrings.or}&nbsp;
            </>
          )}
          <span>
            <ClickWrapper onClick={resetComparedProfiles}>{labelStrings.clickHere}</ClickWrapper>
            &nbsp;
            {labelStrings.toClearAllComparedProfiles}
          </span>
        </TipWrapper>
      )
    }
    return (
      <TipWrapper>
        {isCompared ? labelStrings.removeFromCompareProfiles : labelStrings.addToCompareProfiles}
      </TipWrapper>
    )
  }, [isForbiddenPlatform, isAddExeedsLimits, isInCollections, isActionInProgress])

  const _createUpgradeRequired = () => {
    return (
      <WithUpgradeTip
        tipText={labelStrings.useComapreProfiles}
        userindex={userindex}
        withSignup={!isUserAuthenticated}
      >
        {CompareIcon}
      </WithUpgradeTip>
    )
  }

  const _createEnabled = () => {
    return (
      <WithTip TipComponent={_createTip()} position="top center" userindex={userindex}>
        {CompareIcon}
      </WithTip>
    )
  }

  const render = useMemo(() => {
    return isDisabled ? _createUpgradeRequired() : _createEnabled()
  }, [isDisabled, noActions, isCompared, isAddExeedsLimits])

  // render
  return <Wrapper>{render}</Wrapper>
}

ProfileCompareTool.propTypes = {
  userindex: PropTypes.string,
  enableCompareProfiles: PropTypes.bool,
  isUserAuthenticated: PropTypes.bool,
  manageCompareProfile: PropTypes.func,
  isAlreadyCompared: PropTypes.bool,
  isForbiddenPlatform: PropTypes.bool,
  isInCollections: PropTypes.bool,
  resetComparedProfiles: PropTypes.func,
  comparedProfilesCount: PropTypes.number,
}

export default ProfileCompareTool
