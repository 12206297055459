import React, { useEffect, useState } from "react"
import { Button, Stack, Box } from "@mui/material"
import {
  ListWrapper,
  SearchListHeader,
} from "../../../components/common/styledWrappers"
import SearchPagePagination from "../../../components/pagination/SearchPagePagination"
import ProfileListFullTableContainer from "../../../components/profileCommon/profileListTable/ProfileListFullTableContainer"
import {
  searchLoadingStatusSelector,
  searchResultsSelector,
} from "../../../redux/selectors"
import { useDispatch, useSelector } from "react-redux"
import { pagesBeforeProfileRedirect } from "../../../constants/pagesBeforeProfileRedirect"
import { updateCurrentSearchQuery } from "../../../redux/ducks/searchDucks"
import { changeAdditionalFieldValue } from "../../../redux/ducks/additionalFieldsDucks"
import { useHistory } from "react-router-dom"
import queryString from "query-string"
import LoadingSpinner from "../../../components/common/spinners/LoadingSpinner"
import SearchResultsPageTip from "../../../components/pagesTips/SearchResultsPageTip"
import { searchContentTypes } from "../../../constants/search"
import useTranslation from "../../../localization/useTranslation"
import { SkeletonLoader } from "../../../components/profileCommon/profileListTable/SkeletonLoader"

const filteredByUserId = ({ array = [], userId = "" }) =>
  array?.filter((user) => user._id !== userId)

export const RecentSearchView = ({ currQuery, changeQuery }) => {
  const { labelStrings } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const searchResults = useSelector(searchResultsSelector)
  const { users, count, dataSuccess } = searchResults

  const isSearchLoading = useSelector(searchLoadingStatusSelector)

  const [searchUsers, setSearchUsers] = useState(users)

  const resultsExist = !!searchUsers?.length

  const onBlockUnblockUser = async ({ profileId, isBlack }) => {
    const filteredUsers = filteredByUserId({
      array: !!searchUsers?.length ? searchUsers : users,
      userId: profileId,
    })
    setSearchUsers(filteredUsers)

    await dispatch(
      // @ts-ignore
      changeAdditionalFieldValue({
        profileId: profileId,
        changeField: "isBlack",
        valueObj: `${!isBlack}`,
      }),
    )
  }

  const onApplyToSearch = () => {
    const filteredData = Object.fromEntries(
      Object.entries(currQuery).filter(
        ([key, value]) => !!value || typeof value === "number",
      ),
    )
    const newQueryString = queryString.stringify(filteredData)
    dispatch(updateCurrentSearchQuery(currQuery))
    history.replace(`/search?${newQueryString}`)
  }

  useEffect(() => {
    if (users?.length) {
      setSearchUsers(users)
    }
  }, [users])

  return (
    <Stack sx={{ width: "100%" }}>
      {!users?.length && isSearchLoading && (
        <LoadingSpinner isTransparent isExplore />
      )}
      <Stack sx={{ m: 0, p: 0 }} direction='row' justifyContent='end'>
        {resultsExist && (
          <Button
            sx={{
              borderRadius: "13px",
              borderColor: "#BC9797",
              color: "#784E4E",
              fontWeight: "bold",
              fontFamily: "Inter, sans-serif",
              fontSize: "15px",
              lineHeight: "19px",
              backgroundColor: "#FFF9F9",
            }}
            variant='outlined'
            onClick={onApplyToSearch}
          >
            {labelStrings.explorePage.applyToSearch}
          </Button>
        )}
      </Stack>

      <SearchListHeader>
        {!!count && !isSearchLoading && (
          <SearchPagePagination
            withResultsCount
            isExplore
            searchType={searchContentTypes.CREATOR}
            changeExploreQuery={changeQuery}
          />
        )}
      </SearchListHeader>

      <ListWrapper hasPagination>
        {isSearchLoading ? (
          <Box>
            <SkeletonLoader searchType={searchContentTypes.CREATOR} />
          </Box>
        ) : (
          <>
            {resultsExist && (
              <ProfileListFullTableContainer
                users={searchUsers}
                beforeRedirectPage={pagesBeforeProfileRedirect.EXPLORE}
                onBlockUnblockUser={onBlockUnblockUser}
                isExplore
              />
            )}
            {!!count && (
              <SearchPagePagination
                onlyPagination
                isExplore
                searchType={searchContentTypes.CREATOR}
                changeExploreQuery={changeQuery}
                isBottom
              />
            )}
          </>
        )}
      </ListWrapper>
      {!resultsExist && !isSearchLoading && (
        <SearchResultsPageTip
          noResults={!dataSuccess}
          isUserAuthenticated={true}
          isExplore
        />
      )}
    </Stack>
  )
}
