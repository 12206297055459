import styled from 'styled-components'

export const MainWrapper = styled.div`
  height: 35px;
  background: ${props => props.theme.primaryColorMidLight};
  display: flex;
  align-items: center;
  ${props => props.disabled && props.theme.disableBlock}
  position: relative;
`

export const PersonIconWrapper = styled.div`
  margin: 0 20px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  ${props => props.theme.flex.centerAll};
  align-items: flex-end;
  overflow: hidden;
  background: ${props => props.theme.primaryColor};
  svg {
    fill: ${props => props.theme.color.milk};
    height: 16px;
    width: 16px;
    opacity: 0.9;
  }
`

export const LimitContent = styled.div`
  width: 75%;
  height: 100%;
  ${props => props.theme.flex.centerAll};
  flex-direction: column;
  padding-top: 5px;
`

export const LimitMessage = styled.div`
  color: ${props => props.theme.textColor};
  font-family: ${props => props.theme.fontFamilyRoboto};
  font-size: ${props => (props.isSmallerFont ? 10 : 11)}px;
  margin-top: 1px;
`

export const ExceedLimitMessage = styled(LimitMessage)`
  color: ${props => props.theme.primaryColor};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`

export const LimitLineOuterWrapper = styled.div`
  width: 100%;
  height: 3px;
  background: ${props => props.theme.color.white};
  border-radius: 8px;
`
export const LimitLineInnerWrapper = styled.div`
  height: inherit;
  width: ${props => props.widthPercent || 0}%;
  transition: all 0.5s ease;
  background: ${props => props.theme.primaryColor};
`

export const ChangeSeatsPlanWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  text-decoration: underline;
  font-size: 11px;
  color: ${props => props.theme.textColor};
  font-family: ${props => props.theme.fontFamilyRoboto};
  position: absolute;
  right: 16px;
  bottom: -16px;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`
