import styled from 'styled-components'

export const FollowersHistoryContainer = styled.div`
  ${props => !props.isForPdf && 'margin: 50px 0'};
`

export const FollowersChartWrapper = styled.div`
  overflow: hidden;
  ${props => !props.isForPdf && 'padding-left: 25px'};
`

export const BtnYtChartWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
`
export const BtnYTChart = styled.button`
  ${props => props.isDisabled && props.theme.disabled};
  ${props =>
    !props.isActive
      ? `
    border: 1px solid ${props.theme.colors.mauve};
    color: ${props.theme.colors.mauve};
    background: none;
  `
      : `
    border: solid 1px transparent;
    background-image:
    linear-gradient(rgba(255, 255, 255, 0),rgba(255, 255, 255, 0)),
      ${props.theme.gradients.mainYoutube};
    background-origin: border-box;
    background-clip: content-box, border-box;
    color: ${props.theme.colors.white};

    &:hover {
      box-shadow: none;
    }
  `}
  border-radius: 7px;
  font-weight: 700;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  padding: 3px 8px;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
`
