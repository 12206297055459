import React, { useEffect, useState } from 'react'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import useTranslation from '../../../../../localization/useTranslation'
import { useDispatch } from 'react-redux'
import httpService from '../../../../../services/httpService'
import { copyCampaign } from '../../../../../redux/ducks/campaignsDucks'

export const CopyCampaignFirst = ({ getValues, goToNextStep }) => {
  const { labelStrings, currentLang } = useTranslation()
  const dispatch = useDispatch()

  const enteredCampaignName = getValues('campaignName')

  const [campaignsList, setCampaignsList] = useState([])
  const filteredCampaigns = campaignsList.filter(x => !x.isDemoCampaign)
  const [selectedCampaign, setSelectedCampaign] = useState('')

  const getAllCampaigns = async () => {
    try {
      const allCampaigns = await httpService.getAllNewCampaigns()

      if (allCampaigns?.length) {
        setCampaignsList(allCampaigns)
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getAllCampaigns()
  }, [])

  const handleChangeCampaign = event => {
    const value = event.target.value
    setSelectedCampaign(value)
  }

  const onHandleCopy = () => {
    dispatch(
      // @ts-ignore
      copyCampaign({
        campaignId: selectedCampaign,
        newCampaignName: enteredCampaignName,
      })
    )
    goToNextStep()
  }

  return (
    <Stack alignItems="center" spacing={1.5}>
      <Stack spacing={1}>
        <Typography
          component="label"
          sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '24px', mb: 1 }}
        >
          {labelStrings.copyCampaign.first.selectTitle}
        </Typography>
        {campaignsList?.length ? (
          <FormControl fullWidth>
            <InputLabel size="small">{labelStrings.selectYourCampaign}</InputLabel>
            <Select
              label={labelStrings.selectYourCampaign}
              onChange={handleChangeCampaign}
              value={selectedCampaign}
              size="small"
              autoWidth
              MenuProps={{
                PaperProps: {
                  style: {
                    width: '100%',
                    maxWidth: '360px',
                    maxHeight: '318px',
                    marginTop: '4px',
                  },
                },
              }}
            >
              {filteredCampaigns?.map(campaign => {
                return (
                  <MenuItem key={campaign.campaignId} value={campaign.campaignId}>
                    {campaign.campaignName}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        ) : (
          <Skeleton
            variant="rounded"
            animation="wave"
            width={currentLang === 'de' ? 434 : 305}
            height={40}
          />
        )}
      </Stack>

      <Typography
        component="label"
        textAlign="center"
        sx={{
          color: '#637381',
          lineHeight: '22px',
          fontSize: `14px`,
          fontFamily: `Inter`,
          fontWeight: 400,
          textAlign: `center`,
          marginTop: 2,
          marginRight: `14px`,
          marginBottom: 0,
          marginLeft: `14px`,
          maxWidth: currentLang === 'de' ? '480px' : '422px',
        }}
      >
        {labelStrings.copyCampaign.first.helperText}
      </Typography>

      <Button sx={{ mt: '24px !important' }} variant="outlined" onClick={onHandleCopy}>
        {labelStrings.copyCampaign.first.btnText}
      </Button>
    </Stack>
  )
}
