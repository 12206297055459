import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { SentimentAnalytics } from './SentimentAnalytics'
import Image from '../../image'
import { Box, Stack, Avatar, Typography, Card, TextField, Link, Chip, Tooltip } from '@mui/material'

import { Favorite as FavoriteIcon, Comment as CommentIcon, Visibility as VisibilityIcon, MonetizationOn as MonetizationOnIcon, Search as SearchIcon, Percent as PercentIcon, Bookmark as BookmarkIcon, Send as SendIcon, Group as GroupIcon } from '@mui/icons-material'


import { fDateTime } from '../../../utils/formatTime'
import { fShortenNumber } from '../../../utils/formatNumber'
import { StyledPaper, StyledCardTitle, StyledIconBtn, ShowSentimentBtn } from './styles'
import { EditCampaignCardContentIcon, CrossIcon, AccessIcon } from '../../../assets/icons/navbar'
import { PlayingVideo } from './PlayingVideo'
import { CARD_HEIGHT, getPercent } from './utils'
import { GroupItemCarousel } from './GroupsItemCarousel'
import { WrapperMediaItem } from './WrapperMediaItem'
import { useSelector } from 'react-redux'
import { contentListSelector } from '../../../../redux/selectors'
import { LoadingButton } from '@mui/lab'
import useTranslation from '../../../../localization/useTranslation'
import { USER_INDEXES } from '../../../../constants/appSettings'

export const PublishedItem = props => {
  const {
    item,
    isNotWidget,
    isDataEnrichment,
    openModal,
    setEditItem,
    isEdit = false,
    openUnmatchedContent,
    onAddToCampaign,
    setCurrentCreatorsData,
  } = props

  const {
    nonRecognizedContent: { isLoading, dataSuccess },
  } = useSelector(contentListSelector) || {}

  const { labelStrings } = useTranslation()

  const {
    avatar,
    name,
    dateTimeAt,
    likes,
    comments,
    estReach,
    value,
    found,
    contentImg,
    contentType,
    platform,
    contentVideo,
    contentVideoPreview,
    // commentPositivityRate,
    // controversialCommentRate,
    commentSummary,
    contentLink,
    videoUrls,
    imageUrls,
    engagementRate,
    platformLink,
    label,
    ad_media,
    shares, 
    saves
  } = item

  const [startVideo, setStartVideo] = useState(false)
  const [viewsValue, setViewsValue] = useState(0)
  const [showSentimentAnalytics, setShowSentimentAnalytics] = useState(false)

  const [isLoadingAddToCampaign, setIsLoadingAddToCampaign] = useState(false)
  const [addToCampaignSuccess, setIsAddToCampaignSuccess] = useState(false)

  const elementRef = useRef(null)
  const [heightElement, setHeightElement] = useState(0)
  const [widthElement, setWidthElement] = useState(0)

  const isDownloadContentModal =
    (item.platform === USER_INDEXES.instagram &&
      (contentType === 'post' || contentType === 'reel')) ||
    item.platform === USER_INDEXES.tiktok

  useEffect(() => {
    if (isLoadingAddToCampaign && !addToCampaignSuccess) {
      setIsAddToCampaignSuccess(dataSuccess)
      setIsLoadingAddToCampaign(isLoading)
    }
  }, [dataSuccess, isLoadingAddToCampaign])

  useLayoutEffect(() => {
    setHeightElement(elementRef.current.offsetHeight)
    setWidthElement(elementRef.current.offsetWidth)
  }, [heightElement, widthElement])

  const onHandlePlayVideo = () => {
    setStartVideo(!startVideo)
  }

  const onHandleEdit = () => {
    openModal(true)
    setEditItem(item)
  }

  const onShowSentimentAnalytics = () => {
    setShowSentimentAnalytics(!showSentimentAnalytics)
  }

  const isVisibleShowSentimentBtn = commentSummary //&&
  //controversialCommentRate &&
  //commentPositivityRate) ||
  //contentType === 'Story_group'

  const addToCampaign = ({ contentIds }) => {
    setIsLoadingAddToCampaign(true)
    setIsAddToCampaignSuccess(false)
    onAddToCampaign({ contentIds })
  }

  const onHandleSetCurrCreatorsData = data => {
    setCurrentCreatorsData && setCurrentCreatorsData(data)
  }

  return (
    <Box sx={{ width: isNotWidget ? '100%' : '279px' }}>
      {showSentimentAnalytics ? (
        <SentimentAnalytics
          isNotWidget={isNotWidget}
          item={item}
          onShowSentimentAnalytics={onShowSentimentAnalytics}
        />
      ) : (
        <StyledPaper
          sx={{
            marginBottom: isNotWidget ? '16px' : 0,
            height: openUnmatchedContent ? '585px' : '539px',
          }}
        >
          <Stack spacing={2.5} sx={{ p: 3, pb: 2.5 }} ref={elementRef}>
            <Stack direction="row" alignItems="center" spacing={2} sx={{ position: 'relative' }}>
              <Link href={contentLink} target="_blank">
                <Avatar alt={name} src={avatar} />
              </Link>
              <Box>
                <Typography variant="subtitle2">{name}</Typography>

                <Typography
                  variant="caption"
                  sx={{ color: 'text.disabled', mt: 0.5, display: 'block' }}
                >
                  {fDateTime(dateTimeAt)}
                </Typography>
              </Box>
              {isNotWidget && !isEdit && !isDataEnrichment && !openUnmatchedContent && (
                <Box
                  sx={{ position: 'absolute', top: '-18px', right: 0, cursor: 'pointer' }}
                  onClick={onHandleEdit}
                >
                  <EditCampaignCardContentIcon />
                </Box>
              )}
            </Stack>
            <Stack direction="row" alignItems="center" spacing={3} sx={{ color: 'text.secondary' }}>
              {isDataEnrichment ? (
                <Stack direction="column">
                  <Stack direction="column" alignItems="start" spacing={1}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: '#637381',
                      }}
                    >
                      Instagram Post
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: '#637381',
                        marginTop: '0 !important',
                      }}
                    >
                      Please add the value “Story Views“ for this content piece
                    </Typography>
                  </Stack>
                  <Box sx={{ marginTop: 3, alignItems: 'center', display: 'flex' }}>
                    <TextField
                      label="Views"
                      value={viewsValue}
                      onChange={event => {
                        setViewsValue(event.target.value)
                      }}
                      size="small"
                      sx={{ width: '100%' }}
                    />
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={0.5}
                      sx={{ marginLeft: '12px', width: 'fit-content' }}
                    >
                      <StyledIconBtn
                        size="small"
                        sx={{
                          backgroundColor: '#FF5D5A',
                          boxShadow: '0px 8px 16px rgba(255, 93, 90, 0.25)',
                          '&:hover': {
                            backgroundColor: '#FF5D5A',
                            filter: 'brightness(90%)',
                          },
                        }}
                      >
                        <AccessIcon />
                      </StyledIconBtn>
                      <StyledIconBtn
                        size="small"
                        sx={{
                          backgroundColor: '#FFBC00',
                          boxShadow: '0px 8px 16px rgba(255, 188, 0, 0.25)',
                          '&:hover': {
                            backgroundColor: '#FFBC00',
                            filter: 'brightness(90%)',
                          },
                        }}
                      >
                        <CrossIcon />
                      </StyledIconBtn>
                    </Stack>
                  </Box>
                </Stack>
              ) : (
                <Stack direction="column" alignItems="center" spacing={1}>
                  {label && (
                    <Tooltip title={labelStrings.customLabel || "Custom Label"}>
                      <Chip
                        label={label}
                        size="small"
                        variant="outlined"
                        sx={{ mr: 0.5, color: 'darkred' }} // Adjust the styling as needed
                      />
                    </Tooltip>
                  )}
                    
                  <Stack direction="row" flexWrap="wrap" justifyContent="center" sx={{ width: '100%', gap: '3px' }} spacing={1}>
                    {typeof likes !== 'undefined' && likes !== null && (
                      <Tooltip title={labelStrings.likes || 'Likes'}>
                        <Chip size="small" icon={<FavoriteIcon />} label={fShortenNumber(likes)} variant="outlined" />
                      </Tooltip>
                    )}
                    {typeof comments !== 'undefined' && comments !== null && (
                      <Tooltip title={labelStrings.comments || 'Comments'}>
                        <Chip size="small" icon={<CommentIcon />} label={fShortenNumber(comments)} variant="outlined" />
                      </Tooltip>
                    )}
                    <Tooltip title={labelStrings.reach || 'Reach'}>
                      <Chip size="small" icon={<VisibilityIcon />} label={fShortenNumber(estReach)} variant="outlined" />
                    </Tooltip>
                    <Tooltip title={labelStrings.emvForContent || 'Value'}>
                      <Chip size="small" icon={<MonetizationOnIcon />} label={value} variant="outlined" />
                    </Tooltip>
                    {typeof shares !== 'undefined' && shares !== null && (
                      <Tooltip title={labelStrings.shares || 'Shares'}>
                        <Chip size="small" icon={<SendIcon />} label={fShortenNumber(shares)} variant="outlined" />
                      </Tooltip>
                    )}
                    {typeof saves !== 'undefined' && saves !== null && (
                      <Tooltip title={labelStrings.saves || 'Saves'}>
                        <Chip size="small" icon={<BookmarkIcon />} label={fShortenNumber(saves)} variant="outlined" />
                      </Tooltip> 
                    )}
                    {typeof engagementRate !== 'undefined' && engagementRate !== null && engagementRate !== 0 && (
                      <Tooltip title={labelStrings.engagementMean || 'Engagement Rate'}>
                        <Chip size="small" icon={<PercentIcon />} label={getPercent(engagementRate || 0)} variant="outlined" />
                      </Tooltip>
                    )}
                    {found && found.map((item, index) => (
                      <Tooltip key={index} title={labelStrings.found || 'Found'}>
                        <Chip size="small" icon={<SearchIcon />} label={item} variant="outlined" sx={{ mr: 0.5 }} />
                      </Tooltip>
                    ))}
                    {ad_media && (
                      <Tooltip title={labelStrings.sponsoredDetails || "Sponsored Content"}>
                        <Chip
                          label="Sponsored"
                          size="small"
                          sx={{ mr: 0.5, bgcolor: 'lightpink', color: 'darkred' }} // Adjust the styling as needed
                        />
                      </Tooltip>
                    )}
                    {ad_media && typeof ad_media.like_count !== 'undefined' && (
                      <Tooltip title={labelStrings.adLikeCountTooltip}>
                        <Chip
                          label={ad_media.like_count}
                          size="small"
                          icon={<FavoriteIcon />}
                          sx={{ mr: 0.5, bgcolor: 'lightpink', color: 'darkred' }} // Adjust the styling as needed
                        />
                      </Tooltip>
                    )}
                    {ad_media && typeof ad_media.comment_count !== 'undefined' && (
                      <Tooltip title={labelStrings.adCommentCountTooltip || "Sponsored Content"}>
                        <Chip
                          label={ad_media.comment_count}
                          size="small"
                          icon={<CommentIcon />}
                          sx={{ mr: 0.5, bgcolor: 'lightpink', color: 'darkred' }} // Adjust the styling as needed
                        />
                      </Tooltip>
                    )}
                    {ad_media && typeof ad_media.save_count !== 'undefined' && (
                      <Tooltip title={labelStrings.adSaveCountTooltip || "Sponsored Content"}>
                        <Chip
                          label={ad_media.save_count}
                          size="small"
                          icon={<BookmarkIcon />}
                          sx={{ mr: 0.5, bgcolor: 'lightpink', color: 'darkred' }} // Adjust the styling as needed
                        />
                      </Tooltip>
                    )}
                  </Stack>
                </Stack>
              )}
            </Stack>
            {isVisibleShowSentimentBtn && (
              <ShowSentimentBtn onClick={onShowSentimentAnalytics} variant="contained">
                Show Sentiment Analytics
              </ShowSentimentBtn>
            )}
          </Stack>

          <Box
            sx={{
              p: 1,
              position: 'relative',
              height: `calc(${CARD_HEIGHT}px - ${heightElement}px)`,
            }}
          >
            <Box
              sx={{
                height: `calc(520px - ${heightElement}px)`,
              }}
            >
              {videoUrls?.length || imageUrls?.length || contentType === 'story_group' ? (
                <GroupItemCarousel
                  videoUrls={videoUrls}
                  imageUrls={imageUrls}
                  heightElement={heightElement}
                  widthElement={widthElement}
                  onHandleSetCurrCreatorsData={() => onHandleSetCurrCreatorsData(item)}
                  isDownloadContentModal={isDownloadContentModal}
                />
              ) : (
                <>
                  {contentVideo ? (
                    <WrapperMediaItem
                      contentType={contentType}
                      platform={platform}
                      widthElement={widthElement}
                      heightElement={heightElement}
                      isDataEnrichment={isDataEnrichment}
                      contentVideo={contentVideo}
                      contentImg={contentImg}
                      onHandleSetCurrCreatorsData={() => onHandleSetCurrCreatorsData(item)}
                      isDownloadContentModal={isDownloadContentModal}
                    >
                      <Card sx={{ width: '100%', height: '100%' }}>
                        <PlayingVideo
                          singleVideo={contentVideo}
                          singleVideoPreview={contentVideoPreview}
                          contentImg={contentImg}
                          startVideo={startVideo}
                          setStartVideo={setStartVideo}
                          onHandlePlayVideo={onHandlePlayVideo}
                        />
                      </Card>
                    </WrapperMediaItem>
                  ) : (
                    <>
                      <WrapperMediaItem
                        contentType={contentType}
                        platform={platform}
                        widthElement={widthElement}
                        heightElement={heightElement}
                        isDataEnrichment={isDataEnrichment}
                        contentVideo={contentVideo}
                        contentImg={contentImg}
                        onHandleSetCurrCreatorsData={() => onHandleSetCurrCreatorsData(item)}
                        isDownloadContentModal={isDownloadContentModal}
                      >
                        {platformLink ? (
                          <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => window.open(platformLink, '_blank')}
                          >
                            <Image
                              alt="cover"
                              src={contentImg}
                              ratio="1/1"
                              sx={{
                                borderRadius: 1.5,
                                height: `calc(520px - ${heightElement}px)`,
                              }}
                            />
                          </div>
                        ) : (
                          <Image
                            alt="cover"
                            src={contentImg}
                            ratio="1/1"
                            sx={{ borderRadius: 1.5, height: `calc(520px - ${heightElement}px)` }}
                          />
                        )}
                      </WrapperMediaItem>
                    </>
                  )}
                </>
              )}
            </Box>
          </Box>
          {openUnmatchedContent && (
            <Box sx={{ px: 1, mb: 1 }}>
              <LoadingButton
                loading={isLoadingAddToCampaign}
                variant="outlined"
                onClick={() =>
                  addToCampaign({
                    contentIds: item.ids && item.ids.length > 0 ? item.ids : [item.id],
                  })
                }
                sx={{ width: '100%' }}
                disabled={addToCampaignSuccess}
              >
                {!isLoadingAddToCampaign && !addToCampaignSuccess
                  ? labelStrings.campaignContentOverview.addToCampaign
                  : labelStrings.campaignContentOverview.addedToCampaign}
              </LoadingButton>
            </Box>
          )}
        </StyledPaper>
      )}
    </Box>
  )
}

PublishedItem.propTypes = {
  item: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    dateTimeAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    likes: PropTypes.number,
    comments: PropTypes.number,
    estReach: PropTypes.number,
    value: PropTypes.string,
    reachedTargetGroup: PropTypes.string,
    found: PropTypes.array,
    contentImg: PropTypes.string,
    contentType: PropTypes.string,
    platform: PropTypes.string,
  }),
  isColumn: PropTypes.bool,
  openModal: PropTypes.func,
  setEditItem: PropTypes.func,
  isEdit: PropTypes.bool,
  isNotWidget: PropTypes.bool,
  isDataEnrichment: PropTypes.bool,
  openUnmatchedContent: PropTypes.bool,
  onAddToCampaign: PropTypes.func,
  setCurrentCreatorsData: PropTypes.func,
}
