import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, List, Stack, Typography } from '@mui/material'
import AddProfileToInfludataContainerMUI from '../../add-profile-to-infludata/AddProfileToInfludataContainerMUI'
import Scrollbar from '../../scrollbar/Scrollbar'
import { CreatorsListItem } from './CreatorsListItem'
import useTranslation from '../../../../localization/useTranslation'
import { StyledCardCreatorList, StyledSkeleton } from './styles'

export const CreatorsList = props => {
  const {
    creators = [],
    notFoundData,
    loadingCreators,
    currentPlatform = '',
    creatorsName = '',
    showList,
    error,
  } = props || {}

  const { labelStrings } = useTranslation()

  const isExactUsernameFound = useMemo(() => {
    if (!creators.length || !creatorsName) return false
    return creators.some(u => u.username === creatorsName)
  }, [creators?.length, creatorsName])

  return (
    <StyledCardCreatorList sx={{ visibility: showList ? 'visible' : 'hidden' }}>
      {!notFoundData && error && !creators?.length && (
        <Box sx={{ padding: 2 }}>
          <Typography
            sx={{ fontWeight: 600, textAlign: 'center', fontSize: '14px', lineHeight: '22px' }}
          >
            {labelStrings.somethingWentWrong}
          </Typography>
        </Box>
      )}
      {notFoundData && (
        <Stack spacing={1} sx={{ padding: 2 }}>
          <Typography
            sx={{ fontWeight: 600, textAlign: 'center', fontSize: '14px', lineHeight: '22px' }}
          >
            {labelStrings.noCreatorsFound}
          </Typography>
          <Divider />
          <AddProfileToInfludataContainerMUI
            key="add_creators_to_campaign"
            usernameToAdd={creatorsName}
            userindex={currentPlatform?.toLowerCase()}
            usersFound={notFoundData}
            isExactUsernameFound={isExactUsernameFound}
          />
        </Stack>
      )}
      {loadingCreators && (
        <Stack spacing={1} sx={{ padding: 2, height: '286px' }}>
          <StyledSkeleton animation="wave" variant="rectangular" width="100%" height={160} />
          <StyledSkeleton animation="wave" variant="rectangular" width="60%" height={80} />
          <StyledSkeleton animation="wave" variant="rectangular" width="40%" height={80} />
        </Stack>
      )}
      {!notFoundData && !loadingCreators && !!creators?.length && (
        <List>
          <Scrollbar sx={{ maxHeight: '286px', padding: 1 }}>
            {creators?.map(creator => (
              <Fragment key={creator._id}>
                <CreatorsListItem creator={creator} />
              </Fragment>
            ))}
            {!isExactUsernameFound && (
              <Stack sx={{ py: 1 }}>
                <Divider sx={{ mb: 1 }} />
                <AddProfileToInfludataContainerMUI
                  key="add_creators_to_campaign"
                  usernameToAdd={creatorsName}
                  userindex={currentPlatform?.toLowerCase()}
                  usersFound={notFoundData}
                  isExactUsernameFound={isExactUsernameFound}
                />
              </Stack>
            )}
          </Scrollbar>
        </List>
      )}
    </StyledCardCreatorList>
  )
}

CreatorsList.propTypes = {
  creators: PropTypes.array,
  notFoundData: PropTypes.bool,
  loadingCreators: PropTypes.bool,
  currentPlatform: PropTypes.string,
  creatorsName: PropTypes.string,
  showList: PropTypes.bool,
  error: PropTypes.bool,
}