import React, { useEffect } from 'react'
import { ModalComponent } from '../../components/modal/ModalComponent'
import { Stack, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AddValueManuallySchema } from './Form/FormSchema'
import { AddValueManuallyFields } from './Form/AddValueManuallyFields'
import useTranslation from '../../../localization/useTranslation'

export const AddValueManuallyModal = props => {
  const {
    setOpenAddValueModal,
    openAddValueModal: isOpenModal,
    setCurrRow,
    currRow,
    creatorsList,
    onAddValueManually,
  } = props

  const { labelStrings, currentLang } = useTranslation()

  const { applyLevel, customFieldId } = currRow || {}
  const isApplyLevelCreator = applyLevel === 'creator'

  const methods = useForm({
    resolver: yupResolver(AddValueManuallySchema),
    defaultValues: {
      value: 0 || '',
      date: new Date() || '',
      creatorId: '',
    },
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods

  const handleClose = () => {
    setOpenAddValueModal(false)
    setCurrRow(null)
    reset({})
  }

  const onSubmit = async data => {
    try {
      await new Promise(resolve => setTimeout(resolve, 500))
      onAddValueManually({
        ...data,
        customFieldId,
      })
      handleClose()
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    reset({
      date: new Date(),
    })
  }, [])

  return (
    <ModalComponent
      isOpenModal={isOpenModal}
      closeModal={handleClose}
      title={`Add Value Manually`}
      contentWidth="480px"
      contentHeight={currentLang === 'de' ? '400px' : '340px'}
      smallPadding
      isCenterTitle
    >
      <Stack>
        <Stack justifyContent="center">
          <Stack direction="row" justifyContent="center" sx={{ mb: 1 }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
              {labelStrings.forCustomField}:{' '}
            </Typography>
            <Typography
              sx={{ textTransform: 'capitalize', fontWeight: 'bold', ml: 0.5, fontSize: '14px' }}
            >
              "{currRow?.customFieldName}"
            </Typography>
          </Stack>
          <Typography sx={{ fontSize: '14px', whiteSpace: 'pre-line' }}>
            {labelStrings.addValueManuallyFormHelperText}
          </Typography>
        </Stack>

        <AddValueManuallyFields
          methods={methods}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          handleClose={handleClose}
          isApplyLevelCreator={isApplyLevelCreator}
          creatorsList={creatorsList}
        />
      </Stack>
    </ModalComponent>
  )
}
