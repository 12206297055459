import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import CommonSingleRangeSlider from './CommonSingleRangeSlider'
import LinkToFaqQuestion from '../../faqs/LinkToFaqQuestion'
import useTranslation from '../../../localization/useTranslation'
import {
  SliderWrapper,
  TitleWrapper,
  SliderTitle,
  CurrentValueLabel,
  SliderTipLabel,
} from '../styledWrappers/audienceSubSearchStyles'
import WithTip from '../popups/WithTip'

const AudienceSubSearchSingleRangeSlider = ({
  faqQuestionId = '',
  value = null,
  onChange,
  disabledTip = '',
  isDisabled = false,
  title = '',
  sliderRanges,
  formatCurrentValueTip = null,
  isMirrorSlider = false,
  zeroValue = null,
  leftSubLabel = '',
  rightSubLabel = '',
}) => {
  const { labelStrings } = useTranslation()
  /**
   * SLIDER_KEY is used for cases when url query contains growthRate when first load(when link shared)
   * in this case first incomming value is null before query url is processed and value is extracted
   * after that we just reinit slider with new value
   */
  const SLIDER_KEY = value === null ? 'beforequeryvalue' : 'withqueryvalue'

  const onNewValueSelect = newValue => {
    if (newValue !== value) {
      onChange(newValue)
    }
  }

  const startSliderValue = value === null ? 0 : value
  const showCurrentValueLabel = value !== null
  const isZeroValue = zeroValue !== null

  const preparedSliderRanges = useMemo(() => {
    if (isMirrorSlider) {
      const { beforeZero, afterZero } = sliderRanges
      return {
        ...beforeZero,
        [labelStrings.off]: isZeroValue ? zeroValue : 0, // zero value, like a "turn off",
        ...afterZero,
      }
    }
    return {
      [labelStrings.off]: isZeroValue ? zeroValue : 0, // zero value, like a "turn off"
      ...sliderRanges,
    }
  }, [])

  const Slider = (
    <div>
      <SliderWrapper isDeactivated={isDisabled}>
        {showCurrentValueLabel && (
          <CurrentValueLabel>
            {formatCurrentValueTip ? formatCurrentValueTip(value) : `${value}%`}
          </CurrentValueLabel>
        )}

        <TitleWrapper>
          <SliderTitle>{title}</SliderTitle>
          <LinkToFaqQuestion
            faqQuestionId={faqQuestionId}
            linkPositions={{ top: 8, right: -28 }}
            tipIconSize={14}
          />
        </TitleWrapper>

        <CommonSingleRangeSlider
          key={SLIDER_KEY}
          initValue={startSliderValue}
          sliderRanges={preparedSliderRanges}
          onRangeChange={onNewValueSelect}
          isMirrorSlider={isMirrorSlider}
        />
        {leftSubLabel && <SliderTipLabel leftPosition={-3}>{leftSubLabel}</SliderTipLabel>}
        {rightSubLabel && <SliderTipLabel leftPosition={90}>{rightSubLabel}</SliderTipLabel>}
      </SliderWrapper>
    </div>
  )

  return isDisabled ? (
    <WithTip tipText={disabledTip} position="top center" offsetY={-15}>
      {Slider}
    </WithTip>
  ) : (
    Slider
  )
}

AudienceSubSearchSingleRangeSlider.propTypes = {
  faqQuestionId: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(null)]),
  onChange: PropTypes.func,
  userindex: PropTypes.string,
  isDisabled: PropTypes.bool,
  disabledTip: PropTypes.string,
  title: PropTypes.string,
  sliderRanges: PropTypes.object.isRequired,
  formatCurrentValueTip: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(null)]),
  isMirrorSlider: PropTypes.bool,
  leftSubLabel: PropTypes.string,
  rightSubLabel: PropTypes.string,
  zeroValue: PropTypes.any,
}

export default AudienceSubSearchSingleRangeSlider
