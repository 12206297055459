import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, IconButton, InputAdornment, Stack } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import { CreatorsList } from './CreatorsList'
import { StyledCreatorsSuggestedInput } from './styles'
import useTranslation from '../../../../../localization/useTranslation'
import useOutsideClick from '../../../../../hooks/useOutsideClick'

export const SuggestedCreatorsInput = props => {
  const {
    creatorsData = [],
    notFoundData = false,
    debouncedInputValue = '',
    setInputValue,
    setCreatorsData,
    setLoadingCreators,
    setNotFoundData,
    inputValue = '',
    currentPlatform = '',
    loadingCreators = false,
    error = false,
    isAllowedToAddCreators = false,
    creatorIdFromState = '',
    setCreatorIdFromState,
    isSetCreatorId = true,
    isDisabledInput = false,
  } = props || {}

  const { labelStrings } = useTranslation()

  const inputRef = useRef(null)
  const containerRef = useRef(null)

  const [showListCreators, setShowListCreators] = useState(true)

  useOutsideClick({ ref: containerRef, callback: () => setShowListCreators(false) })

  const toInputFocus = () => {
    inputRef?.current?.focus()
  }

  const onFocusInput = () => {
    if (!showListCreators) {
      setShowListCreators(true)
    }
  }

  const onChangeInputValue = e => {
    const value = e.target.value
    setInputValue(value)
  }

  const onClearInput = () => {
    setInputValue('')
    setCreatorsData([])
    setLoadingCreators(false)
    setNotFoundData(false)
  }

  return (
    <Stack ref={containerRef} sx={{ width: '100%', height: '100%' }}>
      <StyledCreatorsSuggestedInput
        autoFocus
        inputRef={inputRef}
        label={'Username'}
        variant="outlined"
        value={inputValue}
        onChange={onChangeInputValue}
        placeholder={labelStrings.typeUsername}
        onFocus={onFocusInput}
        disabled={isDisabledInput}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {inputValue ? (
                <IconButton onClick={onClearInput}>
                  <ClearIcon />
                </IconButton>
              ) : (
                <IconButton onClick={toInputFocus}>
                  <SearchIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <Box sx={{ position: 'relative', height: 'auto' }}>
        <CreatorsList
          creators={creatorsData}
          notFoundData={notFoundData}
          loadingCreators={loadingCreators}
          currentPlatform={currentPlatform}
          creatorsName={debouncedInputValue}
          showList={showListCreators}
          error={error}
          isAllowedToAddCreators={isAllowedToAddCreators}
          creatorIdFromState={creatorIdFromState}
          setCreatorIdFromState={setCreatorIdFromState}
          onClearInput={onClearInput}
          isSetCreatorId={isSetCreatorId}
          isDisabledInput={isDisabledInput}
        />
      </Box>
    </Stack>
  )
}

SuggestedCreatorsInput.propTypes = {
  creatorsData: PropTypes.array,
  notFoundData: PropTypes.bool,
  debouncedInputValue: PropTypes.string,
  setInputValue: PropTypes.func,
  setCreatorsData: PropTypes.func,
  setLoadingCreators: PropTypes.func,
  setNotFoundData: PropTypes.func,
  inputValue: PropTypes.string,
  currentPlatform: PropTypes.string,
  loadingCreators: PropTypes.bool,
  error: PropTypes.bool,
  isAllowedToAddCreators: PropTypes.bool,
  creatorIdFromState: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  setCreatorIdFromState: PropTypes.func,
  isSetCreatorId: PropTypes.bool,
  isDisabledInput: PropTypes.bool,
}