import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../localization/useTranslation'
import { currentOpenedCampaignSelector } from '../../../redux/selectors'
import { ModalComponent } from '../../components/modal/ModalComponent'
import { Stack, TextField, Typography, Button } from '@mui/material'
import { editCustomFields } from '../../../redux/ducks/campaignsDucks'

const CONTENT_MODAL_WIDTH = '640px'
const httpsRegex = /^https:\/\/.+$/

export const BitLyModal = props => {
  const { setOpenModal, isOpenModal, editData, setEditField } = props

  const dispatch = useDispatch()
  const { labelStrings } = useTranslation()
  const { campaignId } = useSelector(currentOpenedCampaignSelector)

  const [forwardUrlValue, setForwardUrlValue] = useState('')
  const [isFocusedForwardUrl, setIsFocusedForwardUrl] = useState(false)
  const [errorForwardUrlValue, setErrorForwardUrlValue] = useState(false)

  const [linkNameValue, setLinkNameValue] = useState('')

  const isEditData = editData?.forwardedUrl || editData?.customFieldName

  const onChangeForwardUrlValue = ({ target }) => {
    const value = target.value

    setForwardUrlValue(target.value)
    setErrorForwardUrlValue(!httpsRegex.test(value))
  }

  const onChangeLinkValue = ({ target }) => {
    setLinkNameValue(target.value)
  }

  const handleClose = () => {
    setOpenModal(false)
    setForwardUrlValue('')
    setLinkNameValue('')
    setEditField({})
  }

  const onCreate = () => {
    dispatch(
      // @ts-ignore
      editCustomFields({
        campaignId,
        method: isEditData ? 'change' : 'add',
        applyLevel: 'creator',
        dataFormat: 'integer',
        metricName: 'Clicks',
        trackingMethod: 'bitly',
        applyContentArray: [
          'ig_posts',
          'ig_stories',
          'ig_reels',
          'tt_videos',
          'yt_shorts',
          'yt_videos',
        ],
        forwardedUrl: forwardUrlValue,
        customFieldName: linkNameValue,
      })
    )
    handleClose()
  }

  const onEdit = () => {
    dispatch(
      // @ts-ignore
      editCustomFields({
        campaignId,
        method: 'change',
        forwardedUrl: forwardUrlValue,
        customFieldName: linkNameValue,
        customFieldId: editData?.customFieldId,
        applyLevel: 'creator',
        dataFormat: 'integer',
        metricName: 'Clicks',
        trackingMethod: 'bitly',
        applyContentArray: [
          'ig_posts',
          'ig_stories',
          'ig_reels',
          'tt_videos',
          'yt_shorts',
          'yt_videos',
        ],
      })
    )
    handleClose()
  }

  useEffect(() => {
    if (isEditData) {
      setForwardUrlValue(editData?.forwardedUrl)
      setLinkNameValue(editData?.customFieldName)
    }
  }, [editData])

  return (
    <ModalComponent
      isOpenModal={isOpenModal}
      closeModal={handleClose}
      title={isEditData ? labelStrings.editBitlyField : labelStrings.bitLyFieldModal.createNewField}
      contentWidth={CONTENT_MODAL_WIDTH}
      contentHeight="auto"
      isCenterTitle
    >
      <Stack>
        <Stack spacing={1} alignItems="left">
          <Typography textAlign="left" sx={{ pb: 2}}>{labelStrings.bitLyFieldModal.step1}</Typography>
          <Typography textAlign="left" sx={{ pb: 2, fontWeight: 'bold' }}>{labelStrings.bitLyFieldModal.step2}</Typography>
          <Typography textAlign="left" sx={{ fontSize: '90%' }}>{labelStrings.bitLyFieldModal.step3}</Typography>
          <Typography textAlign="left" sx={{ fontSize: '90%' }}>{labelStrings.bitLyFieldModal.step4}</Typography>
          <Typography textAlign="left" sx={{ fontSize: '90%' }}>{labelStrings.bitLyFieldModal.step5}</Typography>
          <Typography textAlign="left" sx={{ fontSize: '90%', pb: 2 }}>{labelStrings.bitLyFieldModal.step6}</Typography>
        </Stack>

        <Stack sx={{ mt: 2 }} spacing={2}>
          <TextField
            label={labelStrings.bitLyFieldModal.yourForwardUrl}
            placeholder={labelStrings.bitLyFieldModal.yourForwardUrlExtended}
            sx={{
              '& .MuiInputBase-input': {
                color: '#637381',
              },
            }}
            onFocus={() => setIsFocusedForwardUrl(true)}
            onBlur={() => setIsFocusedForwardUrl(false)}
            onChange={onChangeForwardUrlValue}
            value={forwardUrlValue}
            helperText={
              errorForwardUrlValue && isFocusedForwardUrl && !!forwardUrlValue?.length
                ? labelStrings.bitLyFieldModal.enterValidUrl
                : ''
            }
            error={errorForwardUrlValue && isFocusedForwardUrl && !!forwardUrlValue?.length}
          />
          <TextField
            label={labelStrings.bitLyFieldModal.yourLinkName}
            placeholder={labelStrings.bitLyFieldModal.yourLinkName}
            sx={{
              '& .MuiInputBase-input': {
                color: '#637381',
              },
              '& .MuiFormHelperText-root': {
                marginTop: '0 !important',
              },
            }}
            onChange={onChangeLinkValue}
            value={linkNameValue}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="center">
          <Button
            disabled={errorForwardUrlValue || !forwardUrlValue?.length}
            onClick={() => (isEditData ? onEdit() : onCreate())}
            sx={{ my: 3, px: 4, width: 'fit-content' }}
            variant="contained"
          >
            {isEditData ? labelStrings.edit : labelStrings.create}
          </Button>
        </Stack>
      </Stack>
    </ModalComponent>
  )
}
