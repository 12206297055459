import React from 'react'
import { ModalComponent } from '../../../../new-ui/components/modal/ModalComponent'
import { Button, Stack, Typography } from '@mui/material'
import useTranslation from '../../../../localization/useTranslation'

export const CollectionInitialOverlapModal = props => {
  const { isOpenModal, closeModal } = props

  const { labelStrings } = useTranslation()

  return (
    <ModalComponent
      isOpenModal={isOpenModal}
      closeModal={closeModal}
      title={null}
      contentWidth="500px"
      contentHeight="auto"
    >
      <Stack spacing={2} sx={{ py: 3 }}>
        <Typography textAlign="center" sx={{ whiteSpace: 'pre-line' }}>
          {labelStrings.collectionOverlap.modalOverlapContentText}
        </Typography>
        <Stack direction="row" justifyContent="center">
          <Button sx={{ width: 'fit-content' }} onClick={closeModal} variant="contained">
            {labelStrings.confirm}
          </Button>
        </Stack>
      </Stack>
    </ModalComponent>
  )
}
