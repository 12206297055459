import React from 'react'
import PropTypes from 'prop-types'

import { themeCommonStyles } from '../../../../themes/infludataThemes'

const DraggableUser = ({ username = '', draggableCords = {} }) => {
  const { clientX, clientY } = draggableCords || {}
  const showDraggable = clientX !== undefined && clientY !== undefined && username

  return (
    <tr
      style={{
        margin: 0,
        background: themeCommonStyles.color.white,
        boxShadow: '0 0 0 1px #e0e0e0',
        transition: 'opacity 0.02s linear 0.2s',
        zIndex: 13000,
        position: 'fixed',
        top: clientY,
        left: clientX,
        padding: '2px 10px 4px 10px',
        borderRadius: '8px',
        fontFamilyInter: themeCommonStyles.fontFamilyInter,
        fontSize: '16px',
        fontWeight: 700,
        color: themeCommonStyles.color.mineShaft,
        opacity: '0.5',
        ...(!showDraggable && {
          opacity: '0',
          height: 0,
          overflow: 'hidden',
        }),
      }}
    >
      <td>{username}</td>
    </tr>
  )
}

DraggableUser.propTypes = {
  draggableCords: PropTypes.object,
  username: PropTypes.string,
}

export default DraggableUser
