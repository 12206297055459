import * as Yup from 'yup'

export const goalSchema = Yup.object().shape({
  goalName: Yup.string().required('Goal Name is Required'),
  goalLevel: Yup.string().required('Goal Level is Required'),
  isPaused: Yup.mixed().transform(v => v ? v : false),
  // isPaused: Yup.mixed()
  //   .required('Goal Status is Required')
  //   .test('type', 'Goal Status is Required', value => {
  //     return typeof (value === 'boolean' || typeof value === 'string') && value !== ''
  //   }),
  goalInterval: Yup.string().required('Goal Time Period is Required'),
  isCreatorIndividual: Yup.mixed().transform(v => (v ? v : false)),
  // isCreatorIndividual: Yup.mixed()
  //   .required('Custom goal for each influencer is Required')
  //   .test('type', 'Custom goal for each influencer is Required', value => {
  //     return typeof (value === 'boolean' || typeof value === 'string') && value !== ''
  //   }),
  goalAmount: Yup.mixed()
    .required('Goal Reached is Required')
    .test('type', 'Goal Reached is Required', value => {
      return typeof (value === 'number' || typeof value === 'string') && value !== 0 && value !== ''
    }),
  connectedCustomField: Yup.string().required('Select a Goal is Required'),
})
