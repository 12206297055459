import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import useTranslation from '../../localization/useTranslation'
import { SignButton } from '../common/buttons/styledButtons'
// import UserFeedbacksContainer from '../feedbacks/UserFeedbacksContainer'
import ProfileNavContainer from './profile/ProfileNavContainer'
import { LoginContainerWrapper, LoginItemsDivider } from '../common/styledWrappers/navbarStyles'
import { INFLUDATA_HOSTS } from '../../constants/appSettings'

const FAQLink = styled.a`
  font-family: ${props => props.theme.fontFamilyInter};
  font-size: 13px;
  font-weight: normal;
  line-height: 17px;
  margin: 0 15px;
  color: ${props => props.theme.color.white};
  background: none;

  &:hover {
    text-decoration: underline;
    color: ${props => props.theme.color.white};;
  }
`

const NavBarUserRelated = ({ onLoginClick, isUserAuthorized = false }) => {
  const { labelStrings } = useTranslation()

  return (
    <LoginContainerWrapper>
      {/* <UserFeedbacksContainer isUserAuthorized={isUserAuthorized} /> */}
      <FAQLink href={INFLUDATA_HOSTS.faq} target="__blank">FAQs</FAQLink>
      {!isUserAuthorized && (
        <>
          <LoginItemsDivider />
          <SignButton onClick={onLoginClick}>{labelStrings.login}</SignButton>
        </>
      )}
      <LoginItemsDivider isInvisible={!isUserAuthorized} />
      <ProfileNavContainer isUserAuthorized={isUserAuthorized} />
    </LoginContainerWrapper>
  )
}

NavBarUserRelated.propTypes = {
  onLoginClick: PropTypes.func,
  isUserAuthorized: PropTypes.bool,
}

export default NavBarUserRelated
