import styled from 'styled-components'

export const ToggleSwitchContainer = styled.div`
  ${props => props.isDisabled && props.theme.disabled }
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${props => props.theme.colors.offWhite};
  border: 0.5px solid ${props => props.theme.colors.mauve};
  border-radius: 13px;
  padding: 13px;
  max-width: 304px;
  margin-left: 15px;
  margin-top: 13px;
  height: 51px;
`

export const ToggleSwitchLabel = styled.div`
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  ${props =>
    props.isActive
      ? props.theme.getTextGradientStyles()
      : `color: ${props.theme.colors.lightMauve}`};
  span {
    padding: 0 7px;
  }
`

export const SwitchStyled = styled.div`
  position: relative;
  ${'' /* margin-right: 10px;
  margin-left: 10px; */}
  width: 66px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  .toggle-switch-checkbox-styled {
    display: none;
  }
  .toggle-switch-label-styled {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #eed9d9;
    border-radius: 19px;
    margin: 0;
    &:focus {
      outline: none;
      > span {
        box-shadow: 0 0 2px 5px red;
      }
    }
    > span:focus {
      outline: none;
    }
  }
  .toggle-switch-inner-styled {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 30px;
      padding: 0;
      line-height: 30px;
      font-size: 14px;
      color: #fff;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: attr(data-yes);
      text-transform: uppercase;
      padding-left: 10px;
      background: linear-gradient(90deg, #ffbc00 0.35%, #ff5d5a 100%);
      color: #fff;
    }
  }
  .toggle-switch-disabled-styled {
    background-color: #ddd;
    cursor: not-allowed;
    &:before {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }
  .toggle-switch-inner-styled:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background: linear-gradient(90deg, #ffbc00 0.35%, #ff5d5a 100%);
    color: #fff;
    text-align: right;
  }
  .toggle-switch-switch-styled {
    display: block;
    width: 22px;
    margin: 4px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 34.5px;
    border: 0 solid #eed9d9;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  .toggle-switch-checkbox-styled:checked + .toggle-switch-label-styled {
    .toggle-switch-inner-styled {
      margin-left: 0;
    }
    .toggle-switch-switch-styled {
      right: -0.5px;
    }
  }
  &.small-switch-styled {
    width: 33px;
    .toggle-switch-inner-styled {
      &:after,
      &:before {
        content: '';
        height: 17px;
        line-height: 17px;
        background: #ff5d5a;
      }
    }
    .toggle-switch-switch-styled {
      width: 12px;
      right: 15px;
      margin: 3px;
    }
  }
  @media screen and (max-width: 991px) {
    transform: scale(0.9);
  }
  @media screen and (max-width: 767px) {
    transform: scale(0.825);
  }
  @media screen and (max-width: 575px) {
    transform: scale(0.75);
  }
`
