import React from 'react'
import { ModalComponent } from '../../../../new-ui/components/modal/ModalComponent'
import { Button, Stack, Typography } from '@mui/material'
import useTranslation from '../../../../localization/useTranslation'

export const ConfirmWhenUnlockedUsersModal = props => {
  const { isOpenModal, closeModal, notUnlockedUsers } = props

  const { labelStrings, currentLang } = useTranslation()
  const handleConfirm = () => {
    closeModal();
    props.onResolve();
  };

  const handleCancel = () => {
    closeModal();
    props.onReject();
  };

  return (
    <ModalComponent
      isOpenModal={isOpenModal}
      closeModal={closeModal}
      title={null}
      smallPadding
      contentWidth="500px"
      contentHeight={currentLang === 'de' ? '210px' : '190px'}
    >
      <Stack spacing={3}>
        <Typography sx={{ whiteSpace: 'pre-line' }}>
          {labelStrings.collectionOverlap.modalOverlapCofirmContentText(notUnlockedUsers)}
        </Typography>
        <Stack direction="row" justifyContent="center" spacing={2}>
          <Button sx={{ width: 'fit-content' }} onClick={handleCancel} variant="outlined">
            {labelStrings.cancel}
          </Button>
          <Button sx={{ width: 'fit-content' }} onClick={handleConfirm} variant="contained">
            {labelStrings.confirm}
          </Button>
        </Stack>
      </Stack>
    </ModalComponent>
  )
}
