export const AUDIENCE_AVAILABLE_STATUS = {
  lockedInstant: 'locked_instant',
  lockedDelayed: 'locked_delayed',
  unlockedAvailable: 'unlocked_available',
  unlockedRequested: 'unlocked_requested',
  reportImpossible: 'report_impossible',
  reportBlocked: 'report_blocked'
}

export const AUDIENCE_ANALYSIS_SECTIONS_NAMES = {
  country: 'countryArray',
  city: 'cityArray',
  lang: 'languageArray',
  gender: 'genderMtoF',
  age: 'averageBirthyearArray',
  mentions: 'mentions',
  audienceType: 'audienceType',
  sentimentAnalysis: 'sentimentAnalysis',
  genderObject: 'genderObject'
}
