import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DayPicker from 'react-day-picker'

import CalendarNavbar from './CalendarNavbar'

import { DayPickerGlobalStyles, DayPickerWithNavbarGlobalStyles } from './styles'
import 'react-day-picker/lib/style.css'

const Calendar = ({ dateToHighlight = null, canSwitchMonthYear = true, changeDate }) => {
  const [localDate, setLocalDate] = useState(dateToHighlight || new Date())

  let modifiers = null
  if (dateToHighlight) {
    modifiers = {
      highlighted: dateToHighlight,
    }
  }

  const onDayClickHandler = (day, modifiers, event) => {
    changeDate(day)
  }

  const navbarElement = canSwitchMonthYear ? (
    <CalendarNavbar
      currentDate={localDate}
      changeDate={setLocalDate}
    />
  ) : (
    <></>
  )

  return (
    <>
      <DayPickerGlobalStyles />
      {canSwitchMonthYear && <DayPickerWithNavbarGlobalStyles />}

      <DayPicker
        modifiers={modifiers}
        month={localDate}
        fromMonth={localDate}
        toMonth={localDate}
        onDayClick={onDayClickHandler}
        navbarElement={navbarElement}
        firstDayOfWeek={1}
        showOutsideDays
      />
    </>
  )
}

Calendar.propTypes = {
  dateToHighlight: PropTypes.instanceOf(Date),
  canSwitchMonthYear: PropTypes.bool,
  changeDate: PropTypes.func,
}

export default Calendar
