import styled from 'styled-components'

export const InfoTipOverlay = styled.div`
  position: absolute;
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  width: 220px;
  padding: 20px;
  font-family: ${props => props.theme.fontFamily.inter};
  font-size: 13px;
  line-height: 16px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.darkBrown};
  border-radius: 15px;
  cursor: default;
  z-index: 999;

  &:after {
    content: '';
    position: absolute;
    top: calc(100% - 5px);
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 10px;
    height: 10px;
    background-color: ${props => props.theme.colors.darkBrown};
  }
`

export const InfoTipContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  position: relative;
  cursor: pointer;

  &:hover {
    ${InfoTipOverlay} {
      visibility: visible;
    }
  }
`
