import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { OptionsButton } from './styledButtons'
import useTranslation from '../../../localization/useTranslation'

const Button = styled.button`
  border: none;
  background: none;
  margin: 10px auto;
  color: ${props => props.theme.pickColorDependOnPlatform(props.userindex).primary};
  text-decoration: underline;
  font-weight: normal;
  cursor: pointer;
  font-size: 15px;
  font-family: ${props => props.theme.fontFamilyInter};
  &:hover {
    opacity: 0.6;
  }
`

const CancelPrimaryButton = ({ onClick, userindex = '', isActive = false, isDisabled = false }) => {
  const { labelStrings } = useTranslation()

  return (
    <Button
      noFocusEffect
      onClick={onClick}
      userindex={userindex}
      isActive={isActive}
      isDisabled={isDisabled}
    >
      {labelStrings.cancel}
    </Button>
  )
}

CancelPrimaryButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  userindex: PropTypes.string,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

export default CancelPrimaryButton
