import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import GradientTransparentButton from '../../common/buttons/GradientTransparentButton'
import useTranslation from '../../../localization/useTranslation'
import { INITIAL_LIST_VIEW_COLLECTIONS_PROPERTIES } from '../../../constants/collections'
import {
  AdditionalFieldDetailsOverlay,
  MainOverlay,
  SwitchOverlayText,
  SwitchScrollContainer,
  SwitchWrapper,
} from './style'
import useOutsideClick from '../../../hooks/useOutsideClick'
import ToggleSwitch from '../../toggleSwitch'

const CollectionEditFields = props => {
  const { onChangeHiddenFields, hiddenFields = [], setCurrFieldsState, additionalFields = [] } = props || {}
  const { labelStrings, currentLang } = useTranslation()

  const refOverlay = useRef(null)

  const togglesAdditionalFieldsProperties = additionalFields?.map(field => {
    return {
      show: !field.isHidden,
      width: 150,
      key: field.code,
      label: field.label,
      edit: true,
    }
  })

  const slicedDataArr = [...INITIAL_LIST_VIEW_COLLECTIONS_PROPERTIES, ...togglesAdditionalFieldsProperties]

  const initialToggleState = useMemo(() => slicedDataArr?.reduce(
    (obj, item) => ((obj[item?.key] = item), obj),
    {}
  ), [slicedDataArr])

  const [toggleState, setToggleState] = useState(initialToggleState)
  const [isOverlayOpened, setOverlayOpened] = useState(false)

  useOutsideClick({ ref: refOverlay, callback: () => setOverlayOpened(false) })

  useEffect(() => {
    !!hiddenFields?.length && hiddenFields.forEach(key => {
      setToggleState(prev => {
        return {
          ...prev,
          [key]: {
            ...prev[key],
            show: false,
          },
        }
      })
    })
  }, [hiddenFields])

  useEffect(() => {
    setCurrFieldsState(toggleState)
  }, [toggleState])

  const onToggleChange = key => {
    const changedState = {
      ...toggleState,
      [key]: {
        ...toggleState[key],
        show: !toggleState[key]?.show,
      },
    }

    setToggleState(changedState)

    const hiddenFields = Object.values(changedState)
      ?.map(field => !field?.show && field?.key)
      ?.filter(Boolean)
    onChangeHiddenFields({
      hiddenArrFields: hiddenFields,
      viewMode: null,
      isUpdateCollection: false,
    })
  }

  const generateSwitchToggles = useMemo(() => {
    return slicedDataArr?.map(el => {
      const toggleKey = el?.key
      const toggleChecked = toggleState[toggleKey]?.show
      const toggleLabel = labelStrings?.tableHeadLabels[el?.key] || el.label

      if (!el.edit) return

      return (
        <SwitchWrapper key={toggleKey}>
          <SwitchOverlayText>{toggleLabel}</SwitchOverlayText>
          <ToggleSwitch
            id={`toggle-${toggleKey}`}
            checked={toggleChecked}
            onChange={() => onToggleChange(toggleKey)}
            size='medium-switch'
          />
        </SwitchWrapper>
      )
    })
  }, [toggleState, onToggleChange, currentLang, slicedDataArr])

  return (
    <MainOverlay ref={refOverlay}>
      <GradientTransparentButton onClick={() => setOverlayOpened(!isOverlayOpened)}>
        {labelStrings?.editFields}
      </GradientTransparentButton>

      {isOverlayOpened && (
        <AdditionalFieldDetailsOverlay>
          <SwitchScrollContainer>{generateSwitchToggles}</SwitchScrollContainer>
        </AdditionalFieldDetailsOverlay>
      )}
    </MainOverlay>
  )
}

CollectionEditFields.propTypes = {
  onChangeHiddenFields: PropTypes.func,
  hiddenFields: PropTypes.array,
  setCurrFieldsState: PropTypes.func,
  additionalFields: PropTypes.array
}

export default CollectionEditFields
