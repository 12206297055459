import React from 'react'
import { Grid, useTheme, Chip, Typography, Box } from '@mui/material'
import { PublishedContentCarousel } from '../../components/carousel/published-content'

export const CarouselContent = ({ mockedContentData, openModal, setEditItem }) => {
  const theme = useTheme()

  const carouselSettings = {
    dots: false,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    rows: 2,
    className: 'padding',
    // slidesPerRow: 1,
    speed: 500,
  }

  const ChipTitle = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography sx={{ fontWeight: 700, fontSize: '18px', lineHeight: '28px' }}>
        Open for Enrichment
      </Typography>
      <Chip
        sx={{
          backgroundColor: '#F9F213',
          color: '#212B36',
          fontWeight: 700,
          fontSize: '12px',
          lineHeight: '20px',
          marginLeft: '10px',
          borderRadius: '6px'
        }}
        label="3 Open Tasks"
        size='small'
      />
    </Box>
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <PublishedContentCarousel
          title={ChipTitle}
          subheader="In order to have full data for your report, you need to add missing information. If you don’t want to track certain information manually, you need to change your settings, deactivate goals, edit your custom fields or add webhooks for automated tracking."
          list={mockedContentData}
          carouselSettings={carouselSettings}
          openModal={openModal}
          setEditItem={setEditItem}
          isNotWidget
          isDataEnrichment
        />
      </Grid>
    </Grid>
  )
}
