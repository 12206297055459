import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../../../localization/useTranslation'
import { USER_INDEXES } from '../../../../../constants/appSettings'
import { IG_SCORE_NAMES } from '../../../../../constants/profile'
import SponsoredMediaScore from './SponsoredMediaScore'
import { SponsoredMediaScoresContainer } from './styles'
import { Stack } from '@mui/material'

const SponsoredMediaScores = ({ profileScores, isForPdf = false }) => {
  const { labelStrings, scoreStrings } = useTranslation()
  const igScoreStrings = scoreStrings[USER_INDEXES.instagram]
  const brandValueStrings = igScoreStrings[IG_SCORE_NAMES.sponsoredPosts]

  const { sponsoredPostObject, sponsoredStoryObject, sponsoredReelObject } = profileScores || {}

  const scoresArr = [
    {
      name: brandValueStrings.sponsoredPosts,
      data: sponsoredPostObject,
      label: labelStrings.ofAllPostsAreSponsored,
    },
    {
      name: brandValueStrings.sponsoredStories,
      data: sponsoredStoryObject,
      label: labelStrings.ofAllStoriesAreSponsored,
    },
    {
      name: brandValueStrings.sponsoredReels,
      data: sponsoredReelObject,
      label: labelStrings.ofAllReelsAreSponsored,
    },
  ]

  return (
    <SponsoredMediaScoresContainer isForPdf={isForPdf}>
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        sx={{
          width: '100%',
          maxWidth: isForPdf ? '690px' : '100%',
          flexWrap: isForPdf ? 'wrap' : 'nowrap',
        }}
      >
        {scoresArr.map((scoreItem, index) => {
          if (!scoreItem.data && scoreItem.name !== brandValueStrings.sponsoredPosts) {
            return null
          }
          return (
            <SponsoredMediaScore
              key={index}
              scoreName={scoreItem.name}
              scoreData={scoreItem.data}
              scoreLabel={scoreItem.label}
              isForPdf={isForPdf}
            />
          )
        })}
      </Stack>
    </SponsoredMediaScoresContainer>
  )
}

SponsoredMediaScores.propTypes = {
  profileScores: PropTypes.object.isRequired,
  isForPdf: PropTypes.bool,
}

export default SponsoredMediaScores
