import React from 'react'
import Cookies from 'js-cookie'
import { withRouter } from "react-router-dom";

const GrantsContext = React.createContext({});

function getCookie(cookieName) {
  let whoAmICookie = Cookies.get(cookieName) || "{}";
  whoAmICookie = whoAmICookie && JSON.parse(whoAmICookie);
  return whoAmICookie;
}

async function refreshGrants(cookieName, [state, setState], refresh = false) {
  let grantsResp = await fetch(refresh ? '/api/profile/grants?refresh' : '/api/profile/grants', {
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' }
  })
  if (!grantsResp.ok) {
    console.error(grantsResp)
    throw new Error("Could not fetch grants")
  } else {
    let grants = await grantsResp.json();
    let whoAmICookie = getCookie(cookieName);

    let newState = Object.assign({}, state, whoAmICookie, {
      roles: grants.roles || [],
      grants: grants.grants || [],
      props: grants.props || {},
      isValid: (state.id == whoAmICookie.id),
      refresh: function () { refreshGrants(cookieName, [state, setState], true) }
    })

    setState(newState)
  }
}

function SessionGuard({ cookieName, loginPath, ignorePaths, children }) {
  if (cookieName == null || cookieName == '') {
    console.error('cookieName must be provided!')
    throw new Error('cookieName must be provided!')
  }

  let whoAmICookie = getCookie(cookieName);

  const initialState = Object.assign({}, whoAmICookie,
    {
      grants: [],
      roles: [],
      isValid: true
    })

  const [state, setState] = React.useState(initialState)

  React.useEffect(() => {
    refreshGrants(cookieName, [state, setState])
  }, [])

  console.log("whoami:", cookieName, whoAmICookie, state, ignorePaths);

  if (state.isValid &&
    (window.location.pathname == loginPath
      || ignorePaths.reduce((res, path) => {
        return res || window.location.pathname.startsWith(path)
      }, false)
      || whoAmICookie.id)) {
    return <React.Fragment>
      <GrantsContext.Provider value={state}>
        {React.Children.toArray(children)}
      </GrantsContext.Provider>
    </React.Fragment>
  } else {
    console.log('auth needed, redirecting to login...');
    window.location.href = loginPath
    return null
  }
}

export { GrantsContext, SessionGuard };
export default withRouter(SessionGuard);
