import styled from 'styled-components'
import { RoundedInput, Label } from '../common/styledWrappers'

const USER_ITEM_HEIGHT = 45
const MAX_LIST_HEIGHT = 270

export const SuggestedLocationsListWrapper = styled.div`
  ${props => props.isVisible && `position: absolute;`}
  background: ${props => props.theme.colors.lightMauve};
  display: flex;
  flex-direction: column;
  width: 99%;
  overflow-y: ${props => (props.isVisible && props.locationsFound ? 'scroll' : 'hidden')};
  overflow-x: hidden;
  left: 0;
  z-index: 100;
  max-height: ${props => (props.isVisible ? MAX_LIST_HEIGHT : 0)}px;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: #e0e0e0;
    border-color: ${props => props.theme.pickColorDependOnPlatform(props.userindex).primary};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.pickColorDependOnPlatform(props.userindex).primary};
  }
  scrollbar-width: thin;
  border-radius: 0 0 11px 11px;
`

export const LocationInfoWrapper = styled.div`
  color: ${props => props.theme.colors.darkBrown};
  border-bottom: 1px solid ${props => props.theme.color.lightGrey};
  min-height: 35px;
  padding-left: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: ${USER_ITEM_HEIGHT}px;
  &:focus,
  &:active {
    outline: none;
  }
  ${props =>
    props.disabledSearch &&
    `justify-content: center; padding-left: 15px; height: ${
      props.currLang === 'de' ? '135px' : '110px'
    }; cursor: default;`}
  ${props => !props.notFound && `justify-content: center; padding-left: 15px; cursor: default;`}
  ${props =>
    props.enableHover &&
    `
    &:hover {
      background: ${props.theme.pickColorDependOnPlatform(props.platform).primary};
      color: ${props.theme.color.white};
    }
  `}
`
export const LocationNameWrapper = styled.span`
  font-size: 14px;
  font-family: ${props => props.theme.fontFamilyInter};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const LocationsSearchWrapper = styled.div`
  ${'' /* ${props => props.theme.flex.centerAll} */}
  height: 35px;
  width: 100%;
  ${'' /* min-width: 319px; */}
  max-width: 285px;
  border-radius: 11px;
  background: transparent linear-gradient(111deg, #ffffff 0%, #ffffff 100%) 0% 0% no-repeat
    padding-box;
  padding-left: 5px;
  border: 1px solid #784e4e;
  border-radius: 8px;
`
export const SuggestLocationsWrapper = styled.div`
  ${'' /* width: 290px; */}
  height: 100%;
  display: flex;
  align-items: center;
  ${'' /* @media (max-width: 1300px) {
    width: 250px;
  } */}
`

export const InputWrapper = styled.div`
  display: flex;
  ${props => props.withParentHeight && 'height: 100%'};
`

export const SuggestInput = styled(RoundedInput)`
  height: ${props => (props.withParentHeight ? 'inherit' : '38px')};
  &:focus {
    border-color: ${props => props.theme.pickColorDependOnPlatform(props.userindex).primary};
    box-shadow: 0 0 0 1px ${props => props.theme.pickColorDependOnPlatform(props.userindex).primary};
  }
  ${props =>
      props.isAnalyzeSuggest &&
      `
    width: 90%;
    border: none;
    border-radius: unset;
    background: transparent;
    padding-left: 5px;
    font-weight: 500;
    font-family: ${props.theme.fontFamilyInter};
    font-size: 14px;
    color: ${props.theme.colors.darkBrown};
    &:focus, &:active {
      border: none;
      box-shadow: unset;
    }
    &::placeholder {
      color: ${props.theme.colors.mauve};
      text-align: center;
    }
  `}
    ::placeholder {
    color: ${props => props.theme.colors.darkBrown};
    margin: 0;
    padding: 0;
    text-align: left;
  }
`

export const SuggestLabel = styled(Label)`
  ${'' /* margin: 0 0 5px 0;
  padding-left: 7px; */}
`

export const ClearInputWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 8%;

  ${props => props.withParentHeight && 'top: 18%;'}
  ${props =>
    props.isAnalyzeSuggest &&
    `
    position: inherit;
    display: flex;
    align-items: center;
  `}
  height: inherit;
  width: 10px;
  font-size: 20px;
  color: ${props => props.theme.colors.darkBrown};
  font-family: ${props => props.theme.fontFamilyInter};
  ${props =>
    props.isCampaignsSuggest &&
    `
      top: -4px;
    `}
  &::before {
    display: block;
    position: absolute;
    content: '+';
    transform: rotateZ(45deg);
    cursor: pointer;
    font-size: 32px;
    right: ${props => (props.isAnalyzeSuggest ? 0 : '-15px')};
    ${props =>
      props.isCampaignsSuggest &&
      `
      right: -10px;
    `}
  }
`
export const AnalyzeBarWrapper = styled.div`
  width: 98.5%;
  ${'' /* margin: 0 auto; */}
  padding: 4px 7px 4px 3px;
  position: relative;
  ${'' /* max-width: 224px; */}
  ${props =>
    props.withParentHeight &&
    `
    height: inherit;
    padding: 0;
  `}
`