import React from 'react'
import { Stack, Typography } from '@mui/material'
import useTranslation from '../../../../localization/useTranslation'
import { RHFRadioGroup } from '../../../components/hook-form'

export const StepFour = props => {
  const { getValues } = props

  const { labelStrings } = useTranslation()

  const trackedMetricsValues = getValues('trackedMetrics')
  const creatorNames = trackedMetricsValues?.map(creator => `@${creator?.username}`)

  return (
    <Stack spacing={1.5} alignItems="center">
      <Typography
        align="center"
        component="label"
        sx={{
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '24px',
          mb: 1,
          maxWidth: '480px',
          wordBreak: 'break-all',
        }}
      >
        {labelStrings.createCampaign.steps.four.label(creatorNames?.toString())}
      </Typography>
      <RHFRadioGroup
        name="brandAccountIncludeAll"
        label=""
        sx={{ marginLeft: 1 }}
        options={[
          { value: true, label: labelStrings.createCampaign.steps.three.optionLabels.true },
          { value: false, label: labelStrings.createCampaign.steps.three.optionLabels.false },
          { value: 'null', label: labelStrings.createCampaign.steps.four.optionLabels.null },
        ]}
        helperText={labelStrings.createCampaign.steps.three.helperText}
        isCenterHelperText
      />
    </Stack>
  )
}
