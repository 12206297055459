import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ContentWrapper } from '../../common/buttons/GradientTransparentButton'
import useTranslation from '../../../localization/useTranslation'
import * as Styled from './styles'
import Modal from '../../common/modals/Modal/Modal'
import { ActionButtonsWrapper, Message } from '../collectionTools/commonStyles'
import { CancelButton, GradientButton } from '../../common/buttons/styledButtons'

const CollectionUnlockBtn = ({ confirmAction, unlockedReportsCount = 0 }) => {
  const { labelStrings } = useTranslation()

  const [isModalOpened, setModalOpened] = useState(false)

  const onConfirm = () => {
    confirmAction();
    setModalOpened(false);
  }

  const headerModal = (
    <Styled.HeaderModalTitle>
      {labelStrings.modalUnlockReportsTitle}
    </Styled.HeaderModalTitle>
  )

  return (
    <>
      <Styled.UnlockAllButton onClick={() => setModalOpened(!isModalOpened)}>
        {labelStrings.unlockAll}
      </Styled.UnlockAllButton>

      {isModalOpened && (
        <Modal headerContent={headerModal} closeModal={() => setModalOpened(!isModalOpened)}>
          <ContentWrapper>
            <Message isCentered>
              <Styled.BodyModalContent>
               {labelStrings.modalUnlockReportsBody(unlockedReportsCount)}
              </Styled.BodyModalContent>
            </Message>
          </ContentWrapper>
          <ActionButtonsWrapper>
            <GradientButton onClick={onConfirm}>
              {labelStrings.confirm}
            </GradientButton>
            <CancelButton onClick={() => setModalOpened(!isModalOpened)}>
              {labelStrings.cancel}
            </CancelButton>
          </ActionButtonsWrapper>
        </Modal>
      )}
    </>
  )
}

CollectionUnlockBtn.propTypes = {
  unlockedReportsCount: PropTypes.number,
  confirmAction: PropTypes.func
}

export default CollectionUnlockBtn
