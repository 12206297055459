import React from 'react'
import PropTypes from 'prop-types'
import { ListItemAvatar } from '@mui/material'
import { StyledListItem, StyledListItemText } from './styles'
import { CustomAvatar } from '../../custom-avatar'

export const CreatorsListItem = props => {
  let { creator } = props || {}

  const onOpenCreatorProfile = () => {
    window.open(`${process.env.REACT_APP_PREFIX}profile/${creator._id}`, '_blank')
  }

  return (
    <StyledListItem onClick={onOpenCreatorProfile}>
      <ListItemAvatar>
        <CustomAvatar
          src={creator.profilePicURL}
          name={creator.username ? creator.username : creator.displayName}
          alt={creator.username ? creator.username : creator.displayName}
          color='default'
        />
      </ListItemAvatar>
      <StyledListItemText
        primary={creator.username ? `@${creator.username}` : `@${creator.displayName}`}
      />
    </StyledListItem>
  )
}

CreatorsListItem.propTypes = {
  creator: PropTypes.object,
}