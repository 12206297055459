// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react'
import { CalendarView } from './CalendarView'
import {
  createCalendarEvent,
  updateCalendarEvent,
  deleteCalendarEvent,
} from '../../../redux/ducks/campaignsDucks'
import useResponsive from '../../hooks/useResponsive'
import { useDateRangePicker } from '../../components/date-range-picker'
import { useDispatch, useSelector } from 'react-redux'
import { applyFilter, useGetEvents } from './utils'
import { format } from 'date-fns'

export const CalendarContainer = () => {
  const dispatch = useDispatch()
  const isDesktop = useResponsive('up', 'sm')
  const calendarRef = useRef(null)
  const events = useGetEvents()
  const picker = useDateRangePicker(null, null)

  const [openForm, setOpenForm] = useState(false)
  const [selectedEventId, setSelectedEventId] = useState(null)
  const [selectedRange, setSelectedRange] = useState(null)
  const [date, setDate] = useState(format(new Date(), 'yyyy-MM-dd HH:mm'))
  const [openFilter, setOpenFilter] = useState(false)
  const [filterEventColor, setFilterEventColor] = useState([])
  const [view, setView] = useState(isDesktop ? 'dayGridMonth' : 'listWeek')
  const [isEventOutsideCalendar, setIsEventOutsideCalendar] = useState(false)

  const selectedEvent = useSelector(() => {
    if (selectedEventId) {
      return events.find(event => event.calendarItemId === selectedEventId)
    }

    return null
  })

  const isDeleteAndEditBlocked = useSelector(() => {
    if (selectedEventId) {
      let event = events.find(event => event.calendarItemId === selectedEventId)
      return event.calendarItemId.includes('unchangeable_') ? true : false
    }
    return null
  })

  useEffect(() => {
    const calendarEl = calendarRef.current
    if (calendarEl) {
      const calendarApi = calendarEl.getApi()

      const newView = isDesktop ? 'dayGridMonth' : 'listWeek'
      calendarApi.changeView(newView)
      setView(newView)
    }
  }, [isDesktop])

  const handleOpenModal = () => {
    setOpenForm(true)
  }

  const handleCloseModal = () => {
    setOpenForm(false)
    setSelectedRange(null)
    setSelectedEventId(null)
    setIsEventOutsideCalendar(false)
  }

  const handleClickToday = () => {
    const calendarEl = calendarRef.current
    if (calendarEl) {
      const calendarApi = calendarEl.getApi()

      calendarApi.today()
      setDate(calendarApi.getDate())
    }
  }

  const handleChangeView = newView => {
    const calendarEl = calendarRef.current
    if (calendarEl) {
      const calendarApi = calendarEl.getApi()

      calendarApi.changeView(newView)
      setView(newView)
    }
  }

  const handleClickDatePrev = () => {
    const calendarEl = calendarRef.current
    if (calendarEl) {
      const calendarApi = calendarEl.getApi()

      calendarApi.prev()
      setDate(calendarApi.getDate())
    }
  }

  const handleClickDateNext = () => {
    const calendarEl = calendarRef.current
    if (calendarEl) {
      const calendarApi = calendarEl.getApi()

      calendarApi.next()
      setDate(calendarApi.getDate())
    }
  }

  const handleSelectRange = arg => {
    const calendarEl = calendarRef.current
    if (calendarEl) {
      const calendarApi = calendarEl.getApi()

      calendarApi.unselect()
    }
    handleOpenModal()
    setSelectedRange({
      start: arg.start,
      end: arg.end
    })
  }

  const handleSelectEvent = arg => {
    const calendarItemId = arg.event.extendedProps.calendarItemId
    handleOpenModal()
    setSelectedEventId(calendarItemId)
  }

  const handleResizeEvent = ({ event }) => {
    try {
      dispatch(
        updateCalendarEvent({
          eventId: event.calendarItemId,
          event: {
            allDay: event.allDay,
            start: event.start,
            end: event.end,
          },
        })
      )
    } catch (error) {
      console.error(error)
    }
  }

  const handleDropEvent = ({ event }) => {
    try {
      dispatch(
        updateCalendarEvent({
          eventId: event.calendarItemId,
          event: {
            allDay: event.allDay,
            start: event.start,
            end: event.end,
          },
        })
      )
    } catch (error) {
      console.error(error)
    }
  }

  const handleCreateUpdateEvent = newEvent => {
    if (selectedEventId) {
      dispatch(updateCalendarEvent({ eventId: selectedEventId, event: newEvent }))
    } else {
      dispatch(createCalendarEvent(newEvent))
    }
  }

  const handleDeleteEvent = () => {
    try {
      if (selectedEventId) {
        handleCloseModal()
        dispatch(deleteCalendarEvent(selectedEventId))
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleFilterEventColor = eventColor => {
    const checked = filterEventColor.includes(eventColor)
      ? filterEventColor.filter(value => value !== eventColor)
      : [...filterEventColor, eventColor]

    setFilterEventColor(checked)
  }

  const handleResetFilter = () => {
    const { setStartDate, setEndDate } = picker

    if (setStartDate && setEndDate) {
      setStartDate(null)
      setEndDate(null)
    }

    setFilterEventColor([])
  }

  const dataFiltered = applyFilter({
    inputData: events,
    filterEventColor,
    filterStartDate: picker.startDate,
    filterEndDate: picker.endDate,
    isError: !!picker.isError,
  })

  return (
    <CalendarView
      date={date}
      view={view}
      events={events}
      picker={picker}
      calendarRef={calendarRef}
      openForm={openForm}
      isDesktop={isDesktop}
      handleClickDateNext={handleClickDateNext}
      handleClickDatePrev={handleClickDatePrev}
      handleClickToday={handleClickToday}
      handleChangeView={handleChangeView}
      selectedEvent={selectedEvent}
      setSelectedEventId={setSelectedEventId}
      handleDropEvent={handleDropEvent}
      handleSelectEvent={handleSelectEvent}
      handleResizeEvent={handleResizeEvent}
      handleCreateUpdateEvent={handleCreateUpdateEvent}
      handleDeleteEvent={handleDeleteEvent}
      selectedRange={selectedRange}
      handleSelectRange={handleSelectRange}
      handleOpenModal={handleOpenModal}
      handleCloseModal={handleCloseModal}
      openFilter={openFilter}
      setOpenFilter={setOpenFilter}
      filterEventColor={filterEventColor}
      dataFiltered={dataFiltered}
      handleFilterEventColor={handleFilterEventColor}
      handleResetFilter={handleResetFilter}

      isEventOutsideCalendar={isEventOutsideCalendar}
      setIsEventOutsideCalendar={setIsEventOutsideCalendar}
      isDeleteAndEditBlocked={isDeleteAndEditBlocked}
    />
  )
}
