import React, { useMemo, useCallback } from 'react'
// @ts-ignore
import { renderToStaticMarkup } from 'react-dom/server'
import PropTypes from 'prop-types'
import { VennDiagramContainer } from './VennDiagramContainer'
import Chart from 'react-apexcharts'
import NoDataMessage from '../../../influencerProfile/detailedInfludataRating/NoDataMessage'
import useTranslation from '../../../../localization/useTranslation'
import useWindowResize from '../../../../hooks/useWindowResize'

import * as Styled from './styles'
import './vennDiagram.styles.scss'

export const HeatMapChart = props => {
  const { metadata = [], userdata = [], tabSelect = '' } = props || {}
  const { labelStrings, currentLang } = useTranslation()
  const { isMobile } = useWindowResize()

  const getUserFollowersCount = useCallback(
    userId => {
      return userdata?.find(user => user._id === userId)
    },
    [userdata]
  )

  const influX = metadata?.slice(1)?.map(item => item[0]?.compared?.influencerY)
  const resInfluX =
    influX?.length > 1 && metadata?.length > 1
      ? [
          ...new Set([
            ...metadata[metadata?.length - 1]?.map(el => el?.compared?.influencerX),
            ...influX,
          ]),
        ]
      : []

  const newDataFormatted = metadata?.slice(1)?.map((collection, idx) => {
    const res = collection?.map(el => {
      return {
        x: el?.compared?.influencerX,
        y:
          el?.compared?.userAScore < el?.compared?.userBScore
            ? el?.compared.userBScore?.toFixed(1)
            : el?.compared.userAScore?.toFixed(1),
      }
    })

    return {
      name: influX[idx],
      data: res,
      idx: idx,
    }
  })

  const chartWidth = useMemo(
    () => (newDataFormatted?.length <= 14 ? `${150 + newDataFormatted?.length * 45}px` : '100%'),
    [newDataFormatted]
  )

  const chartHeight = useMemo(
    () => (newDataFormatted?.length <= 14 ? `${150 + newDataFormatted?.length * 45}px` : '1200px'),
    [newDataFormatted]
  )

  const series = useMemo(() => newDataFormatted?.reverse(), [newDataFormatted])
  const options = useMemo(() => {
    return {
      chart: {
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        enabled: isMobile ? false : true,
        shared: false,
        custom: function({ seriesIndex, dataPointIndex }) {
          const currentCell =
            metadata?.length && metadata?.slice(1)?.reverse()[seriesIndex][dataPointIndex]
          const countFollowerX = getUserFollowersCount(currentCell?.compared?.influencerReferenceX)
            ?.followers
          const countFollowerY = getUserFollowersCount(currentCell?.compared?.influencerReferenceY)
            ?.followers
          return renderToStaticMarkup(
            <VennDiagramContainer
              data={currentCell}
              labelStrings={labelStrings}
              countFollowerX={countFollowerX}
              countFollowerY={countFollowerY}
            />
          )
        },
      },
      markers: {
        size: 0,
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#fff'],
        },
      },
      grid: {
        show: false,
      },
      legend: {
        show: false,
      },
      plotOptions: {
        heatmap: {
          shadeIntensity: 0.5,
          colorScale: {
            ranges: [
              {
                from: 0.0,
                to: 0.5,
                color: '#CBD923',
              },
              {
                from: 0.5,
                to: 1,
                color: '#7AB752',
              },
              {
                from: 1,
                to: 2,
                color: '#FE8D30',
              },
              {
                from: 2,
                to: 5,
                color: '#F4BD09',
              },
              {
                from: 5,
                to: 10,
                color: '#FF5D5A',
              },
              {
                from: 10,
                to: 100,
                color: '#501126',
              },
            ],
          },
        },
      },
      colors: ['#CBD923', '#7AB752', '#FE8D30', '#F4BD09', '#FF5D5A', '#501126'],
      xaxis: {
        categories: resInfluX?.slice(0, -1),
      },
    }
  }, [metadata, currentLang])

  const renderChart = useMemo(() => {
    return metadata?.length ? (
      <Styled.HeatMapChartWrapper>
        <div className="topContainer">
          <div className="heatMapTopLeftSide">
            <h3>{labelStrings?.individualComparison}</h3>
            <div>{labelStrings?.graphDescrIndividual}</div>
            <div className="salmonText">{labelStrings?.individualComparisonHoverDescr}</div>
          </div>
        </div>
        <Chart
          // @ts-ignore
          options={options}
          series={series}
          height={chartHeight}
          width={chartWidth}
          type="heatmap"
        />
      </Styled.HeatMapChartWrapper>
    ) : (
      <Styled.NoDataWrapper>
        <h3>{labelStrings?.individualComparison}</h3>
        <NoDataMessage tabSelect={tabSelect} />
      </Styled.NoDataWrapper>
    )
  }, [metadata, currentLang])

  return renderChart
}

HeatMapChart.propTypes = {
  metadata: PropTypes.array,
  userdata: PropTypes.array,
  tabSelect: PropTypes.string,
}