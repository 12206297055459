import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useTranslation from '../../localization/useTranslation'
import { SIGN_UP_AMOUNT_OF_INFLUENCERS } from '../../constants/search'
import CommonSingleRangeSlider from '../common/sliders/CommonSingleRangeSlider'

const SliderWrapper = styled.div`
  ${props => props.isDeactivated && props.theme.disabled}
  position: relative;
  width: 98%;
  margin: 10px auto;
  margin-bottom: 0;
  & .single_range_slider {
    height: 30px !important;
  }
  & .single_range_slider_rail_one {
    height: 30px !important;
  }
  & .single_range_slider_rail_two {
    height: 1px !important;
    background-color: ${props => props.theme.colors.darkBrown};
    transform: unset;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      right: -4px;
      height: 7px;
      width: 7px;
      background: ${props => props.theme.colors.darkBrown};
      border-radius: 50%;
      top: -3px;
      z-index: 1;
    }
  }
  & .single-range-slider-handles_role_slider {
    height: 10px !important;
    width: 10px !important;
    background: ${props => props.theme.platformColor} !important;
    box-shadow: unset;
  }
  & .single-range-slider-tracks_single_track {
    height: 3px !important;
    background: ${props => props.theme.platformColor} !important;
    top: -1px !important;
    transform: unset;
    border-radius: 10px;
  }
  & .single-range-slider-ticks_single_tick_line {
    height: 10px !important;
    margin-top: 0 !important;
  }
  & .single-range-slider-ticks_single_tick_label {
    margin-top: 10px !important;
    color: ${props => props.theme.colors.mauve};
  }
`
const TitleWrapper = styled.div`
  position: relative;
  width: fit-content;
`
const SliderTitle = styled.div`
  padding-left: 0;
  font-size: 14px;
  font-weight: 400;
  color: '#6B7280';
  font-family: 'Lato', sans-serif;
  margin-bottom: 12px;
`
const SliderSubtitle = styled.div`
  padding-left: 0;
  margin-bottom: 15px;
  font-size: 9px;
  font-weight: 500;
`
const CommonRangeSliderWrapper = styled.div`
  padding: 0 10px;
`

const AmountInfluencersRangeSlider = ({ value = 'None', onChange, isMobile = false }) => {
  const { labelStrings } = useTranslation()

  const SLIDER_KEY = value === null ? 'beforequeryvalue' : 'withqueryvalue'

  const onNewValueSelect = newValue => {
    if (newValue !== value) {
      onChange(newValue)
    }
  }

  return (
    <SliderWrapper>
      <TitleWrapper>
        <SliderTitle>{labelStrings.yearsInInfluencerMarketing}</SliderTitle>
      </TitleWrapper>

      <CommonRangeSliderWrapper>
        <CommonSingleRangeSlider
          key={SLIDER_KEY}
          initValue={'None'}
          sliderRanges={{
            ...SIGN_UP_AMOUNT_OF_INFLUENCERS,
          }}
          onRangeChange={onNewValueSelect}
        />
      </CommonRangeSliderWrapper>
    </SliderWrapper>
  )
}

AmountInfluencersRangeSlider.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  isMobile: PropTypes.bool
}

export default AmountInfluencersRangeSlider
