import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { withReduxActions } from '../hocs/withRedux'
import {
  setSearchUsersSelected,
  changeUserInSearchList,
  fetchSearchResults,
  updateSearchTypeStatus,
  updateSearch,
  clearSearch,
  setActiveMapMarker,
} from '../redux/ducks/searchDucks'
import {
  searchResultsSelector,
  searchLoadingStatusSelector,
  userCredsLoadProgressSelector,
  isUserAuthenticatedSelector,
  userGrantSelector,
  currentSearchQuerySelector,
  searchTypeSelector,
  selectedPlatformSelector,
} from '../redux/selectors'

import { filterSelectedUsersFromUsersList } from '../utils'

import ProfileListFullTableContainer from '../components/profileCommon/profileListTable/ProfileListFullTableContainer'
import SidebarContainer from '../components/sidebar/SidebarContainer'
import SearchPagePagination from '../components/pagination/SearchPagePagination'
import {
  Layout,
  MainContainer,
  SearchListHeader,
  ListWrapper,
} from '../components/common/styledWrappers'
import SearchResultsPageTip from '../components/pagesTips/SearchResultsPageTip'
import { pagesBeforeProfileRedirect } from '../constants/pagesBeforeProfileRedirect'
import { changeAdditionalFieldValue } from '../redux/ducks/additionalFieldsDucks'
import { searchContentTypes } from '../constants/search'
import { ProfileContentTable } from '../components/profileCommon/profileListTable/profileContentUser/ProfileContentTable'
import { NAVBAR_FOOTER_SUM_HEIGHT, USER_INDEXES } from '../constants/appSettings'
import { ProfileContentTopFilters } from '../components/profileCommon/profileListTable/profileContentUser/ProfileContentTopFilters'
import { MapContent } from '../components/profileCommon/profileListTable/profileContentUser/MapContent'
import { SkeletonLoader } from '../components/profileCommon/profileListTable/SkeletonLoader'
import { Box } from '@mui/material'

const uploadFromRegex = /uploadedFrom=([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
const uploadUploadTo = /uploadedTo=([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
const filteredByUserId = ({ array = [], userId = '' }) => array?.filter(user => user._id !== userId)

const SearchResultsPage = ({ setSearchUsersSelected, changeUserInSearchList }) => {
  const { search } = useLocation()
  const dispatch = useDispatch()
  const searchResults = useSelector(searchResultsSelector)
  const searchType = useSelector(searchTypeSelector)
  const isSearchLoading = useSelector(searchLoadingStatusSelector)
  const isPageLoading = useSelector(userCredsLoadProgressSelector)
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const { no_plan, content_search } = useSelector(userGrantSelector, shallowEqual)
  const currentQuery = useSelector(currentSearchQuerySelector, shallowEqual)
  const currentPlatform = useSelector(selectedPlatformSelector)

  const [, setSearchPlatform] = useState(USER_INDEXES.instagram)

  const [showMap, setShowMap] = useState(false)
  const [activeCard, setActiveCard] = useState({})

  const onChangeSearchType = () => {
    if (isUserAuthenticated) {
      const changeType =
        searchType === searchContentTypes.CONTENT
          ? searchContentTypes.CREATOR
          : searchContentTypes.CONTENT
      // @ts-ignore
      dispatch([
        clearSearch(),
        updateSearchTypeStatus(changeType),
        fetchSearchResults(currentQuery),
      ])
    }
  }

  useEffect(() => {
    document.getElementById('searchResults')?.scroll({ top: 0, behavior: 'smooth' })
  }, [isSearchLoading])

  const { users, count, dataSuccess, content } = searchResults

  const [searchUsers, setSearchUsers] = useState(users)

  const selectedUsers = filterSelectedUsersFromUsersList(searchUsers)
  const areAllSelected = selectedUsers.length === searchUsers.length
  const filteredContentForMap = content?.filter(el => el.locationArray?.length)

  const handlersMap = {
    modifyUser: changeUserInSearchList,
    selectAllUsers: selectAll => setSearchUsersSelected({ selectAll }),
    selectOne: userId => setSearchUsersSelected({ userId }),
  }

  useEffect(() => {
    setSearchUsers(users)
  }, [users])

  const resultsExist = !!searchUsers?.length || !!content?.length

  const onBlockUnblockUser = async ({ profileId, isBlack }) => {
    const filteredUsers = filteredByUserId({
      array: !!searchUsers?.length ? searchUsers : users,
      userId: profileId,
    })
    setSearchUsers(filteredUsers)

    await dispatch(
      changeAdditionalFieldValue({
        profileId: profileId,
        changeField: 'isBlack',
        valueObj: `${!isBlack}`,
      })
    )
  }

  useEffect(() => {
    const isContentTypes = search?.includes('contentTypes')

    if (search && isContentTypes) {
      const isTypePosts = isContentTypes && search.includes('posts') && 'posts'
      const isTypeReels = isContentTypes && search.includes('reels') && 'reels'
      const isTypeStories = isContentTypes && search.includes('stories') && 'stories'
      const isUploadFrom = isContentTypes && search.match(uploadFromRegex)
      const isUploadTo = isContentTypes && search.match(uploadUploadTo)

      const contentTypesValues = [isTypePosts, isTypeStories, isTypeReels]?.filter(Boolean)?.join()

      content_search && dispatch(updateSearchTypeStatus(searchContentTypes.CONTENT))
      dispatch(
        updateSearch({
          newValuesObj: {
            contentTypes: contentTypesValues,
            uploadedFrom: !!isUploadFrom?.length ? isUploadFrom[1] : '',
            uploadedTo: !!isUploadTo?.length ? isUploadTo[1] : '',
          },
        })
      )
    }
  }, [content_search])

  //refetch data when change search type
  useEffect(() => {
    if (isSearchLoading) return
    dispatch(fetchSearchResults(currentQuery))
  }, [currentPlatform])

  const onHandleShowMap = () => {
    setShowMap(!showMap)
    !showMap && dispatch(setActiveMapMarker({}))
  }

  return (
    <Layout>
      <SidebarContainer
        searchType={searchType}
        onChangeSearchType={onChangeSearchType}
        withSearch
      />

      <MainContainer
        id="searchResults"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: isSearchLoading ? `calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px)` : '100%',
          padding: isSearchLoading ? 0 : '0 20px',
          overflow: isSearchLoading ? 'hidden' : 'auto',
        }}
      >
        {isSearchLoading ? (
          <Box>
            <SkeletonLoader searchType={searchType} />
          </Box>
        ) : (
          <>
            {searchType === searchContentTypes.CREATOR && (
              <SearchListHeader>
                {!!count && <SearchPagePagination searchType={searchType} withResultsCount />}
              </SearchListHeader>
            )}
            <ListWrapper hasPagination>
              {resultsExist && searchType === searchContentTypes.CREATOR ? (
                <ProfileListFullTableContainer
                  users={searchUsers}
                  beforeRedirectPage={pagesBeforeProfileRedirect.SEARCH}
                  areAllSelected={areAllSelected}
                  actionsHandlers={handlersMap}
                  onBlockUnblockUser={onBlockUnblockUser}
                  isSelectedProfileView={true}
                  isDownloadSearchResults
                />
              ) : (
                <>
                  {isUserAuthenticated && searchType === searchContentTypes.CONTENT && (
                    <>
                      <ProfileContentTopFilters platform={currentPlatform?.toLowerCase()} />
                      <SearchListHeader isShowMap={showMap} isContent>
                        {!!content?.length && (
                          <SearchPagePagination
                            withResultsCount
                            searchType={searchContentTypes.CONTENT}
                            platform={currentPlatform.toLowerCase()}
                            isShowMap={showMap}
                            onHandleShowMap={onHandleShowMap}
                          />
                        )}
                        {showMap && currentPlatform.toLowerCase() === USER_INDEXES.instagram && (
                          <MapContent
                            filteredContentForMap={filteredContentForMap}
                            activeCard={activeCard}
                          />
                        )}
                      </SearchListHeader>
                    </>
                  )}
                  <ProfileContentTable
                    content={content}
                    isSearchLoading={isSearchLoading}
                    setActiveCard={setActiveCard}
                    activeCard={activeCard}
                  />
                </>
              )}
              {!!count && <SearchPagePagination onlyPagination searchType={searchType} isBottom />}
            </ListWrapper>
            {!resultsExist && !isSearchLoading && !isPageLoading && (
              <SearchResultsPageTip
                noResults={!dataSuccess}
                isUserAuthenticated={isUserAuthenticated}
                noPlan={no_plan}
              />
            )}
          </>
        )}
      </MainContainer>
    </Layout>
  )
}

SearchResultsPage.propTypes = {
  setSearchUsersSelected: PropTypes.func,
  changeUserInSearchList: PropTypes.func,
}

export default withReduxActions({
  setSearchUsersSelected,
  changeUserInSearchList,
})(memo(SearchResultsPage))
