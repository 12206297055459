import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { ColorfulSmallCardWidget } from '../../components/cardWidgets/ColorfulSmallCardWidget'

export const Goals = props => {
  const { goalsData = [] } = props || {}

  return (
    <Grid container rowSpacing={1} spacing={2}>
      {goalsData?.map((goal, idx) => (
        <Grid item md={3} key={`${idx}-${goal.goalId}`}>
          <ColorfulSmallCardWidget
            title={goal?.goalName}
            total={goal?.goalAbsoluteValue}
            metricLabel={goal?.metricLabel}
            dateRange={goal?.dateRange}
            subText={goal?.subtext}
            goalLevel={goal.goalLevel}
            icon="ic:round-trending-up"
            reachedRate={goal?.goalReachedRate * 100}
            chart={{
              series: Math.ceil(goal?.goalReachedRate * 100),
            }}
            sx={{
              width: '100%',
            }}
          />
        </Grid>
      ))}
    </Grid>
  )
}

Goals.propTypes = {
  goalsData: PropTypes.array,
}