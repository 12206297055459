import React, { useEffect, useRef, useState } from 'react'
import { Avatar, Box, Chip, Paper, Stack, Typography } from '@mui/material'
import Scrollbar from '../../../components/scrollbar/Scrollbar'
import useTranslation from '../../../../localization/useTranslation'
import AddCreatorsTo from '../../../components/add-creator'

export const StepSix = props => {
  const { setValue, getValues, topCreators, watch } = props

  const { labelStrings, currentLang } = useTranslation()

  const heightRef = useRef(null)

  const [creators, setCreators] = useState([])
  const creatorIds = creators?.map(creator => creator.profileId || creator._id)

  const valueFromFormState = getValues('brandAccountIds')
  const watchedValueBrandAccountIncludeAll = watch('brandAccountIncludeAll')

  const addCreator = creator => {
    const updatedValue = [...creators, creator]
    setCreators(updatedValue)
    setValue('creatorIds', updatedValue)
  }

  const removeCreator = creatorId => {
    const updatedValue = creators?.filter(creator => {
      return creator._id !== creatorId && creator.profileId !== creatorId
    })
    setCreators(updatedValue)
    setValue('creatorIds', updatedValue)
  }

  useEffect(() => {
    if (
      !creators?.length &&
      valueFromFormState?.length &&
      (watchedValueBrandAccountIncludeAll === 'false' ||
        watchedValueBrandAccountIncludeAll === 'true')
    ) {
      setCreators(valueFromFormState)
    }
  }, [valueFromFormState?.length, watchedValueBrandAccountIncludeAll])

  return (
    <Stack direction="row" sx={{ position: 'relative' }}>
      <Stack spacing={1.5} alignItems="center" ref={heightRef}>
        <Typography
          align="center"
          component="label"
          sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '24px', mb: 1 }}
        >
          {labelStrings.createCampaign.steps.six.label}
        </Typography>
        <Box sx={{ width: '464px' }}>
          <AddCreatorsTo
            isAllowedToAddCreators={true}
            creatorIdFromState={creatorIds}
            setCreatorIdFromState={addCreator}
            isSetCreatorId={false}
          />
        </Box>
        <Scrollbar
          sx={{
            maxHeight: '250px',
            width: '525px',
          }}
        >
          <Stack
            direction="row"
            sx={{ width: '100%', flexWrap: 'wrap', gap: 1, px: 3.5 }}
            justifyContent="center"
          >
            {creators?.map(creator => (
              <Chip
                sx={{
                  borderColor: 'transparent',
                  backgroundColor: '#F4F6F8',
                  py: 3.5,
                  px: 1,
                  width: 'fit-content',
                  fontWeight: 'bold',
                  '.MuiChip-avatar': { width: '36px', height: '36px', mr: 0.5 },
                }}
                key={creator._id || creator?.profileId}
                avatar={<Avatar alt={creator?.displayName} src={creator?.profilePicURL} />}
                label={`@${creator?.username}`}
                variant="outlined"
                onDelete={() => removeCreator(creator?._id || creator?.profileId)}
              />
            ))}
          </Stack>
        </Scrollbar>
        <Typography
          sx={{
            color: '#637381',
            lineHeight: 1.5,
            fontSize: `0.75rem`,
            fontFamily: `Public Sans,sans-serif`,
            fontWeight: 400,
            textAlign: `center`,
            marginTop: 2,
            marginRight: `14px`,
            marginBottom: 0,
            marginLeft: `14px`,
            px: currentLang === 'de' ? 8 : 2,
          }}
        >
          {labelStrings.createCampaign.steps.six.helperText}
        </Typography>
      </Stack>
      {!!topCreators?.length && (
        <Paper
          sx={{
            position: 'absolute',
            left: currentLang === 'de' ? '630px' : '550px',
            top: '-69px',
            p: 1,
            borderRadius: '16px',
          }}
        >
          <Typography align="center" sx={{ mb: 1, px: 1, fontSize: '14px', fontWeight: 'bold' }}>
            {labelStrings.createCampaign.steps.six.suggestionLabel}
          </Typography>
          <Scrollbar
            sx={{
              maxHeight:
                heightRef?.current?.clientHeight > 250 ? heightRef?.current?.clientHeight : '250px',
              width: '300px',
            }}
          >
            <Stack
              direction="row"
              justifyContent="start"
              sx={{ width: '100%', flexWrap: 'wrap', gap: 1, px: 1.5 }}
            >
              {topCreators?.map(creator => (
                <Chip
                  disabled={creators?.some(el => el.profileId === creator.profileId)}
                  sx={{
                    borderColor: 'transparent',
                    backgroundColor: '#F4F6F8',
                    py: 3.5,
                    px: 1,
                    width: 'fit-content',
                    fontWeight: 'bold',
                    '.MuiChip-avatar': { width: '36px', height: '36px', mr: 0.5 },
                    cursor: 'pointer',
                    '&:hover': {
                      opacity: 0.7,
                    },
                  }}
                  key={creator.profileId}
                  avatar={<Avatar alt={creator?.displayName} src={creator?.profilePicURL} />}
                  label={`@${creator?.username}`}
                  variant="outlined"
                  onClick={() => addCreator(creator)}
                />
              ))}
            </Stack>
          </Scrollbar>
        </Paper>
      )}
    </Stack>
  )
}
