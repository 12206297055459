import React from 'react'
import PropTypes from 'prop-types'
import { Card, Typography, CardHeader, CardContent, IconButton } from '@mui/material'
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
} from '@mui/lab'
import { fDateTime } from '../../utils/formatTime'
import { TooltipComponent } from '../../components/tooltip/TooltipComponent'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export const UploadedContentTimeline = ({
  title,
  subheader = '',
  helperText = '',
  list,
  isPdf = false,
  ...other
}) => {
  return (
    <Card {...other}>
      <CardHeader
        sx={{ pt: isPdf ? 0 : '24px' }}
        title={title}
        subheader={subheader}
        {...(helperText?.length && {
          action: (
            <IconButton aria-label="Helper-Text">
              <TooltipComponent text={helperText}>
                <InfoOutlinedIcon />
              </TooltipComponent>
            </IconButton>
          ),
        })}
      />

      <CardContent
        sx={{
          '& .MuiTimelineItem-missingOppositeContent:before': {
            display: 'none',
          },
        }}
      >
        <Timeline>
          {list?.map((item, index) => (
            <OrderItem key={item.id || index} item={item} isLast={index === list.length - 1} />
          ))}
        </Timeline>
      </CardContent>
    </Card>
  )
}

UploadedContentTimeline.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
  subheader: PropTypes.string,
}

const OrderItem = ({ item, isLast }) => {
  const { type = 'primary', message, date } = item

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          color={
            (type === 'order1' && 'primary') ||
            (type === 'order2' && 'success') ||
            (type === 'order3' && 'info') ||
            (type === 'order4' && 'warning') ||
            'error'
          }
        />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>

      <TimelineContent>
        <Typography variant="subtitle2">{message}</Typography>

        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {fDateTime(date)}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  )
}

OrderItem.propTypes = {
  isLast: PropTypes.bool,
  item: PropTypes.shape({
    time: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    title: PropTypes.string,
    type: PropTypes.string,
  }),
  helperText: PropTypes.string,
  isPdf: PropTypes.bool
}
