import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectedProfileSelector } from '../../../../redux/selectors'
import AudienceEngagementPdf from '../../detailedInfludataRating/AudienceEngagement/AudienceEngagementPdf'
import PageLayout from '../PageLayout/PageLayout'

const IgPageEngagement = ({ isBranded = true }) => {
  const { profile } = useSelector(selectedProfileSelector)
  const { _id: profileId, username, _index: platform, profileScore } = profile || {}

  return (
    <PageLayout
      profileId={profileId}
      username={username}
      platform={platform}
      isBranded={isBranded}
    >
      <AudienceEngagementPdf platform={platform} profileScores={profileScore} isForPdf />
    </PageLayout>
  )
}

IgPageEngagement.propTypes = {
  isBranded: PropTypes.bool,
}

export default IgPageEngagement
