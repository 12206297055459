import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ProfileAvatar from '../profileCommon/profileAvatar'
import { USER_INDEXES } from '../../constants/appSettings'

const AvatarAndToolsContainer = styled.div`
  width: ${props => (props.withParentWidth ? '100%' : 'calc(312px - 34px)')};
  min-width: ${props => (props.withParentWidth ? '100%' : 'calc(312px - 34px)')};
  padding-bottom: ${props => (props.withParentWidth ? '0' : '30px')};
`

const AvatarSplit = styled.div`
  display: flex;
`

const UserProfileAvatar = ({
  profileScore = 0,
  userindex = '',
  profilePicURL = '',
  withShadow = true,
  avatarSize = 150,
  animated = true,
  withParentWidth = false,
}) => {
  return (
    <AvatarSplit>
      <AvatarAndToolsContainer withParentWidth={withParentWidth}>
        <ProfileAvatar
          profilePicUrl={profilePicURL}
          score={profileScore}
          userindex={userindex}
          withRating
          size={avatarSize}
          // slim
          withGradient={userindex !== USER_INDEXES.tiktok}
          withShadow={withShadow}
          animated={animated}
        />
      </AvatarAndToolsContainer>
    </AvatarSplit>
  )
}

UserProfileAvatar.propTypes = {
  profileScore: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  userindex: PropTypes.string.isRequired,
  profilePicURL: PropTypes.string.isRequired,
  avatarSize: PropTypes.number,
  animated: PropTypes.bool,
  withParentWidth: PropTypes.bool,
}

export default UserProfileAvatar
