import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useTranslation from '../../../localization/useTranslation'
import CommonSingleRangeSlider from './CommonSingleRangeSlider'
import LinkToFaqQuestion from '../../faqs/LinkToFaqQuestion'

import { ENGAGEMENTS_RATES } from '../../../constants/search'
import { SearchLabel } from '../styledWrappers'
import WithTip from '../popups/WithTip'
import { Link } from 'react-router-dom'

const SliderWrapper = styled.div`
  ${props => props.isDeactivated && props.theme.disabled}
  position: relative;
  width: 95%;
  margin: 10px auto;
  & .single_range_slider {
    height: 30px !important;
  }
  & .single_range_slider_rail_one {
    height: 1px !important;
  }
  & .single_range_slider_rail_two {
    height: 1px !important;
    background-color: ${props => props.theme.colors.darkBrown};
    transform: unset;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      right: -4px;
      height: 7px;
      width: 7px;
      background: ${props => props.theme.colors.darkBrown};
      border-radius: 50%;
      top: -3px;
      z-index: 1;
    }
  }
  & .single-range-slider-handles_role_slider {
    height: 10px !important;
    width: 10px !important;
    background: ${props => props.theme.platformColor} !important;
    box-shadow: unset;
  }
  & .single-range-slider-tracks_single_track {
    height: 3px !important;
    background: ${props => props.theme.platformColor} !important;
    top: -1px !important;
    transform: unset;
    border-radius: 10px;
  }
  & .single-range-slider-ticks_single_tick_line {
    height: 10px !important;
    margin-top: 0 !important;
  }
  & .single-range-slider-ticks_single_tick_label {
    margin-top: 10px !important;
    color: ${props => props.theme.colors.mauve};
  }
`
const UpgradeLinkWrapper = styled(Link)`
  color: ${props => props.theme.color.black};
  text-decoration: underline;
  &:hover {
    color: ${props => props.theme.color.black};
    opacity: 0.9;
  }
`

const TitleWrapper = styled.div`
  position: relative;
  width: fit-content;
`

const SliderTitle = styled(SearchLabel)`
  padding-left: 0;
  margin: 10px 0;
  font-size: 10px;
`

const CurrentValueLabel = styled.div`
  position: absolute;
  right: -5px;
  top: 8px;
  font-weight: bold;
  font-size: 10px;
  color: ${props => props.theme.platformColor};
`

const EngagementRateRangeSlider = ({ faqQuestionId = '', value = null, onChange, grantMinimumEngagement = false}) => {
  const { labelStrings } = useTranslation()

  const SLIDER_KEY = value === null ? 'beforequeryvalue' : 'withqueryvalue'

  const onNewValueSelect = newValue => {
    if (newValue !== value) {
      onChange(newValue)
    }
  }

  const startSliderValue = value || 0
  const showCurrentValueLabel = value !== null

  const isDisabled = grantMinimumEngagement ? false : true

  const Slider = (
    <div>
      <SliderWrapper isDeactivated={isDisabled}>
        {showCurrentValueLabel && <CurrentValueLabel>{value}%</CurrentValueLabel>}

        <TitleWrapper>
          <SliderTitle>{labelStrings.minEngagementRate}</SliderTitle>
          <LinkToFaqQuestion
            faqQuestionId={faqQuestionId}
            linkPositions={{ top: 10, right: -28 }}
            tipIconSize={14}
          />
        </TitleWrapper>

        <CommonSingleRangeSlider
          key={SLIDER_KEY}
          initValue={startSliderValue}
          sliderRanges={{
            [labelStrings.off]: 0, // zero value, like a "turn off"
            ...ENGAGEMENTS_RATES,
          }}
          onRangeChange={onNewValueSelect}
        />
      </SliderWrapper>
    </div>
  )

  return isDisabled ? (
    <WithTip
      tipText={
        <>
          <UpgradeLinkWrapper to="/settings">{labelStrings.contactSales}</UpgradeLinkWrapper>{' '}
          {labelStrings.to} {labelStrings.useEngagementGrowthRate}
        </>
      }
      position="top center"
      offsetY={-15}
    >
      {Slider}
    </WithTip>
  ) : (
    Slider
  )
}

EngagementRateRangeSlider.propTypes = {
  faqQuestionId: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(null)]),
  onChange: PropTypes.func,
  grantMinimumEngagement: PropTypes.bool,
}

export default EngagementRateRangeSlider
