import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const MentionedProfilesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  min-height: ${props => (props.hasPagination ? '200px' : 'auto')};
`

export const PaginationButton = styled.button`
  height: 30px;
  width: 30px;
  background: none;
  border: none;
  outline: none;
  align-self: center;
  cursor: pointer;
  visibility: visible;
  opacity: 1;

  &:hover {
    border-radius: 100%;
    opacity: 0.5;
  }

  ${props =>
    props.isDisabled &&
    `
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s linear,opacity 0.3s linear;

    .icon_arrow path {
      fill: ${props.theme.colors.mediumBrown};
    }
  `}
`

export const StyledMentionedProfileItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100px;
  min-width: 100px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    filter: brightness(90%);
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
    border-radius: 16px;
  }

  * {
    max-width: 100%;
    white-space: nowrap;
    ${'' /* overflow: ${props => !props.isForPdf ? 'auto' : 'hidden'}; */}
    text-overflow: ellipsis;
  }
`

export const MentionedProfilesGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  ${'' /* justify-content: stars; */}
  ${'' /* grid-template-columns: repeat(4, 100px);
  justify-items: center;
  grid-column-gap: 20px;
  grid-row-gap: 15px; */}
  margin: ${props => (props.hasPagination ? '0 15px' : '0')};
`

export const AccountDetails = styled.div`
  padding: 10px 5px;
  background: ${props => props.theme.colors.white};
  border: 0.5px solid ${props => props.theme.colors.mediumBrown};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  overflow: visible;
`

export const AvatarWrapper = styled.div`
  max-width: 42px;
  max-height: 42px;
  min-width: 42px;
  min-height: 42px;
  border-radius: 50%;
`

export const AvatarImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`

export const Username = styled.div`
  font-size: 10px;
  line-height: 12px;
  ${props =>
    props.isForPdf
      ? `color: ${props.theme.getColorPaletteForPlatform(props.platform).main}`
      : props.theme.getTextGradientStyles()};
  margin-top: 5px;
  margin-bottom: 2px;
  max-width: 100%;
`

export const AccountDetailsField = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: ${props => props.theme.colors.brown};
  text-align: center;
  overflow: visible;
  
  & + & {
    margin-top: 3px;
  }
`

export const Bold = styled.b`overflow: visible;`

export const Br = styled.br``

export const AppearsDetails = styled.div`
  padding: 40px 5px 10px;
  margin-top: -30px;
  border-radius: 16px;
  border: 0.5px solid ${props => props.theme.colors.lightBrown};
  background: ${props => props.theme.colors.lightBrown};
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;
`;


export const AppearsLabel = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: ${props => props.theme.colors.darkBrown};
  text-transform: capitalize;
  overflow: visible;
  fontWeight: 600;

`

export const AppearsValue = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: ${props => props.theme.colors.darkBrown};
  font-weight: bold;
  overflow: visible;
`

export const PaginationButtonsContainer = styled.div`
  padding: 15px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  button + button {
    margin-left: 10px;
  }
`

export const TimelineContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 12px;
`
export const BtnTimeline = styled.button`
  background: ${props => (props.isActive ? props.theme.gradients.main : 'transparent')};
  border: none;
  border-radius: 9px;
  text-align: center;
  padding: 0;
  margin: 0;
  &:hover {
    background: linear-gradient(310.06deg, rgba(179, 77, 77, 0.95) 13.13%, #e3702f 85.43%);
  }

  ${props => !props.isDisabled && props.theme.disabled};

  ${props =>
    !props.isActive &&
    `
    border: solid 1px transparent;
    background-image:
      linear-gradient(rgba(255,255,255,0),rgba(255,255,255,0)), linear-gradient(90deg,#FFBC00 0.35%,#FF5D5A 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff9f9 inset;

    &:hover {
      box-shadow: none;
      background: ${props.theme.gradients.main};
      color: ${props.theme.colors.white};
      background-origin: border-box;
      background-clip: content-box, border-box;
    }
  `}
`
export const TimeLineBtnText = styled.div`
  min-height: 29px;
  width: 116px;
  word-break: break-word;
  font-family: ${props => props.theme.fontFamilyInter};
  font-size: 15px;
  font-weight: bold;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props =>
    props.isActive
      ? `
    color: ${props.theme.colors.white};
    min-height: 31px;
  `
      : `
    background: linear-gradient(90deg,#FFBC00 0.35%,#FF5D5A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    &:hover{ 
      -webkit-text-fill-color: ${props.theme.colors.white};
    }
  `}
`
export const MentionedProfileContainer = styled.div`
  min-width: 540px;
  width: 100%;
`

// export const ChartMarkerImgWrapper = styled.div``

export const ChartWrapper = styled.div`
  .highcharts-credits {
    display: none;
  }
  .highcharts-axis-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    color: ${props => props.theme.colors.darkBrown} !important;
    fill: ${props => props.theme.colors.darkBrown} !important;
  }
  .highcharts-axis-line {
    stroke: ${props => props.theme.colors.mediumBrown} !important;
  }
  .highcharts-tick {
    display: none;
  }
  .highcharts-xaxis-labels {
    text {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      color: ${props => props.theme.colors.darkBrown} !important;
      fill: ${props => props.theme.colors.darkBrown} !important;
    }
  }
  .highcharts-yaxis-labels {
    text {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      color: ${props => props.theme.colors.darkBrown} !important;
      fill: ${props => props.theme.colors.darkBrown} !important;
    }
  }
  .highcharts-scatter-series {
    text {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      text-transform: uppercase;
      color: ${props => props.theme.colors.darkBrown} !important;
      fill: ${props => props.theme.colors.darkBrown} !important;
    }
  }
  .highcharts-series-0 {
    cursor: pointer;
    image {
      outline: 2px solid ${props => props.theme.colors.salmon};
      outline-offset: 4px;
      border-radius: 8px;
    }
    .highcharts-point-hover {
      &:hover {
        filter: drop-shadow(0px 10px 15px rgba(255, 93, 90, 1));
      }
    }
  }
  .highcharts-series-1 {
    cursor: pointer;
    image {
      outline: 2px solid #6cb75b;
      outline-offset: 4px;
      border-radius: 8px;
    }
    .highcharts-point-hover {
      &:hover {
        filter: drop-shadow(0px 10px 15px rgba(108, 183, 91, 1));
      }
    }
  }
  .highcharts-series-2 {
    cursor: pointer;
    image {
      outline: 2px solid ${props => props.theme.colors.yellow};
      outline-offset: 4px;
      border-radius: 8px;
    }
    .highcharts-point-hover {
      &:hover {
        filter: drop-shadow(0px 10px 15px rgba(rgba(90, 126, 255, 1)));
      }
    }
  }
`
export const TooltipWrapper = styled.div`
  background: #ffffff;
  border-radius: 14px;
  padding: 7px;
  width: 240px;

  .under-img {
    max-width: 230px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #442424;
    margin-left: 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .tooltip-img {
    img {
      display: block;
      border-radius: 5px;
      width: 100%;
      height: 100%;
      min-height: auto;
    }
  }
  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #442424;
    margin-left: 4px;
  }
  div {
    color: #442424;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
  .tooltip-content {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 11px;
    div {
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

export const BtnGroupWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`
export const BtnChartDate = styled.button`
  padding: 0;
  border: none;
  border: solid 1px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    linear-gradient(90deg, #ffbc00 0.35%, #ff5d5a 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  ${props =>
    !props.isActive &&
    `box-shadow: 2px 1000px 1px #fff9f9 inset
    `}

  ${props =>
    props.isFirst &&
    `
      border-top-left-radius: 9px;
      border-bottom-left-radius: 9px;
      border-right: none;
    `}
  ${props =>
    props.isMiddle &&
    `
      border-radius: 0;
      border-right: none;
  `}
  ${props =>
    props.isEnd &&
    `
      border-top-right-radius: 9px;
      border-bottom-right-radius: 9px;
    `}
    
  div {
    min-height: 25px;
    min-width: 80px;

    ${props =>
      props.isFirst &&
      `
      border-top-left-radius: 9px;
      border-bottom-left-radius: 9px;
    `}

    ${props =>
      props.isEnd &&
      props.isActive &&
      `
      border-top-right-radius: 9px;
      border-bottom-right-radius: 9px;
    `}

    border: none;
    padding: 0 8px;
    word-break: break-word;
    font-family: ${props => props.theme.fontFamilyInter};
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;

    ${props =>
      props.isActive
        ? `
      color: ${props.theme.colors.white};
      background: linear-gradient(90deg,#FFBC00 0.35%,#FF5D5A 100%);
    `
        : `
      background: linear-gradient(90deg,#FFBC00 0.35%,#FF5D5A 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `}
  }
`
