import React from 'react'
import { Tooltip } from '@mui/material'
import PropTypes from 'prop-types'

export const TooltipComponent = ({ text, placement, children }) => {
  return (
    <Tooltip
      arrow
      title={text}
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            whiteSpace: 'pre-line',
          },
        },
      }}
    >
      {children}
    </Tooltip>
  )
}

TooltipComponent.propTypes = {
  text: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  placement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
}
