import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import VerticalBarChart from '../../../charts/VerticalBarChart'
import useTranslation from '../../../../localization/useTranslation'
import disabledChartData from '../../../charts/disabledChartsData'
import { pretifyBigNum, getExoticLangName } from '../../../../utils'

const AudienceLanguageChart = ({
  userindex = '',
  languageArray = [],
  isDisabledAnalysis = true,
  followersCount = 0,
  isForPdf = false,
  isLikersReport = false,
  isSmall = false,
  customHeight = ''
}) => {
  const { labelStrings, languageOptions } = useTranslation()
  const isDataExist = arr => !!arr && !!arr.length

  const createAudienceLanguageChartData = useCallback(() => {
    if (isDisabledAnalysis || !isDataExist(languageArray)) {
      return disabledChartData.disabledVerticalChartData()
    }

    const otherlangs = {
      label: labelStrings.other,
      value: 0,
      barTip: '',
      grayed: true,
    }

    const preparedlangs = languageArray?.slice(0, 5)?.reduce((arr, item) => {
      const lang = languageOptions?.find(el => el.value === item.language)
      // if lang is not found in our localized languages, we use "langs" lib for getting lang name by its shortcode. Usually it will work for not common langs
      const langName = lang ? lang.label : getExoticLangName(item.language)
      if (!langName) {
        otherlangs.value += item.value
        return arr
      }

      const langItem = {
        label: langName,
        value: item.value,
        followersCount: isLikersReport ? null : (followersCount * item.value) / 100,
        grayed: !lang,
      }
      arr.push(langItem)

      return arr
    }, [])

    if (otherlangs.value) {
      otherlangs.barTip = `${otherlangs.value}% (${pretifyBigNum(
        (followersCount * otherlangs.value) / 100
      )})`
    }

    return otherlangs.value ? [...preparedlangs, otherlangs] : preparedlangs
  }, [languageArray, isDisabledAnalysis])

  return (
    <VerticalBarChart
      title={isForPdf ? '' : labelStrings.language}
      userindex={userindex}
      chartData={createAudienceLanguageChartData()}
      isDisabledChart={isDisabledAnalysis}
      notEnoughData={!isDisabledAnalysis && !isDataExist(languageArray)}
      isForPdf={isForPdf}
      isSmall={isSmall}
      customHeight={customHeight}
    />
  )
}

AudienceLanguageChart.propTypes = {
  userindex: PropTypes.string,
  languageArray: PropTypes.array,
  isDisabledAnalysis: PropTypes.bool,
  followersCount: PropTypes.number,
  isForPdf: PropTypes.bool,
  isLikersReport: PropTypes.bool,
  isSmall: PropTypes.bool,
  customHeight: PropTypes.string
}

export default AudienceLanguageChart
