import styled from 'styled-components'

export const Overlay = styled.div`
  font-family: ${props => props.theme.fontFamily.inter};
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Title = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.brown};
  margin-bottom: 3px;
`
