import styled from 'styled-components'

export const CheckboxWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const StyledCheckbox = styled.div`
  min-width: 22px;
  min-height: 22px;
  max-width: 22px;
  max-height: 22px;
  border: 1px solid ${props => props.theme.colors.darkBrown};
  border-radius: 6px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .icon_tick {
    display: none;
  }

  ${props =>
    props.isChecked &&
    `
    background-color: ${props.theme.colors.mauve};

    .icon_tick {
      display: block;
    }
  `}
`

export const Label = styled.label`
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.brown};
  font-weight: ${props => (props.isChecked ? '700' : 'normal')};
  margin: 0;
  cursor: pointer;
`
