import React from 'react'
import useTranslation from '../../../../../localization/useTranslation'
import { pretifyBigNum } from '../../../../../utils'
import Chart from '../../../../../new-ui/components/chart'
import { Stack, Typography } from '@mui/material'

// Function to interpolate new entries
function interpolateData(data, numEntries) {
  if (data.length === 0) return []
  const startDate = new Date(data[0][0])
  const endDate = new Date(data[data.length - 1][0])
  const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24)
  const step = totalDays / (numEntries - 1)

  const interpolatedResults = []
  for (let i = 0; i < numEntries; i++) {
    const currentDate = new Date(startDate.getTime() + step * i * 1000 * 60 * 60 * 24)
    const strDate = currentDate.toISOString().split('T')[0]

    // Find closest points for value interpolation
    let lowerBound = null
    let upperBound = null
    for (let j = 0; j < data.length - 1; j++) {
      if (new Date(data[j][0]) <= currentDate && new Date(data[j + 1][0]) >= currentDate) {
        lowerBound = data[j]
        upperBound = data[j + 1]
        break
      }
    }

    let interpolatedValue = null
    if (lowerBound && upperBound) {
      const x0 = (new Date(lowerBound[0]) - startDate) / (1000 * 60 * 60 * 24)
      const y0 = lowerBound[1]
      const x1 = (new Date(upperBound[0]) - startDate) / (1000 * 60 * 60 * 24)
      const y1 = upperBound[1]
      const x = (currentDate - startDate) / (1000 * 60 * 60 * 24)
      interpolatedValue = Math.round(linearInterpolate(x0, y0, x1, y1, x))
    }

    interpolatedResults.push([strDate, interpolatedValue])
  }
  return interpolatedResults
}

function calculateGrowthPercentage(firstData, lastData) {
  if (!firstData || lastData === null || lastData === undefined) {
    return '0%'
  }

  // Calculate the growth as a percentage
  const growth = ((lastData - firstData) / firstData) * 100

  // Round the growth to one decimal place
  const roundedGrowth = Math.round(growth * 10) / 10

  return roundedGrowth + '%'
}

// Function to perform linear interpolation
function linearInterpolate(x0, y0, x1, y1, x) {
  return y0 + ((y1 - y0) * (x - x0)) / (x1 - x0)
}

export const FollowerChartPdf = props => {
  const { chartData, monthlyGrowthFollowers } = props || {}

  const { labelStrings } = useTranslation()

  let formattedChartData = chartData.slice(1)

  // Filter data for the last 365 days
  const oneYearAgo = new Date()
  oneYearAgo.setDate(oneYearAgo.getDate() - 365)

  let filteredData = formattedChartData.filter(([date]) => new Date(date) >= oneYearAgo)
  filteredData = interpolateData(filteredData, 8)
  const lastData =
    filteredData && filteredData.length > 1 ? filteredData[filteredData.length - 1][1] : null
  const firstData = filteredData && filteredData.length > 1 ? filteredData[0][1] : null
  const newFollowers = firstData && lastData ? pretifyBigNum(lastData - firstData) : 0
  const newFollowersInPercent = calculateGrowthPercentage(firstData, lastData)

  let getGraphColor = lastData - firstData >= 0 ? '#6CB75B' : '#FF3636'
  const chartOptions = {
    options: {
      chart: {
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          show: false,
          datetimeFormatter: {
            year: 'yyyy',
            month: "MMM 'yy",
            day: 'dd MMM',
            hour: 'HH:mm',
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      grid: {
        show: false,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      colors: [getGraphColor],
      markers: {
        size: 4,
        colors: [getGraphColor],
        strokeColors: getGraphColor,
        strokeWidth: 2,
        strokeOpacity: 0.9,
        shape: 'circle',
        radius: 2,
      },
    },
    series: [
      {
        name: 'Followers',
        data: filteredData,
      },
    ],
  }

  return (
    <Stack>
      <Chart options={chartOptions.options} series={chartOptions.series} type='line' height={120}/>
      <Stack alignItems="center" sx={{ mb: 3 }}>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 'bold',
            color: getGraphColor,
            lineHeight: 'initial',
          }}
        >
          {newFollowersInPercent}
        </Typography>
        <Stack direction="row" spacing={0.5}>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 'bold',
              color: getGraphColor,
              lineHeight: 'initial',
            }}
          >
            {newFollowers}
          </Typography>
          <Typography
            sx={{
              fontSize: '20px',
              color: getGraphColor,
              lineHeight: 1.7,
            }}
          >
            {labelStrings?.followers}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
