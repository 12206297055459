import React from 'react'
import PropTypes from 'prop-types'
import { Box, Stack, SvgIcon, Typography, styled } from '@mui/material'
import { fDateTime } from '../../../utils/formatTime'
import Scrollbar from '../../scrollbar/Scrollbar'

const StyledImg = styled('img')({
  borderRadius: '12px',
  width: '100%',
  height: '200px',
  objectFit: 'cover',
})

export const CommentsItem = ({ item, isPdf = false }) => {
  const {
    username,
    uploaded,
    text,
    upvotesCount,
    connectedUsername,
    platform,
    connectedContentType,
    imageUrl,
  } = item || {}

  return (
    <Stack
      spacing={2}
      sx={{
        position: 'relative',
        p: theme => theme.spacing(3, 2, 2, 3),
        minHeight: '100px',
      }}
    >
      {item && !Object.keys(item)?.length ? (
        <Box
          sx={{
            width: '100%',
            height: '70px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: '16px',
              width: '100%',
            }}
            variant="body2"
          >
            {'No data available'}
          </Typography>
        </Box>
      ) : (
        <>
          <Stack direction="row" justifyContent="space-between">
            {username && uploaded && (
              <Stack spacing={1} sx={{ width: '40%' }} alignItems={'center'}>
                <Box sx={{ width: '100%', height: '100%' }}>
                  <StyledImg src={imageUrl} />
                </Box>
              </Stack>
            )}

            <Stack spacing={1} sx={{ width: '60%', paddingLeft: 3 }}>
              {username && uploaded && (
                <Stack>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                    by @{username}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      color: 'text.secondary',
                      display: 'block',
                    }}
                  >
                    on {fDateTime(uploaded)}
                  </Typography>
                </Stack>
              )}
              {text && (
                <Scrollbar sx={{ maxHeight: '150px', overflow: isPdf ? 'hidden' : 'inherit' }}>
                  <Typography sx={{ pr: 2, py: isPdf ? 1 : 0 }} variant="body2">
                    {text}
                  </Typography>
                </Scrollbar>
              )}
            </Stack>
          </Stack>
          {(connectedUsername || upvotesCount > 0) && (
            <Stack
              sx={{ mt: 1 }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {connectedUsername && (
                <Stack>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                    @{connectedUsername}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      color: 'text.secondary',
                      display: 'block',
                      // fontSize: '12px',
                      // lineHeight: '18px',
                    }}
                  >
                    Creator {platform ? 'on ' + platform[0].toUpperCase() + platform.slice(1) : ''}
                    {connectedContentType
                      ? ' (' +
                        connectedContentType[0].toUpperCase() +
                        connectedContentType.slice(1) +
                        ')'
                      : ''}
                  </Typography>
                </Stack>
              )}
              {upvotesCount > 0 && (
                <Stack direction="row" alignItems="center" spacing={0.5} sx={{ py: isPdf ? 1 : 0 }}>
                  <SvgIcon color="success" sx={{ mt: isPdf ? '3px' : '-3px' }}>
                    <path
                      d="M14.2143 9.91433H18.4988C18.8787 9.91433 19.2536 10.0009 19.5951 10.1675C19.9365 10.3341 20.2355 10.5763 20.4693 10.8758C20.7031 11.1753 20.8656 11.5241 20.9444 11.8957C21.0232 12.2674 21.0163 12.6521 20.9241 13.0207L19.6162 18.2523C19.4771 18.8086 19.1561 19.3025 18.7041 19.6554C18.252 20.0083 17.695 20.2 17.1216 20.2H5.28571C4.94472 20.2 4.61769 20.0646 4.37658 19.8235C4.13546 19.5823 4 19.2553 4 18.9143V11.2C4 10.859 4.13546 10.532 4.37658 10.2909C4.61769 10.0498 4.94472 9.91433 5.28571 9.91433H8.5V9.91168L10.1683 4.97718C10.2165 4.83464 10.2923 4.70303 10.3915 4.58989C10.4907 4.47674 10.6112 4.3843 10.7462 4.31785C10.8812 4.25141 11.028 4.21228 11.1782 4.2027C11.3283 4.19312 11.4789 4.21328 11.6212 4.26204L12.2829 4.48864C12.8464 4.68163 13.3355 5.04592 13.6818 5.53057C14.0281 6.01522 14.2143 6.59598 14.2143 7.19164V9.91168"
                      fill="#323232"
                    />
                    <path
                      d="M14.2143 9.91433H18.4988C18.8787 9.91433 19.2536 10.0009 19.5951 10.1675C19.9365 10.3341 20.2355 10.5763 20.4693 10.8758C20.7031 11.1753 20.8656 11.5241 20.9444 11.8957C21.0232 12.2674 21.0163 12.6521 20.9241 13.0207L19.6162 18.2523C19.4771 18.8086 19.1561 19.3025 18.7041 19.6554C18.252 20.0083 17.695 20.2 17.1216 20.2H5.28571C4.94472 20.2 4.61769 20.0646 4.37658 19.8235C4.13546 19.5823 4 19.2553 4 18.9143V11.2C4 10.859 4.13546 10.532 4.37658 10.2909C4.61769 10.0498 4.94472 9.91433 5.28571 9.91433H8.5V9.91168L10.1683 4.97718C10.2165 4.83464 10.2923 4.70303 10.3915 4.58989C10.4907 4.47674 10.6112 4.3843 10.7462 4.31785C10.8812 4.25141 11.028 4.21228 11.1782 4.2027C11.3283 4.19312 11.4789 4.21328 11.6212 4.26204L12.2829 4.48864C12.8464 4.68163 13.3355 5.04592 13.6818 5.53057C14.0281 6.01522 14.2143 6.59598 14.2143 7.19164V9.91168"
                      stroke="#323232"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.5 20.1978V9.91431"
                      stroke="#323232"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </SvgIcon>
                  <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>{upvotesCount}</Typography>
                </Stack>
              )}
            </Stack>
          )}
        </>
      )}
    </Stack>
  )
}

CommentsItem.propTypes = {
  item: PropTypes.shape({
    tags: PropTypes.array,
    name: PropTypes.string,
    avatar: PropTypes.string,
    description: PropTypes.string,
    postedAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  }),
  isPdf: PropTypes.bool,
}