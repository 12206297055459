import React, { useMemo, memo } from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { selectedProfileDetailsSelector } from '../redux/selectors'
import useTranslation from '../localization/useTranslation'
import { INFLUDATA_NAME, SOCIAL_PLATFORMS_NAMES, USER_INDEXES } from '../constants/appSettings'

const withPageMeta = Component => {
  const HOC = ({ ...props }) => {
    const { pathname } = useLocation()
    const { labelStrings } = useTranslation()
    const selectedProfile = useSelector(selectedProfileDetailsSelector)

    const pageTitle = useMemo(() => {
      if (pathname.includes('search')) return `${INFLUDATA_NAME} ${labelStrings.search}`
      if (pathname.includes('collections')) return `${INFLUDATA_NAME} ${labelStrings.collections}`
      if (pathname.includes('campaigns')) return `${INFLUDATA_NAME} ${labelStrings.campaigns}`
      if (pathname.includes('compare-profiles'))
        return `${INFLUDATA_NAME} ${labelStrings.compareProfiles}`
      if (pathname.includes('profile')) {
        if (selectedProfile) {
          return `${INFLUDATA_NAME} - ${selectedProfile?.username} - ${
            SOCIAL_PLATFORMS_NAMES[USER_INDEXES[selectedProfile._index]]?.prettyName
          } ${labelStrings.profile}`
        }
        return `${INFLUDATA_NAME} ${labelStrings.profile}`
      }
      if (pathname.includes('plans')) return `${INFLUDATA_NAME} ${labelStrings.pricing}`
      return INFLUDATA_NAME
    }, [selectedProfile])

    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Component {...props} />
      </>
    )
  }

  return memo(HOC)
}

export default withPageMeta
