import * as Yup from 'yup'
const phoneRegExp = /^$|^(\+|00)[0-9]{1,3}[0-9]{11,14}(?:x.+)?$/

export const EditCreatorSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email format"),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    countryArray: Yup.array().of(
        Yup.object().shape({
        value: Yup.lazy((value) => (value === '' ? Yup.string() : Yup.number()
        .moreThan(0, 'Percent should not be zero or less than zero')
        .lessThan(100, "Percent should not exceed 100")))
      })
    ),
    languageArray: Yup.array().of(
        Yup.object().shape({
        value: Yup.lazy((value) => (value === '' ? Yup.string() : Yup.number()
        .moreThan(0, 'Percent should not be zero or less than zero')
        .lessThan(100, "Percent should not exceed 100")))
      })
    ),
    ageArray: Yup.array().of(
        Yup.object().shape({
        value: Yup.lazy((value) => (value === '' ? Yup.string() : Yup.number()
        .moreThan(0, 'Percent should not be zero or less than zero')
        .lessThan(100, "Percent should not exceed 100")))
      })
    ),
})



