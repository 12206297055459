import SimpleBar from 'simplebar-react'
import { styled } from '@mui/material/styles'

export const StyledRootScrollbar = styled('div')(() => ({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
}))

export const StyledScrollbar = styled(SimpleBar)(() => ({
  maxHeight: '100%',
  '& .simplebar-scrollbar': {
    '&:before': {
      backgroundColor: '#784E4E',
    },
    '&.simplebar-visible:before': {
      opacity: 0.8,
    },
  },
  '& .simplebar-track.simplebar-vertical': {
    width: 8,
    '&:hover': {
      width: 10,
    },
  },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: 6,
  },
  '& .simplebar-mask': {
    zIndex: 'inherit',
  },
}))
