import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

export const CalendlyLinkMeet = props => {
  const { email, firstName, lastName } = props

  //const calendlyLink = `https://meetings-eu1.hubspot.com/imanuel-jakubow/infludata-demo?embed=true&email=${email}&firstName=${firstName}&lastName=${lastName}`
  const calendlyLink = 'https://meetings-eu1.hubspot.com/imanuel-jakubow/infludata-onboarding?embed=true'

  const [scriptLoaded, setScriptLoaded] = useState(false)

  useEffect(() => {
    if (!scriptLoaded) {
      const script = document.createElement('script')
      script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
      script.async = true
      script.onload = () => {
        if (window.HubspotMeetings) {
          window.HubspotMeetings.refresh()
          setScriptLoaded(true)
        }
      }
      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [scriptLoaded])

  return (
    <>
      <div
        className="meetings-iframe-container"
        style={{ width: '420px', height: '620px', border: 'none' }}
      >
        <iframe
          title="Calendly"
          src={calendlyLink}
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
          }}
          allow="microphone; camera; geolocation"
        />
      </div>

      <Helmet />
    </>
  )
}
