import React from 'react'
import { Button, Stack, Typography } from '@mui/material'
import useTranslation from '../../../../../localization/useTranslation'
import { useDispatch } from 'react-redux'
import {
  changeCampaign,
  changeLoadingCurrentCampaign,
  changeLoadingDashboard,
  onlyFetchNewCampaigns,
} from '../../../../../redux/ducks/campaignsDucks'
import { useHistory } from 'react-router-dom'

export const CopyCampaignLast = ({ setOpenModal, newCopyCampaignId, resetStep, reset }) => {
  const { labelStrings, currentLang } = useTranslation()

  const dispatch = useDispatch()
  const history = useHistory()

  const onClosed = () => {
    setOpenModal(false)

    resetStep()
    reset({
      campaignName: '',
      startAsInactive: '',
      brandAccountIncludeAll: '',
      brandAccountIds: [],
      campaignCurrency: 'EUR',
      creatorIds: [],
      trackedMetrics: [],
      overwriteStartingDate: null,
    })

    if (!!newCopyCampaignId?.length) {
      // @ts-ignore
      dispatch([
        changeLoadingDashboard(true),
        changeLoadingCurrentCampaign(true),
        changeCampaign({ campaignId: newCopyCampaignId, history, isCopyCampaign: true }),
        onlyFetchNewCampaigns(),
      ])
    }
  }

  return (
    <Stack alignItems="center" spacing={1.5}>
      <Typography
        sx={{
          color: '#637381',
          lineHeight: '22px',
          fontSize: `14px`,
          fontFamily: `Inter`,
          fontWeight: 400,
          textAlign: `center`,
          marginTop: 2,
          marginRight: `14px`,
          marginBottom: 0,
          marginLeft: `14px`,
          maxWidth: currentLang === 'de' ? '450px' : '422px',
        }}
      >
        {labelStrings.copyCampaign.finish.helperText}
      </Typography>

      <Button
        onClick={onClosed}
        sx={{ minWidth: '120px', mt: '24px !important' }}
        variant="contained"
      >
        {labelStrings.close}
      </Button>
    </Stack>
  )
}
