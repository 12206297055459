import React from 'react'
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from '../../../../new-ui/components/table'
import { Card, Table, TableBody, TableContainer } from '@mui/material'
import useTranslation from '../../../../localization/useTranslation'
import Scrollbar from '../../../../new-ui/components/scrollbar/Scrollbar'
import { TableRowsLoader } from '../../../../new-ui/components/table/TableRowSkeleton'
import { YourOverlapsFieldTableRow } from './YourOverlapsFieldTableRow'

export const YourOverlapsTable = props => {
  const { tableData, isLoading, onOpenOverlap } = props

  const { labelStrings } = useTranslation()
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    // onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable()

  const TABLE_HEAD = [
    { id: 'platform', label: labelStrings.collectionOverlap.tableFields.platform, align: 'left' },
    { id: 'creators', label: labelStrings.collectionOverlap.tableFields.creators, align: 'left' },
    { id: 'created', label: labelStrings.collectionOverlap.tableFields.created, align: 'left' },
    { id: 'overlap', label: labelStrings.collectionOverlap.tableFields.overlap, align: 'left' },
    { id: '' },
  ]

  const denseHeight = dense ? 52 : 72
  const isNotFound = !tableData?.length && !isLoading

  return (
    <Card sx={{ backgroundColor: '#fffcfc', mb: 2 }}>
      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <Scrollbar>
          <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData?.length}
              // onSort={onSort}
              sx={{
                '.MuiTableCell-head': {
                  backgroundColor: '#FFF9F9',
                  borderBottom: '1px solid #CBB3B3',
                  textTransform: 'uppercase',
                  fontFamily: `Inter, sans-serif`,
                  color: '#442424',
                },
                '.MuiTableSortLabel-root': {
                  textTransform: 'uppercase',
                  fontFamily: `Inter, sans-serif`,
                  color: '#442424',
                },
              }}
            />

            <TableBody
              sx={{
                '.MuiTableCell-body': {
                  backgroundColor: '#fffcfc',
                  color: '#442424',
                },
                '.MuiTableRow-root': {
                  borderBottom: isLoading ? 'none' : '1px solid #F4E8E8',
                  '&:nth-last-of-type(-n+2)': {
                    borderBottom: 'none'
                  },
                }
              }}
            >
              {isLoading ? (
                <TableRowsLoader rowsNum={11} thNum={4} />
              ) : (
                !isLoading &&
                tableData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => (
                    <YourOverlapsFieldTableRow
                      key={row._id}
                      row={row}
                      onOpenOverlap={() => onOpenOverlap(row)}
                    />
                  ))
              )}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(page, rowsPerPage, tableData?.length)}
              />

              <TableNoData isNotFound={isNotFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={tableData.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        dense={dense}
        onChangeDense={onChangeDense}
        sx={{
          '& p': { m: 0 },
          '.MuiTablePagination-toolbar': {
            backgroundColor: '#fffcfc',
            color: '#442424',
          },
          '.MuiTablePagination-root': {
            borderTop: '1px solid #CBB3B3',
          },
          '.MuiSelect-icon': {
            color: '#442424',
          },
          '.MuiIconButton-root': {
            color: '#442424',
          },
        }}
      />
    </Card>
  )
}
