import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'
import CloseButton from '../buttons/CloseButton'
import PrimaryButton from '../buttons/PrimaryButton'

import { EditWrapper, DialogBluredWrapper, DialogBluredTitle } from './dialogsCommonStyles'

const DialogWrapper = styled(DialogBluredWrapper)`
  min-width: 489px;
  max-width: 489px;
`

const DialogContent = styled(EditWrapper)`
  ${props => props.theme.flex.centerAll}
  flex-direction: column;
  height: 95px;
  margin: 40px 0 0px 0;
  justify-content: space-between;
  & > * {
    font-family: ${props => props.theme.fontFamilyInter};
  }
`

const CampaignSuccessSeatsChangeDialog = ({ closeModal }) => {
  const { labelStrings } = useTranslation()

  return (
    <DialogWrapper>
      <DialogBluredTitle>{labelStrings.changeSeatPlan}</DialogBluredTitle>
      <CloseButton close={closeModal} top="14px" right="14px" />
      <DialogContent>
        {labelStrings.seatsSuccessChange}

        <PrimaryButton onClick={closeModal} label={labelStrings.close} />
      </DialogContent>
    </DialogWrapper>
  )
}

CampaignSuccessSeatsChangeDialog.propTypes = {
  closeModal: PropTypes.func,
}

export default CampaignSuccessSeatsChangeDialog
