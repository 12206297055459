import { actionProvider } from './actionsProvider'
import {
  SOCIAL_PLATFORMS_NAMES,
  TRIAL_LIMIT_REASONS,
  ADD_USER_RESPONSE_CODES_MAP,
} from '../../constants/appSettings'
import { countryOptions as getAvailableCountries } from '../../localization/localizations'
import availableLangs from '../../localization/availableLangs'
import { ERROR_MSG } from '../../constants/errorMessages'
import httpService from '../../services/httpService'
import sessionCacheService from '../../services/sessionCacheService'
import { resetSearchResults } from './searchDucks'
import { setError } from './errorDucks'
import {
  selectedPlatformSelector,
  userGrantSelector,
  availableCountriesCitiesSelector,
} from '../selectors'
import { delayFunc } from '../../utils'

const SET_SOCIAL_PLATFORM = 'SET_SOCIAL_PLATFORM'
const TOGGLE_SIGN_UP = 'TOGGLE_SIGN_UP'
const TOGGLE_SIGN_IN = 'TOGGLE_SIGN_IN'
const TOGGLE_SIGN_UP_SUCCESS = 'TOGGLE_SIGN_UP_SUCCESS'
const SET_ACCOUNT_ALREADY_EXISTS = 'SET_ACCOUNT_ALREADY_EXISTS'
const TRIGGER_CONTACTS_US = 'TRIGGER_CONTACTS_US'
const TOGGLE_FREE_USER_LIMIT = 'TOGGLE_FREE_USER_LIMIT'
const TOGGLE_TRIAL_USER_LIMIT = 'TOGGLE_TRIAL_USER_LIMIT'
const RESET_LIMITS = 'RESET_LIMITS'
const SET_AVAILABLE_COUNTRIES_CITIES = 'SET_AVAILABLE_COUNTRIES_CITIES'
const RESET_APP_SETTINGS = 'RESET_APP_SETTINGS'
const SET_LAST_PAGE_BEFORE_PROFILE_REDIRECT = 'SET_LAST_PAGE_BEFORE_PROFILE_REDIRECT'
const SET_NEWS_MODAL_CONTENT_OBJECT = 'SET_NEWS_MODAL_CONTENT_OBJECT'

const createInitState = () => {
  const defaultSocialPlatform = SOCIAL_PLATFORMS_NAMES.instagram.name
  const cachedPlatform = sessionCacheService.getCachedSocialPlatform()
  if (!cachedPlatform) {
    sessionCacheService.setCachedSocialPlatform(defaultSocialPlatform)
  }
  return {
    selectedPlatform: cachedPlatform || defaultSocialPlatform,
    isSignInOpened: false,
    isSignUpOpened: false,
    isSignUpSuccessOpened: false,
    triggerToContacts: false,
    accountAlreadyExists: false,
    limits: {
      isFreeUserLimited: false,
      isTrialUserLimited: false,
      trialLimitReason: '',
    },
    availableCountriesCities: {},
    lastPageBeforeProfileRedirect: '',
    newsModalContentObject: {},
  }
}

// reducer
export const appSettingsReducer = (state = createInitState(), action = {}) => {
  switch (action.type) {
    case SET_SOCIAL_PLATFORM:
      return { ...state, selectedPlatform: action.payload }
    case TOGGLE_SIGN_UP:
      return {
        ...state,
        isSignUpOpened: !state.isSignUpOpened,
        ...(state.isSignInOpened && { isSignInOpened: false }),
        ...(state.accountAlreadyExists && { accountAlreadyExists: false }),
      }
    case TOGGLE_SIGN_IN:
      return {
        ...state,
        isSignInOpened: !state.isSignInOpened,
        ...(state.isSignUpOpened && { isSignUpOpened: false }),
        ...(state.accountAlreadyExists && { accountAlreadyExists: false }),
      }
    case TOGGLE_SIGN_UP_SUCCESS:
      return {
        ...state,
        isSignUpSuccessOpened: !state.isSignUpSuccessOpened,
      }
    case SET_ACCOUNT_ALREADY_EXISTS:
      return {
        ...state,
        accountAlreadyExists: true,
        isSignInOpened: true,
        ...(state.isSignUpOpened && { isSignUpOpened: false }),
      }
    case TRIGGER_CONTACTS_US:
      return {
        ...state,
        triggerToContacts: !state.triggerToContacts,
      }
    case TOGGLE_FREE_USER_LIMIT:
      return {
        ...state,
        limits: { ...state.limits, isFreeUserLimited: !state.limits.isFreeUserLimited },
      }
    case TOGGLE_TRIAL_USER_LIMIT:
      const { trialLimitReason } = action.payload || {}
      return {
        ...state,
        limits: {
          ...state.limits,
          isTrialUserLimited: !state.limits.isTrialUserLimited,
          trialLimitReason: trialLimitReason || '',
        },
      }
    case RESET_LIMITS:
      return {
        ...state,
        limits: {
          isFreeUserLimited: false,
          isTrialUserLimited: false,
          trialLimitReason: '',
        },
      }
    case SET_AVAILABLE_COUNTRIES_CITIES:
      return { ...state, availableCountriesCities: action.payload }
    case RESET_APP_SETTINGS:
      return createInitState()
    case SET_LAST_PAGE_BEFORE_PROFILE_REDIRECT:
      return { ...state, lastPageBeforeProfileRedirect: action.payload }
    case SET_NEWS_MODAL_CONTENT_OBJECT:
      return {
        ...state,
        newsModalContentObject: action.payload,
      }
    default:
      return state
  }
}

// actions
export const setPlatform = payload => actionProvider(SET_SOCIAL_PLATFORM, payload)
export const toggleSignUp = () => actionProvider(TOGGLE_SIGN_UP)
export const toggleSignIn = () => actionProvider(TOGGLE_SIGN_IN)
export const toggleSignUpSuccess = () => actionProvider(TOGGLE_SIGN_UP_SUCCESS)
export const setAlreadyExistAccount = () => actionProvider(SET_ACCOUNT_ALREADY_EXISTS)
export const triggerContactsUs = () => actionProvider(TRIGGER_CONTACTS_US)
export const toggleFreeUserLimit = () => actionProvider(TOGGLE_FREE_USER_LIMIT)
export const toggleTrialUserLimit = payload => actionProvider(TOGGLE_TRIAL_USER_LIMIT, payload)
export const resetLimits = () => actionProvider(RESET_LIMITS)
export const setAvailableCountriesCities = payload =>
  actionProvider(SET_AVAILABLE_COUNTRIES_CITIES, payload)
export const resetAppSettings = () => actionProvider(RESET_APP_SETTINGS)
export const setLastPageBeforeProfileRedirect = payload =>
  actionProvider(SET_LAST_PAGE_BEFORE_PROFILE_REDIRECT, payload)
export const setNewsContentObjectModal = payload =>
  actionProvider(SET_NEWS_MODAL_CONTENT_OBJECT, payload)

export const changeAppSocialPlatform = (newPlatformName, history) => dispatch => {
  const result = dispatch(triggerSocialPlatform(newPlatformName))
  if (result) dispatch(resetSearchResults(history))
}

export const triggerSocialPlatform = newPlatformName => (dispatch, getState) => {
  const currentPlatform = selectedPlatformSelector(getState())
  const platform = newPlatformName
  
  if (newPlatformName !== currentPlatform) {
    sessionCacheService.setCachedSocialPlatform(platform)
    // change platform for api calls
    httpService.configurePlatformForApi(platform)
    dispatch(setPlatform(platform))
    return true
  }
  return false
}

export const toggleContactUs = () => dispatch => {
  dispatch(triggerContactsUs())
  delayFunc(() => dispatch(triggerContactsUs()), 500)
}

export const limitAccess = () => (dispatch, getState) => {
  const { package_free: isFreeUser, in_trial: isTrialUser } = userGrantSelector(getState())
  if (isTrialUser) {
    return dispatch([limitTrialRequestsAccess()])
  }
  if (isFreeUser) {
    dispatch([toggleFreeUserLimit()])
  }
}

export const limitTrialFeaturesAccess = () => dispatch => {
  dispatch([toggleTrialUserLimit({ trialLimitReason: TRIAL_LIMIT_REASONS.forbiddenFeature })])
}

export const limitTrialRequestsAccess = () => dispatch => {
  dispatch([toggleTrialUserLimit({ trialLimitReason: TRIAL_LIMIT_REASONS.requestsExceed })])
}

export const limitTrailAudienceTokensAccess = () => dispatch => {
  dispatch([
    toggleTrialUserLimit({ trialLimitReason: TRIAL_LIMIT_REASONS.audienceTokensTrialLimit }),
  ])
}

export const addProfileToInfludata = ({ profileName, withNotify, platform }) => async dispatch => {
  try {
    const response = await httpService.addProfileToInfludata({ profileName, withNotify, platform })
    const returnStatus = ADD_USER_RESPONSE_CODES_MAP[response.status]
    if (returnStatus) return returnStatus
    throw new Error()
  } catch (err) {
    dispatch(setError(ERROR_MSG.failAddProfileToInfludata))
  }
}

export const getAvailableCountriesCities = () => async (dispatch, getState) => {
  const availableCountriesCities = availableCountriesCitiesSelector(getState())
  if (Object.keys(availableCountriesCities).length) return // avoid refetching if exists

  try {
    const countriesCitiesFormCache = await sessionCacheService.getCachedCountriesCities()
    if (countriesCitiesFormCache) {
      return dispatch(setAvailableCountriesCities(countriesCitiesFormCache))
    }
    const countries = getAvailableCountries(availableLangs.en) // take only
    const countriesList = countries.reduce((arr, c) => {
      if (c.value) arr.push(c.value)
      return arr
    }, [])
    const preparedContries = encodeURIComponent(JSON.stringify(countriesList))
    const countriesCities = await httpService.fetchCountriesCities(preparedContries)
    dispatch(setAvailableCountriesCities(countriesCities))
    sessionCacheService.setCachedCountriesCities(countriesCities)
  } catch (err) {
    // do nothing for now
  }
}

export const resetAllAppSettings = () => dispatch => {
  sessionCacheService.resetCachedSocialPlatform()
  dispatch(resetAppSettings())
}
