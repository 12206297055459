import React from 'react'
import PropTypes from 'prop-types'
import { USER_INDEXES } from '../../../../../constants/appSettings'
import ComparisonToSimilarInfluencers from '../../../../qualityScores/ComparisonToSimilarInfluencers'
import ProgressBar from '../../../../common/progressBar'
import NoDataMessage from '../../NoDataMessage'
import {
  StyledSponsoredMediaScore,
  TitleWrapper,
  TitleBorder,
  Title,
  ScoreWrapper,
  ProgressBarWrapper,
  SponsoredValue,
  Label,
} from './styles'

const SponsoredMediaScore = ({ scoreName = '', scoreData = null, scoreLabel = '', isForPdf = false }) => {
  const { benchmarkArray, label, xAxisHigh, xAxisMid, xAxisLow } = scoreData || {
    benchmarkArray: [],
    label: '',
    xAxisHigh: null,
    xAxisMid: null,
    xAxisLow: null,
  }
  const xAxisValues = [xAxisHigh, xAxisMid, xAxisLow]

  return (
    <StyledSponsoredMediaScore>
      <TitleWrapper>
        {!isForPdf && <TitleBorder />}
        <Title>{scoreName}</Title>
      </TitleWrapper>

      {!!scoreData ? (
        <ScoreWrapper isForPdf={isForPdf}>
          <ProgressBarWrapper isForPdf={isForPdf}>
            <SponsoredValue isForPdf={isForPdf}>{label}</SponsoredValue>
            <Label isForPdf={isForPdf}>{scoreLabel}</Label>
            <ProgressBar
              current={+label.replace('%', '')}
              limit={100}
              {...(!isForPdf ? { platform: USER_INDEXES.instagram } : {})}
            />
          </ProgressBarWrapper>

          <ComparisonToSimilarInfluencers
            benchmarkArray={benchmarkArray}
            xAxisValues={xAxisValues}
            isTitleHidden
          />
        </ScoreWrapper>
      ) : (
        <NoDataMessage />
      )}
    </StyledSponsoredMediaScore>
  )
}

SponsoredMediaScore.propTypes = {
  scoreName: PropTypes.string,
  scoreData: PropTypes.shape({
    actualValue: PropTypes.number,
    benchmark: PropTypes.number,
    benchmarkArray: PropTypes.arrayOf(
      PropTypes.shape({
        height: PropTypes.number,
        p: PropTypes.number,
        isActive: PropTypes.bool,
        parsedValue: PropTypes.string,
        score: PropTypes.number,
        value: PropTypes.number,
      })
    ),
    label: PropTypes.string,
    score: PropTypes.number,
  }),
  scoreLabel: PropTypes.string,
  isForPdf: PropTypes.bool,
}

export default SponsoredMediaScore
