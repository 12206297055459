import React, { useEffect, useState } from 'react'
import { ModalComponent } from '../../../components/modal/ModalComponent'
import { Stack, Typography } from '@mui/material'
import { AddCreators } from './AddCreators'
import useTranslation from '../../../../localization/useTranslation'
import { AddLabel } from './AddLabel'
import { ImportFromCollection } from './ImportFromCollection'

const _BRANDS_OR_CREATORS = {
  BRANDS: 'brands',
  CREATORS: 'creators',
}

export const LookalikeAiModal = props => {
  const {
    openModalLookalikeAi,
    onCloseModalLookalikeAi,
    onHandleConfirmLookalike,
    setDataFromLookalike,
  } = props || {}

  const { labelStrings } = useTranslation()

  const [currentStep, setCurrentStep] = useState(0)

  const [currentType, setCurrentType] = useState(_BRANDS_OR_CREATORS.BRANDS)

  const [creators, setCreators] = useState([])

  const [isEnabledImportFromCollection, setIsEnabledImportFromCollection] = useState(false)

  const onConfirmFirstStep = ({ platform, userList }) => {
    setDataFromLookalike({ userList, platform })
    setCurrentStep(1)
  }

  const onConfirmLastStep = ({ label }) => {
    onHandleConfirmLookalike({ label })
    onClose()
  }

  const onClose = () => {
    onCloseModalLookalikeAi()
    setIsEnabledImportFromCollection(false)
    setCurrentStep(0)
    setDataFromLookalike({
      userList: [],
      platform: '',
    })
    setCreators([])
    setCurrentType(_BRANDS_OR_CREATORS.BRANDS)
  }

  useEffect(() => {
    setCurrentStep(0)
    setDataFromLookalike({
      userList: [],
      platform: '',
    })
    setCreators([])
    setIsEnabledImportFromCollection(false)
  }, [openModalLookalikeAi])

  return (
    <>
      <ModalComponent
        isOpenModal={openModalLookalikeAi}
        closeModal={onClose}
        title={labelStrings?.buildLookalike?.titleModal}
        smallPadding
        contentWidth="550px"
        contentHeight="auto"
        isCenterTitle
        withoutScrollWrapper
      >
        <Stack spacing={1}>
          {!isEnabledImportFromCollection && (
            <>
              {currentStep === 0 ? (
                <Typography sx={{ whiteSpace: 'pre-line', fontSize: '15px', textAlign: 'center' }}>
                  {labelStrings?.buildLookalike?.subtitleModal}
                </Typography>
              ) : (
                <Typography sx={{ whiteSpace: 'pre-line', fontSize: '15px', textAlign: 'center' }}>
                  {labelStrings?.buildLookalike?.pleaseAddALabel}
                </Typography>
              )}
            </>
          )}
          <Stack direction="row" justifyContent="center" sx={{ pb: 0.5 }}>
            {isEnabledImportFromCollection && currentStep === 0 ? (
              <ImportFromCollection
                setCreators={setCreators}
                setIsEnabledImportFromCollection={setIsEnabledImportFromCollection}
              />
            ) : (
              <>
                {currentStep === 0 ? (
                  <AddCreators
                    onConfirmFirstStep={onConfirmFirstStep}
                    openModalLookalikeAi={openModalLookalikeAi}
                    setIsEnabledImportFromCollection={setIsEnabledImportFromCollection}
                    setCurrentType={setCurrentType}
                    currentType={currentType}
                    creators={creators}
                    setCreators={setCreators}
                  />
                ) : (
                  <AddLabel onConfirmLastStep={onConfirmLastStep} />
                )}
              </>
            )}
          </Stack>
        </Stack>
      </ModalComponent>
    </>
  )
}
