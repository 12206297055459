import React from 'react'
import PropTypes from 'prop-types'
import {
  FormElement,
  Label,
  Tip,
  InputContainer,
  StyledInput,
  IconWrapper,
  ErrorMessage,
} from './styles'

const Input = ({
  id = '',
  name = '',
  type = 'text',
  label = '',
  tip = '',
  placeholder = '',
  value = '',
  onChange,
  icon = null,
  errorMessage = '',
  ...props
}) => {
  return (
    <FormElement className="form-element">
      {label && (
        <Label className="label" htmlFor={id} hasError={!!errorMessage}>
          {label}
        </Label>
      )}
      {tip && (
        <Tip className="tip" hasError={!!errorMessage}>
          {tip}
        </Tip>
      )}
      <InputContainer className="input-container">
        <StyledInput
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className="input"
          hasError={!!errorMessage}
          {...props}
        />
        {icon && <IconWrapper className="icon-wrapper">{icon}</IconWrapper>}
        {!!errorMessage && <ErrorMessage className="error-message">{errorMessage}</ErrorMessage>}
      </InputContainer>
    </FormElement>
  )
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'number', 'email', 'password', 'url', 'date']),
  label: PropTypes.string,
  tip: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  icon: PropTypes.node,
  errorMessage: PropTypes.string,
}

export default Input
