export const disabledHorisontalMultiChartData = () => {
  const createMockedItem = percent => ({
    value: percent,
    label: percent && percent.toString(),
    barTip: {
      percent: `${percent}%`,
      count: `(${percent})`,
    },
  })
  return [100, 70, 50, 25, 10].map(num => createMockedItem(num))
}

export const disabledHorisontalSingleChartData = () => {
  const createMockedItem = num => ({
    value: num,
    label: num && num.toString(),
    barTip: {
      percent: `${num}%`,
    },
  })
  return [100, 70, 50, 25, 10].map(num => createMockedItem(num))
}

export const disabledPieChartData = ({ items = 4 }) => {
  const mockedNamesMap = {
    0: { text: 'mock1', color: '#F4E8E8', value: 50 },
    1: { text: 'mock2', color: '#EED9D9', value: 25 },
    2: { text: 'mock3', color: '#F4E8E8', value: 15 },
    3: { text: 'mock4', color: '#EED9D9', value: 10 },
  }
  const mockedArr = Array.from(new Array(items).fill(0), (item, idx) => idx)
  return mockedArr.reduce(
    (finalObj, item) => {
      finalObj.values.push([`${mockedNamesMap[item].text}`, mockedNamesMap[item].value])
      finalObj.colors.push(mockedNamesMap[item].color)
      return finalObj
    },
    { values: [['Type', 'Value']], colors: [] }
  )
}

export const disabledVerticalChartData = () => {
  return [100, 70, 50, 25, 10].map(item => {
    return {
      label: item && item.toString(),
      value: item,
      barTip: `${item}% (${item})`,
      grayed: true,
    }
  })
}

export const disabledLineChartData = () => {
  return [
    ['date', 'Followers'],
    ['2019-1-15', 330650],
    ['2019-8-15', 370650],
    ['2019-8-21', 376374],
    ['2019-9-3', 379035],
    ['2019-9-6', 380740],
    ['2019-9-8', 383590],
    ['2019-9-16', 391969],
    ['2019-9-18', 393219],
    ['2019-9-21', 394884],
    ['2019-9-24', 396802],
    ['2019-10-4', 404175],
    ['2019-10-9', 408131],
    ['2019-10-15', 411023],
    ['2019-12-29', 480805],
    ['2020-2-5', 513730],
    ['2020-4-6', 572153],
  ]
}

const disabledChartData = {
  disabledHorisontalMultiChartData,
  disabledHorisontalSingleChartData,
  disabledPieChartData,
  disabledVerticalChartData,
  disabledLineChartData,
}

export default disabledChartData
