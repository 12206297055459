import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import { WithPopupWrapper } from '../styledWrappers'
import CampaignsBuySeatsDialog from '../dialogs/CampaignsBuySeatsDialog'
import CampaignSuccessSeatsChangeDialog from '../dialogs/CampaignSuccessSeatsChangeDialog'

const BuyCampaignsSeatsModal = ({ isModalOpened = false, isChangeSeatsSuccess = false, ...restOfProps }) => {
  const { closeModal } = restOfProps
  return (
    <WithPopupWrapper isTransparent fullScreen enableHeader withBlurBG aboveAll>
      <Popup
        open={isModalOpened}
        position="right center"
        closeOnDocumentClick={false}
        closeOnEscape={false}
        modal
      >
        {close =>
          isChangeSeatsSuccess ? (
            <CampaignSuccessSeatsChangeDialog closeModal={closeModal} />
          ) : (
            <CampaignsBuySeatsDialog {...restOfProps} />
          )
        }
      </Popup>
    </WithPopupWrapper>
  )
}

BuyCampaignsSeatsModal.propTypes = {
  isModalOpened: PropTypes.bool,
  isChangeSeatsSuccess: PropTypes.bool,
}

export default memo(BuyCampaignsSeatsModal)
