import React from 'react'
import { Grid, Button } from '@mui/material'
import AddRoundedIcon from '@mui/icons-material/AddRounded'

export const TopControls = ({ onCreateCustomField, isLoading }) => {
  return (
    <Grid
      container
      sx={{
        justifyContent: 'flex-end',
      }}
    >
      <Grid item xs={12} md={1.5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          disabled={isLoading}
          startIcon={<AddRoundedIcon />}
          variant="outlined"
          sx={{ fontSize: '14px', lineHeight: '24px', fontWeight: 700 }}
          onClick={onCreateCustomField}
        >
          New Goal
        </Button>
      </Grid>
    </Grid>
  )
}
