import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import useOutsideClick from '../../../../hooks/useOutsideClick'
import { ADDITIONAL_FIELD_PROP_TYPE } from '../../../../constants/additionalFields'
import { themeCommonStyles } from '../../../../themes/infludataThemes'
import { ArrowIcon, ArrowInCircleIcon, CloseIcon, TickIcon } from '../../../common/icons/icons'
import {
  MultiSelectSelectedItems,
  MultiSelectSelectedItem,
  MultiSelectSelectedItemText,
  MultiSelectSelectedItemRemoveButton,
  MultiSelectOption,
  MultiSelectCheckbox,
  MultiSelectOptionValue,
  MultiSelectScrollButton,
  AdditionalFieldInputIconWrapper,
  SelectOptionsDropdown,
} from './styles'

const SCROLL_WIDTH = 200 //250

const AdditionalFieldMultiSelect = ({ additionalFieldData, onChange }) => {
  const { code: id, value: selectedOptionIds, allowedValues: options } = additionalFieldData

  const [isSelectOpened, setIsSelectOpened] = useState(false)
  const [hasScroll, setHasScroll] = useState(false)

  const optionsDropdownRef = useRef(null)
  const selectedItemsRef = useRef(null)

  useEffect(() => {
    setHasScroll(selectedItemsRef.current.scrollWidth > SCROLL_WIDTH)
  }, [additionalFieldData])

  useOutsideClick({
    ref: optionsDropdownRef,
    callback: event => {
      if (!event.target.closest(`#af-${id}__button_open-select`)) {
        setIsSelectOpened(false)
      }
    },
  })

  const onScrollSelectedItems = scrollTo => {
    const currentScroll = selectedItemsRef.current.scrollLeft
    let nextScroll =
      scrollTo === 'left' ? currentScroll - SCROLL_WIDTH : currentScroll + SCROLL_WIDTH
    selectedItemsRef.current.scroll(nextScroll, 0)
  }

  const toggleSelectOptionById = optionId => {
    const isSelected = selectedOptionIds?.includes(optionId)

    if (isSelected) {
      onChange(selectedOptionIds?.filter(value => value !== optionId))
    } else {
      onChange([...(selectedOptionIds || []), optionId])
    }
  }

  const openSelect = () => setIsSelectOpened(true)

  return (
    <>
      <MultiSelectSelectedItems ref={selectedItemsRef} hasScroll={hasScroll}>
        {selectedOptionIds?.map(selectedOptionId => {
          const option = options?.find(option => option.id === selectedOptionId)
          if (!option) return null
          const onClick = () => toggleSelectOptionById(option.id)

          return (
            <MultiSelectSelectedItem key={selectedOptionId} hasScroll={hasScroll}>
              <MultiSelectSelectedItemText>{option.value}</MultiSelectSelectedItemText>
              <MultiSelectSelectedItemRemoveButton onClick={onClick}>
                <CloseIcon color={themeCommonStyles.colors.brown} />
              </MultiSelectSelectedItemRemoveButton>
            </MultiSelectSelectedItem>
          )
        })}
      </MultiSelectSelectedItems>

      {hasScroll && (
        <>
          <MultiSelectScrollButton isScrollToLeft onClick={() => onScrollSelectedItems('left')}>
            <ArrowInCircleIcon direction="left" />
          </MultiSelectScrollButton>

          <MultiSelectScrollButton isScrollToRight onClick={() => onScrollSelectedItems('right')}>
            <ArrowInCircleIcon direction="right" />
          </MultiSelectScrollButton>
        </>
      )}

      <AdditionalFieldInputIconWrapper id={`af-${id}__button_open-select`} onClick={openSelect}>
        <ArrowIcon />
      </AdditionalFieldInputIconWrapper>

      {isSelectOpened && (
        <SelectOptionsDropdown ref={optionsDropdownRef}>
          {options?.map(option => {
            const isSelected = selectedOptionIds?.includes(option.id)
            const onClick = () => toggleSelectOptionById(option.id)

            return (
              <MultiSelectOption key={option.id} isSelected={isSelected} onClick={onClick}>
                <MultiSelectCheckbox isSelected={isSelected}>
                  <TickIcon />
                </MultiSelectCheckbox>
                <MultiSelectOptionValue>{option.value}</MultiSelectOptionValue>
              </MultiSelectOption>
            )
          })}
        </SelectOptionsDropdown>
      )}
    </>
  )
}

AdditionalFieldMultiSelect.propTypes = {
  additionalFieldData: ADDITIONAL_FIELD_PROP_TYPE.isRequired,
  onChange: PropTypes.func,
}

export default AdditionalFieldMultiSelect
