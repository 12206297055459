import React from 'react'
import { Box, MenuItem, Stack, Typography } from '@mui/material'
import { RHFSelect } from '../../../components/hook-form'
import useTranslation from '../../../../localization/useTranslation'

export const StepFive = () => {
  const { labelStrings } = useTranslation()
  return (
    <Stack spacing={1.5} alignItems="center">
      <Typography
        align="center"
        component="label"
        sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '24px', mb: 1 }}
      >
        {labelStrings.createCampaign.steps.five.label}
      </Typography>
      <Stack alignItems="center">
        <Box sx={{ width: '50%' }}>
          <RHFSelect
            name="campaignCurrency"
            label={labelStrings.createCampaign.steps.five.fieldLabel}
            InputLabelProps={{ shrink: true }}
            defaultValue={'EUR'}
          >
            {[
              { value: 'EUR', label: 'EUR' },
              { value: 'USD', label: 'USD' },
            ]?.map(currency => (
              <MenuItem key={currency.value} value={currency.value}>
                {currency.label}
              </MenuItem>
            ))}
          </RHFSelect>
        </Box>

        <Typography
          sx={{
            color: '#637381',
            lineHeight: 1.5,
            fontSize: `0.75rem`,
            fontFamily: `Public Sans,sans-serif`,
            fontWeight: 400,
            textAlign: `left`,
            marginTop: 2,
            marginRight: `14px`,
            marginBottom: 0,
            marginLeft: `14px`,
          }}
        >
          {labelStrings.createCampaign.steps.five.helperText}
        </Typography>
      </Stack>
    </Stack>
  )
}
