import styled from 'styled-components'
import { convertHexToRgba } from '../../utils'
import { ProfileSectionCommonHeader } from '../common/styledWrappers'

export const ChartWrapper = styled.div`
  margin-top: 10px;
  min-height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 900px;

  & .line-chart-custom-tooltip {
    position: absolute;
    bottom: -10px;
    right: -70px;
    min-width: 100px;
    padding: 5px 10px;
    border: 0.5px solid ${props => props.theme.colors.mediumBrown};
    border-radius: 12px;
    background: ${props => convertHexToRgba(props.theme.colors.white, 0.7)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__followers {
      font-family: ${props => props.theme.fontFamilyInter};
      ${props => props.theme.getTextGradientStyles({ platform: props.userindex })};
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
      margin-bottom: 1px;
      white-space: nowrap;
    }

    &__date {
      font-family: ${props => props.theme.fontFamilyInter};
      ${props => props.theme.getTextGradientStyles({ platform: props.userindex })};
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      white-spacnowrape
    }
  }
`

export const HorizontalBarChartInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${props => (!props.isForPdf ? (props.isSmall ? '15px' : '50px') : '15px')};
`

export const ChartName = styled.div`
  font-family: ${props => props.theme.fontFamilyInter};
  font-size: 15px;
  line-height: 19px;
  text-transform: uppercase;
  font-weight: bold;
  color: ${props => props.theme.colors.darkBrown};
`

export const ChartTitle = styled(ProfileSectionCommonHeader)`
  position: absolute;
  top: 10px;
  left: 50px;
  z-index: 1;
  & > span {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 4.9px;
    color: ${props => props.theme.pickColorDependOnPlatform(props.userindex).primary};
    font-family: ${props => props.theme.fontFamilyInter};
    text-transform: uppercase;
  }
`

export const ChartTitletextWrapper = styled.span`
  position: relative;
  width: fit-content;
  display: block;
`

export const FaqtipWrapper = styled.div`
  & > * {
    line-height: initial;
    letter-spacing: initial;
    font-weight: initial;
    text-transform: none;
    font-family: ${props => props.theme.fontFamilyInter};
  }
`
