import React from 'react'
import PropTypes from 'prop-types'
import { USER_INDEXES } from '../../../constants/appSettings'
import AudienceGenderChart from './audienceAnalysisCharts/AudienceGenderChart'
import AudienceLanguageChart from './audienceAnalysisCharts/AudienceLanguageChart'
import AudienceTypeChart from './audienceAnalysisCharts/AudienceTypeChart'
import { AudienceAnalysisChartsGrid, AudienceAnalysisChartsGridItem } from './styles'
import AudienceTagsCloud from './audienceAnalysisCharts/AudienceTagsCloud'

const AudienceAnalysisChartsYT = ({
  audienceAnalysis = {
    languageArray: [],
    genderObject: {},
    sentimentAnalysis: {},
    wordCloud: []
  },
  platform = '',
  followersNumber = 0,
  isDisabledAnalysis = false,
  hasSmartMediaValue = false,
}) => {
  const { languageArray, genderObject, sentimentAnalysis, wordCloud } = audienceAnalysis

  return (
    <AudienceAnalysisChartsGrid
      isDisabledAnalysis={isDisabledAnalysis}
      hasSmartMediaValue={hasSmartMediaValue}
    >
      <AudienceAnalysisChartsGridItem>
        <AudienceLanguageChart
          userindex={platform}
          languageArray={languageArray}
          isDisabledAnalysis={isDisabledAnalysis}
          followersCount={followersNumber}
        />
      </AudienceAnalysisChartsGridItem>

      <AudienceAnalysisChartsGridItem>
        <AudienceGenderChart
          userindex={platform}
          genderMtoF={genderObject}
          isDisabledAnalysis={isDisabledAnalysis}
        />
      </AudienceAnalysisChartsGridItem>

      <AudienceAnalysisChartsGridItem>
        <AudienceTypeChart
          userindex={platform}
          audienceType={sentimentAnalysis}
          isDisabledAnalysis={isDisabledAnalysis}
        />
      </AudienceAnalysisChartsGridItem>

      <AudienceAnalysisChartsGridItem>
        <AudienceTagsCloud userindex={platform} data={wordCloud} />
      </AudienceAnalysisChartsGridItem>
    </AudienceAnalysisChartsGrid>
  )
}

AudienceAnalysisChartsYT.propTypes = {
  audienceAnalysis: PropTypes.shape({
    sentimentAnalysis: PropTypes.object,
    genderObject: PropTypes.object,
    languageArray: PropTypes.array,
    wordCloud: PropTypes.array,
  }),
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
  isDisabledAnalysis: PropTypes.bool,
  hasSmartMediaValue: PropTypes.bool,
  followersNumber: PropTypes.number
}

export default AudienceAnalysisChartsYT
