import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Popup from 'reactjs-popup'
import styled from 'styled-components'
import { withReduxActions } from '../../hocs/withRedux'
import { editCollection, deleteCollection } from '../../redux/ducks/collectionsDucks'
import { isSubUserSelector, userGrantSelector } from '../../redux/selectors'
import useTranslation from '../../localization/useTranslation'
import {
  COLLECTIONS_ACCESS_TYPES,
  FAV_COLLECTION_NAME,
  UNLOCKED_COLLECTION_NAME,
} from '../../constants/collections'

import GradientTransparentButton from '../common/buttons/GradientTransparentButton'
import CollectionOptionsItems from './CollectionOptionsItems'
import CollectionOptionsRenderer from './CollectionOptionsRenderer'

const Wrapper = styled.div`
  ${props =>
    props.withWidth &&
    `
    .popup-content,
    .collection-sidebar-menu-content {
        width: 170px !important;
    }
  `}
`

const CollectionOptionsContainer = ({
  collection,
  enableExports = false,
  editCollection,
  deleteCollection,
}) => {
  const [chosenItem, setChosenItem] = useState(null)
  const history = useHistory()
  const { labelStrings } = useTranslation()
  const { in_trial: isTrialUser } = useSelector(userGrantSelector)
  const isSubUser = useSelector(isSubUserSelector)

  const isFavCollection = collection.collectionId === FAV_COLLECTION_NAME
  const isUnlockedCollection = collection.collectionId === UNLOCKED_COLLECTION_NAME
  const isPublicCollection = collection.access === COLLECTIONS_ACCESS_TYPES.public

  const isDisabledCollectionChanging =  isFavCollection || isUnlockedCollection

  const itemClickHandler = close => type => () => {
    close()
    setChosenItem(type)
  }

  const resetChosenItem = () => setChosenItem(null)

  const editCollectionHandler = newValuesObject => editCollection(newValuesObject)

  const deleteCollectionHandler = () => {
    deleteCollection(collection.collectionId)

    history.replace(`${process.env.REACT_APP_PREFIX}collections/fav`)
  }

  return (
    <Wrapper withWidth={!chosenItem}>
      <Popup
        trigger={
          <div>
            <GradientTransparentButton>{labelStrings.options}</GradientTransparentButton>
          </div>
        }
        className={'collection-sidebar-menu'}
        position={'bottom right'}
        on="hover"
      >
        {close => (
          <CollectionOptionsItems
            itemClickHandler={itemClickHandler(close)}
            enableExports={enableExports}
            isTrialUser={isTrialUser}
            disableCollectionChange={isDisabledCollectionChanging}
          />
        )}
      </Popup>
      <CollectionOptionsRenderer
        isEditOpen={chosenItem === 'edit'}
        isDeleteOpen={chosenItem === 'remove'}
        getCsv={chosenItem === 'csv'}
        collection={collection}
        editCollection={editCollectionHandler}
        deleteCollection={deleteCollectionHandler}
        resetChosenItem={resetChosenItem}
      />
    </Wrapper>
  )
}

CollectionOptionsContainer.propTypes = {
  collection: PropTypes.object.isRequired,
  enableExports: PropTypes.bool,
  editCollection: PropTypes.func,
  deleteCollection: PropTypes.func,
}

export default withReduxActions({ editCollection, deleteCollection })(CollectionOptionsContainer)
