import React, { useState } from 'react'
import PropTypes from 'prop-types'

import useTranslation from '../../../localization/useTranslation'
import {
  MENTION_PREFIX,
  HASHTAG_PREFIX,
  MAX_NAME_LIMIT,
  MAX_DESCRIPTION_LIMIT,
  USER_INDEXES,
} from '../../../constants/appSettings'
import { withReduxActions } from '../../../hocs/withRedux'
import { createCampaign, fetchUpdateCampaign } from '../../../redux/ducks/campaignsDucks'

import Modal from '../../common/modals/Modal/Modal'
import Input from '../../common/inputs/Input'
import Textarea from '../../common/textarea/Textarea'
import MultiInput from '../../common/selects/MultiInput'
import Spinner from '../../common/spinners/Spinner'
import { GradientButton } from '../../common/buttons/styledButtons'
import { CreateCampaignModalWrapper, FlexRow } from './commonStyles'

const createMentionsAndHashtagsValuesArr = (mentions = [], hashtags = []) =>
  [...mentions, ...hashtags].map(item => ({ label: item, value: item }))

const prepareMentionsHashtagsValues = mentionsHashtagsArr => {
  const prepared = {
    mentions: [],
    hashtags: [],
  }
  mentionsHashtagsArr.forEach(item => {
    if (item.value && item.value[0] === MENTION_PREFIX) {
      prepared.mentions.push(item.value)
    } else if (item.value && item.value[0] === HASHTAG_PREFIX) {
      prepared.hashtags.push(item.value)
    }
  })
  return prepared
}

const EditCampaignModal = ({
  campaignData = {},
  platform = '',
  isNewCampaign = false,
  closeModal,
  fetchUpdateCampaign,
  createCampaign,
  withoutPortal = false,
}) => {
  const { labelStrings, errors } = useTranslation()

  const [campaignState, setCampaignState] = useState({
    name: campaignData?.name,
    description: campaignData?.description,
    storyCTA: campaignData?.storyCTA,
    mentionsHashtags: createMentionsAndHashtagsValuesArr(
      campaignData?.mentions,
      campaignData?.hashtags
    ),
  })
  const [campaignNameError, setCampaignNameError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onChangeCampaignName = event => {
    setCampaignNameError('')
    setCampaignState({ ...campaignState, name: event.target.value })
  }

  const onChangeCampaignDescription = event =>
    setCampaignState({ ...campaignState, description: event.target.value })

  const onChangeStoryCTA = event =>
    setCampaignState({ ...campaignState, storyCTA: event.target.value })

  const onChangeMentionsHashtags = valuesArr => {
    let updatedMentionsHashtags = []

    if (valuesArr) {
      const newValuesArr = valuesArr?.map(({ value }) => {
        const mentionHashtagsCharacter = value[0]
        const exactWord = value?.replace(/\s/g, '')?.split(/(?:@|#)+/)
        const correctMentionsHashtags = `${mentionHashtagsCharacter}${exactWord[1]}`
        return {
          label: correctMentionsHashtags,
          value: correctMentionsHashtags,
        }
      })
      const uniqValuesArr = [...new Map(newValuesArr?.map(v => [v.value, v])).values()]

      updatedMentionsHashtags = uniqValuesArr?.filter(({ label, value }) => {
        if ((value[0] === MENTION_PREFIX || value[0] === HASHTAG_PREFIX) && value[1]) {
          return { label, value }
        }
      })
    }
    setCampaignState({
      ...campaignState,
      mentionsHashtags: updatedMentionsHashtags,
    })
  }

  const validateMentionHashtagsInput = userInput => {
    if (userInput && (userInput[0] === MENTION_PREFIX || userInput[0] === HASHTAG_PREFIX)) {
      return `${labelStrings.create} ${userInput}`
    }
    return `${labelStrings.mentionsHashTagValidationTip}`
  }

  const onSaveCampaign = async () => {
    if (!campaignState.name) {
      return setCampaignNameError(errors.fieldIsRequired)
    }
    setIsLoading(true)

    const { name, description: desc, storyCTA, mentionsHashtags } = campaignState
    const { mentions, hashtags } = prepareMentionsHashtagsValues(mentionsHashtags)
    const handler = isNewCampaign ? createCampaign : fetchUpdateCampaign

    await handler({ name, desc, mentions, hashtags, storyCTA })
    setIsLoading(false)
    closeModal()
  }

  return (
    <Modal
      headerContent={isNewCampaign ? labelStrings.createNewCampaign : labelStrings.editCampaign}
      platform={platform}
      closeModal={closeModal}
      withoutPortal={withoutPortal}
    >
      <CreateCampaignModalWrapper>
        <Input
          id="campaign-name"
          name="campaign-name"
          label={labelStrings.campaignName}
          value={campaignState.name}
          maxLength={MAX_NAME_LIMIT}
          errorMessage={campaignNameError}
          onChange={onChangeCampaignName}
        />

        <Textarea
          id="campaign-description"
          name="campaign-description"
          value={campaignState.description}
          label={`${labelStrings.campaignDescription} (${labelStrings.optional})`}
          maxLength={MAX_DESCRIPTION_LIMIT}
          onChange={onChangeCampaignDescription}
        />

        <Input
          id="campaign-story-cta"
          name="campaign-story-cta"
          label={labelStrings.storyCTALabel}
          tip={labelStrings.storyCTAOptionalLabel}
          value={campaignState.storyCTA}
          maxLength={100}
          onChange={onChangeStoryCTA}
        />

        <MultiInput
          values={campaignState.mentionsHashtags}
          labelText={labelStrings.mentionsAndHashtags}
          noOptionsMessageText={labelStrings.mentionHashtagsTip}
          onChange={onChangeMentionsHashtags}
          validateInputFunc={validateMentionHashtagsInput}
        />

        <FlexRow>
          <GradientButton platform={platform} onClick={onSaveCampaign}>
            {isNewCampaign ? labelStrings.startCampaign : labelStrings.updateCampaign}
          </GradientButton>
        </FlexRow>

        {isLoading && <Spinner isOverlay />}
      </CreateCampaignModalWrapper>
    </Modal>
  )
}

EditCampaignModal.propTypes = {
  campaignData: PropTypes.shape({
    campaignId: PropTypes.string,
    count: PropTypes.number,
    created: PropTypes.string,
    description: PropTypes.string,
    hashtags: PropTypes.arrayOf(PropTypes.string),
    mentions: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    storyCTA: PropTypes.string,
  }),
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube]),
  isNewCampaign: PropTypes.bool,
  closeModal: PropTypes.func,
  withoutPortal: PropTypes.bool,
}

export default withReduxActions({ fetchUpdateCampaign, createCampaign })(EditCampaignModal)
