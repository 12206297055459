import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, useTheme } from '@mui/material'
import { SmallCardWidget } from '../../../components/cardWidgets/SmallCardWidget'
import useTranslation from '../../../../localization/useTranslation'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { TooltipComponent } from '../../../components/tooltip/TooltipComponent'

export const CpmCpe = ({ cpmOrganicData = [], cpeOrganicData = [], emvData = [], isPdf = false }) => {
  const { labelStrings } = useTranslation()
  const theme = useTheme()
  const dangerColor = theme.palette.primary.lighter

  const totalWidgets = (cpmOrganicData?.length || 0) + (cpeOrganicData?.length || 0) + (emvData?.length || 0);

  const renderTooltip = message => (
    <Box sx={{ position: 'relative', ml: '1rem', mt: '1rem' }}>
      <TooltipComponent text={message} placement="bottom-start">
        <InfoOutlinedIcon style={{ fill: '#d0d5d9', cursor: 'pointer' }} />
      </TooltipComponent>
    </Box>
  )

  const renderCpmWidget = (cpm) => {
    const icon = {
      ['cpm_organic_ig']: 'skill-icons:instagram',
      ['cpm_organic_yt']: 'logos:youtube-icon',
      ['cpm_organic_tt']: 'logos:tiktok-icon',
      ['cpm_organic_all']: 'Overall',
    }

    return (
      <Grid item xs={12} md={2} key={cpm.graphId}>
        <SmallCardWidget
            total={cpm?.totalCPM}
            title={labelStrings.reach}
            subTitle={`CPM (organic)`}
            iconText={cpm.graphId === 'cpm_organic_all' && icon[cpm.graphId]}
            icon={icon[cpm.graphId]}
            color="#006097"
            dangerColor={dangerColor}
            сpmAvg={cpm?.overallCpmMedian}
            postMedianAvg={cpm?.postCpm}
            reelsMedianAvg={cpm?.reelsCpm}
            storiesMedianAvg={cpm?.storiesCpm}
            shortsMedianAvg={cpm?.shortsCpmAverage}
            videosMedianAvg={cpm?.videosCpmAverage}
            minCreatorValue={cpm?.minCreatorValue}
            maxCreatorValue={cpm?.maxCreatorValue}
            totalReachAvg={cpm?.totalReach}
            totalSpendAvg={cpm?.totalSpend}
            totalContentAvg={cpm?.totalContent}
            missingCreators={cpm?.missingCreators}
            isPdf={isPdf}          
        />
      </Grid>
    )
  }

  const renderCpeWidget = (cpe) => {
    const icon = {
      ['cpe_organic_ig']: 'skill-icons:instagram',
      ['cpe_organic_yt']: 'logos:youtube-icon',
      ['cpe_organic_tt']: 'logos:tiktok-icon',
      ['cpe_organic_all']: 'Overall',
    }

    return (
      <Grid item xs={12} md={2} key={cpe.graphId}>
        <SmallCardWidget
          total={cpe?.totalCPE}
          title={labelStrings.reach}
          subTitle={`CPE (organic)`}
          iconText={cpe.graphId === 'cpe_organic_all' && icon[cpe.graphId]}
          icon={icon[cpe.graphId]}
          color="#006097"
          dangerColor={dangerColor}
          postMedianAvg={cpe?.postCpe}
          reelsMedianAvg={cpe?.reelsCpe}
          shortsMedianAvg={cpe?.shortsCpe}
          videosMedianAvg={cpe?.videosCpe}
          minCreatorValue={cpe?.minCreatorValue}
          maxCreatorValue={cpe?.maxCreatorValue}
          totalReachAvg={cpe?.totalEngagement}
          totalSpendAvg={cpe?.totalSpend}
          totalContentAvg={cpe?.totalContent}
          missingCreators={cpe?.missingCreators}
          isPdf={isPdf}
        />
      </Grid>
    )
  }

  const renderEmvWidget = (emv) => {
    const icon = {
      ['emv_ig']: 'skill-icons:instagram',
      ['emv_yt']: 'logos:youtube-icon',
      ['emv_tt']: 'logos:tiktok-icon',
      ['emv_all']: 'Overall',
    }

    return (
      <Grid item xs={12} md={2} key={emv.graphId}>
        <SmallCardWidget
          total={emv?.overallEmv}
          title={''}
          subTitle={`Earned Media Value`}
          iconText={emv.graphId === 'emv_all' && icon[emv.graphId]}
          icon={icon[emv.graphId]}
          color="#006097"
          averagePerContent={emv?.averageEmvPerContent}
          postMedianAvg={emv?.overallEmvPosts}
          reelsMedianAvg={emv?.overallEmvReels}
          storiesMedianAvg={emv?.overallEmvStories}
          videosMedianAvg={emv?.videosCpmAverage}
          shortsMedianAvg={emv?.shortsCpmAverage}
          isPdf={isPdf}
        />
      </Grid>
    )
  }

  if (!cpmOrganicData?.length && !cpeOrganicData?.length && !emvData?.length) return null

  if (totalWidgets <= 6) {
    return (
      <Grid container spacing={isPdf ? 1.5 : 3}>
        {cpmOrganicData?.map(cpm => renderCpmWidget(cpm))}
        {!isPdf && cpmOrganicData?.length ? renderTooltip(labelStrings.cpmInfo) : null}
        
        {cpeOrganicData?.map(cpe => renderCpeWidget(cpe))}
        {!isPdf && cpeOrganicData?.length ? renderTooltip(labelStrings.cpeInfo) : null}
        
        {emvData?.map(emv => renderEmvWidget(emv))}
        {!isPdf && emvData?.length ? renderTooltip(labelStrings.emvInfo) : null}
      </Grid>
    )
  }

  return (
  <>
    <>
      {!!cpmOrganicData?.length && (
        <Grid container spacing={isPdf ? 1.5 : 3} sx={{ pb: 1 }}>
          {cpmOrganicData?.map(cpm => renderCpmWidget(cpm))}
          {!isPdf ? renderTooltip(labelStrings.cpmInfo) : null}
        </Grid>
      )}

      {!!cpeOrganicData?.length && (
        <Grid container spacing={isPdf ? 1.5 : 3} sx={{ pt: 1, pb: 1 }}>
          {cpeOrganicData?.map(cpe => renderCpeWidget(cpe))}
          {!isPdf ? renderTooltip(labelStrings.cpeInfo) : null}
        </Grid>
      )}

      {!!emvData?.length && (
        <Grid container spacing={isPdf ? 1.5 : 3} sx={{ pb: 2, pt: 1 }}>
          {emvData?.map(emv => renderEmvWidget(emv))}
          {!isPdf ? renderTooltip(labelStrings.emvInfo) : null}
        </Grid>
      )}
    </>
  </>
  )
}

CpmCpe.propTypes = {
  cpmOrganicData: PropTypes.array,
  cpeOrganicData: PropTypes.array,
  emvData: PropTypes.array,
  isPdf: PropTypes.bool,
}