import React, { useState } from 'react'
import AddCreatorsTo from '../../components/add-creator'
import { Backdrop, Box, Fade, Modal, Stack, Typography } from '@mui/material'

const defaultCreatorConfig = {
  inColumnId: '',
  creatorId: '',
  cardId: '',
  subText: '',
  dueDate: null,
  assignedTo: [],
  priority: 'none',
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '600px',
  bgcolor: 'background.paper',
  borderRadius: '12px',
  p: 4,
}

export const PipelineModalAddCreator = props => {
  const { openModal, closeModal, handleAddTask } = props || {}

  const [creatorIdFromState, setCreatorIdFromState] = useState('')

  const onHandleAddCreator = creatorId => {
    setCreatorIdFromState(creatorId)
    handleAddTask({
      ...defaultCreatorConfig,
      creatorId: creatorId,
    })
    closeModal()
  }

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      keepMounted
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 350,
        },
      }}
    >
      <Fade in={openModal}>
        <Box sx={style}>
          <Typography
            variant="h6"
            component="h2"
            sx={{ color: '#212B36', fontWeight: 700, fontSize: '18px', lineHeight: '28px', pb: 2 }}
          >
            {`Add Creator`}
          </Typography>
          <Stack sx={{ width: '100%', py: 1 }} spacing={2}>
            <AddCreatorsTo
              isAllowedToAddCreators={true}
              creatorIdFromState={creatorIdFromState}
              setCreatorIdFromState={onHandleAddCreator}
            />
          </Stack>
        </Box>
      </Fade>
    </Modal>
  )
}
