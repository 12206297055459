import React from 'react'
import PropTypes from 'prop-types'

import { USER_INDEXES } from '../../../constants/appSettings'
import useTranslation from '../../../localization/useTranslation'

import UnavailableProfileSection from '../../common/unavailableProfileSection'
import { TrSkeleton, TdSkeleton, TdWrapperSkeleton, AvatarSkeleton, ValueSkeleton } from './styles'

const arrThreeItems = new Array(3).fill()

const SimilarAccountsSkeleton = ({ platform }) => {
  const { labelStrings } = useTranslation()

  return arrThreeItems.map((_, index) => {
    if (index === 1) {
      return (
        <TrSkeleton key={index}>
          <TdSkeleton colSpan="4">
            <TdWrapperSkeleton>
              <AvatarSkeleton />
              <UnavailableProfileSection
                unavailableSectionLabel={labelStrings.toSeeSimilarAccounts}
                faqQuestionId=""
                linkToFaqText={labelStrings.whatAreSimilarAccounts}
                platform={platform}
              />
            </TdWrapperSkeleton>
          </TdSkeleton>
        </TrSkeleton>
      )
    }
    return (
      <TrSkeleton key={index}>
        <TdSkeleton>
          <TdWrapperSkeleton>
            <AvatarSkeleton />
          </TdWrapperSkeleton>
        </TdSkeleton>
        <TdSkeleton>
          <TdWrapperSkeleton>
            <ValueSkeleton />
            <ValueSkeleton />
            <ValueSkeleton />
          </TdWrapperSkeleton>
        </TdSkeleton>
        <TdSkeleton>
          <TdWrapperSkeleton>
            <ValueSkeleton />
            <ValueSkeleton />
            <ValueSkeleton />
          </TdWrapperSkeleton>
        </TdSkeleton>
        <TdSkeleton>
          <TdWrapperSkeleton>
            <ValueSkeleton />
            <ValueSkeleton />
            <ValueSkeleton />
          </TdWrapperSkeleton>
        </TdSkeleton>
      </TrSkeleton>
    )
  })
}

SimilarAccountsSkeleton.propTypes = {
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube])
    .isRequired,
}

export default SimilarAccountsSkeleton
