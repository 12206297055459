import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ProfileContentTable } from '../../profileCommon/profileListTable/profileContentUser/ProfileContentTable'
import { ListWrapper } from '../../common/styledWrappers'
import { useSelector } from 'react-redux'
import { searchLoadingStatusSelector } from '../../../redux/selectors'

export const CollectionSavedContent = ({ collectionData = [], openedCollection = {} }) => {
  const isSearchLoading = useSelector(searchLoadingStatusSelector)

  const [activeCard, setActiveCard] = useState({})

  return (
    <ListWrapper hasPagination>
      <ProfileContentTable
        content={collectionData}
        isSearchLoading={isSearchLoading}
        setActiveCard={setActiveCard}
        activeCard={activeCard}
        openedCollection={openedCollection}
      />
    </ListWrapper>
  )
}

CollectionSavedContent.propTypes = {
  collectionData: PropTypes.array,
  openedCollection: PropTypes.object
}