import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { isSubUserSelector } from '../../redux/selectors'
import { withReduxActions } from '../../hocs/withRedux'
import { changeCollectionAccess } from '../../redux/ducks/collectionsDucks'
import GradientTransparentButton from '../common/buttons/GradientTransparentButton'
import useTranslation from '../../localization/useTranslation'
import { COLLECTIONS_ACCESS_TYPES } from '../../constants/collections'
import ChangeAccessModal from '../collections/collectionTools/ChangeAccessModal'
import { CollectionAccessButtonWrapper } from '../common/styledWrappers'

const CollectionAccessSwitcher = ({ access = '', changeCollectionAccess, collectionId = '' }) => {
  const { labelStrings } = useTranslation()
  const isSubUser = useSelector(isSubUserSelector)

  const [isModalOpened, setModalOpened] = useState(false)
  const toggleModal = () => setModalOpened(!isModalOpened)

  const isPublic = !access || access === COLLECTIONS_ACCESS_TYPES.public
  const hideButton = isSubUser && isPublic

  const onChangeAccess = () => {
    toggleModal()
    if (!collectionId) return
    changeCollectionAccess({
      collectionId,
      access: isPublic ? COLLECTIONS_ACCESS_TYPES.private : COLLECTIONS_ACCESS_TYPES.public,
    })
  }

  return (
    !hideButton && (
      <CollectionAccessButtonWrapper isPublic={isPublic}>
        <GradientTransparentButton onClick={toggleModal}>
          {isPublic ? labelStrings.makePrivate : labelStrings.makePublic}
        </GradientTransparentButton>

        {isModalOpened && (
          <ChangeAccessModal
            currentAccessStatus={access}
            isSubUser={isSubUser}
            closeModal={toggleModal}
            changeAccessStatus={onChangeAccess}
          />
        )}
      </CollectionAccessButtonWrapper>
    )
  )
}

CollectionAccessSwitcher.propTypes = {
  access: PropTypes.string,
  changeCollectionAccess: PropTypes.func,
  collectionId: PropTypes.string,
}

export default withReduxActions({ changeCollectionAccess })(CollectionAccessSwitcher)
