import availableLangs from './availableLangs'
import { AVALIABLE_PACKAGES, PACKAGES_PRICES } from '../constants/user'

export default {
  // locales contain the featuresFullList obj with all avaliable features by categories.
  // each plan also contains disabled features arr, which consists of features obj keys for further checks
  // each plan also contains limits obj with particular limit strings for each feature. Its keys are same as keys from featuresFullList

  /* IMPORTANT NOTE: if some limit in plan has diff values for month/year, you need to use object with keys, e.g.:
  *   paymentMethods: {
        monthly: '[some string value]',
        yearly: '[some string value]',
      }
      PLEASE KEEP IN MIND, the keys MUST BE 'monthly' and 'yearly'. These keys are being searched in render component
  */

  [availableLangs.en]: {
    permonth: 'per month',
    peryear: 'per year',
    month12: '12 month',
    days90: '90 days',
    billedAnnually: 'billed annually',
    billedSumYear: sum => `billed ${sum}€ per year`,
    bindingYear: '12 months contract term',
    perYear: 'per year',
    per90days: 'per 90 days',
    getAllFeaturesFor: 'Get all features for',
    startingFrom: 'Get all features from',
    yourPlan: 'Your Plan',
    letUsHelpYou: 'Let us help you finding the perfect plan for your needs.',
    reportsIncluded: 'reports included',
    cancelPlan:
      'Are you sure? You will lose access to some features and possibly lose the data of your collections and campaigns.',
    cancelledToFreePlan:
      'Your plan is cancelled. You current subscription will stay active until the end of your term.',
    cancelledWillBeDowngraded: date =>
      `Your subscription is cancelled and you will be downgraded on ${date}`,
    reactivateTexts: {
      reactivateWarning:
        'If you reactivate your subscription, you need to set a payment method. When a payment method is set, it will be charged immediately.',
      expirationDateCancelled:
        'Your plan will stay active and will be renewed at the end of your term.',
      trialActiveUntil: date =>
        `Your trial is active until ${date} and ends automatically. Please get in contact with our sales team to start your subscription.`,
    },
    titles: {
      plan: 'plan',
      pricing: 'pricing',
    },
    featuresFullList: [
      {
        name: 'Find',
        items: {
          instagramInfluencers: 'Instagram Influencers',
          tiktokInfluencers: 'TikTok Influencers',
          brands: 'Brands',
          microMacroInfluencers: 'Micro- and Macro Influencers',
        },
      },
      {
        name: 'Limits',
        items: {
          searchRequests: 'Search Requests',
          resultsShown: 'Results shown',
          collections: 'Collections',
          userPerCollection: 'User per collection',
          audienceReports: 'Audience Reports',
          campaignTracking: 'Campaign Tracking',
          influencerTracking: 'Influencer Tracking',
          licences: 'Licences (Log-Ins)',
        },
      },
      {
        name: 'Features',
        items: {
          searchBy: 'Search by Country, City, Followers, Keywords and Gender',
          basicInfluencerAnalysis: 'Influencer Quality Analysis',
          specificKeywordSearch: 'Specific Keyword Search',
          favorites: 'Favorites',
          emailAvaliable: 'E-Mails available',
          privateNotes: 'Private Notes',
          privateRating: 'Private Rating',
          privatePublicCollections: 'Private/Public Collections',
          profilePdfExports: 'Profile PDF Exports',
          csvExports: 'CSV Exports',
          customerSupport: 'Customer Support',
          paymentMethods: 'Payment Methods',
        },
      },
    ],
    plans: [
      // FREE PLAN IS HIDDEN, maybe in future it will be restored
      // {
      //   name: 'Free',
      //   planId: AVALIABLE_PACKAGES.free,
      //   desc:
      //     'Search and analyze influencers with our free package. Fast and reliable. No credit card needed!',
      //   price: PACKAGES_PRICES[AVALIABLE_PACKAGES.free].month,
      //   disabled: [
      //     'brands',
      //     'favorites',
      //     'emailAvaliable',
      //     'privateNotes',
      //     'privateRating',
      //     'collections',
      //     'userPerCollection',
      //     'audienceReports',
      //     'privatePublicCollections',
      //     'profilePdfExports',
      //     'csvExports',
      //     'campaignTracking',
      //     'influencerTracking',
      //     'paymentMethods'
      //   ],
      //   limits: {
      //     searchRequests: 'unlimited',
      //     resultsShown: 'Max 20',
      //     customerSupport: 'E-mail',
      //     licences: '1',
      //   },
      // },
      {
        name: 'Search',
        planId: AVALIABLE_PACKAGES.advanced,
        desc:
          'Perfect for Start-Ups and Marketers. Unlimited search and unlimited results. Basic Influencer Tracking. Store suitable influencers to your favorites and add notes and ratings to profiles. Contact influencers via the email we provide.',
        price: PACKAGES_PRICES[AVALIABLE_PACKAGES.advanced].month,
        disabled: [
          'audienceReports',
          'brands',
          'profilePdfExports',
          'csvExports',
          'privatePublicCollections',
        ],
        limits: {
          searchRequests: 'unlimited',
          resultsShown: 'unlimited',
          collections: 'Max 3',
          userPerCollection: 'Max 100',
          campaignTracking: 'Max 1 Campaign',
          influencerTracking: '5 Influencers',
          customerSupport: 'E-Mail',
          licences: '1',
          paymentMethods: {
            monthly: 'Credit Card / PayPal / Direct Debit',
            yearly: 'Bank Transfer / Credit Card / PayPal / Direct Debit',
          },
        },
      },
      {
        name: 'Professional',
        planId: AVALIABLE_PACKAGES.pro,
        desc:
          'Perfect for brands and agencies. Audience Reports. Unlimited collections. Influencer Trackings. Create unlimited collections and export all your data as CSV to import in your marketing tool. One year too long? Try our 90 Day Plan!\n\nYou need influData for one campaign only? No need to get commited to a yearly plan with our 90-Days Professional Plan.',
        price: PACKAGES_PRICES[AVALIABLE_PACKAGES.pro].month,
        disabled: [],
        limits: {
          searchRequests: 'unlimited',
          resultsShown: 'unlimited',
          collections: 'unlimited',
          userPerCollection: 'unlimited',
          audienceReports: 'up to 100/month',
          campaignTracking: 'unlimited',
          influencerTracking: '20 Influencers included (+1 seat/4.99 Euro)',
          customerSupport: '24/7 Support via Live-Chat/E-Mail',
          licences: '3',
          paymentMethods: {
            monthly: 'Credit Card / PayPal / Direct Debit',
            yearly: 'Bank Transfer / Credit Card / PayPal / Direct Debit',
          },
        },
      },
      {
        name: 'Enterprise',
        planId: AVALIABLE_PACKAGES.enterprise,
        desc:
          'Our custom made plan for your custom needs. Everything is possible. Unlimited Audience Reports. Seats for your whole company and exclusive consulting by our customer success manager.',
        disabled: [],
        limits: {
          searchRequests: 'unlimited',
          resultsShown: 'unlimited',
          collections: 'unlimited',
          userPerCollection: 'unlimited',
          audienceReports: 'unlimited',
          campaignTracking: 'unlimited',
          influencerTracking: 'unlimited',
          customerSupport: '24/7 Support via Live-Chat/E-Mail/Dedicated Customer Success Manager',
          licences: 'unlimited',
          paymentMethods: {
            monthly: 'Credit Card / PayPal / Direct Debit',
            yearly: 'Bank Transfer / Credit Card / PayPal / Direct Debit',
          },
        },
      },
    ],
  },
  //
  // GERMAN TRANSLATIONS
  //
  [availableLangs.de]: {
    permonth: 'pro Monat',
    peryear: 'pro Jahr',
    month12: '12 Monate',
    days90: '90 Tage',
    billedAnnually: 'jährlich abgerechnet',
    billedSumYear: sum => `abgerechnet ${sum}€ pro Jahr`,
    bindingYear: 'Laufzeit: 12 Monate',
    perYear: 'pro Jahr',
    per90days: 'pro 90 Tage',
    getAllFeaturesFor: 'Das gesamte Paket für',
    startingFrom: 'Das gesamte Paket ab',
    yourPlan: 'Dein Paket',
    letUsHelpYou: 'Wir helfen dir, das perfekte Paket für dein Unternehmen zu finden.',
    reportsIncluded: 'Reports inklusive',
    cancelPlan:
      'Bist du sicher? Du verlierst den Zugriff auf einige Funktionen und möglicherweise gehen Daten Deiner Sammlungen und Kampagnen verloren.',
    cancelledToFreePlan:
      'Dein Abonnement ist gekündigt und es fallen keine Zahlungen mehr an. Es bleibt noch bis zum Ende der Laufzeit aktiv.',
    cancelledWillBeDowngraded: date =>
      `Dein Abonnement ist gekündigt und du wirst am ${date} herabgestuft.`,
    reactivateTexts: {
      reactivateWarning:
        'Wenn du dein Abonnement erneut aktivierst, musst du zuerst deine Zahlungsdaten hinterlegen. Sind diese hinterlegt, wird deine Zahlungsmethode sofort belastet.',
      expirationDateCancelled:
        'Dein Abonnement bleibt aktiv und wird am Ende der Laufzeit erneuert.',
      trialActiveUntil: date =>
        `Dein Trial ist bis zum ${date} aktiv und endet automatisch. Bitte setze dich mit unserem Support in Verbindung, um eine Lizenz zu erhalten.`,
    },
    titles: {
      plan: 'Paket',
      pricing: 'Preis',
    },
    featuresFullList: [
      {
        name: 'Finde',
        items: {
          instagramInfluencers: 'Instagram Influencer',
          tiktokInfluencers: 'TikTok Influencer',
          brands: 'Brands',
          microMacroInfluencers: 'Micro- and Macro Influencer',
        },
      },
      {
        name: 'Limits',
        items: {
          searchRequests: 'Suchanfragen',
          resultsShown: 'Ergebnisse',
          collections: 'Collections',
          userPerCollection: 'Influencer pro Collection',
          audienceReports: 'Audience Reports',
          campaignTracking: 'Campaign Tracking',
          influencerTracking: 'Influencer Tracking',
          licences: 'Lizenzen (Log-Ins)',
        },
      },
      {
        name: 'Features',
        items: {
          searchBy: 'Suche nach Land, Stadt, Followerzahl, Keywords und Geschlecht',
          basicInfluencerAnalysis: 'Basic Influencer Analyse',
          specificKeywordSearch: 'Spezifische Suche',
          favorites: 'Favoriten',
          emailAvaliable: 'E-Mails verfügbar',
          privateNotes: 'Private Notizen',
          privateRating: 'Private Bewertungen',
          privatePublicCollections: 'Private/öffentliche Collections',
          profilePdfExports: 'Profil PDF-Exporte',
          csvExports: 'CSV Export',
          customerSupport: 'Kundenservice',
          paymentMethods: 'Zahlungsarten',
        },
      },
    ],
    plans: [
      // FREE PLAN IS HIDDEN, maybe in future it will be restored
      // {
      //   name: 'Free',
      //   planId: AVALIABLE_PACKAGES.free,
      //   desc:
      //     'Suche und analysiere passende Influencer mit unserem kostenlosen Paket. Keine Zahlungsdaten nötig!',
      //   price: PACKAGES_PRICES[AVALIABLE_PACKAGES.free].month,
      //   disabled: [
      //     'brands',
      //     'favorites',
      //     'emailAvaliable',
      //     'privateNotes',
      //     'privateRating',
      //     'collections',
      //     'userPerCollection',
      //     'audienceReports',
      //     'privatePublicCollections',
      //     'profilePdfExports',
      //     'csvExports',
      //     'campaignTracking',
      //     'influencerTracking',
      //      'paymentMethods'
      //   ],
      //   limits: {
      //     searchRequests: 'unbegrenzt',
      //     resultsShown: 'Max 20',
      //     customerSupport: 'E-mail',
      //     licences: '1',
      //   },
      // },
      {
        name: 'Search',
        planId: AVALIABLE_PACKAGES.advanced,
        desc:
          'Perfekt für Start-Ups und Marketers. Unbegrenzte Suche und unbegrenzte Ergebnisse. Basic Influencer Tracking. Speichere passende Infleuncer in den Favoriten und füge private Notizen und Bewertungen hinzu. Lass die Kontaktdaten anzeigen und kontaktiere die Influencer direkt per Email.',
        price: PACKAGES_PRICES[AVALIABLE_PACKAGES.advanced].month,
        disabled: [
          'audienceReports',
          'brands',
          'profilePdfExports',
          'csvExports',
          'privatePublicCollections',
        ],
        limits: {
          searchRequests: 'unbegrenzt',
          resultsShown: 'unbegrenzt',
          collections: 'Max 3',
          userPerCollection: 'Max 100',
          campaignTracking: 'Max 1 Campaign',
          influencerTracking: 'Max 5 Influencer',
          customerSupport: 'E-mail',
          licences: '1',
          paymentMethods: {
            monthly: 'Kreditkarte / PayPal / Lastschrift',
            yearly: 'Banküberweisung / Kreditkarte / PayPal / Lastschrift',
          },
        },
      },
      {
        name: 'Professional',
        planId: AVALIABLE_PACKAGES.pro,
        desc:
          'Perfekt für etabliertere Brands und Agenturen. Audience Reports. Influencer Trackings. Erstelle unbegrenzt Collections und exportiere deine Daten, um sie mit deinem beliebigen Marketing-Tool zu nutzen.\nDu benötigst influData nur für eine Kampagne? Dann hast du mit unserem 90-Tage-Professional-Paket die Möglichkeit auf eine kürzere Laufzeit.',
        price: PACKAGES_PRICES[AVALIABLE_PACKAGES.pro].month,
        disabled: [],
        limits: {
          searchRequests: 'unbegrenzt',
          resultsShown: 'unbegrenzt',
          userPerCollection: 'unbegrenzt',
          collections: 'unbegrenzt',
          audienceReports: 'bis zu 100/Monat',
          campaignTracking: 'unbegrenzt',
          influencerTracking: '20 Influencer enthalten (+1 Seat / 4,99 Euro)',
          customerSupport: '24/7 Support per Live-Chat/E-Mail',
          licences: '3',
          paymentMethods: {
            monthly: 'Kreditkarte / PayPal / Lastschrift',
            yearly: 'Banküberweisung / Kreditkarte / PayPal / Lastschrift',
          },
        },
      },
      {
        name: 'Enterprise',
        planId: AVALIABLE_PACKAGES.enterprise,
        desc:
          'Unser maßgeschneiderter Plan für Ihre individuellen Bedürfnisse. Alles ist möglich. Unbegrenzte Zielgruppenberichte. Plätze für Ihr ganzes Unternehmen und exklusive Beratung durch unseren Kundenerfolgsmanager.',
        disabled: [],
        limits: {
          searchRequests: 'unbegrenzt',
          resultsShown: 'unbegrenzt',
          collections: 'unbegrenzt',
          userPerCollection: 'unbegrenzt',
          audienceReports: 'unbegrenzt',
          campaignTracking: 'unbegrenzt',
          influencerTracking: 'unbegrenzt',
          customerSupport: '24/7 Support per Live-Chat/E-Mail/Engagierter Customer Success Manager',
          licences: 'unbegrenzt',
          paymentMethods: {
            monthly: 'Kreditkarte / PayPal / Lastschrift',
            yearly: 'Banküberweisung / Kreditkarte / PayPal / Lastschrift',
          },
        },
      },
    ],
  },
}
