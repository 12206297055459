import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import PieChart from '../../../charts/PieChart'
import useTranslation from '../../../../localization/useTranslation'
import disabledChartData from '../../../charts/disabledChartsData'
import { themeCommonStyles } from '../../../../themes/infludataThemes'
import { sortObjectByValues } from '../../../../utils'
import { USER_INDEXES } from '../../../../constants/appSettings'
import {
  ChartTitle,
  TipDescriptionContainer,
  TipDescriptionLabel,
  TipDescriptionOverlay,
  TipDescriptionText,
} from './styles'
import { Box } from '@mui/material'

const AudienceTypeChart = ({ userindex = '', audienceType = {}, isDisabledAnalysis = true, isSmall = false, isForPdf = false }) => {
  const { labelStrings } = useTranslation()
  const isDataExist = arr => !!arr && !!arr.length

  const createAudienceTypeChartData = useCallback(() => {
    if (isDisabledAnalysis || !audienceType || !isDataExist(Object.keys(audienceType))) {
      return disabledChartData.disabledPieChartData({ items: 4 })
    }

    const namesMap =
      userindex === USER_INDEXES.youtube
        ? {
            negative: {
              text: labelStrings.negative,
              color: themeCommonStyles.colors.salmon,
            },
            positive: {
              text: labelStrings.positive,
              color: themeCommonStyles.colors.green,
            },
            neutral: {
              text: labelStrings.neutral,
              color: themeCommonStyles.colors.brownGrey,
            },
          }
        : {
            realPeoplePercentage: {
              text: labelStrings.realPeople,
              color: themeCommonStyles.colors.salmon,
            },
            influencersPercentage: {
              text: labelStrings.influencers,
              color: themeCommonStyles.colors.darkBrown,
            },
            massFollowersPercentage: {
              text: labelStrings.massFollowers,
              color: themeCommonStyles.colors.yellow,
            },
            suspiciousPercentage: {
              text: labelStrings.suspicious,
              color: themeCommonStyles.colors.mediumBrown,
            },
          }

    const sortedAudienceType = sortObjectByValues({ obj: audienceType })

    return Object.keys(sortedAudienceType).reduce(
      (finalObj, item) => {
        if (sortedAudienceType[item] > 0) {
          finalObj.values.push([`${namesMap[item].text.toUpperCase()}`, sortedAudienceType[item]])
          finalObj.colors.push(namesMap[item].color)
        }

        return finalObj
      },
      { values: [['Type', 'Value']], colors: [] }
    )
  }, [audienceType, isDisabledAnalysis])

  return (
    <>
      {!isForPdf && (
        <Box sx={{ mb: isSmall ? '15px' : '40px' }}>
          <ChartTitle>{labelStrings.audienceType}</ChartTitle>
          <TipDescriptionContainer>
            <TipDescriptionLabel>{labelStrings.whatDoesThisAudienceTypeMean}?</TipDescriptionLabel>
            <TipDescriptionOverlay>
              <TipDescriptionText
                dangerouslySetInnerHTML={{ __html: labelStrings.audienceTypeTip[userindex] }}
              />
            </TipDescriptionOverlay>
          </TipDescriptionContainer>
        </Box>
      )}
      <PieChart
        chartData={createAudienceTypeChartData()}
        userindex={userindex}
        withCustomLegend
        isDisabledChart={isDisabledAnalysis}
        isAudienceTypeChart
        notEnoughData={
          !isDisabledAnalysis && (!audienceType || !isDataExist(Object.keys(audienceType)))
        }
        isForPdf={isForPdf}
      />
    </>
  )
}

AudienceTypeChart.propTypes = {
  userindex: PropTypes.string,
  audienceType: PropTypes.object,
  isDisabledAnalysis: PropTypes.bool,
  isForPdf: PropTypes.bool,
  isSmall: PropTypes.bool,
}

export default AudienceTypeChart
