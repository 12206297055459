import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { SuggestedCreatorsInput } from './SuggestedCreatorsInput'
import { Stack } from '@mui/material'
import { SocialPlatformSwitcher } from './SocialPlatformSwitcher'
import { SOCIAL_PLATFORMS_NAMES } from '../../../../../constants/appSettings'
import useDebounce from '../../../../../hooks/useDebounce'
import httpService from '../../../../../services/httpService'

const AddCreatorsTo = ({
  isAllowedToAddCreators = false,
  creatorIdFromState = '',
  setCreatorIdFromState,
  isSetCreatorId = true,
  isDisabledInput = false,
  creatorType = '',
  changeCreatorsData,
  openModalLookalikeAi = false,
}) => {
  const [loadingCreators, setLoadingCreators] = useState(false)
  const [creatorsData, setCreatorsData] = useState([])
  const [notFoundData, setNotFoundData] = useState(false)
  const [error, setError] = useState(false)

  const [currentPlatform, setCurrentPlatform] = useState(SOCIAL_PLATFORMS_NAMES.instagram.name)

  const [inputValue, setInputValue] = useState('')
  const debouncedInputValue = useDebounce(inputValue, 500)

  const fetchSuggestedCreators = async searchString => {
    try {
      setLoadingCreators(true)
      setNotFoundData(false)
      setError(false)
      setCreatorsData([])
      const creatorsUsers = await httpService.fetchSuggestedUsers(
        searchString,
        currentPlatform?.toLowerCase(),
        creatorType
      )
      if (!creatorsUsers?.length) {
        setNotFoundData(true)
      }
      if (creatorsUsers?.length) {
        setCreatorsData(creatorsUsers)
      }
      setLoadingCreators(false)
    } catch (err) {
      setError(true)
      setLoadingCreators(false)
      setCreatorsData([])
      setNotFoundData(false)
    }
  }

  useEffect(() => {
    if (debouncedInputValue === inputValue && debouncedInputValue.length >= 2) {
      fetchSuggestedCreators(debouncedInputValue)
    }
    if (!debouncedInputValue?.length) {
      setCreatorsData([])
      setNotFoundData(false)
      setLoadingCreators(false)
      setError(false)
    }
  }, [debouncedInputValue, creatorType])

  useEffect(() => {
    if (!creatorIdFromState?.length) {
      setInputValue('')
    }
  }, [creatorIdFromState?.length, currentPlatform])

  useEffect(() => {
    setInputValue('')
    setCurrentPlatform(SOCIAL_PLATFORMS_NAMES.instagram.name)
  }, [openModalLookalikeAi])

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <SocialPlatformSwitcher
        currentPlatform={currentPlatform}
        changeCurrentPlatform={setCurrentPlatform}
        changeCreatorsData={changeCreatorsData}
      />
      <SuggestedCreatorsInput
        creatorsData={creatorsData}
        notFoundData={notFoundData}
        error={error}
        currentPlatform={currentPlatform}
        debouncedInputValue={debouncedInputValue}
        inputValue={inputValue}
        loadingCreators={loadingCreators}
        setInputValue={setInputValue}
        setCreatorsData={setCreatorsData}
        setLoadingCreators={setLoadingCreators}
        setNotFoundData={setNotFoundData}
        isAllowedToAddCreators={isAllowedToAddCreators}
        creatorIdFromState={creatorIdFromState}
        setCreatorIdFromState={setCreatorIdFromState}
        isSetCreatorId={isSetCreatorId}
        isDisabledInput={isDisabledInput}
      />
    </Stack>
  )
}

AddCreatorsTo.propTypes = {
  isAllowedToAddCreators: PropTypes.bool,
  creatorIdFromState: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  setCreatorIdFromState: PropTypes.func,
  isSetCreatorId: PropTypes.bool,
  isDisabledInput: PropTypes.bool,
  creatorType: PropTypes.string,
  changeCreatorsData: PropTypes.func,
  openModalLookalikeAi: PropTypes.bool,
}

export default AddCreatorsTo
