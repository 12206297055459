import React, { useState } from 'react'
import {
  Box,
  Stack,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Stepper,
  Step,
  StepLabel,
  Link,
} from '@mui/material'
import { useLocation } from 'react-router-dom'
import LensRoundedIcon from '@mui/icons-material/LensRounded'
import PanoramaFishEyeRoundedIcon from '@mui/icons-material/PanoramaFishEyeRounded'

const PROD_URL = 'https://app.infludata.com'
const PLAY_ARR_COUNT = Array.from(Array(7).keys())

export const SubInactivePage = props => {
  const { magicLinkId, hasChargebeeId } = props || {}

  const { pathname, search } = useLocation() || {}

  const currentUlr = `${PROD_URL}${pathname}${search}`

  const [activeStep, setActiveStep] = useState(2)

  const stepData = {
    [0]: {
      titleMonthly: 'Subscribe Monthly\\n100 EUR - 20 Reports',
      titleYearly: 'Subscribe Yearly\\n1,200 EUR - 240 Reports',
      subtitle: '20 Audience Reports per Month',
      caption: '20 Reports',
      linkMonthly: `https://infludata-audience.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=20-Audience-Reports-Per-Month-EUR-Monthly&subscription_items[quantity][0]=1&layout=full_page&customer[cf_magic_link_id]=${magicLinkId}&subscription[cf_redirect_url]=${currentUlr}`,
      linkYearly: `https://infludata-audience.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=20-Audience-Reports-Per-Month-EUR-Yearly&subscription_items[quantity][0]=1&layout=full_page&customer[cf_magic_link_id]=${magicLinkId}&subscription[cf_redirect_url]=${currentUlr}`,
    },
    [1]: {
      titleMonthly: 'Subscribe Monthly\\n200 EUR - 50 Reports',
      titleYearly: 'Subscribe Yearly\\n2,400 EUR - 600 Reports',
      subtitle: '50 Audience Reports per Month',
      caption: '50 Reports',
      linkMonthly: `https://infludata-audience.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=50-Audience-Report-Per-Month-EUR-Monthly&subscription_items[quantity][0]=1&layout=full_page&customer[cf_magic_link_id]=${magicLinkId}&subscription[cf_redirect_url]=${currentUlr}`,
      linkYearly: `https://infludata-audience.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=50-Audience-Report-Per-Month-EUR-Yearly&subscription_items[quantity][0]=1&layout=full_page&customer[cf_magic_link_id]=${magicLinkId}&subscription[cf_redirect_url]=${currentUlr}`,
    },
    [2]: {
      titleMonthly: 'Subscribe Monthly\\n300 EUR - 100 Reports',
      titleYearly: 'Subscribe Yearly\\n3,600 EUR - 1,200 Reports',
      subtitle: '100 Audience Reports per Month',
      caption: '100 Reports',
      linkMonthly: `https://infludata-audience.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=100-Audience-Reports-Per-Month-EUR-Monthly&subscription_items[quantity][0]=1&layout=full_page&customer[cf_magic_link_id]=${magicLinkId}&subscription[cf_redirect_url]=${currentUlr}`,
      linkYearly: `https://infludata-audience.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=100-Audience-Reports-Per-Month-EUR-Yearly&subscription_items[quantity][0]=1&layout=full_page&customer[cf_magic_link_id]=${magicLinkId}&subscription[cf_redirect_url]=${currentUlr}`,
    },
    [3]: {
      titleMonthly: 'Subscribe Monthly\\n400 EUR - 200 Reports',
      titleYearly: 'Subscribe Yearly\\n4,800 EUR - 2,400 Reports',
      subtitle: '200 Audience Reports per Month',
      caption: '200 Reports',
      linkMonthly: `https://infludata-audience.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=200-Audience-Reports-Per-Month-EUR-Monthly&subscription_items[quantity][0]=1&layout=full_page&customer[cf_magic_link_id]=${magicLinkId}&subscription[cf_redirect_url]=${currentUlr}`,
      linkYearly: `https://infludata-audience.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=200-Audience-Reports-Per-Month-EUR-Yearly&subscription_items[quantity][0]=1&layout=full_page&customer[cf_magic_link_id]=${magicLinkId}&subscription[cf_redirect_url]=${currentUlr}`,
    },
    [4]: {
      titleMonthly: 'Subscribe Monthly\\n600 EUR - 400 Reports',
      titleYearly: 'Subscribe Yearly\\n7,200 EUR - 4,800 Reports',
      subtitle: '400 Audience Reports per Month',
      caption: '400 Reports',
      linkMonthly: `https://infludata-audience.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=400-Audience-Reports-Per-Month-EUR-Monthly&subscription_items[quantity][0]=1&layout=full_page&customer[cf_magic_link_id]=${magicLinkId}&subscription[cf_redirect_url]=${currentUlr}`,
      linkYearly: `https://infludata-audience.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=400-Audience-Reports-Per-Month-EUR-Yearly&subscription_items[quantity][0]=1&layout=full_page&customer[cf_magic_link_id]=${magicLinkId}&subscription[cf_redirect_url]=${currentUlr}`,
    },
    [5]: {
      titleMonthly: 'Subscribe Monthly\\n1,200 EUR - 1,000 Reports',
      titleYearly: 'Subscribe Yearly\\n14,400 EUR - 12,000 Reports',
      subtitle: '1,000 Audience Reports per Month',
      caption: '1,000 Reports',
      linkMonthly: `https://infludata-audience.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=1000-Audience-Reports-Per-Month-EUR-Monthly&subscription_items[quantity][0]=1&layout=full_page&customer[cf_magic_link_id]=${magicLinkId}&subscription[cf_redirect_url]=${currentUlr}`,
      linkYearly: `https://infludata-audience.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=1000-Audience-Reports-Per-Month-EUR-Yearly&subscription_items[quantity][0]=1&layout=full_page&customer[cf_magic_link_id]=${magicLinkId}&subscription[cf_redirect_url]=${currentUlr}`,
    },
    [6]: {
      caption: 'Custom Pricing',
    },
  }

  return (
    <Stack alignItems="center">
      {!hasChargebeeId && (
        <>
          <Stack>
            <Typography align="center" sx={{ color: '#784E4E', fontWeight: 500, fontSize: '18px' }}>
              Hello, you don't have a subscriptions with us.
            </Typography>
            <Typography align="center" sx={{ color: '#784E4E', fontWeight: 500, fontSize: '18px' }}>
              Please create a new subscription or join your company account.
            </Typography>
          </Stack>

          <Box sx={{ maxWidth: '750px', width: '100%', py: 2 }}>
            <Stepper
              sx={{
                '& .MuiStepConnector-line': {
                  borderColor: '#F4E8E8',
                },
              }}
              activeStep={activeStep}
              alternativeLabel
              nonLinear
            >
              {PLAY_ARR_COUNT.map((label, index) => (
                <Step
                  key={label}
                  onClick={() => setActiveStep(index)}
                  sx={{ cursor: 'pointer', '&:hover': { opacity: 0.8 } }}
                >
                  <StepLabel
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fill: '#784E4E',
                        height: '16px', // Adjust the height of the step icon
                      },
                      '& .MuiStepLabel-labelContainer': {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: '-4px', // Adjust the margin to reduce the spacing
                      },
                    }}
                    StepIconComponent={
                      index === activeStep ? LensRoundedIcon : PanoramaFishEyeRoundedIcon
                    }
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: '#784E4E',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        lineHeight: '1', // Adjust the line height to reduce the spacing
                      }}
                    >
                      {stepData[index]?.caption}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          {activeStep !== 6 && (
            <Typography
              align="center"
              sx={{ color: '#784E4E', fontWeight: 500, fontSize: '18px', fontWeight: 'bold' }}
            >
              Your Selection: {stepData[activeStep]?.subtitle}
            </Typography>
          )}

          {activeStep === 6 ? (
            <Stack spacing={2} alignItems="center">
              <Stack spacing={1.5} sx={{ maxWidth: '750px' }}>
                <Typography
                  align="center"
                  sx={{ color: '#784E4E', fontWeight: 'bold', fontSize: '18px' }}
                >
                  Tailored Solution for Your Business
                </Typography>
                <Typography
                  align="center"
                  sx={{ color: '#784E4E', fontWeight: 400, fontSize: '16px' }}
                >
                  We offer fully customizable solutions designed to match your unique needs and
                  budget. Our flexible plan can adapt as your business grows, ensuring you always
                  have the right features and services.
                </Typography>
              </Stack>
              <Stack spacing={1.5} sx={{ maxWidth: '750px' }}>
                <Typography
                  align="center"
                  sx={{ color: '#784E4E', fontWeight: 'bold', fontSize: '16px' }}
                >
                  Personalized Invoicing and Payment Terms
                </Typography>
                <Typography
                  align="center"
                  sx={{ color: '#784E4E', fontWeight: 400, fontSize: '16px' }}
                >
                  We understand that each business has it own invoicing and payment requirements.
                  That's why we offer customizable invoicing options and flexible payment terms
                  tailored to your specific needs. Whether you prefer monthly, quarterly, or annual
                  billing cycles, or have specific invoice formatting requirements, we'll work with
                  you to find the best solution.
                </Typography>
                <Typography
                  align="center"
                  sx={{ color: '#784E4E', fontWeight: 400, fontSize: '16px' }}
                >
                  Contact us today to discuss your specific requirements, and let us create a
                  personalized plan.
                </Typography>
              </Stack>
              <Link
                underline="none"
                target="_top"
                rel="noopener noreferrer"
                href={`mailto:johannes@wecreate.media?subject=influData Audience - Custom Offer`}
                sx={{
                  minWidth: '160px',
                  p: 1.5,
                  mb: '18px !important',
                  textAlign: 'center',
                  border: '1px solid #784E4E',
                  color: '#784E4E',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'none',
                    border: '1px solid #784E4E',
                    color: '#784E4E',
                    opacity: 0.7,
                  },
                }}
              >
                Contact Us
              </Link>
            </Stack>
          ) : (
            <Stack direction="row" justifyContent="center" spacing={1} sx={{ mb: 3, mt: 1 }}>
              <Button
                variant="outlined"
                sx={{
                  color: '#784E4E',
                  borderColor: '#784E4E',
                  fontSize: '14px',
                  maxWidth: '230px',
                  lineHeight: 'initial',
                }}
                onClick={() => window.open(stepData[activeStep]?.linkMonthly, '_blank')}
              >
                {stepData[activeStep]?.titleMonthly.split('\\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    {index !== stepData[activeStep]?.titleMonthly.split('\\n').length - 1 && <br />}
                  </React.Fragment>
                ))}
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: '#784E4E',
                  borderColor: '#784E4E',
                  fontSize: '14px',
                  maxWidth: '230px',
                  lineHeight: 'initial',
                }}
                onClick={() => window.open(stepData[activeStep]?.linkYearly, '_blank')}
              >
                {stepData[activeStep]?.titleYearly.split('\\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    {index !== stepData[activeStep]?.titleYearly.split('\\n').length - 1 && <br />}
                  </React.Fragment>
                ))}
              </Button>
            </Stack>
          )}
        </>
      )}

      {hasChargebeeId && (
        <>
          <Stack>
            <Typography align="center" sx={{ color: '#784E4E', fontWeight: 500, fontSize: '18px' }}>
              Hello, you currently don't have an active subscriptions with us or you are out of
              reports.
            </Typography>
            <Typography align="center" sx={{ color: '#784E4E', fontWeight: 500, fontSize: '18px' }}>
              Please update your subscription or - if you have an active subscription - wait for the
              subscription renewal.
            </Typography>
          </Stack>
          <Button
            variant="outlined"
            sx={{
              color: '#784E4E',
              borderColor: '#784E4E',
              fontWeight: 600,
              fontSize: '14px',
              margin: '20px',
              '&:hover': {
                textDecoration: 'none',
                color: '#784E4E',
                backgroundColor: 'transparent',
              },
            }}
            onClick={() => window.open('https://infludata-audience.chargebeeportal.com', '_blank')}
          >
            Manage Subscription
          </Button>
        </>
      )}

      {activeStep !== 6 && (
        <Stack alignItems="center">
          <Box sx={{ width: '100%', maxWidth: '800px' }}>
            <Typography
              align="left"
              sx={{ color: '#784E4E', fontSize: '14px', fontWeight: 'bold' }}
            >
              Subscription Information
            </Typography>
          </Box>

          <Box sx={{ maxWidth: '800px', pl: 4 }}>
            <List sx={{ listStyleType: 'none', p: 0 }}>
              <ListItem sx={{ display: 'list-item', p: 0, color: '#784E4E' }}>
                <ListItemText
                  primaryTypographyProps={{ fontSize: '14px' }}
                  primary="1. Cancellation is possible at any time before the next subscription renewal date."
                />
              </ListItem>
              <ListItem sx={{ display: 'list-item', p: 0, color: '#784E4E' }}>
                <ListItemText
                  primaryTypographyProps={{ fontSize: '14px' }}
                  primary="2. Subscription changes can be made at any time, granting you the new full amount of reports. Remaining reports from the previous subscription remain valid until their original expiry date. Note: Refunds are not offered for changes during a subscription period."
                />
              </ListItem>
              <ListItem sx={{ display: 'list-item', p: 0, color: '#784E4E' }}>
                <ListItemText
                  primaryTypographyProps={{ fontSize: '14px' }}
                  primary="3. For monthly plans, unused Audience Reports expire at the end of each month and do not carry over. Each new month provides a new set of Audience Reports according to your subscription tier."
                />
              </ListItem>
              <ListItem sx={{ display: 'list-item', p: 0, color: '#784E4E' }}>
                <ListItemText
                  primaryTypographyProps={{ fontSize: '14px' }}
                  primary="4. For yearly plans, you have immediate access to the full yearly amount of report (e.g. 1200 reports for the 100 reports plan). Unused Audience Reports expire at the end of each year and do not carry over."
                />
              </ListItem>
              <ListItem sx={{ display: 'list-item', p: 0, color: '#784E4E' }}>
                <ListItemText
                  primaryTypographyProps={{ fontSize: '14px' }}
                  primary="5. Unlocked Audience Reports can be viewed at any time without additional tokens and even without an active subscription. Updates to audience reports are automatic and free."
                />
              </ListItem>
              <ListItem sx={{ display: 'list-item', p: 0, color: '#784E4E' }}>
                <ListItemText
                  primaryTypographyProps={{ fontSize: '14px' }}
                  primary="6. Team members can be added to your account to access and share the contingent of your reports."
                />
              </ListItem>
            </List>
          </Box>
        </Stack>
      )}
    </Stack>
  )
}
