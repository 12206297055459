import { Button, Box, styled } from '@mui/material'

export const StyledButton = styled(Button)({
  color: '#212B36',
  borderColor: '#212B36',
  fontWeight: 700,
  fontSize: '13px',
  lineHeight: '22px',
})

export const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'space-between',
  '& button': {
    marginBottom: '18px',
    '&:last-child': {
      marginBottom: 0
    }
  }
})

export const StyledBoxPopoverContent = styled(Box)({
  width: '100%',
  '& button': {
    marginBottom: '5px',
    '&:last-child': {
      marginBottom: 0
    }
  }
})