import React from 'react'
import {
  SearchSvgIcon,
  CollectionSvgIcon,
  TrackingIcon,
  ProfileIcon,
  CompareProfilesSvgIcon,
  ToolsForBrandsSvgIcon
} from '../common/icons'
import { IconWrapper } from '../common/styledWrappers/navbarStyles'

export const NavIconsRenderer = ({ IconComponent }) => {
  const iconSize = 20
  return (
    <IconWrapper>
      <IconComponent
        extraStyle={{ position: 'relative', top: '-2px' }}
        height={`${iconSize}px`}
        width={`${iconSize}px`}
      />
    </IconWrapper>
  )
}

export const SearchNavIcon = () => <NavIconsRenderer IconComponent={SearchSvgIcon} />
export const CollectionsNavIcon = () => <NavIconsRenderer IconComponent={CollectionSvgIcon} />
export const InfluencerTrackingNavIcon = () => <NavIconsRenderer IconComponent={TrackingIcon} />
export const ToolsForBrandsIcon = () =>  <NavIconsRenderer IconComponent={ToolsForBrandsSvgIcon} />
export const CompareProfilesNavIcon = () => (
  <NavIconsRenderer IconComponent={CompareProfilesSvgIcon} />
)
export const ProfileNavIcon = () => <ProfileIcon height="25px" width="25px" />
// export const LearningNavIcon = () => <NavIconsRenderer IconComponent={LearnCenterSvgIcon}/> // not used for now
