import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'

const TrackWrapper = styled.div`
  position: absolute;
  transform: translate(0%, -50%);
  height: 10px;
  z-index: 1;
  background: ${props => props.theme.themeGradientBackground};
  border-radius: 5px;
  cursor: pointer;
  left: ${props => props.sourcePercent}%;
  width: ${props => props.targetPercent - props.sourcePercent}%;
`

const RailOneWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 42px;
  transform: translate(0%, -50%);
  border-radius: 4px;
  cursor: pointer;
`

const RailTwoWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 10px;
  transform: translate(0%, -50%);
  border-radius: 4px;
  pointer-events: none;
  background-color: ${props => props.theme.color.midGrey};
`

const HandleWrapper = styled.div`
  left: ${props => props.percent}%;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3);
  background-color: ${props => props.theme.primaryColor};
`

const HandleInvisibleWrapper = styled.div`
  left: ${props => props.percent}%;
  position: absolute;
  transform: translate(-50%, -50%);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 5;
  width: 28px;
  height: 42px;
  cursor: pointer;
  background-color: none;
`

const TickLineWrapper = styled.div`
  position: absolute;
  margin-top: 8px;
  width: 1px;
  height: 12px;
  background-color: rgb(200, 200, 200);
  left: ${props => props.percent}%;
  ${props =>
    props.isDefault &&
    `
    // margin-top: -14px !important;
    height: 30px;
    &::after {
      position: absolute;
      content: "${props.defaultValueLabel}";
      top: -20px;
      font-size: 13px;
      text-transform: uppercase;
      font-weight: bold;
      color: #B99796;
      
    }
  `}
`
const TickLineLabelWrapper = styled.div`
  position: absolute;
  margin-top: 22px;
  font-size: 10px;
  text-align: center;
  margin-left: ${props => -(100 / props.ticksLength) / 2}%;
  width: ${props => 100 / props.ticksLength}%;
  left: ${props => props.percent}%;
  cursor: pointer;
`

const CommonSingleRangeSlider = ({
  initValue = 100, // start value (some value from sliderRanges)
  sliderRanges = {
    '0': 0,
    '10': 10,
    '50': 50,
    '100': 100,
    '500': 500,
    '>500': 600,
  }, // object of labels/values shaped as [label]: value
  onRangeChange, // will get the new selected value as argument
  onRangeUpdate, // will get the new selected item postion in sliderRanges as an argument
  defaultValue = 0,
  defaultValueLabel = '',
  isMirrorSlider = false,
}) => {
  const MIRROR_OFFSET = 50
  const sliderRangesLabels = Object.keys(sliderRanges)
  const sliderRangesValues = Object.values(sliderRanges)
  const minRangeValue = sliderRangesValues[0]
  const maxRangeValue = sliderRangesValues[sliderRangesValues?.length - 1]

  const startSliderValue = sliderRangesValues.findIndex(n => n === initValue) || 0
  const [currentValue, setCurrentValue] = useState(startSliderValue)
  const [isSliderMounted, setIsSliderMounted] = useState(false)

  useEffect(() => {
    setCurrentValue(startSliderValue)
  }, [startSliderValue])

  useEffect(() => {
    setIsSliderMounted(true)
  }, [])

  useEffect(() => {
    if (isSliderMounted) {
      onRangeChange && onRangeChange(sliderRangesValues[currentValue])
    }
  }, [currentValue])

  const setNewValueFromTick = newValue => () => {
    setCurrentValue(newValue)
  }

  const onChangeHandler = newValArr => {
    const [newValue] = newValArr
    setCurrentValue(newValue)
  }

  const onUpdateHandler = newValArr => {
    const [newValue] = newValArr
    onRangeUpdate && onRangeUpdate(newValue)
  }

  const _calcTrackPercents = (targetPercent, sourcePercent) => {
    const percents = {
      targetPercent,
      sourcePercent,
    }

    if (isMirrorSlider) {
      if (percents.targetPercent >= MIRROR_OFFSET) {
        percents.sourcePercent = MIRROR_OFFSET
      } else {
        percents.sourcePercent = percents.targetPercent
        percents.targetPercent = percents.sourcePercent + (MIRROR_OFFSET - percents.targetPercent)
      }
    }
    return percents
  }

  /**
   * NOTE: slider has some default styles
   * to customize styles, change styles in parent components via classes
   */
  return (
    <Slider
      domain={[0, sliderRangesValues?.length - 1]}
      step={1}
      mode={1}
      onChange={onChangeHandler}
      onUpdate={onUpdateHandler}
      values={[currentValue]}
      rootStyle={{
        position: 'relative',
        width: '100%',
        height: 50,
      }}
      className="single_range_slider"
    >
      <Rail>
        {({ getRailProps }) => (
          <>
            <>
              <RailOneWrapper className="single_range_slider_rail_one" {...getRailProps()} />
              <RailTwoWrapper className="single_range_slider_rail_two" />
            </>
          </>
        )}
      </Rail>

      <Handles>
        {({ handles, getHandleProps }) => (
          <div className="single-range-slider-handles">
            {handles?.map(({ id, value, percent }) => (
              <div key={id}>
                <HandleInvisibleWrapper
                  className="single-range-slider-handles_role_slider_invisible"
                  percent={percent}
                  {...getHandleProps(id)}
                />
                <HandleWrapper
                  className="single-range-slider-handles_role_slider"
                  role="slider"
                  aria-valuemin={minRangeValue}
                  aria-valuemax={maxRangeValue}
                  aria-valuenow={value}
                  percent={percent}
                />
              </div>
            ))}
          </div>
        )}
      </Handles>
      <Tracks right={false}>
        {({ tracks, getTrackProps }) => (
          <div className="single-range-slider-tracks">
            {tracks?.map(({ id, source, target }) => {
              const { targetPercent, sourcePercent } = _calcTrackPercents(
                target.percent,
                source.percent
              )
              return (
                <TrackWrapper
                  className="single-range-slider-tracks_single_track"
                  key={id}
                  targetPercent={targetPercent}
                  sourcePercent={sourcePercent}
                  {...getTrackProps()}
                />
              )
            })}
          </div>
        )}
      </Tracks>
      <Ticks count={sliderRangesLabels.length - 1}>
        {({ ticks }) => (
          <div
            className="single-range-slider-ticks"
            style={{
              position: 'relative',
              width: '100%',
              margin: '0 auto',
            }}
          >
            {ticks?.map((tick, idx) => {
              return (
                <div
                  key={tick.id}
                  onClick={setNewValueFromTick(idx)}
                  className="single-range-slider-ticks_single_tick"
                >
                  <TickLineWrapper
                    className="single-range-slider-ticks_single_tick_line"
                    percent={tick.percent}
                    isDefault={sliderRangesValues[idx] === defaultValue}
                    defaultValueLabel={defaultValueLabel}
                  />
                  <TickLineLabelWrapper
                    className="single-range-slider-ticks_single_tick_label"
                    percent={tick.percent}
                    ticksLength={ticks.length}
                  >
                    {sliderRangesLabels[idx]}
                  </TickLineLabelWrapper>
                </div>
              )
            })}
          </div>
        )}
      </Ticks>
    </Slider>
  )
}

CommonSingleRangeSlider.propTypes = {
  initValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sliderRanges: PropTypes.object,
  onRangeChange: PropTypes.func,
  onRangeUpdate: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValueLabel: PropTypes.string,
  isMirrorSlider: PropTypes.bool,
}

export default CommonSingleRangeSlider
