import styled from 'styled-components'
import { USER_INDEXES } from '../../../constants/appSettings'

export const Galery = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.platform === USER_INDEXES.youtube ? `repeat(2, auto)` : `repeat(3, auto)`};
  grid-gap: 10px;
  justify-content: flex-start;
  margin-top: 15px;

  @media screen and (max-width: 1335px) {
    grid-template-columns: repeat(2, auto);
  }
`

export const GaleryItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`

export const GaleryItemOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${props => props.theme.getGradientsForPlatform(props.platform).main};
  visibility: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
`

export const GaleryItemActivityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.colors.white};

  .icon_like,
  .icon_comment {
    width: 20px;
    margin-right: 5px;

    path {
      fill: ${props => props.theme.colors.white};
    }
  }
`

export const GaleryItemAnalysisWrapper = styled.div`
  ._icon-container {
    margin: 0 5px 0 0;

    svg {
      width: 20px;

      path {
        fill: ${props => props.theme.colors.white};
      }
    }
  }
`

export const GaleryItem = styled.a`
  display: block;
  position: relative;
  ${props => {
    let size = { w: 0, h: 0 }
    switch (props.platform) {
      case USER_INDEXES.instagram:
        size.w = props.isIgReels ? 170 : 160
        size.h = props.isIgReels ? 300 : 160
        break
      case USER_INDEXES.tiktok:
        size.w = 170
        size.h = 300
        break
      case USER_INDEXES.youtube:
        size.w = 265
        size.h = 295
    }
    return `
      width: ${size.w}px;
      height: ${size.h}px;
    `
  }}

  &:hover {
    ${GaleryItemOverlay} {
      visibility: visible;
    }
  }
`
export const YtGalleryItem = styled.div`
  background-color: ${props => props.theme.color.white};
  padding: 9px;
  min-width: 265px;
  width: 100%;
  min-height: 303px;
  border-radius: 16px;
  color: ${props => props.theme.colors.darkBrown};
`
export const YtGalleryItemTitle = styled.div`
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 12px;
  padding: 0 12px;
  max-width: 221px;
  max-height: 39px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
`
export const YtGalleryImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 246px;
  max-height: 138px;
  border-radius: 10px;
  margin-bottom: 10px;
`
export const YtIconGalleryWrapper = styled.div`
  display: flex;
  justify-content: ${props => (props.isSpaceBetween ? 'space-between' : 'flex-start')};
  align-items: center;
  padding: 0 12px;
  margin-bottom: 14px;
`
export const YtIconSingle = styled.div`
  display: flex;
  line-height: initial;
  font-weight: 700;
  ${props => !props.isMargin && 'margin-right: 15px'};
  svg {
    margin-right: 4px;
  }
`
export const YtGalleryStatistics = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  div {
    line-height: 16px;
    font-size: 13px;
  }
  span {
    font-weight: 600;
    color: ${props => props.theme.colors.salmon};
  }
  margin-bottom: 5px;
`
