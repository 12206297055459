import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import useTranslation from '../../../localization/useTranslation'

import { BlockUserIcon } from '../../common/icons'
import WithTip from '../../common/popups/WithTip'
import WithUpgradeTip from '../../common/popups/WithUpgradeTip'
import { Link } from 'react-router-dom'

const IconWrapper = styled.div`
  ${props => props.disabled && props.theme.disableBlock};
  position: relative;
  svg {
    width: 20px;
    height: 20px;
  }
  ._icon-container {
    margin: 7.5px;
  }
`
const UpgradeLinkWrapper = styled(Link)`
  color: ${props => props.theme.color.black};
  text-decoration: underline;
  &:hover {
    color: ${props => props.theme.color.black};
    opacity: 0.9;
  }
`

const ProfileBlackListTool = ({
  isBlack = false,
  userindex = '',
  userId = '',
  onBlockUnblockUser,
  isUserAuthenticated = false,
  enableBlackList = false,
}) => {
  const { labelStrings, currentLang } = useTranslation()

  const TipComponent = useMemo(
    () => (
      <>
        {enableBlackList ? (
          <div>
            <UpgradeLinkWrapper to="/settings">{labelStrings.contactSales}</UpgradeLinkWrapper>{' '}
            {labelStrings.to} {labelStrings.addUserToBlackListTip}
          </div>
        ) : isBlack ? (
          <div>{labelStrings.removeFromBlackList}</div>
        ) : (
          <div>
            {labelStrings.blockUserTipsFirstPart}
            <br />
            <Link to="/collections/blacklist">
              <u>{labelStrings.blockUserTipsLastPart}</u>
            </Link>
          </div>
        )}
      </>
    ),
    [currentLang, isBlack, enableBlackList]
  )

  return (
    <div onClick={() => onBlockUnblockUser({ profileId: userId, isBlack })}>
      {!isUserAuthenticated ? (
        <WithUpgradeTip
          tipText={labelStrings.addMoreUsers}
          userindex={userindex}
          withSignup={!isUserAuthenticated}
        >
          <BlockUserIcon userindex={userindex} />
        </WithUpgradeTip>
      ) : (
        <WithTip TipComponent={TipComponent} position="top left" userindex={userindex}>
          <span>
            <IconWrapper disabled={enableBlackList}>
              <BlockUserIcon userindex={userindex} isActiveIcon={isBlack} />
            </IconWrapper>
          </span>
        </WithTip>
      )}
    </div>
  )
}

ProfileBlackListTool.propTypes = {
  userId: PropTypes.string,
  isBlack: PropTypes.bool,
  onBlockUnblockUser: PropTypes.func,
  isUserAuthenticated: PropTypes.bool,
  enableBlackList: PropTypes.bool,
}

export default ProfileBlackListTool
