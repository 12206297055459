import React from 'react'
import PropTypes from 'prop-types'
import { Stack, TableRow, TableCell, Typography, Box } from '@mui/material'
import { fShortenNumber } from '../../../../utils/formatNumber'
import { socialIcon } from './utils'

export const EngagementRows = ({ row }) => {
  const {
    comments,
    engagementRate,
    isEngagementRateEstimated,
    isReachEstimated,
    label,
    likes,
    rateOfLikes,
    reach,
    saves,
    shares,
  } = row

  // if (!comments && !engagementRate && !likes && !rateOfLikes && !reach && !saves && !shares) return

  const isBoldCell = label?.endsWith('_total') || label === 'overall' || label === 'tiktok'
  const isDivider = label?.startsWith('tiktok')

  return (
    <TableRow
      hover
      sx={{
        borderTop: isDivider && isBoldCell ? '1px solid rgba(145, 158, 171, 0.24)' : 'inherit',
        borderBottom:
          label === 'overall' || (isDivider && isBoldCell)
            ? '1px solid rgba(145, 158, 171, 0.24)'
            : 'inherit',
      }}
    >
      <TableCell
        align={label === 'overall' ? 'center' : 'left'}
        sx={{ borderRight: '2px solid rgba(145, 158, 171, 0.24)' }}
      >
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
          {label !== 'overall' ? (
            <Stack direction="row" spacing={0.5}>
              <Box sx={{ width: '25px', height: '25px', display: 'inline-block' }}>
                {socialIcon[label?.split('_')[0]]}
              </Box>
              <Typography
                noWrap
                variant={isBoldCell ? 'subtitle2' : 'inherit'}
                sx={{ textTransform: 'capitalize', minWidth: '75px' }}
              >
                {label?.split('_')[1] || 'total'}
              </Typography>
            </Stack>
          ) : (
            <Typography
              textAlign="center"
              noWrap
              variant={'subtitle2'}
              sx={{ textTransform: 'capitalize' }}
            >
              {label}
            </Typography>
          )}
        </Stack>
      </TableCell>

      <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
        <Typography noWrap variant={isBoldCell ? 'subtitle2' : 'inherit'}>
          {reach ? `${fShortenNumber(reach)}${isReachEstimated ? '*' : ''}` : ''}
        </Typography>
      </TableCell>

      <TableCell align="center">
        <Typography noWrap variant={isBoldCell ? 'subtitle2' : 'inherit'}>
          {likes ? fShortenNumber(likes) : ''}
        </Typography>
      </TableCell>

      <TableCell align="center">
        <Typography noWrap variant={isBoldCell ? 'subtitle2' : 'inherit'}>
          {comments ? fShortenNumber(comments) : ''}
        </Typography>
      </TableCell>

      <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
        <Typography noWrap variant={isBoldCell ? 'subtitle2' : 'inherit'}>
          {shares ? fShortenNumber(shares) : ''}
        </Typography>
      </TableCell>

      <TableCell align="center">
        <Typography noWrap variant={isBoldCell ? 'subtitle2' : 'inherit'}>
          {saves ? fShortenNumber(saves) : ''}
        </Typography>
      </TableCell>

      <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
        <Typography noWrap variant={isBoldCell ? 'subtitle2' : 'inherit'}>
          {engagementRate
            ? `${fShortenNumber(engagementRate)}${isEngagementRateEstimated ? '*' : ''}`
            : ''}
        </Typography>
      </TableCell>

      <TableCell align="center">
        <Typography noWrap variant={isBoldCell ? 'subtitle2' : 'inherit'}>
          {rateOfLikes ? fShortenNumber(rateOfLikes) : ''}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

EngagementRows.propTypes = {
  row: PropTypes.object,
}
