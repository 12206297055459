import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin: 15px 0;
  border-radius: 13px;
  overflow: hidden;
  background-color: ${props => props.theme.colors.offWhite};
`

export const AudienceOverlapInfo = styled.div`
  padding: 20px 35px;
  border-top: 1px solid ${props => props.theme.colors.lightBrown};
  border-bottom: 1px solid ${props => props.theme.colors.lightBrown};

  @media screen and (max-width: 1300px) {
    padding: 20px;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  & + & {
    margin-top: 10px;
  }
`

export const TotalInfluencers = styled.div`
  margin-right: 40px;
  display: inline-flex;
  align-items: center;
`

export const UnlockedInfluencers = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 15px;
  border-radius: 26px;
  background-color: ${props => props.theme.colors.lightBrown};
  white-space: nowrap;
`

export const IconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
`

export const UnlockedInfluencersText = styled.span`
  font-size: 13px;
  line-height: 16px;
  color: ${props => props.theme.colors.brown};
`

export const UnlockAllButton = styled.button`
  display: inline;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
  color: ${props => props.theme.colors.salmon};
  text-decoration: underline;
`

export const Label = styled.span`
  white-space: nowrap;
  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
  color: ${props => props.theme.colors.mauve};
`

export const Value = styled(Label)`
  white-space: nowrap;
  color: ${props => props.theme.colors.brown};
`

export const FollowersInTotal = styled.div`
  margin-right: 60px;
  display: inline-flex;
  align-items: center;
`

export const ExpectedReach = styled.div`
  display: inline-flex;
  align-items: center;
`

export const OverlapChartsWrapper = styled.div`
  padding: 20px 0;
`
export const TabsWrapper = styled.div`
  padding: 20px;
  border-radius: 13px;
  background-color: ${props => props.theme.colors.offWhite};

  .tabs {
    justify-content: flex-start;
  }

  .tab {
    padding: 7px 15px;

    &_is-active {
      padding: 8px 16px;
    }

    &_is-not-active:hover {
      padding: 8px 16px;
    }
  }
`

export const ChartWrapper = styled.div``

export const HeaderModalTitle = styled.div`
  padding: 0 5px;
  font-size: 16px;
`
export const BodyModalContent = styled.div`
  font-size: 16px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
`