import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import useTranslation from '../../../localization/useTranslation'

import Input from '../inputs/Input'
import InputAutocomplete from '../inputs/InputAutocomplete'

import { ArrowIcon } from '../icons/icons'
import {
  CalendarNavbarContainer,
  CalendarNavbarPickMonthButtons,
  CalendarNavbarPickMonthButton,
  CalendarNavbarInputMonthWrapper,
  CalendarNavbarInputYearWrapper,
} from './styles'

const CalendarNavbar = ({ currentDate, changeDate }) => {
  const { labelStrings, months, errors } = useTranslation()
  const monthArray = Object.keys(months).map(key => months[key])

  const currentMonth = currentDate.getMonth()
  const currentYear = currentDate.getFullYear()
  const monthValue = monthArray[currentMonth]

  const [yearValue, setYearValue] = useState(currentYear)
  const [invalidYearError, setInvalidYearError] = useState('')

  useEffect(() => {
    setYearValue(currentDate.getFullYear())
  }, [currentDate])

  const changeMonth = month => {
    if (month < 0 || month > 11) return
    changeDate(new Date(currentYear, month))
  }

  const changeYear = year => {
    changeDate(new Date(year, currentMonth))
  }

  const onSelectMonth = month => {
    const monthNumber = monthArray.indexOf(month)
    if (monthNumber === -1) return
    changeMonth(monthNumber)
  }

  const onChangeYearValue = event => {
    setYearValue(event.target.value)
  }

  const formatAndChangeYear = () => {
    const nextYear = +yearValue
    if (!Number.isInteger(nextYear) || nextYear > 2100 || nextYear < 1900) {
      setInvalidYearError(errors.invalidYear)
    } else {
      changeYear(nextYear)
      setInvalidYearError('')
    }
  }

  const onPrevMonthClick = () => {
    if (currentMonth === -1) changeDate(new Date(currentYear - 1, 11))
    changeDate(new Date(currentYear, currentMonth - 1))
  }

  const onNextMonthClick = () => {
    if (currentMonth === 11) changeDate(new Date(currentYear + 1, 0))
    changeDate(new Date(currentYear, currentMonth + 1))
  }

  return (
    <CalendarNavbarContainer>
      <CalendarNavbarInputMonthWrapper>
        <InputAutocomplete
          id="calendar-input-month"
          name="calendar-input-month"
          label={labelStrings.month}
          initialValue={monthValue}
          options={monthArray}
          selectOption={onSelectMonth}
          autoComplete="off"
        />
      </CalendarNavbarInputMonthWrapper>

      <CalendarNavbarInputYearWrapper>
        <Input
          id="calendar-input-year"
          name="calendar-input-year"
          type="number"
          label={labelStrings.year}
          value={yearValue}
          onChange={onChangeYearValue}
          onBlur={formatAndChangeYear}
          errorMessage={invalidYearError}
          autoComplete="off"
        />
      </CalendarNavbarInputYearWrapper>

      <CalendarNavbarPickMonthButtons>
        <CalendarNavbarPickMonthButton onClick={onPrevMonthClick}>
          <ArrowIcon direction="left" />
        </CalendarNavbarPickMonthButton>

        <CalendarNavbarPickMonthButton onClick={onNextMonthClick}>
          <ArrowIcon direction="right" />
        </CalendarNavbarPickMonthButton>
      </CalendarNavbarPickMonthButtons>
    </CalendarNavbarContainer>
  )
}

CalendarNavbar.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  changeDate: PropTypes.func.isRequired,
}

export default CalendarNavbar
