import React from 'react'
import { Paper, Stack, Typography } from '@mui/material'
import useTranslation from '../../../../../../../localization/useTranslation'
import { pretifyBigNum } from '../../../../../../../utils'
import {
  typographyStyles,
  typographyGreenStyles,
  typographySmallStyles,
} from '../../../commonStyle'
import { getPercent } from '../../../../../../../new-ui/components/carousel/published-content/utils'

export const CreatorsCard = props => {
  const { username, currency, mentionAccountsAndTimeline, metadata } = props
  const { labelStrings, languageOptions, gender } = useTranslation()

  const creatorsCount = mentionAccountsAndTimeline?.length

  const {
    totalContentPieces,
    totalMediaValue,
    totalReach,
    partnershipTypes,
    topCreatorLocations,
    topCreatorLanguages,
    creatorGenderRates,
    contentStrategy,
    topHashtags,
  } = metadata || {}

  const findLanguage = currentLang => languageOptions?.find(lang => lang.value === currentLang)

  const transformObjectToArray = (data, type) => {
    return Object.keys(data).reduce((acc, key) => {
      if (type === 'lang') {
        acc.push({ name: findLanguage(key)?.label, value: data[key] })
      } else if (type === 'gender') {
        const genderObj = {
          w: gender.female,
          m: gender.male,
        }
        acc.push({ name: genderObj[key], value: data[key] })
      } else if (type === 'contentStrategy') {
        acc.push({ name: data[key]?.contentType, value: data[key]?.rate })
      } else if (type === 'topHashtags') {
        acc.push({ name: '', value: data[key]?.hashtag })
      } else {
        acc.push({ name: key, value: data[key] })
      }
      return acc
    }, [])
  }

  const CustomPaper = ({
    title,
    subtitle,
    data,
    formatter,
    isContentStrategy,
    isUnsetFontStyle,
  }) => (
    <Paper
      sx={{
        p: 2.5,
        pt: '4px !important',
        width: 'fit-content',
        borderRadius: '24px',
        border: '1px solid #F4E8E8',
      }}
    >
      <Typography sx={{ ...typographyStyles, mb: 0.5, fontSize: '16px' }}>{title}</Typography>
      {data.map((item, idx) => (
        <Stack key={item.name || idx} direction="row" spacing={0.5}>
          <Typography
            sx={{
              ...typographyGreenStyles,
              textTransform: isContentStrategy ? 'lowercase' : 'uppercase',
              fontSize: '16px',
            }}
          >
            {formatter(item.value)}
          </Typography>
          {!isContentStrategy && (
            <Typography sx={{ ...typographySmallStyles, fontSize: '16px' }}>
              ({item.value} {labelStrings.brandsTimelineText.creators})
            </Typography>
          )}
          <Typography
            sx={{
              ...typographySmallStyles,
              fontWeight: 'bold',
              textTransform: isContentStrategy
                ? 'capitalize'
                : isUnsetFontStyle
                ? 'unset'
                : 'lowercase',
              fontSize: '16px',
            }}
          >
            {subtitle?.toLowerCase()}{' '}
            {isUnsetFontStyle
              ? item.name?.charAt(0).toUpperCase() + item.name?.slice(1)
              : item.name}
          </Typography>
        </Stack>
      ))}
    </Paper>
  )

  const topCreatorLocationsArray = transformObjectToArray(topCreatorLocations)
  const topCreatorLanguagesArray = transformObjectToArray(topCreatorLanguages, 'lang')
  const creatorGenderArray = transformObjectToArray(creatorGenderRates, 'gender')
  const creatorCountWithGender = creatorGenderRates ? creatorGenderRates.m + creatorGenderRates.w : 0

  const creatorContentStrategy = transformObjectToArray(contentStrategy, 'contentStrategy')
  const topCreatorHashtags = transformObjectToArray(topHashtags, 'topHashtags')

  const WithinTheLast365Days = () => (
    <Paper
      sx={{
        p: 2.5,
        pt: '4px !important',
        width: 'fit-content',
        borderRadius: '24px',
        border: '1px solid #F4E8E8',
        // maxWidth: '500px',
      }}
    >
      <Typography sx={{ ...typographyStyles, mb: 0.5, fontSize: '16px' }}>
        {labelStrings.brandsTimelineText.withinTheLast365DaysWasTaggedBy(username)}
      </Typography>

      {!!mentionAccountsAndTimeline?.length && (
        <Typography sx={{ ...typographyGreenStyles, fontSize: '16px' }}>
          {pretifyBigNum(creatorsCount)} {labelStrings.brandsTimelineText.differentCreators}
        </Typography>
      )}
      {totalReach && (
        <Stack direction="row" spacing={0.5} sx={{ marginTop: 2 }}>
          <Typography sx={{...typographyGreenStyles, fontSize: '16px'}}>
            {labelStrings.brandsTimelineText.reach(pretifyBigNum(totalReach))}
          </Typography>
          <Typography sx={{...typographySmallStyles, fontSize: '16px'}}>
            {labelStrings.brandsTimelineText.wereGenerated}
          </Typography>
        </Stack>
      )}
      {totalContentPieces && (
        <Stack direction="row" spacing={0.5} >
          <Typography sx={{ ...typographyGreenStyles, fontSize: '16px' }}>
            {pretifyBigNum(totalContentPieces)}{' '}
            {labelStrings.brandsTimelineText.brandedContentPieces}
          </Typography>
          <Typography sx={{ ...typographySmallStyles, fontSize: '16px' }}>
            {labelStrings.brandsTimelineText.werePublished}
          </Typography>
        </Stack>
      )}
      {totalMediaValue && (
        <Stack direction="row" spacing={0.5}>
          <Typography sx={{ ...typographyGreenStyles, fontSize: '16px' }}>
            {pretifyBigNum(totalMediaValue)} {currency?.toUpperCase()}{' '}
            {labelStrings.brandsTimelineText.emv}
          </Typography>
          <Typography sx={{ ...typographySmallStyles, fontSize: '16px' }}>
            {labelStrings.brandsTimelineText.wasGenerated}
          </Typography>
        </Stack>
      )}

    </Paper>
  )

  const PartnershipTypes = () => (
    <Paper
      sx={{
        p: 2.5,
        pt: '4px !important',
        width: 'fit-content',
        borderRadius: '24px',
        border: '1px solid #F4E8E8',
      }}
    >
      <Typography sx={{ ...typographyStyles, mb: 0.5, fontSize: '16px' }}>
        {labelStrings.brandsTimelineText.partnershipTypes}
      </Typography>

      {partnershipTypes?.oneOff && (
        <Stack direction="row" spacing={0.5}>
          <Typography sx={{ ...typographyGreenStyles, fontSize: '16px' }}>
            {Math.floor((partnershipTypes?.oneOff / creatorsCount) * 100)}%
          </Typography>
          <Typography sx={{ ...typographySmallStyles, fontSize: '16px' }}>
            ({partnershipTypes?.oneOff} {labelStrings.brandsTimelineText.creators})
          </Typography>
          <Typography sx={{ ...typographySmallStyles, fontWeight: 'bold', fontSize: '16px' }}>
            {labelStrings.brandsTimelineText.oneOff}
          </Typography>
          <Typography
            sx={{ ...typographySmallStyles, fontSize: '16px' }}
          >{`(<1 ${labelStrings.brandsTimelineText.month})`}</Typography>
        </Stack>
      )}

      {partnershipTypes?.longTerm && (
        <Stack direction="row" spacing={0.5}>
          <Typography sx={{ ...typographyGreenStyles, fontSize: '16px' }}>
            {Math.floor((partnershipTypes?.longTerm / creatorsCount) * 100)}%
          </Typography>
          <Typography sx={{ ...typographySmallStyles, fontSize: '16px' }}>
            ({partnershipTypes?.longTerm} {labelStrings.brandsTimelineText.creators})
          </Typography>
          <Typography sx={{ ...typographySmallStyles, fontWeight: 'bold', fontSize: '16px' }}>
            {labelStrings.brandsTimelineText.longTerm}
          </Typography>
          <Typography
            sx={{ ...typographySmallStyles, fontSize: '16px' }}
          >{`(1-6 ${labelStrings.brandsTimelineText.month})`}</Typography>
        </Stack>
      )}

      {partnershipTypes?.ambassadors && (
        <Stack direction="row" spacing={0.5}>
          <Typography sx={{ ...typographyGreenStyles, fontSize: '16px' }}>
            {Math.floor((partnershipTypes?.ambassadors / creatorsCount) * 100)}%
          </Typography>
          <Typography sx={{ ...typographySmallStyles, fontSize: '16px' }}>
            ({partnershipTypes?.ambassadors} {labelStrings.brandsTimelineText.creators})
          </Typography>
          <Typography sx={{ ...typographySmallStyles, fontWeight: 'bold', fontSize: '16px' }}>
            {labelStrings.brandsTimelineText.brandAmbassadors}
          </Typography>
          <Typography
            sx={{ ...typographySmallStyles, fontSize: '16px' }}
          >{`(>6 ${labelStrings.brandsTimelineText.month})`}</Typography>
        </Stack>
      )}
    </Paper>
  )

  return (
    <Stack spacing={2} direction="row">
      <Stack spacing={1}>
        <WithinTheLast365Days />
        <PartnershipTypes />
      </Stack>

      {(!!topCreatorLocationsArray?.length ||
        !!topCreatorLanguagesArray?.length ||
        !!creatorGenderArray?.length) && (
        <Stack spacing={1}>
          {!!topCreatorLocationsArray?.length && (
            <CustomPaper
              title={`TOP 3 CREATOR LOCATIONS`}
              subtitle={labelStrings.brandsTimelineText.from}
              data={topCreatorLocationsArray}
              formatter={value => `${Math.floor((value / creatorsCount) * 100)}%`}
              isUnsetFontStyle
            />
          )}
          {!!topCreatorLanguagesArray?.length && (
            <CustomPaper
              title={labelStrings.brandsTimelineText.top3CreatorLanguages}
              subtitle={labelStrings.brandsTimelineText.speak}
              data={topCreatorLanguagesArray}
              formatter={value => `${Math.floor((value / creatorsCount) * 100)}%`}
              isUnsetFontStyle
            />
          )}
          {!!creatorGenderArray?.length && (
            <CustomPaper
              title={labelStrings.brandsTimelineText.creatorGender}
              subtitle={labelStrings.brandsTimelineText.are}
              data={creatorGenderArray}
              formatter={value => `${Math.floor((value / creatorCountWithGender) * 100)}%`}
            />
          )}
        </Stack>
      )}

      {(!!contentStrategy?.length || !!topHashtags?.length) && (
        <Stack spacing={1}>
          {!!contentStrategy?.length && (
            <CustomPaper
              title={labelStrings.brandsTimelineText.contentStrategy}
              data={creatorContentStrategy}
              formatter={value => getPercent(value)}
              isContentStrategy
            />
          )}
          {!!topHashtags?.length && (
            <CustomPaper
              title={labelStrings.brandsTimelineText.campaignHashtags}
              data={topCreatorHashtags}
              formatter={value => value}
              isContentStrategy
            />
          )}
        </Stack>
      )}
    </Stack>
  )
}
