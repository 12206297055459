import React from 'react'
import PropTypes from 'prop-types'
import { TableRow, TableCell, IconButton, Avatar } from '@mui/material'
import { getUrlWithProxy, pretifyBigNum } from '../../../../../../utils'
import Label from '../../../../../../new-ui/components/label'
import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded'
import useTranslation from '../../../../../../localization/useTranslation'

export const FieldTableRow = ({ row, isShowTypeField, isForPdf, isBrandPerformancePdf }) => {
  const { labelStrings } = useTranslation()

  const {
    profilePicURL,
    username,
    displayName,
    followerCount,
    country,
    contentCount,
    reach,
    mediaValue,
    profileId,
    durationInDays,
    isActive,
    creatorType,
  } = row || {}

  const profileLink = `${process.env.REACT_APP_PREFIX}profile/${profileId}`

  const goToProfile = () => {
    window?.open(profileLink, '_blank')
  }

  const calculateDuration = durationInDays => {
    const years = Math.floor(durationInDays / 365)
    const remainingDays = durationInDays % 365
    const months = Math.floor(remainingDays / 30)
    const days = remainingDays % 30

    return `${
      years > 0
        ? years + (years === 1 ? ` ${labelStrings.year}, ` : ` ${labelStrings.years}, `)
        : ''
    }${
      months > 0
        ? months + (months === 1 ? ` ${labelStrings.month} ` : ` ${labelStrings.months}, `)
        : ''
    }${days > 0 ? days + (days === 1 ? ` ${labelStrings.day} ` : ` ${labelStrings.days} `) : ''}`
  }

  return (
    <>
      <TableRow
        sx={{
          cursor: 'pointer',
          '& .MuiTableCell-root': {
            color: isBrandPerformancePdf ? '#BC9797' : '#212B36',
          },
        }}
        hover
        onClick={goToProfile}
      >
        {!isBrandPerformancePdf && (
          <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
            <Avatar src={getUrlWithProxy(profilePicURL)} alt={username} />
          </TableCell>
        )}

        <TableCell
          align="left"
          sx={{
            textTransform: 'capitalize',
            maxWidth: isForPdf ? '100px' : 'fit-content',
            overflowWrap: isForPdf ? 'break-word' : 'normal',
            fontSize: isForPdf ? '12px' : '14px',
          }}
        >
          @{username}
        </TableCell>

        <TableCell
          align="left"
          sx={{
            textTransform: 'capitalize',
            maxWidth: isForPdf ? '100px' : 'fit-content',
            overflowWrap: isForPdf ? 'break-word' : 'normal',
            fontSize: isForPdf ? '12px' : '14px',
          }}
        >
          {displayName}
        </TableCell>

        <TableCell align={'left'} sx={{ fontSize: isForPdf ? '12px' : '14px' }}>
          {pretifyBigNum(followerCount)}
        </TableCell>

        <TableCell
          align="left"
          sx={{
            fontSize: isForPdf ? '12px' : '14px',
            maxWidth: isForPdf ? (isBrandPerformancePdf ? '75px' : '100px') : 'fit-content',
            overflowWrap: isForPdf ? 'break-word' : 'normal',
          }}
        >
          {country}
        </TableCell>

        <TableCell
          align="center"
          sx={{
            fontSize: isForPdf ? '12px' : '14px',
            maxWidth: isBrandPerformancePdf ? '60px' : 'fit-content',
          }}
        >
          {pretifyBigNum(contentCount)}
        </TableCell>

        <TableCell
          align={isBrandPerformancePdf ? 'center' : 'left'}
          sx={{
            fontSize: isForPdf ? '12px' : '14px',
            width: isBrandPerformancePdf ? '110px' : 'fit-content',
          }}
        >
          {pretifyBigNum(reach)}
        </TableCell>

        <TableCell align="left" sx={{ fontSize: isForPdf ? '12px' : '14px' }}>
          {pretifyBigNum(mediaValue)}
        </TableCell>

        {isShowTypeField && (
          <TableCell
            align="left"
            sx={{ textTransform: 'capitalize', fontSize: isForPdf ? '12px' : '14px' }}
          >
            {creatorType}
          </TableCell>
        )}

        {(!isForPdf || isBrandPerformancePdf) && (
          <TableCell
            align="left"
            sx={{
              textTransform: 'lowercase',
              fontSize: isForPdf ? '12px' : '14px',
              maxWidth: isBrandPerformancePdf ? '130px' : 'fit-content',
            }}
          >
            {calculateDuration(durationInDays)}
          </TableCell>
        )}

        {false && !isForPdf && (
          <TableCell align="center">
            <Label
              variant="soft"
              color={isActive ? 'success' : 'error'}
              sx={{ textTransform: 'capitalize' }}
            >
              {isActive ? labelStrings.yes : labelStrings.no}
            </Label>
          </TableCell>
        )}

        {!isForPdf && (
          <TableCell align="right">
            <IconButton sx={{ width: '34px', height: '34px' }} onClick={goToProfile}>
              <ArrowOutwardRoundedIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
    </>
  )
}

FieldTableRow.propTypes = {
  row: PropTypes.object,
  isShowTypeField: PropTypes.bool,
  isForPdf: PropTypes.bool,
  isBrandPerformancePdf: PropTypes.bool,
}
